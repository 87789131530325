class BeefCattleSbvRadarChartController {
  constructor(
  ) {
    'ngInject';
  }

  $onChanges(changes) {
    if (changes.evaluations) {
      this.drawChart(this.evaluations);
    }
  }

  drawChart(evaluations) {
    const width = this.chartSize.width;
    const height = this.chartSize.height;
    const chartWidth = this.chartSize.chartWidth;
    const labelWidth = this.chartSize.labelWidth;

    const traitCount = 6;

    const label = [{
      name: '枝肉重量',
      adjust: {
        y: 20
      }
    }, {
      name: 'ロース芯面積',
      adjust: {
        x: -5
      }
    }, {
      name: 'バラの厚さ'
    }, {
      name: '皮下脂肪厚',
      adjust: {
        y: -20
      }
    }, {
      name: '歩留基準値'
    }, {
      name: 'BMS No.'
    }];

    const breedingValues = [];
    if (evaluations) {
      breedingValues.push(evaluations.carcassWeightSbv);
      breedingValues.push(evaluations.ribEyeAreaSbv);
      breedingValues.push(evaluations.ribsThicknessSbv);
      breedingValues.push(evaluations.subcutaneousFatThicknessSbv * -1);
      breedingValues.push(evaluations.yieldStandardValueSbv);
      breedingValues.push(evaluations.bmsSbv);
    }

    const dataset = [
      breedingValues
    ];

    const max = 4;
    const min = -4;

    const parentElement = d3.select(`#${this.parentElementId}`);

    parentElement.html('');
    const svg = parentElement
      .append('svg')
      .attr('width', width)
      .attr('height', height);

    const chartScale = d3.scale.linear()
      .domain([min, max])
      .range([0, chartWidth / 2]);

    const labelScale = d3.scale.linear()
      .domain([min, max])
      .range([0, labelWidth / 2]);

    let grid = [];

    for (let i = min; i <= max; i++) {
      if (i % 2 === 0) {
        grid.push(new Array(traitCount).fill(i));
      }
    }

    // 目盛のラベル
    const scale = grid.map((g) => {
      if (g[0] === 0) return g[0];

      return (g[0]).toFixed(1);
    });

    // 目盛線
    const line = d3.svg.line()
      .x((d, i) => {
        return chartScale(d) * Math.cos(2 * Math.PI / traitCount * i - (Math.PI / 2)) + width / 2;
      })
      .y((d, i) => {
        return chartScale(d) * Math.sin(2 * Math.PI / traitCount * i - (Math.PI / 2)) + width / 2;
      })
      .interpolate('linear');

    // グラデーション
    const radialGradient = svg.append('defs')
      .append('radialGradient')
      .attr('id', 'radial-gradient');

    radialGradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', 'rgba(255, 255, 255, 0.24)');

    radialGradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', 'rgba(27, 163, 214, 0.24)');

    // 目盛のグリッド
    svg.selectAll('grid')
      .data(grid)
      .enter()
      .append('path')
      .attr('d', (d) => {
        return `${line(d)}z`;
      })
      .attr('stroke', '#ddd')
      .attr('stroke-width', 1.6)
      .attr('fill', 'none');

    svg.selectAll('grid.line')
      .data(new Array(traitCount).fill(max))
      .enter()
      .append('line')
      .attr('x1', width / 2)
      .attr('y1', height / 2)
      .attr('x2', (d, i) => {
        return chartScale(d) * Math.cos(2 * Math.PI / traitCount * i - (Math.PI / 2)) + width / 2;
      })
      .attr('y2', (d, i) => {
        return chartScale(d) * Math.sin(2 * Math.PI / traitCount * i - (Math.PI / 2)) + width / 2;
      })
      .attr('stroke', '#ddd')
      .attr('stroke-width', 1.6)
      .attr('fill', 'none');

    svg.selectAll('grid.zero')
      .data(grid.filter((g) => g[0] === 0))
      .enter()
      .append('path')
      .attr('d', (d) => {
        return `${line(d)}z`;
      })
      .attr('stroke', '#909090')
      .attr('stroke-width', 1.6)
      .attr('fill', 'none');

    // 目盛のラベル
    svg.selectAll('grid.scale')
      .data(scale)
      .enter()
      .append('text')
      .text((d) => {
        return d;
      })
      .attr('text-anchor', 'end')
      .attr('dominant-baseline', 'middle')
      .attr('x', (width / 2) - 5)
      .attr('y', (d, i) => {
        const center = height / 2;
        const unit = (chartWidth / 2) / (scale.length - 1);
        return center - (unit * i) + 1;
      })
      .attr('fill', 'rgba(33, 33, 33, 0.47)')
      .attr('font-size', '13px');

    // 形質のラベル
    svg.selectAll('label')
      .data(label)
      .enter()
      .append('text')
      .text((d) => {
        return d.name;
      })
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .attr('x', (d, i) => {
        const adjust = d.adjust ? (d.adjust.x || 0) : 0;
        return (labelScale(max + 1) * Math.cos(2 * Math.PI / traitCount * i - (Math.PI / 2)) + width / 2) + adjust;
      })
      .attr('y', (d, i) => {
        const adjust = d.adjust ? (d.adjust.y || 0) : 0;
        return (labelScale(max + 1) * Math.sin(2 * Math.PI / traitCount * i - (Math.PI / 2)) + width / 2) + adjust;
      })
      .attr('fill', 'rgba(33, 33, 33, 0.62)')
      .attr('font-size', '14px')
      .attr('font-weight', '700');

    if (evaluations) {
      // チャート
      svg.selectAll('data')
        .data(dataset)
        .enter()
        .append('path')
        .attr('d', (d) => {
          return `${line(d)}z`;
        })
        .attr('stroke', 'rgba(22, 103, 162, 0.4)')
        .attr('stroke-width', 2)
        .attr('fill', 'url(#radial-gradient)');

      // 点
      const circle = dataset.map((data) => {
        return data.map((d, i) => {
          return [
            chartScale(d) * Math.cos(2 * Math.PI / traitCount * i - (Math.PI / 2)) + width / 2,
            chartScale(d) * Math.sin(2 * Math.PI / traitCount * i - (Math.PI / 2)) + width / 2
          ];
        });
      });

      // 点
      circle.forEach((c, i) => {
        svg.selectAll(`c${i}`)
          .data(c)
          .enter()
          .append('circle')
          .attr('r', 3.5)
          .attr('fill', '#316c98')
          .attr('cx', (d) => {
            return d[0];
          })
          .attr('cy', (d) => {
            return d[1];
          });
      });
    }
  }
}

app.controller('BeefCattleSbvRadarChartController', BeefCattleSbvRadarChartController);

app.component('beefCattleSbvRadarChart', {
  template: '',
  controller: 'BeefCattleSbvRadarChartController',
  bindings: {
    'parentElementId': '@',
    'evaluations': '<',
    'chartSize': '<',
  }
});
