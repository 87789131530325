class APIService {
  constructor(
    $http,
    $q,
    $state,
    SessionStateService,
    DateUtilService) {
    'ngInject';
    this.$http = $http;
    this.$q = $q;
    this.$state = $state;
    this.SessionStateService = SessionStateService;
    this.DateUtilService = DateUtilService;
    this.forceRequest = true;

    this.cache = {
      token: null,
      timeReceived: null
    };
  }

  request(path, params, httpMethod, upload = false) {
    return this.refreshToken().then((res) => {
      const auth = 'JWT ' + this.SessionStateService.sessionToken();
      const url = environment.apiUrl + path;
      const req = {
        method: httpMethod,
        url: url,
        headers: {'Authorization': auth}
      };
      if (['POST', 'PUT', 'DELETE'].some((method) => method === httpMethod)) {
        req.data = params;
      }
      if (httpMethod === 'DELETE') {
        req.headers = {'Authorization': auth, 'Access-Control-Allow-Methods': 'GET, POST, DELETE'};
      }
      if (upload) {
        req.headers['Content-type'] = undefined;
      }

      return this.httpRequest(req);
    }).catch((err) => {
      if (err.status === 401) {
        this.SessionStateService.logout();
        this.$state.go('login');
      }
      throw err;
    });
  }

  refreshToken() {
    if (this.DateUtilService.diffSeconds(new Date(), this.SessionStateService.expiredAt()) < 60) {
      return this.SessionStateService.refreshRequest();
    } else {
      const d = this.$q.defer();
      d.resolve({});
      return d.promise;
    }
  }

  httpRequest(req, retryCount = 0) {
    return this.$http(req).catch((err) => {
      if (err.data.errorCode === 'UNAUTHORIZED') {
        if (retryCount < 2) {
          return this.SessionStateService.refreshRequest().then((res) => {
            req.headers.Authorization = `JWT ${this.SessionStateService.sessionToken()}`;
            return this.httpRequest(req, retryCount + 1);
          });
        } else {
          this.SessionStateService.logout();
          this.$state.go('login');
        }
      }
      throw err;
    });
  }

  get(path) {
    return this.request(path, {}, 'GET');
  }

  post(path, params) {
    return this.request(path, params, 'POST');
  }

  put(path, params) {
    return this.request(path, params, 'PUT');
  }

  delete(path, params) {
    return this.request(path, params, 'DELETE');
  }

  upload(path, params) {
    return this.request(path, params, 'POST', true);
  }
}

app.service('APIService', APIService);
