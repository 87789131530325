// 活動量積み立て棒グラフ
class ActivityStackedBarChart {
  static defaultOptions(isMobile) {
    return JSON.parse(JSON.stringify({
      padding: {
        left: isMobile ? 30 : 60,
        right: isMobile ? 0 : 20,
        bottom: isMobile ? 0 : 10
      },
      size: {},
      data: {
        x: 'x',
        json: {},
        type: 'bar',
        groups: [
          ['feedMin', 'moveMin', 'ruminationStandingMin', 'ruminationLyingMin', 'standMin', 'lieMin']
        ],
        colors: {
          feedMin: '#074f07',
          moveMin: '#31a062',
          ruminationStandingMin: '#ff8c00',
          ruminationLyingMin: '#ffd700',
          standMin: '#dcdcdc',
          lieMin: '#a9a9a9'
        },
        names: {
          feedMin: '採食',
          moveMin: '動態',
          ruminationStandingMin: '起立(反芻)',
          ruminationLyingMin: '横臥(反芻)',
          standMin: '起立(非活動)',
          lieMin: '横臥(非活動)'
        },
        order: null
      },
      axis: {
        y: {
          max: 1440,
          min: 0,
          tick: {},
          label: {
            text: '時間(分)',
            position: (isMobile ? 'inner-left' : 'outer-right')
          },
          padding: {
            top: 0,
            bottom: 0
          }
        },
        x: {
          type: 'timeseries',
          tick: {
            fit: true,
            format: '%m/%d %H:%M'
          }
        }
      },
      grid: {
        y: {
          show: true
        }
      },
      subchart: {
        show: false
      },
      point: {
        show: false
      },
      tooltip: {
        format: {}
      },
    }));
  }

  constructor(selector, isMobile = false, options) {
    this.selector = selector;
    this.isMobile = isMobile;
    this.options = options || ActivityStackedBarChart.defaultOptions();
    this.options.bindto = selector;
  }

  show(jsonData, kind) {
    this.destroy();

    this.options.data.json = jsonData;
    this._xTickFormat(kind);
    this.xAxis(kind);
    this._yAxis(kind);
    this.tooltipFormatTitle(kind);
    this._tooltipFormatValue(kind);
    this.chart = c3.generate(this.options);
  }

  _xTickFormat(kind) {
    if (kind === ActivityStackedBarChart.KIND.DATE) {
      this.options.axis.x.tick.format = '%m/%d';
      if (this.options.axis.x.tick.culling) {
        delete this.options.axis.x.tick.culling.max;
      }
      if (this.isMobile) {
        if (!this.options.axis.x.tick.culling) this.options.axis.x.tick.culling = {};
        this.options.axis.x.tick.culling.max = 7;
      }
    } else if (kind === ActivityStackedBarChart.KIND.DATE_TIME) {
      this.options.axis.x.tick.format = '%m/%d %H:%M';
      if (this.options.axis.x.tick.culling) {
        delete this.options.axis.x.tick.culling.max;
      }
      if (this.chart) {
        if (!this.isMobile && this.chart.element && this.chart.element.clientWidth < 720) {
          this.options.axis.x.tick.culling = {
            max: 7
          };
        }
      }
    } else if (kind === ActivityStackedBarChart.KIND.DATE_TIME_SIMPLE) {
      this.options.axis.x.tick = {
        format: (d) => {
          return d.getHours() === 0
            ? DateUtil.toMMDD(d)
            : '';
        },
        culling: {
          // 1日8データ x 7日分
          max: 56
        }
      };
    }
  }

  xAxis(kind) {
    if (this.isMobile) {
      if (kind === ActivityStackedBarChart.KIND.DATE_TIME_SIMPLE) {
        this.options.axis.x.padding = {
          left: 1000 * 60 * 60 * 24 / 2
        };
      } else {
        this.options.axis.x.padding = {
          left: 1000 * 60 * 60 * 24 * 2
        };
      }

    } else if (kind === ActivityStackedBarChart.KIND.DATE) {
      this.options.axis.x.padding = {
        left: ActivityRateChart.PADDING_035DAY,
        right: ActivityRateChart.PADDING_035DAY
      };
    } else {
      this.options.axis.x.padding = {};
    }
  }

  _yAxis(kind) {
    if (kind === ActivityStackedBarChart.KIND.DATE_TIME || kind === ActivityStackedBarChart.KIND.DATE_TIME_SIMPLE) {
      this.options.axis.y.max = 180;
      this.options.axis.y.tick = {};
    } else {
      this.options.axis.y.max = 1440;
      this.options.axis.y.tick = {
        values: [
          0, 200, 400, 600, 800, 1000, 1200, 1440
        ]
      };
    }
  }

  tooltipFormatTitle(kind) {
    if ([
      ActivityStackedBarChart.KIND.DATE_TIME_SIMPLE,
      ActivityStackedBarChart.KIND.DATE_TIME
    ].includes(kind)
    ) {
      this.options.tooltip.format.title = (d) => {
        return DateUtil.toMMDDHHmm(d);
      };
    } else {
      this.options.tooltip.format.title = (d) => {
        return DateUtil.toMMDD(d);
      };
    }
  }

  _tooltipFormatValue(kind) {
    if (kind === ActivityStackedBarChart.KIND.DATE_TIME || kind === ActivityStackedBarChart.KIND.DATE_TIME_SIMPLE) {
      this.options.tooltip.format.value = (value, ratio, id, index) => {
        if (value) {
          return value;
        } else {
          return undefined;
        }
      };
    } else {
      this.options.tooltip.format.value = null;
    }
  }

  update(jsonData) {
    if (!this.chart) return;
    this.chart.load({
      json: jsonData
    });
  }

  destroy() {
    if (!this.chart) return;
    this.chart.destroy();
  }

  clear() {
    $(this.selector).html('');
  }

  flush() {
    this.chart && this.chart.flush();
  }

  toggle(ids) {
    this.chart.toggle(ids);
  }

  onClickLegend(onChangeHiddenLegendIds) {
    d3.selectAll('.c3-legend-item').on('click', (id) => {
      this.chart.toggle([id]);

      onChangeHiddenLegendIds({
        ids: this.chart.internal.hiddenTargetIds
      })
    });
  }
}
// 定数
ActivityStackedBarChart.KIND = {
  DATE: 0,
  DATE_TIME: 1,
  DATE_TIME_SIMPLE: 2
};
