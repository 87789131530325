class HomeNotificationController {
  constructor(
    $modal,
    SessionCache,
    NotificationAPI
  ) {
    'ngInject';

    this.$modal = $modal;
    this.NotificationAPI = NotificationAPI;
    this.homeSettings = new HomeSettings(SessionCache);

    this.init();
  }

  init() {
    this.index();
  }

  index() {
    this.NotificationAPI.index().then((res) => {
      const data = Notification.decorate(Notification.sort(Notification.filter(res.data, true)));
      const incidents = data.filter((item) => item.category.key === 'incident');
      const others = data.filter((item) => item.category.key !== 'incident');

      this.notifications = [...incidents, ...others];
    });
  }

  onClickHidden(id) {
    const hidden = this.homeSettings.get('notification').hidden;
    const newHidden = {
      hidden: [...hidden, ...[id]]
    };

    this.homeSettings.update('notification', newHidden);
  }

  showNotification(id) {
    return !this.homeSettings.get('notification').hidden.includes(id);
  }

  showHiddenButton(key) {
    return key !== 'incident';
  }

  classNameNoticeItem(key) {
    return `uNotice__item--${key}`;
  }

  classNameIcon(key) {
    return key === 'incident' ? 'icon-l-caution' : 'icon-m-notice';
  }

  openNotificationDetailModal(notification) {
    this.$modal.open({
      templateUrl: 'dialog/top-notification/top-notification-dialog.html',
      controller: 'TopNotificationDialogController',
      controllerAs: 'ctrl',
      windowClass: 'modal-center',
      animation: true,
      keyboard: false,
      resolve: {
        params: () => {
          return {
            notification
          };
        }
      }
    });
  }
}

function homeNotification() {
  return {
    templateUrl: 'menu/home/notification/index.html',
    controller: HomeNotificationController,
    controllerAs: 'ctrl',
    bindings: {
      notifications: '='
    }
  };
}

app.component('homeNotification', homeNotification());
