class SettingSelectListController {
  constructor(
    $q,
    $stateParams,
    FarmService,
    OperationUserService,
    UtilService,
    appConst
  ) {
    'ngInject';

    this.$q = $q;
    this.FarmService = FarmService;
    this.OperationUserService = OperationUserService;
    this.UtilService = UtilService;
    this.appConst = appConst;

    this.selectedItemText = '';
    this.selectedItem = $stateParams.selectedItem || '';

    this.listNameMap = {};
    this.selectItemList = [];
  }

  init(requeted) {
    if (requeted) {
      this.listNameMap = {};
      this.selectItemList = [];
      const previousSelectedItem = this.selectedItem;

      this.$q.all([
        this.FarmService.show(),
        this.OperationUserService.index()
      ]).then((result) => {
        this.setSelections(result[0]);
        this.setOperators(result[1].data);

        this.selectedItem = previousSelectedItem || this.selectItemList[0].value;

        const selectedItemText = this.listNameMap[this.selectedItem].text || '';
        this.selectedItemText = selectedItemText;
        this.switchReadOnly();
      });
    } else {
      this.switchReadOnly();
    }

    this.errorMessage = null;
  }

  changeItem() {
    if (this.listNameMap[this.selectedItem]) {
      this.selectedItemText = this.listNameMap[this.selectedItem].text || '';
      this.init(false);
    }
  }

  cancel() {
    this.changeItem();
  }

  update() {
    this.switchReadOnly();

    const infoName = this.listNameMap[this.selectedItem].infoName;
    if (infoName === 'selections') {
      const params = {};
      params[this.selectedItem] = this.trimAndConcat(
        this.selectedItemText,
        this.listNameMap[this.selectedItem]
      );
      this.FarmService.update(params).then(() => {
        this.init(true);
      });
    } else if (infoName === 'operators') {
      const operators = this.trimAndDivide(this.selectedItemText);
      this.OperationUserService.update(operators).then(() => {
        this.init(true);
      });
    }
  }

  setSelections(selections) {
    const selectionsDefinitions = this.appConst.settingListNameMap['AccountInfo'];

    selectionsDefinitions.forEach((selection) => {
      const itemName = selection.name;
      this.listNameMap[itemName] = {
        text: selections[itemName],
        infoName: 'selections',
        default: ''
      };
      this.selectItemList.push({label: selection.label, value: itemName});
    });
    this.selections = selections;
  }

  setOperators(operators) {
    const concatedOperatorNames = operators.map((operator) => operator.name).join('\n');

    this.listNameMap['operators'] = {
      text: concatedOperatorNames,
      infoName: 'operators',
      default: ''
    };
    this.selectItemList.push({label: 'ユーザ名', value: 'operators'});
    this.operators = operators;
  }

  edit() {
    $('#editBtn').css('display', 'none');
    $('#updateBtn').css('display', 'block');
    $('#cancelBtn').css('display', 'block');
    this.selectedItemTextDisabled = false;
  }

  switchReadOnly() {
    $('#editBtn').css('display', 'block');
    $('#updateBtn').css('display', 'none');
    $('#cancelBtn').css('display', 'none');
    this.selectedItemTextDisabled = true;
  }

  trimAndDivide(text) {
    const lines = text ? text.split(/\r\n?|\n/) : [];
    return lines.map((line) => line.trim()).filter((line) => line.length > 0);
  }

  trimAndConcat(text, listNameObj) {
    const actualLines = this.trimAndDivide(text);
    return actualLines.join('\n') || listNameObj.default;
  }
}

app.controller('settingSelectListController', SettingSelectListController);
