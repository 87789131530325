class AlertConfigController {
  constructor(
    $modal,
    $q,
    AlertConfigService,
    AlertHeatDetectionConfigAPI,
    AlertCalvingDetectionThresholdAPI,
    CowshedService,
    CowGroupService,
    FarmService
  ) {
    'ngInject';
    this.$modal = $modal;
    this.$q = $q;
    this.AlertConfigService = AlertConfigService;
    this.AlertHeatDetectionConfigAPI = AlertHeatDetectionConfigAPI;
    this.AlertCalvingDetectionThresholdAPI = AlertCalvingDetectionThresholdAPI;
    this.FarmService = FarmService;
    this.CowshedService = CowshedService;
    this.CowGroupService = CowGroupService;

    this.alertTypes = [
      'heat',
      'illness',
      'chronic-illness',
      'milkVol',
      'activityVol',
      'calving'
    ];

    this.alertTemplateNames = {
      heat: 'heat',
      illness: 'illness',
      chronicIllness: 'chronic-illness',
      milkVol: 'milk-vol',
      activityVol: 'activity-vol',
      calving: 'calving'
    };

    this.init();
  }

  init() {
    this.isEditing = false;
    this.states = {};
    this.cowGroups = {};
    this.config = {};

    // データ取得
    this.$q.all([
      this.FarmService.show(),
      this.CowGroupService.index(),
      this.AlertConfigService.show(),
      this.AlertHeatDetectionConfigAPI.show(),
      this.CowshedService.index(),
      this.AlertCalvingDetectionThresholdAPI.show()
    ]).then((res) => {
      const farm = new Farm(res[0]);
      this.farmType = farm.farmType();
      this.typeMilk = this.farmType === Farm.farmTypeMilk;
      this.typeBeef = this.farmType === Farm.farmTypeBeef;
      this.shouldShowCalving = farm.useAlertCalving();
      this.isDepositor = farm.isDepositor();

      State.values(this.farmType).forEach((v) => {
        this.states[v.label] = v.id;
      });

      // 牛群名マップ作成
      res[1].data.forEach((group) => {
        this.cowGroups[group.cowGroupId] = group.cowGroupName;
      });

      // アラート設定と発情アラート設定をマージ
      this.config = Object.assign({}, res[2].data, res[3].data);

      // 疾病アラート
      if (this.config.illnessCowGroupIds) {
        const cowGroupIds = this.config.illnessCowGroupIds.split(',').map((s) => Number(s));
        this.config['illnessCowGroupConditionLabel'] = cowGroupIds.map((cowGroupId) => this.cowGroups[cowGroupId]).join('、');
      } else {
        this.config['illnessCowGroupConditionLabel'] = '(全ての牛群が対象になります)';
      }

      if (this.config.illnessCowStates) {
        const states = this.config.illnessCowStates.split(',');
        this.config['illnessCowStateIds'] = states.map((state) => this.states[state]).join(',');
        this.config['illnessStateConditionLabel'] = states.map((state) => state).join('、');
      } else {
        this.config['illnessStateConditionLabel'] = '(全ての状態が対象になります)';
      }

      this.initializeChronicIllnessSettingParams();

      this.initializeHeatSettingParams(res[4].data);

      this.config.calving = res[5].data;

    }).catch((err) => console.error(err));
  }

  edit(alertType) {
    const config = angular.copy(this.config);

    this.$modal.open({
      templateUrl: 'menu/setting/alert/edit.html',
      controller: 'AlertConfigEditController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        params: () => ({
          config: config,
          templateUrl: 'menu/setting/alert/' + this.alertTemplateNames[alertType] + '.html',
          farmType: this.farmType,
          alertType: alertType,
          isDepositor: this.isDepositor
        })
      }
    }).result.then(() => this.init());
  }

  initializeChronicIllnessSettingParams() {
    this.config.chronicIllnessCowGroupConditionLabel = '(全ての牛群が対象になります)';
    this.config.chronicIllnessStateConditionLabel = '(全ての状態が対象になります)';

    if (this.config.chronicIllnessCowGroupIds) {
      const cowGroupIds = this.config.chronicIllnessCowGroupIds.split(',').map((s) => Number(s));
      this.config.chronicIllnessCowGroupConditionLabel = cowGroupIds.map((cowGroupId) => this.cowGroups[cowGroupId]).join('、');
    }

    if (this.config.chronicIllnessCowStates) {
      const states = this.config.chronicIllnessCowStates.split(',');
      this.config.chronicIllnessCowStateIds = states.map((state) => this.states[state]).join(',');
      this.config.chronicIllnessStateConditionLabel = states.map((state) => state).join('、');
    }
  }

  /**
   * 発情アラート設定の項目初期化
   */
  initializeHeatSettingParams(cowsheds) {
    // HeatDetectionConfig
    if (this.config.heatAlertCowGroupIds && this.config.heatAlertCowGroupIds.length) {
      this.config.cowGroupConditionLabel = this.config.heatAlertCowGroupIds.map((cowGroupId) => this.cowGroups[cowGroupId]).join('、');
      // 他の部分と合わすためにカンマ区切りの文字列に戻しています
      this.config.heatAlertCowGroupIds = this.config.heatAlertCowGroupIds.join(',');
    } else {
      this.config.cowGroupConditionLabel = '(全ての牛群が対象になります)';
      this.config.heatAlertCowGroupIds = '';
    }

    if (this.config.heatAlertCowStates && this.config.heatAlertCowStates.length) {
      this.config.heatAlertCowStateIds = this.config.heatAlertCowStates.map((state) => this.states[state]).join(',');
      this.config.stateConditionLabel = this.config.heatAlertCowStates.map((state) => state).join('、');
      // 他の部分と合わすためにカンマ区切りの文字列に戻しています
      this.config.heatAlertCowStates = this.config.heatAlertCowStates.join(',');
    } else {
      this.config.stateConditionLabel = '(全ての状態が対象になります)';
      this.config.heatAlertCowStates = '';
    }

    this.config.includeVwpHeiferCaption = this.config.includeVwpHeifer ? '対象' : '対象外';
    this.config.includeVwpParousCaption = this.config.includeVwpParous ? '対象' : '対象外';

    // HeatDetectionDetail
    const groupedCowshed = cowsheds.reduce((acc, cowshed) => {
      const heatIndexCalculationLogic = cowshed.heatIndexCalculationLogic;
      if (!acc[heatIndexCalculationLogic]) acc[heatIndexCalculationLogic] = [];
      acc[heatIndexCalculationLogic].push(cowshed.cowshedName);
      return acc;
    }, {});

    const detailsLen = this.config.heatDetectionDetailsAttributes.length;
    this.config.heatDetectionDetailsAttributes.forEach((heatDetectionDetail) => {
      const heatIndexCalculationLogic = heatDetectionDetail.heatIndexCalculationLogic;
      // 牧場の発情指数算出ロジックが複数ある場合だけ牛舎名などの情報を表示する
      if (detailsLen > 1) {
        heatDetectionDetail.heatIndexCalcLogicLabel =
          AlertHeatDetectionConfig.HEAT_INDEX_CALC_LOGIC_LABELS[heatIndexCalculationLogic];
        heatDetectionDetail.cowshedNames = (groupedCowshed[heatIndexCalculationLogic] || []).join('、');
      }
      // SLICE3Hしか持続時間は対応していないのでタイストールの場合は表示しない
      if (AlertHeatDetectionConfig.isSlice3h(heatIndexCalculationLogic)) heatDetectionDetail.showHeatDuration = true;
    });

    this.config.heatDetectionEventAttributes = AlertHeatDetectionConfig
      .initializeHeatDetectionEvents(this.config.heatDetectionEventAttributes);
  }
}

app.controller('AlertConfigController', AlertConfigController);
