class DepositAlertConfigAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/deposit/alert/config');
  }

  show(qwert) {
    return this.APIService.get(`/deposit/alert/config/${qwert}`);
  }
}

app.service('DepositAlertConfigAPI', DepositAlertConfigAPI);
