// eslint-disable-next-line no-unused-vars
class CollectionUtil {

  /**
   * 第1引数の配列に第2引数の配列を破壊的に結合します
   * @param {Array.<Object>} a
   * @param {Array.<Object>} b
   * @return {Array.<Object>} 使い勝手が良いように結合した結果の配列を返します
  */
  static mergeList(a, b) {
    if (!a || !b) return a;

    b.forEach((e) => a.push(e));
    return a;
  }

  /**
   * 重複を排除した配列を返す
   * @param {Array.<Object>} list
   * @return {Array.<Object>}
  */
  static uniqueList(list) {
    if (!list) return [];

    return list.filter((x, i, self) => {
      return self.indexOf(x) === i;
    });
  }

  /**
   * 選択肢として有効なマスタ設定を抽出して返します。
   * 選択済の設定が存在する場合はそれも含まれます。
   * @param {Array.<Object>} masters マスタ設定の配列
   * @param {Array.<number|string>} selectedIds 選択済のマスタのID配列 (ex. [5, 11, 13])
   * @param {string} keyColumn selectedIdsと照合するカラム名(デフォルトはid)
   * @param {string} displayFlgColumn 有効/無効の設定を保持するカラム名(デフォルトはvisible)
   * @return {Array.<Object>}
  */
  static selectableMasters(masters, selectedIds = [], keyColumn = 'id', displayFlgColumn = 'visible') {
    return masters.filter((m) => {
      return m[displayFlgColumn] || selectedIds.includes(m[keyColumn]);
    });
  }

  /**
   * 配列の合計値を返す
   *
   * @param {Array.<number>} values
   * @return {number}
   */
  static sum(values) {
    return values.reduce((prev, cur) => {
      return prev + cur;
    }, 0);
  }

  /**
   * 配列の平均値を返す
   *
   * @param {Array.<number>} values
   * @return {number}
   */
  static avg(values) {
    if (!values) return null;
    return CollectionUtil.sum(values) / values.length;
  }

  /**
   * オブジェクトの配列内の指定した要素のインデックスを返します。
   *
   * @param {Array.<Object>} items オブジェクトの配列
   * @param {number|string|etc.} selectedItemKey 選択されている要素のキー値
   * @param {string} keyField 配列の要素のキー値を格納するフィールド
   * @return {number} 配列内でのインデックスの値
  */
  static indexOfSelected(items, selectedItemKey, keyField) {
    return items.findIndex((item) => {
      return item[keyField] === selectedItemKey;
    });
  }

  /**
   * 配列内の指定した要素(複数)の位置を上にずらします。
   *
   * @param {Array.<Object>} items オブジェクトの配列
   * @param {Array.<number|string|etc.>|number|string|etc.} argSelectedItemKeys 選択されている要素のキー値(配列)
   * @param {string} keyField 配列の要素のキー値を格納するフィールド
   * @return {Array.<Object>} 位置を変更した新規の配列
  */
  static shiftToUp(items, argSelectedItemKeys, keyField) {
    let selectedItemKeys = argSelectedItemKeys;
    if (!Array.isArray(argSelectedItemKeys)) {
      selectedItemKeys = [argSelectedItemKeys];
    }

    const selectedIndex = CollectionUtil.indexOfSelected(items, selectedItemKeys[0], keyField);
    if (selectedIndex <= 0) {
      return items;
    }

    return this.swapItemsUp(items, selectedIndex, selectedItemKeys.length);
  }

  /**
   * 配列内の指定した要素(複数)の位置を上にずらした配列を生成して返します。
   * ※内部処理用の関数
   *
   * @param {Array.<Object>} items オブジェクトの配列
   * @param {number} selectedIndex 指定要素の配列内のインデックス(複数ある場合は先頭の要素)
   * @param {number} slectedSize 指定要素の数
   * @return {Array.<Object>} 位置を変更した新規の配列
  */
  static swapItemsUp(items, selectedIndex, slectedSize) {
    const result = [];
    const swappedIndex = selectedIndex - 1;
    const slectedLastIndex = selectedIndex + (slectedSize - 1);

    for (let i = 0; i < swappedIndex; i++) {
      result.push(items[i]);
    }

    for (let i = selectedIndex; i <= slectedLastIndex; i++) {
      result.push(items[i]);
    }

    result.push(items[swappedIndex]);

    for (let i = slectedLastIndex + 1; i < items.length; i++) {
      result.push(items[i]);
    }

    return result;
  }

  /**
   * 配列内の指定した要素(複数)の位置を先頭にずらします。
   *
   * @param {Array.<Object>} items オブジェクトの配列
   * @param {Array.<number|string|etc.>|number|string|etc.} argSelectedItemKeys 選択されている要素のキー値(配列)
   * @param {string} keyField 配列の要素のキー値を格納するフィールド
   * @return {Array.<Object>} 位置を変更した新規の配列
  */
  static shiftToFirst(items, argSelectedItemKeys, keyField) {
    let selectedItemKeys = argSelectedItemKeys;
    if (!Array.isArray(argSelectedItemKeys)) {
      selectedItemKeys = [argSelectedItemKeys];
    }

    const selectedIndex = CollectionUtil.indexOfSelected(items, selectedItemKeys[0], keyField);
    if (selectedIndex <= 0) {
      return items;
    }

    return this.swapItemsFirst(items, selectedIndex, selectedItemKeys.length);
  }

  /**
   * 配列内の指定した要素(複数)の位置を先頭にずらした配列を生成して返します。
   * ※内部処理用の関数
   *
   * @param {Array.<Object>} items オブジェクトの配列
   * @param {number} selectedIndex 指定要素の配列内のインデックス(複数ある場合は先頭の要素)
   * @param {number} slectedSize 指定要素の数
   * @return {Array.<Object>} 位置を変更した新規の配列
  */
  static swapItemsFirst(items, selectedIndex, slectedSize) {
    const result = [];
    const swappedIndex = selectedIndex - 1;
    const slectedLastIndex = selectedIndex + (slectedSize - 1);

    for (let i = selectedIndex; i <= slectedLastIndex; i++) {
      result.push(items[i]);
    }

    for (let i = 0; i <= swappedIndex; i++) {
      result.push(items[i]);
    }

    for (let i = slectedLastIndex + 1; i < items.length; i++) {
      result.push(items[i]);
    }

    return result;
  }

  /**
   * 配列内の指定した要素(複数)の位置を下にずらします。
   *
   * @param {Array.<Object>} items オブジェクトの配列
   * @param {Array.<number|string|etc.>|number|string|etc.} argSelectedItemKeys 選択されている要素のキー値(配列)
   * @param {string} keyField 配列の要素のキー値を格納するフィールド
   * @return {Array.<Object>} 位置を変更した新規の配列
  */
  static shiftToDown(items, argSelectedItemKeys, keyField) {
    let selectedItemKeys = argSelectedItemKeys;
    if (!Array.isArray(argSelectedItemKeys)) {
      selectedItemKeys = [argSelectedItemKeys];
    }

    const selectedIndex = CollectionUtil.indexOfSelected(items, selectedItemKeys[0], keyField);
    if (selectedIndex < 0) {
      return items;
    }

    return this.swapItemsDown(items, selectedIndex, selectedItemKeys.length);
  }

  /**
   * 配列内の指定した要素(複数)の位置を下にずらした配列を生成して返します。
   * ※内部処理用の関数
   *
   * @param {Array.<Object>} items オブジェクトの配列
   * @param {number} selectedIndex 指定要素の配列内のインデックス(複数ある場合は先頭の要素)
   * @param {number} slectedSize 指定要素の数
   * @return {Array.<Object>} 位置を変更した新規の配列
  */
  static swapItemsDown(items, selectedIndex, slectedSize) {
    const result = [];
    const slectedLastIndex = selectedIndex + (slectedSize - 1);
    const swappedIndex = slectedLastIndex + 1;
    if (swappedIndex >= items.length) {
      return items;
    }

    for (let i = 0; i < selectedIndex; i++) {
      result.push(items[i]);
    }

    result.push(items[swappedIndex]);

    for (let i = selectedIndex; i <= slectedLastIndex; i++) {
      result.push(items[i]);
    }

    for (let i = swappedIndex + 1; i < items.length; i++) {
      result.push(items[i]);
    }

    return result;
  }

  /**
   * 配列内の指定した要素(複数)の位置を最後方にずらします。
   *
   * @param {Array.<Object>} items オブジェクトの配列
   * @param {Array.<number|string|etc.>|number|string|etc.} argSelectedItemKeys 選択されている要素のキー値(配列)
   * @param {string} keyField 配列の要素のキー値を格納するフィールド
   * @return {Array.<Object>} 位置を変更した新規の配列
  */
  static shiftToLast(items, argSelectedItemKeys, keyField) {
    let selectedItemKeys = argSelectedItemKeys;
    if (!Array.isArray(argSelectedItemKeys)) {
      selectedItemKeys = [argSelectedItemKeys];
    }

    const selectedIndex = CollectionUtil.indexOfSelected(items, selectedItemKeys[0], keyField);
    if (selectedIndex < 0) {
      return items;
    }

    return this.swapItemsLast(items, selectedIndex, selectedItemKeys.length);
  }

  /**
   * 配列内の指定した要素(複数)の位置を最後方にずらした配列を生成して返します。
   * ※内部処理用の関数
   *
   * @param {Array.<Object>} items オブジェクトの配列
   * @param {number} selectedIndex 指定要素の配列内のインデックス(複数ある場合は先頭の要素)
   * @param {number} slectedSize 指定要素の数
   * @return {Array.<Object>} 位置を変更した新規の配列
  */
  static swapItemsLast(items, selectedIndex, slectedSize) {
    const result = [];
    const slectedLastIndex = selectedIndex + (slectedSize - 1);
    const swappedIndex = slectedLastIndex + 1;
    if (swappedIndex >= items.length) {
      return items;
    }

    for (let i = 0; i < selectedIndex; i++) {
      result.push(items[i]);
    }

    for (let i = swappedIndex; i < items.length; i++) {
      result.push(items[i]);
    }

    for (let i = selectedIndex; i <= slectedLastIndex; i++) {
      result.push(items[i]);
    }

    return result;
  }
}
