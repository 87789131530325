class CowSearchDialogController {
  constructor(
    $q,
    $modalInstance,
    params,
    Dictionary,
    SessionCache,
    SelectionService,
    MasterCowLabelAPI,
    DepoistAgentFarmService
  ) {
    'ngInject';

    const farm = SessionCache.farm();

    this.$q = $q;
    this.$modalInstance = $modalInstance;
    this.Dictionary = Dictionary;
    this.isDepositor = farm.isDepositor();

    this.init(params, farm, SelectionService, MasterCowLabelAPI, DepoistAgentFarmService);
  }

  init(params, farm, SelectionService, MasterCowLabelAPI, DepoistAgentFarmService) {
    const conditions = params.searchCondition.conditions || [];

    this.activeOnly = params.searchCondition.activeOnly;
    this.conditions = conditions.map((condition) => {
      if (condition.conditionType === 'SELECTION') {
        const item = Object.assign({}, condition);

        item.openSelectMultiple = false;
        item.selectMultiple = item.selections.map((selection) => {
          return {
            label: selection
          };
        });

        return item;
      }

      return condition;
    });

    this.selections = [];

    this.polyfill();
    this.definitionFarmOptions(DepoistAgentFarmService).then(() => {
      this.definitionColumns();
      this.definitionOperators();
      this.definitionDefaultFieldLabels();
      this.definitionSelections(SelectionService, MasterCowLabelAPI);
      this.generateColumnIdOptions();
      this.generateOperatorOptions();
      this.setConditions();
    });
  }

  polyfill() {
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
      Element.prototype.closest = function(s) {
        let el = this;

        do {
          if (Element.prototype.matches.call(el, s)) return el;
          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);

        return null;
      };
    }
  }

  definitionFarmOptions(DepoistAgentFarmService) {
    if (!this.isDepositor) {
      const deferred = this.$q.defer();
      deferred.resolve({});
      return deferred.promise;
    }

    return DepoistAgentFarmService.index().then((res) => {
      this.farmOptions = res.data.map((data) => {
        return {
          key: data.id,
          label: data.farmName
        };
      });
    });
  }

  definitionColumns() {
    this.columns = [{
      columnId: 'farm',
      label: '牧場',
      conditionType: 'MASTER',
      fieldType: 'masterId',
      depositor: true
    }, {
      columnId: 'cow_no',
      label: this.Dictionary.COW.COW_NO,
      conditionType: 'ID',
      fieldType: 'string'
    }, {
      columnId: 'cow_uid',
      label: '個体識別番号',
      conditionType: 'STRING',
      fieldType: 'string'
    }, {
      columnId: 'ble_id',
      label: 'センサータグ',
      conditionType: 'BLE_ID',
      fieldType: 'string'
    }, {
      columnId: 'state',
      label: '状態',
      conditionType: 'SELECTION',
      fieldType: 'selectMultiple'
    }, {
      columnId: 'cow_labels',
      label: '個体ラベル',
      conditionType: 'SELECTION',
      fieldType: 'selectMultiple'
    }, {
      columnId: 'birthday_months',
      label: '月齢',
      conditionType: 'NUMERIC',
      fieldType: 'number',
      fieldLabel: '月齢の範囲',
      append: 'ヶ月'
    }, {
      columnId: 'birthday_days',
      label: '日齢',
      conditionType: 'NUMERIC',
      fieldType: 'number',
      fieldLabel: '日齢の範囲',
      append: '日'
    }, {
      columnId: 'birthday',
      label: '出生日',
      conditionType: 'DATE',
      fieldType: 'date'
    }, {
      columnId: 'introduce_date',
      label: '導入日',
      conditionType: 'DATE',
      fieldType: 'date'
    }, {
      columnId: 'latest_treatment_date',
      label: '治療日',
      conditionType: 'DATE',
      fieldType: 'date'
    }, {
      columnId: 'eliminate_date',
      label: '出荷日',
      conditionType: 'DATE',
      fieldType: 'date'
    }, {
      columnId: 'falling_dead_date',
      label: 'へい死日',
      conditionType: 'DATE',
      fieldType: 'date'
    }, {
      columnId: 'latest_breeding_count',
      label: '授精回数',
      conditionType: 'NUMERIC',
      fieldType: 'number',
      fieldLabel: '授精回数の範囲',
      append: '回'
    }, {
      columnId: 'birth_number',
      label: '産次',
      conditionType: 'NUMERIC',
      fieldType: 'number',
      fieldLabel: '産次の範囲',
      append: '回'
    }, {
      columnId: 'latest_fertilization_date',
      label: '授精日',
      conditionType: 'DATE',
      fieldType: 'date'
    }, {
      columnId: 'latest_judge_pregnant_date',
      label: '妊娠鑑定日',
      conditionType: 'DATE',
      fieldType: 'date'
    }, {
      columnId: 'latest_calving_date',
      label: '分娩日',
      conditionType: 'DATE',
      fieldType: 'date'
    }];

    this.columns = this.columns.filter((column) => {
      return !column.depositor || (column.depositor && this.isDepositor);
    });
  }

  definitionOperators() {
    this.operators = [{
      key: 'equal',
      label: 'キーワードで完全一致',
      settingLabel: 'で完全一致',
      conditionTypes: ['MASTER', 'ID']
    }, {
      key: 'not_equal',
      label: 'キーワード以外',
      settingLabel: '以外',
      conditionTypes: ['ID']
    }, {
      key: 'include',
      label: 'キーワードを含む',
      settingLabel: 'を含む',
      conditionTypes: ['ID', 'STRING', 'SELECTION', 'BLE_ID']
    }, {
      key: 'exclude',
      label: 'キーワードを含まない',
      settingLabel: 'を含まない',
      conditionTypes: ['STRING', 'SELECTION', 'BLE_ID']
    }, {
      key: 'between',
      label: '指定範囲内',
      settingLabel: '',
      conditionTypes: ['NUMERIC']
    }, {
      key: 'between',
      label: '指定期間内',
      settingLabel: '',
      conditionTypes: ['DATE']
    }, {
      key: 'blank',
      label: '未入力の牛を検索',
      settingLabel: '未入力の牛を検索',
      conditionTypes: ['STRING', 'DATE']
    }, {
      key: 'blank',
      label: '未装着の牛を検索',
      settingLabel: '未装着の牛を検索',
      conditionTypes: ['BLE_ID']
    }];
  }

  definitionDefaultFieldLabels() {
    this.defaultFieldLabels = [{
      fieldType: 'masterId',
      label: '牧場名'
    }, {
      fieldType: 'string',
      label: 'キーワード'
    }, {
      fieldType: 'selectMultiple',
      label: 'キーワード',
    }, {
      fieldType: 'number',
      label: '範囲',
    }, {
      fieldType: 'date',
      label: '期間',
    }];
  }

  definitionSelections(SelectionService, MasterCowLabelAPI) {
    SelectionService.index().then((res) => {
      this.selections.state = res.state.map((item) => {
        return {
          label: item.label
        };
      });

      MasterCowLabelAPI.available().then((res) => {
        this.selections.cow_labels = res.data.filter((item) => {
          return item.visible;
        }).map((item) => {
          return {
            label: item.name
          };
        });

        this.generateSettingConditions();
      });
    });
  }

  generateColumnIdOptions() {
    this.columnIdOptions = this.columns.map((column) => {
      return {
        key: column.columnId,
        label: column.label,
        conditionType: column.conditionType
      };
    });

    if (this.conditions.length === 0) return;

    this.conditions.forEach((condition, index) => {
      this.columnIdOptions.find((columnIdOption) => {
        return columnIdOption.key === condition.columnId;
      }).used = index + 1;
    });
  }

  generateOperatorOptions() {
    this.operatorOptions = this.operators.reduce((acc, cur) => {
      cur.conditionTypes.forEach((conditionType) => {
        if (!acc[conditionType]) {
          acc[conditionType] = [];
        }

        acc[conditionType].push({
          key: cur.key,
          label: cur.label,
          settingLabel: cur.settingLabel
        });
      });

      return acc;
    }, {});
  }

  generateSettingConditions() {
    if (this.conditions.length === 0) {
      this.settingConditions = [];

      return;
    }

    this.settingConditions = this.conditions.reduce((acc, cur) => {
      const column = this.filterColumns(cur);
      const settingLabel = this.operatorOptions[cur.conditionType].find((operator) => {
        return operator.key === cur.operator;
      }).settingLabel;

      let condition;

      switch (column.fieldType) {
      case 'string':
        condition = cur.conditionString;
        break;
      case 'selectMultiple':
        condition = this.formatSelections(cur.selections);
        break;
      case 'number':
        condition = cur.conditionMinNumber && cur.conditionMaxNumber ?
          `${cur.conditionMinNumber}〜${cur.conditionMaxNumber}${column.append}` : null;
        break;
      case 'date':
        condition = cur.conditionMinDate || cur.conditionMaxDate ?
          `${DateUtil.toYYYYMMDD(cur.conditionMinDate)}〜${DateUtil.toYYYYMMDD(cur.conditionMaxDate)}` : null;
        break;
      }

      if (cur.operator === 'blank') {
        condition = settingLabel;
      } else if (condition) {
        condition = `${condition}${settingLabel}`;
      } else {
        condition = '未入力...';
      }

      acc.push({
        column: column.label,
        condition: condition
      });

      return acc;
    }, []);

    this.settingConditions.push({
      column: '死亡牛、出荷牛',
      condition: this.activeOnly ? '除外する' : '除外しない'
    });
  }

  filterColumns(condition) {
    if (!this.columns) return;

    return this.columns.find((column) => {
      return column.columnId === condition.columnId;
    });
  }

  onChangeColumnId(condition, index) {
    const column = this.filterColumns(condition);
    const conditionType = column.conditionType;

    this.clearConditionField(condition);

    condition.conditionType = conditionType;
    condition.operator = this.operatorOptions[conditionType][0].key;

    if (condition.columnId === 'farm') {
      condition.masterId = this.farmOptions[0].key;
    }

    this.columnIdOptions.find((column) => {
      return (column.used === index + 1) && column.key !== condition.columnId;
    }).used = null;

    this.columnIdOptions.find((column) => {
      return column.key === condition.columnId;
    }).used = index + 1;

    this.validate();
    this.generateSettingConditions();
  }

  onChangeOperator(condition) {
    if (condition.operator === 'blank') {
      this.clearConditionField(condition);
    }

    this.validate();
    this.generateSettingConditions();
  }

  onChangeString() {
    this.validate();
    this.generateSettingConditions();
  }

  onChangeSelectMultiple($select) {
    $select.searchInput[0].placeholder = $select.placeholder;

    this.conditions.forEach((condition, index) => {
      const fieldType = this.filterColumns(condition).fieldType;

      if (fieldType === 'selectMultiple') {
        const selectMultiple = condition.selectMultiple;

        condition.selections = [];

        if (selectMultiple) {
          selectMultiple.forEach((item) => {
            condition.selections.push(item.label);
          });
        }
      }
    });

    this.validate();
    this.generateSettingConditions();
  }

  onClickSelectMultipleItem(selectMultiple, event, condition, label) {
    if (!condition.selections || !condition.selections.includes(label)) return;

    event.stopPropagation();

    const index = condition.selections.findIndex((item) => {
      return item === label;
    });

    selectMultiple.removeChoice(index);

    condition.selections = condition.selections.filter((item) => {
      return item !== label;
    });
  }

  onChangeDate() {
    this.validate();
    this.generateSettingConditions();
  }

  onChangeNumber(condition, fieldKey) {
    const value = StringUtil.toHalfWidthCharacters(condition[fieldKey]);

    condition[fieldKey] = StringUtil.removeNonSingleByteNumericCharacters(value);

    this.validate();
    this.generateSettingConditions();
  }

  onKeydownNumber(e) {
    if (KeyInputUtil.isNumberKey(e)) return;

    e.preventDefault();
  }

  onChangeActiveOnly() {
    this.generateSettingConditions();
  }

  showOperatorOptions(conditionType) {
    if (!this.operatorOptions) return;

    return this.operatorOptions[conditionType].length > 1;
  }

  showConditionField(condition, fieldType) {
    if (!this.filterColumns(condition)) return;

    return this.filterColumns(condition).fieldType === fieldType;
  }

  classFieldTypeText(condition) {
    return condition.operator === 'blank' ?
      'uModal__formConditionTextBody--disabled' : 'uModal__formConditionTextBody';
  }

  classSelectMultipleFocus(condition) {
    return condition.openSelectMultiple ?
      'uModal__formConditionSelectMultipleFocus--focus' : 'uModal__formConditionSelectMultipleFocus';
  }

  classUiSelectSearchContainer(condition) {
    return condition.openSelectMultiple ?
      'ui-select-search-container--open' : 'ui-select-search-container';
  }

  classSideText(condition) {
    return condition === '未入力...' ? 'uModal__formConditionSideText--empty' : 'uModal__formConditionSideText';
  }

  getColumnIdOptions(index) {
    if (!this.columnIdOptions) return;

    return this.columnIdOptions.filter((column) => {
      return !column.used || column.used === index + 1;
    });
  }

  getFieldLabel(condition, fieldType) {
    const column = this.filterColumns(condition);
    const defaultFieldLabel = this.defaultFieldLabels.find((fieldLabel) => {
      return fieldLabel.fieldType === column.fieldType;
    }).label;

    return column.fieldLabel || defaultFieldLabel;
  }

  getAppend(condition, fieldType) {
    return this.filterColumns(condition).append;
  }

  setConditions() {
    if (this.conditions.length === 0) {
      this.addCondition();

      return;
    }
  }

  canAddCondition() {
    return this.conditions && this.columns && (this.conditions.length < this.columns.length);
  }

  addCondition() {
    const notUsedColumn = this.columnIdOptions.find((columnIdOption) => {
      return !this.conditions.some((condition) => {
        return condition.columnId === columnIdOption.key;
      });
    });

    notUsedColumn.used = this.conditions.length + 1;

    const conditionType = notUsedColumn.conditionType;

    let condition = {
      columnId: notUsedColumn.key,
      conditionType: conditionType,
      operator: this.operatorOptions[conditionType][0].key
    };

    if (notUsedColumn.key === 'farm') {
      condition.masterId = this.farmOptions[0].key;
    }

    this.conditions.push(condition);
    this.validate();
    this.generateSettingConditions();
  }

  deleteCondition(index) {
    this.conditions.splice(index, 1);

    this.columnIdOptions.find((column) => {
      return column.used === index + 1;
    }).used = null;

    this.columnIdOptions = this.columnIdOptions.map((column) => {
      const used = column.used > index + 1 ? column.used - 1 : column.used;

      return {
        key: column.key,
        label: column.label,
        conditionType: column.conditionType,
        used: used || null
      };
    });

    this.validate();
    this.generateSettingConditions();
  }

  clearConditionField(condition) {
    delete condition.conditionString;
    delete condition.conditionMinNumber;
    delete condition.conditionMaxNumber;
    delete condition.conditionMinDate;
    delete condition.conditionMaxDate;
    delete condition.selections;
    delete condition.selectMultiple;
  }

  formatSelections(selections) {
    if (!selections) return;

    return selections.join('、');
  }

  setOpenSelectMultiple(condition, isOpen) {
    condition.openSelectMultiple = isOpen;
  }

  toggleOpenSelectMultiple($select) {
    $select.searchInput[0].placeholder = $select.placeholder;
    $select.open = !$select.open;

    setTimeout(() => $select.searchInput[0].focus());
  }

  validate() {
    this.invalid = true;

    if (this.conditions.length === 0) return;

    this.invalid = this.conditions.some((condition) => {
      const fieldType = this.filterColumns(condition).fieldType;

      switch (fieldType) {
      case 'string':
        const replaceComma = String(condition.conditionString).replace(/[,、]/g, '');

        return (condition.operator !== 'blank' && !condition.conditionString) ||
          !replaceComma;
      case 'selectMultiple':
        return !(condition.selections && condition.selections.length);
      case 'number':
        if (!(condition.conditionMinNumber && condition.conditionMaxNumber)) return true;
        return Number(condition.conditionMinNumber) > Number(condition.conditionMaxNumber);
      case 'date':
        if (condition.operator === 'blank') {
          return false;
        }
        return !(condition.conditionMinDate || condition.conditionMaxDate) ||
          (condition.conditionMinDate && condition.conditionMaxDate &&
            condition.conditionMinDate > condition.conditionMaxDate);
      }
    });

    this.adjustCondition();
  }

  adjustCondition() {
    this.conditions.forEach((condition) => {
      if (condition.columnId === 'state' && condition.operator === 'include') {
        const selections = condition.selections || [];
        const nonActiveStateSlected = selections.some((selection) => ['出荷', 'へい死'].includes(selection));

        if (nonActiveStateSlected) {
          this.activeOnly = false;
        }
      } else if (['eliminate_date', 'falling_dead_date'].includes(condition.columnId) && condition.operator === 'between') {
        this.activeOnly = false;
      }
    });
  }

  search() {
    const conditions = this.conditions.map((condition) => {
      const item = Object.assign({}, condition);

      delete item.openSelectMultiple;
      delete item.selectMultiple;

      return item;
    });

    this.$modalInstance.close({
      activeOnly: this.activeOnly,
      conditions: conditions
    });
  }

  close() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('CowSearchDialogController', CowSearchDialogController);
