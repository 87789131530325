class HomeAlertBadConditionController {
  constructor(
    $state,
    SessionCache
  ) {
    'ngInject';

    this.$state = $state;

    HomeAlertBaseController.bind(this);

    const customlistSystemConfigs = SessionCache.customlistSystemConfigs();

    this.init(customlistSystemConfigs);
  }

  init(customlistSystemConfigs) {
    const config = customlistSystemConfigs.find((config) => config.configType === 'alert_bad_condition');

    if (config) {
      this.customlistId = config.customlistId;
    }
  }

  goToAlertList(filter) {
    if (this.customlistId) {
      this.$state.go('alertList', {customlistId: this.customlistId, filter: filter});
    }
  }
}

function homeAlertBadCondition() {
  return {
    templateUrl: 'menu/home/alert/bad-condition/index.html',
    controller: HomeAlertBadConditionController,
    controllerAs: 'ctrl',
    bindings: {
      summary: '<'
    }
  };
}

app.component('homeAlertBadCondition', homeAlertBadCondition());
