class MobileEventDetailModalController {
  static get RETURN_STATUS() {
    return {
      SUBMIT: 'submit',
      CANCEL: 'cancel',
      CHANGE_EVENT_TYPE: 'changeEventType'
    };
  }

  constructor(
    $modalInstance,
    event,
    EventSelectionMasterInitializerService,
    EventLabelResolverService,
    options,
    SessionCache
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
    this.EventSelectionMasterInitializerService = EventSelectionMasterInitializerService;
    this.selectionMaster = null;
    this.canEditFilterItem = !SessionCache.account().isItemFilterApplied();

    this.medicineLabels = [];
    this.cowGroupNameMap = {};
    this.eventDisplay = {};
    this.EventLabelResolverService = EventLabelResolverService;
    this.options = options;

    this.init(event);
  }

  init(event) {
    // 空のダイアログが表示されるのを防ぐ
    this.eventDisplay = angular.copy(event);

    this.EventSelectionMasterInitializerService.initSelectionMaster(new EventSelectionMasterContainer(this.event))
      .then((result) => {
        this.selectionMaster = result;

        this.eventDisplay = this.EventLabelResolverService.resolveLabel(event, this.selectionMaster);
      });
  }

  onCancel() {
    this.$modalInstance.close({
      status:
        MobileEventDetailModalController.RETURN_STATUS.CANCEL
    });
  }

  onCowDetailMobile() {
    this.$modalInstance.close({
      status:
        MobileEventDetailModalController.RETURN_STATUS.SUBMIT
    });
  }

  shouldShowField(fieldName) {
    return CowEvent.shouldShowField(this.eventDisplay, fieldName);
  }

  shouldShowCowNo() {
    return this.options.showCowNo;
  }

  shouldShowCowDetailButton() {
    return this.eventDisplay.eventType !== 'nisshi';
  }

  shouldShowFilterItem(price) {
    return price && this.canEditFilterItem;
  }
}

app.controller('MobileEventDetailModalController', MobileEventDetailModalController);
