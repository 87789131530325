/**
 * @deprecated AlertAPIを利用してください
 * ※この実装の修正および、新規の利用は禁止です
 */
class AlertService {
  constructor(
    APIService,
    DateUtilService) {
    'ngInject';
    this.APIService = APIService;
    this.DateUtilService = DateUtilService;

    this.alertsByCow = this.alertsByCow.bind(this);
  }

  index() {
    const date = getDateLabelForApiResDateTime(new Date());
    const endpoint = `/alert/${date}`;
    return this.APIService.get(endpoint);
  }

  getAlertEc() {
    return this.APIService.get('/alert/ec');
  }

  summary() {
    return this.APIService.get('/alert/summary');
  }

  alertCows(alertType) {
    const serverAlertType = {
      'milk-low': 'MILK_LOW',
      'feed-low': 'FEED_LOW',
      'water-low': 'WATER_LOW',
      'lie-high': 'LIE_HIGH'
    };

    const url = '/alert/activity/' + serverAlertType[alertType] + '/';
    const showDate = getDateLabelForApiResDateTime(new Date());
    return this.APIService.get(url + showDate);
  }

  getAlertDysstasia() {
    return this.APIService.get('/alert/dysstasia');
  }

  alertDysstasiaDigest() {
    return this.APIService.get('/alert/dysstasia/digest');
  }

  updateAlertDysstasia(params) {
    return this.APIService.put('/alert/dysstasia', params);
  }

  getAlertLocationRecent() {
    return this.APIService.get('/alert/location');
  }

  updateAlertLocation(alerts) {
    return this.APIService.put('/alert/location', alerts);
  }

  getAlertLocation(date) {
    const w3cdate = this.DateUtilService.toW3CFormat(date);
    return this.APIService.get('/alert/location/' + w3cdate);
  }

  /**
   * 牛ごとのアラート履歴を取得
   * @param {string} cowId 牛番号
   */
  alertsByCow(cowId) {
    return this.APIService.get('/alert/cow/' + cowId);
  }

  /**
   * 全てのアラート履歴を取得
   * @param {string} alertType - アラートの種類。Alerts.ALERT_TYPES
   * @param {Date} startDate - 開始日
   * @param {Date} endDate - 終了日
   * @param {Object} options
   *   {number} cowNo: 牛番号を指定
   *   {number} cowGroupId: 牛群IDを指定
   *   {bool} inactive: 出荷・へい死を含む
   */
  alerts(alertType, startDate, endDate, options) {
    const queryParams = {
      alert_type: alertType,
      start_date: this.DateUtilService.toYYYYMMDD(startDate, '-'),
      end_date: this.DateUtilService.toYYYYMMDD(endDate, '-'),
      cow_no: 'cowNo' in options ? options.cowNo : null,
      cow_group_id: 'cowGroupId' in options ? options.cowGroupId : null,
      inactive: 'inactive' in options ? options.inactive : null
    };

    const query = Object.keys(queryParams)
      .filter((key) => queryParams[key] !== null)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');

    return this.APIService.get('/alert/cow/search?' + query);
  }
}

app.service('AlertService', AlertService);
