class DepositUserDefinedSelectionAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  available(qwert) {
    return this.APIService.get(`/deposit/user_defined_selection/available?qwert=${qwert}`);
  }
}

app.service('DepositUserDefinedSelectionAPI', DepositUserDefinedSelectionAPI);
