class GroupTransferHistoryCurrentFarmController {
  constructor(
    $state,
    $modal,
    $stateParams,
    Dictionary,
    StandardDialog,
    GroupTransferAPI
  ) {
    'ngInject';

    this.$state = $state;
    this.$modal = $modal;
    this.$stateParams = $stateParams;
    this.StandardDialog = StandardDialog;
    this.GroupTransferAPI = GroupTransferAPI;
    this.cowNoLabel = Dictionary.COW.COW_NO;

    this.init();
  }

  init() {
    this.model = {
      condition: {},
      histories: []
    };
    this.transferTypes = GroupTransfer.generateAllTransferTypes();
    this.transferTypes.unshift({
      value: 'all',
      label: '全て',
    });

    this.index();
  }

  index() {
    if (Object.keys(this.$stateParams.condition).length) {
      this.model.condition = this.$stateParams.condition;
    } else {
      this.model.condition = {
        fromTransferDate: DateUtil.toMSec(DateUtil.addDays(DateUtil.today(), -7)),
        toTransferDate: DateUtil.toMSec(DateUtil.today()),
        inOut: 'all',
        transferType: 'all',
        enrolledOnly: true
      };
    }

    this.search();
  }

  search() {
    this.validate();

    if (this.invalid) return;

    this.uiGrid = null;

    this.GroupTransferAPI.historyCurrentFarm(this.model.condition).then((res) => {
      const data = res.data;
      const generator = new GroupTransferHistoryCurrentFarmGenerator();

      this.count = `検索結果：${data.length}件`;
      this.model.histories = GroupTransferHistoryCurrentFarm.updateCow(data, this.transferTypes);
      this.rawCows = [].concat(this.model.histories);
      this.columns = GroupTransferHistoryCurrentFarm.COLUMNS;
      this.uiGrid = {
        appScopeProvider: this,
        columnDefs: generator.generateColumnDefs(this.columns),
        rowTemplate: generator.generateRowTemplate(),
        data: this.model.histories,
        onRegisterApi: (gridApi) => {
          this.gridApi = gridApi;
        }
      };
    });
  }

  validate() {
    this.invalid = false;

    const invalidFromDate = GroupTransfer.validateDate(this.model.condition.fromTransferDate);

    if (invalidFromDate) {
      this.invalid = true;
      this.StandardDialog.showMessage({
        title: '転出転入一覧',
        text1: '異動日（開始日）の入力が正しくありません'
      });
      return;
    }

    const invalidToDate = GroupTransfer.validateDate(this.model.condition.toTransferDate);

    if (invalidToDate) {
      this.invalid = true;
      this.StandardDialog.showMessage({
        title: '転出転入一覧',
        text1: '異動日（終了日）の入力が正しくありません'
      });
      return;
    }
  }

  toggleAllSelected() {
    this.model.histories = this.model.histories.map((history) => {
      if (history.canAddEvent) {
        history.selected = this.model.allSelected;
      }

      return history;
    });
  }

  toggleSelected() {
    this.model.allSelected = this.model.histories.filter((history) => {
      return history.canAddEvent;
    }).every((history) => {
      return history.selected;
    });
  }

  onClickBulkEvent() {
    const cowIds = this.model.histories.filter((history) => {
      return history.selected;
    }).map((history) => {
      return history.cowId;
    });

    this.$modal.open({
      templateUrl: 'components/event-registration/event-selection.html',
      controller: 'EventSelectionController',
      controllerAs: 'ctrl',
      size: '1012',
      resolve: {
        cowIds: () => cowIds,
        cowType: () => null,
        isDepositor: () => null,
        qwert: () => 0,
        caller: () => null
      },
    });
  }

  onClickColumnHeader(columnId, gridCol) {
    const column = this.columns.find((column) => column.columnId === columnId);

    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        CustomlistSort.sort(this.model.histories, column, false);

        this.currentColumnSort = {
          column,
          isAscending: false
        };
      } else {
        this.currentColumnSort = null;
        this.model.histories = [].concat(this.rawCows);
        this.uiGrid.data = this.model.histories;
      }
    } else {
      CustomlistSort.sort(this.model.histories, column, true);

      this.currentColumnSort = {
        column,
        isAscending: true
      };
    }
  }

  goToCowDetail(cowId) {
    const cowIds = this.model.histories.filter((history) => {
      return history.canAddEvent;
    }).map((history) => {
      return history.cowId;
    });
    const caller = {
      state: 'group-transfer-history-current-farm',
      name: '転出転入一覧',
      params: {
        condition: this.model.condition
      },
      cowIds: cowIds,
      viewState: 'DETAIL'
    };

    this.$state.go('cowDetail', {
      cowId: cowId,
      caller
    });
  }

  selectedCount() {
    const num = this.model.histories.filter((history) => {
      return history.selected;
    }).length;

    return `${num}件を選択`;
  }

  classToolbar() {
    const selectedHistories = this.model.histories.filter((history) => {
      return history.selected;
    });

    return selectedHistories.length ? 'display' : '';
  }

  classHeaderCell(gridCol) {
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        return 'ui-grid-sorted-asc';
      } else {
        return 'ui-grid-sorted-desc';
      }
    }
  }

  classRow(canAddEvent) {
    return !canAddEvent ? 'contents__uiGridInactive' : '';
  }
}

app.controller('GroupTransferHistoryCurrentFarmController', GroupTransferHistoryCurrentFarmController);
