// eslint-disable-next-line no-unused-vars
class CustomlistContentGenerator {
  static bredDigestSummary(options) {
    const contentsMap = {
      'date': '作業日',
      'comment': 'コメント',
      'detail': '詳細',
    };
    const contents = options.contents.map((content) => {
      return contentsMap[content];
    }).join('、');

    const eventTypesMap = {
      'bred': '種付',
      'diagnosis': '妊鑑',
      'timed_ai': 'ホルモン',
      'heat': '発情',
    };
    const eventTypes = options.eventTypes.map((eventType) => {
      return eventTypesMap[eventType];
    }).join('、');

    const rangeMap = {'latest': '直近の作業', 'all': '全ての作業'};
    const range = rangeMap[options.range];

    const sortOrderMap = {'asc': '作業日の昇順', 'desc': '作業日の降順'};
    const sortOrder = sortOrderMap[options.sortOrder];

    const styleMap = {'line_break': '作業毎に行を分ける', 'one_line': '1行にまとめる'};
    const style = styleMap[options.style];

    const result = [
      `内容: ${contents}`,
      `種類: ${eventTypes}`,
      `対象: ${range}`,
      `順序: ${sortOrder}`,
      `形式: ${style}`,
    ];
    return result.join('\n');
  }
}
