app.factory('CheckBoxFactory', () => {
  'ngInject';
  /**
   * 値のチェックを行う。
   *
   * @param {string} value
   * @return {boolean} true or false
   */
  function checkValue(value) {
    if (typeof value === 'string') {
      if (value === 'true' || value === 'TRUE') {
        return true;
      } else {
        return false;
      }
    }
    return value ? true : false;
  }

  return {
    /**
     * チェックボックスがON、OFF時の文字列を返す。
     * 文字列が設定されていない場合は空文字を返す。
     *
     * @param {boolean|string} value チェックボックスの値
     * @param {string} trueString true時の文字列
     * @param {string} falseString false時の文字列
     * @return {string} 文字列
     */
    displayString: (value, trueString = '', falseString = '') => {
      return checkValue(value) ? trueString : falseString;
    },
  };
});
