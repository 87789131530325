class DepositFarmService {
  constructor(
    APIService
  ) {
    'ngInject';
    this.APIService = APIService;
  }

  show(qwert) {
    const farm = this.APIService.get(`/deposit/farm?qwert=${qwert}`)
      .then((response) => response.data)
      .catch((error) => console.error('Data read failed', error.message));

    return farm;
  }
}

app.service('DepositFarmService', DepositFarmService);
