class GroupTransferHistoryController {
  constructor(
    $q,
    $state,
    $modal,
    $timeout,
    blockUI,
    Dictionary,
    SessionCache,
    GroupFarmAPI,
    StandardDialog,
    FileUtilService,
    uiGridConstants,
    GroupTransferAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$modal = $modal;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.GroupFarmAPI = GroupFarmAPI;
    this.StandardDialog = StandardDialog;
    this.FileUtilService = FileUtilService;
    this.uiGridConstants = uiGridConstants;
    this.GroupTransferAPI = GroupTransferAPI;
    this.cowNoLabel = Dictionary.COW.COW_NO;
    this.useName = SessionCache.farm().useName();

    this.init();
  }

  init() {
    this.loading = true;
    this.model = {
      historyCondition: {
        cowCondition: {
          type: 'cow_no'
        }
      },
      histories: []
    };

    this.index();
  }

  index() {
    this.model.historyCondition.fromTransferDate = DateUtil.toMSec(DateUtil.addMonths(DateUtil.today(), -1));
    this.model.historyCondition.toTransferDate = DateUtil.toMSec(DateUtil.today());
    this.GroupFarmAPI.index().then((res) => {
      const farms = res.data;

      this.memberFarms = GroupTransfer.generateMemberFarms(farms);
      this.transferTypes = GroupTransfer.generateTransferTypes(farms);
      this.loading = false;

      this.search();
    });
  }

  search() {
    const historyCondition = GroupTransferHistory.setHistoryCondition(this.model.historyCondition);

    this.uiGrid = null;

    this.GroupTransferAPI.history(historyCondition).then((res) => {
      const data = res.data;
      const generator = new GroupTransferHistoryGenerator();

      this.count = data.length;
      this.model.histories = GroupTransferHistory.updateCow(data, this.transferTypes);
      this.rawCows = [].concat(this.model.histories);
      this.columns = GroupTransferHistory.COLUMNS;
      this.uiGrid = {
        appScopeProvider: this,
        columnDefs: generator.generateColumnDefs(this.columns),
        rowTemplate: generator.generateRowTemplate(),
        data: this.model.histories,
        onRegisterApi: (gridApi) => {
          this.gridApi = gridApi;
        }
      };
    });
  }

  exportExcel() {
    const columns = GroupTransferHistory.filterColumns({
      enableExport: true
    });
    const names = GroupTransferHistory.names(columns);
    const rows = GroupTransferHistory.rows(columns, this.model.histories);
    const data = names.concat(rows);
    const fileName = '入力履歴.xlsx';

    this.FileUtilService.exportAsExcel(data, fileName);
  }

  toggleAllSelected() {
    this.model.histories = this.model.histories.map((history) => {
      if (history.editable) {
        history.selected = this.model.allSelected;
      }

      return history;
    });
  }

  toggleSelected() {
    this.model.allSelected = this.model.histories.filter((history) => {
      return history.editable;
    }).every((history) => {
      return history.selected;
    });
  }

  onChangeTransferDate() {
    this.search();
  }

  onEditSelected() {
    const params = GroupTransferHistory.setUpdateHistoriesParams(this.model.histories);

    this.$state.go('group-transfer-input', params);
  }

  onRollbackSelected() {
    const histories = this.model.histories.filter((history) => {
      return history.selected;
    });

    this.rollback(histories);
  }

  onClickColumnHeader(columnId, gridCol) {
    const column = this.columns.find((column) => column.columnId === columnId);

    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        CustomlistSort.sort(this.model.histories, column, false);

        this.currentColumnSort = {
          column,
          isAscending: false
        };
      } else {
        this.currentColumnSort = null;
        this.model.histories = [].concat(this.rawCows);
        this.uiGrid.data = this.model.histories;
      }
    } else {
      CustomlistSort.sort(this.model.histories, column, true);

      this.currentColumnSort = {
        column,
        isAscending: true
      };
    }
  }

  openAdvancedSearchModal() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/group/transfer/history/advanced-search/index.html',
      controller: 'GroupTransferHistoryAdvancedSearchController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            cowNoLabel: this.cowNoLabel,
            memberFarms: this.memberFarms,
            transferTypes: this.transferTypes,
            historyCondition: this.model.historyCondition
          };
        }
      }
    }).result.then((params) => {
      this.model.historyCondition = params;
      this.search();
    });
  }

  openOperationModal(id, operationType) {
    const history = this.model.histories.find((h) => {
      return h.transferInputId === id;
    });

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/group/transfer/history/edit/index.html',
      controller: 'GroupTransferHistoryEditController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            operationType: operationType,
            history: history,
            memberFarms: this.memberFarms,
            transferTypes: this.transferTypes,
            function: operationType === 'edit' ?
              (histories) => this.update(histories) : (histories) => this.rollback(histories)
          };
        }
      }
    });
  }

  update(histories) {
    const params = GroupTransferHistory.setUpdateParams(histories);

    this.blockUI.start('更新中');

    return this.GroupTransferAPI.update(params).then(() => {
      this.blockUI.stop();
      this.blockUI.start('入力履歴の更新が完了しました');
      this.$timeout(() => {
        this.blockUI.stop();
        this.search();
      }, 1000);

      const d = this.$q.defer();
      d.resolve(true);
      return d.promise;
    }).catch((err) => {
      let errorMessage;

      this.blockUI.stop();

      if (err.data && err.data.messages) {
        errorMessage = ErrorUtil.formatErrorMessage(err.data.messages);
      } else {
        errorMessage = '入力履歴の更新でエラーが発生しました';
      }

      this.StandardDialog.showMessage({
        title: '履歴の編集',
        text1: errorMessage,
      });

      const d = this.$q.defer();
      d.resolve(false);
      return d.promise;
    });
  }

  rollback(histories) {
    return this.confirmRollback().then((res) => {
      if (!res) {
        const d = this.$q.defer();
        d.resolve(false);
        return d.promise;
      }

      return this.confirmRemovedSensor(histories).then((res) => {
        if (!res) {
          const d = this.$q.defer();
          d.resolve(false);
          return d.promise;
        }

        return this.rollbackGroupTransfer(histories).then((res) => {
          const d = this.$q.defer();
          d.resolve(true);
          return d.promise;
        });
      });
    });
  }

  confirmRollback() {
    return this.StandardDialog.showDeleteConfirm({
      title: '履歴の削除',
      text1: '入力履歴',
      yes: 'OK',
      no: 'キャンセル'
    }).result.then(() => {
      const d = this.$q.defer();
      d.resolve(true);
      return d.promise;
    }).catch(() => {
      const d = this.$q.defer();
      d.resolve(false);
      return d.promise;
    });
  }

  confirmRemovedSensor(histories) {
    const hasRemoveSensor = histories.some((h) => h.removeSensor);

    if (!hasRemoveSensor) {
      const d = this.$q.defer();
      d.resolve(true);
      return d.promise;
    }

    return this.StandardDialog.showYesNoConfirm({
      title: '履歴の削除',
      text1: '異動入力時に変更したセンサー情報は削除されません。必要に応じてセンサー履歴を修正してください。',
      text2: 'よろしいですか？',
      yes: 'OK',
      no: 'キャンセル'
    }).result.then(() => {
      const d = this.$q.defer();
      d.resolve(true);
      return d.promise;
    }).catch(() => {
      const d = this.$q.defer();
      d.resolve(false);
      return d.promise;
    });
  }

  rollbackGroupTransfer(histories) {
    const ids = histories.map((h) => h.transferInputId);

    this.blockUI.start('削除中');

    return this.GroupTransferAPI.rollback(ids).then(() => {
      this.blockUI.stop();
      this.blockUI.start('入力履歴の削除が完了しました');
      this.$timeout(() => {
        this.blockUI.stop();
        this.search();
      }, 1000);
    }).catch((err) => {
      let errorMessage;

      this.blockUI.stop();

      if (err.data && err.data.messages) {
        errorMessage = ErrorUtil.formatErrorMessage(err.data.messages);
      } else {
        errorMessage = '入力履歴の削除でエラーが発生しました';
      }

      this.StandardDialog.showMessage({
        title: '履歴の削除',
        text1: errorMessage,
      });
    });
  }

  decideFarmName(id) {
    if (!id) return null;

    return GroupTransferHistory.decideFarmName(this.memberFarms, id);
  }

  decideTransferTypeLabel(typeValue) {
    if (!typeValue) return null;

    return GroupTransferHistory.decideTransferTypeLabel(this.transferTypes, typeValue);
  }

  decideMatchingPatternLabel() {
    return this.model.historyCondition.cowCondition.matchingPatternComplete ? '（完全一致）' : '（あいまい検索）';
  }

  showCowConditionCowNo() {
    if (!this.model.historyCondition.cowCondition.value) return false;

    return this.model.historyCondition.cowCondition.type === 'cow_no';
  }

  showCowConditionCowUid() {
    if (!this.model.historyCondition.cowCondition.value) return false;

    return this.model.historyCondition.cowCondition.type === 'cow_uid';
  }

  showGridWarning() {
    return this.model.histories.some((history) => {
      return !history.editable;
    });
  }

  showGridSelectedError() {
    return this.model.histories.filter((history) => {
      return history.selected;
    }).some((history, index, array) => {
      return history.origin.transferDate !== array[0].origin.transferDate ||
        history.fromId !== array[0].fromId ||
        history.toId !== array[0].toId ||
        history.transferType !== array[0].transferType;
    });
  }

  showButtonAction() {
    return this.selectedCount() && !this.showGridSelectedError();
  }

  hasHistoryCondition() {
    return this.model.historyCondition.cowCondition.value ||
      this.model.historyCondition.fromId ||
      this.model.historyCondition.toId ||
      this.model.historyCondition.transferType;
  }

  selectedCount() {
    return this.model.histories.filter((history) => {
      return history.selected;
    }).length;
  }

  disabledExportButton() {
    return !this.model.histories.length;

  }

  classHeaderCell(gridCol) {
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        return 'ui-grid-sorted-asc';
      } else {
        return 'ui-grid-sorted-desc';
      }
    }
  }
}

app.controller('GroupTransferHistoryController', GroupTransferHistoryController);
