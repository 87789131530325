app.service('PDFService', (JWTService) => {
  'ngInject';
  let service = {
    getPDFReport: (menuId, params) => {
      let url = '/pdf/' + menuId;

      // メニュー毎のパラメーターの違いを吸収する
      if (params) {
        let converted = params.map(function(param) {
          return param.key + '=' + param.value;
        });
        url += '?' + converted.join('&');
      }
      return JWTService.sendJWTBlobRequest(url, 'GET');
    }

  };
  return service;
});
