class TopScheduleController {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.total = {};
    this.date = DateUtil.today();
    this.updateDateDisplay(this.date);
  }

  updateDateDisplay(date) {
    const dayOfWeek = DateUtil.japaneseDayOfWeek(date);
    this.dateDisplay = DateUtil.format(date, `MM/DD (${dayOfWeek})`);
  }

  isToday(date) {
    return DateUtil.isToday(date);
  }

  onPreviousDay() {
    this.date = DateUtil.previousDay(this.date);
    this.updateDateDisplay(this.date);
  }

  onNextDay() {
    this.date = DateUtil.nextDay(this.date);
    this.updateDateDisplay(this.date);
  }
}

function topSchedule() {
  return {
    templateUrl: 'top/schedule/index.html',
    controller: TopScheduleController,
    controllerAs: 'ctrl'
  };
}

app.component('topSchedule', topSchedule());
