class CowCalvingSensorHistoryAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  list(cowId) {
    return this.APIService.get(`/cow_calving_sensor_history/cow/${cowId}`);
  }

  create(history) {
    return this.APIService.post('/cow_calving_sensor_history', history);
  }

  update(id, history) {
    return this.APIService.put(`/cow_calving_sensor_history/${id}`, history);
  }

  delete(id) {
    return this.APIService.delete(`/cow_calving_sensor_history/${id}`);
  }

  bulkEquip(params) {
    return this.APIService.post('/cow_calving_sensor_history/bulk', params);
  }

  bulkUnequip(params) {
    return this.APIService.put('/cow_calving_sensor_history/bulk', params);
  }
}

app.service('CowCalvingSensorHistoryAPI', CowCalvingSensorHistoryAPI);
