// eslint-disable-next-line no-unused-vars
class HomeSettings {
  constructor(sessionCache) {
    this.sessionCache = sessionCache;
  }

  init() {
    if (this.get()) return;

    const settings = {
      notification: {
        hidden: []
      },
      headage: {
        visible: true,
        visibleDetails: ['dairy', 'breeding', 'state', 'breed', 'inventory']
      },
      schedule: {
        date: DateUtil.today()
      },
      dailyReport: {
        date: DateUtil.today(),
        displayMode: 'summary',
        visibleDetails: []
      }
    };

    this.sessionCache.store('homeSettings', settings);
  }

  get(key) {
    const settings = this.sessionCache.homeSettings();

    if (!key) return settings;

    return settings[key] || {};
  }

  update(category, newValue) {
    const params = Object.assign({}, this.get(category), newValue);
    const settings = Object.assign({}, this.get(), {[category]: params});

    this.sessionCache.store('homeSettings', settings);
  }
}
