function lameDiagnosis() {
  'ngInject';
  return {
    restrict: 'E',
    template: '<p></p>',
    link: (scope, element, attrs) => {
      let currentElement = element;
      scope.$watch('r', () => {
        const positions = ['左前', '右前', '左後', '右後'];
        let hasClawDiseaseName = false;
        let contentHTML = '';

        positions.forEach((p) => {
          if (hasClawDiseaseName) return;
          scope.r.groupedLameDiagnosises[p].forEach((d) => {
            if (hasClawDiseaseName) return;
            if (d.clawDiseaseName) return hasClawDiseaseName = true;
          });
        });

        positions.forEach((p) => {
          scope.r.groupedLameDiagnosises[p].forEach((d, i) => {
            if (!i) contentHTML += `<span>[${p}]&nbsp;</span>`;
            if (i && hasClawDiseaseName) contentHTML += `<span style="color: #fff;">[${p}]&nbsp;</span>`;
            if (d.lameAffectedPart) contentHTML += `<span>${d.lameAffectedLimbAndPart}&nbsp;</span>`;
            if (d.lameCondition) contentHTML += `<span>${d.lameCondition}&nbsp;</span>`;
            if (d.clawDiseaseName) contentHTML += `<span>${d.clawDiseaseName}&nbsp;</span>`;
            if (hasClawDiseaseName) contentHTML += '<br>';
          });
        });

        contentHTML = `<p>${contentHTML}</p>`;
        const replacementElement = angular.element(contentHTML);
        currentElement.replaceWith(replacementElement);
        currentElement = replacementElement;
      });
    }
  };
}

app.directive('lameDiagnosis', lameDiagnosis);
