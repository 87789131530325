/**
 * 対象アラートのhtml埋め込み
 * 使い方: <mobile-alert-list-template type="alertType"></mobile-alert-list-template>
 */
app.directive('mobileAlertListTemplate', mobileAlertListTemplate);

const toggleDetails = (e, cow) => {
  $(e.currentTarget).next().slideToggle();
  cow.selected = !cow.selected;
};

const decideTemplateName = (alertType, farmKind) => {
  return farmKind === '乳用' ? 'milk' : 'beef';
};

function mobileAlertListTemplate($compile) {
  'ngInject';
  return {
    restrict: 'E',
    scope: {
      alertType: '=',
      alertCows: '=',
      farmKind: '=',
      goToDetails: '=',
      title: '='
    },
    link: (s, el, attr) => {
      if (!s.alertType) return;

      s.$watch('farmKind', (farmKind) => {
        if (!farmKind) return;

        let templateName = decideTemplateName(s.alertType, s.farmKind);

        const template = $compile(
          `<div ng-include="'mobile/alert-list/templates/${templateName}.html'" ng-init="isMilkFarm = ${farmKind === '乳用'}; isMeatFarm = ${farmKind === '肉用'}; isFalseHeat = ${s.alertType === 'falseHeat'}"></div>`
        )(s);
        el.replaceWith(template);
      });

      s.toggleDetails = toggleDetails;
    }
  };
}
