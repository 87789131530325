app.directive('treatmentCommon', treatmentCommon);

function treatmentCommon($compile) {
  'ngInject';
  return {
    restrict: 'A',
    scope: '=',
    link: (s, el, attr) => {
      const template = $compile(
        `
        <td>
          <select ng-model="e.workerName"
            ng-options="option.value as option.label for option in ctrl.state.selection.treatmentPersons"
            ng-init="ctrl.SelectBoxFactory.defaultOption(e, 'workerName')">
          </select>
        </td>

        <td>
          <div class="align-left">
            <display-selected-medicine medicines="e.selectedMedicines"
              ng-hide="ctrl.shouldDisableTreatmentDetail()">
            </display-selected-medicine>
          </div>

          <button ng-click="ctrl.openMedicineModal(e)"
            ng-disabled="ctrl.shouldDisableTreatmentDetail()"
            href="" class="btn-apply edit-btn glyphic hover" >
            <span class="icon-ss-check"></span>選択
          </button>
        </td>

        <td>
          <input type="text" name="bodyTemperature" maxlength="4"
            ng-model="e.bodyTemperature" format-check="decimal"
            class="body-temperature" />
          <span class="unit">℃</span>
        </td>
        `
      )(s);

      el.replaceWith(template);
    },
  };
}
