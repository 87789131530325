class BleIdDialogController {
  constructor(
    $modalInstance,
    params,
    BleIdService,
    UtilService,
    DateUtilService,
    blockUI,
    $timeout,
    modalDialogFactory,
    SessionCache
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.BleIdService = BleIdService;
    this.UtilService = UtilService;
    this.DateUtilService = DateUtilService;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.modalDialogFactory = modalDialogFactory;
    this.farm = SessionCache.farm();

    this.cowId = params.cowId || '';
    this.cowNo = params.cowNo || '';
    this.canDelete = params.canDelete;

    this.deleteExecuted = false;

    this.bleIdValid = false;
    this.inputDateValid = false;

    this.bleIdErrorMessage = '';
    this.invalid = true;
    this.invalidToUnequip = true;

    this.init();
  }

  init() {
    this.bleId = null;
    this.inputDate = null;
    this.cowBleIds = [];
    this.current = {bleId: null, startDate: null, canDelete: false};
    this.showHistory = false;
    this.errorMessage = '';
    this.equipButtonLabel = '装着';
    this.showSensorType = this.farm.data.useSensorType === 'use_both_sensor';

    this.BleIdService.cowBleIds(this.cowId).then((res) => {
      const cowBleIds = res.data.reverse();
      if (cowBleIds.length > 0) {
        const current = cowBleIds[0];
        current.canDelete = this.canDelete;

        this.bleId = current.bleId;
        if (this.showSensorType ) {
          this.sensorType = current.sensorType;
        }
        this.current = current;

        this.showHistory = true;
        this.equipButtonLabel = '交換';
      } else {
        if (this.showSensorType ) {
          this.sensorType = 1;
        }
      }

      this.cowBleIds = cowBleIds;
    });
  }

  validate() {
    this.invalid = (!this.bleIdValid || !this.inputDateValid);
    if (this.showHistory) {
      this.invalidToUnequip = !this.inputDateValid;
    }
  }

  onChangeBleId() {
    if (!this.bleId) {
      this.bleIdErrorMessage = {required: true};
      this.bleIdValid = false;
    } else {
      this.bleIdErrorMessage = '';
      this.bleIdValid = true;
    }
    this.validate();
  }

  onChangeInputDate() {
    this.inputDateValid = this.DateUtilService.isValidDate(this.inputDate);
    this.validate();
  }

  onEquip() {
    if (this.current.bleId === this.bleId
        && this.current.startDate === this.inputDate
        && this.current.sensorType === this.sensorType) {
      this.blockUI.start('変更がありません');
      this.$timeout(() => {
        this.blockUI.stop();
      }, 1000);
      return;
    }

    this.onChangeBleId();
    if (this.invalid) {
      return;
    }

    // 牛個体のble_idはゼロ埋めの12桁の文字列で運用しています(例 000000000123)
    const formatedBleId = ('000000000000' + this.bleId).slice(-12);
    const params = {
      cowId: this.cowId,
      bleId: formatedBleId,
      startDate: this.inputDate,
      sensorType: this.sensorType
    };

    this.blockUI.start('更新中');

    this.BleIdService.equip(params)
      .then(() => {
        this.blockUI.done(() => {
          this.bleId = formatedBleId;
          this.afterSave();
          this.blockUI.start('センサー番号履歴の追加が完了しました');
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close();
      }).catch((error) => {
        this.errorMessage = this.UtilService.formatErrorMessage(error.data.messages);
        this.blockUI.stop();
      });
  }

  onUnequip() {
    const modalInstance = this.modalDialogFactory.showYesNoConfirm({
      title: '解除確認',
      text1: 'センサーを解除します。',
      text2: 'よろしいでしょうか？',
      no: true,
      yes: true,
    });
    modalInstance.result.then((result) => {
      if (!result) return;

      this.blockUI.start('解除中');

      this.BleIdService.unequip(this.cowId, this.inputDate)
        .then(() => {
          this.blockUI.done(() => {
            this.blockUI.start('センサー解除が完了しました');
            this.$timeout(() => {
              this.blockUI.stop();
            }, 1000);
          });
          this.blockUI.stop();
          this.$modalInstance.close();
        }).catch((error) => {
          this.errorMessage = this.UtilService.formatErrorMessage(error.data.messages);
          this.blockUI.stop();
        });
    });
  }

  onDelete(cowBleId) {
    const startDate = this.DateUtilService.toYYYYMMDD(cowBleId.startDate);
    const modalInstance = this.modalDialogFactory.showYesNoConfirm({
      title: '削除確認',
      text1: `適用日が [${startDate}] の履歴を削除します。`,
      text2: 'よろしいでしょうか？',
      no: true,
      yes: true,
    });
    modalInstance.result.then((result) => {
      if (!result) return;

      this.blockUI.start('削除中');

      this.BleIdService.delete(this.cowId, cowBleId.startDate)
        .then(() => {
          this.deleteExecuted = true;

          this.blockUI.done(() => {
            this.blockUI.start('履歴の削除が完了しました');
            this.$timeout(() => {
              this.blockUI.stop();
            }, 1000);
          });
          this.blockUI.stop();
          this.init();
        }).catch((error) => {
          this.errorMessage = this.UtilService.formatErrorMessage(error.data.messages);
          this.blockUI.stop();
        });
    });
  }

  cancel() {
    if (this.deleteExecuted) {
      this.$modalInstance.close();
    } else {
      this.$modalInstance.dismiss('cancel');
    }
  }

  afterSave() {
    this.bleId = this.bleId; // Angularのモデル変更イベントを強制的に発生させる
  }

  onKeydownBleId(e) {
    if (!KeyInputUtil.isNumberKey(e)) {
      e.preventDefault();
    }
  }

  bleIdChanged(bleId) {
    return this.current.bleId !== bleId;
  }

  toYYYYMMDD(date) {
    return this.DateUtilService.toYYYYMMDD(date);
  }

  displayEndDate(cowBleId) {
    if (this.DateUtilService.isInfiniteDate(cowBleId.endDate)) {
      return '-';
    }
    return this.DateUtilService.toYYYYMMDD(cowBleId.endDate);
  }
}

app.controller('BleIdDialogController', BleIdDialogController);
