/**
 * 対象の文字列をnormalize(正規化)してから処理を実行します。
*/

// eslint-disable-next-line no-unused-vars
class NormalizedString {

  /**
   * 検査対象の文字列と指定した文字列を正規化して一致するか判定します
   *
   * @param {String} str 検査対象の文字列
   * @param {String} word キーワード文字列
   * @return {boolean} true: 同一の文字列
   */
  static equal(str, word) {
    if (!str || !word) return false;

    const s = StringUtil.normalize(str);
    const w = StringUtil.normalize(word);
    return s === w;
  }

  /**
   * 検査対象の文字列が指定したキーワード文字列を含むかを判定します
   *
   * @param {String} str 検査対象の文字列
   * @param {String} word キーワード文字列
   * @return {boolean} true: 指定したキーワード文字列を含む
   */
  static include(str, word) {
    if (!str || !word) return false;

    const s = StringUtil.normalize(str);
    const w = StringUtil.normalize(word);
    return s.includes(w);
  }

  /**
   * 絞込対象の指定したキーの値を対象に指定したキーワード文字列で絞込をします
   *
   * @param {Array.<Object>} records 絞込対象
   * @param {Array} itemKeys 絞込対象のキー
   * @param {String} word キーワード文字列
   * @return {Array.<Object>} 絞込をした配列
   */
  static filter(records, itemKeys, word) {
    if (!records.length || !itemKeys.length || !word) return records;

    return records.filter((r) => {
      return itemKeys.some((itemKey) => {
        return NormalizedString.include(r[itemKey], word);
      });
    });
  }

  /**
   * 絞込対象の指定したキーの値を対象に条件値に一致する要素を返します。
   * 文字列の比較は正規化して行われます。
   * 該当する要素が無い場合は Array.prototype.find に準拠して undefined を返します。
   *
   * @param {Array.<Object>} records 絞込対象
   * @param {Array} itemKey 絞込対象の項目のキー
   * @param {String} word 条件値
   * @return {Array.<Object>} 絞込をした配列
   */
  static find(records, itemKey, word) {
    if (!records.length === 0 || !itemKey || !word) return undefined;

    return records.find((r) => {
      return NormalizedString.equal(r[itemKey], word);
    });
  }
}
