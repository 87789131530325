class MobileNoSuchCowModalController {
  constructor(
    $modalInstance,
    searchType,
    Dictionary
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.searchType = searchType;
    this.Dictionary = Dictionary;
    this.message = this.generateMessage(searchType);
  }

  onCloseClick() {
    this.$modalInstance.close();
  }

  generateMessage(searchType = 'cowNo') {
    let searchTypeLabel;
    if (searchType === 'cowNo') searchTypeLabel = this.Dictionary.COW.COW_NO;
    if (searchType === 'cowUid') searchTypeLabel = '個体識別番号';
    if (searchType === 'bleId') searchTypeLabel = 'センサー番号';
    return `その${searchTypeLabel}の個体は存在しません`;
  }
}

app.controller('MobileNoSuchCowModalController', MobileNoSuchCowModalController);
