app.component('eventRegistration', eventRegistrationComponent());

function eventRegistrationComponent() {
  'ngInject';
  return {
    template: `
      <label class="bulk-event-label">イベント投稿</label>
      <button class="button bulk-event-button" type="button" ng-click="$ctrl.bulk()">一括登録</button>
      <button class="button bulk-event-button" type="button" ng-click="$ctrl.single()">個別登録</button>
    `,
    controller: eventRegistrationController,
    bindings: {
      caller: '='
    }
  };
}

function eventRegistrationController($modal) {
  'ngInject';
  const vm = this;

  /**
   * 一括登録のモーダルを開く
   */
  vm.bulk = () => {
    $modal.open({
      templateUrl: 'components/event-registration/event-selection.html',
      controller: 'EventSelectionController',
      controllerAs: 'ctrl',
      size: '1012',
      resolve: {
        cowIds: () => [],
        cowType: () => null,
        isDepositor: () => false,
        qwert: () => 0,
        caller: () => {}
      },
    });
  };

  /**
   * 個別登録のモーダルを開く
   */
  vm.single = () => {
    $modal.open({
      templateUrl: 'components/event-registration/cow-selection.html',
      controller: 'CowSelectionController as ctrl',
      size: 'm',
    });
  };
}
