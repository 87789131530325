// eslint-disable-next-line no-unused-vars
class CowBleId {
  constructor(obj) {
    Object.assign(this, obj);
    this.startDateYYYYMMDD = DateUtil.toYYYYMMDD(obj.startDate);
    this.endDateYYYYMMDD = DateUtil.isInfiniteDate(obj.endDate) ? '-' : DateUtil.toYYYYMMDD(obj.endDate);
  }

  /**
   * 牛個体に関連付けられたセンサー番号のリストから、最後につけていたものを返す。
   * @param {array} cowBleIds BleIdServiceで取得したセンサー番号のリスト
   * @return {string} 直近で利用しているセンサー番号。期間にマッチしない場合は、最後のセンサー番号を返す(「出荷・へい死」対応)。
   */
  static lastCowBleId(cowBleIds, date) {
    if (!date) {
      date = today().getTime();
    }

    const matched = cowBleIds.filter((cowBleId) => {
      return (date >= cowBleId.startDate) && (date <= cowBleId.endDate);
    });

    if (matched.length === 0) {
      if (cowBleIds.length > 0) {
        // 出荷・へい死した牛個体の活動量を参照するための措置
        return cowBleIds[cowBleIds.length - 1].bleId;
      }
      return '';
    }
    return matched[0].bleId;
  }

  /**
   * 牛個体に関連付けられたセンサー番号のリストから、現在のものを返す。
   * @param {array} cowBleIds BleIdServiceで取得したセンサー番号のリスト
   * @return {string} 直近で利用しているセンサー番号。期間がマッチしない場合は、空のストリングを返す。
   */
  static currentCowBleId(cowBleIds, currentTime = today().getTime()) {
    const matched = cowBleIds.filter((cowBleId) => {
      return (currentTime >= cowBleId.startDate) && (currentTime < cowBleId.endDate);
    });

    if (matched.length === 0) {
      return '';
    }
    return matched[0].bleId;
  }

  /**
   * 牛個体に関連付けられたセンサーのリストから、現在のセンサーオブジェクトを返す。
   * @param {array} cowBleIds BleIdServiceで取得したセンサーのリスト
   * @return {object} 直近で利用しているセンサーのオブジェクト。期間がマッチしない場合は、空のオブジェクトを返す。
   */
  static currentCowBleIdObj(cowBleIds, currentTime = today().getTime()) {
    const matched = cowBleIds.filter((cowBleId) => {
      return (currentTime >= cowBleId.startDate) && (currentTime < cowBleId.endDate);
    });

    if (matched.length === 0) {
      return {};
    }
    return matched[0];
  }

  /**
   * 有効なセンサー番号かを判定する
   * @param {string} cowBleId センサー番号
   * @return {bool} 有効な番号ならばtrue、無効ならばfalseを返す。
   */
  static validate(cowBleId) {
    if (!cowBleId) return false;
    if (!StringUtil.isDigit(cowBleId)) return false;
    return true;
  }

  /**
   * 12桁未満のセンサー番号を左から0詰めして12桁に整形する
   * @param {string} cowBleId センサー番号
   * @return {string} 12桁に整形されたセンサー番号
   */
  static to12Digits(cowBleId) {
    if (cowBleId === null || cowBleId === undefined) return '';
    if (cowBleId.length >= 12) return cowBleId;
    const digitsToPrepend = 12 - cowBleId.length;
    const zeros = [];
    for (let i = 0; i < digitsToPrepend; i++) {
      zeros.push('0');
    }
    return zeros.join('') + cowBleId;
  }

  static availablePeriods(cowBleIds, startDate, endDate) {
    const startDateInt = dateConversion(startDate, 'YYYYMMDD').getTime();
    const endDateInt = dateConversion(endDate, 'YYYYMMDD').getTime();

    return cowBleIds.filter((cowBleId) => (cowBleId.startDate <= endDateInt) && (cowBleId.endDate >= startDateInt))
      .map((cowBleId) => {
        // 期間の開始日は後の日付を優先
        const start = startDateInt >= cowBleId.startDate ? startDateInt : cowBleId.startDate;

        // 期間の開終了日は先の日付を優先
        const end = endDateInt <= cowBleId.endDate ? endDateInt : cowBleId.endDate;

        return {
          startDate: getDateForApiParam(start),
          endDate: getDateForApiParam(end),
          bleId: cowBleId.bleId
        };
      });
  }

  static isEartagSensor(cowBleId) {
    if (!cowBleId) return false;
    return cowBleId.startsWith('00200');
  }
}
