// eslint-disable-next-line no-unused-vars
class CowConfirmation {
  static toDisplayObj(cow, cowGroups, masterMarkets) {
    const obj = Object.assign({}, cow);
    if (obj.breedingBull) obj.breedingBullDisplay = '(種雄牛)';

    const group = cowGroups.find((g) => {
      return g.value === cow.cowGroupId;
    }) || {};

    const masterMarket = masterMarkets.find((market) => {
      return market.value === cow.masterMarketId;
    }) || {};

    obj.cowGroupName = group.label;
    obj.masterMarketName = masterMarket.label;

    return obj;
  }
}
