class ReproductionPlannedEventsAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  show(date, type) {
    if (!date) return new Promise((resolve, reject) => reject());

    const dateQuery = DateUtil.format(date, 'YYYY-MM-DD');
    const query = `/reproduction/next_event?date=${dateQuery}` + (type ? `&type=${type}` : '');

    return this.APIService.get(query);
  }

  /**
   * 基準日時点で表示対象の繁殖予定のイベント種類毎の頭数サマリーを取得する
   * @param {Date} value フォーマットする日付} date
   * @return {Object} サマリーデータ
   *
   * 例:
   *   {
   *     summary:  {
   *       bunben:  2,
   *       overBunben:  1,
   *       hatsujo:  5,
   *       tanetsuke: 5,
   *       hormoneProgram: 5,
   *       ninshinkantei: 3
   *     }
   *   }
   */
  summary(date) {
    const dateQuery = DateUtil.format(date, 'YYYY-MM-DD');

    if (!date) return new Promise((resolve, reject) => reject());

    return this.APIService.get(`/reproduction/next_event/summary?date=${dateQuery}`);
  }
}

app.service('ReproductionPlannedEventsAPI', ReproductionPlannedEventsAPI);
