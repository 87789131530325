/**
 * 分娩センサー番号のコンポーネント
 *
 * ex.
 * [編集可]
 *  <calving-sensor-number
 *    value="value"
 *    on-change="onChange(firstNumber, lastNumber)">
 *  </calving-sensor-number>
 *
 * [編集不可]
 *  <calving-sensor-number
 *    value="value"
 *    readonly="true">
 *  </calving-sensor-number>
 *
 * プロパティ:
 *  @param {string} value 分娩センサー番号
 *  @param {boolean} readonly true: 読み取り専用、省略時はfalseと同じ
 *  @param {Function} on-change 番号変更時に発生するイベント、calvingSensorNumberを引数で渡す
 */
class CalvingSensorNumberControler {
  constructor() {
    'ngInject';

    this.init();
  }

  $onChanges(obj) {
    this.splitCalvingSensorNumber(obj.value.currentValue);
  }

  init() {
    this.origin = {
      firstNumber: '',
      lastNumber: ''
    };
    this.firstNumber = '';
    this.lastNumber = '';
  }

  splitCalvingSensorNumber(value) {
    if (!value) return this.init();

    const splitedNumber = CowCalvingSensorHistory.splitCalvingSensorNumber(value);

    this.origin = splitedNumber;
    this.firstNumber = splitedNumber.firstNumber;
    this.lastNumber = splitedNumber.lastNumber;
  }

  displayedCalvingSensorNumber() {
    if (!this.value) return '';
    return `${this.firstNumber}-${this.lastNumber}`;
  }

  numberChanged(key) {
    return this.origin[key] !== this[key];
  }

  // イベント

  handleChange() {
    let calvingSensorNumber;
    if (CowCalvingSensorHistory.validate(this.firstNumber, this.lastNumber)) {
      calvingSensorNumber = CowCalvingSensorHistory.generateCalvingSensorNumber(this.firstNumber, this.lastNumber);
    } else {
      calvingSensorNumber = null;
    }
    this.onChange({calvingSensorNumber: calvingSensorNumber});
  }

  handleKeydown(e) {
    if (!KeyInputUtil.isNumberKey(e)) {
      e.preventDefault();
    }
  }
}

function calvingSensorNumberComponent() {
  return {
    templateUrl: 'components/calving-sensor-number/index.html',
    controller: CalvingSensorNumberControler,
    bindings: {
      value: '<',
      readonly: '<',
      onChange: '&'
    }
  };
}

app.component('calvingSensorNumber', calvingSensorNumberComponent());
