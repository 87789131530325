class CowEventBulkInput {
  constructor(
    $modal,
    $q,
    $rootScope,
    $state,
    $window,
    appConfig,
    blockUI,
    DateTimeUtilFactory,
    modalDialogFactory,
    SelectMedicineModal,
    DateUtilService,
    EventSelectionSimpleConfirm,
    SelectCowService,
    Dictionary,
    BulkEntryEventContext,
    DepositBulkEntryEventContext,
    IsMobile,
    SessionCache,
    StandardDialog
  ) {
    'ngInject';
    this.$modal = $modal;
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$window = $window;
    this.appConfig = appConfig;
    this.blockUI = blockUI;
    this.DateTimeUtilFactory = DateTimeUtilFactory;
    this.modalDialogFactory = modalDialogFactory;
    this.SelectMedicineModal = SelectMedicineModal;
    this.DateUtilService = DateUtilService;
    this.EventSelectionSimpleConfirm = EventSelectionSimpleConfirm;
    this.SelectCowService = SelectCowService;
    this.Dictionary = Dictionary;
    this.BulkEntryEventContext = BulkEntryEventContext;
    this.DepositBulkEntryEventContext = DepositBulkEntryEventContext;
    this.IsMobile = IsMobile;
    this.StandardDialog = StandardDialog;

    this.farm = SessionCache.farm();

    // イベントの行コピー時に、コピー対象外にするフィールド
    this.excludeKeyMap = {
      'gyugunidou': ['beforeCowGroupId', 'beforeCowGroupName', 'beforePen'],
      'mastitis': ['stopMilkingOfBreastBl', 'stopMilkingOfBreastBr', 'stopMilkingOfBreastFl', 'stopMilkingOfBreastFr'],
      'embryo_recovery': ['embryos', 'masterSpermId'],
      'carcass': ['eliminateDate', 'noEliminateDate']
    };

    // へい死時の除籍理由
    this.EXPELLED_REASON_OF_DEATH = '死亡';

    this.targetHoofMap = [
      {key: 'infectLameDiagnosisResultOfHoof', value: '注意'},
      {key: 'infectLameDiagnosisResultOfHoof', value: '悪い'},
      {key: 'infectLameDiagnosisResultOfHoof', value: '重篤'},
      {key: 'noninfectLameDiagnosisResultOfHoof', value: '注意'},
      {key: 'noninfectLameDiagnosisResultOfHoof', value: '悪い'},
      {key: 'noninfectLameDiagnosisResultOfHoof', value: '重篤'},
      {key: 'arthropathyDiagnosisResultOfHoof', value: '注意'},
      {key: 'arthropathyDiagnosisResultOfHoof', value: '悪い'},
      {key: 'arthropathyDiagnosisResultOfHoof', value: '重篤'}
    ];

    this.breedingDifficultyMap = [
      {key: 'difficultiesOvaryStill', value: '軽'},
      {key: 'difficultiesOvaryStill', value: '中'},
      {key: 'difficultiesOvaryStill', value: '重'},
      {key: 'difficultiesOvaryCystic', value: '軽'},
      {key: 'difficultiesOvaryCystic', value: '中'},
      {key: 'difficultiesOvaryCystic', value: '重'},
      {key: 'difficultiesRemnant', value: '軽'},
      {key: 'difficultiesRemnant', value: '中'},
      {key: 'difficultiesRemnant', value: '重'},
      {key: 'difficultiesMetritis', value: '軽'},
      {key: 'difficultiesMetritis', value: '中'},
      {key: 'difficultiesMetritis', value: '重'},
      {key: 'difficultiesPyometra', value: '軽'},
      {key: 'difficultiesPyometra', value: '中'},
      {key: 'difficultiesPyometra', value: '重'},
    ];

    /**
     * イベント種別とイベント直近発生日の紐づけ用
     */
    this.deseaseOccurredDateFields = {
      mastitis: 'occurredMastitisDate',
      perinatalDifficulty: 'occurredPerinatalDifficultyDate',
      lame: 'occurredLameDate',
      infect: 'occurredInfectDate',
      otherDifficulty: 'occurredOtherDifficultyDate'
    };

    this.error = {};
  }

  clearCommonParams() {
    this.hasSelection = false;
    this.allSelected = false;
    this.shouldShowPasteButton = false;
    this.targetDate = null;
    this.errMessage = '';
    this.bredForEmbryoRecovery = false;
    this.selectedHour = '';
    this.selectedMinute = '';
    this.workerName = '';
    this.moveToCowGroupId = null;
    this.moveToPen = null;
    this.buyer = '';
  }

  init(params, isPasteMode = false) {
    this.eventType = params.eventType;
    this.isDepositor = params.isDepositor === 'true';

    if (this.isDepositor) {
      this.context = this.DepositBulkEntryEventContext;
      this.context.setCurrentFarmId(params.qwert);
    } else {
      this.context = this.BulkEntryEventContext;
    }

    this.qwert = params.qwert;
    this.caller = params.caller || {};
    this.selection = {};

    this.mNow = moment(this.DateTimeUtilFactory.parseClientDate(new Date())); // 現時刻
    this.targetDate = null;
    this.inputDate = DateUtil.today();

    // ハッシュテーブル
    this.penListMap = {}; // 牛房簡易検索用
    this.cowNoMap = {};
    this.cowIdMap = {};
    this.cowUidMap = {};

    this.isPasteMode = isPasteMode;
    if (this.isPasteMode) {
      this.setDefaultUiGrid();
      this.pasteFilterDate = DateUtil.addDays(DateUtil.today(), -1).getTime();
    } else {
      this.uiGrid = null;
    }
    this.canSave = false;
    this.showPasteFilterDate = false;

    this.masters = {};

    return this.onInit(params);
  }

  /**
   * 選択した行へのアクションを実行する
  */
  executeAction(actionType) {
    const selectedEvents = this.cowEvents.filter((cowEvent) => cowEvent.selected === true);

    switch (actionType) {
    case 'deleteSelectedRows':
      this.deleteSelectedRows();
      break;
    case 'copySelectedRows':
      this.copySelectedRows(selectedEvents);
      break;
    case 'pasteSelectedRows':
      this.pasteSelectedRows(selectedEvents);
      break;
    }
  }

  /**
  * イベントコピー時に不要なpropertyを除外する
  * @param {Object} event イベント
  */
  maskCopiedEventProperties(event) {
    event.selected = false;

    const excludeKeys = [
      'cowNo', 'cowUid', 'cowId', 'formattedCowUid', 'gender',
      'currentCowNo', 'currentCowUid', 'continuousOccurredDiseaseDate',
      'beforeCowGroupId', 'beforeCowGroupName', 'beforePen',
    ]
      .concat(event.diseaseContinuation === 'continuous' ? 'occurredDiseaseDate' : [])
      .concat(this.excludeKeyMap[this.eventType] || []);

    Object.keys(event).forEach((key) => {
      if (excludeKeys.some((element) => element === key)) {
        delete event[key];
      }
    });

    return event;
  }

  /**
   * 選択された行を削除する
   */
  deleteSelectedRows() {
    this.cowEvents = this.cowEvents.filter((event) => !event.selected);
    this.updateHasSelection();
  }

  /**
  * 選択された行をコピーする
  * @param {Array} selectedEvents 選択されたイベント
  */
  copySelectedRows(selectedEvents) {
    if (!this.cowEvents) {
      return;
    }

    // 選択項目から同内容の新規データを作成
    this.clipboard = selectedEvents.map((selectedEvent, index) => {
      const newEvent = angular.copy(selectedEvent, {});
      newEvent.index = index;
      selectedEvent.selected = false;
      return newEvent;
    });

    this.shouldShowPasteButton = true;
    this.updateHasSelection();
  }

  /**
   * 選択された行にペーストする
   * @param {Array} selectedEvents 選択されたイベント
   */
  pasteSelectedRows(selectedEvents) {
    if (!this.cowEvents) {
      return;
    }

    selectedEvents.forEach((targetEvent, index) => {
      let clipboardIndex = index === 0 ? 0 : index % this.clipboard.length;
      Object.assign(targetEvent, this.maskCopiedEventProperties(angular.copy(this.clipboard[clipboardIndex], {})));
      this.onChangeDiseaseContinuation(targetEvent, true);
    });

    this.updateHasSelection();
  }

  /**
   * データのない空行を探す
   */
  findBlankRowIndex() {
    let events = this.cowEvents.slice().reverse();
    for (const [index, cowEvent] of events.entries()) {
      if (!this.isBlankEvent(cowEvent)) {
        return this.cowEvents.length - index;
      }
    }
    return -1;
  }

  /**
   * ブランクイベントかどうかチェック
   * - データ入力がされていないものは、propertyが存在しない
   * - 一度データは入力されたが後に削除されたものは、propertyは存在するがデータは undefined, null, '' になる
   */
  isBlankEvent(event) {
    for (let prop in event) {
      if (prop === 'selected') {
        continue;
      }

      if (!([undefined, null, ''].includes(event[prop]))) {
        return false;
      }
    }

    return true;
  }

  updateHasSelection() {
    this.hasSelection = this.cowEvents.find((cowEvent) => cowEvent.selected === true) !== undefined ? true : false;
    if (!this.hasSelection) {
      this.allSelected = false;
    }
  }

  /**
   * 全行選択ハンドラー
   * NOTE: selectedの値のトグルは、テンプレートのng-modelバインディングで行うため、ここでは変更しない
   */
  toggleAllSelection(selected) {
    this.cowEvents
      .filter((cowEvent) => !this.isBlankEvent(cowEvent))
      .forEach((cowEvent) => cowEvent.selected = selected);
    this.updateHasSelection();
  }

  copy1stLine() {
    this.cowEvents
      .filter((targetEvent, index) => {
        return index !== 0 && targetEvent.cowNo ? true : false;
      })
      .forEach((targetEvent) => {
        let sourceEvent = this.maskCopiedEventProperties(angular.copy(this.cowEvents[0], {}));
        Object.assign(targetEvent, sourceEvent);
        this.onChangeDiseaseContinuation(targetEvent, true);
      });
    this.shouldShowQuickActionMenu = false;
  }

  shouldShowCopy1stLineButton() {
    if (!this.eventName
        || !this.cowEvents
        || !this.cowEvents[0]
        || !this.cowEvents[0].cowNo && !this.cowEvents[0].cowUid
        || this.hasSelection) {
      return false;
    }

    for (let i = 1; i < this.cowEvents.length; i++) {
      if (!this.cowEvents[i]) contiune;
      if (this.cowEvents[i].cowNo || this.eventType === 'carcass' && this.cowEvents[i].cowUid) return true;
    }

    return false;
  }

  /**
  * ターゲット日付・時刻を表示するかどうかの判定
  */
  shouldShowTargetDateTime() {
    return this.targetDate ? true : false;
  }

  shouldShowLameDiagnosisDetail(lame) {
    return lame.lameAffectedLimb !== '';
  }

  shouldShowBredForEmbryo() {
    return this.tanetsukeMethod === '人工授精';
  }

  addLame(index) {
    this.cowEvents[index] = this.addLameToEvent(this.cowEvents[index]);
  }

  removeLame(eventIndex, lameIndex) {
    delete this.cowEvents[eventIndex].lames.splice(lameIndex, 1);
  }

  addOtherDiseaseName(eventIndex) {
    this.cowEvents[eventIndex].otherDiseaseNames.push({
      id: 0
    });
  }

  deleteOtherDiseaseName(eventIndex, diseaseIndex) {
    this.cowEvents[eventIndex].otherDiseaseNames.splice(diseaseIndex, 1);
  }

  canAddOtherDiseaseName(eventIndex) {
    const maxLength = Math.min(this.selection.diseaseDefault.length - 1, 5);
    return this.cowEvents[eventIndex].otherDiseaseNames.length < maxLength;
  }

  // 起動時の処理
  onInit(params) {
    if (!params.eventType) return new Promise((resolve) => resolve());

    this.blockUI.start('ロード中');

    this.clearCommonParams();
    this.initialiseValues(params);
    this.inputStartAt = new Date();

    // 必須データ習得
    return this.context.load(this.allowInactiveCow()).then((res) => {
      this.createCowMap(res[0].data);

      // 乳房炎や牛群移動用、initEventValuesに移すべし?
      this.cowGroupList = res[1].data || [];
      this.cowGroupOptions = this.cowGroupList.filter((cg) => cg.menuDisplayed);
      this.cowGroupMap = {};

      this.cowGroupList.forEach((group) => {
        this.penListMap[group.cowGroupId] = (typeof group.pens === 'string') ?
          group.pens.split(',').map((val) => ({label: val, value: val})) : [];
        this.cowGroupMap[group.cowGroupId] = group.cowGroupName;
      });

      this.masters.cowGroups = this.cowGroupList.map((group) => {
        return {
          cowGroupId: group.cowGroupId,
          cowGroupName: group.cowGroupName,
          pens: (group.pens || '').split(','),
        };
      });

      // ドロップダウンのラベルに数字を追加する -> {label: 1.オプション, value：オプション}
      Object.keys(res[3]).forEach((key) => {
        if (key === 'locomotionScore' || this.IsMobile) {
          this.selection[key] = res[3][key].map((option) => ({
            label: option.label,
            value: option.value,
          }));
          return;
        }
        this.selection[key] = res[3][key].map((option, index) => ({
          label: `${index + 1}：${option.label}`,
          value: option.value,
        }));
      });

      if (this.eventType === 'tanetsuke') {
        this.selection.tanetsukeMethod = this.selection.tanetsukeMethod.filter((m) => m.value !== '本交');
        this.tanetsukeMethod = this.selection.tanetsukeMethod.length ? this.selection.tanetsukeMethod[0].value : '人工授精';
        this.bredForEmbryoRecovery = false;

        this.masterEmbryos = res[9].data;
        this.masters.masterEmbryos = CollectionUtil.selectableMasters(res[9].data);

        this.selection['masterEmbryoOptions'] = this.masterEmbryos.map((embryoMaster) => {
          return {
            label: embryoMaster.name,
            masterEmbryoId: embryoMaster.id
          };
        });
      }

      if (this.eventType === 'mastitis') {
        this.selection.mastitisScores = Mastitis.MASTITIS_SCORES;
      }

      if(this.eventType === 'hatsujo') {
        this.selection.plannedBredMethods = ReproductionPlannedEvent.plannedBredMethods;
      }

      // アカウント情報からさらにオプション生成して、selectionに足す
      const userDefinedSelections = res[5].data;

      const pregnantDiagnosisTimings = res[6].data;
      if (pregnantDiagnosisTimings.length > 0) {
        userDefinedSelections.judgePregnantTimings = pregnantDiagnosisTimings.map((t) => t.name).join('\n');
      }

      [
        'inseminators',
        'pregnancyAppraisers',
        'treatmentPersons',
        'vaccineTimings',
        'judgePregnantTimings',
        'buyers',
        'producingAreas',
        'producingFarmNames',
        'otherReproductionWorks'
      ].forEach((optionId) => {
        this.selection[optionId] = this.generateUserDefinedOption(
          userDefinedSelections[optionId],
          true,
          !this.IsMobile
        );

        this.masters[optionId] = (userDefinedSelections[optionId] || '').split(/\n/);
      });

      const operationUsers = res[7].data.map((u) => u.name).join('\n');
      this.selection['workerNames'] = this.generateUserDefinedOption(
        operationUsers,
        true,
        !this.IsMobile
      );

      this.masters.workerNames = res[7].data.map((u) => u.name);

      this.selection.mastitisDiagnosisResult.unshift({label: '', value: ''});
      this.selection.mastitisBacteriaKind.unshift({label: '', value: ''});

      // ホルモンや種付プロミスが要る場合追加する
      const additionalPromises = this.initEventValues(res[2].farmKind, params.cowIds);

      this.farmKind = res[2].farmKind;
      this.farmName = res[2].farmName;

      this.selection['diseaseDefault'] =
        res[4].data.filter((d) => d.visible).map((d, index) => {
          return this.IsMobile
            ? {key: d.id, label: `${d.name}`}
            : {key: d.id, label: `${index + 1}: ${d.name}`};
        }).filter((obj) => obj.key !== '' || obj.label !== '');

      this.shouldShowDiseaseSelection = this.selection.diseaseDefault.length >= 1;
      this.selection.diseaseDefault.unshift({key: 0, label: ''});

      const masterWorkNoteTypes = res[10].data;
      if (masterWorkNoteTypes.length) {
        this.selection['workNoteType'] = this.generateWorkNoteTypeSelection(masterWorkNoteTypes);
        this.showWorkNoteTypeSelection = true;
        const initialSelectedMaster = masterWorkNoteTypes.find((r) => r.initialSelected);
        if (initialSelectedMaster) {
          this.defaultMasterWorkNoteTypeId = initialSelectedMaster.id;
        } else {
          this.defaultMasterWorkNoteTypeId = 0;
        }
      } else {
        this.showWorkNoteTypeSelection = false;
      }

      this.username = this.appConfig.staff.name || res[8].name;

      if (additionalPromises) {
        return additionalPromises;
      }
    }).catch((err) => console.error(err))
      .finally(() => {
        // FIXME: BulkEntryEventsStateをfactoryなどで実装し直す
        this.clearErrors();

        // 牛群一括投稿があるかどうか判断する
        if (params.cowIds.length) {
          this.isPasteMode = false;
          this.cowEvents = params.cowIds.map((cowId) => {
            return this.createEmptyEvent({cowId: cowId});
          });

          // 各行に必要な項目を追加する
          this.cowEvents.forEach((event) => {
            const targetCow = this.cowIdMap[event.cowId];
            if (!targetCow) return;
            event.cowNo = targetCow.cowNo;
            if (this.farm.isBeef()) {
              event.diseaseContinuation = 'new';
            } else {
              event.diseaseContinuation = 'continuous';
            }

            this.settingEventFields(targetCow, event);
          });
          if (this.eventType === 'lame' ) {
            this.cowEvents = this.cowEvents.map((event) => this.addLameToEvent(event));
          }
          if (this.cowEvents.length < 20) this.addRows(20 - this.cowEvents.length);

          this.setLineNumber(this.cowEvents);
          this.updateStateError();

          this.blockUI.stop();

          return;
        }

        // 画面起動時、入力枠を20行設置する
        this.addRows(20);
        this.blockUI.stop();
      });
  }

  setOptions() {
    const context = {
      isMilkFarm: this.farm.isMilk(),
    };
    this.selection = CowEventBulkInputDefinition.options(this.eventType, context);
  }

  /**
   * 起動時に表示に必要な値を準備する
   */
  initialiseValues(params) {
    this.dateLabel = CowEventBulkInputDefinition.dateLabel(this.eventType);

    this.cowType = params.cowType;
    this.cowIds = params.cowIds;
    this.qwert = params.qwert;
    this.eventName = EventType.eventNameForLegacy(this.eventType) || '';
    this.showMoveToCowGroup = CowEvent.isMoveEvent(this.eventType, this.farm.isMilk());
    this.showMoveToPen = false;

    this.isBredEvent = this.eventType === 'tanetsuke';
    this.isCulledEvent = this.eventType === 'touta';
    this.isPregnantDiagnosisEvent = this.eventType === 'ninshinkantei';
    this.isDiedEvent = this.eventType === 'heishi';

    if (this.eventType !== 'carcass') {
      this.targetDate = this.inputDate.getTime();
    }

    this.setOptions();
    this.cowEvents = [];
  }

  /**
   * 種付けイベントの初期処理を行う
   * @return {Promise}
   */
  initTanetsukeValues() {
    // 種付けの受精コードドロッップダウン用
    this.context.loadHormonePrograms().then((res) => {
      const selectableMasters = CollectionUtil.selectableMasters(res.data);
      this.inseminationCodeList = Bred.inseminationCodeOptions(selectableMasters);

      this.masters.inseminationCodes = this.inseminationCodeList.map((e) => e.value);
    });

    return this.context.loadSperms().then((res) => {
      const selectableMasters = CollectionUtil.selectableMasters(res.data);
      this.spermInfoList = selectableMasters.map((sperm, index) => {
        sperm.label = `${index + 1}:${sperm.name} ${sperm.spermNo}`;
        return sperm;
      });

      this.masters.masterSperms = selectableMasters;
    });
  }

  /**
   * ホルモンプログラムの初期処理を行う
   * @return {Promise}
   */
  initHormoneProgramValues(cowIds) {
    // プログラム名のオプション
    this.hormonePrograms = [{label: '適用なし', value: 0}];

    // ホルモンコードのハッシュテーブル
    // directiveで使うため、$scopeに載せた
    this.hormoneCodesMap = {};

    return this.context.loadHormonePrograms().then((res) => {
      const selectableMasters = CollectionUtil.selectableMasters(res.data);
      selectableMasters.forEach((master, index) => {
        // プログラム名オプション生成
        this.hormonePrograms.push({
          label: `${index + 1}: ${master.name}`,
          value: master.id,
        });

        // ホルモンコードオプション生成
        this.hormoneCodesMap[master.id] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
          .reduce((arr, value) => {
            if (!master[`hormoneCode${value}`]) {
              return arr;
            }
            arr.push({
              label: master[`hormoneName${value}`],
              value: master[`hormoneCode${value}`],
              period: master[`hormonePeriod${value}`],
            });
            return arr;
          }, []);
      });

      if (cowIds) {
        const cows = cowIds
          .map((cowId) => this.cowIdMap[cowId])
          .filter((cow) => cow !== undefined);

        const fetchApis = [];
        cows.forEach((cow) => {
          fetchApis.push(this.context.findEventByCow(cow.cowId, 'hormoneProgram'));
        });

        return Promise.all(fetchApis).then((results) => {
          results.forEach((res, index) => {
            cows[index].lastTimedAiEvent = CowEvent.latest(res.data);
          });
        }).catch((e)=>{
          console.error(`BulkEntryEventsState.initHormoneProgramValues()`);
          console.error(e);
        });
      }
    });
  }

  initOtherDifficultyValues(cowIds) {
    const cows = cowIds
      .map((cowId) => this.cowIdMap[cowId])
      .filter((cow) => cow !== undefined);

    const fetchApis = [];
    cows.forEach((cow) => {
      fetchApis.push(this.context.findEventByCow(cow.cowId, 'otherDifficulty'));
    });

    return Promise.all(fetchApis).then((results) => {
      results.forEach((res, index) => {
        const latest = CowEvent.latest(res.data);
        if (latest) {
          const fieldName = this.deseaseOccurredDateFields['otherDifficulty'];
          cows[index][fieldName] = latest['occurredDiseaseDate'];
        }
      });
    }).catch((e)=>{
      console.error(`BulkEntryEventsState.initOtherDifficultyValues()`);
      console.error(e);
    });
  }

  /**
   * イベントに必要なデータや値を設定
   * @param {string} farmKind 乳用 || 肉用
   * @param {Array} cowIds 初期化処理を行う牛のcow_id
   * @return {Promise || void} ホルモンや種付の場合、プロミスを返却する
   */
  initEventValues(farmKind, cowIds) {
    if (this.shouldShowTimeField(this.eventType)) {
      this.hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
      this.minutes = ['00', '10', '20', '30', '40', '50'];
    }

    if (this.eventType === 'heishi') {
      // へい死時は除籍理由を死亡に固定する
      this.expelledReason = this.EXPELLED_REASON_OF_DEATH;
    } else if (this.eventType === 'hormoneProgram') {
      return this.initHormoneProgramValues(cowIds);
    } else if (this.eventType === 'tanetsuke') {
      return this.initTanetsukeValues();
    } else if (this.eventType === 'gyugunidou') {
      this.penList = []; // 牛群移動イベント用の牛房選択リスト
    } else if (this.eventType === 'carcass') {
      this.carcassSelection = Carcass.generateCarcassOptions();
    } else if (this.eventType === 'embryo_recovery') {
      return this.initHormoneProgramValues(cowIds).then(() => {
        return this.initTanetsukeValues();
      });
    } else if (this.eventType === 'touta') {
      this.existsProducingAreas = this.selection.producingAreas.length > 0;
      this.existsProducingFarmNames = this.selection.producingFarmNames.length > 0;
      if (!this.IsMobile) {
        this.addUserEntryValueToProducing(this.selection, this.cowIdMap);
      }
    } else if (this.eventType === 'otherDifficulty') {
      if (cowIds && cowIds.length) {
        return this.initOtherDifficultyValues(cowIds);
      }
    }
  }

  /* イベントゾーン */

  /**
   * 入力列をn行足す
   * @param {number} number 足す行数
   */
  addRows(number) {
    this.errMessage = '';
    for (let i = 0; i < Number(number); i++) {
      const event = this.createEmptyEvent();
      event.eventType = this.eventType;

      this.cowEvents.push(this.eventType === 'lame' ? this.addLameToEvent(event) : event);
    }

    this.setLineNumber(this.cowEvents);
  }

  createEmptyEvent(params = {}) {
    const values = {
      selected: false,
      diseaseContinuation: this.farm.isBeef() ? 'new' : 'continuous',
      carcassDefectAction: '瑕疵を入力',
      canAddCarcassDefect: true,
    };
    Object.assign(values, params);
    const cowEvent = LegacyEventType.createEvent(this.eventType, values);

    if (this.eventType === 'embryo_recovery') {
      cowEvent.embryos = angular.copy(this.embryos);
    }

    if (this.eventType === 'touta') {
      cowEvent.expelledReason = this.farm.isDairy() || this.farm.isFattening() ? '肉用出荷' : '素牛出荷';
    }

    if (this.eventType === 'mastitis') {
      cowEvent.mastitisDiagnosisResultOfBreastBl = '';
      cowEvent.mastitisDiagnosisResultOfBreastBr = '';
      cowEvent.mastitisDiagnosisResultOfBreastFl = '';
      cowEvent.mastitisDiagnosisResultOfBreastFr = '';
      cowEvent.mastitisBacteriaOfBreastBl = '';
      cowEvent.mastitisBacteriaOfBreastBr = '';
      cowEvent.mastitisBacteriaOfBreastFl = '';
      cowEvent.mastitisBacteriaOfBreastFr = '';
    }

    if (this.eventType === 'otherDifficulty' && this.shouldShowDiseaseSelection) {
      cowEvent.otherDiseaseNames = [{id: 0}];
    }

    if (this.eventType === 'sagyomemo' && this.showWorkNoteTypeSelection) {
      cowEvent.masterWorkNoteTypeId = this.defaultMasterWorkNoteTypeId;
    }

    if (this.eventType === 'tanetsuke') {
      cowEvent.embryoType = '体内受精卵';
    }

    cowEvent.errors = this.createEmptyError();

    return cowEvent;
  }

  /**
   * @param {Ojbect} event イベント
   * @returns {Object} lamesフィールドを追加したイベント
   */
  addLameToEvent(event) {
    const decorated = angular.copy(event);
    if (!decorated.lames) decorated.lames = [];
    if (decorated.lames.length >= 4) return event;

    decorated.lames.push({
      lameAffectedPart: '蹄',
      lameAffectedLimb: '',
      lameAffectedPartMedial: false,
      lameAffectedPartLateral: false,
      lameCondition: ''
    });

    return decorated;
  }

  onChangeDiseaseContinuation(record, isCopy) {
    if (record.diseaseContinuation === 'new' && !isCopy) {
      record.occurredDiseaseDate = this.inputDate.getTime();
      if (this.eventType === 'mastitis') {
        const cow = this.cowIdMap[event.cowId];
        if (cow) {
          record.mastitisCowGroupId = cow.cowGroupId;
        }
      }
    } else if (record.diseaseContinuation === 'continuous') {
      if (record.continuousOccurredDiseaseDate) {
        record.occurredDiseaseDate = record.continuousOccurredDiseaseDate;
        if (this.eventType === 'mastitis') {
          record.mastitisCowGroupId = record.continuousMastitisCowGroupId;
        }
      }
    }
  }

  onChangeVisceralDestruction(record) {
    if (record.selectedVisceralDestruction.value.includes('全廃棄')) {
      record.selectedVisceralDestruction.value = ['全廃棄'];
    }
  }

  onClickCarcassDefectAdd(record) {
    record.addDefect();
    this.updateCarcassDefectAction(record);
  }

  onClickCarcassDefectRemove(record, index) {
    record.removeDefect(index);
    this.updateCarcassDefectAction(record);
  }

  onClickCarcassDefectPartAdd(record, index) {
    record.addDefectPart(index);
  }

  onClickCarcassDefectPartRemove(record, index, partIndex) {
    record.removeDefectPart(index, partIndex);
  }

  onChangeEmbryoRecoveryRank(record) {
    record.canMasterEmbryoRegistration = EmbryoRecovery.isAnyRankCounts(record);
    if (!record.canMasterEmbryoRegistration) record.embryoMasterRegistered = false;
  }

  validateOnChange(row) {
    if (row.cowId && row.cowState) {
      row.occurredAt = DateUtil.toMSec(this.targetDate);
      const rows = [row];
      this.updateStateError(rows);
    }
  }

  updateCarcassDefectAction(record) {
    if (record.carcassDefects.length >= 1) {
      record.carcassDefectAction = '瑕疵を追加';
    } else {
      record.carcassDefectAction = '瑕疵を入力';
    }
    record.canAddCarcassDefect = record.canAddDefect();
  }

  pasteModeOn() {
    this.isPasteMode = true;
    this.canSave = false;
    this.setDefaultUiGrid();
  }

  pasteModeOff() {
    this.isPasteMode = false;
    this.cowEvents = [];
    this.addRows(20);
  }

  pasteFromClipboard(input) {
    const handler = new CowEventClipboardHandler(this.StandardDialog);
    const result = handler.parse(this.eventType, input);
    if (!result) {
      this.uiGrid = null;
      this.showPasteFilterDate = false;
      return;
    }

    const rows = result.rows;
    const items = result.items;

    const promises = [];
    rows.forEach((row) => {
      row.errors = this.createEmptyError();

      if (row.cowNo) {
        promises.push(
          this.setRelatedFields(row)
        );
      }
    });

    this.$q.all(promises).then(() => {
      const cowEvents = CowEventClipboardHandler.rowToCowEvent(
        this.eventType, rows, this.masters
      );

      const result = this.validateForClipboard(cowEvents);
      this.setValidateErrorToRow(rows, result.errors);
      this.canSave = result.valid;
      this.showPasteFilterDate = rows.length > 0;

      this.setUiGrid(items, rows);
      this.pasteItems = items;
      this.pasteRows = rows;
      this.cowEvents = cowEvents;

      if (!result.valid) {
        this.StandardDialog.showMessage({
          title: 'エラー',
          text1: '入力した内容に入力漏れ・誤りがあります。',
          text2: '修正を行ってから再度貼り付けを実行してください。',
        });
      }
    });
  }

  filterPasteRow() {
    const removeIndexes = [];
    this.cowEvents.forEach((e, index) => {
      if (!e.occurredAt) {
        removeIndexes.push(index);
        return;
      }
      if (DateUtil.isBeforeDay(e.occurredAt, this.pasteFilterDate)) {
        removeIndexes.push(index);
      }
    });

    this.pasteRows = this.pasteRows.filter((r, index) => {
      return !removeIndexes.includes(index);
    });
    this.cowEvents = this.cowEvents.filter((e, index) => {
      return !removeIndexes.includes(index);
    });
    this.uiGrid.data = this.pasteRows;

    if (this.cowEvents.length === 0) {
      this.canSave = false;

      this.StandardDialog.showMessage({
        title: 'エラー',
        text1: '登録対象となる日付のイベントデータが含まれていません。',
        text2: 'イベントデータの日付を確認してから再度貼付を行ってください。',
      });
      return;
    }

    const result = this.validateForClipboard(this.cowEvents);
    this.canSave = result.valid;
  }

  pasteSample() {
    return CowEventBulkInputDefinition.pasteSample(this.eventType);
  }

  setRelatedFields(row) {
    const cows = this.cowNoMap[row.cowNo] || [];
    if (cows.length === 0) {
      this.initializeCowEventProperties(row);
      return this.fakePromise();
    }

    return this.selectTargetCow(cows).then((cow) => {
      this.setCowFieldToRow(row, cow);
    });
  }

  setCowFieldToRow(row, cow) {
    row.cowId = cow.cowId;
    row.cowUid = cow.cowUid;
    row.gender = cow.gender;
    row.cowState = cow.state;
    row.latestFertilizationDate = cow.latestFertilizationDate;
    row.latestBreedingMethod = cow.latestBreedingMethod;
    row.latestPregnancyDate = cow.latestPregnancyDate;
    row.latestCalvingDate = cow.latestCalvingDate;

    if (this.showMoveToCowGroup) {
      const cowGroup = this.cowGroupList.find((g) => g.cowGroupId === cow.cowGroupId);
      if (cowGroup) {
        row.beforePen = cow.pen;
        row.beforeCowGroupId = cow.cowGroupId;
        row.beforeCowGroupName = cowGroup.cowGroupName;
      }
    }
  }

  fakePromise() {
    return new Promise((resolve) => resolve());
  }

  setDefaultUiGrid() {
    const handler = new CowEventClipboardHandler(this.StandardDialog);
    const sample = handler.sampleItems(this.eventType);
    this.setUiGrid(sample.items, sample.rows);
  }

  setUiGrid(items, rows) {
    const columns = items.map((item) => {
      return {
        columnId: item.id,
        label: item.actualLabel,
        width: item.width || 150,
      };
    });

    const cellTemplates = {
      // 牛番号
      cowNo: `
        <span ng-bind="row.entity.cowNo"></span>

        <p ng-if="row.entity.errors.message.key" class="error-message">
          <span ng-bind="row.entity.errors.message.key"></span>
        </p>

        <p ng-if="row.entity.errors.message.state" class="error-message">
          <span ng-bind-html="row.entity.errors.message.state"></span>
        </p>

        <p ng-if="row.entity.errors.warning.state" class="warning-message">
          <span ng-bind="row.entity.errors.warning.state"></span>
        </p>

        <p ng-if="row.entity.errors.warning.pregnantDays" class="warning-message">
          <span ng-bind="row.entity.errors.warning.pregnantDays"></span>
        </p>
      `,
      // 作業日
      occurredAt: `
        <span ng-bind="row.entity.occurredAt"></span>

        <p ng-if="row.entity.errors.message.date" class="error-message">
          <span ng-bind="row.entity.errors.message.date"></span>
        </p>
      `,
    };

    const generateSimpleCellTemplate = (field) => {
      return `
        <span ng-bind="row.entity.${field}"></span>

        <p ng-if="row.entity.errors.message.${field}" class="error-message">
          <span ng-bind="row.entity.errors.message.${field}"></span>
        </p>
      `;
    };

    [
      'workerName',
      // 牛群移動
      'moveToCowGroupId',
      'moveToPen',
      // 種付
      'tanetsukeMethod',
      'masterSpermNo',
      'inseminationCode',
      'otherReproductionWork',
      'bredForEmbryoRecovery',
      'masterEmbryoName',
      // 妊娠鑑定
      'ninshinkanteiResult',
      'judgePregnantTiming',
    ].forEach((item) => {
      cellTemplates[item] = generateSimpleCellTemplate(item);
    });

    const options = {cellTemplates: cellTemplates};

    this.uiGrid = new UiGridGenerator().generate(this, columns, options);
    this.uiGrid.data = rows;
  }

  bulkEntry() {
    this.selectedHour = moment().format('HH');
    this.selectedMinute = moment().format('mm').substr(0, 1) + '0';

    this.blockUI.start('登録中');

    this.setLineNumber(this.cowEvents);
    const records = this.removeEmptyRow(this.cowEvents, this.eventType);

    records.forEach((r) => {
      r.eventType = this.eventType;

      const result = this.calculateOccurredAt(this.eventType, this.targetDate, r);
      r.occurredAt = result.occurredAt;
      if (typeof result.treatmentDiseaseDate !== 'undefined') {
        r.treatmentDiseaseDate = result.treatmentDiseaseDate;
      }
    });

    const validateResult = this.validate(records);
    this.error = validateResult.headerError;
    this.setValidateErrorToRow(this.cowEvents, validateResult.errors);

    if (!validateResult.valid) {
      const errors = validateResult.errors;
      if (this.IsMobile) {
        errors.forEach((e) => {
          const cowEvent = this.cowEvents.find((cowEvent) => {
            return cowEvent.lineNo === e.lineNo;
          });
          e.cowEvent = cowEvent;
        });
      }

      return new Promise((resolve, reject) => {
        reject({
          status: {
            error: true
          },
          errors: errors
        });
      });
    }

    const inputOption = {
      eventName: this.eventName,
      masterEmbryos: this.masterEmbryos,
      isMobile: this.IsMobile,
      headerInput: {
        moveToCowGroupId: this.moveToCowGroupId,
        moveToPen: this.moveToPen,
        tanetsukeMethod: this.tanetsukeMethod,
        bredForEmbryoRecovery: this.bredForEmbryoRecovery,
        workerName: this.workerName,
        buyer: this.buyer,
        expelledReason: this.expelledReason,
      },
    };
    const cowEvents = records.map((record) => {
      if (this.eventType === 'carcass') {
        record.setRoundedDecimal();
      }

      const cowEvent = this.inputToModel(this.eventType, record, inputOption);

      cowEvent.eventType = this.eventType;
      cowEvent.eventName = this.eventName;
      cowEvent.occurredAt = record.occurredAt;
      cowEvent.cowId = record.cowId;
      cowEvent.entryStaffName = this.appConfig.staff.name;
      cowEvent.lastEditStaffName = this.appConfig.staff.name;
      cowEvent.comment = record.comment;

      return cowEvent;
    });

    if (cowEvents.length === 0) {
      this.blockUI.stop();
      return new Promise((resolve, reject) => reject());
    }

    // 治療日 treatmentDiseaseDate と 発生日（イベント種類によって呼び名は異なる）occurredAt を this.cowEventsにセットする。
    cowEvents.forEach((cowEvent) => {
      if (cowEvent.treatmentDiseaseDate || cowEvent.occurredAt) {
        this.cowEvents.forEach((cE, index) => {
          if (cowEvent.cowId === cE.cowId) {
            this.cowEvents[index].treatmentDiseaseDate =
              (cowEvent.treatmentDiseaseDate) ?
                cowEvent.treatmentDiseaseDate : null;
            this.cowEvents[index].occurredAt = (cowEvent.occurredAt) ? cowEvent.occurredAt : null;
          }
        });
      }
    });

    // 一括登録実行
    if (this.eventType === 'embryo_recovery') {
      const noEgeCowNos = records.map((e) => e.canMasterEmbryoRegistration ? null : e.cowNo).filter(Boolean);
      if (noEgeCowNos.length) {
        this.blockUI.stop();
        return this.confirmEmbryoRecoveryRegistraion(noEgeCowNos).result.then((res) => {
          if (res) {
            this.blockUI.start('登録中');
            return this.insertInBulk(cowEvents);
          } else {
            return true;
          }
        });
      } else {
        return this.insertInBulk(cowEvents);
      }
    }

    return this.insertInBulk(cowEvents)
      .catch((e) => {
        // メッセージポップアップ通知して処理を終了
        this.modalDialogFactory.showAlertModal({
          title: 'エラー',
          text1: 'エラーが発生したため、登録できませんでした。',
          text2: 'もう一度操作を行ってください。',
          no: false, yes: false
        });
        this.blockUI.stop();
      });
  }

  saveFromClipboard() {
    this.cowEvents.forEach((cowEvent) => {
      cowEvent.eventType = this.eventType;
      cowEvent.eventName = this.eventName;
      cowEvent.entryStaffName = this.appConfig.staff.name;
      cowEvent.lastEditStaffName = this.appConfig.staff.name;
    });

    this.blockUI.start('登録中');

    let saveAction = (cowEvents) => {
      return this.insertInBulk(cowEvents);
    };
    if (this.eventType === 'ninshinkantei') {
      saveAction = (cowEvents) => {
        return this.insertInBulk(cowEvents);
      };
    }

    saveAction(this.cowEvents).then(() => {
      this.canSave = false;
      this.showPasteFilterDate = false;
      this.blockUI.stop();

      this.modalDialogFactory.showSuccessiveAlertModal({
        title: '完了',
        text1: `${this.eventName}の一括イベント登録を完了しました。`,
        text2: '',
      });
    });
  }

  setLineNumber(rows) {
    rows.forEach((row, index) => {
      row.lineNo = index + 1;
    });
  }

  removeEmptyRow(rows, eventType) {
    const items = CowEventBulkInputDefinition.inputItems(eventType);

    return rows.filter((row) => {
      return items.some((item) => {
        const value = row[item];

        if (eventType === 'touta' && item === 'expelledReason' && typeof row.cowNo === 'undefined') {
          return false;
        }

        if (eventType === 'tanetsuke' && item === 'embryoType' && !row.cowNo) {
          return false;
        }

        if (Array.isArray(value)) {
          if (eventType === 'embryo_recovery') {
            return value.some((item) => item.count);
          }
          return value.length >= 1;
        }

        const isEmpty = value === null || value === undefined || value === '' || value === 0;
        return !isEmpty;
      });
    });
  }

  inputToModel(eventType, input, option) {
    const cowEvent = {};

    if (option.headerInput.moveToCowGroupId > 0) {
      cowEvent.moveToCowGroupId = option.headerInput.moveToCowGroupId;
      cowEvent.moveToPen = option.headerInput.moveToPen;
      cowEvent.beforeCowGroupId = input.beforeCowGroupId;
      cowEvent.beforePen = input.beforePen;
    }

    if (EventType.isTreatmentForLegacy(eventType)) {
      cowEvent.occurredDiseaseDate = input.occurredDiseaseDate;
      cowEvent.treatmentDiseaseDate = input.treatmentDiseaseDate;
      cowEvent.examinationTreatmentKind = option.eventName;
      cowEvent.workerName = input.workerName;
      cowEvent.bodyTemperature = input.bodyTemperature;

      if (input.treatmentDiseaseDate && input.selectedMedicines) {
        const medication = CowEvent.summarizeMedication(
          input.selectedMedicines,
          input.treatmentDiseaseDate
        );
        [
          'masterMedicineIds', 'medicineCapacities', 'medicineMethods',
          'maxMilkMedicineName', 'maxBeefMedicineName',
          'endDateOfMilkWashoutPeriod', 'endDateOfBeefWashoutPeriod'
        ].forEach((f) => {
          cowEvent[f] = medication[f];
        });
      }
    }

    switch (eventType) {
    // 乳房炎
    case 'mastitis': {
      [
        'mastitisCowGroupId', 'mastitisScore',
        'mastitisDiagnosisResultOfBreastFl', 'mastitisBacteriaOfBreastFl',
        'mastitisDiagnosisResultOfBreastFr', 'mastitisBacteriaOfBreastFr',
        'mastitisDiagnosisResultOfBreastBl', 'mastitisBacteriaOfBreastBl',
        'mastitisDiagnosisResultOfBreastBr', 'mastitisBacteriaOfBreastBr',
        'stopMilkingOfBreastFl', 'stopMilkingOfBreastFr',
        'stopMilkingOfBreastBl', 'stopMilkingOfBreastBr'
      ].forEach((f) => {
        cowEvent[f] = input[f];
      });
      return cowEvent;
    }
    // 周産病・代謝病
    case 'perinatalDifficulty': {
      if (option.isMobile) {
        [
          'difficultiesKetosis',
          'difficultiesFattyLiver',
          'difficultiesAbomasumDisplacement',
          'difficultiesHypocalcium',
          'difficultiesAcidosis',
          'difficultiesMetritis',
          'difficultiesDowner',
          'difficultiesStagnantFood',
          'difficultiesPlacenta',
          'difficultiesTympanites',
          'difficultiesVitaminA',
          'difficultiesUrolithiasis'
        ].forEach((field) => {
          if (field in input) {
            cowEvent[field] = input[field];
          }
        });
      } else {
        if (input.severity) {
          input.condition.forEach((option) => {
            cowEvent[option.key] = input.severity;
          });
        }
      }
      return cowEvent;
    }
    // 跛行・蹄病
    case 'lame': {
      if (input.locomotionScore === '' || input.locomotionScore === '未選択') {
        cowEvent.locomotionScore = null;
      } else {
        cowEvent.locomotionScore = input.locomotionScore || null;
      }

      if (input.lames) {
        [1, 2, 3, 4].forEach((index) => {
          const lame = input.lames[index - 1];
          if (!lame) return;
          if (!lame.lameAffectedLimb) return;

          cowEvent['lameAffectedLimb' + index] = lame.lameAffectedLimb;

          if (lame.lameAffectedPart) {
            cowEvent['lameAffectedPart' + index] = lame.lameAffectedPart;
          }

          const lameClawZoneValues = lame.lameAffectedPartMedial ? ['内'] : [];
          if (lame.lameAffectedPartLateral === true) {
            lameClawZoneValues.push('外');
          }
          cowEvent['lameClawZones' + index] = lameClawZoneValues.join(',');

          cowEvent['lameCondition' + index] = lame.lameCondition;
          cowEvent['clawDiseaseName' + index] = lame.clawDiseaseName || null;
        });
      }
      return cowEvent;
    }
    // 感染症
    case 'infect': {
      cowEvent.difficultiesDiarrhea = input.difficultiesDiarrhea;
      cowEvent.difficultiesPneumonia = input.difficultiesPneumonia;
      cowEvent.difficultiesSkinDisease = input.difficultiesSkinDisease;
      if (input.otherDiseaseName) {
        cowEvent.otherDiseaseName = input.otherDiseaseName;
        cowEvent.difficultiesOther = input.difficultiesOther;
      }
      return cowEvent;
    }
    // その他
    case 'otherDifficulty': {
      if (this.shouldShowDiseaseSelection) {
        const otherDiseaseNames = input.otherDiseaseNames
          .filter((input) => input.id !== 0)
          .map((input) => {
            const disease = this.selection.diseaseDefault
              .find((option) => option.key === input.id) || {};

            let name = null;
            if (this.IsMobile) {
              name = disease.label;
            } else {
              name = disease.label.split(': ')[1];
            }

            if (input.id > 0) {
              return {id: input.id, name: name};
            } else {
              return {name: name};
            }
          });
        cowEvent.otherDiseaseNames = otherDiseaseNames;
      } else {
        if (input.otherDiseaseName) {
          cowEvent.otherDiseaseNames = [
            {name: input.otherDiseaseName}
          ];
          cowEvent.difficultiesOther = input.difficultiesOther;
        } else {
          cowEvent.otherDiseaseNames = [];
        }
      }

      return cowEvent;
    }
    // フレッシュチェック
    case 'freshCheck': {
      cowEvent.diagnosisResultOfOvaryL = input.diagnosisResultOfOvaryL;
      cowEvent.diagnosisResultOfOvaryR = input.diagnosisResultOfOvaryR;
      cowEvent.uterusDiagnosisResult = input.uterusDiagnosisResult;
      cowEvent.bcs = input.bcs;
      cowEvent.otherReproductionWork = input.otherReproductionWork;
      cowEvent.freshCheckResult = input.freshCheckResult;
      return cowEvent;
    }
    // 種付
    case 'tanetsuke': {
      cowEvent.workerName = input.workerName;
      cowEvent.inseminationCode = input.inseminationCode;
      cowEvent.otherReproductionWork = input.otherReproductionWork;
      cowEvent.tanetsukeMethod = option.headerInput.tanetsukeMethod;

      switch (option.headerInput.tanetsukeMethod) {
      case '人工授精': {
        cowEvent.masterSpermId = input.masterSpermId || 0;
        cowEvent.bredForEmbryoRecovery = option.headerInput.bredForEmbryoRecovery;
        break;
      }
      case '移植': {
        const embryo = option.masterEmbryos.find((e) => e.id === input.masterEmbryoId);
        if (embryo) {
          EventState.setEmbryo(cowEvent, embryo);
        }
        cowEvent.bredForEmbryoRecovery = false;
        cowEvent.embryoType = input.embryoType;
        cowEvent.embryoCertificateNo = input.embryoCertificateNo;
        break;
      }
      }

      return cowEvent;
    }
    // 妊娠鑑定
    case 'ninshinkantei': {
      cowEvent.workerName = option.headerInput.workerName;
      cowEvent.ninshinkanteiResult = input.ninshinkanteiResult;
      cowEvent.judgePregnantTiming = input.judgePregnantTiming;
      cowEvent.otherReproductionWork = input.otherReproductionWork;

      return cowEvent;
    }
    // ホルモンプログラム
    case 'hormoneProgram': {
      cowEvent.masterHormoneProgramId = input.masterHormoneProgramId || 0;
      if (input.selectedHormoneCode) {
        cowEvent.hormoneCode = input.selectedHormoneCode.value;
      }
      return cowEvent;
    }
    // 採卵
    case 'embryo_recovery': {
      Object.assign(cowEvent, EmbryoRecovery.createRequestParams(input));
      return cowEvent;
    }
    // 乾乳
    case 'kannyu': {
      cowEvent.selectedDryPeriod = input.selectedDryPeriod;
      cowEvent.bcs = input.bcs;
      return cowEvent;
    }
    // ワクチン
    case 'vaccine': {
      if (input.selectedMedicines) {
        const medication = CowEvent.summarizeMedication(
          input.selectedMedicines,
          input.occurredAt
        );

        [
          'masterMedicineIds', 'medicineCapacities', 'medicineMethods',
          'maxMilkMedicineName', 'maxBeefMedicineName',
          'endDateOfMilkWashoutPeriod', 'endDateOfBeefWashoutPeriod'
        ].forEach((f) => {
          cowEvent[f] = medication[f];
        });
      }

      cowEvent.workerName = input.workerName;
      cowEvent.vaccineTiming = input.vaccineTiming;
      return cowEvent;
    }
    // 削蹄
    case 'hoofTrim': {
      cowEvent.workerName = input.workerName;
      return cowEvent;
    }
    // 牛群移動
    case 'gyugunidou': {
      cowEvent.workerName = input.workerName;
      return cowEvent;
    }
    // 作業メモ
    case 'sagyomemo': {
      cowEvent.taioTitle = input.taioTitle;
      if (this.showWorkNoteTypeSelection) {
        cowEvent.masterWorkNoteTypeId = input.masterWorkNoteTypeId;
      }
      return cowEvent;
    }
    // 出荷
    case 'touta': {
      cowEvent.expelledReason = input.expelledReason;
      cowEvent.producingArea = input.producingArea;
      cowEvent.producingFarmName = input.producingFarmName;
      cowEvent.shipmentNo = input.shipmentNo;
      cowEvent.salesPrice = input.salesPrice;
      cowEvent.buyer = option.headerInput.buyer;
      return cowEvent;
    }
    // へい死
    case 'heishi': {
      cowEvent.expelledReason = option.headerInput.expelledReason;
      cowEvent.deathReason = input.deathReason;
      cowEvent.deadProcessCost = 0;
      return cowEvent;
    }
    // 除角
    case 'dehorn': {
      cowEvent.dehornMethod = input.dehornMethod;
      cowEvent.workerName = input.workerName;
      return cowEvent;
    }
    // 去勢
    case 'castration': {
      cowEvent.castratMethod = input.castratMethod;
      return cowEvent;
    }
    // 枝肉成績
    case 'carcass': {
      cowEvent.slaughterNo = input.slaughterNo;
      cowEvent.yieldGrade = input.yieldGrade;
      cowEvent.meetGrade = input.meetGrade;
      cowEvent.grade = `${input.yieldGrade}${input.meetGrade}`;
      cowEvent.beforeSlaughterWeight = input.beforeSlaughterWeight;
      cowEvent.dressedCarcassWeightOfL = input.dressedCarcassWeightOfL;
      cowEvent.dressedCarcassWeightOfR = input.dressedCarcassWeightOfR;
      cowEvent.totalDressedCarcassWeight = input.totalDressedCarcassWeight;
      cowEvent.loinArea = input.loinArea;
      cowEvent.ribsThickness = input.ribsThickness;
      cowEvent.subcutaneousFat = input.subcutaneousFat;
      cowEvent.yieldBaseValue = input.yieldBaseValue;
      cowEvent.bmsNo = input.bmsNo;
      cowEvent.marblingGrade = input.marblingGrade;
      cowEvent.bcsNo = input.bcsNo;
      cowEvent.gloss = input.gloss;
      cowEvent.bcsAndGlossGrade = input.bcsAndGlossGrade;
      cowEvent.tight = input.tight;
      cowEvent.texture = input.texture;
      cowEvent.tightAndTextureGrade = input.tightAndTextureGrade;
      cowEvent.bfsNo = input.bfsNo;
      cowEvent.fatLuster = input.fatLuster;
      cowEvent.bfsAndFatLusterGrade = input.bfsAndFatLusterGrade;
      cowEvent.carcassDefects = input.carcassDefects;
      cowEvent.otherFaultCorrection = input.otherFaultCorrection;
      cowEvent.dressedCarcassUnitPrice = this.removeComma(input.dressedCarcassUnitPrice);
      cowEvent.dressedCarcassSalesPrice = this.removeComma(input.dressedCarcassSalesPrice);
      cowEvent.visceralDestruction = input.selectedVisceralDestruction ?
        input.selectedVisceralDestruction.value.join(':') : [];
      return cowEvent;
    }
    //発情
    case 'hatsujo': {
      cowEvent.plannedBredMethod = input.plannedBredMethod;

      if (input.plannedBredAt) {
        const date = DateUtil.startOfDay(Number(input.plannedBredAt));
        const hour = Number(input.plannedBredAtHour);
        const minute = Number(input.plannedBredAtMinute);

        cowEvent.plannedBredAt = DateUtil.addTimeToDate(
          date,
          hour,
          minute
        );
      } else {
        cowEvent.plannedBredAt = '';
      }
    }
    }
    return cowEvent;
  }

  calculateOccurredAt(eventType, inputDate, row) {
    if (EventType.isTreatmentForLegacy(eventType)) {
      // FIXME: cow_event.treatment_disease_date をセットする仕様を見直す
      if (inputDate) {
        const occurredAt = this.calculateTimeAdustedOccurredAt(inputDate, row);
        return {
          occurredAt: occurredAt,
          treatmentDiseaseDate: occurredAt
        };
      } else {
        return {
          occurredAt: row.occurredDiseaseDate,
          treatmentDiseaseDate: null
        };
      }
    } else if (this.shouldShowTimeField(eventType)) {
      return {
        occurredAt: this.calculateTimeAdustedOccurredAt(inputDate, row)
      };
    } else {
      return {
        occurredAt: DateUtil.toMSec(inputDate)
      };
    }
  }

  shouldShowTimeField(eventType) {
    return [
      'hatsujo',
      'tanetsuke',
      'hormoneProgram',
      'mastitis',
      'perinatalDifficulty',
      'lame',
      'infect',
      'otherDifficulty',
      'sagyomemo',
    ].includes(eventType);
  }

  calculateTimeAdustedOccurredAt(date, row) {
    if (!date) {
      return null;
    }

    const hour = Number(row.selectedHour ? row.selectedHour : 0);
    const minute = Number(row.selectedMinute ? row.selectedMinute : 0);
    return DateUtil.addTimeToDate(date, hour, minute);
  }

  validate(rows) {
    let valid = true;

    const validator = new CowEventValidator();
    const headerError = {};

    const dateResult = validator.validateDate(this.targetDate);
    if (!dateResult.valid) {
      const ignoreError = dateResult.type === CowEventValidator.ERROR_TYPE_EMPTY
        && EventType.isTreatmentForLegacy(this.eventType);
      if (!ignoreError) {
        headerError.date = dateResult.message;
        valid = false;
      }
    }

    if (this.eventType === 'gyugunidou') {
      if (!this.moveToCowGroupId) {
        headerError.cowGroupId = '必須項目です';
        valid = false;
      }
    }

    let keyField = 'cowNo';
    let keyLabel = this.Dictionary.COW.COW_NO;
    if (this.eventType === 'carcass') {
      keyField = 'cowUid';
      keyLabel = '番号';
    }

    const errors = [];
    CollectionUtil.mergeList(
      errors, this.validateKey(rows, keyField, keyLabel)
    );

    CollectionUtil.mergeList(
      errors, this.validateDuplicatedCow(rows, keyLabel)
    );

    CollectionUtil.mergeList(
      errors, this.validateFields(rows)
    );

    CollectionUtil.mergeList(
      errors, this.validateGender(this.eventType, rows)
    );

    CollectionUtil.mergeList(
      errors, this.validateState(this.eventType, rows)
    );

    if (EventType.isTreatmentForLegacy(this.eventType)) {
      CollectionUtil.mergeList(
        errors, this.validateTreatment(rows)
      );
    }

    if (this.eventType === 'perinatalDifficulty') {
      CollectionUtil.mergeList(
        errors, this.validatePerinatalDifficulty(rows)
      );
    }

    if (this.eventType === 'hatsujo') {
      CollectionUtil.mergeList(
        errors,
        this.validatePlannedBredAt(rows)
      );
    }

    const errorCount = errors.filter((e) => e.type !== 'warning').length;
    if (errorCount > 0) {
      valid = false;
    }

    return {valid: valid, errors: errors, headerError: headerError};
  }

  validateForClipboard(rows) {
    let valid = true;

    let keyField = 'cowNo';
    let keyLabel = this.Dictionary.COW.COW_NO;
    if (this.eventType === 'carcass') {
      keyField = 'cowUid';
      keyLabel = '番号';
    }

    const errors = [];
    CollectionUtil.mergeList(
      errors, this.validateKey(rows, keyField, keyLabel)
    );

    CollectionUtil.mergeList(
      errors, this.validateOccurredAt(rows)
    );

    CollectionUtil.mergeList(
      errors, this.validateState(this.eventType, rows)
    );

    CollectionUtil.mergeList(
      errors,
      new CowEventClipboardValidator().validate(this.eventType, rows, this.masters)
    );

    const errorCount = errors.filter((e) => e.type !== 'warning').length;
    if (errorCount > 0) {
      valid = false;
    }

    return {valid: valid, errors: errors};
  }

  createEmptyError() {
    return {
      message: {},
      warning: {},
    };
  }

  setValidateErrorToRow(rows, errors) {
    const formatedErrors = {};
    errors.forEach((e) => {
      const error = formatedErrors[e.lineNo] || this.createEmptyError();

      if (e.field === 'occurredDiseaseDate') {
        error.message.occurredDiseaseDate = e.message;
        formatedErrors[e.lineNo] = error;
        return;
      }

      if (e.type === 'warning') {
        error.warning[e.field] = e.message;
        formatedErrors[e.lineNo] = error;
        return;
      }

      switch (e.type) {
      case CowEventValidator.ERROR_TYPE_EMPTY: {
        if (e.field === 'cowNo' || e.field === 'cowUid') {
          error.message.key = e.message;
        } else {
          error.message[e.field] = e.message;
        }
        break;
      }
      case CowEventValidator.ERROR_TYPE_INVALID: {
        if (e.field === 'cowNo' || e.field === 'cowUid') {
          error.message.key = e.message;
        } else {
          error.message[e.field] = e.message;
        }
        break;
      }
      case CowEventValidator.ERROR_TYPE_DUPLICATED: {
        error.message.key = e.message;
        break;
      }
      case CowEventValidator.ERROR_TYPE_BEFORE_CALVING_DATE: {
        error.message.date = e.message;
        break;
      }
      case CowEventValidator.ERROR_TYPE_INVALID_BREEDING_DATE: {
        error.message[e.field] = e.message;
        break;
      }
      case CowEventValidator.ERROR_TYPE_INVALID_NUMBER: {
        // FIXME: エラーメッセージの見直し、統一
        error.message[e.field] = '数値のみの項目です';
        break;
      }
      case CowEventValidator.ERROR_TYPE_UNMATCHED_GENDER: {
        error.message.gender = e.message;
        break;
      }
      default: {
        error.message[e.field] = e.message;
      }
      }

      formatedErrors[e.lineNo] = error;
    });

    rows.forEach((r) => {
      r.errors = formatedErrors[r.lineNo] || this.createEmptyError();
    });
  }

  validateKey(rows, keyField, keyLabel) {
    return rows.map((r) => {
      if (!r[keyField]) {
        return {
          valid: false,
          type: CowEventValidator.ERROR_TYPE_EMPTY,
          message: '必須項目です',
          field: keyField, lineNo: r.lineNo
        };
      }
      if (!r.cowId) {
        return {
          valid: false,
          type: CowEventValidator.ERROR_TYPE_INVALID,
          message: `存在しない${keyLabel}です`,
          field: keyField, lineNo: r.lineNo
        };
      }
      return {valid: true};
    }).filter((r) => !r.valid);
  }

  validateDuplicatedCow(rows, keyLabel) {
    const activeRows = rows.filter((r) => r.cowId);

    const cowIdMap = {};
    activeRows.forEach((r) => {
      const ar = cowIdMap[r.cowId] || [];
      ar.push(r);
      cowIdMap[r.cowId] = ar;
    });

    return activeRows.map((r) => {
      const ar = cowIdMap[r.cowId];
      if (ar.length >= 2) {
        return {
          valid: false,
          type: CowEventValidator.ERROR_TYPE_DUPLICATED,
          message: `${keyLabel}が重複しています`,
          lineNo: r.lineNo
        };
      }
      return {valid: true};
    }).filter((r) => !r.valid);
  }

  validateFields(rows) {
    const validator = new CowEventValidator();
    const errors = [];
    rows.forEach((row) => {
      const result = validator.validateFields(row);
      result.forEach((e) => {
        e.lineNo = row.lineNo;
        errors.push(e);
      });

      const calvingDate = row.latestCalvingDate;
      if (calvingDate) {
        const resultOccurredAt = validator.validateOccurredAt(row, calvingDate, this.dateLabel);
        if (resultOccurredAt.type === CowEventValidator.ERROR_TYPE_BEFORE_CALVING_DATE) {
          resultOccurredAt.lineNo = row.lineNo;
          errors.push(resultOccurredAt);
        }
      }
    });
    return errors;
  }

  validateFieldFormat(row) {
    const validator = new CowEventValidator();
    const errors = validator.validateNumberFields(row);
    const fields = validator.numberFields(row.eventType);
    fields.forEach((f) => {
      const error = errors.find((e) => e.field === f);
      if (error) {
        // FIXME: エラーメッセージの見直し、統一
        row.errors.message[f] = '数値のみの項目です';
      } else {
        row.errors.message[f] = '';
      }
    });
  }

  validateTreatment(rows) {
    const validator = new CowEventValidator();
    const errors = [];
    rows.forEach((row) => {
      const result = validator.validateOccurredDiseaseDate(row);
      if (!result.valid) {
        result.lineNo = row.lineNo;
        errors.push(result);
      }
    });
    return errors;
  }

  validatePerinatalDifficulty(rows) {
    const errors = [];
    rows.forEach((row) => {
      if (row.condition &&
        Object.keys(row.condition).length > 0) {
        if (!row.severity) {
          const error = {
            valid: false,
            type: CowEventValidator.ERROR_TYPE_EMPTY,
            message: '選択してください',
            field: 'severity',
            lineNo: row.lineNo
          };
          errors.push(error);
        }
      }
    });
    return errors;
  }

  validateOccurredAt(rows) {
    const errors = [];
    rows.forEach((row) => {
      if (!row.occurredAt) {
        if (row.occurredAtString) {
          const error = {
            valid: false, lineNo: row.lineNo,
            type: CowEventValidator.ERROR_TYPE_INVALID,
            field: 'occurredAt', message: '日付の書式が不正です',
          };
          errors.push(error);
        } else {
          const error = {
            valid: false, lineNo: row.lineNo,
            type: CowEventValidator.ERROR_TYPE_EMPTY,
            field: 'occurredAt', message: '日付が未入力です',
          };
          errors.push(error);
        }
        return;
      }

      const validator = new CowEventValidator();
      const dateResult = validator.validateDate(row.occurredAt);
      if (!dateResult.valid) {
        const error = {
          valid: false, lineNo: row.lineNo,
          type: dateResult.type,
          field: 'occurredAt', message: dateResult.message,
        };
        errors.push(error);
      } else {
        if (row.latestCalvingDate) {
          const result = validator.validateOccurredAt(
            row, row.latestCalvingDate, row.dateLabel
          );
          if (result.type === CowEventValidator.ERROR_TYPE_BEFORE_CALVING_DATE) {
            result.lineNo = row.lineNo;
            errors.push(result);
          }
        }
      }
    });
    return errors;
  }

  validatePlannedBredAt(rows) {
    const errors = [];

    rows.forEach((row) => {
      const validator = new CowEventValidator();
      const error = validator.validatePlannedBredAt(row);

      if (!error.valid) {
        error.lineNo = row.lineNo
        errors.push(error);
      }
    });
    return errors;
  }

  updateStateError(argRows = null) {
    const rows = argRows || this.removeEmptyRow(this.cowEvents, this.eventType);
    const result = this.validate(rows);
    this.setValidateErrorToRow(rows, result.errors);
  }

  validateGender(eventType, rows) {
    const errors = [];

    rows.forEach((r) => {
      if (!r.cowId) return;

      switch (eventType) {
      case 'castration': {
        if (r.gender !== 'オス') {
          const error = {
            type: CowEventValidator.ERROR_TYPE_UNMATCHED_GENDER,
            message: `この牛は${r.gender}です`,
            field: 'gender',
            lineNo: r.lineNo
          };
          errors.push(error);
        }
      }
      }
    });

    return errors;
  }

  validateState(eventType, rows) {
    const addWarning = (errors, message, field, lineNo) => {
      const warning = {
        type: 'warning', message: message, field: field, lineNo: lineNo
      };
      errors.push(warning);
    };

    const errors = [];

    rows.forEach((r) => {
      if (!r.cowId) return;

      switch (eventType) {
      case 'freshCheck':
      case 'hatsujo':
      case 'hormoneProgram':
      case 'tanetsuke': {
        const cow = {state: r.cowState};
        if (Cow.isPregnant(cow)) {
          addWarning(errors, '妊娠しています', 'state', r.lineNo);
          if (r.latestPregnancyDate) {
            const days = DateUtil.countDays(r.latestPregnancyDate, DateUtil.today());
            addWarning(errors, `(妊娠日数：${days}日)`, 'pregnantDays', r.lineNo);
          }
        }
        break;
      }
      case 'ninshinkantei': {
        if (!r.latestFertilizationDate) {
          addWarning(errors, 'この牛は未授精です', 'state', r.lineNo);
        } else {
          if (r.occurredAt && (r.ninshinkanteiResult === '受胎' || r.ninshinkanteiResult === '双子受胎')) {
            let days = DateUtil.diffDays(r.latestFertilizationDate, r.occurredAt);
            if (days < 14) {
              if (days < 0) {
                days = 0;
              }
              const error = {
                type: CowEventValidator.ERROR_TYPE_INVALID,
                message: `授精後${days}日です<br>登録できません`,
                field: 'state',
                lineNo: r.lineNo
              };
              errors.push(error);
            }
          }
        }
        break;
      }
      case 'kannyu': {
        if (r.cowState === '乾乳前期' && r.selectedDryPeriod === '前期') {
          addWarning(errors, 'この牛は乾乳前期です', 'state', r.lineNo);
        } else if (r.cowState === '乾乳後期') {
          addWarning(errors, 'この牛は乾乳後期です', 'state', r.lineNo);
        } else {
          const cow = {state: r.cowState};
          if (!Cow.isPregnant(cow)) {
            addWarning(errors, '妊娠していません', 'state', r.lineNo);
          }
        }
        break;
      }
      case 'embryo_recovery': {
        if (r.cowState !== '授精') {
          addWarning(errors, '授精牛ではありません', 'state', r.lineNo);
        }
        break;
      }
      case 'carcass': {
        if (!r.eliminateDate) {
          addWarning(errors, '出荷日が未登録です', 'state', r.lineNo);
        }
        break;
      }
      }
    });

    return errors;
  }

  cancel() {
    if (!this.caller || !this.caller.state) {
      this.$state.go('top', {});
      return;
    }
    this.$state.go(this.caller.state, this.caller.params);
  }

  insertInBulk(cowEvents) {
    const path = this.caller.bqNotes || this.caller.state || 'navi_menu';
    const options = {
      bqNotes: `path: ${path}`,
      bqInputTime: new Date().getTime() - this.inputStartAt.getTime(),
    };
    return this.context.bulkImport(cowEvents, options).then((res) => {
      this.clearCommonParams();
      this.cowEvents = [];
      this.cowIds = [];
      this.inputStartAt = new Date();

      for (let i = 0; i < 20; i++) {
        const event = this.createEmptyEvent();
        this.cowEvents.push(this.eventType === 'lame' ? this.addLameToEvent(event) : event);
      }
      // 再取得しないと自動提案される発生日が更新されないため
      this.context.loadDeprecatedDigestCows(this.allowInactiveCow()).then((res) => {
        this.createCowMap(res.data);
      }).catch((err) => console.error(err));

      // 枝肉成績の場合で、出荷日が未登録の牛がいる場合は、つづけて出荷イベントの登録を促す
      if (this.eventType === 'carcass' && !this.IsMobile) {
        const noEliminateDateCows = cowEvents.filter((cowEvent) => {
          return cowEvent.noEliminateDate;
        });
        if (noEliminateDateCows.length > 0) {
          this.blockUI.stop();
          this.EventSelectionSimpleConfirm.open(
            '完了',
            `枝肉成績一括イベント登録を完了しました。`,
            `出荷日が未登録の牛が${noEliminateDateCows.length}頭います。つづけて出荷イベントを登録しますか？`
          ).result.then((r) => {
            if (r) {
              this.$state.go('cow-event-bulk-input', {
                eventType: 'touta',
                cowIds: noEliminateDateCows.map((c) => c.cowId),
                cowType: this.cowType
              });
            }
          });
        } else {
          this.blockUI.stop();
          this.modalDialogFactory.showSuccessiveAlertModal({
            title: '完了',
            text1: `${this.eventName}一括イベント登録を完了しました。`,
            text2: '',
          });
        }

        return {
          ok: true
        };

      }
      this.blockUI.stop();

      // 牛群移動の場合、メニューを更新する
      if (this.eventType === 'gyugunidou') {
        this.$rootScope.$emit('menu:refreshGroups', []);
      }

      if (this.IsMobile) {
        this.modalDialogFactory.showMobileAlertDialog({
          title: '完了',
          texts: [`${this.eventName}一括イベント登録を完了しました。`]
        });
        return;
      }

      if (!this.isPasteMode) {
        const modalInstance = this.modalDialogFactory.showYesNoConfirm({
          title: '確認',
          text1: `${this.eventName}一括イベント登録を完了しました`,
          text2: '引き続き、同じ牛個体に一括イベント登録を行いますか？',
          no: true,
          yes: true,
        });

        modalInstance.result.then((result) => {
          if (result) {
            this.cowIds = cowEvents.map((c) => c.cowId);
            this.openEventDialog();
          }
        });
      }
    }).then(() => {
      return {
        status: {
          ok: true
        }
      };
    }).catch((res) => {
      this.errMessage = res.data.message;
      this.blockUI.stop();
    });
  }

  /**
   * 一括登録モーダルを開く
   */
  openEventDialog() {
    this.$modal.open({
      templateUrl: 'components/event-registration/event-selection.html',
      controller: 'EventSelectionController',
      controllerAs: 'ctrl',
      size: '1012',
      resolve: {
        cowIds: () => this.cowIds,
        cowType: () => this.cowType,
        isDepositor: () => this.isDepositor,
        qwert: () => this.qwert,
        caller: () => Object.assign(this.caller, {eventType: this.eventType})
      },
    });
  }

  /**
   * 牛群移動イベント
   * 牛群選択時に対象牛群に定義されている牛房リストをプルダウンにセットする
   */
  updatePenList() {
    this.penList = this.moveToCowGroupId ? this.penListMap[this.moveToCowGroupId] : [];
    this.showMoveToPen = this.penList && this.penList.length > 0 && this.penList[0].value;
    if (!this.showMoveToPen) {
      this.moveToPen = null;
    }
  }

  /**
   * プログラム名選択すると
   *  処置内容のオプションをリセット
   * @param {Object} workingRow 作業中の行
   * @param {string} masterHormoneProgramId 選択したプログラムのID
   */
  selectHormoneProgram(workingRow, masterHormoneProgramId) {
    workingRow.hormoneCodes = this.hormoneCodesMap[masterHormoneProgramId];
    workingRow.selectedHormoneCode = workingRow.hormoneCodes ?
      workingRow.hormoneCodes[0] :
      undefined;
    delete workingRow.hormoneCode;
    delete workingRow.hormoneName;
  }

  /* モデル・バリデーションゾーン */

  /**
   * 牛番号入力後、牛の関連データを補填する
   * @param {String} cowNo 4桁の牛番号 -> "1234"
   * @param {Object} event 作業中のscope.cowEvents
   */
  appendCowData(cowNo, event) {
    if (!this.isCowNoChanged(event)) return;

    if (this.eventType === 'ninshinkantei') {
      event.ninshinkanteiResult = '受胎';
    }

    if (this.eventType === 'hatsujo') {
      event.plannedBredMethod = '';
      this.onChangePlannedBredMethod(event);
    }

    const targetCows = (this.eventType === 'carcass') ? [this.cowUidMap[event.cowUid]] : this.cowNoMap[cowNo];

    if (!targetCows || !targetCows[0]) return this.initializeCowEventProperties(event);

    return this.selectTargetCow(targetCows).then((cow) => {
      return this.setParticularParamsToCow(cow).then(() => {
        return cow;
      });
    }).then((cow)=>{
      this.settingEventFields(cow, event);

      const rows = [event];
      this.updateStateError(rows);
    });
  }

  isCowNoChanged(event) {
    return (this.eventType === 'carcass' && event.cowUid !== event.currentCowUid) ||
           (event.cowNo !== event.currentCowNo);
  }

  selectTargetCow(targetCows) {
    return new Promise((resolve, reject) => {
      if (targetCows.length > 1) {
        this.$modal.open({
          templateUrl: 'header/cow-no-select.html',
          controller: 'CowNoSelectController as ctrl',
          size: 'md',
          resolve: {
            params: () => ({cows: targetCows}),
          },
        }).result.then((result) => {
          result = targetCows.find((c) => c.cowId === result.selected.cowId);
          resolve(result);
        });
      } else {
        resolve(targetCows[0]);
      }
    });
  }

  setParticularParamsToCow(targetCow) {
    return new Promise((resolve, reject) => {
      switch (this.eventType) {
      case 'hormoneProgram':
      case 'embryo_recovery': {
        this.context.findEventByCow(targetCow.cowId, 'hormoneProgram').then((res) => {
          targetCow.lastTimedAiEvent = CowEvent.latest(res.data);
          resolve();
        }).catch((e)=>{
          console.error(`BulkEntryEventsState.setParticularParamsToCow() cowId: ${targetCow.cowId} eventType: otherDifficulty`);
          console.error(e);
          reject(e);
        });
        break;
      }
      case 'otherDifficulty':
        this.context.findEventByCow(targetCow.cowId, 'otherDifficulty').then((res) => {
          const latest = CowEvent.latest(res.data);
          if (latest) {
            const fieldName = this.deseaseOccurredDateFields['otherDifficulty'];
            targetCow[fieldName] = latest['occurredDiseaseDate'];
          }
          resolve();
        }).catch((e)=>{
          console.error(`BulkEntryEventsState.setParticularParamsToCow() cowId: ${targetCow.cowId} eventType: otherDifficulty`);
          console.error(e);
          reject(e);
        });
        break;
      default:
        resolve();
      }
    });
  }

  settingEventFields(targetCow, event) {
    // バリデーション用
    event.cowId = targetCow.cowId;
    event.cowUid = targetCow.cowUid;
    event.gender = targetCow.gender;
    event.cowState = targetCow.state;
    event.eliminateDate = Number(targetCow.eliminateDate);
    event.latestCalvingDate = targetCow.latestCalvingDate;
    // 枝肉成績一括登録フォームでの行内容コピー用
    event.cowNo = targetCow.cowNo;
    // ツールチップ用
    event.formattedCowUid = formatCowUid(event.cowUid);
    // 現在入力中の牛の牛番号
    event.currentCowNo = targetCow.cowNo;
    event.currentCowUid = targetCow.cowUid;

    if (this.shouldShowTimeField(this.eventType) && !event.selectedHour && !event.selectedMinute) {
      // 現在時刻をセット
      event.selectedHour = moment().format('HH');
      event.selectedMinute = moment().format('mm').substr(0, 1) + '0';
    }

    if (this.showMoveToCowGroup) {
      // 牛群移動用
      const targetCowGroup = this.cowGroupList.filter((group) =>
        group.cowGroupId === targetCow.cowGroupId);

      if (targetCowGroup.length) {
        // 移動前牛群・牛房を設定
        event.beforePen = targetCow.pen;
        event.beforeCowGroupId = targetCow.cowGroupId;
        event.beforeCowGroupName = targetCowGroup[0].cowGroupName;
      }
    }

    if (this.eventType === 'mastitis') {

      // 直近の病気発生日を設定
      this.setPreviousEventDate(targetCow, event);

      // 乳房炎用
      event.stopMilkingOfBreastBl = targetCow.stopMilkingDateOfBreastBl ? true : false;
      event.stopMilkingOfBreastBr = targetCow.stopMilkingDateOfBreastBr ? true : false;
      event.stopMilkingOfBreastFl = targetCow.stopMilkingDateOfBreastFl ? true : false;
      event.stopMilkingOfBreastFr = targetCow.stopMilkingDateOfBreastFr ? true : false;
      event.mastitisCowGroupId = targetCow.mastitisCowGroupId || targetCow.cowGroupId;

    } else if (this.eventType === 'perinatalDifficulty') {
      // 直近の病気発生日を設定
      this.setPreviousEventDate(targetCow, event);

      event.difficultiesKetosis = '';
      event.difficultiesFattyLiver = '';
      event.difficultiesAbomasumDisplacement = '';
      event.difficultiesHypocalcium = '';
      event.difficultiesAcidosis = '';
      event.difficultiesMetritis = '';
      event.difficultiesDowner = '';
      event.difficultiesStagnantFood = '';
      event.difficultiesPlacenta = '';
      event.difficultiesTympanites = '';
      event.difficultiesVitaminA = '';
      event.difficultiesUrolithiasis = '';
    } else if (this.eventType === 'hormoneProgram') {
      // 現行プログラム & 次回処置がある場合のみ、設定。
      if (targetCow.lastTimedAiEvent) {
        event.masterHormoneProgramId = targetCow.lastTimedAiEvent.masterHormoneProgramId;
      } else {
        event.masterHormoneProgramId = 0;
      }

      const hormoneCodes = this.hormoneCodesMap[event.masterHormoneProgramId];
      let nextHormone = null;
      if (hormoneCodes) {
        nextHormone = hormoneCodes.find((hormone) => targetCow.nextHormoneCode === hormone.value);
      }

      if (nextHormone) {
        event.hormoneProgramName = targetCow.hormoneProgramName;
        event.hormoneCode = nextHormone.value;
        event.hormoneName = nextHormone.label;

        // 処置内容オプションを設定
        event.hormoneCodes = hormoneCodes;

        event.selectedHormoneCode = event.hormoneCodes.find((hormone) => {
          return hormone.value === nextHormone.value;
        });
      } else {
        event.masterHormoneProgramId = 0;
        event.hormoneCodes = undefined;
        event.selectedHormoneCode = undefined;
      }

      event.latestPregnancyDate = Number(targetCow.latestPregnancyDate);

    } else if (this.eventType === 'tanetsuke') {
      event.latestPregnancyDate = Number(targetCow.latestPregnancyDate);
      event.workerName = event.workerName || this.defaultWorkerName(this.selection.inseminators, this.username);

      const hour = this.targetDate ? event.selectedHour : 0;
      const minute = this.targetDate ? event.selectedMinute : 0;
      const occurredAt = this.DateUtilService.addTimeToDate(Number(this.targetDate), hour, minute);
      event.inseminationCode = HormoneProgram.currentHormoneProgramName(targetCow, occurredAt);

      if (targetCow.state !== '繁殖除外') return;
      this.EventSelectionSimpleConfirm.open(
        '繁殖除外牛種付確認',
        `${this.Dictionary.COW.COW_NO}：${event.cowNo}(${event.formattedCowUid})は繁殖除外ですが種付しますか？`
      ).result.then((result) => {
        if (result) return;
        // ダイアログで「いいえ」クリック時は牛番号、牛ID、個体識別番号をクリアする
        event.cowNo = null;
        event.cowId = null;
        event.cowUid = null;
        event.currentCowNo = null;
        event.currentCowUid = null;
      });

      event.embryoType = '体内受精卵';

    } else if (this.eventType === 'freshCheck' || this.eventType === 'hatsujo') {
      event.latestPregnancyDate = Number(targetCow.latestPregnancyDate);

    } else if (this.eventType === 'ninshinkantei') {
      event.latestFertilizationDate = Number(targetCow.latestFertilizationDate);
      event.ninshinkanteiResult = event.ninshinkanteiResult || '受胎';
      event.latestBreedingMethod = targetCow.latestBreedingMethod;
    } else if (this.eventType === 'lame') {
      this.setPreviousEventDate(targetCow, event);
    } else if (this.eventType === 'infect') {
      [
        'difficultiesDiarrhea',
        'difficultiesPneumonia',
        'difficultiesSkinDisease',
        'difficultiesOther'
      ].forEach((key) => {
        event[key] = event[key] || '';
      });

      this.setPreviousEventDate(targetCow, event);
    } else if (this.eventType === 'otherDifficulty') {
      this.setPreviousEventDate(targetCow, event);
    } else if (this.eventType === 'embryo_recovery') {
      event.embryoMasterRegistered = event.embryoMasterRegistered || false;
      event.nextCowState = event.nextCowState || 'non_pregnant';

      if (targetCow.lastTimedAiEvent) {
        event.masterHormoneProgramId = targetCow.lastTimedAiEvent.masterHormoneProgramId;
      } else {
        event.masterHormoneProgramId = 0;
      }

      if (targetCow.latestMasterSpermNo) {
        const sperm = this.spermInfoList.find((s) => s.spermNo === targetCow.latestMasterSpermNo) || {};
        event.masterSpermId = sperm.id;
      }
      if (targetCow.latestFertilizationDate) {
        event.targetBreedingDate = targetCow.latestFertilizationDate;
      }
    } else if (!this.IsMobile && this.eventType === 'touta') {
      event.producingArea = targetCow.producingArea;
      event.producingFarmName = targetCow.producingFarmName;
    }
    if (EventType.isTreatmentForLegacy(this.eventType) || this.eventType === 'vaccine') {
      event.workerName = event.workerName || this.defaultWorkerName(this.selection.treatmentPersons, this.username);
    }

    if (['hoofTrim', 'dehorn', 'gyugunidou'].includes(this.eventType)) {
      event.workerName = event.workerName || this.defaultWorkerName(this.selection.workerNames, this.username);
    }
  }

  /**
  * @param {Object} event 作業中のscope.cowEvents
  * @return {void}
  */
  initializeCowEventProperties(event) {
    event.cowId = null;
    event.cowUid = this.eventType === 'carcass' ? event.cowUid : null;
    event.currentCowNo = null;
    event.currentCowUid = null;
    event.cowState = null;

    event.errors.message.state = '';
    event.errors.warning.state = '';
    event.errors.warning.pregnantDays = '';
  }

  /**
   * 選択した牛の直近の病気発生日を取得し発生日に設定する。
   * 直近の発生日が存在しない場合は今日の日付を発生日に設定する。
   * 肉用牧場の場合は「新規/継続」設定によってセットする日付を切り替える。
   *
   * @param {Object} cow 対象牛
   * @param {Object} event 編集中のcowEventsの1行
   */
  setPreviousEventDate(targetCow, event) {
    event.eventType = this.eventType;
    this.setTreatmentState(event, this.targetDate, this.farm, targetCow, this.context);
  }

  setTreatmentState(event, treatmentDate, farm, cow, cowEventAPI) {
    const isBlankOccurredDiseaseDate = !event.occurredDiseaseDate;

    if (event.eventType === 'mastitis') {
      event.mastitisCowGroupId = cow.cowGroupId;
    }

    if (isBlankOccurredDiseaseDate) {
      event.diseaseContinuation = 'new';
      event.occurredDiseaseDate = treatmentDate;
      event.treatmentDiseaseDate = treatmentDate;
    }

    cowEventAPI.findEventByCow(cow.cowId, event.eventType).then((res) => {
      const occurredEvent = res.data.find((e) => {
        return DateUtil.isSameOrBeforeDay(e.occurredDiseaseDate, treatmentDate);
      });

      if (occurredEvent) {
        event.continuousOccurredDiseaseDate = occurredEvent.occurredDiseaseDate;
        if (event.eventType === 'mastitis') {
          event.continuousMastitisCowGroupId = occurredEvent.mastitisCowGroupId;
        }

        const isIncludedWashoutPeriod = CowEvent.isIncludedWashoutPeriod(
          treatmentDate,
          farm.isMilk(),
          occurredEvent.endDateOfBeefWashoutPeriod,
          occurredEvent.endDateOfMilkWashoutPeriod
        );
        if (isIncludedWashoutPeriod) {
          if (isBlankOccurredDiseaseDate) {
            event.occurredDiseaseDate = occurredEvent.occurredDiseaseDate;
            event.diseaseContinuation = 'continuous';
          }

          if (event.eventType === 'mastitis') {
            if (isBlankOccurredDiseaseDate) {
              event.occurredDiseaseDate = cow.occurredMastitisDate || treatmentDate;
            }
            event.continuousOccurredDiseaseDate = event.occurredDiseaseDate;
            event.mastitisCowGroupId = occurredEvent.mastitisCowGroupId;
          }
        }
      }
    });
  }

  /**
   * エラーをクリアする。
   */
  clearErrors() {
    this.error = {};

    // FIXME: モバイルでのみ使用している実装なのでモバイル側に移したい
    this.errorStatus = {
      targetDate: {},
      occurredDate: {},
      moveToCowGroupId: {},
      bodyTemperature: {}
    };
  }

  setTotalCarcassWeight(row) {
    this.validateFieldFormat(row);

    row.totalDressedCarcassWeight =
      Math.round(
        ((Number(row.dressedCarcassWeightOfL) || 0) +
         (Number(row.dressedCarcassWeightOfR) || 0)) * 10
      ) / 10;

    this.setDressedCarcassSalesPrice(row);
  }

  setDressedCarcassSalesPrice(row) {
    row.dressedCarcassSalesPrice = Carcass.calculateDressedCarcassSalesPrice(
      row.dressedCarcassUnitPrice,
      row.totalDressedCarcassWeight
    );
  }

  focusPriceField(row, field) {
    const price = row[field];
    row[field] = this.removeComma(price);
  }

  blurPriceField(row, field) {
    const formatComma = (value) => {
      if (!StringUtil.isDecimal(value)) {
        return value;
      }
      return Number(value).toLocaleString();
    };

    const price = row[field];
    row[field] = formatComma(price);
  }

  removeComma(value) {
    if (!value) {
      return value;
    }
    return value.replace(/,/g, '');
  }

  /**
   * 週産病の症状の選択の有無により、重症度の選択を有・無効化する
   *   少し手を加えればuiSelectで選択したもの存在チェックとしても使用可能。今回はしないけど
   * @param {Object} row 入力フォームの一行
   */
  validateSeveritySelection(row) {
    // row.condition.value はuiSelectで選択されたObj
    if (row.condition &&
      Object.keys(row.condition).length > 0) {
      return row.hasCondition = true;
    }
    row.hasCondition = false;
    row.severity = '';
  }

  /**
   * 牛情報取得用のMapを作成する
   *
   * [example]
   * cows = [
   *   { cowId: '12', cowNo: '1', cowUid: '1234567890', .. },
   *   { cowId: '24', cowNo: '1', cowUid: '1234567891', .. },
   *   { cowId: '35', cowNo: '2', cowUid: '1234567892', .. }];
   * createCowMap(cows);
   * this.cowNoMap => { '1': [Object, Object], '2': [Object]... }
   * this.cowIdMap => { '12': Object, '24': Object, '35': Object }
   *
   * イベントが枝肉成績の場合はcowUidをキーにしたMapも作成する
   * this.cowUidMap => {
   *   '1234567890': Object,
   *   '1234567891': Object,
   *   '1234567892': Object }
   *
   * @param {Array} cows 牛一覧
   */
  createCowMap(cows) {
    this.cowNoMap = {};
    this.cowIdMap = {};
    this.cowUidMap = {};

    const dateColumns = [
      'eliminateDate',
      'occurredMastitisDate',
      'occurredPerinatalDifficultyDate',
      'occurredLameDate',
      'occurredInfectDate',
      'stopMilkingDateOfBreastBl',
      'stopMilkingDateOfBreastBr',
      'stopMilkingDateOfBreastFl',
      'stopMilkingDateOfBreastFr',
      'latestCalvingDate',
      'latestFertilizationDate',
      'latestPregnancyDate',
    ];

    cows.forEach((cow) => {
      dateColumns.forEach((column) => {
        const value = cow[column];
        if (value) {
          cow[column] = Number(value);
        }
      });

      if (!this.cowNoMap[cow.cowNo]) this.cowNoMap[cow.cowNo] = [];
      this.cowNoMap[cow.cowNo].push(cow);
      this.cowIdMap[cow.cowId] = cow;
      if (this.eventType === 'carcass') {
        this.cowUidMap[cow.cowUid] = cow;
      }
    });
  }

  allowInactiveCow() {
    return this.eventType === 'carcass';
  }

  shouldShowAddLame(event) {
    if (!event.lames) return false;
    return event.lames.length < 4;
  }

  /**
   * 選択リスト生成
   * 例: [{ label: '1:選択オプション', value: 選択オプション }]
   * @param {Array} list UserDefinedSelectionAPIのレスポンス
   * @param {boolean} allowBlank
   * @param {boolean} isNumbered 番号付きリストにする
   * @return {Array}
   */
  generateUserDefinedOption(list, allowBlank, isNumbered) {
    if (!list) return [];

    const result = allowBlank ? [{label: '', value: ''}] : [];

    list.split(/\n/).forEach((selection, index) => {
      if (!selection) return;
      const prefix = isNumbered ? (index + 1) + '：' : '';
      result.push({label: prefix + selection, value: selection});
    });

    return result;
  }

  defaultWorkerName(workerNames, username) {
    if (!workerNames) return '';
    const matched = workerNames.find((workerName) => {
      return StringUtil.removeSpaces(workerName.value) === StringUtil.removeSpaces(username);
    });
    return matched ? matched.value : '';
  }

  updateEmbryoMasters() {
    return this.context.loadMasterEmbryo().then((res) => {
      this.masterEmbryos = res.data;
      this.selection['masterEmbryoOptions'] = res.data.map((embryoMaster) => {
        return {
          label: embryoMaster.name,
          masterEmbryoId: embryoMaster.id
        };
      });
    });
  }

  confirmEmbryoRecoveryRegistraion(cowNos) {
    return this.modalDialogFactory.showHistoryConfirm({
      title: '確認',
      text1: '以下の牛の回収卵の数が入力されていません。よろしいですか？',
      text2: `[牛番号] ${cowNos.join('、')}`,
      no: true, yes: true
    });
  }

  addUserEntryValueToProducing(selection, cowIdMap) {
    [
      {selectionItem: 'producingAreas', cowItem: 'producingArea'},
      {selectionItem: 'producingFarmNames', cowItem: 'producingFarmName'}
    ].forEach((obj) => {
      const items = selection[obj.selectionItem];
      if (!items.length) return;

      Object.values(cowIdMap).forEach((cow) => {
        const item = cow[obj.cowItem];
        if (!item) return;

        const optionExisted = items.some((option) => option.value === item);
        if (optionExisted) return;

        const option = {label: `${items.length}：${item}`, value: item};
        items.push(option);
      });
    });
  }

  generateWorkNoteTypeSelection(masters) {
    const result = masters.filter((m) => m.visible).map((m, index) => {
      return {
        key: m.id,
        label: `${index + 1}: ${m.name}`
      };
    });
    result.unshift({key: 0, label: ''});
    return result;
  }

  onChangePlannedBredMethod(event) {
    const date = DateUtil.startOfDay(Number(this.targetDate));

    switch (event.plannedBredMethod) {
      case '人工授精':
        event.plannedBredAt = DateUtil.addDays(date, 1).valueOf();
        break;
      case '移植':
        event.plannedBredAt = DateUtil.addDays(date, 7).valueOf();
        break;
      case '本交':
        event.plannedBredAt = DateUtil.addDays(date, 1).valueOf();
        break;
      default:
        event.plannedBredAt = '';
        event.plannedBredAtHour = '';
        event.plannedBredAtMinute = '';
    }

    if (event.plannedBredAt) {
      event.plannedBredAtHour = event.selectedHour;
      event.plannedBredAtMinute = event.selectedMinute;
    }
  }
}

app.service('CowEventBulkInput', CowEventBulkInput);
