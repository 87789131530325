class GenomCowAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  show(cowId) {
    return this.APIService.get(`/genom/cow/${cowId}`);
  }
}

app.service('GenomCowAPI', GenomCowAPI);
