/**
 * モバイル用の分娩アラートのリストアイテムコンポーネントです
 *
 * ex.
 *   <mobile-alert-calving-list-item
 *     alert-calving="alertCalving"
 *     on-click="onClick(id)"
 *   ></mobile-alert-calving-report-form>
 *
 * プロパティ:
 *  @param {Object} alert-calving 分娩アラートオブジェクト
 *  @param {Function} on-click コンポーネントクリック時に発生するイベント idを引数で渡す
 */
class MobileAlertCalvingListItemController {
  constructor() {
    'ngInject';
  }

  alertLevelClass(alertLevel) {
    return alertLevel === 'HIGH' ? 'alert-level-high' : 'alert-level-low';
  }

  handleClick() {
    this.onClick({id: this.alertCalving.id});
  }
}

function mobileAlertCalvingListItemComponents() {
  return {
    templateUrl: 'mobile/alert-list/calving/list-item.html',
    controller: MobileAlertCalvingListItemController,
    bindings: {
      alertCalving: '<',
      onClick: '&',
    }
  };
}

app.component('mobileAlertCalvingListItem', mobileAlertCalvingListItemComponents());
