class DepositCowEventsService {
  constructor(
    APIService
  ) {
    'ngInject';
    this.APIService = APIService;
  }

  index(cowId) {
    return this.APIService.get(`/deposit/cow_event/cow/${cowId}`);
  }

  getByEventType(cowId, eventType) {
    return this.APIService.get(`/deposit/cow_event/cow/${cowId}/${eventType}`);
  }

  insert(data) {
    return this.APIService.post(`/deposit/cow_event`, data);
  }

  insertInBulk(data, options = {}) {
    const params = Object.assign({}, options);
    params['_json'] = data;
    return this.APIService.post(`/deposit/cow_event/bulk`, params);
  }

  update(data) {
    return this.APIService.put(`/deposit/cow_event/${data.id}`, data);
  }

  delete(id) {
    return this.APIService.delete(`/deposit/cow_event/${id}`);
  }

  workerName() {
    return this.APIService.get('/deposit/cow_event/worker_name');
  }

  static get EVENT_CLASSES() {
    return {
      bunben: Calved,
    };
  }

  /**
   * イベントタイプのインスタンスを作成する。
   * 一致するイベントタイプが無い場合は、引数のparamsを返す。
   *
   * @param {string} eventType bunben, tanetsuke, etc
   * @param {Object} params
   */
  createInstance(eventType, params) {
    const EventClass = DepositCowEventsService.EVENT_CLASSES[eventType];
    return EventClass ? new EventClass(params) : params;
  }
}

app.service('DepositCowEventsService', DepositCowEventsService);
