/* eslint-disable */
class HormoneProgram {
  constructor(params) {
    // Change to Object.assign in the future
    this.origin = angular.extend({}, this.defaultContent_(), params);
    this.initializeContent();
  }

  // public content

  /**
   * 項目を初期化する。
   */
  initializeContent() {
    angular.extend(this, this.origin);
  }

  static currentHormoneProgramName(cow, date) {
    if (!cow.hormoneProgramName || !cow.latestHormoneDate || !cow.artificialInseminationDate) return null;

    if (Number(cow.latestHormoneDate) <= date && date < DateUtil.addDays(Number(cow.artificialInseminationDate), 4)) {
      return cow.hormoneProgramName;
    }
    return null;
  }

  // private method

  /**
   * デフォルトオブジェクトを返す。
   * 
   * @return {Object} デフォルトオブジェクト
   */
  defaultContent_() {
    return {
      name: '',
      hormoneName1: '',
      hormoneName2: '',
      hormoneName3: '',
      hormoneName4: '',
      hormoneName5: '',
      hormoneName6: '',
      hormoneName7: '',
      hormoneName8: '',
      hormoneName9: '',
      hormoneName10: '',
      hormonePeriod1: '',
      hormonePeriod2: '',
      hormonePeriod3: '',
      hormonePeriod4: '',
      hormonePeriod5: '',
      hormonePeriod6: '',
      hormonePeriod7: '',
      hormonePeriod8: '',
      hormonePeriod9: '',
      hormonePeriod10: '',
      cowCount: null,
    };
  }
}
