/**
 * TOP画面の分娩アラート件数表示欄のコンポーネント
 *
 * ex.
 *  <alert-calving-box
 *    confirmed-count="confirmedCount"
 *    unconfirmed-count="unconfirmedCount">
 *  </alert-calving-box>
 *
 * プロパティ:
 *  @param {number} confirmed-count 確認済の分娩アラート件数
 *  @param {number} unconfirmed-count 未確認の分娩アラート件数
 */
class AlertCalvingBoxController {
  constructor() {
    'ngInject';
  }

  existsBoth() {
    return this.unconfirmedCount > 0 && this.confirmedCount > 0;
  }

  existsUnconfirmedOnly() {
    return this.unconfirmedCount > 0 && this.confirmedCount === 0;
  }

  existsConfirmedOnly() {
    return this.unconfirmedCount === 0 && this.confirmedCount > 0;
  }

  notExistsAlerts() {
    return this.unconfirmedCount === 0 && this.confirmedCount === 0;
  }
}

function alertCalvingBox() {
  return {
    templateUrl: 'top/alert/calving-box/index.html',
    controller: AlertCalvingBoxController,
    bindings: {
      confirmedCount: '<',
      unconfirmedCount: '<'
    }
  };
}

app.component('alertCalvingBox', alertCalvingBox());
