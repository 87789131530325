function MobileAlertBoxComponent() {
  return {
    restrict: 'E',
    templateUrl: 'mobile/components/mobile-alert-box.html',
    scope: {},
    bindToController: {
      farmKind: '='
    },
    controller: MobileAlertBoxController,
    controllerAs: 'ctrl'
  };
}

app.directive('mobileAlertBox', MobileAlertBoxComponent);
