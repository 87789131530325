class FatteningItemReportChartController {
  constructor(
  ) {
    'ngInject';
  }

  $onDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  $onChanges(obj) {
    if (this.shouldUpdateChart(obj)) {
      this.draw();
    }
  }

  shouldUpdateChart(obj) {
    if (!this.chart) return true;
    return obj.year || obj.frequency || obj.itemId;
  }

  draw() {
    if (this.chart) {
      this.updateChart();
    } else {
      this.createChart();
    }
  }

  createChart() {
    this.chart = c3.generate({
      bindto: '.fattening-item-report-chart',
      data: this.generateChartData(),
      axis: {
        x: {
          type: 'category',
          tick: {
            centered: true
          }
        }
      },
      tooltip: {
        grouped: false,
        contents: this.customTooltip
      },
      zoom: {
        enabled: true,
        rescale: true
      }
    });
  }

  updateChart() {
    this.chart.load(
      this.generateChartData()
    );
  }

  generateChartData() {
    if (this.frequency === 'monthly') {
      return this.generateMonthlyChartData();
    } else {
      return this.generateYearlyChartData();
    }
  }

  generateMonthlyChartData() {
    const data = {x: 'x', columns: []};
    const yearMonths = this.annualSummaries.reduce((acc, cur) => {
      if (cur.fiscalYear !== this.year) return acc;
      const records = FatteningReport.fillLackMonth(cur.monthly);
      records.pop();
      return acc.concat(records.map((r) => r.yearMonth));
    }, []).filter((m) => m);

    // X軸のラベル
    const x = yearMonths.map((m) => `${m % 100}月`);
    x.unshift('x');
    data.columns.push(x);

    // グラフデータ
    const yearMonth = yearMonths[0];
    const temp = this.agentFarmAnnualSummaries.map((f) => {
      const summary = f.annualSummaries.find((m) => m.fiscalYear === this.year && m.monthly[0].yearMonth === yearMonth);

      if (!summary) return;

      return FatteningReport.fillLackMonth(summary.monthly).reduce((acc, cur) => {
        if (cur.yearMonth % 100 === 99) return acc;
        const value = [null, undefined].includes(cur.summary[this.itemId]) ? null : Number(cur.summary[this.itemId].replace(/,/g, ''));
        acc.push(value);
        return acc;
      }, [f.farmName]);
    }).filter((v) => v);

    data.columns.push(...temp);

    return data;
  }

  generateYearlyChartData() {
    const data = {x: 'x', columns: []};
    const years = this.annualSummaries.map((s) => s.fiscalYear);

    // X軸のラベル
    const x = years.map((year) => `${year}年`);
    x.unshift('x');
    data.columns.push(x);

    // グラフデータ
    const startMonth = this.annualSummaries[0].monthly[0].yearMonth % 100;
    const temp = this.agentFarmAnnualSummaries.map((f) => {
      const values = years.reduce((acc, year) => {
        const summary = f.annualSummaries.find((s) => {
          const month = s.monthly[0].yearMonth % 100;
          return s.fiscalYear === year && month === startMonth;
        });

        if (!summary) return acc;
        const month = summary.monthly.find((m) => m.yearMonth % 100 === 99);
        acc.push([null, undefined].includes(month.summary[this.itemId]) ? null : Number(month.summary[this.itemId].replace(/,/g, '')));
        return acc;
      }, []);
      return values.length ? values.reduce((a, v) => a.concat(v), [f.farmName]) : null;
    }).filter((v) => v);

    data.columns.push(...temp);

    return data;
  }

  customTooltip(data, titleFormat, valueFormat, color) {
    const index = data[0].index;
    const value = data[0].value;

    const targets = this.data.targets.reduce((acc, cur) => {
      const temp = cur.values.find((v) => v.value === value && v.index === index);
      if (temp) acc.push(temp);
      return acc;
    }, []);

    let text = '<table class="c3-tooltip">';
    text += '<tbody>';
    targets.forEach((target) => {
      text += '<tr class="c3-tooltip-name-' + target.id + '">';
      text += '<td class="name">' + target.name + '</td>';
      text += '<td class="value">' + target.value + '</td>';
      text += '</tr>';
    });
    text += '</tbody>';
    text += '</table>';
    return text;
  }
}

function fatteningItemReportChart() {
  return {
    templateUrl: 'menu/report/fattening/components/item-report-chart/index.html',
    controller: FatteningItemReportChartController,
    bindings: {
      annualSummaries: '<',
      agentFarmAnnualSummaries: '<',
      year: '<',
      frequency: '<',
      itemId: '<'
    }
  };
}

app.component('fatteningItemReportChart', fatteningItemReportChart());
