class LabelConfirmDialogController {
  constructor(
    $scope,
    $modal,
    $modalInstance,
    MasterCowLabelAPI,
    CowLabelAPI,
    DepositCowLabelAPI,
    params,
    blockUI,
    $timeout) {
    'ngInject';
    this.$modal = $modal;
    this.$modalInstance = $modalInstance;
    this.blockUI = blockUI;
    this.$timeout = $timeout;
    this.MasterCowLabelAPI = MasterCowLabelAPI;

    $scope.$on('ngRepeatFinished', () => {
      FixedMidashi.create();
    });

    const api = params.isDepositor ? DepositCowLabelAPI : CowLabelAPI;

    this.initialize(params, api);
  }

  initialize(params, api) {
    this.actionType = params.actionType;

    if (params.actionType === 'add') {
      this.title = 'ラベルの一括貼付';
      this.explanation = '貼付するラベルを選択してください';
      this.updateAction = api.bulkAdd.bind(api);
      this.isAddAction = true;
    } else {
      this.title = 'ラベルの一括除去';
      this.explanation = '除去するラベルを選択してください';
      this.updateAction = api.bulkRemove.bind(api);
      this.isAddAction = false;

      const labels = new Set();
      params.cows.forEach((cow) => {
        cow.cowLabels.forEach((label) => {
          if (label) {
            labels.add(label);
          }
        });
      });
      this.usedLabels = Array.from(labels);
    }

    this.cows = params.cows.map((c) => {
      c.cowLabelsDisplay = c.cowLabels.join('、');
      return c;
    });

    this.labels = [];
    this.setLabelSummary();

    this.validate();

    this.onClickSelectLabel();
  }

  validate() {
    this.invalid = false;

    if (this.labels.length > 0) return;

    this.invalid = true;
  }

  ok() {
    this.blockUI.start('更新中');

    const cowIds = this.cows.map((cow) => cow.cowId);

    this.MasterCowLabelAPI.available().then((res) => {
      const masters = res.data.reduce((o, m) => {
        o[m.name] = m;
        return o;
      }, {});
      const labels = this.labels.map((label) => {
        const master = masters[label];
        if (master) {
          return {
            masterCowLabelId: master.id
          };
        }
        return null;
      }).filter((label) => label);

      this.updateAction(cowIds, labels).then(() => {
        this.blockUI.done(() => {
          this.blockUI.start('更新が完了しました');
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close(this.labels);
      }).catch((error) => {
        this.errorMessage = ErrorUtil.formatErrorMessage(error.data.messages);
        this.blockUI.stop();
      });
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  onClickSelectLabel() {
    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'dialog/condition/selection-dialog.html',
      controller: 'SelectionDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'select-standard',
      resolve: {
        params: () => {
          return {
            title: this.isAddAction ? '貼付対象のラベルの選択' : '除去対象のラベルの選択',
            columnId: 'cow_labels',
            caption: '個体ラベル一覧',
            currentSelections: this.labels,
            needToSelect: true,
            conditions: this.usedLabels || []
          };
        }
      }
    });

    modalInstance.result.then((labels) => {
      this.labels = labels;
      this.setLabelSummary();
      this.validate();
    });
  }

  setLabelSummary() {
    if (this.labels.length > 0) {
      this.labelSummary = this.labels.join('、');
    } else {
      this.labelSummary = '(対象を指定してください)';
    }

    if (this.isAddAction) {
      const duplicaedCows = this.cows.filter((cow) => {
        return cow.cowLabels.some((label) => this.labels.includes(label));
      });
      if (duplicaedCows.length > 0) {
        this.showDuplicatedError = true;
        this.errorCowNos = duplicaedCows.map((cow) => cow.cowNo).join('、');
      } else {
        this.showDuplicatedError = false;
        this.errorCowNos = '';
      }
    } else {
      const notRelatedCows = this.cows.filter((cow) => {
        const allIncluded = this.labels.every((label) => cow.cowLabels.includes(label));
        return !allIncluded;
      });
      if (notRelatedCows.length > 0) {
        this.showNotRelatedError = true;
        this.errorCowNos = notRelatedCows.map((cow) => cow.cowNo).join('、');
      } else {
        this.showNotRelatedError = false;
        this.errorCowNos = '';
      }
    }
  }
}

app.controller('LabelConfirmDialogController', LabelConfirmDialogController);
