class AlertLocationListController {
  constructor(
    $rootScope,
    $scope,
    $state,
    FarmService,
    AlertService,
    DateUtilService,
    blockUI,
    SelectCowService
  ) {
    'ngInject';
    this.$state = $state;
    this.FarmService = FarmService;
    this.AlertService = AlertService;
    this.DateUtilService = DateUtilService;
    this.blockUI = blockUI;
    this.SelectCowService = SelectCowService.init();

    this.loading = true;
    this.title = '牛群移動アラート';
    this.uri = 'alert-location';

    this.callbackParam = {
      state: 'alertLocation',
      name: this.title
    };

    this.autoMovement = false;

    $scope.$on('ngRepeatFinished', () => {
      FixedMidashi.create();
    });

    this.init();
  }

  init() {
    this.FarmService.show().then((result) => {
      this.autoMovement = result.autoMovement;
      this.typeMilk = result.farmKind === '乳用';
    });
    this.load();
  }

  load() {
    this.AlertService.getAlertLocationRecent().then((result) => {
      this.cows = result.data.map((cow) => new AlertLocationDecorator(cow, this.DateUtilService, Date.now()));
      this.loading = false;
    });
  }

  useAutoMovement() {
    return this.autoMovement;
  }

  goToDetails(cowId) {
    const cowIds = this.cows.map((cow) => cow.cowId);
    const caller = {
      state: this.callbackParam.state,
      name: this.callbackParam.name,
      cowIds: cowIds
    };
    this.$state.go('cowDetail', {cowId: cowId, caller: caller});
  }

  toggleAutoMovement() {
    const updateCows = this.cows.filter((row) => row.selected)
      .map((row) => {
        return {
          id: row.obj.id,
          autoMoveTarget: !row.obj.autoMoveTarget
        };
      });

    this.blockUI.start('更新中');
    this.AlertService.updateAlertLocation(updateCows).then((res) => {
      this.blockUI.stop();
      this.cows = [];
      this.SelectCowService.init();
      this.allSelected = false;
      this.load();
    }).catch((err) => console.error(err));
  }

  goToHistory() {
    this.$state.go('alertLocationHistory', {}, {parent: this.$state.current});
  }

  onPrint() {
    print();
  }
}

app.controller('AlertLocationListController', AlertLocationListController);
