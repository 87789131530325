class SpermInfoSelectModalController {
  constructor(
    $scope,
    $modal,
    $modalInstance,
    MasterSpermAPI,
    DepositMasterSpermService,
    params,
    SessionCache
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$modal = $modal;
    this.$modalInstance = $modalInstance;
    this.rawRecords = [];
    this.selectedSperm = {};
    this.shouldDisableOk = true;
    this.params = params || {};
    this.filterItemKeys = ['spermNo', 'name'];

    SelectionBaseController.bind(this);

    // 預託元コンテキストか否かでサービスの使い分け
    this.MasterSpermAPI = params.isDeposit ? DepositMasterSpermService : MasterSpermAPI;

    this.fetchSpermMasters(this.params.spermId);

    const farm = SessionCache.farm();
    this.isAgent = farm.isAgent();

    this.$scope.$on('ngRepeatFinished', function() {
      FixedMidashi.create();

      $('.modal-table-list-lg .fixed_header_display_none_at_print table .medicine-col3').css('width', '120px');
      $('.modal-table-list-lg .fixed_header_display_none_at_print table .medicine-col4').css('width', '120px');
      $('.modal-table-list-lg .fixed_header_display_none_at_print table .medicine-col1').css('width', '50px');
    });
  }

  setSperm(id) {
    this.rawRecords.forEach((sperm) => {
      if (sperm.id === id) {
        sperm.checked = true;
        this.selectedSperm = sperm;
        this.shouldDisableOk = false;
      } else {
        sperm.checked = false;
      }
    });
  }

  ok() {
    this.$modalInstance.close(this.selectedSperm);
  }

  cancel() {
    this.$modalInstance.close(false);
  }

  openNewSperm() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/master/sperm/edit.html',
      controller: 'MasterSpermEditController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            title: '精液の新規登録',
            model: null,
            viewMode: ViewMode.CREATE,
          };
        }
      }
    }).result.then((newSperm) => {
      if (newSperm) {
        this.fetchSpermMasters(newSperm.spermNo);
      }
      return newSperm;
    });
  }

  fetchSpermMasters(checkedValue = null, keyColumn = 'id') {
    this.MasterSpermAPI.available(this.params.qwert).then((result) => {
      const rawRecords = CollectionUtil.selectableMasters(result.data, [checkedValue], keyColumn);
      this.rawRecords = rawRecords.map((sperm) => {
        if (sperm[keyColumn] === checkedValue) {
          sperm.checked = true;
          this.selectedSperm = sperm;
        }
        return sperm;
      });

      this.records = this.rawRecords;
    });
  }
}

app.controller('spermInfoSelectModalController', SpermInfoSelectModalController);
