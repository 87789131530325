class temporaryGroupViewCowDetailSummaryController {
  constructor(
    FatteningStageAPI
  ) {
    'ngInject';

    this.FatteningStageAPI = FatteningStageAPI;

    this.init();
  }

  $onChanges() {
    if (!(Object.keys(this.cow).length && this.ble)) return;

    this.index();
  }

  init() {
    this.activeTab = 'summaryTab';
    this.now = new Date();
  }

  index() {
    this.FatteningStageAPI.cow(this.cow.cowId).then((res) => {
      this.fatteningStage = res.data;
      this.showTimeline = true;
      this.setDates();
    });
  }

  setDates() {
    if (['出荷', 'へい死'].includes(this.cow.state)) {
      const eliminateDate = this.cow.eliminateDate ? new Date(Number(this.cow.eliminateDate)) : null;
      const fallingDeadDate = this.cow.fallingDeadDate ? new Date(Number(this.cow.fallingDeadDate)) : null;
      const baseDate = eliminateDate || fallingDeadDate || this.now;

      this.baseDate = DateUtil.startOfDay(baseDate);
    } else {
      this.baseDate = DateUtil.startOfDay(this.now);
    }

    this.minTimeBudgetDate = new Date(DateUtil.addYears(this.baseDate, -1));

    if (Number(this.minTimeBudgetDate) < Number(this.ble.startDate)) {
      this.minTimeBudgetDate = this.ble.startDate;
    }

    this.minActivityDate = this.minProtDate = new Date(Number(this.ble.startDate));
    this.timeBudgetDate = this.activityDate = this.protDate = this.baseDate;
  }

  updateCowDetails(eventType) {
    if (['heishi', 'touta'].includes(eventType)) {
      this.updateSensorIds();
    }
  }
}

function temporaryGroupViewCowDetailSummary() {
  return {
    restrict: 'E',
    templateUrl: 'temporary-group-view/cow-detail/summary/index.html',
    controller: temporaryGroupViewCowDetailSummaryController,
    controllerAs: 'ctrl',
    scope: true,
    replace: true,
    bindToController: {
      cow: '<',
      ble: '<',
      callerId: '<',
      updateSensorIds: '&'
    }
  };
}

app.directive('temporaryGroupViewCowDetailSummary', temporaryGroupViewCowDetailSummary);
