function chartMilkingComponent() {
  'ngInject';
  return {
    controller: 'ChartMilkingController',
    controllerAs: 'ctrl',
    templateUrl: 'components/chart/milking/index.html',
    transclude: true,
    bindings: {
      cow: '<',
      height: '<',
      paddingTop: '<',
      paddingBottom: '<',
      paddingRight: '<',
      paddingLeft: '<',
      disableHourlyTick: '<',
      defaultActivityPeriod: '<',
      onChangePeriod: '&'
    }
  };
}

app.component('chartMilking', chartMilkingComponent());
