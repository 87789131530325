// eslint-disable-next-line no-unused-vars
class EventSelectionMasterInitializerService {
  constructor(
    $q,
    CowGroupService,
    MasterMedicineAPI,
    SelectionService,
    MasterHormoneProgramAPI,
    MasterSpermAPI,
    FarmService,
    CowService,
    appConst
  ) {
    'ngInject';

    this.$q = $q;
    this.CowGroupService = CowGroupService;
    this.MasterMedicineAPI = MasterMedicineAPI;
    this.SelectionService = SelectionService;
    this.MasterHormoneProgramAPI = MasterHormoneProgramAPI;
    this.MasterSpermAPI = MasterSpermAPI;
    this.FarmService = FarmService;
    this.CowService = CowService;
    this.appConst = appConst;
  }

  /**
   * @param {object} event イベント・オブジェクト。nullの場合、全てのイベントを対象にマスターを生成します
   */
  initSelectionMaster(master) {
    const promises = [
      this.FarmService.show().then((res) => {
        master.farmKind = res.farmKind;
        this.SelectionService.index().then((res) => {
          master.selection = this.initSelectionOptions(res, master.farmKind);

          if ('calvingDifficultyMap' in master) {
            res.calvingDifficulty.forEach((calvingDifficulty) => {
              master.calvingDifficultyMap[calvingDifficulty.value] = calvingDifficulty.label;
            });
          }
        });
      })
    ];

    if ('cowGroupNameMap' in master) {
      promises.push(
        this.CowGroupService.index().then((res) => {
          master.cowGroupNameMap = this.createCowGroupNameMap(res.data);
          master.cowGroupList = this.createCowGroupList(res.data);
          master.cowPenMap = this.createCowPenMap(res.data);
        })
      );
    }

    if ('tanetsukeBulls' in master) {
      promises.push(
        this.CowService.getBulls().then((res) => {
          master.tanetsukeBulls = res.data;
        })
      );
    }

    if ('spermInfoMap' in master) {
      promises.push(
        this.MasterSpermAPI.available().then((res) => {
          const spermMasters = res.data;
          spermMasters.forEach((spermMaster) => {
            master.spermInfoMap[spermMaster.id] = {
              name: spermMaster.name,
              spermNo: spermMaster.spermNo
            };
          });
        })
      );

    }

    if ('medicineInfoMap' in master) {
      promises.push(
        this.MasterMedicineAPI.available().then((res) => {
          res.data.forEach((medicine) => {
            master.medicineInfoMap[medicine.id] = medicine;
          });
        })
      );
    }

    if ('hormoneProgramNameList' in master) {
      promises.push(
        this.MasterHormoneProgramAPI.available().then((res) => {
          master.hormoneProgramNameList = this.getHormoneProgramNameList(res.data);
          master.hormoneListMap = this.getHormoneListMap(res.data);
        })
      );
    }

    return this.$q.all(promises).then(() => {
      return master;
    });
  }

  // private

  /**
   * 牛群名のマップ作成
   */
  createCowGroupNameMap(groups) {
    const cowGroupNameMap = {};

    groups.forEach((group) => {
      cowGroupNameMap[group.cowGroupId] = group.cowGroupName || '';
    });

    return cowGroupNameMap;
  }

  /**
  * 牛群名のリスト
  */
  createCowGroupList(groups) {
    const groupList = [];

    groups.forEach((group) => {
      groupList.push({
        label: group.cowGroupName,
        value: group.cowGroupName,
        id: group.cowGroupId
      });
    });

    return groupList;
  }

  /**
  * 牛房名のマップ作成
  */
  createCowPenMap(groups) {
    const penListMap = {};

    groups.filter((group) => typeof group.pens === 'string')
      .forEach((group) => {
        penListMap[group.cowGroupId] = group.pens.split(',').
          map((val) => ({
            label: val,
            value: val
          }));
      });

    return penListMap;
  }

  /**
   * 各イベントのドロップダウンオプション作成
   * @param {Object} selections
   * @return {Object} transformedSelections
   */
  initSelectionOptions(selections, account) {
    const transformedSelections = angular.copy(selections);
    this.appConst.settingListNameMap.AccountInfo.forEach((listNameObj) => {
      transformedSelections[listNameObj.name] = getCustomSelectList(listNameObj.name, account, listNameObj.allowBlank);
    });

    // 子牛毎に出生時状態を作成(分娩)
    [1, 2, 3, 4].forEach((i) =>
      transformedSelections['birthStates' + i] = angular.copy(transformedSelections['birthStates']));

    transformedSelections['clawDiseaseNames'] = [
      '',
      '蹄底潰瘍',
      '白線病',
      '趾皮膚炎',
      '趾間フレグモーネ',
      '蹄底血斑',
      '蹄尖潰瘍',
      '変形蹄',
      '横裂蹄',
      '縦裂蹄',
      '趾間過形成',
      '蹄底菲薄化',
      '蹄球びらん',
      '趾間皮膚炎',
      '乳頭状趾皮膚炎',
      'その他',
    ];

    return transformedSelections;
  }

  /**
   * ホルモンプログラム名オプション生成
   * @param {Array} hormonePrograms
   */
  getHormoneProgramNameList(hormonePrograms) {
    const hormoneProgramNameList = CowEvent.hormoneProgramNameListDefault;

    hormonePrograms.forEach((program, index) => {
      hormoneProgramNameList.push({
        label: `${index + 1}: ${program.name}`,
        value: program.name,
      });
    });

    return hormoneProgramNameList;
  }

  /**
   * ホルモンプログラム名オプション生成
   * @param {Array} hormonePrograms
   */
  getHormoneListMap(hormonePrograms) {
    const hormoneListMap = [];

    hormonePrograms.forEach((program, index) => {
      // ホルモンコードオプション生成
      hormoneListMap[program.name] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        .reduce((arr, value) => {
          if (!program[`hormoneCode${value}`]) return arr;

          arr.push({
            label: program[`hormoneName${value}`],
            value: program[`hormoneCode${value}`],
            period: program[`hormonePeriod${value}`],
          });
          return arr;
        }, []);
    });

    return hormoneListMap;
  }

}

app.service('EventSelectionMasterInitializerService', EventSelectionMasterInitializerService);
