/* eslint-disable */

class AlertLocationDecorator {
  constructor(obj,
    DateUtilService,
    date) {
    this.obj = obj;
    this.DateUtilService = DateUtilService;
    this.selected = false;
    this.date = date;

    // TODO: Proxyに変える
    Object.keys(obj).forEach((key) => {
      if (key in this) return;
      this[key] = obj[key];
    });
  }

  get cowUid() {
    return formatCowUid(this.obj.cowUid);
  }

  get cowLabels() {
    const cowLabels = this.obj.cowLabels || '';
    return cowLabels ? cowLabels.replace(/[[\]']+/g, '')
      .split(',')
      .filter((value, index, arr) => arr.indexOf(value) === index) : [];
  }

  get birthNumber() {
    return this.obj.birthNumber || 0;
  }

  get dim() {
    return this.DateUtilService.diffDays(this.obj.latestCalvingDate, this.date) + '日';
  }

  get alertAt() {
    return this.DateUtilService.toMMDDHHmm(this.obj.alertAt);
  }

  get autoMoveTarget() {
    if (this.obj.autoMoveTarget) {
      return '対象';
    }
    return '対象外'
  }

  get moved() {
    return this.obj.moved ? '済' : '未';
  }

}
