// eslint-disable-next-line no-unused-vars
class CustomlistSelection {
  static selectionColumnIds() {
    return [
      'breed', 'breeding_exclusion_reason', 'castrat_method',
      'color', 'dehorn_method', 'expelled_reason', 'gender',
      'latest_breeding_method', 'pregnancy', 'pregnant_breeding_method', 'state'
    ];
  }

  static userDefinedSelectionColumnIds() {
    return [
      'buyer', 'cow_labels', 'introduce_state', 'judge_pregnant_timing', 'other_reproduction_work',
      'producing_area', 'producing_farm_name', 'raising_farm_name', 'vaccine_timing'
    ];
  }

  static isSelection(columnId) {
    const columnIds = new Set(CustomlistSelection.selectionColumnIds());
    return columnIds.has(columnId);
  }

  static isUserDefined(columnId) {
    const columnIds = new Set(CustomlistSelection.userDefinedSelectionColumnIds());
    return columnIds.has(columnId);
  }

  static generateSelectionLabel(selections) {
    if (!selections || selections.length === 0) {
      return '(対象が選択されていません)';
    }
    return selections.join('、');
  }

  static validate(conditions) {
    const invalid = conditions.some((condition) => {
      return condition.conditionDetails.some((detail) => {
        if (detail.fieldType === 'SELECTION') {
          if (!detail.conditionValue.selections) return true;
          if (detail.conditionValue.selections.length === 0) return true;
        } else if (detail.fieldType === 'MASTER') {
          if (!detail.conditionValue.ids) return true;
          if (detail.conditionValue.ids.length === 0) return true;
        }
        return false;
      });
    });
    return !invalid;
  }
}
