/**
 * @deprecated
 * 牛個体登録メニューはReactに移行されました。
 * IEユーザーへの対応のために残しています。
 */
class CowRegisterController {
  constructor(
    $scope,
    $q,
    $state,
    BleIdService,
    CowGroupService,
    CowService,
    MasterHormoneProgramAPI,
    StandardDialog,
    SelectionService,
    MasterSpermAPI,
    MasterMarketAPI,
    UserDefinedSelectionAPI,
    SessionCache
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$q = $q;
    this.$state = $state;
    this.formData = {};
    this.formData.stopShipmentMilkingFlg = false;
    this.formData.stopShipmentBeefFlg = false;
    this.penListMap = {};
    this.penList = [];
    this.groupSelection = [];
    this.errors = {};

    this.CowGroupService = CowGroupService;
    this.BleIdService = BleIdService;
    this.CowService = CowService;
    this.StandardDialog = StandardDialog;
    this.MasterHormoneProgramAPI = MasterHormoneProgramAPI;
    this.MasterSpermAPI = MasterSpermAPI;
    this.MasterMarketAPI = MasterMarketAPI;
    this.UserDefinedSelectionAPI = UserDefinedSelectionAPI;
    this.SelectionService = SelectionService;

    this.farm = SessionCache.farm();
    this.isMilk = this.farm.isMilk();
    this.isBeef = this.farm.isBeef();

    this.cowValidator = new CowValidator(this.farm);

    this.useCowNoInput = this.farm.useCowNo() || this.farm.useName();

    //乳用牧場の場合は、デフォルトで性別をメスにセットする
    this.formData.gender = this.isMilk ? 'メス' : null;

    this.isEntering = true;

    this.init();
  }

  init() {
    this.CowGroupService.index().then((res) => {
      const groupList = res.data || [];
      groupList.forEach((group) => {
        // 牛群ドロップダウン
        this.groupSelection.push({
          label: group.cowGroupName,
          value: group.cowGroupId,
        });

        // 牛房ドロップダウン用マップ
        this.penListMap[group.cowGroupId] = [];
        if (typeof group.pens === 'string') {
          group.pens.split(',').forEach((val) => {
            this.penListMap[group.cowGroupId].push({label: val, value: val});
          });
        }
      });
    }).catch((err) => console.error(err));

    this.$q.all([
      this.SelectionService.index(),
      this.MasterHormoneProgramAPI.index(),
      this.MasterSpermAPI.index(),
      this.MasterMarketAPI.index(),
      this.UserDefinedSelectionAPI.available()
    ]).then((res) => {
      const selection = res[0];
      if (!this.formData.breed) {
        this.formData.breed = selection['breed'][0]['value'];
      }

      selection['hormoneNameList'] = CollectionUtil.selectableMasters(res[1].data).map((hormone) => {
        return {label: hormone.name, value: hormone.name};
      });
      selection['spermInfo'] = CollectionUtil.selectableMasters(res[2].data).map((sperm) => {
        return {label: sperm.spermNo, value: sperm.spermNo};
      });
      selection['masterMarkets'] = CollectionUtil.selectableMasters(res[3].data).map((market) => {
        return {label: market.name, value: market.id};
      });

      const userDefinedSelections = res[4].data;
      UserDefinedOption.definitions.forEach((listNameObj) => {
        selection[listNameObj.name] = getCustomSelectList(
          listNameObj.name,
          userDefinedSelections,
          listNameObj.allowBlank
        );
      });

      this.existsRaisingFarmNames = selection.raisingFarmNames.length > 0;
      this.existsProducingAreas = selection.producingAreas.length > 0;
      this.existsProducingFarmNames = selection.producingFarmNames.length > 0;

      this.selection = selection;

      if (0 < this.selection.state.length) {
        this.formData.state = this.selection.state[0].label;
      }
    });
  }

  onChangeGender() {
    this.showBreedingBull = this.formData.gender === 'オス';
    if (!this.showBreedingBull) {
      this.formData.breedingBull = false;
    }
  }

  changeGroupId() {
    this.penList = [];
    if (this.formData.cowGroupId) {
      this.penList = this.penListMap[this.formData.cowGroupId];
    }
  }

  changeCowUid() {
    if (this.useCowNoInput) return;
    const cowUid = this.formData.cowUid;
    const length = this.farm.useRegulationNumber4() ? 4 : 5;
    this.formData.cowNo = this.CowService.generateCowNo(cowUid, length);
  }

  clearInput(targetName) {
    this.formData[targetName] = null;
  }

  confirm() {
    const bleId = this.formData.bleId;
    const bleIdStartDate = this.formData.bleIdStartDate;
    if ((bleId && !bleIdStartDate) || (!bleId && bleIdStartDate)) {
      this.StandardDialog.showMessage({
        title: '牛個体登録',
        text1: 'センサー番号と適用開始日の両方を入力してください。',
      });
      return;
    }

    // 入力されたセンサー番号を0埋めの12桁に整形する
    this.formData.bleId = this.BleIdService.formatBleId(bleId);

    this.cow = CowConfirmation.toDisplayObj(this.formData, this.groupSelection, this.selection.masterMarkets);
    this.isEntering = false;
  }

  validateCowNo(cowNo) {
    this.invalid = true;

    this.errors['cowNo'] = this.cowValidator.validateCowNo(cowNo);

    if (this.errors['cowNo'].valid) this.invalid = false;
  }

  isValidField(field) {
    if (!this.errors[field]) return true;
    return this.errors[field].valid;
  }

  toEntering() {
    this.isEntering = true;
  }
}

app.controller('CowRegisterController', CowRegisterController);
