class MobileCowSearchModalController {
  constructor(
    $modalInstance,
    $scope,
    $timeout,
    $window,
    MobileCowSearchUIService,
    titleLabel,
    submitLabel,
    SessionCache
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$window = $window;
    this.MobileCowSearchUIService = MobileCowSearchUIService;
    this.titleLabel = titleLabel;
    this.submitLabel = submitLabel;
    this.SessionCache = SessionCache;

    this.searchKey = null;
    this.activeOnly = true;

    this.searchTypes = [
      {
        label: '',
        value: 'cowNo',
        placeholder: '番号を入力してください'
      },
      {
        label: '個体識別番号',
        value: 'cowUid',
        placeholder: '番号を入力してください (4桁か10桁)'
      },
      {
        label: 'センサー番号',
        value: 'bleId',
        placeholder: '番号を入力してください'
      }
    ];

    this.selectedSearchType = this.SessionCache.cowSearchOption() || 'cowNo';

    $window.onresize = this.calculateMainAreaHeight.bind(this);
    this.$scope.$on('$destroy', () => {
      $window.onresize = null;
    });
  }

  generatePlaceholder(selectedSearchType) {
    const searchType = this.searchTypes.find((obj) => {
      return obj.value === selectedSearchType;
    });
    return searchType ? searchType.placeholder : '';
  }

  onCancelClick() {
    this.$modalInstance.close({
      status: {
        CANCEL: true
      }
    });
  }

  onSubmitClick() {
    if (!this.isValidIf(this.selectedSearchType, this.searchKey)) return;

    this.SessionCache.store('cowSearchOption', this.selectedSearchType);

    this.$modalInstance.close({
      status: {
        SUBMIT: true
      },
      searchType: this.selectedSearchType,
      searchKey: this.searchKey,
      activeOnly: this.activeOnly,
    });
  }

  closeKeyboard() {
    document.activeElement.blur();
  }

  calculateMainAreaHeight() {
    // ウィンドウの高さを変更中に呼ばれると高さが不正になるためブロックする
    if (this.applying) {
      return;
    }
    this.applying = true;

    this.$timeout(() => {
      const windowHeight = $(window).innerHeight();
      const headerHeight = $('header.layout-header .container').innerHeight();
      this.$scope.$apply(() => {
        this.mainAreaHeight = windowHeight - headerHeight;
        this.applying = false;
      });
    });
  }

  isCowNoLabel(label) {
    return label === 'cowNo';
  }

  isValidIf(searchType, searchKey) {
    if (searchType === 'cowUid') {
      if (!(searchKey.length === 4 || searchKey.length === 10)) {
        this.MobileCowSearchUIService.openInvalidCowUidModal();
        return false;
      }
    }
    return true;
  }
}

app.controller('MobileCowSearchModalController', MobileCowSearchModalController);
