class DepositAlertSummaryController {
  constructor(
    $state,
    SessionCache,
    DepositAlertAPI
  ) {
    'ngInject';
    this.$state = $state;

    this.init(SessionCache, DepositAlertAPI);
  }

  init(SessionCache, DepositAlertAPI) {
    const customlistSystemConfigs = SessionCache.customlistSystemConfigs();
    this.customlistConfigMap = customlistSystemConfigs
      .filter((config) => config.configType.startsWith('alert'))
      .reduce((o, config) => {
        o[config.configType] = config.customlistId;
        return o;
      }, {});

    DepositAlertAPI.summary().then((res) => {
      this.alertSummary = res.data.summary;
    });
  }

  linkToAlertList(configType) {
    const customlistId = this.customlistConfigMap[configType];
    if (customlistId) {
      this.$state.go('alertList', {customlistId: customlistId});
    }
  }
}

function depositAlertSummary() {
  return {
    templateUrl: 'menu/deposit/home/content/alert-summary.html',
    controller: DepositAlertSummaryController,
    controllerAs: 'ctrl',
  };
}

app.component('depositAlertSummary', depositAlertSummary());
