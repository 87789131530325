class MobileBulkEntryEventsModal {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  open(eventType, cowIds, cowType, isDepositor, qwert, caller) {
    const params = {
      eventType: eventType,
      cowIds: cowIds,
      cowType: cowType,
      isDepositor: isDepositor,
      qwert: qwert,
      caller: caller,
      modalInstance: null
    };
    const modalInstance = this.$modal.open({
      templateUrl: 'mobile/bulk-entry/mobile-bulk-entry-register.html',
      controller: 'MobileBulkEntryEventsController as ctrl',
      resolve: {
        $stateParams: () => {
          return params;
        }
      }
    });
    // BulkEntryEventsControllerに $modalInstance をDIするとPC版でエラーになるので、パラメータ経由でインスタンスを渡す
    params.modalInstance = modalInstance;

    return modalInstance.result;
  }
}
app.service('MobileBulkEntryEventsModal', MobileBulkEntryEventsModal);
