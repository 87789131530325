class temporaryGroupViewCowDetailLineChartController {
  constructor(
    SessionCache
  ) {
    'ngInject';

    const farm = SessionCache.farm();

    this.init(farm);
  }

  $onChanges() {
    if (!(Object.keys(this.cow).length && this.fatteningStage)) return;

    this.index();
  }

  init(farm) {
    this.isMilk = farm.isMilk();
    this.showFeed = !farm.isUMLight();
    this.showChart = false;
    this.height = null;
  }

  index() {
    this.showChart = true;
  }
}

function temporaryGroupViewCowDetailLineChart() {
  return {
    restrict: 'E',
    templateUrl: 'temporary-group-view/cow-detail/summary/line-chart.html',
    controller: temporaryGroupViewCowDetailLineChartController,
    controllerAs: 'ctrl',
    scope: true,
    replace: true,
    bindToController: {
      cow: '<',
      fatteningStage: '<'
    }
  };
}

app.directive('temporaryGroupViewCowDetailLineChart', temporaryGroupViewCowDetailLineChart);
