class DhiImportHmrtController {
  constructor(
    SessionCache
  ) {
    'ngInject';

    this.account = SessionCache.account();

    this.init();
  }

  $onChanges(changes) {
    if (changes.activeImportType) {
      this.clearSelectedFiles();
    }
  }

  init() {
    this.selectedFiles = [];
  }

  addSelectedFiles(name, files) {
    files.forEach((file) => {
      this.selectedFiles.push(file);
    });

    this.selectedFiles = this.selectedFiles.filter((value1, index, array) => {
      return (array.findIndex((value2) => {
        return value1.name === value2.name;
      }) === index);
    });
  }

  showHmrtImport() {
    return this.activeImportType === 'hmrt';
  }

  showSelectedFiles() {
    return this.selectedFiles.length > 0;
  }

  disabledUpload() {
    return !this.showSelectedFiles();
  }

  clearSelectedFiles() {
    this.selectedFiles = [];
  }

  deleteSelectedFiles(id) {
    this.selectedFiles = this.selectedFiles.filter((file, index) => {
      return id !== index;
    });
  }

  uploadConfirm() {
    if (this.disabledUpload()) return;

    const fd = new FormData();

    fd.append('dhi_linkage', this.activeImportType);
    fd.append('operation_user_name', this.account.name);

    this.selectedFiles.forEach((file) => {
      fd.append('files[]', file);
    });

    this.uploadSelectedFiles({
      fd: fd
    });
  }
}

function dhiImportHmrtComponent() {
  return {
    templateUrl: 'menu/dhi/dhi-import/import-hmrt.html',
    controller: DhiImportHmrtController,
    controllerAs: 'ctrl',
    bindings: {
      activeImportType: '<',
      changeTab: '&',
      classHeading: '&',
      uploadSelectedFiles: '&'
    }
  };
}

app.component('dhiImportHmrt', dhiImportHmrtComponent());
