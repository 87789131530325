class SwitchFarmDialogController {
  constructor(
    $scope,
    $timeout,
    $modalInstance,
    SessionCache,
    FarmService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$modalInstance = $modalInstance;
    this.FarmService = FarmService;

    this.initialize(SessionCache.account());
  }

  initialize(account) {
    this.$scope.$on('ngRepeatFinished', () => {
      FixedMidashi.create();
    });

    this.showFarmDetail = !account.isOrganizer();
    this.invalid = true;
    this.noData = false;
    this.showClickableListItems = false;
    this.selectedFarm = null;
    this.records = [];
    this.FarmService.index().then((res) => {
      const records = res.data.map((r) => {
        return {
          id: r.id,
          farmName: r.farmName,
          farmType: new Farm(r).farmTypeDescription(),
          contractedPlan: r.contractedPlan,
          managementStyleLabel: r.managementStyleLabel
        };
      });
      this.records = records;
      this.$timeout(() => {
        this.showClickableListItems = true;
      });
    });
  }

  select(farm) {
    if (this.selectedFarm) {
      this.selectedFarm.checked = false;
    }
    farm.checked = true;
    this.selectedFarm = farm;
    this.invalid = false;
  }

  changeSearchText() {
    const searchText = new RegExp(`${this.searchText}`, 'i');
    const filtered = this.records.filter((record) => {
      return record.farmName.match(searchText) ||
        record.farmType.match(searchText) ||
        record.contractedPlan.match(searchText) ||
        record.managementStyleLabel.match(searchText);
    });

    this.noData = false;
    this.showClickableListItems = false;

    if (filtered.length === 1) {
      this.selectedFarm = filtered[0];
      this.records = this.records.map((record) => {
        return {
          id: record.id,
          farmName: record.farmName,
          farmType: record.farmType,
          contractedPlan: record.contractedPlan,
          managementStyleLabel: record.managementStyleLabel,
          checked: record.id === filtered[0].id
        };
      });

      this.$timeout(() => {
        this.showClickableListItems = true;
      });
    } else {
      this.selectedFarm = null;
      this.records = this.records.map((record) => {
        return {
          id: record.id,
          farmName: record.farmName,
          farmType: record.farmType,
          contractedPlan: record.contractedPlan,
          managementStyleLabel: record.managementStyleLabel,
          checked: false
        };
      });

      if (filtered.length === 0) {
        this.$timeout(() => {
          this.noData = true;
        }, 300);
      } else {
        this.$timeout(() => {
          this.showClickableListItems = true;
        });
      }
    }
  }

  ok(farm) {
    if (farm) {
      if (this.selectedFarm) {
        this.selectedFarm.checked = false;
      }

      farm.checked = true;
      this.selectedFarm = farm;
      this.invalid = false;
    }

    const result = {id: this.selectedFarm.id, farmName: this.selectedFarm.farmName};
    this.$modalInstance.close(result);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  classErrorMessage() {
    return this.noData ? 'uModal__errorMessage--show' : 'uModal__errorMessage';
  }
}

app.controller('SwitchFarmDialogController', SwitchFarmDialogController);
