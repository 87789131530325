// eslint-disable-next-line no-unused-vars
class UmPlatformPartner {
  constructor(data) {
    this.data = angular.extend({}, data);
  }

  hasCoolPescon() {
    return this.data['coolpescon'];
  }
}
