class MilkingScheduleController {
  constructor(
    $scope,
    $modal,
    MilkingScheduleService,
    DateUtilService
  ) {
    'ngInject';
    this.$scope = $scope;
    this.$modal = $modal;
    this.MilkingScheduleService = MilkingScheduleService;
    this.DateUtilService = DateUtilService;

    this.milkingSchedules = [];

    this.init();
  }

  init() {
    this.MilkingScheduleService.index().then((res) => {
      this.milkingSchedules = res.data;

      // ※以下のメッセージは現状では未使用です
      this.milkingSchedules.forEach((r) => {
        r['explanation'] = `${r.boundaryStartAt} ～ ${r.boundaryEndAt} は ${r.milkingOrder} 回目`;
      });
    });
  }

  edit() {
    const records = angular.copy(this.milkingSchedules);
    if (!records.length) {
      records.push({'milkingOrder': 1, 'startAt': '0:00', 'endAt': '23:59'});
    }

    this.$modal.open({
      templateUrl: 'menu/setting/milking-schedule/edit.html',
      controller: 'MilkingScheduleEditController',
      controllerAs: 'ctrl',
      width: 'md',
      resolve: {
        params: () => {
          return {
            milkingSchedules: records
          };
        }
      }
    }).result.then(() => {
      this.init();
    });
  }
}

app.controller('MilkingScheduleController', MilkingScheduleController);
