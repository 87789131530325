class CertificateBirthAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  /**
   * 指定した条件に合致する種付記録を返します。
   *
   * @param {object} condition
   * @return {object} HTTP Response
   *
   * conditionは以下のデータ形式になります。
   * {
   *    birthStartDate: 対象期間の開始日 (Dateインスタンス),
   *    birthEndDate: 対象期間の終了日 (Dateインスタンス),
   *    cowNos: 対象の牛個体の牛番号の配列,
   *    cowUids: 対象の牛個体の個体識別番号の配列,
   *    motherCowNos: 対象の母牛の牛番号の配列,
   *    motherCowUids: 対象の母牛の個体識別番号の配列
   * }
   */
  search(condition) {
    const params = {
      cowNos: condition.cowNos || [],
      cowUids: condition.cowUids || [],
      motherCowNos: condition.motherCowNos || [],
      motherCowUids: condition.motherCowUids || [],
    };
    if (condition.birthStartDate) {
      params.birthStartDate = DateUtil.toMSec(condition.birthStartDate);
    }
    if (condition.birthEndDate) {
      params.birthEndDate = DateUtil.toMSec(condition.birthEndDate);
    }

    return this.APIService.post('/certificate/birth', params);
  }
}

app.service('CertificateBirthAPI', CertificateBirthAPI);
