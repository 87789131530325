class MobileCustomlistCowDetailDialog {
  constructor(
    $state,
    EventRegistrationSelectionService
  ) {
    'ngInject';
    this.$state = $state;

    this.customlist = new Customlist();
    this.EventRegistrationSelectionService = EventRegistrationSelectionService;
  }

  onCowClick(cow) {
    this.cowIndex = this.cows.indexOf(cow);
  }

  onCowDetailClick() {
    const filterIllness = this.$state.params.filter === 'illness';

    let params = {
      customlistId: this.caller.customlistId,
      listName: this.caller.name,
      cowGroupId: this.caller.cowGroupId,
      cowIndex: this.cowIndex,
    };

    if (filterIllness) {
      params.filter = 'illness';
    }

    const caller = {
      state: this.caller.state,
      name: this.caller.name,
      params,
      bqNotes: `customlist config_type = ${this.caller.configType}`
    };

    this.$state.go('mobileCowDetail', {
      cowId: this.cows[this.cowIndex].cowId,
      caller
    });
  }

  onCloseCowDetailClick() {
    this.showDetailDialog = false;
    this.cowIndex = null;
  }

  shouldRenderCarouselItem(index) {
    return index > this.cowIndex - 3 && index < this.cowIndex + 3;
  }

  isCommentColumn(column) {
    return column.columnId === 'comments';
  }

  isCowLabelColumn(column) {
    return column.columnId === 'cow_labels';
  }

  isAlertTypeColumn(column) {
    return column.columnId === 'alert_type';
  }

  isSummaryColumn(column) {
    return this.customlist.isSummaryColumn(column);
  }

  isDefaultColumn(column) {
    return !this.isSummaryColumn(column) && !this.isCowLabelColumn(column) && !this.isCommentColumn(column) &&
      !this.isAlertTypeColumn(column);
  }

  // alertType「illness」などで指定したかったがPCの印刷時に影響がでてしまうため苦肉の策
  alertLabelClass(alertLabel) {
    if (alertLabel === '急性(改)') return 'alertLabel--acuteIllness';
    if (alertLabel === '急性') return 'alertLabel--illness';
    if (alertLabel === '慢性') return 'alertLabel--chronicIllness';
  }

  displayEventSelection() {
    const filterIllness = this.$state.params.filter === 'illness';

    let params = {
      customlistId: this.caller.customlistId,
      listName: this.caller.name,
      cowGroupId: this.caller.cowGroupId,
      cowIndex: this.cowIndex,
    };

    if (filterIllness) {
      params.filter = 'illness';
    }

    const caller = {
      state: this.caller.state,
      name: this.caller.name,
      params,
      bqNotes: `customlist config_type = ${this.caller.configType}`
    };

    this.EventRegistrationSelectionService.displayEventSelection(this.cows[this.cowIndex].cowId, caller);
  }
}

function mobileCustomlistCowDetailDialog() {
  return {
    templateUrl: 'mobile/customlist/base/cow-detail-dialog/index.html',
    controller: MobileCustomlistCowDetailDialog,
    controllerAs: 'ctrl',
    bindings: {
      caller: '<',
      cows: '<',
      columns: '<',
      cowIndex: '=',
      showDetailDialog: '=',
    }
  };
}

app.component('mobileCustomlistCowDetailDialog', mobileCustomlistCowDetailDialog());
