class EmbryoSelectDialogController {
  static get RETURN_STATUS() {
    return {
      OK: 'ok',
      CANCEL: 'cancel'
    };
  }

  constructor(
    $scope,
    $modal,
    $modalInstance,
    MasterEmbryoAPI,
    params,
    CowService,
    SessionCache
  ) {
    'ngInject';
    this.$scope_ = $scope;
    this.$modal = $modal;
    this.$modalInstance_ = $modalInstance;
    this.params = params;
    this.MasterEmbryoAPI = MasterEmbryoAPI;
    this.CowService = CowService;

    const farm = SessionCache.farm();
    this.isAgent = farm.isAgent();

    this.onInit();
  }

  onInit() {
    if (this.isMilkFarm()) {
      this.filterItemKeys = ['name', 'masterSpermNo'];
      this.placeholder = '受精卵名称、精液番号で検索する';
    } else {
      this.filterItemKeys = ['name'];
      this.placeholder = '受精卵名称で検索する';
    }

    SelectionBaseController.bind(this);

    this.fetchCowNoList();
    this.fetchEmbryoMasters();
  }

  fetchCowNoList() {
    this.CowService.getCowNoList().then((res) => {
      this.cowNoList = res.data;
    }).catch((err) => console.error(err));
  }

  fetchEmbryoMasters() {
    // TODO: グループ管理に対応するため、呼出元から受精卵マスタをパラメータで受け取るか
    //       預託フラグを受け取るなどの対応が必要
    return this.MasterEmbryoAPI.available()
      .then((res) => {
        this.rawRecords = CollectionUtil.selectableMasters(res.data, [this.params.masterEmbryoId]);

        const selectedEmbryo = this.rawRecords.find((embryoMaster) => {
          return Number(embryoMaster.id) === Number(this.params.masterEmbryoId);
        });
        if (selectedEmbryo) {
          selectedEmbryo.checked = true;
        }

        this.records = this.rawRecords;
      }).catch((error) => {
        console.error(error);
      });
  }

  ok() {
    const result = {
      status: EmbryoSelectDialogController.RETURN_STATUS.CANCEL,
      embryoMaster: null
    };
    this.rawRecords.forEach((embryoMaster) => {
      if (embryoMaster.checked) {
        result.status = EmbryoSelectDialogController.RETURN_STATUS.OK;
        result.masterEmbryoId = embryoMaster.id;
        result.masterEmbryoName = embryoMaster.name;
      }
    });

    this.$modalInstance_.close(result);
  }

  cancel() {
    this.$modalInstance_.dismiss({
      status: EmbryoSelectDialogController.RETURN_STATUS.CANCEL,
      embryoMaster: null
    });
  }

  onAddEmbryoClick() {
    this.openNewEmbryoDialog();
  }

  setChecked(id) {
    this.rawRecords.map((r) => {
      if (r.id === id) {
        r.checked = true;
      } else {
        r.checked = false;
      }

      return r;
    });
  }

  isMilkFarm() {
    return this.params.farmKind === '乳用';
  }

  isMeatFarm() {
    return this.params.farmKind === '肉用';
  }

  openNewEmbryoDialog() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/master/embryo/edit.html',
      controller: 'MasterEmbryoEditController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            title: '受精卵の登録',
            model: null,
            viewMode: ViewMode.CREATE,
            cowNoList: this.cowNoList
          };
        }
      }
    }).result.then((result) => {
      if (!result) return;

      this.fetchEmbryoMasters()
        .then(() => {
          this.rawRecords.forEach((m) => m.checked = m.name === result.name);
        });
    });
  }
}

app.controller('EmbryoSelectDialogController', EmbryoSelectDialogController);
