class InseminatorDialog {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  execute() {
    this.modalConfig = {
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'components/dialog/inseminator/index.html',
      controller: 'InseminatorDialogController',
      controllerAs: 'ctrl',
      backdrop: false
    };
    this.$modal.open(this.modalConfig);
  }
}

app.service('InseminatorDialog', InseminatorDialog);
