class EmbryoRecoveryReportController {
  constructor(
    $rootScope,
    $modal,
    $state,
    $stateParams,
    EmbryoRecoveryReportAPI,
    CowGroupService,
    MasterCowLabelAPI,
    SessionCache,
    blockUI,
    DetailsStateFactory,
    ReproductionConfigService
  ) {
    'ngInject';

    this.$modal = $modal;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.EmbryoRecoveryReportAPI = EmbryoRecoveryReportAPI;
    this.CowGroupService = CowGroupService;
    this.MasterCowLabelAPI = MasterCowLabelAPI;
    this.blockUI = blockUI;
    this.DetailsStateFactory = DetailsStateFactory;
    this.ReproductionConfigService = ReproductionConfigService;
    this.farm = new Farm(SessionCache.farm());
    this.today = DateUtil.today();

    this.sortItems = [
      {key: 'cowNoAsc', label: '牛番号の昇順'},
      {key: 'cowNoDesc', label: '牛番号の降順'},
      {key: 'embryoRecoveryDateDesc', label: '採卵日の新しい順'},
      {key: 'embryoRecoveryDateAsc', label: '採卵日の古い順'},
      {key: 'recoveryCountDesc', label: '採卵回数の多い順'},
      {key: 'recoveryCountAsc', label: '採卵回数の少ない順'}
    ];

    this.records = [];
    this.filteredRecords = [];
    this.cowLabels = [];

    this.requestParams = {
      startDate: DateUtil.subtractDays(this.today, 183),
      endDate: this.today,
    };
    this.filterParams = [];
    this.sortBy = 'cowNoAsc';

    this.cows = [];
    this.componentFuncs = {};
    this.callbackParam = {
      state: 'embryoRecoveryReport'
    };
    this.callerFuncs = {};
    this.needLabelAction = true;
    this.isDepositor = this.farm.isDepositor();
  }

  $onInit() {
    if (this.$stateParams.requestParams) {
      this.requestParams = angular.copy(this.$stateParams.requestParams);
    }
    if (this.$stateParams.filterParams) {
      this.filterParams = angular.copy(this.$stateParams.filterParams);
    }
    if (this.$stateParams.sortBy) {
      this.sortBy = this.$stateParams.sortBy;
    }

    this.ReproductionConfigService.show().then((res) => {
      const embryoRecoveryRank = res.data.embryoRecoveryRank;

      this.embryoRecoveryRankItemIds = Object.entries(embryoRecoveryRank).filter(([_, value]) => {
        return value.use;
      }).map(([key, value]) => {
        const {subrank} = value;
        const rank = `${key[0].toUpperCase()}${key.slice(1)}`;

        if (!subrank || subrank === 1) {
          return `embryoRecoveryRank${rank}`;
        }

        return Array(subrank).fill('').map((_, index) => {
          const subrank = index + 1;

          return `embryoRecoveryRank${rank}${subrank}`;
        });
      }).flat();

      this.request();
    });

    this.MasterCowLabelAPI.index()
      .then(({data}) => {
        this.cowLabels = data;
      });
  }

  request() {
    this.blockUI.start('ロード中');
    return this.EmbryoRecoveryReportAPI.index(this.requestParams.startDate, this.requestParams.endDate)
      .then(({data: records}) => {
        this.records = records.map((record) => {
          record.monthAge = DateUtil.monthAge(record.birthday, this.today);

          record.embryoRecoveries = record.embryoRecoveries.map((item) => {
            [
              'embryoRecoveryRankA',
              'embryoRecoveryRankB',
              'embryoRecoveryRankC',
              'embryoRecoveryRankD',
              'embryoRecoveryUnfertilized'
            ].map((itemId) => {
              delete item[itemId];
            });

            item.embryos.map((embryo) => {
              const {rank, count} = embryo;
              const itemId = `embryoRecoveryRank${rank[0].toUpperCase()}${rank.slice(1)}`;

              item[itemId] = count;
            });

            return item;
          });

          const extraParams = this.getRecordExtraParams(record);
          return Object.assign(record, extraParams);
        });

        return this.CowGroupService.index();
      })
      .then(({data: cowGroups}) => {
        this.cowGroups = cowGroups;

        this.records = this.records.map((record) => {
          record.cowGroup = cowGroups.find((cowGroup) => cowGroup.cowGroupId === record.cowGroupId);
          return record;
        });
      })
      .finally(() => {
        this.blockUI.stop();
        this.filter();
      });
  }

  filter() {
    const records = this.filterParams.reduce((records, {key, operator, value}) => {
      switch (key) {
      case 'cowNo':
        if (value === null) break;

        const filterCowNoList = value.split(',').map((str) => str.trim());
        records = records.filter((record) => {
          const recordCowNoStr = String(record.cowNo);
          switch (operator) {
          case 'equal':
            return filterCowNoList.includes(recordCowNoStr);
          case 'notEqual':
            return !filterCowNoList.includes(recordCowNoStr);
          case 'include':
            return filterCowNoList.some((filterCowNo) => recordCowNoStr.includes(filterCowNo));
          }
        });
        break;

      case 'embryoRecoveryDate':
      case 'monthAge':
      case 'birthNumber':
      case 'recoveryCount':
        if (value[0] !== null) {
          records = records.filter((record) => record[key] >= Number(value[0]));
        }
        if (value[1] !== null) {
          records = records.filter((record) => record[key] <= Number(value[1]));
        }
        break;

      case 'cowGroupId':
      case 'state':
        records = records.filter((record) => {
          switch (operator) {
          case 'include':
            return value.includes(String(record[key]));
          case 'exclude':
            return !value.includes(String(record[key]));
          }
        });
        break;

      case 'cowLabels':
        records = records.filter((record) => {
          switch (operator) {
          case 'include':
            return value.some((cowLabel) => record[key].includes(cowLabel));
          case 'exclude':
            return !value.some((cowLabel) => record[key].includes(cowLabel));
          }
        });
        break;
      }

      return records;
    }, angular.copy(this.records));

    this.filteredRecords = records;
    this.sort();
  }

  sort() {
    this.filteredRecords.sort((record1, record2) => {
      switch (this.sortBy) {
      case 'cowNoAsc':
        return this.compareString(record1.cowNoForOrder, record2.cowNoForOrder, true);
      case 'cowNoDesc':
        return this.compareString(record1.cowNoForOrder, record2.cowNoForOrder, false);
      case 'cowNameAsc':
        return this.compareString(record1.cowName, record2.cowName, true);
      case 'cowNameDesc':
        return this.compareString(record1.cowName, record2.cowName, false);
      case 'embryoRecoveryDateAsc':
        return this.compareNumber(record1.embryoRecoveryDate, record2.embryoRecoveryDate, true);
      case 'embryoRecoveryDateDesc':
        return this.compareNumber(record1.embryoRecoveryDate, record2.embryoRecoveryDate, false);
      case 'recoveryCountAsc':
        return this.compareNumber(record1.embryoRecoveries.length, record2.embryoRecoveries.length, true);
      case 'recoveryCountDesc':
        return this.compareNumber(record1.embryoRecoveries.length, record2.embryoRecoveries.length, false);
      }
    });
  }

  onClickCow($event, record) {
    if ($event.target.tagName !== 'INPUT') {
      record.selected = !record.selected;
    }
    this.componentFuncs.onClickCow($event, this.filteredRecords);
  }

  goToCowDetail(record) {
    const cowIds = this.filteredRecords.map(({cowId}) => cowId);

    const caller = {
      name: '採卵成績',
      state: 'embryoRecoveryReport',
      cowIds,

      params: {
        requestParams: this.requestParams,
        filterParams: this.filterParams,
        sortBy: this.sortBy,
      }
    };

    this.DetailsStateFactory.setDisplayTab(this.DetailsStateFactory.tabNames.dataTab);
    this.$state.go('cowDetail', {cowId: record.cowId, caller});
  }

  openFilterDialog() {
    this.$modal.open({
      templateUrl: 'menu/report/embryo-recovery/filter-dialog.html',
      controller: 'EmbryoRecoveryReportFilterDialogController',
      controllerAs: 'filterDialogCtrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        resolved: () => ({
          filterParams: this.filterParams,
          cowGroups: this.cowGroups,
          cowLabels: this.cowLabels
        })
      },
    }).result
      .then((filterParams) => {
        Object.assign(this.filterParams, filterParams);
        this.filter();
      });
  }

  getRecordExtraParams(record) {
    const splitCowUid = Cow.formatCowUid(record.cowUid).split('-');
    const cowLabels = this.toCowLabelList(record.cowLabels);
    const cowLabelsDisplay = cowLabels.length === 0 ? '-' : cowLabels.join('、');
    const recoveryCount = record.embryoRecoveries.length;
    const selected = false;

    const embryoRecoveries = record.embryoRecoveries.map((embryoRecovery) => {
      embryoRecovery.totalEmbryoCount = this.getTotalEmbryoCount(embryoRecovery);
      embryoRecovery.normalEmbryoCount = this.getNormalEmbryoCount(embryoRecovery);
      embryoRecovery.normalEmbryoRatio = this.getNormalEmbryoRatio(embryoRecovery);
      embryoRecovery.monthAge = DateUtil.monthAge(record.birthday, embryoRecovery.occurredAt);
      return embryoRecovery;
    });

    const averageKeys = [
      'embryoRecoveryRankA',
      'embryoRecoveryRankA1',
      'embryoRecoveryRankA2',
      'embryoRecoveryRankA3',
      'embryoRecoveryRankA4',
      'embryoRecoveryRankA5',
      'embryoRecoveryRankB',
      'embryoRecoveryRankB1',
      'embryoRecoveryRankB2',
      'embryoRecoveryRankB3',
      'embryoRecoveryRankB4',
      'embryoRecoveryRankB5',
      'embryoRecoveryRankC',
      'embryoRecoveryRankD',
      'embryoRecoveryRankDegenerated',
      'embryoRecoveryRankUnfertilized',
      'totalEmbryoCount',
      'normalEmbryoCount'
    ];

    const average = averageKeys.reduce((obj, key) => {
      if (typeof record.embryoRecoveries[0][key] === 'undefined') return obj;

      obj[key] = this.getAverage(key, embryoRecoveries);
      return obj;
    }, {});
    average.normalEmbryoRatio = this.getNormalEmbryoRatio(average);

    return {splitCowUid, cowLabels, cowLabelsDisplay, recoveryCount, selected, embryoRecoveries, average};
  }

  getTotalRowHeight() {
    const $rows = angular.element('#embryo-recovery-report-grid .ui-grid-row');
    const heights = $rows.map((i, el) => angular.element(el).height());
    return heights.get().reduce((a, b) => a + b, 0);
  }

  getGridStyle() {
    return {height: this.getTotalRowHeight()};
  }

  getAverage(key, records) {
    return records.map((record) => record[key]).reduce((accum, val) => accum + val, 0) / records.length;
  }

  getTotalEmbryoCount(record) {
    const count = Object.entries(record).filter(([rank]) => {
      return rank.startsWith('embryoRecoveryRank');
    }).reduce((acc, [_, count]) => {
      return acc + count;
    }, 0);

    return count;
  }

  getNormalEmbryoCount(record) {
    const count = Object.entries(record).filter(([rank]) => {
      return rank.startsWith('embryoRecoveryRank') &&
        !['embryoRecoveryRankDegenerated', 'embryoRecoveryRankUnfertilized'].includes(rank);
    }).reduce((acc, [_, count]) => {
      return acc + count;
    }, 0);

    return count;
  }

  getNormalEmbryoRatio(record) {
    return this.getNormalEmbryoCount(record) / this.getTotalEmbryoCount(record) * 100;
  }

  compareNumber(a, b, asc = false) {
    return asc ? a - b : b - a;
  }

  compareString(a, b, asc = false) {
    if (a === b) return 0;

    if (asc) {
      return a > b ? 1 : -1;
    } else {
      return a < b ? 1 : -1;
    }
  }

  toCowLabelList(cowLabels) {
    if (!cowLabels) return [];

    return cowLabels.split(',').reduce((accum, label) => {
      const trimmed = _.trim(label, ['[', ']']);
      accum.push(trimmed);
      return accum;
    }, []);
  }
}

app.controller('EmbryoRecoveryReportController', EmbryoRecoveryReportController);
