/**
 * 分娩アラートの確認結果報告用のフォームコンポーネントです
 *
 * ex.
 *   <alert-calving-report-form
 *     confirmed-comment="confirmedComment"
 *     confirmed-status="confirmedStatus"
 *     on-cancel-click="onCancel()"
 *     on-ok-click="onOk(confirmedStatus, confirmedComment)"
 *   ></alert-calving-report-form>
 *
 * プロパティ:
 *  @param {string} confirmed-comment 確認コメント
 *  @param {string} confirmed-status 確認ステータス
 *  @param {Function} on-cancel-click キャンセルボタンクリック時に発生するイベント
 *  @param {Function} on-ok-click 報告ボタンクリック時に発生するイベント confirmedStatus, confirmedCommentを引数で渡す
 */
class AlertCalvingReportFormController {
  constructor(IsMobile) {
    'ngInject';

    this.IsMobile = IsMobile;
    this.statusOption = [
      {value: 'sign', label: '兆候あり'},
      {value: 'no_sign', label: '兆候なし'},
      {value: 'other', label: 'その他'}
    ];
  }

  $onChanges() {
    this.validate();
  }

  validate() {
    this.invalid = true;

    if (!this.confirmedStatus) return;

    if (this.confirmedStatus === 'other') {
      const comment = this.confirmedComment ? this.confirmedComment.trim() : null;
      this.invalid = !comment;
    } else {
      this.invalid = false;
    }
  }

  handleOkClick() {
    const params = {
      confirmedStatus: this.confirmedStatus,
      confirmedComment: this.confirmedComment
    };
    this.onOkClick(params);
  }

  handleCancelClick() {
    this.onCancelClick();
  }
}

function alertCalvingReportFormComponent() {
  return {
    templateUrl: 'menu/alert/calving/report-form.html',
    controller: AlertCalvingReportFormController,
    bindings: {
      confirmedComment: '<',
      confirmedStatus: '<',
      onCancelClick: '&',
      onOkClick: '&'
    }
  };
}

app.component('alertCalvingReportForm', alertCalvingReportFormComponent());
