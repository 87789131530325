app.directive('treatmentOccurredDateTd', treatmentOccurredDateTd);

function treatmentOccurredDateTd($compile) {
  'ngInject';
  return {
    restrict: 'A',
    scope: '=',
    link: (s, el, attr) => {
      const template = $compile(
        `<td>
          <div class="treatment-occurred-date-continuation-radio-group">
            <div class="radio-item">
              <input type="radio" id="{{'diseaseContinuationNew' + $index}}"
                value="new"
                ng-model="e.diseaseContinuation"
                ng-change="ctrl.state.onChangeDiseaseContinuation(e)">
              <label for="{{'diseaseContinuationNew' + $index}}">新規</label>
            </div>
            <div class="radio-item">
              <input type="radio" id="{{'diseaseContinuationContinuous' + $index}}"
                value="continuous"
                ng-model="e.diseaseContinuation"
                ng-change="ctrl.state.onChangeDiseaseContinuation(e)">
              <label for="{{'diseaseContinuationContinuous' + $index}}">継続</label>
            </div>
          </div>
        </td>
        <td>
          <input type="text" name="occurredDiseaseDate" ng-model="e.occurredDiseaseDate" jqdatepicker autocomplete="off"/>
          <p ng-if="e.errors.message.occurredDiseaseDate" class="error-message">
            <span ng-bind="e.errors.message.occurredDiseaseDate"></span>
          </p>
        </td>`
      )(s);

      el.replaceWith(template);
    },
  };
}
