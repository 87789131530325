/**
 * 起立困難アラートのリストアイテムコンポーネントです
 *
 * ex.
 *   <alert-dysstasia-list-item
 *     alert-dysstasia="alertDysstasia"
 *     on-cow-no-click="onCowNoClick(cowId)"
 *     on-report-click="onReportClick(alert)"
 *   ></alert-dysstasia-report-form>
 *
 * プロパティ:
 *  @param {Object} alert-dysstasia 起立困難アラートオブジェクト
 *  @param {Function} on-cow-no-click 牛番号クリック時に発生するイベント cowIdを引数で渡す
 *  @param {Function} on-report-click 確認結果を報告ボタンクリック時に発生するイベント alertを引数で渡す
 */
class AlertDysstasiaListItemController {
  constructor() {
    'ngInject';
  }

  confirmedClass(alert) {
    return alert.confirmed ? 'state-confirmed' : 'state-unconfirmed';
  }

  handleCowNoClick() {
    this.onCowNoClick({cowId: this.alertDysstasia.cowId});
  }

  handleReportClick() {
    this.onReportClick({alert: this.alertDysstasia});
  }
}

function alertDysstasiaListItemComponents() {
  return {
    templateUrl: 'alert/dysstasia/list-item.html',
    controller: AlertDysstasiaListItemController,
    bindings: {
      alertDysstasia: '<',
      onCowNoClick: '&',
      onReportClick: '&'
    }
  };
}

app.component('alertDysstasiaListItem', alertDysstasiaListItemComponents());
