class DetailDhiController {
  constructor(
    $window,
    $timeout,
    DhiAPI,
    DetailsStateFactory
  ) {
    'ngInject';

    this.$window = $window;
    this.$timeout = $timeout;
    this.DhiAPI = DhiAPI;
    this.DetailsStateFactory = DetailsStateFactory;

    this.init();
  }

  $onDestroy() {
    if (this.chartDhi) {
      this.chartDhi.destroy();
    }
  }

  init() {
    this.columnDefinitions();

    this.$onChanges = () => {
      if (this.activeTab === 'dhiTab' && this.cowId) {
        if (!this.cowDhi) {
          this.index().then(() => {
            this.$timeout(() => {
              this.drawChartDhi();
            });
          });
        } else {
          this.drawChartDhi();
        }
      }
    };
  }

  columnDefinitions() {
    this.dhiColumnsMap = [{
      field: 'birthNumber',
      name: '産次'
    }, {
      field: 'dim',
      name: '搾乳日数',
      unit: '日'
    }, {
      field: 'milkDaily',
      name: '乳量',
      unit: 'kg',
      visibleGridUnit: true,
      step: 5,
      default: {
        max: 45
      }
    }, {
      field: 'milkExpected',
      name: '標準/管理乳量',
      unit: 'kg',
      visibleGridUnit: true
    }, {
      field: 'fatPercent',
      name: '乳脂肪率',
      unit: '%',
      visibleGridUnit: true
    }, {
      field: 'protPercent',
      name: '乳蛋白率',
      unit: '%',
      visibleGridUnit: true
    }, {
      field: 'snfPercent',
      name: '無脂固形率',
      unit: '%',
      visibleGridUnit: true
    }, {
      field: 'mun',
      name: 'MUN',
      unit: 'mg/dl',
      visibleGridUnit: true
    }, {
      field: 'scc',
      name: '体細胞数',
      unit: '千個/ml',
      visibleGridUnit: true,
      step: 10,
      default: {
        max: 400
      }
    }, {
      field: 'linearScore',
      name: 'リニアスコア',
      step: 0.5,
      default: {
        max: 5
      }
    }, {
      field: 'milk305em',
      name: '305日補正乳量',
      unit: 'kg',
      visibleGridUnit: true
    }];

    this.historyDhiColumnsMap = [{
      field: 'dhiDate',
      name: '検定日'
    }, {
      field: 'dim',
      name: '搾乳日数',
      unit: '日'
    }, {
      field: 'milkDaily',
      name: '乳量',
      unit: 'kg',
      visibleGridUnit: true
    }, {
      field: 'milkExpected',
      name: '標準/管理乳量',
      unit: 'kg',
      visibleGridUnit: true
    }, {
      field: 'fatPercent',
      name: '乳脂肪率',
      unit: '%',
      visibleGridUnit: true
    }, {
      field: 'protPercent',
      name: '乳蛋白率',
      unit: '%',
      visibleGridUnit: true
    }, {
      field: 'snfPercent',
      name: '無脂固形率',
      unit: '%',
      visibleGridUnit: true
    }, {
      field: 'mun',
      name: 'MUN',
      unit: 'mg/dl',
      visibleGridUnit: true
    }, {
      field: 'scc',
      name: '体細胞数',
      unit: '千個/ml',
      visibleGridUnit: true
    }, {
      field: 'linearScore',
      name: 'リニアスコア'
    }, {
      field: 'milk305em',
      name: '305日補正乳量',
      unit: 'kg',
      visibleGridUnit: true
    }, {
      field: 'cowGroupName',
      name: '牛群名'
    }];
  }

  index() {
    return this.DhiAPI.cow(this.cowId).then((res) => {
      this.cowDhi = res.data;

      if (this.cowDhi.length === 0) return;

      this.latestDhi = this.cowDhi.reduce((acc, cur) => {
        return acc.dhiDate > cur.dhiDate ? acc : cur;
      });

      this.birthNumberDhi = this.cowDhi.reduce((acc, cur) => {
        const itemBirthNumber = acc.find((result) => {
          return result.birthNumber === cur.birthNumber;
        });

        if (itemBirthNumber) {
          if (itemBirthNumber.dhiDate < cur.dhiDate) {
            const index = acc.findIndex((item) => {
              return item.id === itemBirthNumber.id;
            });

            acc[index] = cur;
          }
        } else {
          acc.push(cur);
        }

        return acc;
      }, []).sort((a, b) => {
        return a.birthNumber - b.birthNumber;
      });

      this.selectionBirthNumberDhi = this.birthNumberDhi.map((item) => {
        return item.birthNumber;
      }).sort((a, b) => {
        return b - a;
      });

      const maxBirthNumber = this.selectionBirthNumberDhi[0];

      this.generateHistoryDhi(maxBirthNumber);
      this.filterHistoryDhi = maxBirthNumber;
    });
  }

  generateHistoryDhi(filter) {
    this.historyDhi = this.cowDhi.filter((item) => {
      return item.birthNumber === filter;
    }).map((item) => {
      return {
        origin: item,
        dhiDate: DateUtil.toYYYYMMDD(item.dhiDate),
        dim: item.dim,
        milkDaily: item.milkDaily,
        milkExpected: item.milkExpected,
        fatPercent: item.fatPercent,
        protPercent: item.protPercent,
        snfPercent: item.snfPercent,
        mun: item.mun,
        scc: item.scc,
        linearScore: item.linearScore,
        milk305em: item.milk305em,
        cowGroupName: item.cowGroupName
      };
    }).sort((a, b) => {
      return a.origin.dhiDate - b.origin.dhiDate;
    });
  }

  changeFilterHistoryDhi() {
    this.generateHistoryDhi(this.filterHistoryDhi);
  }

  displayGridTitle(column) {
    return column.unit && column.visibleGridUnit ? `${column.name}<br>(${column.unit})` : column.name;
  }

  displayLatestDhiValue(column) {
    if (!this.latestDhi) return;

    return column.unit ? `${this.latestDhi[column.field]}${column.unit}` : this.latestDhi[column.field];
  }

  drawChartDhi() {
    if (!this.cowDhi) return;

    if (this.chartDhi) {
      this.chartDhi.destroy();
    }

    this.columnsChartDhi = [];
    this.formatChartDhiYTick = d3.format('.1f');

    const columnsMap = [
      'milkDaily',
      'scc',
      'linearScore'
    ];

    const colorsMap = [
      '#ff1212',
      '#0212ff',
      '#12ff10',
      '#ff8812',
      '#10f0f0',
      '#8010f0',
      '#f010f0',
      '#f01080',
      '#10f0f0',
      '#1080f0'
    ];

    const namesMap = [...10].map((item) => {
      return item === 0 ? '初産' : `${item + 1}産`;
    });

    let xs = {};
    let columns = [];
    let colors = {};
    let names = {};

    columnsMap.forEach((column) => {
      [...colorsMap.length].forEach((index) => {
        const key = `${column}${index + 1}`;

        this.columnsChartDhi.push(key);
        colors[key] = colorsMap[index];
        names[key] = namesMap[index];
      });
    });

    this.cowDhi.sort((a, b) => {
      return a.dhiDate - b.dhiDate;
    }).forEach((item) => {
      columnsMap.forEach((column) => {
        const field = {
          dim: `dim_${column}${item.birthNumber}`,
          column: `${column}${item.birthNumber}`
        };

        const value = {
          dim: item.dim,
          column: item[column]
        };

        const index = {
          dim: columns.findIndex((elem) => {
            return elem[0] === field.dim;
          }),
          column: columns.findIndex((elem) => {
            return elem[0] === field.column;
          })
        };

        if (index.column === -1) {
          xs[field.column] = field.dim;
          columns.push([field.dim, value.dim]);
          columns.push([field.column, value.column]);
        } else {
          columns[index.dim].push(value.dim);
          columns[index.column].push(value.column);
        }
      });
    });

    this.columns = columns;

    this.chartDhi = c3.generate({
      bindto: '#chart-dhi',
      data: {
        xs: xs,
        columns: columns,
        colors: colors,
        names: names
      },
      legend: {
        show: true
      },
      transition: {
        duration: 0
      },
      axis: {
        x: {
          min: 0,
          padding: {
            left: 0
          },
          label: {
            text: '搾乳日数',
            position: 'outer-right'
          },
          tick: {
            fit: false
          }
        },
        y: {
          min: 0,
          padding: {
            top: 2,
            bottom: 0
          },
          label: {
            text: '乳量(kg)',
            position: 'outer-top'
          },
          tick: {
            format: (value) => {
              return this.formatChartDhiYTick(value);
            }
          }
        }
      },
      tooltip: {
        format: {
          title: (dim) => {
            return `${dim}日`;
          }
        }
      },
      size: {
        width: this.getChartDhiWidth(),
        height: 380
      },
      padding: {
        top: 10,
        right: 20
      },
      onresize: () => {
        this.chartDhi.resize({
          width: this.getChartDhiWidth()
        });
      }
    });

    this.changeChartDhiColumn(this.DetailsStateFactory.get('chartDhiColumn').chartDhiColumn);
  }

  getChartDhiWidth() {
    return angular.element('#cow-detail-dhi-content').width() - 384;
  }

  changeChartDhiColumn(visible = 'milkDaily') {
    const hide = this.columnsChartDhi.filter((column) => {
      return !column.match(visible);
    });

    const show = this.columnsChartDhi.filter((column) => {
      return column.match(visible);
    });

    const activeDhiColumn = this.dhiColumnsMap.filter((column) => {
      return column.field === visible;
    });

    const labelY = activeDhiColumn.map((column) => {
      return column.unit ? [column.name, '(', column.unit, ')'] : [column.name];
    })[0].join('');

    const stepY = activeDhiColumn[0].step;
    const defaultMaxY = activeDhiColumn[0].default.max;
    let dataY = [defaultMaxY];
    let maxY;

    this.columns.forEach((column, index) => {
      if (column[0].match(`^${visible}`)) {
        dataY = dataY.concat(angular.copy(column).splice(1));
      }
    });

    maxY = Math.ceil(Math.max.apply(null, dataY) / stepY) * stepY;

    if (visible === 'scc') {
      this.formatChartDhiYTick = d3.format(',');
    } else {
      this.formatChartDhiYTick = d3.format('.1f');
    }

    this.activeChartDhiColumn = visible;
    this.chartDhi.axis.labels({
      y: labelY
    });
    this.chartDhi.axis.max({
      y: maxY
    });
    this.chartDhi.hide(hide, {
      withLegend: true
    });
    this.chartDhi.show(show, {
      withLegend: true
    });

    this.DetailsStateFactory.set('chartDhiColumn', visible);
  }

  classChartDhiColumn(column) {
    return this.activeChartDhiColumn === column ? 'contents__buttonGroupItem--active' : 'contents__buttonGroupItem';
  }
}

function detailDhiComponent() {
  return {
    templateUrl: 'cow/detail/tabs/dhi.html',
    controller: DetailDhiController,
    controllerAs: 'ctrl',
    bindings: {
      activeTab: '<',
      cowId: '<'
    }
  };
}

app.component('detailDhi', detailDhiComponent());
