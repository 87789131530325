/**
 * @deprecated FarmDiaryAPIを利用してください
 * ※この実装の修正および、新規の利用は禁止です
 */
app.service('FarmHistoryService', (APIService) => {
  'ngInject';
  return {
    getFarmHistory: (id) =>
      APIService.get(`/farm_diary/${id}`),
    enterFarmHistory: (data) =>
      APIService.post('/farm_diary', data),
    updateFarmHistory: (data) =>
      APIService.put(`/farm_diary/${data.id}`, data),
    deleteFarmHistory: (id) =>
      APIService.delete(`/farm_diary/${id}`),
  };
});
