// eslint-disable-next-line no-unused-vars
class DepositCustomlistRunner {
  constructor(runner, showAcuteIllness) {
    this.runner = runner;
    this.runner.showAcuteIllness = showAcuteIllness;
  }

  /**
   * @see CustomlistRunner.run
   */
  run(customlistId, date, option = {}) {
    return this.runner.run(customlistId, date, option);
  }
}
