class MobileEditBleIdConfirmModal {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  open(param) {
    return this.$modal.open({
      templateUrl: 'mobile/components/edit-ble-id-confirm-modal/edit-ble-id-confirm-modal.html',
      controller: 'MobileEditBleIdConfirmModalController as ctrl',
      resolve: {
        param: () => param
      }
    });
  }
}
app.service('MobileEditBleIdConfirmModal', MobileEditBleIdConfirmModal);
