class FarmDiaryAPI {
  constructor(
    APIService
  ) {
    'ngInject';
    this.APIService = APIService;
  }

  show(id) {
    return this.APIService.get(`/farm_diary/${id}`);
  }

  create(data) {
    return this.APIService.post('/farm_diary', data);
  }

  update(data) {
    return this.APIService.put(`/farm_diary/${data.id}`, data);
  }

  delete(id) {
    return this.APIService.delete(`/farm_diary/${id}`);
  }
}

app.service('FarmDiaryAPI', FarmDiaryAPI);
