app.controller('RelationGraphController', function(
  $rootScope,
  $scope,
  $q,
  FarmService,
  CowGroupAPI,
  CowService,
  CowshedService,
  MilkingService,
  ActiveAmountService,
  EnvironmentService,
  DateUtilService,
  ActivityAPI
) {
  'ngInject';

  let analyticChart = new AnalyticChart('#analyticChart');
  let checkDatesMessage = {
    default: '対象開始日時と対象終了日時の入力内容に誤りがあります',
    overPeriod: '終了日が開始日から最大31日を超えています'
  };
  let _summaryTypes = [
    {label: '-', value: ''},
    {label: '搾乳量', value: 'milk'},
    {label: '活動量', value: 'activity'},
    {label: '気温', value: 'temperature'},
    {label: '湿度', value: 'humidity'},
    {label: 'THI', value: 'stress'}
  ];
  let _dataTypes = [
    {label: '採食', value: 'feed'},
    {label: '反芻', value: 'rumination'},
    {label: '動態', value: 'move'},
    {label: '横臥', value: 'lie'},
    {label: '静止', value: 'stand'}
  ];
  let _statsTypes = [
    {label: '平均', value: 'avg'},
    {label: '最大', value: 'max'},
    {label: '最小', value: 'min'}
  ];
  let dataRequestMethod = {
    milk_farm: MilkingService.getFarmSummary.bind(MilkingService),
    milk_cowshed: MilkingService.getCowshedSummary.bind(MilkingService),
    milk_cowGroup: MilkingService.getCowGroupSummary.bind(MilkingService),
    milk_cow: MilkingService.getDailySummaryByCowId.bind(MilkingService),
    activity_farm: ActiveAmountService.getFarmSummary.bind(ActiveAmountService),
    activity_cowshed: ActiveAmountService.getCowshedSummary.bind(ActiveAmountService),
    activity_cowGroup: ActiveAmountService.getCowGroupSummary.bind(ActiveAmountService),
    environment_farm: EnvironmentService.getFarmSummary.bind(EnvironmentService),
    environment_cowshed: EnvironmentService.getCowshedSummary.bind(EnvironmentService),
    environment_cowGroup: EnvironmentService.getCowGroupSummary.bind(EnvironmentService),
    environment_cow: EnvironmentService.getDailySummaryByCowId.bind(EnvironmentService)
  };

  let parseResponseData = {
    milk: {
      amount: 'amount',
      date: 'date',
      parse: (date) => {
        return getDateLabelForApiResDateTime(date);
      }
    },
    activity: {
      amount: {
        feed: 'feedMin',
        rumination: 'ruminationMin',
        move: 'moveMin',
        lie: 'lieMin',
        stand: 'standMin'
      },
      date: 'date',
      parse: (date) => {
        return getDateLabelForApiResDateTime(date);
      }
    },
    temperature: {
      amount: 'temperature',
      date: 'date',
      parse: (date) => {
        return getDateLabelForApiResDateTime(date);
      }
    },
    humidity: {
      amount: 'humidity',
      date: 'date',
      parse: (date) => {
        return getDateLabelForApiResDateTime(date);
      }
    },
    stress: {
      amount: 'stress',
      date: 'date',
      parse: (date) => {
        return getDateLabelForApiResDateTime(date);
      }
    }
  };
  const replaceStatusList = {
    '哺育': 'infant',
    '育成': 'replacement',
    '肥育': 'fatting_stage',
    'フレッシュ': 'fresh',
    '未授精': 'not_bred',
    '授精': 'bred',
    '受胎(＋)': 'pregnant',
    '未受胎(－)': 'non_pregnant',
    '乾乳前期': 'far_off',
    '乾乳後期': 'close_up',
    '出荷': 'sold',
    'へい死': 'died',
    '繁殖除外': 'do_not_breed',
    'その他': 'others'
  };

  // 牛個体の検索結果を描画したタイミングで以下を実行する
  $scope.$on('ngRepeatFinished', function() {
    FixedMidashi.create();
  });

  $scope.checkTargetAndStartEndDate = () => {
    let disabled = true, isError = true, overPeriod = true, tcount = 0;
    _.each($scope.analyticTargetMap, (list, groupKey) => {
      _.each(list, function(target) {
        if (target.isSelected) tcount++;
      });
    });
    disabled = (tcount === 0 || tcount > 3);
    isError = ($scope.startDate > $scope.endDate);
    overPeriod = ((($scope.endDate - $scope.startDate) / (1000 * 60 * 60 * 24)) > 30);
    if (disabled || isError || overPeriod) $scope.settingGraph = true;
    $('#checkDatesMessage').html(isError || overPeriod ? checkDatesMessage[!overPeriod ? 'default' : 'overPeriod'] : '');
    $('#settingButton').attr('disabled', !(!isError && !disabled && !overPeriod));
  };

  $scope.changeSummaryTypes = (type) => {
    if ($scope.setting[type].summaryType.value === 'activity') {
      $scope.setting[type].dtDisabled = false;
      $scope.setting[type].dataType = $scope.showFeed ? $scope[type + 'DataTypes'][0] : $scope[type + 'DataTypes'][1];
    } else {
      $scope.setting[type].dtDisabled = true;
      $scope.setting[type].dataType = {label: '-', value: ''};
    }
    checkSettingPulldown(type, false);
    $scope.disableStartAnalytic();
  };

  $scope.disableStartAnalytic = () => {
    $('#startAnalytic').attr('disabled', !$scope.setting.left.summaryType.value);
  };

  // type で選択されたもの以外をもう一方のプルダウンにセットする
  $scope.changeDataTypes = (type) => {
    checkSettingPulldown(type, false);
  };

  $scope.changeStatsTypes = (type) => {
    checkSettingPulldown(type, type === 'right');
  };

  const checkSettingPulldown = (type, isRStats) => {
    let target = type === 'left' ? 'right' : 'left';
    if ((($scope.setting[type].summaryType.value === '')
      && ($scope.setting[type].dataType.value === ''))
      || (($scope.setting[target].summaryType.value === '')
      && ($scope.setting[target].dataType.value === ''))) {
      $scope.leftStatsTypes = _.clone(_statsTypes);
      $scope.rightStatsTypes = _.clone(_statsTypes);
      return;
    }

    if (($scope.setting[type].summaryType.value === $scope.setting[target].summaryType.value)
      && ($scope.setting[type].dataType.value === $scope.setting[target].dataType.value)) {
      if ($scope.setting.left.statsType.value === $scope.setting.right.statsType.value) {
        if ($scope.setting.left.statsType.value === 'min') {
          $scope.setting[isRStats ? 'left' : 'right'].statsType = _statsTypes[0];
        } else if ($scope.setting.left.statsType.value === 'avg') {
          $scope.setting[isRStats ? 'left' : 'right'].statsType = _statsTypes[1];
        } else if ($scope.setting.left.statsType.value === 'max') {
          $scope.setting[isRStats ? 'left' : 'right'].statsType = _statsTypes[2];
        }
      }

      $scope['rightStatsTypes'] = [];
      $scope['leftStatsTypes'] = [];
      _.each(_statsTypes, (statsType) => {
        if (statsType.value !== $scope.setting.left.statsType.value) $scope['rightStatsTypes'].push(statsType);
        if (statsType.value !== $scope.setting.right.statsType.value) $scope['leftStatsTypes'].push(statsType);
      });
    } else {
      $scope.leftStatsTypes = _.clone(_statsTypes);
      $scope.rightStatsTypes = _.clone(_statsTypes);
    }
  };

  let createCheckBoxForTargetCows = (params) => {
    const query = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
    CowService.getAnalyticTargetCows(query).then((res) => {
      $scope.analyticTargetMap['cow'] = res.data.map((cow) => ({
        id: cow.cowId,
        cowUid: cow.cowUid,
        cowGroup: cow.cowGroupName,
        cowNo: cow.cowNo,
        state: cow.state,
        birthNumber: cow.birthNumber,
        label: cow.cowName,
        latestCalvingDateLabel: cow.latestCalvingDate ? getDateForLabel(Number(cow.latestCalvingDate)) : '',
        isSelected: false
      }));

      $scope.openSearch();

    }).catch((err) => console.error(err));

  };

  $scope.getCows = () => {
    $('#cowSelectMessage').remove();
    $('#seachResultList').css('display', 'block');

    const statusList = Object.keys($scope.statusList)
      .filter((key) => $scope.statusList[key]).map((key) => replaceStatusList[key])
      .join(',');

    const params = {
      cow_group_id: $scope.formData['cowGroup'] || '',
      cow_no: $scope.formData['cowNo'] || '',
      states: statusList,
      birth_number: $scope.formData['birthNumber'] ? $scope.formData['birthNumber'].value : ''
    };

    createCheckBoxForTargetCows(params);
  };

  $scope.changeCheckBox = function(targetType, id) {
    _.chain($scope.analyticTargetMap[targetType])
      .filter((target) => {
        return target.id === id;
      })
      .each((target) => {
        target.isSelected = !target.isSelected;
      });
    $scope.checkTargetAndStartEndDate();
  };

  $scope.openSearch = function() {
    $scope.searchCondition = !$scope.searchCondition;
  };

  $scope.startAnalytic = (setting) => {
    $scope.loading = true;
    $('#analyticChart').html('');
    $('#initMessage').css('display', 'none');
    $scope.getAnalyticTargetIdList();
    $scope.getAnalyticTargetData().then((chartDataList) => {
      createAnalyticChart(chartDataList);
      $scope.settingGraph = true;
    });
  };

  $scope.openSetting = function() {
    $scope.settingGraph = !$scope.settingGraph;
    $scope.disableStartAnalytic();
  };

  $scope.getAnalyticTargetIdList = () => {
    let targetList = [];
    _.each($scope.analyticTargetMap, (objList, key) => {
      $scope.analyticTargetMap;
      _.each(objList, (obj) => {
        if (obj.isSelected) {
          targetList.push({
            targetType: key,
            target: obj
          });
        }
      });
    });
    $scope.targetList = targetList;
  };

  $scope.getAnalyticTargetData = () => {
    let deferred = $q.defer();
    $scope.targetList = $scope.targetList || [];
    let graphYCnt = 0;
    let chartDataList = [];
    $scope.graphY = [];
    $scope.requestOrderList = [];

    _.each(['left', 'right'], (val) => {
      if ($scope.setting[val].summaryType.value) $scope.graphY.push(val);
    });

    let graphY = $scope.graphY;
    $scope.analyticDateColumns = createStartEndDateColumn($scope.startDate, $scope.endDate);

    graphY.forEach((lr) => {
      let params = {
        targetId: '',
        requestMethodType: '',
        startDate: DateUtilService.toW3CFormat(Number($scope.startDate)),
        endDate: DateUtilService.toW3CFormat(Number($scope.endDate)),
        summaryType: $scope.setting[lr].summaryType.value,
        dataType: $scope.setting[lr].dataType.value,
        statsType: $scope.setting[lr].statsType.value,
        graphType: $scope.setting[lr].graphType.value
      };
      let targetCnt = 0;
      $scope.targetList.forEach((target) => {
        params.targetId = target['target'].id;
        params[target['targetType'] + 'Id'] = target['target'].id;
        params.requestMethodType = convertSummaryType(params.summaryType) + '_' + target['targetType'];

        $scope.requestOrderList.push({
          targetId: params.targetId,
          summaryType: params.summaryType,
          dataType: params.dataType,
          statsType: params.statsType
        });

        getAnalyticData(params).then((data) => {
          chartDataList.push({
            targetId: target['target'].id,
            data: data,
            summaryType: $scope.setting[lr].summaryType.value,
            dataType: $scope.setting[lr].dataType.value,
            statsType: $scope.setting[lr].statsType.value,
            graphType: $scope.setting[lr].graphType.value,
            axes: lr === 'left' ? 'y' : 'y2',
            dataName: (target['target'].label || target['target'].cowNo || '') + '_'
              + ($scope.setting[lr].summaryType.value === 'activity' ?
                $scope.setting[lr].dataType.label :
                $scope.setting[lr].summaryType.label ) + '_'
              + $scope.setting[lr].statsType.label
          });

          if ((graphY.length - 1) === graphYCnt && ($scope.targetList.length - 1) === targetCnt) {
            deferred.resolve(chartDataList);
          } else {
            (($scope.targetList.length - 1) === targetCnt) ? graphYCnt++ : targetCnt++;
          }
        });
      });
    });

    return deferred.promise;
  };

  const getAnalyticData = (params) => {
    let deferred = $q.defer();
    params = params || {};

    if (params.requestMethodType === 'activity_cow') {
      ActivityAPI.dailySummary(params.cowId, params.startDate, params.endDate)
        .then((result) => {
          deferred.resolve(result.data);
        });
      return deferred.promise;
    } else {
      dataRequestMethod[params.requestMethodType](params).then((result) => {
        deferred.resolve(result.data);
      });
      return deferred.promise;
    }
  };

  const createAnalyticChart = (chartDataList) => {
    let columns = [], classes = {}, colors = {}, axes = {}, types = {}, axisY = {};
    let dataTypeCnt = {bar: 0, line: 0};
    let dataTypeColor = {
      bar: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9229E3', '#8c564b'],
      line: ['#d62728', '#9229E3', '#8c564b', '#1f77b4', '#ff7f0e', '#2ca02c']
    };

    chartDataList = chartDataList || [];
    $scope.chartDataList = [];
    $scope.chartDataList = _.chain($scope.requestOrderList)
      .map((req) => {
        return _.find(chartDataList, (data) => {
          return data.targetId === req.targetId
          && data.summaryType === req.summaryType
          && data.dataType === req.dataType
          && data.statsType === req.statsType;
        });
      }).reduce((dataList, data) => {
        dataList.push(data);
        return dataList;
      }, []).value();

    columns = _.chain($scope.chartDataList)
      .map((dataObj) => {
        let column = _.clone($scope.analyticDateColumns);
        if (!$scope.showFeed && dataObj.dataType === 'feed') {
          Object.keys(column).forEach((k) => column[k] = null);
        }
        column['x'] = dataObj.dataName;
        let parseResponse = {};
        parseResponse['date'] = parseResponseData[dataObj.summaryType].date;
        parseResponse['parse'] = parseResponseData[dataObj.summaryType].parse;
        if (dataObj.summaryType === 'activity') {
          parseResponse['amount'] = parseResponseData[dataObj.summaryType].amount[dataObj.dataType];
        } else {
          parseResponse['amount'] = parseResponseData[dataObj.summaryType].amount;
        }
        if ($scope.showFeed || dataObj.dataType !== 'feed') {
          dataObj.data.forEach((d) => {
            if (column[parseResponse.parse(d[parseResponse.date])] === 0) {
              column[parseResponse.parse(d[parseResponse.date])] = Number(d[parseResponse.amount]);
            }
          });
        }

        axes[String(dataObj.dataName)] = dataObj.axes;
        types[String(dataObj.dataName)] = dataObj.graphType;
        classes[String(dataObj.dataName)] = 'opacity-' + dataObj.graphType + dataTypeCnt[dataObj.graphType];
        colors[[String(dataObj.dataName)]] = dataTypeColor[dataObj.graphType][dataTypeCnt[dataObj.graphType]];
        dataTypeCnt[dataObj.graphType]++;

        return _.values(column);
      })
      .reduce((_columns, column) => {
        _columns.push(column);
        return _columns;
      }, []).value();

    axisY = {
      labelY: getDataType('left'),
      labelY2: getDataType('right'),
      isShowY2: Boolean(getDataType('right'))
    };

    columns.unshift(_.keys($scope.analyticDateColumns));
    analyticChart.showAnalyticChart(columns, classes, colors, axes, types, axisY);
    $scope.loading = false;
  };

  const getDataType = (type) => {
    return $scope.setting[type][$scope.setting[type].summaryType.value === 'activity' ? 'dataType' : 'summaryType'].value;
  };

  // 起動処理を行う
  onInit();

  /**
   * 画面起動時に行う処理
   */
  function onInit() {
    initValues();

    // 牧場、牛舎、牛群のチェックボックスデータ取得
    getAccountInfo();
    getCowSheds();
    getCowGroups();
  }

  /**
   * 初期データ色々設定する
   */
  function initValues() {
    $scope.loading = false;
    $scope.analyticDateColumns = [];
    $scope.analyticTargetMap = {
      'farm': [],
      'cowshed': [],
      'cowGroup': [],
      'cow': []
    };
    $scope.birthNumbers = [
      {name: '指定無し', value: ''},
      {name: '未出産', value: '0'},
      {name: '初産', value: '1'},
      {name: '初産以外', value: '2'}
    ];
    $scope.chartDataList = [];
    $scope.formData = {};
    $scope.graphTypes = [
      {label: '棒グラフ', value: 'bar'},
      {label: '折れ線グラフ', value: 'line'}
    ];
    $scope.startDate = new Date(moment().date(1).hour(0).minute(0).second(0).millisecond(0).unix() * 1000).getTime();
    $scope.endDate = new Date(moment().hour(0).minute(0).second(0).millisecond(0).unix() * 1000).getTime();

    $scope.requestOrderList = [];
    $scope.searchTargetCowGroup = [{id: '', label: '-'}];
    $scope.setting = {
      left: {},
      right: {}
    };

    $scope.statusList = {
      '哺育': true,
      '育成': true,
      '肥育': true, // 肉用牧場のみ
      'フレッシュ': true,
      '未授精': true,
      '授精': true,
      '受胎(＋)': true,
      '未受胎(－)': true,
      '乾乳前期': true, // 乳用牧場のみ
      '乾乳後期': true, // 乳用牧場のみ
      '出荷': false,
      'へい死': false,
      '繁殖除外': true,
      'その他': true
    };
  }

  /**
   * アカウント情報取得
   */
  function getAccountInfo() {
    return FarmService.show().then((res) => {
      $scope.analyticTargetMap['farm'].push({id: res.id, label: '牧場全体', isSelected: false});

      // format selection options
      _summaryTypes = setSummaryTypes(res.farmKind);
      setLeftRightTypes();

      const farm = new Farm(res);
      $scope.showFeed = !farm.isUMLight();
      $scope.showDrink = farm.isUMFull();

      if (res.farmKind === '乳用') {
        delete $scope.statusList['肥育'];
      } else if (res.farmKind === '肉用') {
        delete $scope.statusList['乾乳前期'];
        delete $scope.statusList['乾乳後期'];
      }
    });
  }

  /**
   * 牛舎情報取得
   */
  function getCowSheds() {
    CowshedService.index().then((res) => {
      res.data.forEach((shed) => {
        $scope.analyticTargetMap['cowshed'].push({id: shed.cowshedId, label: shed.cowshedName, isSelected: false});
      });
    }).catch((err) => console.error(err));
  }

  /**
   * 牛群情報取得
   */
  function getCowGroups() {
    CowGroupAPI.active().then((res) => {
      $scope.formData.cowGroup = '';
      res.data.forEach((group) => {
        $scope.analyticTargetMap['cowGroup'].push({id: group.cowGroupId, label: group.cowGroupName, isSelected: false});
        $scope.searchTargetCowGroup.push({id: group.cowGroupId, label: group.cowGroupName});
      });
    }).catch((err) => console.error(err));
  }

  /**
   * 乳用じゃない場合、搾乳のオプションを削除
   * @param {string} farmKind
   */
  function setSummaryTypes(farmKind) {
    return farmKind === '乳用' ? _summaryTypes : _summaryTypes.filter((type) => type.value !== 'milk');
  }

  /**
   * 分析グラフの左右種類をセットする
   */
  function setLeftRightTypes() {
    $scope.leftSummaryTypes = _.clone(_summaryTypes);
    $scope.rightSummaryTypes = _.clone(_summaryTypes);
    $scope.setting.left.summaryType = $scope.leftSummaryTypes[0];
    $scope.setting.right.summaryType = $scope.rightSummaryTypes[0];

    $scope.leftDataTypes = _.clone(_dataTypes);
    $scope.rightDataTypes = _.clone(_dataTypes);
    $scope.setting.left.dataType = {label: '-', value: ''};
    $scope.setting.right.dataType = {label: '-', value: ''};

    $scope.leftStatsTypes = _.clone(_statsTypes);
    $scope.rightStatsTypes = _.clone(_statsTypes);
    $scope.setting.left.statsType = $scope.leftStatsTypes[0];
    $scope.setting.right.statsType = $scope.rightStatsTypes[0];

    $scope.setting.left.graphType = $scope.graphTypes[0];
    $scope.setting.right.graphType = $scope.graphTypes[1];
  }

  /**
   * サマリタイプが温度、湿度、ストレスの場合はenvironmentへ変換する
   * その他は変換しない
   *
   * @params {String} summaryType サマリタイプ
   * @return {String} 変換後サマリタイプ || サマリタイプ
   */
  function convertSummaryType(summaryType) {
    const environments = ['temperature', 'humidity', 'stress'];
    return environments.includes(summaryType) ? 'environment' : summaryType;
  }
});
