class FileUploadController {
  constructor(
    $modalInstance,
    FileImportAPI,
    blockUI,
    params,
    StandardDialog,
    ViewStateAPI
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.FileImportAPI = FileImportAPI;
    this.blockUI = blockUI;
    this.StandardDialog = StandardDialog;
    this.ViewStateAPI = ViewStateAPI;

    this.init(params.farm, params.isAdmin, params.importedFiles);
  }

  static get S3_FILE_IMPORT_SAMPLE_PATH() {
    return 'https://s3.ap-northeast-1.amazonaws.com/www.u-motion.co.jp/sample/file-import';
  }

  init(farm, isAdmin, importedFiles) {
    this.fileTypes = FileImport.selectableFileTypes(farm, isAdmin);
    this.fileType = this.fileTypes[0].key;
    this.importedFiles = importedFiles;
    this.sampleFileNames = FileImport.sampleFileNames(farm);
    this.selectedFiles = [];

    this.onChangeFieType();
  }

  addSelectedFiles(newFiles) {
    const MEGA_BYTE_UNIT = 1024 * 1024;
    const hugeFiles = newFiles.filter((file) => {
      return file.size > MEGA_BYTE_UNIT;
    });

    if (hugeFiles.length >= 1) {
      const summary = hugeFiles.map((file) => {
        const megaByte = (Math.round((file.size / MEGA_BYTE_UNIT) * 10) / 10);
        return `・ ${file.name} (${megaByte} MB)`;
      }).join('<br>');

      const message = `
        <br>
        <div>・ 取込対象のファイルを間違えていないか</div>
        <div>・ ファイルの末尾に余分な空行が存在しないか</div>
        <br>
        [対象ファイル]<br>
        ${summary}
      `;

      this.StandardDialog.showMessage({
        title: 'エラー',
        text1: '取込対象のファイルのサイズが大き過ぎます。以下の事項を確認してください。',
        htmlMessage: message,
      });
      return;
    }

    newFiles.forEach((file) => {
      const exists = this.selectedFiles.some((previous) => {
        return previous.name === file.name;
      });
      if (!exists) {
        this.selectedFiles.push(file);
      }
    });
  }

  deleteSelectedFiles(id) {
    this.selectedFiles = this.selectedFiles.filter((file, index) => {
      return id !== index;
    });
  }

  onChangeFieType() {
    const sampleAvailableFileTypes = [
      'cow',
      'cow_ble_id',
      'cow_ble_id_unequip',
      'milking_amount',
      'master_medicine',
    ];

    this.showSampleDownloadButton = sampleAvailableFileTypes.includes(this.fileType);

    this.instruction = '';

    switch (this.fileType) {
    case 'cow_event_advanced': {
      this.instruction = `
        <div>
          イベントデータを新規追加します。既に取込まれたデータの上書は行いません。
          過去に取込んだデータが含まれる場合は以前の取込内容の取消を事前に行ってください。
        </div>

        <div>
          取消は取込内容の詳細画面の 「データ削除」 で実行できます。
        </div>
      `;
    }
    }
  }

  downloadSample() {
    this.ViewStateAPI.create(
      'downloadSample',
      'fileImport',
      {bqNotes: this.fileType}
    );

    const link = document.createElement('a');
    link.href = this.sampleFileUrl(this.fileType);
    link.click();
  }

  sampleFileUrl(fileType) {
    const filename = this.sampleFileNames[fileType];
    return `${FileUploadController.S3_FILE_IMPORT_SAMPLE_PATH}/${filename}`;
  }

  fileLabel() {
    const length = this.selectedFiles.length;

    return length === 0 ? 'ファイルを選択' : `${length}つのファイルが選択されています`;
  }

  upload() {
    if (!this.selectedFiles) return;

    this.confirmUpload().then(() => {
      this.executeUpload();
    });
  }

  confirmUpload() {
    if (this.fileType === 'cow_event_advanced') {
      const duplicatedFiles = this.selectedFiles.filter((file) => {
        return this.importedFiles.some((imported) => {
          return imported.fileType === this.fileType && imported.fileName === file.name;
        });
      });

      if (duplicatedFiles.length >= 1) {
        const duplicatedFileNames = duplicatedFiles.map((f) => f.name).join('、');

        return this.StandardDialog.showYesNoConfirm({
          title: '取込ファイルの重複確認',
          text1: '以前に取込されたファイルと同じ名前のファイルが選択されています。',
          text2: '同じイベントデータが含まれている場合は取消処理が必要です。取込処理を実行しますか？',
          text3: `[対象ファイル] ${duplicatedFileNames}`,
          yes: '実行する',
          no: 'キャンセル'
        }).result;
      }
    }

    return new Promise((resolve) => resolve());
  }

  executeUpload() {
    const fd = new FormData();
    fd.append('file_type', this.fileType);
    fd.append('file_count', this.selectedFiles.length);

    // Caution: selectedFiles is FileList
    for (let i = 0; i < this.selectedFiles.length; i++) {
      fd.append(`file${(i + 1)}`, this.selectedFiles[i]);
    }

    const formatErrorMessage = (messages) => {
      return messages.map((message) => message.message).join('\n');
    };

    this.blockUI.start('アップロード中');
    this.FileImportAPI.upload(fd)
      .then((res) => {
        this.blockUI.stop();
        this.$modalInstance.close();
      }).catch((res) => {
        this.blockUI.stop();
        this.message = formatErrorMessage(res.data.messages);
      });
  }

  cancel() {
    this.$modalInstance.close(false);
  }
}

app.controller('FileUploadController', FileUploadController);

/**
 * 以下の要件を実現するための実装です。
 * AngularJSの ng-change では最後に選択したものしか保持できないために独自に実装しています。
 *
 * [要件]
 * 1. ファイル選択を複数回実行した場合に前回選択したものを保持する
 * 2. 1の要件のために前回と同じファイルを選択することが可能になるが重複した場合は除外する
 *
 * [実装概要]
 * - 選択されたファイル情報を道的にメモリ上(scope)に展開する
 * - メモリ上のファイル情報をコントローラーの addSelectedFiles 関数に引き渡す
 * - コントローラーは新規に追加されたファイル情報を追加していき、全ての選択されたファイル情報を保持する
 *
**/
function inputFilesDirective($parse) {
  return {
    restrict: 'A',
    scope: {
      addSelectedFiles: '&'
    },
    link: function(scope, element, attrs) {
      const name = attrs.umInputFiles;
      const model = $parse(name);
      const modelSetter = model.assign;

      element.bind('change', () => {
        scope.$apply(() => {
          const inputFiles = element[0].files;
          modelSetter(scope, inputFiles);

          const newFiles = Array.from(inputFiles);
          scope.addSelectedFiles({
            files: newFiles
          });
        });
      });

      scope.$watch(attrs.umInputFiles, () => {
        element.val('');
      });
    }
  };
}

app.directive('umInputFiles', ['$parse', inputFilesDirective]);
