class ActivityStackedBarChartComponentController {
  constructor(
    $q,
    $interval,
    FarmService,
    ChartGenerator,
    $scope,
    IsMobile,
    SessionCache
  ) {
    'ngInject';

    this.$q = $q;
    this.$interval = $interval;
    this.FarmService = FarmService;
    this.HeatIndexAPI = HeatIndexAPI;
    this.ChartGenerator = ChartGenerator;
    this.$scope = $scope;
    this.IsMobile = IsMobile;
    this.useCalfManagement = SessionCache.farm().useCalfManagement();
  }

  $onDestroy() {
    if (this.state && this.state.activityStackedBarChart) {
      this.state.activityStackedBarChart.destroy();
    }
  }

  initializeState() {
    const options = ActivityStackedBarChart.defaultOptions(this.IsMobile);

    options.height = this.height || options.height;
    options.padding.left = this.paddingLeft || options.padding.left,
    options.padding.right = this.paddingRight || options.padding.right,
    options.padding.top = this.paddingTop || options.padding.top,
    options.padding.bottom = this.paddingBottom || options.padding.bottom;
    options.axis.y.label.position = 'inner-right';

    const activityStackedBarChart = new ActivityStackedBarChart('#activity-stacked-bar-chart', this.IsMobile, options);

    return {
      activityPeriod: null,
      baseDate: new Date(),
      activityDate: null,
      activityStackedBarChart: activityStackedBarChart,
      isLoading: false
    };
  }

  $onInit() {
    this.state = this.initializeState();

    this.isInitialized = true;
  }

  setIsLoading(state) {
    this.state.isLoading = state;
    this.$scope.$broadcast('isLoadingChange', state);
  }

  loadDataAndDrawChart() {
    if (!this.state.activityDate || !this.state.activityPeriod || !this.cow) return;

    this.setIsLoading(true);
    this.state.activityStackedBarChart.clear();

    return this.ChartGenerator.activityChart(
      this.state.activityDate,
      this.cow.cowId,
      this.state.activityPeriod,
      this.useCalfManagement,
      this.cow.birthday
    ).then((res) => {
      this.state.chartData = res;
    }).then(() => {
      this.drawChart(
        this.state.activityStackedBarChart,
        this.state.chartData,
        this.state.activityPeriod
      );
    }).finally(() => {
      this.setIsLoading(false);
      this.state.activityStackedBarChart.onClickLegend(this.onChangeHiddenLegendIds);
      this.state.activityStackedBarChart.toggle(this.defaultHiddenLegendIds);
    });
  }

  drawChart(activityStackedBarChart, chartData, activityPeriod) {
    if (this.hasNoData()) return;

    let kind;
    if (activityPeriod === 7) {
      kind = this.disableHourlyTick
        ? ActivityStackedBarChart.KIND.DATE_TIME_SIMPLE
        : ActivityStackedBarChart.KIND.DATE_TIME;
    } else {
      kind = ActivityStackedBarChart.KIND.DATE;
    }

    activityStackedBarChart.show(chartData, kind);
  }

  hasNoData() {
    return !this.state.isLoading && this.state.chartData && Object.keys(this.state.chartData).length === 0;
  }

  onActivityPeriodChanged(period) {
    this.state.activityPeriod = period;
    this.onChangePeriod({period: period});
    if (this.state.activityDate) this.loadDataAndDrawChart();
  }

  onActivityDateChanged(activityDate) {
    this.state.activityDate = activityDate;
    if (this.state.activityPeriod) this.loadDataAndDrawChart();
  }
}

app.controller('ActivityStackedBarChartComponentController', ActivityStackedBarChartComponentController);
