class GroupHeadageDetailsAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  farm(farmType) {
    return this.APIService.get(`/group/headage/details/farm?farm_type=${farmType}`);
  }
}

app.service('GroupHeadageDetailsAPI', GroupHeadageDetailsAPI);
