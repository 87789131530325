// eslint-disable-next-line no-unused-vars
class MasterBaseController {
  static bind(ctrl) {
    const base = new MasterBaseController();
    angular.extend(ctrl, base);

    const functions = [
      'filter',
      'filterOff',
    ];
    functions.forEach((f) => {
      ctrl[f] = base[f].bind(ctrl);
    });
  }

  constructor() {
    this.filterCondition = '';
  }

  filter() {
    let records = this.rawRecords.filter((r) => r.visible || this.showAll);

    if (this.filterCondition) {
      records = records.filter((r) => {
        return NormalizedString.include(r[this.filterItemKey], this.filterCondition);
      });
    }

    this.records = records;
    this.uiGrid.data = this.records;
  }

  filterOff() {
    this.filterCondition = '';
    this.filter();
  }
}
