class AlertCowListController {
  constructor(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    AlertService,
    DateUtilService,
    CowCollectionService,
    SelectCowService,
    ViewStateAPI
  ) {
    'ngInject';
    this.$state = $state;
    this.AlertService = AlertService;
    this.DateUtilService = DateUtilService;
    this.CowCollectionService = CowCollectionService;
    this.SelectCowService = SelectCowService.init();
    this.ViewStateAPI = ViewStateAPI;

    this.alertType = $stateParams.alertType;
    this.loading = true;

    const CONTENTS = {
      'feed-low': {title: '採食低下アラート', uri: 'alert-feed-low-beef'},
      'water-low': {title: '飲水低下アラート', uri: 'alert-water-low-beef'},
      'lie-high': {title: '横臥時間増加アラート', uri: 'alert-lie-high-beef'},
      'milk-low': {title: '乳量低下アラート', uri: 'alert-milk-low'}
    };
    const content = CONTENTS[this.alertType] || {title: 'アラート'};
    this.title = content.title;
    this.uri = content.uri;

    this.callbackParam = {
      state: Alert.convertAlertTypesToStates(this.alertType, 'milk'),
      params: $stateParams
    };

    $scope.$on('ngRepeatFinished', function() {
      FixedMidashi.create();
    });

    this.init();
  }

  init() {
    this.AlertService.alertCows(this.alertType).then((result) => {
      if (result.data && result.data.length > 0) {
        this.cows = result.data.map((cow) => new AlertActivityMilkDecorator(cow, this.DateUtilService));
        this.CowCollectionService.sort(this.cows);
      }
      this.loading = false;
    });
  }

  goToDetails(cowId) {
    const cowIds = this.cows.map((cow) => cow.cowId);
    const viewState = this.alertType === 'milk-low' ? 'MILKING' : 'ACTIVITY';
    const caller = {
      name: this.title,
      state: this.callbackParam.state,
      cowIds,
      viewState
    };

    this.$state.go('cowDetail', {cowId, caller});
  }

  onPrint() {
    this.ViewStateAPI.create(`print-in-legacy-alert-${this.alertType}`, 'legacy-alert');
    print();
  }

  /**
   * 日数をフォーマットする
   *
   * @param {number) days 日数
   * @return {String} (*日)
   */
  formatDays(days) {
    if (!days) return '';
    return `(${days}日)`;
  }
}

app.controller('AlertCowListController', AlertCowListController);
