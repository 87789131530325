app.controller('PurchaseListController', function($scope, $rootScope, $state, $stateParams, OrderedListService) {
  'ngInject';

  let SELLER_ALL = '0';

  $scope.title = '導入牛リスト';

  // 印刷用の処理
  let params = getPathParams();

  $scope.printable = params.printable === 'true';
  if ($scope.printable) {
    $('header').remove();
    $('#header-band').remove();
    $('body').addClass('print');
    $('#external-input').addClass('option-wrapper');
    $('.wrapper .content').css('margin', '0px');
    $('#print-link').remove();
    $('.content .title-box').css('position', 'absolute');
    $('.table-fix').parent().removeClass('fixed-tb');
  }

  // 印刷モードの場合は外部パラメーターを初期値としてセットする
  if (params.startDate) {
    $scope.startDate = new Date(Number(params.startDate)).getTime();
  } else {
    // 当月内の確認が多いので当月の1日を初期値として設定しておく
    $scope.startDate = new Date(moment().hour(0).minute(0).second(0).millisecond(0).unix() * 1000).getTime();
  }

  if (params.endDate) {
    $scope.endDate = new Date(Number(params.endDate)).getTime();
  } else {
    $scope.endDate = null;
  }

  $scope.seller = params.seller || SELLER_ALL;

  $scope.$on('ngRepeatFinished', function() {
    FixedMidashi.create();
  });

  (() => {
    $scope.busy = true;
    $scope.selection = {};

    $scope.selection.seller = [
      {'label': 'すべて表示する', 'value': SELLER_ALL}
    ];
    // 印刷モード画面への遷移を考慮
    $scope.seller = $scope.seller || SELLER_ALL;

    search();
  })();

  $scope.onSearch = () => {
    search();
  };

  function search() {
    let startDate = toParameterDate($scope.startDate);
    let endDate = toParameterDate($scope.endDate);

    $scope.loading = true;
    OrderedListService.getPurchaseList(startDate, endDate).then(function(result) {
      if (result.data && result.data.length > 0) {
        $scope.raw_records = result.data;
        $scope.selection.seller = createSellerOption($scope.raw_records);
        $scope.changeSeller();

        // 印刷モードの場合にはブラウザの印刷機能を起動する
        executePrinterIfPrintable();
      }
      $scope.loading = false;
    });
  }

  function createSellerOption(records) {
    // sellerの重複を排除した配列を作るだけでこれだけコードを書かないといけないのが切ない
    let uniqueSeller = records.map(function(record) {
      return record.seller;
    }).filter(function(seller, index, newArray) {
      if (!seller) {
        return false;
      }
      return newArray.indexOf(seller) === index;
    }).map(function(seller, index) {
      return {'label': seller, 'value': String(index + 1)};
    });
    uniqueSeller.unshift({'label': 'すべて表示する', 'value': SELLER_ALL});
    return uniqueSeller;
  }

  function toParameterDate(inputDate) {
    if (!inputDate) {
      return null;
    }
    return new Date(Number(inputDate));
  }

  $scope.changeSeller = () => {
    if ($scope.seller === SELLER_ALL) {
      $scope.cows = $scope.raw_records;
    } else {
      let filtered = $scope.selection.seller.filter(function(e) {
        return e.value === $scope.seller;
      });
      let selected = filtered[0].label;
      $scope.cows = $scope.raw_records.filter(function(record) {
        return record.seller === selected;
      });
    }
  };

  $scope.formatCowUid = (cowUid) => {
    return formatCowUid(cowUid);
  };

  $scope.formatYYMMDD = (date) => {
    return DateUtil.toYYMMDD(date);
  };

  $scope.formatGMMDD = (date) => {
    const option = {year: 'g', month: 'mm', day: 'dd'};
    return DateUtil.formatDate(date, option);
  };

  $scope.selectRow = (cowId) => {
    $state.go('cowDetail', {cowId: cowId}, {parent: $state.current});
  };

  $scope.onPrint = function(path) {
    let params = [];
    if ($scope.startDate) {
      params.push('startDate=' + $scope.startDate);
    }
    if ($scope.endDate) {
      params.push('endDate=' + $scope.endDate);
    }
    if ($scope.seller !== SELLER_ALL) {
      params.push('seller=' + $scope.seller);
    }

    oepnPrintableWindow('purchase-list', params);
  };

  $scope.clearInput = (key) => {
    $scope[key] = null;
  };

  function oepnPrintableWindow(path, params) {
    params = params || [];
    params.push('mode=1'); // src/js/app.js で使用しているのでセットする
    params.push('printable=true');

    let context = location.href.split('#');
    let href = context[0] + '#/' + path + '?' + params.join('&');
    let windowStyle = 'width=1100,height=600,menubar=no,toolbar=no,location=no,scrollbars=yes';
    window.open(href, path, windowStyle);
  }

  function executePrinterIfPrintable() {
    if ($scope.printable) {
      $scope.title = '導入牛リスト - ' + formatSearchConditoin(params);

      const interval = setInterval(function() {
        executePrinter(params, interval, null);
      }, 100);
    }
  }

  function formatSearchConditoin(params) {
    let start = params.startDate ? moment(new Date(Number(params.startDate))).format('YYYY/MM/DD') : '';
    let end = params.endDate ? moment(new Date(Number(params.endDate))).format('YYYY/MM/DD') : '';
    let condition = '対象期間：' + start + '～' + end;

    if (params.seller && params.seller !== SELLER_ALL) {
      let selected = $scope.selection.seller.filter(function(e) {
        return e.value === params.seller;
      });
      condition = condition + '、仕入先：' + selected[0].label;
    }
    return condition;
  }

});
