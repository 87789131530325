// eslint-disable-next-line no-unused-vars
class CustomlistOrdinalScale {
  static selectOptions(columnId) {
    if (GeBeefCattle.isClassItem(columnId)) {
      return GeBeefCattle.classItemSelectOptions();
    }

    return [];
  }
}
