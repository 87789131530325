/* eslint-disable */
class GebvIndexSetting {
  constructor(data) {
    this.data = angular.extend({}, data);
  }

  get weights() {
    return this.data.weights;
  }

  set weights(value) {
    return this.data.weights = value;
  }

  static get MAX_WEIGHT() {
    return 9;
  }

  static typicalWeights(type) {
    switch (type) {
      case 'balance':
        return [
          {
            trait: 'carcass_weight',
            weight: 1
          }, {
            trait: 'bms',
            weight: 2
          }, {
            trait: 'rib_eye_area',
            weight: 1
          }
        ];
      case 'quality':
        return [
          {
            trait: 'carcass_weight',
            weight: 1
          }, {
            trait: 'bms',
            weight: 2
          }
        ];
      case 'carcass_weight':
        return [
          {
            trait: 'carcass_weight',
            weight: 2
          }, {
            trait: 'bms',
            weight: 1
          }
        ];
      default:
        return [];
    }
  }
}
