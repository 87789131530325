class ReproductionPlannedEventsListController {
  constructor(
    ReproductionPlannedEventsAPI,
    $stateParams,
    SelectCowService,
    $modal,
    FarmService,
    DetailsStateFactory,
    $state,
    $rootScope
  ) {
    'ngInject';

    this.ReproductionPlannedEventsAPI = ReproductionPlannedEventsAPI;
    this.$modal = $modal;
    this.SelectCowService = SelectCowService.init();
    this.FarmService = FarmService;
    this.DetailsStateFactory = DetailsStateFactory;
    this.$state = $state;
    this.$rootScope = $rootScope;

    this.eventType = $stateParams.eventType || null;
    this.currentColumnSort = $stateParams.currentColumnSort || {};
    const date = $stateParams.date || new Date();
    this.init(date);
  }

  init(date) {
    this.updateDate(date);

    this.FarmService.show().then((res) => {
      const farm = new Farm(res);
      this.farmType = farm.farmType();
      this.typeMilk = this.farmType === Farm.farmTypeMilk;
      this.typeBeef = this.farmType === Farm.farmTypeBeef;
      this.labels = ReproductionPlannedEvent.eventDisplayLabels(false);
      this.eventFields = ReproductionPlannedEvent.eventFields(this.typeBeef, false);

      this.initCallbackParam();
      this.fetchData();
    });
  }

  updateDate(date) {
    this.date = date;
    this.dateDisplay = DateUtil.toYYYYMMDD(date);
  }

  shouldShowPrintAllButton() {
    return this.tasks && this.tasks.length > 0;
  }

  onPrevDateClick() {
    this.updateDate(DateUtil.previousDay(this.date));
    this.fetchData();
  }

  onNextDateClick() {
    this.updateDate(DateUtil.nextDay(this.date));
    this.fetchData();
  }

  onCowNoClick(cowId) {
    this.updateCallbackParam();
    this.DetailsStateFactory.setDisplayTab('dataTab');
    this.callbackParam.params.currentColumnSort = this.currentColumnSort;
    this.$state.go('cowDetail', {cowId: cowId, caller: this.callbackParam});
  }

  selectRow(event) {
    this.updateSelectedRows();
    this.updateCallbackParam();
  }

  selectAllRow(task) {
    task.events.forEach((e) => e.selected = task.selected);
    this.updateCallbackParam();
    this.updateSelectedRows();
  }

  initCallbackParam(date, eventType) {
    this.callbackParam = {
      state: 'reproductionPlannedEventsList',
      name: '繁殖予定',
      params: {
        date: date,
        eventType: eventType
      },
      cowIds: []
    };
  }

  updateCallbackParam() {
    const cowIds = this.tasks
      .map((t) => t.events)
      .reduce((acc, cur) => {
        return acc.concat(cur);
      }, [])
      .map((e) => e.cowId);

    this.callbackParam.params.date = this.date;
    this.callbackParam.params.eventType = this.eventType;
    this.callbackParam.cowIds = cowIds;
  }

  updateSelectedRows() {
    if (this.tasks.length === 0) return;

    const events = this.tasks
      .map((task) => task.events)
      .reduce((acc, cur) => acc.concat(cur))
      .filter((event) => event.selected);

    this.SelectCowService.selectRow(events, null);
    this.updateCallbackParam();
  }

  fetchData() {
    return this.ReproductionPlannedEventsAPI.show(this.date, this.eventType).then((result) => {
      const tasks = result.data;

      this.tasks = [
        'bunben',
        'overBunben',
        'hatsujo',
        'tanetsuke',
        'hormoneProgram',
        'ninshinkantei',
        'kannyu'
      ].filter((key) => key in tasks && tasks[key].length > 0)
        .map((key) => ({
          eventType: key,
          name: ReproductionPlannedEvent.eventTypeLabel(key),
          events: ReproductionPlannedEvent.addDisplayFields(tasks[key])
        }));

      this.eventCount = this.tasks.reduce((acc, cur) => {
        return acc + cur.events.length;
      }, 0);

      this.rawTasks = angular.copy(this.tasks);

      this.generateHeaders();
      this.updateSelectedRows();
      this.updateCallbackParam();

      this.tasks.forEach((task) => {
        const sort = this.currentColumnSort[task.eventType];

        if (sort) {
          const itemId = sort.column.columnId;
          const columnId = itemId === 'cowNo' ? 'cow_no' : itemId;
          const column = Object.assign({}, {columnId}, this.labels[itemId]);

          CustomlistSort.sort(task.events, column, sort.isAscending);
        }
      });
    });
  }

  generateHeaders() {
    this.headers = this.tasks.map((task) => {
      return this.eventFields[task.eventType]
        .reduce((acc, columnId) => {
          if (!columnId) return acc;

          if (Array.isArray(columnId)) {
            const label = columnId.reduce((acc, cur, index) => {
              let text = this.labels[cur].label;

              if (index > 0 && text) {
                text = `(${text})`;
              }

              acc.push(text);

              return acc;
            }, []).join('<br>');

            acc.push({
              columnId: columnId[0],
              classesCol: this.labels[columnId[0]].classesCol,
              label
            });
          } else {
            acc.push({
              columnId,
              classesCol: this.labels[columnId].classesCol,
              label: this.labels[columnId].label
            });
          }

          return acc;
        }, []);
    });
  }

  generateHeaderClass(itemId, eventType) {
    const columnId = itemId === 'cowNo' ? 'cow_no' : itemId;
    let sortedClass = 'sorted-none';

    if (this.currentColumnSort[eventType] && this.currentColumnSort[eventType].column.columnId === columnId) {
      if (this.currentColumnSort[eventType].isAscending) {
        sortedClass = 'sorted-asc';
      } else {
        sortedClass = 'sorted-desc';
      }
    }

    return sortedClass;
  }

  onClickColumnHeader(itemId, eventType, index) {
    const columnId = itemId === 'cowNo' ? 'cow_no' : itemId;
    const column = Object.assign({}, {columnId}, this.labels[itemId]);

    if (this.currentColumnSort[eventType] && this.currentColumnSort[eventType].column.columnId === columnId) {
      if (this.currentColumnSort[eventType].isAscending) {
        CustomlistSort.sort(this.tasks[index].events, column, false);
        this.currentColumnSort[eventType] = {column, isAscending: false};
      } else {
        this.currentColumnSort[eventType] = null;
        this.tasks[index] = angular.copy(this.rawTasks[index]);
      }
    } else {
      CustomlistSort.sort(this.tasks[index].events, column, true);
      this.currentColumnSort[eventType] = {column, isAscending: true};
    }
  }

  printOne(eventType) {
    const savedEventType = this.eventType;
    this.eventType = eventType;
    this.fetchData().then(() => {
      this.$rootScope.isPrinting = true;
      setTimeout(() => print(), 0);
      setTimeout(() => {
        this.$rootScope.$apply(() => this.$rootScope.isPrinting = false);
        this.eventType = savedEventType;
        this.fetchData();
      });
    });
  }

  printAll() {
    this.$rootScope.isPrinting = true;
    setTimeout(() => {
      print();
      this.$rootScope.$apply(() => this.$rootScope.isPrinting = false);
    });
  }

  openFullScreenWindow() {
    this.$modal.open({
      templateUrl: 'reproduction-planned-events/list/reproduction-planned-events-list.html',
      controller: 'ReproductionPlannedEventsListController',
      controllerAs: 'ctrl',
      size: 'custom-lg'
    });
  }
}

app.controller('ReproductionPlannedEventsListController', ReproductionPlannedEventsListController);
