class CowSearchIndexController {
  constructor(
    $modal,
    $state,
    $stateParams,
    SessionCache,
    CustomlistAPI,
    CustomlistRunner,
    CowSearchDialog,
    uiGridConstants,
    $rootScope
  ) {
    'ngInject';

    this.$modal = $modal;
    this.$state = $state;
    this.CustomlistRunner = CustomlistRunner;
    this.CowSearchDialog = CowSearchDialog;
    this.uiGridConstants = uiGridConstants;

    this.windowState = new WindowState();

    this.searchCondition = $stateParams.searchCondition;
    this.isDepositor = SessionCache.farm().isDepositor();
    this.isDepositorMode = this.isDepositor;

    this.callerFuncs = {
      refreshGrid: this.notifyUiGridRowChange.bind(this)
    };

    this.init(CustomlistAPI);
  }

  init(CustomlistAPI) {
    this.conditionOperators = [
      {key: 'equal', label: '条件値と等しい'},
      {key: 'not_equal', label: '条件値以外'},
      {key: 'include', label: '条件値を含む'},
    ];
    this.conditionOperator = 'equal';

    if (this.searchCondition.conditions) {
      const cowNoCondition = this.searchCondition.conditions.find((c) => c.columnId === 'cow_no');
      if (cowNoCondition) {
        this.cowNoCondition = cowNoCondition.conditionString;
        this.conditionOperator = cowNoCondition.operator;
      }
    }

    this.customlist = new Customlist();

    this.master = {};
    this.columns = [];

    this.cows = [];
    this.rawCows = [];
    this.currentColumnSort = null;
    this.sortedGridCol = null; // ソート対象でない他の列のソート状態を解除するための苦肉の策
    this.showCowSelect = true;

    // 牛個体詳細ボードの表示用
    this.currentCow = null;

    this.setCallbackParam();

    this.componentFuncs = {};

    const action = this.isDepositor ?
      CustomlistAPI.deposit.bind(CustomlistAPI) : CustomlistAPI.farm.bind(CustomlistAPI);
    action().then((res) => {
      const master = res.data;
      this.customlistId = master.customlistId;

      return this.run();
    });
  }

  setCallbackParam() {
    this.callbackParam = {
      state: 'cowSearch',
      name: '検索結果',
      params: {
        searchCondition: this.searchCondition
      }
    };
  }

  run() {
    // リロードやブックマークからの遷移などのケースへの対処
    if (!this.searchCondition.conditions) {
      this.$state.go('top');
      return;
    }

    this.loading = true;

    return this.CustomlistRunner.search(
      this.customlistId,
      this.searchCondition
    ).then((res) => {
      this.setConfig(res.data.config);

      this.cows = res.data.cows;
      this.rawCows = [].concat(this.cows);

      this.limitOver = res.data.limitOver;

      if (this.master.useFixedColumnWidth) {
        this.setUiGrid();
      } else {
        this.setLegacyGrid();
      }
    }).finally(() => {
      this.loading = false;
    });
  }

  setConfig(config) {
    this.master = config.master;
    this.columns = config.columns;
    this.needLabelAction = this.columns.some((c) => c.columnId === 'cow_labels');

    this.tableStyle = this.tableWidth();

    const columnMap = {};
    this.columns.forEach((c) => {
      columnMap[c.columnId] = c;
    });
    this.columnMap = columnMap;

    this.columns
      .filter((column) => column.fieldType === 'DATE' || column.fieldType === 'DATETIME')
      .forEach((column) => {
        const displayStyle = column.displayStyle ? column.displayStyle : Customlist.DEFAULT_DATE_STYLE;
        const styles = displayStyle.split('-');
        const option = {
          year: styles[0],
          month: styles[1],
          day: styles[2],
          separator_style: styles[3]
        };
        column.dateFormat = option;
      });

    this.columns.forEach((c) => {
      if (CustomlistSort.isUnsortable(c.columnId)) return;
      c.sortedClass = 'sorted-none';
    });
  }

  setUiGrid() {
    const showCowNoLink = !this.isDepositorMode;
    const generator = new UiGridColumnDefGenerator(this.columnMap, showCowNoLink);
    const columnDefs = generator.generate(this.columns);

    this.uiGrid = {
      appScopeProvider: this,
      enableSorting: false,
      columnDefs: columnDefs,
      rowTemplate: generator.generateRowTemplate(),
      data: this.cows,
      onRegisterApi: (gridApi) => {
        this.gridApi = gridApi;
      },
    };

    this.showUiGrid = true;
    this.showLegacyGrid = false;

    setTimeout(() => this.windowState.resize());
  }

  setLegacyGrid() {
    this.columns.forEach((c) => {
      if (CustomlistSort.isUnsortable(c.columnId)) return;
      c.sortedClass = 'sorted-none';
    });
    this.showLegacyGrid = true;
    this.showUiGrid = false;
  }

  notifyUiGridRowChange() {
    this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
  }

  onClickSearch() {
    this.CowSearchDialog.show(this.searchCondition);
  }

  onKeyDownCowNoCondition(event) {
    if (KeyInputUtil.isEnterKey(event)) {
      this.updateCowNoCondition();
    }
  }

  updateCowNoCondition() {
    this.noCondition = false;

    if (this.cowNoCondition) {
      const cowNoCondition = this.searchCondition.conditions.find((c) => c.columnId === 'cow_no');
      if (cowNoCondition) {
        cowNoCondition.operator = this.conditionOperator;
        cowNoCondition.conditionString = this.cowNoCondition;
      } else {
        const condition = {
          columnId: 'cow_no',
          conditionType: 'ID',
          operator: this.conditionOperator,
          conditionString: this.cowNoCondition
        };
        this.searchCondition.conditions.push(condition);
      }
    } else {
      // 検索条件の無い状態は許容しない
      if (this.searchCondition.conditions.length === 1) {
        this.noCondition = true;
        return;
      }

      const conditions = this.searchCondition.conditions.filter((c) => c.columnId !== 'cow_no');
      this.searchCondition.conditions = conditions;
    }
    this.run();
  }

  onClickConfig() {
    this.$modal.open({
      templateUrl: 'menu/customlist/config/edit.html',
      controller: 'CustomlistEditController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        params: () => {
          return {
            title: 'リスト設定編集',
            customlistId: this.customlistId,
            farmType: this.farmType,
            isDepositor: this.isDepositor
          };
        }
      }
    }).result.then(() => {
      this.cows = []; // CustomlistRuntimeDirectiveを再描画させるための措置
      this.run();
    });
  }

  onClickColumnHeader(columnId, gridCol) {
    if (CustomlistSort.isUnsortable(columnId)) return;

    const column = this.columnMap[columnId];
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        CustomlistSort.sort(this.cows, column, false);
        this.currentColumnSort = {column, isAscending: false};
      } else {
        this.currentColumnSort = null;
        this.cows = [].concat(this.rawCows);
        this.uiGrid.data = this.cows;
      }
    } else {
      CustomlistSort.sort(this.cows, column, true);
      this.currentColumnSort = {column, isAscending: true};
    }
  }

  // 旧実装用のイベント
  onClickColumnHeaderLegacy(column) {
    if (CustomlistSort.isUnsortable(column.columnId)) return;

    this.columns.forEach((c) => {
      if (c.columnId !== column.columnId) {
        if (CustomlistSort.isUnsortable(c.columnId)) return;
        c.sortedClass = 'sorted-none';
      }
    });

    let isAscending = true;
    switch (column.sortedClass) {
    case 'sorted-none':
      column.sortedClass = 'sorted-asc';
      break;
    case 'sorted-asc':
      column.sortedClass = 'sorted-desc';
      isAscending = false;
      break;
    case 'sorted-desc':
      column.sortedClass = 'sorted-none';
      this.currentColumnSort = null;
      this.cows = [].concat(this.rawCows);
      return;
    }

    const definition = this.columnMap[column.columnId];
    CustomlistSort.sort(this.cows, definition, isAscending);
    this.currentColumnSort = {column: definition, isAscending: isAscending};
  }

  // 旧実装用の関数
  generateHeaderClassLegacy(classes) {
    let thClass = '';
    classes.forEach((c) => thClass += ` ${c}`);
    return thClass;
  }

  /**
   * 列ヘッダーのCSSを生成します。
   * 項目毎の静的なCSSクラスと列のソート状態を表すCSSクラスを結合します。
   *
   * @param {String} customlist_column_definition.css_class
   * @param {Object} gridCol UI Gridの列オブジェクト
   * @return {String}
   */
  generateHeaderClass(cssClass, gridCol) {
    let sortedClass = '';
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        sortedClass = 'ui-grid-sorted-asc';
      } else {
        sortedClass = 'ui-grid-sorted-desc';
      }
    }

    return `${cssClass} ${sortedClass}`;
  }

  tableWidth() {
    if (!this.master.useFixedColumnWidth || this.printable) {
      return {width: '100%'};
    }

    const totalColumnWidth = this.columns.reduce((sum, current) => {
      return sum + current.width;
    }, 0);
    let width = totalColumnWidth + 70;
    if (this.master.showEntryColumn) {
      width += 200;
    }

    return {'max-width': `${width}px`, width: '100%'};
  }

  columnStyle(columnId) {
    if (!this.master.useFixedColumnWidth) {
      return null;
    }

    const definition = this.columnMap[columnId];
    if (!definition) {
      return null;
    }

    const width = `${definition.width}px`;
    return {width: width, minWidth: width};
  }

  cowBoardState() {
    return this.currentCow ? 'cow-board-opened' : '';
  }

  /* 以下は全画面での共通処理 */

  cowNumber() {
    return this.cows.length + '頭';
  }

  goToDetails(cowId) {
    const cowIds = this.cows.map((cow) => cow.cowId);
    this.callbackParam['cowIds'] = cowIds;
    this.$state.go('cowDetail', {cowId: cowId, caller: this.callbackParam});
  }

  updateCurrentCow(cow = null, colRenderIndex) {
    if (colRenderIndex === 0) return;

    return this.currentCow = cow;
  }

  classCell(cowId) {
    if (!this.currentCow) return;
    if (cowId !== this.currentCow.cowId) return;

    return 'ui-grid-row-cow-board-opened';
  }
}

app.controller('CowSearchIndexController', CowSearchIndexController);
