function mobileFooterNavComponent() {
  return {
    restrict: 'E',
    templateUrl: 'mobile/components/footer-nav/index.html',
    controller: MobileFooterNavController,
    controllerAs: 'MobileFooterNavController'
  };
}

app.directive('mobileFooterNav', mobileFooterNavComponent);
