class HomeHeadageSummaryDairyController {
  constructor(
    $state,
    SessionCache
  ) {
    'ngInject';

    this.$state = $state;

    HomeHeadageBaseController.bind(this);

    const farm = SessionCache.farm();

    this.init(farm);
  }

  init(farm) {
    this.calfManagement = farm.data.calfManagement;
  }

  $onChanges() {
    if (!this.data) return;

    this.showCalf = ['calved', 'always'].includes(this.calfManagement) || this.data.dairy.dry;
  }
}

function homeHeadageSummaryDairy() {
  return {
    templateUrl: 'menu/home/headage/summary/dairy/index.html',
    controller: HomeHeadageSummaryDairyController,
    controllerAs: 'ctrl',
    bindings: {
      data: '<'
    }
  };
}

app.component('homeHeadageSummaryDairy', homeHeadageSummaryDairy());
