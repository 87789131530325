class AlertMailAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/alert_mail');
  }

  show(id, confirmed) {
    const baseUrl = confirmed ? '/alert_mail' : '/alert_mail/entry';
    return this.APIService.get(`${baseUrl}/${id}`);
  }

  create(mailAddresses) {
    const params = {
      mailAddresses: mailAddresses
    };
    return this.APIService.post('/alert_mail/entry', params);
  }

  update(id, params, confirmed) {
    const baseUrl = confirmed ? '/alert_mail' : '/alert_mail/entry';
    return this.APIService.put(`${baseUrl}/${id}`, params);
  }

  delete(id, confirmed) {
    const baseUrl = confirmed ? '/alert_mail' : '/alert_mail/entry';
    return this.APIService.delete(`${baseUrl}/${id}`);
  }

  sendMail(id) {
    return this.APIService.put(`/alert_mail/entry/${id}/send_mail`, {});
  }
}

app.service('AlertMailAPI', AlertMailAPI);
