class ReactEventRegisterModalController {
  constructor($modalInstance,
    $scope,
    $modal,
    FarmService,
    CowService,
    SessionCache,
    $window,
    $timeout
  ) {
    'ngInject';
    this.$scope = $scope;
    this.$scope.onClose = (result) => {
      const isSaved = result.operation !== 'closed' && !!result.event;
      $modalInstance.close(isSaved ? result.event : null);
    };
  }
}

app.controller('ReactEventRegisterModalController', ReactEventRegisterModalController);
