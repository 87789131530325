class CowGroupAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/cow_group');
  }

  show(id) {
    return this.APIService.get(`/cow_group/${id}`);
  }

  summary() {
    return this.APIService.get('/cow_group/summary');
  }

  create(cowGroup) {
    return this.APIService.post('/cow_group', cowGroup);
  }

  update(id, cowGroup) {
    return this.APIService.put(`/cow_group/${id}`, cowGroup);
  }

  delete(id) {
    return this.APIService.delete(`/cow_group/${id}`);
  }

  updateSortOrder(orders) {
    const params = {orders: orders};
    return this.APIService.post('/cow_group/sort_order', params);
  }

  active() {
    return this.APIService.get('/cow_group/active');
  }
}

app.service('CowGroupAPI', CowGroupAPI);
