app.controller('CowNoSelectController', function(
  $modalInstance,
  params
) {
  'ngInject';
  this.cows = params.cows;
  this.seletedCow = this.cows[0];
  this.showCancel = params.disableCancel ? false : true;

  this.displayName = (cow) => {
    const shipDate = cow.eliminateDate ? ' 出荷日: ' + DateUtil.formatDate(cow.eliminateDate, {separator_style: 'jp'}) : '';
    const deathDate = cow.fallingDeadDate ? ' へい死日: ' + DateUtil.formatDate(cow.fallingDeadDate, {separator_style: 'jp'}) : '';
    let cowGroupName = '';
    let pen = '';

    if (cow.active) {
      cowGroupName = cow.cowGroupName ? ' 牛群:' + cow.cowGroupName : '';
      pen = cow.pen ? ' 牛房:' + cow.pen : '';
    }

    const bleId = cow.bleId ? ` センサー番号: ${cow.bleId}` : '';
    return cow.cowNo +
      ` (個体識別番号: ${formatCowUid(cow.cowUid)}` +
      ` [${cow.state}]` +
      shipDate +
      deathDate +
      cowGroupName +
      pen +
      bleId +
      ')';
  };

  this.decide = () => {
    $modalInstance.close({selected: this.seletedCow});
  };

  this.cancel = () => {
    $modalInstance.dismiss('cancel');
  };

});
