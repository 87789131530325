// eslint-disable-next-line no-unused-vars
class CowEventItemDefinition {
  static get allItems() {
    return {
      cowNo: {
        label: '牛番号',
        classesTh: ['col-w80'],
        classesTd: ['center'],
      },
      cowUid: {
        label: '個体識別番号',
        classesTh: ['col-w110'],
        classesTd: ['center'],
      },
      birthNumber: {
        label: '種付時産次',
        classesTh: ['col-w60'],
        classesTd: ['center'],
      },
      treatmentDiseaseDate: {
        label: '治療日',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      occurredDiseaseDate: {
        label: '発生日時',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      dim: {
        label: '分娩後日数',
        classesTh: ['col-w60'],
        classesTd: ['center'],
      },
      bodyTemperature: {
        label: '体温',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'FLOAT',
      },
      medication: {
        label: '投薬品名',
        classesTh: ['col-w180'],
        classesTd: [],
      },
      difficultiesOvaryStill: {
        label: '卵巣静止・萎縮<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesOvaryCystic: {
        label: '卵胞嚢腫<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesRemnant: {
        label: '黄体遺残<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesMetritis: {
        label: '子宮炎<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesPyometra: {
        label: '子宮蓄膿症<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      diagnosisResultOfOvaryL: {
        label: '卵巣診断結果(左)',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      diagnosisResultOfOvaryR: {
        label: '卵巣診断結果(右)',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      uterusDiagnosisResult: {
        label: '子宮診断結果',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      comment: {
        label: 'コメント',
        classesTh: ['col-w400'],
        classesTd: [],
      },
      occurredAt: {
        label: '発生日時',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      calvingDifficulty: {
        label: '分娩難易度',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      childNumber: {
        label: '出生頭数',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        textAfter: ' 頭',
        fieldType: 'INTEGER',
      },
      calf1birthResult: {
        label: '出産結果<br>(1頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
      },
      calf1breed: {
        label: '品種<br>(1頭目)',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      calf1gender: {
        label: '性別<br>(1頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
      },
      calf1registered: {
        label: 'データ作成<br>(1頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      calf1cowNo: {
        label: '牛番号<br>(1頭目)',
        classesTh: ['col-w100'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      calf1cowUid: {
        label: '個体識別番号<br>(1頭目)',
        classesTh: ['col-w110'],
        classesTd: ['center'],
      },
      calf1weightOnBirth: {
        label: '体重<br>(1頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        textAfter: ' kg',
        fieldType: 'INTEGER',
      },
      calf1calfNo: {
        label: '子牛番号<br>(1頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      calf1birthState: {
        label: '出生時状態<br>(1頭目)',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      calf2birthResult: {
        label: '出産結果<br>(2頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
      },
      calf2breed: {
        label: '品種<br>(2頭目)',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      calf2gender: {
        label: '性別<br>(2頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
      },
      calf2registered: {
        label: 'データ作成<br>(2頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      calf2cowNo: {
        label: '牛番号<br>(2頭目)',
        classesTh: ['col-w100'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      calf2cowUid: {
        label: '個体識別番号<br>(2頭目)',
        classesTh: ['col-w110'],
        classesTd: ['center'],
      },
      calf2weightOnBirth: {
        label: '体重<br>(2頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        textAfter: ' kg',
        fieldType: 'INTEGER',
      },
      calf2calfNo: {
        label: '子牛番号<br>(2頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      calf2birthState: {
        label: '出生時状態<br>(2頭目)',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      calf3birthResult: {
        label: '出産結果<br>(3頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
      },
      calf3breed: {
        label: '品種<br>(3頭目)',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      calf3gender: {
        label: '性別<br>(3頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
      },
      calf3registered: {
        label: 'データ作成<br>(3頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      calf3cowNo: {
        label: '牛番号<br>(3頭目)',
        classesTh: ['col-w100'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      calf3cowUid: {
        label: '個体識別番号<br>(3頭目)',
        classesTh: ['col-w110'],
        classesTd: ['center'],
      },
      calf3weightOnBirth: {
        label: '体重<br>(3頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        textAfter: ' kg',
        fieldType: 'INTEGER',
      },
      calf3calfNo: {
        label: '子牛番号<br>(3頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      calf3birthState: {
        label: '出生時状態<br>(3頭目)',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      calf4birthResult: {
        label: '出産結果<br>(4頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
      },
      calf4breed: {
        label: '品種<br>(4頭目)',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      calf4gender: {
        label: '性別<br>(4頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
      },
      calf4registered: {
        label: 'データ作成<br>(4頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      calf4cowNo: {
        label: '牛番号<br>(4頭目)',
        classesTh: ['col-w100'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      calf4cowUid: {
        label: '個体識別番号<br>(4頭目)',
        classesTh: ['col-w110'],
        classesTd: ['center'],
      },
      calf4weightOnBirth: {
        label: '体重<br>(4頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        textAfter: ' kg',
        fieldType: 'INTEGER',
      },
      calf4calfNo: {
        label: '子牛番号<br>(4頭目)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      calf4birthState: {
        label: '出生時状態<br>(4頭目)',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      freshCheckResult: {
        label: 'フレッシュチェック結果',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      bcs: {
        label: 'BCS',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'FLOAT',
      },
      beforeCowGroupName: {
        label: '移動元牛群',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      beforePen: {
        label: '移動元牛房',
        classesTh: ['col-w100'],
        classesTd: ['center'],
      },
      cowGroupName: {
        label: '移動先牛群',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      moveToPen: {
        label: '移動先牛房',
        classesTh: ['col-w100'],
        classesTd: ['center'],
      },
      deathReason: {
        label: 'へい死理由',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      difficultiesHipDislocation: {
        label: '股関節脱臼<br>軽重度',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesTeatInjury: {
        label: '乳頭損傷<br>軽重度',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesOther: {
        label: 'その他<br>軽重度',
        classesTh: ['col-w80'],
        classesTd: ['center'],
      },
      otherDisease: {
        label: 'その他',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      difficultiesPneumonia: {
        label: '肺炎<br>軽重度',
        classesTh: ['col-w120'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesDiarrhea: {
        label: '下痢<br>軽重度',
        classesTh: ['col-w120'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesSkinDisease: {
        label: '皮膚病<br>軽重度',
        classesTh: ['col-w120'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      selectedDryPeriod: {
        label: '前後期',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      lameDiagnosisResultFl: {
        label: '症状・診断<br>(左前)',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      lameDiagnosisResultFr: {
        label: '症状・診断<br>(右前)',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      lameDiagnosisResultBl: {
        label: '症状・診断<br>(左後)',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      lameDiagnosisResultBr: {
        label: '症状・診断<br>(右後)',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      locomotionScore: {
        label: '跛行スコア',
        classesTh: ['col-w100'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      mastitisCowGroupName: {
        label: '発生時牛群',
        classesTh: ['col-w100'],
        classesTd: ['center'],
      },
      mastitisScore: {
        label: '乳房炎スコア',
        classesTh: ['col-w100'],
        classesTd: ['center'],
      },
      mastitisBacteriaOfBreastFl: {
        label: '乳房炎菌<br>(左前)',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      mastitisBacteriaOfBreastFr: {
        label: '乳房炎菌<br>(右前)',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      mastitisBacteriaOfBreastBl: {
        label: '乳房炎菌<br>(左後)',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      mastitisBacteriaOfBreastBr: {
        label: '乳房炎菌<br>(右後)',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      mastitisDiagnosisResultOfBreastFl: {
        label: '乳房炎診断結果<br>(左前)',
        classesTh: ['col-w120'],
        classesTd: [],
      },
      mastitisDiagnosisResultOfBreastFr: {
        label: '乳房炎診断結果<br>(右前)',
        classesTh: ['col-w120'],
        classesTd: [],
      },
      mastitisDiagnosisResultOfBreastBl: {
        label: '乳房炎診断結果<br>(左後)',
        classesTh: ['col-w120'],
        classesTd: [],
      },
      mastitisDiagnosisResultOfBreastBr: {
        label: '乳房炎診断結果<br>(右後)',
        classesTh: ['col-w120'],
        classesTd: [],
      },
      stopMilkingOfBreastFl: {
        label: '盲乳処置<br>(左前)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      stopMilkingOfBreastFr: {
        label: '盲乳処置<br>(右前)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      stopMilkingOfBreastBl: {
        label: '盲乳処置<br>(左後)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      stopMilkingOfBreastBr: {
        label: '盲乳処置<br>(右後)',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      judgePregnantTiming: {
        label: '妊娠鑑定タイミング',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      ninshinkanteiResult: {
        label: '鑑定結果',
        classesTh: ['col-w100'],
        classesTd: ['center'],
      },
      workerName: {
        label: '作業者名',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      difficultiesKetosis: {
        label: 'ケトーシス<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesFattyLiver: {
        label: '脂肪肝<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesAbomasumDisplacement: {
        label: '第四胃変位<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesHypocalcium: {
        label: '低カルシウム血疾(乳熱)<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesAcidosis: {
        label: 'ルーメンアシドーシス<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesDowner: {
        label: 'ダウナー症候群<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesPlacenta: {
        label: '胎盤停滞<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesUrolithiasis: {
        label: '尿石<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesVitaminA: {
        label: 'ビタミンA欠乏症<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesTympanites: {
        label: '鼓張症<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      difficultiesStagnantFood: {
        label: '食滞<br>軽重度',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        fieldType: 'ORDINAL_SCALE',
      },
      tanetsukeMethod: {
        label: '種付方法',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      masterSpermNo: {
        label: '精液番号',
        classesTh: ['col-w80'],
        classesTd: ['center'],
      },
      masterSpermName: {
        label: '種雄牛名',
        classesTh: ['col-w100'],
        classesTd: ['center'],
      },
      embryoType: {
        label: '受精卵種類',
        classesTh: ['col-w100'],
        classesTd: ['center'],
      },
      embryoCertificateNo: {
        label: '受精卵証明書番号',
        classesTh: ['col-w180'],
        classesTd: ['center'],
      },
      inseminationCode: {
        label: '授精コード',
        classesTh: ['col-w100'],
        classesTd: ['center'],
      },
      otherReproductionWork: {
        label: 'その他処置',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      pregnantResult: {
        label: '受胎結果',
        classesTh: ['col-w80'],
        classesTd: ['center'],
      },
      buyer: {
        label: '販売先',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      shipmentNo: {
        label: '出荷番号',
        classesTh: ['col-w100'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      producingArea: {
        label: '生産地',
        classesTh: ['col-w100'],
        classesTd: ['center'],
      },
      producingFarmName: {
        label: '生産農場名',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      salesPrice: {
        label: '販売価格',
        classesTh: ['col-w150'],
        classesTd: ['center'],
        textBefore: '&yen;',
        fieldType: 'INTEGER',
      },
      expelledReason: {
        label: '除籍理由',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      vaccineName: {
        label: 'ワクチン種類',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      slaughterNo: {
        label: 'と畜番号',
        classesTh: ['col-w80'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      yieldGrade: {
        label: '歩留',
        classesTh: [],
        classesTd: [],
      },
      meetGrade: {
        label: '肉質',
        classesTh: [],
        classesTd: [],
        fieldType: 'INTEGER',
      },
      beforeSlaughterWeight: {
        label: 'と畜前<br>体重',
        classesTh: ['col-w80'],
        classesTd: [],
        fieldType: 'FLOAT',
      },
      dressedCarcassWeightOfL: {
        label: '枝肉重量<br>(左)',
        classesTh: [],
        classesTd: [],
        fieldType: 'FLOAT',
      },
      dressedCarcassWeightOfR: {
        label: '枝肉重量<br>(右)',
        classesTh: [],
        classesTd: [],
        fieldType: 'FLOAT',
      },
      loinArea: {
        label: '胸最長筋面積<br>(㎠)',
        classesTh: [],
        classesTd: [],
        fieldType: 'INTEGER',
      },
      ribsThickness: {
        label: 'ばらの厚さ',
        classesTh: [],
        classesTd: [],
        fieldType: 'FLOAT',
      },
      subcutaneousFat: {
        label: '皮下脂肪の厚さ',
        classesTh: [],
        classesTd: [],
        fieldType: 'FLOAT',
      },
      yieldBaseValue: {
        label: '歩留基準値',
        classesTh: [],
        classesTd: [],
        fieldType: 'FLOAT',
      },
      bmsNo: {
        label: 'BMS<br>No.',
        classesTh: [],
        classesTd: [],
        fieldType: 'INTEGER',
      },
      marblingGrade: {
        label: '脂肪交雑等級',
        classesTh: [],
        classesTd: [],
        fieldType: 'INTEGER',
      },
      bcsNo: {
        label: 'BCS<br>No.',
        classesTh: [],
        classesTd: [],
        fieldType: 'INTEGER',
      },
      gloss: {
        label: '光沢',
        classesTh: [],
        classesTd: [],
        fieldType: 'INTEGER',
      },
      bcsAndGlossGrade: {
        label: '等級',
        classesTh: [],
        classesTd: [],
        fieldType: 'INTEGER',
      },
      tight: {
        label: '締まり',
        classesTh: [],
        classesTd: [],
        fieldType: 'INTEGER',
      },
      texture: {
        label: 'きめ',
        classesTh: [],
        classesTd: [],
        fieldType: 'INTEGER',
      },
      tightAndTextureGrade: {
        label: '等級',
        classesTh: [],
        classesTd: [],
        fieldType: 'INTEGER',
      },
      bfsNo: {
        label: 'BFS<br>No.',
        classesTh: [],
        classesTd: [],
        fieldType: 'INTEGER',
      },
      fatLuster: {
        label: '光沢と質',
        classesTh: [],
        classesTd: [],
        fieldType: 'INTEGER',
      },
      bfsAndFatLusterGrade: {
        label: '等級',
        classesTh: [],
        classesTd: [],
        fieldType: 'INTEGER',
      },
      carcassDefectSummary: {
        label: '瑕疵',
        classesTh: ['col-w200'],
        classesTd: [],
      },
      otherFaultCorrection: {
        label: '歩留補正',
        classesTh: ['col-w80'],
        classesTd: [],
      },
      dressedCarcassUnitPrice: {
        label: '枝肉単価<br>(kgあたり)',
        classesTh: ['col-w80'],
        classesTd: [],
        fieldType: 'FLOAT',
      },
      totalDressedCarcassWeight: {
        label: '枝肉総重量<br>(kg)',
        classesTh: ['col-w80'],
        classesTd: [],
        fieldType: 'FLOAT',
      },
      dressedCarcassSalesPrice: {
        label: '枝肉販売金額<br>(円)',
        classesTh: ['col-w80'],
        classesTd: [],
        fieldType: 'FLOAT',
      },
      selectedVisceralDestruction: {
        label: '内臓廃棄',
        classesTh: ['col-w100'],
        classesTd: [],
      },
      carcassGrade: {
        label: '等級',
        classesTh: ['col-w100'],
        classesTd: [],
      },
      carcassYieldGrade: {
        label: '歩留',
        classesTh: ['col-w360'],
        classesTd: [],
      },
      carcassMeatGrade: {
        label: '肉質',
        classesTh: ['col-w550'],
        classesTd: [],
      },
      carcassFault: {
        label: 'その他',
        classesTh: ['col-w200'],
        classesTd: [],
      },
      embryoRecoveryRankA: {
        label: 'A',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankA1: {
        label: 'A1',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankA2: {
        label: 'A2',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankA3: {
        label: 'A3',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankA4: {
        label: 'A4',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankA5: {
        label: 'A5',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankB: {
        label: 'B',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankB1: {
        label: 'B1',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankB2: {
        label: 'B2',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankB3: {
        label: 'B3',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankB4: {
        label: 'B4',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankB5: {
        label: 'B5',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankC: {
        label: 'C',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankD: {
        label: 'D',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankDegenerated: {
        label: '変性',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      embryoRecoveryRankUnfertilized: {
        label: '未受精',
        classesTh: ['col-w50'],
        classesTd: ['center'],
        fieldType: 'INTEGER',
      },
      targetBreedingDate: {
        label: '対象種付日',
        classesTh: ['col-w120'],
        classesTd: ['center'],
      },
      embryoMasterRegistered: {
        label: '受精卵マスタへの登録',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      hormoneProgramName: {
        label: 'ホルモンプログラム名',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      },
      nextCowState: {
        label: '採卵後の状態',
        classesTh: ['col-w120'],
        classesTd: ['center'],
      },
      taioTitle: {
        label: 'タイトル',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      donorName: {
        label: '供卵牛',
        classesTh: ['col-w80'],
        classesTd: ['center'],
      },
      masterWorkNoteTypeName: {
        label: '作業区分',
        classesTh: ['col-w150'],
        classesTd: [],
      },
      plannedBredMethod: {
        label: '種付予定',
        classesTh: ['col-w100'],
        classesTd: [],
      },
      plannedBredAt: {
        label: '種付予定日',
        classesTh: ['col-w150'],
        classesTd: ['center'],
      }
    };
  }
}
