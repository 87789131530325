class CowCollectionService {
  constructor() {
    'ngInject';
    this.ZERO_FILL_20 = '0'.repeat(20);
  }

  /**
   * 牛群名称、牛番号でソートする
   * ※引数の配列に対して破壊的操作を行います
   *
   * @param {Array.<Object>} cows 牛個体情報の配列
   */
  sort(cows) {
    cows.sort((a, b) => {
      // 過渡期なのでスネークケースの変数名も考慮
      const groupNameA = a.cowGroupName || a.cow_group_name || '';
      const groupNameB = b.cowGroupName || b.cow_group_name || '';
      if (groupNameA > groupNameB) {
        return 1;
      } else if (groupNameA < groupNameB) {
        return -1;
      }
      return this.compareCowNo(a, b);
    });
  }

  /**
   * 牛番号の大小を比較する
   *
   * @param {Object} cowA 牛個体
   * @param {Object} cowB 牛個体
   * @param {boolean} isAscending true: 昇順、false: 降順
   * @return {number} 1: A > B, -1: A < B, 0: A = B
   */
  compareCowNo(cowA, cowB, isAscending = true) {
    const coefficient = isAscending ? 1 : -1;
    const cowNoA = cowA.cowNoForOrder || '';
    const cowNoB = cowB.cowNoForOrder || '';
    if (cowNoA > cowNoB) {
      return 1 * coefficient;
    } else if (cowNoA < cowNoB) {
      return -1 * coefficient;
    }
    return 0;
  }

  /**
   * 牛房の並び順を比較する
   *
   * @param {Object} cowA 牛個体
   * @param {Object} cowB 牛個体
   * @param {boolean} isAscending true: 昇順、false: 降順
   * @return {number} 1: A > B, -1: A < B, 0: A = B
   */
  comparePen(cowA, cowB, isAscending = true) {
    const result = this.compareZeroFilledString(cowA.pen, cowB.pen);
    if (isAscending || result === 0) {
      return result;
    }
    return result * (-1);
  }

  /**
   * 文字列を20桁のゼロ埋めをしてから大小比較を行います。
   * 数字列を含む文字列で直感的な並び順を実現するために使用します。
   *
   * @param {string} a 文字列
   * @param {string} b 文字列
   * @return {number} 1: a > b, -1: a < b, 0: a = b
   */
  compareZeroFilledString(a, b) {
    // null、undifined、空文字は一番下に配置させる
    if (!a && !b) return 0;
    if (!a) return 1;
    if (!b) return -1;

    const zeroFilledA = (this.ZERO_FILL_20 + a).slice(-20);
    const zeroFilledB = (this.ZERO_FILL_20 + b).slice(-20);

    if (zeroFilledA < zeroFilledB) return -1;
    if (zeroFilledA > zeroFilledB) return 1;
    return 0;
  }
}

app.service('CowCollectionService', CowCollectionService);
