class UmPlatformPartnerApi {
  constructor(UmPlatformApi) {
    'ngInject';
    this.UmPlatformApi = UmPlatformApi;
  }

  show() {
    return this.get();
  }

  get(path) {
    return this.UmPlatformApi.get(`/partners${path || ''}`);
  }
}

app.service('UmPlatformPartnerApi', UmPlatformPartnerApi);
