class RestartShipmentController {
  constructor(
    appConfig,
    appConst,
    $modalInstance,
    CowEventAPI,
    blockUI,
    params,
    StandardDialog
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.CowEventAPI = CowEventAPI;
    this.historyModalBlock = blockUI.instances.get('historyModal');
    this.StandardDialog = StandardDialog;

    const selectedEventType = appConst.eventTypes['shukkateishikaijo'];

    if (params.targetShipmentKind === 'milk') {
      this.title = '休薬期間変更(牛乳)';
      this.dateLabel = '休薬終了日(牛乳)';
    } else {
      this.title = '休薬期間変更(食肉)';
      this.dateLabel = '休薬終了日(食肉)';
    }

    this.formData = {
      cowId: params.cowId,
      eventType: 'shukkateishikaijo',
      eventName: selectedEventType.name,
      occurredAt: params.endDate,
      targetShipmentKind: params.targetShipmentKind,
      entryStaffName: appConfig.staff.name,
      lastEditStaffName: appConfig.staff.name,
    };

    this.initialState = {
      occurredAt: params.endDate,
    };

    this.CowEventAPI.getByEventType(params.cowId, 'shukkateishikaijo').then((res) => {
      const cowEvent = res.data.find((cowEvent) => {
        return (cowEvent.targetShipmentKind === params.targetShipmentKind) &&
          (DateUtil.isSameOrAfterDay(cowEvent.occurredAt, params.endDate));
      });
      if (cowEvent) {
        this.formData.id = cowEvent.id;
        this.formData.comment = cowEvent.comment;

        this.initialState.comment = cowEvent.comment;
      }
    });
  }

  ok() {
    const confirmDialog = this.StandardDialog.showYesNoConfirm({
      title: '確認',
      text1: `${this.dateLabel} を変更します。`,
      text2: 'よろしいですか？',
      yes: 'OK',
      no: 'キャンセル'
    });

    confirmDialog.result.then((_) => {
      this.historyModalBlock.start('登録中');

      let action = this.CowEventAPI.create.bind(this.CowEventAPI);
      if (this.formData.id) {
        action = this.CowEventAPI.update.bind(this.CowEventAPI);
      }

      action(this.formData).then((res) => {
        this.historyModalBlock.stop();
        this.$modalInstance.close();
      }).catch((err) => {
        this.historyModalBlock.stop();
        this.message = err.message;
      });
    });
  }

  clearInput(targetName) {
    this.formData[targetName] = null;
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  isValid() {
    if (!this.isChanged()) {
      return false;
    }

    this.message = '';

    if (DateUtil.isBeforeDay(this.formData.occurredAt, DateUtil.today())) {
      this.message = '本日以降の日付を入力してください';
      return false;
    }

    return true;
  }

  isChanged() {
    if (!DateUtil.isSameDay(this.formData.occurredAt, this.initialState.occurredAt)) {
      return true;
    }

    const comment = this.formData.comment || '';
    const initialComment = this.initialState.comment || '';
    if (comment !== initialComment) {
      return true;
    }

    return false;
  }
}

app.controller('RestartShipmentController', RestartShipmentController);
