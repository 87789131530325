// eslint-disable-next-line no-unused-vars
class Carcass {
  static get tallyByOptions() {
    return {
      'fatherName': '種牛',
      'producingFarmName': '生産農場',
      'raisingFarmName': '育成農場',
      'motherName': '母牛',
      'maternalGrandfatherName': '母父牛',
      'maternalGreatGrandfatherName': '母母父牛',
      'maternalFather4thName': '母母母父',
    };
  }

  static get targetGender() {
    return ['去勢', 'オス', 'メス', 'フリーマーチン'];
  }

  static get REQUIRED_FIELDS() {
    return [
      'yieldGrade',
      'meetGrade'
    ];
  }

  static get NUMERIC_FIELDS() {
    return [
      'slaughterNo',
      'beforeSlaughterWeight',
      'dressedCarcassWeightOfL',
      'dressedCarcassWeightOfR',
      'loinArea',
      'ribsThickness',
      'subcutaneousFat',
      'yieldBaseValue',
      'dressedCarcassUnitPrice',
      'dressedCarcassSalesPrice'
    ];
  }

  static get CARCASS_DEFECT_TYPES() {
    return {
      1: 'ア',
      2: 'イ',
      3: 'ウ',
      4: 'エ',
      5: 'オ',
      6: 'カ'
    };
  }

  static generateCarcassOptions() {
    const abc = ['A', 'B', 'C'];
    const oneToFive = [1, 2, 3, 4, 5];
    const oneToSeven = [1, 2, 3, 4, 5, 6, 7];
    const oneToTwelve = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const defects = [
      {key: 1, label: 'ア: シミ'},
      {key: 2, label: 'イ: ズル'},
      {key: 3, label: 'ウ: シコリ'},
      {key: 4, label: 'エ: アタリ'},
      {key: 5, label: 'オ: カツジョ'},
      {key: 6, label: 'カ: その他'}
    ];

    const organs = [
      '肝臓',
      '心臓',
      '肺',
      '頭',
      '舌',
      '横隔膜（ハラミ）',
      '横隔膜（サガリ）',
      '胃1',
      '胃2',
      '胃3',
      '胃4',
      '小腸',
      '大腸',
      '直腸',
      '膵臓',
      '膀胱',
      '脾臓',
      '腎臟',
      '子宮',
      '尾',
      '全廃棄'
    ];

    return {
      yieldGrade: abc,
      meetGrade: oneToFive,
      bmsNo: oneToTwelve,
      marblingGrade: oneToFive,
      bcsNo: oneToSeven,
      gloss: oneToFive,
      bcsAndGlossGrade: oneToFive,
      tight: oneToFive,
      texture: oneToFive,
      tightAndTextureGrade: oneToFive,
      bfsNo: oneToSeven,
      fatLuster: oneToFive,
      bfsAndFatLusterGrade: oneToFive,
      defects: defects,
      visceralDestruction: organs
    };
  }

  constructor(params) {
    Object.assign(this, params);
    this.carcassDefects = [];
    this.errors = {};
    this.createSelectedVisceralDestruction();
  }

  addDefect() {
    if (!this.canAddDefect()) return;

    const selectedValues = this.carcassDefects.map((d) => d.value);
    const value = [1, 2, 3, 4, 5, 6].find((n) => !selectedValues.includes(n));
    this.carcassDefects.push({value: value, parts: [{value: ''}]});
  }

  removeDefect(index) {
    this.carcassDefects.splice(index, 1);
  }

  addDefectPart(index) {
    const parts = this.carcassDefects[index].parts;
    parts.push({value: ''});
  }

  removeDefectPart(index, partIndex) {
    const parts = this.carcassDefects[index].parts;
    parts.splice(partIndex, 1);
  }

  canAddDefect() {
    return this.carcassDefects.length <= 5;
  }

  static defectSummary(defects) {
    if (!defects) {
      return '';
    }

    return defects.map((d) => {
      const type = Carcass.CARCASS_DEFECT_TYPES[d.value] || '';
      const parts = d.parts || [];
      const joinedParts = parts.filter((p) => p.value).map((p) => p.value).join('、');
      if (joinedParts) {
        return `${type}：${joinedParts}`;
      } else {
        return type;
      }
    }).join('、');
  }

  calculateTotalCarcassWeight() {
    const leftWeight = this.dressedCarcassWeightOfL || 0;
    const rightWeight = this.dressedCarcassWeightOfR || 0;

    this.totalDressedCarcassWeight = Math.round((leftWeight + rightWeight) * 10) / 10;
  }

  setRoundedDecimal() {
    [
      'slaughterNo',
      'beforeSlaughterWeight',
      'dressedCarcassWeightOfL',
      'dressedCarcassWeightOfR',
      'loinArea',
      'ribsThickness',
      'subcutaneousFat',
      'yieldBaseValue'
    ].forEach((f) => {
      if (this.hasOwnProperty(f) && StringUtil.isDecimal(this[f])) {
        this[f] = Math.round(Number(this[f]) * 10) / 10;
      }
    });
  }

  isValid() {
    this.validateRequiredFields();
    this.validateNumericFields();

    const error = Object.keys(this.errors).some((key) => {
      return this.errors[key] === true;
    });
    return !error;
  }

  toRequestParams() {
    return {
      cowId: this.cowId,
      entryStaffName: this.entryStaffName || this.lastEditStaffName,
      lastEditStaffName: this.lastEditStaffName,
      eventType: 'carcass',
      occurredAt: this.occurredAt,
      slaughterNo: this.slaughterNo,
      grade: this.generateGrade(),
      yieldGrade: this.yieldGrade,
      meetGrade: this.meetGrade,
      beforeSlaughterWeight: this.roundNumeric(this.beforeSlaughterWeight),
      dressedCarcassWeightOfL: this.roundNumeric(this.dressedCarcassWeightOfL),
      dressedCarcassWeightOfR: this.roundNumeric(this.dressedCarcassWeightOfR),
      loinArea: this.roundNumeric(this.loinArea),
      ribsThickness: this.roundNumeric(this.ribsThickness),
      subcutaneousFat: this.roundNumeric(this.subcutaneousFat),
      yieldBaseValue: this.roundNumeric(this.yieldBaseValue),
      bmsNo: this.bmsNo,
      marblingGrade: this.marblingGrade,
      bcsNo: this.bcsNo,
      gloss: this.gloss,
      bcsAndGlossGrade: this.bcsAndGlossGrade,
      tight: this.tight,
      texture: this.texture,
      tightAndTextureGrade: this.tightAndTextureGrade,
      bfsNo: this.bfsNo,
      fatLuster: this.fatLuster,
      bfsAndFatLusterGrade: this.bfsAndFatLusterGrade,
      fault: this.fault,
      otherFault: this.otherFault,
      otherFaultCorrection: this.otherFaultCorrection,
      dressedCarcassUnitPrice: this.dressedCarcassUnitPrice,
      totalDressedCarcassWeight: this.totalDressedCarcassWeight,
      dressedCarcassSalesPrice: this.dressedCarcassSalesPrice,
      visceralDestruction: this.joinedVisceralDestruction(),
      comment: this.comment
    };
  }

  // private

  generateGrade() {
    if (this.yieldGrade && this.meetGrade) {
      return this.yieldGrade + this.meetGrade;
    } else {
      return null;
    }
  }

  joinedVisceralDestruction() {
    return this.selectedVisceralDestruction ?
      this.selectedVisceralDestruction.value.join(':') : '';
  }

  createSelectedVisceralDestruction() {
    if (this.visceralDestruction) {
      this.selectedVisceralDestruction = {value: this.visceralDestruction.split(':')};
    }
  }

  roundNumeric(value) {
    return value ? Math.round(value * 10) / 10 : value;
  }

  // validation

  validateRequiredFields() {
    Carcass.REQUIRED_FIELDS.forEach((field) => {
      this.errors[field] = false;
      if (!this[field]) this.errors[field] = true;
    });
  }

  // IE, Edgeのtype=number対応
  validateNumericFields() {
    Carcass.NUMERIC_FIELDS.forEach((field) => {
      this.errors[field] = false;
      if (this[field] === undefined) this.errors[field] = true;
    });
  }

  // 枝肉販売金額の計算
  static calculateDressedCarcassSalesPrice(dressedCarcassUnitPrice, totalDressedCarcassWeight) {
    dressedCarcassUnitPrice = dressedCarcassUnitPrice ? dressedCarcassUnitPrice.replace(/,/g, '') : null;

    if (!(StringUtil.isDecimal(dressedCarcassUnitPrice) && StringUtil.isDecimal(totalDressedCarcassWeight))) {
      return null;
    }

    const dressedCarcassSalesPrice = dressedCarcassUnitPrice * totalDressedCarcassWeight;

    return dressedCarcassSalesPrice.toLocaleString();
  }
}
