class SelectionDialog {
  constructor(
    $modal
  ) {
    'ngInject';
    this.$modal = $modal;
  }

  execute(columnId, caption, currentSelections, excludeSelections) {
    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'dialog/condition/selection-dialog.html',
      controller: 'SelectionDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'select-standard',
      resolve: {
        params: () => {
          return {
            columnId: columnId,
            caption: caption,
            currentSelections: currentSelections,
            excludeSelections: excludeSelections || []
          };
        }
      }
    });

    return modalInstance.result;
  }
}

app.service('SelectionDialog', SelectionDialog);
