class JWTService {
  constructor(
    $http,
    $q,
    SessionStateService
  ) {
    'ngInject';
    this.$http = $http;
    this.$q = $q;
    this.SessionStateService = SessionStateService;

    this.cache = {
      token: undefined,
      timeReceived: undefined,
      bleToken: undefined,
      bleTimeReceived: undefined,
    };

    // JWT Api先
    this.remote = {
      api: 'getRemoteApiInfo',
      ble: 'getRemoteBleApiInfo',
    };
  }

  /**
   * JWTがまだ有効かどうか確認する
   *  5分間隔で更新されるから290s超えると無効にする
   * @param {moment} timeReceived
   * @return {bool}
   */
  isCachedJWTValid(timeReceived) {
    return timeReceived ? moment().diff(timeReceived, 's') <= 290 : false;
  }

  /**
   * JWTが必要なリクエストを発火
   * @param {string} path リクエスト先
   * @param {string} method GET / POST / DELETE
   * @return {promise} pathの返事をプロミスで返す
   */
  sendJWTRequest(path, method) {
    const request = {
      method: method,
      url: environment.legacyApiUrl + path,
      headers: {'Authorization': 'JWT ' + this.SessionStateService.sessionToken()},
    };

    return this.$http(request);
  }

  bleSenserRequest(path, method) {
    const request = {
      method: method,
      url: environment.activityApiUrl + path,
      headers: {'Authorization': 'JWT ' + this.SessionStateService.activityApiToken()},
    };

    return this.$http(request);
  }

  sendJWTBlobRequest(path, method) {
    const request = {
      method: method,
      url: environment.legacyApiUrl + path,
      headers: {'Authorization': 'JWT ' + this.SessionStateService.sessionToken()},
      responseType: 'blob',
      transformRequest: (data, headersGetter) => data,
    };

    return this.$http(request);
  }

  postWithJWT(path, params) {
    const request = {
      method: 'post',
      url: environment.legacyApiUrl + path,
      data: params,
      headers: {
        'Authorization': 'JWT ' + this.SessionStateService.sessionToken(),
      },
    };

    return this.$http(request);
  }
}

app.service('JWTService', JWTService);
