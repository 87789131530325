function umStopPropagationDirective() {
  return {
    restrict: 'A',
    link: function(scope, element) {
      element.on('click', (event) => event.stopPropagation());
    }
  };
}

app.directive('umStopPropagation', umStopPropagationDirective);
