/* eslint-disable */
/**
 * @deprecated Use AlertDecorator instead.
 */
class AlertActivityBeefDecorator {
  constructor(obj, DateUtilService) {
    this.obj = obj;
    this.DateUtilService = DateUtilService;
    this.selected = false;

    // TODO: Proxyに変えたい
    Object.keys(obj).forEach((key) => {
      if (key in this) return;
      this[key] = obj[key];
    });
  }

  get cowUid() {
    return formatCowUid(this.obj.cowUid);
  }

  get monthAge() {
    if (!this.obj.birthday) return '';
    const months = DateUtil.monthAge(Number(this.obj.birthday), DateUtil.today(), 1);
    return months > 0 ? String(months) : '';
  }

  get introducedDays() {
    if (!this.obj.introduceDate) return '';
    const days = this.DateUtilService.countDays(Number(this.obj.introduceDate), Date.now());
    return days > 0 ? String(days) : '';
  }
}
