app.controller('HelpDialogController', function(
  $document,
  $modalInstance,
  $timeout,
  type,
  top,
  left
) {
  'ngInject';
  const ctrl = this;

  // TO DELETE
  ctrl.type = type;

  // ダイアログの位置を指定する
  $timeout(() => {
    const bodyEl = $document[0].querySelector('body');
    bodyEl.classList.remove('modal-open');

    // top,leftを数字に変換する
    const x = $document[0].body.clientWidth * (Number(left) / 100);
    const y = $document[0].body.clientHeight * (Number(top) / 100);

    // element取得
    const el = $document[0].querySelector('.help-content');

    if (!el) return;
    // 位置設定
    el.style.top = `${y}px`;
    el.style.left = `${x}px`;
  });

  // ダイアログを閉じる
  ctrl.ok = () => {
    return $modalInstance.close();
  };
});
