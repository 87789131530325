class CountContainerController {
  constructor(
    $q,
    CowService,
    MilkCountAttributes,
    BeefCountAttributes,
    FatteningStageService,
    CustomlistAPI
  ) {
    'ngInject';
    this.$q = $q;
    this.CowService = CowService;
    this.MilkCountAttributes = MilkCountAttributes;
    this.BeefCountAttributes = BeefCountAttributes;
    this.FatteningStageService = FatteningStageService;
    this.CustomlistAPI = CustomlistAPI;
  }

  $onInit() {
    if (this.farm.isBeef()) {
      this.getCowSummaryAndStage();
    } else {
      this.getCowSummary();
    }
  }

  getCowSummary() {
    this.CowService.getCowSummary().then((result) => {
      this.cowsCount = result.data;
    }).catch((error) => {
      console.error(error);
    });
  }

  getCowSummaryAndStage() {
    this.$q.all([
      this.FatteningStageService.index(),
      this.CowService.getCowSummary(),
      this.CustomlistAPI.system()
    ]).then((res) => {
      const stages = res[0].data;
      this.beefByStages = stages.length > 0;
      this.cowsCount = res[1].data;
      const fatteningStageMaster = res[2].data.find((master) => master.configType === 'fattening_stage');
      if (fatteningStageMaster) {
        this.customlistId = fatteningStageMaster.customlistId;
      }
    }).catch((error) => {
      console.error(error);
    });
  }
}

function countContainer() {
  return {
    templateUrl: 'top/count/container.html',
    controller: CountContainerController,
    bindings: {
      farm: '<',
    }
  };
}

app.component('countContainer', countContainer());
