class CowLabelDialogController {
  constructor(
    $modal,
    $modalInstance,
    $timeout,
    blockUI,
    params,
    CowLabelAPI,
    MasterCowLabelAPI) {
    'ngInject';
    this.$modal = $modal;
    this.$modalInstance = $modalInstance;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.CowLabelAPI = CowLabelAPI;
    this.MasterCowLabelAPI = MasterCowLabelAPI;

    this.initialize(params);
  }

  initialize(params) {
    this.cowId = params.cowId;
    this.invalid = true;

    this.index();
  }

  index() {
    this.CowLabelAPI.show(this.cowId).then((res) => {
      this.initialLabels = res.data;
      this.labels = [].concat(this.initialLabels);

      this.validate();
    });
  }

  validate() {
    this.invalid = true;

    if (this.labels.length > 0) {
      this.noDataMessage = '';
    } else {
      this.noDataMessage = 'ラベルは設定されていません。';
    }

    if (this.labels.toString() === this.initialLabels.toString()) {
      return;
    }

    this.invalid = false;
  }

  add() {
    this.$modal.open({
      templateUrl: 'components/dialog/multi-select/index.html',
      controller: 'MultiSelectDialogController',
      controllerAs: 'ctrl',
      size: 'select-standard',
      resolve: {
        params: () => ({
          title: '個体ラベルの選択',
          caption: '貼付する個体ラベルを選択してください',
          indexAction: this.MasterCowLabelAPI.available.bind(this.MasterCowLabelAPI),
          filterFunc: (m) => {
            return m.visible;
          },
          currentSelectedIds: this.labels.map((label) => label.masterCowLabelId)
        }),
      },
    }).result.then((labels) => {
      labels.forEach((l) => {
        const label = {
          name: l.name,
          masterCowLabelId: l.id
        };
        this.labels.push(label);
      });
      this.validate();
    });
  }

  remove(index) {
    this.labels.splice(index, 1);
    this.validate();
  }

  save() {
    this.blockUI.start('更新中');

    this.CowLabelAPI.update(this.cowId, this.labels)
      .then(() => {
        this.blockUI.done(() => {
          this.blockUI.start('更新が完了しました');
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close();
      }, (res) => {
        this.blockUI.stop();
        this.errorMessage = ErrorUtil.formatErrorMessage(res.data.messages);
      });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('CowLabelDialogController', CowLabelDialogController);
