app.service('OrderedListService', ($http, $rootScope, JWTService) => {
  'ngInject';
  let service = {
    getPurchaseList: (startDate, endDate) => {
      // YYYY-MM-DD形式に変換する
      let paramStartDate = startDate ? getDateLabelForApiResDateTime(startDate) : '1900-01-01';
      let paramEndDate = endDate ? getDateLabelForApiResDateTime(endDate) : '9999-12-31';
      let params = 'start_date=' + paramStartDate + '&end_date=' + paramEndDate;
      return JWTService.sendJWTRequest('/ordered-list/purchase-list?' + params, 'GET');
    },

    getEstrusCycleList: () => {
      return JWTService.sendJWTRequest('/ordered-list/estrus-cycle-list', 'GET');
    },

    getAiResults: (startDate, endDate) => {
      // YYYY-MM-DD形式に変換する
      let paramStartDate = startDate ? getDateLabelForApiResDateTime(startDate) : '1900-01-01';
      let paramEndDate = endDate ? getDateLabelForApiResDateTime(endDate) : '9999-12-31';
      let params = 'start_date=' + paramStartDate + '&end_date=' + paramEndDate;
      return JWTService.sendJWTRequest('/ordered-list/ai-results?' + params, 'GET');
    },

    saveAiResults: (params) => {
      return JWTService.postWithJWT('/ordered-list/ai-results-save', params);
    },

    getMastitisList: () => {
      return JWTService.sendJWTRequest('/ordered-list/mastitis-list', 'GET');
    },

    getOtherPerinatalList: () => {
      return JWTService.sendJWTRequest('/ordered-list/other-perinatal-list', 'GET');
    },

    getHospitalPerinatalList: () => {
      return JWTService.sendJWTRequest('/ordered-list/hospital-perinatal-list', 'GET');
    },
  };
  return service;
});
