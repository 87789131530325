class CertificateController {
  constructor(
    $state,
    SessionCache
  ) {
    'ngInject';

    this.$state = $state;

    const farm = SessionCache.farm();

    this.index(farm);
  }

  index(farm) {
    this.title = '提出書類';
    this.items = [{
      category: '出生',
      hidden: !(farm.isDairy() || farm.isBreeding()) || !['always', 'calved'].includes(farm.data.calfManagement),
      fields: [{
        field: 'certificate-birth',
        name: '出生報告'
      }]
    }, {
      category: '繁殖',
      fields: [
        {
          field: 'certificate-ai',
          name: '授精証明書',
          hidden: !farm.certificateAiMenus().includes('standard')
        },
        {
          field: 'certificate-ai-specific',
          name: '授精証明書 (鹿児島版)',
          params: {menu_id: 'kagoshima'},
          hidden: !farm.certificateAiMenus().includes('kagoshima')
        }
      ]
    }];
  }

  goTo(to, params) {
    params = params || {};
    this.$state.go(to, params);
  }
}

app.controller('CertificateController', CertificateController);
