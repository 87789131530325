class DhiImportLiajController {
  constructor(
    $modal,
    SessionCache,
    DhiImportAPI
  ) {
    'ngInject';

    this.$modal = $modal;
    this.account = SessionCache.account();
    this.DhiImportAPI = DhiImportAPI;

    this.init();
  }

  $onChanges(changes) {
    if (changes.activeImportType) {
      this.clearSelectedFiles();
    }
  }

  init() {
    this.invalidDate = false;
    this.selectedFiles = [];
  }

  addSelectedFiles(name, files) {
    const key = name === 'inputFileLiaj1' ? 0 : 1;
    this.selectedFiles[key] = files[0];
  }

  showLiajImport() {
    return this.activeImportType === 'liaj';
  }

  showSelectedFiles(key) {
    return typeof this.selectedFiles[key] !== 'undefined';
  }

  disabledUpload() {
    return !(!this.invalidDate && this.dhiDate && this.showSelectedFiles(0) && this.showSelectedFiles(1));
  }

  changeDhiDate() {
    this.invalidDate = !(DateUtil.isValidDate(this.dhiDate) && DateUtil.includedPermittedPeriod(this.dhiDate));
  }

  clearDhiDate() {
    this.dhiDate = '';
    this.invalidDate = false;
  }

  clearSelectedFiles() {
    this.clearDhiDate();
    this.selectedFiles = [];
  }

  deleteSelectedFiles(id) {
    this.selectedFiles = this.selectedFiles.filter((file, index) => {
      return id !== index;
    });
  }

  uploadConfirm() {
    if (this.disabledUpload()) return;

    this.DhiImportAPI.index().then((res) => {
      const confirm = res.data.some((item) => {
        return DateUtil.toYYYYMMDD(item.dhiDate) === DateUtil.toYYYYMMDD(this.dhiDate) && item.dhiLinkage === 'liaj';
      });

      const fd = new FormData();

      fd.append('dhi_linkage', this.activeImportType);
      fd.append('operation_user_name', this.account.name);
      fd.append('dhi_date', DateUtil.toMSec(this.dhiDate));

      this.selectedFiles.forEach((file) => {
        fd.append('files[]', file);
      });

      if (!confirm) {
        this.uploadSelectedFiles({
          fd: fd
        });
        return;
      }

      const message = `選択した検定日のデータは取込済です。<br>
      ファイル取込を実行するとデータが上書きされますが、よろしいでしょうか？<br><br>
      検定日 ${DateUtil.toYYYYMMDD(this.dhiDate)}`;

      this.$modal.open({
        windowTemplateUrl: 'components/u-modal/window.html',
        templateUrl: 'menu/dhi/dhi-import/modal.html',
        controller: 'DhiImportModalController',
        controllerAs: 'ctrl',
        backdrop: false,
        resolve: {
          params: () => {
            return {
              size: 'small',
              mode: 'confirm',
              heading: '検定データ取込',
              message: message
            };
          }
        }
      }).result.then(() => {
        this.uploadSelectedFiles({
          fd: fd
        });
      }, () => {
        return;
      });
    }).catch((res) => {
      const message = this.formatErrorMessage({
        res: res,
        defaultMessage: '検定データの取込でエラーが発生しました。'
      });

      this.$modal.open({
        windowTemplateUrl: 'components/u-modal/window.html',
        templateUrl: 'menu/dhi/dhi-import/modal.html',
        controller: 'DhiImportModalController',
        controllerAs: 'ctrl',
        backdrop: false,
        resolve: {
          params: () => {
            return {
              size: 'small',
              mode: 'message',
              heading: '検定データ取込',
              message: message
            };
          }
        }
      });
    });
  }
}

function dhiImportLiajComponent() {
  return {
    templateUrl: 'menu/dhi/dhi-import/import-liaj.html',
    controller: DhiImportLiajController,
    controllerAs: 'ctrl',
    bindings: {
      activeImportType: '<',
      changeTab: '&',
      classHeading: '&',
      uploadSelectedFiles: '&',
      formatErrorMessage: '&'
    }
  };
}

app.component('dhiImportLiaj', dhiImportLiajComponent());
