class DepositAlertAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  summary() {
    return this.APIService.get('/deposit/alert/summary');
  }

  dysstasiaAlertsByCow(cowId) {
    return this.APIService.get('/deposit/alert/dysstasia/' + cowId);
  }
}

app.service('DepositAlertAPI', DepositAlertAPI);
