class GlobalNavController {
  constructor(
    $rootScope,
    $q,
    $modal,
    $state,
    $document,
    $timeout,
    AccountService,
    SessionStateService,
    SessionCache,
    SwitchFarmDialog,
    CowGroupService,
    CustomlistAPI,
    ViewStateAPI
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$q = $q;
    this.$modal = $modal;
    this.$state = $state;
    this.AccountService = AccountService;
    this.SessionStateService = SessionStateService;
    this.SwitchFarmDialog = SwitchFarmDialog;
    this.CowGroupService = CowGroupService;
    this.CustomlistAPI = CustomlistAPI;
    this.ViewStateAPI = ViewStateAPI;
    this.farm = SessionCache.farm();
    this.useUClips = this.farm.data.useUClips;
    this.account = SessionCache.account();
    this.isOpen = false;
    this.showEmbryoRecoveryReport = this.farm.isDairy() || this.farm.isBreeding();
    this.showCertificate = !this.farm.isDepositor() && (this.farm.isDairy() || this.farm.isBreeding());
    this.useReproductionCowGroupSummary = this.farm.data.useReproductionCowGroupSummary;
    this.useAlertMail = this.farm.useAlertMail();
    this.deregisters = [];

    this.deregisters.push(this.$rootScope.$on('$locationChangeSuccess', () => {
      this.closeMenu();
    }));

    this.deregisters.push($rootScope.$on('onClickUmBackdrop', () => {
      $timeout(() => this.closeMenu());
    }));

    // 牛群の更新を反映
    this.deregisters.push($rootScope.$on('menu:refreshGroups', () => {
      this.updateGroups();
    }));

    // カスタムリストの更新を反映
    this.deregisters.push($rootScope.$on('menu:refreshCustomList', () => {
      this.updateCustomlists();
    }));

    this.onInit();
  }

  $onDestroy() {
    this.deregisters.forEach((d) => d());
  }

  onInit() {
    this.isMilkFarm = this.farm.isDairy();
    this.isBeefFarm = this.farm.isBeef();
    this.isDepositorFarm = this.farm.isDepositor();
    this.isDeposit = this.isDepositorFarm || this.farm.isAgent();
    this.isGroupOrganizer = this.farm.isGroupOrganizer();
    this.isGroupMember = this.farm.isGroupMember();
    this.isAdministrator = this.account.isAdministrator();
    this.showDepositMenu = this.isDeposit && (this.isAdministrator || this.account.isDepositor());
    this.showSwitchFarm = this.account.canSwitchFarm();
    this.showManageAccount = this.account.canManageFarm();
    this.showOrderedList = this.farm.data.useLegacyReportMenu;
    this.showGroupManagementMenu = this.isDepositorFarm || (this.showDepositMenu && this.showSwitchFarm) ||
      this.isGroupMember;
    this.showExternalList = ['owner', 'admin', 'organizer'].includes(this.account.values.role);

    if (this.account.isItemFilterApplied()) {
      this.showCowEntry = false;
      this.showFileImport = false;
    } else {
      this.showCowEntry = !this.isDeposit;
      this.showFileImport = true;
    }

    this.activeTabListName = this.isDepositorFarm ? 'custom' : 'cowGroup';

    if (this.isDepositorFarm) {
      this.CustomlistAPI.deposit().then((res) => {
        this.depositList = res.data;
      });
    }

    this.showDhiImport = this.isMilkFarm && this.farm.data.dhiLinkage !== 'none';
    this.showGenomicEvaluationList = this.farm.showBeefGenom() && !this.isDepositorFarm;

    this.updateGroups();
    this.updateCustomlists();
  }

  updateGroups() {
    this.$q.all([
      this.CowGroupService.summary(),
      this.CustomlistAPI.cowGroups()
    ]).then((res) => {
      const groups = res[0].data.filter((group) => group.menuDisplayed);

      const customlists = {};
      res[1].data.forEach((customlist) => {
        customlists[customlist.configType] = customlist;
      });

      const setCustomList = (group, customlist) => {
        group.customlistId = customlist.customlistId;
        group.customListName = customlist.name;
        group.configType = customlist.configType;
      };

      groups.forEach((group) => {
        if (group.calfGroup) {
          setCustomList(group, customlists['group_calf']);
        } else if (group.reproductionGroup) {
          setCustomList(group, customlists['group_reproduction']);
        } else if (group.cullingGroup) {
          setCustomList(group, customlists['group_culling']);
        } else if (group.milkingGroup) {
          setCustomList(group, customlists['group_milking']);
        } else if (group.dryGroup) {
          setCustomList(group, customlists['group_dry']);
        } else if (group.fatteningGroup) {
          setCustomList(group, customlists['group_fattening']);
        } else {
          setCustomList(group, customlists['group_reproduction']);
        }
      });

      this.groups = groups;
    });
  }

  updateCustomlists() {
    this.CustomlistAPI.index().then((res) => {
      this.customlists = res.data.filter((master) => master.showInMenu);
    });
  }

  openMenu() {
    this.isOpen = true;
    this.$rootScope.isGlobalNavOpen = true;
  }

  closeMenu() {
    this.isOpen = false;
    this.$rootScope.isGlobalNavOpen = false;
  }

  goTo(uiSref, params) {
    this.closeMenu();
    params = params || {};
    this.$state.go(uiSref, params);
  }

  bulkEventEntry() {
    this.closeMenu();
    this.$modal.open({
      templateUrl: 'components/event-registration/event-selection.html',
      controller: 'EventSelectionController',
      controllerAs: 'ctrl',
      size: '1012',
      resolve: {
        cowIds: () => [],
        cowType: () => null,
        isDepositor: () => false,
        qwert: () => 0,
        caller: () => {}
      },
    });
  }

  singleEventEntry() {
    this.closeMenu();
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'components/event-registration/cow-selection.html',
      controller: 'CowSelectionController as ctrl'
    }).result.then(() => {
      this.closeMenu();
    });
  }

  tabListItemClass(listName) {
    return listName === this.activeTabListName
      ? 'gn-tab-list-item is-active'
      : 'gn-tab-list-item';
  }

  toggleTabList(listName) {
    this.activeTabListName = listName;
  }

  switchFarm() {
    this.closeMenu();
    this.SwitchFarmDialog.excute();
  }

  logout() {
    this.ViewStateAPI.create('logout', 'global-nav').finally(() => {
      this.SessionStateService.logout();
    });
  }
}

function globalNavComponent() {
  return {
    templateUrl: 'components/global-nav/index.html',
    transclude: true,
    controller: GlobalNavController,
  };
}

app.component('globalNav', globalNavComponent());
