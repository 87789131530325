class FileImportDetailController {
  constructor(
    $state,
    $stateParams,
    FileImportAPI,
    FileUtilService,
    blockUI,
    StandardDialog
  ) {
    'ngInject';
    this.$state = $state;
    this.FileImportAPI = FileImportAPI;
    this.FileUtilService = FileUtilService;
    this.blockUI = blockUI;
    this.StandardDialog = StandardDialog;

    this.init($stateParams);
  }

  init(params) {
    this.id = params.id;

    this.index();
  }

  index() {
    this.FileImportAPI.show(this.id).then((res) => {
      const importStatus = res.data;

      this.fileName = importStatus.fileName;
      this.allCount = importStatus.allCount;
      this.canceled = importStatus.canceled;
      this.fileType = importStatus.fileType;
      this.canCancel = FileImport.canCancel(this.canceled, this.fileType);

      this.columns = importStatus.header.map((column) => {
        if (column.ignored) {
          column['headerClass'] = 'state-error';
        }
        return column;
      });

      this.ignoredColumns = this.columns
        .filter((column) => column.ignored)
        .map((column) => column.caption)
        .join('、');

      this.records = importStatus.details.map((record) => {
        const values = record.detail.map((v) => {
          if (!v) return '';
          return v;
        });
        if (values.length < this.columns.length) {
          const diff = this.columns.length - values.length;
          const filler = Array(diff).fill('');
          filler.forEach((s) => values.push(s));
        }
        return values;
      });

      const width = (this.columns.length * 150) + 50;
      this.tableStyle = {'max-width': `${width}px`, 'width': '100%'};
    });
  }

  export() {
    if (!this.allCount) return;

    const matrixData = [].concat(this.records);
    const headerRow = this.columns.map((c) => c.caption);
    matrixData.unshift(headerRow);
    this.FileUtilService.exportAsExcel(matrixData, this.fileName);
  }

  cancel() {
    if (!this.allCount) return;
    if (!this.canCancel) return;

    this.StandardDialog.showDeleteConfirm({
      title: '取込ファイルの削除',
      text1: `${this.fileName}で取込んだデータ`,
    }).result.then((result) => {
      if (!result) return;

      this.blockUI.start('削除通知中');

      this.FileImportAPI.delete(this.id).then((res) => {
        this.blockUI.stop();
        this.return();
      }).catch((res) => {
        this.blockUI.stop();
        this.message = ErrorUtil.formatErrorMessage(res.data.messages);
      });
    });
  }

  return() {
    this.$state.go('fileImport');
  }
}

app.controller('FileImportDetailController', FileImportDetailController);
