// eslint-disable-next-line no-unused-vars
class CustomlistFilter {
  filter(cows, conditions) {
    if (conditions.length === 0) return cows;

    return cows.filter((cow) => {
      return conditions.every((condition) => {
        if (condition.columnId === 'comments') {
          const value = cow.comments.join('\t');
          return this.stringFilter(value, condition);
        }

        if (Customlist.isCommaDelimitedColumn(condition.columnId)) {
          const value = cow.origin[condition.columnId];
          return this.numericFilter(value, condition);
        }

        switch (condition.conditionType) {
        case 'STRING': {
          const value = cow.origin[condition.columnId];
          return this.stringFilter(value, condition);
        }
        case 'NUMERIC': {
          const value = cow[condition.columnId]; // 日付から計算される日数は変換後のオブジェクトに保持している
          return this.numericFilter(value, condition);
        }
        case 'DATE': {
          const value = cow.origin[condition.columnId];
          return this.dateFilter(value, condition);
        }
        case 'SELECTION': {
          if (condition.columnId === 'cow_labels') {
            const values = cow.cowLabels;
            return this.cowLabelsFilter(values, condition);
          } else {
            const value = cow.origin[condition.columnId];
            return this.selectionFilter(value, condition);
          }
        }
        case 'MASTER': {
          const value = cow.origin[condition.columnId];
          return this.masterFilter(value, condition);
        }
        case 'ORDINAL_SCALE': {
          const value = cow.origin[condition.columnId];
          return this.numericFilter(value, condition);
        }
        }
        return false;
      });
    });
  }

  stringFilter(value, condition) {
    const word = condition.conditionString || '';
    const words = word.replace(/、/g, ',').replace(/，/g, ',').split(',').map((s) => s.trim());
    if (condition.operator === 'include') {
      return words.some((w) => {
        return StringUtil.include(value, w);
      });
    } else if (condition.operator === 'exclude') {
      const included = words.some((w) => {
        return StringUtil.include(value, w);
      });
      return !included;
    } else if (condition.operator === 'equal') {
      return words.some((w) => {
        return value === w;
      });
    } else if (condition.operator === 'blank') {
      return !value;
    }
    return false;
  }

  numericFilter(value, condition) {
    const num = Number(value);
    if (condition.operator === 'between') {
      const min = condition.conditionMinNumber ? Number(condition.conditionMinNumber) : Number.NEGATIVE_INFINITY;
      const max = condition.conditionMaxNumber ? Number(condition.conditionMaxNumber) : Infinity;
      return (num >= min) && (num <= max);
    }
    return false;
  }

  dateFilter(unixtime, condition) {
    if (condition.operator === 'between') {
      if (!unixtime) return false;

      const min = condition.conditionMinDate ? condition.conditionMinDate.getTime() : Number.NEGATIVE_INFINITY;
      const max = condition.conditionMaxDate ? condition.conditionMaxDate.getTime() : Infinity;
      return (unixtime >= min) && (unixtime <= max);
    } else if (condition.operator === 'blank') {
      return !unixtime;
    }
    return false;
  }

  selectionFilter(value, condition) {
    if (condition.operator === 'include') {
      return condition.selections.includes(value);
    } else if (condition.operator === 'exclude') {
      const included = condition.selections.includes(value);
      return !included;
    }
    return false;
  }

  masterFilter(value, condition) {
    const ids = condition.masters.map((m) => m.id);
    if (condition.operator === 'include') {
      return ids.includes(value);
    } else if (condition.operator === 'exclude') {
      const included = ids.includes(value);
      return !included;
    }
    return false;
  }

  cowLabelsFilter(values, condition) {
    if (condition.operator === 'include') {
      return values.some((value) => {
        return condition.selections.includes(value);
      });
    } else if (condition.operator === 'exclude') {
      const included = values.some((value) => {
        return condition.selections.includes(value);
      });
      return !included;
    }
    return false;
  }
}
