/**
 * 各アラート回数取得、保持用のサービスクラスです
 *
 * [example]
 * AlertSummaryStateService.load() <- 回数取得
 * AlertSummaryStateService.summary <- 保持している各アラート回数
 * {
 *  milkLow: number, <- 乳量低下アラート頭数
 *  feedLow: number, <- 採食時間低下アラート頭数(活動量系)
 *  waterLow: number, <- 飲水回数低下アラート頭数(活動量系)
 *  lieHigh: number, <- 横臥時間増加アラート頭数(活動量系)
 *  heat: number, <- 発情アラート頭数
 *  falseHeat: number, <- 発情(妊娠牛)アラート頭数
 *  dysstasia: number, <- 起立困難アラート頭数(未解決)
 *  dysstasiaResolved: number, <- 起立困難アラート頭数(解決済)
 *  illness: number, <- 疾病アラート頭数
 *  badConditionCow: number <- 乳量低下、EC、活動量系、疾病の重複除去した頭数
 *  calving: number, <- 分娩アラート頭数
 *  calvingConfirmed: number, <- 分娩アラート頭数(確認済)
 *  calvingSensorDropped: number, <- 分娩センサー脱落アラート頭数
 * }
 */
class AlertSummaryStateService {
  constructor(
    AlertService
  ) {
    'ngInject';

    this.AlertService = AlertService;
    this.summary = {};
  }

  load() {
    this.AlertService.summary().then((res) => {
      const alerts = {
        MILK_LOW: 'milkLow',
        FEED_LOW: 'feedLow',
        WATER_LOW: 'waterLow',
        LIE_HIGH: 'lieHigh',
        HEAT: 'heat',
        FALSE_HEAT: 'falseHeat',
        DYSSTASIA: 'dysstasia',
        DYSSTASIA_RESOLVED: 'dysstasiaResolved',
        ILLNESS: 'illness',
        BAD_CONDITION_COW: 'badConditionCow',
        SENSOR_DROPPED: 'sensorDropped',
        CALVING: 'calving',
        CALVING_CONFIRMED: 'calvingConfirmed',
        CALVING_SENSOR_DROPPED: 'calvingSensorDropped'
      };

      res.data.forEach((alertObj) => {
        if (alerts[alertObj.alertType]) {
          this.summary[alerts[alertObj.alertType]] = alertObj.count;
        }
      });
    }).catch((error) => console.error(error));
  }
}

app.service('AlertSummaryStateService', AlertSummaryStateService);
