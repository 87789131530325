function reproductionFertilizationHistory($window) {
  return {
    restrict: 'A',
    scope: {
      showPagerPrev: '='
    },
    link: (scope, element, attrs) => {
      angular.element('#uGrid--reproduction__scroll').bind('scroll', (e) => {
        if (!$window.matchMedia('(pointer: coarse)').matches) return;

        if (angular.element(e.target).scrollLeft() > 0) {
          scope.showPagerPrev = true;
        } else {
          scope.showPagerPrev = false;
        }
      });
    }
  };
}

app.directive('reproductionFertilizationHistory', ['$window', reproductionFertilizationHistory]);
