// eslint-disable-next-line no-unused-vars
class GroupTransfer {
  static get TRANSFER_TYPE_SELECTION() {
    return [{
      farmType: 'farmTypeBreeding',
      items: ['breeding', 'growing', 'moving', 'other']
    }, {
      farmType: 'farmTypeFattening',
      items: ['fattening', 'moving', 'other']
    }, {
      farmType: 'farmTypeDairy',
      items: ['drying', 'growing', 'moving', 'other']
    }];
  }

  static get TRANSFER_TYPE_MAP() {
    return [{
      value: 'fattening',
      label: '肥育開始',
      cowGroupAttribute: 'fatteningGroup'
    }, {
      value: 'breeding',
      label: '繁殖'
    }, {
      value: 'growing',
      label: '育成',
      cowGroupAttribute: 'calfGroup'
    }, {
      value: 'drying',
      label: '乾乳',
      cowGroupAttribute: 'dryGroup'
    }, {
      value: 'moving',
      label: '所属変更'
    }, {
      value: 'other',
      label: 'その他'
    }];
  }

  static get WARNING_MESSAGE() {
    return {
      cowGroupAttribute: '異動先の牛群属性が異動理由と一致していません'
    };
  }

  static get ERROR_MESSAGE() {
    return {
      nothing: '異動元牧場に存在しない個体です',
      required: '必須項目です',
      duplicate: {
        cowNo: '牛番号が重複しています',
        cowName: '名前が重複しています',
        cowUid: '個体識別番号が重複しています'
      },
      noData: {
        cowNo: '牛番号が入力されていません',
        cowName: '名前が入力されていません',
        cowUid: '個体識別番号が入力されていません'
      }
    };
  }

  static generateMemberFarms(farms) {
    return farms.filter((farm) => {
      return farm.groupRole === 'member';
    }).map((farm) => {
      return {
        id: farm.id,
        farmName: farm.farmName,
      };
    });
  }

  static setMemberToFarms(farms, fromId) {
    return farms.filter((farm) => farm.id !== fromId);
  }

  static generateTransferTypes(farms) {
    const organizerFarm = farms.find((farm) => {
      return farm.groupRole === 'organizer';
    });

    return GroupTransfer.TRANSFER_TYPE_SELECTION.filter((option) => {
      return organizerFarm[option.farmType];
    }).map((option) => {
      return option.items;
    }).reduce((acc, cur) => {
      return [...acc, ...cur];
    }, []).filter((items, index, array) => {
      return array.lastIndexOf(items) === index;
    }).map((item) => {
      return GroupTransfer.TRANSFER_TYPE_MAP.find((label) => {
        return label.value === item;
      });
    });
  }

  static generateAllTransferTypes() {
    return GroupTransfer.TRANSFER_TYPE_SELECTION.map((option) => {
      return option.items;
    }).reduce((acc, cur) => {
      return [...acc, ...cur];
    }, []).filter((items, index, array) => {
      return array.lastIndexOf(items) === index;
    }).map((item) => {
      return GroupTransfer.TRANSFER_TYPE_MAP.find((label) => {
        return label.value === item;
      });
    });
  }

  static generatePens(cowGroups, toCowGroupId) {
    if (!toCowGroupId) return;

    const pens = cowGroups.find((cowGroup) => {
      return cowGroup.cowGroupId === toCowGroupId;
    }).pens;

    return pens ? pens.split(',') : [];
  }

  static validateRequired(model, requiredFields) {
    return requiredFields.filter((item) => !model[item.value]);
  }

  static validateDate(date) {
    return !(DateUtil.isValidDate(date) && DateUtil.includedPermittedPeriod(date));
  }

  static validateCowGroupAttribute(cowGroups, toCowGroupId, cowGroupAttribute) {
    const cowGroup = cowGroups.find((item) => {
      return item.cowGroupId === toCowGroupId;
    });

    if (!cowGroupAttribute || !cowGroup || cowGroup[cowGroupAttribute]) return null;

    return GroupTransfer.WARNING_MESSAGE.cowGroupAttribute;
  }

  static hasError(row) {
    return Object.entries(row.error).some(([key, value]) => value);
  }
}
