class DepositCowEventAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  search(condition) {
    const params = {
      eventTypes: condition.eventTypes,
      startDate: DateUtil.toW3CFormat(condition.startDate),
      endDate: DateUtil.toW3CFormat(condition.endDate),
      qwerts: condition.ids,
      workerNames: condition.workerNames,
    };
    return this.APIService.post('/deposit/cow_event/search', params);
  }
}

app.service('DepositCowEventAPI', DepositCowEventAPI);
