class CowDetailIndexController {
  constructor(
    $state,
    $rootScope,
    $stateParams,
    SessionCache
  ) {
    'ngInject';

    this.$rootScope = $rootScope;

    if (SessionCache.farm().useTemporaryGroupView()) {
      const cowId = $stateParams.cowId;
      const caller = $stateParams.caller;

      $state.go('temporary-group-view-cow-detail', {cowId, caller});
    }
  }

  showCowDetailLegacy() {
    return this.$rootScope.uiVersion === 'c-1';
  }
}

app.controller('CowDetailIndexController', CowDetailIndexController);
