class SessionStateService {
  constructor(
    $rootScope,
    $http,
    $q,
    $window
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$http = $http;
    this.$q = $q;
    this.$window = $window;

    this.session = {};
  }

  sessionToken() {
    return this.session.sessionToken;
  }

  refreshToken() {
    return this.session.refreshToken;
  }

  expiredAt() {
    return this.session.expiredAt;
  }

  activityApiToken() {
    return this.session.activityApiToken;
  }

  authenticated() {
    // TODO セッション管理の仕様を綿密に検討したうえで実装を見直すこと
    if (this.sessionToken()) {
      return true;
    }

    const session = JSON.parse(localStorage.getItem(environment.sessionTokenKey));
    if (!session || !session.sessionToken) {
      return false;
    }

    this.session = session;
    return true;
  }

  login(email, password) {
    const params = {email: email, password: password};
    return this.postRequest('/session', params)
      .then((res) => {
        const session = res.data;

        if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
          window.ReactNativeWebView.postMessage(JSON.stringify({
            type: 'updateSession',
            sessionToken: session.sessionToken,
            refreshToken: session.refreshToken,
            expiredAt: session.expiredAt,
          }));

          return;
        }

        this.session = {
          sessionToken: session.sessionToken,
          refreshToken: session.refreshToken,
          expiredAt: session.expiredAt,
          activityApiToken: session.activityApiToken,
        };
        localStorage.setItem(environment.sessionTokenKey, JSON.stringify(this.session));
        localStorage.setItem('u-motion-last-auth-method', 'e-mail');
        this.$rootScope.authenticated = true;
        this.$rootScope.showLogin = false;

        const d = this.$q.defer();
        d.resolve(res);
        return d.promise;
      });
  }

  refreshRequest() {
    return this.putRequest('/session', {});
  }

  switchFarm(id) {
    return this.putRequest('/session/farm', {id});
  }

  sendChangePasswordEmail(email) {
    return this.postRequest('/session/password', {email: email});
  }

  logout() {
    localStorage.removeItem(environment.sessionTokenKey);
    this.$rootScope.authenticated = false;
    this.$rootScope.showLogin = true;
    this.session = {};

    const urlBase = this.$window.location.href.split('/#/')[0];
    this.$window.location.href = `${urlBase}/#/login`;
    this.$window.location.reload();
  }

  postRequest(uri, params) {
    const req = {
      method: 'POST',
      url: environment.apiUrl + uri,
      data: params
    };
    return this.$http(req);
  }

  putRequest(uri, params) {
    const token = `Bearer ${this.refreshToken()}, JWT ${this.sessionToken()}`;
    const req = {
      method: 'PUT',
      url: environment.apiUrl + uri,
      headers: {'Authorization': token},
      data: params
    };

    return this.$http(req)
      .then((res) => {
        const session = res.data;
        this.session = {
          sessionToken: session.sessionToken,
          refreshToken: session.refreshToken,
          expiredAt: session.expiredAt,
          activityApiToken: session.activityApiToken,
        };
        localStorage.setItem(environment.sessionTokenKey, JSON.stringify(this.session));
      });
  }
}

app.service('SessionStateService', SessionStateService);
