class CowEventsService {
  constructor(
    APIService
  ) {
    'ngInject';
    this.APIService = APIService;

    // 牛個体画面で現在表示中の牛のUid
    this.selectedCowId = '';

    // 牛個体画面で現在表示中の牛のデータ色々
    // サービス内には使われてないから置き場的に間違ってる。
    this.selectedCow = {};
  }

  // 牛個体の全イベントの取得
  index(cowId = undefined) {
    return this.APIService.get(`/cow_event/cow/${cowId || this.selectedCowId}`);
  }

  // 牛個体のイベント別の取得
  getByEventType(cowId, eventType) {
    return this.APIService.get(`/cow_event/cow/${cowId}/${eventType}`);
  }

  // 牛イベントの追加
  insert(data) {
    return this.APIService.post(`/cow_event`, data);
  }

  // 牛イベントの更新
  update(data) {
    return this.APIService.put(`/cow_event/${data.id}`, data);
  }

  // 対象イベント情報を削除
  delete(id) {
    return this.APIService.delete(`/cow_event/${id}`);
  }

  // 一括登録
  insertInBulk(data, options = {}) {
    const params = Object.assign({}, options);
    params['_json'] = data;
    return this.APIService.post(`/cow_event/bulk`, params);
  }

  /**
   * イベント投稿のバリデーション
   * @param {obect} data
   */
  confirm(data) {
    return this.APIService.post(`/cow_event/confirm`, data);
  }
}

app.service('CowEventsService', CowEventsService);
