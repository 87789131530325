function changeTimelineYear() {
  'ngInject';
  return {
    restrict: 'A',
    scope: '=',
    link: (s, el, attr) => {
      let targetTop, timelineContents;

      el.on('scroll', () => {
        if (!targetTop || -33) {
          targetTop = document.querySelector('.timeline-year-wrapper').getBoundingClientRect().top - 33;
        }
        timelineContents = el.find('.timeline-content-wrapper').toArray();
        const nextTimelineContent = timelineContents.find((elem) => {
          return elem.getBoundingClientRect().top >= targetTop;
        });
        if (nextTimelineContent) s.$apply(() => s.latestEventYear = nextTimelineContent.getAttribute('year'));
      });
    }
  };
}

app.directive('changeTimelineYear', changeTimelineYear);
