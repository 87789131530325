// 活動量指数タイムライングラフ
class ActivityRateChart {
  static defaultOptions() {
    return JSON.parse(JSON.stringify({
      padding: {
        left: 60,
        right: 20,
        bottom: 10
      },
      size: {
        height: null
      },
      data: {
        x: 'x',
        columns: [],
        colors: {},
        type: 'line',
        names: {
          '発情指数': 'スコア'
        }
      },
      legend: {
        show: false
      },
      axis: {
        y: {
          max: 100,
          min: 0,
          label: {
            text: '発情指数',
            position: 'outer-right'
          },
          padding: {
            top: 0,
            bottom: 0
          }
        },
        x: {
          type: 'timeseries',
          tick: {
            format: '%m/%d %H:%M'
          }
        }
      },
      subchart: {
        show: false
      },
      point: {
        show: false
      },
      tooltip: {
        show: true,
        format: {}
      },
      grid: {
        x: {
          show: true
        }
      },
    }));
  }

  constructor(selector, options, IsMobile) {
    this.selector = selector;
    this.options = options || ActivityRateChart.defaultOptions();
    this.options.bindto = this.selector;
    this.options.onrendered = () => {
      let axisX = $(selector + ' .c3-axis.c3-axis-x .c3-axis-x-label');
      axisX.attr('x', Number(axisX.attr('x')) + 40);
      axisX.attr('y', -20);
    };
    this.isMobile = IsMobile;
  }

  show(data, regions, kind) {
    this.destroy();

    this.options.data.columns = data;
    this.options.regions = regions;
    this._xTickFormat(kind, data);
    this.tooltipFormatTitle(kind);
    this.xAxis(kind);
    this.grid(kind);
    this.chart = c3.generate(this.options);
  }

  _xTickFormat(kind, data) {
    if (kind === ActivityRateChart.KIND.DATE) {
      this.options.axis.x.tick.format = '%m/%d';
      this.options.axis.x.tick.fit = true;
      delete this.options.axis.x.tick.values;
      if (this.options.axis.x.tick.culling) {
        delete this.options.axis.x.tick.culling.max;
      }
      if (this.isMobile) {
        if (!this.options.axis.x.tick.culling) this.options.axis.x.tick.culling = {};
        this.options.axis.x.tick.culling.max = 7;
      }
    } else if (kind === ActivityRateChart.KIND.DATE_TIME_SIMPLE) {
      this.options.axis.x.tick = {
        fit: false,
        culling: {
          max: 7
        },
        format: (d) => {
          if (d.getHours() === 0) return DateUtil.toMMDD(d);
        },
        values: data[0].reduce((acc, cur) => {
          const last = acc[acc.length - 1];
          if (cur === 'x') return acc;
          if (!last || DateUtil.diffMinutes(last, cur) === 180) {
            acc.push(cur);
          }
          return acc;
        }, [])
      };

      if (this.options.axis.x.tick.culling && this.isMobile) {
        delete this.options.axis.x.tick.culling.max;
      }
    } else {
      this.options.axis.x.tick.format = '%m/%d';
      this.options.axis.x.tick.fit = false;
      delete this.options.axis.x.tick.values;
      if (this.options.axis.x.tick.culling) {
        delete this.options.axis.x.tick.culling.max;
      }
    }
  }

  tooltipFormatTitle(kind) {
    this.options.tooltip.format.title = (d) => {
      return kind === ActivityRateChart.KIND.DATE ? DateUtil.toMMDD(d) : DateUtil.toMMDDHHmm(d);
    };
  }

  xAxis(kind) {
    if (kind === ActivityStackedBarChart.KIND.DATE) {
      this.options.axis.x.padding = {
        left: ActivityRateChart.PADDING_035DAY,
        right: ActivityRateChart.PADDING_035DAY
      };
    } else {
      this.options.axis.x.padding = {};
    }
  }

  grid(kind) {
    this.options.grid.x.show = kind !== ActivityRateChart.KIND.DATE;
  }

  update(data) {
    this.chart.load({
      columns: data
    });
  }

  updateGridXLines(lines) {
    this.options.grid.x.lines = lines;
  }

  destroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  clear() {
    $(this.selector).html('');
  }
}
// 定数
ActivityRateChart.KIND = {
  DATE: 0,
  DATE_TIME: 1,
  DATE_TIME_SIMPLE: 2
};

ActivityRateChart.PADDING_035DAY = 1000 * 60 * 60 * 24 * 0.35;
