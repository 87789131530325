/**
 * Help Dialog Component
 * usage: <help-dialog
 *          type="string"
 *          top="15"
 *          left="65">
 *        </help-dialog>
 *
 * top, left = cssのtop,left. ダイアログの初期位置を決めるもの。単位は%。
*/

function helpDialogComponent() {
  'ngInject';
  return {
    template: `
      <button class="btn btn-help" ng-click="$ctrl.help()">?</buttonlabel>
    `,
    bindings: {
      type: '@',
      top: '<',
      left: '<'
    },
    controller: helpDialogController,
  };
}

function helpDialogController($modal) {
  'ngInject';
  const vm = this;

  // ダイアログ表示フラグ
  // 1つ以上に開けないためのフラグ
  let isDialogOpen = false;

  vm.$onInit = () => {
    // top/leftに指定がない場合、デフォルト値を設定
    vm.top = vm.top || 15;
    vm.left = vm.left || 65;
  };

  /**
   * 一括登録のモーダルを開く
   */
  vm.help = () => {
    if (isDialogOpen) return;

    isDialogOpen = true;
    $modal.open({
      // draggable directive追加するために親テンプレを指定
      windowTemplateUrl: 'components/help-dialog/help-dialog-window.html',

      // typeによって内容テンプレを変更
      // templateUrl: `components/help-dialog/dialogs/help-dialog-${vm.type}.html`,
      templateUrl: 'components/help-dialog/help-dialog.html',
      controller: 'HelpDialogController as ctrl',
      backdrop: false, // disable black backdrop
      keyboard: false, // disable ESC
      resolve: {
        type: () => vm.type, // DELETE
        top: () => vm.top,
        left: () => vm.left,
      },
    }).result.then(() => isDialogOpen = false);
  };
}

app.component('helpDialog', helpDialogComponent());
