class HomeDailyReportTimelineFilterController {
  constructor(
    $modalInstance,
    params
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
    this.filterCondition = params.filterCondition;

    this.init();
  }

  init() {
    this.items = EventType.allTypes.reduce((acc, cur) => {
      const findItem = acc.find((item) => {
        return item.group === cur.group;
      });

      const field = {
        eventType: cur.eventType,
        label: cur.label,
        selected: this.filterCondition.includes(cur.eventType)
      };

      if (!findItem) {
        acc.push({
          group: cur.group,
          items: [field],
        });
      } else {
        findItem.items.push(field);
      }

      return acc;
    }, []).map((item) => {

      item.selected = item.items.every((field) => {
        return field.selected;
      });

      return item;
    });
  }

  onClickGroup(group) {
    this.items = this.items.map((item) => {
      if (item.group === group) {
        item.items = item.items.map((field) => {
          field.selected = item.selected;

          return field;
        });
      }

      return item;
    });
  }

  onClickField(group) {
    this.items = this.items.map((item) => {
      if (item.group === group) {
        item.selected = item.items.every((field) => {
          return field.selected;
        });
      }

      return item;
    });
  }

  onClickAll(selected) {
    this.items = this.items.map((item) => {
      item.selected = selected;
      item.items = item.items.map((field) => {
        field.selected = selected;

        return field;
      });

      return item;
    });
  }

  submit() {
    this.filterCondition = this.items.map((item) => {
      return item.items.filter((field) => {
        return field.selected;
      }).map((field) => {
        return field.eventType;
      });
    }).reduce((acc, cur) => {
      return [...acc, ...cur];
    }, []);

    this.$modalInstance.close(this.filterCondition);
  }

  close() {
    this.$modalInstance.close(this.filterCondition);
  }
}

app.controller('HomeDailyReportTimelineFilterController', HomeDailyReportTimelineFilterController);
