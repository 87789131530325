class detailGenomController {
  constructor(
    $q,
    $modal,
    GenomCowAPI,
    SessionCache
  ) {
    'ngInject';

    this.$q = $q;
    this.$modal = $modal;
    this.GenomCowAPI = GenomCowAPI;
    this.showIndexSetting = SessionCache.farm().isBeefGenomLinkage();

    this.init();
  }

  init() {
    this.chartSize = {
      width: 350,
      height: 350,
      chartWidth: 230,
      labelWidth: 280,
    };
  }

  $onChanges() {
    if (this.activeTab !== 'genomTab' || !this.cowId) return;

    this.load();
  }

  load() {
    this.$q.all([
      this.GenomCowAPI.show(this.cowId)
    ]).then((res) => {
      this.evaluations = res[0].data;
      const formated = GeBeefCattle.formatEvaluations(this.evaluations);
      Object.assign(this, formated);
    });
  }

  showGenom() {
    return this.activeTab === 'genomTab';
  }

  openSettingGebvIndexModal() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/genom/setting/gebv-index/index.html',
      controller: 'GenomSettingGebvIndexController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
        }
      }
    }).result.then(() => {
      this.load();
    });
  }
}

function detailGenomComponent() {
  return {
    templateUrl: 'cow/detail/tabs/genom.html',
    controller: detailGenomController,
    controllerAs: 'ctrl',
    bindings: {
      activeTab: '<',
      cowId: '<'
    }
  };
}

app.component('detailGenom', detailGenomComponent());
