class CalvingSensorController {
  constructor(
    $state,
    $modal,
    $rootScope,
    $stateParams,
    SessionCache,
    CalvingSensorAPI,
    DetailsStateFactory
  ) {
    'ngInject';

    this.$state = $state;
    this.$modal = $modal;
    this.$rootScope = $rootScope;
    this.CalvingSensorAPI = CalvingSensorAPI;
    this.DetailsStateFactory = DetailsStateFactory;
    this.eventType = $stateParams.eventType || null;

    const date = $stateParams.date || new Date();
    const farm = SessionCache.farm();

    this.init(date, farm);
  }

  init(date, farm) {
    this.isBeef = farm.isBeef();
    this.heading = `分娩センサー${CalvingSensor.eventTypeLabel(this.eventType)}作業予定`;
    this.buttonLabel = CalvingSensor.bulkButtonLabel(this.eventType);

    this.updateDate(date);
    this.initCallbackParam();
    this.index();
  }

  updateDate(date) {
    this.date = date;
    this.dateDisplay = DateUtil.toYYYYMMDD(date);
  }

  shouldShowPrintButton() {
    return this.cows && this.cows.length > 0;
  }

  shouldShowLatestBullName(cow) {
    return cow.latestBullName;
  }

  shouldShowLatestMasterSpermNo(cow) {
    return !cow.latestBullName && cow.latestMasterSpermNo;
  }

  onPrevDateClick() {
    this.updateDate(DateUtil.previousDay(this.date));
    this.index();
  }

  onNextDateClick() {
    this.updateDate(DateUtil.nextDay(this.date));
    this.index();
  }

  onCowNoClick(cowId) {
    this.updateCallbackParam();
    this.DetailsStateFactory.setDisplayTab('dataTab');
    this.$state.go('cowDetail', {
      cowId: cowId,
      caller: this.callbackParam
    });
  }

  selectRow() {
    this.selectAll = this.cows.every((cow) => {
      return cow.selected;
    });

    this.updateSelectedRows();
    this.updateCallbackParam();
  }

  selectAllRow() {
    this.cows = this.cows.map((cow) => {
      cow.selected = this.selectAll;
      return cow;
    });

    this.updateCallbackParam();
    this.updateSelectedRows();
  }

  initCallbackParam() {
    this.callbackParam = {
      state: 'calving-sensor',
      name: this.heading,
      params: {
        eventType: this.eventType
      },
      cowIds: []
    };
  }

  updateCallbackParam() {
    const cowIds = this.cows.map((cow) => cow.cowId);

    this.callbackParam.params.date = this.date;
    this.callbackParam.cowIds = cowIds;
  }

  updateSelectedRows() {
    if (this.cows.length === 0) return;

    this.selectedCows = this.cows.filter((cow) => cow.selected);
    this.updateCallbackParam();
  }

  index() {
    this.selectAll = false;

    return this.CalvingSensorAPI[`${this.eventType}Candidates`](this.date).then((res) => {
      const data = res.data;

      this.cows = CalvingSensor.addDisplayFields(data);
      this.cowsCount = this.cows.length;

      this.updateSelectedRows();
      this.updateCallbackParam();
    });
  }

  print() {
    this.$rootScope.isPrinting = true;
    setTimeout(() => {
      print();
      this.$rootScope.$apply(() => this.$rootScope.isPrinting = false);
    });
  }

  openEditModal() {
    let titles = [
      '牛番号',
      '分娩センサー番号',
      '適用日'
    ];

    const data = this.selectedCows.map((cow) => {
      return {
        cowId: cow.cowId,
        cowNo: cow.cowNo,
        cowCalvingSensorHistoryId: cow.cowCalvingSensorHistoryId,
        calvingSensorNumber: cow.calvingSensorNumber,
        startDate: cow.cowCalvingSensorStartDate
      };
    });

    if (this.eventType === 'equip') {
      this.$modal.open({
        windowTemplateUrl: 'components/u-modal/window.html',
        templateUrl: 'menu/calving-sensor/modal/equip.html',
        controller: 'CalvingSensorEquipCandidatesModalController',
        controllerAs: 'ctrl',
        backdrop: false,
        resolve: {
          params: () => {
            return {
              heading: this.heading,
              grid: {
                titles: titles,
                data: data
              }
            };
          }
        }
      }).result.then(() => {
        this.index();
      });
    } else {
      titles.push('解除日');

      this.$modal.open({
        windowTemplateUrl: 'components/u-modal/window.html',
        templateUrl: 'menu/calving-sensor/modal/unequip.html',
        controller: 'CalvingSensorUnequipCandidatesModalController',
        controllerAs: 'ctrl',
        backdrop: false,
        resolve: {
          params: () => {
            return {
              heading: this.heading,
              grid: {
                titles: titles,
                data: data
              }
            };
          }
        }
      }).result.then(() => {
        this.index();
      });
    }
  }
}

app.controller('CalvingSensorController', CalvingSensorController);
