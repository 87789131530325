class GroupActivityScatterPlotIndexController {
  constructor(
    $q,
    Dictionary,
    CowGroupService,
    FatteningStageService,
    ChartActivityScatterPlotAPI
  ) {
    'ngInject';
    this.$q = $q;
    this.Dictionary = Dictionary;
    this.FatteningStageService = FatteningStageService;
    this.CowGroupService = CowGroupService;
    this.ChartActivityScatterPlotAPI = ChartActivityScatterPlotAPI;

    const chartOptions = this.chartOptions();
    this.activityScatterPlotChart = new ActivityScatterPlotChart('#activity-scatter-plot-chart', Dictionary, chartOptions);
    this.activityLyingScatterPlotChart = new ActivityLyingScatterPlotChart('#activity-lying-scatter-plot-chart', Dictionary, chartOptions);

    this.initialize($q);
  }

  initialize() {
    this.menus = [
      {id: 'cow-group', label: '牛群', api: 'cowGroup'},
      {id: 'fattening-stage', label: '肥育ステージ', api: 'fatteningStage'}
    ];
    this.menu = this.menus[0];

    this.$q.all([
      this.CowGroupService.summary(),
      this.FatteningStageService.summary()
    ]).then((res) => {
      this.cowGroups = res[0].data.filter((el) => el.menuDisplayed);
      this.fatteningStages = res[1].data;

      if (this.fatteningStages.length) {
        this.showMenu = true;
      } else {
        this.showMenu = false;
      }
    });

    this.plotDate = DateUtil.today();
    this.displayPlotDate();

    this.showChartArea = false;
    this.selectedGroupId = null;
    this.showChart = false;
    this.loading = false;
    this.noData = false;
    this.currentCow = null;
    this.showCowBoard = false;
  }

  onChangeMenu(menu) {
    this.menu = menu;
    this.showChartArea = false;
    this.selectedGroupId = null;
    this.updateCurrentCow(null);
  }

  addClassActive(menu) {
    if (this.menu.id === menu.id) {
      return 'active';
    }
  }

  showCowGroup() {
    return this.menu && this.menu.id === 'cow-group';
  }

  showFatteningStage() {
    return this.menu && this.menu.id === 'fattening-stage';
  }

  onChangeGroupId(id) {
    this.selectedGroupId = id;
    this.fetchPlotData();
    this.updateCurrentCow(null);
  }

  fetchPlotData() {
    this.showChartArea = true;
    this.showChart = false;
    this.loading = true;
    this.noData = false;

    let date = null;
    if (this.plotDate && this.plotDate.getTime() !== DateUtil.today().getTime() ) {
      date = DateUtil.toW3CFormat(this.plotDate);
    }

    const api = this.menus.find((menu) => menu.id === this.menu.id).api;

    this.ChartActivityScatterPlotAPI[api](this.selectedGroupId, date).then((res) => {
      this.scatterPlotData = res.data;

      this.activityScatterPlotChart.clear();
      this.activityLyingScatterPlotChart.clear();

      if (Object.keys(this.scatterPlotData).length) {
        this.activityScatterPlotChart.show(this.scatterPlotData);
        this.activityLyingScatterPlotChart.show(this.scatterPlotData);
        this.showChart = true;
      } else {
        this.noData = true;
      }
    }).catch((err) => console.error(err)
    ).finally(() => {
      this.loading = false;
    });
  }

  onMoveDate(days) {
    this.plotDate = DateUtil.addDays(this.plotDate, days);
    this.displayPlotDate();
    this.fetchPlotData();
  }

  displayPlotDate() {
    this.plotDateCaption = DateUtil.toYYYYMMDD(this.plotDate);
  }

  disableNextDate() {
    return this.plotDate.getTime() >= DateUtil.today().getTime();
  }

  cowBoardState() {
    return this.currentCow ? 'cow-board-opened' : '';
  }

  updateCurrentCow(cowId = null) {
    if (!cowId) {
      this.currentCow = null;
      this.showCowBoard = false;
      return;
    }
    this.currentCow = {cowId: cowId};
    this.showCowBoard = true;
  }

  chartOptions() {
    const options = {};
    options['chart'] = {data: {names: {self: null, normal: null, fertilization: '授精', sick: '疾病'}}};
    options['action'] = this.chartOnClickAction();
    return options;
  }

  chartOnClickAction() {
    const self = this;
    return (cowId) => {
      self.updateCurrentCow(cowId);
    };
  }
}

app.controller('GroupActivityScatterPlotIndexController', GroupActivityScatterPlotIndexController);
