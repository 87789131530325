function jqDatepicker($filter, $window, $timeout, IsTablet, IsMobile, DateTimeUtilFactory) {
  'ngInject';

  /**
   * タブレットの処理
   *
   * @param {Object} elem エレメント
   */
  function forTablet(elem) {
    elem.attr('readonly', true);
    // datepickerのサイズをでかくする。
    angular.element(document.getElementById('ui-datepicker-div')).addClass('large');
  }

  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      dateFormat: '@',
      onSelect: '&',
      minDate: '<',
      maxDate: '<'
    },
    link: function(scope, elem, attrs, ngModelCtrl) {
      let updateModel = function(dateText) {
        scope.$apply(function() {
          ngModelCtrl.$setViewValue(dateText);
          if (scope.onSelect) {
            scope.onSelect();
          }
        });
      };
      let options = {
        yearSuffix: '年',
        showMonthAfterYear: true,
        monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        dateFormat: scope.dateFormat || 'yy/mm/dd',
        showButtonPanel: true,
        currentText: '今日',
        closeText: '閉じる',
        onSelect: function(dateText) {
          updateModel(dateText);
        }
      };

      if (scope.minDate) {
        options.minDate = scope.minDate;
        scope.$watch('minDate', () => {
          $(elem).datepicker('option', 'minDate', scope.minDate);
        });
      }
      if (scope.maxDate) {
        options.maxDate = scope.maxDate;
        scope.$watch('maxDate', () => {
          $(elem).datepicker('option', 'maxDate', scope.maxDate);
        });
      }

      if (IsMobile) {
        const createRipple = () => {
          document.querySelectorAll('[data-event="click"]').forEach((button) => {
            const borderRadius = window.getComputedStyle(button).borderRadius;
            const diameter = Math.max(button.clientWidth, button.clientHeight);
            const radius = diameter / 2;
            const background = '#d7e8fc';
            const color = button.dataset.handler === 'selectDay' ? '#0e52a2' : 'inherit';

            const elmRipple = `
                <div class="ripple" style="clip-path:inset(0px round ${borderRadius})">
                  <div class="ripple__position">
                    <div class="ripple__circle" style="width:${diameter}px; height:${diameter}px;
                     top:-${radius}px; left:-${radius}px; background:${background};" />
                    </div>
                  </div>
                </div>`;

            button.insertAdjacentHTML('beforeend', elmRipple);

            button.addEventListener('click', (event) => {
              if (['prev', 'next'].includes(event.target.dataset.handler)) {
                createRipple();

                return;
              }

              const {offsetX, offsetY} = ['selectDay'].includes(event.target.parentNode.dataset.handler) ?
                {offsetX: radius, offsetY: radius} :
                event;

              button.style.color = color;
              button.querySelector('.ripple__position').style.left = `${offsetX}px`;
              button.querySelector('.ripple__position').style.top = `${offsetY}px`;
              button.querySelector('.ripple__circle').style.transitionProperty = 'all';
              button.querySelector('.ripple__circle').style.transitionDuration = '400ms';
              button.querySelector('.ripple__circle').style.transitionTimingFunction = 'linear';
              button.querySelector('.ripple__circle').style.transform = 'scale(4)';
              button.querySelector('.ripple__circle').style.opacity = 0;

              setTimeout(() => {
                button.querySelector('.ripple__circle').style.transition = null;
                button.querySelector('.ripple__circle').style.transform = null;
                button.querySelector('.ripple__circle').style.opacity = null;
              }, 400);
            });
          });
        };

        options.prev = `<div class="ripple" style="clip-path:inset(0px round 50%)">
                          <div class="ripple__position">
                            <div class="ripple__circle" style="width:40px; height:40px;
                             top:-20px; left:-20px; background:rgba(164, 203, 250, 0.4);" />
                            </div>
                          </div>
                        </div>`;

        options.closeText = '<div class="ui-datepicker-close-icon"></div>閉じる';

        options.onClose = (input, inst) => {
          inst.dpDiv.css({bottom: -500, opacity: 0, visibility: 'hidden', transitionDelay: '300ms'});
        };

        options.beforeShow = (input, inst) => {
          setTimeout(() => {
            createRipple();
          });

          setTimeout(() => {
            inst.dpDiv.css({bottom: 0, opacity: 1, visibility: 'visible', transitionDelay: '0ms'});
          }, 100);
        };

        options.showOtherMonths = true;
        options.selectOtherMonths = true;
        options.monthNames = ['01月', '02月', '03月', '04月', '05月', '06月', '07月', '08月', '09月', '10月', '11月', '12月'];
      }

      $(elem).datepicker(options);
      ngModelCtrl.$parsers.push(function(data) {
        //convert data from view format to model format
        if (!data) return '';
        return DateTimeUtilFactory.parseRegionUnixTime(new Date(data).getTime());
      });

      ngModelCtrl.$formatters.push(function(data) {
        if (!data) return '';
        return $filter('date')(data, 'yyyy/MM/dd');
      });
      // タブレット時
      if (IsTablet || IsMobile) {
        forTablet(elem);
      }

      $.datepicker._gotoToday = (id) => {
        angular.element(id).datepicker('setDate', new Date()).datepicker('hide').blur().change();
      };

      scope.$on('$destroy', function() {
        try {
          elem.datepicker('destroy');
          $.datepicker._curInst = null; //datepickerを開いた時に登録される参照をクリア
        } catch (err) {
          //
        }
      });
    }
  };
}

app.directive('jqdatepicker', jqDatepicker);
