/**
 * 牛個体登録/編集確認のコンポーネント
 *
 * ex.
 *  <cow-confirmation
 *    cow="cow"
 *    is-registered="isRegistered"
 *    on-return-click="onReturnClick()">
 *  </cow-confirmation>
 *
 * プロパティ:
 *  @param {Object} cow 牛個体オブジェクト
 *  @param {boolean} is-registered true: 登録済(編集)、false: 未登録(新規)
 *  @param {Function} on-return-click 戻るクリック時のイベント
 */
class CowConfirmationController {
  constructor(
    $q,
    $state,
    blockUI,
    CowService,
    SessionCache
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.blockUI = blockUI;
    this.CowService = CowService;

    this.canEditFilterItem = !SessionCache.account().isItemFilterApplied();

    this.farm = SessionCache.farm();
  }

  submit() {
    const caller = {
      viewState: 'DETAIL'
    };

    if (this.farm.useName()) {
      this.cow.motherName = this.cow.motherCowNo;
    }

    if (this.isRegistered) {
      this.blockUI.start('更新中');
      delete this.cow['lastModifiedDate'];
      this.CowService.update(this.cow).then((res) => {
        this.blockUI.stop();
        this.$state.go('cowDetail', {cowId: this.cow.cowId, caller});
      }).catch((err) => {
        this.errorProcess(err);
      });
    } else {
      this.blockUI.start('登録中');
      this.CowService.insert(this.cow).then((res) => {
        this.blockUI.stop();
        this.$state.go('cowDetail', {cowId: res.data.cowId});
      }).catch((err) => {
        this.errorProcess(err);
      });
    }
  }

  errorProcess(error) {
    console.error(error);
    this.blockUI.stop();
    this.errorMessage = ErrorUtil.formatErrorMessage(error.data.messages);
  }
}

function cowConfirmationComponent() {
  return {
    templateUrl: 'menu/cow/components/confirmation/index.html',
    controller: CowConfirmationController,
    bindings: {
      cow: '<',
      isRegistered: '<',
      onReturnClick: '&'
    }
  };
}

app.component('cowConfirmation', cowConfirmationComponent());
