// eslint-disable-next-line no-unused-vars
class GroupTransferHistoryGenerator {
  generateColumnDefs(columns) {
    return columns.map((column) => {
      return {
        field: column.columnId,
        name: column.name,
        width: column.width,
        enableSorting: column.enableSorting,
        headerCellTemplate: this.generateHeaderCellTemplate(column),
        headerCellClass: () => {
          return 'ui-grid-sorted-none';
        },
        cellTemplate: this.generateCellTemplate(column)
      };
    });
  }

  generateHeaderCellTemplate(column) {
    if (column.columnId === 'selected') {
      return `
        <div class="contents__uiGridCenter">
          <input ng-model="grid.appScope.model.allSelected" ng-click="grid.appScope.toggleAllSelected()" type="checkbox" class="contents__uiGridCheckbox">
        </div>`;
    } else if (column.enableSorting) {
      return `
        <div
          ng-click="grid.appScope.onClickColumnHeader('${column.columnId}', col)"
          ng-class="grid.appScope.classHeaderCell(col)"
        >
          <div class="customlist-th-inner">
            <span>${column.name}</span>
            <div class="sort-icon"></div>
          </div>
        </div>`;
    } else {
      return `
        <div>
          <div class="customlist-th-inner">
            <span>${column.name}</span>
          </div>
        </div>`;
    }
  }

  generateRowTemplate() {
    return `
      <div ng-click="grid.appScope.toggleCheck($event, row.entity)"
        ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
        ng-class="grid.appScope.classRow(row.entity)"
        class="ui-grid-cell"
        ui-grid-cell>
      </div>
    `;
  }

  generateCellTemplate(column) {
    if (column.columnId === 'selected') {
      return `
        <div class="contents__uiGridCenter">
          <input ng-if="row.entity.editable" ng-model="row.entity.selected" ng-click="grid.appScope.toggleSelected()" type="checkbox" class="contents__uiGridCheckbox">
        </div>`;
    } else if (column.columnId === 'operation') {
      return `
        <div ng-if="row.entity.editable" class="flex-container">
          <p ng-click="grid.appScope.openOperationModal(row.entity.transferInputId, 'edit')"
          class="icon-s-edit contents__icon--size14">
            <span class="uTooltip" data-text="編集"></span>
          </p>
          <p ng-click="grid.appScope.openOperationModal(row.entity.transferInputId, 'delete')"
          class="icon-s-delete contents__icon--size14">
            <span class="uTooltip" data-text="削除"></span>
          </p>
        </div>`;
    }
  }
}
