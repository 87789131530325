// eslint-disable-next-line no-unused-vars
class LactationPerformanceDefaultConfig {
  static init(ctrl, selectChartKey) {
    ctrl.invalidDate = {};
    ctrl.setting = {
      selectChart: selectChartKey
    };

    ctrl.activeChartConfigMap = ctrl.chartConfigMap[ctrl.activeTab.key].find((item) => {
      return ctrl.setting.selectChart === `${item.chart.api}_${item.chart.item}`;
    });

    const dataType = ctrl.isDhiLinkage && !ctrl.hasDhiDates ?
      'milking' :
      ctrl.activeChartConfigMap.defaultSetting.dataType;

    ctrl.setting.dataType = ctrl.dataTypeMap.find((item) => {
      return item.key === dataType;
    });

    if (ctrl.showChartSettingThreshold()) {
      ctrl.setting.threshold = ctrl.activeChartConfigMap.defaultSetting.threshold;
    }

    if (ctrl.showChartSettingSelectReferenceDate()) {
      this.selectReferenceDate(ctrl);
    }

    if (ctrl.showChartSettingInputReferenceDate()) {
      this.inputReferenceDate(ctrl);
    }

    if (ctrl.showChartSettingSelectRangeDate()) {
      this.selectRangeDate(ctrl, false, 'start');
      this.selectRangeDate(ctrl, false, 'end');
    }

    if (ctrl.showChartSettingInputRangeDate()) {
      this.inputRangeDate(ctrl);
    }

    if (ctrl.showChartSettingInterval()) {
      this.interval(ctrl);
    }

    if (ctrl.showChartSettingRangeAxis()) {
      this.rangeAxis(ctrl);
    }
  }

  static selectReferenceDate(ctrl, isChanged) {
    if (!isChanged) {
      if (!ctrl.activeChartConfigMap.defaultSetting.selectReferenceDate) return;

      const defaultSetting = Object.entries(ctrl.activeChartConfigMap.defaultSetting.selectReferenceDate)[0];
      const key = defaultSetting[0];
      const value = defaultSetting[1];

      ctrl.setting.referenceDate = angular.copy(ctrl.findDhiDatesMap(key, value));
    } else {
      const dates = ctrl.filterSelectDhiDatesMapByYear(ctrl.setting.referenceDate.year);

      ctrl.setting.referenceDate.date = Math.min.apply(null, dates);
    }
  }

  static inputReferenceDate(ctrl) {
    if (!ctrl.activeChartConfigMap.defaultSetting.inputReferenceDate) return;

    const defaultSetting = Object.entries(ctrl.activeChartConfigMap.defaultSetting.inputReferenceDate)[0];
    const key = defaultSetting[0];
    const value = defaultSetting[1];

    ctrl.setting.referenceDate = {
      date: this.defaultDate(key, value)
    };

    if (!ctrl.invalidDate.referenceDate) {
      ctrl.invalidDate.referenceDate = {};
    }

    ctrl.invalidDate.referenceDate.date = false;
  }

  static selectRangeDate(ctrl, isChanged, field = 'start') {
    if (!isChanged) {
      if (!ctrl.activeChartConfigMap.defaultSetting.selectRangeDate[field]) return;

      const defaultSetting = Object.entries(ctrl.activeChartConfigMap.defaultSetting.selectRangeDate[field])[0];
      const key = defaultSetting[0];
      const value = defaultSetting[1];

      if (!ctrl.setting.rangeDate) {
        ctrl.setting.rangeDate = {};
      }

      ctrl.setting.rangeDate[field] = angular.copy(ctrl.findDhiDatesMap(key, value));
    } else {
      const dates = ctrl.filterSelectDhiDatesMapByYear(ctrl.setting.rangeDate[field].year);

      ctrl.setting.rangeDate[field].date = Math.min.apply(null, dates);
      ctrl.changeRangeDate(field);
    }
  }

  static inputRangeDate(ctrl) {
    if (!ctrl.activeChartConfigMap.defaultSetting.inputRangeDate) return;

    ctrl.setting.rangeDate = {};

    Object.entries(ctrl.activeChartConfigMap.defaultSetting.inputRangeDate).forEach((item) => {
      const field = item[0];
      const defaultSetting = Object.entries(item[1])[0];
      const key = defaultSetting[0];
      const value = defaultSetting[1];

      if (!ctrl.setting.rangeDate[field]) {
        ctrl.setting.rangeDate[field] = {};
      }

      ctrl.setting.rangeDate[field].date = this.defaultDate(key, value);

      if (!ctrl.invalidDate.rangeDate) {
        ctrl.invalidDate.rangeDate = {};
      }

      ctrl.invalidDate.rangeDate[field] = false;
    });
  }

  static interval(ctrl) {
    ctrl.setting.interval = ctrl.intervalMap.find((item) => {
      return item.key === ctrl.activeChartConfigMap.defaultSetting.interval;
    });
  }

  static rangeAxis(ctrl) {
    ctrl.setting.rangeAxis = {
      x: {},
      y: {},
      prev: {
        x: {},
        y: {}
      }
    };
  }

  static defaultDate(key, value) {
    const today = DateUtil.today();

    switch (key) {
    case 'daysAgo':
      return DateUtil.addDays(today, value * -1);
    case 'monthsAgo':
      return DateUtil.addMonths(today, value * -1);
    }
  }
}
