/**
 * モバイル用の分娩センサー脱落アラートの処置選択コンポーネントです
 *
 * ex.
 *   <mobile-alert-calving-sensor-dropped-work-selection
 *     on-close-click="onCloseClick()"
 *     on-solution-click="onSolutionClick(solution)"
 *   ></mobile-alert-calving-sensor-dropped-work-selection>
 *
 * プロパティ:
 *  @param {Function} on-close-click Xクリック時に発生するイベント
 *  @param {Function} on-solution-click 処置内容クリック時に発生するイベント solutionを引数で渡す
 */
class MobileAlertCalvingSensorDroppedSolutionSelectionController {
  constructor() {
    'ngInject';
  }

  handleCloseClick() {
    this.onCloseClick();
  }

  handleSolutionClick(solution) {
    this.onSolutionClick({solution: solution});
  }
}

function mobileAlertCalvingSensorDroppedSolutionSelectionComponents() {
  return {
    templateUrl: 'mobile/alert-list/calving-sensor-dropped/solution-selection.html',
    controller: MobileAlertCalvingSensorDroppedSolutionSelectionController,
    bindings: {
      onCloseClick: '&',
      onSolutionClick: '&'
    }
  };
}

app.component('mobileAlertCalvingSensorDroppedSolutionSelection',
  mobileAlertCalvingSensorDroppedSolutionSelectionComponents());
