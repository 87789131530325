class mobileCowSelectionModalController {
  constructor(
    $state,
    $modalInstance,
    CowGroupService,
    CowService,
    SessionCache,
    params
  ) {
    'ngInject';

    this.$state = $state;
    this.$modalInstance = $modalInstance;
    this.CowGroupService = CowGroupService;
    this.params = params;
    this.CowService = CowService;
    this.isBeefFarm = SessionCache.farm().data.farmKind === '肉用';
    this.cowId = String(this.params.cows[0].cowId);
    this.cows = [];
    this.selectButtonLabel = params.selectButtonLabel || '詳細画面へ';

    this.groupNameMap = {};
    this.init();
  }

  static get RETURN_STATUS() {
    return {
      OK: 'ok',
      CANCEL: 'cancel'
    };

  }

  init() {
    this.CowGroupService.index().then((result) => {
      this.createGroupNameMap(result.data);
    });
    this.cows = this.params.cows.map((cow) => {
      cow.cowUid = this.CowService.formatCowUid(cow.cowUid);
      cow.introduceDateDisplay = DateUtil.toYYMMDD(Number(cow.introduceDate));
      return cow;
    });
  }

  /**
  * 牛群名のマップ作成
  */
  createGroupNameMap(groups) {
    groups.forEach((group) => {
      this.groupNameMap[group.cowGroupId] = group.cowGroupName || '';
    });
  }

  onCancelClick() {
    this.$modalInstance.close({
      status: mobileCowSelectionModalController.RETURN_STATUS.CANCEL
    });
  }

  onOkClick() {
    this.$modalInstance.close({
      status: mobileCowSelectionModalController.RETURN_STATUS.OK,
      cowId: this.cowId
    });
  }
}

app.controller('mobileCowSelectionModalController', mobileCowSelectionModalController);
