function DysstasiaChart($timeout) {
  'ngInject';

  function drawChart(element, score, alertAt) {
    return c3.generate({
      bindto: element,
      data: {
        json: score,
        keys: {
          x: 'time',
          value: ['score']
        }
      },
      point: {
        show: false,
      },
      axis: {
        x: {
          label: {
            text: '時刻',
            position: 'outer-right',
          },
          type: 'timeseries',
          tick: {
            format: '%H:%M',
            culling: {
              max: 11 // X軸の目盛を1時間刻みにするため
            }
          }
        },
        y: {
          max: 48,
          min: 2,
          label: {
            text: '異常指数',
            position: 'outer-right'
          },
          padding: {
            top: 0,
            bottom: 0
          },
          tick: {
            format: d3.format('d')
          }
        }
      },
      grid: {
        x: {
          lines: [
            {value: alertAt, text: '検知時刻', class: 'chart-grid-line-red'},
          ]
        }
      },
      legend: {
        show: false
      },
      regions: [
        {axis: 'y', start: 25, class: 'dysstasia-gte-25'}
      ],
      size: {
        width: 600,
        height: 200
      },
      tooltip: {
        show: false
      },
      zoom: {
        enabled: true,
      },
    });
  }

  return {
    restrict: 'E',
    scope: {
      score: '=',
      alertAt: '='
    },
    link: (scope, element) => {
      drawChart(element[0], scope.score, scope.alertAt);
    },
  };
}

app.directive('dysstasiaChart', DysstasiaChart);
