// eslint-disable-next-line no-unused-vars
class EventValidator {

  /**
   * 入力された作業内容のバリデーション。
   *
   * @param {Object} event 入力値
   * @param {Array.<Object>} events 作業履歴
   * @param {Object} cow 牛個体情報
   * @param {Object} params 分娩イベント用パラメータ
   * @return {Object} 以下の形式でバリデーション結果を返す。
   *
   * {valid: boolean(false: エラー), messages: Array.<string>(エラーメッセージ)}
   *
   * ex.
   * {valid: true}
   * {valid: false, messages: ['治療日が発生日より前の日付になっています。']}
   */
  validate(event, events, cow, params) {
    const errors = [];
    const eventTypeMap = EventType.convertMap();

    if (!LegacyEventType.isShipmentOrDeadEvent(event.eventType)) {
      const dateErrors = this.validateShipmentAndDeadDate(event, cow);
      if (dateErrors.length > 0) errors.push(...dateErrors);
    }

    // format-check-directiveの代替
    const decimalResult = this.validateDecimal(event);
    if (decimalResult) {
      errors.push(...decimalResult);
    }

    const numberResult = this.validateNumber(event);
    if (numberResult) {
      errors.push(...numberResult);
    }

    const greaterOrEqualZeroResult = this.validateGreaterOrEqualZero(event);
    if (greaterOrEqualZeroResult) {
      errors.push(...greaterOrEqualZeroResult);
    }

    const dateResult = this.validateDate(event);
    if (dateResult) {
      errors.push(...dateResult);
    }

    if (LegacyEventType.isTreatmentEvent(event.eventType)) {
      const occurredDate = DateUtil.startOfDay(Number(event.occurredDiseaseDate)).valueOf();
      const treatmentDiseaseDate = DateUtil.startOfDay(Number(event.treatmentDiseaseDate)).valueOf();

      if (!event.occurredDiseaseDate) {
        errors.push({
          field: 'occurredDiseaseDate',
          message: '正しい発生日を入力してください。'
        });
      }

      if (event.treatmentDiseaseDate) {
        if (!EventType.canEditAfterCalved(eventTypeMap[event.eventType]) &&
          cow.latestCalvingDate && treatmentDiseaseDate < cow.latestCalvingDate) {
          errors.push({
            field: 'treatmentDiseaseDate',
            message: '治療日が最新分娩日より前の日付になっています。'
          });
        } else if (treatmentDiseaseDate < occurredDate) {
          errors.push({
            field: 'treatmentDiseaseDate',
            message: '治療日が発生日より前の日付になっています。'
          });
        }
      }
    } else {
      if (!event.occurredAt) {
        const inputDateCaption = LegacyEventType.inputDateCaption(event.eventType);

        errors.push({
          field: 'occurredAt',
          message: `${inputDateCaption}を入力して下さい。`
        });
      } else if (!LegacyEventType.isCalvedEventTypes(event.eventType) &&
        !EventType.canEditAfterCalved(eventTypeMap[event.eventType]) &&
        cow.latestCalvingDate && event.occurredAt < cow.latestCalvingDate) {
        const inputDateCaption = LegacyEventType.inputDateCaption(event.eventType);
        errors.push({
          field: 'occurredAt',
          message: `${inputDateCaption}が最新分娩日より前の日付になっています。`
        });
      }
    }

    switch (event.eventType) {
    case 'abort': {
      if (event.nextStartMilkingFlg) {
        if (CowEventCollection.existsLaterReproduction(events, event)) {
          errors.push({
            field: 'occurredAt',
            message: '流産日が登録済の繁殖イベントより前の日付になっています。'
          });
        }
      } else {
        const lastCalvingEvent = events.find((e) => {
          if (e.id === event.id) return false;
          if (e.eventType === 'bunben') return true;
          return (e.eventType === 'abort' && e.nextStartMilkingFlg);
        });

        if (lastCalvingEvent && event.occurredAt < lastCalvingEvent.occurredAt) {
          errors.push({
            field: 'occurredAt',
            message: '流産日時が最新分娩日より前の日付になっています。'
          });
        }
      }

      const sameDateEvent = CowEventCollection.findSameDateCalvedOrAborted(
        events, event
      );
      if (sameDateEvent) {
        errors.push({
          field: 'occurredAt',
          message: `流産日が登録済の${sameDateEvent.eventName}と同じ日付になっています。`
        });
      }

      break;
    }

    case 'bunben': {
      if (CowEventCollection.existsLaterReproduction(events, event) && !params.skipLaterReproductionValidation) {
        errors.push({
          field: 'occurredAt',
          message: '分娩日が登録済の繁殖イベントより前の日付になっています。'
        });
      }

      const sameDateEvent = CowEventCollection.findSameDateCalvedOrAborted(
        events, event
      );
      if (sameDateEvent) {
        errors.push({
          field: 'occurredAt',
          message: `分娩日が登録済の${sameDateEvent.eventName}と同じ日付になっています。`
        });
      }

      const calfsIndexes = [0, 1, 2, 3];

      if (!params.useName) {
        const cowNoValidator = params.alphanumericCowNo ?
          new CowNoAlphamericValidator() :
          new CowNoNumericValidator();

        calfsIndexes.forEach((index) => {
          let calf = event.calfs[index];

          if (!calf) return;

          let value = calf.cowNo;
          let errorMessage = `${params.labelCowNo}に${cowNoValidator.errorMessage()}`;

          if (!params.isMobile) {
            errorMessage = `[${index + 1}頭目] ${errorMessage}`;
          }

          if (value && !cowNoValidator.validate(value)) {
            errors.push({
              field: `cowNo${index}`,
              message: errorMessage
            });
          }
        });
      }

      if (params.isValidatorCalfCowUidLength) {
        const REQUIRED_LENGTH = 10;

        calfsIndexes.forEach((index) => {
          let calf = event.calfs[index];

          if (!calf) return;

          let value = calf.cowUid;
          let errorMessage = `個体識別番号は${REQUIRED_LENGTH}桁の半角数値を入力してください`;

          if (!params.isMobile) {
            errorMessage = `[${index + 1}頭目] ${errorMessage}`;
          }

          if (value && String(value).length !== REQUIRED_LENGTH) {
            errors.push({
              field: `cowUid${index}`,
              message: errorMessage
            });
          }
        });
      }

      const fields = {
        cowUid: '個体識別番号',
        weightOnBirth: '体重'
      };

      calfsIndexes.forEach((index) => {
        let calf = event.calfs[index];

        if (!calf) return;

        Object.entries(fields).forEach(([field, label]) => {
          let value = calf[field];
          let errorMessage = `${label}に入力できるのは数値のみです`;

          if (!params.isMobile) {
            errorMessage = `[${index + 1}頭目] ${errorMessage}`;
          }

          if (value && !StringUtil.isDigit(value)) {
            errors.push({
              field: `${field}${index}`,
              message: errorMessage
            });
          }
        });
      });
      break;
    }

    case 'carcass': {
      if (!event.yieldGrade) {
        errors.push({
          field: 'yieldGrade',
          message: ''
        });
      }
      if (!event.meetGrade) {
        errors.push({
          field: 'meetGrade',
          message: ''
        });
      }

      const carcassNumericFields = {
        slaughterNo: 'と畜番号',
        beforeSlaughterWeight: 'と畜前体重',
        dressedCarcassWeightOfL: '枝肉重量(左)',
        dressedCarcassWeightOfR: '枝肉重量(右)',
        loinArea: '胸最長筋面積',
        subcutaneousFat: '皮下脂肪の厚さ',
        ribsThickness: 'ばらの厚さ',
        yieldBaseValue: '歩留基準値'
      };

      Object.entries(carcassNumericFields).forEach(([field, label]) => {
        if (!StringUtil.isNumeric(event[field])) {
          errors.push({
            field: field,
            message: `${label}に入力できるのは数値のみです`
          });
        }
      });
      break;
    }

    case 'gyugunidou': {
      if (!event.moveToCowGroupId) {
        errors.push({
          field: 'moveToCowGroupId',
          message: '移動先牛群を選択して下さい。'
        });
      }
      break;
    }

    case 'hatsujo': {
      if (event.plannedBredAt) {
        const hour = Number(event['plannedBredAtHour']);
        const minute = Number(event['plannedBredAtMinute']);
        const inputPlannedBredAt = DateUtil.addTimeToDate(Number(event.plannedBredAt), hour, minute);

        if (inputPlannedBredAt <= event.occurredAt) {
          errors.push({
            field: 'plannedBredAt',
            message: '種付予定日時は発情日時より後にしてください。'
          });
        }
      }
      break;
    }

    case 'kannyu': {
      if (!event.selectedDryPeriod) {
        errors.push({
          field: 'selectedDryPeriod',
          message: ''
        });
      }
      break;
    }

    case 'ninshinkantei': {
      const isPregnant = event.ninshinkanteiResult === '受胎' || event.ninshinkanteiResult === '双子受胎';
      if (isPregnant) {
        if (!event.targetBreedingDate) {
          errors.push({
            field: 'targetBreedingDate',
            message: ''
          });
          break;
        }

        const daysAfterBreedingDate = DateUtil.countDays(event.targetBreedingDate, event.occurredAt);
        if (daysAfterBreedingDate < 14) {
          errors.push({
            field: 'occurredAt',
            message: `授精後${daysAfterBreedingDate}日です。登録できません。`
          });
        }
      }
      break;
    }

    case 'tanetsuke': {
      if (!event.tanetsukeMethod) {
        errors.push({
          field: 'tanetsukeMethod',
          message: '種付方法を入力して下さい。'
        });
      }
      break;
    }

    case 'embryo_recovery': {
      if (!event.masterSpermId) {
        errors.push({
          field: 'masterSpermId',
          message: '精液番号を選択してください'
        });
      }

      if (event.targetBreedingDate && event.targetBreedingDate >= event.occurredAt) {
        errors.push({
          field: 'targetBreedingDate',
          message: '対象種付日は採卵日より前にしてください。'
        });
      }
    }
    }

    if (errors.length > 0) {
      return {valid: false, errors: errors};
    } else {
      return {valid: true};
    }
  }

  validateDecimal(event) {
    const targets = {
      breedingDifficulty: {
        bodyTemperature: '体温',
      },
      infect: {
        bodyTemperature: '体温',
      },
      injury: {
        bodyTemperature: '体温',
      },
      lame: {
        bodyTemperature: '体温',
      },
      mastitis: {
        bodyTemperature: '体温',
      },
      otherDifficulty: {
        bodyTemperature: '体温',
      },
      perinatalDifficulty: {
        bodyTemperature: '体温',
      },
      observation: {
        bodyTemperature: '体温',
      },
      freshCheck: {
        bcs: 'BCS'
      },
      kannyu: {
        bcs: 'BCS'
      }
    };

    const target = targets[event.eventType] || {};
    return Object.keys(target).reduce((acc, key) => {
      return event[key] && !StringUtil.isDecimal(event[key])
        ? [{
          field: key,
          message: `${target[key]}に入力できるのは少数点を含めた数値のみです`
        }, ...acc]
        : acc;
    }, []);
  }

  validateNumber(event) {
    const targets = {
      measurement: {
        weight: '体重',
      },
      tanetsuke: {
        donorCowUid: '個体識別番号',
      },
      touta: {
        salesPrice: '販売価格',
        shipmentNo: '出荷番号',
      },
    };

    const target = targets[event.eventType] || {};
    return Object.keys(target).reduce((acc, key) => {
      return event[key] && !StringUtil.isDigit(event[key])
        ? [{
          field: key,
          message: `${target[key]}に入力できるのは数値のみです`
        }, ...acc]
        : acc;
    }, []);
  }

  validateGreaterOrEqualZero(event) {
    if (event.eventType === 'embryo_recovery') {
      return event.embryos.filter((embryo) => {
        const {count} = embryo;

        return count && !StringUtil.isDigit(count);
      }).map((embryo) => {
        const {rank} = embryo;

        const label =
          rank === 'degenerated' ? '変性' :
            rank === 'unfertilized' ? '未受精' : rank;

        return {
          field: rank,
          message: `${label}に0以上の数値を入力して下さい。`
        };
      });
    }

    return [];
  }

  validateDate(event) {
    const targets = [
      'occurredAt',
      'occurredDiseaseDate',
      'treatmentDiseaseDate',
      'targetBreedingDate',
      'plannedBredAt',
    ];

    return targets.reduce((acc, key) => {
      if (!event[key]) return acc;

      if (!Number(event[key])) {
        return [{
          field: key,
          message: `正しい日付を入力して下さい。`
        }, ...acc];
      }

      const unixtime = Number(event[key]);

      const d = new Date(unixtime);
      if (d && !DateUtil.isValidDate(d)) {
        return [{
          field: key,
          message: `正しい日付を入力して下さい。`
        }, ...acc];
      }

      if (!DateUtil.includedPermittedPeriod(unixtime)) {
        return [{
          field: key,
          message: '日付が入力可能な範囲ではありません。'
        }, ...acc];
      }

      return acc;
    }, []);
  }

  validateShipmentAndDeadDate(event, cow) {
    const errors = [];
    const fallingDeadDate = Number(cow.fallingDeadDate) || null;
    const eliminateDate = Number(cow.eliminateDate) || null;

    if (LegacyEventType.isTreatmentEvent(event.eventType)) {
      // 発生日
      if (fallingDeadDate && event.occurredDiseaseDate > fallingDeadDate) {
        errors.push({
          field: 'occurredDiseaseDate',
          message: `発生日がへい死日より後の日付になっています。`
        });
      }

      if (eliminateDate && event.occurredDiseaseDate > eliminateDate) {
        errors.push({
          field: 'occurredDiseaseDate',
          message: `発生日が出荷日より後の日付になっています。`
        });
      }

      // 治療日
      if (fallingDeadDate && event.treatmentDiseaseDate > fallingDeadDate) {
        errors.push({
          field: 'treatmentDiseaseDate',
          message: `治療日がへい死日より後の日付になっています。`
        });
      }

      if (eliminateDate && event.treatmentDiseaseDate > eliminateDate) {
        errors.push({
          field: 'treatmentDiseaseDate',
          message: `治療日が出荷日より後の日付になっています。`
        });
      }
    } else {
      // 発生日
      if (fallingDeadDate && event.occurredAt > fallingDeadDate) {
        const inputDateCaption = LegacyEventType.inputDateCaption(event.eventType);

        errors.push({
          field: 'occurredAt',
          message: `${inputDateCaption}がへい死日より後の日付になっています。`
        });
      }

      if (eliminateDate && event.occurredAt > eliminateDate) {
        const inputDateCaption = LegacyEventType.inputDateCaption(event.eventType);
        errors.push({
          field: 'occurredAt',
          message: `${inputDateCaption}が出荷日より後の日付になっています。`
        });
      }
    }

    return errors;
  }

}
