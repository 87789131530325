// eslint-disable-next-line no-unused-vars
class ChartConvertor {

  /**
   * ある期間の活動量をC3で描画するためのJSONデータに変換します。
   *
   * @param {Array.{Date}} dates グラフのX軸になる日付(日時)
   * @param {Array.{Object}} activities グラフのY軸になる期間の活動量
   * @param {string} keyColumn activitiesの日付(日時)を格納するカラム名(dateなど)
   * @param {Boolean} useCalfManagement 子牛管理機能の利用フラグ
   * @param {Number} birthday 出生日
   * @return {Object} C3のグラフを描画するためのJSONデータ
   */
  static activityToC3(dates, activities, keyColumn, useCalfManagement, birthday) {
    if (activities.length === 0) {
      return {};
    }

    const columns = ['feedMin', 'moveMin', 'ruminationStandingMin', 'ruminationLyingMin', 'standMin', 'lieMin'];
    const result = {x: []};
    columns.forEach((column) => {
      result[column] = [];
    });

    // 後続の日付をキーとしたマッチングのための変換処理です
    const dateKeyValues = activities.reduce((o, activity) => {
      const showCalfPeriodChart = useCalfManagement && Cow.isCalfPeriod(birthday, activity[keyColumn]);
      const values = {};

      columns.forEach((column) => {
        values[column] = activity[column] || 0;
      });

      if (showCalfPeriodChart) {
        values.moveMin = values.feedMin + values.moveMin;
        values.feedMin = 0;
      }

      const unixtime = activity[keyColumn];
      o[unixtime] = values;
      return o;
    }, {});

    dates.forEach((unixtime) => {
      const date = DateUtil.toDate(Number(unixtime));
      result.x.push(date);

      const values = dateKeyValues[unixtime] || {};
      columns.forEach((column) => {
        const value = values[column] || 0;
        result[column].push(value);
      });
    });

    return result;
  }

  /**
   * ある期間の発情指数をC3で描画するためのJSONデータに変換します。
   *
   * @param {Array.{Date}} dates グラフのX軸になる日付(日時)
   * @param {Array.{Object}} indexes グラフのY軸になる期間のは発情指数
   * @param {string} keyColumn indexesの日付(日時)を格納するカラム名(dateなど)
   * @return {Object} C3のグラフを描画するためのJSONデータ
   */
  static heatIndexToC3(dates, indexes, keyColumn) {
    if (indexes.length === 0) {
      return {};
    }

    const result = {
      x: ['x'],
      y: ['発情指数']
    };

    // 後続の日付をキーとしたマッチングのための変換処理です
    const dateKeyValues = indexes.reduce((o, index) => {
      const unixtime = index[keyColumn];
      o[unixtime] = index.heatValue || 0;
      return o;
    }, {});

    dates.forEach((unixtime) => {
      const date = DateUtil.toDate(Number(unixtime));
      result.x.push(date);

      const value = dateKeyValues[unixtime] === undefined
        ? null
        : dateKeyValues[unixtime] || 0;
      result.y.push(value);
    });

    return result;
  }

  /**
   * ある期間の搾乳量をC3で描画するためのJSONデータに変換します。
   *
   * @param {Array.{Date}} dates グラフのX軸になる日付(日時)
   * @param {Array.{Object}} records グラフのY軸になる期間の搾乳量
   * @param {string} keyColumn recordの日付(日時)を格納するカラム名(dateなど)
   * @return {Object} C3のグラフを描画するためのJSONデータ
   */
  static milkingToC3(dates, records, keyColumn) {
    if (records.length === 0) {
      return {};
    }

    const maxOrder = Math.max(...records.map((r) => r.milkingOrder));
    const columns = [];
    for (let i = 1; i <= maxOrder; i++) columns.push(i);

    // 後続の日付をキーとしたマッチングのための変換処理です
    const dateKeyValues = records.reduce((obj, r) => {
      const unixtime = r[keyColumn];
      if (!obj[unixtime]) obj[unixtime] = {};
      obj[unixtime][r.milkingOrder] = r.amount;
      return obj;
    }, {});

    const result = {x: []};
    columns.forEach((column) => {
      result[column] = [];
    });
    dates.forEach((unixtime) => {
      const date = DateUtil.toDate(Number(unixtime));
      result.x.push(date);

      const values = dateKeyValues[unixtime] || {};
      columns.forEach((column) => {
        const value = values[column] || 0;
        result[column].push(value);
      });
    });

    return result;
  }

  /**
   * 指定した期間に含まれる日付(日時)をキーとした配列を生成する。
   * 基準日の(days - 1)日前の日付から基準日の翌日までの期間を対象とします。
   *
   * @param {Date} baseDate 基準日
   * @param {number} days 対象期間の日数
   * @param {number} hourInterval 1以上を指定するとその時間数刻みでキーを生成する
   * @return {Object} 日付(日時)をキーとした値がゼロの二次元表を表現するオブジェクト
   *
   * ex. baseDate = 2019-01-04、days = 3
   * {
   *   startDate: 2019-01-02,
   *   endDate: 2019-01-05,
   *   dates: [
   *     1546354800000, // 2019-01-02
   *     1546441200000, // 2019-01-03
   *     1546527600000, // 2019-01-04
   *     1546614000000  // 2019-01-05
   *   ]
   * }
   */
  static generateXAxisDates(baseDate, days, hourInterval = 0) {
    const date = DateUtil.startOfDay(baseDate);
    const gap = days - 1;

    const startDate = DateUtil.addDays(date, -gap);
    const endDate = DateUtil.addDays(date, 1);
    const result = {startDate: startDate, endDate: endDate, dates: []};

    const additionalHours = [];
    const x = Math.floor(hourInterval);
    if (x > 0 && x < 24) {
      const n = Math.ceil(24 / x);
      for (let i = 1; i < n; i++) {
        const hour = x * i;
        additionalHours.push(hour);
      }
    }

    for (let i = 0; i <= days; i++) {
      const date = DateUtil.addDays(startDate, i);
      result.dates.push(date.getTime());

      if (i < days) {
        additionalHours.forEach((hour) => {
          const additionalDate = DateUtil.addHours(date, hour);
          result.dates.push(additionalDate.getTime());
        });
      }
    }

    return result;
  }
}
