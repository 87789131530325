app.constant('MilkCountAttributes', {
  all: [
    {key: 'breeding', label: '未経産頭数'},
    {key: 'delivered', label: '経産頭数'},
    {key: 'all', label: '合計'}
  ],
  milkingAndDry: [
    {key: 'milking', label: '搾乳頭数'},
    {key: 'dry', label: '乾乳頭数'},
    {key: 'milkingAndDry', label: '合計'}
  ]
});
