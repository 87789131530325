/**
 * モバイル機種であるかどうか判断する
 * TEMP: こういう処理はcss @mediaQueryでやった方が早い
 * TODO: MOVE TO CSS @mediaqueries
 */
app.factory('IsMobile', IsMobileFactory);

function IsMobileFactory($window) {
  'ngInject';
  const ua = $window.navigator.userAgent;
  // T/Fを返す
  return (ua.indexOf('iPhone') >= 0 || ua.indexOf('iPod') >= 0 || (ua.indexOf('Android') >= 0 && ua.indexOf('Mobile') >= 0));
}
