function NgCowNoValidator() {
  'ngInject';
  return {
    require: 'ngModel',
    link: (scope, element, attrs, model) => {
      const numericValidator = new CowNoNumericValidator();
      const alphamericValidator = new CowNoAlphamericValidator();

      // directiveのロード時に牧場設定が読み込まれていることが保証されていないので残念なことになっています
      model.$validators.number = (modelValue, viewValue) => {
        if (scope.alphanumericCowNo || scope.farm.useName()) return true;
        return numericValidator.validate(viewValue);
      };
      model.$validators.alphaNum = (modelValue, viewValue) => {
        if (!scope.alphanumericCowNo || scope.farm.useName()) return true;
        return alphamericValidator.validate(viewValue);
      };
    }
  };
}

class CowNoNumericValidator {
  static get REGEX() {
    return /^[0-9]+$/;
  }

  validate(cowNo) {
    if (!cowNo) return true;
    return CowNoNumericValidator.REGEX.test(cowNo);
  }

  errorMessage() {
    return '入力できるのは半角数値のみです';
  }
}

class CowNoAlphamericValidator {
  static get REGEX() {
    return /^[a-zA-Z0-9]+$/;
  }

  validate(cowNo) {
    if (!cowNo) return true;
    return CowNoAlphamericValidator.REGEX.test(cowNo);
  }

  errorMessage() {
    return '入力できるのは英数字のみです';
  }
}

app.directive('ngCowNoValidator', NgCowNoValidator);
