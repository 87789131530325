class TimelineContext {
  constructor(
    $q,
    FarmService,
    SelectionService,
    CowEventsService,
    CowService,
    CowGroupService,
    MasterHormoneProgramAPI,
    MasterMedicineAPI,
    AlertService,
    MasterSpermAPI,
    MasterEmbryoAPI,
    MasterDiseaseAPI,
    ReproductionConfigService,
    UserDefinedSelectionAPI,
    PregnantDiagnosisTimingAPI,
    OperationUserService,
    AccountService,
    AlertConfigService,
    AlertAPI,
    MasterWorkNoteTypeAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.FarmService = FarmService;
    this.SelectionService = SelectionService;
    this.CowEventsService = CowEventsService;
    this.CowService = CowService;
    this.CowGroupService = CowGroupService;
    this.MasterHormoneProgramAPI = MasterHormoneProgramAPI;
    this.MasterMedicineAPI = MasterMedicineAPI;
    this.AlertService = AlertService;
    this.MasterSpermAPI = MasterSpermAPI;
    this.MasterEmbryoAPI = MasterEmbryoAPI;
    this.MasterDiseaseAPI = MasterDiseaseAPI;
    this.ReproductionConfigService = ReproductionConfigService;
    this.UserDefinedSelectionAPI = UserDefinedSelectionAPI;
    this.PregnantDiagnosisTimingAPI = PregnantDiagnosisTimingAPI;
    this.OperationUserService = OperationUserService;
    this.AccountService = AccountService;
    this.AlertConfigService = AlertConfigService;
    this.AlertAPI = AlertAPI;
    this.MasterWorkNoteTypeAPI = MasterWorkNoteTypeAPI;
  }

  load(cowId) {
    return this.$q.all([
      this.FarmService.show(),
      this.CowGroupService.index(),
      this.CowService.getBulls(),
      this.MasterMedicineAPI.available(),
      this.SelectionService.index(),
      this.MasterHormoneProgramAPI.available(),
      this.CowEventsService.index(cowId),
      this.AlertService.alertsByCow(cowId),
      this.MasterSpermAPI.available(),
      this.MasterDiseaseAPI.available(),
      this.ReproductionConfigService.show(),
      this.UserDefinedSelectionAPI.available(),
      this.PregnantDiagnosisTimingAPI.show(),
      this.OperationUserService.available(),
      this.AccountService.cachedAccount(),
      this.AlertConfigService.show(),
      this.AlertAPI.dysstasiaAlertsByCow(cowId),
      this.MasterWorkNoteTypeAPI.available(),
    ]);
  }

  cowEventsService() {
    return this.CowEventsService;
  }

  cowService() {
    return this.CowService;
  }

  masterEmbryoAPI() {
    return this.MasterEmbryoAPI;
  }

  masterDiseaseAPI() {
    return this.MasterDiseaseAPI;
  }
}

app.service('TimelineContext', TimelineContext);
