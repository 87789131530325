function MastitisDiagnosisResults() {
  'ngInject';
  return {
    restrict: 'E',
    template: '<p ng-bind-html="contentHTML"></p>',
    link: (scope, element, attrs) => {
      scope.$watch('r', () => {
        let count = 0;
        let contentHTML = '';
        if (scope.r.mastitisDiagnosisResultOfBreastFl) {
          contentHTML += `<span>[左前]&nbsp;${scope.r.mastitisDiagnosisResultOfBreastFl}&nbsp;</span>`;
          count++;
        }
        if (scope.r.mastitisDiagnosisResultOfBreastFr) {
          contentHTML += `<span>[右前]&nbsp;${scope.r.mastitisDiagnosisResultOfBreastFr}&nbsp;</span>`;
          count++;
          if (count === 2) contentHTML += '<br>';
        }
        if (scope.r.mastitisDiagnosisResultOfBreastBl) {
          contentHTML += `<span>[左後]&nbsp;${scope.r.mastitisDiagnosisResultOfBreastBl}&nbsp;</span>`;
          count++;
          if (count === 2) contentHTML += '<br>';
        }
        if (scope.r.mastitisDiagnosisResultOfBreastBr) {
          contentHTML += `<span>[右後]&nbsp;${scope.r.mastitisDiagnosisResultOfBreastBr}&nbsp;</span>`;
        }
        scope.contentHTML = contentHTML;
      });
    }
  };
}

app.directive('mastitisDiagnosisResults', MastitisDiagnosisResults);
