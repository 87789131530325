// eslint-disable-next-line no-unused-vars
class TopReproductionPlannedEventsBinder {
  static bind(ctrl, base) {
    angular.extend(ctrl, base);

    const functions = [
      '$onChanges',
      'index'
    ];

    functions.forEach((f) => {
      ctrl[f] = base[f].bind(ctrl);
    });
  }
}
