/* eslint-disable */
class GeBeefCattle {
  constructor(data) {
    this.data = angular.extend({}, data);
  }

  static get CLASS_ITEMS() {
    return [
      'carcass_weight_class',
      'rib_eye_area_class',
      'ribs_thickness_class',
      'subcutaneous_fat_thickness_class',
      'yield_standard_value_class',
      'bms_class',
      'carcassWeightClass',
      'ribEyeAreaClass',
      'ribsThicknessClass',
      'subcutaneousFatThicknessClass',
      'yieldStandardValueClass',
      'bmsClass',
    ];
  }

  static formatEvaluations(evaluations) {
    const formated = {};
    if (!evaluations) return formated;

    Object.entries(evaluations).forEach(([key, value]) => {
         formated[key] = GeBeefCattle.formatValue(key, value);
    });

    return formated;
  }

  static formatValue(key, value) {
    if (key === 'evaluationDate') {
      return DateUtil.toYYYYMMDD(value);
    } else if (GeBeefCattle.isClassItem(key)) {
      return GeBeefCattle.classNotation(value);
    } else {
      return GeBeefCattle.formatBv(value);
    }
  }

  static classNotation(classValue) {
    const CLASSE_NOTATIONS = { 5: 'H', 4: 'A', 3: 'B', 2: 'C', 1: 'D' };
    return CLASSE_NOTATIONS[classValue];
  }

  static formatBv(breedingValue) {
    if ([undefined, null, ''].includes(breedingValue)) return '';

    const value = parseFloat(breedingValue);
    return NumberUtil.format(value, 3);
  }

  static isClassItem(itemName) {
    if (!itemName) return false;
    return GeBeefCattle.CLASS_ITEMS.includes(itemName);
  }

  static classItemSelectOptions() {
    return [
      {value: 5, label: 'H'},
      {value: 4, label: 'A'},
      {value: 3, label: 'B'},
      {value: 2, label: 'C'},
      {value: 1, label: 'D'},
    ];
  }
}
