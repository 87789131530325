class MobileStandardModalController {
  constructor(
    $modalInstance,
    message,
    option
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.message = message;
    this.option = option;

    this.cancelLabel = this.option.cencelLabel || 'キャンセル';
    this.okLabel = this.option.okLabel || 'OK';
  }

  onCancelClick() {
    this.$modalInstance.close();
  }

  onOkClick() {
    this.$modalInstance.close(true);
  }
}

app.controller('MobileStandardModalController', MobileStandardModalController);
