// eslint-disable-next-line no-unused-vars
class FileImport {
  static get standardFileTypes() {
    return [
      {key: 'cow', label: '牛個体', farmType: 'ALL'},
      {key: 'cow_event_advanced', label: 'イベント (牛個体に反映)', farmType: 'ALL'},
      {key: 'cow_event_carcass', label: '枝肉成績', farmType: 'BEEF'},
      {key: 'cow_ble_id', label: 'センサー番号の適用', farmType: 'ALL'},
      {key: 'cow_ble_id_unequip', label: 'センサー番号の解除', farmType: 'ALL'},
      {key: 'milking_amount', label: '搾乳量', farmType: 'MILK'},
      {key: 'master_medicine', label: '薬品マスタ', farmType: 'ALL'},
      {key: 'cow_event', label: 'イベント (旧機能、非推奨)', farmType: 'ALL'},
    ];
  }

  static get depositorFileTypes() {
    return [
      {key: 'deposited_cow', label: '預託牛', farmType: 'ALL'},
      {key: 'deposited_cow_event_carcass', label: '預託牛の枝肉成績', farmType: 'BEEF'},
      {key: 'deposited_cow_ble_id', label: '預託牛のセンサー番号の適用', farmType: 'ALL'},
      {key: 'deposited_cow_ble_id_unequip', label: '預託牛のセンサー番号の解除', farmType: 'ALL'},
      {key: 'master_medicine', label: '薬品マスタ', farmType: 'ALL'},
    ];
  }

  static get agentFileTypes() {
    return [
      {key: 'cow_event_advanced', label: 'イベント (牛個体に反映)', farmType: 'ALL'},
      {key: 'cow_ble_id', label: 'センサー番号の適用', farmType: 'ALL'},
      {key: 'cow_ble_id_unequip', label: 'センサー番号の解除', farmType: 'ALL'},
    ];
  }

  static get hiddenFileTypes() {
    return [
      {key: 'cow_cancel', label: 'データ削除(牛個体)', farmType: 'ALL', hidden: true},
      {key: 'cow_event_cancel', label: 'データ削除(イベント)', farmType: 'ALL', hidden: true},
      {key: 'cow_event_legacy_cancel', label: 'データ削除(イベント)', farmType: 'ALL', hidden: true},
      {key: 'master_medicine_cancel', label: 'データ削除(薬品マスタ)', farmType: 'ALL', hidden: true},
      {key: 'deposited_cow_cancel', label: 'データ削除(預託牛)', farmType: 'ALL', hidden: true},
    ];
  }

  static fileTypes(farm, isAdmin) {
    let fileTypes;
    if (farm.isDepositor()) {
      fileTypes = FileImport.depositorFileTypes;
    } else if (farm.isAgent()) {
      fileTypes = FileImport.agentFileTypes;
    } else {
      fileTypes = FileImport.standardFileTypes;
    }

    fileTypes = fileTypes.concat(FileImport.hiddenFileTypes);

    return fileTypes
      .filter((f) => {
        return f.farmType === 'ALL' || f.farmType === farm.farmType();
      })
      .filter((f) => {
        return !f.adminOnly || f.adminOnly && isAdmin;
      });
  }

  static selectableFileTypes(farm, isAdmin) {
    return FileImport.fileTypes(farm, isAdmin).filter((f) => !f.hidden);
  }

  static sampleFileNames(farm) {
    const filenames = {
      cow_ble_id: 'センサー番号の適用_サンプル.xlsx',
      cow_ble_id_unequip: 'センサー番号の解除_サンプル.xlsx',
      milking_amount: '搾乳量_サンプル.xlsx',
      master_medicine: '薬品マスタ_サンプル.xlsx',
    };

    if (farm.isBeef()) {
      filenames['cow'] = '牛個体_肥育_サンプル.xlsx';
    } else {
      filenames['cow'] = '牛個体_酪農_サンプル.xlsx';
    }

    return filenames;
  }

  static canCancel(canceled, fileType) {
    if (canceled) {
      return false;
    }

    const isFileTypeBleId = ['cow_ble_id', 'deposited_cow_ble_id'].some((el) => el === fileType);
    return !isFileTypeBleId;
  }
}
