class GenomSettingGebvIndexController {
  constructor(
    $q,
    $timeout,
    $modal,
    $modalInstance,
    params,
    blockUI,
    GebvIndexSettingAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.$timeout = $timeout;
    this.$modal = $modal;
    this.$modalInstance = $modalInstance;
    this.blockUI = blockUI;
    this.GebvIndexSettingAPI = GebvIndexSettingAPI;
    this.helpTemplateUrl = params ? params.helpTemplateUrl : 'menu/genom/setting/gebv-index/help.html';

    this.init();
  }

  init() {
    const evaluationType = 'beef_cattle';

    this.setting = {weights: []};
    this.clearErrorMessage();

    this.$q.all([
      this.GebvIndexSettingAPI.show(evaluationType)
    ]).then((res) => {
      this.setting = new GebvIndexSetting(res[0].data);
    });

    const weightOptions = [...Array(GebvIndexSetting.MAX_WEIGHT)].map((_, i) => {
      ++i;

      return {
        value: i,
        label: String(i)
      };
    });

    this.selectionItems = {
      traitName: [
        {
          value: 'carcass_weight',
          label: '枝肉重量'
        }, {
          value: 'rib_eye_area',
          label: 'ロース芯面積'
        }, {
          value: 'ribs_thickness',
          label: 'バラの厚さ'
        }, {
          value: 'subcutaneous_fat_thickness',
          label: '皮下脂肪厚'
        }, {
          value: 'yield_standard_value',
          label: '歩留基準値'
        }, {
          value: 'bms',
          label: 'BMS No.'
        }, {
          value: 'mufa_ratio',
          label: 'MUFA割合'
        }, {
          value: 'oleic_acid_ratio',
          label: 'オレイン酸割合'
        }, {
          value: 'carcass_weight_pda',
          label: '日齢枝肉重量'
        }],
      weightItems: weightOptions
    };
  }

  onAddWeighting() {
    this.setting.weights.push({
      trait: '',
      weight: ''
    });
  }

  onDeleteWeighting(index) {
    this.setting.weights.splice(index, 1);
    this.clearErrorMessage();
  }

  showAddWeighting() {
    return this.setting.weights.length < 6;
  }

  showDeleteWeighting() {
    return this.setting.weights.length > 1;
  }

  onTraitChange(index) {
    this.clearErrorMessage();

    const target = this.setting.weights[index];
    this.setting.weights.forEach((w, idx) => {
      if (index === idx) return;
      if (target.trait === w.trait) {
        this.putError(index, '既に選択済みの形質です', '');
        return;
      }
    });
  }

  save() {
    this.setting.weights = this.setting.weights.filter((w) => (w.trait !== '' || w.weight !== ''));

    if (this.checkInputError()) return;

    this.blockUI.start('更新中');

    this.GebvIndexSettingAPI.update(this.setting.data.id, this.setting.data)
      .then(() => {
        this.blockUI.done(() => {
          this.blockUI.start('更新が完了しました');
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close();
      }, (res) => {
        this.blockUI.stop();
        this.errorMessage = ErrorUtil.formatErrorMessage(res.data.messages);
      });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  openSettingGebvIndexHelpModal() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: this.helpTemplateUrl,
      controller: 'GenomSettingGebvIndexHelpController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
        }
      }
    }).result.then((result) => {
      this.setting.weights = result;
    });
  }

  checkInputError() {
    let error = false;
    this.clearErrorMessage();

    this.setting.weights.forEach((w, index) => {
      if (w.trait !== '' && w.weight === '') {
        this.putError(index, '', '入力して下さい');
        error = true;
      }

      if (w.trait === '' && w.weight !== '') {
        this.putError(index, '入力して下さい', '');
        error = true;
      }

      this.setting.weights.forEach((other, idx) => {
        if (index === idx) return;
        if (other.trait === w.trait) {
          this.putError(index, '重複しています', '');
          error = true;
        }
      });
    });

    return error;
  }

  putError(index, traitErrorMessage, weightErrorMessage) {
    this.errorMessage['weights'] = this.errorMessage['weights'] || {};
    this.errorMessage['weights'][index] = this.errorMessage['weights'][index] || {};

    if (traitErrorMessage) {
      this.errorMessage['weights'][index]['trait'] = traitErrorMessage;
    }

    if (weightErrorMessage) {
      this.errorMessage['weights'][index]['weight'] = weightErrorMessage;
    }
  }

  clearErrorMessage() {
    this.errorMessage = {};
  }
}

app.controller('GenomSettingGebvIndexController', GenomSettingGebvIndexController);
