class CowBoardModalController {
  constructor(
    $timeout,
    $modalInstance,
    params
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$modalInstance = $modalInstance;

    this.init(params);
  }

  init(params) {
    this.currentCowId = params.cowId;
    this.isDepositor = params.isDepositor;
    this.currentQwert = params.qwert;

    const dismissButton = document.querySelector('.cow-board-modal-dismiss');

    if (dismissButton) {
      this.$timeout(() => {
        dismissButton.click();
      });
    }
  }

  dismiss() {
    this.$modalInstance.dismiss('dismiss');
  }

  close() {
    this.$modalInstance.close();
  }
}

app.controller('CowBoardModalController', CowBoardModalController);
