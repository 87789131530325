class detailReproductionController {
  constructor(
    $q,
    $state,
    $modal,
    $window,
    $timeout,
    CowGroupAPI,
    SessionCache,
    ReproductionAPI,
    SelectionService,
    CowEventsService,
    ReproductionConfigService
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$modal = $modal;
    this.$window = $window;
    this.$timeout = $timeout;
    this.CowGroupAPI = CowGroupAPI;
    this.ReproductionAPI = ReproductionAPI;
    this.SelectionService = SelectionService;
    this.CowEventsService = CowEventsService;
    this.ReproductionConfigService = ReproductionConfigService;

    const farm = SessionCache.farm();
    const account = SessionCache.account();

    this.init(farm, account);
  }

  $onChanges() {
    if (this.activeTab !== 'reproductionTab' || !this.cowDetail) return;

    this.openGrid = false;
    this.removeEventListeners();

    this.$q.all([
      this.CowGroupAPI.index(),
      this.SelectionService.index(),
      this.ReproductionAPI.cow(this.cowDetail.cowId),
      this.ReproductionConfigService.show()
    ]).then((res) => {
      this.cowGroup = res[0].data;
      this.selection = res[1];
      this.embryoRecoveryRank = res[3].data.embryoRecoveryRank;
      this.generateReproductionList(res[2].data);
      this.generateReproductionHistory();
      this.setMaxExcludeHiddenFertilizationCount();
      this.styleReproductionHistory();
      this.generateTableData();
      this.activeTableData = this.reproductionList[0].birthNumber ? 1 : 2;
      this.initScrollbar();
      this.mouseupBind = this.mouseup.bind(this);
      this.mousemoveBind = this.mousemove.bind(this);
      this.scrollbarPositionBind = this.scrollbarPosition.bind(this);
      this.initScrollbarBind = this.initScrollbar.bind(this);
      this.$window.addEventListener('mouseup', this.mouseupBind);
      this.$window.addEventListener('mousemove', this.mousemoveBind);
      this.$window.addEventListener('scroll', this.scrollbarPositionBind);
      this.$window.addEventListener('resize', this.initScrollbarBind);
    });
  }

  $onDestroy() {
    this.removeEventListeners();
  }

  init(farm, account) {
    this.isDairy = farm.isDairy();
    this.isCalfManagement = ['always', 'calved'].includes(farm.data.calfManagement);
    this.isItemFilterApplied = account.isItemFilterApplied();
  }

  removeEventListeners() {
    this.$window.removeEventListener('mouseup', this.mouseupBind);
    this.$window.removeEventListener('mousemove', this.mousemoveBind);
    this.$window.removeEventListener('scroll', this.scrollbarPositionBind);
    this.$window.removeEventListener('resize', this.initScrollbarBind);
    if (this.scrollbarParams && this.scrollbarParams.elm && this.scrollbarParams.elm.outher) {
      this.scrollbarParams.elm.outher.removeEventListener('scroll', this.onScrollbarOutherScroll);
    }
    if (this.scrollbarParams && this.scrollbarParams.elm && this.scrollbarParams.elm.scrollbarBody) {
      this.scrollbarParams.elm.scrollbarBody.removeEventListener('mousedown', this.onScrollbarBodyMousedown);
    }
  }

  generateReproductionList(res) {
    this.reproductionList = res.map((item, index, arr) => {
      if (!index) {
        item.startFatteningDate = Number(this.cowDetail.startFatteningDate) || null; // 肥育開始日
        item.eliminateDate = Number(this.cowDetail.eliminateDate) || null; // 出荷日
        item.fallingDeadDate = Number(this.cowDetail.fallingDeadDate) || null; // へい死日

        if (!item.startFatteningDate && !item.eliminateDate && !item.fallingDeadDate) {
          item.expectedCalvingDate = Number(this.cowDetail.expectedCalvingDate) || null; // 分娩予定日
          item.expectedDryDate = this.isDairy && !['乾乳前期', '乾乳後期'].includes(this.cowDetail.state) ?
            (Number(this.cowDetail.expectedDryDate) || null) : null; // 乾乳予定日
        }

        // フレッシュ
        item.fresh = this.cowDetail.state === 'フレッシュ' &&
          item.cowEvents.every((event) => {
            return event.eventType !== 'tanetsuke';
          });

        // 未実施
        item.unfertilized = ['未授精', '未受胎(－)', '肥育', '繁殖除外', '出荷', 'へい死'].includes(this.cowDetail.state) &&
          item.cowEvents.every((event) => {
            return event.eventType !== 'tanetsuke';
          });

        // 繁殖除外イベント
        item.doNotBreedEvent = item.cowEvents.find((event) => {
          return event.eventType === 'doNotBreed' ||
            (event.eventType === 'abort' && event.afterCowState === '繁殖除外') ||
            (event.eventType === 'embryo_recovery' && event.nextCowState === 'do_not_breed');
        }) || null;

        // 繁殖除外
        if (item.doNotBreedEvent) {
          item.doNotBreed = item.latestFertilizationDate ?
            item.latestFertilizationDate <= item.doNotBreedEvent.occurredAt : true;
        } else {
          item.doNotBreed = false;
        }

        item.fattening = this.cowDetail.state === '肥育'; // 肥育
        item.eliminate = this.cowDetail.state === '出荷'; // 出荷
        item.fallingDead = this.cowDetail.state === 'へい死'; // へい死
      }

      // 産次開始日時が出生日または1900/1/1の場合は計算対象外のためnullにする
      if (index === arr.length - 1) {
        if (item.startAt === this.cowDetail.birthday || item.startAt === DateUtil.toMSec('1900-01-01')) {
          item.startAt = null;
        }
      }

      item.pregnantDays = 0; // 妊娠日数

      // 分娩間隔
      if (index && item.startAt) {
        const from = DateUtil.startOfDay(item.startAt);
        const to = DateUtil.startOfDay(item.calvingDate);

        item.calvingInterval = DateUtil.diffDays(from, to);
      }

      // 表示するイベント
      item.cowEvents = item.cowEvents.filter((event) => {
        return [
          'breedingDifficulty',
          'freshCheck',
          'hatsujo',
          'hormoneProgram',
          'tanetsuke',
          'ninshinkantei',
          'abort',
          'bunben',
          'embryo_recovery',
          'doNotBreed',
          'kannyu'
        ].includes(event.eventType);
      });

      // 日付表記
      item.cowEvents = item.cowEvents.map((event, index, arr) => {
        event.display = {
          year: {
            value: DateUtil.format(event.occurredAt, 'YYYY')
          },
          occurredAt: {
            mmdd: DateUtil.toMMDD(event.occurredAt),
            mmddhhmm: DateUtil.toMMDDHHmm(event.occurredAt),
            yyyymmdd: DateUtil.toYYYYMMDD(event.occurredAt),
            yyyymmddhhmm: DateUtil.toYYYYMMDDHHmm(event.occurredAt)
          }
        };
        event.display.year.hidden = index ? event.display.year.value === arr[index - 1].display.year.value : false;

        return event;
      });

      // 分娩後日数
      item.cowEvents = item.cowEvents.map((event) => {
        if (item.startAt) {
          const from = DateUtil.startOfDay(item.startAt);
          const to = DateUtil.startOfDay(event.occurredAt);

          event.afterCalviedDays = DateUtil.diffDays(from, to);
        }

        return event;
      });

      // 授精間隔、授精回数
      item.cowEvents.filter((event) => {
        return event.eventType === 'tanetsuke';
      }).map((item2, index2, arr2) => {
        let from;

        if (index2 === arr2.length - 1) {
          from = DateUtil.startOfDay(item.startAt);
        } else {
          from = DateUtil.startOfDay(arr2[index2 + 1].occurredAt);
        }

        const to = DateUtil.startOfDay(item2.occurredAt);

        item2.fertilizationInterval = from ? DateUtil.diffDays(from, to) : 0; // 授精間隔
        item2.fertilizationCount = arr2.length - index2; // 授精回数
        item2.fertilizationEventName = `種付${item2.fertilizationCount}回目`; // 授精回数を含めたイベント名

        item2.display.fertilizationInterval = item2.fertilizationInterval || '-';

        if (!index2) {
          item.fertilizationCount = item2.fertilizationCount;
        }

        return event;
      });

      // 受胎した種付イベント
      const pregnancyBred = item.cowEvents.find((event) => {
        return event.eventType === 'tanetsuke' && DateUtil.isSameDay(CowEvent.calcTargetBreedingDate(event), item.pregnancyDate);
      });

      // 最新種付イベント
      const latestFertilizationEvent = item.cowEvents.find((event) => {
        return event.eventType === 'tanetsuke';
      });

      // 妊娠鑑定待ち、受胎フラグ
      if (latestFertilizationEvent) {
        if (!index) { // 現産次
          if (this.cowDetail.state === '授精') {
            latestFertilizationEvent.waitingForPregnancyTest = true; // 妊娠鑑定待ち
          } else if (['受胎(＋)', '乾乳前期', '乾乳後期'].includes(this.cowDetail.state)) {
            if (pregnancyBred) {
              pregnancyBred.pregnant = true; // 受胎
            } else {
              latestFertilizationEvent.pregnant = true; // 受胎
            }
          }
        } else {
          if (pregnancyBred) {
            pregnancyBred.pregnant = true; // 受胎
          } else {
            latestFertilizationEvent.pregnant = true; // 受胎
          }
        }
      }

      // 最新種付方法が移植
      const isET = latestFertilizationEvent ?
        latestFertilizationEvent.tanetsukeMethod === '移植' : false;

      // 空胎日数
      item.openDays = item.cowEvents.filter((event) => {
        return event.eventType === 'tanetsuke';
      }).reduce((acc, cur, index) => {
        // 移植の場合は -7
        if (isET && !index) {
          acc = acc - 7;
        }

        return acc + cur.fertilizationInterval;
      }, 0);

      // 妊娠日数
      if ((item.expectedCalvingDate || item.calvingDate) && item.latestFertilizationDate) {
        const from = isET ?
          DateUtil.startOfDay(DateUtil.addDays(item.latestFertilizationDate, -7)) :
          DateUtil.startOfDay(item.latestFertilizationDate);
        const to = item.calvingDate ?
          DateUtil.startOfDay(item.calvingDate) :
          DateUtil.startOfDay(DateUtil.today());

        item.pregnantDays = DateUtil.diffDays(from, to);
      }

      // 流産
      item.cowEvents.filter((event) => {
        return event.eventType === 'abort';
      }).forEach((event) => {
        const fertilizationEvent = item.cowEvents.find((event2) => {
          return event2.eventType === 'tanetsuke' && event2.occurredAt <= event.occurredAt;
        });

        if (fertilizationEvent) {
          fertilizationEvent.abort = true;
          fertilizationEvent.pregnant = false;
        }
      });

      // 産次上げ流産
      const abortNextStartMilkingEvent = item.cowEvents.find((event) => {
        return event.eventType === 'abort' && event.nextStartMilkingFlg;
      });

      if (abortNextStartMilkingEvent) {
        item.abort = true;

        // 前産次の流産の戻り先状態が繁殖除外かつ現産次が繁殖除外でない場合、現産次の繁殖除外イベントと繁殖除外の値を変更する
        if (index === 1 && abortNextStartMilkingEvent.afterCowState === '繁殖除外' && !arr[0].doNotBreed) {
          arr[0].doNotBreedEvent = abortNextStartMilkingEvent;
          arr[0].doNotBreed = arr[0].latestFertilizationDate ?
            arr[0].latestFertilizationDate <= abortNextStartMilkingEvent.occurredAt : true;
        }
      }

      // 乾乳前期
      item.dryPreviousPeriodStartDate = Number(item.cowEvents.filter((event) => {
        return event.eventType === 'kannyu' && event.selectedDryPeriod === '前期';
      }).map((event, index, arr) => {
        return arr[arr.length - 1].occurredAt;
      })[0]) || null;

      // 乾乳後期
      item.dryLatePeriodStartDate = Number(item.cowEvents.filter((event) => {
        return event.eventType === 'kannyu' && event.selectedDryPeriod === '後期';
      }).map((event, index, arr) => {
        return arr[arr.length - 1].occurredAt;
      })[0]) || null;

      // 乾乳前期日数
      if (item.dryPreviousPeriodStartDate) {
        const from = DateUtil.startOfDay(item.dryPreviousPeriodStartDate);
        let to;

        if (item.dryLatePeriodStartDate) {
          to = DateUtil.startOfDay(item.dryLatePeriodStartDate);
        } else if (item.calvingDate) {
          to = DateUtil.startOfDay(item.calvingDate);
        } else {
          to = DateUtil.startOfDay(DateUtil.today());
        }

        item.dryPreviousDays = DateUtil.diffDays(from, to);
      }

      // 乾乳後期日数
      if (item.dryLatePeriodStartDate) {
        const from = DateUtil.startOfDay(item.dryLatePeriodStartDate);
        let to;

        if (item.calvingDate) {
          to = DateUtil.startOfDay(item.calvingDate);
        } else {
          to = DateUtil.startOfDay(DateUtil.today());
        }

        item.dryLateDays = DateUtil.diffDays(from, to);
      }

      // 乾乳日数
      if (item.dryDate) {
        const from = DateUtil.startOfDay(item.dryDate);
        let to;

        if (item.calvingDate) {
          to = DateUtil.startOfDay(item.calvingDate);
        } else {
          to = DateUtil.startOfDay(DateUtil.today());
        }

        item.dryDays = DateUtil.diffDays(from, to);
      }

      // 種付イベントのグルーピングとラベル付け（種付履歴）
      let count = 0;

      item.cowEvents.filter((event) => {
        return event.eventType === 'tanetsuke';
      }).sort((a, b) => {
        return a.occurredAt - b.occurredAt;
      }).map((event, index, arr) => {
        const afterEvent = arr[index + 1];

        if (event.bredForEmbryoRecovery && !event.grouping) {
          event.fertilizationEventLabel = '採卵';
        }

        if (event.tanetsukeMethod === '移植' && !event.grouping) {
          event.fertilizationEventLabel = '移植';
        }

        if (afterEvent && event.masterSpermNo && event.masterSpermNo === afterEvent.masterSpermNo) {
          // 追い移植
          if (event.tanetsukeMethod === '人工授精' && afterEvent.tanetsukeMethod === '移植' && [6, 7, 8].includes(afterEvent.fertilizationInterval)) {
            event.grouping = event.grouping || {};
            event.grouping.hidden = true;
            event.grouping.startId = event.grouping.startId || event.id;
            event.grouping.type = 'etAfterAi';
            event.grouping.count = event.grouping.count || 1;
            event.grouping.fertilizationInterval = event.grouping.fertilizationInterval || event.fertilizationInterval;

            afterEvent.grouping = {
              startId: event.grouping.startId,
              type: 'etAfterAi',
              count: event.grouping.count + 1,
              fertilizationInterval: event.grouping.fertilizationInterval + afterEvent.fertilizationInterval
            };
            afterEvent.fertilizationEventLabel = '追い移植';

          // 連続
          } else if (event.tanetsukeMethod === '人工授精' && afterEvent.tanetsukeMethod === '人工授精' &&
            afterEvent.fertilizationInterval <= 3) {

            // 通常連続
            if (!event.bredForEmbryoRecovery && !afterEvent.bredForEmbryoRecovery) {
              event.grouping = event.grouping || {};
              event.grouping.hidden = true;
              event.grouping.startId = event.grouping.startId || event.id;
              event.grouping.type = 'doubleFertilization';
              event.grouping.count = event.grouping.count || 1;
              event.grouping.fertilizationInterval =
                event.grouping.fertilizationInterval || event.fertilizationInterval;

              afterEvent.grouping = {
                startId: event.grouping.startId,
                type: 'doubleFertilization',
                count: event.grouping.count + 1,
                fertilizationInterval: event.grouping.fertilizationInterval + afterEvent.fertilizationInterval
              };
              afterEvent.fertilizationEventLabel = `×${afterEvent.grouping.count}`;

            // 採卵連続
            } else if (event.bredForEmbryoRecovery && afterEvent.bredForEmbryoRecovery) {
              event.grouping = event.grouping || {};
              event.grouping.hidden = true;
              event.grouping.startId = event.grouping.startId || event.id;
              event.grouping.type = 'doubleBredForEmbryoRecovery';
              event.grouping.count = event.grouping.count || 1;
              event.grouping.fertilizationInterval =
                event.grouping.fertilizationInterval || event.fertilizationInterval;

              afterEvent.grouping = {
                startId: event.grouping.startId,
                type: 'doubleBredForEmbryoRecovery',
                count: event.grouping.count + 1,
                fertilizationInterval: event.grouping.fertilizationInterval + afterEvent.fertilizationInterval
              };
              afterEvent.fertilizationEventLabel = `採卵×${afterEvent.grouping.count}`;
            }
          }
        } else if (event.tanetsukeMethod === '本交') {
          event.fertilizationEventLabel = '本交';
        }

        return event;
      }).map((event) => {
        if (!event.grouping || !event.grouping.hidden) {
          count++;
          event.excludeHiddenFertilizationCount = count;
        }

        return event;
      });

      //非表示を除外した授精回数
      item.excludeHiddenFertilizationCount = item.cowEvents.filter((event) => {
        return event.excludeHiddenFertilizationCount;
      }).length;

      // 種付履歴
      item.fertilizationEvents = item.cowEvents.filter((event) => {
        return event.eventType === 'tanetsuke';
      }).sort((a, b) => {
        return a.occurredAt - b.occurredAt;
      }).map((event) => {
        event.display.masterSpermName = event.masterSpermName || '-';

        if (!event.masterSpermName && event.display.occurredAt.yyyymmdd === '-') {
          event.display.occurredAt.yyyymmdd = null;
        }

        return event;
      });

      if (!item.fertilizationEvents.length && (index || item.expectedCalvingDate)) {
        item.fertilizationEvents = [{
          display: {
            masterSpermName: '-',
            fertilizationInterval: '-',
            occurredAt: {
              yyyymmdd: null
            }
          },
          fertilizationInterval: 0
        }];
      }

      // タイムライン
      item.cowEvents = item.cowEvents.map((event, index, arr) => {
        let date;
        let headText;
        let items = [];

        if (event.eventType === 'breedingDifficulty') {
          date = event.display.occurredAt.mmdd;
          items = [{
            name: '卵巣静止・萎縮',
            value: event.difficultiesOvaryStill,
            row: 1
          }, {
            name: '卵胞嚢腫',
            value: event.difficultiesOvaryCystic,
            row: 1
          }, {
            name: '黄体遺残',
            value: event.difficultiesRemnant,
            row: 1
          }, {
            name: '子宮炎',
            value: event.difficultiesMetritis,
            row: 1
          }, {
            name: '子宮蓄膿症',
            value: event.difficultiesPyometra,
            row: 1
          }, {
            name: '投薬',
            value: event.selectedMedicines,
            row: 2
          }, {
            name: '担当者',
            value: event.workerName,
            row: 3
          }, {
            name: '卵巣診断（左）',
            value: event.diagnosisResultOfOvaryL,
            row: 3
          }, {
            name: '卵巣診断（右）',
            value: event.diagnosisResultOfOvaryR,
            row: 3
          }, {
            name: '子宮診断',
            value: event.uterusDiagnosisResult,
            row: 3
          }, {
            name: '体温',
            value: event.bodyTemperature ? `${event.bodyTemperature}℃` : null,
            row: 3
          }, {
            name: '診断治療結果',
            value: event.diagnosisResultDetail,
            row: 3
          }, {
            name: '搾乳休薬終了日',
            value: DateUtil.toMMDD(event.endDateOfMilkWashoutPeriod),
            row: 3
          }, {
            name: '食肉休薬終了日',
            value: DateUtil.toMMDD(event.endDateOfBeefWashoutPeriod),
            row: 3
          }, {
            name: 'コメント',
            value: event.comment,
            row: 4
          }].filter((item) => {
            return !['卵巣診断（左）', '卵巣診断（右）', '子宮診断'].includes(item.name) || item.value !== '良好';
          });
        } else if (event.eventType === 'freshCheck') {
          date = event.display.occurredAt.mmdd;
          items = [{
            name: '卵巣（左）',
            value: event.diagnosisResultOfOvaryL
          }, {
            name: '卵巣（右）',
            value: event.diagnosisResultOfOvaryR
          }, {
            name: '子宮',
            value: event.uterusDiagnosisResult
          }, {
            name: 'BCS',
            value: event.bcs
          }, {
            name: 'その他処置',
            value: event.otherReproductionWork
          }, {
            name: 'チェック結果',
            value: event.freshCheckResult
          }, {
            name: 'コメント',
            value: event.comment
          }].filter((item) => {
            return !['卵巣（左）', '卵巣（右）', '子宮'].includes(item.name) || item.value !== '良好';
          });
        } else if (event.eventType === 'hatsujo') {
          date = event.display.occurredAt.mmddhhmm;
          headText = event.plannedBredMethod ? `${event.plannedBredMethod} (${DateUtil.toMMDDHHmm(event.plannedBredAt)}) ` : null;
          items = [{
            name: 'コメント',
            value: event.comment,
            row: 1
          }];
        } else if (event.eventType === 'hormoneProgram') {
          date = event.display.occurredAt.mmddhhmm;
          items = [{
            name: 'プログラム名',
            value: event.hormoneProgramName,
            row: 1
          }, {
            name: '処置内容',
            value: event.hormoneName,
            row: 1
          }, {
            name: 'コメント',
            value: event.comment,
            row: 2
          }];
        } else if (event.eventType === 'tanetsuke') {
          date = event.display.occurredAt.mmddhhmm;
          headText = event.tanetsukeMethod;
          items = [{
            name: '精液情報',
            value: event.masterSpermId ? `${event.masterSpermName} (${event.masterSpermNo})` : null,
            row: 1
          }, {
            name: '担当者',
            value: event.workerName,
            row: 2
          }, {
            name: '授精コード',
            value: event.inseminationCode,
            row: 2
          }, {
            name: 'その他処置',
            value: event.otherReproductionWork,
            row: 2
          }, {
            name: '供卵牛登録番号',
            value: event.donorRegistrationNo,
            row: 2
          }, {
            name: '供卵牛名',
            value: event.donorName,
            row: 2
          }, {
            name: '供卵牛品種',
            value: event.donorBreed,
            row: 2
          }, {
            name: '供卵父牛精液番号',
            value: event.donorSpermNo,
            row: 2
          }, {
            name: '供卵母牛品種',
            value: event.motherBreedOfDonor,
            row: 2
          }, {
            name: '供卵母牛登録番号',
            value: event.motherRegistrationNoOfDonor,
            row: 2
          }, {
            name: '供卵母牛名',
            value: event.motherNameOfDonor,
            row: 2
          }, {
            name: 'コメント',
            value: event.comment,
            row: 3
          }];
        } else if (event.eventType === 'ninshinkantei') {
          date = event.display.occurredAt.mmdd;
          headText = event.ninshinkanteiResult;
          items = [{
            name: '担当者',
            value: event.workerName,
            row: 1
          }, {
            name: 'タイミング',
            value: event.judgePregnantTiming,
            row: 1
          }, {
            name: 'その他処置',
            value: event.otherReproductionWork,
            row: 1
          }, {
            name: '対象種付日',
            value: DateUtil.toMMDD(event.targetBreedingDate),
            row: 1
          }, {
            name: 'コメント',
            value: event.comment,
            row: 2
          }];
        } else if (event.eventType === 'abort') {
          date = event.display.occurredAt.mmddhhmm;
          items = [{
            name: '戻り先状態',
            value: event.afterCowState,
            row: 1
          }, {
            name: '次産泌乳',
            value: event.nextStartMilkingFlg ? 'あり' : 'なし',
            row: 1
          }, {
            name: 'コメント',
            value: event.comment,
            row: 2
          }];
        } else if (event.eventType === 'bunben') {
          if (event.calfs) {
            items = event.calfs.map((calf, index) => {
              const value = [
                calf.birthResult,
                calf.breed,
                calf.gender,
                calf.registered ? 'データ作成済' : null,
                calf.cowNo,
                calf.cowUid,
                calf.weightOnBirth ? `${calf.weightOnBirth}kg` : null,
                calf.calfNo,
                calf.birthState.length ? calf.birthState.join(', ') : null
              ].filter((item) => {
                return item;
              }).join(', ');
              return {
                name: `${index + 1}頭目`,
                value: value,
                row: index + 1
              };
            });
          }

          const moveToCowGroup = this.cowGroup.find((item) => {
            return item.cowGroupId === event.moveToCowGroupId;
          });

          const calvingDifficulty = this.selection.calvingDifficulty.find((item) => {
            return item.value === event.calvingDifficulty;
          });

          date = event.display.occurredAt.mmddhhmm;
          items = items.concat([{
            name: '分娩後移動先',
            nameHidden: true,
            value: moveToCowGroup ? `${moveToCowGroup.cowGroupName}に移動` : null,
            row: 11
          }, {
            name: '牛房',
            value: event.moveToPen,
            row: 12
          }, {
            name: '出生頭数',
            value: event.childNumber,
            row: 12
          }, {
            name: '難易度',
            value: calvingDifficulty ? calvingDifficulty.label : null,
            row: 12
          }, {
            name: 'コメント',
            value: event.comment,
            row: 13
          }]);
        } else if (event.eventType === 'embryo_recovery') {
          const embryoRecoveryRank = event.embryos.map((embryo) => {
            const {rank, count: value} = embryo;

            const name =
              rank === 'degenerated' ? '変性' :
                rank === 'unfertilized' ? '未受精' : rank;

            return {name, value};
          }).filter((item) => {
            return item.value;
          }).map((item) => {
            return `[${item.name}] ${item.value} `;
          }).join('');

          date = event.display.occurredAt.mmdd;
          items = [{
            name: 'ランク',
            nameHidden: true,
            value: embryoRecoveryRank,
            row: 1
          }, {
            name: '精液番号',
            value: event.masterSpermNo,
            row: 2
          }, {
            name: '対象種付日',
            value: DateUtil.toMMDD(event.targetBreedingDate),
            row: 3
          }, {
            name: '受精卵マスタ登録',
            value: event.embryoMasterRegisteredLabel,
            row: 3
          }, {
            name: 'ホルモンプログラム',
            value: event.hormoneProgramName,
            row: 3
          }, {
            name: '採卵後の状態',
            value: event.nextCowStateDisplay,
            row: 3
          }, {
            name: 'コメント',
            value: event.comment,
            row: 4
          }];
        } else if (event.eventType === 'doNotBreed') {
          date = event.display.occurredAt.mmdd;
          items = [{
            name: '除外理由',
            value: event.breedingExclusionReason,
            row: 1
          }, {
            name: 'コメント',
            value: event.comment,
            row: 2
          }];
        } else if (event.eventType === 'kannyu') {
          const moveToCowGroup = this.cowGroup.find((item) => {
            return item.cowGroupId === event.moveToCowGroupId;
          });

          date = event.display.occurredAt.mmdd;
          headText = event.selectedDryPeriod;
          items = items.concat([{
            name: '乾乳移動先',
            nameHidden: true,
            value: moveToCowGroup ? `${moveToCowGroup.cowGroupName}に移動` : null,
            row: 1
          }, {
            name: '牛房',
            value: event.moveToPen,
            row: 2
          }, {
            name: 'BCS',
            value: event.bcs,
            row: 2
          }, {
            name: 'コメント',
            value: event.comment,
            row: 3
          }]);
        }

        const rows = [...items.map((item) => item.row)].filter((item, index, arr) => {
          return arr.indexOf(item) === index;
        });

        event.timeline = {
          date: date,
          headText: headText,
          items: items.filter((item) => {
            return item.value;
          }),
          groupingItems: rows.reduce((acc, cur) => {
            acc.push(items.filter((item) => {
              return item.row === cur && item.value;
            }));

            return acc;
          }, [])
        };

        return event;
      });

      // 前後の種付イベントID、授精後日数
      item.cowEvents = item.cowEvents.map((event, index, arr) => {
        const beforeFertilizationEvent = arr.find((event2, index2) => {
          return event2.eventType === 'tanetsuke' &&
            event2.occurredAt <= event.occurredAt &&
            event2.id !== event.id;
        }) || [];

        const afterFertilizationEvent = angular.copy(arr).sort((a, b) => {
          return a.occurredAt - b.occurredAt;
        }).find((event2, index2) => {
          return event2.eventType === 'tanetsuke' &&
            event2.occurredAt >= event.occurredAt &&
            event2.id !== event.id;
        }) || [];

        const from = DateUtil.startOfDay(beforeFertilizationEvent.occurredAt);
        const to = DateUtil.startOfDay(event.occurredAt);

        event.afterFertilizationDays =
        beforeFertilizationEvent.occurredAt ? DateUtil.diffDays(from, to) : null; // 授精後日数
        event.beforeFertilizationEventId = beforeFertilizationEvent.id || null; // 前の種付イベントID
        event.afterFertilizationEventId = afterFertilizationEvent.id || null; // 後の種付イベントID

        return event;
      });

      // ホルモンプログラムのグルーピング
      let hormoneProgramEvents = [];

      const groupingHormoneProgramEvents = item.cowEvents.filter((event) => {
        return ['hormoneProgram', 'tanetsuke'].includes(event.eventType);
      }).reduce((acc, cur, index, arr) => {
        if (cur.eventType === 'tanetsuke' || arr.length === index + 1) {
          if (arr.length === index + 1) {
            hormoneProgramEvents.push(cur);
          }

          acc.push(hormoneProgramEvents);
          hormoneProgramEvents = [];
        } else {
          hormoneProgramEvents.push(cur);
        }
        return acc;
      }, []).map((events) => {
        return events.filter((event) => {
          return event.eventType === 'hormoneProgram';
        }).reduce((acc, cur, index, arr) => {
          const filteredHormoneProgramName = acc.find((item) => {
            return item.hormoneProgramName === cur.hormoneProgramName;
          });

          if (filteredHormoneProgramName) {
            filteredHormoneProgramName.items.push({
              hormoneName: cur.hormoneName,
              display: cur.display
            });
          } else {
            acc.push({
              id: cur.id,
              hormoneProgramName: cur.hormoneProgramName,
              items: [{
                hormoneName: cur.hormoneName,
                display: cur.display
              }]
            });
          }

          return acc;
        }, []);
      });

      groupingHormoneProgramEvents.forEach((event, index) => {
        const findId = event.length ? event[0].id : null;

        if (findId) {
          const filteredHormoneProgramEvent = item.cowEvents.find((item) => {
            return item.id === findId;
          });

          filteredHormoneProgramEvent.grouping = groupingHormoneProgramEvents[index];
        }
      });

      // 表示用
      item.display = {
        firstFertilizationDate: {
          yyyymmdd: DateUtil.toYYYYMMDD(item.firstFertilizationDate) || '-'
        },
        latestFertilizationDate: {
          yyyymmdd: DateUtil.toYYYYMMDD(item.latestFertilizationDate) || '-'
        },
        expectedCalvingDate: {
          yyyymmdd: DateUtil.toYYYYMMDD(item.expectedCalvingDate)
        },
        expectedDryDate: {
          yyyymmdd: this.isDairy ? DateUtil.toYYYYMMDD(item.expectedDryDate) : null
        },
        calvingDate: {
          yyyymmdd: DateUtil.toYYYYMMDD(item.calvingDate) || '-'
        },
        dryDate: {
          yyyymmdd: this.isDairy ? (DateUtil.toYYYYMMDD(item.dryDate) || '-') : null
        },
        dryPreviousPeriodStartDate: {
          yyyymmdd: this.isDairy ? (DateUtil.toYYYYMMDD(item.dryPreviousPeriodStartDate) || '-') : null,
        },
        dryLatePeriodStartDate: {
          yyyymmdd: this.isDairy ? (DateUtil.toYYYYMMDD(item.dryLatePeriodStartDate) || '-') : null,
        },
        startFatteningDate: {
          yyyymmdd: DateUtil.toYYYYMMDD(item.startFatteningDate)
        },
        eliminateDate: {
          yyyymmdd: DateUtil.toYYYYMMDD(item.eliminateDate)
        },
        fallingDeadDate: {
          yyyymmdd: DateUtil.toYYYYMMDD(item.fallingDeadDate)
        },
        calvingInterval: item.calvingInterval || '-',
        fertilizationCount: item.fertilizationCount || '-',
        openDays: item.openDays || '-',
        pregnantDays: item.pregnantDays || '-',
        dryPreviousDays: this.isDairy ? (item.dryPreviousDays >= 0 ? item.dryPreviousDays : '-') : null,
        dryLateDays: this.isDairy ? (item.dryLateDays >= 0 ? item.dryLateDays : '-') : null,
        dryDays: this.isDairy ? (item.dryDays >= 0 ? item.dryDays : '-') : null,
      };

      return item;
    });
  }

  generateReproductionHistory() {
    this.reproductionHistory = this.reproductionList.reduce((acc, cur, index, arr) => {
      if (acc.length < 3) {
        acc = acc.concat(cur.cowEvents);
      }

      return acc;
    }, []).slice(0, 3);
  }

  generateTableData() {
    this.tableData = [{
      headings: [{
        name: '産次'
      }, {
        name: '分娩間隔'
      }, {
        name: '空胎日数'
      }, {
        name: '授精回数'
      }, {
        name: '妊娠日数'
      }, {
        name: '乾乳日数',
        hidden: !this.isDairy
      }, {
        name: '分娩日',
        appendLabel: true
      }, {
        name: '初回授精日'
      }, {
        name: '最終授精日'
      }, {
        name: '乾乳日',
        hidden: !this.isDairy
      }],
      items: this.reproductionList.map((item, index, arr) => {
        const calvingInterval = arr[index + 1] ? arr[index + 1].display.calvingInterval : '-';
        const calvingDate = arr[index + 1] ? arr[index + 1].display.calvingDate.yyyymmdd : '-';
        const abort = arr[index + 1] && arr[index + 1].abort ? '流産' : null;

        return [
          item.birthNumber,
          calvingInterval,
          item.display.openDays,
          item.display.fertilizationCount,
          item.display.pregnantDays,
          item.display.dryDays,
          [calvingDate, abort],
          item.display.firstFertilizationDate.yyyymmdd,
          item.display.latestFertilizationDate.yyyymmdd,
          item.display.dryDate.yyyymmdd
        ];
      })
    }, {
      headings: [{
        name: 'init',
        hidden: true
      }, {
        name: '産次'
      }, {
        name: '分娩日時'
      }, {
        name: '分娩間隔'
      }, {
        name: '妊娠日数'
      }, {
        name: '精液番号'
      }, {
        name: '種雄牛名'
      }, {
        name: '品種'
      }, {
        name: '出産結果'
      }, {
        name: '性別'
      }, {
        name: '体重'
      }, {
        name: '出生時状態'
      }, {
        name: '分娩難易度'
      }, {
        name: 'コメント'
      }],
      items: this.reproductionList.filter((item) => {
        return item.calvingDate;
      }).reduce((acc, cur, index) => {

        const calvingEvent = cur.cowEvents.find((event) => {
          return event.eventType === 'bunben';
        });

        if (calvingEvent) {
          const calvingDifficulty = this.selection.calvingDifficulty.find((item) => {
            return item.value === calvingEvent.calvingDifficulty;
          }) || [];

          let pregnancyBred = cur.cowEvents.find((event) => {
            return event.eventType === 'tanetsuke' && DateUtil.isSameDay(CowEvent.calcTargetBreedingDate(event), cur.pregnancyDate);
          });

          if (!pregnancyBred) {
            pregnancyBred = cur.cowEvents.find((event) => {
              return event.eventType === 'tanetsuke';
            });
          }

          let masterSpermNo = '-';
          let masterSpermName = '-';

          if (pregnancyBred) {
            masterSpermNo = pregnancyBred.masterSpermNo || '-';
            masterSpermName = pregnancyBred.masterSpermName || '-';
          }

          const calfs = calvingEvent.calfs || [{}];
          calfs.reduce((acc2, cur2, index2) => {
            acc.push(
              [{
                grouping: !!index2
              },
              !index2 ? cur.birthNumber + 1 : null,
              !index2 ? calvingEvent.display.occurredAt.yyyymmddhhmm : null,
              !index2 ? cur.display.calvingInterval : null,
              !index2 ? cur.display.pregnantDays : null,
              !index2 ? masterSpermNo : null,
              !index2 ? masterSpermName : null,
              !index2 ? (cur2.breed || '-') : null,
              cur2.birthResult || '-',
              cur2.gender || '-',
              cur2.weightOnBirth ? `${cur2.weightOnBirth}kg` : '-',
              cur2.birthState && cur2.birthState.length ? cur2.birthState.join(', ') : '-',
              !index2 ? (calvingDifficulty.label || '-') : null,
              !index2 ? (calvingEvent.comment || '-') : null
              ]
            );
          }, []);
        }

        return acc;
      }, [])
    }, {
      headings: [{
        name: 'init',
        hidden: true
      }, {
        name: '産次'
      }, {
        name: '種付日時'
      }, {
        name: '妊娠鑑定',
        nameHidden: true,
        label: true
      }, {
        name: '分娩後日数'
      }, {
        name: '種付方法'
      }, {
        name: '精液番号'
      }, {
        name: '種雄牛名'
      }, {
        name: '供卵牛',
      }, {
        name: '担当者'
      }, {
        name: '授精コード'
      }, {
        name: 'その他処置'
      }, {
        name: 'コメント'
      }],
      items: this.reproductionList.reduce((acc, cur, index, arr) => {
        cur.cowEvents.filter((event) => {
          return event.eventType === 'tanetsuke';
        }).reduce((acc2, cur2, index2) => {
          const label = [
            cur2.pregnant ? '受胎' : null,
            cur2.abort ? '流産' : null
          ];

          acc.push([{
            grouping: !!index2
          },
          !index2 ? cur.birthNumber : null,
          cur2.display.occurredAt.yyyymmddhhmm,
          label,
          cur2.afterCalviedDays || '-',
          cur2.tanetsukeMethod,
          cur2.masterSpermNo || '-',
          cur2.masterSpermName || '-',
          cur2.donorName || '-',
          cur2.workerName || '-',
          cur2.inseminationCode || '-',
          cur2.otherReproductionWork || '-',
          cur2.comment || '-'
          ]);
        }, []);

        return acc;
      }, [])
    }, {
      headings: [{
        name: 'init',
        hidden: true
      }, {
        name: '産次'
      }, {
        name: '出生日'
      }, {
        name: '種雄牛名',
        appendLabel: true
      }, {
        name: '品種'
      }, {
        name: '牛番号',
        toCowDetail: true
      }, {
        name: '個体識別番号'
      }, {
        name: '子牛番号'
      }, {
        name: '性別'
      }, {
        name: '出生時体重'
      }, {
        name: '販売価格',
        hidden: this.isItemFilterApplied
      }, {
        name: '販売先'
      }],
      items: this.reproductionList.reduce((acc, cur) => {
        const fertilizationEventET = cur.cowEvents.find((event) => {
          return event.eventType === 'tanetsuke' &&
            event.tanetsukeMethod === '移植' &&
            event.pregnant;
        });

        let labelET = [];
        if (fertilizationEventET) {
          labelET.push('ET');

          if (fertilizationEventET.donorName) {
            labelET.push(fertilizationEventET.donorName);
          }
        }
        labelET = labelET.join('：');

        cur.calfs.map((event, index) => {
          acc.push([{
            grouping: !!index
          },
          !index ? cur.birthNumber + 1 : null,
          !index ? cur.display.calvingDate.yyyymmdd : null,
          [(!index ? (event.fatherName || '-') : null), (!index ? labelET : null)],
          !index ? (event.breed || '-') : null,
          [(event.cowNo || '-'), event.cowId],
          event.cowUid || '-',
          event.calfNo || '-',
          event.gender,
          event.weightOnBirth ? `${event.weightOnBirth}kg` : '-',
          event.salesPrice ? event.salesPrice.toLocaleString() : '-',
          event.buyer || '-'
          ]);
        });

        return acc;
      }, [])
    }, {
      headings: [{
        name: 'init',
        hidden: true
      }, {
        name: '産次'
      }, {
        name: '前期開始日'
      }, {
        name: '前期日数'
      }, {
        name: '後期開始日'
      }, {
        name: '後期日数'
      }, {
        name: '乾乳日数'
      }],
      items: this.reproductionList.filter((item) => {
        return item.dryDays;
      }).map((item) => {
        return [
          {},
          item.birthNumber,
          item.display.dryPreviousPeriodStartDate.yyyymmdd,
          item.display.dryPreviousDays,
          item.display.dryLatePeriodStartDate.yyyymmdd,
          item.display.dryLateDays,
          item.display.dryDays
        ];
      })
    }, {
      headings: [{
        name: 'init',
        hidden: true
      }, {
        name: '産次'
      }, {
        name: '採卵日'
      }, {
        name: '月齢'
      }, {
        name: '種雄牛'
      }, {
        name: 'ホルモンプログラム'
      }, {
        name: '回収卵数'
      }, {
        name: '正常卵数'
      }, {
        name: '正常卵率',
        append: '%'
      }, {
        name: 'A',
        hidden: !(this.embryoRecoveryRank.a.use && this.embryoRecoveryRank.a.subrank === 1)
      }, {
        name: 'A1',
        hidden: !(this.embryoRecoveryRank.a.use && this.embryoRecoveryRank.a.subrank >= 2)
      }, {
        name: 'A2',
        hidden: !(this.embryoRecoveryRank.a.use && this.embryoRecoveryRank.a.subrank >= 2)
      }, {
        name: 'A3',
        hidden: !(this.embryoRecoveryRank.a.use && this.embryoRecoveryRank.a.subrank >= 3)
      }, {
        name: 'A4',
        hidden: !(this.embryoRecoveryRank.a.use && this.embryoRecoveryRank.a.subrank >= 4)
      }, {
        name: 'A5',
        hidden: !(this.embryoRecoveryRank.a.use && this.embryoRecoveryRank.a.subrank >= 5)
      }, {
        name: 'B',
        hidden: !(this.embryoRecoveryRank.b.use && this.embryoRecoveryRank.b.subrank === 1)
      }, {
        name: 'B1',
        hidden: !(this.embryoRecoveryRank.b.use && this.embryoRecoveryRank.b.subrank >= 2)
      }, {
        name: 'B2',
        hidden: !(this.embryoRecoveryRank.b.use && this.embryoRecoveryRank.b.subrank >= 2)
      }, {
        name: 'B3',
        hidden: !(this.embryoRecoveryRank.b.use && this.embryoRecoveryRank.b.subrank >= 3)
      }, {
        name: 'B4',
        hidden: !(this.embryoRecoveryRank.b.use && this.embryoRecoveryRank.b.subrank >= 4)
      }, {
        name: 'B5',
        hidden: !(this.embryoRecoveryRank.b.use && this.embryoRecoveryRank.b.subrank >= 5)
      }, {
        name: 'C',
        hidden: !this.embryoRecoveryRank.c.use
      }, {
        name: 'D',
        hidden: !this.embryoRecoveryRank.d.use
      }, {
        name: '変性',
        hidden: !this.embryoRecoveryRank.degenerated.use
      }, {
        name: '未受精',
        hidden: !this.embryoRecoveryRank.unfertilized.use
      }, {
        name: 'コメント'
      }],
      items: this.reproductionList.reduce((acc, cur) => {
        cur.cowEvents.filter((event) => {
          return event.eventType === 'embryo_recovery';
        }).reduce((acc2, cur2, index2, arr2) => {
          const totalEmbryoCount = cur2.embryos.reduce((acc, cur) => {
            return acc + cur.count;
          }, 0);

          const normalEmbryoCount = cur2.embryos.reduce((acc, cur) => {
            if (['degenerated', 'unfertilized'].includes(cur.rank)) {
              return acc;
            }

            return acc + cur.count;
          }, 0);

          const normalEmbryoRatio = Math.round((normalEmbryoCount / totalEmbryoCount) * 100);

          const embryo = cur2.embryos.reduce((acc, cur) => {
            acc[cur.rank] = cur.count;

            return acc;
          }, {});

          acc.push([{
            grouping: !!index2
          },
          !index2 ? cur.birthNumber : null,
          cur2.display.occurredAt.yyyymmdd,
          DateUtil.diffMonths(this.cowDetail.birthday, cur2.occurredAt),
          cur2.masterSpermName,
          cur2.hormoneProgramName || '-',
          totalEmbryoCount,
          normalEmbryoCount,
          normalEmbryoRatio,
          embryo.A || 0,
          embryo.A1 || 0,
          embryo.A2 || 0,
          embryo.A3 || 0,
          embryo.A4 || 0,
          embryo.A5 || 0,
          embryo.B || 0,
          embryo.B1 || 0,
          embryo.B2 || 0,
          embryo.B3 || 0,
          embryo.B4 || 0,
          embryo.B5 || 0,
          embryo.C || 0,
          embryo.D || 0,
          embryo.degenerated || 0,
          embryo.unfertilized || 0,
          cur2.comment || '-'
          ]);

          if (index2 === arr2.length - 1) {
            const averages = acc.reduce((acc3, cur3) => {
              cur3.reduce((acc4, cur4, index4) => {
                if (!acc3[index4]) {
                  acc3[index4] = [];
                }

                if (index4 >= 6 && index4 <= 24) {
                  acc3[index4].push(cur4);
                }
              }, []);

              return acc3;
            }, []).reduce((acc5, cur5, index5, arr5) => {
              if (cur5.length) {
                if (index5 === 8) {
                  const totalEmbryoCount = arr5[6].reduce((acc, cur) => acc + cur, 0);
                  const normalEmbryoCount = arr5[7].reduce((acc, cur) => acc + cur, 0);
                  const average = Math.round((normalEmbryoCount / totalEmbryoCount) * 100);

                  acc5.push(average);

                  return acc5;
                }

                const average = Math.round((cur5.reduce((acc, cur) => {
                  return acc + cur;
                }, 0) / cur5.length) * 10) / 10;

                acc5.push(average);
              } else if (!index5) {
                acc5.push({
                  doublet: true
                });
              } else if (index5 === 1) {
                acc5.push('平均');
              } else {
                acc5.push('-');
              }

              return acc5;
            }, []);

            acc.push(averages);
          }
        }, []);

        return acc;
      }, [])
    }];
  }

  setMaxExcludeHiddenFertilizationCount() {
    this.maxExcludeHiddenFertilizationCount = Math.max(
      ...this.reproductionList.map((item) => {
        return item.excludeHiddenFertilizationCount;
      })
    );
  }

  styleReproductionHistory() {
    let width;
    let widthBody;
    let widthBodyGroupInner;

    this.$timeout(() => {
      width = Math.max.apply(Math, angular.element('.uGrid__bodyMain').map((index, elm) => {
        return angular.element(elm).width();
      }).get());

      angular.element('.uGrid__bodyMain').width(width);
    });

    this.$timeout(() => {
      widthBody = angular.element('.uGrid__body').width();
      widthBodyGroupInner = angular.element('.uGrid__bodyGroupInner').width();

      if (widthBodyGroupInner > widthBody) {
        angular.element('.uGrid__scroll').animate({
          scrollLeft: widthBodyGroupInner - widthBody + 16
        }, 400, 'swing');
      }
    }, 100);
  }

  openTimeline(e, event, birthNumber) {
    const cowEvents = this.reproductionList.find((item) => {
      return item.birthNumber === birthNumber;
    }).cowEvents;

    const startIndex = cowEvents.findIndex((item, index) => {
      if (!event) {
        return 0;
      }

      return item.id === event.id;
    });

    const endIndex = cowEvents.findIndex((item, index, arr) => {
      if (!event || event.excludeHiddenFertilizationCount === 1) {
        return index === arr.length - 1;
      }

      return index > startIndex &&
        item.eventType === 'tanetsuke' &&
        item.occurredAt <= event.occurredAt;
    });

    this.filteredTimelineEvents = cowEvents.filter((event, index) => {
      return index >= startIndex && index <= endIndex;
    });

    this.timelineCalvingNumber = birthNumber;
    this.timelineHeading = `産次${birthNumber}`;
    this.timelineText = event && event.excludeHiddenFertilizationCount > 1 ?
      `種付 ${event.excludeHiddenFertilizationCount - 1} - ${event.excludeHiddenFertilizationCount}` : '初回授精';
    this.timelineTargetId = event ? event.id : null;
    this.isOpenTimeline = true;

    const css = {
      top: angular.element(e.target).offset().top + angular.element(e.target).height() - this.$window.pageYOffset,
      left: angular.element(e.target).offset().left
    };

    angular.element('.uGrid__bodyTimelineInner').css(css);

    this.pageOffset = {
      y: this.$window.pageYOffset
    };
    this.timelineOffset = css;

    this.$timeout(() => {
      angular.element('.uGrid__bodyTimelineItems').scrollTop(0);
    });
  }

  closeTimeline() {
    this.isOpenTimeline = false;
  }

  openReproductionHistoryModal(birthNumber, timelineTargetId) {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'cow/detail/tabs/reproduction-history-modal.html',
      controller: 'ReproductionHistoryModalController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            items: this.reproductionList,
            birthNumber: birthNumber,
            timelineTargetId: timelineTargetId
          };
        }
      }
    });
  }

  showReproduction() {
    return this.activeTab === 'reproductionTab';
  }

  showFertilizationEvent(event) {
    return !event.grouping || !event.grouping.hidden;
  }

  showGridFoot() {
    return this.reproductionList.length > 2;
  }

  showGridBodyLine(item) {
    return item.calvingDate ||
      item.expectedCalvingDate ||
      item.fattening ||
      item.doNotBreed ||
      item.eliminate ||
      item.fallingDead;
  }

  showPagerPrev() {
    const elm = document.querySelector('#uGrid--reproduction__scroll');

    return elm.scrollLeft;
  }

  showPagerNext() {
    const elm = document.querySelector('#uGrid--reproduction__scroll');
    const scrollLeft = elm.scrollLeft;
    const clientWidth = elm.clientWidth;
    const scrollWidth = elm.scrollWidth;

    return scrollLeft + clientWidth !== scrollWidth;
  }

  showTableData(index) {
    return this.activeTableData === index;
  }

  showTableCalf() {
    return this.isCalfManagement && this.reproductionList[0].birthNumber;
  }

  showTableCellText(heading) {
    return !heading.toCowDetail && !heading.label & !heading.appendLabel;
  }

  showTableCellLink(heading) {
    return heading.toCowDetail;
  }

  showTableCellLabel(heading) {
    return heading.label;
  }

  showTableCellAppendLabel(heading) {
    return heading.appendLabel;
  }

  changeActiveTableData(index) {
    this.activeTableData = index;

    angular.element('.uTable').scrollLeft(0);
  }

  clickGridMore() {
    this.openGrid = true;

    this.$timeout(() => {
      this.scrollbarPosition();
    }, 100);
  }

  clickGridClose() {
    angular.element('html, body').animate({
      scrollTop: 0
    }, 500, 'swing');

    this.$timeout(() => {
      this.openGrid = false;
    }, 500);

    this.$timeout(() => {
      this.scrollbarPosition();
    }, 600);
  }

  clickPagerPrev() {
    const scrollLeft = angular.element('#uGrid--reproduction__scroll').scrollLeft() - 140;

    angular.element('#uGrid--reproduction__scroll').animate({
      scrollLeft: scrollLeft
    }, 250, 'swing');
  }

  clickPagerNext() {
    const scrollLeft = angular.element('#uGrid--reproduction__scroll').scrollLeft() + 140;

    angular.element('#uGrid--reproduction__scroll').animate({
      scrollLeft: scrollLeft
    }, 250, 'swing');
  }

  initScrollbar() {
    if (this.$window.matchMedia('(pointer: coarse)').matches) return;

    this.scrollbarParams = {
      elm: {},
      width: {},
      scrolling: false
    };

    this.$timeout(() => {
      this.scrollbarParams.elm.outher = document.querySelector('#uGrid--reproduction__scroll');
      this.scrollbarParams.elm.scrollbar = document.querySelector('#uGrid__scrollbar');
      this.scrollbarParams.elm.scrollbarBody = document.querySelector('.uGrid__scrollbarBody');
      this.scrollbarParams.width.outher = this.scrollbarParams.elm.outher.clientWidth;
      this.scrollbarParams.width.scroll = this.scrollbarParams.elm.outher.scrollWidth;
      this.scrollbarParams.width.scrollbar = this.scrollbarParams.elm.scrollbar.clientWidth *
        (this.scrollbarParams.elm.scrollbar.clientWidth / this.scrollbarParams.width.scroll);
      this.scrollbarParams.width.scrollbarBody = this.scrollbarParams.width.outher -
        this.scrollbarParams.width.scrollbar;

      if (this.scrollbarParams.width.scroll === this.scrollbarParams.elm.scrollbar.clientWidth) {
        this.scrollbarParams.elm.scrollbar.style.visibility = 'hidden';
      } else {
        this.scrollbarParams.elm.scrollbar.style.visibility = 'visible';
      }

      this.scrollbarParams.elm.scrollbarBody.style.width = `${this.scrollbarParams.width.scrollbar}px`;

      const offset = this.scrollbarParams.elm.outher.scrollLeft *
        this.scrollbarParams.width.scrollbarBody /
        (this.scrollbarParams.width.scroll - this.scrollbarParams.width.outher);

      this.scrollbarParams.elm.scrollbarBody.style.transform = `translateX(${offset}px)`;

      this.onScrollbarOutherScroll = () => {
        if (this.scrollbarParams.elm.outher) {
          const offset = this.scrollbarParams.elm.outher.scrollLeft *
            this.scrollbarParams.width.scrollbarBody /
            (this.scrollbarParams.width.scroll - this.scrollbarParams.width.outher);

          this.scrollbarParams.elm.scrollbarBody.style.transform = `translateX(${offset}px)`;
        }
      };
      this.scrollbarParams.elm.outher.addEventListener('scroll', this.onScrollbarOutherScroll);

      this.onScrollbarBodyMousedown = () => {
        const scrollbarBodyLeft = this.scrollbarParams.elm.scrollbarBody.getBoundingClientRect().left +
          this.$window.pageXOffset;

        this.scrollbarParams.scrolling = true;
        this.scrollbarThumbCursorX = event.pageX - scrollbarBodyLeft;
      };
      this.scrollbarParams.elm.scrollbarBody.addEventListener('mousedown', this.onScrollbarBodyMousedown);
    }, 100);
  }

  mouseup() {
    if (this.$window.matchMedia('(pointer: coarse)').matches) return;

    this.scrollbarParams.scrolling = false;
  }

  mousemove(event) {
    if (this.$window.matchMedia('(pointer: coarse)').matches ||
      !this.scrollbarParams.scrolling) return;

    const scrollbarLeft = this.scrollbarParams.elm.scrollbar.getBoundingClientRect().left +
      this.$window.pageXOffset;
    const scrollbarBodyLeft = this.scrollbarParams.elm.scrollbarBody.getBoundingClientRect().left +
      this.$window.pageXOffset;
    let scrollbarThumbX = ((event.pageX - scrollbarLeft) /
        this.scrollbarParams.width.scrollbarBody * this.scrollbarParams.width.scrollbarBody) -
      this.scrollbarThumbCursorX;

    if (scrollbarThumbX < 0) {
      scrollbarThumbX = 0;
    } else if (scrollbarThumbX > this.scrollbarParams.width.scrollbarBody) {
      scrollbarThumbX = this.scrollbarParams.width.scrollbarBody;
    }

    this.scrollbarParams.elm.scrollbarBody.style.transform = `translateX(${scrollbarThumbX}px)`;
    this.scrollbarParams.elm.outher.scrollLeft = (scrollbarBodyLeft - scrollbarLeft) /
      this.scrollbarParams.width.scrollbarBody *
      (this.scrollbarParams.width.scroll - this.scrollbarParams.width.outher);
  }

  scrollbarPosition() {
    if (this.$window.matchMedia('(pointer: coarse)').matches) return;

    const height = this.$window.outerHeight;
    const top = document.querySelector('.uGrid__foot').getBoundingClientRect().top;

    if (height <= top + 100) {
      this.scrollbarFixed = true;
    } else {
      this.scrollbarFixed = false;
    }
  }

  displayDays(days) {
    return days === null ? '-' : days;
  }

  classGridScroll() {
    return this.openGrid ? 'uGrid__scroll--open' : 'uGrid__scroll';
  }

  classGridBodyGroupItems(fresh) {
    return fresh ? 'uGrid__bodyGroupItems--fresh' : 'uGrid__bodyGroupItems';
  }

  classGridBodyLine(item) {
    return item.expectedCalvingDate ? 'uGrid__bodyLine--dashed' : 'uGrid__bodyLine';
  }

  classGridBodyArrow(expected) {
    return expected ? 'uGrid__bodyArrow--dashed' : 'uGrid__bodyArrow';
  }

  classGridBodyDry(expected) {
    return expected ? 'uGrid__bodyDry--expected' : 'uGrid__bodyDry';
  }

  classGridBodyGroupItem(mate) {
    if (mate.waitingForPregnancyTest) {
      return 'uGrid__bodyGroupItem--waitingForPregnancyTest';
    } else if (mate.pregnant) {
      return 'uGrid__bodyGroupItem--pregnant';
    } else {
      return 'uGrid__bodyGroupItem';
    }
  }

  classGridScrollbar() {
    return this.scrollbarFixed ? 'uGrid__scrollbar--fixed' : 'uGrid__scrollbar';
  }

  classContentTabItem(active) {
    return this.activeTableData === active ? 'uContent__tabItem--active' : 'uContent__tabItem';
  }

  classTableRow(headings, item) {
    const index = headings.findIndex((heading) => {
      return heading.name === 'init';
    });

    if (item[index].grouping) {
      return 'uTable__row--grouping';
    }

    if (item[index].doublet) {
      return 'uTable__row--doublet';
    }

    return 'uTable__row';
  }

  classTableCellLabel(index) {
    switch (index) {
    case 0:
      return 'uTable__cellLabel--primary';
    case 1:
      return 'uTable__cellLabel--secondary';
    }
  }

  goToCowDetail(cowId) {
    this.$state.go('cowDetail', {
      cowId: cowId,
      caller: {
        state: 'cowDetail',
        displayTab: 'dataTab'
      },
    });
  }

  getColspan(headings) {
    return headings.filter((heading) => {
      return !heading.hidden;
    }).length;
  }
}

function detailReproductionComponent() {
  return {
    templateUrl: 'cow/detail/tabs/reproduction.html',
    controller: detailReproductionController,
    controllerAs: 'ctrl',
    bindings: {
      activeTab: '<',
      cowDetail: '<'
    }
  };
}

app.component('detailReproduction', detailReproductionComponent());
