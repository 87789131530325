// eslint-disable-next-line no-unused-vars
class CowEventValidator {
  static get ERROR_TYPE_EMPTY() {
    return 'empty';
  }

  static get ERROR_TYPE_INVALID() {
    return 'invalid';
  }

  static get ERROR_TYPE_INVALID_NUMBER() {
    return 'invalid_number';
  }

  static get ERROR_TYPE_INVALID_DATE() {
    return 'invalid_date';
  }

  static get ERROR_TYPE_DUPLICATED() {
    return 'duplicated';
  }

  static get ERROR_TYPE_DATE_RANGE() {
    return 'date_range';
  }

  static get ERROR_TYPE_BEFORE_CALVING_DATE() {
    return 'beforeCalvingDate';
  }

  static get ERROR_TYPE_INVALID_OCCURRED_DISEASE_DATE() {
    return 'invalidOccurredDiseaseDate';
  }

  static get ERROR_TYPE_INVALID_BREEDING_DATE() {
    return 'invalid_breeding_date';
  }

  static get ERROR_TYPE_UNMATCHED_GENDER() {
    return 'unmatched_gender';
  }

  static get ERROR_TYPE_INVALID_PLANNED_BRED_AT() {
    return 'invalidPlannedBredAt';
  }

  /**
   * 入力された日付を検査します。
   *
   * @param {Date|unixtime|string} date 入力された日付
   * @param {string} fieldName 入力対象の項目のID
   * @param {string} dateLabel エラーメッセージに日付項目のキャプションを表示する場合にセットします
   *   (ex. 治療日、作業日、種付日)
   * @return {Object} 検査結果
   *   ex. {valid: true or false, message: 'エラーメッセージ', type: 'empty' or 'dateRange'}
   */
  validateDate(date, fieldName, dateLabel = '') {
    if (!date) {
      let message = '日付が未入力です';
      if (dateLabel) {
        message = `${dateLabel}が未入力です`;
      }
      return {
        valid: false, message: message, field: fieldName,
        type: CowEventValidator.ERROR_TYPE_EMPTY
      };
    }

    if (!DateUtil.isValidDate(date)) {
      let message = '日付の書式が不正です';
      if (dateLabel) {
        message = `${dateLabel}の書式が不正です`;
      }
      return {
        valid: false, message: message, field: fieldName,
        type: CowEventValidator.ERROR_TYPE_INVALID_DATE
      };
    }

    if (!DateUtil.includedPermittedPeriod(date)) {
      let message = '日付が入力可能な範囲ではありません';
      if (dateLabel) {
        message = `${dateLabel}が入力可能な範囲ではありません`;
      }
      return {
        valid: false, message: message, field: fieldName,
        type: CowEventValidator.ERROR_TYPE_DATE_RANGE
      };
    }

    return {valid: true};
  }

  /**
   * イベント発生日が入力可能な日付かを検査します。
   *
   * @param {object} cowEvent イベントデータ
   * @param {string} dateLabel エラーメッセージに日付項目のキャプションを表示する場合にセットします
   *   (ex. 治療日、作業日、種付日)
   * @param {Date|unixtime} calvingDate 前産次の分娩日
   * @return {Object} 検査結果
   *   ex. {valid: true or false, message: 'エラーメッセージ', type: 'empty' or 'dateRange'}
   */
  validateOccurredAt(cowEvent, calvingDate, dateLabel = '') {
    const result = this.validateDate(cowEvent.occurredAt, 'occurredAt', dateLabel);
    if (!result.valid) {
      return result;
    }

    if (EventType.canEditAfterCalvedForLegacy(cowEvent.eventType)) {
      return {valid: true};
    }

    if (calvingDate) {
      if (DateUtil.isBeforeDay(cowEvent.occurredAt, calvingDate)) {
        let message = '発生日が分娩日より前の日付です';
        if (dateLabel) {
          message = `${dateLabel}が分娩日より前の日付です`;
        }
        return {
          valid: false, message: message, field: 'occurredAt',
          type: CowEventValidator.ERROR_TYPE_BEFORE_CALVING_DATE
        };
      }
    }

    return {valid: true};
  }

  validateOccurredDiseaseDate(cowEvent) {
    if (!cowEvent.occurredDiseaseDate) {
      return {
        valid: false, message: '必須項目です', field: 'occurredDiseaseDate',
        type: CowEventValidator.ERROR_TYPE_EMPTY
      };
    }

    const result = this.validateDate(cowEvent.occurredDiseaseDate, 'occurredDiseaseDate');
    if (!result.valid) {
      return result;
    }

    if (DateUtil.isBeforeDay(cowEvent.occurredAt, cowEvent.occurredDiseaseDate)) {
      return {
        valid: false, message: '発生日が治療日より後の日付です', field: 'occurredDiseaseDate',
        type: CowEventValidator.ERROR_TYPE_INVALID_OCCURRED_DISEASE_DATE
      };
    }

    return {valid: true};
  }

  /**
   * 発生日を除く入力内容のバリデーションを行います。
   *
   * @param {Object} cowEvent イベントデータ
   * @return {Array.<Object>} バリデーションエラーの配列
   */
  validateFields(cowEvent) {
    const errors = [];

    CollectionUtil.mergeList(
      errors,
      this.validateRequiredFields(cowEvent)
    );
    CollectionUtil.mergeList(
      errors,
      this.validateNumberFields(cowEvent)
    );

    return errors;
  }

  validateRequiredFields(cowEvent) {
    const fields = this.requiredFields(cowEvent.eventType);

    const errors = [];
    fields.forEach((f) => {
      if (!cowEvent[f]) {
        const error = {
          valid: false, message: '必須項目です', field: f,
          type: CowEventValidator.ERROR_TYPE_EMPTY
        };
        errors.push(error);
      }
    });

    switch (cowEvent.eventType) {
    case 'embryo_recovery': {
      const validateResults = this.validateEmbryoRecovery(cowEvent);
      validateResults.forEach((error) => errors.push(error));
    }
    }

    return errors;
  }

  /**
   * イベント種別毎の必須項目の一覧を返します。
   * ※cowNo、cowUidといったキー項目やoccurredAt、occurredDiseaseDateなどを除く
   *
   * @param {string} legacyEventType イベント種別
   * @return {Array.<string>} 対象項目の一覧
   */
  requiredFields(legacyEventType) {
    switch (legacyEventType) {
    case 'kannyu': {
      return ['selectedDryPeriod'];
    }
    case 'carcass': {
      return ['yieldGrade', 'meetGrade'];
    }
    default: {
      return [];
    }
    }
  }

  validateNumberFields(cowEvent) {
    const fields = this.numberFields(cowEvent.eventType);

    const errors = [];
    fields.forEach((f) => {
      const value = cowEvent[f];
      if (StringUtil.isEmpty(value)) return;

      if (!StringUtil.isNumeric(value)) {
        const error = {
          valid: false, message: '数値を入力してください', field: f,
          type: CowEventValidator.ERROR_TYPE_INVALID_NUMBER
        };
        errors.push(error);
      }
    });

    return errors;
  }

  /**
   * イベント種別毎の任意入力の数値項目の一覧を返します。
   *
   * @param {string} legacyEventType イベント種別
   * @return {Array.<string>} 対象項目の一覧
   */
  numberFields(legacyEventType) {
    switch (legacyEventType) {
    case 'carcass': {
      return [
        'slaughterNo',
        'beforeSlaughterWeight',
        'dressedCarcassWeightOfL',
        'dressedCarcassWeightOfR',
        'loinArea',
        'ribsThickness',
        'subcutaneousFat',
        'yieldBaseValue'
      ];
    }
    default: {
      return [];
    }
    }
  }

  validateEmbryoRecovery(cowEvent) {
    const errors = [];

    cowEvent.embryos.filter((embryo) => {
      const {count} = embryo;

      return count && (!StringUtil.isDigit(count) || Number(count) < 0);
    }).forEach((embryo) => {
      const {rank} = embryo;

      const error = {
        valid: false,
        message: '0以上の数値を入力して下さい',
        field: rank,
        type: CowEventValidator.ERROR_TYPE_INVALID
      };

      errors.push(error);
      return;
    });

    if (cowEvent.targetBreedingDate) {
      if (DateUtil.isSameOrBeforeDay(cowEvent.occurredAt, cowEvent.targetBreedingDate)) {
        const error = {
          valid: false, message: '採卵日より前にしてください', field: 'targetBreedingDate',
          type: CowEventValidator.ERROR_TYPE_INVALID_BREEDING_DATE
        };
        errors.push(error);
      }
    }

    return errors;
  }

  validatePlannedBredAt(cowEvent) {
    if (cowEvent.plannedBredAt) {
      const plannedBredAt = Number(cowEvent.plannedBredAt)
      const hour = Number(cowEvent.plannedBredAtHour);
      const minute = Number(cowEvent.plannedBredAtMinute);
      const inputPlannedBredAt = DateUtil.addTimeToDate(plannedBredAt, hour, minute);

      if (inputPlannedBredAt <= cowEvent.occurredAt) {
        return {
          valid: false,
          field: 'plannedBredAt',
          message: '種付予定日時は発情日時より後にしてください。',
          type: 'ERROR_TYPE_INVALID_PLANNED_BRED_AT'
        };
      }
    }

    return { valid: true };
  }
}
