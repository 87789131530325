app.directive('vimeo', function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      //Assumes that true means the video is playing
      controlBoolean: '='
    },
    template: '<iframe id="{{id}}" class="{{class}}" height="{{height}}" width="{{width}}" mozallowfullscreen webkitallowfullscreen allowfullscreen> {{text}} </iframe>',
    link: function postLink(scope, element, attrs) {
      const url = '//player.vimeo.com/video/' + attrs.vid + '?title=0&byline=0&portrait=0&api=1';
      element.attr('src', url);
    }
  };
});
