/**
 * タイムライングラフ API
 */
class PeriodicityService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  /**
   * 牛個体に対して一定期間の搾乳量をGET
   *
   * @deprecated 旧テーブルを参照している
   * @param {Object} params cowId, startDate, endDate
   */
  milkIndex(params) {
    return this.APIService.get(`/milk/periodicity/${params.cowId}?start_date=${params.startDate}&end_date=${params.endDate}`);
  }

  /**
   * 牛個体に対して一定期間の活動量をGET
   * @param {Object} params cowId, startDate, endDate, type = feed || lie || move || stand || rumination
   */
  activityIndex(params) {
    return this.APIService.get(`/activity/periodicity/${params.cowId}/${params.type}?start_date=${params.startDate}&end_date=${params.endDate}`);
  }
}

app.service('PeriodicityService', PeriodicityService);
