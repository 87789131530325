function WhenScrolled() {
  'ngInject';

  return {
    link: function(scope, element, attrs) {
      const scrollTopBuffer = 20;
      const raw = element[0];
      element.bind('scroll', () => {
        if (raw.scrollTop + raw.offsetHeight + scrollTopBuffer >= raw.scrollHeight) {
          scope.$apply(attrs.whenScrolled);
        }
      });
    }
  };
}

app.directive('whenScrolled', WhenScrolled);
