class TimelineAPI {
  constructor(
    APIService
  ) {
    'ngInject';
    this.APIService = APIService;
  }

  index(date) {
    const w3cDate = DateUtil.toW3CFormat(date);
    return this.APIService.get(`/timeline/${w3cDate}`);
  }
}

app.service('TimelineAPI', TimelineAPI);
