// eslint-disable-next-line no-unused-vars
class CustomlistInitializer {
  static initialize(ctrl, params) {
    ctrl.message = '';
    ctrl.valid = false;
    ctrl.showCowGroupCondition = true;

    ctrl.farmType = params.farmType;
    ctrl.isDepositor = params.isDepositor;
    ctrl.isDhiLinkage = params.isDhiLinkage;
    ctrl.useAlertCalving = params.useAlertCalving;
    ctrl.allowLegacyGrid = params.disableLegacyGrid ? false : true;
    ctrl.isBeefGenomLinkage = params.isBeefGenomLinkage;

    ctrl.states = {};
    State.values(ctrl.farmType).forEach((v) => {
      ctrl.states[v.id] = v.label;
    });

    // 項目の定義情報
    ctrl.definitions = [];
    ctrl.definitionMap = {};

    // テンプレートの定義
    ctrl.templates = [];
    ctrl.templateIndex = 0;

    // 設定の概要に関連するオブジェクト
    ctrl.master = {
      customlistId: params.customlistId,
      name: '',
      configType: 'user_defined',
      showEntryColumn: false,
      remarks: null,
      sortOrder: params.sortOrder,
      showInMenu: true,
      conditionType: 'standard',
      showDepositedCow: false,
      allowAgentToUse: false,
      useFixedColumnWidth: true,
      alertContent: 'none',
    };
    ctrl.useFixedColumnWidthExplanation = '列が画面内に収まりきらない場合は横スクロールバーが表示されます';

    // [表示項目] に関連するオブジェクト
    ctrl.items = [];
    ctrl.seletedItems = []; // 現在選択している項目のリスト
    ctrl.useItems = [];
    ctrl.seletedUseItems = []; // 現在選択している表示対象の項目のリスト
    ctrl.currentItem = null;
    ctrl.currentShortCaption = null;
    ctrl.currentDisplayStyle = null;

    // [日付書式] に関連するオブジェクト
    ctrl.dateStyles = [
      {dateStyle: 'yyyy-mm-dd-slash', label: '2016/06/01'},
      {dateStyle: 'yyyy-m-d-slash', label: '2016/6/1'},
      {dateStyle: 'yy-mm-dd-slash', label: '16/06/01'},
      {dateStyle: 'yy-m-d-slash', label: '16/6/1'},
      {dateStyle: 'yyyy-m-d-jp', label: '2016年6月1日'},
      {dateStyle: 'yy-m-d-jp', label: '16年6月1日'},
      {dateStyle: 'ggg-m-d-jp', label: '平成28年6月1日'},
      {dateStyle: 'gg-m-d-jp', label: '平28年6月1日'},
      {dateStyle: 'g-mm-dd-slash', label: 'H28/06/01'},
    ];

    ctrl.booleanStyleOptions = Customlist.BOOLEAN_STYLE_OPTIONS;

    // [項目分類] に関連するオブジェクト
    ctrl.groupTag = 'ALL';

    // [リスト並び順] に関連するオブジェクト
    ctrl.orderItems = [
      {value: 'cow_no', label: '牛番号'},
      {value: 'cow_group_name', label: '牛群'},
    ];
    ctrl.ordeTypes = [
      {value: 'ASC', label: '昇順'},
      {value: 'DESC', label: '降順'}
    ];
    ctrl.orderMaps = [{columnId: 'cow_no', sortType: 'ASC'}];

    // [記入欄] に関連するオブジェクト
    ctrl.describes = [
      {value: false, label: 'なし'},
      {value: true, label: 'あり'}
    ];

    CustomlistInitializer.setConditionItems(ctrl);
  }

  static setConditionItems(ctrl) {
    // [抽出条件] に関連するオブジェクト
    ctrl.conditions = [CustomlistInitializer.createCondition()];

    ctrl.conditionTypes = [
      {value: 'standard', label: '選択式'},
      {value: 'advanced', label: 'フリースタイル'},
    ];
    ctrl.standardType = true;

    ctrl.groupFilterConditions = [
      {value: 'include', label: '指定したものを対象'},
      {value: 'exclude', label: '指定したものを除く'},
    ];

    ctrl.valueCorrectTypes = [
      {value: 'previous', label: '日前'},
      {value: 'next', label: '日後'},
    ];

    const conditionFields = [
      {value: 'gebv_index', label: '育種価指数', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'cow_info_remarks', label: '牛状態備考', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'cow_no', label: '牛番号', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'carcass_weight_bv', label: '枝肉重量 育種価', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'mufa_ratio_bv', label: 'オレイン酸割合 育種価', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'latest_dry_late_period_start_date', label: '乾乳後期開始', farmType: 'MILK', groupTag: '繁殖  搾乳', explanation: ''},
      {value: 'latest_dry_previous_period_start_date', label: '乾乳前期開始', farmType: 'MILK', groupTag: '繁殖  搾乳', explanation: ''},
      {value: 'event_comment_dried', label: '乾乳のコメント', farmType: 'MILK', groupTag: 'イベント  繁殖  搾乳', explanation: ''},
      {value: 'expected_dry_date', label: '乾乳予定日', farmType: 'MILK', groupTag: '繁殖  搾乳', explanation: ''},
      {value: 'expected_dry_date_expected_days', label: '乾乳予定日までの日数', farmType: 'MILK', groupTag: '繁殖  搾乳', explanation: ''},
      {value: 'event_comment_moved', label: '牛群移動のコメント', farmType: 'ALL', groupTag: 'イベント', explanation: ''},
      {value: 'move_cow_group_date', label: '牛群移動日', farmType: 'ALL', groupTag: '個体情報', explanation: '最新の牛群移動を行った日'},
      {value: 'end_date_of_beef_washout_period', label: '休薬終了日(肉)', farmType: 'ALL', groupTag: '治療', explanation: ''},
      {value: 'end_date_of_milk_washout_period', label: '休薬終了日(乳)', farmType: 'MILK', groupTag: '治療  搾乳', explanation: ''},
      {value: 'donor_name', label: '供卵牛の名前', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'birthday_months', label: '月齢', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'monthly_milk_daily', label: '検定乳量', farmType: 'MILK', groupTag: '検定  搾乳', explanation: ''},
      {value: 'peak_milk_daily', label: '検定ピーク乳量', farmType: 'MILK', groupTag: '検定  搾乳', explanation: ''},
      {value: 'master_market_name', label: '購入先', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'cow_uid', label: '個体識別番号', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'cow_labels', label: '個体ラベル', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'event_comment_embryo_recovery', label: '採卵のコメント', farmType: 'BREEDING', groupTag: 'イベント  繁殖', explanation: ''},
      {value: 'embryo_recovery_date', label: '採卵日', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'latest_bull_name', label: '最新種雄牛名', farmType: 'BREEDING', groupTag: '繁殖', explanation: '最新の種付で使用した精液の種雄牛名'},
      {value: 'last_medicine_names', label: '最新投薬名', farmType: 'ALL', groupTag: '治療', explanation: ''},
      {value: 'introduce_date_months', label: '在籍月数', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'introduce_date_days', label: '在籍日数', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'days_of_pregnancy_bv', label: '在胎期間 育種価', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'event_comment_work_note', label: '作業メモのコメント', farmType: 'ALL', groupTag: 'イベント', explanation: ''},
      {value: 'work_note_type_comment', label: '作業メモのコメント(区分毎)', farmType: 'ALL', groupTag: 'イベント', explanation: ''},
      {value: 'work_note_type_date', label: '作業メモの日付(区分毎)', farmType: 'ALL', groupTag: 'イベント', explanation: ''},
      {value: 'work_note_type_date_days', label: '作業経過日数(区分毎)', farmType: 'ALL', groupTag: 'イベント', explanation: ''},
      {value: 'latest_hoof_trim_date_days', label: '削蹄後日数', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'latest_hoof_trim_date', label: '削蹄日', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'weekly_mean', label: '搾乳量平均(週)', farmType: 'MILK', groupTag: '搾乳', explanation: '直近1週間の1日あたりの平均搾乳量'},
      {value: 'birth_number', label: '産次', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'next_hormone_date', label: '次回処置日', farmType: 'BREEDING', groupTag: '繁殖', explanation: '次のホルモンプログラムの実施予定日'},
      {value: 'event_comment_culled', label: '出荷のコメント', farmType: 'ALL', groupTag: 'イベント', explanation: ''},
      {value: 'eliminate_date', label: '出荷日', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'latest_breeding_count', label: '授精回数', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'consecutive_bred_count', label: '授精回数(連)', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'latest_fertilization_date_days', label: '授精後日数', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'latest_fertilization_date', label: '授精日', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'latest_breeding_method', label: '授精方法', farmType: 'BREEDING', groupTag: '繁殖', explanation: '最新の種付の授精方法'},
      {value: 'embryo_certificate_no', label: '受精卵証明書番号', farmType: 'BREEDING', groupTag: '繁殖', explanation: '最新の種付で使用した受精卵の証明書番号'},
      {value: 'birthday', label: '出生日', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'bull_name', label: '種雄牛名', farmType: 'BREEDING', groupTag: '繁殖', explanation: '現産次で受胎した種付で使用した精液の種雄牛名'},
      {value: 'dehorned_date', label: '除角日', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'expelled_reason', label: '除籍理由', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'birth_weight_bv', label: '生時体重 育種価', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'gender', label: '性別', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'difference_previous_milk_daily', label: '前回検定乳量との差', farmType: 'MILK', groupTag: '検定  搾乳', explanation: ''},
      {value: 'ble_id', label: 'センサータグ', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'previous_day_amount', label: '前日搾乳量', farmType: 'MILK', groupTag: '搾乳', explanation: ''},
      {value: 'event_comment_others', label: 'その他のコメント', farmType: 'ALL', groupTag: 'イベント  治療', explanation: ''},
      {value: 'monthly_scc', label: '体細胞数', farmType: 'MILK', groupTag: '検定  搾乳', explanation: ''},
      {value: 'bred_work_date_days', label: '種付作業後日数', farmType: 'BREEDING', groupTag: 'イベント  繁殖', explanation: '種付イベントで入力した種付日の日付からの経過日数'},
      {value: 'bred_work_date', label: '種付作業日', farmType: 'BREEDING', groupTag: 'イベント  繁殖', explanation: '種付イベントで入力した種付日'},
      {value: 'event_comment_bred', label: '種付のコメント', farmType: 'BREEDING', groupTag: 'イベント  繁殖', explanation: ''},
      {value: 'planned_bred_date_days', label: '種付予定経過日数', farmType: 'BREEDING', groupTag: 'イベント  繁殖', explanation: '発情イベントで入力した種付予定の日付からの経過日数'},
      {value: 'planned_bred_date', label: '種付予定日', farmType: 'BREEDING', groupTag: 'イベント  繁殖', explanation: '発情イベントで入力した種付予定の日付'},
      {value: 'latest_treatment_date_days', label: '治療後日数', farmType: 'ALL', groupTag: '治療', explanation: ''},
      {value: 'latest_treatment_date', label: '治療日', farmType: 'ALL', groupTag: '治療', explanation: ''},
      {value: 'occurred_lame_date', label: '蹄病発生日', farmType: 'ALL', groupTag: '治療', explanation: ''},
      {value: 'introduce_date', label: '導入日', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'medication_summary', label: '投薬履歴(すべて)', farmType: 'ALL', groupTag: '治療', explanation: ''},
      {value: 'treatment_summary', label: '投薬履歴(ワクチン以外)', farmType: 'ALL', groupTag: '治療', explanation: ''},
      {value: 'cow_name', label: '名前', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'birthday_days', label: '日齢', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'carcass_weight_pda_bv', label: '日齢枝肉重量 育種価', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'monthly_fat_percent', label: '乳脂率', farmType: 'MILK', groupTag: '検定  搾乳', explanation: ''},
      {value: 'monthly_prot_percent', label: '乳蛋白質率', farmType: 'MILK', groupTag: '検定  搾乳', explanation: ''},
      {value: 'event_comment_mastitis', label: '乳房炎のコメント', farmType: 'MILK', groupTag: 'イベント  搾乳', explanation: ''},
      {value: 'pregnancy', label: '妊娠鑑定結果', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'latest_judge_pregnant_date_days', label: '妊娠鑑定後日数', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'judge_pregnant_timing', label: '妊娠鑑定タイミング', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'event_comment_pregnant_diagnosis', label: '妊娠鑑定のコメント', farmType: 'BREEDING', groupTag: 'イベント  繁殖', explanation: ''},
      {value: 'latest_judge_pregnant_date', label: '妊娠鑑定日', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'latest_pregnancy_date_days', label: '妊娠日数', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'latest_pregnancy_date', label: '妊娠日', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'event_comment_claw_diseases', label: '跛行・蹄病のコメント', farmType: 'ALL', groupTag: 'イベント  治療', explanation: ''},
      {value: 'event_comment_heat', label: '発情のコメント', farmType: 'BREEDING', groupTag: 'イベント  繁殖', explanation: ''},
      {value: 'latest_estrus_date', label: '発情日', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'mother_birth_number', label: '母牛の産次数', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'ribs_thickness_bv', label: 'バラの厚さ 育種価', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'event_comment_do_not_breed', label: '繁殖除外のコメント', farmType: 'BREEDING', groupTag: 'イベント  繁殖', explanation: ''},
      {value: 'breeding_exclude_date', label: '繁殖除外日', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'start_fattening_date', label: '肥育開始日', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'start_fattening_date_days', label: '肥育日数', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'event_comment_fattened', label: '肥育のコメント', farmType: 'ALL', groupTag: 'イベント  肥育', explanation: ''},
      {value: 'subcutaneous_fat_thickness_bv', label: '皮下脂肪厚 育種価', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'breed', label: '品種', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'yield_standard_value_bv', label: '歩留基準値 育種価', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'event_comment_fresh_check', label: 'フレッシュチェックのコメント', farmType: 'BREEDING', groupTag: 'イベント  繁殖', explanation: ''},
      {value: 'latest_calving_date_days', label: '分娩後日数', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'calving_sensor_number', label: '分娩センサー番号', farmType: 'BREEDING', groupTag: '個体情報  繁殖', explanation: ''},
      {value: 'event_comment_calved', label: '分娩のコメント', farmType: 'BREEDING', groupTag: 'イベント  繁殖', explanation: ''},
      {value: 'latest_calving_date', label: '分娩日', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'expected_calving_date', label: '分娩予定日', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'expected_calving_date_expected_days', label: '分娩予定日までの日数', farmType: 'BREEDING', groupTag: '繁殖', explanation: ''},
      {value: 'event_comment_died', label: 'へい死のコメント', farmType: 'ALL', groupTag: 'イベント', explanation: ''},
      {value: 'falling_dead_date', label: 'へい死日', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'death_reason', label: 'へい死理由', farmType: 'ALL', groupTag: '個体情報', explanation: ''},
      {value: 'event_comment_timed_ai', label: 'ホルモンプログラムのコメント', farmType: 'BREEDING', groupTag: 'イベント  繁殖', explanation: ''},
      {value: 'monthly_linear_score', label: 'リニアスコア', farmType: 'MILK', groupTag: '検定  搾乳', explanation: ''},
      {value: 'alert_linear_score_count', label: 'リニアスコア5以上の回数', farmType: 'MILK', groupTag: '検定  搾乳', explanation: ''},
      {value: 'event_comment_aborted', label: '流産のコメント', farmType: 'BREEDING', groupTag: 'イベント  繁殖', explanation: ''},
      {value: 'rib_eye_area_bv', label: 'ロース芯面積 育種価', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'vaccine_timing', label: 'ワクチンタイミング', farmType: 'ALL', groupTag: 'ワクチン', explanation: ''},
      {value: 'vaccine_summary', label: 'ワクチン投薬履歴', farmType: 'ALL', groupTag: 'ワクチン', explanation: ''},
      {value: 'event_comment_vaccination', label: 'ワクチンのコメント', farmType: 'ALL', groupTag: 'イベント  ワクチン', explanation: ''},
      {value: 'monthly_milk_305em', label: '305日補正乳量', farmType: 'MILK', groupTag: '検定  搾乳', explanation: ''},
      {value: 'bms_bv', label: 'BMS No. 育種価', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'oleic_acid_ratio_bv', label: 'MUFA割合 育種価', farmType: 'BEEF', groupTag: '肥育', explanation: ''},
      {value: 'monthly_mun', label: 'MUN', farmType: 'MILK', groupTag: '検定  搾乳', explanation: ''},
    ];

    let filteredConditionFields = conditionFields.filter((field) => {
      switch (field.farmType) {
      case 'ALL':
        return true;
      case 'MILK':
        return ctrl.isDairy;
      case 'BEEF':
        return ctrl.isFattening;
      case 'BREEDING':
        return ctrl.isBreeding || ctrl.isDairy;
      }
    });

    if (!ctrl.useAlertCalving) {
      const index = filteredConditionFields.findIndex((f) => f.value === 'calving_sensor_number');
      filteredConditionFields.splice(index, 1);
    }

    if (!ctrl.isDhiLinkage) {
      const dhiFields = [
        'monthly_milk_daily', 'monthly_scc', 'monthly_linear_score', 'monthly_fat_percent', 'monthly_prot_percent',
        'monthly_mun', 'monthly_milk_305em', 'peak_milk_daily', 'alert_linear_score_count', 'difference_previous_milk_daily'
      ];
      filteredConditionFields = filteredConditionFields.filter((field) => !dhiFields.includes(field.value));
    }

    if (!ctrl.isBeefGenomLinkage) {
      const genomicBreedingValues = [
        'gebv_index', 'carcass_weight_bv', 'rib_eye_area_bv', 'ribs_thickness_bv', 'subcutaneous_fat_thickness_bv',
        'yield_standard_value_bv', 'bms_bv', 'mufa_ratio_bv', 'oleic_acid_ratio_bv', 'birth_weight_bv', 'carcass_weight_pda_bv', 'days_of_pregnancy_bv'
      ];

      filteredConditionFields = filteredConditionFields.filter((field) => !genomicBreedingValues.includes(field.value));
    }

    ctrl.conditionFields = filteredConditionFields;

    ctrl.conditionOperatorDefs = [
      // DIFF_DAYS
      {value: 'more_than_or_equal', label: '以上', fieldType: 'DIFF_DAYS'},
      {value: 'less_than_or_equal', label: '以下', fieldType: 'DIFF_DAYS'},
      {value: 'more_than', label: '超える', fieldType: 'DIFF_DAYS'},
      {value: 'less_than', label: '未満', fieldType: 'DIFF_DAYS'},
      {value: 'equal', label: '等しい', fieldType: 'DIFF_DAYS'},
      {value: 'between', label: '指定範囲内', fieldType: 'DIFF_DAYS'},
      // LAPSED_DAYS
      {value: 'more_than_or_equal', label: '以上', fieldType: 'LAPSED_DAYS'},
      {value: 'less_than_or_equal', label: '以下', fieldType: 'LAPSED_DAYS'},
      {value: 'more_than', label: '超える', fieldType: 'LAPSED_DAYS'},
      {value: 'less_than', label: '未満', fieldType: 'LAPSED_DAYS'},
      {value: 'equal', label: '等しい', fieldType: 'LAPSED_DAYS'},
      {value: 'between', label: '指定範囲内', fieldType: 'LAPSED_DAYS'},
      // LAPSED_MONTHS
      {value: 'more_than_or_equal', label: '以上', fieldType: 'LAPSED_MONTHS'},
      {value: 'less_than_or_equal', label: '以下', fieldType: 'LAPSED_MONTHS'},
      {value: 'more_than', label: '超える', fieldType: 'LAPSED_MONTHS'},
      {value: 'less_than', label: '未満', fieldType: 'LAPSED_MONTHS'},
      {value: 'equal', label: '等しい', fieldType: 'LAPSED_MONTHS'},
      {value: 'between', label: '指定範囲内', fieldType: 'LAPSED_MONTHS'},
      // EXPECTED_DAYS
      {value: 'more_than_or_equal', label: '以上', fieldType: 'EXPECTED_DAYS'},
      {value: 'less_than_or_equal', label: '以下', fieldType: 'EXPECTED_DAYS'},
      {value: 'more_than', label: '超える', fieldType: 'EXPECTED_DAYS'},
      {value: 'less_than', label: '未満', fieldType: 'EXPECTED_DAYS'},
      {value: 'equal', label: '等しい', fieldType: 'EXPECTED_DAYS'},
      {value: 'between', label: '指定範囲内', fieldType: 'EXPECTED_DAYS'},
      // NUMBER
      {value: 'more_than_or_equal', label: '以上', fieldType: 'NUMBER'},
      {value: 'less_than_or_equal', label: '以下', fieldType: 'NUMBER'},
      {value: 'more_than', label: '超える', fieldType: 'NUMBER'},
      {value: 'less_than', label: '未満', fieldType: 'NUMBER'},
      {value: 'equal', label: '等しい', fieldType: 'NUMBER'},
      {value: 'between', label: '指定範囲内', fieldType: 'NUMBER'},
      // NULLABLE_NUMBER
      {value: 'more_than_or_equal', label: '以上', fieldType: 'NULLABLE_NUMBER'},
      {value: 'less_than_or_equal', label: '以下', fieldType: 'NULLABLE_NUMBER'},
      {value: 'more_than', label: '超える', fieldType: 'NULLABLE_NUMBER'},
      {value: 'less_than', label: '未満', fieldType: 'NULLABLE_NUMBER'},
      {value: 'equal', label: '等しい', fieldType: 'NULLABLE_NUMBER'},
      {value: 'between', label: '指定範囲内', fieldType: 'NULLABLE_NUMBER'},
      {value: 'nullable_less_than_or_equal', label: '以下 (値がない場合も含む)', fieldType: 'NULLABLE_NUMBER'},
      {value: 'nullable_less_than', label: '未満 (値がない場合も含む)', fieldType: 'NULLABLE_NUMBER'},
      // STRING
      {value: 'include', label: '含む', fieldType: 'STRING'},
      {value: 'exclude', label: '含まない', fieldType: 'STRING'},
      {value: 'equal', label: '等しい', fieldType: 'STRING'},
      {value: 'present', label: '入力済', fieldType: 'STRING'},
      {value: 'blank', label: '未入力', fieldType: 'STRING'},
      // DATE
      {value: 'before', label: 'より以前', fieldType: 'DATE'},
      {value: 'after', label: 'より以後', fieldType: 'DATE'},
      {value: 'between', label: '期間内', fieldType: 'DATE'},
      {value: 'present', label: '入力済', fieldType: 'DATE'},
      {value: 'blank', label: '未入力', fieldType: 'DATE'},
      {value: 'last_recent_days', label: '直近のX日以内', fieldType: 'DATE'},
      {value: 'last_recent_months', label: '直近のXヶ月以内', fieldType: 'DATE'},
      {value: 'days_ago', label: 'X日以上前', fieldType: 'DATE'},
      {value: 'months_ago', label: 'Xヶ月以上前', fieldType: 'DATE'},
      {value: 'current_month', label: '当月', fieldType: 'DATE'},
      {value: 'last_month', label: '前月', fieldType: 'DATE'},
      {value: 'current_year', label: '今年', fieldType: 'DATE'},
      {value: 'fiscal_year', label: '今年度', fieldType: 'DATE'},
      // DATETIME
      {value: 'before', label: 'より以前', fieldType: 'DATETIME'},
      {value: 'after', label: 'より以後', fieldType: 'DATETIME'},
      {value: 'between', label: '期間内', fieldType: 'DATETIME'},
      {value: 'present', label: '入力済', fieldType: 'DATETIME'},
      {value: 'blank', label: '未入力', fieldType: 'DATETIME'},
      {value: 'last_recent_days', label: '直近のX日以内', fieldType: 'DATETIME'},
      {value: 'last_recent_months', label: '直近のXヶ月以内', fieldType: 'DATETIME'},
      {value: 'days_ago', label: 'X日以上前', fieldType: 'DATETIME'},
      {value: 'months_ago', label: 'Xヶ月以上前', fieldType: 'DATETIME'},
      {value: 'current_month', label: '当月', fieldType: 'DATETIME'},
      {value: 'last_month', label: '前月', fieldType: 'DATETIME'},
      {value: 'current_year', label: '今年', fieldType: 'DATETIME'},
      {value: 'fiscal_year', label: '今年度', fieldType: 'DATETIME'},
      // BLE_ID
      {value: 'present', label: '装着済', fieldType: 'BLE_ID'},
      {value: 'blank', label: '未装着(解除含む)', fieldType: 'BLE_ID'},
      // SELECTION
      {value: 'include', label: '指定したものを対象', fieldType: 'SELECTION'},
      {value: 'exclude', label: '指定したものを除く', fieldType: 'SELECTION'},
      // MASTER
      {value: 'include', label: '指定したものを対象', fieldType: 'MASTER'},
      {value: 'exclude', label: '指定したものを除く', fieldType: 'MASTER'},
    ];

    if (ctrl.useAlertCalving) {
      // CALVING_SENSOR
      ctrl.conditionOperatorDefs.push({value: 'present', label: '装着済', fieldType: 'CALVING_SENSOR'});
      ctrl.conditionOperatorDefs.push({value: 'blank', label: '未装着(解除含む)', fieldType: 'CALVING_SENSOR'});
    }
  }

  static createCondition() {
    return {
      inputPhrase: '',
      matchedComment: '',
      reactionEventType: '',
      cowGroupCondition: '',
      cowGroupConditionOperator: 'include',
      cowGroupConditionLabel: '(全ての牛群が対象になります)',
      stateCondition: '',
      stateConditionOperator: 'include',
      stateConditionLabel: '(全ての状態が対象になります)',
      excludeNonActive: true,
      showCalf: false,
      alertOnly: false,
      conditionDetails: [],
    };
  }

  static loadAndSetConfig(ctrl, $q, CowGroupService, MasterMarketAPI, MasterWorkNoteTypeAPI) {
    const requests = [
      CowGroupService.index(),
      ctrl.CustomlistAPI.definition(),
      ctrl.CustomlistAPI.template(),
      MasterMarketAPI.available(),
      MasterWorkNoteTypeAPI.available()
    ];
    if (ctrl.master.customlistId) {
      requests.push(ctrl.CustomlistAPI.show(ctrl.master.customlistId));
    }

    return $q.all(requests).then((res) => {
      const groups = res[0].data;
      const definitions = res[1].data.map((json) => new CustomlistColumnDefinition(json));
      const templates = res[2].data;
      const markets = CollectionUtil.selectableMasters(res[3].data);

      const workNoteTypes = CollectionUtil.selectableMasters(res[4].data);
      if (workNoteTypes.length) {
        ctrl.workNoteTypes = workNoteTypes.map((m) => {
          return {key: m.id, label: m.name};
        });
        const initialSelected = workNoteTypes.find((m) => m.initialSelected);
        if (initialSelected) {
          ctrl.currentMasterWorkNoteTypeId = initialSelected.id;
        } else {
          ctrl.currentMasterWorkNoteTypeId = workNoteTypes[0].id;
        }
      } else {
        ctrl.conditionFields = ctrl.conditionFields.filter((field) => {
          return !field.value.startsWith('work_note_type_');
        });
      }

      const config = ctrl.master.customlistId ? res[5].data : null;
      const master = config ? config.master : ctrl.master;

      CustomlistInitializer.setCowGroups(ctrl, groups);
      CustomlistInitializer.setDefinitions(ctrl, definitions, master);
      CustomlistInitializer.setTemplates(ctrl, templates);
      CustomlistInitializer.setMarkets(ctrl, markets);
      CustomlistInitializer.setGroupTags(ctrl, definitions);

      if (config) {
        CustomlistInitializer.setConfig(ctrl, config);
      }

      // アラート情報の表示
      const alertContentsDefinitions = [
        {key: 'none', label: '表示しない', farmType: 'ALL'},
        {key: 'heat', label: '発情傾向', farmType: 'BREEDING'},
        {key: 'bad_condition', label: '注意牛', farmType: 'ALL'},
      ];

      ctrl.alertContents = alertContentsDefinitions.filter((definition) => {
        switch (definition.farmType) {
        case 'ALL':
          return true;
        case 'BREEDING':
          if (ctrl.isBreeding || ctrl.isDairy) {
            return master.configType !== 'fattening_stage';
          }
        }
      });
      });
  }

  static setCowGroups(ctrl, groups) {
    const cowGroups = {};
    groups.forEach((group) => {
      cowGroups[group.cowGroupId] = group.cowGroupName;
    });
    ctrl.cowGroups = cowGroups;
  }

  static setMarkets(ctrl, markets) {
    ctrl.markets = markets.reduce((o, m) => {
      o[m.id] = m;
      return o;
    }, {});
  }

  static setDefinitions(ctrl, definitions, master) {
    ctrl.definitions = definitions;
    const items = ctrl.definitions.map((definition) => ctrl.definitionToItem(definition));
    ctrl.items = items;
    ctrl.refreshItems();

    // column_idでの検索を高速にするために連想配列を生成しておく
    ctrl.definitions.forEach((definition) => {
      ctrl.definitionMap[definition.columnId] = definition;
    });
  }

  static setTemplates(ctrl, templates) {
    templates.forEach((template, index) => template.index = index);
    ctrl.templates = templates;
    ctrl.templateIndex = 0;
  }

  static setConfig(ctrl, config) {
    ctrl.master = config.master;

    const includeAlerts = config.master.includeAlerts || [];
    if (includeAlerts.length) {
      ctrl.master.alertContent = includeAlerts[0];
    } else {
      ctrl.master.alertContent = 'none';
    }

    // 強制的にchangeイベントを実行して表示を制御する
    ctrl.onChangeUseFixedColumnWidth();
    ctrl.onChangeConditionType();
    if (ctrl.master.showDepositedCow) {
      ctrl.onChangeShowDepositedCow();
    } else if (ctrl.master.allowAgentToUse) {
      ctrl.onChangeDepositItem();
    }

    const tempItems = config.columns.map((column) => {
      const definition = ctrl.definitionMap[column.columnId];
      return {
        label: definition.caption,
        value: definition.columnId,
        shortCaption: column.shortCaption,
        fieldType: definition.fieldType,
        displayStyle: column.displayStyle,
        options: column.options || null,
      };
    });
    ctrl.useItems = tempItems;

    if (tempItems.length) {
      const lastIndex = ctrl.useItems.length - 1;
      ctrl.seletedUseItems = [ctrl.useItems[lastIndex]];
    }
    ctrl.refreshItems();
    ctrl.refreshOrderItems();

    const tempConditions = config.conditions.map((condition) => {
      condition.conditionDetails.forEach((conditionDetail) => {
        const field = ctrl.conditionFields.find((field) => field.value === conditionDetail.columnId);
        conditionDetail.label = field.label;
      });

      const value = {
        inputPhrase: condition.inputPhrase,
        matchedComment: condition.matchedComment,
        reactionEventType: condition.reactionEventType,
        cowGroupCondition: condition.cowGroupCondition,
        cowGroupConditionOperator: condition.cowGroupConditionOperator,
        stateCondition: condition.stateCondition,
        stateConditionOperator: condition.stateConditionOperator,
        excludeNonActive: condition.excludeNonActive,
        showCalf: condition.showCalf,
        alertOnly: condition.alertOnly,
        conditionDetails: condition.conditionDetails,
      };

      if (condition.cowGroupCondition) {
        const cowGroupIds = condition.cowGroupCondition.split(',').map((s) => Number(s));
        value['cowGroupConditionLabel'] = cowGroupIds.map((cowGroupId) => ctrl.cowGroups[cowGroupId]).join('、');
      } else {
        value['cowGroupConditionLabel'] = '(全ての牛群が対象になります)';
      }

      if (condition.stateCondition) {
        const stateIds = condition.stateCondition.split(',');
        value['stateConditionLabel'] = stateIds.map((id) => ctrl.states[id]).join('、');
      } else {
        value['stateConditionLabel'] = '(全ての状態が対象になります)';
      }

      return value;
    });
    ctrl.conditions = tempConditions;

    // 強制的にchangeイベントを実行して表示を制御する
    ctrl.conditions.forEach((condition) => {
      condition.conditionDetails.forEach((detail) => {
        ctrl.setConditionOperator(detail);
        ctrl.onChangeConditonDetail(detail);
        ctrl.onChangeConditionOperator(detail);
      });
    });

    const tempSorts = config.sorts.map((sort) => {
      return {
        columnId: sort.columnId,
        sortType: sort.sortType
      };
    });
    ctrl.orderMaps = tempSorts;
  }

  static generateOrderItems(definitions, master, selectedItems) {
    const selectedColumnIds = selectedItems.map((item) => item.value);
    const orderItems = definitions
      .filter((d) => {
        return selectedColumnIds.includes(d.columnId)
          && d.sortKeyAvailable
          && d.isAvailable(master);
      })
      .map((d) => d.toOrderItem());

    if (orderItems.length === 0) {
      const cowNoItem = definitions.find((d) => d.columnId === 'cow_no');
      orderItems.push(cowNoItem.toOrderItem());
      const cowGroupItem = definitions.find((d) => d.columnId === 'cow_group_name');
      orderItems.push(cowGroupItem.toOrderItem());
    }
    return orderItems;
  }

  static setGroupTags(ctrl, definitions) {
    ctrl.groupTags = Customlist.groupTags(definitions);
  }
}
