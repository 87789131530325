function MasterMenuDirective() {
  'ngInject';
  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'menu/master/menu.html',
    controller: 'MasterMenuController',
    controllerAs: 'menuCtl',
    bindToController: {
      menu: '=',
    }
  };
}

app.directive('masterMenu', MasterMenuDirective);
