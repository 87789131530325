// eslint-disable-next-line no-unused-vars
class GroupTransferHistory {
  static get COLUMNS() {
    return [{
      columnId: 'selected',
      name: '選択',
      width: 50,
      enableExport: false
    }, {
      columnId: 'transferDate',
      name: '異動日',
      width: 100,
      enableSorting: true,
      enableExport: true
    }, {
      columnId: 'cowNo',
      name: '牛番号',
      width: 100,
      enableSorting: true,
      enableExport: true
    }, {
      columnId: 'cowUid',
      name: '個体識別番号',
      width: 130,
      enableSorting: true,
      enableExport: true
    }, {
      columnId: 'introduceDate',
      name: '導入日',
      width: 90,
      enableSorting: true,
      enableExport: true
    }, {
      columnId: 'fromFarmName',
      name: '異動元',
      width: 130,
      enableSorting: true,
      enableExport: true
    }, {
      columnId: 'toFarmName',
      name: '異動先',
      width: 130,
      enableSorting: true,
      enableExport: true
    }, {
      columnId: 'transferTypeLabel',
      name: '異動理由',
      width: 130,
      enableSorting: true,
      enableExport: true
    }, {
      columnId: 'operator',
      name: '異動担当者',
      width: 120,
      enableSorting: true,
      enableExport: true
    }, {
      columnId: 'changedCowNo',
      name: '牛番号変更',
      width: 120,
      enableSorting: true,
      enableExport: true
    }, {
      columnId: 'removeSensorLabel',
      name: 'センサー情報',
      width: 150,
      enableSorting: true,
      enableExport: true
    }, {
      columnId: 'notes',
      name: 'コメント',
      width: 150,
      enableSorting: true,
      enableExport: true
    }, {
      columnId: 'operation',
      name: '操作',
      width: 60,
      enableSorting: false,
      enableExport: false
    }];
  }

  static get REQUIRED_FIELDS() {
    return [{
      value: 'toId',
      label: '異動先牧場'
    }, {
      value: 'transferType',
      label: '異動理由'
    }, {
      value: 'cowGroupId',
      label: '牛群'
    }];
  }

  static filterColumns(conditions) {
    return GroupTransferHistory.COLUMNS.filter((column) => {
      if (!conditions) {
        return true;
      }

      for (let key in conditions) {
        if (conditions.hasOwnProperty(key)) {
          if (column[key] !== conditions[key]) {
            return false;
          }
        }
      }

      return true;
    });
  }

  static names(columns) {
    const names = columns.map((column) => {
      return column.name;
    });

    return [names];
  }

  static rows(columns, histories) {
    return histories.map((cow) => {
      return columns.map((column) => {
        return cow[column.columnId];
      });
    });
  }

  static setHistoryCondition(historyCondition) {
    const params = {
      fromTransferDate: historyCondition.fromTransferDate,
      toTransferDate: historyCondition.toTransferDate,
      cowCondition: {
        type: historyCondition.cowCondition.type,
        value: historyCondition.cowCondition.value,
        matchingPattern: historyCondition.cowCondition.matchingPatternComplete ? 'complete' : 'fuzzy',
      },
      fromId: historyCondition.fromId,
      toId: historyCondition.toId,
      transferType: historyCondition.transferType
    };

    return Object.entries(params).filter(([key, value]) => {
      if (key === 'cowCondition') {
        return value.value;
      }

      return value;
    }).reduce((acc, [key, value]) => {
      acc[key] = value;

      return acc;
    }, {});
  }

  static setUpdateParams(histories) {
    const history = histories[0];

    return {
      fromId: history.fromId,
      toId: history.input.toId,
      transferDate: history.input.transferDate,
      transferType: history.input.transferType,
      operator: history.input.operator,
      cows: [{
        transferInputId: history.transferInputId,
        cowId: history.cowId,
        cowGroupId: history.input.cowGroupId,
        pen: history.input.pen,
        cowNo: history.input.cowNo,
        removeSensor: history.input.removeSensor,
        notes: history.input.notes
      }]
    };
  }

  static setUpdateHistoriesParams(params) {
    const selectedHistories = params.filter((cow) => {
      return cow.selected;
    });

    const histories = selectedHistories.map((cow) => {
      return {
        transferInputId: cow.transferInputId,
        removeSensorEditable: cow.removeSensorEditable,
        cow: {
          cowId: cow.cowId,
          cowUid: cow.origin.cowUid,
          bleId: cow.bleId,
          introduceDate: cow.origin.introduceDate,
        },
        input: {
          cowNo: cow.fromCowNo,
          cowUid: cow.origin.cowUid,
          toCowGroupId: cow.input.cowGroupId,
          toPen: cow.input.pen,
          toCowNo: cow.input.cowNo,
          removeSensor: cow.input.removeSensor,
          notes: cow.input.notes
        }
      };
    });

    const fromId = selectedHistories[0].fromId;
    const toId = selectedHistories[0].toId;
    const transferDate = selectedHistories[0].origin.transferDate;
    const transferType = selectedHistories[0].transferType;
    const operator = selectedHistories[0].operator;
    const caller = {
      state: 'group-transfer-history'
    };

    return {
      histories,
      fromId,
      toId,
      transferDate,
      transferType,
      operator,
      caller
    };
  }

  static updateCow(histories, transferTypes) {
    return histories.map((cow) => {
      return {
        origin: angular.copy(cow),
        transferInputId: cow.transferInputId,
        cowId: cow.cowId,
        transferDate: DateUtil.toYYYYMMDD(cow.transferDate),
        fromCowNo: cow.previous.cowNo,
        cowNo: cow.cowNo,
        toCowNo: cow.input.cowNo,
        cowUid: Cow.formatCowUid(cow.cowUid),
        introduceDate: DateUtil.toYYYYMMDD(cow.introduceDate),
        fromId: cow.previous.id,
        fromFarmName: cow.previous.farmName,
        toId: cow.input.id,
        toFarmName: cow.input.farmName,
        transferType: cow.transferType,
        transferTypeLabel: GroupTransferHistory.decideTransferTypeLabel(transferTypes, cow.transferType),
        operator: cow.operator,
        changedCowNo: GroupTransferHistory.formatChangeCowNo(cow),
        removeSensorEditable: GroupTransferHistory.decideRemoveSensorEditable(cow),
        bleId: cow.bleId,
        removeSensor: cow.input.removeSensor,
        removeSensorLabel: GroupTransferHistory.formatRemoveSensorLabel(cow),
        notes: cow.input.notes,
        editable: cow.editable,
        input: {
          transferDate: cow.transferDate,
          toId: cow.input.id,
          transferType: cow.transferType,
          operator: cow.operator,
          cowGroupId: cow.input.cowGroupId,
          pen: cow.input.pen,
          cowNo: cow.input.cowNo,
          removeSensor: cow.input.removeSensor,
          notes: cow.input.notes
        }
      };
    });
  }

  static decideFarmName(memberFarms, id) {
    const farm = memberFarms.find((farm) => farm.id === id) || [];

    return farm.farmName;
  }

  static decideTransferTypeLabel(transferTypes, typeValue) {
    const transferType = transferTypes.find((type) => type.value === typeValue) || [];

    return transferType.label;
  }

  static decideRemoveSensorEditable(cow) {
    if (cow.input.removeSensor &&
      cow.bleId === cow.input.removedSensorNumber &&
      !DateUtil.isInfiniteDate(cow.bleIdEndDate)) {
      return true;
    }

    if (!cow.input.removeSensor &&
      cow.bleId &&
      DateUtil.isInfiniteDate(cow.bleIdEndDate)) {
      return true;
    }

    return false;
  }

  static formatChangeCowNo(cow) {
    if (!cow.input.cowNo) return null;

    return `${cow.previous.cowNo}→${cow.input.cowNo}`;
  }

  static formatRemoveSensorLabel(cow) {
    if (!cow.input.removeSensor) return null;

    const removedSensorNumber = cow.input.removedSensorNumber || '';

    return `${removedSensorNumber} 解除`;
  }
}
