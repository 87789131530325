/**
 * HTMLタグをdrag可能にする
 * 使い方: <div draggable></div>
 */
class DraggableController {
  constructor(
    $element,
    $document,
    $interval
  ) {
    'ngInject';

    // DOM描画完了まで少々お待ち
    $interval.cancel(waitForDomRender);
    const waitForDomRender = $interval(() => {
      const elem = $element[0];
      if (!elem) return;
      const domRect = elem.getBoundingClientRect();
      const x = domRect.x || domRect.left;
      const y = domRect.y || domRect.top;

      // 初期位置を設定、この位置に対して移動する
      this.setOffsetPosition(x, y);

      // ループ終了
      $interval.cancel(waitForDomRender);
    });

    this.setCursorPosition(0, 0);

    this.$element = $element;
    this.$document = $document;

    // クリック押下時ドラグ開始
    $element.on('mousedown', (event) => this.beginDrag(event));
  }

  /**
   * ドラグ開始、マウス位置観察開始
   * @param {Object} event
   */
  beginDrag(event) {
    event.preventDefault();

    const x = event.pageX - this.offsetPosition.x;
    const y = event.pageY - this.offsetPosition.y;
    this.setCursorPosition(x, y);

    this.mouseMoveEventListener = this.$document.on('mousemove.draggable', (e) => this.drag(e));
    this.mouseUpEventListener = this.$document.on('mouseup.draggable', (e) => this.endDrag());
  }

  /**
   * ドラグ動作、マウスカーソルを追従するように
   * @param {Object} event
   */
  drag(event) {
    const x = event.pageX - this.cursorPosition.x;
    const y = event.pageY - this.cursorPosition.y;

    this.setOffsetPosition(x, y);

    this.$element.css({
      left: `${x}px`,
      top: `${y}px`
    });
  }

  /**
   * マウス動作観察停止
   */
  endDrag() {
    this.mouseMoveEventListener.off('mousemove.draggable');
    this.mouseUpEventListener.off('mouseup.draggable');
  }

  /**
   * 移動距離の比較対象ようにマウスカーソルの現在位置を記憶
   * @param {number} x
   * @param {number} y
   */
  setCursorPosition(x, y) {
    this.cursorPosition = {
      x: x,
      y: y
    };
  }

  /**
   * 移動したいものを位置を指定
   * @param {number} x
   * @param {number} y
   */
  setOffsetPosition(x, y) {
    this.offsetPosition = {
      x: x,
      y: y
    };
  }
}

function draggableDirective() {
  return {
    restrict: 'A',
    controller: DraggableController,
  };
}

app.directive('draggable', draggableDirective);
