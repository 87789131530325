class CowAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/cow');
  }

  show(cowId) {
    return this.APIService.get(`/cow/${cowId}`);
  }

  create(cow) {
    return this.APIService.post('/cow', cow);
  }

  bulkAdd(cows) {
    return this.APIService.post('/cow/bulk', cows);
  }

  update(data) {
    return this.APIService.put(`/cow/${data.cowId}`, data);
  }

  delete(cowId) {
    return this.APIService.delete(`/cow/${cowId}`);
  }

  // ラベル「種雄牛」がついてる牛のみGET
  getBulls() {
    return this.APIService.get('/cow/bull');
  }

  // uId, cowId, cowNoを取得する
  getCowNoList() {
    return this.APIService.get('/cow/cow_no');
  }

  // 分析画面用
  getAnalyticTargetCows(params) {
    return this.APIService.get(`/cow/cow_analytics?${params}`);
  }

  // 牛頭数取得
  summary() {
    return this.APIService.get('/cow/summary', 'GET');
  }

  // 血統情報取得
  pedigree() {
    return this.APIService.get('/cow/pedigree', 'GET');
  }

  // 牛群に属する牛全頭GET
  findByCowGroup() {
    return this.APIService.get(`/cow/cow_group/${this.group.id}`);
  }

  // イベント一括入力用
  deprecatedDigest(allowInactive) {
    const params = allowInactive ? '?allow_inactive=true' : '';
    return this.APIService.get(`/cow/deprecated_digest${params}`);
  }
}

app.service('CowAPI', CowAPI);
