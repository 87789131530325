class FarmEditController {
  constructor(
    $modalInstance,
    blockUI,
    DateUtilService,
    FarmService,
    SessionCache,
    Dictionary
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
    this.blockUI = blockUI;
    this.DateUtilService = DateUtilService;
    this.FarmService = FarmService;
    this.SessionCache = SessionCache;
    this.Dictionary = Dictionary;

    this.init();
  }

  init() {
    this.load();
    this.identificationTypeOptions = Farm.identificationTypeOptions;
    this.cowNoPatternOptions = Farm.cowNoPatternOptions;
    this.cowNoMatchingPatternOptions = Farm.cowNoMatchingPatternOptions;
    this.fatteningStartMonthAgeOptions = Farm.fatteningStartMonthAgeOptions;
    this.months = [
      {key: 1, label: '1月'},
      {key: 2, label: '2月'},
      {key: 3, label: '3月'},
      {key: 4, label: '4月'},
      {key: 5, label: '5月'},
      {key: 6, label: '6月'},
      {key: 7, label: '7月'},
      {key: 8, label: '8月'},
      {key: 9, label: '9月'},
      {key: 10, label: '10月'},
      {key: 11, label: '11月'},
      {key: 12, label: '12月'}
    ];
    this.faxNumber = {};
    this.stopShipmentDisplayOptions = Farm.stopShipmentDisplayOptions;
    this.stopShipmentDisplayPeriodOptions = [...Array(31).keys()].map((n) => {
      return {key: n, label: String(n)};
    });
  }

  save() {
    const data = this.viewToModel();
    this.blockUI.start('更新中');

    this.FarmService.update(data).then((res) => {
      this.blockUI.stop();
      this.$modalInstance.close();
      const farm = new Farm(this.farm.data); // インスタンスの使い回しは危険なので新規に作成する
      this.SessionCache.store('farm', farm);
      this.Dictionary.initialize();
    }).catch((err) => {
      this.blockUI.stop();
      this.message = err.messages ? ErrorUtil.formatErrorMessage(err.messages) : null;
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  cowNoPatternExample() {
    return this.farm.data.cowNoPattern === 'number' ? '例: 123、0456' : '例: B123、G0456';
  }

  cowNoMatchingPatternExample() {
    switch (this.farm.data.cowNoMatchingPattern) {
    case 'complete':
      return '「123」と入力した場合は「123」が検索されます';
    case 'prefix':
      return '「123」と入力した場合は「123、1234、12345」などが検索されます';
    case 'fuzzy':
      return '「123」と入力した場合は「123、01234、012345」などが検索されます';
    default:
      return '';
    }
  }

  fatteningStartMonthAgeExplain() {
    if (this.farm.isBreeding()) {
      return '去勢牛はこの月齢に達した時点で自動的に肥育状態になります';
    } else {
      return '子牛はこの月齢に達した時点で自動的に肥育状態になります';
    }
  }

  formattedStartDate() {
    return this.farm.startDate ? this.DateUtilService.toJapaneseYYYYMMDD(this.farm.startDate) : '';
  }

  // private

  load() {
    this.FarmService.show().then((result) => {
      this.farm = new Farm(result);

      if (this.farm.data.faxNumber) {
        const faxNumber = this.farm.data.faxNumber.split('-');

        this.faxNumber.num1 = faxNumber[0];
        this.faxNumber.num2 = faxNumber[1];
        this.faxNumber.num3 = faxNumber[2];
      }
    });
  }

  viewToModel() {
    const updateFieldKeys = [
      'farmName',
      'farmPhone',
      'faxNumber',
      'farmAddress',
      'farmCode',
      'identificationType',
      'allowDuplicationCowNo',
      'cowNoPattern',
      'feedThresholdRate',
      'waterThresholdRate',
      'lieThresholdRate',
      'milkThresholdRate',
      'cowNoMatchingPattern',
      'showHeatIndexFattening',
      'fiscalYearStartMonth',
      'fatteningStartMonthAge',
      'stopShipmentDisplay',
      'stopShipmentDisplayPeriod',
    ];

    if (Object.values(this.faxNumber).every((number) => !number)) {
      this.farm.data.faxNumber = '';
    } else {
      this.farm.data.faxNumber = Object.values(this.faxNumber).join('-');
    }

    const updateValues = {};
    updateFieldKeys.forEach((key) => {
      updateValues[key] = this.farm.data[key];
    });

    if (updateValues.stopShipmentDisplay !== 'period') {
      updateValues.stopShipmentDisplayPeriod = 0;
    }

    return updateValues;
  }
}

app.controller('FarmEditController', FarmEditController);
