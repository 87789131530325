function mobileStickyHeaderDirective() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      const height = element.find('#sticky-header-default').outerHeight() * 1.2;
      let prevY;

      element.on('touchstart', function() {
        angular.element('#sticky-header-fixed').removeClass('is-focus');
        document.activeElement.blur();
      });

      element.find('#sticky-header-fixed-text').on('focus', function() {
        angular.element('#sticky-header-fixed').addClass('is-focus');
      });

      element.on('touchmove', function() {
        const timer = setInterval(() => {
          const y = element[0].getBoundingClientRect().y;

          if (y === prevY) {
            clearInterval(timer);
          }

          if (Math.abs(y) > height) {
            angular.element('#sticky-header-fixed').addClass('is-fixed');
          } else {
            angular.element('#sticky-header-fixed').removeClass('is-fixed');
          }

          prevY = y;
        }, 500);
      });

      element.on('touchend', function() {
        const y = element[0].getBoundingClientRect().y;

        if (Math.abs(y) > height) {
          angular.element('#sticky-header-fixed').addClass('is-fixed');
        } else {
          angular.element('#sticky-header-fixed').removeClass('is-fixed');
        }
      });
    }
  };
}

app.directive('mobileStickyHeader', mobileStickyHeaderDirective);
