class LoginController {
  constructor(
    $rootScope,
    $scope,
    $state,
    $modal,
    $window,
    $timeout,
    SessionStateService,
    UtilService,
    ApplicationInitialService,
    SelectOperator,
    $stateParams
  ) {
    'ngInject';

    $timeout(() => {
      if ($stateParams.reload === true) $window.location.reload();
    });

    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$modal = $modal;
    this.SessionStateService = SessionStateService;
    this.UtilService = UtilService;
    this.ApplicationInitialService = ApplicationInitialService;
    this.SelectOperator = SelectOperator;

    this.redirectToName = $stateParams.redirectToName;
    this.redirectParams = $stateParams.redirectParams;

    this.email = null;
    this.password = null;
    this.invalid = true;
    this.errorMessage = null;

    // ウィンドウリサイズ時の表示の調整
    const loginElem = document.getElementsByClassName('login')[0];
    const loginContainerHeight = document.getElementById('js-l-container').clientHeight;

    const onResize = () => {
      const windowHeight = $window.innerHeight;
      if (windowHeight <= loginContainerHeight + 80) {
        loginElem.className = (loginElem.className.indexOf(' is-collapse') === -1) ? loginElem.className + ' is-collapse' : loginElem.className;
      } else {
        loginElem.className = loginElem.className.replace(/ is-collapse/g, '');
      }
    };
    angular.element($window).bind('resize', onResize);

    $scope.$on('$destroy', () => {
      angular.element($window).unbind('resize', onResize);
    });
  }

  login() {
    const email = this.email;
    const password = this.password;
    this.SessionStateService.login(email, password)
      .then((res) => {
        this.ApplicationInitialService.initialize().then((res) => {
          if (this.$rootScope.isMobile) {
            if (this.redirectToName) {
              this.$state.go(this.redirectToName, this.redirectParams);
            } else {
              this.$state.go('mobile-top');
            }
            return;
          }

          if (this.redirectToName) {
            this.$state.go(this.redirectToName, this.redirectParams);
          } else {
            this.$state.go('top');
          }
          this.SelectOperator.execute();
        });
      })
      .catch((error) => {
        this.errorMessage = this.UtilService.formatErrorMessage(error.data.messages);
      });
  }

  changePassword() {
    this.$state.go('changePassword', {email: this.email});
  }

  goToSmsLogin() {
    this.$state.go('login-sms', {
      disableMainHeader: true,
      redirectToName: this.redirectToName,
      redirectParams: this.redirectParams,
    });
  }

  validate() {
    if (!this.email || !this.password) {
      this.invalid = true;
    } else {
      this.invalid = false;
    }
  }

  isFieldError() {
    if (this.errorMessage) return 'is-alert';
  }

  onKeydownEmail(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      document.querySelector('#js-login [type=password]').focus();
    }
  }

}

app.controller('LoginController', LoginController);
