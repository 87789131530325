class AlertMailEditController {
  constructor(
    $modalInstance,
    params,
    AlertMailAPI,
    blockUI,
    $timeout,
    SessionCache,
    StandardDialog
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.AlertMailAPI = AlertMailAPI;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.StandardDialog = StandardDialog;

    this.init(params, SessionCache.farm());
  }

  init(params, farm) {
    this.title = params.title;
    this.model = params.model;
    this.viewMode = new ViewMode(params.viewMode);

    this.previousModel = {
      userName: params.model.userName,
      mailAddress: params.model.mailAddress,
      notificationMenus: {
        dysstasia: params.model.notificationMenus.dysstasia,
        calving: params.model.notificationMenus.calving,
      }
    };

    this.isConfirmed = params.model.status === 'confirmed';
    this.showAlertField = farm.useAlertDysstasia() && farm.useAlertCalving();

    this.invalid = false;
    this.errors = {};
  }

  validate() {
    const model = this.model;
    const errors = {};

    if (!model.userName) {
      errors.userName = 'ユーザー名が未入力です';
    }
    if (!model.mailAddress) {
      errors.mailAddress = 'メールアドレスが未入力です';
    } else {
      const result = StringUtil.smartlyValidateEmail(model.mailAddress);
      if (!result.valid) {
        errors.mailAddress = result.messages[0];
      }
    }

    if (this.showAlertField) {
      if (!model.notificationMenus.dysstasia && !model.notificationMenus.calving) {
        errors.notificationMenus = 'いずれかを選択してください';
      }
    }

    this.errors = errors;
    this.invalid = Object.values(errors).length > 0;
  }

  isChanged() {
    const current = this.model;
    const previous = this.previousModel;
    if (previous.userName !== current.userName) return true;
    if (previous.mailAddress !== current.mailAddress) return true;
    if (previous.notificationMenus.dysstasia !== current.notificationMenus.dysstasia) return true;
    if (previous.notificationMenus.calving !== current.notificationMenus.calving) return true;

    return false;
  }

  cancel() {
    this.$modalInstance.close();
  }

  save() {
    if (!this.viewMode.isDelete()) {
      this.validate();
      if (this.invalid) return;

      if (!this.isChanged()) {
        this.StandardDialog.showMessage({
          title: '警告',
          text1: '変更がありません。',
        });
        return;
      }
    }

    if (this.viewMode.isUpdate()) {
      this.update();
    } else if (this.viewMode.isDelete()) {
      this.delete();
    }
  }

  update() {
    const params = {
      userName: this.model.userName,
      mailAddress: this.model.mailAddress,
      notificationMenus: this.model.notificationMenus,
    };
    const procedure = this.AlertMailAPI.update(
      this.model.id,
      params,
      this.isConfirmed
    );
    this.callApi(procedure);
  }

  delete() {
    const procedure = this.AlertMailAPI.delete(
      this.model.id,
      this.isConfirmed
    );
    this.callApi(procedure);
  }

  callApi(procedure) {
    this.blockUI.start('更新中');
    this.errorMessage = {};

    procedure
      .then(() => {
        this.blockUI.done(() => {
          this.blockUI.start(`メールアドレスの${this.viewMode.label}が完了しました`);
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close();
      })
      .catch((res) => {
        const items = res.data.messages;
        items.forEach((item) => {
          if (item.field) {
            this.errorMessage[item.field] = item.message;
          } else {
            this.errorMessage.message = item.message;
          }
        });
        this.blockUI.stop();
      });
  }

  classModal() {
    if (this.viewMode.isCreate()) {
      return '';
    } else if (this.viewMode.isDelete()) {
      return 'uModal--small';
    }

    return 'uModal';
  }
}

app.controller('AlertMailEditController', AlertMailEditController);
