class HomeAlertHeatController {
  constructor(
    $state,
    SessionCache
  ) {
    'ngInject';

    this.$state = $state;

    HomeAlertBaseController.bind(this);

    const customlistSystemConfigs = SessionCache.customlistSystemConfigs();

    this.init(customlistSystemConfigs);
  }

  init(customlistSystemConfigs) {
    this.customlistIdMap = customlistSystemConfigs.filter((config) => {
      return ['alert_heat', 'alert_false_heat'].includes(config.configType);
    }).reduce((acc, cur) => {
      acc[cur.configType] = cur.customlistId;

      return acc;
    }, {});
  }

  goToAlertList(configType) {
    this.$state.go('alertList', {customlistId: this.customlistIdMap[configType]});
  }
}

function homeAlertHeat() {
  return {
    templateUrl: 'menu/home/alert/heat/index.html',
    controller: HomeAlertHeatController,
    controllerAs: 'ctrl',
    bindings: {
      summary: '<'
    }
  };
}

app.component('homeAlertHeat', homeAlertHeat());
