class FilterDialogController {
  constructor(
    $modalInstance,
    $modal,
    params,
    DateUtilService,
    SessionCache,
    MasterDialog) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.$modal = $modal;
    this.DateUtilService = DateUtilService;
    this.MasterDialog = MasterDialog;

    this.initialize(params, SessionCache.farm());
  }

  initialize(params, farm) {
    const ignoreColumnIds = new Set(['cow_group_name', 'farm_name', 'dummy', 'alert_cow_view_alert_type']);

    this.columns = params.columns.filter((c) => {
      const isIgnoreColumn = ignoreColumnIds.has(c.columnId);
      return !isIgnoreColumn;
    });

    if (farm.isMilk()) {
      const beforeCowGroupId = {
        columnId: 'before_cow_group_id',
        caption: '直前に所属していた牛群',
        fieldType: 'MASTER'
      };
      this.columns.push(beforeCowGroupId);
    }

    this.columnSelections = this.columns.map((column) => {
      return {key: column.columnId, label: column.caption};
    });

    this.columnMap = this.columns.reduce((obj, column) => {
      obj[column.columnId] = column;
      return obj;
    }, {});

    this.operators = [
      // STRING
      {value: 'include', label: '指定した値を含む', conditionType: 'STRING'},
      {value: 'exclude', label: '指定した値を含まない', conditionType: 'STRING'},
      {value: 'equal', label: '指定した値と等しい', conditionType: 'STRING'},
      {value: 'blank', label: '未入力', conditionType: 'STRING'},
      // NUMBER
      {value: 'between', label: '指定範囲内', conditionType: 'NUMERIC'},
      // DATE、DATETIME
      {value: 'between', label: '指定期間内', conditionType: 'DATE'},
      {value: 'blank', label: '未入力', conditionType: 'DATE'},
      // SELECTION
      {value: 'include', label: '指定した値を含む', conditionType: 'SELECTION'},
      {value: 'exclude', label: '指定した値を含まない', conditionType: 'SELECTION'},
      // MASTER
      {value: 'include', label: '指定したものを対象', conditionType: 'MASTER'},
      {value: 'exclude', label: '指定したものを除く', conditionType: 'MASTER'},
      // ORDINAL_SCALE
      {value: 'between', label: '指定範囲内', conditionType: 'ORDINAL_SCALE'},
    ];

    if (params.currentConditions.length > 0) {
      this.conditions = angular.copy(params.currentConditions);
      this.canClear = true;
    } else {
      this.conditions = [];
      this.addCondition();
      this.canClear = false;
    }
    this.canAddCondition = this.conditions.length < this.columns.length;

    this.conditions.forEach((c) => {
      if (c.conditionType === 'SELECTION') {
        this.setSelectionSummary(c);
      } else if (c.conditionType === 'MASTER') {
        this.setMasterSummary(c);
      }
      this.onChangeoperator(c);
    });
  }

  validate() {
    this.invalid = this.conditions.some((c) => {
      if (c.conditionType === 'STRING') {
        if (c.operator === 'blank') return false;
        if (!c.conditionString) return true;
      } else if (c.conditionType === 'NUMERIC') {
        if (!c.conditionMinNumber && !c.conditionMaxNumber) return true;
      } else if (c.conditionType === 'DATE') {
        if (c.operator === 'blank') return false;
        if (!c.conditionMinDate && !c.conditionMaxDate) return true;
      } else if (c.conditionType === 'SELECTION') {
        if (!c.selections) return true;
        if (c.selections.length === 0) return true;
      } else if (c.conditionType === 'MASTER') {
        if (!c.masters) return true;
        if (c.masters.length === 0) return true;
      } else if (c.conditionType === 'ORDINAL_SCALE') {
        if (!c.conditionMinNumber && !c.conditionMaxNumber) return true;
      }

      return false;
    });
  }

  ok() {
    const result = this.conditions.map((c) => {
      const result = {
        columnId: c.columnId,
        conditionType: c.conditionType,
        operator: c.operator
      };

      if (c.conditionType === 'STRING') {
        result['conditionString'] = c.conditionString;
      } else if (c.conditionType === 'NUMERIC') {
        result['conditionMinNumber'] = c.conditionMinNumber;
        result['conditionMaxNumber'] = c.conditionMaxNumber;
      } else if (c.conditionType === 'DATE') {
        result['conditionMinDate'] = this.DateUtilService.toDate(c.conditionMinDate);
        result['conditionMaxDate'] = this.DateUtilService.toDate(c.conditionMaxDate);
      } else if (c.conditionType === 'SELECTION') {
        result['selections'] = c.selections;
      } else if (c.conditionType === 'MASTER') {
        result['masters'] = c.masters;
      } else if (c.conditionType === 'ORDINAL_SCALE') {
        result['conditionMinNumber'] = c.conditionMinNumber;
        result['conditionMaxNumber'] = c.conditionMaxNumber;
      }

      return result;
    });

    this.$modalInstance.close(result);
  }

  clear() {
    this.$modalInstance.close([]);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  addCondition() {
    const column = this.columns.find((column) => {
      const used = this.conditions.some((condition) => {
        return column.columnId === condition.columnId;
      });
      return !used;
    });

    const condition = {columnId: column.columnId};
    this.conditions.push(condition);
    this.onChangeColumnId(condition);

    this.canAddCondition = this.conditions.length < this.columns.length;
  }

  removeCondition(index) {
    this.conditions.splice(index, 1);
    this.canAddCondition = true;
    this.validate();
  }

  onChangeColumnId(c) {
    const column = this.columnMap[c.columnId];

    if (column.fieldType === 'STRING') {
      if (CustomlistSelection.isSelection(c.columnId) || CustomlistSelection.isUserDefined(c.columnId)) {
        c.conditionType = 'SELECTION';
        this.setSelectionSummary(c);
      } else {
        c.conditionType = 'STRING';
      }
    } else if (column.fieldType === 'MASTER') {
      c.conditionType = 'MASTER';
      this.setMasterSummary(c);
    } else if (this.isNumericType(column.fieldType)) {
      c.conditionType = 'NUMERIC';
    } else if (this.isDateType(column.fieldType)) {
      c.conditionType = 'DATE';
    } else if (column.fieldType === 'ORDINAL_SCALE') {
      c.conditionType = 'ORDINAL_SCALE';
    }

    this.onChangeoperator(c);
  }

  onChangeoperator(c) {
    c.showStringCondition = false;
    c.showNumericCondition = false;
    c.showDateCondition = false;
    c.showSelectionCondition = false;
    c.showMasterCondition = false;
    c.showOrdinalScaleCondition = false;

    if (c.conditionType === 'STRING') {
      const valid = ['include', 'exclude', 'equal', 'blank'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'include';
      }
      c.showStringCondition = ['include', 'exclude', 'equal'].includes(c.operator);

      if (!c.showStringCondition) {
        c.conditionString = '';
      }
    } else if (c.conditionType === 'NUMERIC') {
      const valid = ['between'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'between';
      }
      c.showNumericCondition = true;
    } else if (c.conditionType === 'DATE') {
      const valid = ['between', 'blank'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'between';
      }
      c.showDateCondition = c.operator === 'between';

      if (!c.showDateCondition) {
        c.conditionMinDate = null;
        c.conditionMaxDate = null;
      }
    } else if (c.conditionType === 'SELECTION') {
      const valid = ['include', 'exclude'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'include';
      }
      c.showSelectionCondition = true;
    } else if (c.conditionType === 'MASTER') {
      const valid = ['include', 'exclude'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'include';
      }
      c.showMasterCondition = true;
    } else if (c.conditionType === 'ORDINAL_SCALE') {
      const valid = ['between'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'between';
      }

      this.ordinalScaleOptions = CustomlistOrdinalScale.selectOptions(c.columnId);
      c.showOrdinalScaleCondition = true;
    }

    this.validate();
  }

  onClickSelection(c) {
    const column = this.columnMap[c.columnId];
    const selections = c.selections || [];

    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'dialog/condition/selection-dialog.html',
      controller: 'SelectionDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'select-standard',
      resolve: {
        params: () => {
          return {
            columnId: c.columnId,
            caption: column.caption,
            currentSelections: selections
          };
        }
      }
    });

    modalInstance.result.then((result) => {
      c.selections = result;
      this.setSelectionSummary(c);
      this.validate();
    });
  }

  setSelectionSummary(c) {
    if (c.selections && c.selections.length > 0) {
      c.selectionSummary = c.selections.join('、');
    } else {
      c.selectionSummary = '(対象を指定してください)';
    }
  }

  onClickMaster(c) {
    const column = this.columnMap[c.columnId];

    let masterId;
    if (column.columnId === 'before_cow_group_id') {
      masterId = 'cow_group';
    }
    const ids = (c.masters || []).map((m) => m.id);

    this.MasterDialog.execute(masterId, ids).then((result) => {
      c.masters = result;
      this.setMasterSummary(c);
      this.validate();
    });
  }

  setMasterSummary(c) {
    const names = (c.masters || []).map((m) => m.name);
    c.masterSummary = names.join('、') || '(対象を指定してください)';
  }

  isNumericType(fieldType) {
    return ['INTEGER', 'FLOAT', 'LAPSED_DAYS', 'DIFF_DAYS', 'LAPSED_MONTHS', 'EXPECTED_DAYS'].some((s) => fieldType === s);
  }

  isDateType(fieldType) {
    return fieldType === 'DATE' || fieldType === 'DATETIME';
  }

  onKeydownNumber(e) {
    const isNumberKey = (key) => {
      if ('0123456789.-'.indexOf(key) >= 0) {
        return true;
      }
      // タブやリターンキーなどの特殊キーに対応するための例外処理
      if (key.length > 1) {
        return true;
      }
      return false;
    };

    if (!isNumberKey(e.key)) {
      e.preventDefault();
    }
  }

  clearConditionDate(condition, fieldKey) {
    condition[fieldKey] = null;
    this.validate();
  }
}

app.controller('FilterDialogController', FilterDialogController);
