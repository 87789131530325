class ChartActivityScatterPlotAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  show(params) {
    let endpoint = `/chart/activity/scatter_plot?cow_id=${params.cowId}`;
    endpoint += params.date ? `&date=${params.date}` : '';
    endpoint += params.date_condition ? `&date_condition=${params.date_condition}` : '';
    return this.APIService.get(endpoint);
  }

  calf(params) {
    let endpoint = `/chart/activity/scatter_plot/calf?cow_id=${params.cowId}`;
    endpoint += params.date ? `&date=${params.date}` : '';
    return this.APIService.get(endpoint);
  }

  cowGroup(id, date) {
    let endpoint = `/chart/activity/scatter_plot/cow_group/${id}`;
    endpoint += date ? `?date=${date}` : '';
    return this.APIService.get(endpoint);
  }

  fatteningStage(id, date) {
    let endpoint = `/chart/activity/scatter_plot/fattening_stage/${id}`;
    endpoint += date ? `?date=${date}` : '';
    return this.APIService.get(endpoint);
  }

}

app.service('ChartActivityScatterPlotAPI', ChartActivityScatterPlotAPI);
