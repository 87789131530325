/**
 * @deprecated CowBleIdAPIなどを利用してください
 * ※この実装の修正および、新規の利用は禁止です
 */
class BleIdService {
  constructor(APIService, $q, DateUtilService) {
    'ngInject';
    this.APIService = APIService;
    this.$q = $q;
    this.DateUtilService = DateUtilService;
  }

  cowBleIds(cowId) {
    return this.APIService.get(`/ble_id/cow/${cowId}`);
  }

  equip(params) {
    return this.APIService.post('/ble_id/cow', params);
  }

  unequip(cowId, endDate) {
    const params = {endDate: endDate};
    return this.APIService.put(`/ble_id/cow/${cowId}`, params);
  }

  delete(cowId, startDate) {
    const date = this.DateUtilService.toW3CFormat(startDate);
    return this.APIService.delete(`/ble_id/cow/${cowId}/${date}`, null);
  }

  formatBleId(input) {
    if (!input) return input;

    // 牛個体のble_idはゼロ埋めの12桁の文字列で運用しています(例 000000000123)
    return ('000000000000' + input).slice(-12);
  }

  availablePeriods(cowBleIds, startDate, endDate) {
    const startDateInt = dateConversion(startDate, 'YYYYMMDD').getTime();
    const endDateInt = dateConversion(endDate, 'YYYYMMDD').getTime();

    return cowBleIds.filter((cowBleId) => (cowBleId.startDate <= startDateInt) && (cowBleId.endDate >= startDateInt))
      .map((cowBleId) => {
        // 期間の開始日は後の日付を優先
        const start = startDateInt >= cowBleId.startDate ? startDateInt : cowBleId.startDate;

        // 期間の開終了日は先の日付を優先
        const end = endDateInt <= cowBleId.endDate ? endDateInt : cowBleId.endDate;

        if (cowBleId.sensorType === 1) {
          return {
            startDate: start,
            bleId: cowBleId.bleId,
            sensorType: cowBleId.sensorType
          };
        }

        return {
          startDate: getDateForApiParam(start),
          endDate: getDateForApiParam(end),
          bleId: cowBleId.bleId,
          sensorType: cowBleId.sensorType
        };
      });
  }
}

app.service('BleIdService', BleIdService);
