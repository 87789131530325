app.controller('bullInfoSelectModalController', function(
  $scope,
  $modalInstance,
  params,
  FarmService,
  DepositFarmService
) {
  'ngInject';

  $scope.bullInfoList = [];
  $scope.selectedBullInfo = {};
  params = params || {};

  // 預託元コンテキストか否かでサービスの使い分け
  this.FarmService = params.isDeposit ? DepositFarmService : FarmService;

  this.FarmService.show(params.qwert).then((res) => {
    $scope.farm = new Farm(res);
  });

  $scope.$on('ngRepeatFinished', function() {
    // console.log("ngRepeatFinished");
    FixedMidashi.create();

    $('.modal-table-list-lg .fixed_header_display_none_at_print table .medicine-col3').css('width', '120px');
    $('.modal-table-list-lg .fixed_header_display_none_at_print table .medicine-col4').css('width', '120px');
    $('.modal-table-list-lg .fixed_header_display_none_at_print table .medicine-col1').css('width', '50px');
  });

  $scope.bullInfoList = params.bulls.map((bullInfo) => {
    if (bullInfo.cowId === params.cowId) {
      bullInfo.checked = true;
    } else {
      bullInfo.checked = false;
    }
    return bullInfo;
  });

  $scope.setBullInfo = (cowId) =>{
    _.each($scope.bullInfoList, (bullInfo) => {
      if (bullInfo.cowId === cowId) {
        bullInfo.checked = true;
      } else {
        bullInfo.checked = false;
      }
    });
  };

  $scope.ok = () => {
    $scope.selectedBullInfo = $scope.bullInfoList.find((bullInfo) => bullInfo.checked);
    $modalInstance.close($scope.selectedBullInfo);
  };

  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');
  };
  // 共通関数を紐づけ
  $scope.formatCowUid = formatCowUid;
});
