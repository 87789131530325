class DepoistAgentFarmAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/deposit/agent_farm');
  }

  summary() {
    return this.APIService.get('/deposit/agent_farm/summary');
  }

  updateSortOrder(orders) {
    const params = {orders: orders};
    return this.APIService.put('/deposit/agent_farm/sort_order', params);
  }
}

app.service('DepoistAgentFarmAPI', DepoistAgentFarmAPI);
