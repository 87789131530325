/**
 * @deprecated
 * マスタ設定メニューはReactに移行されました。
 * IEユーザーへの対応のために残しています。
 */
function masterIndexDirective() {
  const MENUS = {
    COW_LABEL: {
      contentUrl: 'menu/master/cow-label/index-template.html'
    },
    SPERM: {
      contentUrl: 'menu/master/sperm/index-template.html'
    },
    EMBRYO: {
      contentUrl: 'menu/master/embryo/index-template.html'
    },
    HORMONE_PROGRAM: {
      contentUrl: 'menu/master/hormone-program/index-template.html'
    },
    MEDICINE: {
      contentUrl: 'menu/master/medicine/index-template.html'
    },
    MASTER_DISEASE: {
      contentUrl: 'menu/master/master-disease/index-template.html'
    },
    MARKET: {
      contentUrl: 'menu/master/market/index-template.html'
    },
  };

  return {
    restrict: 'E',
    scope: {
      menuId: '='
    },
    template: '<ng-container ng-include="contentUrl"></ng-container>', // ES6のテンプレート文字列を使うと動かない！
    link: (scope, element, attrs) => {
      scope.$watch('menuId', () => {
        const menu = MENUS[scope.menuId];
        if (!menu) return; // 古い実装のメニューが渡された場合は抜ける
        scope.contentUrl = menu.contentUrl;
      });
    }
  };
}

app.directive('masterIndexDirective', masterIndexDirective);
