// eslint-disable-next-line no-unused-vars
class NumberUtil {

  /**
   * 渡された数値を指定された小数点以下の桁数までの文字列に変換して返します
   *
   * @param {number} num 数値
   * @param {number} digits 小数点以下の桁数
   * @return {string} 数値文字列
   */
  static format(num, digits = 0) {
    if (num === null || num === undefined) return '';

    const coefficient = Math.pow(10, digits);
    return (Math.floor(num * coefficient) / coefficient).toFixed(digits);
  }

  /**
   * 渡された数値を指定された桁数でカンマ区切りにした文字列に変換して返します
   *
   * @param {number|string} val 数値
   * @param {number} digits カンマで区切る桁数
   * @return {string} 数値文字列
   */
  static commaSeparated(val, digits = 3) {
    if (val === null || val === undefined) return '';

    let num = Number(val);
    let sign = '';
    if (num < 0) {
      num = Math.abs(num);
      sign = '-';
    }

    const str = String(num).split('.');
    let integerPart = str[0];
    let fractionPart = '';
    if (str.length >= 2) {
      fractionPart = '.' + str[1];
    }

    let len = integerPart.length;
    const count = Math.ceil(len / digits);

    const parts = [];
    for (let i = 1; i <= count; i++) {
      let cutLength = digits;
      const offset = digits * i;
      let index = len - offset;
      if (index < 0) {
        index = 0;
        cutLength = len - (offset - digits);
      }

      const s = integerPart.substr(index, cutLength);
      parts.push(s);
    }

    return sign + parts.reverse().join(',') + fractionPart;
  }

  /**
   * モジュラス10 ウェイト3・1のチェックデジットを返します
   *
   * @param {number} num 数値
   * @return {number} チェックデジット
   */
  static modulus10Weight3CheckDigit(num) {
    if (!num) return null;

    const nums = num.toString().split('');

    const sum =
      nums.reduce((acc, cur, index) => {
        if (index % 2 === 0) {
          return acc + Number(cur) * 3;
        }

        return acc + Number(cur);
      }, 0) % 10;

    if (sum === 0) {
      return 0;
    }

    return 10 - sum;
  }
}
