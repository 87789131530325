class FileImportAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/file_import');
  }

  show(id) {
    return this.APIService.get(`/file_import/${id}`);
  }

  upload(file) {
    return this.APIService.upload('/file_import', file);
  }

  delete(id) {
    return this.APIService.delete(`/file_import/${id}`);
  }

  errorList(id) {
    return this.APIService.get(`/file_import/error/${id}`);
  }
}

app.service('FileImportAPI', FileImportAPI);
