// eslint-disable-next-line no-unused-vars
class CertificateBirthIndexGenerator {
  generateColumnDefs(columns) {
    return columns.filter((column) => {
      return !column.hidden;
    }).map((column) => {
      return {
        field: column.columnId,
        name: column.name,
        width: column.width,
        headerCellTemplate: this.generateHeaderCellTemplate(column),
        headerCellClass: () => {
          return 'ui-grid-sorted-none';
        },
        cellTemplate: this.generateCellTemplate(column)
      };
    });
  }

  generateHeaderCellTemplate(column) {
    if (column.columnId === 'selected') {
      return `
        <div class="contents__uiGridCenter">
          <input ng-model="grid.appScope.allCheck" ng-click="grid.appScope.toggleAllCheck()" type="checkbox" class="contents__uiGridCheckbox">
        </div>`;
    } else {
      return `
        <div
          ng-click="grid.appScope.clickHeaderCell('${column.columnId}', col)"
          ng-class="grid.appScope.classHeaderCell(col)"
        >
          <div class="customlist-th-inner">
            <span>${column.name}</span>
            <div class="sort-icon"></div>
          </div>
        </div>`;
    }
  }

  generateRowTemplate() {
    return `
      <div ng-click="grid.appScope.toggleCheck($event, row.entity)"
        ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
        ng-class="grid.appScope.classRow(row.entity)"
        class="ui-grid-cell"
        ui-grid-cell>
      </div>
    `;
  }

  generateCellTemplate(column) {
    if (column.columnId === 'selected') {
      return `
        <div class="contents__uiGridCenter">
          <input ng-model="row.entity.selected" type="checkbox" class="contents__uiGridCheckbox">
        </div>`;
    }
  }
}
