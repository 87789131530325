class SortOrderEditDialog {
  constructor(
    $modal,
    blockUI
  ) {
    'ngInject';
    this.$modal = $modal;
    this.blockUI = blockUI;
  }

  /**
   * 表示順序設定画面ダイアログを表示するためのエントリーポイント
   *
   * @param {string} title 設定画面に表示されるタイトル(薬品表示順設定、グループ牧場表示順設定、etc.)
   * @param {string} itemLabel 設定画面のリストの項目ヘッダー(設定名、薬品名、牧場名、etc.)
   * @param {Array.<Object>} items 表示変更対象の設定オブジェクト(id、nameをフィールドに持つ)の配列
   *   ex. [
   *         {id: 1, name: '薬品A'},
   *         {id: 2, name: '薬品B'},
   *         {id: 3, name: '薬品C'}
   *       ]
   * @param {Object} updateFunction 表示順を更新するAPIを呼び出す関数(呼び出し元でthisのbindを行うこと)
   *
   */
  execute(title, itemLabel, items, updateFunction) {
    const config = {
      animation: true,
      templateUrl: 'components/dialog/sort-order/index-legacy.html',
      controller: 'SortOrderEditController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'select-standard',
      resolve: {
        params: () => {
          return {
            title: title,
            itemLabel: itemLabel,
            items: items,
            updateFunction: updateFunction,
            isLegacy: true,
          };
        }
      }
    };

    return this.$modal.open(config).result.then((result) => {
      return result;
    });
  }

  /**
   * 表示順序設定画面ダイアログを表示するためのエントリーポイント（新しいモーダル用）
   *
   * @param {string} title 設定画面に表示されるタイトル(薬品表示順設定、グループ牧場表示順設定、etc.)
   * @param {string} itemLabel 設定画面のリストの項目ヘッダー(設定名、薬品名、牧場名、etc.)
   * @param {Array.<Object>} items 表示変更対象の設定オブジェクト(id、nameをフィールドに持つ)の配列
   *   ex. [
   *         {id: 1, name: '薬品A'},
   *         {id: 2, name: '薬品B'},
   *         {id: 3, name: '薬品C'}
   *       ]
   * @param {Object} updateFunction 表示順を更新するAPIを呼び出す関数(呼び出し元でthisのbindを行うこと)
   */
  open(title, itemLabel, items, updateFunction) {
    const config = {
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'components/dialog/sort-order/index.html',
      controller: 'SortOrderEditController',
      controllerAs: 'ctrl',
      animation: true,
      backdrop: false,
      keyboard: false,
      resolve: {
        params: () => {
          return {
            title: title,
            itemLabel: itemLabel,
            items: items,
            updateFunction: updateFunction,
          };
        }
      }
    };

    return this.$modal.open(config).result.then((result) => {
      return result;
    });
  }
}

app.service('SortOrderEditDialog', SortOrderEditDialog);
