class temporaryGroupViewCowGroupController {
  constructor(
    CowGroupAPI,
    TgvGroupConfigAPI
  ) {
    'ngInject';

    this.CowGroupAPI = CowGroupAPI;
    this.TgvGroupConfigAPI = TgvGroupConfigAPI;
  }

  $onInit() {
    this.selectedId = this.cowGroupId;
    this.index();
  }

  index() {
    this.CowGroupAPI.summary().then((res) => {
      const cowGroups = res.data;

      this.TgvGroupConfigAPI.index().then((res) => {
        const groupConfigs = res.data;

        this.items = groupConfigs.filter((config) => {
          return config.groupName === this.groupName;
        }).map((config) => {
          const cowCount = cowGroups.find((group) => {
            return group.cowGroupId === config.cowGroupId;
          }).cowCount;

          config.cowCount = cowCount;

          return config;
        });
      });
    });
  }

  onSelect(group) {
    this.selectedId = group.cowGroupId;
    this.onChangeId({
      '$id': group.cowGroupId
    });
  }

  addClassActive(group) {
    if (this.selectedId === group.cowGroupId) {
      return 'active';
    }
  }
}

function temporaryGroupViewCowGroup() {
  return {
    restrict: 'E',
    templateUrl: 'temporary-group-view/activity/cow-group.html',
    controller: temporaryGroupViewCowGroupController,
    controllerAs: 'ctrl',
    scope: true,
    bindToController: {
      groupName: '<',
      cowGroupId: '<',
      onChangeId: '&'
    }
  };
}

app.directive('temporaryGroupViewCowGroup', temporaryGroupViewCowGroup);
