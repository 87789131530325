// eslint-disable-next-line no-unused-vars
class StringUtil {
  static get KATAKANA_MAP() {
    return {
      // 濁音
      'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
      'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
      'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
      'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
      // 半濁音
      'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
      'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
      // ↑ 濁音、半濁音を先に記述しないと動かなくなるので変更しないこと！！
      'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
      'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
      'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
      'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
      'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
      'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
      'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
      'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
      'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
      'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
      // 拗音
      'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
      'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
    };
  }

  /**
   * 検査対象の文字列が指定したキーワード文字列を含むかを判定します
   *
   * @param {String} value 検査対象の文字列
   * @param {String} word キーワード文字列
   * @return {boolean} true: 指定したキーワード文字列を含む
   */
  static include(value, word) {
    if (!value || !word) return false;

    const result = value.indexOf(word);
    return result >= 0;
  }

  /**
   * 文字列を改行コードで分割し配列で返します
   *
   * @param {string} str 対象文字列
   * @return {Array.<string>} 配列
   */
  static splitByNewline(str) {
    return str ? String(str).split(/\r\n?|\n/) : [];
  }

  /**
   * 渡された値が空文字、null。undefinedの場合はtrueを返します
   * @param {string} str 文字列
   * @return {boolean} true: 未入力、または空文字
   */
  static isEmpty(str) {
    if (str === null || str === undefined || str === '') return true;
    return false;
  }

  /**
   * 文字列が数字のみで構成されているかを判定する
   * @param {string} str 文字列
   */
  static isDigit(str) {
    if (!str) return false;
    return /^[0-9]+$/.test(str);
  }

  /**
  * 文字列が小数点を含む数字のみで構成されているかを判定する
  * @param {string} str 文字列
  */
  static isDecimal(str) {
    if (!str) return false;
    return /(^\d+$|^\d+\.\d+$)/.test(str);
  }

  /**
   * 渡された値が数値かどうかを判定する
   */
  static isNumeric(value) {
    return value === undefined || value === null || !isNaN(Number(value));
  }

  /**
   * 渡された数字を文字列に置き換える。
   * 渡された数字が0かnullの場合は指定された文字列に置き換えます。
   *
   * @param {number} or {string} n 数字または数字文字列
   * @param {string} replacement 0またはnullの場合に置き換える文字列
   * @return {string} 変換後の文字列
   *
   * ex. 置き換え文字列が「-」のケース
   * 1 => '1'
   * 2 => '2'
   * 0 => '-'
   * null => '-'
   * '1.5' => '1.5'
   * '0.0' => '-'
  */
  static replaceZeroOrNull(n, replacement = '') {
    const num = (typeof n === 'string') ? Number(n) : n;
    if (num) {
      return String(num);
    } else {
      return replacement;
    }
  }

  static camelToKebabCase(str) {
    if (!str) return '';
    function replacer(match, offset, string) {
      return (offset > 0 ? '-' : '') + match.toLowerCase();
    }
    return str.replace(/[A-Z]/g, replacer);
  }

  /**
   * スネークケース文字列をキャメルケースに変換します
   * @param {string} str
   * @return {string}
   */
  static snakeToCamelCase(str) {
    if (!str) return '';
    return str.replace(/(_\w)/g, (match) => {
      return match.toUpperCase().substr(1);
    });
  }

  /**
   * スネークケース文字列をケバブケースに変換します
   * @param {string} str
   * @return {string}
   */
  static snakeToKebabCase(str) {
    if (!str) return '';
    return str.replace(/_/g, '-');
  }

  /**
   * 全角英数字を半角に変換する
   * @param {string} str
   * @return {string}
   */
  static toHalfWidthCharacters(str) {
    if (!str) return str;

    return str.replace(/[！-～]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
  }

  /**
   * 半角カタカナを全角カタカナに変換した文字列を返します
   * @param {string} str 文字列
   * @return {string}
   */
  static toFullWidthKatakana(str) {
    if (!str) return str;

    const map = StringUtil.KATAKANA_MAP;
    const keys = Object.keys(map).join('|');
    const reg = new RegExp(`(${keys})`, 'g');
    return str.replace(reg, (s) => {
      return map[s];
    });
  }

  /**
   * 全角英数を半角英数、半角カタカナを全角カタカナに変換した文字列を返します
   * @param {string} str 文字列
   * @return {string}
   */
  static normalize(str) {
    const s = StringUtil.toFullWidthKatakana(str);
    return StringUtil.toHalfWidthCharacters(s);
  }

  /**
   * 文字列からスペースを省く
   * @param {string} str
   * @return {string}
   */
  static removeSpaces(str) {
    return str.replace(/\s/g, '');
  }

  /**
   * 文字列から半角数値以外を省く
   * @param {string} str
   * @return {string}
   */
  static removeNonSingleByteNumericCharacters(str) {
    return str.replace(/[^0-9]/g, '');
  }

  /**
   * 文字列から半角英数記号以外の文字を除去します
   * @param {string} str
   * @return {string}
   */
  static removeNonHalfWidthCharacters(str) {
    return str.replace(/[^!-~]/g, '').replace(/[\u{20}\u{3000}]/ug, '');
  }

  /**
   * 渡された数字を文字列に置き換える。
   * 渡された数字がnullの場合は指定された文字列に置き換えます。
   *
   * @param {number} or {string} n 数字または数字文字列
   * @param {string} replacement nullの場合に置き換える文字列
   * @return {string} 変換後の文字列
  */
  static replaceNull(n, replacement = '') {
    if (n === null) {
      return replacement;
    } else {
      return String(n);
    }
  }

  /**
   * 検査対象の文字列に指定した文字が含まれる数を返します。
   *
   * @param {string} value 検査対象の文字列
   * @return {number} 対象の文字が含まれる数
   */
  static countCharacter(value, charcter) {
    if (!value) return 0;
    return (value.match(new RegExp(charcter, 'g')) || []).length;
  }

  /**
   * 検査対象の文字列がメールアドレスとして有効かを判定します。
   *
   * @param {string} value 検査対象の文字列
   * @return {boolean} true: 有効
   */
  static isEmail(value) {
    if (!value) return false;
    return /^([a-zA-Z_0-9=!#%&'`{}~+\-*/?^$|]+)*^([.-]?[a-zA-Z_0-9=!#%&'`{}~+\-*/?^$|]+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value);
  }

  /**
   * 検査対象の文字列がメールアドレスとして有効かを判定します。
   *
   * @param {string} value 検査対象の文字列
   * @return {object} 検査結果 {valid: boolean, messages: Array.<string>}
   *
   * ex.
   * {valid: true}
   * {valid: false, messages: ['無効なメールアドレスです']}
   */
  static smartlyValidateEmail(value) {
    if (!value) {
      return {valid: false, messages: ['未入力です']};
    }

    if (!value.includes('@')) {
      return {valid: false, messages: ['@が入力されていません']};
    }

    if (!value.includes('"')) {
      if (StringUtil.countCharacter(value, '@') >= 2) {
        return {valid: false, messages: ['@が複数入力されています']};
      }
    }

    if (value.includes('..')) {
      return {valid: false, messages: ['ピリオド(.)は連続して入力できません']};
    }

    if (value.includes('@') && value.includes('.')) {
      const elements = value.split('@');
      if (elements[0].endsWith('.') || elements[1].endsWith('.')) {
        return {valid: false, messages: ['文字列の終端にピリオド(.)は入力できません']};
      }
    }

    const valid = StringUtil.isEmail(value);
    if (!valid) {
      return {valid: false, messages: ['無効なメールアドレスです']};
    }
    return {valid: true};
  }

  /**
   * Excelなどのスプレッドシートからクリップボードにコピーされた文字列を改行コード、タブで分割して二次元配列にして返す。
   * @param {string} input
   * @return {Array.<Array<string>>}
   */
  static parseClippedMatrix(input, ignoreBlankRow = true) {
    if (!input) return [];

    const trimmed = input.replace(/\r/g, '');
    const lines = trimmed.split('\n');

    const rows = lines.filter((line) => {
      if (ignoreBlankRow) {
        const replaced = line.replace(/\t/g, '');
        if (!replaced) {
          return false;
        }
      }
      return true;
    });

    if (!ignoreBlankRow) {
      const removeFooterBlankRows = (rows) => {
        const lastIndex = rows.length - 1;
        let inputLastIndex = -1;
        for (let i = lastIndex; i > 0; i--) {
          const replaced = rows[i].replace(/\t/g, '');
          if (replaced) {
            inputLastIndex = i;
            break;
          }
        }
        rows.splice(inputLastIndex + 1);
      };

      removeFooterBlankRows(rows);
    }

    return rows.map((row) => row.split('\t'));
  }

  /**
   * 性と名の間に空白を含む可能性のある名前の同一判定を行います。
   * @param {string} a、b: 判定対象の名前
   * @return {boolean} true: 同じ名前
   */
  static isSameName(a, b) {
    if (!a || !b) return false;

    return StringUtil.removeSpaces(a) === StringUtil.removeSpaces(b);
  }
}
