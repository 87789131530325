class DepositTimelineContext {
  constructor(
    $q,
    DepositFarmService,
    SelectionService,
    DepositCowEventsService,
    DepositCowService,
    DepositCowGroupService,
    DepositMasterHormoneProgramService,
    DepositMasterMedicineService,
    DepositAlertService,
    DepositMasterSpermService,
    MasterEmbryoAPI,
    MasterDiseaseAPI,
    ReproductionConfigService,
    DepositUserDefinedSelectionAPI,
    PregnantDiagnosisTimingAPI,
    DepositOperationUserAPI,
    AccountService,
    DepositAlertConfigAPI,
    DepositAlertAPI,
    MasterWorkNoteTypeAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.DepositFarmService = DepositFarmService;
    this.SelectionService = SelectionService;
    this.DepositCowEventsService = DepositCowEventsService;
    this.DepositCowService = DepositCowService;
    this.DepositCowGroupService = DepositCowGroupService;
    this.DepositMasterHormoneProgramService = DepositMasterHormoneProgramService;
    this.DepositMasterMedicineService = DepositMasterMedicineService;
    this.DepositAlertService = DepositAlertService;
    this.DepositMasterSpermService = DepositMasterSpermService;
    this.MasterEmbryoAPI = MasterEmbryoAPI;
    this.MasterDiseaseAPI = MasterDiseaseAPI;
    this.ReproductionConfigService = ReproductionConfigService;
    this.DepositUserDefinedSelectionAPI = DepositUserDefinedSelectionAPI;
    this.PregnantDiagnosisTimingAPI = PregnantDiagnosisTimingAPI;
    this.OperationUserAPI = DepositOperationUserAPI;
    this.AccountService = AccountService;
    this.DepositAlertConfigAPI = DepositAlertConfigAPI;
    this.DepositAlertAPI = DepositAlertAPI;
    this.MasterWorkNoteTypeAPI = MasterWorkNoteTypeAPI;
  }

  setCurrentQwert(qwert) {
    this.qwert = qwert;
  }

  load(cowId) {
    return this.$q.all([
      // 預託牛用のAPIを設定する
      this.DepositFarmService.show(this.qwert),
      this.DepositCowGroupService.index(this.qwert),
      this.DepositCowService.getBulls(this.qwert),
      this.DepositMasterMedicineService.available(this.qwert),
      this.SelectionService.index(),
      this.DepositMasterHormoneProgramService.available(this.qwert),
      this.DepositCowEventsService.index(cowId),
      this.DepositAlertService.alertsByCow(cowId),
      this.DepositMasterSpermService.available(this.qwert),
      this.MasterDiseaseAPI.available(), // 預託先にマスターは作成しないので預託元だけで要件を満たす
      this.ReproductionConfigService.show(),
      this.DepositUserDefinedSelectionAPI.available(this.qwert),
      this.PregnantDiagnosisTimingAPI.show(), // 未対応だがAPIの数を合わせる
      this.OperationUserAPI.available(this.qwert),
      this.AccountService.cachedAccount(),
      this.DepositAlertConfigAPI.show(this.qwert),
      this.DepositAlertAPI.dysstasiaAlertsByCow(cowId),
      this.MasterWorkNoteTypeAPI.available()
    ]);
  }

  cowEventsService() {
    // TODO: 預託先用のサービスを返すようにする
    return this.DepositCowEventsService;
  }

  cowService() {
    // TODO: 預託先用のサービスを返すようにする
    return this.DepositCowService;
  }

  masterEmbryoAPI() {
    // TODO: 預託先用のサービスを返すようにする
    return this.MasterEmbryoAPI;
  }

  masterDiseaseAPI() {
    return this.MasterDiseaseAPI;
  }
}

app.service('DepositTimelineContext', DepositTimelineContext);
