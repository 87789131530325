class HomeAlertCalvingController {
  constructor() {
    'ngInject';

    HomeAlertBaseController.bind(this);
  }
}

function homeAlertCalving() {
  return {
    templateUrl: 'menu/home/alert/calving/index.html',
    controller: HomeAlertCalvingController,
    controllerAs: 'ctrl',
    bindings: {
      summary: '<'
    }
  };
}

app.component('homeAlertCalving', homeAlertCalving());
