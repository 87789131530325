class HomeAlertLowController {
  constructor(
    $state,
    SessionCache
  ) {
    'ngInject';

    this.$state = $state;

    HomeAlertBaseController.bind(this);

    const farm = SessionCache.farm();

    this.init(farm);
  }

  init(farm) {
    this.isDairy = farm.isDairy();
  }

  goToAlertLieHigh() {
    if (this.isDairy) {
      this.$state.go('alertLieHigh', {}, {parent: this.$state.current});
    } else {
      this.$state.go('alertLieHighBeef', {}, {parent: this.$state.current});
    }
  }

  goToAlertFeedLow() {
    if (this.isDairy) {
      this.$state.go('alertFeedLow', {}, {parent: this.$state.current});
    } else {
      this.$state.go('alertFeedLowBeef', {}, {parent: this.$state.current});
    }
  }

  goToAlertWaterLow() {
    if (this.isDairy) {
      this.$state.go('alertWaterLow', {}, {parent: this.$state.current});
    } else {
      this.$state.go('alertWaterLowBeef', {}, {parent: this.$state.current});
    }
  }
}

function homeAlertLow() {
  return {
    templateUrl: 'menu/home/alert/low/index.html',
    controller: HomeAlertLowController,
    controllerAs: 'ctrl',
    bindings: {
      summary: '<'
    }
  };
}

app.component('homeAlertLow', homeAlertLow());
