class ReproductionConfigController {
  constructor(
    $modal,
    $q,
    ReproductionConfigService,
    PregnantDiagnosisTimingAPI,
    CalvingConfigAPI,
    FarmService
  ) {
    'ngInject';
    this.$modal = $modal;
    this.ReproductionConfigService = ReproductionConfigService;
    this.PregnantDiagnosisTimingAPI = PregnantDiagnosisTimingAPI;
    this.CalvingConfigAPI = CalvingConfigAPI;
    this.FarmService = FarmService;
    this.$q = $q;

    this.init();
  }

  init() {
    this.isEditing = false;

    this.$q.all([
      this.FarmService.show(),
      this.ReproductionConfigService.show(),
      this.PregnantDiagnosisTimingAPI.show(),
      this.CalvingConfigAPI.show(),
    ]).then((res) => {
      const farm = new Farm(res[0]);
      this.farmType = farm.farmType();
      this.typeMilk = this.farmType === Farm.farmTypeMilk;
      this.typeBeef = this.farmType === Farm.farmTypeBeef;
      this.shouldShowCalvingConfig = farm.useAlertCalving();

      this.config = res[1].data;
      this.implementFreshCheckCaption = this.config.implementFreshCheckFlg ? '実施' : '未実施';

      this.pregnantDiagnosisTimings = res[2].data;
      this.calvingConfig = res[3].data;
    });
  }

  edit(target) {
    this.$modal.open({
      templateUrl: `menu/setting/reproduction/edit.html`,
      controller: 'ReproductionConfigEditController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        params: () => ({
          config: Object.assign({}, this.config),
          target: target,
          pregnantDiagnosisTimings: angular.copy(this.pregnantDiagnosisTimings, []),
          calvingConfig: Object.assign({}, this.calvingConfig),
          farmType: this.farmType
        })
      }
    }).result.then(() => this.init());
  }

  timingPeriodicityDisplay(timing) {
    return timing.periodicity === 'regular' ? '定期' : '不定期';
  }
}

app.controller('ReproductionConfigController', ReproductionConfigController);
