app.constant('BeefCountAttributes', {
  breeding: [
    {key: 'afterParturition0To30', label: '0~30'},
    {key: 'afterParturition30To60', label: '30~60'},
    {key: 'afterParturition60To90', label: '90~120'},
    {key: 'afterParturition90To120', label: '90~120'},
    {key: 'afterParturition120Over', label: '120~'},
    {key: 'ageBull0To6', label: '6ヶ月未満'},
    {key: 'ageBull6To12', label: '6~12ヶ月未満'},
    {key: 'ageBull12To18', label: '12~18ヶ月未満'},
    {key: 'ageBull18To24', label: '18~24ヶ月未満'},
    {key: 'ageBull24Over', label: '24ヶ月以上'},
    {key: 'ageCow0To6', label: '6ヶ月未満'},
    {key: 'ageCow6To12', label: '6~12ヶ月未満'},
    {key: 'ageCow12To18', label: '12~18ヶ月未満'},
    {key: 'ageCow18To24', label: '18~24ヶ月未満'},
    {key: 'ageCow24Over', label: '24ヶ月以上'},
  ]
});
