class ReproductionConfigService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  show() {
    return this.APIService.get('/reproduction/config');
  }

  update(params) {
    return this.APIService.put('/reproduction/config', params);
  }
}

app.service('ReproductionConfigService', ReproductionConfigService);
