// eslint-disable-next-line no-unused-vars
class LegacyEventType {
  static treatmentEventTypes() {
    return [
      'breedingDifficulty',
      'infect',
      'injury',
      'lame',
      'mastitis',
      'otherDifficulty',
      'perinatalDifficulty',
    ];
  }

  static calvedEventTypes() {
    return ['bunben', 'abort'];
  }

  static shipmentOrDeadEventTypes() {
    return ['touta', 'carcass', 'heishi'];
  }

  static isTreatmentEvent(legacyEventType) {
    return LegacyEventType.treatmentEventTypes().includes(legacyEventType);
  }

  static isCalvedEventTypes(legacyEventType) {
    return LegacyEventType.calvedEventTypes().includes(legacyEventType);
  }

  static isShipmentOrDeadEvent(legacyEventType) {
    return LegacyEventType.shipmentOrDeadEventTypes().includes(legacyEventType);
  }

  static get EVENT_CLASSES() {
    return {
      bunben: Calved,
      carcass: Carcass,
      mastitis: Mastitis
    };
  }

  static createEvent(legacyEventType, params) {
    const EventClass = LegacyEventType.EVENT_CLASSES[legacyEventType];
    return EventClass ? new EventClass(params) : params;
  }

  static inputDateCaption(legacyEventType) {
    if (LegacyEventType.isTreatmentEvent(legacyEventType)) {
      return '発生日';
    }

    const DATE_CAPTIONS = {
      abort: '流産日', bunben: '分娩日', carcass: '販売日', castration: '去勢日',
      dehorn: '除角日', doNotBreed: '繁殖除外日', fattening: '肥育開始日', freshCheck: '日付',
      gyugunidou: '移動日', hatsujo: '発情日', heishi: 'へい死日', hoofTrim: '削蹄日',
      hormoneProgram: '処置日', kannyu: '乾乳日', measurement: '測定日', ninshinkantei: '鑑定日',
      observation: '観察日', sagyomemo: '日付', shukkateishikaijo: '出荷再開日', tanetsuke: '種付日',
      touta: '出荷日', vaccine: '投与日', wean: '離乳日', embryo_recovery: '採卵日'
    };

    return DATE_CAPTIONS[legacyEventType];
  }
}
