// eslint-disable-next-line no-unused-vars
class WindowState {
  constructor() {
    'ngInject';

    // ウィンドウリサイズイベントを発火させるためのIE用のpolyfill
    (function() {
      if (typeof window.CustomEvent === 'function') return false;
      function CustomEvent(event, params) {
        params = params || {bubbles: false, cancelable: false, detail: null};
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
      }
      CustomEvent.prototype = window.Event.prototype;
      window.CustomEvent = CustomEvent;
    })();
  }

  resize() {
    window.dispatchEvent(new CustomEvent('resize'));
  }
}
