class EventSelectionController {
  constructor(
    $modalInstance,
    $modal,
    $scope,
    $state,
    $q,
    $timeout,
    cowIds,
    cowType,
    IsMobile,
    qwert,
    caller,
    DepoistAgentFarmService,
    SessionCache,
    ReproductionConfigService,
    MobileBulkEntryEventsModal,
    modalDialogFactory
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
    this.$modal = $modal;
    this.$scope = $scope;
    this.$state = $state;
    this.$q = $q;
    this.$timeout = $timeout;
    this.cowIds = cowIds;
    this.cowType = cowType;
    this.IsMobile = IsMobile;
    this.qwert = qwert;
    this.caller = caller;
    this.DepoistAgentFarmService = DepoistAgentFarmService;
    this.SessionCache = SessionCache;
    this.ReproductionConfigService = ReproductionConfigService;
    this.MobileBulkEntryEventsModal = MobileBulkEntryEventsModal;
    this.modalDialogFactory = modalDialogFactory;
    this.canEditFilterItem = !this.SessionCache.account().isItemFilterApplied();

    this.cow = {};

    this.init();
  }

  init() {
    this.calcMainAreaHeight();
    this.$q.all([
      this.SessionCache.farm(),
      this.ReproductionConfigService.show()
    ]).then((res) => {
      this.isBeefFarm = res[0].data.farmKind === '肉用';
      this.isMilkFarm = res[0].data.farmKind === '乳用';
      this.isFattening = res[0].data.farmTypeFattening;
      this.isBreeding = res[0].data.farmTypeBreeding;
      this.isAgentFarm = res[0].data.agentFarm;
      this.isDepositor = res[0].data.depositorFarm;
      this.implementFreshCheckFlg = res[1].data.implementFreshCheckFlg;
      this.setTypeList();
      if (this.isDepositor) {
        this.DepoistAgentFarmService.index().then((res) => {
          this.agentFarms = res.data;
          this.selectedQwert = this.qwert || this.agentFarms[0].id;
          this.qwertReadOnly = !!this.qwert;
        }).catch((err) => console.error(err));
      }
    });
  }

  calcMainAreaHeight() {
    // ウィンドウの高さを変更中に呼ばれると高さが不正になるためブロックする
    if (this.$scope.applying) {
      return;
    }
    this.$scope.applying = true;

    this.$timeout(() => {
      const windowHeight = $(window).innerHeight();
      const headerHeight = $('header.layout-header').innerHeight();
      this.$scope.$apply(() => {
        this.mainAreaHeight = windowHeight - headerHeight;
        this.$scope.applying = false;
      });
    });
  }

  setTypeList() {
    if (this.isMilkFarm) {
      this.cow.typeList = [
        {label: '乳牛', value: 'milking'},
        {label: '育成牛', value: 'calf'},
      ];
      this.cow.type = this.cowType || 'milking';
    }
    if (this.isBeefFarm) {
      this.cow.typeList = [
        {label: '肥育牛', value: 'fattening'},
        {label: '肉用繁殖牛', value: 'beefBreeding'},
        {label: '育成牛', value: 'calf'},
      ];

      if (this.isFattening && !this.isBreeding) {
        this.cow.type = 'fattening';
      } else if (!this.isFattening && this.isBreeding) {
        this.cow.type = 'beefBreeding';
      } else if (this.isFattening && this.isBreeding) {
        this.cow.type = 'beefBreeding';
      } else {
        this.cow.type = 'fattening';
      }
    }
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  selectEventType(eventType) {
    const eventName = EventType.eventNameForLegacy(eventType);

    if (!this.IsMobile && this.caller && this.caller.eventType === eventType) {
      this.$modalInstance.close();

      this.modalDialogFactory.showYesNoConfirm({
        title: '確認',
        text1: `前回と同じ${eventName}イベントが選択されています。`,
        text2: `${eventName}一括イベント登録を行いますか？`,
        no: true, yes: true
      }).result
        .then((result) => {
          if (result) {
            return this._selectEventType(eventType, this.cowIds);
          } else {
            return this.$modal.open({
              templateUrl: 'components/event-registration/event-selection.html',
              controller: 'EventSelectionController',
              controllerAs: 'ctrl',
              size: '1012',
              resolve: {
                cowIds: () => this.cowIds,
                cowType: () => null,
                isDepositor: () => this.isDepositor,
                qwert: () => this.qwert,
                caller: () => this.caller
              },
            });
          }
        });
    } else {
      return this._selectEventType(eventType, this.cowIds);
    }
  }

  _selectEventType(eventType, cowIds) {
    if (this.IsMobile) {
      return this.MobileBulkEntryEventsModal.open(
        eventType,
        cowIds,
        this.cow.type,
        this.isDepositor,
        this.selectedQwert,
        this.caller
      ).then((result) => {
        if (result.status && (result.status.submit || result.status.cancel)) {
          this.$modalInstance.close(result);
        }
      });
    } else {
      this.$state.go('cow-event-bulk-input', {
        eventType,
        cowIds,
        cowType: this.cow.type,
        isDepositor: this.isDepositor,
        qwert: this.selectedQwert,
        caller: this.caller
      }, {reload: true});
    }

    this.$modalInstance.close();
  }

  selectFarmClass() {
    if (this.qwertReadOnly) return 'select-farm disabled';
    return 'select-farm';
  }

  isNarrow() {
    if (this.cow.type === 'calf' || this.cow.type === 'fattening') return true;
    if (this.cow.type === 'beefBreeding' && !this.implementFreshCheckFlg) return true;
    if (this.cow.type === 'milking' && !this.implementFreshCheckFlg) return true;
    return false;
  }

  shouldShowCarcass() {
    return !this.isAgentFarm && this.canEditFilterItem;
  }
}
app.controller('EventSelectionController', EventSelectionController);
