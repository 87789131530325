// eslint-disable-next-line no-unused-vars
class DysstasiaChartGenerator {
  constructor(config = {}) {
    this.config = config;
  }

  draw(element, scores, alertAt) {
    return c3.generate({
      bindto: element,
      data: {
        json: scores,
        keys: {
          x: 'time',
          value: ['score']
        }
      },
      point: {
        show: false,
      },
      axis: {
        x: {
          label: {
            text: '時刻',
            position: 'outer-right',
          },
          type: 'timeseries',
          tick: {
            format: '%H:%M',
            culling: {
              max: this.config.cullingMax
            }
          }
        },
        y: {
          max: 48,
          min: 2,
          label: {
            text: '異常指数',
            position: 'outer-right'
          },
          padding: {
            top: 0,
            bottom: 0
          },
          tick: {
            format: d3.format('d')
          }
        }
      },
      grid: {
        x: {
          lines: [
            {value: alertAt, text: '検知時刻', class: 'chart-grid-line-red'},
          ]
        }
      },
      legend: {
        show: false
      },
      regions: [
        {axis: 'y', start: 25, class: 'dysstasia-gte-25'}
      ],
      size: this.config.size,
      tooltip: {
        show: false
      },
      padding: this.config.padding,
      zoom: {
        enabled: true,
      }
    });
  }
}
