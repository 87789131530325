class AlertEartagSensorAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  getAlerts(startDate, endDate, options) {
    const queryParams = {
      start_date: DateUtil.toYYYYMMDD(startDate, '-'),
      end_date: DateUtil.toYYYYMMDD(endDate, '-'),
      cow_no: 'cowNo' in options ? options.cowNo : null,
      active_only: options.inactive ? null : true,
      cow_group_id: 'cowGroupId' in options ? options.cowGroupId : null
    };

    const query = Object.keys(queryParams)
      .filter((key) => queryParams[key] !== null)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');

    return this.APIService.get('/alert/eartag_sensor?' + query);
  }
}

app.service('AlertEartagSensorAPI', AlertEartagSensorAPI);
