class CertificateAiModalController {
  constructor(
    $timeout,
    $modalInstance,
    params,
    blockUI,
    FarmService,
    SessionCache,
    StandardDialog
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$modalInstance = $modalInstance;
    this.params = params;
    this.blockUI = blockUI;
    this.FarmService = FarmService;
    this.SessionCache = SessionCache;
    this.StandardDialog = StandardDialog;

    this.init();
  }

  init() {
    this.model = angular.copy(this.params.farmData.certificateInseminator);
    this.prefectures = [
      '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県',
      '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県',
      '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県',
      '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県',
      '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県',
      '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県',
      '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'
    ];

    this.defaultLicencedPrefecture();
    this.showMessageModal();
  }

  showMessageModal() {
    if (this.licencedPrefectureRegistered()) return;

    this.StandardDialog.showMessage({
      title: '授精師設定',
      text1: '契約している外部の獣医師や授精師の情報を設定する際には、本人の承諾を得たうえで設定してください'
    });
  }

  licencedPrefectureRegistered() {
    return this.model.licencedPrefecture &&
      this.model.licencedNumber &&
      this.model.address &&
      this.model.name;
  }

  defaultLicencedPrefecture() {
    if (this.model) return;

    this.model = {};

    const farmAddress = this.params.farmData.farmAddress;

    if (farmAddress) {
      const matchPrefecture = farmAddress.match(/^(.{2}[都道府県]|.{3}県)/);

      if (matchPrefecture) {
        this.model.licencedPrefecture = matchPrefecture[0];
      }
    }
  }

  update() {
    const params = {
      certificateInseminator: this.model
    };

    this.params.farmData.certificateInseminator = this.model;
    this.blockUI.start('更新中');

    this.FarmService.update(params).then((res) => {
      this.blockUI.done(() => {
        this.blockUI.start('更新が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);
      });
      this.blockUI.stop();
      this.$modalInstance.close();

      const farm = new Farm(this.params.farmData);
      this.SessionCache.store('farm', farm);
    });
  }

  cancel() {
    this.$modalInstance.close();
  }

  clearLicencedPrefecture() {
    this.model.licencedPrefecture = '';
  }
}

app.controller('CertificateAiModalController', CertificateAiModalController);
