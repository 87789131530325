// eslint-disable-next-line no-unused-vars
class ReproductionPlannedEventsListControllerBinder {
  static bind(ctrl, executor) {
    angular.extend(ctrl, executor);

    ctrl.init = ctrl.init || executor.init.bind(ctrl);
    ctrl.updateDate = ctrl.updateDate || executor.updateDate.bind(ctrl);
    ctrl.shouldShowPrintAllButton = ctrl.shouldShowPrintAllButton || executor.shouldShowPrintAllButton.bind(ctrl);
    ctrl.onPrevDateClick = ctrl.onPrevDateClick || executor.onPrevDateClick.bind(ctrl);
    ctrl.onNextDateClick = ctrl.onNextDateClick || executor.onNextDateClick.bind(ctrl);
    ctrl.onCowNoClick = ctrl.onCowNoClick || executor.onCowNoClick.bind(ctrl);
    ctrl.selectRow = ctrl.selectRow || executor.selectRow.bind(ctrl);
    ctrl.selectAllRow = ctrl.selectAllRow || executor.selectAllRow.bind(ctrl);
    ctrl.initCallbackParam = ctrl.initCallbackParam || executor.initCallbackParam.bind(ctrl);
    ctrl.updateCallbackParam = ctrl.updateCallbackParam || executor.updateCallbackParam.bind(ctrl);
    ctrl.updateSelectedRows = ctrl.updateSelectedRows || executor.updateSelectedRows.bind(ctrl);
    ctrl.beforeFetchData = ctrl.beforeFetchData || executor.beforeFetchData.bind(ctrl);
    ctrl.fetchData = ctrl.fetchData || executor.fetchData.bind(ctrl);
    ctrl.afterFetchData = ctrl.afterFetchData || executor.afterFetchData.bind(ctrl);
  }
}
