/**
 * サマリー用
 * サマリーに新しい指標を追加する場合は追記する事
 */
app.constant('ReportSummary', {
  categories: {
    reproductionPerformance: [
      {
        label: '産次数',
        unit: '産',
        base: 'birthNumber'
      },
      {
        label: '発情発見率',
        unit: '%',
        base: 'heatDetectionRateAll'
      },
      {
        label: '妊娠率',
        unit: '%',
        base: 'pregnancyRateAll'
      },
      {
        label: '初回授精日数',
        unit: '日',
        base: 'daysFirstServiceAll'
      },
      {
        label: '空胎日数',
        unit: '日',
        base: 'daysOpenAll'
      },
      {
        label: '分娩間隔',
        unit: '日',
        base: 'calvingInterval'
      }
    ],
    carcassCharacteristic: [
      {
        label: '販売頭数',
        unit: '頭',
        base: 'soldNumber'
      },
      {
        label: '平均DG',
        unit: 'kg/日',
        base: 'averageDg'
      },
      {
        label: '平均枝肉重量',
        unit: 'kg',
        base: 'averageWeight'
      },
      {
        label: '平均枝肉単価',
        unit: '円',
        base: 'averageUnitPrice'
      },
      {
        label: '平均枝肉販売金額',
        unit: '円',
        base: 'averageSalesAmount'
      },
      {
        label: '合計販売金額',
        unit: '円',
        base: 'totalSalesAmount'
      }
    ]
  }
});
