class DepositCowLabelAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  bulkAdd(cowIds, labels) {
    return this.bulkAction(cowIds, labels, 'bulk_add');
  }

  bulkRemove(cowIds, labels) {
    return this.bulkAction(cowIds, labels, 'bulk_remove');
  }

  bulkAction(cowIds, labels, action) {
    const params = {
      cowIds: cowIds,
      masterCowLabelIds: labels.map((label) => label.masterCowLabelId)
    };
    return this.APIService.post(`/deposit/cow_label/${action}`, params);

  }
}

app.service('DepositCowLabelAPI', DepositCowLabelAPI);
