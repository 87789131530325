class EventSelectionAlert {
  constructor(modalDialogFactory) {
    'ngInject';
    this.modalDialogFactory = modalDialogFactory;
  }

  open(title, text1) {
    return this.modalDialogFactory.showAlertModal({
      title: title,
      text1: text1,
      no: false, yes: false
    });
  }
}

app.service('EventSelectionAlert', EventSelectionAlert);
