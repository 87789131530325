class DhiImportController {
  constructor(
    $modal,
    $timeout,
    $stateParams,
    blockUI,
    SessionCache,
    DhiImportAPI
  ) {
    'ngInject';

    this.$modal = $modal;
    this.$timeout = $timeout;
    this.$stateParams = $stateParams;
    this.blockUI = blockUI;
    this.farm = SessionCache.farm();
    this.DhiImportAPI = DhiImportAPI;
    this.isUploading = false;

    this.init();
  }

  init() {
    this.dhiLinkage = this.farm.data.dhiLinkage;
    this.activeTab = 'dataImport';
    this.activeImportType = this.showBothImport() ? 'hmrt' : this.dhiLinkage;
    this.defaultImportType = this.activeImportType;

    if (this.$stateParams.caller.state === 'dhi-import-detail') {
      this.changeTab('dataImportHistory');
    }
  }

  showDataImport() {
    return this.activeTab === 'dataImport';
  }

  showDataImportHistory() {
    return this.activeTab === 'dataImportHistory';
  }

  showBothImport() {
    return this.dhiLinkage === 'both';
  }

  changeTab(tab) {
    if (this.activeTab === tab) return;
    this.activeTab = tab;
    this.activeImportType = this.defaultImportType;
  }

  classTab(tab) {
    return this.activeTab === tab ? 'contents__tabItem--active' : 'contents__tabItem';
  }

  classHeading() {
    return this.showBothImport() ? 'contents__heading' : 'contents__heading--first';
  }

  formatErrorMessage(res, defaultMessage) {
    if (res.data && res.data.messages) {
      return ErrorUtil.formatErrorMessage(res.data.messages).replace(/\n|,/g, '<br>');
    }

    return defaultMessage;
  }

  uploadSelectedFiles(fd) {
    if (this.isUploading) return;

    this.isUploading = true;

    this.blockUI.start('アップロード中');

    this.DhiImportAPI.upload(fd).then((res) => {
      this.blockUI.stop();
      this.blockUI.done(() => {
        this.blockUI.start('検定データのアップロードが完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
          this.changeTab('dataImportHistory');
        }, 1000);
      });
      this.blockUI.stop();
    }).catch((res) => {
      let message;
      if (res.status === 503) {
        const errorMessage = [
          '検定データの取り込み処理中です。',
          '処理の完了まで15分程度お待ち下さい。',
          '',
          '処理が完了したらデータ取込履歴画面に反映されます。',
          '取込み失敗している場合には、さらに5分程度時間をおいてから、',
          '再度取込み操作を行ってください。'
        ].join('<br>');
        message = this.formatErrorMessage({}, errorMessage);
      } else {
        message = this.formatErrorMessage(res, '検定データの取込でエラーが発生しました。');
      }

      this.blockUI.stop();
      this.$modal.open({
        windowTemplateUrl: 'components/u-modal/window.html',
        templateUrl: 'menu/dhi/dhi-import/modal.html',
        controller: 'DhiImportModalController',
        controllerAs: 'ctrl',
        backdrop: false,
        resolve: {
          params: () => {
            return {
              size: 'small',
              mode: 'message',
              heading: '検定データ取込',
              message: message
            };
          }
        }
      });
    }).finally(() => this.isUploading = false);
  }
}

app.controller('DhiImportController', DhiImportController);
