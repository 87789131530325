class EventRegisterService {
  constructor(
    // CowTimelineController初期化用
    $modal,
    $q,
    $rootScope,
    $timeout,
    FarmService,
    appConfig,
    appConst,
    blockUI,
    CarcassService,
    CowGroupService,
    MasterHormoneProgramAPI,
    IsMobile,
    MasterMedicineAPI,
    modalDialogFactory,
    SelectBoxFactory,
    SelectionService,
    SelectMedicineModal,
    DateUtilService,
    TimelineService,
    DoNotBreedAlert,
    EventSelectionSimpleConfirm,
    EventSelectionMultipleChoicesConfirm,
    UtilService,
    AlertService,
    MasterSpermAPI,
    // 共通
    CowService,
    CowEventsService,
    DateTimeUtilFactory,
    // EventRegisterService用
    $controller,
    TimelineContext,
    DepositTimelineContext
  ) {
    'ngInject';

    this.CowEventsService = CowEventsService;
    this.$controller = $controller;
    this.CowService = CowService;
    this.DateTimeUtilFactory = DateTimeUtilFactory;

    const $scope = $rootScope.$new();

    this.cowTimelineController = $controller('CowTimelineController', {
      $modal,
      $q,
      $rootScope,
      $scope,
      $timeout,
      FarmService,
      appConfig,
      appConst,
      blockUI,
      CarcassService,
      CowEventsService,
      CowService,
      DateTimeUtilFactory,
      CowGroupService,
      MasterHormoneProgramAPI,
      IsMobile,
      MasterMedicineAPI,
      modalDialogFactory,
      SelectBoxFactory,
      SelectionService,
      SelectMedicineModal,
      DateUtilService,
      TimelineService,
      DoNotBreedAlert,
      EventSelectionSimpleConfirm,
      EventSelectionMultipleChoicesConfirm,
      UtilService,
      AlertService,
      MasterSpermAPI,
      TimelineContext,
      DepositTimelineContext
    });
  }

  displayEventSelection(cowId, callerState) {
    if (!cowId) return new Promise((resolved, rejected) => {
      resolved();
    });

    this.CowEventsService.selectedCowId = cowId;

    return this.CowService.get(cowId).then((res) => {
      this.CowEventsService.selectedCow =
      this.cowTimelineController.cow =
      this.DateTimeUtilFactory.parseClientDateForCowInfo(res.data);

      return this.cowTimelineController.initialize();
    }).then(() => {
      this.cowTimelineController.showDetailAfterPost = true;
      this.cowTimelineController.displayEventSelection(callerState);
      this.cowTimelineController.callerState = callerState;
    });
  }

}

app.service('EventRegisterService', EventRegisterService);
