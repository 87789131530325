/**
 * 起立困難アラート設定用のコンポーネント
 *
 * ex.
 *  <alert-dysstasia-config>
 *  </alert-dysstasia-config>
 */
class AlertDysstasiaConfigController {
  constructor(
    $q,
    $modal,
    AlertDysstasiaDetectionThresholdAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.$modal = $modal;
    this.AlertDysstasiaDetectionThresholdAPI = AlertDysstasiaDetectionThresholdAPI;

    this.init();
  }

  init() {
    this.$q.all([
      this.AlertDysstasiaDetectionThresholdAPI.index(),
    ]).then((res) => {
      this.thresholds = AlertDysstasiaDetectionThreshold.toViewModel(res[0].data.thresholds);
      this.thresholdOptions = AlertDysstasiaDetectionThresholdOption.toViewModel(res[0].data.thresholdOptions);
    }).catch((err) => console.error(err));
  }

  edit() {
    const thresholds = angular.copy(this.thresholds);
    const thresholdOptions = angular.copy(this.thresholdOptions);

    this.$modal.open({
      templateUrl: 'menu/setting/alert/dysstasia-config/edit/index.html',
      controller: 'AlertDysstasiaConfigEditController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'md',
      resolve: {
        params: () => ({
          thresholds: thresholds,
          thresholdOptions: thresholdOptions
        })
      }
    }).result.then((res) => {
      if (res) this.init();
    });
  }
}

function alertDysstasiaConfigComponent() {
  return {
    templateUrl: 'menu/setting/alert/dysstasia-config/index.html',
    controller: AlertDysstasiaConfigController,
    bindings: {}
  };
}

app.component('alertDysstasiaConfig', alertDysstasiaConfigComponent());
