class temporaryGroupViewCowDetailStackedBarChartController {
  constructor(
    SessionCache,
    ChartGenerator
  ) {
    'ngInject';

    this.SessionCache = SessionCache;
    this.ChartGenerator = ChartGenerator;

    this.init();
  }

  $onDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  $onChanges(changes) {
    if (!(Object.keys(this.cow).length && this.baseDate && this.activityDate && this.minActivityDate)) return;

    this.index();
  }

  init() {
    const defaults = {
      chartDisplayRange: 30
    };

    this.defaults = this.SessionCache.cowDetailState() || defaults;
    this.activityPeriod = this.defaults.chartDisplayRange;
    this.chart = new ActivityStackedBarChart('#activity-stacked-bar-chart');
    this.loading = true;
  }

  index() {
    this.isDateInit = true;
    this.drawChart(this.activityDate);
  }

  /**
   * チャートを描画する
   *
   * @param {Date} targetDate 基準日
   */
  drawChart(targetDate) {
    this.loading = true;
    this.chart.clear();

    this.ChartGenerator.activityChart(
      targetDate,
      this.cow.cowId,
      this.activityPeriod || this.defaults.chartDisplayRange
    ).then((res) => {
      this.loading = false;
      this.chartData = res;

      if (!Object.keys(this.chartData).length) return;

      const kind = this.activityPeriod === 7 ?
        ActivityStackedBarChart.KIND.DATE_TIME : ActivityStackedBarChart.KIND.DATE;
      this.chart.show(this.chartData, kind);
    });
  }

  /**
   * 活動量の表示期間を変更する
   *
   * @param {string} retreat prev or next
   */
  moveRangeActivity(retreat) {
    const target = new Date(this.activityDate);
    if (retreat === 'prev') {
      target.setDate(target.getDate() - this.activityPeriod);
    } else {
      target.setDate(target.getDate() + this.activityPeriod);
    }
    this.activityDate = target;

    this.drawChart(target);
  }

  /**
   * チャート表示期間を変更する
   *
   * @param {number} period 表示日数
   */
  changePeriodOfActivity(period) {
    this.defaults.chartDisplayRange = period;
    this.SessionCache.store('cowDetailState', this.defaults);
    this.activityPeriod = period;
    this.setActivitySelectableRange();

    let target = this.activityDate;
    this.drawChart(target);
  }

  setActivitySelectableRange(currentDate = null) {
    const date = currentDate || DateUtil.today();
    const isHourlySummary = this.activityPeriod === 7;
    this.minActivityDate = Activity.selectableMinimumDate(
      date,
      isHourlySummary,
      this.cowBleStartDate
    );

    if (DateUtil.isSameOrBeforeDay(this.activityDate, this.minActivityDate)) {
      this.activityDate = DateUtil.toDate(this.minActivityDate);
    }
  }

  /**
   * カレンダーから活動量の表示期間を変更する
   */
  changeRangeActivityFromCalendar() {
    let target = this.activityDate;
    this.drawChart(target);
  }

  showPreviousDate() {
    return Number(this.activityDate) > Number(this.minActivityDate);
  }

  showNextDate() {
    return Number(this.activityDate) < Number(this.baseDate);
  }

  showMessage() {
    if (this.loading) return false;

    return !Object.keys(this.chartData).length;
  }

  showChart() {
    if (this.loading) return false;

    return Object.keys(this.chartData).length;
  }
}

function temporaryGroupViewCowDetailStackedBarChart() {
  return {
    restrict: 'E',
    templateUrl: 'temporary-group-view/cow-detail/summary/stacked-bar-chart.html',
    controller: temporaryGroupViewCowDetailStackedBarChartController,
    controllerAs: 'ctrl',
    scope: true,
    replace: true,
    bindToController: {
      cow: '<',
      baseDate: '<',
      activityDate: '<',
      minActivityDate: '<'
    }
  };
}

app.directive('temporaryGroupViewCowDetailStackedBarChart', temporaryGroupViewCowDetailStackedBarChart);
