class MobileCalvingSensorHistoryModalController {
  constructor(
    $modalInstance,
    $timeout,
    blockUI,
    CowCalvingSensorHistoryAPI,
    MobileEditCalvingSensorHistoryConfirmModal,
    modalDialogFactory,
    param
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.CowCalvingSensorHistoryAPI = CowCalvingSensorHistoryAPI;
    this.MobileEditCalvingSensorHistoryConfirmModal = MobileEditCalvingSensorHistoryConfirmModal;
    this.modalDialogFactory = modalDialogFactory;
    this.cowId = param.cowId;

    const calvingSensorHistories = param.calvingSensorHistories;
    this.calvingSensorHistories = calvingSensorHistories.map((obj) => {
      return CowCalvingSensorHistory.toDisplayObj(obj);
    }).reverse();
    this.lastCalvingSensorHistory = this.calvingSensorHistories[0];
  }

  onDelete() {
    if (!this.lastCalvingSensorHistory) return;

    const blockUIMoment = (message) => {
      this.blockUI.start(message);
      this.$timeout(() => {
        this.blockUI.stop();
      }, 1000);
    };

    const showErrorMessage = (message) => {
      this.modalDialogFactory.showMobileAlertDialog({
        texts: [message]
      });
    };

    this.MobileEditCalvingSensorHistoryConfirmModal.open({
      title: '確認',
      texts: [
        '下記の分娩センサー番号履歴を削除しますか？',
        `分娩センサー番号：${this.lastCalvingSensorHistory.calvingSensorNumber}`,
        `適用日：${this.lastCalvingSensorHistory.startDate}`,
        `解除日：${this.lastCalvingSensorHistory.endDate}`
      ],
      yesLabel: '削除する',
      noLabel: 'キャンセル'
    }).result.then((r) => {
      if (r) {
        this.blockUI.start('解除中');
        this.CowCalvingSensorHistoryAPI.delete(this.lastCalvingSensorHistory.id)
          .then(() => {
            this.blockUI.stop();
            blockUIMoment('履歴の削除が完了しました');
            this.$modalInstance.close(true);
          })
          .catch((e) => {
            console.error(e);
            this.blockUI.stop();
            showErrorMessage(ErrorUtil.formatErrorMessage(e.data.messages));
          });
      }
    });
  }

  onCancel() {
    this.$modalInstance.close(false);
  }
}

app.controller('MobileCalvingSensorHistoryModalController', MobileCalvingSensorHistoryModalController);
