/**
 * @deprecated NotificationAPIを利用してください
 * ※この実装の修正および、新規の利用は禁止です
 */
class NotificationsService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/notification');
  }
}

app.service('NotificationsService', NotificationsService);
