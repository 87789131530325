class CustomlistRunner {
  static get filterColumnIds() {
    return [
      'purchase_price',
      'sales_price',
      'dressed_carcass_unit_price',
      'dressed_carcass_sales_price',
    ];
  }

  constructor($q, CustomlistAPI, Dictionary, SessionCache, AlertConfigService) {
    'ngInject';
    this.$q = $q;
    this.CustomlistAPI = CustomlistAPI;
    this.Dictionary = Dictionary;
    this.account = SessionCache.account();
    AlertConfigService.show().then((res) => {
      this.showAcuteIllness = res.data.showAcuteIllness;
    });
  }

  run(customlistId, date, option = {}) {
    return this.$q.all([
      this.CustomlistAPI.runtime(customlistId),
      this.CustomlistAPI.run(customlistId, date, option)
    ]).then((res) => {
      const config = res[0].data;
      const cows = res[1].data;

      config.columns = this.itemFilter(config.columns, this.account.isItemFilterApplied());

      config.columns.forEach((c) => c.actualCaption = c.shortCaption || c.caption);

      config.columns
        .filter((column) => column.fieldType === 'DATE' || column.fieldType === 'DATETIME')
        .forEach((column) => {
          const displayStyle = column.displayStyle ? column.displayStyle : Customlist.DEFAULT_DATE_STYLE;
          const styles = displayStyle.split('-');
          const option = {
            year: styles[0],
            month: styles[1],
            day: styles[2],
            separator_style: styles[3]
          };
          column.dateFormat = option;
        });

      const cowNoColumn = config.columns.find((column) => column.columnId === 'cow_no');
      if (cowNoColumn) cowNoColumn.caption = this.Dictionary.COW.COW_NO;

      const converter = new Customlist(this.showAcuteIllness);
      const converted = converter.convert(config.columns, cows, date);

      const result = {
        data: {
          config: config,
          cows: converted
        }
      };

      const deferred = this.$q.defer();
      deferred.resolve(result);
      return deferred.promise;
    });
  }

  search(customlistId, condition) {
    return this.$q.all([
      this.CustomlistAPI.runtime(customlistId),
      this.CustomlistAPI.search(customlistId, condition)
    ]).then((res) => {
      const config = res[0].data;
      const cows = res[1].data.cows;
      const limitOver = res[1].data.limitOver;

      config.columns = this.itemFilter(config.columns, this.account.isItemFilterApplied());

      config.columns.forEach((c) => c.actualCaption = c.shortCaption || c.caption);

      const converter = new Customlist();
      const converted = converter.convert(config.columns, cows, DateUtil.today());

      const result = {
        data: {
          config: config,
          cows: converted,
          limitOver: limitOver
        }
      };

      const deferred = this.$q.defer();
      deferred.resolve(result);
      return deferred.promise;
    });
  }

  itemFilter(columns, isItemFilterApplied) {
    if (!isItemFilterApplied) {
      return columns;
    }

    const filterColumnIds = CustomlistRunner.filterColumnIds;
    return columns.filter((column) => {
      const isFilterItem = filterColumnIds.includes(column.columnId);
      return !isFilterItem;
    });
  }
}

app.service('CustomlistRunner', CustomlistRunner);
