class MobileGroupTransferHistoryCurrentFarmFilterController {
  constructor(
    $modalInstance,
    params
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
    this.state = this.initialState();

    this.init(params);
  }

  init(params) {
    const sortColumns = params.sortColumns;
    const filterColumns = params.filterColumns;
    const conditions = params.conditions;
    const sortColumnId = params.sortColumnId || null;
    const sortOrder = params.sortOrder || 'ascending';

    this.initialSortOptions(sortColumns);

    const newState = this.initialConditions(filterColumns, conditions);

    newState.isInitialized = true;
    newState.sortColumnId = sortColumnId;
    newState.sortOrder = sortOrder;

    this.setState(newState);

    this.state.conditions.forEach((c) => {
      if (c.conditionType === 'DATE') {
        c.showDateCondition = true;
      } else if (c.conditionType === 'SELECTION') {
        c.showSelectionCondition = true;
      }
    });

    this.mode = 'filter';
  }

  initialState() {
    return {
      isInitialized: false,
      filterColumns: [],
      currentConditions: [],
      selectionConditions: [],
    };
  }

  initialSortOptions(sortColumns) {
    this.state.sortOptions = sortColumns.map((column) => {
      return {
        value: column.columnId,
        label: column.name
      };
    });
  }

  initialConditions(filterColumns, conditions) {
    const state = {};

    state.filterColumns = filterColumns;

    state.filterOptions = filterColumns.map((column) => {
      return {
        value: column.columnId,
        label: column.name
      };
    });

    state.filterColumnMap = state.filterColumns.reduce((obj, column) => {
      obj[column.columnId] = column;
      return obj;
    }, {});

    state.conditions = Object.entries(conditions).reduce((acc, [key, value]) => {
      const column = state.filterColumnMap[key];

      if (column.conditionType === 'DATE') {
        acc.push({
          columnId: key,
          conditionType: column.conditionType,
          conditionMinDate: value.minDate,
          conditionMaxDate: value.maxDate
        });

        return acc;
      }

      acc.push({
        columnId: key,
        conditionType: column.conditionType,
        selectionOptions: column.selectionOptions,
        selected: value
      });

      return acc;
    }, []).filter((item) => {
      if (item.conditionType === 'DATE') {
        return item.conditionMinDate && item.conditionMaxDate;
      }

      return item.selected;
    });

    return state;
  }

  onCancelClick() {
    this.$modalInstance.dismiss('cancel');
  }

  onSubmitClick() {
    const result = {};

    result.conditions = this.state.conditions.reduce((acc, cur) => {

      if (cur.conditionType === 'DATE') {
        acc[cur.columnId] = {
          minDate: cur.conditionMinDate,
          maxDate: cur.conditionMaxDate
        };

        return acc;
      }

      acc[cur.columnId] = cur.selected;

      return acc;
    }, {});

    result.sortColumnId = this.state.sortColumnId;
    result.sortOrder = this.state.sortOrder;

    result.status = {
      submit: true
    };

    this.$modalInstance.close(result);
  }

  setState(state) {
    Object.assign(this.state, state);
  }

  showSort() {
    return this.mode === 'sort';
  }

  showFilter() {
    return this.mode === 'filter';
  }

  classModalFormConditionToggleBackdrop(model, value) {
    return model === value ? 'uModal__formConditionToggleBackdrop' : 'uModal__formConditionToggleBackdrop--right';
  }

  classModalFormConditionToggleText(model, value) {
    return model === value ? 'uModal__formConditionToggleText--active' : 'uModal__formConditionToggleText';
  }

  classMain() {
    const openSelectMultiple = this.conditions.some((condition) => {
      return condition.openSelectMultiple;
    });

    return openSelectMultiple ? 'uModal__formConditionMain--openSelectMultiple' : 'uModal__formConditionMain';
  }

  openSelectMultiple(condition, event) {
    condition.openSelectMultiple = true;
  }

  closeSelectMultiple(condition) {
    condition.openSelectMultiple = false;
  }

  classSelectMultipleBackdrop(condition) {
    return condition.openSelectMultiple ?
      'uModal__formConditionSelectMultipleBackdrop--open' : 'uModal__formConditionSelectMultipleBackdrop';
  }

  classSelectMultipleFocus(condition) {
    return condition.openSelectMultiple ?
      'uModal__formConditionSelectMultipleFocus--focus' : 'uModal__formConditionSelectMultipleFocus';
  }

  classSelectMultipleList(condition) {
    return condition.openSelectMultiple ?
      'uModal__formConditionSelectMultipleList--open' : 'uModal__formConditionSelectMultipleList';
  }

  formatSelections(selectionOptions) {
    if (!selectionOptions) return;

    return selectionOptions.filter((s) => s.selected).map((s) => s.label).join('、');
  }
}

app.controller('MobileGroupTransferHistoryCurrentFarmFilterController', MobileGroupTransferHistoryCurrentFarmFilterController);
