class HomeHeadageSummaryDetailController {
  constructor(
    $timeout,
    $modalInstance,
    params
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$modalInstance = $modalInstance;
    this.params = params;

    this.init();
  }

  init() {
    this.data = this.params.data;

    this.$timeout(() => {
      this.masonry = new Masonry(document.querySelector('#masonry__headageSummaryDetail'), {
        itemSelector: '.masonry__item'
      });
    });
  }

  close() {
    this.$modalInstance.close();
  }
}

app.controller('HomeHeadageSummaryDetailController', HomeHeadageSummaryDetailController);
