class MobileIndexController {
  constructor(
    $window,
    $modal,
    $state,
    $stateParams,
    $timeout,
    SessionStateService,
    FarmService,
    AccountService,
    Dictionary,
    blockUI,
    $scope
  ) {
    'ngInject';

    $timeout(() => {
      if ($stateParams.reload === true) $window.location.reload();
    });

    this.$window = $window;
    this.$modal = $modal;
    this.$state = $state;
    this.SessionStateService = SessionStateService;
    this.FarmService = FarmService;

    this.Dictionary = Dictionary;
    this.blockUI = blockUI;
    this.from = $stateParams.from;
    this.$timeout = $timeout;

    this.callerState = {state: 'mobile-top'};
    this.carouselItems = [0];

    AccountService.cachedAccount().then((account) => {
      this.showSwitchFarm = account.switchFarm;
    });

    this.init();
  }

  init() {
    this.getFarmKind();
    this.selectedTabIndex = 0;
    if (this.from === 'reproduction-planned-events') {
      this.$timeout(() => {
        this.selectedTabIndex = 1;
      });
    }
  }

  onTabClick(index, dummy) {
    if (!this.shouldDisableTasksTab()) {
      this.selectedTabIndex = index;
    }
  }

  isTabActive(index) {
    return this.selectedTabIndex === index ? true : false;
  }

  switchFarm() {
    this.SessionStateService.switchFarm().then((res) => {
      this.$state.go('mobile-top');
      this.$window.location.reload();
    });
  }

  shouldDisableTasksTab() {
    if (!this.farm) return false;
    return !this.farm.isDairy() && !this.farm.isBreeding() && this.farm.isFattening();
  }

  // private
  getFarmKind() {
    this.FarmService.show().then((res) => {
      this.farm = new Farm(res);
      this.farmKind = res.farmKind;
      this.farmName = this.farm.data.farmName;
      if (!this.shouldDisableTasksTab()) {
        this.carouselItems.push(1);
      }
    }).catch((err) => console.error(err));
  }
}

app.controller('MobileIndexController', MobileIndexController);
