// eslint-disable-next-line no-unused-vars
class EventType {
  static get allTypes() {
    return [
      // 治療
      {group: '治療', color: 3, eventType: 'mastitis', legacyEventType: 'mastitis', label: '乳房炎'},
      {group: '治療', color: 3, eventType: 'claw_diseases', legacyEventType: 'lame', label: '跛行・蹄病'},
      {group: '治療', color: 3, eventType: 'reproductive_disorders', legacyEventType: 'breedingDifficulty', label: '繁殖障害'},
      {group: '治療', color: 3, eventType: 'perinatal_and_metabolic_diseases', legacyEventType: 'perinatalDifficulty', label: '周産病・代謝病'},
      {group: '治療', color: 3, eventType: 'injured', legacyEventType: 'injury', label: '外傷'},
      {group: '治療', color: 3, eventType: 'infection_diseases', legacyEventType: 'infect', label: '感染症'},
      {group: '治療', color: 3, eventType: 'others', legacyEventType: 'otherDifficulty', label: 'その他'},
      // 育成
      {group: '育成', color: 8, eventType: 'individual_observation', legacyEventType: 'observation', label: '個体観察'},
      {group: '育成', color: 9, eventType: 'weaned', legacyEventType: 'wean', label: '離乳'},
      {group: '育成', color: 4, eventType: 'dehorned', legacyEventType: 'dehorn', label: '除角'},
      {group: '育成', color: 4, eventType: 'castrated', legacyEventType: 'castration', label: '去勢'},
      {group: '育成', color: 2, eventType: 'fattened', legacyEventType: 'fattening', label: '肥育'},
      // 繁殖
      {group: '繁殖', color: 8, eventType: 'fresh_check', legacyEventType: 'freshCheck', label: 'フレッシュチェック'},
      {group: '繁殖', color: 3, eventType: 'heat', legacyEventType: 'hatsujo', label: '発情'},
      {group: '繁殖', color: 4, eventType: 'bred', legacyEventType: 'tanetsuke', label: '種付'},
      {group: '繁殖', color: 2, eventType: 'pregnant_diagnosis', legacyEventType: 'ninshinkantei', label: '妊娠鑑定'},
      {group: '繁殖', color: 7, eventType: 'aborted', legacyEventType: 'abort', label: '流産'},
      {group: '繁殖', color: 7, eventType: 'calved', legacyEventType: 'bunben', label: '分娩'},
      {group: '繁殖', color: 8, eventType: 'timed_ai', legacyEventType: 'hormoneProgram', label: 'ホルモンプログラム'},
      {group: '繁殖', color: 10, eventType: 'do_not_breed', legacyEventType: 'doNotBreed', label: '繁殖除外'},
      {group: '繁殖', color: 7, eventType: 'embryo_recovery', legacyEventType: 'embryo_recovery', label: '採卵'},
      // その他
      {group: 'その他', color: 7, eventType: 'body_weight_measurement', legacyEventType: 'measurement', label: '体重測定'},
      {group: 'その他', color: 8, eventType: 'work_note', legacyEventType: 'sagyomemo', label: '作業メモ'},
      {group: 'その他', color: 7, eventType: 'vaccination', legacyEventType: 'vaccine', label: 'ワクチン'},
      {group: 'その他', color: 9, eventType: 'dried', legacyEventType: 'kannyu', label: '乾乳'},
      {group: 'その他', color: 10, eventType: 'culled', legacyEventType: 'touta', label: '出荷'},
      {group: 'その他', color: 11, eventType: 'died', legacyEventType: 'heishi', label: 'へい死'},
      {group: 'その他', color: 12, eventType: 'claw_trimmed', legacyEventType: 'hoofTrim', label: '削蹄'},
      {group: 'その他', color: 12, eventType: 'moved', legacyEventType: 'gyugunidou', label: '牛群移動'},
      {group: 'その他', color: 7, eventType: 'carcass_characteristic', legacyEventType: 'carcass', label: '枝肉成績'},

      // 牧場日誌
      {group: '牧場メモ', eventType: 'farm_diary', legacyEventType: 'nisshi', label: '牧場日誌'},
    ];
  }

  static eventName(eventType) {
    const EVENT_NAMES = {
      mastitis: '乳房炎', reproductive_disorders: '繁殖障害', perinatal_and_metabolic_diseases: '周産病・代謝病',
      injured: '外傷', claw_diseases: '蹄病・跛行', infection_diseases: '感染症', others: 'その他',
      fresh_check: 'フレッシュチェック', heat: '発情', timed_ai: 'ホルモンプログラム', bred: '種付',
      pregnant_diagnosis: '妊娠鑑定', aborted: '流産', calved: '分娩', embryo_recovery: '採卵',
      do_not_breed: '繁殖除外', moved: '牛群移動', dried: '乾乳', vaccination: 'ワクチン', work_note: '作業メモ',
      claw_trimmed: '削蹄', individual_observation: '個体観察', weaned: '離乳', dehorned: '除角',
      castrated: '去勢', fattened: '肥育', body_weight_measurement: '体重測定', culled: '出荷', died: 'へい死',
      carcass_characteristic: '枝肉成績', open_withholding_time: '出荷停止解除', farm_diary: '牧場日誌'
    };
    return EVENT_NAMES[eventType];
  }

  static eventNameForLegacy(legacyEventType) {
    const eventNames = {
      mastitis: '乳房炎', lame: '跛行・蹄病', breedingDifficulty: '繁殖障害',
      perinatalDifficulty: '周産病・代謝病', injury: '外傷', infect: '感染症',
      otherDifficulty: 'その他',
      freshCheck: 'フレッシュチェック', hatsujo: '発情', tanetsuke: '種付',
      ninshinkantei: '妊娠鑑定', kannyu: '乾乳',
      abort: '流産', bunben: '分娩',
      hormoneProgram: 'ホルモンプログラム', doNotBreed: '繁殖除外', embryo_recovery: '採卵',
      touta: '出荷', heishi: 'へい死', carcass: '枝肉成績',
      wean: '離乳', dehorn: '除角', castration: '去勢', fattening: '肥育',
      observation: '個体観察', measurement: '体重測定',
      gyugunidou: '牛群移動', vaccine: 'ワクチン', hoofTrim: '削蹄', sagyomemo: '作業メモ',
    };
    return eventNames[legacyEventType];
  }

  static toLegacyEventType(eventType) {
    const type = EventType.allTypes.find((t) => t.eventType === eventType);
    if (!type) {
      throw new Error(`Invalid event type: ${eventType}`);
    }
    return type.legacyEventType;
  }

  static convertMap() {
    return EventType.allTypes.reduce((result, type) => {
      result[type.legacyEventType] = type.eventType;
      return result;
    }, {});
  }

  static isTreatment(eventType) {
    return [
      'mastitis',
      'claw_diseases',
      'reproductive_disorders',
      'perinatal_and_metabolic_diseases',
      'injured',
      'infection_diseases',
      'others'
    ].includes(eventType);
  }

  static isTreatmentForLegacy(legacyEventType) {
    return [
      'mastitis',
      'lame',
      'breedingDifficulty',
      'perinatalDifficulty',
      'injury',
      'infect',
      'otherDifficulty'
    ].includes(legacyEventType);
  }

  static isMedicationForLegacy(legacyEventType) {
    return [
      'mastitis',
      'lame',
      'breedingDifficulty',
      'perinatalDifficulty',
      'injury',
      'infect',
      'otherDifficulty',
      'vaccine',
    ].includes(legacyEventType);
  }

  static isReproduction(eventType) {
    return [
      'fresh_check',
      'heat',
      'bred',
      'pregnant_diagnosis',
      'aborted',
      'calved',
      'timed_ai',
      'do_not_breed',
      'embryo_recovery'
    ].includes(eventType);
  }

  static isCalvedOrAbortedForLegacy(legacyEventType) {
    return [
      'bunben',
      'abort',
    ].includes(legacyEventType);
  }

  static isReproductionForLegacy(legacyEventType) {
    return [
      'freshCheck',
      'hatsujo',
      'hormoneProgram',
      'tanetsuke',
      'embryo_recovery',
      'ninshinkantei',
      'kannyu',
      'bunben',
      'abort',
      'doNotBreed',
    ].includes(legacyEventType);
  }

  static isGrowingCalf(eventType) {
    return [
      'individual_observation',
      'weaned',
      'dehorned',
      'castrated',
      'fattened',
    ].includes(eventType);
  }

  static isOther(eventType) {
    return [
      'moved',
      'dried',
      'vaccination',
      'work_note',
      'claw_trimmed',
      'body_weight_measurement',
      'culled',
      'died',
      'carcass_characteristic',
    ].includes(eventType);
  }

  static canEditAfterCalved(eventType) {
    return [
      'mastitis',
      'perinatal_and_metabolic_diseases',
      'reproductive_disorders',
      'injured',
      'infection_diseases',
      'claw_diseases',
      'others',
      'timed_ai',
      'individual_observation',
      'weaned',
      'dehorned',
      'castrated',
      'fattened',
      'moved',
      'body_weight_measurement',
      'vaccination',
      'claw_trimmed',
      'work_note'
    ].includes(eventType);
  }

  static canEditAfterCalvedForLegacy(legacyEventType) {
    return [
      'mastitis',
      'perinatalDifficulty',
      'lame',
      'breedingDifficulty',
      'injury',
      'infect',
      'otherDifficulty',
      'hormoneProgram',
      'observation',
      'wean',
      'dehorn',
      'castration',
      'fattening',
      'gyugunidou',
      'measurement',
      'vaccine',
      'hoofTrim',
      'sagyomemo'
    ].includes(legacyEventType);
  }
}
