function umBackdropDirective($rootScope) {
  return {
    restrict: 'A',
    link: function(scope, element) {
      element.on('click', (event) => {
        $rootScope.$broadcast('onClickUmBackdrop');
      });
    }
  };
}

app.directive('umBackdrop', ['$rootScope', umBackdropDirective]);
