class ReproductionAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  cow(cowId) {
    return this.APIService.get(`/reproduction/cow/${cowId}`);
  }
}

app.service('ReproductionAPI', ReproductionAPI);
