class temporaryGroupViewCowDetailScatterPlotChartController {
  constructor(
    $q,
    $state,
    Dictionary,
    ChartActivityScatterPlotAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.Dictionary = Dictionary;
    this.ChartActivityScatterPlotAPI = ChartActivityScatterPlotAPI;

    this.init();
  }

  $onDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  $onChanges() {
    if (!(Object.keys(this.cow).length && this.baseDate && this.protDate && this.minProtDate &&
        this.fatteningStage)) return;

    this.index();
  }

  init() {
    this.chart = new ActivityScatterPlotChart('#activity-scatter-plot-chart', this.Dictionary);
    this.chart.afterClickEventHandler = (cowId) => {
      this.$state.go('cowDetail', {
        cowId: cowId
      });
    };

    this.protFilter = 'all';
    this.loading = true;
  }

  index() {
    this.drawChart();
  }

  /**
   * チャートを描画する
   */
  drawChart() {
    this.loading = true;
    this.formatProtDate = DateUtil.toYYYYMMDD(this.protDate);
    this.chart.clear();

    this.createData().then((res) => {
      this.loading = false;
      this.chartData = res;

      if (!Object.keys(this.chartData).length) return;

      this.chart.show(this.chartData);
    });
  }

  /**
   * プロット用データを取得する
   *
   * @return {Object} promise
   */
  createData() {
    if (!this.cow.bleId) {
      const deferred = this.$q.defer();
      deferred.resolve([]);
      return deferred.promise;
    }

    const params = {
      cowId: this.cow.cowId,
    };

    if (this.protDate && this.protDate.getTime() !== DateUtil.today().getTime()) {
      params.date = DateUtil.toW3CFormat(this.protDate);
    }

    if (this.protFilter !== 'all') {
      params.date_condition = this.protFilter;
    }

    return this.ChartActivityScatterPlotAPI.show(params).then((res) => {
      return 'data' in res && Object.keys(res.data).length ? res.data : [];
    }).catch(() => []);
  }

  activityProtGroupCaption() {
    if (this.chartData) {
      return this.chartData.groupCaption;
    }
    return '';
  }

  moveProtDate(retreat) {
    if (retreat === 'prev') {
      this.protDate = DateUtil.addDays(this.protDate, -1);
    } else {
      this.protDate = DateUtil.addDays(this.protDate, 1);
    }

    this.drawChart();
  }

  showPreviousDate() {
    return Number(this.protDate) > Number(this.minProtDate);
  }

  showNextDate() {
    return Number(this.protDate) < Number(this.baseDate);
  }

  showMessage() {
    if (this.loading) return false;

    return !Object.keys(this.chartData).length;
  }

  showChart() {
    if (this.loading) return false;

    return Object.keys(this.chartData).length;
  }
}

function temporaryGroupViewCowDetailScatterPlotChart() {
  return {
    restrict: 'E',
    templateUrl: 'temporary-group-view/cow-detail/summary/scatter-plot-chart.html',
    controller: temporaryGroupViewCowDetailScatterPlotChartController,
    controllerAs: 'ctrl',
    scope: true,
    replace: true,
    bindToController: {
      cow: '<',
      baseDate: '<',
      protDate: '<',
      minProtDate: '<',
      fatteningStage: '<',
    }
  };
}

app.directive('temporaryGroupViewCowDetailScatterPlotChart', temporaryGroupViewCowDetailScatterPlotChart);
