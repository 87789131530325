class AlertContainerController {
  constructor(
    AlertSummaryStateService,
    UnexpectedDysstasiaInputDialog
  ) {
    'ngInject';

    this.AlertSummaryStateService = AlertSummaryStateService;
    this.UnexpectedDysstasiaInputDialog = UnexpectedDysstasiaInputDialog;
  }

  $onInit() {
    this.AlertSummaryStateService.load();
    this.showDysstasiaInput = this.farm.useAlertDysstasia();
    this.showCalving = this.farm.useAlertCalving();
    this.useTemporaryGroupView = this.farm.useTemporaryGroupView();
  }

  inputUnexpectedDysstasia() {
    this.UnexpectedDysstasiaInputDialog.create();
  }
}

function alertContainer() {
  return {
    templateUrl: 'top/alert/container.html',
    controller: AlertContainerController,
    bindings: {
      farm: '<',
    }
  };
}

app.component('alertContainer', alertContainer());
