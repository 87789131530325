class MobileGenomEvaluationListController {
  constructor(
    $scope,
    $modal,
    $window,
    $stateParams,
    $timeout,
    blockUI,
    SessionCache,
    CustomlistRunner,
    MobileBulkActionSelectModal,
    MobileBulkEditLabelSelectModal
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$modal = $modal;
    this.$window = $window;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.customlistSystemConfigs = SessionCache.customlistSystemConfigs();
    this.CustomlistRunner = CustomlistRunner;
    this.MobileBulkActionSelectModal = MobileBulkActionSelectModal;
    this.MobileBulkEditLabelSelectModal = MobileBulkEditLabelSelectModal;

    this.state = 'mobile-genomic-evaluation-list';
    this.cowIndex = $stateParams.cowIndex || null;
    this.showIndexSetting = SessionCache.farm().isBeefGenomLinkage();

    this.init();

    this.$scope.$on('$destroy', () => {
      this.$window.onresize = null;
    });
  }

  init() {
    this.selectedTabIndex = 0;
    this.defaultTabs = [
      'carcass',
      'other',
      'pedigree'
    ];
    this.tabs = this.defaultTabs;
    this.activeTab = '';
    this.cows = [];

    const config = this.customlistSystemConfigs.find((config) => config.configType === 'ge_beef_cattle_mobile');
    if (config) {
      this.customlistId = config.customlistId;
    }

    this.filterConditions = [];

    this.$timeout(() => {
      this.setContentHeight();
      this.$window.onresize = () => {
        this.setContentHeight();
      };
    }, 100);

    this.stateParams = {
      state: this.state,
      name: this.listName,
      customlistId: this.customlistId,
      configType: this.configType,
      cowGroupId: this.cowGroupId,
    };

    this.run();
  }

  run() {
    this.blockUI.start('ロード中');
    return this.CustomlistRunner.run(
      this.customlistId,
      DateUtil.today()
    ).then((res) => {
      this.cows = res.data.cows;
      this.formatBVClass();
      this.rawCows = [].concat(this.cows);
      this.setConfig(res.data.config);
      this.setGroupCondition(this.cows);
      this.filter();
      if (this.cowIndex !== null) {
        this.onCowClick(this.cows[this.cowIndex]);
      }
    }).finally(() => {
      this.blockUI.stop();
    });
  }

  setConfig(config) {
    this.columns = config.columns;
    this.listName = config.master.name;
    this.configType = config.master.configType;
    this.needLabelAction = this.columns.some((c) => c.columnId === 'cow_labels');
    this.hasCowGroupField = this.columns.some((c) => c.columnId === 'cow_group_name');
    this.hasPenField = this.columns.some((c) => c.columnId === 'pen');
    this.hasCowUidField = this.columns.some((c) => c.columnId === 'cow_uid');
    this.columnMap = this.columns.reduce((acc, cur) => {
      acc[cur.columnId] = cur;
      return acc;
    }, {});
  }

  formatBVClass() {
    this.cows.forEach((c) => {
      c.bvClass = [
        c.carcass_weight_class,
        c.rib_eye_area_class,
        c.ribs_thickness_class,
        c.subcutaneous_fat_thickness_class,
        c.yield_standard_value_class,
        c.bms_class
      ].join('');
    });
  }

  setGroupCondition(cows) {
    const conditions = CustomlistRuntimeCondition.generateFilterCondition(cows, 'cow_group_name');
    if (conditions) {
      this.groupConditions = conditions;
    }
  }

  /**
   * コンテンツの高さを設定
   */
  setContentHeight() {
    const content = angular.element('.js-uContent');
    const excludeHeight = [
      angular.element('.js-stickyAlert').outerHeight(),
      angular.element('.js-layout-header').outerHeight(),
      angular.element('.js-dt-list').outerHeight(),
      angular.element('.js-nav-footer').outerHeight()
    ].reduce((sum, current) => {
      return sum + current;
    }, 0);
    const height = this.$window.innerHeight - excludeHeight;

    content.css({
      height: height
    });
  }

  changeTab(newTab) {
    this.selectedTabIndex = this.tabs.findIndex((tab) => tab === newTab);
  }

  isTabActive(tabName) {
    if (!this.tabs || this.tabs.length === 0) {
      return false;
    }

    if (!this.isSelectMode && (this.activeTab !== this.tabs[this.selectedTabIndex])) {
      this.adjustCard();
    }

    return this.tabs[this.selectedTabIndex] === tabName;
  }

  adjustCard() {
    if (!this.cows || this.cows.length === 0) return;

    const oldTab = this.activeTab;
    this.activeTab = this.tabs[this.selectedTabIndex];

    if (!oldTab) return;
    let topCowId = 0;

    this.cows.some((c) => {
      const id = `card-${oldTab}-${c.cowId}`;
      const card = document.getElementById(id);
      if (!card) {
        //選択モードが終わった直後は取得できないので処理を終了する
        topCowId = 0;
        return true;
      }

      const top = card.getBoundingClientRect().top;
      if (top > 78) {// 牛番号がヘッダーにギリギリ隠れない位置にあるカードを探す
        topCowId = c.cowId;
        return true;
      }
    });

    if (topCowId === 0) return;

    this.$timeout(() => {
      document.getElementById(`card-${this.activeTab}-${topCowId}`).scrollIntoView();
    }, 400);// angular-carousel でのタブ切替時間(初期値 300ms)を待って位置を調整する
  }

  onCowClick(cow) {
    if (this.isSelectMode) {
      this.onCheckBoxClick(cow);
    } else {
      this.cowIndex = this.cows.indexOf(cow);
      this.showDetailDialog = true;
    }
  }

  onFilterButtonClick() {
    this.showFloatingboxLists = false;

    if (this.isFilterModalOpen) return;
    this.isFilterModalOpen = true;

    const params = {
      sortColumns: this.sortColumns(),
      filterColumns: this.filterColumns(),
      conditions: this.filterConditions,
      sortColumnId: this.sortColumnId,
      sortOrder: this.sortOrder,
      groupConditions: this.groupConditions || [],
      groupCondition: this.groupCondition
    };

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'mobile/genom/evaluation-list/filter/index.html',
      controller: 'MobileGenomEvaluationListFilterController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => params
      }
    }).result
      .then((result) => {
        if (result.status && result.status.submit) {
          if (result.sortColumnId !== '') {
            this.sortColumnId = result.sortColumnId;
            this.sortOrder = result.sortOrder;
          }
          this.filterConditions = result.conditions.filter((c) => c.columnId);
          this.groupCondition = result.groupCondition;
          this.filter();
          this.sort();
        }
        this.isFilterModalOpen = false;
      })
      .catch((result) => {
        if (result.status && result.status === 'error') console.error(result);
        this.isFilterModalOpen = false;
      });
  }

  filter() {
    let cows = [].concat(this.rawCows);
    if (this.groupCondition) {
      cows = cows.filter((cow) => {
        return cow.cow_group_name === this.groupCondition;
      });
    }

    this.cows = new CustomlistFilter().filter(cows, this.filterConditions);
  }

  sort() {
    CustomlistSort.sort(
      this.cows,
      this.columnMap[this.sortColumnId],
      this.sortOrder === 'ascending'
    );
  }

  sortColumns() {
    const emptyColumn = {columnId: null, caption: ''};
    const sortColumns = [
      'cow_no',
      'cow_group_name',
      'gebv_index',
      'carcass_weight_bv',
      'rib_eye_area_bv',
      'ribs_thickness_bv',
      'subcutaneous_fat_thickness_bv',
      'yield_standard_value_bv',
      'bms_bv',
      'mufa_ratio_bv',
      'oleic_acid_ratio_bv',
      'carcass_weight_class',
      'rib_eye_area_class',
      'ribs_thickness_class',
      'subcutaneous_fat_thickness_class',
      'yield_standard_value_class',
      'bms_class',
    ];

    const filtered = this.columns.filter((c) => sortColumns.includes(c.columnId));

    return [emptyColumn].concat(filtered);
  }

  filterColumns() {
    const filterColumns = [
      'gebv_index',
      'carcass_weight_bv',
      'rib_eye_area_bv',
      'ribs_thickness_bv',
      'subcutaneous_fat_thickness_bv',
      'yield_standard_value_bv',
      'bms_bv',
      'mufa_ratio_bv',
      'oleic_acid_ratio_bv',
      'carcass_weight_class',
      'rib_eye_area_class',
      'ribs_thickness_class',
      'subcutaneous_fat_thickness_class',
      'yield_standard_value_class',
      'bms_class',
    ];

    const filtered = this.columns.filter((c) => filterColumns.includes(c.columnId));

    return filtered;
  }

  onSelectButtonClick() {
    this.showFloatingboxLists = false;
    this.toggleIsSelectMode();
  }

  onSelectAllButtonClick() {
    this.isAllSelected = !this.isAllSelected;
    this.setAllSelected(this.isAllSelected);
    this.cowSelected = this.cows.some((cow) => cow.selected);
  }

  onCheckBoxClick(cow) {
    cow.selected = !cow.selected;
    this.cowSelected = this.cows.some((cow) => cow.selected);
  }

  toggleIsSelectMode() {
    this.isSelectMode = !this.isSelectMode;
    this.setAllSelected(false);
    this.isAllSelected = false;

    this.$timeout(() => {
      this.setContentHeight();
    }, 100);

    if (this.isSelectMode) {
      this.maskHeadAndFoot = true;
      this.tabs = [this.tabs[this.selectedTabIndex]];
    } else {
      this.maskHeadAndFoot = false;
      this.tabs = this.defaultTabs;
    }
  }

  toggleShowFloatingboxLists() {
    this.showFloatingboxLists = !this.showFloatingboxLists;
  }

  setAllSelected(selected) {
    this.cows.forEach((cow) => cow.selected = selected);
    this.cowSelected = selected;
  }

  onActionButtonClick() {
    const selectedCows = this.cows.filter((cow) => cow.selected);

    const actions = [
      {value: 'registerEvent', text: 'イベント登録', new: true}
    ];
    if (this.needLabelAction) {
      actions.unshift({
        value: 'editLabel',
        text: 'ラベル編集',
        new: true
      });
    }

    // ダイアログの上下がブロックされるのを防ぐためのフラグ操作
    this.maskHeadAndFoot = false;
    this.canceled = true;

    this.MobileBulkActionSelectModal
      .open(selectedCows, actions).result.then((result) => {
        this.canceled = false;

        switch (result.value) {
        case 'editLabel':
          this.MobileBulkEditLabelSelectModal.open(selectedCows)
            .then((result) => {
              if (result.status && result.status.ok) {
                this.run();
                this.toggleIsSelectMode();
              }
            })
            .catch((result) => {
              if (result.status === 'error') console.error(result);
            }).finally(() => {
              this.maskHeadAndFoot = this.isSelectMode;
            });
          break;
        case 'registerEvent':
          this.openBulkEventEntry(selectedCows).finally(() => {
            this.maskHeadAndFoot = this.isSelectMode;
          });
          break;
        default:
        case 'cancel':
          this.maskHeadAndFoot = true;
          break;
        }
      }).finally(() => {
        if (this.canceled) {
          this.maskHeadAndFoot = true;
        }
      });
  }

  openBulkEventEntry(selectedCows) {
    const callerState = {
      bqNotes: `alertList config_type = ${this.configType}`,
    };

    return this.$modal.open({
      templateUrl: 'mobile/bulk-entry/mobile-bulk-entry-event-select.html',
      controller: 'EventSelectionController',
      controllerAs: 'ctrl',
      size: '770',
      resolve: {
        cowIds: () => selectedCows.map((cow) => cow.cowId),
        cowType: () => null,
        isDepositor: () => this.isDepositor,
        qwert: () => this.qwert,
        caller: () => callerState
      },
    }).result.then((result) => {
      if (result.status && result.status.submit) {
        this.run();
        this.toggleIsSelectMode();
      }
    }).catch((result) => {
      if (result.status && result.status === 'error') console.error(result);
    });
  }

  openSettingGebvIndexModal() {
    this.showFloatingboxLists = false;

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'mobile/genom/setting/gebv-index/index.html',
      controller: 'GenomSettingGebvIndexController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            helpTemplateUrl: 'mobile/genom/setting/gebv-index/help.html'
          };
        }
      }
    }).result.then(() => {
      this.run();
    });
  }

  openHelpModal() {
    this.showFloatingboxLists = false;

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'mobile/genom/evaluation-list/help/index.html',
      controller: 'MobileGenomEvaluationListHelpController',
      controllerAs: 'ctrl',
      backdrop: false
    });
  }

  showCowLabel(index) {
    return this.cows[index].cowLabels.length > 0;
  }

  classCard() {
    return this.isSelectMode ? 'uCard--selectable' : 'uCard--unselectable';
  }

  classCardClickable(selected) {
    return selected ? 'uCard__clickable--selected' : 'uCard__clickable';
  }

  classCardInner(selected) {
    return selected ? 'uCard__inner--selected' : '';
  }

  classCardHeadRight(tab, index) {
    const cardRightElement = document.querySelector('.js-uCard__headRight');
    const clientWidth = cardRightElement.clientWidth;
    const computedStyle = getComputedStyle(cardRightElement);
    const maxWidth = (clientWidth - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight)) / 2;

    const targetWidth = document.querySelector(`[data-${tab}-head-cow-group-name="${index}"]`).offsetWidth;

    if (targetWidth < maxWidth) {
      return '';
    } else {
      return 'uCard__headRight--directionColumn';
    }
  }

  classFloatingbox() {
    return this.isSelectMode ? 'uFloatingbox' : 'uFloatingbox--open';
  }

  classFloatingboxLink() {
    return this.showFloatingboxLists ? 'uFloatingbox__link--open' : 'uFloatingbox__link';
  }

  classFloatingboxLists() {
    return this.showFloatingboxLists ? 'uFloatingbox__lists--open' : 'uFloatingbox__lists';
  }

  classHeadMask() {
    return document.querySelector('.stickyAlert__items') ? 'uContent__mask--head__withStickyAlert' : 'uContent__mask--head';
  }

  classCowGroupName(index) {
    return this.cows[index].cowLabels.length > 0 ? '' : 'uCard__headCowGroupName--noCowLabel';
  }
}

app.controller('MobileGenomEvaluationListController', MobileGenomEvaluationListController);
