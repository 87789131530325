class MobileCalvingSensorController {
  constructor(
    $state,
    $modal,
    $rootScope,
    $stateParams,
    SessionCache,
    CalvingSensorAPI,
    DetailsStateFactory
  ) {
    'ngInject';

    this.$state = $state;
    this.$modal = $modal;
    this.$rootScope = $rootScope;
    this.CalvingSensorAPI = CalvingSensorAPI;
    this.DetailsStateFactory = DetailsStateFactory;
    this.eventType = $stateParams.eventType || null;
    this.date = $stateParams.date || new Date();
    this.cowIndex = $stateParams.cowIndex || 0;
    this.shouldShowCarousel = $stateParams.cowIndex !== null;

    const farm = SessionCache.farm();

    this.init(farm);
  }

  init(farm) {
    this.isBeef = farm.isBeef();
    this.heading = `分娩センサー${CalvingSensor.eventTypeLabel(this.eventType)}作業予定`;
    this.buttonLabel = CalvingSensor.mobileButtonLabel(this.eventType);
    this.labels = CalvingSensor.cowDisplayLabels;
    this.cows = [];

    this.cowFields = CalvingSensor.cowFields(this.isBeef)[this.eventType].reduce((eventFields, cur) => {
      if (Array.isArray(cur)) {
        cur.forEach((field) => eventFields.push(field));
      } else {
        eventFields.push(cur);
      }
      return eventFields;
    }, []);

    this.index();
  }

  index() {
    this.CalvingSensorAPI[`${this.eventType}Candidates`](this.date).then((res) => {
      const data = res.data;

      this.cows = CalvingSensor.addDisplayFields(data);
      this.cowsCount = this.cows.length;

      if (!this.cowsCount) {
        this.shouldShowCarousel = false;
      }
    });
  }

  onEventClick(cow) {
    this.cowIndex = this.cows.findIndex((c) => c === cow);
    this.shouldShowCarousel = true;
  }

  onCloseClick() {
    this.shouldShowCarousel = false;
  }

  onCowDetailClick() {
    const caller = {
      state: 'mobile-calving-sensor',
      name: this.heading,
      params: {
        name: this.heading,
        eventType: this.eventType,
        cowIndex: this.cowIndex,
        date: DateUtil.toYYYYMMDD(this.date, '-')
      }
    };

    this.DetailsStateFactory.setDisplayTab(this.DetailsStateFactory.tabNames.timelineTab);
    this.$state.go('mobileCowDetail', {
      cowId: this.cows[this.cowIndex].cowId,
      caller
    });
  }

  openEditModal() {
    const cow = [this.cows[this.cowIndex]].map((cow) => {
      return {
        cowId: cow.cowId,
        cowNo: cow.cowNo,
        cowCalvingSensorHistoryId: cow.cowCalvingSensorHistoryId,
        calvingSensorNumber: cow.calvingSensorNumber,
        startDate: cow.cowCalvingSensorStartDate
      };
    })[0];

    if (this.eventType === 'equip') {
      this.$modal.open({
        templateUrl: 'mobile/calving-sensor/modal/equip.html',
        controller: 'MobileCalvingSensorEquipModalController',
        controllerAs: 'ctrl',
        backdrop: 'static',
        keyboard: false,
        size: 'size-normal',
        resolve: {
          params: () => {
            return {
              cow: cow
            };
          }
        }
      }).result.then(() => {
        this.index();
      });
    } else {
      this.$modal.open({
        templateUrl: 'mobile/calving-sensor/modal/unequip.html',
        controller: 'MobileCalvingSensorUnequipModalController',
        controllerAs: 'ctrl',
        backdrop: 'static',
        keyboard: false,
        size: 'size-normal',
        resolve: {
          params: () => {
            return {
              cow: cow
            };
          }
        }
      }).result.then(() => {
        this.index();
      });
    }
  }
}

app.controller('MobileCalvingSensorController', MobileCalvingSensorController);
