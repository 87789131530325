class UmPlatformApi {
  constructor(
    $http,
    $q,
    $state,
    SessionStateService,
    DateUtilService) {
    'ngInject';
    this.$http = $http;
    this.$q = $q;
    this.$state = $state;
    this.SessionStateService = SessionStateService;
    this.DateUtilService = DateUtilService;
  }

  request(path, params, httpMethod, upload = false) {
    return this.refreshToken().then((res) => {
      const auth = 'JWT ' + this.SessionStateService.sessionToken();
      const url = `https:${environment.umPlatformApiUrl}${path}`;
      const req = {
        method: httpMethod,
        url: url,
        headers: {'Authorization': auth}
      };
      return this.httpRequest(req);
    }).catch((err) => {
      console.error(err);
    });
  }

  refreshToken() {
    if (this.DateUtilService.diffSeconds(new Date(), this.SessionStateService.expiredAt()) < 60) {
      return this.SessionStateService.refreshRequest();
    } else {
      const d = this.$q.defer();
      d.resolve({});
      return d.promise;
    }
  }

  httpRequest(req, retryCount = 0) {
    return this.$http(req).catch((err) => {
      if (err.status === 401 && retryCount < 2) {
        return this.SessionStateService.refreshRequest().then((res) => {
          req.headers.Authorization = `JWT ${this.SessionStateService.sessionToken()}`;
          return this.httpRequest(req, retryCount + 1);
        });
      }
      throw err;
    });
  }

  get(path) {
    return this.request(path, {}, 'GET');
  }
}

app.service('UmPlatformApi', UmPlatformApi);
