app.directive('treatmentDate', treatmentDate);

function treatmentDate($compile) {
  'ngInject';
  return {
    restrict: 'A',
    scope: '=',
    link: (s, el, attr) => {
      const template = $compile(
        `
        <td ng-if="ctrl.state.shouldShowTargetDateTime()">
          <div>
            <span class="target-date">{{ctrl.state.targetDate | date: 'yyyy/MM/dd'}}</span>
            <select class="time-selectors"
              ng-model="e.selectedHour"
              ng-options="h for h in ctrl.state.hours">
            </select>
            <span>時</span>
            <select class="time-selectors"
              ng-model="e.selectedMinute"
              ng-options="m for m in ctrl.state.minutes">
            </select>
            <span>分</span>
          </div>
        </td>
        `
      )(s);

      el.replaceWith(template);
    },
  };
}
