// eslint-disable-next-line no-unused-vars
class MonthlyAccordionReportBinder {
  static bind(ctrl, executor) {
    angular.extend(ctrl, executor);

    ctrl.initCommon = executor.initCommon.bind(ctrl);
    ctrl.initSelections = executor.initSelections.bind(ctrl);
    ctrl.loadData = executor.loadData.bind(ctrl);
    ctrl.onPrint = executor.onPrint.bind(ctrl);
    ctrl.onRangeChanged = executor.onRangeChanged.bind(ctrl);
    ctrl.onSubmit = executor.onSubmit.bind(ctrl);
    ctrl.validate = executor.validate.bind(ctrl);
    ctrl.shouldShowYear = executor.shouldShowYear.bind(ctrl);
    ctrl.adjustScrollSize = executor.adjustScrollSize.bind(ctrl);
    ctrl.isReportReady = executor.isReportReady.bind(ctrl);
    ctrl.initDataMap = executor.initDataMap.bind(ctrl);
    ctrl.isPositive = executor.isPositive.bind(ctrl);
    ctrl.isNegative = executor.isNegative.bind(ctrl);
    ctrl.shouldShowErrorMessage = executor.shouldShowErrorMessage.bind(ctrl);
  }
}
