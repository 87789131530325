class MobileAlertBoxController {
  constructor(
    $state,
    AlertSummaryStateService,
    SessionCache
  ) {
    'ngInject';

    this.$state = $state;
    this.AlertSummaryStateService = AlertSummaryStateService;
    this.summary = null;

    const farm = SessionCache.farm();

    this.init(SessionCache, farm);
  }

  init(SessionCache, farm) {
    this.AlertSummaryStateService.load();
    this.summary = this.AlertSummaryStateService.summary;

    const customlistSystemConfigs = SessionCache.customlistSystemConfigs();
    this.customlistConfigMap = customlistSystemConfigs
      .filter((config) => config.configType.startsWith('alert'))
      .reduce((o, config) => {
        o[config.configType] = config.customlistId;
        return o;
      }, {});

    this.showHeat = farm.isDairy() || farm.isBreeding() || farm.isDepositor();
    this.showCalving = farm.useAlertCalving();
    this.isBeef = farm.isBeef();
    this.isDairy = farm.isDairy();
  }

  isDisabled(alertType) {
    return !this.summary || !this.summary[alertType];
  }

  toAlertList(alertType) {
    switch (alertType) {
    case 'alert_heat':
    case 'alert_false_heat':
    case 'alert_bad_condition':
      const customlistId = this.customlistConfigMap[alertType];
      if (customlistId) {
        this.$state.go('mobileAlertList', {customlistId}, {parent: this.$state.current});
      }
      break;
    default:
      this.$state.go('mobile-alert-list', {alertType: alertType}, {parent: this.$state.current});
    }
  }

  toAlertDysstasiaResolved() {
    this.$state.go('mobile-alert-dysstasia-resolved', {}, {parent: this.$state.current});
  }

  showAlertDysstasiaResolved() {
    return this.isBeef && this.summary.dysstasiaResolved > 0;
  }

  toAlertCalving(confirmed = false) {
    if (confirmed) {
      this.$state.go('mobile-alert-calving-confirmed', {}, {parent: this.$state.current});
    } else {
      this.$state.go('mobile-alert-calving', {}, {parent: this.$state.current});
    }
  }
}

app.controller('MobileAlertBoxController', MobileAlertBoxController);
