class CustomlistIndexController {
  static get LOCAL_STORAGE_NAME_FILTER_KEY() {
    return 'Customlist-NameFilter';
  }

  constructor(
    $modal,
    $rootScope,
    $scope,
    appConfig,
    FarmService,
    CustomlistAPI,
    SortOrderEditDialog,
    blockUI
  ) {
    'ngInject';
    this.$modal = $modal;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.appConfig = appConfig;
    this.CustomlistAPI = CustomlistAPI;
    this.SortOrderEditDialog = SortOrderEditDialog;
    this.blockUI = blockUI;

    this.initialize(FarmService);
  }

  initialize(FarmService) {
    this.$scope.$on('ngRepeatFinished', () => {
      FixedMidashi.create();
    });

    this.masters = [];
    this.farmType = 'MILK';

    this.items = [];
    this.nameFilter = '';
    this.summaryStatus = {};

    const cache = LocalStorageEntry.use(
      this.$rootScope.userName,
      CustomlistIndexController.LOCAL_STORAGE_NAME_FILTER_KEY,
      {}
    );

    if (cache.inputAt) {
      const inputAt = new Date(cache.inputAt);
      if (DateUtil.isSameDay(inputAt, DateUtil.today())) {
        this.nameFilter = cache.nameFilter;
      }
    }

    FarmService.show().then((res) => {
      const farm = new Farm(res);
      this.farmType = farm.farmType();
      this.isDepositor = farm.isDepositor();
      this.isDhiLinkage = farm.isDhiLinkage();
      this.useAlertCalving = farm.useAlertCalving();
    });

    this.fetchSummaryStatus(true);

    this.load(false);
  }

  load(update = false) {
    this.CustomlistAPI.index().then((response) => {
      this.masters = response.data;
      this.existsConfig = this.masters.length > 0;
      this.enableUpdateSortOrder = this.masters.length >= 2;

      this.refreshItems();

      if (update) {
        this.$rootScope.$emit('menu:refreshCustomList', response.data);
      }
    });
  }

  entry(copiedCustomlistId = null) {
    this.$modal.open({
      templateUrl: 'menu/customlist/config/edit.html',
      controller: 'CustomlistEditController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        params: () => {
          return {
            title: '個別リストの登録',
            customlistId: null,
            copiedCustomlistId: copiedCustomlistId,
            farmType: this.farmType,
            isDepositor: this.isDepositor,
            sortOrder: this.masters.length + 1,
            isDhiLinkage: this.isDhiLinkage,
            useAlertCalving: this.useAlertCalving
          };
        }
      }
    }).result.then(() => this.load(true));
  }

  edit(customlistId) {
    this.$modal.open({
      templateUrl: 'menu/customlist/config/edit.html',
      controller: 'CustomlistEditController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        params: () => {
          return {
            title: '個別リストの編集',
            customlistId: customlistId,
            farmType: this.farmType,
            isDepositor: this.isDepositor,
            isDhiLinkage: this.isDhiLinkage,
            useAlertCalving: this.useAlertCalving
          };
        }
      }
    }).result.then(() => this.load(true));
  }

  delete(customlistId) {
    this.$modal.open({
      templateUrl: 'menu/customlist/delete.html',
      controller: 'CustomlistEditController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        params: () => {
          return {
            title: '個別リストの削除',
            customlistId: customlistId,
            farmType: this.farmType
          };
        }
      }
    }).result.then(() => this.load(true));
  }

  sortOrder() {
    const items = this.masters.filter((master) => master.editable)
      .map((r) => {
        return {id: r.customlistId, name: r.name};
      });
    const updateFunction = this.CustomlistAPI.updateSortOrder.bind(this.CustomlistAPI);

    this.SortOrderEditDialog.execute(
      '個別リストの表示順設定',
      'リスト名',
      items,
      updateFunction
    ).then((result) => {
      if (result.executed) {
        this.load(true);
      }
    });
  }

  eventType() {
    this.$modal.open({
      templateUrl: 'menu/customlist/event-type/index.html',
      controller: 'CustomlistEventTypeController',
      controllerAs: 'ctrl',
      size: 'md'
    });
  }

  copy() {
    this.$modal.open({
      templateUrl: 'menu/customlist/config/copy.html',
      controller: 'CustomlistCopyController',
      controllerAs: 'customlistCopyCtrl',
      size: 'md',
      resolve: {
        params: () => {
          return {
            masters: this.masters,
          };
        }
      },
    }).result.then((customlistId) => this.entry(customlistId));
  }

  executeSummary() {
    this.summaryStatus = {
      status: 'WAITING',
      statusLabel: '処理待ち',
      timeLabel: '',
    };

    this.blockUI.start('リクエスト中');
    this.CustomlistAPI.executeSummary().finally(() => {
      this.blockUI.stop();
      setTimeout(() => {
        this.fetchSummaryStatus();
      }, 5000);
    });
  }

  canExecuteSummary() {
    return !this.summaryStatus.status || ['FINISHED', 'ABEND'].includes(this.summaryStatus.status);
  }

  fetchSummaryStatus(initializing = false) {
    this.CustomlistAPI.summaryStatus().then((res) => {
      const summaryStatus = res.data;

      this.summaryStatus = {
        status: summaryStatus.status,
        statusLabel: CustomlistSummaryStatus.toStatusLabel(summaryStatus.status),
        timeLabel: CustomlistSummaryStatus.toTimeLabel(summaryStatus),
      };

      if (['WAITING', 'PROCESSING'].includes(summaryStatus.status)) {
        setTimeout(() => {
          this.fetchSummaryStatus();
        }, 5000);
      }

      if (!initializing && summaryStatus.status === 'FINISHED') {
        this.load(true);
      }
    });
  }

  refreshItems() {
    this.items = this.masters.filter((m) => {
      if (!this.nameFilter) return true;
      return m.name.includes(this.nameFilter);
    });
  }

  onChangeNameFilter() {
    this.refreshItems();

    if (this.items.length) {
      LocalStorageEntry.set(
        this.$rootScope.userName,
        CustomlistIndexController.LOCAL_STORAGE_NAME_FILTER_KEY,
        {nameFilter: this.nameFilter, inputAt: new Date().getTime()}
      );
    }
  }

  onClickNameFilterOff() {
    this.nameFilter = '';
    this.refreshItems();

    LocalStorageEntry.set(
      this.$rootScope.userName,
      CustomlistIndexController.LOCAL_STORAGE_NAME_FILTER_KEY,
      {}
    );
  }
}

app.controller('CustomlistIndexController', CustomlistIndexController);
