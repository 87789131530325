class MobileOperationHistoryController {
  constructor(
    TimelineService,
    DateUtilService,
    appConst,
    $modal,
    $state,
    EventSelectionMasterInitializerService,
    EventLabelResolverService,
    blockUI
  ) {
    'ngInject';

    this.TimelineService = TimelineService;
    this.DateUtilService = DateUtilService;
    this.currentDate = null;
    this.events = [];
    this.eventTypes = appConst.eventTypes;
    this.$modal = $modal;
    this.$state = $state;
    this.blockUI = blockUI;

    this.EventSelectionMasterInitializerService = EventSelectionMasterInitializerService;
    this.EventLabelResolverService = EventLabelResolverService;
    this.selectionMaster = null;
    this.groupedEventsArray = [];
    this.shouldShowNoEvents = false;

    this.init();
  }

  init() {
    this.targetEventTypes = Object.keys(this.eventTypes)
      .filter((key) => this.eventTypes[key].operationHistory && this.eventTypes[key].operationHistory.show === true);

    this.currentDate = this.DateUtilService.today();
    this.EventSelectionMasterInitializerService.initSelectionMaster(new EventSelectionMasterContainer(this.event))
      .then((result) => {
        this.selectionMaster = result;
        this.refreshEvents();
      });
  }

  onPreviousDayClick() {
    if (this.isLoading) return;
    this.currentDate = this.DateUtilService.subtractDays(this.currentDate, 1);
    this.refreshEvents();
  }

  onNextDayClick() {
    if (this.isLoading) return;
    this.currentDate = this.DateUtilService.nextDay(this.currentDate);
    this.refreshEvents();
  }

  onEventClick(event) {
    event = CowEvent.dateStringToNumber(event);

    this.$modal.open({
      templateUrl: 'mobile/events/event-detail-modal/mobile-event-detail-modal.html',
      controller: 'MobileEventDetailModalController as ctrl',
      keyboard: false,
      size: 'lg',
      resolve: {
        event: () => {
          return event;
        },
        options: () => {
          return {
            showCowNo: true
          };
        }
      }
    }).result.then((result) => {
      if (result.status === MobileEventDetailModalController.RETURN_STATUS.SUBMIT) {
        this.$state.go('mobileCowDetail', {
          cowId: event.cowId
        });
      }
    });
  }

  shouldShowField(event, fieldName) {
    return CowEvent.shouldShowField(event, fieldName);
  }

  // private

  refreshEvents() {
    this.blockUI.start('ロード中');
    this.isLoading = true;
    this.shouldShowNoEvents = false;
    this.groupedEventsArray.length = 0;

    this.TimelineService.index(this.currentDate)
      .then((result) => {
        if (result.status === 200) {
          const events = result.data
            .filter((event) => this.targetEventTypes.includes(event.eventType))
            .map((event) => {
              const resolved = this.EventLabelResolverService.resolveLabel(event, this.selectionMaster);

              if (!event.treatmentDiseaseDate && event.eventType !== 'vaccine') resolved.medicineLabels = null;

              return this.addDisplayFlag(resolved);
            });

          const groupedEvents = this.groupEventsByType(events);

          this.groupedEventsArray = [];
          Object.keys(groupedEvents)
            .sort((a, b) => this.eventTypes[a].operationHistory.order - this.eventTypes[b].operationHistory.order)
            .forEach((key) => {
              this.groupedEventsArray.push(groupedEvents[key]);
            });
        }
        this.blockUI.stop();
        this.isLoading = false;
        this.shouldShowNoEvents = this.groupedEventsArray.length === 0;
      })
      .catch((error) => {
        this.blockUI.stop();
        this.isLoading = false;
        this.shouldShowNoEvents = false;
        console.error(error);
      });
  }

  groupEventsByType(events) {
    const groupedEvents = {};
    events.forEach((event) => {
      const eventDisplay = angular.copy(event);
      const eventName = this.eventTypes[event.eventType]['name'];

      groupedEvents[event.eventType] = groupedEvents[event.eventType] || {
        name: eventName,
        eventType: event.eventType,
        events: []
      };
      groupedEvents[event.eventType]['events'].push(eventDisplay);
    });

    Object.keys(groupedEvents).forEach((key) => {
      groupedEvents[key].events = groupedEvents[key].events
        .sort((a, b) =>
          a.occurredAt !== b.occurredAt
            ? b.occurredAt - a.occurredAt
            : b.createdAt - a.createdAt);
    });

    return groupedEvents;
  }

  addDisplayFlag(event) {
    let copy = this.addMedicinesDisplayFlag(event);
    copy = this.addTimeDisplayFlag(copy);

    switch (copy.eventType) {
    case 'lame':
      return this.addLameDisplayFlag(copy);
    case 'observation':
      return this.addObservationDisplayFlag(copy);
    case 'carcass':
      return this.addCarcassDisplayFlag(copy);
    default:
      return copy;
    }
  }

  addLameDisplayFlag(event) {
    const copy = angular.copy(event);
    copy.shouldShowLameDiagnosis = {};

    ['左前', '右前', '左後', '右後'].forEach((position) => {
      copy.shouldShowLameDiagnosis[position] = event.groupedLameDiagnosises[position].length > 0;

      copy.shouldShowLameDiagnosisOnMultiLine = copy.shouldShowLameDiagnosisOnMultiLine ||
        event.groupedLameDiagnosises[position]
          .some((diagnosis) => {
            return diagnosis.clawDiseaseName && diagnosis.clawDiseaseName !== '';
          });
    });

    return copy;
  }

  addMedicinesDisplayFlag(event) {
    const copy = angular.copy(event);
    if (!copy.medicineLabels || copy.medicineLabels.length === 0) return copy;

    copy.shouldShowMedicinesWithMultiLine =
      copy.shouldShowMedicinesWithMultiLine || event.medicineLabels.some((label) => label.method);

    return copy;
  }

  addTimeDisplayFlag(event) {
    const copy = angular.copy(event);

    copy.shouldShowTime =
      CowEvent.isTreatmentEvent(event.eventType) && event.treatmentDiseaseDate ? true : false;
    copy.shouldShowTime =
      copy.shouldShowTime ||
      CowEvent.occuredAtHasTime(event.eventType);

    return copy;
  }

  addObservationDisplayFlag(event) {
    const copy = angular.copy(event);

    const badConditions = [
      'eyeCondition',
      'earCondition',
      'muzzleCondition',
      'hairCondition',
      'hipCondition',
      'bodyCondition',
      'fecesCondition',
      'urineCondition'
    ].filter((key) => event[key] !== '良好');

    copy.shouldShowObservationResult = badConditions.length > 0;

    return copy;
  }

  addCarcassDisplayFlag(event) {
    const copy = angular.copy(event);

    const yieldGrades = [
      'dressedCarcassWeightOfL',
      'dressedCarcassWeightOfR',
      'loinArea',
      'ribsThickness',
      'subcutaneousFat',
      'yieldBaseValue',
    ].filter((key) => event[key]);

    copy.shouldShowYieldGrades = yieldGrades.length > 0;

    const meetGrades = [
      'bmsNo',
      'marblingGrade',
      'bcsNo',
      'gloss',
      'bcsAndGlossGrade',
      'tight',
      'texture',
      'tightAndTextureGrade',
      'bfsNo',
      'fatLuster',
      'bfsAndFatLusterGrade'
    ].filter((key) => event[key]);

    copy.shouldShowMeetGrades = meetGrades.length > 0;

    return copy;
  }
}

app.controller('MobileOperationHistoryController', MobileOperationHistoryController);
