function temporaryGroupViewCowDetailTimelineFixed($window) {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      const onScroll = () => {
        if (!(scope.ctrl.$state.current.name === 'temporary-group-view-cow-detail' &&
            scope.ctrl.activeTab === 'dataTab')) {
          return;
        }

        if ($window.pageYOffset > 128) {
          element.addClass('fixed');
        } else {
          element.removeClass('fixed');
        }
      };
      angular.element($window).bind('scroll', onScroll);

      scope.$on('$destroy', function() {
        angular.element($window).unbind('scroll', onScroll);
      });
    }
  };
}

app.directive('temporaryGroupViewCowDetailTimelineFixed', ['$window', temporaryGroupViewCowDetailTimelineFixed]);
