class ItemFilterController {
  constructor(
    $modalInstance,
    blockUI,
    $timeout,
    ItemFilterAPI) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.blockUI = blockUI;
    this.$timeout = $timeout;
    this.ItemFilterAPI = ItemFilterAPI;

    this.initialize();
  }

  initialize() {
    this.keyField = 'id';
    this.showGrid = false;
    this.invalid = true;
    this.useItemFilter = false;

    this.setUiGrid();
    this.index();
  }

  index() {
    this.ItemFilterAPI.index().then((res) => {
      this.useItemFilter = res.data.useItemFilter;
      this.uiGrid.data = res.data.accounts;
      this.showGrid = true;

      this.initialState = {
        useItemFilter: this.useItemFilter,
        exemptedIds: res.data.accounts.filter((a) => a.exempted).map((a) => a.id)
      };
    });
  }

  validate() {
    this.invalid = false;

    if (this.useItemFilter !== this.initialState.useItemFilter) return;

    const exemptedIds = this.uiGrid.data.filter((a) => a.exempted).map((a) => a.id);
    if (exemptedIds.length !== this.initialState.exemptedIds.length) return;

    const changed = exemptedIds.some((id) => !this.initialState.exemptedIds.includes(id));
    if (changed) return;

    this.invalid = true;
  }

  setUiGrid() {
    const columns = [
      {columnId: 'name', label: 'ユーザー名', width: 300},
      {columnId: 'exempted', label: '権限の付与', width: 100},
    ];

    const options = {
      clickRowEvent: 'onClickRow',
      cellTemplates: {
        exempted: `
          <div class="align-center">
            <input type="checkbox" ng-checked="row.entity.exempted" class="margin-top-ignore" />
          </div
          `
      }
    };

    const uiGrid = new UiGridGenerator().generate(this, columns, options);
    this.uiGrid = uiGrid;
  }

  onClickRow(row) {
    row.exempted = !row.exempted;
    this.validate();
  }

  update() {
    this.blockUI.start('更新中');

    const exemptedAccountIds = this.uiGrid.data
      .filter((account) => account.exempted)
      .map((account) => {
        return account.id;
      });

    this.ItemFilterAPI.create(this.useItemFilter, exemptedAccountIds)
      .then(() => {
        this.blockUI.done(() => {
          this.blockUI.start('更新が完了しました');
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close({executed: true});
      }, (res) => {
        this.blockUI.stop();
        this.errorMessage = ErrorUtil.formatErrorMessage(res.data.messages);
      });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('ItemFilterController', ItemFilterController);
