class AlertEartagSensorDroppedSolutionSelctionController {
  constructor(
    $modalInstance,
    appConfig,
    params,
    StandardDialog
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
    this.appConfig = appConfig;
    this.StandardDialog = StandardDialog;
    this.eartagSensorNumber = params.eartagSensorNumber;
    this.title = `センサー番号: ${this.eartagSensorNumber}`;
    this.buyers = params.buyers;
    this.expelledReasons = params.expelledReasons;
    this.active = params.active;

    this.solution = null;
    this.factor = null;

    this.validate();
  }

  handleClick() {
    this.validate();
    this.clearFactor();
    this.shipmentsForm = {};
  }

  handleFactorClick() {
    this.validate();
  }

  clearFactor() {
    if (this.solution === 'ignore' || this.solution === 'shipments') {
      this.factor = null;
    }
  }

  validate() {
    if (this.solution === null) {
      this.invalid = true;
      return;
    }

    if (this.solution === 'ignore' || this.solution === 'shipments') {
      this.invalid = false;
      return;
    }

    this.invalid = this.factor === null;
  }

  cancel() {
    this.$modalInstance.close();
  }

  select() {
    const solutionHash = {'reequip': '再装着', 'change': '交換・解除', 'ignore': '問題なし', 'shipments': '出荷'};

    if (this.solution === 'change') {
      this.StandardDialog.showYesNoConfirm({
        title: '処置内容の登録',
        text1: `処置内容を「${solutionHash[this.solution]}」で登録します。`,
        text2: 'センサーの交換・解除を行ったうえで登録して下さい。',
        text3: 'よろしいでしょうか？',
        yes: 'OK',
        no: 'キャンセル'
      }).result.then((modalResult) => {
        this.$modalInstance.close({solution: this.solution, factor: this.factor});
      });
    } else {
      let shipmentsText = '';
      if (this.solution === 'shipments' && this.active && this.shipmentsForm.occurredAt) {
        shipmentsText = '同時に出荷イベントが登録されますが、';

        this.shipmentsForm.entryStaffName = this.appConfig.staff.name;
        this.shipmentsForm.lastEditStaffName = this.appConfig.staff.name;
      }
      this.StandardDialog.showYesNoConfirm({
        title: '処置内容の登録',
        text1: `処置内容を「${solutionHash[this.solution]}」で登録します。`,
        text2: `${shipmentsText}よろしいでしょうか？`,
        yes: 'OK',
        no: 'キャンセル'
      }).result.then((modalResult) => {

        this.$modalInstance.close({solution: this.solution, factor: this.factor, shipmentsForm: this.shipmentsForm});
      });
    }
  }
}

app.controller('AlertEartagSensorDroppedSolutionSelctionController', AlertEartagSensorDroppedSolutionSelctionController);
