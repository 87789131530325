class CowEntryBulkController {
  constructor(
    $q,
    FarmService,
    CowService,
    CowGroupAPI,
    StandardDialog,
    SelectionService,
    UtilService,
    DateUtilService,
    blockUI,
    MasterMarketService,
    Dictionary,
    $rootScope
  ) {
    'ngInject';

    this.$q = $q;
    this.FarmService = FarmService;
    this.CowService = CowService;
    this.CowGroupAPI = CowGroupAPI;
    this.StandardDialog = StandardDialog;
    this.SelectionService = SelectionService;
    this.UtilService = UtilService;
    this.DateUtilService = DateUtilService;
    this.blockUI = blockUI;
    this.MasterMarketService = MasterMarketService;
    this.Dictionary = Dictionary;

    // 起動処理
    this.onInit();
  }

  /**
   * 起動処理
   * - デフォルト値を設定、
   * - プロミスを走らせる
   */
  onInit() {
    this.rowsToAdd = 5; // default = 5
    this.invalidIntroductionDate = false; // 導入年月日の警告エラー
    this.registrationData = [];
    this.introduceDate = today().getTime();
    this.bleIdStartDate = null;
    this.masterMarketId = null;
    this.showIntroductionType = false;
    this.isFattening = true;
    this.isSubmitting = false;

    // get data
    const deferred = this.$q.defer();
    return this.$q.all([
      this.CowGroupAPI.active()
        .then((res) => this.cowGroups = res.data)
        .catch((err) => console.error(err)),
      this.SelectionService.index()
        .then((selection) => {
          this.breeds = selection.breed;
          this.gender = selection.gender;

          this.addRows(20);
        }),
      this.FarmService.show()
        .then((account) => {
          this.farm = new Farm(account);
          if (this.farm.data.useSensorType === 'use_both_sensor') {
            this.showSensorType = true;
            this.sensorType = 1;
          } else {
            this.showSensorType = false;
          }

          this.cowNoValidator = account.cowNoPattern === 'alphanumeric' ?
            new CowNoAlphamericValidator() :
            new CowNoNumericValidator();

          if (account.farmTypeFattening) {
            this.showIntroductionType = account.farmTypeBreeding;
            if (!account.farmTypeBreeding) this.isFattening = true;
          } else {
            this.showIntroductionType = false;
            this.isFattening = false;
          }

          return deferred.resolve(account);
        }),
      this.MasterMarketService.index().then((res) => {
        this.masterMarkets = CollectionUtil.selectableMasters(res.data);
      }),
    ]);
  }

  /**
   * 選択された牛群からその行の牛房プルダウンを設定
   * @param {Object} group 牛群obj
   * @param {number} index 行目
   */
  groupSelected(group, index) {
    if (group) {
      this.registrationData[index].pens = group.pens ?
        group.pens.split(',') :
        [];
    } else {
      this.registrationData[index].pens = null;
    }
    this.registrationData[index].pen = null;
  }

  /**
   * 入力フォムの行数を足す
   * @param {number|string} number 行を足す数
   */
  addRows(number) {
    const createCow = () => {
      return {
        breed: this.breeds[0].value
      };
    };

    // 数字以外の入力を認めない
    if (!this.UtilService.isNumber(number)) {
      return;
    }

    for (let i = 0; i < number; i++) {
      this.registrationData.push(createCow());
    }
  }

  /**
   * 「行を追加する」入力欄でエンターを押下した時、行を追加するようにする
   * @param {number|string} number 行を足す数
   */
  inputEnter(keyEvent, number) {
    if (keyEvent.which !== 13) {
      return;
    }

    this.addRows(number);
  }

  changeCowUid(row) {
    if (this.useCowNoInput()) return;
    const cowUid = row.cowUid;
    const length = this.farm.useRegulationNumber4() ? 4 : 5;
    row.cowNo = this.CowService.generateCowNo(cowUid, length);
  }

  /**
   * 登録処理
   * - バリデーションを行って、問題なければ確認画面を開く
   */
  submit() {
    if (this.isSubmitting) return;
    this.isSubmitting = true;

    this.clearErrors();
    this.errorMessages = null;
    this.apiErrorMessages = null;

    if (!this.isValidHeader()) {
      return;
    }

    // 1. 空行を外し
    this.registrationData = this.registrationData.filter(this.isInputRow);
    // 2. エラーチェックを行う
    this.registrationData = this.registrationData
      .map((row) => this.validateRequired(row))
      .map((row, index) => this.checkForDuplicates(row, index))
      .map((row) => this.validateNumericForBleId(row));

    this.registrationData = this.registrationData
      .map((row) => this.validateCowUid(row));

    if (!this.registrationData.length) {
      this.addRows(20);
      this.isSubmitting = false;
      return this.showMessageModal('登録する牛を入力してください。');
    }

    // 入力内容にエラーがある場合ストップ
    if (this.registrationData.some((row) => this.countErrors(row))) {
      this.errorMessages = this.createErrorMessages();
      this.isSubmitting = false;
      return this.showMessageModal('入力内容に誤りがあります。');
    }

    if (this.registrationData.some((row) => row.bleId) && !this.bleIdStartDate) {
      this.isSubmitting = false;
      return this.showMessageModal('牛個体と一緒にセンサー番号を登録する場合は、センサー番号適用開始日を入力してください。');
    }

    this.confirmCheckDigitCowUid().then((res) => {
      // POST用にデータ整理
      const cleanData = this.cleanUpData(this.registrationData);

      this.blockUI.start('登録中');

      // POST
      this.CowService.insertInBulk(cleanData).then((res) => {
        // 入力データを空にする
        this.registrationData = [];
        this.addRows(20);

        // 成功メッセージを表示する
        this.showMessageModal('個体登録（一括）を完了しました。');

        // display success modal
      }).catch((err) => {
        this.setApiErrors(err.data.messages);
        this.showMessageModal('入力内容に誤りがあります。');
      }).finally(() => {
        this.blockUI.stop();
        this.isSubmitting = false;
      });
    }).catch(() => {
      this.isSubmitting = false;
    });
  }

  // POST用データ生成
  cleanUpData(registrationData) {
    return registrationData.map((row) => {
      // Obj生成
      const cleanRow = {
        cowNo: row.cowNo,
        cowUid: row.cowUid,
        cowGroupId: row.cowGroup.cowGroupId,
        pen: row.pen,
        breed: row.breed,
        registrationNo: row.registrationNo,
        weightOnPurchase: row.weightOnPurchase,
        purchasePrice: row.purchasePrice,
        birthday: row.birthday,
        gender: row.gender,
        fatherName: row.fatherName,
        maternalGrandfatherName: row.maternalGrandfatherName,
        maternalGreatGrandfatherName: row.maternalGreatGrandfatherName,
        maternalFather4thName: row.maternalFather4thName,
        producingFarmName: row.producingFarmName,
        introduceDate: this.introduceDate,
        masterMarketId: this.masterMarketId,
        stopShipmentBeefFlg: false,
        stopShipmentMilkingFlg: false,
        bleId: row.bleId,
        bleIdStartDate: this.bleIdStartDate,
        sensorType: this.sensorType
      };

      if (this.isFattening) cleanRow.startFatteningDate = this.introduceDate;

      if (this.shouldShowRegulationNumberCowName() || this.shouldShowCowNoCowName()) {
        cleanRow.cowName = row.cowName;
      }

      // 空propをSFに渡すとエラるため削除
      Object.keys(cleanRow).forEach((key) =>
        (cleanRow[key] === undefined) && delete cleanRow[key]);

      return cleanRow;
    });
  }

  // 入力重複チェック
  checkForDuplicates(row, index) {
    row.error = row.error || {};

    if (this.farm.useDuplicationOption() && !this.farm.allowDuplicationCowNo()) {
      row.error['duplicateNo'] = !!this.registrationData.filter((data, idx) =>
        (index !== idx) && // 自分と比較しない
        row.cowNo && // 入力あり
        (data.cowNo === row.cowNo)).length;
    }

    row.error['duplicateCowUid'] = !!this.registrationData.filter((data, idx) =>
      (index !== idx) &&
      row.cowUid &&
      (Number(data.cowUid) === Number(row.cowUid))).length;

    row.error.duplicateBleId = !!this.registrationData.filter((data, idx) =>
      (index !== idx) &&
      row.bleId &&
      (Number(data.bleId) === Number(row.bleId))).length;

    return row;
  }

  isInputRow(row) {
    return Object.keys(row).some((col) => {
      if (col === 'error') return false;
      if (col === 'breed') return false; // 品種は必ず値がセットされるので除外する
      if (!row[col]) return false;
      return true;
    });
  }

  /**
   * 必須項目の入力をチェック、ない場合errorに追加
   *   fieldがある場合、その入力欄のみのバリデーションを行う
   * @param {number} index データの行目
   * @param {string} field 入力欄のデータモデル名、デフォルトは空
   * @return {Object} row データの行
   */
  validateRequired(row, field = '') {
    row.error = row.error || {};

    if (field === 'cowUid') {
      row.error[field] = !(row[field] && this.UtilService.isNumber(row[field]) && row[field].toString().length === 10);
      return row;
    } else if (field) {
      row.error[field] = !row[field];
      return row;
    }

    if (this.farm.useCowNo()) {
      row.error['cowNo'] = !row.cowNo || !this.cowNoValidator.validate(row.cowNo);
    } else if (this.farm.useName()) {
      row.error['cowNo'] = !row.cowNo;
    }

    row.error['cowUid'] = !(row.cowUid && this.UtilService.isNumber(row.cowUid) && row.cowUid.toString().length === 10);
    row.error['cowGroup'] = !row.cowGroup;
    row.error['birthday'] = !row.birthday || !this.DateUtilService.isValidDate(row.birthday);
    row.error['gender'] = !row.gender;

    return row;
  }

  // エラーのある行数を返す
  // @return {number}
  countErrors(row) {
    return Object.keys(row.error)
      .filter((err) => row.error[err])
      .length;
  }

  /**
   * 入力欄移動後、その行の必須枠の入力チェックを行う
   * @param {number} index データの行目
   * @param {string} field 入力欄のデータモデル名、デフォルトは空
   */
  validateRow(index, field = '') {
    this.registrationData[index] = this.validateRequired(this.registrationData[index], field);
  }

  /**
   * ヘッダー項目の値が正しいか検証を行う
   *
   * @return {boolean} valid: true, invalid: false
   */
  isValidHeader() {
    const validateDate = (date) => {
      return DateUtil.isValidDate(date) && DateUtil.includedPermittedPeriod(date);
    };

    let valid = true;
    this.invalidIntroductionDate = false;
    this.invalidBleIdStartDate = false;

    const introduceDateValid = validateDate(this.introduceDate);
    if (!introduceDateValid) {
      this.invalidIntroductionDate = true;
      valid = false;
    }

    if (this.bleIdStartDate) {
      const bleIdStartDateValid = validateDate(this.bleIdStartDate);
      if (!bleIdStartDateValid) {
        this.invalidBleIdStartDate = true;
        valid = false;
      }
    }

    return valid;
  }

  /**
   * エラーをクリアする
   */
  clearErrors() {
    this.registrationData.forEach((row) => delete row.error);
  }

  /**
   * 発生したAPIエラーを設定する
   *
   * @param {Array<Object>} errors APIエラー
   */
  setApiErrors(errors) {
    this.apiErrorMessages = this.UtilService.formatMultilineErrorMessage(errors);

    errors.forEach((error) => {
      const row = this.registrationData[error.lineNo - 1];
      row.error = row.error || {};
      if (error.field === 'cowNo') {
        row.error.existingCowNo = true;
      }

      if (error.field === 'cowUid') {
        row.error.existingCowUid = true;
      }

      if (error.field === 'bleId') {
        row.error.existingBleId = true;
      }
    });
  }

  /**
   * メッセージモーダルを表示する
   *
   * @param {String} message メッセージ
   */
  showMessageModal(message) {
    this.StandardDialog.showMessage({
      title: '個体登録（一括）',
      text1: message
    });
  }

  /**
   * エラー発生時のメッセージを作成する
   *
   * @return {String} エラーメッセージ
   */
  createErrorMessages() {
    return this.registrationData.map((row, index) => {
      if (row.error.duplicateNo || row.error.duplicateCowUid || row.error.duplicateBleId || row.error.formatBleId) {
        const rowNo = index + 1;
        let errorMessage = `${rowNo}行目： `;
        errorMessage += row.error.duplicateNo ? `${this.Dictionary.COW.COW_NO}が重複してます。` : '';
        errorMessage += row.error.duplicateCowUid ? '個体識別番号が重複してます。' : '';
        errorMessage += row.error.duplicateBleId ? 'センサー番号が重複してます。' : '';
        errorMessage += row.error.formatBleId ? 'センサー番号は半角数字のみ有効です。' : '';
        return errorMessage;
      }
    }).filter((v) => v).join('\n');
  }

  /**
   * bleidが数値か検証し、数値以外の場合はエラーに追加する
   * 値が無い場合は、何もしない。
   *
   * @param {Object} row rowデータ
   * @return {Object} rowデータ
   */
  validateNumericForBleId(row) {
    if (!row.bleId) return row;

    if (!/^\d*$/.test(row.bleId)) {
      row.error.formatBleId = true;
    }

    return row;
  }

  /**
   * BleIdのエラーがある場合は、trueを返す。
   *
   * @praram {Object} row rowデータ
   * @return {boolean} true: エラーあり, false: エラーなし
   */
  hasErrorsForBleId(col) {
    if (!col.error) return false;

    if (col.error.duplicateBleId || col.error.formatBleId || col.error.existingBleId) {
      return true;
    } else {
      return false;
    }
  }

  useCowNoInput() {
    return this.farm.useCowNo() || this.farm.useName();
  }

  validateCowUid(row) {
    if (!row.cowUid) return row;

    row.warning = row.warning || {};

    const checkDigitCowUid = NumberUtil.modulus10Weight3CheckDigit(row.cowUid.slice(0, 9));

    if (checkDigitCowUid !== Number(row.cowUid.slice(9, 10))) {
      row.warning.invalidCowUid = true;
    }

    return row;
  }

  confirmCheckDigitCowUid() {
    const hasInvalidCowUid = this.registrationData
      .filter((data) => {
        return data.cowUid;
      }).map((data) => {
        const checkDigitCowUid = NumberUtil.modulus10Weight3CheckDigit(data.cowUid.slice(0, 9));

        return checkDigitCowUid !== Number(data.cowUid.slice(9, 10));
      }).some((invalid) => {
        return invalid;
      });

    if (!hasInvalidCowUid) return new Promise((resolve, reject) => resolve(true));

    const modalInstance = this.StandardDialog.showYesNoConfirm({
      title: '個体登録（一括）',
      text1: '「個体識別番号」が間違っています。このまま登録しますか？',
      yes: 'OK',
      no: 'キャンセル'
    });

    return modalInstance.result.then((modalResult) => modalResult);
  }

  shouldShowRegulationNumberCowName() {
    if (!this.farm.isBreeding()) return false;

    return this.farm.useRegulationNumber4() || this.farm.useRegulationNumber5();
  }

  shouldShowCowNoCowName() {
    if (!this.farm.isBreeding()) return false;

    return this.farm.useCowNo();
  }
}

app.controller('CowEntryBulkController', CowEntryBulkController);
