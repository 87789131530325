/**
 * 牛状態のステートを維持するデーターストア
 */
class DetailsStateFactory {
  constructor() {
    'ngInject';

    // 起動初期ステートはないだから初期化
    this.initialise();
  }

  set(key, detailsSetting) {
    this.state[key] = detailsSetting;
  }

  get() {
    return this.state;
  }

  // ステートの初期化
  initialise() {
    this.state = {
      chartDisplayRange: 30, // 各チャートの表示日数: 7 || 30 || 90
      displayTab: this.tabNames.summaryTab, // 表示するタブ: summaryTab || dataTab || activityTab || milkingTab
      milkingChartRange: 30, // 搾乳量チャートの表示領域: 30 || 90
      hiddenLegendIds: [],
    };
  }

  /**
   * setDisplayTabの引数用
   */
  get tabNames() {
    return {
      summaryTab: 'summaryTab',
      dataTab: 'dataTab',
      activityTab: 'activityTab',
      heatTab: 'heatTab', // モバイル版用
      milkingTab: 'milkingTab',
      timelineTab: 'timelineTab'
    };
  }

  /**
   * setChartDisplayRangeの引数用
   */
  get chartDisplayRanges() {
    return {
      7: '7',
      30: '30',
      90: '90'
    };
  }

  /**
   * デフォルト・タブを指定する
   * @param  {string} tabName tag type
   * @return {void}
   */

  setDisplayTab(tabName) {
    if (!this.tabNames.hasOwnProperty(tabName)) {
      tabName = this.tabNames.summaryTab;
    }

    this.state.displayTab = tabName;
  }

  setChartDisplayRange(range) {
    this.state.chartDisplayRange = range;
  }

  setMilkingChartRange(range) {
    this.state.milkingChartRange = range;
  }
}

app.service('DetailsStateFactory', DetailsStateFactory);
