class MasterWorkNoteTypeAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  available() {
    return this.APIService.get('/master_work_note_type/available');
  }
}

app.service('MasterWorkNoteTypeAPI', MasterWorkNoteTypeAPI);
