class DepoistAgentFarmService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/deposit/agent_farm');
  }

  summary() {
    return this.APIService.get('/deposit/agent_farm/summary');
  }
}

app.service('DepoistAgentFarmService', DepoistAgentFarmService);
