class FatteningReportConfigController {
  constructor(
    $modal,
    $modalInstance,
    modalDialogFactory,
    blockUI,
    $timeout,
    FatteningReportConfigAPI) {
    'ngInject';
    this.$modal = $modal;
    this.$modalInstance = $modalInstance;
    this.modalDialogFactory = modalDialogFactory;
    this.blockUI = blockUI;
    this.$timeout = $timeout;
    this.FatteningReportConfigAPI = FatteningReportConfigAPI;

    this.initialize();
  }

  initialize() {
    this.months = [
      {key: 1, label: '1月'},
      {key: 2, label: '2月'},
      {key: 3, label: '3月'},
      {key: 4, label: '4月'},
      {key: 5, label: '5月'},
      {key: 6, label: '6月'},
      {key: 7, label: '7月'},
      {key: 8, label: '8月'},
      {key: 9, label: '9月'},
      {key: 10, label: '10月'},
      {key: 11, label: '11月'},
      {key: 12, label: '12月'}
    ];

    this.FatteningReportConfigAPI.show().then((res) => {
      this.config = res.data;
    }).catch((error) => {
      if (ErrorUtil.isRecordNotFound(error.data)) {
        this.config = {
          fiscalYearStartMonth: 4
        };
      }
    });
  }

  save() {
    this.blockUI.start('更新中');
    this.FatteningReportConfigAPI.create(this.config.fiscalYearStartMonth).then((res) => {
      this.blockUI.start('更新が完了しました');
      this.$timeout(() => {
        this.blockUI.stop();
      }, 1000);

      this.blockUI.stop();
      this.$modalInstance.close({executed: true});
    }).catch((error) => {
      this.errorMessage = ErrorUtil.formatErrorMessage(error.data.messages);
      this.blockUI.stop();
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('FatteningReportConfigController', FatteningReportConfigController);
