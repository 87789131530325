class GroupCowGroupAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index(id) {
    return this.APIService.get(`/group/${id}/cow_group`);
  }
}

app.service('GroupCowGroupAPI', GroupCowGroupAPI);
