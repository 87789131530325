class MobileEventRegisterModalController {
  static get RETURN_STATUS() {
    return {
      SUBMIT: 'submit',
      CANCEL: 'cancel',
      GO_BACK: 'goBack'
    };
  }

  constructor(
    $modalInstance,
    $scope,
    $modal,
    MasterSpermAPI,
    $timeout,
    AccountService,
    OperationUserService,
    appConfig,
    $rootScope,
    $window,
    Dictionary,
    MasterEmbryoAPI
  ) {
    'ngInject';
    this.derrivedScope = this.$scope = $scope;
    this.$modalInstance = $modalInstance;
    this.$modal = $modal;
    this.formData = $scope.formData;
    this.eventType = $scope.formData.eventType;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.AccountService = AccountService;
    this.OperationUserService = OperationUserService;
    this.appConfig = appConfig;
    this.$rootScope = $rootScope;

    this.$timeout = $timeout;
    this.$window = $window;
    this.Dictionary = Dictionary;

    //種付
    this.MasterSpermAPI = MasterSpermAPI;
    this.MasterEmbryoAPI = MasterEmbryoAPI;

    $scope.onIncreaseChildNumberClick = this.onIncreaseChildNumberClick.bind(this);
    $scope.onDecreaseChildNumberClick = this.onDecreaseChildNumberClick.bind(this);

    this.onInit(MasterEmbryoAPI);

    this.$timeout(this.calculateMainAreaHeight.bind(this));
    $window.onresize = this.calculateMainAreaHeight.bind(this);
    $scope.$on('$destroy', () => {
      $window.onresize = null;
    });
  }

  onInit() {
    switch (this.eventType) {
    case 'tanetsuke':
      this.composeSpermInfo();
      this.composeEmbryoOptions();
      this.composeTaneushiOptions();
      break;
    case 'abort':
      if (this.$scope.messages.length > 0) {
        this.$timeout(() => { // angular validationの完了を待つ
          this.$scope.validate();
        });
      }
    case 'embryo_recovery':
      this.composeSpermInfo();
      break;
    }

    ['bodyTemperature', 'salesPrice'].forEach((attr) => {
      if (this.$scope.formData[attr]) {
        this.$scope.formData[attr] = Number(this.$scope.formData[attr]);
      }
    });
    if (this.$scope.formData.childNumber > 0) {
      for (let i = 1; i <= this.$scope.formData.childNumber; i++) {
        const attr = `weightOnBirth${i}`;
        if (this.$scope.formData[attr]) {
          this.$scope.formData[attr] = Number(this.$scope.formData[attr]);
        }
      }
    }
    if ('selectedMedicineList' in this.$scope) {
      for (let i = 0; i < this.$scope.selectedMedicineList.length; i++) {
        if (this.$scope.selectedMedicineList[i].capacity) {
          this.$scope.selectedMedicineList[i].capacity = Number(this.$scope.selectedMedicineList[i].capacity);
        }
      }
    }

    this.composeOperatorOptions();
  }

  onCancel() {
    this.$modalInstance.close(MobileEventRegisterModalController.RETURN_STATUS.CANCEL);
  }

  onSubmit() {
    // 投稿者の情報をセット
    this.appConfig.staff.name = this.$rootScope.userName = this.formData.operatorName;

    const masterMedicineIds = this.formData.masterMedicineIds;

    if (masterMedicineIds) {
      const medicineCapacities = this.formData.medicineCapacities.split('、');
      const medicineMethods = this.formData.medicineMethods.split('、');

      const medicines = masterMedicineIds.split('、').reduce((acc, cur, index) => {
        acc.push({
          id: cur,
          capacity: medicineCapacities[index],
          method: medicineMethods[index]
        });

        return acc;
      }, []).filter((medicine) => {
        return medicine.id && medicine.id !== '0';
      });

      this.formData.masterMedicineIds = medicines.map((medicine) => medicine.id).join('、');
      this.formData.medicineCapacities = medicines.map((medicine) => medicine.capacity).join('、');
      this.formData.medicineMethods = medicines.map((medicine) => medicine.method).join('、');
    }

    // um-form-medicines がidをStringに変換するため、念の為Numberに戻す
    if ('selectedMedicineList' in this.$scope) {
      this.$scope.selectedMedicineList = this.$scope.selectedMedicineList
        .filter((medicine) => medicine && medicine.id)
        .map((medicine) => {
          medicine.id = Number(medicine.id);
          return medicine;
        });
    }

    if (this.eventType === 'embryo_recovery') {
      this.$scope.setIsValidatorDisabled(false);
      this.$scope.validate();
      if (this.$scope.messages.length > 0) return;
    }

    this.$modalInstance.close(MobileEventRegisterModalController.RETURN_STATUS.SUBMIT);
  }

  onGoBack() {
    this.$modalInstance.close(MobileEventRegisterModalController.RETURN_STATUS.GO_BACK);
  }

  calculateMainAreaHeight() {
    // ウィンドウの高さを変更中に呼ばれると高さが不正になるためブロックする
    if (this.applying) {
      return;
    }
    this.applying = true;

    this.$timeout(() => {
      const windowHeight = $(window).innerHeight();
      const headerHeight = $('header.layout-header .container').innerHeight();
      this.$scope.$apply(() => {
        this.mainAreaHeight = windowHeight - headerHeight;
        this.applying = false;
      });
    });
  }

  // 種付
  /*
    精液選択用オプションのリストを構築
  */
  composeSpermInfo() {
    this.MasterSpermAPI.available().then((res) => {
      const selectableMasters = CollectionUtil.selectableMasters(
        res.data,
        [this.formData.masterSpermId]
      );
      this.$scope.spermOptions = selectableMasters.map((spermInfo) => {
        spermInfo.label = `${spermInfo.spermNo} (${spermInfo.name})`;
        return spermInfo;
      });
    });
  }

  composeEmbryoOptions() {
    this.MasterEmbryoAPI.available().then((res) => {
      const selectableMasters = CollectionUtil.selectableMasters(
        res.data,
        [this.formData.masterEmbryoId]
      );
      this.$scope.masterEmbryoOptions = selectableMasters.map((m) => {
        return {
          label: m.name,
          masterEmbryoId: m.id
        };
      });
    });
  }

  onIncreaseChildNumberClick() {
    this.increaseChildNumber();
    this.$timeout(() => {
      this.$scope.onChangeChildNumber();
    });
  }

  onDecreaseChildNumberClick() {
    this.decreaseChildNumber();
    this.$timeout(() => {
      this.$scope.onChangeChildNumber();
    });
  }

  // 分娩
  increaseChildNumber() {
    const childNumber = parseInt(this.formData.childNumber) + 1;
    this.formData.childNumber = `${childNumber}`;
  }

  decreaseChildNumber() {
    const childNumber = parseInt(this.formData.childNumber) - 1;
    this.formData.childNumber = `${childNumber}`;
  }

  // オペレーター
  composeOperatorOptions() {
    this.AccountService.cachedAccount().then((account) => {
      if (account.shared) {
        this.shouldShowOperatorName = true;
        this.OperationUserService.index().then((res) => {
          this.operatorOptions = res.data.map((operator) => operator.name);
          this.formData.operatorName = this.appConfig.staff.name || this.operatorOptions[0] || '';
        });
      } else {
        this.shouldShowOperatorName = false;
        this.operatorOptions = [account.name];
        this.formData.operatorName = account.name;
      }
    });
  }

  // 種付: 種牛情報
  composeTaneushiOptions() {
    this.$scope.tanetsukeBulls = this.$scope.tanetsukeBulls
      .map((bull) => {
        bull.label = `${bull.cowName || '-'} (牛群: ${bull.cowGroupName || '-'} ${this.Dictionary.COW.COW_NO}: ${bull.cowNo || '-'} 個体識別番号: ${bull.cowUid || '-'} 品種: ${bull.breed || '-'})`;
        return bull;
      });
  }

  // : 種付方法の初期化
  initTanetsukeMethod() {
    this.$scope.changeTanetsukeMethod();
    this.$scope.validate();
  }

  // 外傷・感染症・その他
  onOtherDiseaseNameUpdated() {
    if (this.$scope.formData.otherDiseaseName === '') {
      this.$scope.formData.difficultiesOther = '';
    } else if (!this.$scope.formData.difficultiesOther) {
      this.$scope.formData.difficultiesOther = this.$scope.selection.otherDifficultiesLevel[0].value;
    }
  }

}

app.controller('MobileEventRegisterModalController', MobileEventRegisterModalController);
