/**
 * タブレットであるかどうか判断する
 * Androidにはスマートフォンも含まれるが、Androidタブレットのユーザエージェントには
 * modileが含まれる場合があるのでmodileの判定は行わない。
 */
function IsTabletFactory($window) {
  'ngInject';
  const ua = $window.navigator.userAgent;
  // T/Fを返す
  return (ua.indexOf('iPad') >= 0 || ua.indexOf('Android') >= 0);
}

app.factory('IsTablet', IsTabletFactory);
