app.controller('SenserController', function(
  $scope,
  $stateParams,
  DateTimeUtilFactory,
  CowshedService,
  EnvironmentService,
  DateUtilService,
  $rootScope
) {
  'ngInject';

  const chartConfigs = {
    stress: {
      regions: [
        {axis: 'y', start: 65, end: 70, class: 'score-color1', opacity: '0.15'},
        {axis: 'y', start: 70, end: 75, class: 'score-color2', opacity: '0.15'},
        {axis: 'y', start: 75, end: 100, class: 'score-color3', opacity: '0.15'}
      ]
    }
  };

  let dUtil = DateTimeUtilFactory;
  let now = dUtil.parseClientDate(new Date());

  let thermoChart = new ThermoChart('#temperature');
  let humidityChart = new HumidityChart('#humidity');
  let stressChart = new StressChart('#stress');
  let stressRegions = chartConfigs.stress.regions;
  let startDate;
  let endDate;

  // initialize event
  CowshedService.index().then((res) => {
    $scope.selection = {};
    $scope.selection.temp = [
      {'label': '平均気温', 'value': 'avg'},
      {'label': '最高気温', 'value': 'max'},
      {'label': '最低気温', 'value': 'min'}
    ];
    $scope.temp = 'avg';

    $scope.selection.hum = [
      {'label': '平均湿度', 'value': 'avg'},
      {'label': '最高湿度', 'value': 'max'},
      {'label': '最低湿度', 'value': 'min'}
    ];
    $scope.hum = 'avg';
    const shed = res.data.find((item) =>
      item.cowshedId === Number($stateParams.id));

    // 牛舎名を画面名としてセット
    $scope.shedName = shed ? shed.cowshedName : '';

    getLatestAllData(shed).then((resolve) => {
      $scope.bleIdList = settingLatestSenserInfo(resolve, shed);
      createDailyEnvironmentData(getDate(now), 24 * 60, 7, 'prev').then(createAllWeekChart);
    });
  }).catch((err) => console.error(err));

  const getLatestAllData = function(shed) {
    shed = shed || {};
    const bleIds = toBleIds(shed);

    return EnvironmentService.getLatestEnvironment(bleIds.join(','))
      .then((res) => res.data)
      .catch((err) => console.error(err));
  };

  function createChartData(chartData, chartName, period) {
    chartData = chartData || {};
    // console.log(chartData);

    let summary = [], bleIdList = $scope.bleIdList;
    chartData.forEach(function(daily) {
      let obj = {};
      // Unixtime(ミリ秒)を YYYY-MM-DD に変更して保持する
      obj.date = DateUtilService.toW3CFormat(daily.date);

      daily.status.forEach(function(senser) {
        bleIdList.forEach(function(bleId) {
          if (bleId.id === senser.bleId) {
            obj[bleId.id] = obj[bleId.id] || {};
            obj[bleId.id]['name'] = bleId.name;
            obj[bleId.id]['max_temperature'] = parseFloat(senser.maxTemperature);
            obj[bleId.id]['min_temperature'] = parseFloat(senser.minTemperature);
            obj[bleId.id]['avg_temperature'] = parseFloat(senser.avgTemperature);
            obj[bleId.id]['max_humidity'] = parseFloat(senser.maxHumidity);
            obj[bleId.id]['min_humidity'] = parseFloat(senser.minHumidity);
            obj[bleId.id]['avg_humidity'] = parseFloat(senser.avgHumidity);
          }
        });
      });
      summary.push(obj);
    });

    $scope.summary = $scope.summary || {};
    $scope.summary[period] = $scope.summary[period] || {};
    if (chartName === 'all') {
      $scope.summary[period]['temperature'] = summary;
      $scope.summary[period]['humidity'] = summary;
      $scope.summary[period]['stress'] = summary;
    } else {
      $scope.summary[period][chartName] = summary;
    }
  }

  // start より、length 日分の、最高・最低・平均温度、湿度を取得し内部変数に保持する
  const createDailyEnvironmentData = function(start, interval, length, retreat) {
    const bleIdList = $scope.bleIdList || [];
    const bleIds = bleIdList.map((bleId) => bleId.id);

    const column = createTimeLineColumn(start, interval, length, retreat);

    startDate = column[0];
    endDate = column[column.length - 1];

    return EnvironmentService.getDailyEnvironment(bleIds.join(','), startDate, endDate)
      .then((res) => $scope.chartData = res.data)
      .catch((err) => console.error(err));
  };

  let selectChartData = function(chartType, chartName, period) {
    let data = [];
    let bleIdList = $scope.bleIdList;
    let summary = $scope.summary[period][chartName];
    let dataType = chartType + '_' + chartName;
    let temp = 0, hum = 0;

    summary.forEach(function(daily) {
      let obj = {};
      daily = daily || {};
      obj.date = daily.date;

      bleIdList.forEach(function(bleId) {
        if (chartName === 'stress') {
          // ここでストレスの算出を行う
          if (daily[bleId.id]) {
            temp = daily[bleId.id][chartType + '_' + 'temperature'];
            hum = daily[bleId.id][chartType + '_' + 'humidity'];
            obj[bleId.name] = (calculateStress(temp, hum)).toFixed(1);
          } else {
            obj[bleId.name] = 0;
          }
        } else {
          obj[bleId.name] = daily[bleId.id] ? (daily[bleId.id][dataType]).toFixed(1) : 0;
        }
      });
      data.push(obj);
    });

    data = completionDataForWeek(data, startDate, endDate);

    $scope[chartName] = $scope[chartName] || {};
    $scope[chartName].chartName = chartName;
    $scope[chartName].chartType = chartType;
    $scope[chartName].period = period;
    $scope[chartName].farstColumn = data.length !== 0 ? data[0].date : startDate;
    $scope[chartName].lastColumn = data.length !== 0 ? data[data.length - 1].date : endDate;
    $scope[chartName].displayDate = moment($scope[chartName].lastColumn).format('YYYY年MM月DD日');

    return data;
  };

  const createAllWeekChart = function(chartData) {
    createChartData(chartData, 'all', 'week');
    // 温度
    let thermoData = selectChartData('avg', 'temperature', 'week');
    if (_.isEmpty(thermoData)) {
      appendEmptyMessage('temperature');
    } else {
      clearEmptyMessage('temperature');
      thermoChart.show(thermoData);
    }
    // 湿度
    let humidityData = selectChartData('avg', 'humidity', 'week');
    if (_.isEmpty(humidityData)) {
      appendEmptyMessage('humidity');
    } else {
      clearEmptyMessage('humidity');
      humidityChart.show(humidityData);
    }
    // ストレス
    let stressData = selectChartData('avg', 'stress', 'week');
    if (_.isEmpty(stressData)) {
      appendEmptyMessage('stress');
    } else {
      clearEmptyMessage('stress');
      stressChart.show(stressData, stressRegions);
    }
  };

  $scope.changeTypeChart = function(chartName) {
    let period = $scope[chartName].period;
    let result = judgeTargetChart(chartName);

    startDate = $scope[chartName].farstColumn;
    endDate = $scope[chartName].lastColumn;
    result.target.show(selectChartData(result.chartType, chartName, period));
  };

  $scope.changeMoveChart = function(chartName, retreat) {
    let period = $scope[chartName].period;
    let targetDate = retreat === 'next' ? $scope[chartName].lastColumn : $scope[chartName].farstColumn;
    let result = judgeTargetChart(chartName);

    createDailyEnvironmentData(getDate(targetDate), 24 * 60, 7, retreat).then((res) => {
      createChartData(res, chartName, period);
      if (_.isEmpty(res)) {
        appendEmptyMessage(chartName);
        let tempFirstColumn = $scope[chartName].farstColumn;
        let tempLastColumn = $scope[chartName].lastColumn;
        if (retreat === 'next') {
          $scope[chartName].farstColumn = moment(tempLastColumn).add(1, 'days').format('YYYY-MM-DD');
          $scope[chartName].lastColumn = moment(tempLastColumn).add(7, 'days').format('YYYY-MM-DD');
        } else if (retreat === 'prev') {
          $scope[chartName].lastColumn = moment(tempFirstColumn).subtract(1, 'days').format('YYYY-MM-DD');
          $scope[chartName].farstColumn = moment(tempFirstColumn).subtract(7, 'days').format('YYYY-MM-DD');
        }
        $scope[chartName].displayDate = moment($scope[chartName].lastColumn).format('YYYY年MM月DD日');
      } else {
        clearEmptyMessage(chartName);
        if (chartName === 'stress') {
          result.target.show(selectChartData(result.chartType, chartName, period), stressRegions);
        } else {
          result.target.show(selectChartData(result.chartType, chartName, period));
        }
      }
    });
  };

  const judgeTargetChart = function(chartName) {
    let result = {};
    if (chartName === 'temperature') {
      result.target = thermoChart;
      result.chartType = $scope.temp;
    }

    if (chartName === 'humidity') {
      result.target = humidityChart;
      result.chartType = $scope.hum;
    }
    if (chartName === 'stress') {
      result.target = stressChart;
      //chartType = $scope.strs;
      result.chartType = 'avg';
    }
    return result;
  };

  /**
   * ストレス値の色を変更する。
   *
   * @param {number} value ストレス値
   * @return {string} クラス名
   */
  $scope.changeColorForStress = (value) => {
    for (let i = 0, length = stressRegions.length; i < length; i++) {
      if (i === 0) {
        if (stressRegions[i].start <= value && stressRegions[i].end >= value) {
          return stressRegions[i].class;
        }
      } else {
        if (stressRegions[i].start < value && stressRegions[i].end >= value) {
          return stressRegions[i].class;
        }
      }
    }
  };

  /**
   * 最新データが存在するか確認する。
   *
   * @param {Object} sensar センサーデータ
   * @return {boolean} true: データ有、false: データ無
   */
  $scope.checkLatestData = (sensar) => {
    return ['latestStress', 'latestTemperature', 'latestHumidity'].some(function(value) {
      return (sensar.hasOwnProperty(value) && sensar[value] !== 0);
    });
  };
});
