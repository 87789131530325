/**
 * 牛の状態を大きく４つに分類する
 *  1. calf 育成
 *    哺育、育成
 *  2. fattening 肥育
 *  3. reproduction 繁殖
 *    フレッシュ、種付なし、授精、未受胎(妊鑑－)、受胎(妊鑑＋)、乾乳前期、乾乳後期、繁殖除外
 *  4. soldOrDied へい死、出荷
 */
app.constant('CowState', {
  '育成': 'calf',
  '哺育': 'calf',
  '肥育': 'fattening',
  'フレッシュ': 'reproduction',
  '未授精': 'reproduction',
  '授精': 'reproduction',
  '未受胎(－)': 'reproduction',
  '受胎(＋)': 'reproduction',
  '乾乳前期': 'reproduction',
  '乾乳後期': 'reproduction',
  '繁殖除外': 'reproduction',
  'へい死': 'soldOrDied',
  '出荷': 'soldOrDied'
});
