class SortOrderController {
  constructor(
    $modalInstance,
    blockUI,
    params) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.blockUI = blockUI;

    this.title = params.title || '表示順設定';
    this.items = angular.copy(params.items);
    this.seletedItemKey = this.items[0].key;

    this.updateFunction = params.updateFunction;
  }

  up() {
    if (!this.seletedItemKey) return;

    const selectedIndex = this.indexOfSelected(this.seletedItemKey, this.items);
    const swappedIndex = selectedIndex - 1;
    if (swappedIndex < 0) return;

    this.swapItem(selectedIndex, swappedIndex);
  }

  down() {
    if (!this.seletedItemKey) return;

    const selectedIndex = this.indexOfSelected(this.seletedItemKey, this.items);
    const swappedIndex = selectedIndex + 1;
    if (swappedIndex >= this.items.length) return;

    this.swapItem(selectedIndex, swappedIndex);
  }

  toFirst() {
    if (!this.seletedItemKey) return;

    const selectedIndex = this.indexOfSelected(this.seletedItemKey, this.items);
    const selectedItem = this.items[selectedIndex];

    for (let i = selectedIndex - 1; i >= 0; i--) {
      this.items[i + 1] = this.items[i];
    }
    this.items[0] = selectedItem;
  }

  toLast() {
    if (!this.seletedItemKey) return;

    const selectedIndex = this.indexOfSelected(this.seletedItemKey, this.items);
    const selectedItem = this.items[selectedIndex];
    const lastIndex = this.items.length - 1;

    for (let i = selectedIndex + 1; i <= lastIndex; i++) {
      this.items[i - 1] = this.items[i];
    }
    this.items[lastIndex] = selectedItem;
  }

  indexOfSelected(seletedItemKey, items) {
    let selectedIndex = -1;
    items.some((item, index) => {
      if (item.key === seletedItemKey) {
        selectedIndex = index;
        return true;
      }
      return false;
    });
    return selectedIndex;
  }

  swapItem(selectedIndex, swappedIndex) {
    const selectedItem = this.items[selectedIndex];
    const swappedItem = this.items[swappedIndex];
    this.items[swappedIndex] = selectedItem;
    this.items[selectedIndex] = swappedItem;
  }

  update() {
    this.blockUI.start('更新中');

    const params = this.items.map((item, index) => {
      return {id: item.key, sortOrder: index + 1};
    });

    this.updateFunction(params)
      .then(() => {
        this.blockUI.stop();
        this.$modalInstance.close();
      }, (res) => {
        this.blockUI.stop();
        this.errorMessage = ErrorUtil.formatErrorMessage(res.data.messages);
      });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('SortOrderController', SortOrderController);
