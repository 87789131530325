class HomeScheduleCalvingSensorController {
  constructor(
    $timeout,
    CalvingSensorAPI
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.CalvingSensorAPI = CalvingSensorAPI;
  }

  $onChanges() {
    this.loading.schedule.calvingSensor = true;

    if (!this.date) return;

    this.index().then(() => {
      this.loading.schedule.calvingSensor = false;

      this.$timeout(() => {
        this.enableMasonry();
      }, 200);
    });
  }

  index() {
    return this.CalvingSensorAPI.candidatesSummary(this.date).then((res) => {
      const data = res.data;
      const total = Object.values(data).reduce((a, b) => a + b);
      const events = Object.entries(data).filter((item) => {
        return item[1] > 0;
      }).map((item) => {
        const key = item[0];
        const count = item[1];

        return {
          eventType: key,
          label: `${CalvingSensor.eventTypeLabel(key)}予定`,
          count: count
        };
      });

      this.summary = {
        total,
        events
      };

      this.total.calvingSensor = this.summary.total;
    });
  }
}

function homeScheduleCalvingSensor() {
  return {
    templateUrl: 'menu/home/schedule/calving-sensor/index.html',
    controller: HomeScheduleCalvingSensorController,
    controllerAs: 'ctrl',
    bindings: {
      enableMasonry: '&',
      loading: '=',
      total: '=',
      date: '<'
    }
  };
}

app.component('homeScheduleCalvingSensor', homeScheduleCalvingSensor());
