class temporaryGroupViewCowDetailTimeBudgetController {
  get TIMEBUDGET_DATA_PERIOD() {
    return 365;
  }

  constructor(
    $q,
    $window,
    SessionCache,
    ActiveAmountAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.$window = $window;
    this.ActiveAmountAPI = ActiveAmountAPI;

    const farm = SessionCache.farm();

    this.init(farm);
  }

  $onChanges() {
    if (!(Object.keys(this.cow).length && this.ble && this.baseDate &&
        this.timeBudgetDate && this.minTimeBudgetDate)) return;

    this.index();
  }

  $onDestroy() {
    if (this.chart) {
      this.chart.clear('activity');
      this.chart = null;
      this.$window.onresize = null;
    }
  }

  init(farm) {
    this.showFeed = !farm.isUMLight();
    this.showDrink = farm.isUMFull();
    this.timeBudgetType = 'all';
    this.loading = true;
    this.onResize();
  }

  index() {
    this.isDateInit = true;
    this.drawChart(this.timeBudgetDate);
  }

  onResize() {
    this.$window.onresize = () => {
      this.drawChart(this.baseDate);
    };
  }

  /**
   * チャートを描画する
   */
  drawChart(targetDate, retreat) {
    this.loading = true;

    this.createData(targetDate, retreat).then((res) => {
      this.loading = false;
      this.timeBudgetData = res[0];
      this.drinkingDailyData = res[1];

      if (this.chart) {
        this.chart.clear('activity');
      }

      this.chart = new TimeBudget('activityTimeBudget', this.showFeed, this.showDrink);
      this.chart.show(
        this.timeBudgetData,
        this.timeBudgetType,
        'activityTimeBudget',
        this.drinkingDailyData
      );
    });
  }

  createData(targetDate, retreat) {
    return this.$q.all([
      this.createTimeBudgetData(targetDate, retreat),
      this.createDrinkingDailyData(targetDate),
    ]).then((res) => {
      return res;
    });
  }

  createTimeBudgetData(targetDate, retreat) {
    if (!this.ble.bleId || this.exceedsTimeBudgetDataPeriod()) {
      const deferred = this.$q.defer();
      deferred.resolve([]);
      return deferred.promise;
    }

    const dateObj = this.getIntervalDate(targetDate, retreat);
    const periods = CowBleId.availablePeriods(
      this.ble.bleIds,
      dateObj.startDate.urlParam,
      dateObj.endDate.urlParam
    );

    const promises = periods.map((period) => {
      const endDate = period.startDate === period.endDate ?
        DateUtil.toYYYYMMDD(DateUtil.addDays(period.endDate, 1), '') : period.endDate;

      return this.ActiveAmountAPI.getActiveTimelineFindByDate(period.bleId, period.startDate, endDate);
    });

    const feedSourceTypePromise = this.ActiveAmountAPI.getDeprecatedFeedSourceType(
      this.cow.cowId, DateUtil.toW3CFormat(targetDate));

    promises.unshift(feedSourceTypePromise);

    return this.$q.all(promises).then((res) => {
      const feedSourceType = res[0].data['feed_source_type'];
      // プロミス結果を合算
      return res.slice(1).reduce((a, b) => a.concat('data' in b ? b.data : []), [])
        .sort((a, b) => Number(a.datehourmin) - Number(b.datehourmin)) // 日付順にソート
        .map((record) => {
          // 走る/歩くを動態に変更
          if (record.status === 'run' || record.status === 'walk') {
            record.status = 'move';
          } else if (record.status === 'feed_acc') {
            record.status = feedSourceType === 'acc' ? 'feed' : 'move';
          } else if (record.status === 'walk_acc') {
            record.status = feedSourceType === 'acc' ? 'move' : 'feed';
          }
          return record;
        });
    }).catch(() => []);
  }

  exceedsTimeBudgetDataPeriod() {
    const date = this.cow.eliminateDate || this.cow.fallingDeadDate;
    return date && DateUtil.diffDays(parseInt(date), DateUtil.today()) > this.TIMEBUDGET_DATA_PERIOD;
  }

  /**
   * タイムバジェットで表示している日付の飲水回数を取得(活動量 & サマリー)
   *
   * @param {Date} targetDate タイムバジェット表示日付
   * @return {Object} promise
   */
  createDrinkingDailyData(targetDate) {
    const startDate = moment(targetDate)
      .startOf('day')
      .valueOf();
    const endDate = moment(targetDate)
      .endOf('day')
      .valueOf();

    return this.ActiveAmountAPI.getDailyDrinkingTimes(this.cow.cowId, startDate, endDate)
      .then((res) => 'data' in res ? res.data : [])
      .catch(() => []);
  }

  getIntervalDate(targetDate, retreat) {
    const toDateString = (date, delimit) => {
      delimit = delimit || '';

      return String(date.getFullYear()) + delimit +
        String(('0' + (date.getMonth() + 1)).slice(-2)) + delimit +
        String(('0' + date.getDate()).slice(-2));
    };

    const dateObj = {};
    const endDate = new Date(toDateString(targetDate, '-'));

    endDate.setDate(endDate.getDate() + 1);
    dateObj.startDate = {
      urlParam: toDateString(targetDate),
      format: toDateString(targetDate, '-')
    };
    dateObj.endDate = {
      urlParam: toDateString(endDate),
      format: toDateString(endDate, '-')
    };

    return dateObj;
  }

  /**
   * タイムバジェットの表示期間を変更する
   *
   * @param {string} retreat prev or next
   */
  moveRangeBudget(retreat) {
    let target = new Date(this.timeBudgetDate);
    if (retreat === 'prev') {
      target.setDate(target.getDate() - 1);
    } else if (retreat === 'next') {
      target.setDate(target.getDate() + 1);
    }

    this.timeBudgetDate = target;

    this.drawChart(target, retreat);
  }

  /**
   * 選択された対象の活動量のみ表示する
   * @param {string} selector 対象の活動量
   */
  changeTimeBudget(selector) {
    if (!Object.keys(this.timeBudgetData).length) return;

    this.chart.selectShow(this.timeBudgetType, selector, this.drinkingDailyData.length);
  }

  showPreviousDate() {
    return Number(this.timeBudgetDate) > Number(this.minTimeBudgetDate);
  }

  showNextDate() {
    return Number(this.timeBudgetDate) < Number(this.baseDate);
  }

  showMessage() {
    if (this.loading) return false;

    return !this.timeBudgetData.length && !this.drinkingDailyData.length;
  }

  showChart() {
    if (this.loading) return false;

    return this.timeBudgetData.length || this.drinkingDailyData.length;
  }

  shouldDisableTimebudget() {
    if (!this.ble) {
      return false;
    }
    return CowBleId.isEartagSensor(this.ble.currentCowBleId);
  }
}

function temporaryGroupViewCowDetailTimeBudget() {
  return {
    restrict: 'E',
    templateUrl: 'temporary-group-view/cow-detail/summary/time-budget.html',
    controller: temporaryGroupViewCowDetailTimeBudgetController,
    controllerAs: 'ctrl',
    scope: true,
    replace: true,
    bindToController: {
      cow: '<',
      ble: '<',
      baseDate: '<',
      timeBudgetDate: '<',
      minTimeBudgetDate: '<'
    }
  };
}

app.directive('temporaryGroupViewCowDetailTimeBudget', temporaryGroupViewCowDetailTimeBudget);
