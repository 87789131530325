/**
 * タイムライングラフDirective
 *  使い方: <timeline-chart config="timeLineChartConfig"></timeline-chart>
 *
 * timeLineChartConfig = {
 *  data: {columns: [
 *    ['name1', ...data],
 *    ['name2', ...data],
 *    ['x', ...data], // axis data
 *  ]}, // データ
 *  xGridLines: [ // 過去イベント
 *    {value: number, text: 'string', class: ''},
 *  ],
 *  regions: [  // 所属牛群
 *    {start: number, end: number, class: ''}
 *  ],
 * }
 */
function TimelineChart($timeout, Colors) {
  'ngInject';
  // イベント名
  const names = {
    milk: '搾乳量',
    feed: '採食',
    rumination: '反芻',
    move: '動態',
    lie: '横臥',
    stand: '静止',
  };

  const tooltipLabels = {
    milk: 'kg',
    feed: '分',
    rumination: '分',
    move: '分',
    lie: '分',
    stand: '分',
  };

  /**
   * 最低限の設定で空チャート作成＆起動
   * @param {string} element
   */

  function drawChart(
    element,
    columns,
    axisXMax,
    zoom,
    xgrids,
    regions
  ) {
    return c3.generate({
      bindto: element,
      data: {
        columns,
        x: 'x',
        names,
        colors: {
          milk: Colors.blue,
          feed: Colors.limeGreen,
          rumination: Colors.orange,
          move: Colors.yellow,
          lie: Colors.purple,
          stand: Colors.grey,
        },
      },
      point: {
        show: false,
      },
      axis: {
        x: {
          label: {
            position: 'outer-right',
          },
          type: 'indexed',
          tick: {
            culling: {
              max: 12,
            },
            fit: false,
          },
          max: axisXMax,
          extent: zoom
        },
        y: {
          label: {
            position: 'outer-middle',
          },
          tick: {
            format: d3.format('d'),
          },
        }
      },
      size: {
        height: 250,
      },
      padding: {
        left: 55,
        right: 25,
      },
      zoom: {
        enabled: true,
      },
      tooltip: {
        format: {
          title: (d) => `${d}日経過`,
          value: (value, ratio, id) => `${value} ${tooltipLabels[id]}`,
        },
      },
      grid: {
        x: {
          lines: xgrids
        }
      },
      regions
    });
  }

  return {
    restrict: 'E',
    scope: {
      config: '=',
    },
    link: (s, el) => {
      // チャートのあれこれを更新
      function updateChart(config) {
        // 現在表示中のデータを取ってきて、新データロードと同時に旧データを削除
        const currentData = s.chart ? s.chart.data.shown() : null;

        if (!s.schart) {
          s.chart = drawChart(
            el[0],
            config.data.columns,
            config.axis.max,
            config.zoom,
            config.xGridLines,
            config.regions
          );
        } else {
          s.chart.load({
            columns: config.data.columns,
            unload: currentData.length ? currentData[0].id : '',
          });
        }

        // 上で軸更新したからここの処理を遅らせないと初期設定に戻っちゃう
        // 軸ラベル更新
        $timeout(() => {
          s.chart.axis.labels({
            x: config.labels.x,
            y: config.labels.y,
          });
        });
      }

      // configが更新される時に、チャートも更新
      s.$watch('config', (config) => {
        // チャートobjやデータがなければなにもしない
        if (!config || !config.init) return;

        updateChart(config);
      }, true);

      // 画面遷移時、メモリー解放
      s.$on('$destroy', () => {
        if (s.chart) {
          s.chart = s.chart.destroy();
        }
      });
    },
  };
}

app.directive('timelineChart', TimelineChart);
