class MobileAlertIndexController {
  static get routingState() {
    return 'mobileAlertList';
  }

  constructor(
    $stateParams,
    $modal,
    SessionCache,
    CustomlistRunner,
    MobileBulkActionSelectModal,
    MobileBulkEditLabelSelectModal,
    modalDialogFactory,
    DetailsStateFactory
  ) {
    'ngInject';
    const base = new MobileCustomlistControllerBase(
      $stateParams,
      $modal,
      SessionCache,
      CustomlistRunner,
      MobileBulkActionSelectModal,
      MobileBulkEditLabelSelectModal,
      modalDialogFactory,
      MobileAlertIndexController.routingState
    );
    MobileCustomlistBinder.bind(this, base);
    this.useGroupCondition = true;
    this.DetailsStateFactory = DetailsStateFactory;
    this.DetailsStateFactory.initialise();
  }

  shouldShowPeakAt(cow) {
    return cow.peak_at ? true : false;
  }

  shouldAlertType(cow) {
    return !!cow.alertLabels;
  }

  shouldShowTagDroppedOut(cow) {
    return !!cow.alert_notes;
  }

  // alertType「illness」などで指定したかったがPCの印刷時に影響がでてしまうため苦肉の策
  alertLabelClass(alertLabel) {
    if (alertLabel === '急性(改)') return 'alertLabel--acuteIllness';
    if (alertLabel === '急性') return 'alertLabel--illness';
    if (alertLabel === '慢性') return 'alertLabel--chronicIllness';
  }

  $onInit() {
    this.run(DateUtil.today());
  }
}

app.controller('MobileAlertIndexController', MobileAlertIndexController);
