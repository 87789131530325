class GroupActivityScatterPlotFatteningStageListController {
  onSelect(stage) {
    this.selectedId = stage.id;
    this.onChangeId({'$id': stage.id});
  }

  addClassActive(stage) {
    if (this.selectedId === stage.id) {
      return 'active';
    }
  }

  stageCaption(stage) {
    return FatteningStage.description(stage);
  }
}

app.controller('GroupActivityScatterPlotFatteningStageListController', GroupActivityScatterPlotFatteningStageListController);

function GroupActivityScatterPlotFatteningStageListDirective() {
  'ngInject';
  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'menu/group-activity-scatter-plot/fattening-stage-list.html',
    controller: 'GroupActivityScatterPlotFatteningStageListController',
    controllerAs: 'ctrlFatteningStage',
    bindToController: {
      fatteningStages: '<',
      selectedId: '<',
      onChangeId: '&',
    }
  };
}

app.directive('groupActivityScatterPlotFatteningStageList', GroupActivityScatterPlotFatteningStageListDirective);
