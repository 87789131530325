// eslint-disable-next-line no-unused-vars
class CustomlistDateCondition {
  static validateOperator(operator) {
    const operators = [
      'before', 'after', 'between', 'present', 'blank',
      'last_recent_days', 'last_recent_months', 'days_ago', 'months_ago',
      'current_month', 'last_month', 'current_year', 'fiscal_year'
    ];
    return operators.some((s) => operator === s);
  }

  static needConditionNumber1(operator) {
    const operators = [
      'before', 'after', 'between',
      'last_recent_days', 'last_recent_months',
      'days_ago', 'months_ago'
    ];
    return operators.some((s) => operator === s);
  }

  static needCorrectType1(operator) {
    const operators = ['before', 'after', 'between'];
    return operators.some((s) => operator === s);
  }

  static needConditionNumber2(operator) {
    return operator === 'between';
  }

  static needCorrectType2(operator) {
    return operator === 'between';
  }

  static needConditionExplanation(operator) {
    return ['before', 'after', 'between'].some((s) => operator === s);
  }
}
