class HomeAlertCalvingSensorDroppedController {
  constructor() {
    'ngInject';

    HomeAlertBaseController.bind(this);
  }
}

function homeAlertCalvingSensorDropped() {
  return {
    templateUrl: 'menu/home/alert/calving-sensor-dropped/index.html',
    controller: HomeAlertCalvingSensorDroppedController,
    controllerAs: 'ctrl',
    bindings: {
      summary: '<'
    }
  };
}

app.component('homeAlertCalvingSensorDropped', homeAlertCalvingSensorDropped());
