class HomeCoolPesconController {
  constructor(
    $timeout,
    SessionCache,
    CoolPesconApi
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.SessionCache = SessionCache;
    this.CoolPesconApi = CoolPesconApi;
  }

  $doCheck() {
    if (this.umPlatformPartner) return;

    const umPlatformPartner = this.SessionCache.umPlatformPartner();

    if (!umPlatformPartner) return;

    this.umPlatformPartner = umPlatformPartner;
    this.hasCoolPescon = this.umPlatformPartner.hasCoolPescon();

    if (!Object.keys(this.umPlatformPartner.data).length) {
      this.hasCoolPescon = false;
      this.loading.coolPescon = false;

      this.$timeout(() => {
        this.enableMasonry();
      }, 500);

      return;
    }

    if (!this.hasCoolPescon) {
      this.loading.coolPescon = false;

      this.$timeout(() => {
        this.enableMasonry();
      }, 500);

      return;
    }

    this.index().then(() => {
      this.loading.coolPescon = false;

      this.$timeout(() => {
        this.enableMasonry();
      }, 500);
    });
  }

  index() {
    return this.CoolPesconApi.account().then((res) => {
      const coolPescon = new CoolPescon(res.data);

      this.activeCount = coolPescon.activeCount();
      this.inactiveCount = coolPescon.inactiveCount();
    });
  }
}

function homeCoolPescon() {
  return {
    templateUrl: 'menu/home/cool-pescon/index.html',
    controller: HomeCoolPesconController,
    controllerAs: 'ctrl',
    bindings: {
      hasCoolPescon: '=',
      enableMasonry: '&',
      loading: '='
    }
  };
}

app.component('homeCoolPescon', homeCoolPescon());
