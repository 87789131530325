class BredDigestDialogController {
  constructor(
    $scope,
    $modalInstance,
    params) {
    'ngInject';
    this.$scope = $scope;
    this.$modalInstance = $modalInstance;

    this.initialize(params);
  }

  initialize(params) {
    const paramContents = params.options.contents;
    const contents = {
      date: paramContents.includes('date'),
      comment: paramContents.includes('comment'),
      detail: paramContents.includes('detail'),
    };

    const paramEventTypes = params.options.eventTypes;
    const eventTypes = {
      bred: paramEventTypes.includes('bred'),
      diagnosis: paramEventTypes.includes('diagnosis'),
      timedAi: paramEventTypes.includes('timed_ai'),
      heat: paramEventTypes.includes('heat'),
    };

    this.options = {
      contents: contents,
      eventTypes: eventTypes,
      range: params.options.range,
      sortOrder: params.options.sortOrder,
      style: params.options.style,
    };
    this.setOptions();

    this.updateContents();
    this.updateEventTypes();
  }

  setOptions() {
    this.rangeOptions = [
      {value: 'latest', label: '直近の作業のみ'},
      {value: 'all', label: '全ての作業'},
    ];
    this.sortOrderOptions = [
      {value: 'asc', label: '作業日の昇順'},
      {value: 'desc', label: '作業日の降順'},
    ];
    this.styleOptions = [
      {value: 'line_break', label: '作業毎に行を分ける'},
      {value: 'one_line', label: '1行にまとめる'},
    ];
  }

  validate() {
    this.invalid = true;

    if (this.contents.length === 0) return;
    if (this.eventTypes.length === 0) return;

    this.invalid = false;
  }

  updateContents() {
    this.contents = ['date', 'comment', 'detail'].filter((content) => {
      return this.options.contents[content];
    });
  }

  updateEventTypes() {
    this.eventTypes = ['bred', 'diagnosis', 'timedAi', 'heat'].filter((eventType) => {
      return this.options.eventTypes[eventType];
    }).map((eventType) => {
      return eventType === 'timedAi' ? 'timed_ai' : eventType;
    });
  }

  onClickContents() {
    this.updateContents();
    this.validate();
  }

  onClickEventTypes() {
    this.updateEventTypes();
    this.validate();
  }

  decide() {
    const result = {
      contents: this.contents,
      eventTypes: this.eventTypes,
      range: this.options.range,
      sortOrder: this.options.sortOrder,
      style: this.options.style,
    };
    this.$modalInstance.close(result);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('BredDigestDialogController', BredDigestDialogController);
