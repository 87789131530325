class HomeDailyReportTimelineController {
  constructor(
    $modal,
    blockUI,
    TimelineAPI,
    FarmDiaryAPI,
    SessionCache,
    StandardDialog
  ) {
    'ngInject';

    this.$modal = $modal;
    this.blockUI = blockUI;
    this.TimelineAPI = TimelineAPI;
    this.FarmDiaryAPI = FarmDiaryAPI;
    this.StandardDialog = StandardDialog;
    this.useName = SessionCache.farm().useName();
    this.canEditFilterItem = !SessionCache.account().isItemFilterApplied();
    this.homeSettings = new HomeSettings(SessionCache);
  }

  $doCheck() {
    if (!this.map || !this.date || (this.previousDate === this.date && !this.reload)) return;

    this.loading.dailyReport = true;
    this.previousDate = this.date;
    this.reload = false;

    this.fetchTimeline(this.date).then((res) => {
      this.index(res);
      this.loading.dailyReport = false;
      this.enableMasonry();
    });
  }

  fetchTimeline(date) {
    if (!date) return;

    date = DateUtil.toW3CFormat(date);

    return this.TimelineAPI.index(date).then((res) => {
      return res.data.filter((record) => {
        return record.eventType !== 'shukkateishikaijo';
      }).map((record) => {
        const eventType = this.map.eventTypeMap[record.eventType];
        const items = HomeDailyReportTimelineItem.items(eventType, this.useName, this.canEditFilterItem);

        return TimelineItemDefinition.toDisplayFormat(items, record, this.map);
      }).sort((a, b) => {
        return b.date - a.date;
      });
    });
  }

  index(records) {
    this.records = records.length ? records.slice(0, 5) : [];
  }

  showTimelineEditButton(eventType) {
    return eventType === 'farm_diary';
  }

  showTimelineToggleButton(length) {
    return length > 2;
  }

  showTimelineMenu(id) {
    return this.isEditFarmDiary(id);
  }

  showDetail(value) {
    if (!this.isCowDetailLink(value)) return value;

    return value.cows.length;
  }

  showTimelineMask(record) {
    return !this.isVisibleDetails(record.id) && record.details.length > 2;
  }

  classNameTimelineEditButton(id) {
    return this.isEditFarmDiary(id) ?
      'uWidget--dailyReport__timelineEditButton' : 'uWidget--dailyReport__timelineEditButton--closed';
  }

  classNameTimelineToggleButton(id) {
    return this.isVisibleDetails(id) ?
      'uWidget--dailyReport__timelineToggleButton' : 'uWidget--dailyReport__timelineToggleButton--closed';
  }

  styleContainer(record) {
    if (record.details.length <= 2) return;

    const isVisibleDetails = this.isVisibleDetails(record.id);

    if (isVisibleDetails) {
      const elmItem = `.uWidget--dailyReport__timelineItem[data-record-id="${record.id}"]`;
      const elmInner = ' .uWidget--dailyReport__timelineInner';
      const height = document.querySelector(elmItem).querySelector(elmInner).clientHeight;

      return {
        height: `${height}px`
      };
    }

    return {
      height: '91px'
    };
  }

  updateEditFarmDiary(id) {
    if (this.isEditFarmDiary(id)) {
      this.editFarmDiaryId = null;
      return;
    }

    this.editFarmDiaryId = id;
  }

  updateVisibleDetails(id) {
    const isVisibleDetails = this.isVisibleDetails(id);
    const visibleDetails = this.homeSettings.get('dailyReport').visibleDetails;
    let newVisibleDetails;

    if (isVisibleDetails) {
      newVisibleDetails = {
        visibleDetails: visibleDetails.filter((item) => item !== id)
      };
    } else {
      newVisibleDetails = {
        visibleDetails: [...visibleDetails, ...[id]]
      };
    }

    this.homeSettings.update('dailyReport', newVisibleDetails);
    this.enableMasonry();
  }

  isCowDetailLink(value) {
    if (typeof value !== 'object') return false;

    return value.type === 'cowDetailLinks';
  }

  isEditFarmDiary(id) {
    return this.editFarmDiaryId === id;
  }

  isVisibleDetails(id) {
    return this.homeSettings.get('dailyReport').visibleDetails.includes(id);
  }

  onClickAll() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/home/daily-report/timeline/detail.html',
      controller: 'HomeDailyReportTimelineDetailController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            date: this.date,
            fetchTimeline: (date) => {
              return this.fetchTimeline(date);
            }
          };
        }
      }
    }).result.then((res) => {
      this.date = res.date;

      this.fetchTimeline(this.date).then((res) => {
        this.index(res);
      });
    });
  }

  onClickEdit(historyId) {
    const viewMode = new ViewMode('update');

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'history/nisshi/form.html',
      controller: 'FarmHistoryController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            historyId,
            viewMode
          };
        }
      }
    }).result.then(() => {
      this.loading.dailyReport = true;

      this.fetchTimeline(this.date).then((res) => {
        this.index(res);
        this.loading.dailyReport = false;
        this.editFarmDiaryId = null;
        this.enableMasonry();
      });
    });
  }

  onClickDelete(id) {
    this.StandardDialog.showDeleteConfirm({
      title: '牧場日誌',
      text1: '牧場日誌'
    }).result.then((res) => {
      if (!res) return;

      this.blockUI.start('削除中');

      this.FarmDiaryAPI.delete(id).then(() => {
        this.loading.dailyReport = true;

        this.fetchTimeline(this.date).then((res) => {
          this.index(res);
          this.loading.dailyReport = false;
          this.editFarmDiaryId = null;
          this.enableMasonry();
          this.blockUI.stop();
        });
      });
    });
  }
}

function homeDailyReportTimeline() {
  return {
    templateUrl: 'menu/home/daily-report/timeline/index.html',
    controller: HomeDailyReportTimelineController,
    controllerAs: 'ctrl',
    bindings: {
      enableMasonry: '&',
      loading: '=',
      map: '<',
      date: '=',
      reload: '='
    }
  };
}

app.component('homeDailyReportTimeline', homeDailyReportTimeline());
