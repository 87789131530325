// eslint-disable-next-line no-unused-vars
class AlertCalving {
  static get CONFIRMED_STATUS() {
    return {
      sign: '兆候あり',
      no_sign: '兆候なし',
      other: 'その他'
    };
  }

  static get ALERT_LEVEL() {
    return {
      LOW: '弱',
      HIGH: '強'
    };
  }

  static toViewModel(calving) {
    const diffDays1 = DateUtil.diffDays(calving.expectedCalvingDate, new Date());
    let diffDaysLabel;
    if (diffDays1 > 0) {
      diffDaysLabel = `${diffDays1}日前`;
    } else if (diffDays1 < 0) {
      diffDaysLabel = `${Math.abs(diffDays1)}日後`;
    } else {
      diffDaysLabel = '今日';
    }

    const expectedCalvingDateLabel = calving.expectedCalvingDate ?
      `${DateUtil.toYYMMDD(calving.expectedCalvingDate)}(${diffDaysLabel})` : '';

    const diffDays2 = DateUtil.diffDays(calving.latestCalvingDate, new Date());
    const latestCalvingDateLabel = calving.latestCalvingDate ?
      `${DateUtil.toYYMMDD(calving.latestCalvingDate)}(${diffDays2}日)` : '';

    const diffDays3 = DateUtil.diffDays(calving.latestPregnancyDate, new Date());
    const latestPregnancyDateLabel = calving.latestPregnancyDate ?
      `${DateUtil.toYYMMDD(calving.latestPregnancyDate)}(${diffDays3}日)` : '';

    const diffMinutes = DateUtil.diffMinutes(calving.startAt, new Date());
    let startAtLabel;
    if (diffMinutes < 60) {
      startAtLabel = '1時間以内';
    } else {
      const hours = Math.floor(diffMinutes / 60);
      startAtLabel = `${hours}時間前`;
    }

    let latestSpermLabel;
    if (calving.latestBullName) {
      latestSpermLabel = `${calving.latestBullName}(${calving.latestMasterSpermNo})`;
    } else if (calving.latestMasterSpermNo) {
      latestSpermLabel = calving.latestMasterSpermNo;
    }

    return Object.assign({}, calving, {
      alertLevelLabel: calving.alertLevel === 'HIGH' ? '強' : '弱',
      confirmedStatusLabel: AlertCalving.CONFIRMED_STATUS[calving.confirmedStatus],
      formattedCowUid: Cow.formatCowUid(calving.cowUid),
      combinedGroupName: calving.pen ? `${calving.cowGroupName}(${calving.pen})` : calving.cowGroupName,
      expectedCalvingDateLabel: expectedCalvingDateLabel,
      latestCalvingDateLabel: latestCalvingDateLabel,
      latestPregnancyDateLabel: latestPregnancyDateLabel,
      startAtDate: DateUtil.toDate(calving.startAt),
      startAtLabel: startAtLabel,
      latestSpermLabel: latestSpermLabel || '',
      indexes: this.generateIndexes(calving),
      gridXLines: this.generateGridXLines(calving.recentAlerts)
    });
  }

  // private

  static generateIndexes(calving) {
    const startMSec = DateUtil.addHours(calving.startAt, -12).getTime();
    const endMSec = DateUtil.addHours(calving.startAt, 6).getTime();
    const indexes = [];

    for (let mSec = startMSec; mSec < endMSec; mSec += 600000) {
      const index = calving.indexes.find((i) => i.measuredAt === mSec);
      indexes.push({
        time: DateUtil.toDate(mSec),
        score: index ? index.score : null
      });
    }
    return indexes;
  }

  static generateGridXLines(recentAlerts) {
    return recentAlerts.map((a) => {
      const value = DateUtil.toDate(a.startAt);
      if (a.falseDetection) {
        return {value: value, text: '兆候なし', class: 'chart-grid-line-gray'};
      } else {
        const text = `分娩兆候: ${AlertCalving.ALERT_LEVEL[a.alertLevel]}`;
        return {value: value, text: text, class: 'chart-grid-line-orange'};
      }
    });
  }
}
