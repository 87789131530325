/* eslint-disable */
class Medicine {
  static get METHODS() {
    return [
      {key: '経口投与', value: '経口投与'},
      {key: '筋肉注射', value: '筋肉注射'},
      {key: '皮下注射', value: '皮下注射'},
      {key: '静脈注射', value: '静脈注射'},
      {key: '子宮注射', value: '子宮注射'},
      {key: '乳房注射', value: '乳房注射'},
      {key: '塗布', value: '塗布'},
      {key: '点滴', value: '点滴'},
      {key: 'その他', value: 'その他'}
    ];
  }

  /**
   * 引数の薬品を結合し返す
   * [example]
   * medicines = [
   *  { id: 1, capacity: 10, method: 'その他' },
   *  { id: 2, capacity: 1, method: '散布' }
   * ];
   *
   * joinedMedicines = joinMedicines(medicines);
   * console.log(joinedMedicines) => {
   *  masterMedicineIds: '1、2',
   *  medicineCapacities: '10、1',
   *  medicineMethods: 'その他、散布'
   * }
   *
   * @param {Array<Object>} medicines
   * @return {Object}
   */
  static joinMedicines(medicines) {
    const joinedMedicines = {};
    if (medicines && medicines.length) {
      const masterMedicineIds = [];
      const medicineCapacities = [];
      const medicineMethods = [];

      medicines.forEach((medicine) => {
        masterMedicineIds.push(medicine.id);
        medicineCapacities.push(medicine.capacity);
        medicineMethods.push(medicine.method);
      });

      joinedMedicines.masterMedicineIds = masterMedicineIds.join('、');
      joinedMedicines.medicineCapacities = medicineCapacities.join('、');
      joinedMedicines.medicineMethods = medicineMethods.join('、');
    }
    return joinedMedicines;
  }

  constructor(params, farmType) {
    // Change to Object.assign in the future
    this.farmType = farmType;
    this.origin = angular.extend({}, this.defaultContent_(), params);
    this.initializeContent();
  }

  // public method

  /**
   * コンテンツを初期化する。
   */
  initializeContent () {
    angular.extend(this, this.origin);
  }

  // private method

  /**
   * デフォルトオブジェクトを返す。
   * 
   * @return {Object} デフォルトオブジェクト
   */
  defaultContent_ () {
    return {
      medicineCode: '',
      name: '',
      defaultCapacityAdultCow: null,
      defaultCapacityCalf: null,
      unit: null,
      defaultTreatmentMethod: null,
      milkWashoutPeriod: null,
      beefWashoutPeriod: null,
      mastitis: Farm.isFarmTypeMilk(this.farmType),
      breedingDifficulty: true,
      perinatalDifficulty: true,
      injury: true,
      lame: true,
      infect: true,
      otherDifficulty: true,
      vaccine: true
    };
  }
}
