class MilkingScheduleEditController {
  constructor(
    $modalInstance,
    MilkingScheduleService,
    params,
    blockUI
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.MilkingScheduleService = MilkingScheduleService;
    this.milkingSchedules = params.milkingSchedules;
    this.blockUI = blockUI;

    this.invalid = false;
    this.decideRemovable();
  }

  save() {
    const formatErrorMessage = (messages) => {
      return messages.map((message) => message.message).join('\n');
    };

    const params = {
      'schedules': this.milkingSchedules
    };

    this.blockUI.start('更新中');
    this.MilkingScheduleService.update(params)
      .then((res) => {
        this.blockUI.stop();
        this.$modalInstance.close();
      }).catch((res) => {
        this.blockUI.stop();
        this.message = formatErrorMessage(res.data.messages);
      });
  }

  addRow() {
    const record = {'milkingOrder': this.milkingSchedules.length + 1, 'startAt': '', 'endAt': ''};
    this.milkingSchedules.push(record);
    this.decideRemovable();
    this.validateTime();
  }

  removeRow() {
    this.milkingSchedules.pop();
    this.decideRemovable();
    this.validateTime();
  }

  keydownTime(e, value) {
    if (e.key === ':' && value.indexOf(':') >= 0) {
      e.preventDefault();
      return;
    }

    if (!this.isTimeKey(e.key)) {
      e.preventDefault();
    }
  }

  isTimeKey(key) {
    if ('0123456789:'.indexOf(key) >= 0) {
      return true;
    }
    // タブやリターンキーなどの特殊キーに対応するための例外処理
    if (key.length > 1) {
      return true;
    }
    return false;
  }

  static get TIME_REGEX() {
    return /^[0-9]{1,2}:[0-9]{1,2}$/;
  }

  onChangeTime(record, field) {
    record[field] = StringUtil.toHalfWidthCharacters(record[field]);
    this.validateTime();
  }

  validateTime() {
    const valid = this.milkingSchedules.every((r) => {
      return MilkingScheduleEditController.TIME_REGEX.test(r.startAt)
        && MilkingScheduleEditController.TIME_REGEX.test(r.endAt);
    });
    this.invalid = !valid;
  }

  cancel() {
    this.$modalInstance.close(false);
  }

  decideRemovable() {
    this.milkingSchedules.forEach((record, index) => {
      record['removable'] = index > 0 && index === (this.milkingSchedules.length - 1);
    });
  }
}

app.controller('MilkingScheduleEditController', MilkingScheduleEditController);
