class CustomlistEventTypeController {
  constructor(
    $scope,
    $modalInstance,
    CustomlistAPI,
    blockUI
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.CustomlistAPI = CustomlistAPI;
    this.blockUI = blockUI;

    $scope.$on('ngRepeatFinished', () => {
      FixedMidashi.create();
    });

    this.initialize();
  }

  initialize() {
    this.records = [];
    this.CustomlistAPI.eventTypes().then((res) => {
      this.records = res.data;
    });
  }

  isValid() {
    return this.records.some((r) => r.selected);
  }

  ok() {
    this.blockUI.start('更新中');

    const eventTypes = this.records
      .filter((r) => r.selected)
      .map((r) => {
        return {eventType: r.eventType};
      });
    const params = {eventTypes: eventTypes};

    this.CustomlistAPI.updateEventType(params)
      .then((result) => {
        this.blockUI.stop();
        this.$modalInstance.close();
      }, (reject) => {
        this.blockUI.stop();
        this.$scope.message = reject;
      });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('CustomlistEventTypeController', CustomlistEventTypeController);
