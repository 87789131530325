class DailyReportDetailsController {
  constructor(
    $q,
    $state,
    $modal,
    $stateParams,
    blockUI,
    CowGroupAPI,
    FarmDiaryAPI,
    SelectionAPI,
    SessionCache,
    StandardDialog,
    DailyReportAPI,
    SideBoardService,
    ReproductionConfigService
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$modal = $modal;
    this.blockUI = blockUI;
    this.CowGroupAPI = CowGroupAPI;
    this.FarmDiaryAPI = FarmDiaryAPI;
    this.SelectionAPI = SelectionAPI;
    this.StandardDialog = StandardDialog;
    this.DailyReportAPI = DailyReportAPI;
    this.SideBoardService = SideBoardService;
    this.ReproductionConfigService = ReproductionConfigService;

    this.canEditFilterItem = !SessionCache.account().isItemFilterApplied();

    this.condition = {
      date: $stateParams.date,
      eventType: $stateParams.eventType
    };

    this.currentColumnSort = $stateParams.currentColumnSort || null;

    this.init();
  }

  init() {
    this.$q.all([
      this.CowGroupAPI.index(),
      this.SelectionAPI.index(),
      this.ReproductionConfigService.show()
    ]).then((res) => {
      const cowGroupMap = res[0].data.reduce((acc, cur) => {
        acc[cur.cowGroupId] = cur;
        return acc;
      }, {});

      const nextCowStateMap = res[1].data.embryoRecoveryNextCowState.reduce((acc, cur) => {
        acc[cur.value] = cur;
        return acc;
      }, {});

      this.map = {
        cowGroupMap,
        nextCowStateMap
      };

      this.heading = EventType.eventName(this.condition.eventType);
      this.items = DailyReportDetailsItem.items(this.condition, this.canEditFilterItem);
      this.filterItems(res[2].data.embryoRecoveryRank);
      this.columnMap = this.columnMap();
      this.cowBoardDefaultTab = 'info';

      this.index();
    });
  }

  filterItems(embryoRecoveryRank) {
    if (this.condition.eventType !== 'embryo_recovery') return;

    const itemIds = Object.entries(embryoRecoveryRank).filter(([_, value]) => {
      return value.use;
    }).map(([rank, value]) => {
      const {subrank} = value;
      const name = `embryoRecoveryRank${rank[0].toUpperCase()}${rank.slice(1)}`;

      if (!subrank || subrank === 1) {
        return name;
      }

      return Array(subrank).fill('').map((_, index) => {
        const subrank = index + 1;

        return `${name}${subrank}`;
      });
    }).flat();

    this.items = this.items.filter((item) => {
      return !item.itemId.startsWith('embryoRecoveryRank') || itemIds.includes(item.itemId);
    });
  }

  columnMap() {
    return this.items.reduce((acc, cur) => {
      acc[cur.itemId] = cur;
      acc[cur.itemId].columnId = cur.itemId;

      return acc;
    }, {});
  }

  index() {
    const legacyEventType = EventType.toLegacyEventType(this.condition.eventType);

    this.DailyReportAPI.index(this.condition.date, legacyEventType).then((res) => {
      const generator = new DailyReportDetailsGenerator();

      this.cows = DailyReportItemDefinition.toDisplayFormat(this.items, res.data, this.map);
      this.rawCows = [].concat(this.cows);

      this.uiGrid = {
        appScopeProvider: this,
        columnDefs: generator.generateColumnDefs(this.items),
        rowTemplate: generator.generateRowTemplate(),
        data: this.cows,
        onRegisterApi: (gridApi) => {
          this.gridApi = gridApi;
        }
      };

      if (this.currentColumnSort) {
        const {column, isAscending} = this.currentColumnSort;
        CustomlistSort.sort(this.cows, column, isAscending);
      }
    });
  }

  goToDetails(cowId) {
    const params = {
      cowId,
      caller: {
        state: 'daily-report-details',
        name: this.heading,
        cowIds: this.cows.map((cow) => cow.cowId),
        params: {
          name: `作業日報 ${this.heading}`,
          date: this.condition.date,
          eventType: this.condition.eventType,
          currentColumnSort: this.currentColumnSort
        }
      }
    };

    this.$state.go('cowDetail', params);
  }

  updateCurrentCow(cow = null) {
    if (!cow || !cow.cowId) {
      this.currentCow = null;

      return;
    }

    this.SideBoardService.setState('opened', false);
    this.currentCow = {
      cowId: cow.cowId
    };
  }

  cowBoardState() {
    return this.currentCow ? 'cow-board-opened' : '';
  }

  onClickColumnHeader(itemId, gridCol) {
    if (CustomlistSort.isUnsortable(itemId)) return;

    const column = this.columnMap[itemId];

    if (this.currentColumnSort && this.currentColumnSort.column.itemId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        CustomlistSort.sort(this.cows, column, false);
        this.currentColumnSort = {column, isAscending: false};
      } else {
        this.currentColumnSort = null;
        this.cows = [].concat(this.rawCows);
        this.uiGrid.data = this.cows;
      }
    } else {
      CustomlistSort.sort(this.cows, column, true);
      this.currentColumnSort = {column, isAscending: true};
    }
  }

  onClickEdit(historyId) {
    const viewMode = new ViewMode('update');

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'history/nisshi/form.html',
      controller: 'FarmHistoryController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            historyId,
            viewMode
          };
        }
      }
    }).result.then(() => {
      this.index();
      this.sideBoardReload = true;
    });
  }

  onClickDelete(id) {
    this.StandardDialog.showDeleteConfirm({
      title: '牧場日誌',
      text1: '牧場日誌'
    }).result.then((res) => {
      if (!res) return;

      this.blockUI.start('削除中');

      this.FarmDiaryAPI.delete(id).then(() => {
        this.index();
        this.sideBoardReload = true;
        this.blockUI.stop();
      });
    });
  }

  classHeaderCell(gridCol) {
    if (this.currentColumnSort && this.currentColumnSort.column.itemId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        return 'ui-grid-sorted-asc';
      } else {
        return 'ui-grid-sorted-desc';
      }
    }
  }

  classCell(cowId) {
    if (!this.currentCow) return;
    if (cowId !== this.currentCow.cowId) return;

    return 'ui-grid-row-cow-board-opened';
  }
}

app.controller('DailyReportDetailsController', DailyReportDetailsController);
