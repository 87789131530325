// eslint-disable-next-line no-unused-vars
class AlertDysstasiaDetectionThresholdOption {
  static get MIN_THRESHOLD() {
    return 16;
  }

  static get MAX_THRESHOLD() {
    return 50;
  }

  static get DEFAULT_THRESHOLD() {
    return 25;
  }

  static get NOT_SPECIFIED_MONTH_AGE_TO() {
    return 9999;
  }

  static toViewModel(params) {
    return params.map((p) => {
      const temp = angular.copy(p);
      temp.thresholds.forEach((t) => {
        t.disabledMonthAgeTo = t.monthAgeTo === AlertDysstasiaDetectionThresholdOption.NOT_SPECIFIED_MONTH_AGE_TO;
        if (t.disabledMonthAgeTo) t.monthAgeTo = null;
      });
      return temp;
    });
  }

  static createViewModel(thresholdOptions) {
    let sortOrder;
    if (thresholdOptions.length) {
      sortOrder = Math.max(...thresholdOptions.map((o) => o.sortOrder)) + 1;
    } else {
      sortOrder = 1;
    }

    return {
      sortOrder,
      matchingCondition: {breed: []},
      thresholds: [
        {
          threshold: AlertDysstasiaDetectionThresholdOption.DEFAULT_THRESHOLD,
          disabledMonthAgeTo: false
        }
      ]
    };
  }

  static createRequestParams(input) {
    return input.map((cur) => {
      const temp = angular.copy(cur);
      temp.thresholds.map((t) => {
        if (t.disabledMonthAgeTo) {
          t.monthAgeTo = AlertDysstasiaDetectionThresholdOption.NOT_SPECIFIED_MONTH_AGE_TO;
        }
        delete t.disabledMonthAgeTo;
      });
      return temp;
    });
  }

  static validate(input) {
    if (input.matchingCondition.breed.length === 0) return false;

    const invalid = input.thresholds.some((t) => {
      const {
        threshold,
        monthAgeFrom,
        monthAgeTo,
        disabledMonthAgeTo
      } = t;

      if (!threshold) return true;
      if ([null, undefined, ''].includes(monthAgeFrom)) return true;
      if (disabledMonthAgeTo === false && [null, undefined, ''].includes(monthAgeTo)) return true;

      if (threshold < AlertDysstasiaDetectionThresholdOption.MIN_THRESHOLD) return true;
      if (threshold > AlertDysstasiaDetectionThresholdOption.MAX_THRESHOLD) return true;
    });

    return !invalid;
  }
}
