class HomeDailyReportTimelineDetailController {
  constructor(
    $modal,
    $modalInstance,
    params,
    blockUI,
    SessionCache,
    FarmDiaryAPI,
    StandardDialog
  ) {
    'ngInject';

    this.$modal = $modal;
    this.$modalInstance = $modalInstance;
    this.params = params;
    this.blockUI = blockUI;
    this.FarmDiaryAPI = FarmDiaryAPI;
    this.StandardDialog = StandardDialog;
    this.homeSettings = new HomeSettings(SessionCache);

    this.init();
  }

  init() {
    this.date = this.params.date;
    this.filterCondition = [];

    this.index();
  }

  index() {
    return this.params.fetchTimeline(this.date).then((res) => {
      this.rawRecords = res;
      this.records = this.rawRecords;
    });
  }

  showDetail(value) {
    if (!this.isCowDetailLink(value)) return value;

    return value.cows.length;
  }

  showTimelineEditButton(eventType) {
    return eventType === 'farm_diary';
  }

  showTimelineMenu(id) {
    return this.isEditFarmDiary(id);
  }

  isCowDetailLink(value) {
    if (typeof value !== 'object') return false;

    return value.type === 'cowDetailLinks';
  }

  isEditFarmDiary(id) {
    return this.editFarmDiaryId === id;
  }

  filter() {
    if (!this.filterCondition.length) {
      this.records = this.rawRecords;
      return;
    }

    this.records = this.rawRecords.filter((record) => {
      return this.filterCondition.includes(record.eventType);
    });
  }

  changeDate(days = 0) {
    if (days) {
      this.date = DateUtil.addDays(this.date, days);
    }

    this.homeSettings.update('dailyReport', {date: this.date});

    this.index().then(() => {
      this.filter();
    });
  }

  close() {
    this.$modalInstance.close({
      date: this.date
    });
  }

  updateEditFarmDiary(id) {
    if (this.isEditFarmDiary(id)) {
      this.editFarmDiaryId = null;
      return;
    }

    this.editFarmDiaryId = id;
  }

  classNameTimelineEditButton(id) {
    return this.isEditFarmDiary(id) ?
      'uWidget--dailyReport__timelineEditButton' : 'uWidget--dailyReport__timelineEditButton--closed';
  }

  onClickFilter() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/home/daily-report/timeline/filter.html',
      controller: 'HomeDailyReportTimelineFilterController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            filterCondition: this.filterCondition
          };
        }
      }
    }).result.then((res) => {
      this.filterCondition = res;
      this.filter();
    });
  }

  onClickEdit(historyId) {
    const viewMode = new ViewMode('update');

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'history/nisshi/form.html',
      controller: 'FarmHistoryController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            historyId,
            viewMode
          };
        }
      }
    }).result.then(() => {
      this.index().then(() => {
        this.filter();
        this.editFarmDiaryId = null;
      });
    });
  }

  onClickDelete(id) {
    this.StandardDialog.showDeleteConfirm({
      title: '牧場日誌',
      text1: '牧場日誌'
    }).result.then((res) => {
      if (!res) return;

      this.blockUI.start('削除中');

      this.FarmDiaryAPI.delete(id).then(() => {
        this.index().then(() => {
          this.filter();
          this.editFarmDiaryId = null;
          this.blockUI.stop();
        });
      });
    });
  }
}

app.controller('HomeDailyReportTimelineDetailController', HomeDailyReportTimelineDetailController);
