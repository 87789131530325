class FatteningReportConfigDialog {
  constructor(
    $modal,
    blockUI
  ) {
    'ngInject';
    this.$modal = $modal;
    this.blockUI = blockUI;
  }

  execute() {
    const config = {
      animation: true,
      templateUrl: 'menu/report/fattening/config/calculation.html',
      controller: 'FatteningReportConfigController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'select-standard'
    };

    return this.$modal.open(config).result.then((result) => {
      return result;
    });
  }
}

app.service('FatteningReportConfigDialog', FatteningReportConfigDialog);
