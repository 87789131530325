class MobileDysstasiaReportResultController {
  constructor(
    $modalInstance,
    $state,
    DetailsStateFactory,
    params
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.$state = $state;
    this.DetailsStateFactory = DetailsStateFactory;
    this.params = params;
    this.findingAtDisplay = DateUtil.toYYYYMMDDHHmm(this.params.findingAt);
  }

  cancel() {
    this.$modalInstance.close();
  }

  onClickCowDetail() {
    this.DetailsStateFactory.initialise();
    this.DetailsStateFactory.setDisplayTab(this.DetailsStateFactory.tabNames.timelineTab);
    this.$state.go('mobileCowDetail', {
      cowId: this.params.cowId
    });
  }

}

app.controller('MobileDysstasiaReportResultController', MobileDysstasiaReportResultController);
