// eslint-disable-next-line no-unused-vars
class MonthlyAccordionReportControllerBase {
  constructor(
    $scope,
    $state,
    $timeout
  ) {
    this.$scope = $scope;
    this.$state = $state;
    this.$timeout = $timeout;

    this.isResizeListenerAdded = false;
    this.shouldDisableSubmit = true;
    this.selections = {
      startYear: [],
      startMonth: [],
      endYear: [],
      endMonth: []
    };
  }

  initCommon(months) {
    this.monthlyData = [{}, {}, {}];
    this.bodyTableWrapperOriginalWidth = null;

    const startDate = DateUtil.subtractMonths(new Date(), months - 1);
    const endDate = new Date();

    this.loadData(startDate, endDate);

    this.startYear = startDate.getFullYear();
    this.startMonth = startDate.getMonth() + 1;
    this.endYear = endDate.getFullYear();
    this.endMonth = endDate.getMonth() + 1;

    this.initSelections();
  }

  initSelections() {
    this.selections.startYear = this.selections.endYear =
      DateUtil.years(new Date(2018, 0, 1), new Date())
        .reduce((acc, cur) => {
          acc.push({
            label: cur + '年',
            value: cur
          });
          return acc;
        }, []);
    this.selections.endMonth = this.selections.startMonth = [
      {value: 1, label: '1月'},
      {value: 2, label: '2月'},
      {value: 3, label: '3月'},
      {value: 4, label: '4月'},
      {value: 5, label: '5月'},
      {value: 6, label: '6月'},
      {value: 7, label: '7月'},
      {value: 8, label: '8月'},
      {value: 9, label: '9月'},
      {value: 10, label: '10月'},
      {value: 11, label: '11月'},
      {value: 12, label: '12月'}
    ];
  }

  onRangeChanged() {
    this.shouldDisableSubmit = false;
    this.errorMessage = '';
  }

  onSubmit() {
    const startDate = new Date(this.startYear, this.startMonth - 1, 1),
      endDate = new Date(this.endYear, this.endMonth - 1, 1);

    if (!this.validate(startDate, endDate)) {
      this.shouldDisableSubmit = true;
      return;
    }

    this.loadData(startDate, endDate);
  }

  validate(startDate, endDate) {
    if (DateUtil.diffMonths(startDate, endDate) + 1 > 13) {
      this.errorMessage = '表示期間は13ヶ月以内にしてください';

      return false;
    }

    if (startDate > endDate) {
      this.errorMessage = '終了月を開始月よりあとに設定してください';

      return false;
    }

    return true;
  }

  loadData(startDate, endDate) {
    this.dataMap = this.initDataMap(startDate, endDate);

    this.fetchMonthlyData(startDate, endDate, this.dataMap)
      .then((monthlyData) => {
        this.monthlyData = monthlyData;
        if (this.shouldAdjustScrollSize() && this.isReportReady()) {
          // $timeoutで画面描画によるサイズ変更を待って、スクロール位置を修正する(CPUの遅いマシン対応)
          this.$timeout(() => {
            this.adjustScrollSize();
          });

          if (!this.isResizeListenerAdded) {
            // ウィンドウをリサイズした際にスクロール用の余白を再計算する
            let stop = null;
            const onResize = () => {
              if (stop) {
                this.$timeout.cancel(stop);
              }

              // ドラッグ中にresizeイベントが連続するので、全てを処理しないように500ms待つ
              stop = this.$timeout(() => {
                if (this.isReportReady()) {
                  this.adjustScrollSize();
                }
              }, 500);
            };

            window.addEventListener('resize', onResize);
            this.$scope.$on('$destroy', () => {
              window.removeEventListener('resize', onResize);
            });

            this.isResizeListenerAdded = true;
          }
        }
      });
  }

  onPrint() {
    this.$timeout(() => {
      print();
    });
  }

  /**
   * 成績表のヘッダーで「年」を表示するか
   * @param {Number} index
   * @param {Number} monthData
   */
  shouldShowYear(index, monthData) {
    return (index === 0 || monthData.year !== this.monthlyData[index - 1].year);
  }

  /**
   * 繁殖成績表をスクロールした際に、最新３ヶ月の位置で止まるようにする
  */
  adjustScrollSize() {
    const scrollAreaElem = document.getElementById('scroll-area');
    const bodyTableWrapperElem = document.getElementById('body-table-wrapper');
    const headerTableElem = document.getElementById('header-table');
    const bodyTableElem = document.getElementById('body-table');

    if (!scrollAreaElem || !bodyTableWrapperElem || !headerTableElem || !bodyTableElem) {
      return;
    }

    // オリジナルのテーブルサイズを保存
    if (!this.bodyTableWrapperOriginalWidth) {
      this.bodyTableWrapperOriginalWidth = bodyTableWrapperElem.clientWidth;
    } else {
      bodyTableWrapperElem.style.paddingRight = '0px';
      bodyTableWrapperElem.style.width = bodyTableElem.style.width = (this.monthlyData.length * 8) + 'em';
    }

    // 表をスクロールしたときに、最新3ヶ月分の位置で停止するようにする
    const columnWidth = bodyTableWrapperElem.clientWidth / this.monthlyData.length;
    const paddingRight = scrollAreaElem.clientWidth - (columnWidth) * 3 - headerTableElem.clientWidth;

    bodyTableWrapperElem.style.width = `${bodyTableWrapperElem.clientWidth + paddingRight}px`;
    bodyTableWrapperElem.style.paddingRight = `${paddingRight}px`;

    // 初期状態とリサイズ時に、最新3ヶ月分が表示領域の右端に表示されるようにする
    const bodyTableWidth = bodyTableElem.clientWidth + headerTableElem.clientWidth;
    const scrollLeft = bodyTableWidth > scrollAreaElem.clientWidth
      ? bodyTableWidth - scrollAreaElem.clientWidth + 1
      : 0;

    scrollAreaElem.scrollLeft = scrollLeft;
  }

  /**
   * 表示用グラフデータがあるかどうかのチェック
  */
  isReportReady() {
    return this.monthlyData.length > 0;
  }

  /**
   * 表示用データのmapを初期化する
   */
  initDataMap(startMonth, endMonth) {
    const dataMap = {};

    for (let currentMonth = moment(startMonth); currentMonth <= endMonth; currentMonth.add(1, 'month')) {
      let year = currentMonth.format('YYYY'),
        month = currentMonth.format('MM');

      dataMap[year + month] = {
        year: year,
        month: month
      };
    }

    return dataMap;
  }

  /*
  * +-表示用のチェック
  */
  isPositive(value) {
    return value >= 0;
  }

  /*
  * +-表示用のチェック
  */
  isNegative(value) {
    return value < 0;
  }

  shouldShowErrorMessage() {
    return this.errorMessage !== '';
  }
}
