class HomeHeadageController {
  constructor(
    CowHeadageAPI
  ) {
    'ngInject';

    this.CowHeadageAPI = CowHeadageAPI;

    this.init();
  }

  init() {
    this.index();
  }

  index() {
    this.CowHeadageAPI.summary().then((res) => {
      this.data = Object.entries(res.data).reduce((acc, [key, items]) => {
        items.total = Object.values(items).reduce((acc, cur) => {
          if (typeof cur === 'object') {
            cur.total = Object.values(cur).reduce((acc, cur) => {
              acc += cur;

              return acc;
            }, 0);

            acc += cur.total;
          } else {
            acc += cur;
          }

          return acc;
        }, 0);

        if (key === 'dairy') {
          items.total = items.total - items.milkingStop;
        } else if (key === 'inventory') {
          items.lastMonth = items.current - items.increase.total + items.decrease.total;
        } else if (key === 'breed') {
          const data = {
            [key]: items
          };
          const category = key;

          items.subtotal = Object.entries(items).reduce((acc, [key, value]) => {
            if (key === 'total') {
              return acc;
            }

            acc.male += value.male;
            acc.female += value.female;

            return acc;
          }, {
            male: 0,
            female: 0
          });

          const menus = HeadageSummaryDefinition.menus({category}).map((menu) => {
            const count = data[category][menu.item];

            menu.counts = count;
            menu.count = count.total;
            menu.to = 'headage-details';
            menu.params = {
              category,
              subCategory: menu.subCategory,
              item: menu.item
            };

            return menu;
          });

          let breedItems = HeadageSummaryDefinition.grouping(menus);
          breedItems = HeadageSummaryDefinition.sortBreedItems(breedItems);

          items.items = [...breedItems[0].items, ...breedItems[1].items].filter((item) => {
            return item.count > 0;
          });
        }

        acc[key] = items;

        return acc;
      }, {});
    });
  }
}

function homeHeadage() {
  return {
    templateUrl: 'menu/home/headage/index.html',
    controller: HomeHeadageController,
    controllerAs: 'ctrl',
    bindings: {
      enableMasonry: '&'
    }
  };
}

app.component('homeHeadage', homeHeadage());
