function CowEventOptionDirective($compile) {
  'ngInject';

  const itemTypes = {
    // 繁殖系
    'fresh_check': 'bred',
    'bred': 'bred',
    'pregnant_diagnosis': 'bred',
    // 治療系
    'mastitis': 'treatment',
    'claw_diseases': 'treatment',
    'reproductive_disorders': 'treatment',
    'perinatal_and_metabolic_diseases': 'treatment',
    'infection_diseases': 'treatment',
    'injured': 'treatment',
    'others': 'treatment',
    // 枝肉成績
    'carcass_characteristic': 'carcass-characteristic',
    // コメントのみ
    'aborted': 'standard',
    'calved': 'standard',
    'timed_ai': 'standard',
    'do_not_breed': 'standard',
    'embryo_recovery': 'standard',
    'vaccination': 'standard',
    'dried': 'standard',
    'culled': 'standard',
    'died': 'standard',
    'moved': 'standard',
    'individual_observation': 'standard',
    'dehorned': 'standard',
    'castrated': 'standard',
    // 空欄
    'claw_trimmed': 'empty',
    'heat': 'empty',
    'body_weight_measurement': 'empty',
    'work_note': 'empty',
    'weaned': 'empty',
    'fattened': 'empty',
  };

  return {
    restrict: 'E',
    scope: '=',
    link: (s, el, attr) => {
      attr.$observe('eventType', () => {
        const fileName = itemTypes[attr.eventType];
        const template = $compile(
          `<div ng-include="'menu/cow-event/history/template/option/${fileName}.html'"></div>`
        )(s);
        el.html(template);
      });
    }
  };
}

app.directive('cowEventOptionDirective', CowEventOptionDirective);
