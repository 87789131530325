class SideBoardService {
  constructor(
    $rootScope
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.state = {};
  }

  setState(key, value) {
    this.state[key] = value;

    if (key === 'show') {
      this.$rootScope.isShowSideBoard = value;
    }

    this.notifyCloseCowBoard();
  }

  toggleState(key) {
    this.state[key] = !this.state[key];
    this.notifyCloseCowBoard();
  }

  getState(key) {
    return this.state[key];
  }

  notifyCloseCowBoard() {
    if (!this.state.opend) {
      this.$rootScope.$emit('cowBoard:closeCowBoard');
    }
  }
}

app.service('SideBoardService', SideBoardService);
