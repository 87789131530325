// eslint-disable-next-line no-unused-vars
class ViewMode {
  static get CREATE() {
    return 'create';
  }

  static get UPDATE() {
    return 'update';
  }

  static get DELETE() {
    return 'delete';
  }

  static get LABELS() {
    return {
      create: '登録',
      update: '更新',
      delete: '削除'
    };
  }

  constructor(mode) {
    this.mode = mode;
    this.label = ViewMode.LABELS[this.mode];
  }

  isCreate() {
    return this.mode === ViewMode.CREATE;
  }

  isUpdate() {
    return this.mode === ViewMode.UPDATE;
  }

  isDelete() {
    return this.mode === ViewMode.DELETE;
  }

  static asCreate() {
    return new ViewMode(ViewMode.CREATE);
  }

  static asUpdate() {
    return new ViewMode(ViewMode.UPDATE);
  }

  static asDelete() {
    return new ViewMode(ViewMode.DELETE);
  }
}
