class NotificationAPI {
  constructor(
    APIService
  ) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/notification');
  }
}

app.service('NotificationAPI', NotificationAPI);
