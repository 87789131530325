app.controller('settingController', function(
  $rootScope,
  $q,
  $scope,
  FarmService,
  AccountService
) {
  'ngInject';

  const tabs = [
    {title: '牛群', route: 'group', active: true},
    {title: '選択リスト設定', route: 'settingSelectList', active: false},
    {title: '牧場設定', route: 'farm', active: false},
    {title: 'アラート設定', route: 'alerts', active: false},
    {title: '繁殖', route: 'reproduction', active: false},
  ];

  const milkingScheduleTab = {title: '搾乳タイミング', route: 'milkingSchedule', active: false};
  const fatteningTab = {title: '肥育', route: 'fattening', active: false};

  $q.all([
    FarmService.show()
  ]).then((res) => {
    const farmAccount = res[0];
    const farm = new Farm(res[0]);

    // 「乳用」の牧場のみ搾乳タイミングのタブを追加する
    if (farmAccount.farmKind === '乳用') {
      tabs.splice(3, 0, milkingScheduleTab);
    }

    if (farm.isBeef()) {
      tabs.push(fatteningTab);
    }

    $scope.tabs = tabs;
  });
});
