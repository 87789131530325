class ReproductionPlannedEvent { // eslint-disable-line no-unused-vars
  /**
   *
   * @param {Array} events 繁殖予定イベントのリスト
  */
  static addDisplayFields(events, date) {
    const capitalize = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return events.map((event) => {
      const converted = ReproductionPlannedEvent.copyEvent(event);

      //日付ラベル YYYY/MM/DD
      [
        'latestCalvingDate',
        'operationDate',
        'operationAt',
        'estimatedDate',
        'latestPregnancyDate',
        'firstFertilizationDate',
        'latestFertilizationDate',
        'latestEstrusDate',
        'expectedCalvingDate'
      ].forEach((key) => {
        if (!key in converted || !converted[key]) return;

        converted[key + 'Display'] = DateUtil.toYYMMDD(converted[key]);
      });

      //日付ラベル MM/DD
      [
        'plannedAt',
      ].forEach((key) => {
        if (!key in converted) return;

        converted[key + 'Display'] = DateUtil.toMMDD(converted[key]);
      });

      // 今日から〜日前
      [
        'plannedAt',
        'estimatedDate'
      ].forEach((key) => {
        if (!key in converted || !converted[key]) return;

        let displayStr = '';
        const days = DateUtil.diffDays(DateUtil.today().valueOf(), DateUtil.startOfDay(Number(converted[key])));

        if (days === 0) {
          displayStr = '今日';
        } else if (days < 0) {
          displayStr = `${Math.abs(days)}日前`;
        } else if (days > 0) {
          displayStr = `${days}日後`;
        }

        converted['daysAfter' + capitalize(key) + 'Display'] = displayStr;
      });

      //経過日数
      [
        'latestCalvingDate',
        'latestPregnancyDate',
        'latestEstrusDate',
        'firstFertilizationDate',
        'latestFertilizationDate',
      ].forEach((key) => {
        if (key in converted && converted[key]) {
          converted['daysAfter' + capitalize(key)] =
            DateUtil.diffDays(
              DateUtil.startOfDay(Number(converted[key])),
              DateUtil.today().valueOf()
            );
        }
      });

      // 個体ラベル
      if (converted.cowLabels) {
        converted.cowLabelsDisplay = converted.cowLabels.replace(/(\[|\])/g, '').split(',');
      }

      // 予定日
      if (converted.plannedAtDisplay) {
        converted.plannedAtMobileDisplay = `${converted.plannedAtDisplay}(${converted.daysAfterPlannedAtDisplay})`;
      }

      // penForOrder
      if (converted.pen === null) {
        converted.penForOrder = '';
      } else {
        converted.penForOrder = String(String(event.pen).padStart(10, '0'));
      }

      // latestBullInfo
      let latestBullInfo;

      if (converted.latestBullName) {
        latestBullInfo = `0${converted.latestBullName}${converted.latestMasterSpermNo || ''}`;
      } else if (converted.latestMasterSpermNo) {
        latestBullInfo = `1${converted.latestMasterSpermNo}`;
      } else {
        latestBullInfo = '2';
      }

      converted.latestBullInfo = latestBullInfo;

      if (converted.content) {
        if (converted.content.plannedBredMethod) {
          converted.plannedBredMethod = converted.content.plannedBredMethod;
        }

        if (converted.content.dryPeriod) {
          converted.dryPeriod = converted.content.dryPeriod;
        }
      }

      return converted;
    });
  }

  static copyEvent(event) {
    return angular.copy(event, {});
  }

  static eventTypeLabel(eventType) {
    const eventTypeLabelMap = {
      bunben: '分娩',
      overBunben: '分娩予定超過',
      hatsujo: '発情',
      hormoneProgram: 'ホルモンプログラム',
      ninshinkantei: '妊娠鑑定',
      tanetsuke: '種付',
      kannyu: '乾乳'
    };

    return eventTypeLabelMap[eventType];
  }

  static get plannedBredMethods() {
    return [
      {
        label: '予定なし',
        value: ''
      },
      {
        label: '人工授精',
        value: '人工授精'
      },
      {
        label: '移植',
        value: '移植'
      },
      {
        label: '本交',
        value: '本交'
      }
    ];
  }

  static get eventTypes() {
    return [
      'bunben',
      'overBunben',
      'hatsujo',
      'tanetsuke',
      'hormoneProgram',
      'ninshinkantei',
      'kannyu'
    ];
  }

  /**
   * 繁殖予定の数をタイプごとに分類して集計
   * @param {Object} events ReproductionPlannedEventsAPI.summary()の戻り値
   */
  static countEvents(events) {
    const total = Object.keys(events)
      .reduce((acc, key) => {
        return acc + events[key];
      }, 0);

    const groupedEvents = ReproductionPlannedEvent.eventTypes
      .filter((key) => key in events)
      .reduce((acc, key) => {
        return acc.concat([{
          eventType: key,
          label: ReproductionPlannedEvent.eventTypeLabel(key),
          count: events[key]
        }]);
      }, []);

    return {
      total: total || null,
      events: groupedEvents
    };
  }

  /*
   * 繁殖予定をタイプごとにグループ化
   * @param {Object} events ReproductionPlannedEventsAPI.summary()の戻り値
   */
  static groupEvents(tasks) {
    return ReproductionPlannedEvent.eventTypes
      .filter((key) => key in tasks && tasks[key].length > 0)
      .map((key) => ({
        eventType: key,
        name: ReproductionPlannedEvent.eventTypeLabel(key),
        events: ReproductionPlannedEvent.addDisplayFields(tasks[key])
      }));
  }

  static eventFields(isBeef, isMobile) {
    const common = [
      isMobile ? 'plannedAtMobileDisplay' : 'plannedAt',
      'cowGroupName',
      'pen',
      'cowNo',
      'state',
      isMobile ? 'cowLabelsDisplay' : 'cowLabels',
      'birthNumber',
      [
        isMobile ? 'latestCalvingDateDisplay' : 'latestCalvingDate',
        'daysAfterLatestCalvingDate'
      ],
    ];

    const beefCommon = isBeef ? [
      'breed',
      'fatherName',
      'maternalGrandfatherName',
      'maternalGreatGrandfatherName',
      'maternalFather4thName',
    ] : [];

    return {
      bunben: [
        ...common,
        [
          isMobile ? 'latestPregnancyDateDisplay' : 'latestPregnancyDate',
          'daysAfterLatestPregnancyDate',
        ],
        isMobile ? [
          'latestBullName',
          'latestMasterSpermNo'
        ] : 'latestBullInfo',
        ...beefCommon
      ],
      overBunben: [
        ...common,
        [
          isMobile ? 'latestPregnancyDateDisplay' : 'latestPregnancyDate',
          'daysAfterLatestPregnancyDate',
        ],
        isMobile ? [
          'latestBullName',
          'latestMasterSpermNo'
        ] : 'latestBullInfo',
        ...beefCommon
      ],
      hatsujo: [
        ...common,
        [
          isMobile ? 'latestEstrusDateDisplay' : 'latestEstrusDate',
          'daysAfterLatestEstrusDate',
        ],
        [
          isMobile ? 'firstFertilizationDateDisplay' : 'firstFertilizationDate',
          'daysAfterFirstFertilizationDate',
        ],
        isMobile ? 'latestBreedingCount' : ['latestBreedingCount', 'latestBull'],
        isMobile ? [
          'latestBullName',
          'latestMasterSpermNo',
        ] : null,
        ...beefCommon
      ],
      tanetsuke: [
        ...common,
        [
          isMobile ? 'latestEstrusDateDisplay' : 'latestEstrusDate',
          'daysAfterLatestEstrusDate',
        ],
        isMobile ? 'latestBreedingCount' : ['latestBreedingCount', 'latestBull'],
        isMobile ? [
          'latestBullName',
          'latestMasterSpermNo',
        ] : null,
        'plannedBredMethod',
        'hormoneProgramName',
        ...beefCommon
      ],
      hormoneProgram: [
        ...common,
        'hormoneProgramName',
        'nextHormoneName',
        ...beefCommon
      ],
      ninshinkantei: [
        ...common,
        [
          isMobile ? 'latestFertilizationDateDisplay' : 'latestFertilizationDate',
          'daysAfterLatestFertilizationDate',
        ],
        isMobile ? 'latestBreedingCount' : ['latestBreedingCount', 'latestBull'],
        isMobile ? [
          'latestBullName',
          'latestMasterSpermNo',
        ] : null,
        'latestBreedingMethod',
        'pregnancy',
        ...beefCommon
      ],
      kannyu: [
        ...common,
        [
          isMobile ? 'latestPregnancyDateDisplay' : 'latestPregnancyDate',
          'daysAfterLatestPregnancyDate',
        ],
        isMobile ? 'expectedCalvingDateDisplay' : 'expectedCalvingDate',
        'dryPeriod',
        ...beefCommon
      ],
    };
  }

  static eventDisplayLabels(isMobile) {
    return {
      plannedAtMobileDisplay: {
        label: '予定日'
      },
      plannedAt: {
        label: '予定日',
        classesCol: ['col-w80'],
      },
      latestCalvingDateDisplay: {
        label: '最新分娩日',
      },
      latestCalvingDate: {
        label: '最新分娩日',
        classesCol: ['col-w110'],
      },
      daysAfterLatestCalvingDate: {
        label: '分娩後日数',
        unit: '日',
        isSubLabel: true
      },
      cowGroupName: {
        label: '牛群',
        classesCol: ['col-w80'],
      },
      pen: {
        label: '牛房',
        classesCol: ['col-w60'],
      },
      cowNo: {
        label: '牛番号',
        classesCol: ['col-w90'],
      },
      state: {
        label: '状態',
        classesCol: ['col-w90'],
      },
      cowLabelsDisplay: {
        label: '個体ラベル',
        type: 'labels'
      },
      cowLabels: {
        label: '個体ラベル',
        type: 'labels',
        classesCol: ['col-w190'],
      },
      birthNumber: {
        label: '産次',
        fieldType: 'INTEGER',
        classesCol: ['col-w70'],
      },
      latestPregnancyDateDisplay: {
        label: '妊娠日',
      },
      latestPregnancyDate: {
        label: '妊娠日',
        classesCol: ['col-w110'],
      },
      daysAfterLatestPregnancyDate: {
        label: '妊娠後日数',
        unit: '日',
        isSubLabel: true
      },
      latestBullName: {
        label: '種雄牛名'
      },
      latestMasterSpermNo: {
        label: '精液番号'
      },
      latestBullInfo: {
        label: '精液情報',
        classesCol: ['col-w120']
      },
      latestEstrusDateDisplay: {
        label: '最新発情日'
      },
      latestEstrusDate: {
        label: '最新発情日',
        classesCol: ['col-w100']
      },
      daysAfterLatestEstrusDate: {
        label: isMobile ? '発情経過日数' : '経過日数',
        unit: '日',
        isSubLabel: true
      },
      firstFertilizationDateDisplay: {
        label: '初回授精日'
      },
      firstFertilizationDate: {
        label: '初回授精日',
        classesCol: ['col-w110']
      },
      daysAfterFirstFertilizationDate: {
        label: '初回授精日数',
        unit: '日',
        isSubLabel: true
      },
      latestBreedingCount: {
        label: '授精回数',
        fieldType: 'INTEGER',
        classesCol: ['col-w120']
      },
      latestBull: {
        label: '最新精液情報'
      },
      plannedBredMethod: {
        label: '種付方法',
        isContent: true,
        classesCol: ['col-w90']
      },
      hormoneProgramName: {
        label: 'ホルモンプログラム',
        classesCol: ['col-w150']
      },
      nextHormoneName: {
        label: '処置内容',
        classesCol: ['col-w150']
      },
      latestFertilizationDateDisplay: {
        label: '最新授精日'
      },
      latestFertilizationDate: {
        label: '最新授精日',
        classesCol: ['col-w80']
      },
      daysAfterLatestFertilizationDate: {
        label: '授精後日数',
        isSubLabel: true,
      },
      latestBreedingMethod: {
        label: '種付方法',
        classesCol: ['col-w60']
      },
      pregnancy: {
        label: '前回鑑定結果',
        classesCol: ['col-w80']
      },
      expectedCalvingDateDisplay: {
        label: '分娩予定日'
      },
      expectedCalvingDate: {
        label: '分娩予定日',
        classesCol: ['col-w100']
      },
      dryPeriod: {
        label: '前後期',
        isContent: true,
        classesCol: ['col-w70']
      },
      breed: {
        label: '品種',
        classesCol: ['col-w70']
      },
      fatherName: {
        label: '父',
        classesCol: ['col-w70']
      },
      maternalGrandfatherName: {
        label: '母父',
        classesCol: ['col-w70']
      },
      maternalGreatGrandfatherName: {
        label: '母母父',
        classesCol: ['col-w70']
      },
      maternalFather4thName: {
        label: '母母母父',
        classesCol: ['col-w90']
      },
    };
  }
}
