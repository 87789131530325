class DysstasiaReportController {
  constructor(
    $modalInstance,
    AlertService,
    UtilService,
    blockUI,
    $timeout,
    params
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.AlertService = AlertService;
    this.UtilService = UtilService;
    this.blockUI = blockUI;
    this.$timeout = $timeout;

    this.initialize(params);
  }

  initialize(params) {
    this.statusOption = [
      {value: 'dysstasia', label: '起立が困難な状態だった'},
      {value: 'louse', label: '皮膚病（シラミなど）'},
      {value: 'dead', label: '死亡していた'},
      {value: 'normal', label: '起立して普段と同じ様子だった'},
      {value: 'other', label: 'その他'},
    ];

    this.id = params.id;
    this.confirmed = params.confirmed;
    this.confirmedStatus = params.confirmedStatus;
    this.comment = params.comment;

    this.validate();
  }

  validate() {
    this.invalid = true;

    if (this.confirmedStatus) {
      if (this.confirmedStatus === 'other') {
        const comment = this.comment ? this.comment.trim() : null;
        this.invalid = !comment;
      } else {
        this.invalid = false;
      }
    }
  }

  ok() {
    const params = {
      id: this.id,
      confirmedStatus: this.confirmedStatus,
      comment: this.comment
    };

    this.blockUI.start('更新中');

    this.AlertService.updateAlertDysstasia(params).then(() => {
      this.blockUI.done(() => {
        this.blockUI.start('更新が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);
      });
      this.blockUI.stop();

      const result = {
        confirmedStatus: this.confirmedStatus,
        comment: this.comment
      };
      this.$modalInstance.close(result);
    }).catch((error) => {
      this.errorMessage = this.UtilService.formatErrorMessage(error.data.messages);
      this.blockUI.stop();
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('DysstasiaReportController', DysstasiaReportController);
