app.service('CarcassService', CarcassServices);

function CarcassServices(
  APIService,
  UtilService
) {
  'ngInject';

  const numericFields = [
    'slaughterNo',
    'beforeSlaughterWeight',
    'dressedCarcassWeightOfL',
    'dressedCarcassWeightOfR',
    'loinArea',
    'ribsThickness',
    'subcutaneousFat',
    'yieldBaseValue'
  ];

  /**
   * 枝肉成績のドロップダウン内容を作成
   */
  function makeCarcassSelection() {
    const abc = ['A', 'B', 'C'];
    const oneToFive = [1, 2, 3, 4, 5];
    const oneToSeven = [1, 2, 3, 4, 5, 6, 7];
    const oneToTwelve = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const defects = [
      {key: 1, label: 'ア: シミ'},
      {key: 2, label: 'イ: ズル'},
      {key: 3, label: 'ウ: シコリ'},
      {key: 4, label: 'エ: アタリ'},
      {key: 5, label: 'オ: カツジョ'},
      {key: 6, label: 'カ: その他'}
    ];

    const organs = [
      '肝臓',
      '心臓',
      '肺',
      '頭',
      '舌',
      '横隔膜（ハラミ）',
      '横隔膜（サガリ）',
      '胃1',
      '胃2',
      '胃3',
      '胃4',
      '小腸',
      '大腸',
      '直腸',
      '膵臓',
      '膀胱',
      '脾臓',
      '腎臟',
      '子宮',
      '尾',
      '全廃棄'
    ];

    return {
      yieldGrade: abc,
      meetGrade: oneToFive,
      bmsNo: oneToTwelve,
      marblingGrade: oneToFive,
      bcsNo: oneToSeven,
      gloss: oneToFive,
      bcsAndGlossGrade: oneToFive,
      tight: oneToFive,
      texture: oneToFive,
      tightAndTextureGrade: oneToFive,
      bfsNo: oneToSeven,
      fatLuster: oneToFive,
      bfsAndFatLusterGrade: oneToFive,
      defects: defects,
      visceralDestruction: organs
    };
  }

  /**
   * 枝肉成績の等級を算出する
   * @param {Object} listItem
   * @return {Object} listItem
   */
  function calculateGrade(listItem) {
    if ('yieldGrade' in listItem && 'meetGrade' in listItem) {
      listItem.grade = listItem.yieldGrade + listItem.meetGrade;
    }
    return listItem;
  }

  /**
   * 数字フィルド小数点を制限し、四捨五入する
   * @param {Object} listItem
   * @return {Object} listItem
   */
  function roundToSingleDecimal(listItem) {
    numericFields.forEach((field) => {
      if (listItem.hasOwnProperty(field) && UtilService.isNumber(listItem[field])) {
        listItem[field] = Math.round(listItem[field] * 10) / 10;
      }
    });
    return listItem;
  }

  /**
   * 枝肉成績の数字入力のバリデーション
   * @param {Object} listItem
   * @param {array} errorList
   * @return {array} errorList
   */
  function validateCarcassNumericInputs(listItem, errorList) {
    let fieldsWithErrors = {};
    numericFields.forEach((field) => {
      // 項目持ち & 数字じゃない場合エラーと判定する
      if (listItem.hasOwnProperty(field) && !StringUtil.isNumeric(listItem[field])) {
        fieldsWithErrors[field] = listItem[field];
      }
    });

    // エラーあるものだけリストに追加する
    if (Object.keys(fieldsWithErrors).length) {
      errorList.push(fieldsWithErrors);
    }

    return errorList;
  }

  // 枝肉成績詳細取得
  function getCarcassCharacteristicDetails(query) {
    return APIService.get(`/carcass_characteristic/search?${query}`);
  }

  return {
    makeCarcassSelection: makeCarcassSelection,
    calculateGrade: calculateGrade,
    validateCarcassNumericInputs: validateCarcassNumericInputs,
    roundToSingleDecimal: roundToSingleDecimal,
    getCarcassCharacteristicDetails: getCarcassCharacteristicDetails
  };
}
