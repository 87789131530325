app.component('bredAnalysisChart', {
  template: '<div id="bred-analysis-chart"></div>',
  controller: 'BredAnalysisChartController',
  bindings: {
    'performances': '<'
  }
});

class BredAnalysisChartController {
  constructor(
  ) {
    'ngInject';

    this.element = '#bred-analysis-chart';
    this.XMAX = 90;
  }

  $onInit() {
    if (!this.performances || this.performances.length <= 0) return;
    this.drawChart(this.element, this.performances);
  }

  $onChanges(changes) {
    if (changes.performances) {
      this.drawChart(this.element, this.performances);
    }
  }

  drawChart(element, performances) {
    const bredAnalysiss = performances.map((p) => p.groupName);
    bredAnalysiss.unshift('x');

    const conceptionRates = performances.map((p) => p.conceptionRate);
    conceptionRates.unshift('受胎率');

    const crConfidenceIntervals = performances.map((p) => p.crConfidenceInterval);

    this.generatePerformanceGraph(element, bredAnalysiss, conceptionRates);
    this.appendCIBar(element, crConfidenceIntervals);
  }

  generatePerformanceGraph(element, bredAnalysiss, conceptionRates) {
    const paddingTop = 20;
    const paddingBottom = 20;
    const barWidth = 20;
    const baseHeight = 70;
    const graphHeight = (barWidth + 20) * (bredAnalysiss.length - 1);
    const totalHeight = graphHeight + baseHeight + paddingTop + paddingBottom;

    c3.generate({
      bindto: element,
      size: {
        width: 670,
        height: totalHeight
      },
      padding: {
        top: paddingTop,
        right: 100,
        bottom: paddingBottom,
        left: 120,
      },
      data: {
        x: 'x',
        columns: [
          bredAnalysiss,
          conceptionRates
        ],
        type: 'bar'
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            multiline: true,
            width: 50
          },
        },
        y: {
          max: this.XMAX,
          tick: {
            multiline: true
          }
        },
        rotated: true
      },
      color: {
        pattern: ['#FAA84B']
      },
      bar: {
        width: barWidth
      },
      legend: {
        show: true,
        item: {
          onclick: function() {}
        }
      },
    });
  }

  appendCIBar(element, crConfidenceIntervals) {
    const errorBars = d3.select(`${element} svg .c3-chart`).append('g');

    errorBars.selectAll('path')
      .data(crConfidenceIntervals)
      .enter().append('path')
      .attr('id', (d, i) => 'error-bar-line-' + i)
      .attr('class', 'error-bar');

    const barMaxWidth = Number(document.getElementsByClassName('c3-zoom-rect')[0].getAttribute('width'));

    const that = this;
    d3.selectAll(`${element} .c3-bar`).each(function(d, i) {
      const segList = this.pathSegList;//eslint-disable-line no-invalid-this
      const yPos = (segList.getItem(2).y + segList.getItem(0).y) / 2;
      const xPos = segList.getItem(2).x;

      errorBars.select('#error-bar-line-' + i)
        .attr('d', (d) => {
          return 'M' + xPos + ',' + yPos + ' ' +
            'L' + (xPos + d * (barMaxWidth / that.XMAX)) + ',' + yPos + ' ' +
            'z';
        });
    });

  }
}

app.controller('BredAnalysisChartController', BredAnalysisChartController);
