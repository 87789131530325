class GebvIndexSettingAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  show(evaluationType) {
    return this.APIService.get(`/genom/gebv_index_setting/?evaluation_type=${evaluationType}`);
  }

  update(id, params) {
    return this.APIService.put(`/genom/gebv_index_setting/${id}`, params);
  }
}

app.service('GebvIndexSettingAPI', GebvIndexSettingAPI);
