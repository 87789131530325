class CowshedCoolPesconChartBoxController {
  constructor() {
    'ngInject';
  }
}

function cowshedCoolPesconChartBoxComponent() {
  'ngInject';
  return {
    transclude: true,
    controller: CowshedCoolPesconChartBoxController,
    templateUrl: 'menu/environment/cowshed/cool-pescon/chart-box.html',
    bindings: {
      title: '@'
    }
  };
}
app.component('cowshedCoolPesconChartBox', cowshedCoolPesconChartBoxComponent());
