class CowEventHistoryConditionController {
  constructor(
    $modalInstance,
    params
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;

    this.init(params);
  }

  init(params) {
    this.farms = params.farms;
    this.workers = params.workers;

    const eventTypes = params.eventTypes;
    this.eventTypeOptions = {
      treatment: eventTypes.filter((e) => EventType.isTreatment(e.eventType)),
      growingCalf: eventTypes.filter((e) => EventType.isGrowingCalf(e.eventType)),
      reproduction: eventTypes.filter((e) => EventType.isReproduction(e.eventType)),
      other: eventTypes.filter((e) => EventType.isOther(e.eventType)),
    };
    this.eventTypes = eventTypes;

    this.editFormConfig = {
      'farm': {
        title: '牧場名選択'
      },
      'event_type': {
        title: 'イベント選択'
      },
      'worker': {
        title: '担当者選択'
      }
    };

    this.changeView('menu');
    this.setConditionSummary();
  }

  onClickFarm() {
    this.changeView('farm');
    this.allFarmSelected = false;
  }

  onClickEventType() {
    this.changeView('event_type');
    this.eventTypeAllAction = 'clear';
    this.eventTypeAllActionLabel = '選択をクリア';

    const isAllSelected = (ar) => {
      return ar.every((e) => e.selected);
    };

    this.allTreatmentSelected = isAllSelected(this.eventTypeOptions.treatment);
    this.allGrowingCalfSelected = isAllSelected(this.eventTypeOptions.growingCalf);
    this.allReproductionSelected = isAllSelected(this.eventTypeOptions.reproduction);
    this.allOtherSelected = isAllSelected(this.eventTypeOptions.other);
  }

  onClickWorker() {
    this.changeView('worker');
    this.allWorkerSelected = false;
  }

  changeView(viewState) {
    this.viewState = viewState;
    this.editForm = this.editFormConfig[this.viewState];

    this.showMenu = this.viewState === 'menu';
    this.showFarm = this.viewState === 'farm';
    this.showEventType = this.viewState === 'event_type';
    this.showWorker = this.viewState === 'worker';

    this.invalid = false;
  }

  decide() {
    this.changeView('menu');
    this.setConditionSummary();
  }

  closeEdiForm() {
    this.changeView('menu');
  }

  setConditionSummary() {
    const generateSummary = (options) => {
      return options.filter((o) => o.selected)
        .map((o) => o.label)
        .join(', ');
    };

    this.farmSummary = generateSummary(this.farms) || '全ての牧場が対象になります';
    this.workerSummary = generateSummary(this.workers) || '全ての担当者が対象になります';

    const allSelected = this.eventTypes.every((eventTypes) => eventTypes.selected);
    if (allSelected) {
      this.eventTypeSummary = '全てのイベントが対象になります';
    } else {
      this.eventTypeSummary = generateSummary(this.eventTypes);
    }
  }

  toggleAllFarm() {
    this.farms.forEach((e) => e.selected = this.allFarmSelected);
  }

  toggleAllWorker() {
    this.workers.forEach((e) => e.selected = this.allWorkerSelected);
  }

  toggleAllTreatment() {
    this.eventTypeOptions.treatment.forEach((e) => e.selected = this.allTreatmentSelected);
    this.validateEventType();
  }

  toggleAllGrowingCalf() {
    this.eventTypeOptions.growingCalf.forEach((e) => e.selected = this.allGrowingCalfSelected);
    this.validateEventType();
  }

  toggleAllReproduction() {
    this.eventTypeOptions.reproduction.forEach((e) => e.selected = this.allReproductionSelected);
    this.validateEventType();
  }

  toggleAllOther() {
    this.eventTypeOptions.other.forEach((e) => e.selected = this.allOtherSelected);
    this.validateEventType();
  }

  eventTypeToggleAction() {
    const action = (state) => {
      [
        this.eventTypeOptions.treatment,
        this.eventTypeOptions.growingCalf,
        this.eventTypeOptions.reproduction,
        this.eventTypeOptions.other,
      ].forEach((ar) => {
        return ar.forEach((e) => e.selected = state);
      });

      this.allTreatmentSelected = state;
      this.allGrowingCalfSelected = state;
      this.allReproductionSelected = state;
      this.allOtherSelected = state;
    };

    if (this.eventTypeAllAction === 'clear') {
      action(false);
      this.eventTypeAllAction = 'select';
      this.eventTypeAllActionLabel = '全てを選択';
    } else {
      action(true);
      this.eventTypeAllAction = 'clear';
      this.eventTypeAllActionLabel = '選択をクリア';
    }
  }

  validateEventType() {
    const selected = [
      this.eventTypeOptions.treatment,
      this.eventTypeOptions.growingCalf,
      this.eventTypeOptions.reproduction,
      this.eventTypeOptions.other,
    ].some((ar) => {
      return ar.some((e) => e.selected);
    });

    this.invalid = !selected;
  }

  ok() {
    const result = {
      farms: this.farms,
      eventTypes: this.eventTypes,
      workers: this.workers,
    };
    this.$modalInstance.close(result);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('CowEventHistoryConditionController', CowEventHistoryConditionController);
