class HomeScheduleController {
  constructor(
    SessionCache
  ) {
    'ngInject';

    const farm = SessionCache.farm();

    this.homeSettings = new HomeSettings(SessionCache);
    this.init(farm);
  }

  $doCheck() {
    const loading = Object.values(this.loading.schedule).some((boolean) => boolean);

    if (loading || (this.previousDate === this.date)) return;

    this.previousDate = this.date;
  }

  init(farm) {
    this.useAlertCalving = farm.data.useAlertCalving;
    this.date = this.homeSettings.get('schedule').date;
    this.total = {
      reproductionPlannedEventsTotal: 0,
      calvingSensor: 0
    };
  }

  changeDate(days = 0) {
    if (days) {
      this.date = DateUtil.addDays(this.date, days);
    }

    this.homeSettings.update('schedule', {date: this.date});
  }
}

function homeSchedule() {
  return {
    templateUrl: 'menu/home/schedule/index.html',
    controller: HomeScheduleController,
    controllerAs: 'ctrl',
    bindings: {
      enableMasonry: '&',
      loading: '='
    }
  };
}

app.component('homeSchedule', homeSchedule());
