// eslint-disable-next-line no-unused-vars
class AlertDecorator {
  constructor(obj, DateUtilService) {
    this.obj = obj;
    this.DateUtilService = DateUtilService;
    this.selected = false;

    // TODO: Proxyに変えたい
    Object.keys(obj).forEach((key) => {
      if (key in this) return;
      this[key] = obj[key];
    });
  }

  get alertLabels() {
    const labels = [[], []];
    const alerts = {
      illness: '急性',
      chronicIllness: '慢性',
      milkLow: '乳量',
      feedLow: '採食',
      lieHigh: '横臥',
      waterLow: '飲水'
    };
    Object.keys(alerts).forEach((key) => {
      if (this.obj[key]) {
        if (key === 'illness' || key === 'chronicIllness') {
          labels[0].push(alerts[key]);
        } else {
          labels[1].push(alerts[key]);
        }
      }
    });
    return labels;
  }

  get tagDroppedOut() {
    return this.obj.tagDroppedOut ? 'タグ脱落チェック' : '';
  }

  get cowUid() {
    return formatCowUid(this.obj.cowUid);
  }

  get latestCalvingDate() {
    return this.DateUtilService.toYYYYMMDD(this.obj.latestCalvingDate);
  }

  get daysAfterCalving() {
    return this.diffDays(this.obj.latestCalvingDate);
  }

  get expectedCalvingDate() {
    return this.DateUtilService.toYYYYMMDD(this.obj.expectedCalvingDate);
  }

  get latestFertilizationDate() {
    return this.DateUtilService.toYYYYMMDD(this.obj.latestFertilizationDate);
  }

  get daysAfterFertilization() {
    return this.diffDays(this.obj.latestFertilizationDate);
  }

  get latestPregnancyDate() {
    return this.DateUtilService.toYYYYMMDD(this.obj.latestPregnancyDate);
  }

  get daysAfterPregnancy() {
    return this.diffDays(this.obj.latestPregnancyDate);
  }

  get expectedDryDate() {
    return this.DateUtilService.toYYYYMMDD(this.obj.expectedDryDate);
  }

  get monthAge() {
    if (!this.obj.birthday) return '';
    const months = DateUtil.monthAge(Number(this.obj.birthday), DateUtil.today(), 1);
    return months > 0 ? String(months) : '';
  }

  get introducedDays() {
    return this.days(Number(this.obj.introduceDate), Date.now());
  }

  // private

  /**
   * 引数の日付から今日までの日数を返す
   *
   * @param {number} date unixtime
   * @return {nuber} 日数
   */
  days(date) {
    if (!date) return '';
    return this.DateUtilService.countDays(Number(date), new Date().getTime());
  }

  /**
   * 引数の日付から今日までの差分日数を返す
   *
   * @param {number} date unixtime
   * @return {nuber} 日数
   */
  diffDays(date) {
    if (!date) return '';
    return DateUtil.diffDays(Number(date), new Date().getTime());
  }
}
