class CowEventAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  show(cowId) {
    return this.APIService.get(`/cow_event/cow/${cowId}`);
  }

  create(data) {
    return this.APIService.post('/cow_event', data);
  }

  bulkAdd(data, options = {}) {
    const params = Object.assign({}, options);
    params['_json'] = data;
    return this.APIService.post(`/cow_event/bulk`, params);
  }

  update(data) {
    return this.APIService.put(`/cow_event/${data.id}`, data);
  }

  delete(id) {
    return this.APIService.delete(`/cow_event/${id}`);
  }

  /**
   * イベント投稿のバリデーション
   * @param {obect} data
   */
  confirm(data) {
    return this.APIService.post(`/cow_event/confirm`, data);
  }

  // 牛個体のイベント別の取得
  getByEventType(cowId, eventType) {
    return this.APIService.get(`/cow_event/cow/${cowId}/${eventType}`);
  }

  /**
   * 一定期間のXイベントを全件取得
   * @param {Object} params
   * {
   *  eventType: string,
   *  dateField: string,
   *    (occurred_at || treatment_disease_date || occurred_disease_date || updated_at) [発生日 || 疾病治療日 || 疾病発生日 || 入力日]
   *  startDate: 'YYYY-MM-DD',
   *  endDate: 'YYYY-MM-DD',
   *  entryStaff: string
   * }
   */
  search(params) {
    const conditions = [];
    conditions.push(`event_type=${params.eventType}`);
    conditions.push(`date_field=${params.dateField}`);
    conditions.push(`start_date=${DateUtil.toW3CFormat(params.startDate)}`);
    conditions.push(`end_date=${DateUtil.toW3CFormat(params.endDate)}`);
    if (params.entryStaff) {
      conditions.push(`entry_staff=${encodeURI(params.entryStaff)}`);
    }
    const condition = conditions.join('&');

    return this.APIService.get(`/cow_event/search?${condition}`);
  }
}

app.service('CowEventAPI', CowEventAPI);
