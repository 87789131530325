/**
 * @deprecated TimelineAPIなどを利用してください
 * ※この実装の修正および、新規の利用は禁止です
 */
/**
 * CowTimeline, TopTimeline, HistoryTimelineの共通パーツ
 */
class TimelineService {
  constructor(APIService, DateUtilService) {
    'ngInject';
    this.APIService = APIService;
    this.DateUtilService = DateUtilService;
  }

  index(date) {
    const w3cdate = this.DateUtilService.toW3CFormat(date);
    return this.APIService.get('/timeline/' + w3cdate);
  }

  /**
   * 乳房炎イベント、盲乳部位のラベルを生成する
   * @param {Object} event 乳房炎のイベントobj
   * @return {string} '左前、右後'
   */
  monyuLabelConvertor(event) {
    // eventの中ら'stopMilkingOfBreast〇〇'などのT/Fを左右前後にマップする
    const keyName = 'stopMilkingOfBreast';
    const positions = {
      'Fl': '左前',
      'Fr': '右前',
      'Bl': '左後',
      'Br': '右後'
    };

    return Object.keys(positions)
      .filter((key, index) => event[keyName + key])
      .map((key) => positions[key])
      .join('、');
  }

  /**
   * イベントオブジェクトをTimelineElementDecoratorでdecorateする
   *
   * @param {Array.<Object>} events
   * @param {Array.<Object>} selections
   * @return {Array.<TimelineElementDecorator>}
   */
  decorateEvents(events, selections) {
    return events.map((e) => new TimelineElementDecorator(e, selections));
  }

  /**
  * 年表記のため、各年のイベントオブジェクトに、eventYear: yyyy というプロパティを追加する。
  * 各年の最後のイベントオブジェクトには、lastEventOfYear: true というプロパティを追加する。
  *
  * @param {Array} events
  * @param {Array} filterResults
  * @return {Array}
  */
  markLastEventOfYear(events, filterResults) {
    let yearOfNextEvent;
    return events.map((e, index) => {
      if (!filterResults.includes(e.eventName)) {
        e.lastEventOfYear = false;
        return e;
      }
      const year = new Date(Number(e.occurredAt)).getFullYear();
      e.eventYear = year;
      if (!yearOfNextEvent) {
        yearOfNextEvent = year;
        e.lastEventOfYear = false;
        return e;
      }
      if (yearOfNextEvent === year) {
        e.lastEventOfYear = false;
        return e;
      }
      yearOfNextEvent = year;
      e.lastEventOfYear = true;
      return e;
    });
  }
}

app.service('TimelineService', TimelineService);
