class EventSelectionSimpleConfirm {
  constructor(modalDialogFactory) {
    'ngInject';
    this.modalDialogFactory = modalDialogFactory;
  }

  open(title, text1, text2) {
    return this.modalDialogFactory.showYesNoConfirm({
      title: title,
      text1: text1,
      text2: text2,
      no: true, yes: true
    });
  }
}

app.service('EventSelectionSimpleConfirm', EventSelectionSimpleConfirm);
