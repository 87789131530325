function MobileStickyAlertComponent() {
  return {
    restrict: 'E',
    templateUrl: 'mobile/components/sticky-alert/mobile-sticky-alert.html',
    controller: MobileStickyAlertController,
    controllerAs: 'ctrl'
  };
}

app.directive('mobileStickyAlert', MobileStickyAlertComponent);
