// eslint-disable-next-line no-unused-vars
class CoolPescon {
  constructor(data) {
    this.data = angular.extend({}, data);
    this.devices = this.data['devices'].map((device) => new CoolPesconDevice(device));
  }

  activeCount() {
    return this.devices.reduce((count, device) => {
      if (device.isActive()) count++;
      return count;
    }, 0);
  }

  inactiveCount() {
    return this.devices.reduce((count, device) => {
      if (!device.isActive()) count++;
      return count;
    }, 0);
  }
}
