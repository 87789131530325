app.directive('detailData', DetailData);

function DetailData() {
  'ngInject';
  return {
    templateUrl: 'cow/detail/tabs/data.html',
    link: (scope, element, attribute, ctl) => {
      // 共通関数を紐づけ
      scope.formatCowUid = formatCowUid;
    }
  };
}
