class MobileBulkEntryEventsController {
  constructor(
    CowEventBulkInput,
    SelectBoxFactory,
    $stateParams,
    $modalInstance,
    $timeout,
    $scope,
    AccountService,
    OperationUserService,
    MobileRemoveCowsModal,
    MobileBulkentryErrorModal,
    blockUI,
    modalDialogFactory,
    appConfig
  ) {
    'ngInject';
    this.MobileRemoveCowsModal = MobileRemoveCowsModal;
    this.MobileBulkentryErrorModal = MobileBulkentryErrorModal;
    this.OperationUserService = OperationUserService;
    this.AccountService = AccountService;
    this.$modalInstance = $modalInstance;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.blockUI = blockUI;
    this.modalDialogFactory = modalDialogFactory;
    this.appConfig = appConfig;

    this.COW_NUMBERS_DISPLAY_LENGTH = 12;

    this.SelectBoxFactory = SelectBoxFactory;
    this.state = CowEventBulkInput;

    this.selection = {
      occurredDateField: [
        {label: '新規', value: 'current'},
        {label: '継続', value: 'previous'}
      ]
    };

    // FIXME: BulkEntryEventsStateをfactoryなどで実装し直す
    this.state.init($stateParams).then(() => {
      // モバイル版では先頭のイベントデータのみをエディットして、送信時に内容をコピーする
      this.state.formData = this.state.cowEvents[0];
      this.state.formData.selectedMedicines = [];

      this.state.targetDate = DateUtil.today().getTime();
      if (CowEvent.isTreatmentEvent(this.state.eventType)) {
        this.state.formData.occurredDate = DateUtil.today().getTime();
        this.state.formData.occurredDateField = this.state.farmKind === '乳用' ? 'previous' : 'current';
      }

      if (this.state.eventType === 'touta') {
        this.state.formData.expelledReason =
          this.state.farm.isDairy() || this.state.farm.isFattening() ?
            '肉用出荷' : '素牛出荷';
      }

      this.initErrorMessages();

      this.composeOperatorOptions().then(() => {
        this.isInitialized = true;
        this.$timeout(() => this.calculateMainAreaHeight());
      });
    });
  }

  initErrorMessages() {
    const dateErrorMessages = {
      invalid: '無効な日付です。',
      required: '日付を入力してください。',
      invalidOrder: '治療日が発生日より前の日付になっています。'
    };

    this.state.errorMessages = {
      targetDate: dateErrorMessages,
      occurredDate: dateErrorMessages,
      moveToCowGroupId: {
        required: '必須項目です。'
      },
      bodyTemperature: {
        invalid: '体温に入力できるのは少数点を含めた数値のみです。'
      }
    };
  }

  // mobile
  cows() {
    return this.state.cowEvents
      .filter((cowEvent) => cowEvent.cowId)
      .map((cowEvent) => this.state.cowIdMap[cowEvent.cowId])
      .filter((cow) => cow)
      .map((cow) => {
        cow.cowGroupName = this.state.cowGroupMap[cow.cowGroupId];
        cow.cowUidDisplay = Cow.formatCowUid(cow.cowUid);
        cow.latestCalvingDateDisplay = DateUtil.toYYYYMMDD(Number(cow.latestCalvingDate)) || null;

        // FIXME: 機能していない実装、必要なのか仕様から見直すべき
        // const dateField = this.state.dateMap[this.state.eventType];
        // cow.previousEventDateDisplay = cow[dateField]
        //   ? DateUtil.toYYYYMMDD(Number(cow[dateField]))
        //   : null;

        cow.checked = false;
        return cow;
      });
  }

  cowNumbersDisplay() {
    const cows = this.cows();

    return cows.length > this.COW_NUMBERS_DISPLAY_LENGTH
      ? cows.slice(0, this.COW_NUMBERS_DISPLAY_LENGTH).map((cow) => cow.cowNo).join(', ') + ' ...'
      : cows.map((cow) => cow.cowNo).join(', ');

  }

  onSubmitClick() {
    if (!this.validateBeforeSubmit()) return;

    // 投稿者の情報をセット
    this.appConfig.staff.name = this.state.formData.operatorName;

    const reshaped = this.reshapeFormData(this.state.formData);
    Object.assign(this.state.cowEvents[0], reshaped);

    if (LegacyEventType.isTreatmentEvent(this.state.eventType)) {
      this.setOccurredDiseaseDate();
    }
    this.inputToEvents();

    this.state.bulkEntry().then((result) => {
      if (result && result.status && result.status.ok) {
        this.$modalInstance.close({
          status: {
            submit: true
          }
        });
      }
    }).catch((result) => {
      if (!result.status.error) return;

      this.blockUI.stop();
      const errors = new Set(result.errors);
      return this.MobileBulkentryErrorModal
        .open([...errors], this.state.dateLabel)
        .then((result) => {
          return {
            status: {
              error: true
            }
          };
        }).catch((error) => console.error(error));
    });
  }

  setOccurredDiseaseDate() {
    const inputDate = this.state.formData.occurredDate;

    this.state.cowEvents
      .filter((event) => {
        return event.cowNo ? true : false;
      })
      .forEach((event) => {
        if (this.state.formData.occurredDateField === 'previous') {
          this.state.setPreviousEventDate(this.state.cowIdMap[event.cowId], event);
        } else {
          event.occurredDiseaseDate = inputDate;
        }
      });
  }

  /**
   * 先頭行の入力内容を2行目以降に反映する
   */
  inputToEvents() {
    this.state.cowEvents
      .filter((event, index) => {
        return index !== 0 && event.cowNo ? true : false;
      })
      .forEach((event) => {
        let sourceEvent = angular.copy(this.state.cowEvents[0], {});

        delete sourceEvent.cowId;
        delete sourceEvent.cowNo;
        delete sourceEvent.cowUid;
        delete sourceEvent.formattedCowUid;
        delete sourceEvent.beforeCowGroupId;
        delete sourceEvent.beforeCowGroupName;
        delete sourceEvent.beforePen;
        delete sourceEvent.occurredDate;
        delete sourceEvent.latestCalvingDate;
        delete sourceEvent.eliminateDate;
        delete sourceEvent.gender;
        delete sourceEvent.cowState;

        Object.assign(event, sourceEvent);
      });
  }

  onCancelClick() {
    this.$modalInstance.close({
      status: {
        cancel: true
      }
    });
  }

  onChangeEventTypeClick() {
    this.$modalInstance.close({
      status: {
        changeEventType: true
      }
    });
  }

  onRemoveCowsClick() {
    const cows = this.cows();
    this.MobileRemoveCowsModal.open(cows)
      .then((result) => {
        if (!result.status.ok) return;

        result.cows.forEach((cow) => {
          const index = this.state.cowEvents.findIndex((cowEvent) => cowEvent.cowId === cow.cowId);
          if (index !== -1) this.state.cowEvents.splice(index, 1);
        });

        if (this.state.cowEvents.length > 0) return;

        this.modalDialogFactory.showMobileAlertDialog({
          texts: [
            '牛個体が未選択です。',
            'リスト画面より牛個体を選択してください。'
          ]
        }).result.then(() => {
          this.$modalInstance.close({
            status: {
              cancel: true
            }
          });
        });
      })
      .catch((result) => {
        if (!result.status.cancel) console.error(result);
      });
  }

  reshapeFormData(formData) {
    const clone = angular.copy(formData);

    delete clone.cowId;
    delete clone.cowNo;
    delete clone.cowUid;
    delete clone.formattedCowUid;
    delete clone.beforeCowGroupId;
    delete clone.beforeCowGroupName;
    delete clone.beforePen;

    // 薬品
    if (clone.selectedMedicines) {
      clone.selectedMedicines = clone.selectedMedicines.filter((medicine) => medicine.id);
    }

    if (clone.selectedMedicines && clone.selectedMedicines.length === 0) delete clone.selectedMedicines;

    return clone;
  }

  validateBeforeSubmit() {
    switch (this.state.eventType) {
    case 'gyugunidou':
      if (!this.validateMoveToCowGroupId()) return false;
    }

    return true;
  }

  composeOperatorOptions() {
    return this.AccountService.cachedAccount()
      .then((account) => {
        if (account.shared) {
          this.state.shouldShowOperatorName = true;
          this.OperationUserService.index()
            .then((res) => {
              this.state.operatorOptions = res.data.map((operator) => operator.name);
              this.state.formData.operatorName = this.appConfig.staff.name || this.state.operatorOptions[0] || '';
            });
        } else {
          this.state.shouldShowOperatorName = false;
          this.state.operatorOptions = [account.name];
          this.state.formData.operatorName = account.name;
        }
      });
  }

  hasError() {
    const errors = [];

    Object.keys(this.state.errorStatus).forEach((field) => {
      Object.keys(this.state.errorStatus[field]).forEach((error) => errors.push(error));
    });

    return errors.length > 0;
  }

  validateDate(field) {
    if (!(field in this.state.formData)) {
      this.state.errorStatus[field].required = true;
    } else if (DateUtil.isValidDate(this.state.formData[field])) {
      delete this.state.errorStatus[field].invalid;
      delete this.state.errorStatus[field].required;
    } else {
      this.state.errorStatus[field].invalid = true;
    }

    if (this.state.formData.occurredDateField && this.state.formData.occurredDateField === 'current') {
      this.validateDateOrder();
    }
  }

  validateTargetDate() {
    if (this.state.targetDate === '' || DateUtil.isValidDate(this.state.targetDate)) {
      delete this.state.errorStatus.targetDate.invalid;
    } else {
      this.state.errorStatus.targetDate.invalid = true;
    }

    if (this.state.formData.occurredDateField && this.state.formData.occurredDateField === 'current') {
      this.validateDateOrder();
    }
  }

  validateDateOrder() {
    const occurredDiseaseDate = this.state.formData.occurredDate;

    if (!occurredDiseaseDate || occurredDiseaseDate === '' || !this.state.targetDate || this.state.targetDate === '') {
      delete this.state.errorStatus.targetDate.invalidOrder;
      return;
    }

    if (this.state.targetDate < occurredDiseaseDate ) {
      this.state.errorStatus.targetDate.invalidOrder = true;
    } else {
      delete this.state.errorStatus.targetDate.invalidOrder;
    }
  }

  validateBodyTemperature() {
    if (!this.state.formData.bodyTemperature) {
      delete this.state.errorStatus.bodyTemperature.invalid;
      return;
    }

    if (StringUtil.isDecimal(this.state.formData.bodyTemperature)) {
      delete this.state.errorStatus.bodyTemperature.invalid;
    } else {
      this.state.errorStatus.bodyTemperature.invalid = true;
    }
  }

  onMoveToCowGroupIdUpdate() {
    this.state.updatePenList();
    if (this.state.penList.length === 0 || this.state.penList[0].value === '') {
      this.state.moveToPen = '';
    }
    this.validateMoveToCowGroupId();
  }

  validateMoveToCowGroupId() {
    if (!this.state.moveToCowGroupId && this.state.eventType === 'gyugunidou') {
      this.state.errorStatus.moveToCowGroupId.required = true;
      return false;
    } else {
      delete this.state.errorStatus.moveToCowGroupId.required;
      return true;
    }
  }

  calculateMainAreaHeight() {
    // ウィンドウの高さを変更中に呼ばれると高さが不正になるためブロックする
    if (this.applying) {
      return;
    }
    this.applying = true;

    this.$timeout(() => {
      const windowHeight = $(window).innerHeight();
      const headerHeight = $('header.layout-header .container').innerHeight();
      this.$scope.$apply(() => {
        this.mainAreaHeight = windowHeight - headerHeight;
        this.applying = false;
      });
    });
  }

  shouldShowPreviousEventDate() {
    if (!this.state || !this.state.formData) return false;
    return this.state.formData.occurredDateField === 'previous';
  }

  shouldDisableTreatmentDetail() {
    return !this.state.targetDate ? true : false;
  }

  shouldShowTreatmentDetailWarning() {
    return LegacyEventType.isTreatmentEvent(this.state.eventType) && !this.state.targetDate;
  }

  showMoveToGroup() {
    return CowEvent.isMoveEvent(this.state.eventType, this.state.farm.isMilk());
  }

  showMoveToPen() {
    return this.showMoveToGroup() && this.state.penList && this.state.penList.length > 0 && this.state.penList[0].value !== '';
  }
}
app.controller('MobileBulkEntryEventsController', MobileBulkEntryEventsController);
