// eslint-disable-next-line no-unused-vars
class CoolPesconDevice {
  constructor(data) {
    this.data = angular.extend({}, data);
  }

  static items() {
    return [
      'thi',
      'temperature',
      'humidity',
      'coolWorkMinute',
      'pestWorkMinute'
    ];
  }

  get id() {
    return this.data['id'];
  }

  get label() {
    return this.data['label'];
  }

  get latestTemperature() {
    return this.data['last_measurement']['temperature'];
  }

  get latestHumidity() {
    return this.data['last_measurement']['humidity'];
  }

  get latestThi() {
    return this.calculateThi(this.latestTemperature, this.latestHumidity);
  }

  get operationMode() {
    const operationModes = {cool: '冷房', pest: '防除'};
    return operationModes[this.data['last_measurement']['operation_mode']];
  }

  get operationStatus() {
    return this.isActive() ? '作動中' : '停止中';
  }

  get workHours() {
    return Math.round(this.data['last_measurement']['work_minutes'] / 60);
  }

  get totalWorkHours() {
    return Math.round(this.data['total_work_minutes'] / 60);
  }

  isActive() {
    if (!this.existsLastMeasurement()) return false;
    return this.data['last_measurement']['now_working'];
  }

  existsLastMeasurement() {
    return this.data['last_measurement'] ? true : false;
  }

  isOverAlertWorkHours() {
    return this.data['alert_work_hours'] <= this.totalWorkHours;
  }

  pickupMeasurements(date) {
    if (!this.data['measurements']) return null;

    const details = [];
    for (let i = 0; i < 24; i++) {
      const dateHour = DateUtil.addHours(date, i);
      const temp = this.data['measurements'].reduce((arr, cur) => {
        if (DateUtil.isSameHour(dateHour, cur['timestamp'])) arr.push(cur);
        return arr;
      }, []);
      details.push(this.generateMeasurement(dateHour, temp));
    }
    const dailyValue = this.calculateDailyValue(details);
    return Object.assign({}, dailyValue, {details: details});
  }

  // private

  generateMeasurement(dateHour, measurements) {
    if (!measurements.length) {
      return {date: dateHour, thi: null, temperature: null, humidity: null, coolWorkMinute: null, pestWorkMinute: null};
    }

    const temperatures = measurements.map((m) => m['temperature']);
    const humidities = measurements.map((m) => m['humidity']);
    const avgTemperature = CollectionUtil.avg(temperatures);
    const avgHumidity = CollectionUtil.avg(humidities);

    return {
      date: dateHour,
      thi: this.calculateThi(avgTemperature, avgHumidity),
      temperature: avgTemperature ? avgTemperature.toFixed(1) : null,
      humidity: avgHumidity ? avgHumidity.toFixed(1) : null,
      coolWorkMinute: this.calculateCoolWorkMinute(measurements),
      pestWorkMinute: this.calculatePestWorkMinute(measurements)
    };
  }

  calculateThi(temperature, humidity) {
    const isBlank = [temperature, humidity].some((v) => [null, undefined, ''].includes(v));
    if (isBlank) {
      return null;
    } else {
      return ((0.8 * temperature) + humidity * (temperature - 14.4) / 100 + 46.4).toFixed(1);
    }
  }

  calculateCoolWorkMinute(measurements) {
    const count = measurements.reduce((prev, cur) => {
      if (cur['operation_mode'] === 'cool' && cur['now_working'] === true) prev++;
      return prev;
    }, 0);
    return count * 10;
  }

  calculatePestWorkMinute(measurements) {
    const count = measurements.reduce((prev, cur) => {
      if (cur['operation_mode'] === 'pest' && cur['now_working'] === true) prev++;
      return prev;
    }, 0);
    return count * 10;
  }

  calculateDailyValue(measurements) {
    if (!measurements.length) {
      return {thi: null, temperature: null, humidity: null, coolWorkMinute: null, pestWorkMinute: null};
    }

    return CoolPesconDevice.items().reduce((obj, cur) => {
      const values = measurements.map((m) => m[cur]).filter((v) => v !== null).map(Number);

      if (!values.length) {
        obj[cur] = null;
        return obj;
      }

      let value = ['coolWorkMinute', 'pestWorkMinute'].includes(cur) ?
        CollectionUtil.sum(values) : CollectionUtil.avg(values);

      obj[cur] = value !== null ? value.toFixed(1) : null;
      return obj;
    }, {});
  }
}
