class EventSelectionMultipleChoicesConfirm {
  constructor(modalDialogFactory) {
    'ngInject';
    this.modalDialogFactory = modalDialogFactory;
  }

  open(template, choices) {
    return this.modalDialogFactory.showMultipleChoicesConfirm({
      template: template,
      choices: choices
    });
  }
}

app.service('EventSelectionMultipleChoicesConfirm', EventSelectionMultipleChoicesConfirm);
