class MobileCowSearchUIService {
  constructor(
    CowSearchAPI,
    $modal,
    $state
  ) {
    'ngInject';

    this.$modal = $modal;
    this.$state = $state;
    this.CowSearchAPI = CowSearchAPI;
  }

  /**
   * 牛番号で検索を行う。重複する牛番号がある場合選択ダイアログを表示する
   * @param {string} cowNo 牛番号(部分一致検索に対応)
   * @param {string} [selectButtonLabel] 検索結果が複数ある場合の選択ボタンのラベル
   * @returns {Promise} status: EMPTY(該当する牛番号なし) / FOUND(牛番号が見つかったか、複数候補からユーザーが選択した) / CANCEL (ユーザーがキャンセルした)
   */
  searchByCowNo(cowNo, selectButtonLabel = '個体詳細画面へ') {
    return this.CowSearchAPI.searchByCowNo(cowNo).then((res) => {
      if (!res.data.length) {
        return this.resultEmpty();
      } else if (res.data.length === 1) {
        return this.resultFound(res.data[0].cowId);
      } else if (res.data.length > 1) {
        return this.openCowSelectModal(res.data, selectButtonLabel).then((cowId) => {
          if (cowId) {
            return this.resultFound(cowId);
          } else {
            return this.resultCancel();
          }
        });
      }
    }).catch((err) => console.error(err));
  }

  searchCow(searchType, searchKey, selectButtonLabel = '個体詳細画面へ', activeOnly = false) {
    let methodName = null;
    switch (searchType) {
    case 'cowNo':
      methodName = 'searchByCowNo';
      break;
    case 'cowUid':
      methodName = 'searchByCowUid';
      break;
    case 'bleId':
      methodName = 'searchByBleId';
      break;
    default:
      throw new Error('Invalid value for searchType');
    }

    return this.CowSearchAPI[methodName](searchKey, activeOnly).then((res) => {
      if (!res.data.length) {
        return this.resultEmpty(searchType);
      } else if (res.data.length === 1) {
        return this.resultFound(res.data[0].cowId);
      } else if (res.data.length > 1) {
        return this.openCowSelectModal(res.data, selectButtonLabel).then((cowId) => {
          if (cowId) {
            return this.resultFound(cowId);
          } else {
            return this.resultCancel();
          }
        });
      }
    }).catch((err) => console.error(err));
  }

  /**
   * 牛番号入力モーダルダイアログを開く
   * @param {string} titleLabel ダイアログのタイトルラベル
   * @param {string} submitLabel OKボタンのラベル
   * @returns {Promise} ダイアログ実行時のPromise
   *     status:
   *         SUBMIT(OKボタン押下)
   *         CANCEL(CANCELボタン押下)
   *     cowNo: 牛番号
   */
  openCowNoModal(titleLabel, submitLabel) {
    return this.$modal.open({
      templateUrl: 'mobile/cow-no-modal/mobile-cow-no-modal.html',
      controller: 'MobileCowNoModalController as $ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'table-list-lg',
      resolve: {
        titleLabel: () => titleLabel,
        submitLabel: () => submitLabel
      }
    }).result.then((result) => {
      if (result.status.SUBMIT) {
        return {
          status: {
            SUBMIT: true
          },
          searchType: 'cowNo',
          searchKey: result.cowNo
        };
      } else {
        return {
          status: {
            CANCEL: true
          }
        };
      }
    });
  }

  openCowSearchModal() {
    return this.$modal.open({
      templateUrl: 'mobile/cow-search-modal/mobile-cow-search-modal.html',
      controller: 'MobileCowSearchModalController as $ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'table-list-lg',
      resolve: {
        titleLabel: () => '牛個体検索',
        submitLabel: () => '検索'
      }
    }).result.then((result) => {
      if (result.status.SUBMIT) {
        return {
          status: {
            SUBMIT: true
          },
          searchType: result.searchType,
          searchKey: result.searchKey,
          activeOnly: result.activeOnly,
        };
      } else {
        return {
          status: {
            CANCEL: true
          }
        };
      }
    });
  }

  openNoSuchCowModal(searchType) {
    return this.$modal.open({
      animation: false,
      templateUrl: 'mobile/components/cow-selection-modal/mobile-no-such-cow-modal.html',
      controller: 'MobileNoSuchCowModalController as ctrl',
      backdrop: 'static',
      windowClass: 'modal-error',
      resolve: {
        searchType: () => searchType
      }
    }).result;
  }

  openInvalidCowUidModal() {
    return this.$modal.open({
      animation: false,
      templateUrl: 'mobile/components/cow-selection-modal/mobile-invalid-cowuid-modal.html',
      controller: 'mobileInvalidCowUidModalController as ctrl',
      backdrop: 'static',
      windowClass: 'modal-error'
    }).result;
  }

  goToCowDetail(cowId, isRegisterEvent) {
    const params = {
      cowId: cowId,
      isRegisterEvent: isRegisterEvent,
      caller: {
        state: 'mobile_cow_search'
      }
    };
    this.$state.go('mobileCowDetail', params);
  }

  // private

  resultFound(cowId) {
    return new Promise((resolve) => {
      resolve({
        status: {
          FOUND: true
        },
        cowId: cowId
      });
    });
  }

  resultEmpty(searchType) {
    return new Promise((resolve) => {
      resolve({
        status: {
          EMPTY: true
        },
        searchType
      });
    });
  }

  resultCancel() {
    return new Promise((resolve) => {
      resolve({
        status: {
          CANCEL: true
        }
      });
    });
  }

  openCowSelectModal(cows, selectButtonLabel) {
    return this.$modal.open({
      templateUrl: 'mobile/components/cow-selection-modal/mobile-cow-selection-modal-component.html',
      controller: 'mobileCowSelectionModalController as ctrl',
      backdrop: 'static',
      resolve: {
        params: () => {
          return {
            cows: cows,
            selectButtonLabel: selectButtonLabel
          };
        }
      }
    }).result.then((result) => {
      if (result.status === mobileCowSelectionModalController.RETURN_STATUS.OK) {
        return result.cowId;
      } else {
        return null;
      }
    }).catch((result) => {
      return null;
    });
  }
}

app.service('MobileCowSearchUIService', MobileCowSearchUIService);
