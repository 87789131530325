class MasterHormoneProgramController {
  constructor(
    MasterHormoneProgramAPI,
    uiGridConstants,
    $modal,
    SortOrderEditDialog
  ) {
    'ngInject';
    this.MasterHormoneProgramAPI = MasterHormoneProgramAPI;
    this.uiGridConstants = uiGridConstants;
    this.$modal = $modal;
    this.SortOrderEditDialog = SortOrderEditDialog;

    MasterBaseController.bind(this);

    this.initialize();
  }

  initialize() {
    this.title = 'ホルモンプログラム';
    this.showAllLabel = '全てのホルモンプログラムを表示する';
    this.showAll = false;
    this.filterInstruction = 'プログラム名で絞り込む';
    this.filterItemKey = 'name';

    this.showGrid = false;
    this.setUiGrid();

    this.index();
  }

  setUiGrid() {
    const columns = [
      {columnId: 'name', label: 'ホルモンプログラム名', width: 410},
      {columnId: 'visible', label: '表示対象', width: 70},
      {columnId: 'action', label: '操作', width: 80},
    ];

    const options = {
      clickRowEvent: 'onClickRow',
      cellTemplates: {
        visible: `<div ng-if="row.entity.visible" class="align-center"><i class="icon-s-check-box-outlined"></i></div>`,
        action: `
          <button ng-click="grid.appScope.update(row.entity)"><i class="icon-s-edit"></i></button>
          <button ng-if="!row.entity.used" ng-click="grid.appScope.delete(row.entity)"><i class="icon-s-delete margin-left-15"></i></button>
          `
      }
    };

    this.uiGrid = new UiGridGenerator().generate(this, columns, options);
  }

  index() {
    this.MasterHormoneProgramAPI.index().then((res) => {
      this.rawRecords = res.data;
      this.records = this.rawRecords.filter((r) => r.visible || this.showAll);
      this.uiGrid.data = this.records;
      this.showGrid = true;
      this.enableUpdateSortOrder = this.rawRecords.filter((r) => r.visible).length >= 2;
    });
  }

  create() {
    this.openEditView(ViewMode.CREATE, 'ホルモンプログラムの新規登録', null);
  }

  update(record) {
    this.openEditView(ViewMode.UPDATE, 'ホルモンプログラムの更新', record);
  }

  delete(record) {
    this.openEditView(ViewMode.DELETE, 'ホルモンプログラムの削除', record);
  }

  openEditView(viewMode, title, model) {
    let promise;
    if (viewMode === ViewMode.UPDATE) {
      promise = this.MasterHormoneProgramAPI.used(model.id);
    } else {
      promise = new Promise((resolve, reject) => resolve({data: {used: false}}));
    }

    promise.then((res) => {
      this.$modal.open({
        windowTemplateUrl: 'components/u-modal/window.html',
        templateUrl: 'menu/master/hormone-program/edit.html',
        controller: 'MasterHormoneProgramEditController',
        controllerAs: 'ctrl',
        backdrop: false,
        resolve: {
          params: () => {
            return {
              title: title,
              model: model,
              viewMode: viewMode,
              used: res.data.used
            };
          }
        }
      }).result.then(() => {
        this.showAll = false;
        this.filterCondition = '';
        this.index();
      }, () => {
        // do nothing
      });
    });
  }

  updateSortOrder() {
    const items = this.rawRecords.filter((r) => r.visible)
      .map((r) => {
        return {id: r.id, name: r.name};
      });
    const updateFunction = this.MasterHormoneProgramAPI.updateSortOrder.bind(this.MasterHormoneProgramAPI);

    this.SortOrderEditDialog.open(
      'ホルモンプログラムの表示順設定',
      'ホルモンプログラム名',
      items,
      updateFunction
    ).then((result) => {
      if (result.executed) {
        this.index();
      }
    });
  }

  toggleShowAll() {
    this.records = this.rawRecords.filter((r) => r.visible || this.showAll);
    this.uiGrid.data = this.records;
  }
}

app.controller('MasterHormoneProgramController', MasterHormoneProgramController);
