/**
 * 分娩アラートの指数チャートコンポーネントです
 *
 * ex.
 *   <alert-calving-index-chart
 *     alert-calving="alertCalving"
 *     is-mobile="isMobile"
 *   ></alert-calving-index-chart>
 *
 * プロパティ:
 *  @param {Object} alert-calving 分娩アラートオブジェクト
 *  @param {Object} is-mobile True: モバイル表示
 */
class AlertCalvingIndexChartController {
  constructor(
    $timeout
  ) {
    'ngInject';

    this.$timeout = $timeout;
  }

  $onInit() {
    this.$timeout(() => this.showChart());
  }

  $onDestroy() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  existsIndexes() {
    return this.alertCalving.indexes && this.alertCalving.indexes.length;
  }

  get className() {
    return `index-chart-${this.alertCalving.id}`;
  }

  // private

  get initialOption() {
    return {
      bindto: `.${this.className}`,
      data: {
        json: this.alertCalving.indexes,
        keys: {
          x: 'time',
          value: ['score']
        }
      },
      point: {
        show: false
      },
      axis: {
        x: {
          label: {
            text: '時刻',
            position: 'outer-top'
          },
          type: 'timeseries',
          tick: {
            format: '%H:%M',
            culling: {
              max: 11
            }
          }
        },
        y: {
          max: 12,
          min: 0,
          label: {
            text: '分娩兆候指数',
            position: 'outer-right'
          },
          padding: {
            top: 0,
            bottom: 0
          },
          tick: {
            format: d3.format('d'),
            values: [0, 2, 4, 6, 8, 10, 12]
          }
        }
      },
      legend: {
        show: false
      },
      size: {
        width: 500,
        height: 200
      },
      tooltip: {
        show: false
      },
      zoom: {
        enabled: true
      },
      grid: {
        x: {
          lines: [
            {value: this.alertCalving.startAtDate, text: '検知時刻', class: 'chart-grid-line-red'}
          ]
        }
      },
      regions: [
        {axis: 'y', start: 7, class: 'calving-high'},
        {axis: 'y', start: 5, class: 'calving-low'}
      ]
    };
  }

  generateOption() {
    const option = Object.assign({}, this.initialOption);

    option.grid.x.lines.unshift(...this.alertCalving.gridXLines);

    if (!this.isMobile) return option;

    option.axis.x.tick.culling.max = 6;
    option.size = {
      width: screen.width * 0.70,
      height: screen.height * 0.225
    };
    option.padding = {top: 10, left: 30};

    return option;
  }

  showChart() {
    if (!this.existsIndexes()) return;

    const option = this.generateOption();

    if (this.chart) this.chart.destroy();

    this.chart = c3.generate(option);
  }
}

function alertCalvingIndexChartComponent() {
  'ngInject';
  return {
    controller: AlertCalvingIndexChartController,
    templateUrl: 'menu/alert/calving/index-chart.html',
    bindings: {
      alertCalving: '<',
      isMobile: '<'
    }
  };
}

app.component('alertCalvingIndexChart', alertCalvingIndexChartComponent());
