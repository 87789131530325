function reproductionTimelineOffset($window) {
  return {
    restrict: 'A',
    scope: {
      pageOffset: '=',
      timelineOffset: '=',
      isOpenTimeline: '='
    },
    link: (scope, element, attrs) => {
      const onScroll = () => {

        if (!scope.timelineOffset || !scope.isOpenTimeline) return;
        const css = {
          top: (scope.timelineOffset.top - $window.pageYOffset) + scope.pageOffset.y
        };

        element.css(css);
      };
      angular.element($window).bind('scroll', onScroll);

      scope.$on('$destroy', () => {
        angular.element($window).unbind('scroll', onScroll);
      });
    }
  };
}

app.directive('reproductionTimelineOffset', ['$window', reproductionTimelineOffset]);
