class GroupTransferAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  /**
   * 牧場異動を実行します。
   *
   * [パラメーター例]
   * {
   *    "fromId": 11,
   *    "toId": 12,
   *    "transferDate": 1622473200000,
   *    "transferType": "fattening",
   *    "cows": [
   *      {"cowId": 101, "cowGroupId": 21, "pen": "1", "cowNo": "B001"},
   *      {"cowId": 102, "cowGroupId": 21, "pen": "2", "cowNo": "B002"},
   *      {"cowId": 103, "cowGroupId": 22, "pen": "1", "cowNo": "B003", "notes": "肥育替え"}
   *    ]
   *  }
   */
  create(params) {
    return this.APIService.post('/group/transfer', params);
  }

  /**
   * 牧場異動の対象となる牛個体を検索します。
   *
   * @param {number} id 異動元の牧場のID
   * @param {string} transferType 異動理由
   * @param {Date|number} transferDate 異動日
   * @param {Object} conditions 牛個体の条件
   *   ex. {cowNo: '123'}, {cowUid: '1234567890'}, {cowId: 5}, {transferInputId: 3}
   * @return {Array.<Object>} 牛個体情報の配列
   */
  candidate(id, transferType, transferDate, conditions) {
    const params = {
      id: id,
      transferType: transferType,
      transferDate: DateUtil.toMSec(transferDate)
    };
    if (conditions.transferInputId) {
      params.transferInputId = conditions.transferInputId;
    } else if (conditions.cowId) {
      params.cowId = conditions.cowId;
    } else if (conditions.cowNo) {
      params.cowNo = conditions.cowNo;
    } else if (conditions.cowUid) {
      params.cowUid = conditions.cowUid;
    }

    return this.APIService.post('/group/transfer/candidate', params);
  }

  /**
   * 条件に合致する異動入力履歴を返します。
   *
   * @param {Object} params 絞込条件
   * @return {Array.<Object>} 異動入力の配列
   *
   * ex1. 「異動理由」を条件にするケース
   * {
   *   fromTransferDate: new Date(2021, 3, 1),
   *   transferType: 'fattening'
   * }
   *
   * ex2. 「異動先牧場」を条件にするケース
   * {
   *   fromTransferDate: new Date(2021, 4, 1),
   *   toId: 456
   * }
   *
   * ex3. 「牛番号」を条件にするケース
   * {
   *   fromTransferDate: new Date(2021, 5, 1),
   *   cowCondition: {
   *     type: 'cow_no',
   *     value: '123',
   *     matchingPattern: 'complete'
   *   }
   * }
   */
  history(conditions) {
    const params = {};
    if (conditions.fromTransferDate) {
      params.fromTransferDate = DateUtil.toMSec(conditions.fromTransferDate);
    }
    if (conditions.toTransferDate) {
      params.toTransferDate = DateUtil.toMSec(conditions.toTransferDate);
    }
    if (conditions.transferType) {
      params.transferType = conditions.transferType;
    }
    if (conditions.fromId) {
      params.fromId = conditions.fromId;
    }
    if (conditions.toId) {
      params.toId = conditions.toId;
    }
    if (conditions.cowCondition) {
      params.cowCondition = conditions.cowCondition;
    }

    return this.APIService.post('/group/transfer/history', params);
  }

  /**
   * 牧場異動を更新します。
   *
   * [パラメーター例]
   * {
   *    "fromId": 11,
   *    "toId": 12,
   *    "transferDate": 1622473200000,
   *    "transferType": "fattening",
   *    "cows": [
   *      {"transferInputId": 11, "cowId": 101, "cowGroupId": 21, "pen": "1", "cowNo": "B001"},
   *      {"transferInputId": 12, "cowId": 102, "cowGroupId": 21, "pen": "2", "cowNo": "B002"},
   *      {"transferInputId": 13, "cowId": 103, "cowGroupId": 22, "pen": "1", "cowNo": "B003", "notes": "肥育替え"}
   *    ]
   *  }
   */
  update(params) {
    return this.APIService.put('/group/transfer', params);
  }

  /**
   * 牧場異動を巻き戻します。
   *
   * @param {Array.<number>|number} transferInputIds transfer_input.idの配列または値890'}
   */
  rollback(transferInputIds) {
    const params = {};
    if (Array.isArray(transferInputIds)) {
      params.ids = transferInputIds;
    } else {
      params.ids = [transferInputIds];
    }

    return this.APIService.post('/group/transfer/rollback', params);
  }

  /**
   * 条件に合致する自牧場の転入・転出履歴を返します。
   *
   * @param {Object} params 絞込条件
   * @return {Array.<Object>} 異動入力の配列
   *
   * ex1. 「異動理由」を条件にするケース
   * {
   *   fromTransferDate: new Date(2021, 3, 1),
   *   transferType: 'fattening'
   * }
   *
   * ex2. 在籍牛のみを条件にするケース
   * {
   *   fromTransferDate: new Date(2021, 4, 1),
   *   enrolledOnly: true
   * }
   *
   * ex3. 転入のみを条件にするケース
   * {
   *   fromTransferDate: new Date(2021, 5, 1),
   *   inOut: 'in'
   * }
   */
  historyCurrentFarm(conditions) {
    const params = {};
    if (conditions.inOut) {
      params.inOut = conditions.inOut;
    }
    if (conditions.fromTransferDate) {
      params.fromTransferDate = DateUtil.toMSec(conditions.fromTransferDate);
    }
    if (conditions.toTransferDate) {
      params.toTransferDate = DateUtil.toMSec(conditions.toTransferDate);
    }
    if (conditions.transferType) {
      params.transferType = conditions.transferType;
    }
    if (conditions.enrolledOnly) {
      params.enrolledOnly = conditions.enrolledOnly;
    }

    return this.APIService.post('/group/transfer/history/current_farm', params);
  }
}

app.service('GroupTransferAPI', GroupTransferAPI);
