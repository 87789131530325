class HomeTemporaryGroupViewController {
  constructor(
    $timeout,
    TgvGroupConfigAPI
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.TgvGroupConfigAPI = TgvGroupConfigAPI;

    this.index();
  }

  index() {
    this.TgvGroupConfigAPI.groupSummary().then((res) => {
      this.groupSummary = res.data.summary.groupSummary;
      this.loading.temporaryGroupView = false;
      this.enableMasonry();
    });
  }
}

function homeTemporaryGroupView() {
  return {
    templateUrl: 'menu/home/temporary-group-view/index.html',
    controller: HomeTemporaryGroupViewController,
    controllerAs: 'ctrl',
    bindings: {
      enableMasonry: '&',
      loading: '='
    }
  };
}

app.component('homeTemporaryGroupView', homeTemporaryGroupView());
