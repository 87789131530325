class MasterDiseaseController {
  constructor(
    MasterDiseaseAPI,
    uiGridConstants,
    $modal,
    SortOrderEditDialog
  ) {
    'ngInject';
    this.MasterDiseaseAPI = MasterDiseaseAPI;
    this.uiGridConstants = uiGridConstants;
    this.$modal = $modal;
    this.SortOrderEditDialog = SortOrderEditDialog;

    MasterBaseController.bind(this);

    this.initialize();
  }

  initialize() {
    this.title = '病名・症状';
    this.showAllLabel = '全ての病名・症状を表示する';
    this.showAll = false;
    this.filterInstruction = '病名・症状で絞り込む';
    this.filterItemKey = 'name';

    this.showGrid = false;
    this.setUiGrid();

    this.index();
  }

  setUiGrid() {
    const columns = [
      {columnId: 'name', label: '病名・症状', width: 200},
      {columnId: 'notes', label: '備考', width: 230},
      {columnId: 'visible', label: '表示対象', width: 70},
      {columnId: 'action', label: '操作', width: 80},
    ];

    const options = {
      clickRowEvent: 'onClickRow',
      cellTemplates: {
        visible: `<div ng-if="row.entity.visible" class="align-center"><i class="icon-s-check-box-outlined"></i></div>`,
        action: `
          <button ng-click="grid.appScope.update(row.entity)"><i class="icon-s-edit"></i></button>
          <button ng-if="!row.entity.used" ng-click="grid.appScope.delete(row.entity)"><i class="icon-s-delete margin-left-15"></i></button>
          `
      }
    };

    this.uiGrid = new UiGridGenerator().generate(this, columns, options);
  }

  index() {
    this.MasterDiseaseAPI.index().then((res) => {
      this.rawRecords = res.data;
      this.records = this.rawRecords.filter((r) => r.visible || this.showAll);
      this.uiGrid.data = this.records;
      this.showGrid = true;
      this.enableUpdateSortOrder = this.rawRecords.filter((r) => r.visible).length >= 2;
    });
  }

  create() {
    this.openEditView(ViewMode.CREATE, '病名・症状の新規登録', null);
  }

  update(record) {
    this.openEditView(ViewMode.UPDATE, '病名・症状の更新', record);
  }

  delete(record) {
    this.openEditView(ViewMode.DELETE, '病名・症状の削除', record);
  }

  openEditView(viewMode, title, model) {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/master/master-disease/edit.html',
      controller: 'MasterDiseaseEditController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            title: title,
            model: model,
            viewMode: viewMode
          };
        }
      }
    }).result.then(() => {
      this.showAll = false;
      this.filterCondition = '';
      this.index();
    }, () => {
      // do nothing
    });
  }

  updateSortOrder() {
    const items = this.rawRecords.filter((r) => r.visible)
      .map((r) => {
        return {id: r.id, name: r.name};
      });
    const updateFunction = this.MasterDiseaseAPI.updateSortOrder.bind(this.MasterDiseaseAPI);

    this.SortOrderEditDialog.open(
      '病名・症状の表示順設定',
      '病名・症状',
      items,
      updateFunction
    ).then((result) => {
      if (result.executed) {
        this.index();
      }
    });
  }

  toggleShowAll() {
    this.records = this.rawRecords.filter((r) => r.visible || this.showAll);
    this.uiGrid.data = this.records;
  }
}

app.controller('MasterDiseaseController', MasterDiseaseController);
