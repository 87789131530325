class DhiImportHistoryDetailController {
  constructor(
    $state,
    $stateParams,
    SessionCache,
    DhiImportAPI
  ) {
    'ngInject';

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.farm = SessionCache.farm();
    this.DhiImportAPI = DhiImportAPI;

    this.init();
  }

  init() {
    this.importId = this.$stateParams.importId;
    this.columnDefinitions();
    this.index();
  }

  columnDefinitions() {
    this.columns = [{
      field: 'dhiDate',
      name: '検定日',
      width: 100
    }, {
      field: 'cowNo',
      name: '牛番号',
      width: 120,
      convertNumber: !this.farm.useName()
    }, {
      field: 'cowUid',
      name: '個体識別番号',
      width: 120
    }, {
      field: 'birthNumber',
      name: '産次',
      width: 70
    }, {
      field: 'dim',
      name: '搾乳日数',
      width: 120
    }, {
      field: 'milkDaily',
      name: '乳量<br>(kg/日)',
      width: 120,
      convertNumber: true
    }, {
      field: 'milkExpected',
      name: '標準/管理乳量<br>(kg/日)',
      width: 120,
      convertNumber: true
    }, {
      field: 'fatPercent',
      name: '乳脂肪<br>(%)',
      width: 120,
      convertNumber: true
    }, {
      field: 'protPercent',
      name: '乳蛋白<br>(%)',
      width: 120,
      convertNumber: true
    }, {
      field: 'snfPercent',
      name: '無脂固形<br>(%)',
      width: 120,
      convertNumber: true
    }, {
      field: 'mun',
      name: 'MUN<br>(mg/dl)',
      width: 120,
      convertNumber: true
    }, {
      field: 'scc',
      name: '体細胞数<br>(千個/ml)',
      width: 120
    }, {
      field: 'milk305em',
      name: '305日補正乳量<br>(kg)',
      width: 120,
      convertNumber: true
    }];
  }

  goToBack() {
    const caller = {
      state: 'dhi-import-detail',
      name: '検定データ取込履歴明細'
    };

    this.$state.go('dhi-import', {
      caller
    });
  }

  goToCowDetail(cowId) {
    const cowIds = this.historyDetail.map((item) => {
      return item.cowId;
    });
    const caller = {
      state: 'dhi-import-detail',
      name: '検定データ取込履歴明細',
      params: {
        importId: this.importId
      },
      cowIds: cowIds,
      viewState: 'DHI'
    };

    this.$state.go('cowDetail', {
      cowId: cowId,
      caller
    });
  }

  formatErrorMessage(res, defaultMessage) {
    if (res.data && res.data.messages) {
      return ErrorUtil.formatErrorMessage(res.data.messages).replace(/\n/g, '<br>');
    }

    return defaultMessage;
  }

  index() {
    this.defaultSort = {
      field: 'cowNo',
      order: 'asc'
    };

    this.currentSort = angular.copy(this.defaultSort);
    this.uiGrid = null;
    this.historyDetailErrorMessage = null;

    this.DhiImportAPI.show(this.importId).then((res) => {
      this.historyDetail = res.data.map((item) => {
        let origin = {};

        Object.keys(item).forEach((key) => {
          const convertNumber = this.columns.some((column) => {
            return column.field === key && column.convertNumber;
          });
          const value = item[key];

          if (convertNumber) {
            origin[key] = !isNaN(value) ? Number(value) : value;
          } else {
            origin[key] = value;
          }
        });

        return {
          origin: origin,
          id: item.id,
          dhiDate: DateUtil.toYYYYMMDD(item.dhiDate),
          cowId: item.cowId,
          cowNo: item.cowNo,
          cowUid: item.cowUid,
          birthNumber: item.birthNumber,
          dim: item.dim,
          milkDaily: item.milkDaily,
          milkExpected: item.milkExpected,
          milk305em: item.milk305em,
          fatPercent: item.fatPercent,
          snfPercent: item.snfPercent,
          protPercent: item.protPercent,
          scc: item.scc,
          mun: item.mun
        };
      });

      this.uiGrid = {
        appScopeProvider: this,
        columnDefs: new DhiImportHistoryDetailGenerator().generateColumnDefs(this.columns),
        data: this.historyDetail,
        onRegisterApi: (gridApi) => {
          this.gridApi = gridApi;
        }
      };

      this.sortHistoryDetail();
    }).catch((res) => {
      this.historyDetailErrorMessage = this.formatErrorMessage(res, '検定データ取込履歴明細の取得でエラーが発生しました。');
    });
  }

  clickHeaderCell(field) {
    this.sortHistoryDetail(field);
  }

  sortHistoryDetail(field) {
    if (field) {
      if (this.currentSort.field !== field) {
        this.currentSort.field = field;
        this.currentSort.order = 'asc';
      } else {
        if (this.currentSort.order === 'asc') {
          this.currentSort.order = 'desc';
        } else if (this.currentSort.field !== this.defaultSort.field) {
          this.currentSort = angular.copy(this.defaultSort);
        } else {
          this.currentSort.order = 'asc';
        }
      }
    }

    this.historyDetail = this.historyDetail.sort((a, b) => {
      const asc = a.origin[this.currentSort.field] < b.origin[this.currentSort.field] ? -1 : 1;
      const desc = b.origin[this.currentSort.field] < a.origin[this.currentSort.field] ? -1 : 1;

      return this.currentSort.order === 'asc' ? asc : desc;
    });
  }

  classHeaderCell(field) {
    if (this.currentSort.field === field) {
      if (this.currentSort.order === 'asc') {
        return 'ui-grid-sorted-asc';
      } else {
        return 'ui-grid-sorted-desc';
      }
    }
  }

  styleHeaderCell(field) {
    const width = `${this.columns.find((column) => {
      return column.field === field;
    }).width}px`;

    return {
      width: width,
      minWidth: width,
      height: '100%',
    };
  }
}

app.controller('DhiImportHistoryDetailController', DhiImportHistoryDetailController);
