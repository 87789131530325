class MobileCustomListFilterModalController {
  constructor(
    DateUtilService,
    UserDefinedSelectionAPI,
    SelectionService,
    SessionCache,
    $modalInstance,
    $window,
    $timeout,
    $scope,
    params,
    AlertConfigService,
    MasterCowLabelAPI
  ) {
    'ngInject';
    this.DateUtilService = DateUtilService;
    this.UserDefinedSelectionAPI = UserDefinedSelectionAPI;
    this.SelectionService = SelectionService;
    this.state = {};
    this.$modalInstance = $modalInstance;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.MasterCowLabelAPI = MasterCowLabelAPI;

    const farm = SessionCache.farm();
    this.farmType = farm.farmType();
    this.useCalfManagement = farm.useCalfManagement();

    $window.onresize = () => {
      this.calculateMainAreaHeight.bind(this);
    };
    this.$scope.$on('$destroy', () => {
      $window.onresize = null;
    });

    angular.element(document).ready(() => this.calculateMainAreaHeight.bind(this)());

    this.state = this.initialState();

    AlertConfigService.show().then((res) => {
      const showAcuteIllness = res.data.showAcuteIllness;

      this.init(params, showAcuteIllness);
    });
  }

  calculateMainAreaHeight() {
    // ウィンドウの高さを変更中に呼ばれると高さが不正になるためブロックする
    if (this.applying) {
      return;
    }
    this.applying = true;

    this.$timeout(() => {
      const windowHeight = $(window).innerHeight();
      const headerHeight = $('header.layout-header .container').innerHeight();
      this.$scope.$apply(() => {
        this.mainAreaHeight = windowHeight - headerHeight;
        this.applying = false;
      });
    });
  }

  static get events() {
    return {
      ON_FILTER_CLICK: 'ON_FILTER_CLICK'
    };
  }

  init(params, showAcuteIllness) {
    const columns = params.columns;
    const conditions = params.conditions;
    const sortColumnId = params.sortColumnId || null;
    const sortOrder = params.sortOrder || 'ascending';
    const groupConditions = params.groupConditions || [];
    const groupCondition = params.groupCondition || '';
    const alertTypeConditions = params.alertTypeConditions || [];

    const newState = this.initialConditions(columns, conditions, groupConditions);
    newState.isInitialized = true;
    newState.sortColumnId = sortColumnId;
    newState.sortOrder = sortOrder;
    newState.canAddCondition = conditions.length < columns.length;
    newState.shouldShowGroupCondition = groupConditions.length > 0;
    newState.groupCondition = groupCondition;
    newState.shouldShowAlertTypeCondition = columns.some((column) => column.columnId === 'alert_type');

    if (newState.shouldShowAlertTypeCondition) {
      this.useAlertTypeFilter = [
        {label: '全てのアラート', value: 'all'},
        {label: '絞り込む', value: 'selected'}
      ];
      const alertTypeOptions = this.generateAlertTypeOptions(this.farmType, showAcuteIllness, this.useCalfManagement);

      if (alertTypeConditions.length > 0) {
        newState.useAlertTypeFilter = 'selected';
        alertTypeConditions.forEach((c) => {
          const option = alertTypeOptions.find((e) => e.key === c);
          if (option) {
            option.selected = true;
          }
        });
      } else {
        newState.useAlertTypeFilter = 'all';
        alertTypeOptions.forEach((option) => {
          option.selected = true;
        });
      }
      newState.alertTypeOptions = alertTypeOptions;
      newState.alertTypeConditions = alertTypeConditions;
    } else {
      newState.alertTypeConditions = [];
    }

    this.setState(newState);

    if (this.state.conditions.length === 0 && !this.state.shouldShowGroupCondition) {
      this.addCondition();
    }

    this.state.conditions.forEach((c) => {
      if (c.conditionType === 'SELECTION') {
        this.setSelectionSummary(c);
        this.setSelection(c);
      }
      this.onChangeoperator(c);
    });
  }

  initialState() {
    return {
      isInitialized: false,
      columns: [],
      currentConditions: [],
      selectionConditions: [],
      groupCondition: '',
      alertTypeCondition: '',
    };
  }

  setSelection(condition) {
    const columnId = condition.columnId;
    let action;

    if (columnId === 'cow_labels') {
      return this.MasterCowLabelAPI.available().then((res) => {
        const selectableMasters = CollectionUtil.selectableMasters(res.data);
        const currentSelections = condition.selectionSummary.split('、');
        condition.selections = selectableMasters
          .map((r) => {
            const selected = currentSelections.includes(r.name);
            return {
              key: r.id,
              label: r.name,
              selected: selected
            };
          });

        this.validate();
      });
    } else if (CustomlistSelection.isUserDefined(columnId)) {
      action = this.loadUserDefinedSelection(this.UserDefinedSelectionAPI, columnId);
    } else {
      action = this.loadSelection(this.SelectionService, columnId);
    }

    return action.then((selections) => {
      const currentSelections = condition.selectionSummary.split('、');
      condition.selections = selections
        .map((s) => {
          const selected = currentSelections.includes(s.value);
          return {
            key: s.value,
            label: s.label,
            selected: selected
          };
        });

      this.validate();
    });
  }

  initialConditions(columns, conditions, groupConditions) {
    const state = {};
    const ignoreColumnIds = new Set(['cow_group_name', 'farm_name', 'peak_at', 'alert_notes']);
    const ignoreColumnIdsFilter = new Set(ignoreColumnIds).add('alert_type');

    state.columns = columns.filter((c) => {
      const isIgnoreColumn = ignoreColumnIds.has(c.columnId);
      return !isIgnoreColumn;
    });

    state.columnSelections = state.columns.map((column) => {
      return {
        key: column.columnId,
        label: column.caption
      };
    });

    state.columnSelectionsFilter = columns.filter((c) => {
      return !ignoreColumnIdsFilter.has(c.columnId);
    }).map((column) => {
      return {
        key: column.columnId,
        label: column.caption
      };
    });

    state.columnMap = state.columns.reduce((obj, column) => {
      obj[column.columnId] = column;
      return obj;
    }, {});

    state.operators = {
      // STRING
      STRING: [
        {
          value: 'include',
          label: '指定した値を含む',
          conditionType: 'STRING'
        },
        {
          value: 'exclude',
          label: '指定した値を含まない',
          conditionType: 'STRING'
        },
        {
          value: 'blank',
          label: '未入力',
          conditionType: 'STRING'
        },
      ],
      // NUMBER
      NUMERIC: [
        {
          value: 'between',
          label: '指定範囲内',
          conditionType: 'NUMERIC'
        },
      ],
      // DATE、DATETIME
      DATE: [
        {
          value: 'between',
          label: '指定期間内',
          conditionType: 'DATE'
        },
        {
          value: 'blank',
          label: '未入力',
          conditionType: 'DATE'
        },
      ],
      // SELECTION
      SELECTION: [
        {
          value: 'include',
          label: '指定した値を含む',
          conditionType: 'SELECTION'
        },
        {
          value: 'exclude',
          label: '指定した値を含まない',
          conditionType: 'SELECTION'
        },
      ],
      // ORDINAL_SCALE
      ORDINAL_SCALE: [
        {
          value: 'between',
          label: '指定範囲内',
          conditionType: 'ORDINAL_SCALE'
        },
      ],
    };

    state.groupConditions = groupConditions;

    if (conditions.length > 0) {
      state.conditions = angular.copy(conditions);
      state.canClear = true;
    } else {
      state.conditions = [];
      state.canClear = false;
    }
    state.canAddCondition = state.conditions.length < state.columns.length;

    return state;
  }

  // UIハンドラー

  onCancelClick() {
    this.$modalInstance.dismiss('cancel');
  }

  onSubmitClick() {
    const result = {};

    result.conditions = this.state.conditions.map((c) => {
      const result = {
        columnId: c.columnId,
        conditionType: c.conditionType,
        operator: c.operator
      };

      if (c.conditionType === 'STRING') {
        result['conditionString'] = c.conditionString;
      } else if (c.conditionType === 'NUMERIC') {
        result['conditionMinNumber'] = c.conditionMinNumber;
        result['conditionMaxNumber'] = c.conditionMaxNumber;
      } else if (c.conditionType === 'DATE') {
        result['conditionMinDate'] = this.DateUtilService.toDate(c.conditionMinDate);
        result['conditionMaxDate'] = this.DateUtilService.toDate(c.conditionMaxDate);
      } else if (c.conditionType === 'SELECTION') {
        result['selections'] = c.selections
          .filter((s) => s.selected)
          .map((s) => s.label);
      } else if (c.conditionType === 'ORDINAL_SCALE') {
        result['conditionMinNumber'] = c.conditionMinNumber;
        result['conditionMaxNumber'] = c.conditionMaxNumber;
      }

      return result;
    });

    result.sortColumnId = this.state.sortColumnId;
    result.sortOrder = this.state.sortOrder;
    result.groupCondition = this.state.groupCondition;

    if (this.state.shouldShowAlertTypeCondition) {
      if (this.state.useAlertTypeFilter === 'all') {
        result.alertTypeConditions = [];
      } else {
        result.alertTypeConditions = this.state.alertTypeOptions
          .filter((e) => e.selected)
          .map((e) => e.key);
      }
    }

    result.status = {
      submit: true
    };

    this.$modalInstance.close(result);
  }

  addCondition() {
    const column = this.state.columns.find((column) => {
      const used = this.state.conditions.some((condition) => {
        return column.columnId === condition.columnId;
      });
      return !used;
    });

    if (!column) return;

    const condition = {
      columnId: column.columnId
    };
    this.state.conditions.push(condition);
    this.onChangeColumnId(condition);

    this.state.canAddCondition = this.state.conditions.length < this.state.columns.length;
  }

  removeCondition(index) {
    this.state.conditions.splice(index, 1);
    this.state.canAddCondition = true;
    this.validate();
  }

  onChangeColumnId(c) {
    const column = this.state.columnMap[c.columnId];

    if (column.fieldType === 'STRING') {
      if (CustomlistSelection.isSelection(c.columnId) || CustomlistSelection.isUserDefined(c.columnId)) {
        c.conditionType = 'SELECTION';
        this.setSelectionSummary(c);
        this.setSelection(c);
      } else {
        c.conditionType = 'STRING';
      }
    } else if (this.isNumericType(column.fieldType)) {
      c.conditionType = 'NUMERIC';
    } else if (this.isDateType(column.fieldType)) {
      c.conditionType = 'DATE';
    } else if (column.fieldType === 'ORDINAL_SCALE') {
      c.conditionType = 'ORDINAL_SCALE';
    }

    this.onChangeoperator(c);
  }

  onChangeoperator(c) {
    c.showStringCondition = false;
    c.showNumericCondition = false;
    c.showDateCondition = false;
    c.showSelectionCondition = false;
    c.showOrdinalScaleCondition = false;

    if (c.conditionType === 'STRING') {
      const valid = ['include', 'exclude', 'blank'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'include';
      }
      c.showStringCondition = c.operator === 'include' || c.operator === 'exclude';

      if (!c.showStringCondition) {
        c.conditionString = '';
      }
    } else if (c.conditionType === 'NUMERIC') {
      const valid = ['between'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'between';
      }
      c.showNumericCondition = true;
    } else if (c.conditionType === 'DATE') {
      const valid = ['between', 'blank'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'between';
      }
      c.showDateCondition = c.operator === 'between';

      if (!c.showDateCondition) {
        c.conditionMinDate = null;
        c.conditionMaxDate = null;
      }
    } else if (c.conditionType === 'SELECTION') {
      const valid = ['include', 'exclude'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'include';
      }
      c.showSelectionCondition = true;
    } else if (c.conditionType === 'ORDINAL_SCALE') {
      const valid = ['between'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'between';
      }

      this.ordinalScaleOptions = CustomlistOrdinalScale.selectOptions(c.columnId).map((option) => {
        return {
          key: option.value,
          label: option.label
        };
      });

      c.showOrdinalScaleCondition = true;
    }

    this.validate();
  }

  // ユーティリティ

  setState(state) {
    Object.assign(this.state, state);
  }

  setSelectionSummary(c) {
    if (c.selections && c.selections.length > 0) {
      c.selectionSummary = c.selections.join('、');
    } else {
      c.selectionSummary = '(対象を指定してください)';
    }
  }

  isNumericType(fieldType) {
    return ['INTEGER', 'FLOAT', 'LAPSED_DAYS', 'DIFF_DAYS', 'LAPSED_MONTHS', 'EXPECTED_DAYS'].some((s) => fieldType === s);
  }

  isDateType(fieldType) {
    return fieldType === 'DATE' || fieldType === 'DATETIME';
  }

  clearConditionDate(condition, fieldKey) {
    condition[fieldKey] = null;
    this.validate();
  }

  validate() {
    const invalid = this.state.conditions.some((c) => {
      if (c.conditionType === 'STRING') {
        if (c.operator === 'blank') return false;
        if (!c.conditionString) return true;
      } else if (c.conditionType === 'NUMERIC') {
        if (!c.conditionMinNumber && !c.conditionMaxNumber) return true;
      } else if (c.conditionType === 'DATE') {
        if (!c.conditionMinDate && !c.conditionMaxDate) return true;
      } else if (c.conditionType === 'SELECTION') {
        if (!c.selections) return false;
        if (c.selections.length === 0) return false;
        if (!c.selections.some((s) => s.selected)) {
          return true;
        }
      } else if (c.conditionType === 'ORDINAL_SCALE') {
        if (!c.conditionMinNumber && !c.conditionMaxNumber) return true;
      }

      return false;
    });

    this.setState({invalid});
  }

  // SelectionDialogController

  loadUserDefinedSelection(UserDefinedSelectionAPI, columnId) {
    const map = {
      buyer: {
        'selectionKey': 'buyers'
      },
      introduce_state: {
        'selectionKey': 'introduceStates'
      },
      judge_pregnant_timing: {
        'selectionKey': 'judgePregnantTimings'
      },
      other_reproduction_work: {
        'selectionKey': 'otherReproductionWorks'
      },
      producing_area: {
        'selectionKey': 'producingAreas'
      },
      producing_farm_name: {
        'selectionKey': 'producingFarmNames'
      },
      raising_farm_name: {
        'selectionKey': 'raisingFarmNames'
      },
      vaccine_timing: {
        'selectionKey': 'vaccineTimings'
      },
    };
    const selectionKey = map[columnId].selectionKey;

    return UserDefinedSelectionAPI.available().then((res) => {
      const userDefinedSelections = res.data;
      const selections = StringUtil.splitByNewline(userDefinedSelections[selectionKey]);
      return selections.map((s) => {
        return {
          value: s,
          label: s
        };
      });
    });
  }

  loadSelection(SelectionService, columnId) {
    const map = {
      breed: {
        'selectionKey': 'breed'
      },
      breeding_exclusion_reason: {
        'selectionKey': 'breedingExclusionReason'
      },
      castrat_method: {
        'selectionKey': 'castratMethod'
      },
      color: {
        'selectionKey': 'color'
      },
      dehorn_method: {
        'selectionKey': 'dehornMethod'
      },
      expelled_reason: {
        'selectionKey': 'expelledReason'
      },
      gender: {
        'selectionKey': 'gender'
      },
      latest_breeding_method: {
        'selectionKey': 'tanetsukeMethod'
      },
      pregnancy: {
        'selectionKey': 'pregnancy'
      },
      pregnant_breeding_method: {
        'selectionKey': 'tanetsukeMethod'
      },
      state: {
        'selectionKey': 'state'
      },
    };
    const selectionKey = map[columnId].selectionKey;

    return SelectionService.index().then((res) => {
      const selections = res[selectionKey];
      return selections;
    });
  }

  generateAlertTypeOptions(farmType, showAcuteIllness, useCalfManagement) {
    const alertTypes = [
      {key: 'acute_illness', label: '急性(改)', farmType: 'ALL', showAcuteIllness: true},
      {key: 'illness', label: '急性疾病', farmType: 'ALL'},
      {key: 'chronic_illness', label: '慢性疾病', farmType: 'ALL'},
      {key: 'milk_low', label: '乳量', farmType: 'MILK'},
      {key: 'feed_low', label: '採食', farmType: 'ALL'},
      {key: 'strong_move_low', label: '動態(強)', farmType: 'ALL', useCalfManagement: true},
      {key: 'lie_high', label: '横臥', farmType: 'ALL'},
      {key: 'water_low', label: '飲水', farmType: 'ALL'},
    ];
    return alertTypes.filter((type) => {
      return (type.farmType === 'ALL' || type.farmType === farmType) &&
        (type.showAcuteIllness === showAcuteIllness || !type.showAcuteIllness) &&
        (type.useCalfManagement === useCalfManagement || !type.useCalfManagement);
    });
  }
}

app.controller('MobileCustomListFilterModalController', MobileCustomListFilterModalController);
