class MobileEditBleIdModalController {
  constructor(
    $modalInstance,
    $timeout,
    blockUI,
    BleIdService,
    MobileEditBleIdConfirmModal,
    modalDialogFactory,
    param,
    SessionCache
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.BleIdService = BleIdService;
    this.MobileEditBleIdConfirmModal = MobileEditBleIdConfirmModal;
    this.modalDialogFactory = modalDialogFactory;
    this.cowId = param.cowId;
    this.type = param.type;
    this.bleIds = param.bleIds;
    this.bleId = CowBleId.currentCowBleIdObj(this.bleIds);
    this.isEquip = this.type === 'equip';
    this.isUnequip = this.type === 'unequip';
    this.isChange = this.type === 'change';
    this.isSubmitting = false;
    this.farm = SessionCache.farm();

    this.errMsgMap = {
      bleId: '',
      startDate: '',
      endDate: ''
    };

    this.init();
  }

  init() {
    this.formData = this.initFormData();
    this.showSensorType = this.farm.data.useSensorType === 'use_both_sensor' && !this.isUnequip;
    if (this.showSensorType) {
      if (Object.keys(this.bleId).length === 0 || this.bleId.sensorType === undefined) {
        this.formData['sensorType'] = '1';
      } else {
        this.formData['sensorType'] = this.bleId.sensorType.toString();
      }
    }

    this.submitLabel = this.isEquip ? '適用する' :
      this.isUnequip ? '解除する' : '交換する';
    this.validateBleId();
    this.validateStartDate(this.formData.startDate);
    this.validateEndDate(this.formData.endDate);

    this.validate();
  }

  initFormData() {
    if (this.isEquip) {
      return {
        bleId: this.bleId.bleId,
        cowId: this.cowId,
        startDate: DateUtil.toMSec(DateUtil.today())
      };
    }

    if (this.isChange) {
      return {
        bleId: null,
        cowId: this.cowId,
        startDate: DateUtil.toMSec(DateUtil.today())
      };
    }

    if (this.isUnequip) {
      return {
        bleId: this.bleId.bleId,
        cowId: this.cowId,
        startDate: this.bleId.startDate,
        endDate: DateUtil.toMSec(DateUtil.today())
      };
    }
  }

  canSubmit() {
    return (this.isValid) && (!this.isSubmitting);
  }

  onSubmit() {
    this.isSubmitting = true;

    const blockUIMoment = (message) => {
      this.blockUI.start(message);
      this.$timeout(() => {
        this.blockUI.stop();
      }, 1000);
    };

    const showErrorMessage = (message) => {
      this.modalDialogFactory.showMobileAlertDialog({
        texts: [message]
      });
    };

    if (this.isEquip) {
      this.blockUI.start('更新中');
      const formDataToSend = angular.copy(this.formData);
      formDataToSend.bleId = CowBleId.to12Digits(formDataToSend.bleId);
      this.BleIdService.equip(formDataToSend)
        .then(() => {
          this.blockUI.stop();
          blockUIMoment('センサー番号履歴の追加が完了しました');
          this.$modalInstance.close(true);
        })
        .catch((e) => {
          console.error(e);
          this.blockUI.stop();
          showErrorMessage(ErrorUtil.formatErrorMessage(e.data.messages));
          this.isSubmitting = false;
        });
    }

    if (this.isChange) {
      this.MobileEditBleIdConfirmModal.open({
        title: '確認',
        texts: [
          '適用中のセンサー番号を解除して、入力した別のセンサー番号を適用します。よろしいですか？',
        ],
        yesLabel: '適用する',
        noLabel: 'キャンセル',
      }).result.then((r) => {
        if (r) {
          this.blockUI.start('更新中');
          const formDataToSend = angular.copy(this.formData);
          formDataToSend.bleId = CowBleId.to12Digits(formDataToSend.bleId);
          this.BleIdService.equip(formDataToSend)
            .then(() => {
              this.blockUI.stop();
              blockUIMoment('センサー番号の交換が完了しました');
              this.$modalInstance.close(true);
            })
            .catch((e) => {
              console.error(e);
              this.blockUI.stop();
              showErrorMessage(ErrorUtil.formatErrorMessage(e.data.messages));
              this.isSubmitting = false;
            });
        } else {
          this.isSubmitting = false;
        }
      });
    }

    if (this.isUnequip) {
      this.MobileEditBleIdConfirmModal.open({
        title: '確認',
        texts: [
          'センサー番号を解除すると解除日以降の活動量が表示されなくなります。下記のセンサー番号を解除しますか？',
          `センサー番号：${this.bleId.bleId}`,
          `適用日：${this.bleId.startDateYYYYMMDD}`,
          `解除日：${DateUtil.toYYYYMMDD(this.formData.endDate)}`,
        ],
        yesLabel: '解除する',
        noLabel: 'キャンセル',
      }).result.then((r) => {
        if (r) {
          this.blockUI.start('解除中');
          this.BleIdService.unequip(this.formData.cowId, this.formData.endDate)
            .then(() => {
              this.blockUI.stop();
              blockUIMoment('センサー解除が完了しました');
              this.$modalInstance.close(true);
            })
            .catch((e) => {
              console.error(e);
              this.blockUI.stop();
              showErrorMessage(ErrorUtil.formatErrorMessage(e.data.messages));
              this.isSubmitting = false;
            });
        } else {
          this.isSubmitting = false;
        }
      }).catch((e) => console.error(e));
    }
  }

  onCancel() {
    this.$modalInstance.close(false);
  }

  onBleIdChange() {
    this.validateBleId();
    this.validateDuplicateBleId();
    this.validate();
  }

  onStartDateChange() {
    this.validateStartDate(this.formData.startDate);
    this.validate();
  }

  onEndDateChange() {
    this.validateEndDate(this.formData.endDate);
    this.validate();
  }

  onSensorTypeChange() {
    this.validateDuplicateBleId();
    this.validate();
  }

  validate() {
    this.updateErrMsgMap();
    this.isValid = (
      this.isValidBleId
      && this.isValidStartDate
      && this.isValidEndDate
      && !this.isDuplicateBleId
    );
  }

  validateBleId() {
    this.isValidBleId = CowBleId.validate(this.formData.bleId);
  }

  validateDuplicateBleId() {
    if (!this.isChange) return;

    const lastBleId = this.bleIds.sort((a, b) => b.endDate - a.endDate)[0];

    this.isDuplicateBleId = Number(lastBleId.bleId) === Number(this.formData.bleId);
    if (this.isDuplicateBleId && this.showSensorType) {
      this.isDuplicateBleId = lastBleId.sensorType === Number(this.formData.sensorType);
    }
  }

  validateStartDate(startDate) {
    if (this.isEquip) {
      const lastBleId = this.bleIds.sort((a, b) => b.endDate - a.endDate)[0];
      if (!lastBleId) {
        this.isValidStartDate = true;
      } else {
        this.isValidStartDate = startDate > lastBleId.endDate;
      }
    } else if (this.isChange) {
      const lastBleId = this.bleIds.sort((a, b) => b.endDate - a.endDate)[0];
      if (!lastBleId) {
        this.isValidStartDate = true;
      } else {
        this.isValidStartDate = startDate > lastBleId.startDate;
      }
    } else {
      this.isValidStartDate = true;
    }
  }

  validateEndDate(endDate) {
    if (this.isUnequip) {
      this.isValidEndDate = this.formData.startDate <= this.formData.endDate;
    } else {
      this.isValidEndDate = true;
    }
  }

  updateErrMsgMap() {
    if (this.isDuplicateBleId) {
      this.errMsgMap.bleId = '現在も適用中のセンサー番号です。';
    } else {
      if (this.isValidBleId || !this.formData.bleId) {
        this.errMsgMap.bleId = '';
      } else {
        this.errMsgMap.bleId = 'センサー番号の形式が正しくありません。';
      }
    }

    if (this.isValidStartDate) {
      this.errMsgMap.startDate = '';
    } else {
      if (this.isEquip) {
        this.errMsgMap.startDate = '適用日が前回のセンサー解除日以前の日付になっています。';
      } else {
        this.errMsgMap.startDate = '適用日が現在のセンサー適用日以前の日付になっています。';
      }
    }

    this.errMsgMap.endDate = this.isValidEndDate ? '' : '解除日が適用日より前の日付になっています。';
  }
}

app.controller('MobileEditBleIdModalController', MobileEditBleIdModalController);
