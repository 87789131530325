class DairyTechnicalReportController {
  constructor(
    $rootScope,
    $scope,
    $state,
    $timeout,
    DairyTechnicalReportAPI
  ) {
    'ngInject';

    const monthlyAccordionReportControllerBase = new MonthlyAccordionReportControllerBase(
      $scope,
      $state,
      $timeout
    );
    MonthlyAccordionReportBinder.bind(this, monthlyAccordionReportControllerBase);

    this.DairyTechnicalReportAPI = DairyTechnicalReportAPI;
    this.showDetailToggleState = {};

    this.initCommon(DairyTechnicalReportController.DEFAULT_DISPLAY_MONTHS);
    this.initLocal();
  }

  static get DEFAULT_DISPLAY_MONTHS() {
    return 13;
  }

  initLocal() {
    this.title = '酪農テクニカルレポート';
    this.detailFields = this.DairyTechnicalReportAPI.detailFields();
    this.detailFields.forEach((f) => {
      this.showDetailToggleState[f.label] = false;
      f.row_count = 1;
      f.printRowspan = f.multi !== false ? 3 : 1;
    });
  }

  /**
   * データの取得
   */
  fetchMonthlyData(startMonth, endMonth, dataMap) {
    this.shouldDisableSubmit = true;
    return this.DairyTechnicalReportAPI
      .index(startMonth, endMonth)
      .then((resp) => {
        if (!resp || !Array.isArray(resp.data.detail) || resp.data.detail.length <= 0) {
          return [];
        }

        resp.data.detail.forEach((monthlyData) => {
          if (dataMap[monthlyData.yearMonth]) {
            Object.assign(dataMap[monthlyData.yearMonth], monthlyData);
          }
        });

        const monthlyData = Object.keys(dataMap)
          .map((key) => {
            dataMap[key].month = Number(dataMap[key].month);
            return dataMap[key];
          });
        const averageData = resp.data.average;
        averageData.average = true;
        monthlyData.push(averageData);

        monthlyData.forEach((data) => {
          if (data.average) {
            data.columnLabel = '過去一年';
            return;
          }
          data.columnLabel = data.month === 1
            ? `${data.year}/${data.month}`
            : `${data.month}`;
        });

        return monthlyData;
      });
  }

  shouldAdjustScrollSize() {
    return true;
  }

  /*
   View用
   */

  onToggleShowDetailClick(index) {
    const category = this.detailFields
      .find((c) => c.label === index);

    if (category.multi) {
      this.showDetailToggleState[index] = !this.showDetailToggleState[index];

      category.row_count = this.showDetailToggleState[index] ? 3 : 1;
    }
  }

  shouldShowDetail(index) {
    return this.showDetailToggleState[index];
  }

  shouldShowYoy(field) {
    return field.yoy ? true : false;
  }

  shouldShowDetailToggle(field) {
    return field ? field.multi : false;
  }
}

app.controller('DairyTechnicalReportController', DairyTechnicalReportController);
