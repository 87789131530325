class MilkingScheduleService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/milking/schedule');
  }

  update(params) {
    return this.APIService.post('/milking/schedule', params);
  }
}

app.service('MilkingScheduleService', MilkingScheduleService);
