class HomeDailyReportController {
  constructor(
    $q,
    $state,
    $modal,
    CowGroupAPI,
    SelectionAPI,
    SessionCache
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$modal = $modal;
    this.CowGroupAPI = CowGroupAPI;
    this.SelectionAPI = SelectionAPI;
    this.homeSettings = new HomeSettings(SessionCache);

    this.init();
  }

  $doCheck() {
    if (this.loading.dailyReport || (this.previousDate === this.date)) return;

    this.previousDate = this.date;
    this.enableMasonry();
  }

  init() {
    this.generateMap();
    this.date = this.homeSettings.get('dailyReport').date;
  }

  generateMap() {
    this.$q.all([
      this.CowGroupAPI.index(),
      this.SelectionAPI.index()
    ]).then((res) => {
      const cowGroupMap = res[0].data.reduce((acc, cur) => {
        acc[cur.cowGroupId] = cur;
        return acc;
      }, {});

      const nextCowStateMap = res[1].data.embryoRecoveryNextCowState.reduce((acc, cur) => {
        acc[cur.value] = cur;
        return acc;
      }, {});

      const eventMap = EventType.allTypes.reduce((acc, cur) => {
        acc[cur.eventType] = cur;
        return acc;
      }, {});

      const eventTypeMap = EventType.convertMap();

      this.map = {
        cowGroupMap,
        nextCowStateMap,
        eventMap,
        eventTypeMap
      };
    });
  }

  displayMode(mode) {
    const dailyReport = this.homeSettings.get('dailyReport');

    return dailyReport.displayMode === mode;
  }

  changeDisplayMode(displayMode) {
    this.homeSettings.update('dailyReport', {displayMode});

    this.enableMasonry();
  }

  changeDate(days = 0) {
    if (days) {
      this.date = DateUtil.addDays(this.date, days);
    }

    this.homeSettings.update('dailyReport', {date: this.date});
  }

  onClickCreate() {
    const viewMode = new ViewMode('create');

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'history/nisshi/form.html',
      controller: 'FarmHistoryController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            historyId: '',
            viewMode
          };
        }
      }
    }).result.then(() => {
      this.reload = true;
    });
  }
}

function homeDailyReport() {
  return {
    templateUrl: 'menu/home/daily-report/index.html',
    controller: HomeDailyReportController,
    controllerAs: 'ctrl',
    bindings: {
      enableMasonry: '&',
      loading: '='
    }
  };
}

app.component('homeDailyReport', homeDailyReport());
