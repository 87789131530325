class AlertLocationHistoryController {
  constructor(
    $scope,
    $state,
    AlertService,
    FarmService,
    DateUtilService,
    SelectCowService,
    $rootScope
  ) {
    'ngInject';

    this.$state = $state;
    this.AlertService = AlertService;
    this.FarmService = FarmService;
    this.DateUtilService = DateUtilService;
    this.SelectCowService = SelectCowService.init();

    this.loading = true;
    this.title = '牛群移動アラート(履歴)';
    this.uri = 'alert-location-history';

    $scope.$on('ngRepeatFinished', () => {
      FixedMidashi.create();
    });

    this.callbackParam = {
      state: 'alertLocationHistory',
      name: this.title
    };

    this.init();
  }

  init() {
    this.currentDate = new Date();
    this.currentDate.setDate(this.currentDate.getDate() - 1);
    this.displayDateTime = this.DateUtilService.toMSec(this.currentDate);
    this.FarmService.show().then((result) => {
      this.autoMovement = result.autoMovement;
      this.typeMilk = result.farmKind === '乳用';
    });
    this.load(this.currentDate);
  }

  load(date) {
    this.AlertService.getAlertLocation(date).then((result) => {
      this.cows = result.data.map((cow) => new AlertLocationDecorator(cow, this.DateUtilService, this.currentDate));
      this.loading = false;
    });
  }

  goToDetails(cowId) {
    const cowIds = this.cows.map((cow) => cow.cowId);
    const caller = {
      state: this.callbackParam.state,
      name: this.callbackParam.name,
      cowIds: cowIds
    };
    this.$state.go('cowDetail', {cowId: cowId, caller: caller});
  }

  selectDate() {
    this.currentDate = this.DateUtilService.toDate(this.displayDateTime);
    this.load(this.currentDate);
  }

  nextDay() {
    this.currentDate.setDate(this.currentDate.getDate() + 1);
    this.displayDateTime = this.DateUtilService.toMSec(this.currentDate);
    this.load(this.currentDate);
  }

  prevDay() {
    this.currentDate.setDate(this.currentDate.getDate() - 1);
    this.displayDateTime = this.DateUtilService.toMSec(this.currentDate);
    this.load(this.currentDate);
  }

  onPrint() {
    print();
  }
}

app.controller('AlertLocationHistoryController', AlertLocationHistoryController);
