class TopNotificationsController {
  constructor(
    $modal,
    NotificationsService
  ) {
    'ngInject';
    this.$modal = $modal;
    this.NotificationsService = NotificationsService;
  }

  listItemClass(notification) {
    return `top-notification-list-item ${notification.category.key}`;
  }

  onNotificationClick(notification) {
    this.$modal.open({
      animation: true,
      templateUrl: 'dialog/top-notification/top-notification-dialog.html',
      controller: 'TopNotificationDialogController',
      controllerAs: 'ctrl',
      keyboard: false,
      windowClass: 'modal-center',
      resolve: {
        params: () => {
          return {
            notification
          };
        }
      }
    });
  }

}

app.controller('TopNotificationsController', TopNotificationsController);
