// eslint-disable-next-line no-unused-vars
class CustomlistMaster {
  constructor(params) {
    Object.assign(this, params);
  }

  needPeriodCondition() {
    return this.configType === 'alert_history';
  }

  availableUFilter() {
    const disableTypes = [
      'alert_heat',
      'alert_false_heat',
      'alert_bad_condition',
      'alert_dysstasia',
    ];
    const disable = disableTypes.some((type) => {
      return this.configType === type;
    });
    return !disable;
  }

  hasAlertType() {
    return this.configType === 'alert_bad_condition' || this.configType === 'alert_history';
  }

  isAlertHistory() {
    return this.configType === 'alert_history';
  }
}
