// eslint-disable-next-line no-unused-vars
class CowEventItemRelation {
  static get allItems() {
    return {
      'mastitis': [
        'cowNo',
        'cowUid',
        'occurredDiseaseDate',
        'mastitisCowGroupName',
        'mastitisScore',
        'treatmentDiseaseDate',
        'workerName',
        'bodyTemperature',
        'medication',
        'mastitisBacteriaOfBreastFl',
        'mastitisBacteriaOfBreastBl',
        'mastitisBacteriaOfBreastFr',
        'mastitisBacteriaOfBreastBr',
        'mastitisDiagnosisResultOfBreastFl',
        'mastitisDiagnosisResultOfBreastBl',
        'mastitisDiagnosisResultOfBreastFr',
        'mastitisDiagnosisResultOfBreastBr',
        'stopMilkingOfBreastFl',
        'stopMilkingOfBreastBl',
        'stopMilkingOfBreastFr',
        'stopMilkingOfBreastBr',
      ],
      'perinatal_and_metabolic_diseases': [
        'difficultiesMetritis',
        'difficultiesKetosis',
        'difficultiesFattyLiver',
        'difficultiesAbomasumDisplacement',
        'difficultiesHypocalcium',
        'difficultiesAcidosis',
        'difficultiesDowner',
        'difficultiesPlacenta',
        'difficultiesUrolithiasis',
        'difficultiesVitaminA',
        'difficultiesTympanites',
        'difficultiesStagnantFood',
      ],
      'reproductive_disorders': [
        'difficultiesOvaryStill',
        'difficultiesOvaryCystic',
        'difficultiesRemnant',
        'difficultiesMetritis',
        'difficultiesPyometra',
        'diagnosisResultOfOvaryL',
        'diagnosisResultOfOvaryR',
        'uterusDiagnosisResult',
      ],
      'claw_diseases': [
        'locomotionScore',
        'lameDiagnosisResultFl',
        'lameDiagnosisResultFr',
        'lameDiagnosisResultBl',
        'lameDiagnosisResultBr',
      ],
      'infection_diseases': [
        'otherDisease',
        'difficultiesPneumonia',
        'difficultiesDiarrhea',
        'difficultiesSkinDisease',
      ],
      'others': [
        'otherDisease',
      ],
      'injured': [
        'difficultiesHipDislocation',
        'difficultiesTeatInjury',
        'otherDisease',
      ],
      'fresh_check': [
        'freshCheckResult',
        'bcs',
        'diagnosisResultOfOvaryL',
        'diagnosisResultOfOvaryR',
        'uterusDiagnosisResult',
      ],
      'pregnant_diagnosis': [
        'judgePregnantTiming',
        'otherReproductionWork',
        'ninshinkanteiResult',
      ],
      'bred': [
        'birthNumber',
        'dim',
        'tanetsukeMethod',
        'masterSpermNo',
        'donorName',
        'masterSpermName',
        'embryoType',
        'embryoCertificateNo',
        'inseminationCode',
        'otherReproductionWork',
        'pregnantResult',
      ],
      'calved': [
        'cowNo',
        'cowUid',
        'occurredAt',
        'calvingDifficulty',
        'childNumber',
        'calf1birthResult',
        'calf1breed',
        'calf1gender',
        'calf1registered',
        'calf1cowNo',
        'calf1cowUid',
        'calf1weightOnBirth',
        'calf1calfNo',
        'calf1birthState',
        'calf2birthResult',
        'calf2breed',
        'calf2gender',
        'calf2registered',
        'calf2cowNo',
        'calf2cowUid',
        'calf2weightOnBirth',
        'calf2calfNo',
        'calf2birthState',
        'calf3birthResult',
        'calf3breed',
        'calf3gender',
        'calf3registered',
        'calf3cowNo',
        'calf3cowUid',
        'calf3weightOnBirth',
        'calf3calfNo',
        'calf3birthState',
        'calf4birthResult',
        'calf4breed',
        'calf4gender',
        'calf4registered',
        'calf4cowNo',
        'calf4cowUid',
        'calf4weightOnBirth',
        'calf4calfNo',
        'calf4birthState',
      ],
      'heat': [
        'cowNo',
        'cowUid',
        'occurredAt',
        'plannedBredMethod',
        'plannedBredAt'
      ],
      'embryo_recovery': [
        'cowNo',
        'cowUid',
        'occurredAt',
        'embryoRecoveryRankA',
        'embryoRecoveryRankA1',
        'embryoRecoveryRankA2',
        'embryoRecoveryRankA3',
        'embryoRecoveryRankA4',
        'embryoRecoveryRankA5',
        'embryoRecoveryRankB',
        'embryoRecoveryRankB1',
        'embryoRecoveryRankB2',
        'embryoRecoveryRankB3',
        'embryoRecoveryRankB4',
        'embryoRecoveryRankB5',
        'embryoRecoveryRankC',
        'embryoRecoveryRankD',
        'embryoRecoveryRankDegenerated',
        'embryoRecoveryRankUnfertilized',
        'masterSpermNo',
        'targetBreedingDate',
        'embryoMasterRegistered',
        'hormoneProgramName',
        'nextCowState',
      ],
      'work_note': [
        'cowNo',
        'cowUid',
        'occurredAt',
        'masterWorkNoteTypeName',
        'taioTitle',
      ],
      'dried': [
        'selectedDryPeriod',
      ],
      'moved': [
        'cowNo',
        'cowUid',
        'occurredAt',
        'beforeCowGroupName',
        'beforePen',
        'cowGroupName',
        'moveToPen',
      ],
      'vaccination': [
        'medication',
      ],
      'culled': [
        'cowNo',
        'cowUid',
        'occurredAt',
        'buyer',
        'shipmentNo',
        'producingArea',
        'producingFarmName',
        'salesPrice',
        'expelledReason',
      ],
      'died': [
        'cowNo',
        'cowUid',
        'occurredAt',
        'deathReason',
      ],
      'carcass_characteristic': [
        'cowNo',
        'cowUid',
        'slaughterNo',
        'occurredAt',
        'yieldGrade',
        'meetGrade',
        'beforeSlaughterWeight',
        'dressedCarcassWeightOfL',
        'dressedCarcassWeightOfR',
        'loinArea',
        'ribsThickness',
        'subcutaneousFat',
        'yieldBaseValue',
        'bmsNo',
        'marblingGrade',
        'bcsNo',
        'gloss',
        'bcsAndGlossGrade',
        'tight',
        'texture',
        'tightAndTextureGrade',
        'bfsNo',
        'fatLuster',
        'bfsAndFatLusterGrade',
        'carcassDefectSummary',
        'otherFaultCorrection',
        'dressedCarcassUnitPrice',
        'totalDressedCarcassWeight',
        'dressedCarcassSalesPrice',
        'selectedVisceralDestruction',
      ],
    };
  }

  static get filterItems() {
    return [
      'dressedCarcassUnitPrice',
      'dressedCarcassSalesPrice',
      'salesPrice',
    ];
  }

  static get standardMedications() {
    return [
      'perinatal_and_metabolic_diseases',
      'reproductive_disorders',
      'claw_diseases',
      'infection_diseases',
      'others',
      'injured',
    ];
  }

  static get medicationItems() {
    return [
      'cowNo',
      'cowUid',
      'occurredDiseaseDate',
      'treatmentDiseaseDate',
      'workerName',
      'bodyTemperature',
      'medication',
    ];
  }

  static get standardEvnets() {
    return [
      'fresh_check',
      'pregnant_diagnosis',
      'bred',
      'dried',
      'vaccination',
    ];
  }

  static get standardItems() {
    return [
      'cowNo',
      'cowUid',
      'occurredAt',
      'workerName',
    ];
  }

  static get beefFarmOnly() {
    return [
      'difficultiesUrolithiasis',
      'difficultiesVitaminA',
      'difficultiesTympanites',
    ];
  }

  static items(eventType, farmType, canEditFilterItem = true) {
    let items = CowEventItemRelation.allItems[eventType];
    if (farmType === 'MILK') {
      const ignoreItems = CowEventItemRelation.beefFarmOnly;
      items = items.filter((item) => !ignoreItems.includes(item));
    }

    if (CowEventItemRelation.standardMedications.includes(eventType)) {
      items = CowEventItemRelation.medicationItems.concat(items);
    } else if (CowEventItemRelation.standardEvnets.includes(eventType)) {
      items = CowEventItemRelation.standardItems.concat(items);
    }
    items.push('comment');

    if (canEditFilterItem) {
      return items;
    } else {
      const excludeItems = CowEventItemRelation.filterItems;
      return items.filter((item) => {
        return !excludeItems.includes(item);
      });
    }
  }
}
