// eslint-disable-next-line no-unused-vars
class Account {
  constructor(json) {
    this.values = json;
    this.name = json.name;
    this.email = json.email;
  }

  static get ROLES() {
    return {
      operator: {label: '作業者', editable: true},
      manager: {label: '管理者', editable: false},
      owner: {label: '管理者', editable: false},
      organizer: {label: 'グループ管理者', editable: false},
      supervisor: {label: 'SV', editable: false},
      admin: {label: '特権ユーザー', editable: false},
    };
  }

  static get DEFAULT_ROLE() {
    return 'operator';
  }

  static get REGEX_8_ALPHANUMERIC() {
    return /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/;
  }

  // FIXME: Accountのインスタンスのvalidate関数として定義し直す
  static validatePassword(password) {
    return Account.REGEX_8_ALPHANUMERIC.test(password);
  }

  id() {
    return this.values.id;
  }

  shared() {
    return this.values.shared;
  }

  isAdmin() {
    return this.values.admin;
  }

  isOrganizer() {
    return this.values.organizer;
  }

  isDepositor() {
    return this.values.depositor;
  }

  isAgent() {
    return this.values.agent;
  }

  canManageFarm() {
    return this.values.manageFarm;
  }

  canSwitchFarm() {
    return this.values.switchFarm || this.values.availableFarmChange;
  }

  /**
   * 管理者または運営者である場合にtrueを返します。
   * ※内部仕様を記したくないのでややこしい実装になっています
   *
   * @return {boolean} true: 管理者または運営者
   */
  isAdministrator() {
    if (!this.canSwitchFarm()) return false;
    if (this.isOrganizer()) return false;
    return true;
  }

  isItemFilterApplied() {
    return this.values.itemFilterApplied;
  }

  uiVersion() {
    return this.values.uiVersion;
  }
}
