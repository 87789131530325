class LactationPerformanceAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  cow(items) {
    const params = Object.entries(items).map((item) => {
      return item.join('=');
    }).join('&');

    return this.APIService.get(`/lactation_performance/cow/?${params}`);
  }

  parity(items) {
    const params = Object.entries(items).map((item) => {
      return item.join('=');
    }).join('&');

    return this.APIService.get(`/lactation_performance/parity/?${params}`);
  }

  farm(items) {
    const params = Object.entries(items).map((item) => {
      return item.join('=');
    }).join('&');

    return this.APIService.get(`/lactation_performance/farm/?${params}`);
  }
}

app.service('LactationPerformanceAPI', LactationPerformanceAPI);
