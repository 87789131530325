class ActivitySummaryChartController {
  constructor(
    $q,
    $interval,
    MilkingService,
    ActiveAmountService,
    DateUtilService,
    Colors,
    $scope,
    ActivityAPI
  ) {
    'ngInject';
    this.$q = $q;
    this.$interval = $interval;
    this.ActiveAmountService = ActiveAmountService;
    this.ActivityAPI = ActivityAPI;
    this.MilkingService = MilkingService;
    this.DateUtilService = DateUtilService;
    this.Colors = Colors;

    this.intervals = [];
    this.chartInterval = 200;

    this.baseDate = this.DateUtilService.today();
    this.loading = false;
    this.noData = false;

    this.chartTypes = [
      {label: '採食', value: 'feed', color: this.Colors.limeGreen},
      {label: '反芻', value: 'rumination', color: this.Colors.orange},
      {label: '動態', value: 'move', color: this.Colors.yellow},
      {label: '横臥', value: 'lie', color: this.Colors.purple},
      {label: '静止', value: 'stand', color: this.Colors.grey}
    ];
    this.chartType = 'feed';

    this.parseResponseData = {
      milk: {
        amount: 'amount',
        date: 'date',
        parse: (date) => {
          return this.DateUtilService.toW3CFormat(date);
        }
      },
      activity: {
        amount: {
          feed: 'feedMin',
          rumination: 'ruminationMin',
          move: 'moveMin',
          lie: 'lieMin',
          stand: 'standMin'
        },
        date: 'date',
        parse: (date) => {
          return this.DateUtilService.toW3CFormat(date);
        }
      }
    };

    $scope.$on('changeTab', (event, tab) => {
      if (this.loading || tab !== 'summaryTab') return;
      this.update();
    });
  }

  $onDestroy() {
    this.intervals.forEach((i) => this.$interval.cancel(i));

    if (this.activitySummaryChart) {
      this.activitySummaryChart.destroy();
    }
  }

  $onInit() {
    this.chartTitle = this.isMilkFarm ? '日毎搾乳量と活動量グラフ' : '活動量グラフ';
    this.chartType = this.showFeed ? 'feed' : 'rumination';

    this.load();
  }

  load() {
    const params = this.createParams(this.cow, this.baseDate, this.chartType);
    const activity = params.activity;

    const createRequests = (isMilkFarm, params) => {
      if (isMilkFarm) {
        return [
          this.MilkingService.getDailySummaryByCowId(params.milking),
          this.MilkingService.getCowGroupSummary(params.groupMilking),
          this.ActivityAPI.dailySummary(activity.cowId, activity.startDate, activity.endDate),
          this.ActiveAmountService.getCowGroupSummary(params.groupActivity)
        ];
      } else {
        return [
          this.ActivityAPI.dailySummary(activity.cowId, activity.startDate, activity.endDate),
          this.fatteningStage ?
            this.ActiveAmountService.getFatteningStageSummary(params.fatteningStageActivity) :
            this.ActiveAmountService.getCowGroupSummary(params.groupActivity)
        ];
      }
    };

    const requests = createRequests(this.isMilkFarm, params);

    if (this.activitySummaryChart) {
      this.activitySummaryChart.clear();
    }

    this.loading = true;
    this.$q.all(requests).then((res) =>{
      this.activitySummaryChartData = res;
    }).then(() => {
      this.update();
    }).finally(() => {
      this.loading = false;
    });
  }

  createParams(cow, date, chartType) {
    const startDate = this.DateUtilService.addDays(date, -31);
    const endDate = this.DateUtilService.addDays(date, -1);
    const params = {
      startDate: this.DateUtilService.toW3CFormat(startDate),
      endDate: this.DateUtilService.toW3CFormat(endDate)
    };

    const milkingParams = Object.assign({}, params, {
      cowId: cow.cowId,
      milkingOrder: 0
    });

    const activityParams = Object.assign({}, params, {cowId: cow.cowId});

    const groupMilkingParams = Object.assign({}, params, {
      cowGroupId: cow.cowGroupId,
      statsType: 'avg'
    });

    const groupActivityParams = Object.assign({}, params, {
      cowGroupId: cow.cowGroupId,
      dataType: chartType,
      statsType: 'avg',
    });

    const fatteningStageActivityParam = {};
    if (this.fatteningStage) {
      Object.assign(fatteningStageActivityParam, params, {
        fatteningStageId: this.fatteningStage.id,
        dataType: chartType,
        statsType: 'avg',
      });
    }

    return {
      milking: milkingParams,
      activity: activityParams,
      groupMilking: groupMilkingParams,
      groupActivity: groupActivityParams,
      fatteningStageActivity: fatteningStageActivityParam
    };
  }

  update() {
    const selectedType = this.chartTypes.find((type) => type.value === this.chartType);

    this.summaryDateColumns = createStartEndDateColumn(minusDays(this.baseDate, 31), minusDays(this.baseDate, 1));

    const groupAvgCaption = this.fatteningStage ? '(肥育ステージ平均)' : '(牛群平均)';

    let dataNames = ['搾乳量', '搾乳量(牛群平均)', selectedType.label, selectedType.label + groupAvgCaption];
    let summaryTypes = ['milk', 'milk', 'activity', 'activity'];
    let dataTypes = ['', '', selectedType.value, selectedType.value];
    let dataTypeColors = [this.Colors.blue, this.Colors.brightOrange, selectedType.color, this.Colors.deepPurple];
    let graphTypes = ['bar', 'bar', 'line', 'line'];
    let axesTypes = ['y', 'y', 'y2', 'y2'];

    if (!this.isMilkFarm) {
      dataNames.splice(0, 2);
      summaryTypes.splice(0, 2);
      dataTypes.splice(0, 2);
      dataTypeColors.splice(0, 2);
      graphTypes.splice(0, 2);
      axesTypes = ['y', 'y'];
    }

    const interval = this.$interval(() => {
      const chartDataExists = this.activitySummaryChartData.some((element) => !_.isEmpty(element.data));
      if (!chartDataExists) {
        return this.$interval.cancel(interval);
      }

      let columns = [], colors = {}, axes = {}, types = {}, axisY = {};

      columns = _.chain(this.activitySummaryChartData)
        .map((dataObj, index) => {
          const column = _.clone(this.summaryDateColumns);
          if (!this.showFeed && dataTypes[index] === 'feed') {
            Object.keys(column).forEach((k) => column[k] = null);
          }
          column['x'] = dataNames[index];
          const parseResponse = {};
          parseResponse['date'] = this.parseResponseData[summaryTypes[index]].date;
          parseResponse['parse'] = this.parseResponseData[summaryTypes[index]].parse;
          if (summaryTypes[index] === 'activity') {
            parseResponse['amount'] = this.parseResponseData[summaryTypes[index]].amount[dataTypes[index]];
          } else {
            parseResponse['amount'] = this.parseResponseData[summaryTypes[index]].amount;
          }

          if (this.showFeed || dataTypes[index] !== 'feed') {
            dataObj.data.forEach((d) => {
              if (column[parseResponse.parse(d[parseResponse.date])] === 0) {
                column[parseResponse.parse(d[parseResponse.date])] = Number(d[parseResponse.amount]);
              }
            });
          }

          colors[[String(dataNames[index])]] = dataTypeColors[index];
          axes[String(dataNames[index])] = axesTypes[index];
          types[String(dataNames[index])] = graphTypes[index];

          return _.values(column);
        }).reduce((_columns, column) => {
          _columns.push(column);
          return _columns;
        }, []).value();

      axisY = {
        labelY: this.isMilkFarm ? 'milk' : selectedType.value,
        labelY2: selectedType.value,
        isShowY2: this.isMilkFarm,
      };

      if (!this.activitySummaryChart) {
        this.activitySummaryChart = new AnalyticChart('#activity_summary_chart');
      }
      columns.unshift(_.keys(this.summaryDateColumns));
      this.activitySummaryChart.showSummaryChart(columns, colors, axes, types, axisY, this.height);

      this.$interval.cancel(interval);
    }, this.chartInterval);
    this.intervals.push(interval);
  }

  onChangeChartType() {
    this.load();
  }
}

function activitySummaryChart() {
  return {
    templateUrl: 'cow/components/charts/activity-summary-chart.html',
    bindings: {
      cow: '=',
      isMilkFarm: '=',
      showFeed: '=',
      fatteningStage: '<',
      height: '='
    },
    controller: ActivitySummaryChartController,
    controllerAs: 'asc',
  };
}

app.component('activitySummaryChart', activitySummaryChart());
