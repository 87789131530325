class DysstasiaAPI {
  constructor(
    APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  show(id) {
    return this.APIService.get(`/dysstasia/${id}`);
  }

  create(cowId, findingAt, comment, reporter) {
    const params = {
      cowId: cowId,
      findingAt: DateUtil.toMSec(findingAt),
      comment: comment || '',
      reporter: reporter || ''
    };
    return this.APIService.post('/dysstasia', params);
  }

  update(id, findingAt, comment, reporter) {
    const params = {
      findingAt: DateUtil.toMSec(findingAt),
      comment: comment || '',
      reporter: reporter || ''
    };
    return this.APIService.put(`/dysstasia/${id}`, params);
  }

  delete(id) {
    return this.APIService.delete(`/dysstasia/${id}`);
  }
}

app.service('DysstasiaAPI', DysstasiaAPI);
