class SelectMedicineModal {
  constructor($modal) {
    'ngInject';
    this.$modal_ = $modal;
  }

  /**
   * モーダルを表示する
   *
   * @param {string} selectedMedicines 選択済薬品
   * @param {string} medicineCapacities 選択済薬品容量
   * @param {string} medicineMethods 選択済薬品処置方法
   * @param {string} qwert
   * @return {Object} modal instance
   */
  open(selectedMedicines, eventType, qwert) {
    return this.$modal_.open({
      animation: true,
      templateUrl: 'history/selection/medicine/medicine-select-modal.html',
      controller: 'MedicineSelectModalController',
      controllerAs: 'medicineSelectModalCtl',
      backdrop: 'static',
      keyboard: false,
      size: 'table-list-lg',
      resolve: {
        params: () => {
          return {
            selectedMedicines: selectedMedicines,
            eventType: eventType,
            qwert: qwert
          };
        }
      }
    });
  }
}

app.service('SelectMedicineModal', SelectMedicineModal);
