class PregnantDiagnosisTimingAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  show() {
    return this.APIService.get('/pregnant_diagnosis_timing');
  }

  update(params) {
    return this.APIService.post('/pregnant_diagnosis_timing', params);
  }
}

app.service('PregnantDiagnosisTimingAPI', PregnantDiagnosisTimingAPI);
