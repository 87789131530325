class ReproductionCowGroupSummaryAPI {
  constructor(
    APIService,
    $timeout
  ) {
    'ngInject';
    this.APIService = APIService;
  }

  show() {
    return this.APIService.get('/deprecated/reproduction_cow_group_summary');
  }
}

app.service('ReproductionCowGroupSummaryAPI', ReproductionCowGroupSummaryAPI);
