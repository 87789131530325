class BeefHistoryController {
  constructor(
    $q,
    $scope,
    $timeout,
    AccountService,
    DateUtilService,
    FarmService,
    ShipmentService,
    $rootScope
  ) {
    'ngInject';

    this.$q = $q;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.AccountService = AccountService;
    this.DateUtilService = DateUtilService;
    this.FarmService = FarmService;
    this.ShipmentService = ShipmentService;
    this.isDepositorFarm = false;
    this.formData = {};
    this.shipments = [];
    this.selectedIndex = null;
    this.farmNames = [];
    this.selectedFarmName = null;
    this.loadedProductionCertificates = {};
    this.productionCertificates = [];
    this.productionCertificatesToPrint = [];
    this.treatmentHistoryMinNumberOfRows = 15;

    this.init();
  }

  init() {
    const now = Date.now();
    this.formData.start_date = now - 1000 * 60 * 60 * 24 * 7;
    this.formData.end_date = now;
    this.FarmService.show().then((res) => {
      this.isDepositorFarm = res.depositorFarm;
    }).catch((err) => console.error(err));
    this.$timeout(() => {
      this.search();
    });
  }

  createParams(formData) {
    const params = {};
    Object.keys(formData).forEach((key) => {
      if (key === 'start_date' || key === 'end_date') return params[key] = this.DateUtilService.toW3CFormat(formData[key]);
      return params[key] = formData[key];
    });

    return params;
  }

  resetState() {
    this.selectedIndex = null;
    this.productionCertificates = [];
  }

  search() {
    const params = this.createParams(this.formData);

    this.ShipmentService.searchShipments(this.isDepositorFarm, params).then((res) => {
      this.resetState();
      this.shipments = res.data.map((e) => {
        return {
          buyer: e.buyer,
          farmId: e.farmId,
          farmName: e.farmName,
          id: e.id,
          quantity: e.quantity,
          shipmentDate: this.DateUtilService.toYYYYMMDD(e.shipmentDate),
        };
      });
      this.farmNames = res.data.map((e) => e.farmName).filter((e, i, a) => {
        return a.indexOf(e) === i; //同じ牧場名が重複して入らないようにする。
      });
    }).catch((err) => console.error(err));
  }

  showPrintButton($index) {
    return this.selectedIndex === $index;
  }

  showShipment(farmName) {
    if (!this.selectedFarmName) return true;
    if (farmName === this.selectedFarmName) return true;
    return false;
  }

  select(selectedIndex) {
    this.selectedIndex = selectedIndex === this.selectedIndex ? null : selectedIndex;
    if (this.selectedIndex !== null) {
      const shipmentId = this.shipments[this.selectedIndex].id;
      const promise = this.updateProductionCertificates(shipmentId);
      promise.then((res) => {
        this.productionCertificates = res;
        this.loadedProductionCertificates[shipmentId] = res;
      }).catch((err) => console.error(err));
    }
  }

  toggleAllCheck($event) {
    if ($event.currentTarget.checked) {
      const toPrints = document.getElementsByClassName('to-print');
      Array.prototype.forEach.call(toPrints, (e, i) => {
        e.checked = true;
      });
    } else {
      const toPrints = document.getElementsByClassName('to-print');
      Array.prototype.forEach.call(toPrints, (e, i) => {
        e.checked = false;
      });
    }
  }

  formatProductionCertificates(productionCertificates) {
    const formattedProductionCertificates = productionCertificates.map((p) => {
      const formattedTreatments = p.treatments.map((t) => {
        return {
          date: this.DateUtilService.toYYYYMMDD(t.date),
          monthAge: (p.birthday && t.date) ? `${DateUtil.monthAge(p.birthday, t.date)}ヶ月` : '',
          diseaseName: t.diseaseName,
          medicineName: t.medicineName,
          medicineAmount: `${t.medicineCapacity || ''}${t.medicineUnit || ''}`,
          beefWashoutEndDate: (t.date && t.beefWashoutPeriod) ? this.DateUtilService.toYYYYMMDD(
            this.DateUtilService.addDays(t.date, t.beefWashoutPeriod)) : '',
          workerName: t.workerName
        };
      });
      if (this.treatmentHistoryMinNumberOfRows - formattedTreatments.length > 0) {
        const rowsToAdd = this.treatmentHistoryMinNumberOfRows - formattedTreatments.length;
        for (let i = 0; i < rowsToAdd; i++) {
          formattedTreatments.push({
            date: '&nbsp;'
          });
        }
      }
      return {
        cowId: p.cowId,
        cowNo: p.cowNo,
        cowUid: formatCowUid(p.cowUid),
        birthday: this.DateUtilService.toYYYYMMDD(p.birthday),
        introduceDate: this.DateUtilService.toYYYYMMDD(p.introduceDate),
        weightOnPurchase: p.weightOnPurchase ? String(p.weightOnPurchase) + ' kg' : '',
        shippedMonthAge: p.shippedMonthAge,
        breed: p.breed,
        gender: p.gender,
        farmName: p.farmName,
        farmAddress: p.farmAddress,
        marketName: p.marketName,
        marketAddress: p.marketAddress,
        fatherName: p.fatherName,
        maternalGrandfatherName: p.maternalGrandfatherName,
        maternalGreatGrandfatherName: p.maternalGreatGrandfatherName,
        feedFormulation: p.feedFormulation,
        roughage: p.roughage,
        treatments: formattedTreatments
      };
    });
    return formattedProductionCertificates;
  }

  updateProductionCertificates(shipmentId) {
    const deferred = this.$q.defer();
    if (this.loadedProductionCertificates[shipmentId]) {
      deferred.resolve(this.loadedProductionCertificates[shipmentId]);
    } else {
      this.ShipmentService.searchProductionCertificates(shipmentId).then((res) => {
        const formattedProductionCertificates = this.formatProductionCertificates(res.data);
        deferred.resolve(formattedProductionCertificates);
      }).catch((err) => deferred.reject(err));
    }
    return deferred.promise;
  }

  print($event, $index) {
    $event.stopPropagation();
    this.productionCertificatesToPrint = null;
    if (this.selectedIndex !== $index) {
      const shipmentId = this.shipments[$index].id;
      const promise = this.updateProductionCertificates(shipmentId);
      promise.then((res) => {
        this.productionCertificatesToPrint = res;
        this.loadedProductionCertificates[shipmentId] = res;
        this.$timeout(() => {
          print();
        });
      }).catch((err) => console.error(err));
    } else {
      const toPrints = document.getElementsByClassName('to-print');
      this.productionCertificatesToPrint = [];
      Array.prototype.forEach.call(toPrints, (e, i) => {
        if (e.checked) this.productionCertificatesToPrint.push(this.productionCertificates[i]);
      });
      this.$timeout(() => {
        print();
      });
    }
  }
}

app.controller('BeefHistoryController', BeefHistoryController);
