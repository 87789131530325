app.controller('OrderedSummaryReportController', function(
  $scope,
  $rootScope,
  $modal,
  $state,
  $stateParams,
  FarmService,
  OrderedListService,
  OrderedSummaryReportService,
  blockUI,
  $timeout
) {
  'ngInject';

  $scope.selection = $scope.selection || {};

  $scope.showOrderedList = false; // 個別リストの表示制御フラグ
  $scope.startDate = null;
  $scope.endDate = null;

  $scope.$on('ngRepeatFinished', function() {
    FixedMidashi.create();
  });

  $scope.mode = '0';

  // 初期化処理
  (() => {
    $scope.busy = true;
    $scope.loading = true;

    FarmService.show().then((result) => {
      $scope.showOrderedList = result.useLegacyReportMenu;
    });

    const unitPeriods = [
      {label: '発情サイクル(21日周期)', value: 'estrous-cycle'},
      {label: '月次', value: 'monthly'}
    ];
    $scope.selection.unitPeriod = unitPeriods;
    $scope.unitPeriod = 'estrous-cycle';
  })();

  $scope.clearInput = (key) => {
    $scope[key] = null;
  };

  $scope.summaryReport = (menuId) => {
    blockUI.start('集計中');

    OrderedSummaryReportService.getSummaryReport(menuId, $scope.startDate, $scope.endDate)
      .then(function(res) {
        blockUI.done(function() {
          blockUI.start('集計が完了しました');
          createPDFWindow(res.data);
          $timeout(function() {
            blockUI.stop();
          }, 1000);
        });
        blockUI.stop();
      })
      .catch(function(error) {
        blockUI.stop();
        console.error((error && error.stack) || error);
      });
  };

  function createPDFWindow(data) {
    let file = new Blob([data], {type: 'application/pdf'});
    let url = window.URL || window.webkitURL;
    if (window.navigator.msSaveOrOpenBlob) {
      // IE、Edge対応です
      window.navigator.msSaveOrOpenBlob(file, 'report.pdf');
    } else {
      let fileURL = url.createObjectURL(file);
      window.open(fileURL);
    }
  }
});
