class CowDetailController {
  get TIMEBUDGET_DATA_PERIOD() {
    return 365;
  }

  static get VIEW_STATES() {
    return {
      DEFAULT: {
        displayTab: 'summaryTab',
        chartDisplayRange: 30,
        milkingChartRange: 30,
      },
      DETAIL: {
        displayTab: 'dataTab',
        chartDisplayRange: 30,
        milkingChartRange: 30,
      },
      ACTIVITY: {
        displayTab: 'activityTab',
        chartDisplayRange: 30,
        milkingChartRange: 30,
      },
      ACTIVITY_RECENT: {
        displayTab: 'activityTab',
        chartDisplayRange: 7,
        milkingChartRange: 30,
      },
      MILKING: {
        displayTab: 'milkingTab',
        chartDisplayRange: 30,
        milkingChartRange: 30,
      },
      TIMELINE: {
        displayTab: 'timelineTab',
        chartDisplayRange: 30,
        milkingChartRange: 30,
      },
      DHI: {
        displayTab: 'dhiTab',
        chartDisplayRange: 30,
        milkingChartRange: 30,
      },
      GENOM: {
        displayTab: 'genomTab',
      },
    };
  }

  constructor(
    $modal,
    $q,
    $scope, // FIXME: CowTimelineControllerに牛個体情報を渡すための苦肉の策
    $rootScope,
    $state,
    $stateParams,
    $interval,
    $window,
    $timeout,
    $document,
    ActiveAmountService,
    appConst,
    BleIdService,
    blockUI,
    Colors,
    CowEventsService,
    CowService,
    CowState,
    DateUtilService,
    DateTimeUtilFactory,
    DetailsStateFactory,
    FarmKind,
    FarmService,
    MilkingService,
    StandardDialog,
    UtilService,
    MasterMarketService,
    Dictionary,
    FatteningStageService,
    HeatIndexAPI,
    ChartGenerator,
    SessionCache,
    ChartActivityScatterPlotAPI,
    CowCalvingSensorHistoryAPI,
    CowCalvingSensorHistoryDialog,
    $location,
    AccountAPI
  ) {
    'ngInject';
    this.$modal = $modal;
    this.$q = $q;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$interval = $interval;
    this.$window = $window;
    this.$timeout = $timeout;
    this.$document = $document;
    this.ActiveAmountService = ActiveAmountService;
    this.HeatIndexAPI = HeatIndexAPI;
    this.appConst = appConst;
    this.BleIdService = BleIdService;
    this.blockUI = blockUI;
    this.Colors = Colors;
    this.CowEventsService = CowEventsService;
    this.CowService = CowService;
    this.CowState = CowState;
    this.DateUtilService = DateUtilService;
    this.DateTimeUtilFactory = DateTimeUtilFactory;
    this.DetailsStateFactory = DetailsStateFactory;
    this.FarmKind = FarmKind;
    this.FarmService = FarmService;
    this.MilkingService = MilkingService;
    this.StandardDialog = StandardDialog;
    this.UtilService = UtilService;
    this.ChartGenerator = ChartGenerator;
    this.MasterMarketService = MasterMarketService;
    this.Dictionary = Dictionary;
    this.FatteningStageService = FatteningStageService;
    this.ChartActivityScatterPlotAPI = ChartActivityScatterPlotAPI;
    this.CowCalvingSensorHistoryAPI = CowCalvingSensorHistoryAPI;
    this.CowCalvingSensorHistoryDialog = CowCalvingSensorHistoryDialog;
    this.AccountAPI = AccountAPI;
    this.SessionCache = SessionCache;
    this.farm = SessionCache.farm();

    this.cowId = this.$stateParams.cowId;
    this.cowIdIndex = this.$stateParams.cowIdIndex;
    this.caller = Object.keys($stateParams.caller).length > 0 ?
      $stateParams.caller :
      this.$rootScope.reactCaller ? this.$rootScope.reactCaller.caller : {};

    this.deregisters = [];
    this.intervals = [];

    // 個体詳細画面のUIバージョン
    const uiVersionCowDetail = SessionCache.account().uiVersion().split('_')[1];

    if (!['c-1', 'c-1.5'].includes(uiVersionCowDetail)) {
      this.changeUiVersion('1.5');
    } else {
      this.$rootScope.uiVersion = uiVersionCowDetail;
    }

    // アクセスログに起動経路を出力する
    if ($stateParams.caller) {
      this.callerId = $stateParams.caller.bqNotes || $stateParams.caller.state;
    }

    this.sellectableCowIds = this.caller.cowIds;

    // ページャー機能
    this.umPagerConfig = {
      caller: this.caller,
      next: '', // sfId
      prev: '' // sfId
    };
    this.enablePager = false;

    // サブメニュー操作
    this.subControlIsOpen = false;

    /**
     * 表示切り替えフラグ {bool}
     */
    this.isFatteningType = false;
    this.isCalfType = false;
    this.isReproductionType = false;
    this.isSoldOrDiedType = false;
    this.isMilkFarm = false;
    this.isMeatFarm = false;
    this.hasMilkingSchedule = false;
    this.showSettingsBoard = false;
    this.canEditFilterItem = !SessionCache.account().isItemFilterApplied();
    this.showClavingSensorNumber = SessionCache.farm().useAlertCalving();
    this.isAdmin = SessionCache.account().isAdmin();

    // Declarations
    this.defaults = this.DetailsStateFactory.get();
    const queryParams = $location.search();

    if (this.caller && this.caller.state === 'customlist' && this.cowIdIndex === '') {
      this.defaults = CowDetailController.VIEW_STATES.DETAIL;
    } else if (this.caller && this.caller.viewState) {
      if (uiVersionCowDetail === 'c-1' && this.caller.viewState === 'GENOM') {
        this.defaults = CowDetailController.VIEW_STATES.DEFAULT;
      } else {
        this.defaults =
             CowDetailController.VIEW_STATES[this.caller.viewState]
          || CowDetailController.VIEW_STATES.DEFAULT;
      }
    } else if (queryParams.activetab && queryParams.activetab === 'activity-heat') {
      this.defaults = CowDetailController.VIEW_STATES.ACTIVITY_RECENT;
    } else if (queryParams.activetab && queryParams.activetab === 'activity-daily') {
      this.defaults = CowDetailController.VIEW_STATES.ACTIVITY;
    }

    if (this.cowIdIndex === '') {
      this.DetailsStateFactory.set('hiddenLegendIds', []);
    }

    if (this.caller && this.caller.displayTab) {
      this.changeTab(this.caller.displayTab);
    }

    this.dUtil = this.DateTimeUtilFactory;
    this.accountInfo = this.appConst.default.AccountInfo;
    this.now = this.dUtil.parseClientDate(new Date());

    // 市場マスタ用
    this.masterMarkets = [];

    // タブ共有
    this.chartInit = {
      activityStackedBarChart: false,
      activityRateChart: false,
      activityScatterPlotChart: false,
      summaryActivityRateChart: false,
      activityTimeBudgetChart: false,
      summaryTimeBudgetChart: false,
      summaryActiveMilkChart: false
    };

    // チャート描画制御フラグ
    this.showMilkingChart = false;
    this.showActivitySummaryChart = false;
    this.showTimelineChart = false;

    // chart interval(ms)
    this.chartInterval = 200;

    // タイムバジェットデータ
    this.activeTimeBudgetData = {
      summary: [],
      activity: [],
    };

    // タイムバジェット用 飲水回数データ
    this.activeDrinkingDailyData = {
      summary: [],
      activity: [],
    };

    // 24時間飲水時間データ
    this.activeDrinking24hoursData = {};

    // タイムバジェット用（サマリー・活動量両方）
    this.timeBudgetChart = {};
    this.timeBudgetChart.activity = new TimeBudget('activityTimeBudget');
    this.timeBudgetChart.summary = new TimeBudget('summaryTimeBudget');

    const SUMMARY_ACTIVITY_RATE_CHART_HEIGHT = 170;
    const options = ActivityRateChart.defaultOptions();
    options.size.height = SUMMARY_ACTIVITY_RATE_CHART_HEIGHT;
    this.summaryActivityRateChart = new ActivityRateChart('#summary-activity-rate-chart', options);

    // 活動量タブ用
    // 活動量の初期日付
    this.activityPeriod = this.defaults.chartDisplayRange;
    this.activeDailyChartPeriod = 7;
    // 活動量推移チャート
    this.activityStackedBarChart = new ActivityStackedBarChart('#activity-stacked-bar-chart');
    // 発情指数グラフ
    this.activityRateChart = new ActivityRateChart('#activity-rate-chart');
    // プロット
    this.activityProtFilter = 'all';

    this.milkingAmountToday = null;
    this.milkingAmountYesterday = null;
    this.milkingAmountAverageSevenDays = null;
    this.accountData = {};

    this.cowBleIds = [];
    this.cowCalvingSensors = [];
    this.cowBleId = null;
    this.currentCowBleId = null;
    this.currentCowCalvingSensorNumber = null;

    this.regions = [
      {axis: 'y', start: 0, end: 0, class: 'score-color3', opacity: '0.15'},
      {axis: 'y', start: 0, end: 0, class: 'score-color4', opacity: '0.15'},
      {axis: 'y', start: 0, end: 0, class: 'score-color5', opacity: '0.15'}
    ];

    this.selection = {};

    this.summaryDateColumns = [];
    this.summaryBudgetType = 'all';
    this.activityBudgetType = 'all';

    this.CowEventsService.selectedCowId = this.$stateParams.cowId;
    this.currentTermDiseasesMap = {
      mastitis: {date: 0, id: ''},
      lame: {date: 0, id: ''},
      breedingDifficulty: {date: 0, id: ''},
      perinatalDifficulty: {date: 0, id: ''},
      injury: {date: 0, id: ''},
      infect: {date: 0, id: ''},
      otherDifficulty: {date: 0, id: ''}
    };

    // タイムラインチャート用処理
    this.timelineChartType = '';
    this.selection.timelineChartTypes = [];

    this.deregisters.push(this.$rootScope.$on('cowDetail:refreshCowInfo', (ev, data) => {
      ev.stopPropagation();
      if (data && Number(data.cowId) !== Number(this.cowId)) {
        return;
      }
      this.updateCowValues(data);
      this.setDisplayOptionFlags(this.farmKind, data.state);
      this.setDates();
      this.setDisplay();
      this.setBasicInfo();
      this.setTabs();
    }));

    this.deregisters.push(this.$rootScope.$on('cowDetail:refreshHistories', (ev, data) => {
      this.hasEvents = data.some((event) => {
        if (event.eventType === 'alert' || event.eventType === 'shukkateishikaijo') {
          return false;
        }
        return true;
      });
      ev.stopPropagation();
      data.filter((event) => this.c && this.c.latestCalvingDate ?
        Number(this.c.latestCalvingDate) <= Number(event.occurredAt) : true)
        .filter((event) => EventType.isTreatmentForLegacy(event.eventType))
        .forEach((event) => {
          if (this.currentTermDiseasesMap[event.eventType].date === 0 ||
            Number(this.currentTermDiseasesMap[event.eventType].date) < Number(event.occurredAt)
          ) {
            this.currentTermDiseasesMap[event.eventType] = {
              date: Number(event.occurredAt),
              id: event.id
            };
          }
        });

      // データタブ表示中 & timeline起動済みの場合、タイムライングラフのデータを更新
      if (this.activeTab === 'dataTab' && this.showTimelineChart && this.isTimelineInitialized) {
        this.showTimelineChart = false;
        // インターバルを設けないとAngularJSが反応しません！
        this.$interval(() => {
          this.showTimelineChart = true;
        }, 1);
      }
      this.isTimelineInitialized = true;

      this.setBasicInfo();
      this.setTabs();

      // 発情グラフに種付日を表示する
      const lines = data.filter((item) => {
        return item.eventType === 'tanetsuke' && item.tanetsukeMethod === '人工授精';
      }).map((item) => {
        const startOfDay = DateUtil.startOfDay(item.occurredAt);
        const hours = new Date(item.occurredAt).getHours();
        const minutes = new Date(item.occurredAt).getMinutes();
        const value = minutes < 30 ? DateUtil.toMSec(DateUtil.addHours(startOfDay, hours)) :
          DateUtil.toMSec(DateUtil.addHours(startOfDay, hours + 1));

        return {
          text: '種付日',
          class: 'breeding-date',
          value
        };
      });

      this.activityRateChart.updateGridXLines(lines);
    }));

    this.hasEvents = false;

    this.replaceBlank = this.UtilService.replaceBlank;

    // ウィンドウリサイズイベント
    $window.onresize = () => {
      if (this.activeTab === 'summaryTab' || this.activeTab === 'activityTab' ) {
        return this.updateTimeBudget(this.activeTab);
      }
      this.forcedRedrawChart();
    };

    this.onInit();
  }

  $onDestroy() {
    this.deregisters.forEach((d) => d());
    this.intervals.forEach((i) => this.$interval.cancel(i));
    this.$window.onresize = null;
    if (this.activityScatterPlotChart) {
      this.activityScatterPlotChart.destroy();
    }
    if (this.activityLyingScatterPlotChart) {
      this.activityLyingScatterPlotChart.destroy();
    }
    if (this.activityStackedBarChart) {
      this.activityStackedBarChart.destroy();
    }
    if (this.summaryActivityRateChart) {
      this.summaryActivityRateChart.destroy();
    }
    if (this.activityRateChart) {
      this.activityRateChart.destroy();
    }
  }

  $onInit() {
    this.caller = this.callbackParam || {state: this.$state.current.name, params: {}};
    if (this.umPagerConfig) {
      const caller = this.umPagerConfig.caller || {};
      if (caller.name) {
        this.listName = this.umPagerConfig.caller.name;
        this.showReturnLink = true;
        return;
      }
    }

    // バックグラウンドクリックにより設定ボードを閉じる
    setTimeout(() => {
      this.$document.find('.uHeading__settings').click((e) => e.stopPropagation());
      this.deregisters.push(this.$rootScope.$on('onClickUmBackdrop', () => {
        this.$timeout(() => this.toggleSettingsBoard(false));
      }));
    });
  }

  forcedRedrawChart() {
    [
      'showMilkingChart',
      'showActivitySummaryChart',
      'showTimelineChart',
    ].forEach((f) => {
      if (this[f]) {
        this[f] = false;
        this[f] = true;
      }
    });
  }

  // 個体データタブ用
  // 個別登録より遷移時にはタイムライン描画を待ってから
  // イベント選択画面の表示制御を行う
  activeEntryEventInit() {
    if (this.$stateParams.activeEntryEvent) {
      this.$interval.cancel(interval);
      const interval = this.$interval(() => {
        let display = $('#box-history').css('display');
        if (display === 'block') {
          this.$interval.cancel(interval);
          $('#box-history').addClass('on-event-input');
          $('#event-input01').fadeIn('3000');
          $('#timeline-cow .changeBackgroundColor').css({backgroundColor: this.Colors.green});
          $('#timeline-cow .box-history-innner').css({border: '4px solid ' + this.Colors.green});
        }
      }, 100);
      this.intervals.push(interval);
    }
  }

  onInit() {
    this.isDateInitialized = false;
    this.isTimelineInitialized = false;

    // 画面起動時、表示タブを判断する。
    this.activeTab = this.defaults.displayTab;

    // データ取得＆整理
    this.$q.all([
      this.MasterMarketService.available(),
      this.CowService.get(this.$stateParams.cowId),
      this.FarmService.show(),
      this.updateSensorIds(),
      this.FatteningStageService.cow(this.$stateParams.cowId),
      this.updateCalvingSensorNumbers(),
    ]).then((res) => {
      this.masterMarkets = res[0].data;
      this.updateCowValues(res[1].data);
      this.updateAccValues(res[2]);
      this.fatteningStage = res[4].data;

      this.setDisplayOptionFlags(this.farmKind, this.c.state);
      this.setDisplay();
      this.setBasicInfo();
      this.setTabs();
      this.setPager();

      this.openCalvingSensorAlert();

      const timeBudgetFunc = this.decideTimeBudgetFunc(this.now);

      this.setDates().then(() => this.$q.all([
        timeBudgetFunc(this.baseDate),
        this.createActivityTimelineLatestData(this.baseDate),
        this.createActiveDrinkingDailyData(this.baseDate),
        this.getMilkAmountData(),
      ])).then((resolves) => {
        this.activeTimeBudgetData.summary = resolves[0];
        this.activeTimeBudgetData.activity = resolves[0];
        this.summaryActivityTimelineData = resolves[1];
        this.activeDrinkingDailyData.summary = resolves[2];
        this.activeDrinkingDailyData.activity = resolves[2];
      }).catch((resolves) => {
        this.activeTimeBudgetData.summary = resolves[0];
        this.activeTimeBudgetData.activity = resolves[0];
        this.summaryActivityTimelineData = resolves[1];
        this.activeDrinkingDailyData.summary = resolves[2];
        this.activeDrinkingDailyData.activity = resolves[2];

      }).finally(() => {
        this.chartInit.summaryTimeBudgetChart = true;
        this.chartInit.summaryActivityRateChart = true;
        this.chartInit.activityTimeBudgetChart = true;

        // 活動量タブの発情チャートデータ
        this.createActivityTimelineSeriesData(this.baseDate).then((resolve) => this.activityTimelineData = resolve)
          .finally(() => this.chartInit.activityRateChart = true);

        // 活動量タブの24時間飲水回数データ
        this.createActiveDrinking24hoursData(this.now).then((resolve) => this.activeDrinking24hoursData = resolve)
          .finally(() => {});

        // 活動量タブの活動量チャートデータ
        if (this.cowBleId) {
          this.ChartGenerator.activityChart(
            this.baseDate,
            this.c.cowId,
            this.activityPeriod || this.defaults.chartDisplayRange,
            this.useCalfManagement,
            this.c.birthday
          )
            .then((resolve) => {
              this.activityStackedBarChartData = resolve;
            })
            .finally(() => this.chartInit.activityStackedBarChart = true);
        }

        // 活動量タブのプロットチャートデータ
        this.createActivityScatterPlotChartData().then((resolve) => this.activityScatterPlotChartData = resolve)
          .finally(() => this.chartInit.activityScatterPlotChart = true);
      });
    });

    // タブ＆チャートをレンダーする
    this.changeTab(this.activeTab);
  }

  getMilkAmountData() {
    let params = {
      cowId: this.c.cowId,
      startDate: this.DateUtilService.toW3CFormat(minusDays(this.now, 7)),
      endDate: this.DateUtilService.toW3CFormat(this.now),
      milkingOrder: 0
    };

    let milkAmountDateList = [];
    for (let i = 0; i < 8; i++) {
      milkAmountDateList.push(this.DateUtilService.toW3CFormat(minusDays(this.now, i)));
    }

    this.MilkingService.getDailySummaryByCowId(params).then((result) =>{
      result = result || {};
      result.data = result.data || [];
      let total = 0;
      if (result.data.length > 0) {
        result.data.forEach((milkObj, index) => {
          let date = this.DateUtilService.toW3CFormat(milkObj.date);
          let amount = Number(milkObj.amount);
          if (milkAmountDateList[0] === date) {
            this.milkingAmountToday = Math.floor(amount);
          } else if (milkAmountDateList[1] === date) {
            this.milkingAmountYesterday = Math.floor(amount);
            total += amount;
          } else {
            total += amount;
          }
        });
        this.milkingAmountAverageSevenDays = Math.floor(total / 7);
        this.attension = this.milkingAmountToday < this.milkingAmountAverageSevenDays;
      }
    });
  }

  openRestartShipment(targetShipmentKind) {
    let endDate = null;
    if (targetShipmentKind === 'milk') {
      endDate = Number(this.c.endDateOfMilkWashoutPeriod);
    } else {
      endDate = Number(this.c.endDateOfBeefWashoutPeriod);
    }

    this.$modal.open({
      templateUrl: 'history/restartShipment/entry.html',
      controller: 'RestartShipmentController',
      controllerAs: 'ctrl',
      size: 'm',
      resolve: {
        params: () => ({
          cowId: this.c.cowId,
          targetShipmentKind: targetShipmentKind,
          endDate: endDate
        }),
      },
    }).result.then(() => this.CowService.get(this.$stateParams.cowId)
      .then((res) => this.updateCowValues(res.data)));
  }

  openUpdateCowLabels() {
    this.$modal.open({
      templateUrl: 'components/dialog/cow-label/index.html',
      controller: 'CowLabelDialogController',
      controllerAs: 'ctrl',
      size: 'm',
      resolve: {
        params: () => ({
          cowId: this.c.cowId
        }),
      },
    }).result.then(() => this.CowService.get(this.$stateParams.cowId)
      .then((res) => this.updateCowValues(res.data)));
  }

  countDays(date) {
    if (!date) return '';
    return this.DateUtilService.countDays(Number(date), new Date().getTime()) + '日';
  }

  diffDays(date, unit = '日') {
    if (!date) return '';
    return this.DateUtilService.diffDays(Number(date), new Date().getTime()) + unit;
  }

  plusDays(date) {
    let epoch = plusDays(date, 280);
    return epoch || '';
  }

  unpregnantDays(start, end) {
    if (start) {
      return (Number(start) < Number(end)) ?
        passedByStartAndEnd(start, end) :
        passedByStartAndEnd(start, this.now.getTime());
    }
  }

  toCowLabelList(cowLabels) {
    cowLabels = cowLabels || '';
    let characters = ['[', ']'], list = [];
    _.each(cowLabels.split(','), (label) => {
      if (label) list.push({text: _.trim(label, characters)});
    });
    return list;
  }

  getIdForSubString(id, start, end) {
    return id ? String(id).substr(start, end) : '';
  }

  /**
   * 活動量タブ(チャート表示期間を変更する。)
   *
   * @param {number} period 表示日数
   */
  changePeriodOfActivity(period) {
    this.DetailsStateFactory.set('chartDisplayRange', period);
    this.activityPeriod = period;
    this.setActivitySelectableRange();

    let target = this.activityDate;
    this.redrawChartsOfActivity(target);
  }

  setActivitySelectableRange(currentDate = null) {
    const date = currentDate || DateUtil.today();
    const isHourlySummary = this.activityPeriod === 7;
    this.minActivityDate = Activity.selectableMinimumDate(
      date,
      isHourlySummary,
      this.cowBleStartDate
    );

    if (DateUtil.isSameOrBeforeDay(this.activityDate, this.minActivityDate)) {
      this.activityDate = DateUtil.toDate(this.minActivityDate);
    }
  }

  /**
   * 活動量系のチャートを再描画する。
   *
   * @param {Date} targetDate 基準日
   */
  redrawChartsOfActivity(targetDate) {
    this.$q.all([
      this.createActivityTimelineSeriesData(targetDate),
      this.ChartGenerator.activityChart(
        targetDate,
        this.c.cowId,
        this.activityPeriod || this.defaults.chartDisplayRange,
        this.useCalfManagement,
        this.c.birthday
      )
    ]).then((resolves) =>{
      this.activityTimelineData = resolves[0];
      this.activityStackedBarChartData = resolves[1];
      this.updateActivityRateChart('activityTab');
      this.updateActivityStackedBarChart();
    });
  }

  // 牛個体削除(ヘッダー)
  delete(id) {
    if (this.hasEvents) {
      this.openHasEventsModal();
    } else if (Cow.isUMCreatedCow(this.c)) {
      this.openUMCreatedCowModal();
    } else if (this.hasSensors()) {
      this.openHasSensorsModal();
    } else {
      const modalInstance = this.openConfirmModal();
      modalInstance.result.then((modalResult) => {
        if (!modalResult) return;
        this.blockUI.start('削除中');
        this.CowService.delete(id).then(() => {
          this.blockUI.stop();
          this.$state.go('top');
        }).catch((err) => console.error(err));
      });
    }
  }

  openConfirmModal() {
    return this.StandardDialog.showDeleteConfirm({
      title: '個体情報の削除',
      text1: '牛個体情報',
    });
  }

  openHasEventsModal() {
    this.StandardDialog.showMessage({
      title: '個体情報の削除',
      text1: 'この牛個体にはイベントが登録されています。',
      text2: 'そのため、牛個体を削除することはできません。',
    });
  }

  openUMCreatedCowModal() {
    this.StandardDialog.showMessage({
      title: '個体情報の削除',
      text1: 'この牛個体は母牛の分娩イベントより作成されたため、削除することができません。'
    });
  }

  openHasSensorsModal() {
    this.StandardDialog.showMessage({
      title: '個体情報の削除',
      text1: 'この牛個体にはセンサー番号が適用されているか、過去に適用した履歴が存在します。',
      text2: 'そのため、牛個体を削除することはできません。'
    });
  }

  /**
   * サマリー、活動量タブ共用
   * @param {string} selector summaryTimeBudget || activityTimeBudget
   */
  changeTimeBudget(selector) {
    // TimeBudgetを削除 -> summary || activity
    const type = selector.substring(0, selector.length - 10);

    // データがなければ何もしない
    if (_.isEmpty(this.activeTimeBudgetData[type])) {
      return;
    }

    // チャート描画
    return this.timeBudgetChart[type].selectShow(this[`${type}BudgetType`], selector, this.activeDrinkingDailyData[type].length);
  }

  getIntervalDate(targetDate) {
    const toDateString = (date, delimit) => {
      delimit = delimit || '';

      return String(date.getFullYear()) + delimit +
        String(('0' + (date.getMonth() + 1 )).slice(-2)) + delimit +
        String(('0' + date.getDate()).slice(-2));
    };

    const dateObj = {};
    const endDate = new Date(toDateString(targetDate, '-'));
    endDate.setDate(endDate.getDate() + 1);
    dateObj.startDate = {urlParam: toDateString(targetDate), format: toDateString(targetDate, '-')};
    dateObj.endDate = {urlParam: toDateString(endDate), format: toDateString(endDate, '-')};
    return dateObj;
  }

  exceedsTimeBudgetDataPeriod() {
    const date = this.c.eliminateDate || this.c.fallingDeadDate;
    return date && DateUtil.diffDays(parseInt(date), DateUtil.today()) > this.TIMEBUDGET_DATA_PERIOD;
  }

  createActiveTimeBudgetData(targetDate, retreat) {
    if (!this.cowBleId || this.exceedsTimeBudgetDataPeriod()) {
      const deferred = this.$q.defer();
      deferred.resolve([]);
      return deferred.promise;
    }

    const dateObj = this.getIntervalDate(targetDate);
    const periods = this.BleIdService.availablePeriods(
      this.cowBleIds,
      dateObj.startDate.urlParam,
      dateObj.endDate.urlParam
    );

    const promises = periods.map((period) => {
      const endDate = period.startDate === period.endDate
        ? this.DateUtilService.toYYYYMMDD(this.DateUtilService.addDays(period.endDate, 1), '')
        : period.endDate;
      return this.ActiveAmountService.getActiveTimelineFindByDate(period.bleId, period.startDate, endDate);
    });
    const feedSourceTypePromise =
      this.ActiveAmountService.getDeprecatedFeedSourceType(this.cowId, this.DateUtilService.toW3CFormat(targetDate));
    promises.unshift(feedSourceTypePromise);

    return this.$q.all(promises).then((res) => {
      const feedSourceType = res[0].data['feed_source_type'];
      // プロミス結果を合算
      return res.slice(1).reduce((a, b) => a.concat('data' in b ? b.data : []), [])
        .sort((a, b) => Number(a.datehourmin) - Number(b.datehourmin)) // 日付順にソート
        .map((record) => {
          const endDate = DateUtil.dateMinuteToDate(record.datehourmin);
          const showCalfPeriodChart = this.useCalfManagement && Cow.isCalfPeriod(this.c.birthday, endDate);

          if (record.status === 'run' || record.status === 'walk') { // 走る/歩くを動態に変更
            record.status = 'move';
          } else if (showCalfPeriodChart && ['feed', 'feed_acc', 'walk_acc'].includes(record.status)) {
            record.status = 'move';
          } else if (record.status === 'feed_acc') {
            record.status = feedSourceType === 'acc' ? 'feed' : 'move';
          } else if (record.status === 'walk_acc') {
            record.status = feedSourceType === 'acc' ? 'move' : 'feed';
          }

          return record;
        });
    }).catch(() => []);
  }

  createActiveTimeBudgetDataEartag(targetDate, retreat) {
    if (!this.cowBleId || this.exceedsTimeBudgetDataPeriod()) {
      const deferred = this.$q.defer();
      deferred.resolve([]);
      return deferred.promise;
    }

    const dateObj = this.getIntervalDate(targetDate);
    const periods = this.BleIdService.availablePeriods(
      this.cowBleIds,
      dateObj.startDate.urlParam,
      dateObj.endDate.urlParam
    );

    const promises = periods.map((period) => {
      return this.ActiveAmountService.getEartagActivityStatusIn10minutes(period.bleId, period.startDate);
    });

    return this.$q.all(promises).then((res) => {
      return res[0].data.map((record) => {
        const endDate = DateUtil.dateMinuteToDate(record.datehourmin);
        const showCalfPeriodChart = this.useCalfManagement && Cow.isCalfPeriod(this.c.birthday, endDate);

        if (showCalfPeriodChart && ['feed'].includes(record.status)) {
          record.status = 'move';
        }

        return record;
      });
    }).catch(() => []);
  }

  // 現在時刻から72時間遡った発情データ取得
  createActivityTimelineLatestData(targetDate, retreat) {
    if (!this.cowBleId) {
      const deferred = this.$q.defer();
      deferred.resolve([]);
      return deferred.promise;
    }

    let dateObj = {datehour: {}};

    dateObj.endAt = moment(targetDate).add(1, 'day').hour(0).minutes(0).seconds(0).milliseconds(0).valueOf();
    dateObj.startAt = moment(dateObj.endAt).add(-3, 'd').valueOf();

    const oneHourInMSec = 3600000;
    for (let dateHour = dateObj.startAt; dateHour <= dateObj.endAt;) {
      dateObj.datehour[getDateHourForApiParam(dateHour)] = null;
      dateHour = dateHour + oneHourInMSec;
    }

    const c3ChartData = (data) => {
      let chartData = {x: [], y: []};

      _.each(data, (hourData) => {
        dateObj.datehour[getDateHourForApiParam(hourData.datehour)] = hourData.heatValue || 0;
      });
      _.map(dateObj.datehour, function(num, datehour) {
        chartData.x.push(moment(String(datehour).substr(0, 8)).add(Number(String(datehour).substr(8, 2)), 'h').toDate());
        chartData.y.push(num);
      });

      chartData.x.unshift('x');
      chartData.y.unshift('発情指数');
      return chartData;
    };

    if (!this.cowBleId) {
      deferred.resolve([]);
      return deferred.promise;
    }

    return this.HeatIndexAPI.hourlySummary(this.c.cowId, dateObj.startAt, dateObj.endAt).then((res) => {
      if (('data' in res) && res.data.length) {
        return c3ChartData(res.data);
      }
      return [];
    }).catch((res) => []);
  }

  /**
   * 活動量タブ(基準日の発情データを取得する。)
   *
   * @param {Date} targetDate 基準日
   * @return {Object} promise
   */
  createActivityTimelineSeriesData(targetDate) {
    const days = this.activityPeriod || this.defaults.chartDisplayRange;
    return this.ChartGenerator.heatIndexChart(targetDate, this.c.cowId, days);
  }

  /**
   * 活動量タブ(現在時刻から過去24時間の飲水回数を取得)
   *
   * @param {Date} targetDate 現在時刻
   * @return {Object} promise
   */
  createActiveDrinking24hoursData(targetDate) {
    const endAt = moment(targetDate).minutes(0).seconds(0)
      .milliseconds(0).valueOf();
    const startAt = moment(endAt).add(-24, 'h').valueOf();

    return this.ActiveAmountService.getDrinkingFindByDatehour(this.c.cowId, startAt, endAt)
      .then((res) => 'data' in res && res.data.length ? res.data[0] : [])
      .catch(() => []);
  }

  /**
   * タイムバジェットで表示している日付の飲水回数を取得(活動量 & サマリー)
   *
   * @param {Date} targetDate タイムバジェット表示日付
   * @return {Object} promise
   */
  createActiveDrinkingDailyData(targetDate) {
    const startDate = moment(targetDate)
      .startOf('day')
      .valueOf();
    const endDate = moment(targetDate)
      .endOf('day')
      .valueOf();

    return this.ActiveAmountService.getDailyDrinkingTimes(this.c.cowId, startDate, endDate)
      .then((res) => 'data' in res ? res.data : [])
      .catch(() => []);
  }

  /**
   * 活動量タブ(プロット用データを取得する。)
   *
   * @return {Object} promise
   */
  createActivityScatterPlotChartData() {
    if (!this.cowBleId) {
      const deferred = this.$q.defer();
      deferred.resolve([]);
      return deferred.promise;
    }

    const params = {
      cowId: this.c.cowId,
    };

    if (this.protDate && this.protDate.getTime() !== DateUtil.today().getTime() ) {
      params['date'] = DateUtil.toW3CFormat(this.protDate);
    }
    if (this.activityProtFilter !== 'all') {
      params['date_condition'] = this.activityProtFilter;
    }

    if (this.activityScatterPlotChart) {
      this.activityScatterPlotChart.destroy();
    }

    this.showCalfPeriodScatterPlot = this.useCalfManagement && Cow.isCalfPeriod(this.c.birthday, this.protDate);

    if (this.showCalfPeriodScatterPlot) {
      this.activityScatterPlotChart = new CalfPeriodActivityScatterPlotChart('#activity-scatter-plot-chart', this.Dictionary);
      this.activityScatterPlotChart.afterClickEventHandler = (cowId) => {
        this.$state.go('cowDetail', {cowId: cowId});
      };

      return this.ChartActivityScatterPlotAPI.calf(params)
        .then((res) => 'data' in res && Object.keys(res.data).length ? res.data : [])
        .catch(() => []);
    } else {
      if (this.activityLyingScatterPlotChart) {
        this.activityLyingScatterPlotChart.destroy();
      }

      this.activityScatterPlotChart = new ActivityScatterPlotChart('#activity-scatter-plot-chart', this.Dictionary);
      this.activityLyingScatterPlotChart = new ActivityLyingScatterPlotChart('#activity-lying-scatter-plot-chart', this.Dictionary);

      [this.activityScatterPlotChart, this.activityLyingScatterPlotChart].forEach((chart) => {
        chart.afterClickEventHandler = (cowId) => {
          this.$state.go('cowDetail', {cowId: cowId});
        };
      });

      return this.ChartActivityScatterPlotAPI.show(params)
        .then((res) => 'data' in res && Object.keys(res.data).length ? res.data : [])
        .catch(() => []);
    }
  }

  reloadActivityProtData() {
    this.createActivityScatterPlotChartData()
      .then((resolve) => this.activityScatterPlotChartData = resolve)
      .then((resolve) => this.updateActivityScatterPlotChart())
      .finally(() => this.chartInit.activityScatterPlotChart = true);
  }

  activityProtGroupCaption() {
    if (this.activityScatterPlotChartData) {
      return this.activityScatterPlotChartData.groupCaption;
    }
    return '';
  }

  /**
   * タイムバジェットチャートを描画する
   * @param {string} tabName summaryTab || activityTab
   */
  updateTimeBudget(tabName) {
    // 'Tab'を削除 -> summary || activity
    const tab = tabName.substring(0, tabName.length - 3);

    // テンプレート生成完了してないと何もしない
    if (!$(`#${tab}TimeBudget`).length) {
      return;
    }

    this.$interval.cancel(interval);
    const interval = this.$interval(() => {
      // 既存のチャートを削除
      if (this.timeBudgetChart[tab]) this.timeBudgetChart[tab].clear(tab);
      this.timeBudgetChart[tab] = undefined;

      // データ取得完了まで無限ループ
      if (!this.chartInit[`${tab}TimeBudgetChart`]) {
        return;
      }

      // データが空の場合描画せず終了
      if (!this.activeTimeBudgetData[tab].length) {
        return this.$interval.cancel(interval);
      }

      // チャート描画
      this.timeBudgetChart[tab] = new TimeBudget(`${tab}TimeBudget`, this.showFeed, this.showDrink);
      this.timeBudgetChart[tab].show(
        this.activeTimeBudgetData[tab],
        this[`${tab}BudgetType`],
        `${tab}TimeBudget`,
        this.activeDrinkingDailyData[tab]
      );

      // 無限ループ終了
      this.$interval.cancel(interval);
    }, this.chartInterval);
    this.intervals.push(interval);

  }

  /**
   *  サマリー、活動量タブ両用
   *
   * @param {string} tabName summaryTab or activityTab
   */
  updateActivityRateChart(tabName) {

    let columns = [];
    this.regions[0].start = Number(this.accountData.thresholdActivityRate2 || this.accountInfo.thresholdActivityRate2);
    this.regions[0].end = Number(this.accountData.thresholdActivityRate3 || this.accountInfo.thresholdActivityRate3);
    this.regions[1].start = Number(this.accountData.thresholdActivityRate3 || this.accountInfo.thresholdActivityRate3);
    this.regions[1].end = Number(this.accountData.thresholdActivityRate4 || this.accountInfo.thresholdActivityRate4);
    this.regions[2].start = Number(this.accountData.thresholdActivityRate4 || this.accountInfo.thresholdActivityRate4);
    this.regions[2].end = Number(this.accountData.thresholdActivityRate5 || this.accountInfo.thresholdActivityRate5);

    if (tabName === 'summaryTab') {
      this.summaryActivityRateChart.clear();

      this.$interval.cancel(interval);
      const interval = this.$interval(() => {
        if (this.chartInit.summaryActivityRateChart) {
          if (!_.isEmpty(this.summaryActivityTimelineData)) {
            columns.push(this.summaryActivityTimelineData.x);
            columns.push(this.summaryActivityTimelineData.y);
            this.summaryActivityRateChart.show(columns, this.regions);
          }
          this.$interval.cancel(interval);
        }
      }, this.chartInterval);
      this.intervals.push(interval);
    } else if (tabName === 'activityTab') {
      this.activityRateChart.clear();

      this.$interval.cancel(interval);
      const interval = this.$interval(() => {
        if (this.chartInit.activityRateChart) {
          if (!_.isEmpty(this.activityTimelineData)) {
            columns.push(this.activityTimelineData.x);
            columns.push(this.activityTimelineData.y);
            const kind = this.activityPeriod === 7 ?
              ActivityRateChart.KIND.DATE_TIME : ActivityRateChart.KIND.DATE;
            this.activityRateChart.show(columns, this.regions, kind);
          }
          this.$interval.cancel(interval);
        }
      }, this.chartInterval);
      this.intervals.push(interval);

    }
  }

  /**
   *  活動量タブ(活動量積み上げ棒グラフ)
   */
  updateActivityStackedBarChart() {
    // 初期化
    this.activityStackedBarChart.clear();

    this.$interval.cancel(interval);
    const interval = this.$interval(() => {
      if (!this.chartInit.activityStackedBarChart) return;

      if (!_.isEmpty(this.activityStackedBarChartData)) {
        const kind = this.activityPeriod === 7 ?
          ActivityStackedBarChart.KIND.DATE_TIME : ActivityStackedBarChart.KIND.DATE;
        this.activityStackedBarChart.show(this.activityStackedBarChartData, kind);

        const hiddenLegendIds = this.DetailsStateFactory.get().hiddenLegendIds;
        const updateHiddenLegendIds = ({ ids }) => {
          this.DetailsStateFactory.set('hiddenLegendIds', ids);
        }

        this.activityStackedBarChart.onClickLegend(updateHiddenLegendIds);
        this.activityStackedBarChart.toggle(hiddenLegendIds);
      }
      this.$interval.cancel(interval);
    }, this.chartInterval);
    this.intervals.push(interval);
  }

  /**
   *  活動量タブ(プロットグラフ[総活動時間,総反芻時間])
   */
  updateActivityScatterPlotChart() {
    // 初期化
    if (this.activityScatterPlotChart) {
      this.activityScatterPlotChart.clear();
    }

    if (this.activityLyingScatterPlotChart) {
      this.activityLyingScatterPlotChart.clear();
    }

    this.$interval.cancel(interval);
    const interval = this.$interval(() => {
      if (!this.chartInit.activityScatterPlotChart) return;

      if (!_.isEmpty(this.activityScatterPlotChartData)) {
        this.activityScatterPlotChart.show(this.activityScatterPlotChartData);

        if (!this.showCalfPeriodScatterPlot) {
          this.activityLyingScatterPlotChart.show(this.activityScatterPlotChartData);
        }
      }
      this.$interval.cancel(interval);
    }, this.chartInterval);
    this.intervals.push(interval);
  }

  /**
   * 活動量タブ(活動量の表示期間を変更する。)
   *
   * @param {string} retreat prev or next
   */
  moveRangeActivity(retreat) {
    const target = new Date(this.activityDate);
    if (retreat === 'prev') {
      target.setDate(target.getDate() - this.activityPeriod);
    } else {
      target.setDate(target.getDate() + this.activityPeriod);
    }
    this.activityDate = target;

    this.redrawChartsOfActivity(target);
  }

  /**
   * 活動量タブ(カレンダーから活動量の表示期間を変更する。)
   */
  changeRangeActivityFromCalendar() {
    let target = this.activityDate;
    this.redrawChartsOfActivity(target);
  }

  /**
   * 活動量タブ(タイムバジェットの表示期間を変更する。)
   *
   * @param {string} retreat prev or next
   */
  moveRangeBudget(retreat) {
    let target = new Date(Number(this.selectedBudgetDate));
    if (retreat === 'prev') {
      target.setDate(target.getDate() - 1);
    } else if (retreat === 'next') {
      target.setDate(target.getDate() + 1);
    }

    this.selectedBudgetDate = target.valueOf();

    const timeBudgetFunc = this.decideTimeBudgetFunc(target);

    this.$q.all([
      timeBudgetFunc(target, retreat),
      this.createActiveDrinkingDailyData(target)
    ]).then((resolves) =>{
      this.activeTimeBudgetData.activity = resolves[0];
      this.activeDrinkingDailyData.activity = resolves[1];
      this.updateTimeBudget('activityTab');
    });
  }

  decideTimeBudgetFunc(targetDate) {
    const dateObj = this.getIntervalDate(targetDate);
    const periods = this.BleIdService.availablePeriods(
      this.cowBleIds,
      dateObj.startDate.urlParam,
      dateObj.endDate.urlParam
    );

    if (periods[0] && periods[0].sensorType === 1) {
      return this.createActiveTimeBudgetDataEartag.bind(this);
    }

    return this.createActiveTimeBudgetData.bind(this);
  }

  shouldDisableTimebudget() {
    return this.minBudgetDate > this.baseDate;
  }

  shouldDisableTimebudgetNextDate() {
    return Number(this.selectedBudgetDate) >= Number(this.baseDate);
  }

  shouldDisableTimebudgetPreviousDate() {
    return Number(this.selectedBudgetDate) <= Number(this.minBudgetDate);
  }

  shouldDisableActivityPreviousDate() {
    return Number(this.activityDate) <= Number(this.minActivityDate);
  }

  shouldDisableActivityNextDate() {
    return Number(this.activityDate) >= Number(this.baseDate);
  }

  shouldDisableProtPreviousDate() {
    return Number(this.protDate) <= Number(this.minProtDate);
  }

  shouldDisableProtNextDate() {
    return Number(this.protDate) >= Number(this.baseDate);
  }

  moveProtDate(retreat) {
    if (retreat === 'prev') {
      this.protDate = DateUtil.addDays(this.protDate, -1);
    } else {
      this.protDate = DateUtil.addDays(this.protDate, 1);
    }
    this.displayProtDate();
    this.reloadActivityProtData();
  }

  displayProtDate() {
    this.protDateCaption = DateUtil.toYYYYMMDD(this.protDate);
  }

  /**
   * ページャー先を指定する
   */
  setPager() {
    const cowIds = this.sellectableCowIds ? this.sellectableCowIds : [];

    // 配列がない場合終了
    if (!cowIds || cowIds.length <= 0) {
      return;
    }

    // 配列の中から現個体のID位置を特定
    if (!this.cowIdIndex) {
      this.cowIdIndex = _.findIndex(cowIds, (cowId) => {
        return cowId === this.c.cowId;
      });
    }

    // ペジャー機能
    // 原位置-1 = 前、 +1 = 次
    this.umPagerConfig.prev = cowIds[this.cowIdIndex - 1] || '';
    this.umPagerConfig.next = cowIds[this.cowIdIndex + 1] || '';

    this.umPagerConfig.current = this.cowIdIndex + 1;
    this.umPagerConfig.total = cowIds.length;

    if (this.umPagerConfig.caller.params && this.umPagerConfig.caller.params.config_type
      && this.umPagerConfig.caller.params.config_type.includes('group')) {
      this.umPagerConfig.name = `牛群：${this.umPagerConfig.caller.name}`;
    } else {
      this.umPagerConfig.name = this.umPagerConfig.caller.name;
    }

    this.$rootScope.umPagerConfig = this.umPagerConfig;

    return;
  }

  setTabs() {
    this.tabs = [{
      field: 'summaryTab',
      name: 'サマリー'
    }, {
      field: 'dataTab',
      name: '個体データ'
    }, {
      field: 'reproductionTab',
      name: '繁殖',
      hidden: ![
        '未授精',
        '授精',
        '未受胎(－)',
        '受胎(＋)',
        '乾乳前期',
        '乾乳後期',
        'フレッシュ',
        '繁殖除外'
      ].includes(this.c.state) &&
      !(this.c.birthNumber >= 1 && ['肥育', '出荷', 'へい死'].includes(this.c.state))
    }, {
      field: 'activityTab',
      name: '活動量',
      disabled: !this.cowBleId
    }, {
      field: 'milkingTab',
      name: '搾乳量',
      hidden: !this.isMilkFarm
    }, {
      field: 'dhiTab',
      name: '検定データ',
      hidden: !this.farm || this.farm.data.dhiLinkage === 'none'
    }, {
      field: 'genomTab',
      name: 'ゲノム',
      hidden: !(this.farm.showBeefGenom() && this.c.gender === 'メス')
    }];
  }

  /**
   * タブの切り替え処理
   */
  changeTab(tabName) {
    this.activeTab = tabName;
    this.DetailsStateFactory.set('displayTab', tabName);
    this.$scope.$broadcast('changeTab', tabName);
    // CowAPIの返事を帰ってくる前に、タブ遷移阻止
    this.$interval.cancel(waitForCowApi);
    const waitForCowApi = this.$interval(() => {
      if (!this.c) {
        return;
      }
      this.$interval.cancel(waitForCowApi);

      if (tabName === 'summaryTab') {
        this.updateTimeBudget('summaryTab');
        this.updateActivityRateChart('summaryTab');
        this.showActivitySummaryChart = true;
        this.forcedRedrawChart();
      } else if (tabName === 'activityTab') {
        this.updateTimeBudget('activityTab');
        this.updateActivityRateChart('activityTab');
        this.updateActivityStackedBarChart();
        this.updateActivityScatterPlotChart();
      } else if (tabName === 'milkingTab') {
        this.showMilkingChart = true;
      } else if (tabName === 'dataTab') {
        this.showTimelineChart = true;
      }
    });
    this.intervals.push(waitForCowApi);
  }

  /**
   * APIレスポンスに存在しないデータを足す
   * @param {Object} cow APIから返ってくる生な牛情報
   */
  updateCowValues(cow) {
    const currentDate = today();

    if (cow.breedingBull) {
      cow.breedingBullDisplay = '(種雄牛)';
    }

    this.c = this.dUtil.parseClientDateForCowInfo(cow);
    this.CowEventsService.selectedCow = this.c;

    if (this.c.birthday) {
      const endDate = Number(this.c.eliminateDate) || Number(this.c.fallingDeadDate) || currentDate;
      const monthAge = DateUtil.monthAge(this.c.birthday, endDate, 1);
      const dayAge = DateUtil.diffDays(this.c.birthday, endDate);
      this.c.birthdayMonthAgeSummary = `${DateUtil.toYYMMDD(this.c.birthday)} (${monthAge})`;
      this.c.birthdayMonthAgeData = `${DateUtil.toJapaneseYYYYMMDD(this.c.birthday)}（${monthAge}ヶ月）`;
      this.c.birthdayDayAge = `${DateUtil.toYYMMDD(this.c.birthday)} (${dayAge})`;
      this.c.birthdayDayAgeData = `${DateUtil.toYYYYMMDD(this.c.birthday)} (${dayAge}日)`;
    } else {
      this.c.birthdayMonthAgeSummary = '-';
      this.c.birthdayMonthAgeData = '-';
      this.c.birthdayDayAge = '-';
      this.c.birthdayDayAgeData = '-';
    }

    // TODO 「月齢」ラベルの下に「在籍月数」を表示しているので仕様を確認したうえで対応を検討する
    this.c.introduceMoonAge = this.c.introduceDate ?
      this.DateUtilService.diffMonths(new Date(Number(this.c.introduceDate)), currentDate, 1) : '-';

    this.cowLabelList = this.toCowLabelList(this.c.cowLabels);

    const masterMarket = this.masterMarkets.find((market) => {
      return market.id === this.c.masterMarketId;
    });
    this.c.masterMarketName = masterMarket ? masterMarket.name : '';

    this.setDisplay();
  }

  /**
   * アカウント関連箇所を更新
   * @param {Object} account
   */
  updateAccValues(account) {
    this.farm = new Farm(account);
    this.accountData = account;
    this.farmKind = this.accountData.farmKind;
    this.showFeed = !this.farm.isUMLight();
    this.showDrink = this.farm.isUMFull();
    this.isAgentFarm = this.accountData.agentFarm;
    this.useCalfManagement = this.farm.useCalfManagement();
  }

  /**
   * 牛センサー関連箇所を更新
   */
  updateSensorIds() {
    return this.BleIdService.cowBleIds(this.cowId)
      .then((res) => {
        this.cowBleIds = res.data;
        this.cowBleId = CowBleId.lastCowBleId(this.cowBleIds);
        this.currentCowBleId = CowBleId.currentCowBleId(this.cowBleIds);

        const firstCowBleId = this.cowBleIds.length ? this.cowBleIds[0] : null;

        this.cowBleStartDate = firstCowBleId ? new Date(firstCowBleId.startDate) : null;
      });
  }

  /**
   * 分娩センサー関連箇所を更新
   */
  updateCalvingSensorNumbers() {
    return this.CowCalvingSensorHistoryAPI.list(this.cowId).then((res) => {
      this.cowCalvingSensors = res.data;
      this.currentCowCalvingSensorNumber = CowCalvingSensorHistory.currentCalvingSensorNumber(res.data);
    });
  }

  toBleIdHistory(cow) {
    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'dialog/ble-id-dialog.html',
      controller: 'BleIdDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'modal-select-standard',
      resolve: {
        params: () => {
          return {
            cowId: cow.cowId,
            cowNo: cow.cowNo,
            canDelete: true
          };
        }
      }
    });

    modalInstance.result.then((result) => {
      this.onInit();
    });
  }

  openCowCalvingSensorHistoryDialog(cow) {
    const modalInstance = this.CowCalvingSensorHistoryDialog.open(cow);

    modalInstance.result.then((result) => {
      this.onInit();
    });
  }

  /**
   * チャートデータの存在チェック
   *
   * @param {Array|Object} data チャートデータ
   * @param {boolean} loaded true(チャートデータ読込済) or false(チャートデータ読込中)
   * @param {boolean} multiple true(マルチチャート) default false
   * @return {boolean} true(有) or false(無)
   */
  existsChartData(data, loaded, multiple = false) {
    if (loaded) {
      if (multiple) {
        return data.some((element) => !_.isEmpty(element.data));
      }
      return !_.isEmpty(data);
    }
    return true;
  }

  setDisplay() {
    this.display = {
      cowNo: this.c.cowNo || '-',
      state: this.c.state || '-',
      cowGroupName: this.c.cowGroupName || '-',
      endDateOfMilkWashoutPeriod: `${DateUtil.toYYYYMMDD(Number(this.c.endDateOfMilkWashoutPeriod))}まで`,
      endDateOfBeefWashoutPeriod: `${DateUtil.toYYYYMMDD(Number(this.c.endDateOfBeefWashoutPeriod))}まで`
    };
  }

  setBasicInfo() {
    if (!this.c) return;

    this.basicInfo = [{
      label: '名前',
      value: this.c.cowName || '-',
      hidden: !this.isMeatFarm || !['フレッシュ', '未授精', '授精', '未受胎(－)', '受胎(＋)', '繁殖除外'].includes(this.c.state) || this.farm.useName()
    },
    {
      label: '導入日（月齢）',
      value: this.c.introduceDate ? `${DateUtil.toYYMMDD(Number(this.c.introduceDate))} (${this.c.introduceMoonAge})` : '-',
      hidden: !(this.isMeatFarm && this.isFatteningType)
    }, {
      label: '出生日（月齢）',
      value: this.c.birthdayMonthAgeSummary,
      hidden: !(this.isMeatFarm && !this.isInfant)
    }, {
      label: '出生日（日齢）',
      value: this.c.birthdayDayAge,
      hidden: !(this.isMeatFarm && this.isInfant)
    }, {
      label: '品種',
      value: this.c.breed || '-',
      hidden: !this.isMeatFarm
    }, {
      label: '性別',
      value: this.c.gender || '-',
      hidden: !this.isMeatFarm
    }, {
      label: '産次数',
      value: `${this.c.birthNumber || 0}回`,
      hidden: this.isFatteningType
    }, {
      label: '分娩後日数',
      value: this.c.latestCalvingDate ?
        this.diffDays(this.c.latestCalvingDate) : '-',
      hidden: this.isFatteningType
    }, {
      label: '分娩予定日',
      value: this.c.expectedCalvingDate ?
        `${DateUtil.toYYMMDD(Number(this.c.expectedCalvingDate))}` : '-',
      hidden: this.isFatteningType
    }, {
      label: '空胎日数',
      value: this.c.latestCalvingDate ?
        `${this.unpregnantDays(this.c.latestCalvingDate, this.c.latestPregnancyDate)}日` : '-',
      hidden: this.isFatteningType
    }, {
      label: '授精回数',
      value: `${this.c.latestBreedingCount || 0}回`,
      hidden: this.isFatteningType
    }, {
      label: '初回授精日',
      value: this.c.firstFertilizationDate ?
        `${DateUtil.toYYMMDD(Number(this.c.firstFertilizationDate))}` : '-',
      hidden: this.isFatteningType
    }, {
      label: '授精後日数',
      value: this.c.latestFertilizationDate ?
        this.diffDays(this.c.latestFertilizationDate) : '-',
      hidden: this.isFatteningType
    }, {
      label: '妊娠日数',
      value: this.c.latestPregnancyDate ?
        this.diffDays(this.c.latestPregnancyDate) : '-',
      hidden: this.isFatteningType
    }, {
      label: '乾乳日数',
      value: this.replaceBlank(this.diffDays(this.c.latestDryPreviousPeriodStartDate)),
      hidden: !(!this.isFatteningType && this.isMilkFarm)
    }, {
      label: this.bullNameLabel(),
      value: this.bullName(),
      hidden: !(!this.isFatteningType && this.isMeatFarm)
    }, {
      label: '母牛',
      value: this.farm.useName() ? this.c.motherCowNo : this.c.motherName || '-',
      link: this.shouldShowMotherCowLink() ? {
        to: 'cowDetail',
        params: {
          cowId: this.c.motherCowId,
          caller: {
            state: 'cowDetail'
          }
        }
      } : null,
      hidden: !this.isMeatFarm
    }, {
      label: '父牛',
      value: this.c.fatherName || '-',
      hidden: !this.isMeatFarm
    }, {
      label: '母父牛',
      value: this.c.maternalGrandfatherName || '-',
      hidden: !this.isMeatFarm
    }, {
      label: '母母父牛',
      value: this.c.maternalGreatGrandfatherName || '-',
      hidden: !this.isMeatFarm
    }];
  }

  /**
   * 牧場種類や牛状態による表示フラグの切替
   * @param {string} farmKind 牧場種類
   * @param {string} state 牛状態
   */
  setDisplayOptionFlags(farmKind, state) {
    const endDate = Number(this.c.eliminateDate) || Number(this.c.fallingDeadDate) || DateUtil.today();

    this.isCalfPeriod = Cow.isCalfPeriod(this.c.birthday, endDate);
    this.isMilkFarm = !!(this.FarmKind[farmKind] === 'milk');
    this.isMeatFarm = !!(this.FarmKind[farmKind] === 'meat');
    this.isFatteningType = !!(this.CowState[state] === 'fattening');
    this.showSummaryChart = this.setShowSummaryChart();
    this.showHeatChart = this.setShowHeatChart();
    this.isCalfType = !!(this.CowState[state] === 'calf');
    this.isReproductionType = !!(this.CowState[state] === 'reproduction');
    this.isSoldOrDiedType = !!(this.CowState[state] === 'soldOrDied');
    this.shouldShowCalvingSensorSetting = Cow.canEquipCalvingSensor(this.c, this.currentCowBleId) ||
      !!this.currentCowCalvingSensorNumber;
    this.isInfant = state === '哺育';
    this.showActivityProtFilter = (this.isFatteningType && this.fatteningStage);
  }

  setShowSummaryChart() {
    if (this.useCalfManagement && this.isCalfPeriod) {
      return false;
    }

    return true;
  }

  setShowHeatChart() {
    if (this.useCalfManagement && this.isCalfPeriod) {
      return false;
    }
    return (!this.isFatteningType || (this.c.gender === 'メス' && this.farm.showHeatIndexFattening()));
  }

  setDates() {
    if (this.isSoldOrDiedType) {
      const eliminateDate = this.c.eliminateDate ? new Date(Number(this.c.eliminateDate)) : null;
      const fallingDeadDate = this.c.fallingDeadDate ? new Date(Number(this.c.fallingDeadDate)) : null;

      this.baseDate = eliminateDate || fallingDeadDate || this.now;
      this.baseDate = DateUtil.startOfDay(this.baseDate);
    } else {
      this.baseDate = DateUtil.startOfDay(this.now);
    }

    this.minBudgetDate = new Date(DateUtil.addYears(this.baseDate, -1));
    if (Number(this.minBudgetDate) < Number(this.cowBleStartDate)) {
      this.minBudgetDate = this.cowBleStartDate;
    }
    this.minActivityDate = this.minProtDate = new Date(Number(this.cowBleStartDate));

    return new Promise((resolve) => {
      setTimeout(() => {
        // 日付フィールドに変更が反映されない問題対策でsetTimeout
        this.selectedBudgetDate
          = this.activityDate
          = this.protDate
          = this.baseDate;

        this.displayProtDate();
        this.isDateInitialized = true;
        resolve();
      });
    });
  }

  /**
   * サブメニュー開閉トグル機能
   *  パラメーターはmouseOut時のみ使用、それ以外はただのboolスイッチ
   * @param {bool} close T = 閉じ、 F = 閉まる
   */
  toggleSubcontrol(close) {
    this.subControlIsOpen = close ? false : !this.subControlIsOpen;
  }

  shouldShowTimeBudgetDetail() {
    return this.showHeatChart &&
      this.existsChartData(this.activeTimeBudgetData.activity, this.chartInit.activityTimeBudgetChart);
  }

  shouldShowMotherCowLink() {
    return this.c.motherCowId ? true : false;
  }

  shouldShowStopShipment() {
    if (this.farm.stopShipmentDisplay() === 'none') {
      return false;
    }

    return this.shouldShowStopShipmentMilking() || this.shouldShowStopShipmentBeef();
  }

  shouldShowStopShipmentMilking() {
    if (!this.c) return false;
    if (!this.isMilkFarm) return false;
    if (!this.c.endDateOfMilkWashoutPeriod) return false;

    switch (this.farm.stopShipmentDisplay()) {
    case 'default': {
      return this.c.stopShipmentMilkingFlg;
    }
    case 'period': {
      const endDate = DateUtil.addDays(
        Number(this.c.endDateOfMilkWashoutPeriod),
        this.farm.stopShipmentDisplayPeriod()
      );
      return DateUtil.isSameOrAfterDay(endDate, DateUtil.today());
    }
    default: {
      return false;
    }
    }
  }

  shouldShowStopShipmentBeef() {
    if (!this.c) return false;
    if (!this.c.endDateOfBeefWashoutPeriod) return false;

    switch (this.farm.stopShipmentDisplay()) {
    case 'default': {
      return this.c.stopShipmentBeefFlg;
    }
    case 'period': {
      const endDate = DateUtil.addDays(
        Number(this.c.endDateOfBeefWashoutPeriod),
        this.farm.stopShipmentDisplayPeriod()
      );
      return DateUtil.isSameOrAfterDay(endDate, DateUtil.today());
    }
    default: {
      return false;
    }
    }
  }

  shouldShowPager() {
    return this.showReturnLink && this.umPagerConfig.total;
  }

  /**
   * 種雄牛フィールドの名前
   */
  bullNameLabel() {
    return this.c.latestPregnancyDate ? '種雄牛名' : '最新種雄牛名';
  }

  /**
   * 種雄牛の名前
   */
  bullName() {
    return (this.c.latestPregnancyDate ? this.c.bullName : this.c.latestBullName) || '-';
  }

  title() {
    return this.Dictionary.COW.COW_NO;
  }

  toggleSettingsBoard(isOpen) {
    if (typeof isOpen === 'boolean') {
      this.showSettingsBoard = isOpen;
    } else {
      this.showSettingsBoard = !this.showSettingsBoard;
    }

    if (this.showSettingsBoard === true) {
      this.overwriteOpen = true;
    }
  }

  mouseleaveSettingsBoard() {
    this.overwriteOpen = false;
  }

  returnToLink() {
    if (this.umPagerConfig.caller.state) {
      const caller = this.umPagerConfig.caller;
      this.$state.go(caller.state, caller.params);
      return;
    }
  }

  goTo(to, params) {
    this.$state.go(to, params);
  }

  addClassExpand() {
    return this.shouldShowStopShipment() ?
      'expand' :
      '';
  }

  settingsButtonClass() {
    if (this.overwriteOpen === true) {
      return 'uHeading__settingsButton--overwriteOpen';
    }

    return this.showSettingsBoard ?
      'uHeading__settingsButton--open' :
      'uHeading__settingsButton';
  }

  settingsBoardClass() {
    return this.showSettingsBoard ?
      'uHeading__settingsBoard--open' :
      'uHeading__settingsBoard';
  }

  numberButtonClass(enable) {
    return enable ?
      'uHeading__numberButton' :
      'uHeading__numberButton--disabled';
  }

  pagerLinkClass(enable) {
    return enable && this.enablePager ?
      'uHeading__pagerLink' :
      'uHeading__pagerLink--disabled';
  }

  subHeadingRowClass() {
    return this.cowLabelList && this.cowLabelList.length ?
      'uSubHeading__row--hasCowLabel' :
      'uSubHeading__row';
  }

  sideBoardItemClass(tab, disabled) {
    if (disabled) {
      return 'uSideBoard__item--disabled';
    }

    return this.activeTab === tab ? 'uSideBoard__item--active' : 'uSideBoard__item';
  }

  adjustContentWidthClass() {
    return ['summaryTab', 'milkingTab'].includes(this.activeTab) ? 'adjust-content-width' : null;
  }

  mainTabClass(tab, disabled) {
    if (disabled) {
      return 'uMain__tab--disabled';
    }

    return this.activeTab === tab ? 'uMain__tab--active' : 'uMain__tab';
  }

  updateCowDetails(eventType) {
    if (['heishi', 'touta'].includes(eventType)) {
      this.updateSensorIds().then(() => this.setDates());
      this.updateCalvingSensorNumbers();
    } else if (['abort', 'bunben'].includes(eventType)) {
      this.updateCalvingSensorNumbers();
    }
  }

  changeUiVersion(uiVersionCowDetail, loading) {
    if (loading) {
      this.$rootScope.updatingUiVersion = true;
    }

    const uiVersion = this.convertUiVersionString({
      h: this.SessionCache.account().uiVersion().split('_')[0] === 'h-1' ? '1' : '2',
      c: uiVersionCowDetail
    });

    this.AccountAPI.updateUiVersion(uiVersion).then(() => {
      this.$rootScope.uiVersion = `c-${uiVersionCowDetail}`;

      let params = angular.copy(this.SessionCache.account().values);
      params.uiVersion = uiVersion;

      const account = new Account(params);
      this.SessionCache.store('account', account);

      if (uiVersionCowDetail === '1' && this.activeTab === 'reproductionTab') {
        this.changeTab('summaryTab');
      }

      this.$timeout(() => {
        this.$rootScope.updatingUiVersion = false;
      }, 1000);
    });
  }

  convertUiVersionString(uiVersion) {
    return Object.entries(uiVersion).map(([key, value]) => {
      return `${key}-${value}`;
    }).join('_');
  }

  showCowDetailLegacy() {
    return this.$rootScope.uiVersion === 'c-1';
  }

  openCalvingSensorAlert() {
    if (this.currentCowBleId || !this.currentCowCalvingSensorNumber) return;

    this.StandardDialog.showMessage({
      title: '警告',
      text1: '分娩兆候アラートをご利用いただくためには、活動量センサーと分娩センサーが装着されている必要があります。',
      text2: `分娩兆候アラートの算出を行いたい場合は活動量センサーを装着してください。
              算出を行わない場合は分娩センサーを解除してください。`
    });
  }

  copyCowUid() {
    ClipboardUtil.copyToClipboard(this.c.cowUid);
  }

  hasSensors() {
    return this.currentCowBleId ||
      this.currentCowCalvingSensorNumber ||
      this.cowBleIds.length > 0 ||
      this.cowCalvingSensors.length > 0;
  }

  currentBleIdRecord() {
    return this.cowBleIds.find((record) => record.bleId === this.currentCowBleId );
  }
}

app.controller('CowDetailController', CowDetailController);
