/**
 * um-formコンポーネント
 *
 * FORMタグを生成する
 *
 * 使い方
 * <um-form>
 *   <um-form-group layout="horizontal">
 *     <um-form-input
 *       :
 *       um-model="ctrl.selectedHour"></um-form-input>
 *     <um-form-input
 *       :
 *       um-model="ctrl.selectedMinute"></um-form-input>
 *   </um-form-group>
 * </um-form>
 *
 * パラメータ:
 * @param {string} um-cancel-click キャンセルボタンがクリックされた時に実行する処理
 * @param {string} um-submit-click 投稿ボタンがクリックされた時に実行する処理
 *
 */

app.component('umForm', {
  template: ($element, $attrs) => {
    'ngInject';
    return `
        <div class="module-form">
          <form name="${$attrs.umName}" novalidate>
            <ng-container ng-transclude></ng-container>
          </form>
          <div class="mf-action">
            <button type="button" ng-click="${$attrs.umCancelClick}" class="mf-action_button">キャンセル</button>
            <button type="submit" ng-click="${$attrs.umSubmitClick}" class="mf-action_button">登録する</button>
          </div>
        </div>
      `;
  },
  transclude: true,
  bindings: {
    'umCancelClick': '&',
    'umSubmitClick': '&',
  },
  controller: 'UmFormController'
});

class UmFormController {
  constructor($attrs) {
    'ngInject';
    this.$attrs = $attrs;
  }
}

app.controller('UmFormController', UmFormController);
