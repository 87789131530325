// eslint-disable-next-line no-unused-vars
class CowEventBulkInputDefinition {
  static inputItems(eventType) {
    const items = CowEventBulkInputDefinition.commonItems(eventType);
    return items.concat(CowEventBulkInputDefinition.uniqueItems(eventType));
  }

  static commonItems(eventType) {
    const items = [
      'cowNo',
      'comment'
    ];

    if (EventType.isTreatmentForLegacy(eventType)) {
      return items.concat([
        'occurredDiseaseDate',
        'selectedHour',
        'selectedMinute',
        'workerName',
        'selectedMedicines',
        'bodyTemperature'
      ]);
    }

    return items;
  }

  static uniqueItems(eventType) {
    switch (eventType) {
    // 乳房炎
    case 'mastitis': {
      return [
        'mastitisScore',
        'mastitisDiagnosisResultOfBreastFl',
        'mastitisDiagnosisResultOfBreastBl',
        'mastitisDiagnosisResultOfBreastFr',
        'mastitisDiagnosisResultOfBreastBr',
        'mastitisBacteriaOfBreastFl',
        'mastitisBacteriaOfBreastBl',
        'mastitisBacteriaOfBreastFr',
      ];
    }
    // 周産病・代謝病
    case 'perinatalDifficulty': {
      return [
        'condition',
        'severity',
      ];
    }
    // 跛行・蹄病
    case 'lame': {
      return [
        'locomotionScore',
      ];
    }
    // 感染症
    case 'infect': {
      return [
        'difficultiesPneumonia',
        'difficultiesDiarrhea',
        'difficultiesSkinDisease',
        'difficultiesOther',
      ];
    }
    // その他
    case 'otherDifficulty': {
      return [
        'otherDiseaseName',
        'difficultiesOther',
      ];
    }
    // フレッシュチェック
    case 'freshCheck': {
      return [
        'diagnosisResultOfOvaryL',
        'diagnosisResultOfOvaryR',
        'uterusDiagnosisResult',
        'bcs',
        'otherReproductionWork',
        'freshCheckResult',
      ];
    }
    // 発情
    case 'hatsujo': {
      return [];
    }
    // ホルモンプログラム
    case 'hormoneProgram': {
      return [
        'selectedHour',
        'selectedMinute',
        'hormoneProgramName',
        'selectedHormoneCode',
      ];
    }
    // 種付
    case 'tanetsuke': {
      return [
        'masterSpermNo',
        'workerName',
        'inseminationCode',
        'otherReproductionWork',
        'masterEmbryoName',
        'embryoType',
        'embryoCertificateNo',
      ];
    }
    // 妊娠鑑定
    case 'ninshinkantei': {
      return [
        'judgePregnantTiming',
        'otherReproductionWork',
        'ninshinkanteiResult',
      ];
    }
    // 乾乳
    case 'kannyu': {
      return [
        'selectedDryPeriod',
        'bcs',
      ];
    }
    // 採卵
    case 'embryo_recovery': {
      return [
        'embryos',
        'masterSpermId',
        'targetBreedingDate',
        'embryoMasterRegistered',
        'hormoneProgramName',
        'nextCowState',
      ];
    }
    // ワクチン
    case 'vaccine': {
      return [
        'workerName',
        'selectedMedicines',
        'vaccineTiming',
      ];
    }
    // 削蹄
    case 'hoofTrim': {
      return [
        'workerName',
      ];
    }
    // 牛群移動
    case 'gyugunidou': {
      return [
        'workerName',
      ];
    }
    // 作業メモ
    case 'sagyomemo': {
      return [
        'selectedHour',
        'selectedMinute',
        'taioTitle',
      ];
    }
    // 出荷
    case 'touta': {
      return [
        'expelledReason',
        'shipmentNo',
        'producingArea',
        'producingFarmName',
        'salesPrice',
      ];
    }
    // へい死
    case 'heishi': {
      return [
        'deathReason',
      ];
    }
    // 離乳
    case 'wean': {
      return [];
    }
    // 除角
    case 'dehorn': {
      return [
        'workerName',
        'dehornMethod',
      ];
    }
    // 去勢
    case 'castration': {
      return [
        'castrationMethod',
      ];
    }
    // 肥育
    case 'fattening': {
      return [];
    }
    // 枝肉成績
    case 'carcass': {
      return [
        'cowUid',
        'slaughterNo',
        'cowUid',
        'yieldGrade',
        'meetGrade',
        'beforeSlaughterWeight',
        'dressedCarcassWeightOfL',
        'dressedCarcassWeightOfR',
        'loinArea',
        'ribsThickness',
        'subcutaneousFat',
        'yieldBaseValue',
        'bmsNo',
        'marblingGrade',
        'bcsNo',
        'gloss',
        'bcsAndGlossGrade',
        'tight',
        'texture',
        'tightAndTextureGrade',
        'bfsNo',
        'fatLuster',
        'bfsAndFatLusterGrade',
        'carcassDefects',
        'otherFaultCorrection',
        'dressedCarcassUnitPrice',
        'dressedCarcassSalesPrice',
        'totalDressedCarcassWeight',
        'visceralDestruction',
      ];
    }
    }
  }

  static dateLabel(eventType) {
    return CowEventBulkInputDefinition.dateLabelMap[eventType];
  }

  static get dateLabelMap() {
    return {
      mastitis: '治療日',
      perinatalDifficulty: '治療日',
      lame: '治療日',
      infect: '治療日',
      otherDifficulty: '治療日',
      freshCheck: '日付',
      hatsujo: '発情日',
      tanetsuke: '種付日',
      ninshinkantei: '鑑定日',
      hormoneProgram: '処置日',
      embryo_recovery: '採卵日',
      kannyu: '乾乳日',
      vaccine: '投与日',
      hoofTrim: '削蹄日',
      gyugunidou: '移動日',
      sagyomemo: '日付',
      touta: '出荷日',
      heishi: 'へい死日',
      wean: '離乳日',
      dehorn: '除角日',
      castration: '去勢日',
      fattening: '肥育開始日',
      carcass: '販売日',
    };
  }

  /**
   * イベント種別、文脈に応じた選択肢のセットを返します。
   *
   * @param {String} eventType イベント種別
   * @param {Object} context 牧場種別などの文脈
   * @return {Object} イベント種別、文脈に応じた選択肢のセット
   *
   * [contextの定義]
   * context: {
   *   isMilkFarm: true or false (省略した場合はfalseとして扱われる),
   * }
   */
  static options(eventType, context = {}) {
    switch (eventType) {
    // 周産病・代謝病
    case 'perinatalDifficulty': {
      let condition = [
        {label: '1:ケトーシス', key: 'difficultiesKetosis'},
        {label: '2:脂肪肝', key: 'difficultiesFattyLiver'},
        {label: '3:第四胃変位', key: 'difficultiesAbomasumDisplacement'},
        {label: '4:低カルシウム', key: 'difficultiesHypocalcium'},
        {label: '5:アシドーシス', key: 'difficultiesAcidosis'},
        {label: '6:子宮炎', key: 'difficultiesMetritis'},
        {label: '7:ダウナー症候群', key: 'difficultiesDowner'},
        {label: '8:食滞', key: 'difficultiesStagnantFood'},
        {label: '9:胎盤停滞', key: 'difficultiesPlacenta'},
        {label: '10:鼓張症', key: 'difficultiesTympanites'},
        {label: '11:ビタミンA欠乏症', key: 'difficultiesVitaminA'},
        {label: '12:尿石', key: 'difficultiesUrolithiasis'}
      ];
      if (context.isMilkFarm) {
        condition = [
          {label: '1:ケトーシス', key: 'difficultiesKetosis'},
          {label: '2:脂肪肝', key: 'difficultiesFattyLiver'},
          {label: '3:第四胃変位', key: 'difficultiesAbomasumDisplacement'},
          {label: '4:低カルシウム', key: 'difficultiesHypocalcium'},
          {label: '5:アシドーシス', key: 'difficultiesAcidosis'},
          {label: '6:子宮炎', key: 'difficultiesMetritis'},
          {label: '7:ダウナー症候群', key: 'difficultiesDowner'},
          {label: '8:食滞', key: 'difficultiesStagnantFood'},
          {label: '9:胎盤停滞', key: 'difficultiesPlacenta'},
          {label: '10:鼓張症', key: 'difficultiesTympanites'}
        ];
      }
      return {
        condition: condition,
        severity: [
          {label: '', key: ''},
          {label: '1:軽症', key: '軽'},
          {label: '2:中症', key: '中'},
          {label: '3:重症', key: '重'}
        ]
      };
    }
    // 感染症
    case 'infect': {
      return {
        difficulties: [
          {label: '症状なし', key: ''},
          {label: '軽', key: '軽'},
          {label: '中', key: '中'},
          {label: '重', key: '重'}
        ],
        otherDifficulties: [
          {label: '軽', key: '軽'},
          {label: '中', key: '中'},
          {label: '重', key: '重'}
        ]
      };
    }
    // その他
    case 'otherDifficulty': {
      return {
        otherDifficulties: [
          {label: '軽', key: '軽'},
          {label: '中', key: '中'},
          {label: '重', key: '重'}
        ]
      };
    }
    }
    return {};
  }

  /**
   * 以下の列の並びになるように項目定義が返されます。
   *
   * 1列目 発生日
   * 2列目 牛番号
   * 3列名以降 イベント毎の項目
   * 最終列 コメント
   **/
  static pasteItems(eventType) {
    const dateLabel = CowEventBulkInputDefinition.dateLabel(eventType);
    const items = [
      {id: 'occurredAt', labels: [dateLabel, '作業日', '作業日時'], required: true, width: 250},
      {id: 'cowNo', labels: ['牛番号'], required: true},
    ];

    CollectionUtil.mergeList(
      items, CowEventBulkInputDefinition.pasteUniqueItems(eventType)
    );

    const commentWidth = ['gyugunidou'].includes(eventType) ? '*' : 500;
    items.push({id: 'comment', labels: ['コメント'], width: commentWidth});

    return items;
  }

  static pasteUniqueItems(eventType) {
    switch (eventType) {
    // 牛群移動
    case 'gyugunidou': {
      return [
        {id: 'moveToCowGroupId', labels: ['移動先', '移動先牛群'], required: true, width: 200},
        {id: 'moveToPen', labels: ['牛房', '移動先牛房'], width: 120},
        {id: 'workerName', labels: ['担当者', '作業者']},
      ];
    }
    // 種付
    case 'tanetsuke': {
      return [
        {id: 'tanetsukeMethod', labels: ['種付方法'], required: true, width: 100},
        {id: 'masterSpermNo', labels: ['精液番号'], width: 150},
        {id: 'inseminationCode', labels: ['授精コード'], width: 150},
        {id: 'bredForEmbryoRecovery', labels: ['採卵用'], width: 80},
        {id: 'otherReproductionWork', labels: ['その他処置'], width: 150},
        {id: 'workerName', labels: ['担当者', '作業者']},
        {id: 'masterEmbryoName', labels: ['受精卵']},
        {id: 'embryoType', labels: ['受精卵種類']},
        {id: 'embryoCertificateNo', labels: ['受精卵証明書番号']},
      ];
    }
    // 妊娠鑑定
    case 'ninshinkantei': {
      return [
        {id: 'ninshinkanteiResult', labels: ['鑑定結果'], required: true, width: 100},
        {id: 'judgePregnantTiming', labels: ['タイミング']},
        {id: 'otherReproductionWork', labels: ['その他処置']},
        {id: 'workerName', labels: ['担当者', '作業者']},
      ];
    }
    }
  }

  static pasteSample(eventType) {
    switch (eventType) {
    // 牛群移動
    case 'gyugunidou': {
      return {
        sample: [
          ['作業日', 'イベント名称', '牛番号', '移動先牛群', '移動先牛房', '作業者', 'コメント'],
          ['※この行は説明用のものです', '(この列は必須です)', '(この列は必須です)', '(この列は必須です)', '', '', ''],
          ['2022/01/02', '牛群移動', '001', '牛群1', '', '作業者A', ''],
          ['2022/10/13', '牛群移動', '002', '牛群2', '3', '', '定期移動'],
        ],
        fileName: '牛群移動.xlsx'
      };
    }
    // 種付
    case 'tanetsuke': {
      return {
        sample: [
          ['作業日', 'イベント名称', '牛番号', '種付方法', '精液番号', '授精コード', '採卵用', 'その他処置', '担当者', '受精卵', '受精卵種類', '受精卵証明書番号', 'コメント'],
          ['※この行は説明用のものです', '(この列は必須です)', '(この列は必須です)', '(この列は必須です)', '', '', '(採卵用の種付の場合には何かしらの文字を入力してください)', '', '', '', '', '', ''],
          ['2022/01/02', '種付', '001', '人工授精', '99HO12345', '', '', '', '作業者A', '', '', '', '', ''],
          ['2022/10/13', '種付', '002', '人工授精', '', 'オブシンク', '', '処置A', '', '', '', '', '2回目'],
          ['2022/11/05', '種付', '003', '人工授精', '黒12345', 'PG', '〇', '処置B', '作業者B', '', '', '', '', ''],
          ['2022/01/02 14:30', '種付', '004', '人工授精', '', 'HK987', '対象', '', '', '', '', '', ''],
          ['2022/01/02', '種付', '101', '移植', '', '', '', '', '', '受精卵A', '体内受精卵', '1234567890', ''],
          ['2022/10/13', '種付', '102', '移植', '', '', '', '', '', '受精卵B', '体外受精卵', 'AB-001', ''],
        ],
        fileName: '種付.xlsx'
      };
    }
    // 妊娠鑑定
    case 'ninshinkantei': {
      return {
        sample: [
          ['作業日', 'イベント名称', '牛番号', '鑑定結果', 'タイミング', 'その他処置', '担当者', 'コメント'],
          ['※この行は説明用のものです', '(この列は必須です)', '(この列は必須です)', '(この列は必須です)', '', '', '', ''],
          ['2022/01/02', '妊娠鑑定', '001', '受胎', '', '', '作業者A', '', '', ''],
          ['2022/01/03', '妊娠鑑定', '002', '受胎', '1回目', '', '作業者A', '要再鑑定'],
          ['2022/10/13', '妊娠鑑定', '003', '未受胎', '2回目', 'PG', '', ''],
          ['2022/11/05', '妊娠鑑定', '004', '双子受胎', '', '', '作業者B', ''],
          ['2022/11/05', '妊娠鑑定', '005', '不明', '', '', '', '要確認'],
        ],
        fileName: '妊娠鑑定.xlsx'
      };
    }
    }
  }
}
