// eslint-disable-next-line no-unused-vars
class HeadageItemDefinition {
  static get definitions() {
    return [
      // fieldType: STRING
      {itemId: 'breed', label: '品種', fieldType: 'STRING', width: 110},
      {itemId: 'breedingExclusionReason', label: '繁殖除外理由', fieldType: 'STRING', width: 120},
      {itemId: 'bullSpermNo', label: '種雄牛の精液ラベル番号', fieldType: 'STRING', width: 180},
      {itemId: 'cowGroupName', label: '牛群', fieldType: 'STRING', width: 150},
      {itemId: 'cowNo', label: '牛番号', fieldType: 'STRING', width: 90},
      {itemId: 'deathReason', label: 'へい死理由', fieldType: 'STRING', width: 100},
      {itemId: 'expelledReason', label: '除籍理由', fieldType: 'STRING', width: 100},
      {itemId: 'fatherName', label: '父牛の名前', fieldType: 'STRING', width: 110},
      {itemId: 'gender', label: '性別', fieldType: 'STRING', width: 100},
      {itemId: 'maternalGrandfatherName', label: '母父牛の名前', fieldType: 'STRING', width: 110},
      {itemId: 'maternalGreatGrandfatherName', label: '母母父牛の名前', fieldType: 'STRING', width: 130},
      {itemId: 'maternalFather4thName', label: '母母母父の名前', fieldType: 'STRING', width: 130},
      {itemId: 'medicationSummary', label: '投薬履歴(すべて)', fieldType: 'STRING', width: 460},
      {itemId: 'motherName', label: '母牛の名前', fieldType: 'STRING', width: 110},
      {itemId: 'pen', label: '牛房', fieldType: 'STRING', width: 60},
      {itemId: 'state', label: '状態', fieldType: 'STRING', width: 90},
      // fieldType: DATE
      {itemId: 'birthday', label: '出生日', fieldType: 'DATE', width: 100},
      {itemId: 'breedingExcludeDate', label: '繁殖除外日', fieldType: 'DATE', width: 100},
      {itemId: 'eliminateDate', label: '出荷日', fieldType: 'DATE', width: 100},
      {itemId: 'endDateOfMilkWashoutPeriod', label: '休薬終了日(乳)', fieldType: 'DATE', width: 140},
      {itemId: 'expectedCalvingDate', label: '分娩予定日', fieldType: 'DATE', width: 100},
      {itemId: 'expectedDryDate', label: '乾乳予定日', fieldType: 'DATE', width: 100},
      {itemId: 'fallingDeadDate', label: 'へい死日', fieldType: 'DATE', width: 100},
      {itemId: 'introduceDate', label: '導入日', fieldType: 'DATE', width: 100},
      {itemId: 'latestCalvingDate', label: '分娩日', fieldType: 'DATE', width: 100},
      {itemId: 'latestDryPreviousPeriodStartDate', label: '乾乳前期開始日', fieldType: 'DATE', width: 130},
      {itemId: 'latestFertilizationDate', label: '授精日', fieldType: 'DATE', width: 100},
      {itemId: 'startFatteningDate', label: '肥育開始日', fieldType: 'DATE', width: 100},
      {itemId: 'calvedDays', label: '分娩後日数', fieldType: 'DIFF_DAYS', width: 100, relatedColumn: 'latestCalvingDate'},
      {itemId: 'dayAge', label: '日齢', fieldType: 'DIFF_DAYS', width: 60, relatedColumn: 'birthday'},
      // fieldType: DIFF_DAYS
      {itemId: 'dryEarlierDays', label: '乾乳前期日数', fieldType: 'DIFF_DAYS', width: 110, relatedColumn: 'latestDryPreviousPeriodStartDate'},
      {itemId: 'dryLaterDays', label: '乾乳後期日数', fieldType: 'DIFF_DAYS', width: 110, relatedColumn: 'latestDryLatePeriodStartDate'},
      {itemId: 'fertilizedDays', label: '授精後日数', fieldType: 'DIFF_DAYS', width: 100, relatedColumn: 'latestFertilizationDate'},
      {itemId: 'milkingDays', label: '搾乳日数', fieldType: 'DIFF_DAYS', width: 90, relatedColumn: 'latestCalvingDate'},
      {itemId: 'pregnantDays', label: '妊娠日数', fieldType: 'DIFF_DAYS', width: 90, relatedColumn: 'latestPregnancyDate'},
      // fieldType: DIFF_MONTHS
      {itemId: 'enrolledMonths', label: '在籍月数', fieldType: 'DIFF_MONTHS', width: 90, relatedColumn: 'introduceDate'},
      // fieldType: FLOAT
      {itemId: 'weeklyMean', label: '平均搾乳量(週)', fieldType: 'FLOAT', width: 140, default: '-'},
      // fieldType: INTEGER
      {itemId: 'birthNumber', label: '産次', fieldType: 'INTEGER', width: 60, default: '0'},
      {itemId: 'consecutiveBredCount', label: '授精回数(連)', fieldType: 'INTEGER', width: 110, default: '0'},
      {itemId: 'weightOnBirth', label: '出生時体重', fieldType: 'INTEGER', width: 100},
      // fieldType: SPECIAL
      {itemId: 'cowLabels', label: '個体ラベル', fieldType: 'SPECIAL', width: 190},
      {itemId: 'cowUid', label: '個体識別番号', fieldType: 'SPECIAL', width: 110},
      {itemId: 'monthAge', label: '月齢', fieldType: 'SPECIAL', width: 60},
      {itemId: 'openDays', label: '空胎日数', fieldType: 'SPECIAL', width: 90},
    ];
  }

  static deinitionMap() {
    const defs = HeadageItemDefinition.definitions;
    return defs.reduce((obj, def) => {
      obj[def.itemId] = def;
      return obj;
    }, {});
  }

  static itemDefinitions(itemIds) {
    const defs = HeadageItemDefinition.deinitionMap();
    return itemIds.map((itemId) => defs[itemId]);
  }

  static toDisplayFormat(items, records, baseDate = DateUtil.today()) {
    return records.map((r) => {
      const result = {
        cowId: r.cowId,
        cowNoForOrder: r.cowNoForOrder,
        penForOrder: r.penForOrder,
      };

      items.forEach((item) => {
        result[item.itemId] = HeadageItemDefinition.toDisplayValue(
          item, r, baseDate
        );
      });

      return result;
    });
  }

  static toDisplayValue(item, record, date) {
    if (item.fieldType === 'SPECIAL') {
      if (item.itemId === 'cowUid') {
        return Cow.formatCowUid(record.cowUid);
      }

      if (item.itemId === 'cowLabels') {
        const cowLabels = record.cowLabels || '';
        return cowLabels.replace(/(\[|\])/g, '').split(',');
      }

      if (item.itemId === 'monthAge') {
        const months = DateUtil.monthAge(record.birthday, date, 1);
        return months > 0 ? String(months) : '';
      }

      if (item.itemId === 'openDays') {
        const openDays = Cow.daysOpen(record.latestCalvingDate, record.latestPregnancyDate);
        return openDays > 0 ? String(openDays) : '';
      }
    }

    if (item.fieldType === 'DATE') {
      return DateUtil.toYYYYMMDD(record[item.itemId]);
    }

    if (item.fieldType === 'DIFF_DAYS') {
      const days = DateUtil.diffDays(record[item.relatedColumn], date);
      return days > 0 ? String(days) : '';
    }

    if (item.fieldType === 'DIFF_MONTHS') {
      const months = DateUtil.diffMonths(record[item.relatedColumn], date, 1);
      return months > 0 ? String(months) : '';
    }

    const value = record[item.itemId];
    if (!value) {
      return item.default || '';
    }
    return value;
  }
}
