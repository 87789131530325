/**
 * モバイル用のイヤタグセンサー脱落アラートのリストアイテムコンポーネントです
 *
 * ex.
 *   <mobile-alert-eartag-sensor-dropped-list-item
 *     alert-eartag-sensor-dropped="alertEartagSensorDropped"
 *     on-go-to-cow-detail-click="onGoToCowDetailClick(cowId)"
 *     on-solution-selection-click="onSolutionSelectionClick(id)"
 *   ></mobile-alert-eartag-sensor-dropped-list-item>
 *
 * プロパティ:
 *  @param {Object} alert-eartag-sensor-dropped イヤタグセンサー脱落アラートオブジェクト
 *  @param {Function} on-go-to-cow-detail-click 個体情報詳細へクリック時に発生するイベント cowIdを引数で渡す
 *  @param {Function} on-solution-selection-click 処置内容の選択クリック時に発生するイベント idを引数で渡す
 */
class MobileAlertEartagSensorDroppedListItemController {
  constructor() {
    'ngInject';

    this.shouldShowDetails = false;
  }

  handleGoToCowDetailClick() {
    this.onGoToCowDetailClick({cowId: this.alertEartagSensorDropped.cowId});
  }

  handleSolutionSelectionClick() {
    this.onSolutionSelectionClick({id: this.alertEartagSensorDropped.id});
  }

  toggleDetails() {
    this.shouldShowDetails = !this.shouldShowDetails;
  }
}

function mobileAlertEartagSensorDroppedListItemComponents() {
  return {
    templateUrl: 'mobile/alert-list/eartag-sensor-dropped/list-item.html',
    controller: MobileAlertEartagSensorDroppedListItemController,
    bindings: {
      alertEartagSensorDropped: '<',
      onGoToCowDetailClick: '&',
      onSolutionSelectionClick: '&'
    }
  };
}

app.component('mobileAlertEartagSensorDroppedListItem', mobileAlertEartagSensorDroppedListItemComponents());
