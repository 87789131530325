// eslint-disable-next-line no-unused-vars
class EventTypeIndexDefinition {
  static get allEventTypes() {
    return [
      // 治療グループ
      {eventType: 'mastitis', label: '乳房炎', group: '治療', farmType: 'MILK'},
      {eventType: 'perinatal_and_metabolic_diseases', label: '周産病・代謝病', group: '治療', farmType: 'ALL'},
      {eventType: 'reproductive_disorders', label: '繁殖障害', group: '治療', farmType: 'ALL'},
      {eventType: 'claw_diseases', label: '跛行・蹄病', group: '治療', farmType: 'ALL'},
      {eventType: 'infection_diseases', label: '感染症', group: '治療', farmType: 'ALL'},
      {eventType: 'others', label: 'その他', group: '治療', farmType: 'ALL'},
      {eventType: 'injured', label: '外傷', group: '治療', farmType: 'ALL'},
      // 繁殖グループ
      {eventType: 'fresh_check', label: 'フレッシュチェック', group: '繁殖', farmType: 'ALL'},
      {eventType: 'pregnant_diagnosis', label: '妊娠鑑定', group: '繁殖', farmType: 'ALL'},
      {eventType: 'bred', label: '種付', group: '繁殖', farmType: 'ALL'},
      {eventType: 'calved', label: '分娩', group: '繁殖', farmType: 'ALL'},
      {eventType: 'heat', label: '発情', group: '繁殖', farmType: 'ALL'},
      {eventType: 'embryo_recovery', label: '採卵', group: '繁殖', farmType: 'ALL'},
      // 処置グループ
      {eventType: 'work_note', label: '作業メモ', group: '処置', farmType: 'ALL'},
      {eventType: 'dried', label: '乾乳', group: '処置', farmType: 'MILK'},
      {eventType: 'moved', label: '牛群移動', group: '処置', farmType: 'ALL'},
      {eventType: 'vaccination', label: 'ワクチン', group: '処置', farmType: 'ALL'},
      {eventType: 'culled', label: '出荷', group: '処置', farmType: 'ALL'},
      {eventType: 'died', label: 'へい死', group: '処置', farmType: 'ALL'},
      {eventType: 'carcass_characteristic', label: '枝肉成績', group: '処置', farmType: 'ALL'},
    ];
  }

  static availableEventTypes(farmType) {
    return EventTypeIndexDefinition.allEventTypes.filter((e) => {
      return e.farmType === 'ALL' || e.farmType === farmType;
    });
  }

  static definition(eventType) {
    return EventTypeIndexDefinition.allEventTypes.find((e) => {
      return e.eventType === eventType;
    });
  }
}
