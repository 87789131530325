// eslint-disable-next-line no-unused-vars
class CustomlistSort {
  static get UNSORTED() {
    return 'ui-grid-sorted-none';
  }

  static get UNSORTABLE_COLUMNS() {
    return [
      'eliminate_and_falling_dead_remarks', 'breeding_difficulty_medicine_names',
      'breeding_remarks', 'calving_remarks', 'cow_info_remarks', 'drye_period_remarks',
      'estrus_remarks', 'examination_remarks', 'infect_medicine_names', 'injury_medicine_names',
      'introduce_remarks', 'judge_pregnant_remarks', 'lame_medicine_names',
      'mastitis_medicine_names', 'other_difficulty_medicine_names', 'other_remarks',
      'perinatal_difficulty_medicine_names', 'synchronization_remarks', 'treatment_remarks',
      'calf_infos', 'mastitis_all_medicine_names', 'birth_state', 'calving_state',
      'occurred_mastitis_text', 'stop_milking_text', 'comments',
      'dummy'
    ].concat(Customlist.SUMMARY_COLUMNS);
  }

  static isUnsortable(columnId) {
    return CustomlistSort.UNSORTABLE_COLUMNS.includes(columnId);
  }

  static toggleSortState(state) {
    switch (state) {
    case 'ui-grid-sorted-none':
      return {state: 'ui-grid-sorted-asc', ascending: true};
    case 'ui-grid-sorted-asc':
      return {state: 'ui-grid-sorted-desc', ascending: false};
    case 'ui-grid-sorted-desc':
      return {state: 'ui-grid-sorted-none', unsorted: true};
    }
  }

  static sort(cows, column, isAscending) {
    const isNumberColumn = (column) => {
      if (Customlist.NUMBER_FIELD_TYPES.some((e) => e === column.fieldType)) {
        return true;
      }
      if (column.fieldType === 'SPECIAL') {
        return ['monthAge', 'openDays'].includes(column.columnId);
      }
      return false;
    };

    const columnId = column.columnId;
    const isNumber = isNumberColumn(column);
    const isOriginSort = Customlist.ORIGIN_SORT_COLUMNS.some((e) => e === columnId);
    const coefficient = isAscending ? 1 : -1;

    const toNumber = (value) => {
      if ((value !== 0 && !value) || value === '-') {
        if (isAscending) {
          return Number.POSITIVE_INFINITY;
        } else {
          return Number.NEGATIVE_INFINITY;
        }
      }

      const num = Number(value);
      if (num === 0) {
        return 0;
      } else {
        if (num) {
          return num;
        }
        if (isAscending) {
          return Number.POSITIVE_INFINITY;
        } else {
          return Number.NEGATIVE_INFINITY;
        }
      }
    };

    cows.sort((a, b) => {
      let valA = a[columnId];
      let valB = b[columnId];

      // nullを空白文字として扱うための措置
      if (!isNumber) {
        valA = valA || '';
        valB = valB || '';
      }

      if (isNumber) {
        valA = toNumber(valA);
        valB = toNumber(valB);
      } else if (isOriginSort) {
        valA = a.origin[columnId];
        valB = b.origin[columnId];
      } else if (['cow_no', 'cowNo'].includes(columnId)) {
        valA = a.cowNoForOrder;
        valB = b.cowNoForOrder;
      } else if (columnId === 'comments') {
        valA = valA.join('');
        valB = valB.join('');
      } else if (columnId === 'pen') {
        return CustomlistSort.comparePen(a.penForOrder, b.penForOrder, isAscending)
          || CustomlistSort.compareCowNo(a, b, isAscending);
      } else if (columnId === 'alert_type') {
        valA = CustomlistSort.alertPriority(a);
        valB = CustomlistSort.alertPriority(b);
      } else if (column.fieldType === 'ORDINAL_SCALE') {
        valA = toNumber(a.origin[columnId]);
        valB = toNumber(b.origin[columnId]);
      }

      if (valA === '') {
        return 1 * coefficient;
      } else if (valB === '') {
        return -1 * coefficient;
      } else if (valA > valB) {
        return 1 * coefficient;
      } else if (valA < valB) {
        return -1 * coefficient;
      }
      return CustomlistSort.compareCowNo(a, b, isAscending);
    });
  }

  static compareCowNo(cowA, cowB, isAscending) {
    const coefficient = isAscending ? 1 : -1;
    const cowNoA = cowA.cowNoForOrder;
    const cowNoB = cowB.cowNoForOrder;

    if (cowNoA > cowNoB) {
      return 1 * coefficient;
    } else if (cowNoA < cowNoB) {
      return -1 * coefficient;
    }
    return 0;
  }

  static comparePen(penA, penB, isAscending) {
    const coefficient = isAscending ? 1 : -1;

    if (penA === '') {
      return -1 * coefficient;
    } else if (penB === '') {
      return 1 * coefficient;
    } else if (!StringUtil.isDigit(penA) && StringUtil.isDigit(penB)) {
      return 1 * coefficient;
    } else if (StringUtil.isDigit(penA) && !StringUtil.isDigit(penB)) {
      return -1 * coefficient;
    } else if (penA > penB) {
      return 1 * coefficient;
    } else if (penA < penB) {
      return -1 * coefficient;
    } else {
      return 0;
    }
  }

  static get ALERT_PRIORITIES() {
    return [
      {id: 'illness', priority: 1},
      {id: 'chronic_illness', priority: 2},
      {id: 'milk_low', priority: 3},
      {id: 'feed_low', priority: 4},
      {id: 'strong_move_low', priority: 5},
      {id: 'lie_high', priority: 6},
      {id: 'water_low', priority: 7},
    ];
  }

  static alertPriority(cow) {
    let result = 9999;
    CustomlistSort.ALERT_PRIORITIES.some((alert) => {
      if (cow[alert.id]) {
        result = alert.priority;
        return true;
      }
    });
    return result;
  }
}
