class MobileBulkEditLabelSelectModal {
  constructor(
    modalDialogFactory,
    MobileBulkAddLabelModal,
    MobileBulkRemoveLabelModal
  ) {
    'ngInject';

    this.modalDialogFactory = modalDialogFactory;
    this.MobileBulkAddLabelModal = MobileBulkAddLabelModal;
    this.MobileBulkRemoveLabelModal = MobileBulkRemoveLabelModal;
  }

  open(cows) {
    const choices = [
      {value: 'addLabel', text: 'ラベル貼付', new: true},
    ];

    if (cows.some((cow) => cow.cowLabels.length > 0)) {
      choices.push({
        value: 'removeLabel',
        text: 'ラベル除去',
        new: true
      });
    }

    return this.modalDialogFactory.showMultipleChoicesConfirm({
      template: `<span>一括操作の内容を選択してください。</span>`,
      choices: choices
    }).result.then((result) => {
      switch (result.value) {
      case 'addLabel':
        return this.MobileBulkAddLabelModal.open(cows);
      case 'removeLabel':
        return this.MobileBulkRemoveLabelModal.open(cows);
      default:
        return new Promise((resolve) => {
          resolve('cancel');
        });
      }
    });
  }
}
app.service('MobileBulkEditLabelSelectModal', MobileBulkEditLabelSelectModal);
