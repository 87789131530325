// eslint-disable-next-line no-unused-vars
class CustomlistColumnDefinition {
  static isDateField(column) {
    if (!column) return false;
    return column.fieldType === 'DATE' || column.fieldType === 'DATETIME';
  }

  static isBooleanField(column) {
    if (!column) return false;
    return column.fieldType === 'BOOLEAN';
  }

  static get ALERT_HEAT_ITEMS() {
    return [
      'alert_heat_peak_at',
    ];
  }

  static get ALLERT_BAD_CONDITON_ITEMS() {
    return [
      'alert_cow_view_alert_type',
      'alert_cow_view_alert_notes',
      'alert_unhealthy_illness_detected_at',
    ];
  }

  constructor(params) {
    Object.assign(this, params);
    this.configTypes = this.configType ? this.configType.split(',') : null;
  }

  isAvailable(master) {
    if (this.columnId === 'farm_name') {
      return master.showDepositedCow;
    } else if (this.configTypes) {
      if (CustomlistColumnDefinition.ALERT_HEAT_ITEMS.includes(this.columnId)) {
        if (master.alertContent !== 'heat') {
          return false;
        }
      }

      if (CustomlistColumnDefinition.ALLERT_BAD_CONDITON_ITEMS.includes(this.columnId)) {
        if (master.alertContent !== 'bad_condition') {
          return false;
        }
      }

      return this.configTypes.some((ct) => {
        return ct === master.configType || master.configType.includes(ct);
      });
    } else {
      return true;
    }
  }

  toOrderItem() {
    return {value: this.columnId, label: this.caption};
  }
}
