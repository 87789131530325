class FilterSearchConditionService {
  constructor(MasterCowLabelAPI) {
    'ngInject';
    this.MasterCowLabelAPI = MasterCowLabelAPI;
    this.selectItems_();
    this.initializeCondition();
  }

  // set, get

  /**
   * 状態を選択条件に設定する。
   *
   * @param {string} state 状態
   */
  set state(state) {
    this.searchData.state[state] = true;
  }

  /**
   * 条件を取得する。
   */
  get condition() {
    return this.searchData;
  }

  /**
   * 産次選択リストを取得する。
   */
  get birthNumbers() {
    return this.birthNumbers_;
  }

  /**
   * 開始種付回数リストを取得する。
   */
  get tanetsukeFroms() {
    return this.tanetsukeFroms_;
  }

  /**
   * 終了種付回数リストを取得する。
   */
  get tanetsukeTos() {
    return this.tanetsukeTos_;
  }

  // public method

  /**
   * 条件の初期化を行う。
   */
  initializeCondition() {
    this.searchData = {
      birthNumber: this.birthNumbers_[0],
      tanetsukeFrom: this.tanetsukeFroms_[0],
      tanetsukeTo: this.tanetsukeTos_[0],
      // 分娩後日数
      afterParturitionDaysFrom: '',
      afterParturitionDaysTo: '',
      // 授精後日数
      afterFertilizationDaysFrom: '',
      afterFertilizationDaysTo: '',
      // 妊娠日数
      gestationDaysFrom: '',
      gestationDaysTo: '',
      // BCS
      bcsFrom: '',
      bcsTo: '',
      // 個体ラベル,
      cowLabels: {
      },
      state: {
        '育成': true,
        '哺育': true,
        '肥育': true,
        'フレッシュ': true,
        '未授精': true,
        '授精': true,
        '受胎(＋)': true,
        '未受胎(－)': true,
        '乾乳前期': true,
        '乾乳後期': true,
        '出荷': false,
        'へい死': false,
        '繁殖除外': true,
        'その他': true
      },
      cowNo: ''
    };

    this.MasterCowLabelAPI.available().then((res) => {
      res.data.forEach((label) => {
        this.searchData.cowLabels[label.name] = true;
      });
      this.searchData.cowLabels['(未設定)'] = true;
    }).catch((err) => {
      console.error(err);
    });
  }

  /**
   * フィルタリングした結果を返す。
   *
   * @param {Object} data 対象オブジェクト
   */
  filter(data) {
    const stateCondition = this.stateCondition_();

    // 個体ラベル、状態以外の条件を確認
    const otherCondition = this.otherCondition_();
    // 検索条件がない場合はfalseを返す。
    if (stateCondition === false && lableCondition === false && otherCondition === false) return false;
    // and検索条件のフィルタリング(１つでもアンマッチの条件が在る場合は非表示)
    // 状態
    if (this.checkState_(data.state) === false) return false;
    // 個体ラベル
    if (this.checkLabel_(data.cowLabels) === false) return false;
    // 牛番号
    if (this.checkItemEquals_(this.searchData.cowNo, data.cowNo) === false) return false;
    // 産次
    if (this.checkBirthNumber_(data.birthNumber) === false) return false;
    // 種付け回数
    if (this.checkBetween_(this.searchData.tanetsukeFrom.value,
      this.searchData.tanetsukeTo.value, data.latestBreedingCount) === false) return false;
    // 分娩後日数
    if (this.checkBetween_(this.searchData.afterParturitionDaysFrom,
      this.searchData.afterParturitionDaysTo, data.latestCalvingDateDaysPassed) === false) return false;
    // 授精後日数
    if (this.checkBetween_(this.searchData.afterFertilizationDaysFrom,
      this.searchData.afterFertilizationDaysTo, data.latestFertilizationDateDaysPassed) === false) return false;
    // 妊娠日数
    if (this.checkBetween_(this.searchData.gestationDaysFrom,
      this.searchData.gestationDaysTo, data.latestPregnancyDateDaysPassed) === false) return false;
    // BCS
    if (this.checkBetween_(this.searchData.bcsFrom,
      this.searchData.bcsTo, data.latestBcs) === false) return false;

    return true;
  }

  // private method

  /**
   * 選択項目を設定する。
   */
  selectItems_() {
    // 産次用
    this.birthNumbers_ = [
      {name: '指定無し', value: ''},
      {name: '未出産', value: '0'},
      {name: '初産', value: '1'},
      {name: '初産以外', value: '2'}
    ];
    // 種付け回数用
    this.tanetsukeFroms_ = [
      {name: '指定なし', value: ''},
      {name: '0回', value: '0'},
      {name: '1回', value: '1'},
      {name: '2回', value: '2'},
      {name: '3回', value: '3'},
      {name: '4回', value: '4'},
      {name: '5回', value: '5'},
      {name: '6回', value: '6'},
      {name: '7回', value: '7'},
      {name: '8回', value: '8'},
      {name: '9回', value: '9'},
      {name: '10回', value: '10'}
    ];
    this.tanetsukeTos_ = [
      {name: '指定なし', value: ''},
      {name: '0回', value: '0'},
      {name: '1回', value: '1'},
      {name: '2回', value: '2'},
      {name: '3回', value: '3'},
      {name: '4回', value: '4'},
      {name: '5回', value: '5'},
      {name: '6回', value: '6'},
      {name: '7回', value: '7'},
      {name: '8回', value: '8'},
      {name: '9回', value: '9'},
      {name: '10回', value: '10'}
    ];
  }

  /**
   * 全状態の中で値がtrueの状態があるか確認する。
   * trueの状態が1つでも有る場合はtrueを返す。
   *
   * @return {boolean} true or false
   */
  stateCondition_() {
    return Object.keys(this.searchData.state).some((key) => {
      return (this.searchData.state[key] === true);
    });
  }

  /**
   * 全個体ラベルの中で値がtrueの個体ラベルがあるか確認する。
   * trueの個体ラベルが1つでも有る場合はtrueを返す。
   *
   * @return {boolean} true or false
   */
  labelCondition_() {
    return Object.keys(this.searchData.cowLabels).some((key) => {
      return (this.searchData.cowLabels[key] === true);
    });
  }

  /**
   * 状態、個体ラベルを除く条件が設定されているか確認する。
   * 条件が設定されている場合はtrueを返す。
   *
   * @return {boolean} true or false
   */
  otherCondition_() {
    return Object.keys(this.searchData).some((key) => {
      if (key === 'cowLabels' || key === 'state') return false;
      if (typeof this.searchData[key] === 'string') {
        return (this.searchData[key] !== '');
      } else if (typeof this.searchData[key] === 'object') {
        return (this.searchData[key].value !== '');
      }
    });
  }

  /**
   * 対象の状態が条件に設定されているか確認する。
   * 条件に設定されている場合はtrueを返す。
   *
   * @param {string} state 状態
   * @return {boolean} true or false
   */
  checkState_(state) {
    if (this.searchData.state[state] === true ) return true;
    return false;
  }

  /**
   * 対象の個体ラベルが条件に設定されているか確認する。
   * 条件に設定されている場合はtrueを返す。
   *
   * @param {string} labels 個体ラベル
   * @return {boolean} true or false
   */
  checkLabel_(labels) {
    if (!labels.length && this.searchData.cowLabels['(未設定)'] === true) return true;
    return labels.some((label) => {
      return (this.searchData.cowLabels[label] === true);
    });
  }

  /**
   * 対象の産次が条件に設定されているか確認する。
   *
   * @param {string} birthNumber 産次数
   * @return {boolean} true or false
   */
  checkBirthNumber_(birthNumber) {
    // 条件に設定されていない場合はtrueを返す。
    if (this.searchData.birthNumber.value === '') return true;

    const num = Number(this.searchData.birthNumber.value);
    // 産次(未出産、初産)
    if (num < 2 && num === Number(birthNumber)) return true;
    // 産次(未出産、初産以外)
    if (num >= 2 && num <= Number(birthNumber)) return true;
    return false;
  }

  /**
   * 値と対象値が等しいか確認する。
   * 値が空、または値と対象値が等しい場合はtrueを返す。
   *
   * @param {string} value 値
   * @param {string} targetValue 対象値
   */
  checkItemEquals_(value, targetValue) {
    if (value === '' || value === targetValue) return true;
    return false;
  }

  /**
   * 対象値が始値から終値の間か確認を行う。
   *
   * @param {string} from 始値
   * @param {string} to 終値
   * @param {string} value 対象値
   * @return {boolean} true or false
   */
  checkBetween_(from, to, value) {
    // from、toが空の場合はtrueを返す。
    if (from === '' && to === '') return true;
    // valueがundifined、nullの場合はfalseを返す。
    if (!value) return false;

    let result = {from: true, to: true};
    value = String(value).replace(/\(|日|\)/g, '');

    if (from !== '' && Number(from) > Number(value)) result.from = false;
    if (to !== '' && Number(value) > Number(to)) result.to = false;
    if (result.from && result.to) return true;
    return false;
  }
}

app.service('FilterSearchConditionService', FilterSearchConditionService);
