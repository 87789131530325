class TemporaryGroupViewCowsController {
  constructor(
    $state,
    $stateParams,
    blockUI,
    CowGroupAPI,
    CustomlistAPI,
    CustomlistRunner
  ) {
    'ngInject';

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.blockUI = blockUI;
    this.CowGroupAPI = CowGroupAPI;
    this.CustomlistAPI = CustomlistAPI;
    this.CustomlistRunner = CustomlistRunner;

    this.init();
  }

  init() {
    this.cowGroupId = this.$stateParams.cowGroupId;
    this.pen = this.$stateParams.pen === '-' ? null : this.$stateParams.pen;

    this.index();
  }

  index() {
    const today = DateUtil.today().getTime();
    const option = {
      cowGroupId: this.cowGroupId
    };

    this.blockUI.start('データ取得中');

    this.CowGroupAPI.show(this.cowGroupId).then((res) => {
      this.cowGroupName = res.data.cowGroupName;
      this.heading = `牛房リスト ${this.cowGroupName}(${this.$stateParams.pen})`;
    });

    this.CustomlistAPI.cowGroups().then((res) => {
      const master = res.data.find((m) => m.configType === 'group_fattening');
      const customlistId = master.customlistId;

      return this.CustomlistRunner.run(
        customlistId,
        today,
        option
      ).then((res) => {
        this.blockUI.stop();

        this.items = res.data.cows.filter((item) => {
          return item.pen === this.pen;
        }).map((item) => {

          if (typeof item.cowLabels === 'undefined') {
            item.showCowLabels = false;
          } else {
            item.showCowLabels = true;
          }

          if (typeof item.cow_no === 'undefined') {
            item.cow_no = null;
          } else if (!item.cow_no && item.cow_no !== '0') {
            item.cow_no = '-';
          }

          if (typeof item.cow_uid === 'undefined') {
            item.cow_uid = null;
          } else if (!item.cow_uid) {
            item.cow_uid = '-';
          }

          if (typeof item.gender === 'undefined') {
            item.gender = null;
          } else if (!item.gender) {
            item.gender = '-';
          }

          if (typeof item.breed === 'undefined') {
            item.formatBreed = null;
          } else if (!item.breed) {
            item.formatBreed = '(-)';
          } else {
            item.formatBreed = `(${item.breed})`;
          }

          if (typeof item.weight_on_purchase === 'undefined') {
            item.formatWeightOnPurchase = null;
          } else if (!item.weight_on_purchase) {
            item.formatWeightOnPurchase = '-';
          } else {
            item.formatWeightOnPurchase = `${item.weight_on_purchase}Kg`;
          }

          if (typeof item.purchase_price === 'undefined') {
            item.formatPurchasePrice = null;
          } else if (!item.purchase_price) {
            item.formatPurchasePrice = '(-)';
          } else {
            item.formatPurchasePrice = `(${item.purchase_price / 1000})`;
          }

          if (typeof item.introduce_remarks === 'undefined') {
            item.introduce_remarks = null;
          } else if (!item.introduce_remarks) {
            item.introduce_remarks = '-';
          }

          if (typeof item.father_name === 'undefined') {
            item.father_name = null;
          } else if (!item.father_name) {
            item.father_name = '-';
          }

          if (typeof item.maternal_grandfather_name === 'undefined') {
            item.maternal_grandfather_name = null;
          } else if (!item.maternal_grandfather_name) {
            item.maternal_grandfather_name = '-';
          }

          if (typeof item.maternal_great_grandfather_name === 'undefined') {
            item.maternal_great_grandfather_name = null;
          } else if (!item.maternal_great_grandfather_name) {
            item.maternal_great_grandfather_name = '-';
          }

          if (typeof item.master_market_name === 'undefined') {
            item.master_market_name = null;
          } else if (!item.master_market_name) {
            item.master_market_name = '-';
          }

          if (typeof item.birthday === 'undefined') {
            item.formatBirthday = null;
            item.birthdayMonthAge = null;
            item.formatBirthdayMonthAge = null;
          } else if (!item.origin.birthday) {
            item.formatBirthday = '-';
            item.birthdayMonthAge = null;
            item.formatBirthdayMonthAge = '(-)';
          } else {
            const birthday = Number(item.origin.birthday);
            const eliminateDate = Number(item.eliminateDate);
            const fallingDeadDate = Number(item.fallingDeadDate);
            const endDate = eliminateDate || fallingDeadDate || today;

            item.formatBirthday = DateUtil.toYYMMDD(birthday);
            item.birthdayMonthAge = DateUtil.monthAge(birthday, endDate, 1);
            item.formatBirthdayMonthAge = `(${item.birthdayMonthAge})`;
          }

          if (typeof item.introduce_date === 'undefined') {
            item.formatIntroduceDate = null;
          } else if (!item.origin.introduce_date) {
            item.formatIntroduceDate = '-';
          } else {
            const introduceDate = Number(item.origin.introduce_date);

            item.formatIntroduceDate = DateUtil.toYYMMDD(introduceDate);
          }

          if (typeof item.day_age_at_entered === 'undefined') {
            item.formatDayAgeAtEntered = null;
          } else if (!item.day_age_at_entered) {
            item.formatDayAgeAtEntered = '(-)';
          } else {
            item.formatDayAgeAtEntered = `(${item.day_age_at_entered}日)`;
          }

          return item;
        });

        this.cowIds = this.items.map((item) => {
          return item.cowId;
        });
      });
    });
  }

  goToDetail(cowId) {
    const caller = {
      name: `${this.cowGroupName}(${this.$stateParams.pen})`,
      cowIds: this.cowIds,
      state: 'temporary-group-view-cows',
      params: {
        cowGroupId: this.cowGroupId,
        pen: this.$stateParams.pen
      }
    };

    this.$state.go('temporary-group-view-cow-detail', {
      cowId,
      caller
    });
  }
}

app.controller('TemporaryGroupViewCowsController', TemporaryGroupViewCowsController);
