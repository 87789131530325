class ChartActivityHeatIndexController {
  constructor(
    $q,
    $interval,
    FarmService,
    HeatIndexAPI,
    ChartGenerator,
    appConst,
    $scope,
    IsMobile,
    CowEventAPI,
    DepositCowEventsService
  ) {
    'ngInject';

    this.$q = $q;
    this.$interval = $interval;
    this.FarmService = FarmService;
    this.HeatIndexAPI = HeatIndexAPI;
    this.ChartGenerator = ChartGenerator;
    this.appConst = appConst;
    this.defaults = {
      chartDisplayRange: 30
    };
    this.$scope = $scope;
    this.isMobile = IsMobile;
    this.CowEventAPI = CowEventAPI;
    this.DepositCowEventsService = DepositCowEventsService;
  }

  $onDestroy() {
    if (this.state && this.state.activityRateChart) {
      this.state.activityRateChart.destroy();
    }
  }

  initializeState() {
    // ActivityRateChartのオプションを上書きする
    const options = ActivityRateChart.defaultOptions();
    options.size.height = this.height || options.height;
    options.size.width = this.width || options.width;
    options.padding = {
      left: this.paddingLeft || options.padding.left,
      right: this.paddingRight || options.padding.right,
      top: this.paddingTop || options.padding.top,
      bottom: this.paddingBottom || options.padding.bottom
    };
    options.axis.y.label.position = 'inner-right';

    options.tooltip.show = true;

    const activityRateChart = new ActivityRateChart(
      '#activity-rate-chart', options, this.isMobile);

    return {
      activityPeriod: null,
      baseDate: new Date(),
      activityDate: null,
      activityRateChart: activityRateChart,
      isLoading: false
    };
  }

  loadCowEvent(depositorFarm) {
    if (depositorFarm) {
      return this.DepositCowEventsService.index(this.cow.cowId);
    } else {
      return this.CowEventAPI.show(this.cow.cowId);
    }
  }

  updateGridXLines(depositorFarm) {
    this.loadCowEvent(depositorFarm).then((res) => {
      // 発情グラフに種付日を表示する
      const lines = res.data.filter((item) => {
        return item.eventType === 'tanetsuke' && item.tanetsukeMethod === '人工授精';
      }).map((item) => {
        const startOfDay = DateUtil.startOfDay(item.occurredAt);
        const hours = new Date(item.occurredAt).getHours();
        const minutes = new Date(item.occurredAt).getMinutes();
        const value = minutes < 30 ? DateUtil.toMSec(DateUtil.addHours(startOfDay, hours)) :
          DateUtil.toMSec(DateUtil.addHours(startOfDay, hours + 1));

        return {
          text: '種付日',
          class: 'breeding-date',
          value
        };
      });

      this.state.activityRateChart.updateGridXLines(lines);
    });
  }

  $onInit() {
    this.state = this.initializeState();
    this.setIsLoading(true);

    // 活動量タブの発情チャートデータ
    return this.FarmService.show()
      .then((res) => {
        this.state.accountData = res || this.appConst.default.AccountInfo;
        this.updateGridXLines(res.depositorFarm);
      }).then(() => {
        this.state.regions = this.composeRegions(this.state.accountData, this.appConst.default.AccountInfo);
      }).finally(() => {
        this.state.isLoading = false;
        this.isInitialized = true;
      });
  }

  loadDataAndDrawChart() {
    if (!this.state.activityDate || !this.state.activityPeriod || !this.cow) return;

    this.setIsLoading(true);
    this.state.activityRateChart.clear();

    return this.ChartGenerator.heatIndexChart(
      this.state.activityDate,
      this.cow.cowId,
      this.state.activityPeriod
    ).then((res) => {
      this.state.chartData = res;
    }).then(() => {
      this.drawChart(
        this.state.activityRateChart,
        this.state.chartData,
        this.state.activityPeriod,
        this.state.regions
      );
    }).finally(() => this.setIsLoading(false));
  }

  drawChart(activityRateChart, chartData, activityPeriod, regions) {
    if (this.hasNoData()) return;

    const columns = [
      chartData.x,
      chartData.y
    ];

    let kind;
    if (activityPeriod === 7) {
      kind = ActivityRateChart.KIND.DATE_TIME;
    } else {
      kind = ActivityRateChart.KIND.DATE;
    }

    activityRateChart.show(columns, regions, kind);
  }

  composeRegions(farm, defaultConfig) {
    return [
      {
        axis: 'y',
        start: Number(farm.thresholdActivityRate2 || defaultConfig.thresholdActivityRate2),
        end: Number(farm.thresholdActivityRate3 || defaultConfig.thresholdActivityRate3),
        class: 'score-color3',
        opacity: '0.15'
      },
      {
        axis: 'y',
        start: Number(farm.thresholdActivityRate3 || defaultConfig.thresholdActivityRate3),
        end: Number(farm.thresholdActivityRate4 || defaultConfig.thresholdActivityRate4),
        class: 'score-color4',
        opacity: '0.15'
      },
      {
        axis: 'y',
        start: Number(farm.thresholdActivityRate4 || defaultConfig.thresholdActivityRate4),
        end: Number(farm.thresholdActivityRate5 || defaultConfig.thresholdActivityRate5),
        class: 'score-color5',
        opacity: '0.15'
      }
    ];
  }

  setIsLoading(state) {
    this.state.isLoading = state;
    this.$scope.$broadcast('isLoadingChange', state);
  }

  hasNoData() {
    return this.state &&
     this.state.chartData &&
     (!this.state.chartData.x || !this.state.chartData.y);
  }

  shouldShowHasNoData() {
    return this.hasNoData() && !this.state.isLoading;
  }

  onActivityPeriodChanged(period) {
    this.state.activityPeriod = period;
    this.onChangePeriod({period: period});
    this.loadDataAndDrawChart();
  }

  onActivityDateChanged(activityDate) {
    this.state.activityDate = activityDate;
    this.loadDataAndDrawChart();
  }
}

app.controller('ChartActivityHeatIndexController', ChartActivityHeatIndexController);
