class FatteningStandardReportContainerController {
  constructor(
  ) {
    'ngInject';
  }

  $onChanges(obj) {
    if (this.shouldUpdateReport(obj)) {
      this.drawReport();
    }
  }

  shouldUpdateReport(obj) {
    if (!this.items) return true;
    return obj.itemDefinitions && obj.itemDefinitions.currentValue;
  }

  drawReport() {
    this.headers = this.generateHeaders();
    this.items = this.generateItems();
  }

  getAnnualSummary() {
    return this.annualSummaries.find((summary) => summary.fiscalYear === this.year);
  }

  generateHeaders() {
    if (this.frequency === 'monthly') {
      return this.generateMonthlyHeaders(this.getAnnualSummary(), this.year);
    } else {
      return this.generateYearlyHeaders(this.annualSummaries);
    }
  }

  generateMonthlyHeaders(annualSummary, year) {
    const records = FatteningReport.fillLackMonth(annualSummary.monthly);

    const headers = records.map((record) => {
      const month = record.yearMonth % 100;
      if (month === 99) {
        return `年度累計`;
      }
      return `${month}月`;
    });
    headers.unshift(`${year}年`);

    return headers;
  }

  generateYearlyHeaders(annualSummaries) {
    const headers = annualSummaries.map((s) => `${s.fiscalYear}年`);
    for (let i = 1; i <= 10 - annualSummaries.length; i++) {
      headers.push('');
    }
    headers.unshift('');

    return headers;
  }

  generateItems() {
    if (this.frequency === 'monthly') {
      return FatteningReport.generateMatrix(this.getAnnualSummary(), this.itemDefinitions);
    } else {
      return FatteningReport.generateMatrixYearly(this.annualSummaries, this.itemDefinitions);
    }
  }

  /** イベント **/

  changeFrequency() {
    this.closeDetails();
    this.drawReport();
    this.onChangeFrequency({frequency: this.frequency});
  }

  changeYear() {
    this.closeDetails();
    this.drawReport();
    this.onChangeYear({year: this.year});
  }

  /** 表示 **/

  closeDetails() {
    FatteningReport.closeDetails(this.items);
  }

  generateTableClass() {
    return FatteningReport.generateTableClass(this.frequency);
  }

  generateTrClass(item) {
    return FatteningReport.generateTrClass(item);
  }

  toggleTr(id) {
    FatteningReport.toggleTr(this.items, id, FatteningReport.rowIds.ITEM_ID);
  }

  generateBtnClass(item) {
    return FatteningReport.generateBtnClass(item);
  }
}

function fatteningStandardReportContainer() {
  return {
    templateUrl: 'menu/report/fattening/components/standard-report-container/index.html',
    controller: FatteningStandardReportContainerController,
    bindings: {
      annualSummaries: '<',
      year: '<',
      years: '<',
      frequency: '<',
      itemDefinitions: '<',
      onChangeFrequency: '&',
      onChangeYear: '&',
      onClickItemConfig: '&',
    }
  };
}
app.component('fatteningStandardReportContainer', fatteningStandardReportContainer());
