/**
 * 分娩センサー番号編集モーダルのフォームコンポーネント
 *
 * ex.
 *  <mobile-edit-calving-sensor-history-modal-form
 *    calving-sensor-histories="calvingSensorHistories"
 *    type="type"
 *    on-cancel="onCancel()"
 *    on-submit="onSubmit(params)">
 *  </mobile-edit-calving-sensor-history-modal-form>
 *
 * プロパティ:
 *  @param {Array<Object>} calving-sensor-histories 分娩センサー履歴の配列
 *  @param {string} type 装着('equip') or 解除('unequip') or 交換('replace')
 *  @param {Function} on-cancel キャンセルボタンクリック時に発生するイベント
 *  @param {Function} on-submit 装着/解除ボタンクリック時にに発生するイベント、paramsを引数で渡す
 */
class MobileEditCalvingSensorHistoryModalFormController {
  constructor() {
    'ngInject';

    this.errMsgMap = {
      calvingSensorNumber: '',
      startDate: '',
      endDate: ''
    };
  }

  $onInit() {
    this.calvingSensorHistory = CowCalvingSensorHistory.currentCalvingSensorHistory(this.calvingSensorHistories);
    this.isEquip = this.type === 'equip';
    this.isUnequip = this.type === 'unequip';
    this.isReplace = this.type === 'replace';
    this.formData = this.initFormData();
    if (this.isEquip) {
      this.submitLabel = '適用する';
    } else if (this.isUnequip) {
      this.submitLabel = '解除する';
    } else if (this.isReplace) {
      this.submitLabel = '交換する';
    }
    this.validateCalvingSensorNumber(this.formData.firstNumber, this.formData.lastNumber);
    this.validateStartDate(this.formData.startDate);
    this.validateEndDate(this.formData.endDate);

    this.validate(true);
  }

  initFormData() {
    if (this.isEquip || this.isReplace) {
      return {
        startDate: DateUtil.toMSec(DateUtil.today()),
        firstNumber: '',
        lastNumber: ''
      };
    }

    if (this.isUnequip) {
      const splittedCalvingSensorNumber =
        CowCalvingSensorHistory.splitCalvingSensorNumber(this.calvingSensorHistory.calvingSensorNumber);
      return {
        id: this.calvingSensorHistory.id,
        startDate: this.calvingSensorHistory.startDate,
        endDate: DateUtil.toMSec(DateUtil.today()),
        firstNumber: splittedCalvingSensorNumber.firstNumber,
        lastNumber: splittedCalvingSensorNumber.lastNumber
      };
    }
  }

  validate(init = false) {
    if (!init) this.updateErrMsgMap();
    this.isValid = (
      this.isValidCalvingSensorNumber
      && this.isValidStartDate
      && this.isValidEndDate
    );
  }

  validateCalvingSensorNumber(firstNumber, lastNumber) {
    this.isValidCalvingSensorNumber = CowCalvingSensorHistory.validate(firstNumber, lastNumber);
  }

  validateStartDate(startDate) {
    if (this.isEquip || this.isReplace) {
      const lastCalvingSensorHistory = this.calvingSensorHistories.sort((a, b) => b.endDate - a.endDate)[0];
      if (!lastCalvingSensorHistory) {
        this.isValidStartDate = true;
      } else if (this.isEquip) {
        this.isValidStartDate = startDate > lastCalvingSensorHistory.endDate;
      } else if (this.isReplace) {
        this.isValidStartDate = startDate > lastCalvingSensorHistory.startDate;
      }
    } else {
      this.isValidStartDate = true;
    }
  }

  validateEndDate(endDate) {
    if (this.isUnequip) {
      this.isValidEndDate = this.formData.startDate <= this.formData.endDate;
    } else {
      this.isValidEndDate = true;
    }
  }

  updateErrMsgMap() {
    this.errMsgMap.calvingSensorNumber = this.isValidCalvingSensorNumber ? '' : '分娩センサー番号の形式が正しくありません。';
    if (this.isEquip) {
      this.errMsgMap.startDate = this.isValidStartDate ? '' : '適用日が前回の分娩センサー解除日以前の日付になっています。';
    } else if (this.isReplace) {
      this.errMsgMap.startDate = this.isValidStartDate ? '' : '適用日が交換前の分娩センサー適用日以前の日付になっています。';
    }
    this.errMsgMap.endDate = this.isValidEndDate ? '' : '解除日が適用日より前の日付になっています。';
  }

  canSubmit() {
    return (this.isValid) && (!this.isSubmitting());
  }

  // イベント

  handleChangeCalvingSensorNumber() {
    this.validateCalvingSensorNumber(this.formData.firstNumber, this.formData.lastNumber);
    this.validate();
  }

  handleChangeStartDate() {
    this.validateStartDate(this.formData.startDate);
    this.validate();
  }

  handleChangeEndDate() {
    this.validateEndDate(this.formData.endDate);
    this.validate();
  }

  handleCancel() {
    this.onCancel();
  }

  handleSubmit() {
    this.onSubmit({params: this.formData});
  }
}

function mobileEditCalvingSensorHistoryModalFormComponent() {
  return {
    templateUrl: 'mobile/components/edit-calving-sensor-history-modal/form.html',
    controller: MobileEditCalvingSensorHistoryModalFormController,
    bindings: {
      calvingSensorHistories: '<',
      type: '<',
      onCancel: '&',
      onSubmit: '&',
      isSubmitting: '&',
    }
  };
}

app.component('mobileEditCalvingSensorHistoryModalForm', mobileEditCalvingSensorHistoryModalFormComponent());
