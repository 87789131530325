/**
 * イベント・データを表示する際に時刻やIDをラベルに変換します
 */
// eslint-disable-next-line no-unused-vars
class EventLabelResolverService {
  constructor(
    DateUtilService,
    CowService
  ) {
    'ngInject';

    this.DateUtilService = DateUtilService;
    this.CowService = CowService;
  }

  /**
   * ラベル変換
   * @param {Object} cowEvent イベント・オブジェクト
   * @param {EventSelectionMasterContainer} selectionMaster EventSelectionMasterInitializerServiceで初期化したインスタンスを
   *  引数に設定してください
   */
  resolveLabel(cowEvent, selectionMaster) {
    const resolved = angular.copy(cowEvent);
    const dateConverter = this.DateUtilService.toYYYYMMDD;
    const dateTimeConverter = DateUtil.toYYYYMMDDHHmm;
    const timeConverter = this.DateUtilService.toHHmm;

    if (cowEvent.eventType === 'touta') {
      resolved.eventName = '出荷';
    }

    if (cowEvent.masterMedicineIds) {
      const medications = TimelineItem.toMedicationItem(cowEvent.medications);
      resolved.medicineLabels = medications.map((m) => {
        const capacity = m.capacity ? `（${m.capacity}${m.unit}）` : '';
        const method = m.method || '-';
        const description = `${method} ${capacity}`;
        return {
          name: m.name,
          method: m.method,
          capacity: m.capacity,
          unit: m.unit,
          description: description
        };
      });
    }

    if (cowEvent.mastitisCowGroupId) {
      resolved.mastitisCowGroupIdDisplay = selectionMaster.cowGroupNameMap[cowEvent.mastitisCowGroupId];
    }

    if (cowEvent.moveToCowGroupId) {
      resolved.moveToCowGroupIdDisplay = selectionMaster.cowGroupNameMap[cowEvent.moveToCowGroupId];
    }

    if (cowEvent.beforeCowGroupId) {
      resolved.beforeCowGroupIdDisplay = selectionMaster.cowGroupNameMap[cowEvent.beforeCowGroupId];
    }

    if (cowEvent.cowGroupId) {
      const cowGroupName = selectionMaster.cowGroupNameMap[cowEvent.cowGroupId];
      resolved.cowGroupIdDisplay = cowEvent.pen ? `${cowGroupName}(${cowEvent.pen})` : cowGroupName;
    }

    if (cowEvent.moveToPen) {
      const pen = selectionMaster.cowPenMap[cowEvent.moveToCowGroupId].find((p) => p.value === cowEvent.moveToPen);
      resolved.moveToPenDisplay = pen.label;
    }

    if (cowEvent.occurredAt) {
      resolved.occurredAtDisplay = CowEvent.occuredAtHasTime(cowEvent.eventType)
        ? dateTimeConverter(Number(cowEvent.occurredAt))
        : dateConverter(Number(cowEvent.occurredAt));

      resolved.occurredAtTimeDisplay = timeConverter(Number(cowEvent.occurredAt));
    }

    if (cowEvent.occurredDiseaseDate) {
      resolved.occurredDiseaseDateDisplay = dateConverter(Number(cowEvent.occurredDiseaseDate));
    }

    if (cowEvent.treatmentDiseaseDate) {
      resolved.treatmentDiseaseDateDisplay = dateTimeConverter(Number(cowEvent.treatmentDiseaseDate));
    }

    if (cowEvent.targetBreedingDate) {
      resolved.targetBreedingDateDisplay = dateConverter(Number(cowEvent.targetBreedingDate));
    }

    if (cowEvent.eventType === 'lame') {
      resolved.groupedLameDiagnosises = CowEvent.groupLameDiagnosisesByPart(cowEvent);
    }

    if (cowEvent.eventType === 'mastitis') {
      resolved.groupedMastitisDiagnosises = CowEvent.groupMastitisDiagnosisesByPart(cowEvent);
      const mastitisScore = Mastitis.MASTITIS_SCORES.find((s) => s.value === cowEvent.mastitisScore) || {};
      resolved.mastitisScoreDisplay = mastitisScore.label || '';
    }

    if (cowEvent.endDateOfMilkWashoutPeriod && cowEvent.treatmentDiseaseDate) {
      resolved.endDateOfMilkWashoutPeriodDisplay = dateConverter(Number(cowEvent.endDateOfMilkWashoutPeriod));
    }

    if (cowEvent.endDateOfBeefWashoutPeriod && cowEvent.treatmentDiseaseDate) {
      resolved.endDateOfBeefWashoutPeriodDisplay = dateConverter(Number(cowEvent.endDateOfBeefWashoutPeriod));
    }

    if (cowEvent.hormoneCode) {
      resolved.hormoneCodeDisp = cowEvent.hormoneName || '';
    }

    if (cowEvent.bullCowId) {
      const bullInfo = selectionMaster.tanetsukeBulls.find((b) => Number(b.cowId) === Number(cowEvent.bullCowId));
      resolved.bullCowIdDisplay = bullInfo
        ? `${bullInfo.cowName} (個体識別番号: ${bullInfo.cowUid})`
        : '';
    }

    if (cowEvent.calvingDifficulty) {
      resolved.calvingDifficultyDisplay = selectionMaster.calvingDifficultyMap[cowEvent.calvingDifficulty];
    }

    if (cowEvent.masterSpermNo) {
      resolved.masterSpermNoDisplay = `${cowEvent.masterSpermName}（${cowEvent.masterSpermNo}）`;
    }

    if (cowEvent.childNumber) {
      resolved.childNumbers = [1, 2, 3, 4].slice(0, cowEvent.childNumber);
    }

    if (cowEvent.cowUid) {
      resolved.cowUidDisplay = this.CowService.formatCowUid(cowEvent.cowUid);
    }

    if (cowEvent.eventType === 'embryo_recovery') {
      const nextState = selectionMaster.selection.embryoRecoveryNextCowState
        ? selectionMaster.selection.embryoRecoveryNextCowState
          .find((s) => s.value === cowEvent.nextCowState)
        : null;
      const registered = selectionMaster.selection.embryoMasterRegistered
        ? selectionMaster.selection.embryoMasterRegistered
          .find((s) => s.value === cowEvent.embryoMasterRegistered)
        : null;

      resolved.embryoMasterRegisteredDisplay = registered ? registered.label : '';
      resolved.nextCowStateDisplay = nextState ? nextState.label : '';
    }

    if (cowEvent.eventType === 'bunben') {
      cowEvent.calfs.forEach((calf) => {
        return calf.birthStateString = calf.birthState.join(',');
      });

      resolved.calfs = cowEvent.calfs;
    }

    if (cowEvent.dressedCarcassUnitPrice) {
      resolved.dressedCarcassUnitPriceDisplay = Number(cowEvent.dressedCarcassUnitPrice).toLocaleString();
    }

    if (cowEvent.dressedCarcassSalesPrice) {
      resolved.dressedCarcassSalesPriceDisplay = Number(cowEvent.dressedCarcassSalesPrice).toLocaleString();
    }

    return resolved;
  }
}

app.service('EventLabelResolverService', EventLabelResolverService);
