/* eslint-disable */
class Embryo {
  static get INPUT_METHOD() {
    return {
      AUTO: 'auto',
      MANUAL: 'manual'
    }
  }

  constructor(params, farm) {
    // Change to Object.assign in the future
    this.origin = angular.extend({}, this.defaultContent(), params);
    this.farm = farm;
    this.inputMethod = Embryo.INPUT_METHOD.AUTO;

    this.EMBRYO_SUPPLIER = {
      MARKET: 'market',
      OWN_FARM: 'own-farm',
    }

    this.initializeContent();
  }

  // public content

  /**
   * 項目を初期化する。
   */
  initializeContent() {
    angular.extend(this, this.origin);
  }

  updateName() {
    if (this.inputMethod === Embryo.INPUT_METHOD.MANUAL) return;
    this.name = this.farm.isBeef() ? this.generateBeefEmbryoName() : this.generateMilkEmbryoName();
  }

  isMarket() {
    return this.embryoSupplier === this.EMBRYO_SUPPLIER.MARKET;
  }

  isOwnFarm() {
    return this.embryoSupplier === this.EMBRYO_SUPPLIER.OWN_FARM;
  }

  sperm(sperm) {
    this.masterSpermId = sperm.id;
    this.masterSpermNo = sperm.spermNo;
    this.bullName = sperm.name;
    this.updateName();
  }

  cow(cow) {
    this.donorCowId = cow.cowId;
    this.donorName = cow.cowName || '';
    this.donorRegistrationNo = cow.registrationNo || '';
    this.donorCowUid = cow.cowUid || '';
    this.donorCowNo = cow.cowNo || '';
    this.donorBreed = cow.breed || '';
    this.donorBreedingValue = cow.breedingValue || '';
    this.fatherNameOfDonor = cow.fatherName || '';
    this.fatherRegistrationNoOfDonor = cow.fatherRegistrationNo || '';
    this.motherNameOfDonor = cow.motherName || '';
    this.motherRegistrationNoOfDonor = cow.motherRegistrationNo || '';
    this.motherBreedOfDonor = cow.motherKind || '';
    this.maternalGrandfatherNameOfDonor = cow.maternalGrandfatherName || '';
    this.maternalGrandfatherRegistrationNoOfDonor = cow.maternalGrandfatherRegistrationNo || '';

    this.updateName();
  }

  clearDonor() {
    this.donorCowId = 0;
    this.donorName = '';
    this.donorRegistrationNo = '';
    this.donorCowUid = '';
    this.donorCowNo = '';
    this.donorBreed = '';
    this.donorBreedingValue = '';
    this.fatherNameOfDonor = '';
    this.fatherRegistrationNoOfDonor = '';
    this.motherNameOfDonor = '';
    this.motherRegistrationNoOfDonor = '';
    this.motherBreedOfDonor = '';
    this.maternalGrandfatherNameOfDonor = '';
    this.maternalGrandfatherRegistrationNoOfDonor = '';

    this.updateName();
  }

  // private method

  /**
   * デフォルトオブジェクトを返す。
   *
   * @return {Object} デフォルトオブジェクト
   */
  defaultContent() {
    return {
      name: '',
      masterSpermId: null,
      masterSpermNo: '',
      bullName: '',
      embryoSupplier: 'own-farm',
      donorCowId: 0,
      donorCowNo: '',
      donorName: '',
      donorRegistrationNo: '',
      donorCowUid: '',
      donorBreed: '',
      donorBreedingValue: '',
      fatherNameOfDonor: '',
      fatherRegistrationNoOfDonor: '',
      motherNameOfDonor: '',
      motherRegistrationNoOfDonor: '',
      motherBreedOfDonor: '',
      maternalGrandfatherNameOfDonor: '',
      maternalGrandfatherRegistrationNoOfDonor: ''
    };
  }

  generateBeefEmbryoName() {
    const temp = [this.bullName];

    if (this.farm.useName()) {
      if (this.donorName) temp.push(this.donorName);
    } else {
      if (this.donorName && this.donorCowNo) {
        temp.push(`${this.donorName}(${this.donorCowNo})`);
      } else if (this.donorName) {
        temp.push(this.donorName);
      } else if (this.donorCowNo) {
        temp.push(this.donorCowNo);
      }
    }
    return temp.join(' × ');
  }

  generateMilkEmbryoName() {
    const temp = [this.masterSpermNo];

    if (this.farm.useName() || this.farm.useCowNo()) {
      if (this.donorCowUid && this.donorCowNo) {
        temp.push(`${this.donorCowUid}(${this.donorCowNo})`);
      } else if (this.donorCowUid) {
        temp.push(this.donorCowUid);
      } else if (this.donorCowNo) {
        temp.push(this.donorCowNo);
      }
    } else {
      if (this.donorCowUid) temp.push(this.donorCowUid);
    }
    return temp.join(' × ');
  }
}
