class DepositOperationUserAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  available(qwert) {
    return this.APIService.get(`/deposit/operation_user/available?qwert=${qwert}`);
  }
}

app.service('DepositOperationUserAPI', DepositOperationUserAPI);
