/**
 * U-Motionによく使う色
 */
app.constant('Colors', {
  blue: '#1f77b4',
  limeGreen: '#b4c82d',
  purple: '#b872e9',
  green: '#8aa633',
  grey: '#777a71',
  yellow: '#f8c515',
  orange: '#c87f2c',
  brightOrange: '#ff7f0e',
  skyblue: '#56a9d4',
  deepPurple: '#9229e3'
});
