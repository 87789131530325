class ReproductionSummaryBoxController {
  constructor(
    ReproductionSummaryAPI
  ) {
    'ngInject';
    this.init(ReproductionSummaryAPI);
  }

  init(reproductionSummaryAPI) {
    reproductionSummaryAPI.show().then((res) => {
      this.summary = res.data;
    });
  }

  calcSum() {
    return Object.keys(this.summary).map((key) => this.summary[key]).reduce((acc, cur) => acc + cur);
  }
}

function reproductionSummaryBox() {
  return {
    templateUrl: 'top/count/reproduction-summary-box.html',
    controller: ReproductionSummaryBoxController
  };
}

app.component('reproductionSummaryBox', reproductionSummaryBox());
