class HomeAlertDysstasiaController {
  constructor() {
    'ngInject';
  }
}

function homeAlertDysstasia() {
  return {
    templateUrl: 'menu/home/alert/dysstasia/index.html',
    controller: HomeAlertDysstasiaController,
    controllerAs: 'ctrl',
    bindings: {
      summary: '<'
    }
  };
}

app.component('homeAlertDysstasia', homeAlertDysstasia());
