/**
 * 分娩センサー履歴ダイアログのフォームコンポーネント
 *
 * ex.
 *  <cow-calving-sensor-history-dialog-form
 *    cow-id="cowId"
 *    cow-no="cowNo"
 *    cow-calving-sensor-histories="cowCalvingSensorHistories"
 *    on-equip="onEquip(cowId, calvingSensorNumber, startDate)"
 *    on-unequip="onUnequip(id, endDate)"
 *    on-delete="onDelete(id, startDate)">
 *  </cow-calving-sensor-history-dialog-form>
 *
 * プロパティ:
 *  @param {number} cow-id 牛個体ID
 *  @param {string} cow-no 牛番号
 *  @param {Array<Object>} cow-calving-sensor-histories 分娩センサー履歴の配列
 *  @param {Function} on-equip 装着/交換時に発生するイベント、cowId/calvingSensorNumber/startDateを引数で渡す
 *  @param {Function} on-unequip 解除時に発生するイベント、id/endtDateを引数で渡す
 *  @param {Function} on-delete 削除時に発生するイベント、id/startDateを引数で渡す
 */
class CowCalvingSensorHistoryDialogFormController {
  constructor() {
    'ngInject';

    this.calvingSensorNumberValid = false;
    this.inputDateValid = false;
    this.invalid = true;

    this.disabledUnEquip = true;
  }

  $onChanges(obj) {
    this.init();
  }

  init() {
    this.calvingSensorNumberErrorMessage = '';

    this.showHistories = false;
    this.equipButtonLabel = '装着';

    this.current = {id: null, calvingSensorNumber: null, startDate: null};

    this.inputDate = null;
    this.calvingSensorNumber = null;

    if (this.cowCalvingSensorHistories && this.cowCalvingSensorHistories.length > 0) {
      const current = Object.assign({}, this.cowCalvingSensorHistories[0]);

      this.calvingSensorNumber = current.calvingSensorNumber;
      this.current = current;
      this.calvingSensorNumberValid = true;

      this.showHistories = true;
      if (DateUtil.isInfiniteDate(current.endDate)) this.equipButtonLabel = '交換';
    }
  }

  formatStartDate(startDate) {
    return DateUtil.toYYYYMMDD(startDate);
  }

  formatEndDate(endDate) {
    if (DateUtil.isInfiniteDate(endDate)) return '-';
    return DateUtil.toYYYYMMDD(endDate);
  }

  validate() {
    const invalid = (!this.calvingSensorNumberValid || !this.inputDateValid);

    if (!invalid && this.calvingSensorNumber === this.current.calvingSensorNumber) {
      this.invalid = this.inputDate <= this.current.endDate;
    } else {
      this.invalid = invalid;
    }

    if (!this.showHistories) return;

    if (this.calvingSensorNumber === this.current.calvingSensorNumber) {
      this.disabledUnEquip = !this.inputDateValid;
    } else {
      this.disabledUnEquip = true;
    }
  }

  validateCalvingSensorNumbers(calvingSensorNumber) {
    if (calvingSensorNumber) {
      this.calvingSensorNumberErrorMessage = '';
      this.calvingSensorNumberValid = true;
    } else {
      this.calvingSensorNumberErrorMessage = '半角数字で1文字以上入力して下さい';
      this.calvingSensorNumberValid = false;
    }
    this.validate();
  }

  // イベント

  handleChangeCalvingSensorNumber(calvingSensorNumber) {
    this.calvingSensorNumber = calvingSensorNumber;
    this.validateCalvingSensorNumbers(calvingSensorNumber);
  }

  handleChangeInputDate() {
    this.inputDateValid = DateUtil.isValidDate(this.inputDate);
    this.validate();
  }

  handleClickEquip() {
    const params = {
      cowId: this.cowId,
      calvingSensorNumber: this.calvingSensorNumber,
      startDate: this.inputDate
    };
    this.onEquip(params);
  }

  handleClickUnequip() {
    const params = {
      id: this.current.id,
      endDate: this.inputDate
    };
    this.onUnequip(params);
  }

  handleClickDelete(calvingSensorHistory) {
    const params = {
      id: calvingSensorHistory.id,
      startDate: calvingSensorHistory.startDate
    };
    this.onDelete(params);
  }
}

function cowCalvingSensorHistoryDialogFormComponent() {
  return {
    templateUrl: 'components/dialog/cow-calving-sensor-history/form.html',
    controller: CowCalvingSensorHistoryDialogFormController,
    transclude: true,
    bindings: {
      cowId: '<',
      cowNo: '<',
      cowCalvingSensorHistories: '<',
      onEquip: '&',
      onUnequip: '&',
      onDelete: '&'
    }
  };
}

app.component('cowCalvingSensorHistoryDialogForm', cowCalvingSensorHistoryDialogFormComponent());
