class CowshedController {
  constructor(
    $scope,
    SessionCache
  ) {
    'ngInject';

    this.init($scope, SessionCache);
  }

  init($scope, SessionCache) {
    $scope.$watch(() => SessionCache.umPlatformPartner(), (newValue) => {
      this.umPlatformPartner = newValue;
    });
  }

  shouldShowCoolPescon() {
    if (!this.umPlatformPartner) return false;
    return this.umPlatformPartner.hasCoolPescon();
  }

  shouldShowLegacySensor() {
    if (!this.umPlatformPartner) return false;
    return !this.umPlatformPartner.hasCoolPescon();
  }
}

app.controller('CowshedController', CowshedController);
