class SelectionDialogController {
  constructor(
    $scope,
    $modalInstance,
    params,
    UserDefinedSelectionAPI,
    SelectionService,
    MasterCowLabelAPI) {
    'ngInject';
    this.$scope = $scope;
    this.$modalInstance = $modalInstance;

    this.initialize(params, UserDefinedSelectionAPI, SelectionService, MasterCowLabelAPI);
  }

  initialize(params, UserDefinedSelectionAPI, SelectionService, MasterCowLabelAPI) {
    this.$scope.$on('ngRepeatFinished', () => {
      FixedMidashi.create();
    });

    const columnId = params.columnId;
    this.title = params.title || '条件選択';
    this.caption = params.caption;
    this.needToSelect = params.needToSelect || false;
    this.conditions = params.conditions || [];
    const currentSelections = params.currentSelections;
    const excludeSelections = params.excludeSelections || [];

    if (columnId === 'cow_labels') {
      MasterCowLabelAPI.available().then((res) => {
        const selectableMasters = CollectionUtil.selectableMasters(res.data, this.conditions, 'name');
        this.selections = selectableMasters.filter((r) => {
          if (this.conditions.length === 0) {
            return true;
          }
          return this.conditions.includes(r.name);
        }).map((r) => {
          const selected = currentSelections.includes(r.name);
          return {
            key: r.id,
            label: r.name,
            selected: selected
          };
        });
      });
      return;
    }

    let action;
    if (CustomlistSelection.isUserDefined(columnId)) {
      action = this.loadUserDefinedSelection(UserDefinedSelectionAPI, columnId);
    } else {
      action = this.loadSelection(SelectionService, columnId);
    }

    action.then((selections) => {
      this.selections = selections
        .filter((s) => {
          if (this.conditions.length === 0) {
            return true;
          }
          return this.conditions.includes(s.value);
        })
        .filter((s) => {
          return !excludeSelections.includes(s.value);
        })
        .map((s) => {
          const selected = currentSelections.includes(s.value);
          return {
            key: s.value,
            label: s.label,
            selected: selected
          };
        });

      this.validate();
    });
  }

  validate() {
    this.invalid = false;

    if (!this.needToSelect) return;

    if (this.selections.some((s) => s.selected)) {
      return;
    }

    this.invalid = true;
  }

  loadUserDefinedSelection(UserDefinedSelectionAPI, columnId) {
    const map = {
      buyer: {'selectionKey': 'buyers'},
      cow_labels: {'selectionKey': 'settingLabels'},
      introduce_state: {'selectionKey': 'introduceStates'},
      judge_pregnant_timing: {'selectionKey': 'judgePregnantTimings'},
      other_reproduction_work: {'selectionKey': 'otherReproductionWorks'},
      producing_area: {'selectionKey': 'producingAreas'},
      producing_farm_name: {'selectionKey': 'producingFarmNames'},
      raising_farm_name: {'selectionKey': 'raisingFarmNames'},
      vaccine_timing: {'selectionKey': 'vaccineTimings'},
    };
    const selectionKey = map[columnId].selectionKey;

    return UserDefinedSelectionAPI.available().then((res) => {
      const userDefinedSelections = res.data;
      const selections = StringUtil.splitByNewline(userDefinedSelections[selectionKey]);
      return selections.map((s) => {
        return {value: s, label: s};
      });
    });
  }

  loadSelection(SelectionService, columnId) {
    const map = {
      breed: {'selectionKey': 'breed'},
      breeding_exclusion_reason: {'selectionKey': 'breedingExclusionReason'},
      castrat_method: {'selectionKey': 'castratMethod'},
      color: {'selectionKey': 'color'},
      dehorn_method: {'selectionKey': 'dehornMethod'},
      expelled_reason: {'selectionKey': 'expelledReason'},
      gender: {'selectionKey': 'gender'},
      latest_breeding_method: {'selectionKey': 'tanetsukeMethod'},
      pregnancy: {'selectionKey': 'pregnancy'},
      pregnant_breeding_method: {'selectionKey': 'tanetsukeMethod'},
      state: {'selectionKey': 'state'},
    };
    const selectionKey = map[columnId].selectionKey;

    return SelectionService.index().then((res) => {
      const selections = res[selectionKey];
      return selections;
    });
  }

  clickRow(selection) {
    selection.selected = !selection.selected;
    this.validate();
  }

  ok() {
    const result = this.selections
      .filter((s) => s.selected)
      .map((s) => {
        return s.label;
      });
    this.$modalInstance.close(result);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('SelectionDialogController', SelectionDialogController);
