class BulkEntryEventContext {
  constructor(
    $q,
    FarmService,
    SelectionService,
    CowEventsService,
    CowService,
    CowGroupService,
    MasterHormoneProgramAPI,
    MasterMedicineAPI,
    MasterSpermAPI,
    MasterDiseaseAPI,
    UserDefinedSelectionAPI,
    PregnantDiagnosisTimingAPI,
    OperationUserService,
    AccountService,
    MasterEmbryoAPI,
    MasterWorkNoteTypeAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.FarmService = FarmService;
    this.SelectionService = SelectionService;
    this.CowEventsService = CowEventsService;
    this.CowService = CowService;
    this.CowGroupService = CowGroupService;
    this.MasterMasterHormoneProgramAPI = MasterHormoneProgramAPI;
    this.MasterMedicineService = MasterMedicineAPI;
    this.MasterSpermService = MasterSpermAPI;
    this.MasterDiseaseAPI = MasterDiseaseAPI;
    this.UserDefinedSelectionAPI = UserDefinedSelectionAPI;
    this.PregnantDiagnosisTimingAPI = PregnantDiagnosisTimingAPI;
    this.OperationUserService = OperationUserService;
    this.AccountService = AccountService;
    this.MasterEmbryoAPI = MasterEmbryoAPI;
    this.MasterWorkNoteTypeAPI = MasterWorkNoteTypeAPI;
  }

  load(allowInactiveCow) {
    return this.$q.all([
      this.CowService.deprecatedDigest(allowInactiveCow),
      this.CowGroupService.index(),
      this.FarmService.show(),
      this.SelectionService.index(),
      this.MasterDiseaseAPI.available(),
      this.UserDefinedSelectionAPI.available(),
      this.PregnantDiagnosisTimingAPI.show(),
      this.OperationUserService.available(),
      this.AccountService.cachedAccount(),
      this.MasterEmbryoAPI.available(),
      this.MasterWorkNoteTypeAPI.available()
    ]);
  }

  bulkImport(cowEvents, options = {}) {
    return this.CowEventsService.insertInBulk(cowEvents, options);
  }

  loadDeprecatedDigestCows(allowInactiveCow) {
    return this.CowService.deprecatedDigest(allowInactiveCow);
  }

  loadSperms() {
    return this.MasterSpermService.available();
  }

  loadHormonePrograms() {
    return this.MasterMasterHormoneProgramAPI.available();
  }

  findBredByCow(cowId) {
    return this.CowEventsService.getByEventType(cowId, 'tanetsuke');
  }

  loadMasterEmbryo() {
    return this.MasterEmbryoAPI.available();
  }

  findEventByCow(cowId, eventType) {
    return this.CowEventsService.getByEventType(cowId, eventType);
  }
}

app.service('BulkEntryEventContext', BulkEntryEventContext);
