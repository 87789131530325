class CowshedLegacySensorController {
  constructor(
    $state,
    DateTimeUtilFactory,
    CowshedService,
    EnvironmentService,
    DateUtilService
  ) {
    'ngInject';

    this.$state = $state;
    this.EnvironmentService = EnvironmentService;
    this.DateUtilService = DateUtilService;
    this.thermoChart = new ThermoChart('#temperature');
    this.humidityChart = new HumidityChart('#humidity');
    this.stressChart = new StressChart('#stress');
    this.chartTypes = EnvironmentChart.CHART_TYPES;
    this.stressRegions = this.chartTypes.stress.regions;
    this.startDate;
    this.endDate;

    this.selection = {};
    this.selection.temp = [
      {'label': '平均気温', 'value': 'avg'},
      {'label': '最高気温', 'value': 'max'},
      {'label': '最低気温', 'value': 'min'}
    ];
    this.temp = 'avg';

    this.selection.hum = [
      {'label': '平均湿度', 'value': 'avg'},
      {'label': '最高湿度', 'value': 'max'},
      {'label': '最低湿度', 'value': 'min'}
    ];
    this.hum = 'avg';

    this.initialize(CowshedService, DateTimeUtilFactory);
  }

  // initialize event
  initialize(cowshedService, dateTimeUtilFactory) {
    cowshedService.index().then((res) => {
      const cowSheds = res.data.filter((shed) => this.checkIfHasSensor(shed));

      this.getLatestAllData(cowSheds).then((res) => {
        this.cowSheds = this.reformatDataForDisplay(res.data, cowSheds);

        const now = dateTimeUtilFactory.parseClientDate(new Date());

        return this.createDailyEnvironmentData(getDate(now), 24 * 60, 7, 'prev');
      }).then((res) => this.createAllWeekChart(this.chartData = res.data))
        .catch((err) => console.error(err));

    }).catch((err) => console.error(err));
  }

  selectShed(cowshedId) {
    this.$state.go('senser', {id: cowshedId}, {parent: this.$state.current});
  }

  getLatestAllData(sheds) {
    sheds = sheds || [];
    let bleIdList = [];

    sheds.forEach((shed) => {
      let bleIds = toBleIds(shed);
      if (bleIds.length > 0) {
        bleIdList.push(bleIds.join(','));
      }
    });

    return this.EnvironmentService.getLatestEnvironment(bleIdList.join(','));
  }

  createChartData(chartData, chartName, period) {
    chartData = chartData || {};

    let summary = [];
    chartData.forEach((daily) => {
      let obj = {};
      // Unixtime(ミリ秒)を YYYY-MM-DD に変更して保持する
      obj.date = this.DateUtilService.toW3CFormat(daily.date);

      daily.status.forEach((senser) => {
        this.cowSheds.forEach((shed) => {
          let bleIds = toBleIds(shed);
          if (bleIds.indexOf(senser.bleId) >= 0) {
            // 初期化
            obj[shed.cowshedId] = obj[shed.cowshedId] || {};
            obj[shed.cowshedId]['name'] = obj[shed.cowshedId]['name'] || '';
            obj[shed.cowshedId]['max_temperature'] = obj[shed.cowshedId]['max_temperature'] || [];
            obj[shed.cowshedId]['min_temperature'] = obj[shed.cowshedId]['min_temperature'] || [];
            obj[shed.cowshedId]['avg_temperature'] = obj[shed.cowshedId]['avg_temperature'] || [];
            obj[shed.cowshedId]['max_humidity'] = obj[shed.cowshedId]['max_humidity'] || [];
            obj[shed.cowshedId]['min_humidity'] = obj[shed.cowshedId]['min_humidity'] || [];
            obj[shed.cowshedId]['avg_humidity'] = obj[shed.cowshedId]['avg_humidity'] || [];

            // データセット
            obj[shed.cowshedId]['name'] = shed.cowshedName;
            obj[shed.cowshedId]['max_temperature'].push(parseFloat(senser.maxTemperature));
            obj[shed.cowshedId]['min_temperature'].push(parseFloat(senser.minTemperature));
            obj[shed.cowshedId]['avg_temperature'].push(parseFloat(senser.avgTemperature));
            obj[shed.cowshedId]['max_humidity'].push(parseFloat(senser.maxHumidity));
            obj[shed.cowshedId]['min_humidity'].push(parseFloat(senser.minHumidity));
            obj[shed.cowshedId]['avg_humidity'].push(parseFloat(senser.avgHumidity));
          }
        });
      });
      summary.push(obj);
    });

    this.summary = this.summary || {};
    this.summary[period] = this.summary[period] || {};
    if (chartName === 'all') {
      this.summary[period]['temperature'] = summary;
      this.summary[period]['humidity'] = summary;
      this.summary[period]['stress'] = summary;
    } else {
      this.summary[period][chartName] = summary;
    }
  }

  // start より、length 日分の、最高・最低・平均温度、湿度を取得し内部変数に保持する
  createDailyEnvironmentData(start, interval, length, retreat) {
    const sheds = this.cowSheds || [];
    const bleIdList = sheds.map((shed) => toBleIds(shed).join(','));

    const column = createTimeLineColumn(start, interval, length, retreat);

    this.startDate = column[0]; // YYYY-MM-DD
    this.endDate = column[column.length - 1]; // YYYY-MM-DD

    return this.EnvironmentService.getDailyEnvironment(bleIdList.join(','), this.startDate, this.endDate);
  }

  selectChartData(chartType, chartName, period) {
    let data = [];
    let cowSheds = this.cowSheds;
    let summary = this.summary[period][chartName];
    let dataType = chartType + '_' + chartName;
    let temp = 0, hum = 0;

    summary.forEach((daily) => {
      let obj = {};
      daily = daily || {};
      obj.date = daily.date;

      cowSheds.forEach((shed) => {
        if (chartName === 'stress') {
          // ここでストレスの算出を行う
          if (daily[shed.cowshedId]) {
            temp = sum(daily[shed.cowshedId][chartType + '_' + 'temperature']) / daily[shed.cowshedId][chartType + '_' + 'temperature'].length;
            hum = sum(daily[shed.cowshedId][chartType + '_' + 'humidity']) / daily[shed.cowshedId][chartType + '_' + 'humidity'].length;
            obj[shed.cowshedName] = (calculateStress(temp, hum)).toFixed(1);
          } else {
            obj[shed.cowshedName] = 0;
          }
        } else {
          obj[shed.cowshedName] = daily[shed.cowshedId] ?
            (sum(daily[shed.cowshedId][dataType]) / daily[shed.cowshedId][dataType].length).toFixed(1) :
            0;
        }
      });
      data.push(obj);
    });

    data = completionDataForWeek(data, this.startDate, this.endDate);

    this[chartName] = this[chartName] || {};
    this[chartName].chartName = chartName;
    this[chartName].chartType = chartType;
    this[chartName].period = period;
    this[chartName].farstColumn = data.length !== 0 ? data[0].date : this.startDate;
    this[chartName].lastColumn = data.length !== 0 ? data[data.length - 1].date : this.endDate;
    this[chartName].displayDate = moment(this[chartName].lastColumn).format('YYYY年MM月DD日');
    return data;
  }

  createAllWeekChart(chartData) {
    this.createChartData(chartData, 'all', 'week');
    // 温度
    let thermoData = this.selectChartData('avg', this.chartTypes.temperature.name, 'week');
    if (_.isEmpty(thermoData)) {
      appendEmptyMessage(this.chartTypes.temperature.name);
    } else {
      clearEmptyMessage(this.chartTypes.temperature.name);
      this.thermoChart.show(thermoData);
    }
    // 湿度
    let humidityData = this.selectChartData('avg', this.chartTypes.humidity.name, 'week');
    if (_.isEmpty(humidityData)) {
      appendEmptyMessage(this.chartTypes.humidity.name);
    } else {
      clearEmptyMessage(this.chartTypes.humidity.name);
      this.humidityChart.show(humidityData);
    }
    // ストレス
    let stressData = this.selectChartData('avg', this.chartTypes.stress.name, 'week');
    if (_.isEmpty(stressData)) {
      appendEmptyMessage(this.chartTypes.stress.name);
    } else {
      clearEmptyMessage(this.chartTypes.stress.name);
      this.stressChart.show(stressData, this.stressRegions);
    }
  }

  changeTypeChart(chartName) {
    let period = this[chartName].period;
    let result = this.judgeTargetChart(chartName);

    this.startDate = this[chartName].farstColumn;
    this.endDate = this[chartName].lastColumn;
    result.target.show(this.selectChartData(result.chartType, chartName, period));
  }

  changeMoveChart(chartName, retreat) {
    let period = this[chartName].period;
    let targetDate = retreat === 'next' ? this[chartName].lastColumn : this[chartName].farstColumn;
    let result = this.judgeTargetChart(chartName);

    this.createDailyEnvironmentData(getDate(targetDate), 24 * 60, 7, retreat).then((res) => {
      this.createChartData(res.data, chartName, period);
      if (!res.data.length) {
        appendEmptyMessage(chartName);
        let tempFirstColumn = this[chartName].farstColumn;
        let tempLastColumn = this[chartName].lastColumn;
        if (retreat === 'next') {
          this[chartName].farstColumn = moment(tempLastColumn).add(1, 'days').format('YYYY-MM-DD');
          this[chartName].lastColumn = moment(tempLastColumn).add(7, 'days').format('YYYY-MM-DD');
        } else if (retreat === 'prev') {
          this[chartName].lastColumn = moment(tempFirstColumn).subtract(1, 'days').format('YYYY-MM-DD');
          this[chartName].farstColumn = moment(tempFirstColumn).subtract(7, 'days').format('YYYY-MM-DD');
        }
        this[chartName].displayDate = moment(this[chartName].lastColumn).format('YYYY年MM月DD日');
      } else {
        clearEmptyMessage(chartName);
        if (chartName === this.chartTypes.stress.name) {
          result.target.show(this.selectChartData(result.chartType, chartName, period), this.stressRegions);
        } else {
          result.target.show(this.selectChartData(result.chartType, chartName, period));
        }
      }
    });
  }

  judgeTargetChart(chartName) {
    let result = {};
    if (chartName === 'temperature') {
      result.target = this.thermoChart;
      result.chartType = this.temp;
    }

    if (chartName === 'humidity') {
      result.target = this.humidityChart;
      result.chartType = this.hum;
    }
    if (chartName === 'stress') {
      result.target = this.stressChart;
      result.chartType = 'avg';
    }
    return result;
  }

  /**
   * ストレス値の色を変更する。
   *
   * @param {number} value ストレス値
   * @return {string} クラス名
   */
  changeColorForStress(value) {
    for (let i = 0, length = this.stressRegions.length; i < length; i++) {
      if (i === 0) {
        if (this.stressRegions[i].start <= value && this.stressRegions[i].end >= value) {
          return this.stressRegions[i].class;
        }
      } else {
        if (this.stressRegions[i].start < value && this.stressRegions[i].end >= value) {
          return this.stressRegions[i].class;
        }
      }
    }
  }

  /**
   * センサーが牛舎に設定されているか確認する。
   * @param {Object} shed 牛舎オブジェクト
   * @return {boolean} true: センサー有, false: センサー無
   */
  checkIfHasSensor(shed) {
    return this.checkIfHasProperties(shed, ['bleId1', 'bleId2', 'bleId3', 'bleId4', 'bleId5']);
  }

  /**
   * プロパティーの有無を確認するヘルパー
   * @param {Object} obj 確認するオブジェクト
   * @param {Array} properties 確認したいプロパティー
   * @return {boolean}
   */
  checkIfHasProperties(obj, properties) {
    return properties.some((key) =>
      obj.hasOwnProperty(key) && obj[key]);
  }

  reformatDataForDisplay(resolve, cowsheds) {
    resolve = resolve || {};
    let dataList = resolve.status;
    let summary = {};

    return cowsheds.map((shed) => {
      summary[shed.cowshedId] = {};
      summary[shed.cowshedId].temperature = [];
      summary[shed.cowshedId].humidity = [];
      dataList = dataList || [];
      dataList.forEach(function(data) {
        let bleIds = toBleIds(shed);
        if (bleIds.indexOf(data.bleId) >= 0) {
          summary[shed.cowshedId].temperature.push(parseFloat(data.temperature));
          summary[shed.cowshedId].humidity.push(parseFloat(data.humidity));
        }
      });

      // 気温の算出
      shed.latestTemperature = !_.isEmpty(summary[shed.cowshedId].temperature) ?
        (sum(summary[shed.cowshedId].temperature) / summary[shed.cowshedId].temperature.length).toFixed(1) : 0;
      // 湿度の算出
      shed.latestHumidity = !_.isEmpty(summary[shed.cowshedId].humidity) ?
        (sum(summary[shed.cowshedId].humidity) / summary[shed.cowshedId].humidity.length).toFixed(1) : 0;
      // ストレス指数の算出
      shed.latestStress = (!_.isEmpty(summary[shed.cowshedId].temperature) &&
        !_.isEmpty(summary[shed.cowshedId].humidity)) ?
        (calculateStress(shed.latestTemperature, shed.latestHumidity)).toFixed(1) : 0;

      // データの有無を確認して表示フラグを設定
      shed.hasData = this.checkIfHasProperties(shed, ['latestStress', 'latestTemperature', 'latestHumidity']);

      return shed;
    });
  }
}

function cowshedLegacySensorComponent() {
  'ngInject';
  return {
    controller: CowshedLegacySensorController,
    controllerAs: 'ctrl',
    templateUrl: 'menu/environment/cowshed/legacy-sensor/index.html'
  };
}
app.component('cowshedLegacySensor', cowshedLegacySensorComponent());
