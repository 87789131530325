// ストレスグラフ
// eslint-disable-next-line no-unused-vars
class StressChart {
  constructor(selector) {
    this.options = {
      bindto: selector,
      onrendered: function() {
        let axisX = $(selector + ' .c3-axis.c3-axis-x .c3-axis-x-label');
        axisX.attr('x', Number(axisX.attr('x')) + 40);
        axisX.attr('y', -20);
      },
      padding: {
        right: 40,
        bottom: 30
      },
      size: {
        height: 200,
      },
      data: {
        type: 'line',
        x: 'date',
        json: [],
        keys: {
          x: 'date',
          value: ['牛舎1', '牛舎2', '牛舎3', '牛舎4', '牛舎5']
        },
      },
      axis: {
        y: {
          label: {
            text: 'THI(pt)',
            position: 'outer-top'
          },
          max: 100,
          min: 40,
          padding: 0
        },
        x: {
          label: {
            text: '日時',
            position: 'outer-right'
          },
          type: 'timeseries',
          tick: {
            fit: true,
            rotate: -50,
            format: '%m/%d'
          }
        }
      },
      subchart: {
        show: false
      },
      point: {
        show: false
      }
    };
  }

  show(data, regions) {
    this.options.regions = regions;
    if (!data || data.length < 1) return;
    let colums = getChartColumns(data[0]);
    this.options.data.json = data;
    this.options.data.keys.value = colums;
    this.chart = c3.generate(this.options);
  }
}
