class MobileTopScheduleController {
  constructor() {
    'ngInject';

    const base = new TopScheduleController();

    TopScheduleBinder.bind(this, base);
  }

  shouldShowHasNoData() {
    if (Object.keys(this.total).length === 0) return false;

    return !Object.values(this.total).reduce((a, b) => a + b);
  }
}

function mobileTopSchedule() {
  return {
    templateUrl: 'mobile/schedule/index.html',
    controller: MobileTopScheduleController,
    controllerAs: 'ctrl'
  };
}

app.component('mobileTopSchedule', mobileTopSchedule());
