function firstAiDimChartDirective(
  DateUtilService,
  ReproductionPerformanceAPI,
  Dictionary
) {
  'ngInject';

  /**
  * データの取得
  */
  function loadFirstAiDims(startDate, endDate) {
    return ReproductionPerformanceAPI.firstAiDim(startDate, endDate);
  }

  function drawChart(element, firstAiDims, baseDate, vwp) {
    // 配列を分娩日降順に並び替え。
    firstAiDims = firstAiDims.sort((a, b) => {
      return b.calvingDate - a.calvingDate;
    });

    // 指定した日付からグラフが表示されるよう、baseDate時点での空データを追加。
    firstAiDims.unshift({
      birthNumber: null,
      calvingDate: baseDate
    });

    // 最初の日付から最後の日付までの期間が６ヶ月に満たない場合、グラフの表示期間が６ヶ月未満にならないように空データを追加。
    let firstDate = firstAiDims[firstAiDims.length - 1].calvingDate;
    let lastDate = firstAiDims[0].calvingDate;
    if (DateUtilService.diffMonths(firstDate, lastDate) < 6) {
      firstDate = DateUtilService.subtractMonths(lastDate, 6).getTime();
      firstAiDims.unshift({
        birthNumber: null,
        calvingDate: firstDate
      });
    }

    const formattedFirstAiDims = firstAiDims.map((e) => {
      const primal = e.birthNumber === 1 ? e.firstAiDim : null;
      const second = e.birthNumber === 2 ? e.firstAiDim : null;
      const multi = e.birthNumber > 2 ? e.firstAiDim : null;
      return ({
        cowNo: e.cowNo,
        primal: primal,
        second: second,
        multi: multi,
        calvingDate: DateUtilService.toYYYYMMDD(e.calvingDate, '-')
      });
    });

    const maxFirstAiDim = Math.max(...firstAiDims.map((c) => c.firstAiDim));
    const targetFirstAIDim = vwp + 20;

    let yearLines = [], xTickValues = [];
    const firstYear = new Date(firstDate).getFullYear();
    const lastYear = new Date(lastDate).getFullYear();
    for (let i = 0; i < lastYear - firstYear; i++) {
      yearLines.push(lastYear - i);
    }
    xTickValues = DateUtilService.yearMonths(new Date(firstDate), new Date(lastDate)).map((e) => DateUtilService.toYYYYMMDD(new Date(e.year, e.month), '-')).slice(1);
    yearLines = yearLines.map((e) => {
      return {
        value: `${e}-01-01`,
        class: 'year',
        text: `${e}年`
      };
    });

    function roundLabelMarks() {
      d3.selectAll('#first-ai-dim-chart line.c3-legend-item-tile').each(function(d, i, nodes) {
        this.style.strokeLinecap = 'round';
        this.setAttribute('x1', this.getAttribute('x2'));
      });
    }

    c3.generate({
      bindto: element,
      size: {
        width: 670
      },
      data: {
        colors: {
          'primal': 'blue',
          'second': 'red',
          'multi': 'green'
        },
        xSort: false,
        json: formattedFirstAiDims,
        keys: {
          x: 'calvingDate',
          value: ['primal', 'second', 'multi']
        },
        type: 'scatter',
      },
      axis: {
        x: {
          type: 'timeseries',
          label: {
            text: '分娩日',
            position: 'outer-right'
          },
          tick: {
            format: '%m/%d',
            values: xTickValues,
          },
        },
        y: {
          label: {
            text: '初回授精日数',
            position: 'outer-top'
          },
          min: 0,
          max: maxFirstAiDim + 10,
          padding: {
            bottom: 0
          }
        }
      },
      legend: {
        item: {
          onclick: function(id) {
            let legend, circles;
            switch (id) {
            case 'primal':
              legend = d3.select('.c3-legend-item-primal');
              circles = d3.selectAll('#first-ai-dim-chart circle.c3-shape').filter((d, i) => d.id === id);
              break;
            case 'second':
              legend = d3.select('.c3-legend-item-second');
              circles = d3.selectAll('#first-ai-dim-chart circle.c3-shape').filter((d, i) => d.id === id);
              break;
            case 'multi':
              legend = d3.select('.c3-legend-item-multi');
              circles = d3.selectAll('#first-ai-dim-chart circle.c3-shape').filter((d, i) => d.id === id);
              break;
            }

            legend.classed('pale', !legend.classed('pale'));
            circles.each(function() {
              const circle = d3.select(this);
              circle.classed('transparent', !circle.classed('transparent'));
            });
          }
        }
      },
      tooltip: {
        contents: (data) => {
          let cowNo = formattedFirstAiDims[data[0].index].cowNo;
          return `
            <div class="first-ai-dim-chart-tooltip">
              <table>
                <tbody>
                  <tr>
                    <td>${Dictionary.COW.COW_NO}</td>
                    <td>${cowNo}</td>
                  </tr>
                </tbody>
              </table>
            </div>`;
        }
      },
      grid: {
        x: {
          lines: yearLines
        },
        y: {
          lines: [{value: vwp, text: 'VWP'}, {value: targetFirstAIDim, class: 'grid-desired-first-ai-dim', text: '目標初回授精日数'}]
        }
      },
      zoom: {
        enabled: true,
        rescale: true
      },
      onrendered: function() {
        roundLabelMarks();
        d3.selectAll('#first-ai-dim-chart .c3-ygrid-line text').attr('dy', '-2');
        const legendItemPrimal = d3.select('.c3-legend-item-primal');
        const legendItemSecond = d3.select('.c3-legend-item-second');
        const legendItemMulti = d3.select('.c3-legend-item-multi');
        const primalLegendXCoordinates = {
          text: Number(legendItemPrimal.select('text').attr('x')),
          line: Number(legendItemPrimal.select('line').attr('x1'))
        };
        legendItemPrimal.select('text').html('初産');
        legendItemSecond.select('text').attr('x', primalLegendXCoordinates.text + 70).html('２産');
        legendItemSecond.select('line').attr('x1', primalLegendXCoordinates.line + 70).attr('x2', primalLegendXCoordinates.line + 70);
        legendItemMulti.select('text').attr('x', primalLegendXCoordinates.text + 140).html('３産以上');
        legendItemMulti.select('line').attr('x1', primalLegendXCoordinates.line + 140).attr('x2', primalLegendXCoordinates.line + 140);
      },
      onresized: function() {
        roundLabelMarks();
      },
    });
  }

  return {
    restrict: 'E',
    template: `<loading class="u-loader"></loading><div id="first-ai-dim-chart" height="320" width="670"></div>`,
    link: (scope, element, attrs) => {
      const firstAiDimChart = document.getElementById('first-ai-dim-chart');
      const uLoader = document.querySelector('.first-ai-dim-chart-wrapper .u-loader');

      function showTooltip(e) {
        const tooltip = d3.select('.first-ai-dim-chart-tooltip');
        tooltip.classed('show-tooltip', !tooltip.classed('show-tooltip'));
      }

      function toggleChart(loading) {
        if (loading) {
          firstAiDimChart.style.display = 'none';
          uLoader.style.display = 'block';
        } else {
          uLoader.style.display = 'none';
          firstAiDimChart.style.display = 'block';
        }
      }

      firstAiDimChart.onclick = showTooltip;

      scope.$on('drawChart', (e, args) => {
        toggleChart(true);
        loadFirstAiDims(args.startDate, args.endDate).then((res) => {
          toggleChart(false);
          drawChart('#first-ai-dim-chart', res.data, args.endDate, args.vwp);
        }).catch((err) => console.error(err));
      });

    }
  };
}

app.directive('firstAiDimChart', ['DateUtilService', 'ReproductionPerformanceAPI', 'Dictionary', firstAiDimChartDirective]);
