class MastitisListController {
  constructor($rootScope, $scope, $state, OrderedListService, UtilService, DateUtilService) {
    'ngInject';

    this.title = '乳房炎リスト';
    this.$rootScope_ = $rootScope;
    this.$scope_ = $scope;
    this.$state_ = $state;
    this.orderedListService_ = OrderedListService;
    this.utilService_ = UtilService;
    this.DateUtilService = DateUtilService;
    // 印刷用の処理
    this.parameterForPrint_ = getPathParams();
    this.initialize_();
  }

  // public method
  /**
   * 値を真偽値にして返す。
   *
   * @param {string} value
   * @return {boolean} true or false
   */
  toBoolean(value) {
    return this.utilService_.toBoolean(value);
  }

  /**
   * 印刷モードの場合はtrueを返す。
   *
   * @return {boolean} true or false
   */
  printable() {
    return this.utilService_.toBoolean(this.parameterForPrint_.printable);
  }

  current() {
    return this.$state_.current;
  }

  /**
   * 印刷ボタンクリック処理
   */
  print() {
    const params = params || [];
    params.push('mode=1'); // src/js/app.js で使用しているのでセットする
    params.push('printable=true');

    let context = location.href.split('#');
    let href = context[0] + '#/mastitis-list?' + params.join('&');
    let windowStyle = 'width=1100,height=600,menubar=no,toolbar=no,location=no,scrollbars=yes';
    window.open(href, 'mastitis-list', windowStyle);
  }

  /**
   * 休薬終了日によって背景色を変更する。
   *
   * @param {number} unixTime 休薬終了日
   * @return {string}
   */
  changeBackgroundEndMilkWashoutPeriod(unixTime) {
    if (!unixTime) return 'no-end-date-milk-washout-period';

    let diff = this.DateUtilService.diffDays(today().getTime(), Number(unixTime));
    if (diff < 2) return 'end-date-2-days-ago';
    if (diff < 3) return 'end-date-3-days-ago';
    return '';
  }

  /**
   * 牛群IDがnullまたは前回の牛群IDと違う場合はクラス名を返す。
   *
   * @param {Object} cowGroupId 牛群ID
   * @param {boolean} first 最初の要素かどうか
   * @return {string} クラス名 or ''
   */
  addClassOfCowGroup(cowGroupId, first) {
    if (first) {
      this.prevCowGroupId = cowGroupId;
      return '';
    }

    if (this.prevCowGroupId !== cowGroupId) {
      this.prevCowGroupId = cowGroupId;
      return 'start-cow-group';
    }
    return '';
  }

  // private method

  initialize_() {
    // 作業日作成
    this.workingDay = '作業日：' + DateUtil.toYYMMDD(addDays(new Date(), 1));
    // グループ毎の罫線用
    this.prevCowGroupId = null;
    this.loading = true;
    if (this.parameterForPrint_.printable) this.preparaPrint_();
    this.$scope_.$on('ngRepeatFinished', this.fixedHeader_);

    this.orderedListService_.getMastitisList().then((result) => {
      if (result.data && result.data.length > 0) {
        this.cows = result.data;
        if (this.parameterForPrint_.printable) this.executePrint_();
      }
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      this.loading = false;
    });
  }

  /**
   * 固定ヘッダー作成
   */
  fixedHeader_() {
    FixedMidashi.create();
  }

  /**
   * 印刷時の前処理
   */
  preparaPrint_() {
    $('header').remove();
    $('#header-band').remove();
    $('body').addClass('print');
    $('#external-input').addClass('option-wrapper');
    $('.wrapper .content').css('margin', '0px');
    $('#print-link').remove();
    $('.content .title-box').css('position', 'absolute');
    $('.table-fix').parent().removeClass('fixed-tb');
  }

  /**
   * 印刷実行
   */
  executePrint_() {
    const interval = setInterval(() => {
      executePrinter(this.parameterForPrint_, interval, null);
    }, 100);
  }
}

app.controller('MastitisListController', MastitisListController);
