class CowSearchAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  searchByCowNo(cowNo, activeOnly = false) {
    return this.APIService.get(`/cow/search?cow_no=${encodeURI(cowNo)}&active_only=${activeOnly}`);
  }

  searchByCowUid(cowUid) {
    return this.APIService.get(`/cow/search?cow_uid=${cowUid}`);
  }

  searchByBleId(bleId) {
    return this.APIService.get(`/cow/search?ble_id=${bleId}`);
  }
}

app.service('CowSearchAPI', CowSearchAPI);
