class ChartActivityRangeSelectorController {
  constructor(
    CowState,
    BleIdService,
    $scope
  ) {
    'ngInject';

    this.CowState = CowState;
    this.BleIdService = BleIdService;
    this.$scope = $scope;
    this.state = {};
    this.defaultMinActivityDate = null;
  }

  $onInit() {
    this.periods = this.periods ? this.periods : [30, 60, 90];
    this.defaultActivityPeriod = this.defaultActivityPeriod
      ? Number(this.defaultActivityPeriod)
      : this.periods.length > 0 ? this.periods[0] : undefined;
    if (this.withoutBle === undefined || this.withoutBle === null) this.withoutBle = false;

    this.state = this.initializeState(this.cow);

    if (!this.withoutBle) {
      this.initializeMinActivityDate(this.cow)
        .then((minActivityDate) => {
          if (minActivityDate) {
            this.state.minActivityDate = minActivityDate;
            this.defaultMinActivityDate = minActivityDate;
            this.setActivitySelectableRange();
          }
          this.state.isDateInitialized = true;
        });
    } else {
      this.state.isDateInitialized = true;
    }

    this.onChangeDate({date: this.state.activityDate});
    this.onChangePeriod({period: this.state.activityPeriod});
    this.isInitialized = true;
  }

  initializeState(cow) {
    return {
      activityDate: this.calcBaseDate(cow),
      baseDate: this.calcBaseDate(cow),
      showHeatChart: null,
      activityPeriod: this.defaultActivityPeriod || 30
    };
  }

  initializeMinActivityDate(cow) {
    if (!cow) {
      return new Promise((resolve) => resolve(null));
    }

    return this.BleIdService.cowBleIds(cow.cowId)
      .then((res) => {
        const cowBleIds = res.data;
        return cowBleIds.length ? new Date(Number(cowBleIds[0].startDate)) : null;
      });
  }

  calcBaseDate(cow) {
    if (this.CowState[cow.state] !== 'soldOrDied') {
      return DateUtil.startOfDay(new Date());
    }

    const eliminateDate = cow.eliminateDate
      ? new Date(Number(cow.eliminateDate))
      : null;
    const fallingDeadDate = cow.fallingDeadDate
      ? new Date(Number(cow.fallingDeadDate))
      : null;

    return DateUtil.startOfDay(eliminateDate || fallingDeadDate || new Date());
  }

  shouldDisablePeriodButton(period) {
    return this.state.activityPeriod === period
      || this.isLoading === true
      || !this.isInitialized;
  }

  shouldDisableActivityPreviousDate() {
    return Number(this.state.activityDate) <= Number(this.state.minActivityDate)
      || this.isLoading === true
      || !this.isInitialized;
  }

  shouldDisableActivityNextDate() {
    return Number(this.state.activityDate) >= Number(this.state.baseDate)
      || this.isLoading === true
      || !this.isInitialized;
  }

  shouldDisableActivityDate() {
    return this.isLoading === true;
  }

  onChangePeriodOfActivityClick(period) {
    this.state.activityPeriod = period;
    this.setActivitySelectableRange();
    this.onChangePeriod({period: period});
  }

  setActivitySelectableRange(currentDate = null) {
    const date = currentDate || DateUtil.today();
    const isHourlySummary = this.state.activityPeriod === 7;
    const minActivityDate = Activity.selectableMinimumDate(
      date,
      isHourlySummary,
      this.defaultMinActivityDate
    );

    if (DateUtil.isSameOrBeforeDay(this.state.baseDate, minActivityDate)) {
      this.state.minActivityDate = DateUtil.toDate(this.state.baseDate);
    } else {
      this.state.minActivityDate = minActivityDate;
    }

    if (DateUtil.isSameOrBeforeDay(this.state.activityDate, this.state.minActivityDate)) {
      this.state.activityDate = DateUtil.toDate(this.state.minActivityDate);
      this.onChangeActivityDate();
    }
  }

  onMoveActivityDateClick(direction) {
    const target = new Date(this.state.activityDate);

    if (direction === 'prev') {
      target.setDate(target.getDate() - this.state.activityPeriod);
    } else {
      target.setDate(target.getDate() + this.state.activityPeriod);
    }
    this.state.activityDate = target;

    this.onChangeDate({date: this.state.activityDate});
  }

  onChangeActivityDate() {
    this.onChangeDate({date: this.state.activityDate});
  }
}

app.controller('ChartActivityRangeSelectorController', ChartActivityRangeSelectorController);
