class AlertDysstasiaMonitoringService {
  constructor($state, $interval, FarmService, AlertService, toastr) {
    'ngInject';
    this.$state = $state;
    this.$interval = $interval;
    this.FarmService = FarmService;
    this.alertService = AlertService;
    this.toastr = toastr;
  }

  /**
   * 処理を開始する。
   * 牧場種別が乳用の場合は処理を終了する。
   */
  run() {
    this.FarmService.show().then((farm) => {
      if (farm.farmKind === '乳用') return;
      this.startMonitoring();
    });
  }

  // private

  /**
   * 監視を開始する。
   * 初回は強制的に起立困難のアラート取得を行い、
   * 2回目以降は10分毎にアラート取得を行う。
   */
  startMonitoring() {
    this.getAlertDysstasia();

    this.$interval(() => {
      this.getAlertDysstasia();
    }, AlertMonitoringService.MONITORING_MSEC);
  }

  /**
   * 起立困難アラートを取得する。
   */
  getAlertDysstasia() {
    this.alertService.alertDysstasiaDigest().then((res) => {
      const summary = res.data;
      if (summary.unresolved) this.showToast();
    }).catch((error) => console.error('get dysstasia', error.message));
  }

  /**
   * 起立困難アラートをトーストで表示する
   */
  showToast() {
    this.toastr.error('起立困難の牛がいます<br>クリックして詳細をご確認下さい', '緊急アラート', {
      onTap: () => this.goToAlertDysstasiaScreen()
    });
  }

  /**
   * 起立困難アラート画面へ遷移する。
   */
  goToAlertDysstasiaScreen() {
    this.$state.go('alertDysstasia', {}, {parent: this.$state.current});
  }
}

AlertMonitoringService.MONITORING_MSEC = 600000;

app.service('AlertDysstasiaMonitoringService', AlertDysstasiaMonitoringService);
