class LactationPerformanceChartController {
  constructor(
    $state,
    $window,
    $timeout,
    blockUI,
    Dictionary,
    LactationPerformanceAPI
  ) {
    'ngInject';

    this.$state = $state;
    this.$window = $window;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.Dictionary = Dictionary;
    this.LactationPerformanceAPI = LactationPerformanceAPI;
  }

  $onChanges(changes) {
    if (changes.isDrawChart && changes.isDrawChart.currentValue) {
      this.draw();
    }
  }

  draw() {
    const api = this.activeChartConfigMap.chart.api;
    const chartType = this.getChartType(api);

    let params = {};

    params.item_type = this.activeChartConfigMap.chart.item;
    params.data_type = this.setting.dataType.key;

    this.viewState = params.data_type.toUpperCase();

    this.callerParams = {
      activeTab: this.activeTab.key,
      selectChart: `${api}_${params.item_type}`,
      dataType: this.setting.dataType
    };

    if (this.showChartSettingThreshold()) {
      params.threshold = this.setting.threshold;
    }

    if (this.showChartSettingSelectReferenceDate() || this.showChartSettingInputReferenceDate()) {
      params.date = DateUtil.toYYYYMMDD(this.setting.referenceDate.date, '-');
      this.callerParams.referenceDate = this.setting.referenceDate;
    }

    if (this.showChartSettingSelectRangeDate() || this.showChartSettingInputRangeDate()) {
      params.start_date = DateUtil.toYYYYMMDD(this.setting.rangeDate.start.date, '-');
      params.end_date = DateUtil.toYYYYMMDD(this.setting.rangeDate.end.date, '-');
    }

    if (this.showChartSettingInterval()) {
      params.interval = this.setting.interval.key;
      this.callerParams.interval = params.interval;
    }

    this.blockUI.start('データ取得中');

    this.LactationPerformanceAPI[api](params).then((res) => {
      this.blockUI.stop();

      this.data = res.data;

      if (this.data.length === 0) {
        this.hasData = false;
        return;
      }

      this.hasData = true;

      const colorsMap = [
        '#ff1212',
        '#0212ff',
        '#12ff10'
      ];

      const parityNamesMap = [
        '初産',
        '2産',
        '3産以上'
      ];

      this.defaultAxisValue();

      let xs = {};
      let colors = {};
      let names = {};
      let json;
      let groups;
      let axis;
      let onclick;
      let tooltip;

      if (chartType === 'scatter') {
        json = angular.copy(this.data).sort((a, b) => {
          return a.parity - b.parity;
        }).reduce((acc, cur) => {
          const parity = cur.parity <= 3 ? cur.parity : 3;
          const xLabel = `parity_${parity}_x`;
          const yLabel = `parity_${parity}`;

          if (!acc[xLabel]) {
            xs[yLabel] = xLabel;
            colors[yLabel] = colorsMap[parity - 1];
            names[yLabel] = parityNamesMap[parity - 1];
            acc[xLabel] = [];
            acc[yLabel] = [];
          }

          acc[xLabel].push(cur.xValue);
          acc[yLabel].push(cur.yValue);

          return acc;
        }, {});

        axis = {
          x: {
            min: this.activeChartConfigMap.axis.x.min,
            max: this.activeChartConfigMap.axis.x.max,
            padding: 0,
            label: {
              text: this.getLabelText('x'),
              position: 'outer-right'
            },
            tick: {
              fit: false,
              format: d3.format('d')
            }
          },
          y: {
            min: this.activeChartConfigMap.axis.y.min,
            max: this.activeChartConfigMap.axis.y.max,
            padding: {
              top: 10,
              bottom: 0
            },
            label: {
              text: this.getLabelText('y'),
              position: 'outer-top'
            },
            tick: {
              format: d3.format(',')
            }
          }
        };

        onclick = (item, element) => {
          this.generatePanel(item, element);
        };

        tooltip = {
          show: false
        };
      } else if (chartType === 'line') {
        const xValues = this.data.reduce((acc, cur) => {
          acc = acc.concat(cur.xValues);
          return acc;
        }, []).filter((item, index, array) => {
          return array.indexOf(item) === index;
        }).sort((a, b) => {
          return a - b;
        }).map((value, index) => {
          return {
            index: index,
            value: value
          };
        });

        json = this.data.reduce((acc, cur) => {
          const parity = cur.parity <= 3 ? cur.parity : 3;
          const xLabel = `parity_${parity}_x`;
          const yLabel = `parity_${parity}`;

          if (!acc[xLabel]) {
            xs[yLabel] = xLabel;
            colors[yLabel] = colorsMap[parity - 1];
            names[yLabel] = parityNamesMap[parity - 1];
            acc[xLabel] = cur.xValues.map((curValue) => {
              return xValues.find((item) => {
                return item.value === curValue;
              }).index;
            });

            acc[yLabel] = cur.yValues;
          }

          return acc;
        }, {});

        axis = {
          x: {
            padding: {
              left: 0.5,
              right: 0.5
            },
            label: {
              text: this.getLabelText('x'),
              position: 'outer-right'
            },
            tick: {
              multiline: false,
              culling: {
                max: 7
              },
              format: (index) => {
                return DateUtil.toYYYYMMDD(xValues.find((item) => {
                  return item.index === index;
                }).value);
              }
            }
          },
          y: {
            label: {
              text: this.getLabelText('y'),
              position: 'outer-top'
            },
            tick: {
              format: d3.format(',')
            }
          }
        };

        tooltip = {
          show: true,
          format: {
            value: d3.format(',')
          }
        };
      } else {
        const threshold = this.setting.threshold;
        const chartTypeBarConfigMap = [{
          key: 'lessThanThreshold',
          name: this.activeChartConfigMap.names.lessThanThreshold,
          color: '#adff2f'
        }, {
          key: 'aboveThreshold',
          name: this.activeChartConfigMap.names.aboveThreshold,
          color: '#ff6347'
        }];

        let xValues = [];

        names = angular.copy(chartTypeBarConfigMap).reduce((acc, cur) => {
          let name = cur.name;

          name.splice(1, 0, threshold);
          acc[cur.key] = name.join('');

          return acc;
        }, {});

        colors = angular.copy(chartTypeBarConfigMap).reduce((acc, cur) => {
          acc[cur.key] = cur.color;

          return acc;
        }, {});

        groups = angular.copy(chartTypeBarConfigMap).reduce((acc, cur) => {
          acc.push(cur.key);

          return acc;
        }, []);
        groups = [groups];

        json = this.data.reduce((acc, cur) => {
          groups[0].forEach((group) => {
            if (!acc[group]) {
              acc[group] = [cur[group]];
            } else {
              acc[group].push(cur[group]);
            }
          });

          xValues = xValues.concat(cur.date);

          return acc;
        }, {});

        axis = {
          x: {
            label: {
              text: this.getLabelText('x'),
              position: 'outer-right'
            },
            tick: {
              multiline: false,
              centered: true,
              culling: {
                max: 7
              },
              format: (index) => {
                return DateUtil.toYYYYMMDD(xValues[index]);
              }
            },
            type: 'category'
          },
          y: {
            padding: 0,
            label: {
              text: this.getLabelText('y'),
              position: 'outer-top'
            }
          }
        };

        tooltip = {
          show: true
        };
      }

      this.$timeout(() => {
        this.chart = c3.generate({
          bindto: '#chart-lactation-performance',
          data: {
            xs: xs,
            json: json,
            groups: groups,
            colors: colors,
            names: names,
            order: null,
            type: chartType,
            onclick: onclick
          },
          axis: axis,
          tooltip: tooltip,
          grid: {
            x: {
              show: true
            },
            y: {
              show: true
            }
          },
          legend: {
            show: true
          },
          transition: {
            duration: 0
          },
          size: {
            width: this.getChartWidth(),
            height: 380
          },
          padding: {
            top: 10,
            right: 20
          },
          onresize: () => {
            this.chart.resize({
              width: this.getChartWidth()
            });
          }
        });
      });
    });
  }

  defaultAxisValue() {
    const directions = ['x', 'y'];
    const scales = ['min', 'max'];
    let maxValue;

    if (!this.activeChartConfigMap.axis.origin) {
      this.activeChartConfigMap.axis.origin = angular.copy(this.activeChartConfigMap.axis);
    }

    directions.forEach((direction) => {
      scales.forEach((scale) => {
        maxValue = this.data.map((item) => {
          const step = this.activeChartConfigMap.axis.origin[direction].step;

          return Math.ceil(item[`${direction}Value`] / step) * step;
        }).reduce((acc, cur) => {
          return acc > cur ? acc : cur;
        });

        if (scale === 'max') {
          this.activeChartConfigMap.axis[direction].maxValue = maxValue;
        }

        if (typeof this.activeChartConfigMap.axis.origin[direction][scale] === 'undefined') {
          if (scale === 'min') {
            this.activeChartConfigMap.axis[direction][scale] = 0;
          } else {
            this.activeChartConfigMap.axis[direction][scale] = maxValue;
          }
        } else {
          if (direction === 'y' &&
            scale === 'max' &&
            (this.activeChartConfigMap.axis.origin[direction][scale] < maxValue)) {
            this.activeChartConfigMap.axis[direction][scale] = maxValue;
            this.setting.rangeAxis[direction][scale] = maxValue;
          } else {
            this.activeChartConfigMap.axis[direction][scale] = this.activeChartConfigMap.axis.origin[direction][scale];
            this.setting.rangeAxis[direction][scale] = this.activeChartConfigMap.axis.origin[direction][scale];
          }
        }
      });
    });
  }

  generatePanel(item, element) {
    const $element = angular.element(element);
    const $chart = angular.element(this.chart.element);
    const $chartPanel = angular.element('.contents__chartPanel');

    this.chartPanelHeading = this.Dictionary.COW.COW_NO;
    this.chartPanelItems = this.data.filter((data) => {
      return Number(data.xValue) === item.x && Number(data.yValue) === item.value;
    });

    $chartPanel.show();

    this.styleChartPanel = {
      top: $element.offset().top - $chart.offset().top,
      left: $element.offset().left - $chart.offset().left
    };

    $chartPanel.on('mouseleave', () => {
      $chartPanel.hide();
    });
  }

  getChartType(api) {
    switch (api) {
    case 'cow':
      return 'scatter';
    case 'parity':
      return 'line';
    case 'farm':
      return 'bar';
    }
  }

  getChartWidth() {
    return this.$window.innerWidth <= 1024 ? 664 : this.$window.innerWidth - 580;
  }

  getLabelText(direction) {
    const text = this.activeChartConfigMap.axis[direction].label.text;

    if (typeof text === 'string') {
      return text;
    } else {
      return text[this.setting.dataType.key];
    }
  }

  goToCowDetail(cowId) {
    const caller = {
      state: 'lactation-performance',
      name: '泌乳成績',
      viewState: this.viewState,
      params: this.callerParams
    };

    this.$state.go('cowDetail', {
      cowId: cowId,
      caller
    });
  }
}

function lactationPerformanceChartComponent() {
  return {
    templateUrl: 'menu/report/lactation-performance/chart.html',
    controller: LactationPerformanceChartController,
    controllerAs: 'ctrl',
    bindings: {
      activeTab: '<',
      activeChartConfigMap: '<',
      setting: '<',
      isDrawChart: '<',
      hasData: '=',
      chart: '=',
      showChartSettingThreshold: '&',
      showChartSettingSelectReferenceDate: '&',
      showChartSettingInputReferenceDate: '&',
      showChartSettingSelectRangeDate: '&',
      showChartSettingInputRangeDate: '&',
      showChartSettingInterval: '&'
    }
  };
}

app.component('lactationPerformanceChart', lactationPerformanceChartComponent());
