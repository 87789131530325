class AlertDysstasiaBoxController {
  constructor(
    AlertSummaryStateService
  ) {
    'ngInject';
    this.AlertSummaryStateService = AlertSummaryStateService;
  }

  showAlertDysstasia() {
    if (!this.farm) return false;
    if (!this.farm.isBeef()) return false;
    const count = this.AlertSummaryStateService.summary.dysstasia || 0;
    return count > 0;
  }

  showAlertDysstasiaResolved() {
    if (!this.farm) return false;
    if (!this.farm.isBeef()) return false;
    const resolved = this.AlertSummaryStateService.summary.dysstasiaResolved || 0;
    return resolved > 0;
  }
}

function alertDysstasiaBox() {
  return {
    templateUrl: 'top/alert/dysstasia-box.html',
    controller: AlertDysstasiaBoxController,
    bindings: {
      farm: '<',
    }
  };
}

app.component('alertDysstasiaBox', alertDysstasiaBox());
