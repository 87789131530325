class AccountEditController {
  constructor(
    $modalInstance,
    params,
    AccountAPI,
    UtilService,
    blockUI,
    $timeout,
    modalDialogFactory
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.AccountAPI = AccountAPI;
    this.UtilService = UtilService;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.modalDialogFactory = modalDialogFactory;

    this.title = params.title;
    this.id = params.id || null;
    this.viewMode = new ViewMode(params.viewMode);

    this.initialize();
  }

  initialize() {
    this.invalid = this.viewMode.isDelete() ? false : true;
    this.errorMessage = null;

    if (this.viewMode.isCreate()) {
      this.account = {
        name: null,
        email: null,
        password: null,
        confirmPassword: null,
        role: Account.DEFAULT_ROLE,
      };
    } else {
      this.AccountAPI.show(this.id).then((res) => {
        this.account = res.data;
      });
    }
  }

  validate() {
    if (this.viewMode.isCreate()) {
      this.validateOnCreate();
    } else if (this.viewMode.isUpdate()) {
      this.validateOnUpdate();
    }
  }

  validateOnCreate() {
    this.invalid = true;
    this.passwordFormatError = false;
    this.confirmPasswordNotMatch = false;

    const account = this.account;

    if (!account.name) return;
    if (!account.email) return;
    if (!account.password) return;
    if (!account.confirmPassword) return;

    if (!Account.validatePassword(account.password)) return;
    if (account.password !== account.confirmPassword) return;

    this.invalid = false;
  }

  validatePassword() {
    const account = this.account;
    if (!account.password) return;

    if (!Account.validatePassword(account.password)) {
      this.passwordFormatError = true;
      return;
    }
  }

  validateConfirmPassword() {
    const account = this.account;
    if (!account.password) return;
    if (!account.confirmPassword) return;

    if (account.password !== account.confirmPassword) {
      this.confirmPasswordNotMatch = true;
      return;
    }
  }

  validateOnUpdate() {
    this.invalid = true;
    const account = this.account;

    if (!account.name) return;

    this.invalid = false;
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  save() {
    if (this.viewMode.isCreate()) {
      this.create();
    } else if (this.viewMode.isUpdate()) {
      this.update();
    } else if (this.viewMode.isDelete()) {
      this.delete();
    }
  }

  create() {
    const params = angular.copy(this.account);
    const procedure = this.AccountAPI.create(
      params.name,
      params.email,
      params.password,
      params.role
    );
    this.callApi(procedure);
  }

  update() {
    const procedure = this.AccountAPI.update(this.id, this.account.name);
    this.callApi(procedure);
  }

  delete() {
    const procedure = this.AccountAPI.delete(this.id);
    this.callApi(procedure);
  }

  callApi(procedure) {
    this.blockUI.start('更新中');

    procedure
      .then(() => {
        this.blockUI.done(() => {
          this.blockUI.start(`ユーザーの${this.viewMode.label}が完了しました`);
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close();
      })
      .catch((res) => {
        this.errorMessage = this.UtilService.formatErrorMessage(res.data.messages);
        this.blockUI.stop();
      });
  }
}

app.controller('AccountEditController', AccountEditController);
