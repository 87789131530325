/**
 * [HTMLテンプレートの内の変数の説明]
 * grid.appScope: コントローラーを指します
 * row: UI Gridの該当する行オブジェクト
 * row.entity: コントローラーでセットした行単位のデータオブジェクトがマッピングされます
 * col: UI Gridの該当する列オブジェクト
 */

// eslint-disable-next-line no-unused-vars
class UiGridColumnDefGenerator {
  constructor(columnDefinitions, showCowNoLink) {
    this.columnDefinitions = columnDefinitions;
    this.showCowNoLink = showCowNoLink;

    this.customlist = new Customlist(null);
  }

  generate(columns) {
    const result = columns.map((c) => {
      const definition = this.columnDefinitions[c.columnId];
      const sortedClass = CustomlistSort.isUnsortable(c.columnId) ? '' : CustomlistSort.UNSORTED;

      const columnDef = {
        name: c.actualCaption,
        field: c.columnId,
        enableColumnMenu: false,
        headerCellTemplate: this.generateHeaderTemplate(c),
        headerCellClass: (grid, row, col, rowRenderIndex, colRenderIndex) => {
          if (CustomlistSort.isUnsortable(col.colDef.field)) {
            return 'align-center';
          }
          return col.colDef.sortedClass || CustomlistSort.UNSORTED;
        },
        cellTemplate: this.generateCellTemplate(c),
        cellClass: (grid, row, col, rowRenderIndex, colRenderIndex) => {
          if (row.entity.selected) {
            return 'ui-grid-row-selected';
          } else {
            return '';
          }
        },
        width: definition.width,
        minimumColumnSize: definition.width,
        // 以下はアプリケーション固有の設定値
        sortedClass: sortedClass,
      };

      return columnDef;
    });

    result.unshift(this.generateRowSelectColumn());

    return result;
  }

  generateHeaderTemplate(column) {
    return `
      <div
        ng-class="grid.appScope.generateHeaderClass('${column.cssClass}', col)"
        ng-click="grid.appScope.onClickColumnHeader('${column.columnId}', col)"
        ng-style="grid.appScope.columnStyle('${column.columnId}')">
        <div class="customlist-th-inner">
          <span>${column.actualCaption}</span>
          <div class="sort-icon"></div>
        </div>
      </div>`;
  }

  generateCellTemplate(column) {
    if (this.customlist.isCowNoColumn(column)) {
      if (this.showCowNoLink) {
        return `<div class="ui-grid-cell-contents"><a href="" ng-bind="row.entity.cow_no" ng-click="grid.appScope.goToDetails(row.entity.cowId)"></a></div>`;
      } else {
        return `<div class="ui-grid-cell-contents"><span ng-bind="row.entity.cow_no"></span></div>`;
      }
    } else if (this.customlist.isSummaryColumn(column)) {
      return `<div><span ng-bind-html="row.entity.${column.columnId}"</span></div>`;
    } else if (this.customlist.isCowLabelColumn(column)) {
      return `
        <div class="cow-labels-wrapper">
          <span style="display: inline-block; min-width: 90px;">
            <span style="font-size: 1.2rem; text-align: center;" ng-repeat="label in row.entity.cowLabels track by $index">
              <span class="cow-labels kindBackgroundColor" ng-bind="label"></span>
            </span>
          </span>
        </div>`;
    } else if (this.customlist.isCalfNoColumn(column)) {
      if (this.showCowNoLink) {
        return `<div><a href="" ng-bind="row.entity.calf_no" ng-click="grid.appScope.goToDetails(row.entity.cowId)"></a></div>`;
      } else {
        return `<div><span ng-bind="row.entity.calf_no"></span></div>`;
      }
    } else if (this.customlist.isCommentColumn(column)) {
      return `
        <div ng-repeat="comment in row.entity.comments track by $index">
          <span ng-bind="comment"></span>
        </div>`;
    } else if (this.customlist.isAlertTypeColumn(column)) {
      return `
        <div class="ui-grid-alertLabels">
          <div ng-class="grid.appScope.alertLabelsBodyClass()">
            <span
              ng-repeat="label in row.entity.alertLabels track by $index"
              ng-bind="label"
              ng-click="grid.appScope.goToDetails(row.entity.cowId, label)"
              ng-class="grid.appScope.alertLabelClass(label)"
              class="alertLabel"
            >
            </span>
          </div>
        </div>`;
    }

    return null;
  }

  generateRowSelectColumn() {
    const headerCellTemplate = `
      <label class="cow-select" ng-if="grid.appScope.showCowSelect">
        <input
          type="checkbox"
          class="ui-grid-checkbox margin-right-ignore"
          ng-model="grid.appScope.allSelected"
          ng-click="grid.appScope.componentFuncs.onClickAllCows(grid.appScope.cows, grid.appScope.allSelected); grid.appScope.notifyUiGridRowChange()">
      </label>`;

    const cellTemplate = `
      <label class="cow-select" ng-if="grid.appScope.showCowSelect">
        <input type="checkbox"
          class="ui-grid-checkbox"
          ng-model="row.entity.selected"
          ng-click="grid.appScope.componentFuncs.onClickCow($event, grid.appScope.cows); grid.appScope.notifyUiGridRowChange()">
      </label>`;

    return {
      name: ' ',
      headerCellTemplate: headerCellTemplate,
      cellTemplate: cellTemplate,
      enableColumnMenu: false,
      cellClass: (grid, row, col, rowRenderIndex, colRenderIndex) => {
        if (row.entity.selected) {
          return 'ui-grid-row-selected';
        } else {
          return '';
        }
      },
      width: 30,
    };
  }

  generateRowTemplate() {
    return `
      <div ng-click="grid.appScope.updateCurrentCow(row.entity, colRenderIndex)"
        ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
        class="ui-grid-cell"
        ng-class="col.colIndex();grid.appScope.classCell(row.entity.cowId)"
        ui-grid-cell>
      </div>
    `;
  }
}
