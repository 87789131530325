class CustomlistEditController {
  constructor(
    $rootScope,
    $q,
    $modal,
    $modalInstance,
    params,
    blockUI,
    CustomlistAPI,
    CowGroupService,
    DateTimeUtilFactory,
    modalDialogFactory,
    SelectionDialog,
    MasterDialog,
    MasterMarketAPI,
    SessionCache,
    MasterWorkNoteTypeAPI
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$modal = $modal;
    this.$modalInstance = $modalInstance;
    this.blockUI = blockUI;
    this.CustomlistAPI = CustomlistAPI;
    this.DateTimeUtilFactory = DateTimeUtilFactory;
    this.modalDialogFactory = modalDialogFactory;
    this.SelectionDialog = SelectionDialog;
    this.MasterDialog = MasterDialog;
    this.MasterMarketAPI = MasterMarketAPI;
    this.MasterWorkNoteTypeAPI = MasterWorkNoteTypeAPI;

    const farm = SessionCache.farm();
    this.isDairy = farm.isDairy();
    this.isFattening = farm.isFattening();
    this.isBreeding = farm.isBreeding();

    this.init($q, params, CowGroupService);
  }

  init($q, params, CowGroupService) {
    this.title = params.title;
    this.itemFilter = '';
    this.showColumnsTab = true;
    this.showConditionsTab = false;
    this.invalid = true;

    CustomlistInitializer.initialize(this, params);
    this.baseConditionField = this.conditionFields.concat();

    if (params.copiedCustomlistId) {
      this.master.customlistId = params.copiedCustomlistId;
    }

    this.blockUI.start('読み込み中');

    CustomlistInitializer.loadAndSetConfig(
      this, $q, CowGroupService, this.MasterMarketAPI, this.MasterWorkNoteTypeAPI
    ).then((res) => {
      this.blockUI.stop();
      this.isUserDefined = this.master.configType === 'user_defined';
      this.showConditionType = this.master.conditionType === 'advanced';
      this.showTemplate = this.isUserDefined && this.master.conditionType === 'standard';
      this.showIncludeAlerts = Customlist.isIncludeAlertsConfig(this.master.configType);
      this.refreshOrderItems();

      if (params.copiedCustomlistId) {
        this.master.customlistId = null;
        this.master.remarks = `${this.master.name}のコピー`;
        this.master.name = null;
      }

      this.validate();
    });
  }

  validate() {
    this.invalid = false;

    if (!this.master.name) {
      this.invalid = true;
    }

    if (this.useItems.length === 0) {
      this.invalid = true;
    }

    if (!this.validateOrderItems()) {
      this.invalid = true;
    }

    if (!CustomlistSelection.validate(this.conditions)) {
      this.invalid = true;
    }
  }

  validateOrderItems() {
    const duplicateChecker = new Set();
    this.orderMaps.forEach((e) => {
      const selectableItem = this.orderItems.some((item) => item.value === e.columnId);
      if (!selectableItem) {
        e.errorMessage = '有効な項目を選択してください';
        return;
      }
      if (duplicateChecker.has(e.columnId)) {
        e.errorMessage = '重複して選択されています';
        return;
      }

      e.errorMessage = '';
      duplicateChecker.add(e.columnId);
    });

    const invalid = this.orderMaps.some((e) => e.errorMessage);
    return !invalid;
  }

  /* ここからイベントゾーン */

  onChangeUseFixedColumnWidth() {
    if (this.master.useFixedColumnWidth) {
      this.useFixedColumnWidthExplanation = '列が画面内に収まりきらない場合は横スクロールバーが表示されます';
    } else {
      this.useFixedColumnWidthExplanation = '画面内に全ての列が表示されるように列幅が調整されます(非推奨)';
    }
  }

  applyTemplate() {
    const template = this.templates[this.templateIndex];
    const config = {
      master: Object.assign({}, this.master),
      columns: template.columns,
      conditions: template.conditions,
      sorts: template.sorts
    };
    CustomlistInitializer.setConfig(this, config);
    this.validate();
  }

  definitionToItem(definition) {
    return {
      label: definition.caption,
      value: definition.columnId,
      shortCaption: definition.shortCaption,
      fieldType: definition.fieldType
    };
  }

  onChangeShowDepositedCow() {
    if (this.master.showDepositedCow) {
      this.orderItems.unshift({label: '牧場名', value: 'farm_name'});
    } else {
      this.orderItems = this.orderItems.filter((item) => item.value !== 'farm_name');

      this.orderMaps.forEach((order) => {
        if (order.columnId === 'farm_name') {
          order.columnId = 'cow_no';
        }
      });

      const needToRemove = this.useItems.some((item) => item.value === 'farm_name');
      if (needToRemove) {
        this.seletedUseItems = ['farm_name'];
        this.removeItem();
      }
    }
    this.onChangeDepositItem();
  }

  onChangeDepositItem() {
    if (this.master.showDepositedCow || this.master.allowAgentToUse) {
      this.showCowGroupCondition = false;
      this.conditions.forEach((condition) => {
        condition.cowGroupCondition = '';
        condition.cowGroupConditionLabel = '(全ての牛群が対象になります)';
      });
    } else {
      this.showCowGroupCondition = true;
    }
    this.refreshItems();
  }

  onClickTabSelector(tabName) {
    if (tabName === 'columns') {
      this.showColumnsTab = true;
      this.showConditionsTab = false;
    } else {
      this.showColumnsTab = false;
      this.showConditionsTab = true;
    }
  }

  onChangeItemFilter() {
    this.refreshItems();
  }

  onChangeConditionFilter(conditionDetail) {
    this.refreshConditions(conditionDetail);
    if (conditionDetail.selectOptions) {
      conditionDetail.filterError = false;
    }
  }

  onClickConditionFilterOff(conditionDetail) {
    conditionDetail.conditionFilter = '';
    this.refreshConditions(conditionDetail);
    conditionDetail.filterError = false;

    if (this.extractConditionFilterError()) {
      this.invalid = true;
      return;
    }
    this.validate();
  }

  onClickItemFilterOff() {
    this.itemFilter = '';
    this.refreshItems();
  }

  extractConditionFilterError() {
    const detailsArray = this.conditions.map((cond) => cond.conditionDetails).flat();
    const errorDetail = detailsArray.find((detail) => detail.error);
    return errorDetail;
  }

  indexOfSelected(selectedColumnId, items) {
    let targetIndex = -1;
    items.some((item, index) => {
      if (item.value === selectedColumnId) {
        targetIndex = index;
        return true;
      }
      return false;
    });
    return targetIndex;
  }

  addItem() {
    if (!this.seletedItems) return false;
    this.seletedItems.forEach((selectedCoulmnId) => {
      const selectedIndex = this.indexOfSelected(selectedCoulmnId, this.items);
      const item = this.items[selectedIndex];
      if (CustomlistColumnDefinition.isDateField(item)) {
        item.displayStyle = Customlist.DEFAULT_DATE_STYLE;
      } else if (CustomlistColumnDefinition.isBooleanField(item)) {
        item.options = {
          booleanStyle: this.booleanStyleOptions[0].key
        };
      } else if (item.value === 'bred_digest') {
        item.options = Customlist.DEFAULT_BRED_DIGEST_OPTIONS;
      }

      if (CustomlistColumnDefinition.isDateField(item)) {
        item.displayStyle = Customlist.DEFAULT_DATE_STYLE;
      }

      if (item.value.startsWith('work_note_type_')) {
        item.options = {masterWorkNoteTypeId: this.currentMasterWorkNoteTypeId};
        item.shortCaption = this.generateWorkNoteItemCaption(
          item.value, this.currentMasterWorkNoteTypeId
        );
      }

      this.useItems.push(item);
      this.items.splice(selectedIndex, 1);
    });

    this.refreshOrderItems();
    this.validate();
  }

  removeItem() {
    if (!this.seletedUseItems) return false;

    this.seletedUseItems.forEach((selectedCoulmnId) => {
      const selectedIndex = this.indexOfSelected(selectedCoulmnId, this.useItems);
      this.useItems.splice(selectedIndex, 1);
    });

    this.refreshOrderItems();
    this.refreshItems();
    this.validate();
  }

  refreshItems() {
    const items = this.definitions
      .filter((definition) => {
        const selectedIndex = this.indexOfSelected(definition.columnId, this.useItems);
        return selectedIndex < 0; // -1: 表示項目に選択されていない
      })
      .filter((definition) => {
        return definition.isAvailable(this.master);
      })
      .filter((definition) => {
        if (this.groupTag === 'ALL') return true;
        return definition.groupTag.indexOf(this.groupTag) >= 0;
      })
      .filter((definition) => {
        if (!this.itemFilter) return true;
        return definition.caption.includes(this.itemFilter);
      })
      .map((definition) => this.definitionToItem(definition));
    this.items = items;
  }

  refreshConditions(conditionDetail) {
    const conditions = this.baseConditionField.concat();

    const reusltConditions = conditions.filter((el) => {
      return el.label.includes(conditionDetail.conditionFilter);
    });
    conditionDetail.selectOptions = reusltConditions;
  }

  toFirstSelectItem() {
    if (this.useItems.length <= 1) return;
    if (this.seletedUseItems.length <= 0) return;

    this.useItems = CollectionUtil.shiftToFirst(this.useItems, this.seletedUseItems, 'value');
  }

  upSelectItem() {
    if (this.useItems.length <= 1) return;
    if (this.seletedUseItems.length <= 0) return;

    this.useItems = CollectionUtil.shiftToUp(this.useItems, this.seletedUseItems, 'value');
  }

  downSelectItem() {
    if (this.useItems.length <= 1) return;
    if (this.seletedUseItems.length <= 0) return;

    this.useItems = CollectionUtil.shiftToDown(this.useItems, this.seletedUseItems, 'value');
  }

  toLastSelectItem() {
    if (this.useItems.length <= 1) return;
    if (this.seletedUseItems.length <= 0) return;

    this.useItems = CollectionUtil.shiftToLast(this.useItems, this.seletedUseItems, 'value');
  }

  onChangeSelected() {
    if (this.seletedUseItems.length === 1) {
      const selectedIndex = this.indexOfSelected(this.seletedUseItems[0], this.useItems);
      this.currentItem = this.useItems[selectedIndex];
      this.currentShortCaption = this.currentItem.shortCaption;
      this.currentDisplayStyle = this.currentItem.displayStyle || 'yyyy-mm-dd-slash';
    } else {
      this.currentItem = null;
    }

    this.setItemDetail();
  }

  setItemDetail() {
    if (this.currentItem) {
      this.showItemDetail = true;
      this.showDateStyle = CustomlistColumnDefinition.isDateField(this.currentItem);
      this.showBooleanStyleOption = CustomlistColumnDefinition.isBooleanField(this.currentItem);

      if (this.showBooleanStyleOption) {
        this.currenteBooleanStyle = this.currentItem.options.booleanStyle;
      }

      if (this.currentItem.value === 'bred_digest') {
        this.showBredDigestStyle = true;
        this.currentBredDigestStyle = CustomlistContentGenerator.bredDigestSummary(this.currentItem.options);
      } else {
        this.showBredDigestStyle = false;
      }

      if (this.currentItem.value.startsWith('work_note_type_')) {
        this.showWorkNoteType = true;
        this.currentMasterWorkNoteTypeId = this.currentItem.options.masterWorkNoteTypeId;
      } else {
        this.showWorkNoteType = false;
      }
    } else {
      this.showItemDetail = false;
      this.showDateStyle = false;
      this.showBooleanOption = false;
      this.showWorkNoteType = false;
    }
  }

  onChangeShortCaption() {
    if (this.currentItem) {
      this.currentItem.shortCaption = this.currentShortCaption;
    }
  }

  onChangeDateStyle() {
    if (this.currentItem) {
      this.currentItem.displayStyle = this.currentDisplayStyle;
    }
  }

  onChangeBooleanStyle() {
    if (this.currentItem) {
      this.currentItem.options = {
        booleanStyle: this.currenteBooleanStyle
      };
    }
  }

  itemLabel(item) {
    return item.shortCaption ? item.label + ' - ' + item.shortCaption : item.label;
  }

  configBredDigest() {
    const options = this.currentItem.options;
    const params = {
      contents: [].concat(options.contents),
      eventTypes: [].concat(options.eventTypes),
      range: options.range,
      sortOrder: options.sortOrder,
      style: options.style,
    };

    const modalInstance = this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/customlist/config/bred-digest.html',
      controller: 'BredDigestDialogController',
      controllerAs: 'ctrl',
      backdrop: false,
      keyboard: false,
      resolve: {
        params: () => {
          return {
            options: params
          };
        }
      }
    });

    modalInstance.result.then((result) => {
      this.currentItem.options = result;
      this.currentBredDigestStyle = CustomlistContentGenerator.bredDigestSummary(this.currentItem.options);
    });
  }

  onChangeWorkNoteType() {
    if (this.currentItem) {
      this.currentItem.options = {
        masterWorkNoteTypeId: this.currentMasterWorkNoteTypeId
      };
      this.currentShortCaption = this.generateWorkNoteItemCaption(
        this.currentItem.value, this.currentMasterWorkNoteTypeId
      );
      this.onChangeShortCaption();
    }
  }

  refreshOrderItems() {
    this.orderItems = CustomlistInitializer.generateOrderItems(
      this.definitions, this.master, this.useItems
    );
    this.confirmCanAddOrderBy();
  }

  addOrderBy() {
    const selectedCoulmnIds = this.orderMaps.map((e) => e.columnId);
    const selectableItem = this.orderItems.find((item) => !selectedCoulmnIds.includes(item.value));
    this.orderMaps.push({columnId: selectableItem.value, sortType: 'ASC'});
    this.confirmCanAddOrderBy();
  }

  removeOrderBy(index) {
    this.orderMaps.splice(index, 1);
    this.confirmCanAddOrderBy();
    this.validate();
  }

  confirmCanAddOrderBy() {
    const limitCount = Math.min(this.orderItems.length, 10);
    this.canAddOrderBy = this.orderMaps.length < limitCount;
  }

  canRemoveOrderBy(index) {
    return index > 0;
  }

  addCondition() {
    this.conditions.push(CustomlistInitializer.createCondition());
  }

  removeCondition(index) {
    this.conditions.splice(index, 1);
  }

  canRemoveCondition() {
    return this.conditions.length >= 2;
  }

  onChangeConditionType() {
    this.standardType = this.master.conditionType === 'standard';
    this.showTemplate = this.isUserDefined && this.standardType;
  }

  onChangeConditonItem(selectedItem, conditionIndex, detailIndex) {
    const conditionDetail = this.conditions[conditionIndex].conditionDetails[detailIndex];
    conditionDetail.columnId = selectedItem.value;
    conditionDetail.label = selectedItem.label;
    this.onChangeConditonDetail(conditionDetail);
    this.setConditionOperator(conditionDetail);

    this.$rootScope.$digest();
  }

  onChangeConditonDetail(detail) {
    const numericFieldTypeConverter = {
      INTEGER: 'NUMBER',
      FLOAT: 'NUMBER',
      LAPSED_DAYS: 'LAPSED_DAYS',
      DIFF_DAYS: 'DIFF_DAYS',
      LAPSED_MONTHS: 'LAPSED_MONTHS',
      EXPECTED_DAYS: 'EXPECTED_DAYS',
    };

    const selectionColumnIds = ['breed', 'vaccine_timing'];
    const masterColumnIds = ['master_market_name'];

    detail.showStringCondition = false;
    detail.showNumericCondition = false;
    detail.showNumericSubCondition = false;
    detail.showDateCondition = false;
    detail.showDateSubCondition = false;
    detail.showSelectionCondition = false;
    detail.showMasterCondition = false;
    detail.showWorkNoteTypeCondition = false;

    detail.showValueCorrectType1 = false;
    detail.showValueCorrectType2 = false;

    if (detail.columnId === null) {
      if (detail.selectOptions.length > 0) {
        detail.columnId = detail.selectOptions[0].value;
      } else {
        return;
      }
    }

    if (detail.columnId === 'ble_id') {
      detail.fieldType = 'BLE_ID';
      this.validate();
      return;
    }

    if (detail.columnId === 'calving_sensor_number') {
      detail.fieldType = 'CALVING_SENSOR';
      this.validate();
      return;
    }

    if (detail.columnId.startsWith('work_note_type_')) {
      detail.showWorkNoteTypeCondition = true;
      if (!detail.conditionValue) {
        detail.conditionValue = {
          masterWorkNoteTypeId: this.currentMasterWorkNoteTypeId
        };
      }
    }

    if (selectionColumnIds.includes(detail.columnId)) {
      detail.fieldType = 'SELECTION';
      detail.showSelectionCondition = true;
      if (!detail.conditionValue) {
        detail.conditionValue = {};
      }
      detail.conditionValueLabel = CustomlistSelection.generateSelectionLabel(detail.conditionValue.selections);
      this.validate();
      return;
    }

    if (masterColumnIds.includes(detail.columnId)) {
      detail.fieldType = 'MASTER';
      detail.showMasterCondition = true;
      if (detail.conditionValue) {
        const ids = detail.conditionValue.ids || [];
        const names = ids.map((id) => {
          const market = this.markets[id];
          return market ? market.name : '';
        });
        detail.conditionValueLabel = CustomlistSelection.generateSelectionLabel(names);
      } else {
        detail.conditionValue = {};
        detail.conditionValueLabel = CustomlistSelection.generateSelectionLabel([]);
      }
      this.validate();
      return;
    }

    const definition = this.definitionMap[detail.columnId];
    if (numericFieldTypeConverter[definition.fieldType]) {
      detail.fieldType = numericFieldTypeConverter[definition.fieldType];
      detail.showNumericCondition = true;
    } else if (definition.fieldType === 'DATE') {
      detail.fieldType = 'DATE';
      detail.showDateCondition = CustomlistDateCondition.needConditionNumber1(detail.conditionOperator);
      detail.showValueCorrectType1 = CustomlistDateCondition.needCorrectType1(detail.conditionOperator);

      detail.showDateSubCondition = CustomlistDateCondition.needConditionNumber2(detail.conditionOperator);
      detail.showValueCorrectType2 = CustomlistDateCondition.needCorrectType2(detail.conditionOperator);
    } else if (definition.fieldType === 'DATETIME') {
      detail.fieldType = 'DATETIME';
      detail.showDateCondition = CustomlistDateCondition.needConditionNumber1(detail.conditionOperator);
      detail.showValueCorrectType1 = CustomlistDateCondition.needCorrectType1(detail.conditionOperator);

      detail.showDateSubCondition = CustomlistDateCondition.needConditionNumber2(detail.conditionOperator);
      detail.showValueCorrectType2 = CustomlistDateCondition.needCorrectType2(detail.conditionOperator);
    } else if (definition.fieldType === 'STRING') {
      detail.fieldType = 'STRING';
      detail.showStringCondition = true;
    }
    this.onChangeConditionOperator(detail);
    this.validate();
  }

  setConditionOperator(conditionDetail) {

    if (conditionDetail.columnId === null) {
      conditionDetail.conditionOperators = [];
      this.invalid = true;
      conditionDetail.filterError = true;
      conditionDetail.errorMessage = '有効な項目を選択してください';
      return;
    }

    if (this.extractConditionFilterError()) {
      this.invalid = true;
    } else {
      this.validate();
    }

    if (conditionDetail.columnId === 'cow_labels') {
      if (conditionDetail.conditionOperators) {
        const values = conditionDetail.conditionOperators.map((el) => el.value);
        const targetList = ['include', 'exclude', 'present', 'blank'];
        const isAllContain = targetList.every((obj) => values.includes(obj));
        if (values.length === 4 && isAllContain) {
          return;
        }
      }

      conditionDetail.conditionOperators = [
        {value: 'include', label: '含む', fieldType: 'STRING'},
        {value: 'exclude', label: '含まない', fieldType: 'STRING'},
        {value: 'present', label: '入力済', fieldType: 'STRING'},
        {value: 'blank', label: '未入力', fieldType: 'STRING'},
      ];
      return;
    }

    const nullableNumberIds = [
      'weekly_mean',
      'previous_day_amount',
    ];
    if (nullableNumberIds.includes(conditionDetail.columnId)) {
      conditionDetail.conditionOperators = this.conditionOperatorDefs.filter((def) => {
        return def.fieldType === 'NULLABLE_NUMBER';
      });
      return;
    }

    conditionDetail.conditionOperators = this.conditionOperatorDefs.filter((def) => {
      return def.fieldType === conditionDetail.fieldType;
    });
  }

  isNumericCondition(conditionDetail) {
    return ['NUMBER', 'LAPSED_DAYS', 'DIFF_DAYS', 'LAPSED_MONTHS', 'EXPECTED_DAYS'].some((s) => conditionDetail.fieldType === s);
  }

  isDateCondition(conditionDetail) {
    return conditionDetail.fieldType === 'DATE';
  }

  isDateTimeCondition(conditionDetail) {
    return conditionDetail.fieldType === 'DATETIME';
  }

  generateConditionExplanation(detail) {
    if (this.isDateCondition(detail) || this.isDateTimeCondition(detail)) {
      if (CustomlistDateCondition.needConditionExplanation(detail.conditionOperator)) {
        return '実行日の';
      }
    }
    return '';
  }

  onChangeConditionOperator(detail) {
    if (this.isNumericCondition(detail)) {
      const validOperators = [
        'more_than_or_equal', 'less_than_or_equal',
        'more_than', 'less_than',
        'equal', 'between',
        'nullable_less_than_or_equal', 'nullable_less_than',
      ];
      const valid = validOperators.some((s) => detail.conditionOperator === s);
      if (!valid) {
        detail.conditionOperator = 'more_than_or_equal';
      }

      detail.showNumericSubCondition = (detail.conditionOperator === 'between');
    } else if (this.isDateCondition(detail) || this.isDateTimeCondition(detail)) {
      if (!CustomlistDateCondition.validateOperator(detail.conditionOperator)) {
        detail.conditionOperator = 'before';
      }

      detail.showDateCondition = CustomlistDateCondition.needConditionNumber1(detail.conditionOperator);
      detail.showValueCorrectType1 = CustomlistDateCondition.needCorrectType1(detail.conditionOperator);

      detail.showDateSubCondition = CustomlistDateCondition.needConditionNumber2(detail.conditionOperator);
      detail.showValueCorrectType2 = CustomlistDateCondition.needCorrectType2(detail.conditionOperator);
    } else if (detail.fieldType === 'STRING') {
      const valid = ['include', 'exclude', 'equal', 'present', 'blank'].some((s) => detail.conditionOperator === s);
      if (!valid) {
        detail.conditionOperator = 'include';
      }

      if (detail.conditionOperator === 'present' || detail.conditionOperator === 'blank') {
        detail.showStringCondition = false;
      } else {
        detail.showStringCondition = true;
      }
    } else if (detail.fieldType === 'BLE_ID') {
      const valid = ['present', 'blank'].some((s) => detail.conditionOperator === s);
      if (!valid) {
        detail.conditionOperator = 'present';
      }
    } else if (detail.fieldType === 'SELECTION' || detail.fieldType === 'MASTER') {
      const valid = ['include', 'exclude'].some((s) => detail.conditionOperator === s);
      if (!valid) {
        detail.conditionOperator = 'include';
      }
    } else if (detail.fieldType === 'CALVING_SENSOR') {
      const valid = ['present', 'blank'].some((s) => detail.conditionOperator === s);
      if (!valid) {
        detail.conditionOperator = 'present';
      }
    }
  }

  createConditionDetails(conditionDetail) {

    if (conditionDetail.selectOptions === undefined) {
      conditionDetail.selectOptions = this.baseConditionField.concat();
    }

    this.onChangeConditonDetail(conditionDetail);
    this.setConditionOperator(conditionDetail);

    return conditionDetail.selectOptions;
  }

  addConditionDetail(selectedItem, conditionIndex) {
    const conditionDetail = {
      columnId: selectedItem.value,
      label: selectedItem.label,
      conditionOperator: 'include',
      conditionString: '',
      conditionNumber1: 0,
      conditionNumber2: 0,
      valueCorrectType1: 'previous',
      valueCorrectType2: 'previous',
      showConditionString: false,
      showConditionNumber1: false,
      showConditionNumber2: false,
      showValueCorrectType1: false,
      showValueCorrectType2: false,
      conditionValue: {},
    };

    if (selectedItem.value.startsWith('work_note_type_')) {
      conditionDetail.conditionValue = {
        masterWorkNoteTypeId: this.currentMasterWorkNoteTypeId
      };
    }

    const condition = this.conditions[conditionIndex];
    condition.conditionDetails.push(conditionDetail);
    this.onChangeConditonDetail(conditionDetail);
    this.setConditionOperator(conditionDetail);

    this.$rootScope.$digest();
  }

  removeConditionDeail(details, index) {

    details.splice(index, 1);
    this.validate();
  }

  onClickSelection(detail) {
    const column = this.definitionMap[detail.columnId];
    const selections = detail.conditionValue.selections || [];

    this.SelectionDialog.execute(column.columnId, column.caption, selections).then((result) => {
      detail.conditionValue.selections = result;
      detail.conditionValueLabel = CustomlistSelection.generateSelectionLabel(detail.conditionValue.selections);
      this.validate();
    });
  }

  onClickMaster(detail) {
    const column = this.definitionMap[detail.columnId];

    let masterId;
    if (column.columnId === 'master_market_name') {
      masterId = 'master_market';
    }
    const ids = detail.conditionValue.ids || [];

    this.MasterDialog.execute(masterId, ids).then((result) => {
      detail.conditionValue.ids = result.map((m) => m.id);
      const names = result.map((m) => m.name);
      detail.conditionValueLabel = CustomlistSelection.generateSelectionLabel(names);
      this.validate();
    });
  }

  onKeydownNumber(e) {
    const isNumberKey = (key) => {
      if ('0123456789'.indexOf(key) >= 0) {
        return true;
      }
      // タブやリターンキーなどの特殊キーに対応するための例外処理
      if (key.length > 1) {
        return true;
      }
      return false;
    };

    if (!isNumberKey(e.key)) {
      e.preventDefault();
    }
  }

  selectCowGroup(condition) {
    const cowGroupIds = condition.cowGroupCondition ? condition.cowGroupCondition.split(',').map((s) => Number(s)) : [];

    this.MasterDialog.execute('cow_group', cowGroupIds).then((result) => {
      const cowGroupIds = result.map((r) => r.id).join(',');
      const cowGroupNames = result.map((r) => r.name).join('、') || '(全ての牛群が対象になります)';
      condition.cowGroupCondition = cowGroupIds;
      condition.cowGroupConditionLabel = cowGroupNames;
    });
  }

  selectState(condition) {
    const states = condition.stateCondition ? condition.stateCondition.split(',') : [];

    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'dialog/state-dialog.html',
      controller: 'StateDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'select-standard',
      resolve: {
        params: () => {
          return {
            states: states,
            farmType: this.farmType
          };
        }
      }
    });

    modalInstance.result.then((result) => {
      const states = result.map((r) => r.id).join(',');
      const label = result.map((r) => r.label).join('、') || '(全ての状態が対象になります)';
      condition.stateCondition = states;
      condition.stateConditionLabel = label;
    });
  }

  inputToModel() {
    // 概要
    const master = Customlist.inputToMaster(this.master);

    // SELECT句
    const columns = this.useItems.map((useItem, index) => {
      const definition = this.definitionMap[useItem.value];
      return {
        displayOrder: index + 1,
        columnId: definition.columnId,
        salseforceColumnId: definition.salseforceColumnId,
        shortCaption: useItem.shortCaption,
        operationType: 'RAW', // TODO 後で機能追加する
        parameterInteger: 0, // TODO 後で機能追加する
        displayStyle: useItem.displayStyle,
        options: Customlist.inputToColumnOptions(definition, useItem.options)
      };
    });

    // WHERE句
    const conditions = Customlist.inputToConditions(this.conditions, this.standardType);

    // 抽出条件詳細
    const conditionDetails = Customlist.inputToConditionDetails(this.conditions, this.standardType);

    // ORDER BY句
    const sorts = this.orderMaps
      .filter((orderMap) => orderMap.columnId)
      .map((orderMap, index) => {
        const definition = this.definitionMap[orderMap.columnId];
        return {
          sortOrder: index + 1,
          columnId: definition.columnId,
          salseforceColumnId: definition.salseforceColumnId,
          sortType: orderMap.sortType
        };
      });

    return {
      master: master,
      columns: columns,
      conditions: conditions,
      conditionDetails: conditionDetails,
      sorts: sorts
    };
  }

  formatErrorMessage(messages) {
    return messages.map((message) => message.message).join('\n');
  }

  entry() {
    const model = this.inputToModel();
    if (this.master.customlistId) {
      this.update(this.master.customlistId, model);
    } else {
      this.create(model);
    }
  }

  create(model) {
    this.message = '';
    this.blockUI.start('登録中');
    this.CustomlistAPI.create(model).then((result) => {
      this.blockUI.stop();
      this.$modalInstance.close();
    }).catch((response) => {
      this.blockUI.stop();
      this.message = this.formatErrorMessage(response.data.messages);
    });
  }

  update(customlistId, model) {
    this.message = '';
    this.blockUI.start('更新中');
    this.CustomlistAPI.update(customlistId, model).then((result) => {
      this.blockUI.stop();
      this.$modalInstance.close();
    }).catch((response) => {
      this.blockUI.stop();
      this.message = this.formatErrorMessage(response.data.messages);
    });
  }

  delete() {
    this.blockUI.start('削除中');
    this.CustomlistAPI.delete(this.master.customlistId).then((result) => {
      this.blockUI.stop();
      this.$modalInstance.close();
    }, (reject) => {
      this.blockUI.stop();
      this.message = reject;
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  generateWorkNoteItemCaption(itemId, masterWorkNoteTypeId) {
    const workNoteItem = this.workNoteTypes.find((e) => e.key === masterWorkNoteTypeId);

    switch (itemId) {
    case 'work_note_type_comment':
      return `${workNoteItem.label}のコメント`;
    case 'work_note_type_date':
      return `${workNoteItem.label}の日付`;
    case 'work_note_type_date_days':
      return `${workNoteItem.label}の経過日数`;
    }
  }

  isIncludeAlerts() {
    const alertContents = [
      'heat',
      'bad_condition',
    ];
    return alertContents.includes(this.master.alertContent);
  }

  onChangeAlertContent() {
    this.removeUnmatchedItems();
    this.refreshItems();
    this.refreshOrderItems();
    this.validate();

    if (!this.isIncludeAlerts()) {
      this.conditions.forEach((c) => c.alertOnly = false);
    }
  }

  removeUnmatchedItems() {
    this.useItems = this.useItems.filter((item) => {
      const definition = this.definitions.find((definition) => {
        return definition.columnId === item.value;
      });
      return definition.isAvailable(this.master);
    });
  }
}

app.controller('CustomlistEditController', CustomlistEditController);
