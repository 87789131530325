function AccountEditDirective() {
  return {
    restrict: 'E',
    template: '<div ng-include="contentUrl"></div>', // ES6のテンプレート文字列を使うと動かない！
    link: (scope, element, attrs) => {
      scope.contentUrl = `menu/account/template/${scope.ctrl.viewMode.mode}.html`;
    }
  };
}

app.directive('accountEditDirective', AccountEditDirective);
