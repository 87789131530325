function chartActivityHeatIndexComponent() {
  'ngInject';
  return {
    controller: 'ChartActivityHeatIndexController',
    controllerAs: 'ctrl',
    templateUrl: 'components/chart/activity/heat-index/index.html',
    transclude: true,
    bindings: {
      cow: '<',
      height: '<',
      paddingTop: '<',
      paddingBottom: '<',
      paddingRight: '<',
      paddingLeft: '<',
      disableHourlyTick: '<',
      defaultActivityPeriod: '<',
      onChangePeriod: '&'
    }
  };
}
app.component('chartActivityHeatIndex', chartActivityHeatIndexComponent());
