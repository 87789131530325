app.service('OrderedSummaryReportService', ($http, $rootScope, JWTService) => {
  'ngInject';
  let service = {

    getSummaryReport: (menuId, startDate, endDate) => {
      // YYYY-MM-DD形式に変換する
      let paramStartDate = startDate ? getDateLabelForApiResDateTime(startDate) : '1900-01-01';
      let paramEndDate = endDate ? getDateLabelForApiResDateTime(endDate) : '9999-12-31';
      let params = 'start_date=' + paramStartDate + '&end_date=' + paramEndDate;
      return JWTService.sendJWTBlobRequest('/ordered-summary-report/' + menuId + '?' + params, 'GET');
    }

  };
  return service;
});
