class HomeHeadageSummaryBreedingController {
  constructor(
    $state,
    SessionCache
  ) {
    'ngInject';

    this.$state = $state;

    HomeHeadageBaseController.bind(this);

    const farm = SessionCache.farm();

    this.init(farm);
  }

  init(farm) {
    this.isDairy = farm.isDairy();
    this.isBreeding = farm.isBreeding();
    this.isFattening = farm.isFattening();
  }

  $onChanges() {
    if (!this.data) return;

    this.showFattening = !this.isDairy &&
      this.isBreeding &&
      this.isFattening &&
      this.data.breeding.fattening;
  }
}

function homeHeadageSummaryBreeding() {
  return {
    templateUrl: 'menu/home/headage/summary/breeding/index.html',
    controller: HomeHeadageSummaryBreedingController,
    controllerAs: 'ctrl',
    bindings: {
      data: '<'
    }
  };
}

app.component('homeHeadageSummaryBreeding', homeHeadageSummaryBreeding());
