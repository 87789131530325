// eslint-disable-next-line no-unused-vars
class CowEventClipboardHandler {
  constructor(StandardDialog) {
    this.StandardDialog = StandardDialog;
  }

  parse(legacyEventType, matrix) {
    if (matrix.length === 0) {
      this.StandardDialog.showMessage({
        title: 'エラー',
        text1: '貼り付ける内容がコピーされていません。',
      });
      return null;
    }

    const headers = matrix[0];
    let values = matrix.slice(1);

    let items = CowEventBulkInputDefinition.pasteItems(legacyEventType);
    items.forEach((item) => {
      item.index = -1;

      headers.forEach((header, index) => {
        if (item.index >= 0) return;

        if (item.labels.includes(header)) {
          item.index = index;
          item.actualLabel = header;
        }
      });
    });

    const validateResult = this.validateHeader(headers, items);
    if (!validateResult.valid) {
      const message = validateResult.errors.map((e) => `・${e}`).join('<br>');

      this.StandardDialog.showMessage({
        title: 'エラー',
        text1: '以下の列が含まれていません。以下の列を含めてコピーしてください。',
        htmlMessage: message,
      });
      return null;
    }

    items = items.filter((item) => item.index >= 0);

    const eventNameIndex = headers.indexOf('イベント名称');
    const eventName = EventType.eventNameForLegacy(legacyEventType);

    const rows = values.map((value, index) => {
      if (value[eventNameIndex] !== eventName) {
        return null;
      }

      const row = {
        lineNo: index + 2
      };
      items.forEach((item) => {
        row[item.id] = value[item.index].trim();

        if (item.id === 'occurredAt') {
          row.dateLabel = item.actualLabel;
        }
      });
      return row;
    }).filter((row) => row);

    if (rows.length === 0) {
      this.StandardDialog.showMessage({
        title: 'エラー',
        text1: '登録対象のイベントデータが含まれていません。',
        text2: 'イベント種別を確認してからコピーを行ってください。',
      });
      return null;
    }

    items.unshift({id: 'lineNo', actualLabel: '行番号', width: 60});

    return {
      items: items,
      rows: rows
    };
  }

  validateHeader(headers, items) {
    const errors = [];

    if (headers.indexOf('イベント名称') < 0) {
      errors.push('イベント名称');
    }

    items.filter((item) => item.required).forEach((item) => {
      if (item.index < 0) {
        let label = item.labels[0];
        if (item.labels.length >= 2) {
          label = `${label} (または ${item.labels[1]})`;
        }
        errors.push(label);
      }
    });

    return {
      valid: errors.length === 0,
      errors: errors
    };
  }

  sampleItems(legacyEventType) {
    const items = CowEventBulkInputDefinition.pasteItems(legacyEventType);
    items.forEach((item) => {
      item.actualLabel = item.labels[0];
    });

    return {
      items: items,
      rows: []
    };
  }

  static rowToCowEvent(legacyEventType, rows, option) {
    return rows.map((row) => {
      const cowEvent = Object.assign({}, row);
      cowEvent.eventType = legacyEventType;

      const dateFormats = [
        'YYYY/MM/DD', 'YYYY/M/D',
        'YYYY/MM/DD HH:mm', 'YYYY/M/D HH:mm',
      ];

      if (row.occurredAt && DateUtil.isValidDate(row.occurredAt, dateFormats)) {
        cowEvent.occurredAt = DateUtil.toMSec(row.occurredAt.replace(/\//g, '-'));
      } else {
        cowEvent.occurredAt = null;
        cowEvent.occurredAtString = row.occurredAt;
      }

      switch (legacyEventType) {
      case 'gyugunidou': {
        cowEvent.moveToCowGroupName = row.moveToCowGroupId;
        const cowGroup = option.cowGroups.find((cowGroup) => {
          return cowGroup.cowGroupName === cowEvent.moveToCowGroupName;
        });
        if (cowGroup) {
          cowEvent.moveToCowGroupId = cowGroup.cowGroupId;
        } else {
          cowEvent.moveToCowGroupId = null;
        }

        if (row.workerName) {
          const workerName = option.workerNames.find((name) => {
            return StringUtil.isSameName(name, row.workerName);
          });
          if (workerName) {
            cowEvent.workerName = workerName;
          }
        }

        return cowEvent;
      }
      case 'tanetsuke': {
        if (row.masterSpermNo) {
          const masterSperm = NormalizedString.find(
            option.masterSperms, 'spermNo', cowEvent.masterSpermNo
          );
          if (masterSperm) {
            cowEvent.masterSpermId = masterSperm.id;
            cowEvent.masterSpermNo = masterSperm.spermNo;
          }
        }

        if (row.masterEmbryoName) {
          const masterEmbryo = NormalizedString.find(
            option.masterEmbryos, 'name', row.masterEmbryoName
          );
          if (masterEmbryo) {
            cowEvent.masterEmbryoId = masterEmbryo.id;
            EventState.setEmbryo(cowEvent, masterEmbryo);
          }
        }

        const bredForEmbryoRecovery = (row.bredForEmbryoRecovery || '').trim();
        if (bredForEmbryoRecovery) {
          cowEvent.bredForEmbryoRecovery = true;
        } else {
          cowEvent.bredForEmbryoRecovery = false;
        }

        if (row.workerName) {
          const workerName = option.inseminators.find((name) => {
            return StringUtil.isSameName(name, row.workerName);
          });
          if (workerName) {
            cowEvent.workerName = workerName;
          }
        }

        return cowEvent;
      }
      case 'ninshinkantei': {
        if (row.workerName) {
          const workerName = option.pregnancyAppraisers.find((name) => {
            return StringUtil.isSameName(name, row.workerName);
          });
          if (workerName) {
            cowEvent.workerName = workerName;
          }
        }

        return cowEvent;
      }
      }
      return cowEvent;
    });
  }
}
