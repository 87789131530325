class MobileBulkRemoveLabelModalController {
  constructor(
    $modalInstance,
    SessionCache,
    MasterCowLabelAPI,
    CowLabelAPI,
    DepositCowService,
    CowService,
    blockUI,
    cows,
    MobileConfirmModal
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.cowLabelMasters = [];
    this.blockUI = blockUI;
    this.cows = cows;
    this.cowLabels = [];
    this.MobileConfirmModal = MobileConfirmModal;

    // [Note]
    // 現状ではモバイルは代行入力に対応していないのでこの実装は不要だが
    // 実装としては正しいのでそのままにしています。
    if (SessionCache.farm().isDepositor()) {
      this.cowLabelAPI = DepositCowLabelAPI;
      this.cowService = DepositCowService;
    } else {
      this.cowLabelAPI = CowLabelAPI;
      this.cowService = CowService;
    }

    this.onInit(MasterCowLabelAPI);
  }

  onInit(MasterCowLabelAPI) {
    const cowLabelsSet = new Set();
    this.cows.forEach((cow) => {
      cow.cowLabels.forEach((label) => cowLabelsSet.add(label));
    });

    MasterCowLabelAPI.available().then((res) => {
      const masters = res.data;
      this.cowLabels = masters
        .filter((m) => cowLabelsSet.has(m.name))
        .map((m) => {
          return {
            value: m.id,
            label: m.name,
            checked: false
          };
        });
    });
  }

  onCloseClick() {
    this.$modalInstance.dismiss({
      status: {
        cancel: true
      }
    });
  }

  onSubmitClick() {
    this.blockUI.start('ラベル除去中');

    const cowIds = this.cows.map((c) => c.cowId);
    const labels = this.cowLabels
      .filter((label) => label.checked)
      .map((label) => {
        return {
          masterCowLabelId: label.value
        };
      });

    this.cowLabelAPI.bulkRemove(cowIds, labels)
      .then(() => {
        this.blockUI.stop();
        this.$modalInstance.close({
          status: {
            ok: true
          }
        });
      })
      .catch((error) => {
        console.error(error);
        this.blockUI.stop();
        this.MobileConfirmModal.open('エラーが発生しました')
          .then(() => {
            this.$modalInstance.dismiss({
              status: {
                error: true
              }
            });
          });
      });
  }
}

app.controller('MobileBulkRemoveLabelModalController', MobileBulkRemoveLabelModalController);
