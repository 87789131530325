// eslint-disable-next-line no-unused-vars
class ClipboardUtil {

  /**
   * クリップボードに書き込まれた内容を取得します。
   * navigator.clipboard.readText に合わせてPromiseを返すようにいています。
   * @return {Promise}
   */
  static pasteFromClipboard() {
    if (navigator.clipboard) {
      return navigator.clipboard.readText();
    } else if (window.clipboardData) {
      const text = window.clipboardData.getData('Text');
      return new Promise((resolve) => {
        resolve(text);
      });
    }
  }

  /**
   * 任意の文字列をクリップボードに書き込みます
   * クリップボード操作が他にも必要になったら
   * @param {string} value
   */
  static copyToClipboard(value) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(value);
    } else if (window.clipboardData) {
      window.clipboardData.setData('Text', value);
    }
  }
}
