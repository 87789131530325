// eslint-disable-next-line no-unused-vars
class HelpData {
  static get breedingPdfFiles() {
    return [
      {
        title: 'おすすめのご利用ツール',
        description: '推奨のOSやブラウザについて',
        url: 'http://www.u-motion.co.jp/manual/forApp/0_tool_daily%26breeding.pdf'
      },
      {
        title: 'ログイン・ログアウト',
        description: 'ログイン・ログアウト、パスワードを忘れたときの再設定方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/0_login_daily%26breeding.pdf'
      },
      {
        title: 'ご利用開始時の確認',
        description: 'お使い始める際に重要な「牛個体の識別方法」の確認・変更方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/0_start_daily%26breeding.pdf'
      },
      {
        title: 'メニュー一覧',
        description: 'パソコン・タブレット・スマートフォンで使用できるメニューについて',
        url: 'http://www.u-motion.co.jp/manual/forApp/0_menu_daily%26breeding.pdf'
      },
      {
        title: 'ホーム画面の確認方法',
        description: 'パソコン・タブレット・スマートフォンのホーム画面について',
        url: 'http://www.u-motion.co.jp/manual/forApp/0_home_daily%26breeding.pdf'
      },
      {
        title: 'アラートを活用する',
        description: 'アラートの種類や仕組み、確認方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_alert_daily%26breeding.pdf'
      },
      {
        title: '牛個別ページの見方',
        description: '牛個体情報画面の各種項目情報やグラフデータの見方について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_cowpage_daily%26breeding.pdf'
      },
      {
        title: '牧場全体の搾乳量の確認方法',
        description: '搾乳量取り込みを行っている場合、牧場全体の搾乳量を確認する方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_milkamount_daily%26breeding.pdf',
        isMilk: true
      },
      {
        title: '牛群リストの確認方法',
        description: '牛群リストの見方やリストの設定方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_cowlist_daily%26breeding.pdf'
      },
      {
        title: '牛を検索する',
        description: '番号入力での簡易検索や細かな条件入力での詳細検索について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_cowsearch_daily%26breeding.pdf'
      },
      {
        title: 'イベント登録で牛の記録を残す',
        description: 'イベントを個別または一括で登録する方法と表示される画面について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_event_daily%26breeding.pdf'
      },
      {
        title: 'イベント登録を簡単に行うために',
        description: '毎回のイベント登録が簡単になる各項目の設定方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_setting_daily%26breeding.pdf'
      },
      {
        title: 'タグ脱落や異常牛を散布図で見つける',
        description: 'タグ脱落や異常のある牛を活動量散布図で見つける方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_plot_daily%26breeding.pdf'
      },
      {
        title: '繁殖予定で繁殖管理をする',
        description: '繁殖予定機能を使うための設定や予定リストの使い方について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_breedschedule_daily%26breeding.pdf'
      },
      {
        title: '牛の『状態』と『状態』の変化とは？',
        description: 'U-motion内で牛に表記される「状態」について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_cowstage_daily%26breeding.pdf'
      },
      {
        title: '牛個体データを登録する',
        description: '個体データを個別または一括で登録する方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_cowinput_daily%26breeding.pdf'
      },
      {
        title: 'センサー番号の設定',
        description: '個体データにU-motionのセンサー番号を適用・解除する方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_sensor_daily%26breeding.pdf'
      },
      {
        title: '出荷イベントの登録',
        description: '出荷した牛に必要な「出荷」イベントの登録方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_shipment_daily%26breeding.pdf'
      },
      {
        title: '個別リストで牛を抽出する',
        description: '個別リストの表示項目や条件設定と、リストの確認方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_customlist_daily%26breeding.pdf'
      },
      {
        title: '注視する牛に個体ラベルを付ける',
        description: '個体ラベルのラベル作成方法と貼付け・除去の方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_label_daily%26breeding.pdf'
      },
      {
        title: '出生報告カードを印刷する',
        description: '出生報告カードの印刷方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_birth_daily%26breeding.pdf'
      },
      {
        title: '授精証明書を印刷する',
        description: '授精証明書の印刷方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_insemination_daily%26breeding.pdf'
      },
      {
        title: '牛舎環境（THI・気温・湿度）データを見る',
        description: '牛舎環境（THI・気温・湿度）データを確認する方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_thi_daily%26breeding.pdf'
      },
      {
        title: 'レポートで繁殖成績を分析する',
        description: '発情発見率・妊娠率・受胎率などの繁殖成績を確認する方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_report_daily%26breeding.pdf'
      },
      {
        title: '泌乳成績を分析する',
        description: '乳質などに関するデータの取り込み方法と、泌乳成績の確認方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_lactation_daily%26breeding.pdf',
        isMilk: true
      },
      {
        title: 'ゲノミック評価を確認する',
        description: '家畜改良事業団様の肉用牛ゲノミック評価を表示させる方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_genomic_daily%26breeding.pdf',
        isBeef: true,
        isNew: true,
      },
      {
        title: '分析グラフで活動量推移を確認する',
        description: '分析を行うためのグラフ設定と表示させる方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_analysisgraph_daily%26breeding.pdf'
      },
      {
        title: 'U-motionからのお知らせ',
        description: 'U-motionからの機能追加などお知らせを確認する方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_notice_daily%26breeding.pdf'
      },
      {
        title: 'ベルトの装着方法',
        description: 'ベルト装着の方法やポイントについて',
        url: 'http://www.u-motion.co.jp/manual/forApp/belt.pdf'
      },
      {
        title: 'UM Utilsの使用方法',
        description: 'アラート通知やセンサー番号調査などが可能な便利アプリの使用方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/um_utils.pdf'
      },
      {
        title: '故障疑いのセンサーがあった場合の対応方法',
        description: '首用センサー・分娩センサーで故障かなと思った際の対応方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/sensor_report.pdf',
        isNew: true,
      },
      {
        title: '【オプション】分娩兆候アラート',
        description: '分娩センサーの装着方法や、アラートの確認方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/calving.pdf',
        isBeef: true
      },
      {
        title: '【オプション】分娩兆候アラート',
        description: '分娩センサーの装着方法や、アラートの確認方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/calving.pdf',
        isMilk: true,
      },
      {
        title: 'お問い合わせ',
        description: 'お問い合わせ先の情報について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_contact_daily%26breeding.pdf'
      }
    ];
  }

  static get fatteningPdfFiles() {
    return [
      {
        title: 'おすすめのご利用ツール',
        description: '推奨のOSやブラウザについて',
        url: 'http://www.u-motion.co.jp/manual/forApp/0_tool_fattening.pdf'
      },
      {
        title: 'ログイン・ログアウト',
        description: 'ログイン・ログアウト、パスワードを忘れたときの再設定方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/0_login_fattening.pdf'
      },
      {
        title: 'ご利用開始時の確認',
        description: 'お使い始める際に重要な「牛個体の識別方法」の確認・変更方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/0_start_fattening.pdf'
      },
      {
        title: 'メニュー一覧',
        description: 'パソコン・タブレット・スマートフォンで使用できるメニューについて',
        url: 'http://www.u-motion.co.jp/manual/forApp/0_menu_fattening.pdf'
      },
      {
        title: 'ホーム画面の確認方法',
        description: 'パソコン・タブレット・スマートフォンのホーム画面について',
        url: 'http://www.u-motion.co.jp/manual/forApp/0_home_fattening.pdf'
      },
      {
        title: 'アラートを活用する',
        description: 'アラートの種類や仕組み、確認方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_alert_fattening.pdf'
      },
      {
        title: '牛個別ページの見方',
        description: '牛個体情報画面の各種項目情報やグラフデータの見方について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_cowpage_fattening.pdf'
      },
      {
        title: '牛群リストの確認方法',
        description: '牛群リストの見方やリストの設定方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_cowlist_fattening.pdf'
      },
      {
        title: '牛を検索する',
        description: '番号入力での簡易検索や細かな条件入力での詳細検索について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_cowsearch_fattening.pdf'
      },
      {
        title: 'イベント登録で牛の記録を残す',
        description: 'イベントを個別または一括で登録する方法と表示される画面について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_event_fattening.pdf'
      },
      {
        title: 'イベント登録を簡単に行うために',
        description: '毎回のイベント登録が簡単になる各項目の設定方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_setting_fattening.pdf'
      },
      {
        title: 'タグ脱落や異常牛を散布図で見つける',
        description: 'タグ脱落や異常のある牛を活動量散布図で見つける方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/1_plot_fattening.pdf'
      },
      {
        title: '牛の『状態』と『状態』の変化とは？',
        description: 'U-motion内で牛に表記される「状態」について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_cowstage_fattening.pdf'
      },
      {
        title: '牛個体データを登録する',
        description: '個体データを個別または一括で登録する方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_cowinput_fattening.pdf'
      },
      {
        title: 'センサー番号の設定',
        description: '個体データにU-motionのセンサー番号を適用・解除する方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_sensor_fattening.pdf'
      },
      {
        title: '出荷イベントの登録',
        description: '出荷した牛に必要な「出荷」イベントの登録方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_shipment_fattening.pdf'
      },
      {
        title: '肥育ステージについて',
        description: '肥育ステージ別で管理する方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_stage_fattening.pdf'
      },
      {
        title: '個別リストで牛を抽出する',
        description: '個別リストの表示項目や条件設定と、リストの確認方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_customlist_fattening.pdf'
      },
      {
        title: '注視する牛に個体ラベルを付ける',
        description: '個体ラベルのラベル作成方法と貼付け・除去の方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_label_fattening.pdf'
      },
      {
        title: '牛舎環境（THI・気温・湿度）データを見る',
        description: '牛舎環境（THI・気温・湿度）データを確認する方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_thi_fattening.pdf'
      },
      {
        title: 'レポートで成績を分析する',
        description: '肉牛レポート（肥育関連のKPI）や枝肉成績を確認する方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_report_fattening.pdf'
      },
      {
        title: '分析グラフで活動量推移を確認する',
        description: '分析を行うためのグラフ設定と表示させる方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_analysisgraph_fattening.pdf'
      },
      {
        title: '生産履歴証明書を出力する',
        description: '生産履歴証明書の出力方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_certificate_fattening.pdf'
      },
      {
        title: 'U-motionからのお知らせ',
        description: 'U-motionからの機能追加などお知らせを確認する方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_notice_fattening.pdf'
      },
      {
        title: 'ベルトの装着方法',
        description: 'ベルト装着の方法やポイントについて',
        url: 'http://www.u-motion.co.jp/manual/forApp/belt.pdf'
      },
      {
        title: 'UM Utilsの使用方法',
        description: 'アラート通知やセンサー番号調査などが可能な便利アプリの使用方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/um_utils.pdf'
      },
      {
        title: '故障疑いのセンサーがあった場合の対応方法',
        description: '首用センサー・分娩センサーで故障かなと思った際の対応方法について',
        url: 'http://www.u-motion.co.jp/manual/forApp/sensor_report.pdf',
        isNew: true,
      },
      {
        title: 'お問い合わせ',
        description: 'お問い合わせ先の情報について',
        url: 'http://www.u-motion.co.jp/manual/forApp/2_contact_fattening.pdf'
      }
    ];
  }

  static get commonVideoFiles() {
    return [
      {
        title: 'ご利用ツールについて',
        description: 'パソコン、タブレット、スマートフォンからご利用いただけますが、デバイスによってご利用できる機能が異なります。',
        id: '363495848'
      },
      {
        title: '牛個体の識別について',
        description: 'U-motionでは牛の識別方法（キーとなる項目）が、4種類あります。お使いいただく段階でご確認をお願いします。',
        id: '364244907'
      },
      {
        title: '出荷の場合',
        description: '牛の出荷した時には、出荷イベントをご登録いただきます。',
        id: '374319212'
      },
      {
        title: 'へい死の場合',
        description: 'へい死の場合には、へい死イベントをご登録いただきます。',
        id: '374315720'
      },
    ];
  }

  static get milkVideoFiles() {
    return [
      ...this.commonVideoFiles,
      {
        title: 'アラートについて',
        description: 'どのようにアラートが通知されるか、どのタイミングで確認するのが良いか、確認の仕方をご説明します。',
        id: '364276155'
      },
      {
        title: '牛個別ページの見方',
        description: '牛一頭ずつにページがあり、その牛の基本情報、発情指数や活動量の推移、同じ牛群にいる牛の平均との比較などができます。',
        id: '364246474'
      },
      {
        title: '治療記録を残す',
        description: '何の病気だったのか、何を投薬したか、など治療の詳細記録を残すことができます。',
        id: '368490016'
      },
      {
        title: 'イベントで繁殖に関する記録を残す',
        description: '発情や種付の記録を残すことができます。',
        id: '369235177'
      },
      {
        title: '個別リストで任意の条件で牛を抽出',
        description: '治療から1週間の牛、などの注意が必要な牛や、繁殖に関する作業が必要な牛を抽出することができます。',
        id: '368527455'
      },
      {
        title: '個体ラベルで牛に目印を付ける',
        description: '注意を払うべきに対し、「注意牛」「淘汰候補」などお好きな名称のラベルを付けることができます。',
        id: '368531311'
      },
      {
        title: '繁殖予定で繁殖管理する',
        description: '繁殖予定で、今日の牧場全体での繁殖に関する予定がわかります。繁殖予定の仕組み、繁殖に関する値の設定の仕方、表示期間などご説明します。',
        id: '373290140'
      },
      {
        title: '牛の検索について',
        description: '牛番号、個体識別番号、センサー番号での検索ができます。その他に、「3回以上授精し、まだ妊娠していない牛」のような、詳細検索も可能です。',
        id: '373284431'
      },
      {
        title: '牛の個体登録・センサータグの登録',
        description: '新たに導入した牛の情報をぜひご登録ください。登録は、一頭ずつの個別登録、複数の牛の一括登録が可能です。センサーを着ける場合、センサー番号の登録もお忘れなく！',
        id: '376100410'
      },
      {
        title: '搾乳量を確認する',
        description: '牧場全体、牛毎の搾乳量を確認することができます。※乳量連携を行っている場合に限ります',
        id: '376099476'
      },
      {
        title: '繁殖レポート',
        description: '発情発見率・妊娠率・受胎率など、繁殖に関する成績がグラフでわかります。',
        id: '376950937'
      },
      {
        title: '分娩センサーの取り付け方法',
        description: '分娩センサーをお使いの農家さんに脱落しにくいコツを紹介していただきました！',
        id: '565523078',
        isNew: true
      },
    ];
  }

  static get fatteningVideoFiles() {
    return [
      {
        title: '【肥育】アラートについて',
        description: 'どのようにアラートが通知されるか、どのタイミングで確認するのが良いか、確認の仕方をご説明します。',
        id: '364245737'
      },
      {
        title: '【肥育】牛個別ページの見方',
        description: '牛一頭ずつにページがあり、その牛の基本情報や活動量の推移、同じ肥育ステージにいる牛の平均との比較などができます。',
        id: '364246606'
      },
      {
        title: '【肥育】治療記録を残す',
        description: '何の病気だったのか、何を投薬したか、など治療の詳細記録を残すことができます。',
        id: '368484581'
      },
      {
        title: '【肥育】個別リストで任意の条件で牛を抽出',
        description: '治療から1週間の牛、など、注意が必要な牛や、作業が必要な牛を抽出することができます。',
        id: '368456867'
      },
      {
        title: '【肥育】個体ラベルで牛に目印を付ける',
        description: '注意を払うべきに対し、「注意牛」「起立困難牛」などお好きな名称のラベルを付けることができます。',
        id: '368457866'
      },
      {
        title: '【肥育】肥育ステージとは？',
        description: '肥育期間は、前期・中期・仕上げ期などの各ステージに分けられますが、これらの期間を牧場毎に設定ができます。',
        id: '368458174'
      },
      {
        title: '【肥育】枝肉成績の記録を残す',
        description: '出荷後の牛の枝肉成績を記録しておくことで、どういったケースが枝肉成績がいいか分析できるようになります。',
        id: '375858067'
      },
      {
        title: '【肥育】牛の検索について',
        description: '牛番号、個体識別番号、センサー番号での検索ができます。その他に、「月齢25か月以上で、病気の牛（病名ラベルが貼られた牛）」のような、詳細検索も可能です。',
        id: '375858388'
      },
      {
        title: '【肥育】牛の個体登録・センサータグの登録',
        description: '新たに導入した牛の情報をぜひご登録ください。登録は、一頭ずつの個別登録、複数の牛の一括登録が可能です。センサー番号の登録もお忘れなく！',
        id: '375858717'
      },
    ];
  }

  static get breedingVideoFiles() {
    return [
      {
        title: '【繁殖】アラートについて',
        description: 'どのようにアラートが通知されるか、どのタイミングで確認するのが良いか、確認の仕方をご説明します。',
        id: '364276155'
      },
      {
        title: '【繁殖】牛個別ページの見方',
        description: '牛一頭ずつにページがあり、その牛の基本情報、発情指数や活動量の推移、同じ牛群にいる牛の平均との比較などができます。',
        id: '364246474'
      },
      {
        title: '【繁殖】治療記録を残す',
        description: '何の病気だったのか、何を投薬したか、など治療の詳細記録を残すことができます。',
        id: '368490016'
      },
      {
        title: '【繁殖】イベントで繁殖に関する記録を残す',
        description: '種付や分娩など繁殖に関する記録を残すことができます。',
        id: '369235177'
      },
      {
        title: '【繁殖】個別リストで任意の条件で牛を抽出',
        description: '治療から1週間の牛、などの注意が必要な牛や、繁殖に関する作業が必要な牛を抽出することができます。',
        id: '368527455'
      },
      {
        title: '【繁殖】個体ラベルで牛に目印を付ける',
        description: '注意を払うべきに対し、「注意牛」「淘汰候補」などお好きな名称のラベルを付けることができます。',
        id: '368531311'
      },
      {
        title: '【繁殖】繁殖予定で繁殖管理する',
        description: '繁殖予定で、今日の牧場全体での繁殖に関する予定がわかります。繁殖予定の仕組み、繁殖に関する値の設定の仕方、表示期間などご説明します。',
        id: '373290140'
      },
      {
        title: '【繁殖】牛の検索について',
        description: '牛番号、個体識別番号、センサー番号での検索ができます。その他に、「3回以上授精し、まだ妊娠していない牛」のような、詳細検索も可能です。',
        id: '373284431'
      },
      {
        title: '【繁殖】牛の個体登録・センサータグの登録',
        description: '新たに導入した牛の情報をぜひご登録ください。登録は、一頭ずつの個別登録、複数の牛の一括登録が可能です。センサー番号の登録もお忘れなく！',
        id: '376100410'
      },
      {
        title: '【繁殖】繁殖レポート',
        description: '発情発見率・妊娠率・受胎率など、繁殖に関する成績がグラフでわかります。',
        id: '376950937'
      },
      {
        title: '【繁殖】分娩センサーの取り付け方法',
        description: '分娩センサーをお使いの農家さんに脱落しにくいコツを紹介していただきました！',
        id: '565523078'
      },
    ];
  }
}
