/**
 * um-form-inputコンポーネント
 *
 * INPUTタグを生成する
 *
 * 使い方
 * <um-form>
 *   <um-form-group layout="horizontal">
 *     <um-form-input
 *       um-label="時"
 *       um-type="text"
 *       um-width="50"
 *       um-model="ctrl.selectedHour"></um-form-input>
 *     <um-form-input
 *       um-label="分"
 *       um-type="text"
 *       um-width="50"
 *       um-model="ctrl.selectedMinute"></um-form-input>
 *   </um-form-group>
 * </um-form>
 *
 * パラメータ:
 * @param {string} um-type 'text' | 'select' | 'checkbox' | 'radio'
 * @param {string} um-id inputフィールドのidを指定
 * @param {string} um-max-length フィールドの最大文字数
 * @param {string} um-label フィールドの上に表示されるラベル
 * @param {string} um-format-check 'number' | 'decimal' | 'date' | 'alphaNum' format-check ディレクティブを挿入し、入力値のテストを行う
 * @param {string} um-field-label フィールドの内側、先頭に表示されるラベル
 * @param {string} um-options inputフィールドにng-optionsを設定する
 * @param {string} um-options-as inputフィールドのng-optionsに'as'を設定する
 * @param {string} um-options-for inputフィールドのng-optionsに'for'を設定する
 * @param {string} um-options-select inputフィールドのng-optionsに'select'を設定する
 * @param {string} um-width inputフィールドの幅 '50' | '75' | '100'
 * @param {string} um-icon inputフィールドに表示するiconのCSSクラスを指定する
 * @param {string} um-unit inputフィールドに表示する単位を文字列で指定する
 * @param {string} um-id inputフィールドのidを指定
 * @param {string} um-name inputフィールドのnameを指定
 * @param {angular変数(bool)} um-has-error inputフィールドにエラーが発生しているかのフラグ
 * @param {string} um-error-messages エラーメッセージ(後方互換用)
 * @param {string} um-error-message エラーメッセージ
 * @param {angular変数(object)} um-error-status エラー発生状況({errorType: true | false})
 * @param {angular変数(bool)} um-is-required 「必須」ラベルの表示／非表示を切り替えるフラグ
 * @param {angular変数(bool)} um-is-disabled disabledアトリビュートの設定/解除
 * @param {angular変数(bool)} um-checked ng-checkedを設定する
 * @param {angular変数} um-model inputフィールドにng-modelを設定する
 * @param {string} um-step 小数点の指定 (0.01で小数点2桁)
 * @param {number} um-max 最大値を指定
 * @param {angular expresion} um-is-children um-form-groupの子要素では自動的にtrueになりますが、
 *                            ng-repeatを使用する場合など親要素を判別できない場合は明示的に指定
 *                            してください
 * @param {string} um-value option/radioタグのvalueアトリビュートの設定
 * @param {angular expresion} um-init inputフィールドにng-initを設定する
 * @param {angular expresion} um-change inputフィールドにng-changeを設定する
 * @param {angular expresion} um-blur inputフィールドにng-blurを設定する
 * @param {angular expresion} um-click inputフィールドにng-clickを設定する
 * @param {angular expresion} um-disabled inputフィールドにng-disabledを設定する
 * @param {angular expresion} um-on-update inputフィールド更新時に実行する処理
 * @param {angular expresion} um-validity-number-group-class inputフィールド更新時に実行する処理
 *
 */

const TYPES = ['text', 'select', 'radio', 'checkbox', 'textarea', 'radioGroup', 'number', 'validity-number'];

app.component('umFormInput', {
  bindings: {
    'umType': '@',
    'umOptionsAs': '@',
    'umOptionsFor': '@',
    'umOptionsSelect': '@',
    'umOptionsTrackBy': '@',
    'umMaxLength': '@',
    'umFormatCheck': '@',
    'umLabel': '@',
    'umFieldLabel': '@',
    'umWidth': '@',
    'umIcon': '@',
    'umUnit': '@',
    'umId': '@',
    'umName': '@',
    'umAddNullOption': '<',
    'umHasError': '@',
    'umPlaceholder': '@',
    'umIsChildren': '<',
    'umIsDate': '<',
    'umErrorMessage': '<',
    'umErrorMessages': '<',
    'umErrorStatus': '<',
    'umIsRequired': '<',
    'umIsDisabled': '<',
    'umChecked': '<',
    'umOptions': '<',
    'umValue': '@',
    'umModel': '=',
    'umStep': '@',
    'umMax': '<',
    'umInit': '&',
    'umChange': '&',
    'umBlur': '&',
    'umClick': '&',
    'umOnUpdate': '&',
    'umDisabled': '&',
    'umValidityNumberGroupClass': '@',
    'umInputmode': '@',
    'umClear': '@',
  },
  transclude: true,
  controller: 'UmFormInputController',
  template: ($attrs, $element) => {
    'ngInject';

    if (!$attrs.umType || !TYPES.some((type) => type === $attrs.umType)) return;

    // 親エレメントのクラスを見て個別のパーツをラップするHTMLを変える
    const parentLayout = ($element[0] && $element[0].parentElement) ? $element[0].parentElement.attributes.getNamedItem('um-layout') : null;
    let isGroupItem = ($element[0] && $element[0].parentElement) ? $element[0].parentElement.localName === 'um-form-group' : false;

    const type = $attrs.umType;
    const derivedAttrsStr = getDerivedAttrsStr($attrs);

    switch (type) {
    case 'text':
      const textTemplate = wrapWithCommonDiv(getTextTemplate(derivedAttrsStr));

      if (parentLayout && parentLayout.value === 'horizontal') {
        return textTemplate;
      } else {
        return wrapWithMfGroup(parentLayout && parentLayout.value === 'vertical', textTemplate);
      }
    case 'number':
      const numberTemplate = wrapWithCommonDiv(
        getNumberTemplate(derivedAttrsStr)
      );

      if (parentLayout && parentLayout.value === 'horizontal') {
        return numberTemplate;
      } else {
        return wrapWithMfGroup(parentLayout && parentLayout.value === 'vertical', numberTemplate);
      }
    case 'validity-number':
      const validityNumberTemplate = wrapValidityNumberWithDiv(
        getValidityNumberTemplate(derivedAttrsStr)
      );

      if (parentLayout && parentLayout.value === 'horizontal') {
        return validityNumberTemplate;
      } else {
        return wrapValidityNumberWithMfGroup(parentLayout && parentLayout.value === 'vertical', validityNumberTemplate);
      }
    case 'select':
      const selectTemplate = wrapWithCommonDiv(getSelectTemplate(derivedAttrsStr));

      if (parentLayout && parentLayout.value === 'horizontal') {
        return selectTemplate;
      } else {
        return wrapWithMfGroup(parentLayout && parentLayout.value === 'vertical', selectTemplate);
      }
    case 'radio':
      return getRadioTemplate(isGroupItem && parentLayout.value === 'radio-group', derivedAttrsStr);
    case 'checkbox':
      return getCheckboxTemplate(isGroupItem && parentLayout.value === 'checkbox-group', derivedAttrsStr);
    case 'textarea':
      return wrapWithMfGroup(false, wrapWithCommonDiv(getTextareaTemplate(derivedAttrsStr)));
    }
  },
});

/**
 * input type="text" のHTMLを生成
 * @param {string} derrivedAttrs inputフィールドに差し込むアトリビュート文字列(id="xx")
 */
function getTextTemplate(derrivedAttrs) {
  return `
    <div class="mf-field">
      <span ng-class="{'is-error': $ctrl.umErrorMessage}" ng-if="$ctrl.umFieldLabel" class="mf-unit">{{$ctrl.umFieldLabel}}</span>
      <input type="text" ng-class="{'form-control': true, 'has-icon': $ctrl.umIcon, 'has-clear': $ctrl.umClear, 'has-unit': $ctrl.umFieldLabel || $ctrl.umUnit, 'is-error': $ctrl.umErrorMessage, 'align-right': $ctrl.umFieldLabel}" ${derrivedAttrs} />
      <span ng-if="$ctrl.umUnit" class="mf-unit" ng-class="{'is-error': $ctrl.umErrorMessage}">{{$ctrl.umUnit}}</span>
      <span ng-if="$ctrl.umIcon" class="mf-icon" ng-class="{'is-error': $ctrl.umErrorMessage}"><span aria-hidden="true" class="{{$ctrl.umIcon}}"></span></span>
      <span ng-if="$ctrl.umClear" ng-click="$ctrl.clear()" class="mf-clear" ng-class="{'is-error': $ctrl.umErrorMessage}"></span>
    </div>
  `;
}

/**
 * input type="number" のHTMLを生成
 * @param {string} derrivedAttrs inputフィールドに差し込むアトリビュート文字列(id="xx")
 */
function getNumberTemplate(derrivedAttrs) {
  return `
    <div class="mf-field">
      <span ng-class="{'is-error': $ctrl.umErrorMessage}" ng-if="$ctrl.umFieldLabel" class="mf-unit">{{$ctrl.umFieldLabel}}</span>
      <input type="number" pattern="\\d*" ng-class="{'form-control': true, 'has-icon': $ctrl.umIcon, 'has-unit': $ctrl.umFieldLabel || $ctrl.umUnit, 'is-error': $ctrl.umErrorMessage, 'align-right': $ctrl.umFieldLabel }" ${derrivedAttrs} um-form-input-number-set-custom-validity />
      <span ng-if="$ctrl.umUnit" class="mf-unit" ng-class="{'is-error': $ctrl.umErrorMessage}">{{$ctrl.umUnit}}</span>
      <span ng-if="$ctrl.umIcon" class="mf-icon" ng-class="{'is-error': $ctrl.umErrorMessage}"><span aria-hidden="true" class="{{$ctrl.umIcon}}"></span></span>
    </div>
  `;
}

/**
 * selectのHTMLを生成
 * @param {string} derrivedAttrs inputフィールドに差し込むアトリビュート文字列(id="xx")
 */
function getSelectTemplate(derrivedAttrs) {
  return `
    <div class="mf-field mf-select">
      <span ng-if="$ctrl.umFieldLabel" class="mf-unit">{{$ctrl.umFieldLabel}}</span>
      <select class="form-control" ng-class="{'is-error': $ctrl.umErrorMessage}" ${derrivedAttrs} >
        <option ng-if="$ctrl.shouldAddNullOption()" value=""></option>
      </select>
      <span ng-if="$ctrl.umUnit" class="mf-unit">{{$ctrl.umUnit}}</span>
    </div>
  `;
}

/**
 * input type="radio"のHTMLを生成
 * @param {string} derrivedAttrs inputフィールドに差し込むアトリビュート文字列(id="xx")
 */
function getRadioTemplate(isGroupItem, derrivedAttrs) {
  return `
    <div ng-class="{'mf-radiogroup_item' : ${isGroupItem} || $ctrl.umIsChildren, 'mf-radio' : !(${isGroupItem} || $ctrl.umIsChildren)}">
      <input type="radio" class="hidden" ${derrivedAttrs} />
      <label for="{{$ctrl.umId}}" ng-class="{'mf-radiogroup_label': ${isGroupItem} || $ctrl.umIsChildren, 'mf-radio_label': !(${isGroupItem} || $ctrl.umIsChildren), }">{{$ctrl.umLabel}}</label>
    </div>`;
}

/**
 * textarea のHTMLを生成
 * @param {string} derrivedAttrs  inputフィールドに差し込むアトリビュート文字列(id="xx")
 */

function getTextareaTemplate(derrivedAttrs) {
  return `
    <div class="mf-field">
      <textarea class="form-control js-autosize" ${derrivedAttrs}></textarea>
    </div>`;
}

/**
 * input type="checkbox" のHTMLを生成
 * @param {bool} isGroupItem グループの子アイテムかどうか
 * @param {string} derrivedAttrs inputフィールドに差し込むアトリビュート文字列(id="xx")
 */
function getCheckboxTemplate(isGroupItem, derrivedAttrs) {
  return `
    <div ng-class="{'mf-checkboxgroup_item' : ${isGroupItem} || $ctrl.umIsChildren, 'mf-checkbox': !(${isGroupItem} || $ctrl.umIsChildren)}">
      <input type="checkbox" class="hidden" ng-checked="$ctrl.umChecked" ${derrivedAttrs} />
      <label for="{{$ctrl.umId}}" class="${isGroupItem ? 'mf-checkboxgroup_label' : 'mf-checkbox_label'}">{{$ctrl.umLabel}}</label>
    </div>`;
}

/**
 * 各アイテム共通のDIVでラップする
 * @param {string} children ラップするHTML
 */
function wrapWithCommonDiv(children) {
  return `
    <div class="{{$ctrl.WIDTH_MAP[$ctrl.umWidth]}}">
      <label class="mf-label" ng-if="$ctrl.umLabel" ng-class="{'is-error': $ctrl.umErrorMessage}">{{$ctrl.umLabel}}<span ng-if="$ctrl.umIsRequired" class="label label-danger mf-label_icon">必須</span></label>
      ${children}
      <span class="mf-group_help" ng-class="{'is-error': $ctrl.umErrorMessage}" ng-if="$ctrl.umErrorMessage">{{$ctrl.umErrorMessage}}</span>
      <span class="mf-group_help is-error">{{$ctrl.umNumberErrorMessage}}</span>
      <ng-container ng-messages="$ctrl.umErrorStatus">
        <span ng-repeat="(key, value) in $ctrl.umErrorMessages">
          <span ng-message="{{key}}">{{value}}</span>
        </span>
      </ng-container>
      <ng-container ng-transclude></ng-container>
    </div>
    `;
}

/**
 * 単独アイテムの場合
 * @param {boolean} isChildren サブグループ下のアイテムかどうか
 * @param {string} childHTML ラップするHTML
 */
function wrapWithMfGroup(isChildren, childHTML) {
  return `
    <um-form-group um-layout="single" um-has-error="$ctrl.umErrorMessage || $ctrl.hasError()" um-is-children="${isChildren}">
      ${childHTML}
    </um-form-group>
  `;
}

function getValidityNumberTemplate(derrivedAttrs) {
  return `
    <div class="mf-field">
      <span ng-if="$ctrl.umFieldLabel" class="mf-unit">{{$ctrl.umFieldLabel}}</span>
      <input type="number" ng-class="{'form-control': true, 'has-icon': $ctrl.umIcon, 'has-unit': $ctrl.umFieldLabel || $ctrl.umUnit, 'align-right': $ctrl.umFieldLabel }" ${derrivedAttrs} />
      <span ng-if="$ctrl.umUnit" class="mf-unit">{{$ctrl.umUnit}}</span>
      <span ng-if="$ctrl.umIcon" class="mf-icon"><span aria-hidden="true" class="{{$ctrl.umIcon}}"></span></span>
    </div>
  `;
}

function wrapValidityNumberWithDiv(children) {
  return `
    <div class="{{$ctrl.WIDTH_MAP[$ctrl.umWidth]}} mf-validity-number">
      <label class="mf-label" ng-if="$ctrl.umLabel">{{$ctrl.umLabel}}<span ng-if="$ctrl.umIsRequired" class="label label-danger mf-label_icon">必須</span></label>
      ${children}
      <span class="mf-group_help">{{$ctrl.numberErrorMessage}}</span>
    </div>
    `;
}

function wrapValidityNumberWithMfGroup(isChildren, childHTML) {
  return `
    <div ng-class="$ctrl.umValidityNumberGroupClass">
      <div class="row">
        ${childHTML}
      </div>
    </div>
  `;
}

/**
 * input/select/textareaタグに差し込むアトリビュート文字列を生成する
 * @param {object} attrs アトリビュートのリスト
 */
function getDerivedAttrsStr(attrs) {
  const attrsToDerive = {
    id: attrs.umId ? '{{$ctrl.umId}}' : null,
    name: attrs.umName ? '{{$ctrl.umName}}' : null,
    value: attrs.umValue ? '{{$ctrl.umValue}}' : null,
    rows: attrs.umRows ? '{{$ctrl.umRows}}' : null,
    maxlength: attrs.umMaxLength ? '{{$ctrl.umMaxLength}}' : null,
    placeholder: attrs.umPlaceholder ? '{{$ctrl.umPlaceholder}}' : null,
    step: attrs.umStep ? '{{$ctrl.umStep}}' : null,
    max: attrs.umMax ? '{{$ctrl.umMax}}' : null,
    inputmode: attrs.umInputmode ? '{{$ctrl.umInputmode}}' : null,
    'format-check': attrs.umFormatCheck ? '{{$ctrl.umFormatCheck}}' : null,
    'ng-model': attrs.umModel ? '$ctrl.umModel' : null,
    'ng-change': attrs.umChange ? '$ctrl.umChange()' : null,
    'ng-blur': attrs.umBlur ? '$ctrl.umBlur()' : null,
    'ng-click': attrs.umClick ? '$ctrl.umClick()' : null,
    'ng-check': attrs.umCheck ? '$ctrl.umCheck()' : null,
    'ng-selected': attrs.umCheck ? '$ctrl.umSelected()' : null,
    'ng-init': attrs.umInit ? '$ctrl.umInit()' : null,
    'ng-disabled': attrs.umDisabled ? '$ctrl.umDisabled()' : null,
  };
  if (attrs.umOptions) {
    const umOptionsSelect = attrs.umOptionsSelect || 'option';
    const umOptionsAs = attrs.umOptionsAs ? `as ${attrs.umOptionsAs}` : 'as option';
    const umOptionsFor = attrs.umOptionsFor ? `for ${attrs.umOptionsFor}` : 'for option';
    const umOptionsTrackBy = attrs.umOptionsTrackBy ? 'track by ' + attrs.umOptionsTrackBy : '';

    attrsToDerive['ng-options'] = `${umOptionsSelect} ${umOptionsAs} ${umOptionsFor} in $ctrl.umOptions ${umOptionsTrackBy}`;
  }

  if (attrs.umType === 'validity-number') {
    attrsToDerive['ng-value'] = '{{$ctrl.umModel}}';
  }

  let derivedAttrsStr = Object.keys(attrsToDerive).map((key) => attrsToDerive[key] ? `${key}="${attrsToDerive[key]}"` : '').join(' ');
  if ('umIsDisabled' in attrs) derivedAttrsStr += ' disabled';
  if ('umIsDate' in attrs) derivedAttrsStr += ' jqdatepicker';

  return derivedAttrsStr;
}

class UmFormInputController {
  constructor($scope, $timeout, $element) {
    'ngInject';
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$element = $element;
  }

  $onInit() {
    if (this.umType === 'validity-number') {
      this.initValidityNumber();
    }

    if (this.umIsDate) {
      this.umClear = true;
    }

    /**
     * 子要素で実行されるng-changeなどのexpressionが古いコンテキストを参照することを回避
     * するため、$timeoutで更新を待ってから実行する
     */
    this.savedUmChange = this.umChange;
    this.umChange = () => {
      this.$timeout(() => {
        this.savedUmChange();
      });
    };

    this.savedUmClick = this.umClick;
    this.umClick = () => {
      this.$timeout(() => this.savedUmClick());
    };

    this.savedUmBlur = this.umBlur;
    this.umBlur = () => {
      this.$timeout(() => this.savedUmBlur());
    };

    this.savedUmCheck = this.umCheck;
    this.umCheck = () => {
      this.$timeout(() => this.savedUmCheck());
    };

    this.savedUmSelected = this.umSelected;
    this.umSelected = () => {
      this.$timeout(() => this.savedUmSelected());
    };

    this.savedUmInit = this.umInit;
    this.umInit = () => {
      this.$timeout(() => this.savedUmInit());
    };

    this.hasNullOption = this.umOptions && this.umOptions.length > 0
      ? this.umOptions.some((option) => option.value === '')
      : false;
  }

  shouldAddNullOption() {
    return this.umAddNullOption && !this.hasNullOption;
  }

  /*
    バリデーションエラーが発生しているかどうか
  */
  hasError() {
    return this.umErrorStatus ? Object.keys(this.umErrorStatus).length > 0 : false;
  }

  /**
   * um-widthをCSSクラスに変換するためのマップ
   */
  get WIDTH_MAP() {
    return {
      '100': 'col-xs-12',
      '75': 'col-xs-9',
      '50': 'col-xs-6'
    };
  }

  initValidityNumber() {
    const $container = this.$element;
    const $input = angular.element('input', this.$element);
    const $help = angular.element('.mf-group_help', this.$element);

    if (this.umErrorMessage) {
      this.numberErrorMessage = this.umErrorMessage;
    } else {
      let message = `半角数値で入力して下さい`;
      if (this.umStep) {
        const result = /\d\.(\d+)$/.exec(this.umStep);
        if (result) {
          const step = result[1].length;
          message = `半角数値(小数の場合は小数点第${step}位まで)で入力して下さい`;
        }
      }
      if (this.umMax) {
        message = this.umMax + '以下の' + message;
      }
      if (this.umLabel) {
        message = this.umLabel + 'は' + message;
      }
      this.numberErrorMessage = message;
    }

    $help.hide();
    $container.removeClass('is-error');
    angular.element('.mf-group, .mf-subgroup', this.$element).removeClass('is-error');

    $input.on('input', () => {
      if ($input[0].validity.valid) {
        $help.hide();
        $container.removeClass('is-error');
        angular.element('.mf-group, .mf-subgroup', this.$element).removeClass('is-error');
      } else {
        $help.show();
        $container.addClass('is-error');
        angular.element('.mf-group, .mf-subgroup', this.$element).addClass('is-error');
      }
    });
  }

  clear() {
    this.umModel = null;
    this.umChange();
  }
}

app.controller('UmFormInputController', UmFormInputController);
