class ItemFilterAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/item_filter');
  }

  create(useItemFilter, exemptedAccountIds) {
    const params = {
      useItemFilter: useItemFilter,
      exemptedAccountIds: exemptedAccountIds,
    };
    return this.APIService.post('/item_filter', params);
  }
}

app.service('ItemFilterAPI', ItemFilterAPI);
