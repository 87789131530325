class MobileCowNoModalController {
  constructor(
    $modalInstance,
    $scope,
    $modal,
    $timeout,
    $window,
    titleLabel,
    submitLabel
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.$modal = $modal;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$window = $window;
    this.titleLabel = titleLabel;
    this.submitLabel = submitLabel;

    this.cowNo = null;

    $window.onresize = this.calculateMainAreaHeight.bind(this);
    this.$scope.$on('$destroy', () => {
      $window.onresize = null;
    });
  }

  onCancelClick() {
    this.$modalInstance.close({
      status: {
        CANCEL: true
      }
    });
  }

  onSubmitClick() {
    this.$modalInstance.close({
      status: {
        SUBMIT: true
      },
      cowNo: this.cowNo
    });
  }

  closeKeyboard() {
    document.activeElement.blur();
  }

  calculateMainAreaHeight() {
    // ウィンドウの高さを変更中に呼ばれると高さが不正になるためブロックする
    if (this.applying) {
      return;
    }
    this.applying = true;

    this.$timeout(() => {
      const windowHeight = $(window).innerHeight();
      const headerHeight = $('header.layout-header .container').innerHeight();
      this.$scope.$apply(() => {
        this.mainAreaHeight = windowHeight - headerHeight;
        this.applying = false;
      });
    });
  }
}

app.controller('MobileCowNoModalController', MobileCowNoModalController);
