/**
 * @deprecated app/utility以下に実装を移行してください
 * ※この実装の修正および、新規の利用は禁止です
 */
class UtilService {
  constructor($window) {
    'ngInject';
    this.$window = $window;
  }

  /**
   * 値をブーリアンに変換する。
   *
   * @param {string|number} value 値
   * @param {stirng} trueValue true時の値
   * @param {string} falseValue false時の値
   * @return {boolean} true or false
   */
  toBoolean(value, trueValue = 'true', falseValue = 'false') {
    if (typeof value === 'number') value = '' + value;
    if (typeof value !== 'string') return !!value;
    if (value === trueValue || value === '1') return true;
    if (value === falseValue || value === '0') return false;
  }

  /**
   * 数字かどうか確認する
   * @param {number} n 数字かストリング
   * @return {bool}
   */
  isNumber(n) {
    return !Number.isNaN(parseFloat(n)) && isFinite(n);
  }

  /**
   * XOR
   *
   * @param {mixed} v1
   * @param {mixed} v2
   */
  xor(v1, v2) {
    return (v1 || v2) && !(v1 && v2);
  }

  /**
   * 経過日数を両端から計算する
   *  1日〜5日 = 5
   * @param {Date} from 発生日を
   * @param {Date} to 現時刻、大抵は new Date()
   * @return {number} 経過日数
   */
  diffDays(from, to) {
    if (!from || !to) {
      return 0;
    }

    const fromDate = moment(from.getTime());
    const toDate = moment(to.getTime());
    return toDate.diff(fromDate, 'days');
  }

  /**
   * 番号付き選択リスト生成
   * 例: [{ label: '1:選択オプション', value: 選択オプション }]
   * @param {string} listName
   * @param {Object} info FarmService.show()のレスポンス
   * @param {bool} allowBlank
   * @return {Array}
   */
  getCustomSelectNumberList(listName, info, allowBlank) {
    if (!info[listName]) return [];

    let blank = {label: '', value: ''};
    let result = allowBlank ? [blank] : [];
    info = info || {};
    let num = 1;
    let array = _.lines(info[listName]);
    _.each(array, (val) => {
      result.push({label: num + '：' + val, value: val});
      num++;
    });
    return result;
  }

  /**
   * CSVファイルを生成してダウンロードする
   * @param {Array.<Array.<string>>} csv
   * @param {string} fileName
   */
  csvExport(csv, fileName) {
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]); // Excelで扱えるようにBOMをつける
    const blob = new Blob([bom, csv], {type: 'text/csv'});
    let url = null;
    if (this.$window.navigator.msSaveBlob) {
      url = this.$window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      url = this.$window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }

  /**
   * 0より大きい数値でない場合はnullに変換する
   * @param {Number} number
   * @returns {Number} 0より大きい数値はそのまま、0以下の場合はnull
  */
  nullIfZeroOrUnder(number) {
    return number > 0 ? number : null;
  }

  /**
   * ブランク(null, undefined, '')の場合に置換した値を返す
   * ブランク以外の場合は値をそのまま返す
   *
   * @param {mixed} value 値
   * @param {mixed} replacementValue 置換値(デフォルト'-')
   */
  replaceBlank(value, replacementValue = '-') {
    const blankValues = [null, undefined, ''];
    if (blankValues.includes(value)) {
      return replacementValue;
    }
    return value;
  }

  /**
   * APIのエラーメッセージを改行整形して返します。
   *
   * @param {Array.<Object>} messages APIのエラーメッセージ
   * @return {string} 改行整形されたエラーメッセージ
   *
   * ex.
   * [{field: 'name', message: '既に使用されている名称です。'},
   *  {field: 'startDate', message: '履歴が重複しています。'}]
   * ⇒ '既に使用されている名称です。\n履歴が重複しています。'
  */
  formatErrorMessage(messages) {
    return messages.map((m) => m.message).join('\n');
  }

  /**
   * APIの一括用のエラーメッセージを改行整形して返します。
   *
   * @param {Array.<Object>} messages APIのエラーメッセージ
   * @return {string} 改行整形されたエラーメッセージ
   *
   * ex.
   * [{field: 'name', lineNo: 1, message: '既に使用されている名称です。'},
   *  {field: 'startDate', lineNo: 1, message: '履歴が重複しています。'},
   *  {field: 'startDate', lineNo:2, message: '履歴が重複しています。'}]
   * ⇒ '1行目:既に使用されている名称です。履歴が重複しています。\n2行目:履歴が重複しています。'
  */
  formatMultilineErrorMessage(messages) {
    const errors = {};

    messages.forEach((m) => {
      errors[m.lineNo] = errors[m.lineNo] || '';
      errors[m.lineNo] += m.message;
    });

    return Object.keys(errors).sort((a, b) => a - b).map((lineNo) => {
      return `${lineNo}行目： ${errors[lineNo]}`;
    }).join('\n');
  }

  /**
   * 文字列を改行(\r\n or \r or \n)で分割し配列で返す。
   *
   * @param {string} str 対象文字列
   * @return {Array} 配列
   */
  lines(str) {
    return str !== null ? String(str).split(/\r\n?|\n/) : [];
  }

  /**
   * 小数点以下も含め任意の桁以下を四捨五入する。
   * e.g. round(125.636, 2) -> 100
   * e.g. round(125.636, 1) -> 130
   * e.g. round(125.636, 0) -> 126
   * e.g. round(125.636, -1) -> 125.6
   * e.g. round(125.636, -2) -> 125.64
   *
   *
   * @param {Number} num 対象の数字
   * @param {Number} precision この数字以下の桁を四捨五入する
   * e.g. 2 -> 二桁目以下を四捨五入
   * e.g. 0 -> 小数点第一位以下を四捨五入
   * e.g. -2 -> 小数点第三位以下を四捨五入
   * @return {Number} 四捨五入後の数字
   */
  round(num, precision) {
    const shift = function(num, precision, reverseShift) {
      if (reverseShift) {
        precision = -precision;
      }
      const numArr = ('' + num).split('e');
      return +(numArr[0] + 'e' + (numArr[1] ? (+numArr[1] + precision) : precision));
    };
    return shift(Math.round(shift(num, precision, true)), precision, false);
  }
}

app.service('UtilService', UtilService);
