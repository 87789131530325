class DepositAlertService {
  constructor(
    APIService,
    DateUtilService) {
    'ngInject';
    this.APIService = APIService;
    this.DateUtilService = DateUtilService;

    this.alertsByCow = this.alertsByCow.bind(this);
  }

  /**
   * 牛ごとのアラート履歴を取得
   * @param {string} cowId 牛番号
   */
  alertsByCow(cowId) {
    return this.APIService.get('/deposit/alert/cow/' + cowId);
  }

  /**
   * 全てのアラート履歴を取得
   * @param {string} alertType - アラートの種類。Alerts.ALERT_TYPES
   * @param {Date} startDate - 開始日
   * @param {Date} endDate - 終了日
   * @param {Object} options
   *   {number} cowNo: 牛番号を指定
   *   {number} cowGroupId: 牛群IDを指定
   *   {bool} inactive: 出荷・へい死を含む
   */
  alerts(alertType, startDate, endDate, options) {
    const queryParams = {
      alert_type: alertType,
      start_date: this.DateUtilService.toYYYYMMDD(startDate, '-'),
      end_date: this.DateUtilService.toYYYYMMDD(endDate, '-'),
      cow_no: 'cowNo' in options ? options.cowNo : null,
      cow_group_id: 'cowGroupId' in options ? options.cowGroupId : null,
      inactive: 'inactive' in options ? options.inactive : null
    };

    const query = Object.keys(queryParams)
      .filter((key) => queryParams[key] !== null)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');

    return this.APIService.get('/deposit/alert/cow/search?' + query);
  }
}

app.service('DepositAlertService', DepositAlertService);
