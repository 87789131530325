class GroupEditController {
  constructor(
    $modalInstance,
    CowGroupService,
    FarmService,
    CowshedService,
    cowGroupId,
    UtilService,
    blockUI
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.CowGroupService = CowGroupService;
    this.UtilService = UtilService;
    this.blockUI = blockUI;

    this.cowGroupId = cowGroupId;
    this.disableMenuDisplayed = false;

    this.toBoolean = this.UtilService.toBoolean;
    this.invalid = false;
    this.errorMessages = [];

    this.init(FarmService, CowshedService);
  }

  init(FarmService, CowshedService) {
    FarmService.show().then((res) => {
      const farm = new Farm(res);
      this.attributes = this.CowGroupService.getAttributes(farm.farmType(), farm.isDepositor());
    });

    CowshedService.index().then((res) => this.cowSheds = res.data);

    this.cowGroup = {
      groupAttribute: '',
      groupAttributeName: '',
      menuDisplayed: true,
    };

    this.pens = [];
    this.inputPens = '';

    if (this.cowGroupId) {
      this.disableAttribute = false;

      this.CowGroupService.get(this.cowGroupId).then((res) => {
        const cowGroup = res.data;
        if (cowGroup.pens) {
          this.pens = cowGroup.pens.split(',');
          this.inputPens = this.pens.join('\n');
        }

        this.cowGroup = cowGroup;

        // 牛軍属性がDefaultの場合は牛群属性を変更できないようにする
        this.disableAttribute = cowGroup.cullingDefaultGroup || cowGroup.calfDefaultGroup || false;

        // 預託牛群はシステム制御用なのでメニューには表示しない
        this.disableMenuDisplayed = this.cowGroup.depositEntryGroup === true;

        const attribute = this.CowGroupService.getAttribute(cowGroup);
        this.cowGroup.groupAttribute = attribute.key;
        this.cowGroup.groupAttributeName = attribute.label;
      });
    }
  }

  static inputToModel(input, groupAttributes) {
    const params = {
      cowGroupName: input.cowGroupName,
      cowshedId: input.cowshedId,
      menuDisplayed: input.menuDisplayed,
      pens: input.pens
    };

    if (input.cowGroupId) {
      params['cowGroupId'] = input.cowGroupId;
    }

    groupAttributes.forEach((a) => {
      params[a.key] = input[a.key] || false;
    });

    return params;
  }

  entry() {
    this.formatPens();
    this.cowGroup.pens = this.pens.join(',');

    const params = GroupEditController.inputToModel(this.cowGroup, this.CowGroupService.allAttributes);

    this.validate(params);
    if (this.invalid) {
      this.message = this.errorMessages.join('\n');
      return;
    }

    this.blockUI.start('登録中');

    this.CowGroupService.insert(params)
      .then((result) => {
        this.blockUI.stop();
        this.$modalInstance.close();
      }).catch((reject) => {
        this.blockUI.stop();
        this.message = ErrorUtil.formatErrorMessage(reject.data.messages);
      });
  }

  edit() {
    this.formatPens();
    this.cowGroup.pens = this.pens.join(',');

    const params = GroupEditController.inputToModel(this.cowGroup, this.CowGroupService.allAttributes);

    this.validate(params);
    if (this.invalid) {
      this.message = this.errorMessages.join('\n');
      return;
    }

    this.blockUI.start('更新中');

    this.CowGroupService.update(params)
      .then((result) => {
        this.blockUI.stop();
        this.$modalInstance.close();
      }).catch((reject) => {
        this.blockUI.stop();
        this.message = ErrorUtil.formatErrorMessage(reject.data.messages);
      });
  }

  delete() {
    this.blockUI.start('削除中');
    this.CowGroupService.delete(this.cowGroupId).then((result) => {
      this.blockUI.stop();
      this.$modalInstance.close();
    }, (reject) => {
      this.blockUI.stop();
      this.message = ErrorUtil.formatErrorMessage(reject.data.messages);
    });
  }

  updateAttritbute() {
    this.CowGroupService.allAttributes.forEach((attr) => {
      this.cowGroup[attr.key] = false;
    });

    this.cowGroup[this.cowGroup.groupAttribute] = true;
    if (this.cowGroup.depositEntryGroup) {
      this.disableMenuDisplayed = true;
      this.cowGroup.menuDisplayed = false;
    } else {
      this.disableMenuDisplayed = false;
    }
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  validate(params) {
    this.errorMessages = [];

    if (params.pens.length > 1024) {
      this.errorMessages.push('牛房が入力可能な上限を超えています');
    }

    const pens = params.pens.split(',');
    if (pens.some((pen) => pen.length > 20)) {
      this.errorMessages.push('牛房は20文字以下で入力してください');
    }

    const duplicated = new Set();
    pens.forEach((pen, index) => {
      const isDuplicated = pens.some((other, otherIndex) => {
        if (other === pen && otherIndex !== index) {
          return true;
        }
        return false;
      });

      if (isDuplicated) {
        duplicated.add(pen);
      }
    });
    if (duplicated.size >= 1) {
      duplicated.forEach((pen) => {
        this.errorMessages.push(`牛房が重複して入力されています: ${pen}`);
      });
    }

    this.invalid = this.errorMessages.length >= 1;
  }

  onChangePens() {
    this.formatPens();
  }

  formatPens() {
    const trimedPens = this.inputPens.split('\n').map((pen) => {
      return pen.trim();
    });
    this.inputPens = trimedPens.join('\n');

    this.pens = trimedPens.filter((pen) => pen !== '');
  }
}

app.controller('GroupEditController', GroupEditController);
