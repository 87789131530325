app.factory('SelectBoxFactory', () => {
  'ngInject';
  return {
    /**
     * セレクトボックスのオプションが設定されていない場合は
     * デフォルト値を設定する。
     * デフォルト値を設定していない場合は、空白をデフォルト値として設定する。
     *
     * @param {Object} obj オブジェクト
     * @param {string} key オブジェクトのキー
     * @param {string} value デフォルト値
     */
    defaultOption: (obj, key, value) => obj[key] = obj[key] || (value || ''),
  };
});
