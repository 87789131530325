class ListMultiActionController {
  constructor(
    $modal,
    $state,
    SessionCache
  ) {
    'ngInject';
    this.$modal = $modal;
    this.$state = $state;

    this.cows = [];
    this.showActionButton = false;
    this.showGroupTransferButton = SessionCache.farm().isGroupOrganizer();
  }

  $onInit() {
    this.componentFuncs['onClickCow'] = this.onClickCow;
    this.componentFuncs['onClickAllCows'] = this.onClickAllCows;
    this.componentFuncs['showActionButton'] = this.showActionButton;
  }

  /**
   * この関数内でのthisはthis.componentFuncsになる点に注意
   */
  onClickCow($event, cows) {
    // thisの扱いの都合で関数内に定義しています
    const validate = (cows) => {
      const farmIds = new Set();
      cows.forEach((c) => {
        if (c.qwert) {
          farmIds.add(c.qwert);
        }
      });
      if (farmIds.size >= 2) {
        return {invalid: true, errorMessage: '複数の牧場が選択されています'};
      }

      return {invalid: false, errorMessage: ''};
    };

    if ($event) $event.stopPropagation();
    this.cows = cows.filter((cow) => cow.selected);
    this.showActionButton = this.cows.length > 0;
    this.showLabelRemoveButton = this.cows.some((cow) => {
      const cowLabels = cow.cowLabels || [];
      return cowLabels.length > 0;
    });

    const result = validate(this.cows);
    this.listActionInvalid = result.invalid;
    this.listActionErrorMessage = result.errorMessage;
  }

  /**
   * この関数内でのthisはthis.componentFuncsになる点に注意
   */
  onClickAllCows(cows, selected) {
    cows.forEach((cow) => cow.selected = selected);
    this.onClickCow(null, cows);
  }

  selectedCount() {
    if (this.componentFuncs.cows) {
      return `${this.componentFuncs.cows.length}頭を選択`;
    } else {
      return '';
    }
  }

  onClickBulkEvent() {
    const cowIds = this.componentFuncs.cows.map((cow) => cow.cowId);
    const qwert = this.componentFuncs.cows[0].qwert;

    this.$modal.open({
      templateUrl: 'components/event-registration/event-selection.html',
      controller: 'EventSelectionController',
      controllerAs: 'ctrl',
      size: '1012',
      resolve: {
        cowIds: () => cowIds,
        cowType: () => null,
        isDepositor: () => this.isDepositor,
        qwert: () => qwert,
        caller: () => this.callbackParam
      },
    });
  }

  onClickLabelAdd() {
    this.updateLabel('add');
  }

  onClickLabelRemove() {
    this.updateLabel('remove');
  }

  onClickGroupTransfer() {
    const cows = this.componentFuncs.cows.map((cow) => {
      return {
        cowId: cow.cowId,
        cowNo: cow.origin.cow_no,
        cowUid: cow.origin.cow_uid
      };
    });
    const fromId = this.componentFuncs.cows[0].qwert;
    const caller = this.callbackParam;
    const params = {
      cows,
      fromId,
      caller
    };

    this.$state.go('group-transfer-input', params);
  }

  updateLabel(actionType) {
    const selectedCows = this.cows.filter((c) => c.selected);
    const cows = selectedCows.map((c) => {
      return {
        cowId: c.cowId,
        cowNo: c.cowNo,
        cowLabels: c.cowLabels
      };
    });

    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'dialog/label/confirm.html',
      controller: 'LabelConfirmDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'select-standard',
      resolve: {
        params: () => {
          return {
            actionType: actionType,
            cows: cows,
            isDepositor: this.isDepositor
          };
        }
      }
    });

    modalInstance.result.then((labels) => {
      if (actionType === 'add') {
        Cow.addLabels(selectedCows, labels);
      } else {
        Cow.removeLabels(selectedCows, labels);
      }

      this.componentFuncs.onClickAllCows(this.cows, false);
      if (this.callerFuncs.refreshGrid) {
        this.callerFuncs.refreshGrid();
      }
    });
  }
}

function listMultiActionComponent() {
  'ngInject';
  return {
    templateUrl: 'components/list-action/index.html',
    controller: ListMultiActionController,
    controllerAs: 'listAction',
    bindings: {
      cows: '=',
      componentFuncs: '=',
      needLabelAction: '=',
      isDepositor: '=',
      callbackParam: '=',
      callerFuncs: '<'
    }
  };
}

app.component('listMultiAction', listMultiActionComponent());
