// eslint-disable-next-line no-unused-vars
class DateUtil {
  static get hours() {
    return ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  }

  static get minutes() {
    return ['00', '10', '20', '30', '40', '50'];
  }

  static japaneseEra() {
    return {
      M: {time: (new Date(1868, 0, 25)).getTime(), era: {g: 'M', gg: '明', ggg: '明治'}},
      T: {time: (new Date(1912, 6, 30)).getTime(), era: {g: 'T', gg: '大', ggg: '大正'}},
      S: {time: (new Date(1926, 11, 25)).getTime(), era: {g: 'S', gg: '昭', ggg: '昭和'}},
      H: {time: (new Date(1989, 0, 8)).getTime(), era: {g: 'H', gg: '平', ggg: '平成'}},
      R: {time: (new Date(2019, 4, 1)).getTime(), era: {g: 'R', gg: '令', ggg: '令和'}}
    };
  }

  static getJapaneseYear(date, eraOption = 'ggg') {
    const eras = DateUtil.japaneseEra();
    const numValue = date.getTime();
    const yyyy = date.getFullYear();
    if (numValue < eras.M.time) return '？？' + yyyy;
    if (numValue < eras.T.time) return eras.M.era[eraOption] + (yyyy - 1868);
    if (numValue < eras.S.time) return eras.T.era[eraOption] + (yyyy - 1912);
    if (numValue < eras.H.time) return eras.S.era[eraOption] + (yyyy - 1925);
    if (numValue < eras.R.time) return eras.H.era[eraOption] + (yyyy - 1988);

    return eras.R.era[eraOption] + (yyyy - 2018);
  }

  /**
   * YYYY/MM/DDにフォーマットする
   *
   * @param {mixed} value 元値
   * @return {String} YYYY/MM/DD
   */
  static toYYYYMMDD(value, separator = '/') {
    if (!value) return '';
    return moment(value).format(`YYYY${separator}MM${separator}DD`);
  }

  /**
   * YYYY年MM月DD日にフォーマットする
   *
   * @param {mixed} value 元値
   * @return {String} YYYY年MM月DD日
   */
  static toJapaneseYYYYMMDD(value) {
    if (!value) return '';
    return moment(value).format(`YYYY年MM月DD日`);
  }

  /**
   * YYYY年MM月DD日 hh:mmにフォーマットする
   *
   * @param {mixed} value 元値
   * @return {String} YYYY年MM月DD日 hh:mm
   */
  static toJapaneseYYYYMMDDHHmm(value) {
    if (!value) return '';
    return moment(value).format(`YYYY年MM月DD日 HH:mm`);
  }

  /**
  * APIパラメータ用にYYYYMMDDhhにフォーマットする
  *
  * @param {Date|string|number} value フォーマットする日付
  * @return {String} YYYY/MM/DD
  */
  static toDateHour(value) {
    if (!value) return '';
    return moment(value).format(`YYYYMMDDHH`);
  }

  /**
   * YYYYMMDDHHをDateに変換する
   */
  static dateHourToDate(dateHour) {
    return moment(String(dateHour).substr(0, 8))
      .add(Number(String(dateHour).substr(8, 2)), 'h')
      .toDate();
  }

  /**
   * YYYYMMDDHHMMをDateに変換する
   */
  static dateMinuteToDate(dateMinute) {
    return moment(String(dateMinute).substr(0, 8))
      .add(Number(String(dateMinute).substr(8, 2)), 'h')
      .add(Number(String(dateMinute).substr(10, 2)), 'm')
      .toDate();
  }

  /**
   * YY/MM/DDにフォーマットする
   *
   * @param {mixed} value 元値
   * @return {String} YY/MM/DD
   */
  static toYYMMDD(value) {
    if (!value) return '';
    return moment(value).format('YY/MM/DD');
  }

  /**
   * YYYY/MMにフォーマットする
   *
   * @param {mixed} date Date()
   * @param {string} separator セパレーター文字列
   * @return {String} YYYYMM
   */
  static toYYYYMM(date, separator = '') {
    if (!date) return '';

    return moment(date).format(`YYYY${separator}MM`);
  }

  /**
   * MM/DDにフォーマットする
   *
   * @param {mixed} value 元値
   * @return {String} MM/DD
   */
  static toMMDD(value, separator = '/') {
    if (!value) return '';
    return moment(value).format(`MM${separator}DD`);
  }

  /**
   * MM/DD HH:mmにフォーマットする
   * @param {Date} value
   * @return {string}
   */
  static toMMDDHHmm(value) {
    if (!value) return '';
    return moment(value).format('MM/DD HH:mm');
  }

  /**
   * HH:mmにフォーマットする
   * @param {Date} value
   * @return {string}
   */
  static toHHmm(value) {
    if (!value) return '';
    return moment(value).format('HH:mm');
  }

  /**
   * YYYY/MM/DD HH:mmにフォーマットする
   * @param {Date} value 日付と時刻
   * @param {string} separator セパレータの文字列
   * @return {string}
   */
  static toYYYYMMDDHHmm(value, separator = '/') {
    if (!value) return '';
    return moment(value).format(`YYYY${separator}MM${separator}DD HH:mm`);
  }

  /**
   * YYYYMMDDHHmmにフォーマットする
  * @param {Date} value
  * @return {string}
  */
  static toYYYYMMDDHHmmParam(value) {
    if (!value) return '';
    return moment(value).format('YYYYMMDDHHmm');
  }

  /**
   * DateオブジェクトをW3Cの「YYYY-MM-DD」形式の文字列に変換します
   * @param {Date} date
   * @return {string}
   */
  static toW3CFormat(date) {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD');
  }

  /**
   * Dateオブジェクトを「YYYY/MM/DD HH:MM」形式の文字列に変換します
   * @param {Date} date
   * @return {string}
   */
  static toTimestampFormat(date) {
    if (!date) return '';
    return moment(date).format('YYYY/MM/DD HH:mm');
  }

  /**
   * 指定の書式でフォーマットされた日付文字列を返します。
   * @param {Date|string|number} dateValue 日付を表す値
   * @param {Object.<string, string>}
   * @return {string} 指定の書式でフォーマットされた日付文字列
   *
   * ex1. date: 2017/2/3, option: { year: 'ggg', month: 'mm', day: 'dd' }
   * return: 平成29/02/03
   *
   * ex2. date: 2017/2/3, option: { }
   * return: 2017/2/3
   *
   * ex3. date: 2017/2/3, option: { separator_style: 'jp' }
   * return: 2017年2月3日
   *
   * ex4. date: 2017/2/3, option: { year: 'g', month: 'mm', day: 'dd' }
   * return: H29/02/03
   *
   * ex5. date: 2017/2/3, option: { year: 'none', month: 'mm', day: 'dd' }
   * return: 02/03
   */
  static formatDate(dateValue, option = {}) {
    const toDate = (dateValue) => {
      if (!dateValue) return null;

      try {
        if (typeof dateValue === 'number') {
          return new Date(dateValue);
        }
        if (typeof dateValue === 'string') {
          return new Date(Number(dateValue));
        }
        return dateValue;
      } catch (e) {
        console.error(e);
        console.error('[ERROR] illegal argument: ' + dateValue);
        return null;
      }
    };

    const date = toDate(dateValue);
    if (!date) return '';

    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();

    const isEra = (year) => year === 'ggg' || year === 'gg' || year === 'g';

    let year = isEra(option.year) ? this.getJapaneseYear(date, option.year) : String(y);
    if (option.year === 'short' || option.year === 'yy') {
      year = year.substr(-2);
    }
    const month = (option.month === 'mm' && m < 10) ? '0' + m : String(m);
    const day = (option.day === 'dd' && d < 10) ? '0' + d : String(d);

    let separatorY = '/';
    let separatorM = '/';
    let separatorD = '';
    if (option.separator_style === 'jp') {
      separatorY = '年';
      separatorM = '月';
      separatorD = '日';
    }

    if (option.year === 'none') {
      year = '';
      separatorY = '';
    }

    return year + separatorY + month + separatorM + day + separatorD;
  }

  /**
   * 引数をUnixtime(秒)に変換する
   *
   * @param {mixed} value 変換元値
   * @return {number} unixtime
   */
  static toUnixtime(value) {
    return moment(value).unix();
  }

  /**
   * 引数をミリ秒に変換する
   *
   * @params {mixed} value 変換元値
   * @return {number} unixtime
   */
  static toMSec(value) {
    return moment(value).valueOf();
  }

  /**
   * 引数をDate型に変換する
   *
   * @param {mixed} value 変換元値
   * @return {number} unixtime
   */
  static toDate(value) {
    if (!value) return null;
    return moment(value).toDate();
  }

  /**
   * ある期間の日数を開始日を起点として計算します。
   * @param {number} from 開始日Unixtime)
   * @param {number} to 終了日(Unixtime)
   * @return {number} 対象期間の日数
   *
   * ex1. from: 2017/5/1, to: 2017/5/2
   * return: 2
   *
   * ex2. from: 2017/5/1, to: 2017/5/1
   * return: 1
   */
  static countDays(from, to) {
    if (!from || !to) {
      return 0;
    }
    const fromDate = moment(from);
    const toDate = moment(to);
    return toDate.diff(fromDate, 'days') + 1;
  }

  /**
   * ある期間の分数を開始日時起点で計算します。
   * @param {number} from 開始日時(Unixtime)
   * @param {number} to 終了日時(Unixtime)
   * @return {number} 指定期間の分数
   *
   * ex1. from: 2017/12/20 0:00, to: 2017/12/20 1:01
   * return: 61
   *
   * ex2. from: 2017/12/20 0:00, to: 2017/12/20 0:00
   * return: 0
   */
  static diffMinutes(from, to) {
    if (!from || !to) {
      return 0;
    }
    const fromTime = moment(from);
    const toTime = moment(to);
    return toTime.diff(fromTime, 'minutes');
  }

  /**
   * ある期間の日数を開始日の翌日を起点として計算します。
   * @param {number} from 開始日Unixtime)
   * @param {number} to 終了日(Unixtime)
   * @return {number} 開始日を含まない対象期間の日数
   *
   * ex1. from: 2017/5/1, to: 2017/5/2
   * return: 1
   *
   * ex2. from: 2017/5/1, to: 2017/5/1
   * return: 0
   */
  static diffDays(from, to) {
    if (!from || !to) {
      return 0;
    }
    const fromDate = moment(from);
    const toDate = moment(to);
    return toDate.diff(fromDate, 'days');
  }

  /**
   * ある期間の月数を開始日を起点として計算します。
   * @param {Date} from 対象期間の開始日
   * @param {Date} to 対象期間の終了日
   * @param {number} scale 小数点以下の有効桁数(省略可能)
   * @return {number} 月数
   *
   * ex1. from: 2017/5/1, to: 2017/6/30
   * return: 1
   *
   * ex2. from: 2017/5/1, to: 2017/6/30, scale: 1
   * return: 1.9
   *
   * ex3. from: 2016/5/1, to: 2017/7/1, scale: 1
   * return: 24
   */
  static diffMonths(from, to, scale = 0) {
    if (!from || !to) {
      return 0;
    }
    const fromDate = typeof from === 'number' ? moment(from) : moment(from.getTime());
    const toDate = typeof to === 'number' ? moment(to) : moment(to.getTime());
    const months = toDate.diff(fromDate, 'months', scale > 0);
    return Number(months.toFixed(scale));
  }

  /**
   * 出生日から計算対象日時点での月齢を返します。
   * @param {Date} from 出生日
   * @param {Date} to 計算対象日
   * @param {number} scale 小数点以下の有効桁数(省略可能)
   * @return {number} 月数
   *
   * ex1. from: 2017/5/1, to: 2017/6/30
   * return: 1
   *
   * ex2. from: 2017/5/1, to: 2017/6/30, scale: 1
   * return: 1.9
   *
   * ex3. from: 2016/5/1, to: 2017/7/1, scale: 1
   * return: 24
   */
  static monthAge(from, to, scale = 0) {
    if (!from || !to) {
      return 0;
    }

    const fromDate = typeof from === 'number' ? new Date(from) : from;
    const toDate = typeof to === 'number' ? new Date(to) : to;

    if (toDate <= fromDate) return 0;

    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth() + 1;
    const fromDay = fromDate.getDate();

    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth() + 1;
    const toDay = toDate.getDate();

    const yearDiff = toYear - fromYear;
    let monthDiff = toMonth - fromMonth;

    let baseDate = null;
    if (toDay >= fromDay) {
      baseDate = new Date(toYear, toMonth - 1, fromDay);
    } else {
      monthDiff -= 1;
      baseDate = new Date(toYear, toMonth - 2, fromDay);
      if (baseDate.getDate() !== fromDay) {
        // 出生日が29日以降で集計日の前月にその日付がない場合は前月末日に置き換える
        const firstDayOfMonth = new Date(toYear, toMonth - 1, 1);
        baseDate = DateUtil.addDays(firstDayOfMonth, -1);
      }
    }

    const nextIncrementDate = DateUtil.addMonths(baseDate, 1);
    const baseDiffDays = DateUtil.diffDays(baseDate, nextIncrementDate);
    const diffDays = DateUtil.diffDays(baseDate, toDate);
    const dayDiff = diffDays / baseDiffDays;

    const months = (yearDiff * 12) + monthDiff + dayDiff;

    const truncate = (value, scale = 0) => {
      const coefficient = Math.pow(10, scale);
      const x = value * coefficient;
      return Math.floor(x) / coefficient;
    };

    const result = truncate(months, scale);

    return Number(result.toFixed(scale));
  }

  /**
   * ある期間の秒数を返す。
   *
   * @param {Date|number} from 開始時刻(Date or unixtime)
   * @param {Date|number} to 終了時刻(Date or unixtime)
   * @return {number} 開始時刻から終了時刻の秒数
   *
   * ex1. from: 2017/5/1 12:11:10, to: 2017/5/1 12:16:20
   * return: 310
   *
   * ex2. from: 2017/5/1 12:00:00, to: 2017/5/1 12:00:00
   * return: 0
   */
  static diffSeconds(from, to) {
    if (!from || !to) {
      return 0;
    }
    const fromDate = moment(from);
    const toDate = moment(to);
    return toDate.diff(fromDate, 's');
  }

  /**
   * 引数で渡された日付の月初を返す
   *
   * [exsample]
   * date = new Date() // 2017/11/28 11:19:00.134
   * DateUtil.startOfMonth(date) // 2017/11/01 00:00:00.000
   *
   * @param {Date} date 日付
   * @return {Date} 月初
   */
  static startOfMonth(date) {
    return moment(date).startOf('month').toDate();
  }

  /**
   * 引数で渡された日付の月末を返す
   *
   * [exsample]
   * date = new Date() // 2017/11/28 11:19:00.134
   * DateUtil.endOfMonth(date) // 2017/11/30 23:59:59.999
   *
   * @param {Date} date 日付
   * @return {Date} 月末
   */
  static endOfMonth(date) {
    return moment(date).endOf('month').toDate();
  }

  /**
   * 引数で渡された日時の0時00分00秒を返す
   *
   * [exsample]
   * date = new Date() // 2017/11/28 11:19:00.134
   * DateUtil.startOfDay(date) // 2017/11/28 00:00:00.000
   *
   * @param {Date} date 日時
   * @return {Date} 日時の0時00分00秒
   */
  static startOfDay(date) {
    return moment(date).startOf('day').toDate();
  }

  /**
   * 引数で渡された日時の23時59分59秒を返す
   *
   * [exsample]
   * date = new Date() // 2017/11/28 11:19:00.134
   * DateUtil.endOfDay(date) // 2017/11/28 00:00:00.000
   *
   * @param {Date} date 日時
   * @return {Date} 日時の0時00分00秒
   */
  static endOfDay(date) {
    return moment(date).endOf('day').toDate();
  }

  /**
   * 日付に時刻を追加する
   * @param {Number} date Date()で
   */
  static addTimeToDate(date, hour, minute) {
    return moment(date)
      .hour(hour || 0)
      .minute(minute || 0)
      .second(0)
      .millisecond(0)
      .valueOf();
  }

  /**
   * 1970年1月1日のDateインスタンスを返します
   *
   * @return {Date} 1970年1月1日
   */
  static minimumDate() {
    return new Date(1970, 0, 1);
  }

  /**
   * 2017年1月1日のDateインスタンスを返します
   *
   * @return {Date} 2017年1月1日
   */
  static umLaunchDate() {
    return new Date(2017, 0, 1);
  }

  /**
   * 9999年12月31日のDateインスタンスを返します
   *
   * @return {Date} 9999年12月31日
   */
  static infiniteDate() {
    return new Date(9999, 11, 31);
  }

  /**
   * 渡された日付が9999年12月31日かを判定します。
   *
   * @param {Date|number} date DateのインスタンスまたはUNIXTIME(ミリ秒)
   * @return {boolean} true: 渡された日付は9999年12月31日
   */
  static isInfiniteDate(date) {
    if (!date) return false;
    return moment(date).toDate().getTime() === DateUtil.infiniteDate().getTime();
  }

  /**
   * 渡された値が日付として有効かを判定します。
   *
   * @param {Date|string|number} date Dateのインスタンスまたは日付文字列またはUNIXTIME(ミリ秒)
   * @param {Array<String>} formats 有効なフォーマット
   * @return {boolean} true: 渡された値は日付として有効
   */
  static isValidDate(date, formats = ['YYYY/MM/DD', 'YYYY/M/D', 'X']) {
    if (!date) return false;
    return moment(date, formats, true).isValid();
  }

  /**
   * 渡された日付が入力可能な期間に含まれているかを判定します。
   * 1970年1月1日から実行日の1年後の日付までが入力可能な日付です。
   *
   * @param {Date|string|number} value Dateのインスタンスまたは日付文字列またはUNIXTIME(ミリ秒)
   * @param {boolean} allowNull true: nullや空文字の場合は検査せずにtrueを返す
   * @return {boolean} true: 渡された日付は入力可能
   */
  static includedPermittedPeriod(value, allowNull = true) {
    if (!value) {
      if (allowNull) {
        return true;
      }
      return false;
    }

    const date = DateUtil.toDate(value);
    if (!date) return false;

    const min = DateUtil.minimumDate().getTime();
    const dayAfterMax = DateUtil.addYears(DateUtil.today(), 1).getTime();
    const unixtime = date.getTime();
    return min <= unixtime && unixtime < dayAfterMax;
  }

  /**
   * 当日(0:00)のDateインスタンスを新規作成して返す
   *
   * @return {Date} 当日(0:00)のDateインスタンス
   */
  static today() {
    return moment().startOf('date').toDate();
  }

  /**
   * 引数で与えた日付が、当日ならばtrueを返す
   * @param {Date|string|number} date Dateのインスタンスまたは日付文字列またはUNIXTIME(ミリ秒)
  */
  static isToday(date) {
    if (!date) return false;

    return DateUtil.startOfDay(date).valueOf() === DateUtil.today().valueOf();
  }

  /**
   * 渡された日付の値に指定時間を加算した日時を返す。
   * マイナスの値を指定した場合は過去の日時が返されます。
   *
   * @param {Date|string|number} date Dateのインスタンスまたは日付文字列またはUNIXTIME(ミリ秒)
   * @param {number} hours
   * @return {Date}
   */
  static addHours(date, hours) {
    if (!date) {
      return null;
    }
    return moment(date).add(hours, 'hours').toDate();
  }

  /**
   * 渡された日付の値に指定日数を加算した日付(日時)を返す。
   * マイナスの値を指定した場合は過去日が返されます。
   *
   * @param {Date|string|number} date Dateのインスタンスまたは日付文字列またはUNIXTIME(ミリ秒)
   * @param {number} days
   * @return {Date}
   */
  static addDays(date, days) {
    if (!date) {
      return null;
    }
    return moment(date).add(days, 'days').toDate();
  }

  /**
   * 渡された日付の値に指定月数を加算した日付(日時)を返す。
   * マイナスの値を指定した場合は過去日が返されます。
   *
   * @param {Date|string|number} date Dateのインスタンスまたは日付文字列またはUNIXTIME(ミリ秒)
   * @param {number} months
   * @return {Date}
   */
  static addMonths(date, months) {
    if (!date) {
      return null;
    }
    return moment(date).add(months, 'months').toDate();
  }

  /**
  * 渡された日付の値に指定年数を加算した日付(日時)を返す。
  * マイナスの値を指定した場合は過去日が返されます。
  *
  * @param {Date|string|number} date Dateのインスタンスまたは日付文字列またはUNIXTIME(ミリ秒)
  * @param {number} years
  * @return {Date}
  */
  static addYears(date, years) {
    if (!date) {
      return null;
    }
    return moment(date).add(years, 'years').toDate();
  }

  /**
   * 渡された日付の値に指定月数を減算した日付(日時)を返す。
   * マイナスの値を指定した場合は未来日が返されます。
   *
   * @param {Date|string|number} date Dateのインスタンスまたは日付文字列またはUNIXTIME(ミリ秒)
   * @param {number} months
   * @return {Date}
   */
  static subtractMonths(date, months) {
    if (!date) {
      return null;
    }
    return moment(date).subtract(months, 'months').toDate();
  }

  /**
   * 渡された日付の次の日の日付で、インスタンスを新規作成
   * @param {Date|string|number} date Dateのインスタンスまたは日付文字列またはUNIXTIME(ミリ秒)
   * @return {Date} インプットが不正な場合はnull
   */
  static nextDay(date) {
    if (!date) return null;

    return moment(date).add(1, 'days').toDate();
  }

  /**
  * 渡された日付の前の日の日付で、インスタンスを新規作成
  * @param {Date|string|number} date Dateのインスタンスまたは日付文字列またはUNIXTIME(ミリ秒)
  * @return {Date} インプットが不正な場合はnull
  */
  static previousDay(date) {
    if (!date) return null;

    return moment(date).add(-1, 'days').toDate();
  }

  static weekAgo(weeks) {
    return moment().subtract(weeks, 'weeks').valueOf();
  }

  /**
  * 渡された開始日と終了日の間の期間の全年月を配列で返す。
  * 開始日と終了日の月も含む。
  * @param {Date} startDate Dateのインスタンス
  * @param {Date} endDate Dateのインスタンス
  * @param {Boolean} descending 降順に並び替える指定。デフォルトは昇順。
  * @return {Array}
  */
  static yearMonths(startDate, endDate, descending) {
    const startYear = startDate.getFullYear();
    const startMonths = startYear * 12 + startDate.getMonth();
    const endYear = endDate.getFullYear();
    const endMonths = endYear * 12 + endDate.getMonth();
    const range = [];
    for (let i = startMonths; i < endMonths + 1; i++) {
      const yyyy = Math.floor(i / 12);
      const mm = i % 12;
      range.push({year: yyyy, month: mm});
    }
    if (descending === true) range.reverse();
    return range;
  }

  /**
   * 渡された開始日と終了日の間の期間の年を配列で返す。
   * @param {Date} startDate Dateのインスタンス
   * @param {Date} endDate Dateのインスタンス
   * @param {Boolean} descending 降順に並び替える指定。デフォルトは昇順。
   * @return {Array}
   */
  static years(startDate, endDate, descending) {
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();

    const range = [];
    for (let i = startYear; i < endYear + 1; i++) {
      range.push(i);
    }
    if (descending === true) range.reverse();
    return range;
  }

  /**
   * @param {Number} timestamp 日付時刻(Unixtimestamp)
   * @param {Number} intervalMinute 整列させる間隔(分)
   * @returns {Number} 整列した日付(Unixtimestamp)
   */
  static alignMinute(timestamp, interval = 10) {
    const minute = moment(timestamp).get('minute'),
      alignedMin = Math.floor(minute / interval) * interval;

    return moment(timestamp)
      .minute(alignedMin)
      .valueOf();
  }

  /**
   * 渡された日付の値に指定日数を減算した日付(日時)を返す。
   * マイナスの値を指定した場合は未来日が返されます。
   *
   * @param {Date|string|number} date Dateのインスタンスまたは日付文字列またはUNIXTIME(ミリ秒)
   * @param {number} months
   * @return {Date}
   */
  static subtractDays(date, days) {
    if (!date) {
      return null;
    }
    return moment(date).subtract(days, 'd').toDate();
  }

  /**
   * 引数で指定された形式の文字列を返す。
   *
   * @param {Date|string|number} date
   * @param {string} format YYYY, MM, DD, HH, mm, etc
   * @retrun {string}
   */
  static format(date, format) {
    if (!date) return null;
    return moment(date).format(format);
  }

  static japaneseDayOfWeek(date) {
    if (!date) return '';
    return '日月火水木金土'[date.getDay()];
  }

  /**
   * 引数で渡されたdate1, date2の日付までが同じ場合はtrueを返す
   * 引数が不正またはdate1, date2の日付までが違う場合はfalseを返す
   *
   * @param {Date|string|number} date
   * @param {Date|string|number} date
   * @retrun {boolean}
   */
  static isSameDay(date1, date2) {
    if (!date1 || !date2) return false;
    return moment(date1).isSame(date2, 'day');
  }

  /**
   * 引数で渡されたdate1, date2の時刻までが同じ場合はtrueを返す
   * 引数が不正またはdate1, date2の時刻までが違う場合はfalseを返す
   *
   * @param {Date|string|number} date
   * @param {Date|string|number} date
   * @retrun {boolean}
   */
  static isSameHour(date1, date2) {
    if (!date1 || !date2) return false;
    return moment(date1).isSame(date2, 'hour');
  }

  /**
   * 引数で渡されたdate1がdate2の日付以前の場合はtrueを返します。
   * 不等号で表現すると [date1 < date2] の場合にtrueを返します。
   * 引数が不正またはdate1がdate2以降の日付の場合はfalseを返します。
   *
   * @param {Date|string|number} date
   * @param {Date|string|number} date
   * @retrun {boolean}
   */
  static isBeforeDay(date1, date2) {
    if (!date1 || !date2) return false;
    return moment(date1).isBefore(date2, 'day');
  }

  /**
   * 引数で渡されたdate1がdate2の日付以前か同日の場合はtrueを返します。
   * 不等号で表現すると [date1 <= date2] の場合にtrueを返します。
   * 引数が不正またはdate1がdate2以降の日付の場合はfalseを返します。
   *
   * @param {Date|string|number} date
   * @param {Date|string|number} date
   * @retrun {boolean}
   */
  static isSameOrBeforeDay(date1, date2) {
    if (!date1 || !date2) return false;
    return moment(date1).isSameOrBefore(date2, 'day');
  }

  /**
   * 引数で渡されたdate1がdate2の日付以降か同日の場合はtrueを返します。
   * 不等号で表現すると [date1 >= date2] の場合にtrueを返します。
   * 引数が不正またはdate1がdate2以前の日付の場合はfalseを返します。
   *
   * @param {Date|string|number} date
   * @param {Date|string|number} date
   * @retrun {boolean}
   */
  static isSameOrAfterDay(date1, date2) {
    if (!date1 || !date2) return false;
    return moment(date1).isSameOrAfter(date2, 'day');
  }

  /**
   * 引数で渡されたdateが実行日付より未来の場合[実行日付 < date]はtrueを返します。
   * 引数が不正またはdateが実行日付と同じもしくは過去の場合はfalseを返します。
   *
   * @param {Date|string|number} date
   * @param {Date|string|number} date
   * @retrun {boolean}
   */
  static isFutureDay(date) {
    if (!date) return false;
    return moment(date).isAfter(DateUtil.today());
  }
}
