class AlertEartagSensorDroppedController {
  constructor(
    $q,
    $modal,
    $state,
    $timeout,
    blockUI,
    AlertEartagSensorDroppedAPI,
    SelectionService,
    UserDefinedSelectionAPI,
    StandardDialog
  ) {
    'ngInject';
    this.$q = $q;
    this.$modal = $modal;
    this.$state = $state;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.AlertEartagSensorDroppedAPI = AlertEartagSensorDroppedAPI;
    this.SelectionService = SelectionService;
    this.UserDefinedSelectionAPI = UserDefinedSelectionAPI;
    this.StandardDialog = StandardDialog;

    this.title = 'イヤタグセンサー脱落アラート';

    this.init();
  }

  init() {
    this.loading = true;
    this.solution = null;
    this.factor = null;
    this.currentAlert = null;

    this.$q.all([
      this.AlertEartagSensorDroppedAPI.unsolved(),
      this.SelectionService.index(),
      this.UserDefinedSelectionAPI.available()
    ]).then((res) => {
      this.rawAlerts = res[0].data.map((d) => AlertEartagSensorDropped.convertViewModel(d));
      this.selectUnsolvedAlerts();

      this.expelledReasons = res[1].expelledReason.map((reason) => {
        return {
          label: reason.label,
          value: reason.value
        };
      });

      this.buyers = getCustomSelectList('buyers', res[2].data, false);

      this.loading = false;
    });
  }

  selectUnsolvedAlerts() {
    this.alerts = this.rawAlerts;
  }

  goToDetails(cowId) {
    const cowIds = this.alerts.map((a) => a.cowId);
    const caller = {
      state: 'alert-eartag-sensor-dropped',
      name: 'イヤタグセンサー脱落アラート',
      cowIds: cowIds,
      viewState: 'ACTIVITY'
    };

    this.$state.go('cowDetail', {cowId: cowId, caller: caller});
  }

  openSolutionSelectionModal(id, active) {
    this.currentAlert = this.alerts.find((a) => a.id === id);
    this.active = active;

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/alert/eartag-sensor-dropped/solution-selection.html',
      controller: 'AlertEartagSensorDroppedSolutionSelctionController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            eartagSensorNumber: this.currentAlert.formattedEartagSensorNumber,
            expelledReasons: this.expelledReasons,
            buyers: this.buyers,
            active: this.active
          };
        }
      }
    }).result.then((res) => {
      if (!res) {
        this.solution = null;
        this.factor = null;
        this.shipmentsForm = null;
        return;
      }

      this.solution = res.solution;
      this.factor = res.factor;

      return this.update(this.solution, this.factor, res.shipmentsForm);
    });
  }

  update(solution, factor, shipmentsForm) {
    const params = Object.assign({solution: solution, factor: factor}, shipmentsForm);

    this.blockUI.start('登録中');

    this.AlertEartagSensorDroppedAPI.update(this.currentAlert.id, params).then((res) => {
      this.blockUI.done(() => {
        this.blockUI.start('登録が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
          this.$state.reload();
        }, 1000);
      });

      this.blockUI.stop();
    }).catch((error) => {
      const errorMessage = ErrorUtil.formatErrorMessage(error.data.messages);
      this.blockUI.stop();
      this.StandardDialog.showMessage({
        title: '処置内容の登録エラー',
        text1: errorMessage
      });
    }).finally(() => {
      this.currentAlert = null;
      this.solution = null;
      this.factor = null;
    });
  }

  onPrint() {
    let printArea = document.getElementById('hidden-print-area');
    let notPrintArea = document.getElementById('not-print-area');

    printArea.hidden = false;
    notPrintArea.hidden = true;

    print();

    printArea.hidden = true;
    notPrintArea.hidden = false;
  }
}

app.controller('AlertEartagSensorDroppedController', AlertEartagSensorDroppedController);
