class AccountAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/account');
  }

  show(id) {
    return this.APIService.get(`/account/${id}`);
  }

  current() {
    return this.APIService.get('/account/current');
  }

  create(name, email, password, role) {
    const params = {name: name, email: email, password: password, role: role};
    return this.APIService.post('/account', params);
  }

  update(id, name) {
    const params = {name: name};
    return this.APIService.put(`/account/${id}`, params);
  }

  updateUiVersion(uiVersion) {
    const params = {uiVersion: uiVersion};
    return this.APIService.put('/account/current/ui_version', params);
  }

  delete(id) {
    return this.APIService.delete(`/account/${id}`);
  }
}

app.service('AccountAPI', AccountAPI);
