function CustomlistRuntimeDirective($compile) {
  'ngInject';

  function generateTableBody(master, columns, printable, isDepositorMode) {
    return `<tr
      ng-repeat="cow in ctrl.cows track by $index"
      repeat-finished
      ng-class="{'row--selected': cow.selected}">`
      + generetaeRow(master, columns, printable, isDepositorMode)
      + `</tr>`;
  }

  function generetaeRow(master, columns, printable, isDepositorMode) {
    const customlist = new Customlist(null);

    const showCowDetailLink = (printable || isDepositorMode) ? false : true;

    const generateCell = (c) => {
      if (customlist.isSimpleColumn(c)) {
        return CustomlistHtmlGenerator.cell(c.columnId, c.cssStyle);
      } else if (customlist.isCowNoColumn(c)) {
        return CustomlistHtmlGenerator.cowNo(showCowDetailLink);
      } else if (customlist.isCalfNoColumn(c)) {
        return CustomlistHtmlGenerator.calfNo(showCowDetailLink);
      } else if (customlist.isCowLabelColumn(c)) {
        return CustomlistHtmlGenerator.cowLables();
      } else if (customlist.isCommentColumn(c)) {
        return CustomlistHtmlGenerator.comment();
      } else if (customlist.isSummaryColumn(c)) {
        return CustomlistHtmlGenerator.summary(c.columnId);
      } else if (customlist.isAlertTypeColumn(c)) {
        return CustomlistHtmlGenerator.cowAlertType();
      }
      return '';
    };

    const cells = columns.map((c) => `
      <td ng-class="c.cssTdClass" ng-click="ctrl.updateCurrentCow(cow)">`
      + generateCell(c) + `</td>`);

    const firstCell = `
      <td class="noPrint">
        <label class="cow-select" ng-if="ctrl.showCowSelect">
          <input
            type="checkbox"
            class="checkbox"
            ng-model="cow.selected"
            ng-click="ctrl.componentFuncs.onClickCow($event, ctrl.cows)">
        </label>
      </td>`;
    cells.unshift(firstCell);

    if (master.showEntryColumn) {
      cells.push(`<td class="entry-column"></td>`);
    }

    return cells.join('');
  }

  return {
    restrict: 'A',
    replace: true,
    link: (scope, element, attrs) => {
      scope.$watchCollection('ctrl.cows.length', (value) => {
        const ctrl = scope.ctrl;
        const contents = $compile(
          generateTableBody(
            ctrl.master,
            ctrl.columns,
            ctrl.printable,
            ctrl.isDepositorMode
          )
        )(scope);
        element.contents().remove();
        element.append(contents);
      });
    }
  };
}

app.directive('customlistRuntimeDirective', CustomlistRuntimeDirective);
