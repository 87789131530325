/* eslint-disable */
class Market {
  constructor(params) {
    // Change to Object.assign in the future
    this.origin = angular.extend({}, this.defaultContent_(), params);
    this.initializeContent();
  }

  // public content

  /**
   * 項目を初期化する。
   */
  initializeContent() {
    angular.extend(this, this.origin);
  }

  // private method

  /**
   * デフォルトオブジェクトを返す。
   * 
   * @return {Object} デフォルトオブジェクト
   */
  defaultContent_() {
    return {
      name: '',
      address: '',
      phone: '',
      fax: ''
    };
  }
}
