class DepositMasterSpermService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  all() {
    return this.APIService.get('/deposit/master_sperm/all');
  }

  available(qwert) {
    return this.APIService.get(`/deposit/master_sperm?qwert=${qwert}`);
  }
}

app.service('DepositMasterSpermService', DepositMasterSpermService);
