class MobileTopReproductionPlannedEventsController {
  constructor(
    ReproductionPlannedEventsAPI
  ) {
    'ngInject';

    const base = new TopReproductionPlannedEventsController(ReproductionPlannedEventsAPI);

    TopReproductionPlannedEventsBinder.bind(this, base);
  }
}

function mobileTopReproductionPlannedEvents() {
  return {
    templateUrl: 'mobile/schedule/reproduction-planned-events/index.html',
    controller: MobileTopReproductionPlannedEventsController,
    controllerAs: 'ctrl',
    bindings: {
      date: '<',
      total: '='
    }
  };
}

app.component('mobileTopReproductionPlannedEvents', mobileTopReproductionPlannedEvents());
