class ReportSummaryController {
  constructor(
    $rootScope,
    ReportSummaryService,
    DateUtilService
  ) {
    'ngInject';

    this.ReportSummaryService = ReportSummaryService;
    this.DateUtilService = DateUtilService;

    this.title = 'サマリー';
    this.summaries = {
      reproductionPerformance: [],
      carcassCharacteristic: []
    };

    this.initialize();
  }

  initialize() {
    this.endDate = new Date();
    this.startDate = this.DateUtilService.subtractMonths(this.endDate, 2);

    this.ReportSummaryService.load(this.startDate, this.endDate)
      .then((res) => this.summaries = res);
  }
}

app.controller('ReportSummaryController', ReportSummaryController);
