class StateDialogController {
  constructor(
    $scope,
    $modalInstance,
    params) {
    'ngInject';
    this.$scope = $scope;
    this.$modalInstance = $modalInstance;

    this.initialize(params);
  }

  initialize(params) {
    this.$scope.$on('ngRepeatFinished', () => {
      FixedMidashi.create();
    });

    const states = params.states ? params.states : [];
    const exclude = params.exclude ? params.exclude : [];

    const values = State.values(params.farmType, exclude);
    const records = values.map((v) => {
      const selected = states.indexOf(v.id) >= 0;
      return {
        id: v.id,
        label: v.label,
        explanation: v.explanation,
        selected: selected
      };
    });
    this.records = records;
  }

  clickRow(record) {
    record.selected = !record.selected;
  }

  ok() {
    const selectedStates = this.records
      .filter((r) => r.selected)
      .map((r) => {
        return {
          id: r.id,
          label: r.label
        };
      });
    this.$modalInstance.close(selectedStates);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('StateDialogController', StateDialogController);
