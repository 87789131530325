class DhiImportAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/dhi_import');
  }

  show(id) {
    return this.APIService.get(`/dhi_import/${id}/detail`);
  }

  upload(file) {
    return this.APIService.upload('/dhi_import', file);
  }

  delete(id) {
    return this.APIService.delete(`/dhi_import/${id}`);
  }

  errorList(id) {
    return this.APIService.get(`/dhi_import/${id}/error`);
  }
}

app.service('DhiImportAPI', DhiImportAPI);
