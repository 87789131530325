class HomeDailyReportSummaryController {
  constructor(
    $state,
    DailyReportAPI
  ) {
    'ngInject';

    this.$state = $state;
    this.DailyReportAPI = DailyReportAPI;

    this.init();
  }

  init() {
    this.eventNameMap = {
      claw_diseases: '跛行・<br>蹄病',
      perinatal_and_metabolic_diseases: '周産病・<br>代謝病',
      fresh_check: 'フレッシュ<br>チェック',
      timed_ai: 'ホルモン<br>プログラム'
    };
  }

  $doCheck() {
    if (!this.date || (this.previousDate === this.date && !this.reload)) return;

    this.loading.dailyReport = true;
    this.previousDate = this.date;
    this.reload = false;

    this.index().then(() => {
      this.loading.dailyReport = false;
      this.enableMasonry();
    });
  }

  index() {
    const date = DateUtil.toW3CFormat(this.date);
    const params = {
      date
    };

    return this.DailyReportAPI.summary(date).then((res) => {
      const summary = DailyReportSummaryDefinition.updateSummary(res.data, params);
      const items = summary.filter((item) => {
        return item.count > 0;
      }).map((item) => {
        const eventName = this.eventNameMap[item.eventType];

        if (eventName) {
          item.label = eventName;
        }

        return item;
      });

      this.items = DailyReportSummaryDefinition.grouping(items);
    });
  }

  goTo(to, params) {
    params = params || {};
    this.$state.go(to, params);
  }

  classNameWidgetHeading(group) {
    switch (group) {
    case '治療':
      return 'uWidget__heading--group1';
    case '育成':
      return 'uWidget__heading--group2';
    case '繁殖':
      return 'uWidget__heading--group3';
    case 'その他':
      return 'uWidget__heading--group4';
    case '牧場メモ':
      return 'uWidget__heading--group5';
    }
  }
}

function homeDailyReportSummary() {
  return {
    templateUrl: 'menu/home/daily-report/summary/index.html',
    controller: HomeDailyReportSummaryController,
    controllerAs: 'ctrl',
    bindings: {
      enableMasonry: '&',
      loading: '=',
      date: '=',
      reload: '='
    }
  };
}

app.component('homeDailyReportSummary', homeDailyReportSummary());
