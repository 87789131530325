class CowEventIndexController {
  constructor(
    $q,
    $state,
    $stateParams,
    $rootScope,
    blockUI,
    CowEventAPI,
    CowGroupService,
    FarmService,
    MasterEmbryoAPI,
    FileUtilService,
    OperationUserService,
    Dictionary,
    SelectionService,
    DetailsStateFactory,
    SessionCache,
    ReproductionConfigService
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.blockUI = blockUI;
    this.CowEventAPI = CowEventAPI;
    this.CowGroupService = CowGroupService;
    this.FarmService = FarmService;
    this.MasterEmbryoAPI = MasterEmbryoAPI;
    this.FileUtilService = FileUtilService;
    this.OperationUserService = OperationUserService;
    this.Dictionary = Dictionary;
    this.SelectionService = SelectionService;
    this.DetailsStateFactory = DetailsStateFactory;
    this.canEditFilterItem = !SessionCache.account().isItemFilterApplied();
    this.ReproductionConfigService = ReproductionConfigService;

    const farm = SessionCache.farm();
    this.farmType = farm.farmType();

    this.init($stateParams, DateUtil.today(), Dictionary.COW.COW_NO);
  }

  init(params, currentDate, cowNoLabel) {
    this.cowGroupsMap = {};
    this.embryoMap = {};
    this.mastitisScoreMap = Mastitis.MASTITIS_SCORES.reduce((acc, cur) => {
      acc[cur.value] = cur.label;
      return acc;
    }, {});

    this.setEventTypeOptions();
    this.staffNames = [{label: '', value: ''}];
    this.dateFieldTypes = [];

    this.displayLimit = 1000;
    this.csvLimit = 2000;

    const definitions = CowEventItemDefinition.allItems;
    const cowNoItem = definitions['cowNo'];
    cowNoItem.label = cowNoLabel;
    this.itemDefinitions = definitions;

    this.eventType = params.eventType || null;
    this.selectedEventType = params.eventType || null;
    this.dateField = params.dateField || 'occurred_at';
    this.startDate = DateUtil.toDate(params.startDate) || DateUtil.addDays(currentDate, -7);
    this.endDate = DateUtil.toDate(params.endDate) || currentDate;
    this.entryStaff = params.entryStaff || '';
    this.currentColumnSort = null;

    this.onChangeEventType();

    const promises = [
      this.CowGroupService.index(),
      this.OperationUserService.index(),
      this.SelectionService.index(),
      this.MasterEmbryoAPI.index(),
      this.ReproductionConfigService.show()
    ];

    if (this.eventType) promises.push(this.load());

    const indexCopwGroup = 0;
    const indexOperationUser = 1;
    const indexSelection = 2;
    const indexMasterEmbryo = 3;
    const indexReproductionConfig = 4;
    const indexCowEvent = 5;

    this.$q.all(promises).then((res) => {
      res[indexCopwGroup].data.forEach((group) => {
        this.cowGroupsMap[group.cowGroupId] = group;
      });

      const operators = res[indexOperationUser].data;
      this.staffNames = operators.map((operator) => {
        return {label: operator.name, value: operator.name};
      });
      this.staffNames.unshift({label: '', value: ''});

      if (res[indexSelection]) {
        this.selectionList = res[indexSelection];
      }

      res[indexMasterEmbryo].data.forEach((embryo) => {
        this.embryoMap[embryo.id] = embryo;
      });

      const embryoRecoveryRank = res[indexReproductionConfig].data.embryoRecoveryRank;

      this.embryoRecoveryRankItemIds = Object.entries(embryoRecoveryRank).filter(([_, value]) => {
        return value.use;
      }).map(([key, value]) => {
        const {subrank} = value;
        const rank = `${key[0].toUpperCase()}${key.slice(1)}`;

        if (!subrank || subrank === 1) {
          return `embryoRecoveryRank${rank}`;
        }

        return Array(subrank).fill('').map((_, index) => {
          const subrank = index + 1;

          return `embryoRecoveryRank${rank}${subrank}`;
        });
      }).flat();

      if (res[indexCowEvent] && res[indexCowEvent].length) {
        this.events = this.toViewModel(res[indexCowEvent]);
        this.rawCows = [].concat(this.events);
      }
    }).catch((err) => console.error(err));
  }

  load() {
    this.blockUI.start('検索中');
    this.selectedEventType = this.eventType;

    const params = {
      eventType: EventType.toLegacyEventType(this.eventType),
      dateField: this.dateField,
      startDate: this.startDate,
      endDate: this.endDate,
      entryStaff: this.entryStaff
    };
    return this.CowEventAPI.search(params)
      .then((res) => res.data)
      .catch((err) => [])
      .finally(() => this.blockUI.stop());
  }

  index() {
    this.currentColumnSort = null;

    this.load().then((cowEvents) => {
      this.events = this.toViewModel(cowEvents);
      this.rawCows = [].concat(this.events);
    });
  }

  toViewModel(events) {
    return events.map((e) => {
      const origin = Object.assign({}, e);

      if (e.cowUid) {
        e.cowUid = Cow.formatCowUid(e.cowUid);
      }

      if (e.occurredAt) {
        e.occurredAt = DateUtil.toTimestampFormat(e.occurredAt);
      }

      if (e.treatmentDiseaseDate) {
        e.treatmentDiseaseDate = DateUtil.toTimestampFormat(e.treatmentDiseaseDate);
      }

      if (e.occurredDiseaseDate) {
        e.occurredDiseaseDate = DateUtil.toTimestampFormat(e.occurredDiseaseDate);
      }

      if (e.plannedBredAt) {
        e.plannedBredAt = DateUtil.toTimestampFormat(e.plannedBredAt);
      }

      if (e.moveToCowGroupId) {
        e.cowGroupName = this.resolveCowGroupName(e.moveToCowGroupId);
      }

      if (e.beforeCowGroupId) {
        e.beforeCowGroupName = this.resolveCowGroupName(e.beforeCowGroupId);
      }

      if (e.medications) {
        e.medication = e.medications.map((medication) => {
          return `${medication.name} ${medication.capacity} ${medication.method}`;
        });
      }

      if (e.mastitisCowGroupId) {
        e.mastitisCowGroupName = this.resolveCowGroupName(e.mastitisCowGroupId);
      }

      if (this.eventType === 'others' && !e.difficultiesOther) {
        e.otherDisease = e.otherDiseaseNames.map((e) => e.name).join('、');
      } else {
        if (e.otherDiseaseName || e.difficultiesOther) {
          const others = [];
          others.push(e.otherDiseaseName);
          others.push(e.difficultiesOther);
          e.otherDisease = others.filter((o) => o).join(' ');
        }
      }

      if (this.eventType === 'claw_diseases') {
        const partLabels = {
          '左前': 'Fl',
          '右前': 'Fr',
          '左後': 'Bl',
          '右後': 'Br'
        };

        e.groupedLameDiagnosises = CowEvent.groupLameDiagnosisesByPart(e);

        ['左前', '右前', '左後', '右後'].forEach((part) => {
          e['lameDiagnosisResult' + partLabels[part]] = [];
          e.groupedLameDiagnosises[part].forEach((diagnosis) => {
            e['lameDiagnosisResult' + partLabels[part]].push(`${diagnosis['lameAffectedLimbAndPart']} ${diagnosis['lameCondition'] || ''} ${diagnosis['clawDiseaseName'] || ''}`);
          });
        });
      }

      if (e.carcassDefects) {
        e.carcassDefectSummary = Carcass.defectSummary(e.carcassDefects);
      }

      if (e.targetBreedingDate) {
        e.targetBreedingDate = DateUtil.toYYYYMMDD(e.targetBreedingDate);
      }

      if (e.embryoMasterRegistered !== undefined) {
        const selection = this.selectionList.embryoMasterRegistered.find((s) => s.value === e.embryoMasterRegistered);
        e.embryoMasterRegistered = selection ? selection.label : '';
      }

      if (e.nextCowState !== undefined) {
        const selection = this.selectionList.embryoRecoveryNextCowState.find((s) => s.value === e.nextCowState);
        e.nextCowState = selection ? selection.label : '';
      }

      if (e.visceralDestruction) {
        e.selectedVisceralDestruction = e.visceralDestruction.split(':').join('、');
      }

      if (this.eventType === 'calved') {
        [...4].map((index) => {
          const no = index + 1;

          if (e.calfs[index]) {
            Object.entries(e.calfs[index]).forEach(([key, value]) => {
              if (key === 'cowUid') {
                value = Cow.formatCowUid(value);
              } else if (key === 'birthState') {
                value = value.join(',');
              }

              e[`calf${no}${key}`] = value;
            });
          } else {
            e[`calf${no}registered`] = false;
          }
        });
      }

      if (this.eventType === 'embryo_recovery') {
        [
          'embryoRecoveryRankA',
          'embryoRecoveryRankB',
          'embryoRecoveryRankC',
          'embryoRecoveryRankD',
          'embryoRecoveryUnfertilized'
        ].map((itemId) => {
          delete e[itemId];
        });

        e.embryos.map((embryo) => {
          const {rank, count} = embryo;
          const itemId = `embryoRecoveryRank${rank[0].toUpperCase()}${rank.slice(1)}`;

          e[itemId] = count;
        });
      }

      if (this.eventType === 'mastitis') {
        e.mastitisScore = this.mastitisScoreMap[e.mastitisScore];
      }

      if (this.eventType === 'bred') {
        if (e.tanetsukeMethod === '移植') {
          const embryo = this.embryoMap[e.masterEmbryoId];
          if (embryo) e.donorName = embryo.donorName;
        }
        e.pregnantResult = e.pregnantResult === 'pregnant' ? '受胎' : '';
        e.dim = e.birthNumber === 0 ? '' : e.dim;
      }

      if (e.dressedCarcassUnitPrice) {
        e.dressedCarcassUnitPrice = Number(e.dressedCarcassUnitPrice).toLocaleString();
      }

      if (e.dressedCarcassSalesPrice) {
        e.dressedCarcassSalesPrice = Number(e.dressedCarcassSalesPrice).toLocaleString();
      }

      ['stopMilkingOfBreastFl',
        'stopMilkingOfBreastFr',
        'stopMilkingOfBreastBl',
        'stopMilkingOfBreastBr',
        'calf1registered',
        'calf2registered',
        'calf3registered',
        'calf4registered',
      ].filter((itemId) => {
        return typeof e[itemId] !== 'undefined';
      }).forEach((itemId) => {
        origin[itemId] = Number(e[itemId]);
      });

      ['difficultiesOvaryStill',
        'difficultiesOvaryCystic',
        'difficultiesRemnant',
        'difficultiesMetritis',
        'difficultiesPyometra',
        'difficultiesHipDislocation',
        'difficultiesTeatInjury',
        'difficultiesPneumonia',
        'difficultiesDiarrhea',
        'difficultiesSkinDisease',
        'difficultiesKetosis',
        'difficultiesFattyLiver',
        'difficultiesAbomasumDisplacement',
        'difficultiesHypocalcium',
        'difficultiesAcidosis',
        'difficultiesDowner',
        'difficultiesPlacenta',
        'difficultiesUrolithiasis',
        'difficultiesVitaminA',
        'difficultiesTympanites',
        'difficultiesStagnantFood',
      ].filter((itemId) => {
        return typeof e[itemId] !== 'undefined';
      }).forEach((itemId) => {
        const difficultiesMap = {
          '軽': 1,
          '中': 2,
          '重': 3
        };

        origin[itemId] = difficultiesMap[e[itemId]] || 0;
      });

      [
        'diagnosisResultOfOvaryL',
        'diagnosisResultOfOvaryR',
        'uterusDiagnosisResult',
        'freshCheckResult',
      ].filter((itemId) => {
        return typeof e[itemId] !== 'undefined';
      }).forEach((itemId) => {
        const diagnosisResultMap = {
          '良好': 1,
          '注意': 2,
          '悪い': 3
        };

        origin[itemId] = diagnosisResultMap[e[itemId]];
      });

      return Object.assign({}, {origin}, e);
    });
  }

  resolveCowGroupName(cowGroupId) {
    const cowGroup = this.cowGroupsMap[cowGroupId] || {cowGroupName: '(削除済牛群)'};
    return cowGroup.cowGroupName;
  }

  setEventTypeOptions() {
    const eventTypes = EventTypeIndexDefinition.availableEventTypes(this.farmType);
    this.eventTypes = eventTypes.map((eventType) => {
      return {
        key: eventType.eventType,
        label: eventType.label,
        group: eventType.group
      };
    });
  }

  onChangeEventType() {
    if (this.eventType) {
      this.itemIds = CowEventItemRelation.items(this.eventType, this.farmType, this.canEditFilterItem);

      if (this.eventType === 'embryo_recovery') {
        this.itemIds = this.itemIds.filter((itemId) => {
          return !itemId.startsWith('embryoRecoveryRank') || this.embryoRecoveryRankItemIds.includes(itemId);
        });
      }

      this.currentDefinition = EventTypeIndexDefinition.definition(this.eventType);
    }

    const isTreatmentEvent = this.currentDefinition ? this.currentDefinition.group === '治療' : false;
    if (isTreatmentEvent) {
      this.dateFieldTypes = [
        {label: '発生日', value: 'occurred_disease_date'},
        {label: '治療日', value: 'treatment_disease_date'},
        {label: '入力日', value: 'updated_at'}
      ];
      if (!this.dateField || this.dateField === 'occurred_at') {
        this.dateField = 'treatment_disease_date';
      }
    } else {
      this.dateFieldTypes = [
        {label: '発生日', value: 'occurred_at'},
        {label: '入力日', value: 'updated_at'}
      ];
      if (!this.dateField || ['occurred_disease_date', 'treatment_disease_date'].includes(this.dateField)) {
        this.dateField = 'occurred_at';
      }
    }
  }

  exportAsCsv() {
    const fileName = this.currentDefinition.label + '.csv';
    const csvString = this.convertDataToCsvString();
    this.FileUtilService.exportAsCsv(csvString, fileName);
  }

  convertDataToCsvString() {
    let itemIds = CowEventItemRelation.items(this.eventType, this.canEditFilterItem);

    if (this.eventType === 'embryo_recovery') {
      itemIds = itemIds.filter((itemId) => {
        return !itemId.startsWith('embryoRecoveryRank') || this.embryoRecoveryRankItemIds.includes(itemId);
      });
    }

    const workerLabel = CowEventIndexHtmlGenerator.workerLabel(this.eventType);

    const headers = itemIds.map((itemId) => {
      if (itemId === 'workerName') {
        return workerLabel;
      } else {
        const definition = this.itemDefinitions[itemId];
        return definition.label.replace(/<br>/i, '');
      }
    });
    const header = headers.join(',');

    const lameItems = [
      'lameDiagnosisResultFl',
      'lameDiagnosisResultFr',
      'lameDiagnosisResultBl',
      'lameDiagnosisResultBr'
    ];
    const body = this.events.reduce((arr, curr) => {
      const row = itemIds.map((itemId) => {
        if (itemId === 'medication' && curr.hasOwnProperty(itemId)) {
          return `"${curr[itemId].join(', ')}"`;
        }

        if (lameItems.some((key) => itemId === key) && curr.hasOwnProperty(itemId)) {
          return `"${curr[itemId].join(',')}"`;
        }

        if (typeof curr[itemId] === 'string' && curr[itemId].match(/[,"\n]/)) {
          curr[itemId] = `"${curr[itemId]}"`;
        }

        if (itemId === 'cowUid') {
          return Cow.unformatCowUid(curr.cowUid);
        }

        return curr[itemId];
      });

      arr.push(row);
      return arr;
    }, []);

    return [header].concat(body).join('\n');
  }

  goToDetails(cowId) {
    const cowIds = this.events.reduce((arr, ev) => {
      if (arr.indexOf(ev.cowId) < 0) arr.push(ev.cowId);
      return arr;
    }, []);

    const params = {
      eventType: this.eventType,
      dateField: this.dateField,
      startDate: DateUtil.toW3CFormat(this.startDate),
      endDate: DateUtil.toW3CFormat(this.endDate),
      entryStaff: this.entryStaff,
    };
    const caller = {
      name: 'イベント検索',
      cowIds,
      state: 'cowEvent',
      params: params
    };

    this.DetailsStateFactory.setDisplayTab(this.DetailsStateFactory.tabNames.dataTab);
    this.$state.go('cowDetail', {cowId, caller});
  }

  generateHeaderClass(className, itemId) {
    if (!itemId) return className;

    const columnId = itemId === 'cowNo' ? 'cow_no' : itemId;
    let sortedClass = 'sorted-none';

    if (this.currentColumnSort && this.currentColumnSort.column.columnId === columnId) {
      if (this.currentColumnSort.isAscending) {
        sortedClass = 'sorted-asc';
      } else {
        sortedClass = 'sorted-desc';
      }
    }

    return `${className} ${sortedClass}`;
  }

  onClickColumnHeader(itemId) {
    const columnId = itemId === 'cowNo' ? 'cow_no' : itemId;
    const column = Object.assign({}, {columnId}, this.itemDefinitions[itemId]);

    if (this.currentColumnSort && this.currentColumnSort.column.columnId === columnId) {
      if (this.currentColumnSort.isAscending) {
        CustomlistSort.sort(this.events, column, false);
        this.currentColumnSort = {column, isAscending: false};
      } else {
        this.currentColumnSort = null;
        this.events = [].concat(this.rawCows);
      }
    } else {
      CustomlistSort.sort(this.events, column, true);
      this.currentColumnSort = {column, isAscending: true};
    }
  }
}

app.controller('CowEventIndexController', CowEventIndexController);
