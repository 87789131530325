app.config(['$compileProvider', '$httpProvider', ($compileProvider, $httpProvider) => {
  'ngInject';
  // 同時期に出すhttp requestをまとめる
  $httpProvider.useApplyAsync(true);

  // htmlの中に散らばる ng-scope, ng-isolate-scopeを追加しないように
  $compileProvider.debugInfoEnabled(false);

  // CSSやコメントなどをdirectiveの検索から外す
  $compileProvider.commentDirectivesEnabled(false);
  $compileProvider.cssClassDirectivesEnabled(false);
}]);
