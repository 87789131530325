// eslint-disable-next-line no-unused-vars
class PregnantDiagnosis {
  static calculateTargetBreedingDate(ninshinkanteiResult, latestBreedingMethod, latestFertilizationDate) {
    if (['受胎', '双子受胎'].includes(ninshinkanteiResult)) {
      return latestBreedingMethod === '移植' ?
        DateUtil.subtractDays(latestFertilizationDate, 7).valueOf() : latestFertilizationDate;
    } else {
      return null;
    }
  }
}
