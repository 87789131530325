class GenomEvaluationListController {
  constructor(
    $rootScope,
    $modal,
    $state,
    $stateParams,
    $timeout,
    SessionCache,
    CustomlistRunner,
    FileUtilService,
    uiGridConstants,
    ViewStateAPI,
    SideBoardService
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$modal = $modal;
    this.$state = $state;
    this.$timeout = $timeout;
    this.CustomlistRunner = CustomlistRunner;
    this.FileUtilService = FileUtilService;
    this.uiGridConstants = uiGridConstants;
    this.ViewStateAPI = ViewStateAPI;
    this.SideBoardService = SideBoardService;

    this.windowState = new WindowState();

    const farm = SessionCache.farm();
    this.farmType = farm.farmType();

    const customlistSystemConfigs = SessionCache.customlistSystemConfigs();
    const config = customlistSystemConfigs.find((config) => config.configType === 'ge_beef_cattle');
    if (config) {
      this.customlistId = config.customlistId;
    }

    this.ROWS_TO_VIRTUALIZE = 100;
    this.ROW_VIRTUALIZATION_THRESHOLD = 200;

    this.isPrinting = false;
    this.loading = true;
    this.showCowBoard = false;
    this.showIndexSetting = farm.isBeefGenomLinkage();

    // ウィンドウ・オープン時にウェブ用のレイアウトが表示されるのを防ぐため、空でプリンタレイアウトを表示
    printScreenLayout({}, () => { });

    this.farmCondition = $stateParams.farmCondition || '';

    this.callerFuncs = {
      refreshGrid: this.notifyUiGridRowChange.bind(this)
    };

    // バックグラウンドクリックにより設定ボードを閉じる
    setTimeout(() => {
      document.getElementsByClassName('uHeading__settings')[0].addEventListener('click', (e) => e.stopPropagation());
      document.body.addEventListener('click', (e) => {
        this.$timeout(() => this.toggleSettingsBoard(false));
      });
    });

    this.init();
  }

  init() {
    this.listName = '肉牛評価一覧';

    this.master = {};
    this.columns = [];
    this.conditions = [];

    this.cows = [];
    this.rawCows = [];
    this.currentColumnSort = null;

    this.showFarmCondition = false;
    this.showSettingsBoard = false;
    this.filterConditions = [];

    this.showCowSelect = true;

    // 牛個体詳細ボードの表示用
    this.currentCow = null;

    this.componentFuncs = {}; // 一括操作用のイベントバインディングに使用する

    return this.run();
  }

  setCallbackParam() {
    this.callbackParam = {
      state: 'genomic-evaluation-list',
      name: this.listName,
      params: {
        customlistId: this.customlistId,
        config_type: this.master.configType,
      },
      bqNotes: `genom evaluation_list config_type = ${this.master.configType}`
    };
  }

  run() {
    this.loading = true;

    return this.CustomlistRunner.run(
      this.customlistId,
      DateUtil.today()
    ).then((res) => {
      this.setConfig(res.data.config);

      this.cows = res.data.cows;
      this.rawCows = [].concat(this.cows);
      this.loading = false;

      this.setUiGrid();

      this.setCallbackParam();
    });
  }

  setConfig(config) {
    this.master = new CustomlistMaster(config.master);
    this.columns = config.columns;
    this.needLabelAction = this.columns.some((c) => c.columnId === 'cow_labels');

    if (this.master.showEntryColumn) {
      const column = {columnId: 'dummy', actualCaption: '記入欄', width: 200};
      this.columns.push(column);
    }

    this.tableStyle = this.tableWidth();

    const columnMap = {};
    this.columns.forEach((c) => {
      columnMap[c.columnId] = c;
    });
    this.columnMap = columnMap;

    this.conditions = config.conditions;

    this.showCowBoard = true;
  }

  setUiGrid() {
    const showCowNoLink = true;
    const generator = new UiGridColumnDefGenerator(this.columnMap, showCowNoLink);
    const columnDefs = generator.generate(this.columns);

    this.filter();
    this.uiGrid = {
      appScopeProvider: this,
      enableSorting: false,
      columnDefs: columnDefs,
      rowTemplate: generator.generateRowTemplate(),
      data: this.cows,
      virtualizationThreshold: this.getVirtualizationThreshold(),
      columnVirtualizationThreshold: this.columns.length + 100,
      onRegisterApi: (gridApi) => {
        this.gridApi = gridApi;
      },
    };

    this.showUiGrid = true;
    this.showPrintGrid = false;

    setTimeout(() => this.windowState.resize());
  }

  getVirtualizationThreshold() {
    return this.cows.length <= this.ROWS_TO_VIRTUALIZE ? this.cows.length + 100 : this.ROW_VIRTUALIZATION_THRESHOLD;
  }

  notifyUiGridRowChange() {
    this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
  }

  filter() {
    let cows = [].concat(this.rawCows);

    this.cows = new CustomlistFilter().filter(cows, this.filterConditions);

    if (this.uiGrid) {
      this.uiGrid.data = this.cows;
      this.uiGrid.virtualizationThreshold = this.getVirtualizationThreshold();
    }
  }

  onClickFilter() {
    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'menu/customlist/runtime/filter-dialog.html',
      controller: 'FilterDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      resolve: {
        params: () => {
          return {
            columns: this.columns,
            currentConditions: this.filterConditions
          };
        }
      }
    });

    modalInstance.result.then((result) => {
      this.filterConditions = result;
      this.filter();
    });
  }

  onClickColumnHeader(columnId, gridCol) {
    if (CustomlistSort.isUnsortable(columnId)) return;

    const column = this.columnMap[columnId];
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        CustomlistSort.sort(this.cows, column, false);
        this.currentColumnSort = {column, isAscending: false};
      } else {
        this.currentColumnSort = null;
        this.filter();
      }
    } else {
      CustomlistSort.sort(this.cows, column, true);
      this.currentColumnSort = {column, isAscending: true};
    }
  }

  // 旧実装用の関数
  generateHeaderClassLegacy(classes) {
    let thClass = '';
    classes.forEach((c) => thClass += ` ${c}`);
    return thClass;
  }

  /**
   * 列ヘッダーのCSSを生成します。
   * 項目毎の静的なCSSクラスと列のソート状態を表すCSSクラスを結合します。
   *
   * @param {String} customlist_column_definition.css_class
   * @param {Object} gridCol UI Gridの列オブジェクト
   * @return {String}
   */
  generateHeaderClass(cssClass, gridCol) {
    let sortedClass = '';
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        sortedClass = 'ui-grid-sorted-asc';
      } else {
        sortedClass = 'ui-grid-sorted-desc';
      }
    }

    return `${cssClass} ${sortedClass}`;
  }

  clearInput(key) {
    this[key] = null;
  }

  onClickConfig() {
    this.$modal.open({
      templateUrl: 'menu/customlist/config/edit.html',
      controller: 'CustomlistEditController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        params: () => {
          return {
            title: 'リスト設定編集',
            customlistId: this.customlistId,
            farmType: this.farmType,
            isDepositor: false,
            disableLegacyGrid: true
          };
        }
      }
    }).result.then(() => {
      this.cows = []; // CustomlistRuntimeDirectiveを再描画させるための措置
      this.filterConditions = [];
      this.loading = true;
      this.run();
    });
  }

  tableWidth() {
    const totalColumnWidth = this.columns.reduce((sum, current) => {
      return sum + current.width;
    }, 0);
    let width = totalColumnWidth + 70;
    if (this.master.showEntryColumn) {
      width += 200;
    }

    return {'max-width': `${width}px`, width: '100%'};
  }

  columnStyle(columnId) {
    const definition = this.columnMap[columnId];
    if (!definition) {
      return null;
    }

    const width = `${definition.width}px`;
    return {width: width, minWidth: width};
  }

  cowBoardState() {
    return this.currentCow ? 'cow-board-opened' : '';
  }

  /* 以下は全画面での共通処理 */

  cowNumber() {
    return this.cows.length;
  }

  goToDetails(cowId, alertLabel = null) {
    const cowIds = this.cows.map((cow) => cow.cowId);
    this.callbackParam['cowIds'] = cowIds;

    this.callbackParam['viewState'] = 'GENOM';
    this.$state.go('cowDetail', {cowId: cowId, caller: this.callbackParam});
  }

  updateCurrentCow(cow = null, colRenderIndex) {
    if (colRenderIndex === 0) return;

    if (cow) {
      this.SideBoardService.setState('opened', false);
    }

    return this.currentCow = cow;
  }

  classCell(cowId) {
    if (!this.currentCow) return;
    if (cowId !== this.currentCow.cowId) return;

    return 'ui-grid-row-cow-board-opened';
  }

  onClickExport() {
    const cows = angular.copy(this.cows).map((cow) => {
      cow.cow_uid = cow.origin.cow_uid;
      return cow;
    });
    const matrixData = Customlist.formatAsExport(cows, this.columns);
    const csv = matrixData.join('\n');
    this.FileUtilService.exportAsCsv(csv, `${this.listName}.csv`);
  }

  /**
   * 印刷時に旧グリッドにすげ替えるための苦肉の策
   */
  printGimmick() {
    if (this.showUiGrid) {
      this.printGimmickOn = true;
      this.showUiGrid = false;
      this.showPrintGrid = true;
    }
  }

  onClickPrint() {
    this.ViewStateAPI.create(`print-in-customlist-${this.master.configType}`, 'genomEvaluationList');

    this.$rootScope.isPrinting = true; // 「class: print」を付与するための状態変更
    this.isPrinting = true;

    this.$timeout(() => {
      print(); // 豆知識: 印刷中は描画・画面操作がブロックされる

      if (this.printGimmickOn) {
        this.showUiGrid = true;
        this.showPrintGrid = false;
      }
      this.$rootScope.isPrinting = false;
      this.isPrinting = false;
    });
  }

  toggleSettingsBoard(isOpen) {
    if (typeof isOpen === 'boolean') {
      this.showSettingsBoard = isOpen;
    } else {
      this.showSettingsBoard = !this.showSettingsBoard;
    }

    if (this.showSettingsBoard === true) {
      this.overwriteOpen = true;
    }
  }

  mouseleaveSettingsBoard() {
    this.overwriteOpen = false;
  }

  classSettingsButton() {
    if (this.overwriteOpen === true) {
      return 'uHeading__settingsButton--overwriteOpen';
    }

    return this.showSettingsBoard ? 'uHeading__settingsButton--open' : 'uHeading__settingsButton';
  }

  classSettingsBoard() {
    return this.showSettingsBoard ? 'uHeading__settingsBoard--open' : 'uHeading__settingsBoard';
  }

  openSettingGebvIndexModal() {
    this.toggleSettingsBoard(false);

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/genom/setting/gebv-index/index.html',
      controller: 'GenomSettingGebvIndexController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
        }
      }
    }).result.then(() => {
      this.cows = [];
      this.filterConditions = [];
      this.loading = true;
      this.run();
    });
  }
}

app.controller('GenomEvaluationListController', GenomEvaluationListController);
