class AlertDysstasiaConfigEditController {
  constructor(
    $modalInstance,
    AlertDysstasiaDetectionThresholdAPI,
    SelectionDialog,
    blockUI,
    params
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.AlertDysstasiaDetectionThresholdAPI = AlertDysstasiaDetectionThresholdAPI;
    this.SelectionDialog = SelectionDialog;
    this.blockUI = blockUI;
    this.thresholds = params.thresholds;
    this.thresholdOptions = params.thresholdOptions;
    this.isSaving = false;
    this.invalid = false;
    this.error = {};
  }

  cancel() {
    this.$modalInstance.close(false);
  }

  save() {
    if (this.isSaving || this.invalid) return;

    this.isSaving = true;
    this.blockUI.start('更新中');

    const params = {
      thresholds: AlertDysstasiaDetectionThreshold.createRequestParams(this.thresholds),
      thresholdOptions: AlertDysstasiaDetectionThresholdOption.createRequestParams(this.thresholdOptions)
    };

    this.AlertDysstasiaDetectionThresholdAPI.create(params).then((res) => {
      this.$modalInstance.close(true);
    }).catch((err) => {
      this.error = err.data.messages;
    }).finally(() => {
      this.blockUI.stop();
      this.isSaving = false;
    });
  }

  addThresholdOption() {
    this.thresholdOptions.push(
      AlertDysstasiaDetectionThresholdOption.createViewModel(this.thresholdOptions)
    );
    this.validate();
  }

  removeThresholdOption(index) {
    this.thresholdOptions.splice(index, 1);

    const error = {};
    this.thresholdOptions.forEach((o, i) => {
      const sortOrder = o.sortOrder;
      o.sortOrder = i + 1;
      if (this.error[sortOrder]) error[o.sortOrder] = this.error[sortOrder];
    });
    this.error = error;
    this.validate();
  }

  openBreedModal(index) {
    const thresholdOption = this.thresholdOptions[index];
    const selectedBreeds = thresholdOption.matchingCondition.breed;

    const excludeBreeds = this.thresholdOptions
      .reduce((acc, cur) => acc.concat(cur.matchingCondition.breed), [])
      .filter((b) => !selectedBreeds.includes(b));

    this.SelectionDialog.execute('breed', '品種', selectedBreeds, excludeBreeds).then((result) => {
      if (!result) return;
      thresholdOption.matchingCondition.breed = result;
      this.validate();
    });
  }

  validate() {
    this.invalid = this.thresholds.some((t) => {
      return !AlertDysstasiaDetectionThreshold.validate(t);
    });

    if (this.invalid) return;
    if (!this.thresholdOptions.length) return;

    this.invalid = this.thresholdOptions.some((o) => {
      return !AlertDysstasiaDetectionThresholdOption.validate(o);
    });
  }
}

app.controller('AlertDysstasiaConfigEditController', AlertDysstasiaConfigEditController);
