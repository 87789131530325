class MasterMedicineEditController {
  constructor(
    $modalInstance,
    params,
    MasterMedicineAPI,
    SelectionService,
    blockUI,
    $timeout,
    SessionCache
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.MasterMedicineAPI = MasterMedicineAPI;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.SelectionService = SelectionService;
    this.treatmentMedicineMethods = Medicine.METHODS;
    this.farm = SessionCache.farm();

    this.initialize(params);
  }

  initialize(params) {
    this.title = params.title;
    this.model = params.model || new Medicine({}, this.farm.farmType());
    this.viewMode = new ViewMode(params.viewMode);
    this.isLegacy = params.isLegacy;

    this.invalid = this.viewMode.isCreate() ? true : false;
    this.errorMessage = this.isLegacy ? null : {};

    this.SelectionService.index()
      .then((res) => {
        this.selectionItems = res;
      })
      .catch((error) => {
        console.error('Error getSelections', error);
      });
  }

  validate() {
    this.invalid = true;
    this.errorMessage = this.isLegacy ? null : {};

    const model = this.model;

    if (this.isLegacy) {
      if (!model.name) return;
    } else {
      if (model.milkWashoutPeriod && !StringUtil.isDecimal(model.milkWashoutPeriod)) {
        this.errorMessage.milkWashoutPeriod = '半角数字で入力してください';
      }

      if (model.beefWashoutPeriod && !StringUtil.isDecimal(model.beefWashoutPeriod)) {
        this.errorMessage.beefWashoutPeriod = '半角数字で入力してください';
      }

      if (!model.name || Object.keys(this.errorMessage).length > 0) return;
    }

    this.invalid = false;
  }

  cancel() {
    this.$modalInstance.dismiss();
  }

  save() {
    if (this.viewMode.isCreate()) {
      this.create();
    } else if (this.viewMode.isUpdate()) {
      this.update();
    } else if (this.viewMode.isDelete()) {
      this.delete();
    }
  }

  create() {
    const procedure = this.MasterMedicineAPI.create(this.model);
    this.callApi(procedure);
  }

  update() {
    const procedure = this.MasterMedicineAPI.update(this.model);
    this.callApi(procedure);
  }

  delete() {
    const procedure = this.MasterMedicineAPI.delete(this.model.id);
    this.callApi(procedure);
  }

  callApi(procedure) {
    this.blockUI.start('更新中');
    this.errorMessage = this.isLegacy ? null : {};

    procedure
      .then(() => {
        this.blockUI.done(() => {
          this.blockUI.start(`薬品の${this.viewMode.label}が完了しました`);
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close(this.model);
      })
      .catch((res) => {
        const items = res.data.messages;

        if (this.isLegacy) {
          this.errorMessage = ErrorUtil.formatErrorMessage(items);
        } else {
          items.forEach((item) => {
            if (item.field) {
              this.errorMessage[item.field] = item.message;
            } else {
              this.errorMessage.message = item.message;
            }
          });
        }

        this.blockUI.stop();
      });
  }

  isEditing() {
    return this.viewMode.isCreate() || this.viewMode.isUpdate();
  }
}

app.controller('MasterMedicineEditController', MasterMedicineEditController);
