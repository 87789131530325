class CowNoSelectDialogController {
  constructor(
    $modalInstance,
    params
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;

    this.init(params);
  }

  init(params) {
    this.cows = params.cows;
    this.invalid = true;
  }

  onClickCow(cowId) {
    this.cows.map((cow) => {
      cow.selected = cow.cowId === cowId;
      return cow;
    });

    this.invalid = false;
  }

  ok() {
    const selectedCowId = this.cows.find((cow) => {
      return cow.selected;
    }).cowId;

    this.$modalInstance.close(selectedCowId);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  classFormGridItem(selected) {
    return selected ? 'uModal__formGridItem--selected' : 'uModal__formGridItem';
  }
}

app.controller('CowNoSelectDialogController', CowNoSelectDialogController);
