function IndividualNumberValidator() {
  'ngInject';
  const REQUIRED_LENGTH = 10;
  return {
    require: 'ngModel',
    link: function(scope, element, attr, model) {

      model.$validators.individualNumberRequiredLength = function(modelValue, viewValue) {
        if (!viewValue) return true;
        return (String(viewValue).length === REQUIRED_LENGTH);
      };
      // TODO : 将来的にチェックディジットを追加する。
    }
  };
}

app.directive('individualNumberValidator', IndividualNumberValidator);
