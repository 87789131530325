class GroupFarmAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/group/farm');
  }
}

app.service('GroupFarmAPI', GroupFarmAPI);
