class MasterHormoneProgramEditController {
  constructor(
    $modalInstance,
    params,
    MasterHormoneProgramAPI,
    UtilService,
    blockUI,
    $timeout
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.MasterHormoneProgramAPI = MasterHormoneProgramAPI;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.UtilService = UtilService;

    this.initialize(params);
  }

  initialize(params) {
    this.title = params.title;
    this.viewMode = new ViewMode(params.viewMode);
    this.model = params.model || new HormoneProgram({});
    this.errorMessage = {};
    this.used = params.used;
    this.formTextBodyClass = this.used ? 'uModal__formTextBody--disabled' : 'uModal__formTextBody';

    let arrayLength;

    if (this.viewMode.isCreate()) {
      this.invalid = true;
      arrayLength = 3;
    } else {
      this.invalid = false;

      let model = angular.copy(this.model);
      let array = [3];

      Object.entries(model).forEach(([key, value]) => {
        if (/^hormoneCode[0-9]{1,2}$/.test(key) && value) {
          array.push(value);
        }
      });

      arrayLength = Math.max.apply(null, array);
    }

    this.hormones = [...Array(arrayLength).keys()].map((i) => {
      const n = i + 1;
      const nameKey = `hormoneName${n}`;
      const periodKey = `hormonePeriod${n}`;

      return {
        name: this.model[nameKey],
        period: this.model[periodKey]
      };
    });
  }

  onAddHormone() {
    const length = this.hormones.length;

    if (length === 10) return;

    const n = length + 1;
    const nameKey = `hormoneName${n}`;
    const periodKey = `hormonePeriod${n}`;

    this.hormones.push({
      name: this.model[nameKey],
      period: this.model[periodKey]
    });
  }

  onDeleteHormone() {
    const length = this.hormones.length;

    if (length === 3) return;

    const n = length - 1;
    const nameKey = `hormoneName${length}`;
    const periodKey = `hormonePeriod${length}`;

    this.model[nameKey] = '';
    this.model[periodKey] = '';
    this.hormones.splice(n, 1);
  }

  validate() {
    this.invalid = true;
    this.blank = false;
    this.errorMessage = {
      hormone: {}
    };

    this.hormones.forEach((h, i) => this.validateHormone(i));

    const isInvalidHormones = Object.keys(this.errorMessage.hormone).length > 0;

    if (!this.model.name || this.blank || isInvalidHormones) {
      this.invalid = true;
      return;
    }
    this.invalid = false;
  }

  validateHormone(index) {
    if (index > 9) return;

    const hormone = this.hormones[index];

    if (index === 0) {
      if (!hormone.name) {
        this.blank = true;
      }
    } else {
      const prevHormone = this.hormones[index - 1];

      if ((hormone.name || hormone.period) && !prevHormone.name) {
        if (!this.errorMessage.hormone[index]) {
          this.errorMessage.hormone[index] = {};
        }
        this.errorMessage.hormone[index].name = '上から順に入力してください';
      }
    }

    if (hormone.period && !StringUtil.isDigit(hormone.period)) {
      if (!this.errorMessage.hormone[index]) {
        this.errorMessage.hormone[index] = {};
      }
      this.errorMessage.hormone[index].period = '半角数字で入力してください';
    }

    if (this.UtilService.xor(hormone.name, hormone.period)) {
      this.blank = true;
    }
  }

  cancel() {
    this.$modalInstance.dismiss();
  }

  save() {
    this.hormones.forEach(({name, period}, i) => {
      const n = i + 1;
      const nameKey = `hormoneName${n}`;
      const periodKey = `hormonePeriod${n}`;

      this.model[nameKey] = name;
      this.model[periodKey] = period;
    });

    if (this.viewMode.isCreate()) {
      this.create();
    } else if (this.viewMode.isUpdate()) {
      this.update();
    } else if (this.viewMode.isDelete()) {
      this.delete();
    }
  }

  create() {
    const procedure = this.MasterHormoneProgramAPI.create(this.model);
    this.callApi(procedure);
  }

  update() {
    const procedure = this.MasterHormoneProgramAPI.update(this.model);
    this.callApi(procedure);
  }

  delete() {
    const procedure = this.MasterHormoneProgramAPI.delete(this.model.id);
    this.callApi(procedure);
  }

  callApi(procedure) {
    this.blockUI.start('更新中');
    this.errorMessage = {};

    procedure
      .then(() => {
        this.blockUI.done(() => {
          this.blockUI.start(`ホルモンプログラムの${this.viewMode.label}が完了しました`);
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close(this.model);
      })
      .catch((res) => {
        const items = res.data.messages;
        items.forEach((item) => {
          if (item.field) {
            this.errorMessage[item.field] = item.message;
          } else {
            this.errorMessage.message = item.message;
          }
        });
        this.blockUI.stop();
      });
  }

  isEditing() {
    return this.viewMode.isCreate() || this.viewMode.isUpdate();
  }
}

app.controller('MasterHormoneProgramEditController', MasterHormoneProgramEditController);
