function reproductionHistoryItemDaysPrimary($timeout) {
  return {
    restrict: 'A',
    scope: {
      targetId: '='
    },
    link: (scope, element, attrs) => {
      $timeout(() => {
        let height;

        if (scope.targetId) {
          const afterFertilizationEvent = angular.element(`.uModal__historyItem--primary[data-event-id="${scope.targetId}"]`);

          if (afterFertilizationEvent.offset()) {
            height = element.offset().top - afterFertilizationEvent.offset().top -
              afterFertilizationEvent.height() - 31;
          }
        } else {
          height = element.offset().top - element.parents('.uModal__historyItems').offset().top - 10;
        }

        if (height) {
          element.css({
            visibility: 'visible',
            height: height
          });
        }
      });
    }
  };
}

app.directive('reproductionHistoryItemDaysPrimary', ['$timeout', reproductionHistoryItemDaysPrimary]);
