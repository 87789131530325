class ReproductionSummaryAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  show() {
    return this.APIService.get('/reproduction/summary');
  }
}

app.service('ReproductionSummaryAPI', ReproductionSummaryAPI);
