class SortOrderEditController {
  constructor(
    $modalInstance,
    blockUI,
    $timeout,
    uiGridConstants,
    params) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.blockUI = blockUI;
    this.$timeout = $timeout;
    this.uiGridConstants = uiGridConstants;

    this.title = params.title || '表示順設定';
    this.itemLabel = params.itemLabel;
    this.items = angular.copy(params.items);
    this.updateFunction = params.updateFunction;
    this.isLegacy = params.isLegacy;

    this.initialize();
  }

  initialize() {
    this.keyField = 'id';
    this.showGrid = false;
    this.invalid = false;

    this.setUiGrid();
    this.index();
  }

  index() {
    this.uiGrid.data = this.items;
    this.showGrid = true;
  }

  setUiGrid() {
    let columns = [
      {columnId: 'name', label: this.itemLabel}
    ];

    if (this.isLegacy) {
      columns.width = 400;
    }

    const options = {
      clickRowEvent: 'onClickRow',
      cellTemplates: {
        visible: `<div ng-bind="row.entity.visibleLabel" class="align-center"></div`
      }
    };
    const uiGrid = new UiGridGenerator().generate(this, columns, options);

    this.uiGrid = uiGrid;

    if (!this.isLegacy) {
      this.uiGrid.headerRowHeight = 40;
      this.uiGrid.rowHeight = 40;
      this.uiGrid.excessRows = this.items.length;
    }
  }

  onClickRow(row) {
    if (this.currentRow) {
      this.currentRow.selected = false;
    }
    this.currentRow = row;
    this.seletedItemKey = row.id;
    this.currentRow.selected = true;

    this.notifyUiGridRowChange();
  }

  notifyUiGridRowChange() {
    this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
  }

  update() {
    this.blockUI.start('更新中');

    const params = this.items.map((item, index) => {
      return {id: item.id, sortOrder: index + 1};
    });

    this.updateFunction(params)
      .then(() => {
        this.blockUI.done(() => {
          this.blockUI.start('表示順の変更が完了しました');
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close({executed: true});
      }, (res) => {
        this.blockUI.stop();
        this.errorMessage = ErrorUtil.formatErrorMessage(res.data.messages);
      });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('SortOrderEditController', SortOrderEditController);
