class CalvingSensorUnequipCandidatesModalController {
  constructor(
    $timeout,
    $modalInstance,
    params,
    blockUI,
    CowCalvingSensorHistoryAPI
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$modalInstance = $modalInstance;
    this.params = params;
    this.blockUI = blockUI;
    this.CowCalvingSensorHistoryAPI = CowCalvingSensorHistoryAPI;

    this.init();
  }

  init() {
    this.errorMessage = {};
    this.items = angular.copy(this.params.grid.data).map((item) => {
      item.startDateDisplay = DateUtil.toYYYYMMDD(item.startDate);
      item.endDate = DateUtil.toMSec(DateUtil.today());
      item.sensorNumber = CowCalvingSensorHistory.splitCalvingSensorNumber(item.calvingSensorNumber);

      return item;
    });
  }

  update() {
    this.errorMessage = this.items.reduce((acc, cur, index) => {
      if (cur.startDate > cur.endDate) {
        if (!acc.endDate) {
          acc.endDate = {};
        }

        acc.endDate[index + 1] = '解除日が適用日より前の日付になっています。';
      }

      return acc;
    }, {});

    if (Object.keys(this.errorMessage).length) return;

    const params = this.items.map((item) => {
      return {
        id: item.cowCalvingSensorHistoryId,
        endDate: item.endDate
      };
    });

    this.CowCalvingSensorHistoryAPI.bulkUnequip(params).then(() => {
      this.blockUI.done(() => {
        this.blockUI.start('分娩センサーの解除が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);
      });
      this.blockUI.stop();
      this.$modalInstance.close();
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  disabledUpdate() {
    return !this.items.every((item) => {
      return DateUtil.isValidDate(item.endDate) &&
        DateUtil.includedPermittedPeriod(item.endDate);
    });
  }

  classFormGridTitle(index) {
    if (index === 0) {
      return 'uModal__formGridTitle--width76';
    } else if (index === 1) {
      return 'uModal__formGridTitle--width136';
    } else if (index === 2) {
      return 'uModal__formGridTitle--width108';
    } else {
      return 'uModal__formGridTitle';
    }
  }
}

app.controller('CalvingSensorUnequipCandidatesModalController', CalvingSensorUnequipCandidatesModalController);
