class ApplicationInitialService {
  constructor(
    $rootScope,
    $location,
    $q,
    $interval,
    SessionCache,
    FarmService,
    AccountService,
    Dictionary,
    AlertMonitoringService,
    CustomlistAPI,
    UmPlatformPartnerApi
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$location = $location;
    this.$q = $q;
    this.$interval = $interval;
    this.SessionCache = SessionCache;
    this.FarmService = FarmService;
    this.AccountService = AccountService;
    this.Dictionary = Dictionary;
    this.AlertMonitoringService = AlertMonitoringService;
    this.CustomlistAPI = CustomlistAPI;
    this.UmPlatformPartnerApi = UmPlatformPartnerApi;
  }

  initialize() {
    this.umPlatformPartnerMonitoring(this.$interval, this.UmPlatformPartnerApi, this.SessionCache);

    return this.$q.all([
      this.FarmService.show(),
      this.AccountService.cachedAccount(), // 呼び出す関数は後々リネームする
      this.CustomlistAPI.system(),
      this.Dictionary.initialize(),
      this.alertMonitoringService()
    ]).then((res) => {
      const farm = new Farm(res[0]);
      this.SessionCache.store('farm', farm);

      const account = new Account(res[1]);

      this.SessionCache.store('account', account);

      const customlistSystemConfigs = res[2].data;
      this.SessionCache.store('customlistSystemConfigs', customlistSystemConfigs);

      this.$rootScope.$root.initialized = true;
    });
  }

  alertMonitoringService() {
    if (this.isPrintMode() || this.$rootScope.isMobile) return;
    return this.AlertMonitoringService.run();
  }

  umPlatformPartnerMonitoring($interval, api, sessionCache) {
    if (this.isPrintMode() || this.$rootScope.isMobile) return;
    const instance = new UmPlatformPartnerMonitoring($interval, api, sessionCache);
    return instance.run();
  }

  isPrintMode() {
    const params = this.$location.search();
    return params.mode === '1';
  }
}

app.service('ApplicationInitialService', ApplicationInitialService);
