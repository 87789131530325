class MobileStandardModal {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  open(message, option = {}) {
    return this.$modal.open({
      templateUrl: 'mobile/components/standard-modal/index.html',
      controller: 'MobileStandardModalController as ctrl',
      resolve: {
        message: () => message,
        option: () => option
      }
    }).result;
  }
}
app.service('MobileStandardModal', MobileStandardModal);
