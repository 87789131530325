/**
 * 表示用血統ボックス
 *
 * [example]
 * <pedigree-box
 *   farm="farm"
 *   cow="cow">
 * </pedigree-box>
 *
 * @param {Farm} farm model/farm.js
 * @param {Object} cow 対象牛
 */
class PedigreeBoxController {
  constructor(CowService, UtilService) {
    'ngInject';

    this.CowService = CowService;
    this.UtilService = UtilService;
  }

  formattedCowUid(cowUid) {
    return this.CowService.formatCowUid(cowUid);
  }

  nullIfZeroOrUnder(number) {
    return this.UtilService.nullIfZeroOrUnder(number);
  }

  shouldShowMotherCowLink() {
    return this.cow && this.cow.motherCowId ? true : false;
  }
}

function pedigreeBox() {
  return {
    templateUrl: 'cow/components/templates/pedigree-box.html',
    controller: PedigreeBoxController,
    bindings: {
      farm: '<',
      cow: '='
    }
  };
}

app.component('pedigreeBox', pedigreeBox());
