class CustomlistRuntimeController {
  constructor(
    $modal,
    $rootScope,
    $state,
    $stateParams,
    $window,
    CustomlistRunner,
    FileUtilService,
    $timeout,
    uiGridConstants,
    ViewStateAPI,
    SideBoardService,
    SessionCache,
    AlertConfigService,
    DepositAlertConfigAPI
  ) {
    'ngInject';
    this.$modal = $modal;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$window = $window;
    this.$timeout = $timeout;
    this.CustomlistRunner = CustomlistRunner;
    this.FileUtilService = FileUtilService;
    this.uiGridConstants = uiGridConstants;
    this.ViewStateAPI = ViewStateAPI;
    this.SideBoardService = SideBoardService;
    this.AlertConfigService = AlertConfigService;
    this.DepositAlertConfigAPI = DepositAlertConfigAPI;

    this.windowState = new WindowState();

    this.ROWS_TO_VIRTUALIZE = 100;
    this.ROW_VIRTUALIZATION_THRESHOLD = 200;

    const farm = SessionCache.farm();
    this.farmType = farm.farmType();
    this.isDepositor = farm.isDepositor();
    this.isDhiLinkage = farm.isDhiLinkage();
    this.useAlertCalving = farm.useAlertCalving();
    this.isBeefGenomLinkage = farm.isBeefGenomLinkage();
    this.useCalfManagement = farm.useCalfManagement();

    const params = getPathParams() || {};

    if (params.targetDate) {
      this.targetDate = new Date(Number(params.targetDate)).getTime();
    } else {
      this.targetDate = DateUtil.today().getTime();
    }

    this.isPrinting = false;
    this.printable = params.mode ? params.mode === '1' : false;
    this.loading = true;

    // ウィンドウ・オープン時にウェブ用のレイアウトが表示されるのを防ぐため、空でプリンタレイアウトを表示
    printScreenLayout({}, () => { });

    const cofigType = $stateParams.config_type || params.config_type || CustomlistConfigType.USER_DEFINED;
    this.configType = new CustomlistConfigType(cofigType);

    this.farmCondition = $stateParams.farmCondition || '';

    this.cowGroupId = $stateParams.cow_group_id || params.cow_group_id;
    this.fatteningStageId = $stateParams.fattening_stage_id || params.fattening_stage_id;
    this.currentColumnSort = $stateParams.currentColumnSort || null;
    this.filterConditions = $stateParams.filterConditions || [];

    // Note: 新牛群メニューの印刷時に牛群名称をパラメーターで渡すために酷いことになっています
    let listName = $stateParams.name;
    if ((this.cowGroupId > 0 || this.fatteningStageId > 0) && !listName) {
      listName = decodeURI(params.name);
    }

    this.showCowBoard = false;

    this.callerFuncs = {
      refreshGrid: this.notifyUiGridRowChange.bind(this)
    };

    this.groupHeadageDetails = $stateParams.groupHeadageDetails;

    if (this.groupHeadageDetails) {
      this.farmCondition = listName;
    }

    this.init($stateParams.customlistId, listName, params);
  }

  init(customlistId, listName, params) {
    this.customlistId = customlistId;
    this.listName = listName;
    this.printParams = params;

    this.customlist = new Customlist();

    this.master = {};
    this.columns = [];
    this.conditions = [];

    this.cows = [];
    this.rawCows = [];

    this.showDateCondition = this.configType.needDateCondition();
    this.showGroupCondition = false;
    this.showFarmCondition = false;
    this.showAlertTypeCondition = false;
    this.alertTypeCondition = '';

    this.sortedGridCol = null; // ソート対象でない他の列のソート状態を解除するための苦肉の策

    this.showCowSelect = true;

    // 牛個体詳細ボードの表示用
    this.currentCow = null;

    this.setCallbackParam();

    this.componentFuncs = {};

    return this.loadAlertConfig().then(() => this.run());
  }

  loadAlertConfig() {
    const promise = this.isDepositor ? this.DepositAlertConfigAPI.index() : this.AlertConfigService.show();

    return promise.then((res) => {
      if (this.isDepositor) {
        this.depositAlertHistory = new DepositAlertHistory(res.data);
        this.showAcuteIllness = this.depositAlertHistory.isShowAcuteIllness();
      } else {
        this.showAcuteIllness = res.data.showAcuteIllness;
      }
    });
  }

  setCallbackParam() {
    this.callbackParam = {
      state: 'customlist',
      name: this.listName,
      params: {
        name: this.listName,
        customlistId: this.customlistId
      },
      bqNotes: `customlist config_type = ${this.configType.value}`
    };

    if (this.configType.isSystemConfig()) {
      this.callbackParam.params['config_type'] = this.configType.value;
      if (this.cowGroupId) {
        this.callbackParam.params['cow_group_id'] = this.cowGroupId;
      }
      if (this.fatteningStageId) {
        this.callbackParam.params['fattening_stage_id'] = this.fatteningStageId;
      }
    }
  }

  run() {
    const option = {
      cowGroupId: this.cowGroupId,
      fatteningStageId: this.fatteningStageId,
    };

    return this.CustomlistRunner.run(
      this.customlistId,
      this.targetDate,
      option
    ).then((res) => {
      this.setConfig(res.data.config);

      this.cows = res.data.cows;
      this.rawCows = [].concat(this.cows);
      this.loading = false;
      this.setFilterCondition(this.rawCows);

      // FIXME: 表示対象が1000頭を超えると描画遅すぎるので暫定措置
      this.temporaryFilterAsapFix();

      if (this.printable) {
        this.setPrintConfig();
      } else {
        this.filter();

        if (this.master.useFixedColumnWidth) {
          this.setUiGrid();
        } else {
          this.setLegacyGrid();
        }

        if (this.currentColumnSort) {
          const {column, isAscending} = this.currentColumnSort;
          CustomlistSort.sort(this.cows, column, isAscending);
        }
      }
    });
  }

  setConfig(config) {
    this.master = config.master;
    this.columns = config.columns;
    this.needLabelAction = this.columns.some((c) => c.columnId === 'cow_labels');
    this.showAlertTypeCondition = this.columns.some((c) => c.columnId === 'alert_cow_view_alert_type');

    if (this.showAlertTypeCondition) {
      this.alertTypeConditions = this.generateAlertTypeOptions(
        this.farmType, this.showAcuteIllness, this.useCalfManagement
      );
    }

    if (!this.printable) {
      if (this.master.useFixedColumnWidth && this.master.showEntryColumn) {
        const column = {columnId: 'dummy', actualCaption: '記入欄', width: 200};
        this.columns.push(column);
      }
    }

    this.tableStyle = this.tableWidth();

    const columnMap = {};
    this.columns.forEach((c) => {
      columnMap[c.columnId] = c;
    });
    this.columnMap = columnMap;

    this.conditions = config.conditions;
    if (!this.listName) {
      this.listName = this.master.name;
    }

    if (this.configType.isDepositConfig() || (this.isDepositor && this.master.showDepositedCow)) {
      this.isDepositorMode = true;
    } else {
      this.isDepositorMode = false;
    }

    // isDepositorModeが初期化されるまでは、テンプレート中のcow-boardをng-ifで非表示にする
    this.showCowBoard = true;
  }

  generateAlertTypeOptions(farmType, showAcuteIllness, useCalfManagement) {
    const alertTypes = [
      {key: '', label: '全て表示', farmType: 'ALL'},
      {key: 'acute_illness', label: '急性(改)', farmType: 'ALL', showAcuteIllness: true},
      {key: 'illness', label: '急性疾病', farmType: 'ALL'},
      {key: 'chronic_illness', label: '慢性疾病', farmType: 'ALL'},
      {key: 'milk_low', label: '乳量', farmType: 'MILK'},
      {key: 'feed_low', label: '採食', farmType: 'ALL'},
      {key: 'strong_move_low', label: '動態(強)', farmType: 'ALL', useCalfManagement: true},
      {key: 'lie_high', label: '横臥', farmType: 'ALL'},
      {key: 'water_low', label: '飲水', farmType: 'ALL'},
    ];
    return alertTypes.filter((type) => {
      if (type.farmType === 'ALL' || type.farmType === farmType) {
        if (type.showAcuteIllness) {
          return showAcuteIllness;
        }
        if (type.useCalfManagement) {
          return useCalfManagement;
        }
        return true;
      }
      return false;
    });
  }

  setUiGrid() {
    const showCowNoLink = !this.isDepositorMode;
    const generator = new UiGridColumnDefGenerator(this.columnMap, showCowNoLink);
    const columnDefs = generator.generate(this.columns);

    this.uiGrid = {
      appScopeProvider: this,
      enableSorting: false,
      columnDefs: columnDefs,
      rowTemplate: generator.generateRowTemplate(),
      data: this.cows,
      virtualizationThreshold: this.getVirtualizationThreshold(),
      columnVirtualizationThreshold: this.columns.length + 100,
      onRegisterApi: (gridApi) => {
        this.gridApi = gridApi;
      }
    };

    this.showUiGrid = true;
    this.showLegacyGrid = false;

    setTimeout(() => this.windowState.resize());
  }

  getVirtualizationThreshold() {
    return this.cows.length <= this.ROWS_TO_VIRTUALIZE ? this.cows.length + 100 : this.ROW_VIRTUALIZATION_THRESHOLD;
  }

  setLegacyGrid() {
    this.columns.forEach((c) => {
      if (CustomlistSort.isUnsortable(c.columnId)) return;
      c.sortedClass = 'sorted-none';
    });
    this.showLegacyGrid = true;
    this.showUiGrid = false;
  }

  notifyUiGridRowChange() {
    this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
  }

  temporaryFilterAsapFix() {
    if (this.configType.isDepositConfig()) {
      if (this.farmConditions && this.farmConditions.length >= 2) {
        if (!this.farmCondition) {
          this.farmCondition = this.farmConditions[1].key;
        }
        this.onChangeFarmCondition();
      }
    }
  }

  setPrintConfig() {
    document.title = this.listName;
    this.showLegacyGrid = true;

    const params = this.printParams;

    // グループ絞込条件に基づいて表示するレコードをフィルターする
    if (params.farmCondition) {
      this.farmCondition = decodeURI(params.farmCondition);
    }
    if (params.groupCondition) {
      this.groupCondition = decodeURI(params.groupCondition);
    }
    this.filter();

    // filter関数実行後でないと対象牧場の牛群絞込条件を生成できないので残念な実装になっています
    if (params.farmCondition) {
      const conditions = CustomlistRuntimeCondition.generateFilterCondition(this.cows, 'cow_group_name', false);
      if (conditions) {
        this.groupConditions = conditions;
        this.showGroupCondition = true;
      }
    }

    if (params.sortColumnId) {
      const definition = this.columnMap[params.sortColumnId];
      const isAscending = params.isAscending === 'true';
      CustomlistSort.sort(this.cows, definition, isAscending);
    }

    this.adjustOnPrint(params);
    this.adjustLongText();
    let interval = setInterval(() => {
      executePrinter(params, interval, null);
    }, 100);
  }

  setFilterCondition(cows) {
    if (this.isDepositorMode) {
      const conditions = CustomlistRuntimeCondition.generateFilterCondition(cows, 'farm_name');
      if (conditions) {
        this.farmConditions = conditions;
        this.showFarmCondition = !this.groupHeadageDetails;
        this.showCowSelect = false;
        return;
      }
    }

    this.setGroupCondition(cows);
  }

  setGroupCondition(cows) {
    const conditions = CustomlistRuntimeCondition.generateFilterCondition(cows, 'cow_group_name');
    if (conditions) {
      this.groupConditions = conditions;
      this.groupCondition = '';
      this.showGroupCondition = true;
    }
  }

  filter() {
    let cows = [].concat(this.rawCows);
    if (this.farmCondition) {
      cows = cows.filter((cow) => {
        return cow.farm_name === this.farmCondition;
      });
    }
    if (this.groupCondition) {
      cows = cows.filter((cow) => {
        return cow.cow_group_name === this.groupCondition;
      });
    }
    if (this.alertTypeCondition) {
      cows = cows.filter((cow) => {
        if (this.alertTypeCondition === 'chronic_illness' &&
          (cow.acute_illness || cow.illness) &&
          cow.chronic_illness) {
          return false;
        }

        return cow[this.alertTypeCondition];
      });
    }
    this.cows = new CustomlistFilter().filter(cows, this.filterConditions);

    if (this.uiGrid) {
      this.uiGrid.data = this.cows;
      this.uiGrid.virtualizationThreshold = this.getVirtualizationThreshold();
    }
  }

  onChangeFarmCondition() {
    if (!this.farmCondition) {
      this.groupCondition = '';
      this.showGroupCondition = false;
      this.showCowSelect = false;
    } else {
      this.showCowSelect = true;
    }
    this.callbackParam.params.farmCondition = this.farmCondition;

    this.filter();
    if (this.currentColumnSort) {
      CustomlistSort.sort(this.cows, this.currentColumnSort.column, this.currentColumnSort.isAscending);
    }

    if (this.farmCondition) {
      const conditions = CustomlistRuntimeCondition.generateFilterCondition(this.cows, 'cow_group_name');
      if (conditions) {
        this.groupConditions = conditions;
        this.showGroupCondition = true;
      } else {
        this.groupConditions = null;
        this.showGroupCondition = false;
      }
      this.groupCondition = '';
    }
  }

  onChangeGroupCondition() {
    this.filter();
    if (this.currentColumnSort) {
      CustomlistSort.sort(this.cows, this.currentColumnSort.column, this.currentColumnSort.isAscending);
    }
  }

  onChangAlertTypeCondition() {
    this.filter();
    if (this.currentColumnSort) {
      CustomlistSort.sort(this.cows, this.currentColumnSort.column, this.currentColumnSort.isAscending);
    }
  }

  onClickFilter() {
    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'menu/customlist/runtime/filter-dialog.html',
      controller: 'FilterDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      resolve: {
        params: () => {
          return {
            columns: this.columns,
            currentConditions: this.filterConditions
          };
        }
      }
    });

    modalInstance.result.then((result) => {
      this.filterConditions = result;
      this.filter();
    });
  }

  adjustLongText() {
    const bodyWidth = $('body').width();
    const longText = $('.long-text');
    const margin = (bodyWidth - $('table').width()) / longText.length;
    longText.each(() => {
      const val = ($(this)[0].width + margin) / bodyWidth;
      $(this).css('width', (val * 100 - 1) + '%');
    });
  }

  clearInput(key) {
    this[key] = null;
  }

  onClickConfig() {
    this.$modal.open({
      templateUrl: 'menu/customlist/config/edit.html',
      controller: 'CustomlistEditController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        params: () => {
          return {
            title: '個別リストの編集',
            customlistId: this.customlistId,
            farmType: this.farmType,
            isDepositor: this.isDepositor,
            isDhiLinkage: this.isDhiLinkage,
            useAlertCalving: this.useAlertCalving,
            isBeefGenomLinkage: this.isBeefGenomLinkage,
          };
        }
      }
    }).result.then(() => {
      this.cows = []; // CustomlistRuntimeDirectiveを再描画させるための措置
      this.filterConditions = [];
      this.loading = true;
      this.run();
    });
  }

  onClickColumnHeader(columnId, gridCol) {
    if (CustomlistSort.isUnsortable(columnId)) return;

    const column = this.columnMap[columnId];
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        CustomlistSort.sort(this.cows, column, false);
        this.currentColumnSort = {column, isAscending: false};
      } else {
        this.currentColumnSort = null;
        this.filter();
      }
    } else {
      CustomlistSort.sort(this.cows, column, true);
      this.currentColumnSort = {column, isAscending: true};
    }
  }

  // 旧実装用のイベント
  onClickColumnHeaderLegacy(column) {
    if (CustomlistSort.isUnsortable(column.columnId)) return;

    this.columns.forEach((c) => {
      if (c.columnId !== column.columnId) {
        if (CustomlistSort.isUnsortable(c.columnId)) return;
        c.sortedClass = 'sorted-none';
      }
    });

    let isAscending = true;
    switch (column.sortedClass) {
    case 'sorted-none':
      column.sortedClass = 'sorted-asc';
      break;
    case 'sorted-asc':
      column.sortedClass = 'sorted-desc';
      isAscending = false;
      break;
    case 'sorted-desc':
      column.sortedClass = 'sorted-none';
      this.currentColumnSort = null;
      return this.filter();
    default:
      column.sortedClass = 'sorted-asc';
    }

    const definition = this.columnMap[column.columnId];
    CustomlistSort.sort(this.cows, definition, isAscending);
    this.currentColumnSort = {column: definition, isAscending: isAscending};
  }

  // 旧実装用の関数
  generateHeaderClassLegacy(classes) {
    let thClass = '';
    classes.forEach((c) => thClass += ` ${c}`);
    return thClass;
  }

  /**
   * 列ヘッダーのCSSを生成します。
   * 項目毎の静的なCSSクラスと列のソート状態を表すCSSクラスを結合します。
   *
   * @param {String} customlist_column_definition.css_class
   * @param {Object} gridCol UI Gridの列オブジェクト
   * @return {String}
   */
  generateHeaderClass(cssClass, gridCol) {
    let sortedClass = '';
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        sortedClass = 'ui-grid-sorted-asc';
      } else {
        sortedClass = 'ui-grid-sorted-desc';
      }
    }

    return `${cssClass} ${sortedClass}`;
  }

  tableWidth() {
    if (!this.master.useFixedColumnWidth || this.printable) {
      return {width: '100%'};
    }

    const totalColumnWidth = this.columns.reduce((sum, current) => {
      return sum + current.width;
    }, 0);
    let width = totalColumnWidth + 70;
    if (this.master.showEntryColumn) {
      width += 200;
    }

    return {'max-width': `${width}px`, width: '100%'};
  }

  columnStyle(columnId) {
    if (!this.master.useFixedColumnWidth) {
      return null;
    }

    const definition = this.columnMap[columnId];
    if (!definition) {
      return null;
    }

    const width = `${definition.width}px`;
    return {width: width, minWidth: width};
  }

  cowBoardState() {
    return this.currentCow ? 'cow-board-opened' : '';
  }

  /* 以下は全画面での共通処理 */

  cowNumber() {
    return this.cows.length + '頭';
  }

  dateLabel() {
    return DateUtil.toYYYYMMDD(
      this.targetDate ?
        Number(this.targetDate) :
        DateUtil.today().getTime()
    );
  }

  farmConditionLabel() {
    return this.farmCondition ?
      this.farmCondition :
      '全ての牧場';
  }

  groupConditionLabel() {
    return this.groupCondition ?
      this.groupCondition :
      '全ての牛群';
  }

  goToDetails(cowId) {
    const cowIds = this.cows.map((cow) => cow.cowId);
    this.callbackParam['cowIds'] = cowIds;
    this.callbackParam.params.currentColumnSort = this.currentColumnSort;
    this.callbackParam.params.filterConditions = this.filterConditions;
    this.$state.go('cowDetail', {cowId: cowId, caller: this.callbackParam});
  }

  updateCurrentCow(cow = null, colRenderIndex) {
    if (colRenderIndex === 0) return;

    if (!cow) {
      this.currentCow = null;
      return;
    }

    this.SideBoardService.setState('opened', false);

    if (this.isDepositorMode) {
      this.currentCow = {cowId: cow.cowId, qwert: cow.qwert};
    } else {
      this.currentCow = {cowId: cow.cowId};
    }
  }

  classCell(cowId) {
    if (!this.currentCow) return;
    if (cowId !== this.currentCow.cowId) return;

    return 'ui-grid-row-cow-board-opened';
  }

  onClickExport() {
    const cows = angular.copy(this.cows).map((cow) => {
      cow.cow_uid = cow.origin.cow_uid;
      return cow;
    });
    const matrixData = Customlist.formatAsExport(cows, this.columns);
    const csv = matrixData.join('\n');
    this.FileUtilService.exportAsCsv(csv, `${this.listName}.csv`);
  }

  onClickExportAsExcel() {
    const cows = angular.copy(this.cows).map((cow) => {
      cow.cow_uid = cow.origin.cow_uid;
      return cow;
    });
    const matrixData = Customlist.formatAsExport(cows, this.columns);
    this.FileUtilService.exportAsExcel(matrixData, `${this.listName}.xlsx`);
  }

  printWorkList() {
    this.ViewStateAPI.create(`print-in-customlist-${this.configType.value}`, 'customlist');

    if (this.filterConditions.length > 0) {
      this.directPrint();
      return;
    }

    const params = {
      groupCondition: this.groupCondition || '',
      targetDate: this.targetDate,
      path: '#/customlist/runtime/' + this.customlistId
    };

    params['config_type'] = this.configType.value;

    if (this.cowGroupId) {
      params['cow_group_id'] = this.cowGroupId;
      params['name'] = encodeURI(this.listName);
    }
    if (this.fatteningStageId) {
      params['fattening_stage_id'] = this.fatteningStageId;
      params['name'] = encodeURI(this.listName);
    }
    if (this.farmCondition) {
      params['farmCondition'] = this.farmCondition;
    }
    if (this.currentColumnSort) {
      params['sortColumnId'] = this.currentColumnSort.column.columnId;
      params['isAscending'] = this.currentColumnSort.isAscending;
    }

    windowOpenWorkList('', params);
  }

  /**
   * 印刷時に旧グリッドにすげ替えるための苦肉の策
   */
  printGimmick() {
    if (this.filterConditions.length > 0) {
      if (this.master.useFixedColumnWidth) {
        this.printGimmickOn = true;
        this.showLegacyGrid = true;
      }
    }
  }

  directPrint() {
    this.$rootScope.isPrinting = this.isPrinting = true;
    this.$timeout(() => {
      print(); // 豆知識: 印刷中は描画・画面操作がブロックされる

      if (this.printGimmickOn) {
        this.printGimmickOn = false;
        this.showLegacyGrid = false;
      }
      this.$rootScope.isPrinting = false;
      this.isPrinting = false;
    });
  }

  shouldShowToolBar() {
    return !this.isPrinting;
  }

  shouldShowPrintToolBar() {
    return this.isPrinting;
  }

  adjustOnPrint(params) {
    printScreenLayout(params, (args) => {
    });
  }
}

app.controller('CustomlistRuntimeController', CustomlistRuntimeController);
