class GenomLinkageAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  linkage() {
    return this.APIService.get('/external/genom/linkage');
  }
}

app.service('GenomLinkageAPI', GenomLinkageAPI);
