class temporaryGroupViewChartController {
  constructor(
    $q,
    $state,
    $timeout,
    blockUI,
    CowGroupAPI,
    CustomlistAPI,
    TgvGroupConfigAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.CowGroupAPI = CowGroupAPI;
    this.CustomlistAPI = CustomlistAPI;
    this.TgvGroupConfigAPI = TgvGroupConfigAPI;

    this.init();
  }

  $onChanges(changes) {
    if (changes.cowGroupId) {
      this.generateCowGroup().then(() => {
        this.generateCows().then(() => {
          this.generateChartData();
        });
      });
    }
  }

  init() {
    this.colors = {
      feedMin: '#074f07',
      moveMin: '#31a062',
      ruminationStandingMin: '#ff8c00',
      ruminationLyingMin: '#ffd700',
      standMin: '#dcdcdc',
      lieMin: '#a9a9a9'
    };
    this.names = {
      feedMin: '採食',
      moveMin: '動態',
      ruminationStandingMin: '起立(反芻)',
      ruminationLyingMin: '横臥(反芻)',
      standMin: '起立(非活動)',
      lieMin: '横臥(非活動)'
    };
  }

  generateCowGroup() {
    return this.$q.all([
      this.CowGroupAPI.show(this.cowGroupId),
      this.CustomlistAPI.cowGroups()
    ]).then((res) => {
      const group = res[0].data;

      const customlists = {};
      res[1].data.forEach((customlist) => {
        customlists[customlist.configType] = customlist;
      });

      const setCustomListId = (group, customlist) => {
        group.customlistId = customlist.customlistId;
      };

      if (group.calfGroup) {
        setCustomListId(group, customlists['group_calf']);
      } else if (group.reproductionGroup) {
        setCustomListId(group, customlists['group_reproduction']);
      } else if (group.cullingGroup) {
        setCustomListId(group, customlists['group_culling']);
      } else if (group.milkingGroup) {
        setCustomListId(group, customlists['group_milking']);
      } else if (group.dryGroup) {
        setCustomListId(group, customlists['group_dry']);
      } else if (group.fatteningGroup) {
        setCustomListId(group, customlists['group_fattening']);
      } else {
        setCustomListId(group, customlists['group_reproduction']);
      }

      this.cowGroup = group;
    });
  }

  generateCows() {
    const customlistId = this.cowGroup.customlistId;
    const date = DateUtil.today().getTime();
    const option = {
      cowGroupId: this.cowGroupId,
    };

    return this.CustomlistAPI.run(customlistId, date, option).then((res) => {
      this.cows = res.data.map((cow) => {
        const {
          cow_id: cowId,
          cow_no: cowNo,
          cow_uid: cowUid,
          pen
        } = cow.cow;

        return {
          cowId,
          cowNo: cowNo || '-',
          cowUid,
          pen: pen ? pen.replace(/[.]/g, '_dot_') : '-'
        };
      });
    });
  }

  generateChartData() {
    this.charts = [];
    this.selectedPens = {};

    this.blockUI.start('データ取得中');

    this.TgvGroupConfigAPI.groupCurrentActivity(this.cowGroupId).then((res) => {
      this.blockUI.stop();

      const rawData = res.data;

      const activities = this.cows.map((cow) => {
        const findActivity = rawData.find((item) => {
          return item.cowId === cow.cowId;
        }) || {};

        cow.feedMin = findActivity.feedMin || 0;
        cow.moveMin = findActivity.moveMin || 0;
        cow.ruminationStandingMin = findActivity.ruminationStandingMin || 0;
        cow.ruminationLyingMin = findActivity.ruminationLyingMin || 0;
        cow.standMin = findActivity.standMin || 0;
        cow.lieMin = findActivity.lieMin || 0;

        return cow;
      });

      let columns = [
        ['feedMin'],
        ['moveMin'],
        ['ruminationStandingMin'],
        ['ruminationLyingMin'],
        ['standMin'],
        ['lieMin']
      ];
      let data = {};
      let cowNos = {};
      let cowIds = [];
      let pens = [];

      activities.forEach((activity) => {
        const pen = activity.pen;

        if (!data[pen]) {
          data[pen] = {
            columns: angular.copy(columns)
          };
        }

        if (!cowNos[pen]) {
          cowNos[pen] = [];
        }

        data[pen].columns[0].push(activity.feedMin);
        data[pen].columns[1].push(activity.moveMin);
        data[pen].columns[2].push(activity.ruminationStandingMin);
        data[pen].columns[3].push(activity.ruminationLyingMin);
        data[pen].columns[4].push(activity.standMin);
        data[pen].columns[5].push(activity.lieMin);
        cowNos[pen].push(activity.cowNo);
        cowIds.push(activity.cowId);
        pens.push(activity.pen);
      });

      this.cowIds = cowIds;
      this.pens = [...new Set(pens)];

      this.pens.forEach((pen) => {
        const filteredActivities = activities.filter((activity) => {
          return activity.pen === pen;
        });

        this.drawChart(cowNos[pen], data[pen].columns, pen, filteredActivities);
      });

      this.legends = Object.entries(this.colors).map((item) => {
        return {
          id: item[0],
          color: item[1],
          label: this.names[item[0]],
          opacity: 1
        };
      });
    });
  }

  drawChart(cowNos, columns, pen, filteredActivities) {
    const height = filteredActivities.length * 30;

    this.$timeout(() => {
      this.charts.push(
        c3.generate({
          bindto: `.temporary-group-view-chart-${pen}`,
          data: {
            type: 'bar',
            columns: columns,
            groups: [
              ['feedMin', 'moveMin', 'ruminationStandingMin', 'ruminationLyingMin', 'standMin', 'lieMin']
            ],
            colors: this.colors,
            names: this.names,
            order: null,
          },
          size: {
            width: 600,
            height: height
          },
          padding: {
            top: 0,
            bottom: 10,
            left: 70,
            right: 0
          },
          bar: {
            width: 15
          },
          legend: {
            show: false
          },
          axis: {
            rotated: true,
            x: {
              type: 'category',
              categories: cowNos,
              tick: {
                culling: false
              }
            },
            y: {
              show: false
            }
          }
        })
      );
    });

    this.$timeout(() => {
      d3.selectAll('.temporary-group-view-chart-body .c3-event-rect').on('click', (_, index) => {
        this.onClickData(index);
      });
    }, 100);
  }

  decideId(id) {
    return `temporary-group-view-chart-${id}`;
  }

  displayPen(pen) {
    return pen.replace(/_dot_/g, '.');
  }

  onClickData(index) {
    const cowId = this.cows[index].cowId;

    this.goToDetail(cowId);
  }

  onClickLegend(id) {
    this.charts.forEach((chart) => {
      chart.toggle(id);
      chart.focus();
    });

    this.legends.map((legend) => {
      if (this.charts[0].internal.hiddenTargetIds.includes(legend.id)) {
        legend.opacity = 0.3;
      } else {
        legend.opacity = 1;
      }

      return legend;
    });

    this.$timeout(() => {
      d3.selectAll('.temporary-group-view-chart-body .c3-event-rect').on('click', (_, index) => {
        this.onClickData(index);
      });
    }, 100);
  }

  onMouseOverLegend(id) {
    if (this.charts[0].internal.hiddenTargetIds.includes(id)) return;

    this.charts.forEach((chart) => {
      chart.focus(id);
    });

    this.legends.filter((legend) => {
      return legend.id !== id;
    }).map((legend) => {
      legend.opacity = 0.3;

      return legend;
    });
  }

  onMouseOutLegend() {
    this.charts.forEach((chart) => {
      chart.focus();
    });

    this.legends.map((legend) => {
      if (this.charts[0].internal.hiddenTargetIds.includes(legend.id)) {
        legend.opacity = 0.3;
      } else {
        legend.opacity = 1;
      }

      return legend;
    });
  }

  opacityLegend(opacity) {
    return {
      opacity: opacity
    };
  }

  colorLegend(color) {
    return {
      background: color
    };
  }

  goToDetail(cowId) {
    const caller = {
      name: `${this.groupName} 活動量グラフと牛房一覧`,
      cowIds: this.cowIds,
      state: 'temporary-group-view-activity',
      params: {
        groupName: this.groupName,
        cowGroupId: this.cowGroupId
      }
    };

    this.$state.go('cowDetail', {
      cowId,
      caller
    });
  }
}

function temporaryGroupViewChart() {
  return {
    restrict: 'E',
    templateUrl: 'temporary-group-view/activity/chart.html',
    controller: temporaryGroupViewChartController,
    controllerAs: 'ctrl',
    scope: true,
    bindToController: {
      groupName: '<',
      cowGroupId: '<',
      selectedPens: '=',
      cows: '=',
    }
  };
}

app.directive('temporaryGroupViewChart', temporaryGroupViewChart);
