app.directive('formatCheck', function() {
  'ngInject';
  return {
    require: 'ngModel',
    priority: 1,
    link: (scope, element, attrs, ctrl) => {
      const param = attrs['formatCheck'];
      const checks = param.split(',');
      if (0 <= checks.indexOf('strictDate')) {
        ctrl.$validators.date = (modelValue, viewValue) => {
          if (!viewValue) return true;
          if (DateUtil.isValidDate(viewValue, ['YYYY/MM/DD', 'YYYY/M/D'])) {
            return DateUtil.includedPermittedPeriod(Number(modelValue));
          }
          return false;
        };
      }
      if (0 <= checks.indexOf('date')) {
        ctrl.$validators.date = function(modelValue, viewValue) {
          if (!viewValue) return true;
          if (DateUtil.isValidDate(viewValue, ['YYYY/MM/DD', 'YYYY/M/D'])) {
            return true;
          }
          return false;
        };
      }
      if (0 <= checks.indexOf('number')) {
        ctrl.$validators.number = function(modelValue, viewValue) {
          if (!viewValue) return true;
          let REGEX = /^\d+$/;
          if (REGEX.test(viewValue)) {
            return true;
          }
          return false;
        };
      }
      if (0 <= checks.indexOf('decimal')) {
        ctrl.$validators.decimal = function(modelValue, viewValue) {
          if (!viewValue) return true;
          let REGEX = /(^\d+$|^\d+\.\d+$)/;
          if (REGEX.test(viewValue)) {
            return true;
          }
          return false;
        };
      }
      if (0 <= checks.indexOf('alphaNum')) {
        ctrl.$validators.alphaNum = function(modelValue, viewValue) {
          if (!viewValue) return true;
          let REGEX = /^[a-zA-Z0-9]+$/;
          if (REGEX.test(viewValue)) {
            return true;
          }
          return false;
        };
      }
    }
  };
});
