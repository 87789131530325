// eslint-disable-next-line no-unused-vars
class Activity {
  static selectableMinimumDate(date, isHourlySummary, defaultDate = null) {
    const minDate = defaultDate || DateUtil.umLaunchDate();
    if (isHourlySummary) {
      const oneYearAgo = DateUtil.nextDay(DateUtil.addYears(date, -1));
      if (DateUtil.isSameOrAfterDay(minDate, oneYearAgo)) {
        return minDate;
      } else {
        return oneYearAgo;
      }
    } else {
      return minDate;
    }
  }
}
