class FarmHistoryController {
  constructor(
    params,
    blockUI,
    appConfig,
    FarmHistoryService,
    DateTimeUtilFactory,
    $modalInstance,
    StandardDialog
  ) {
    'ngInject';

    this.params = params;
    this.blockUI = blockUI;
    this.appConfig = appConfig;
    this.FarmHistoryService = FarmHistoryService;
    this.DateTimeUtilFactory = DateTimeUtilFactory;
    this.$modalInstance = $modalInstance;
    this.StandardDialog = StandardDialog;

    this.init();
  }

  init() {
    this.viewMode = new ViewMode(this.params.viewMode.mode);
    this.heading = `牧場日誌の${this.viewMode.label}`;
    this.formData = {};
    this.hours = DateUtil.hours;
    this.minutes = DateUtil.minutes;

    if (this.params.historyId) {
      this.FarmHistoryService.getFarmHistory(this.params.historyId).then((res) => {
        this.formData = this.DateTimeUtilFactory.parseClientDateForHistory(res.data);

        const occurredAt = this.formData.occurredAt;

        this.formData.selectedHour = DateUtil.format(occurredAt, 'HH');
        this.formData.selectedMinute = DateUtil.format(occurredAt, 'mm');
      }).catch((err) => console.error(err));
    } else {
      const now = moment(this.DateTimeUtilFactory.parseClientDate(new Date()));

      this.formData.occurredAt = DateUtil.startOfDay(now);
      this.formData.selectedHour = DateUtil.format(now, 'HH');
      this.formData.selectedMinute = DateUtil.format(DateUtil.alignMinute(now), 'mm');
    }
  }

  changeOccurredAt() {
    this.invalid = !(DateUtil.isValidDate(this.formData.occurredAt) &&
      DateUtil.includedPermittedPeriod(this.formData.occurredAt));
  }

  save() {
    if (this.viewMode.isCreate()) {
      this.create();
    } else if (this.viewMode.isUpdate()) {
      this.update();
    }
  }

  create() {
    this.blockUI.start('登録中');
    this.modifyFormData();
    this.formData.entryStaffName = this.appConfig.staff.name;
    this.formData.lastEditStaffName = this.appConfig.staff.name;

    this.FarmHistoryService.enterFarmHistory(this.formData).then((result) => {
      this.blockUI.stop();
      this.$modalInstance.close();
    }).catch((err) => {
      console.error(err);
      this.blockUI.stop();

      this.StandardDialog.showMessage({
        title: '牧場日誌の登録エラー',
        text1: '牧場日誌の登録でエラーが発生しました。'
      });
    });
  }

  update() {
    this.blockUI.start('更新中');
    this.modifyFormData();
    this.formData.lastEditStaffName = this.appConfig.staff.name;
    this.formData.occurredAt = this.DateTimeUtilFactory.parseRegionUnixTime(this.formData.occurredAt);
    this.FarmHistoryService.updateFarmHistory(this.formData).then((result) => {
      this.blockUI.stop();
      this.$modalInstance.close();
    }).catch((err) => {
      console.error(err);
      this.blockUI.stop();

      this.StandardDialog.showMessage({
        title: '牧場日誌の登録エラー',
        text1: '牧場日誌の登録でエラーが発生しました。'
      });
    });
  }

  modifyFormData() {
    if (this.formData.selectedHour && this.formData.selectedMinute) {
      this.formData.occurredAt = DateUtil.addTimeToDate(
        this.formData.occurredAt,
        this.formData.selectedHour,
        this.formData.selectedMinute);
    }

    delete this.formData.selectedHour;
    delete this.formData.selectedMinute;
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('FarmHistoryController', FarmHistoryController);
