/**
 * @deprecated
 * ※この実装の修正および、新規の利用は禁止です
 */

/**
 * 選択した牛を保持する為のサービスです。
 * 選択した牛は永続的に保持されるので、
 * 各画面でクリアしたい場合は以下のように使用してください。
 * クリアしない場合は特に必要ありません。
 *
 * [example]
 * this.SelectCowService = SelectCowService.init();
 */
class SelectCowService {
  constructor() {
    'ngInject';
  }

  init() {
    this.cowIds = [];
    this.qwerts = [];
    return this;
  }

  size() {
    return this.cowIds.length;
  }

  selectRow(rows, filter = null) {
    const filteredRows = rows.filter((row) => {
      return filter ? filter(row) && row.selected : row.selected;
    });
    this.cowIds = filteredRows.map((row) => row.cowId);
    this.qwerts = filteredRows.map((row) => row.qwert);
  }

  selectAllRows(rows, val, filter = null) {
    rows.forEach((row) => row.selected = val);
    this.selectRow(rows, filter);
  }

  selectedQwert() {
    const selectedQwerts = this.qwerts.filter((elem, index, arr) => arr.indexOf(elem) === index);
    return selectedQwerts.length === 1 ? selectedQwerts[0] : null;
  }
}

app.service('SelectCowService', SelectCowService);
