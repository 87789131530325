class OperationUserService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/operation_user');
  }

  /**
   * 預託元牧場、通常の牧場は自牧場のオペレーションユーザーを取得する
   * 預託先牧場の場合は預託元牧場と自牧場のオペレーションユーザーを取得する
   */
  available() {
    return this.APIService.get('/operation_user/available');
  }

  update(names) {
    const operators = names.map((name) => {
      return {name: name};
    });
    const params = {operators: operators};
    return this.APIService.post('/operation_user', params);
  }
}

app.service('OperationUserService', OperationUserService);
