class HeatDetectionRateController {
  constructor(
    ReproductionConfigService,
    ReproductionPerformanceAPI,
    SessionCache
  ) {
    'ngInject';
    this.ReproductionConfigService = ReproductionConfigService;
    this.reproductionPerformanceAPI = ReproductionPerformanceAPI;

    this.endDate = DateUtil.today();
    this.startDate = DateUtil.addDays(
      DateUtil.addYears(this.endDate, -1).valueOf(),
      1
    );

    this.displayType = 'cyclic';

    this.birthNumber = 1;
    this.operator = 'GE';
    this.selectedBirthNumber = '+';

    this.dimCaption = SessionCache.farm().isMilk() ? '搾乳日数' : '分娩後日数';

    this.isReversal = false;
    this.isLoading = false;

    this.initialize();
  }

  initialize() {
    this.ReproductionConfigService.show().then((res) => {
      this.targetHeatDetectionRisk = res.data.targetHeatDetectionRisk;
      this.targetPregnancyRisk = res.data.targetPregnancyRisk;
    });
    this.refreshChart();
  }

  selectDisplayType(value) {
    if (this.displayType !== value) {
      this.displayType = value;
      this.refreshChart();
    }
  }

  selectBirthNumber(value) {
    if (this.selectedBirthNumber !== value) {
      switch (value) {
      case '+':
        this.birthNumber = 1;
        this.operator = 'GE';
        break;
      case '0':
        this.birthNumber = 0;
        this.operator = '';
        break;
      case '1':
        this.birthNumber = 1;
        this.operator = '';
        break;
      case '2+':
        this.birthNumber = 2;
        this.operator = 'GE';
        break;
      }
      this.selectedBirthNumber = value;
      this.refreshChart();
    }
  }

  rangeChange() {
    this.isReversal = false;
    this.isFutureEndDate = false;
    this.isOutOfRange = false;

    if (this.startDate < new Date(2000, 1, 1)) {
      return;
    }
    if (this.startDate > this.endDate) {
      this.isReversal = true;
      return;
    }

    if (DateUtil.isFutureDay(this.endDate)) {
      this.isFutureEndDate = true;
      return;
    }

    const diffMonths = DateUtil.diffMonths(this.startDate, this.endDate);
    const fiveYearMonths = 12 * 5;
    if (diffMonths >= fiveYearMonths) {
      this.isOutOfRange = true;
      return;
    }

    return this.refreshChart();
  }

  refreshChart() {
    this.isLoading = true;
    this.loadPerformances().then((res) => {
      this.performances = res.data;
      this.isLoading = false;
    });
  }

  loadPerformances() {
    if (this.displayType === 'cyclic') {
      return this.reproductionPerformanceAPI.cyclic(this.startDate, this.endDate, this.birthNumber, this.operator);
    } else if (this.displayType === 'dim') {
      return this.reproductionPerformanceAPI.dim(new Date(this.startDate), new Date(this.endDate));
    }
  }

  showLoading() {
    return this.isLoading;
  }

  showChart() {
    return !this.isLoading;
  }

  showCyclicChart() {
    return this.showChart() && this.displayType === 'cyclic';
  }

  showDimChart() {
    return this.showChart() && this.displayType === 'dim';
  }

  showBirthNumber() {
    return this.displayType === 'cyclic';
  }
}

function heatDetectionRateComponent() {
  'ngInject';
  return {
    templateUrl: 'reproduction-performance-report/contents/heat-detection-rate/heat-detection-rate.html',
    controller: HeatDetectionRateController,
  };
}

app.component('heatDetectionRate', heatDetectionRateComponent());
