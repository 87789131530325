class TemporaryGroupViewActivityController {
  constructor(
    $modal,
    $stateParams
  ) {
    'ngInject';

    this.$modal = $modal;
    this.$stateParams = $stateParams;

    this.init();
  }

  init() {
    this.tabMap = [{
      key: 'activity',
      label: '活動量'
    }, {
      key: 'pen',
      label: '牛房一覧'
    }];

    this.activeTab = 'activity';
    this.changeTab(this.activeTab);
    this.groupName = this.$stateParams.groupName;
    this.cowGroupId = this.$stateParams.cowGroupId;
    this.selectedPens = {};
    this.cows = [];
  }

  showTab() {
    return this.cowGroupId;
  }

  showChart() {
    return this.cowGroupId && this.activeTab === 'activity';
  }

  showPenList() {
    return this.cowGroupId && this.activeTab === 'pen';
  }

  onChangeCowGroupId(id) {
    this.cowGroupId = id;
  }

  changeTab(tab) {
    this.activeTab = tab;
  }

  onClickBulkEvent() {
    const pens = this.filteredSelected().map(([pen, selected]) => pen);

    const cowIds = this.cows.filter((cow) => {
      return pens.includes(cow.pen);
    }).map((cow) => cow.cowId);

    this.$modal.open({
      templateUrl: 'components/event-registration/event-selection.html',
      controller: 'EventSelectionController',
      controllerAs: 'ctrl',
      size: '1012',
      resolve: {
        cowIds: () => cowIds,
        cowType: () => null,
        isDepositor: () => null,
        qwert: () => 0,
        caller: () => null
      },
    });
  }

  classTab(key) {
    return this.activeTab === key ? 'contents__tabItem--active' : 'contents__tabItem';
  }

  selectedCount() {
    return this.filteredSelected().length;
  }

  filteredSelected() {
    return Object.entries(this.selectedPens).filter(([pen, selected]) => selected);
  }
}

app.controller('TemporaryGroupViewActivityController', TemporaryGroupViewActivityController);
