class TemporaryGroupViewTopController {
  constructor(
    TgvGroupConfigAPI
  ) {
    'ngInject';

    this.TgvGroupConfigAPI = TgvGroupConfigAPI;

    this.index();
  }

  index() {
    this.TgvGroupConfigAPI.groupSummary().then((res) => {
      this.groupSummary = res.data.summary.groupSummary;
    });
  }
}

function temporaryGroupViewTop() {
  return {
    templateUrl: 'temporary-group-view/top/index.html',
    controller: TemporaryGroupViewTopController,
    controllerAs: 'ctrl'
  };
}

app.component('temporaryGroupViewTop', temporaryGroupViewTop());
