class MobileConfirmModalController {
  constructor(
    $modalInstance,
    message
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.message = message;
  }

  onConfirmClick() {
    this.$modalInstance.close();
  }
}

app.controller('MobileConfirmModalController', MobileConfirmModalController);
