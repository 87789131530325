function mobileModalDialogComponent() {
  'ngInject';
  return {
    controller: 'MobileModalDialogController',
    controllerAs: 'ctrl',
    templateUrl: 'mobile/components/modal/dialog/index.html',
    transclude: true,
    bindings: {
      isOpen: '<',
      shouldShowPrevious: '<',
      title: '@',
      onPreviousClick: '&'
    }
  };
}
app.component('mobileModalDialog', mobileModalDialogComponent());
