class CowSelectionController {
  constructor(
    $state,
    $modalInstance,
    CowService,
    CowGroupAPI,
    Dictionary,
    DetailsStateFactory,
    EventRegistrationSelectionService
  ) {
    'ngInject';

    this.$state = $state;
    this.$modalInstance = $modalInstance;
    this.CowService = CowService;
    this.CowGroupAPI = CowGroupAPI;
    this.Dictionary = Dictionary;
    this.DetailsStateFactory = DetailsStateFactory;
    this.EventRegistrationSelectionService = EventRegistrationSelectionService;

    this.init();
  }

  init() {
    this.invalid = true;
    this.cowNoList = [];
    this.selected = {};
    this.placeholder = `${this.Dictionary.COW.COW_NO}を選択`;

    this.CowGroupAPI.index().then((res) => {
      this.cowGroupList = res.data.map((cowGroup) => {
        return {
          id: cowGroup.cowGroupId,
          name: cowGroup.cowGroupName
        };
      });
    });

    this.CowService.getCowNoList().then((res) => {
      this.cowNoList = res.data;
    }).catch((err) => console.error(err));
  }

  onChangeSelected() {
    this.selected = this.cowNoList.filter((cow) => {
      return cow.cowId === this.selected.cowId;
    }).map((cow) => {
      const splitCowUid = Cow.formatCowUid(cow.cowUid).split('-');
      const cowUid = `${splitCowUid[0]}<span class="uModal__formList4digits">${splitCowUid[1] || ''}</span>${splitCowUid[2] || ''}`;
      const cowGroupName = this.cowGroupList.filter((cowGroup) => {
        return cowGroup.id === cow.cowGroupId;
      })[0].name;

      return {
        cowId: cow.cowId,
        cowNo: cow.cowNo,
        cowUid: cowUid,
        state: cow.state,
        cowGroupName: cowGroupName
      };
    })[0];

    this.invalid = false;
  }

  clearSelected() {
    this.invalid = true;
    this.selected = {};
  }

  submit() {
    if (environment.reactCowEvent === 'true') {
      this.submitReact();
    } else {
      this.submitLegacy();
    }
  }

  submitReact() {
    const callerState = {state: 'cow_event_input'};
    this.EventRegistrationSelectionService.displayReactEventRegister(
      this.selected.cowId,
      callerState
    ).then(() => {
      this.DetailsStateFactory.set('displayTab', 'dataTab');

      const params = {
        cowId: this.selected.cowId,
      };

      this.$state.go('cowDetail', params);

      return this.$modalInstance.close();
    });
  }

  // FIXME: React化が完了したらこの実装は削除する
  submitLegacy() {
    this.DetailsStateFactory.set('displayTab', 'dataTab');

    const params = {
      cowId: this.selected.cowId,
      activeEntryEvent: true,
      caller: {
        state: 'cow_event_input'
      }
    };

    this.$state.go('cowDetail', params);

    return this.$modalInstance.close();
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('CowSelectionController', CowSelectionController);
