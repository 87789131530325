class DepositHomeController {
  constructor(
    $q,
    $state,
    SessionCache,
    DepositFarmAPI,
    DepoistAgentFarmAPI,
    CustomlistAPI,
    SortOrderEditDialog,
    $rootScope,
    $window
  ) {
    'ngInject';

    this.$q = $q;
    this.DepositFarmAPI = DepositFarmAPI;
    this.DepoistAgentFarmAPI = DepoistAgentFarmAPI;
    this.CustomlistAPI = CustomlistAPI;
    this.SortOrderEditDialog = SortOrderEditDialog;

    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$window = $window;

    this.farm = SessionCache.farm();

    if (SessionCache.farm().isGroupOrganizer()) {
      $state.go('group-home');
      return;
    }

    // 預託元牧場のホーム画面で牧場切替を実行した場合にはリロード後に「top」に遷移させる
    if (!SessionCache.farm().isDepositor()) {
      $state.go('top');
      return;
    }

    this.decimalFields = ['monthAge', 'fatteningDays'];
    this.priceFields = ['price'];

    this.index();
  }

  index() {
    this.$q.all([
      this.DepositFarmAPI.summary(),
      this.DepositFarmAPI.summaryDetail(),
      this.DepoistAgentFarmAPI.summary(),
      this.CustomlistAPI.system()
    ]).then((res) => {
      this.allFarm = this.format(res[0].data.summary.allFarms);
      this.detail = res[1].data.summary;
      this.fattening = this.detail.fattening.map((f) => {
        return {
          name: `${f.name}[${f.start}-${f.end}ヶ月]`,
          bull: f.bull,
          cow: f.cow
        };
      });
      this.agentFarms = res[2].data.summary.agentFarms.map((f) => {
        return this.format(f);
      });
      this.customlist = res[3].data.find((c) => c.configType === 'deposit');
    });
  }

  format(obj) {
    const result = {};
    Object.keys(obj).forEach((key) => {
      if (this.decimalFields.includes(key)) {
        result[key] = NumberUtil.format(obj[key], 1);
      } else if (this.priceFields.includes(key)) {
        const val = NumberUtil.format(obj[key]); // 小数点以下の切り捨て
        result[key] = NumberUtil.commaSeparated(val);
      } else {
        result[key] = obj[key];
      }
    });

    return result;
  }

  editSortOrder() {
    this.DepoistAgentFarmAPI.index().then((res) => {
      const items = res.data.map((e) => {
        return {id: e.id, name: e.farmName};
      });
      const updateFunction = this.DepoistAgentFarmAPI.updateSortOrder.bind(this.DepoistAgentFarmAPI);

      this.SortOrderEditDialog.open(
        'グループ牧場表示順設定',
        '牧場名',
        items,
        updateFunction
      ).then((result) => {
        if (result.executed) {
          this.index();
        }
      });
    });
  }
}

app.controller('DepositHomeController', DepositHomeController);
