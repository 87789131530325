class DepositEventHistoryContext {
  constructor(
    $q,
    DepoistAgentFarmService,
    DepositCowEventsService,
    DepositCowEventAPI,
    SelectionService,
    ReproductionConfigService
  ) {
    'ngInject';
    this.$q = $q;
    this.DepoistAgentFarmService = DepoistAgentFarmService;
    this.CowEventsService = DepositCowEventsService;
    this.CowEventAPI = DepositCowEventAPI;
    this.SelectionService = SelectionService;
    this.ReproductionConfigService = ReproductionConfigService;

    this.loadConfig = this.loadConfig.bind(this);
    this.search = this.search.bind(this);
  }

  loadConfig() {
    return this.$q.all([
      this.DepoistAgentFarmService.index(),
      this.CowEventsService.workerName(),
      this.SelectionService.index(),
      this.ReproductionConfigService.show()
    ]).then((res) => {
      return {
        ids: res[0].data,
        workers: res[1].data,
        selections: res[2],
        embryoRecoveryRank: res[3].data.embryoRecoveryRank
      };
    });
  }

  search(params) {
    return this.CowEventAPI.search(params);
  }
}

app.service('DepositEventHistoryContext', DepositEventHistoryContext);
