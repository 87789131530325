class CowUidController {
  constructor() {
    'ngInject';
  }

  $onChanges(changes) {
    if (!changes.cowUid.currentValue) return;

    this.splitCowUid = [
      this.cowUid.substr(0, 5),
      this.cowUid.substr(5, 4),
      this.cowUid.substr(9, 1)
    ];
  }
}

function cowUidComponent() {
  return {
    templateUrl: 'components/cow-uid/index.html',
    controller: CowUidController,
    controllerAs: 'ctrl',
    bindings: {
      cowUid: '<'
    }
  };
}

app.component('cowUidComponent', cowUidComponent());
