class SwitchFarmDialog {
  constructor(
    $modal,
    $state,
    $timeout,
    $window,
    blockUI,
    IsMobile,
    SessionStateService
  ) {
    'ngInject';

    this.$modal = $modal;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$window = $window;
    this.blockUI = blockUI;
    this.IsMobile = IsMobile;
    this.SessionStateService = SessionStateService;

    if (this.IsMobile) {
      this.modalConfig = {
        windowTemplateUrl: 'components/u-modal/window.html',
        templateUrl: 'components/dialog/switch-farm/mobile.html',
        controller: 'SwitchFarmDialogController',
        controllerAs: 'ctrl',
        backdrop: false
      };
    } else {
      this.modalConfig = {
        windowTemplateUrl: 'components/u-modal/window.html',
        templateUrl: 'components/dialog/switch-farm/index.html',
        controller: 'SwitchFarmDialogController',
        controllerAs: 'ctrl',
        backdrop: false
      };
    }
  }

  excuteInMobile() {
    this.$modal.open(this.modalConfig).result.then((result) => {
      this.blockUI.start('牧場の切替中');

      this.SessionStateService.switchFarm(result.id)
        .then((res) => {
          this.blockUI.stop();
          this.blockUI.start(`${result.farmName}に切替えました`);
          this.$timeout(() => {
            this.blockUI.stop();
            this.$state.go('mobile-top').then(() => {
              this.$window.location.reload();
            });
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          this.blockUI.stop();
          this.blockUI.start('牧場の切替に失敗しました');
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
    });
  }

  excute() {
    this.$modal.open(this.modalConfig).result.then((result) => {
      this.blockUI.start('牧場の切替中');

      this.SessionStateService.switchFarm(result.id)
        .then((res) => {
          this.blockUI.stop();
          this.blockUI.start(`${result.farmName}に切替えました`);
          this.$timeout(() => {
            this.blockUI.stop();
            this.$state.go('top');
            this.$window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          this.blockUI.stop();
          this.blockUI.start('牧場の切替に失敗しました');
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
    });
  }
}

app.service('SwitchFarmDialog', SwitchFarmDialog);
