function carcassReportTable(SessionCache) {
  'ngInject';
  //テーブルのセル幅を変更する場合、src/sass/page/carcass-report.scssのテーブル幅の指定も合わせて修正する必要
  const leftTableCols = [
    [
      {
        title: '',
        width: 180,
        colspan: 3
      }
    ],
    [
      {
        title: '',
        dynamicValue: 'tallyby',
        width: 60,
      },
      {
        title: '品種',
        width: 60,
      },
      {
        title: '性別',
        width: 60,
      },
    ]
  ];

  const rightItemDefinitions = [
    {
      title: '頭数',
      width: 60,
      key: 'headCount'
    },
    {
      title: '体重',
      width: 60,
      key: 'beforeSlaughterWeight'
    },
    {
      title: 'DG',
      width: 60,
      key: 'DG'
    },
    {
      title: '格付(肉質)',
      width: 60,
      key: 'meatGrade'
    },
    {
      title: '枝肉単価(円/kg)',
      width: 60,
      key: 'dressedCarcassUnitPrice'
    },
    {
      title: '枝肉重量',
      width: 60,
      key: 'totalDressedCarcassWeight'
    },
    {
      title: '枝肉販売金額(円)',
      width: 80,
      key: 'dressedCarcassSalesPrice'
    },
    {
      title: '枝肉重量(左)',
      width: 60,
      key: 'dressedCarcassWeightOfL'
    },
    {
      title: '枝肉重量(右)',
      width: 60,
      key: 'dressedCarcassWeightOfR'
    },
    {
      title: '胸最長筋面積(㎠)',
      width: 80,
      key: 'loinArea'
    },
    {
      title: 'バラの厚さ',
      width: 60,
      key: 'ribsThickness'
    },
    {
      title: '皮下脂肪の厚さ',
      width: 60,
      key: 'subcutaneousFat'
    },
    {
      title: '歩留基準値',
      width: 60,
      key: 'yieldBaseValue'
    },
    {
      title: 'BMS No.',
      width: 60,
      key: 'bmsNo'
    },
    {
      title: '脂肪交雑等級',
      width: 80,
      key: 'marblingGrade'
    },
    {
      title: 'BCS No.',
      width: 60,
      key: 'bcsNo'
    },
    {
      title: '光沢',
      width: 60,
      key: 'gloss'
    },
    {
      title: '等級',
      width: 60,
      key: 'bcsAndGlossGrade'
    },
    {
      title: '締まり',
      width: 60,
      key: 'tight'
    },
    {
      title: 'きめ',
      width: 60,
      key: 'texture'
    },
    {
      title: '等級',
      width: 60,
      key: 'tightAndTextureGrade'
    },
    {
      title: 'BFS No.',
      width: 60,
      key: 'bfsNo'
    },
    {
      title: '光沢と質',
      width: 60,
      key: 'fatLuster'
    },
    {
      title: '等級',
      width: 60,
      key: 'bfsAndFatLusterGrade'
    },
  ];

  let summaryBlock;
  let rightItems;
  if (SessionCache.account().isItemFilterApplied()) {
    summaryBlock = {
      title: '',
      colspan: 5,
      width: 300,
    };

    const filterItems = ['dressedCarcassUnitPrice', 'dressedCarcassSalesPrice'];
    rightItems = rightItemDefinitions.filter((item) => {
      const isFilterItem = filterItems.includes(item.key);
      return !isFilterItem;
    });
  } else {
    summaryBlock = {
      title: '',
      colspan: 7,
      width: 440,
    };
    rightItems = [].concat(rightItemDefinitions);
  }

  const rightTableCols = [
    [
      summaryBlock,
      {
        title: '歩留',
        colspan: 6,
        width: 380
      },
      {
        title: '肉質',
        colspan: 11,
        width: 680
      }
    ],
    rightItems
  ];

  function createTd(colspan, rowspan, width, content) {
    const td = document.createElement('td');
    if (colspan) td.setAttribute('colspan', colspan);
    if (rowspan) td.setAttribute('rowspan', rowspan);
    if (width) td.setAttribute('width', width);
    if (content) td.innerHTML = content;
    return td;
  }

  function generateLeftTableHead(scope) {
    const thead = document.createElement('thead');
    leftTableCols.forEach((elem) => {
      const tr = document.createElement('tr');
      elem.forEach((item) => {
        const title = (item.dynamicValue) ? Carcass.tallyByOptions[scope[item.dynamicValue]] : item.title;
        const colspan = item.colspan;
        const th = document.createElement('th');
        th.setAttribute('colspan', colspan);
        th.innerHTML = title;
        th.width = item.width;
        tr.insertAdjacentElement('beforeend', th);
      });
      thead.insertAdjacentElement('beforeend', tr);
    });
    return thead;
  }

  function generateRightTableHead() {
    const thead = document.createElement('thead');
    rightTableCols.forEach((elem) => {
      const tr = document.createElement('tr');
      elem.forEach((item) => {
        const colspan = item.colspan;
        const th = document.createElement('th');
        if (item.colspan) th.setAttribute('colspan', colspan);
        th.innerHTML = item.title;
        th.width = item.width;
        tr.insertAdjacentElement('beforeend', th);
      });
      thead.insertAdjacentElement('beforeend', tr);
    });
    return thead;
  }

  /**
  * 条件に合致する牛の抽出
  *
  * @param {Array} cows 全ての牛を要素とする配列
  * @param {Object} rules 抽出条件
  * @return {Array} 条件に合致する牛を要素とする配列
  */
  function extractCows(cows, rules) {
    const ruleKeys = Object.keys(rules);
    const extractedCows = cows.filter((cow) => {
      for (let i = 0; i < ruleKeys.length; i++) {
        if (rules[ruleKeys[i]] !== null && rules[ruleKeys[i]] !== cow[ruleKeys[i]]) return false;
      }
      return true;
    });
    return extractedCows;
  }

  /**
   * 合計の計算
   *
   * @param {Array} extractedCows 条件に基づいて抽出された牛を要素とする配列
   * @param {String} property 計算する項目の、cowオブジェクトにおけるプロパティ名
   * @param {Number} precision 合計値を四捨五入する基準となる桁数
   * e.g. 0 -> 小数点以下が四捨五入される
   * e.g. -1 -> 小数点第二位以下が四捨五入される
   * @return {Number || null} 合計値
   */
  function calcSumValue(extractedCows, property, precision) {
    let sum = 0, num = 0;
    extractedCows.forEach((cow) => {
      if (cow[property]) {
        sum += Number(cow[property]);
        num++;
      }
    });
    return (num > 0) ? UtilService.prototype.round(sum, precision) : null;
  }

  /**
   * 平均の計算
   *
   * @param {Array} extractedCows 条件に基づいて抽出された牛を要素とする配列
   * @param {String} property 計算する項目の、cowオブジェクトにおけるプロパティ名
   * @param {Number} precision 平均値を四捨五入する基準となる桁数
   * e.g. 0 -> 小数点以下が四捨五入される
   * e.g. -1 -> 小数点第二位以下が四捨五入される
   * @return {Number || null} 平均値
   */
  function calcMeanValue(extractedCows, property, precision) {
    let sum = 0, num = 0;
    extractedCows.forEach((cow) => {
      if (cow[property]) {
        sum += Number(cow[property]);
        num++;
      }
    });
    return (num > 0) ? UtilService.prototype.round(sum / num, precision) : null;
  }

  /**
   * 平均 DG計算
   * DG =（と畜前体重－購入時体重）/（出荷日－導入月日）
   *
   * @param {Array} extractedCows 条件に基づいて抽出された牛を要素とする配列
   * @return {Number} 平均 DC。小数点第三位以下は四捨五入
   */
  function calcMeanDG(extractedCows) {
    let sum = 0, num = 0;
    extractedCows.forEach((cow) => {
      // 必要な情報が揃っていなければ、計算不可
      if (!(cow.beforeSlaughterWeight &&
        cow.weightOnPurchase &&
        cow.occurredAt &&
        (cow.introduceDate || cow.birthday))) return;

      const diffDays = (cow.introduceDate) ?
        DateUtilService.prototype.diffDays(cow.introduceDate, cow.occurredAt) :
        DateUtilService.prototype.diffDays(cow.birthday, cow.occurredAt);

      // 分母が 0の場合は計算不可
      if (diffDays <= 0) return;

      const DG = (cow.beforeSlaughterWeight - cow.weightOnPurchase) / diffDays;
      sum += DG;
      num++;
    });
    const meanDG = (num > 0) ? (sum / num).toFixed(2) : null;
    return meanDG;
  }

  /**
   * 平均格付（肉質）計算
   *
   * @param {Array} extractedCows 条件に基づいて抽出された牛を要素とする配列
   * @return {Number} 平均格付（肉質）。小数点第二位以下は四捨五入
   */
  function calcMeanMeatGrade(extractedCows) {
    let sum = 0, num = 0;
    extractedCows.forEach((cow) => {
      if (!cow.grade) return;
      const meatGrade = Number(cow.grade.slice(-1));
      sum += meatGrade;
      num++;
    });
    const meanMeatGrade = (num > 0) ? UtilService.prototype.round(sum / num, -1) : null;
    return meanMeatGrade;
  }

  function generateLeftTableRow(colspan, content, thickBorderBottom) {
    const tr = document.createElement('tr');
    if (thickBorderBottom) tr.className += ' border-bottom-thick';
    const cols = leftTableCols[1];
    for (let i = 0; i < cols.length - colspan + 1; i++) {
      if (i === cols.length - colspan) {
        let width = 0;
        for (let j = i; j < cols.length; j++) {
          width += cols[j].width;
        }
        tr.insertAdjacentElement('beforeend', createTd(colspan, null, width, content));
        break;
      }
      tr.insertAdjacentElement('beforeend', createTd(null, null, cols[i].width, null));
    }
    return tr;
  }

  function generateRightTableRow(scope, extractedCows, rowType, thickBorderBottom) {
    const tr = document.createElement('tr');
    if (thickBorderBottom) tr.className += ' border-bottom-thick';
    //合計の行の場合
    if (rowType === 'sum') {
      for (let i = 0; i < rightTableCols[1].length; i++) {
        let val;
        switch (rightTableCols[1][i].key) {
        //頭数計算
        case 'headCount':
          val = extractedCows.length;
          break;
        //枝肉重量
        case 'totalDressedCarcassWeight':
          val = calcSumValue(extractedCows, rightTableCols[1][i].key, -1);
          break;
        //枝肉販売金額
        case 'dressedCarcassSalesPrice':
          val = calcSumValue(extractedCows, rightTableCols[1][i].key, -1);
          if (val) {
            val = Number(val).toLocaleString();
          }
          break;
        default:
          val = null;
        }
        val = (val !== null) ? val.toString() : '-';
        tr.insertAdjacentElement('beforeend', createTd(null, null, rightTableCols[1][i].width, val));
      }
      return tr;
    }
    //合計以外の行の場合
    for (let i = 0; i < rightTableCols[1].length; i++) {
      let val;
      switch (rightTableCols[1][i].key) {
      //頭数計算
      case 'headCount':
        val = extractedCows.length;
        break;
      // 平均DG計算
      case 'DG':
        val = calcMeanDG(extractedCows);
        break;
      // 平均格付（肉質）計算
      case 'meatGrade':
        val = calcMeanMeatGrade(extractedCows);
        break;
      case 'dressedCarcassUnitPrice': // 枝肉単価
      case 'dressedCarcassSalesPrice': // 枝肉販売金額
        val = calcMeanValue(extractedCows, rightTableCols[1][i].key, -1);
        if (val) {
          val = Number(val).toLocaleString();
        }
        break;
      default:
        val = calcMeanValue(extractedCows, rightTableCols[1][i].key, -1);
      }
      val = (val !== null) ? val.toString() : '-';
      tr.insertAdjacentElement('beforeend', createTd(null, null, rightTableCols[1][i].width, val));
    }
    return tr;
  }

  function generateTableBodies(scope) {
    const cows = JSON.parse(scope.cows);
    const tallyByList = JSON.parse(scope[scope.tallyby.toLowerCase()]).map((elem) => elem.label);
    const breed = JSON.parse(scope.breed).map((elem) => elem.label);
    const gender = Carcass.targetGender;
    const tbodyRight = document.createElement('tbody');
    const tbodyLeft = document.createElement('tbody');
    let rules = {}, extractedCows = [];

    for (let i = 0; i < tallyByList.length; i++) {
      rules[scope.tallyby] = tallyByList[i];
      extractedCows = extractCows(cows, rules);
      if (extractedCows.length === 0) {
        // 抽出基準をリセット
        if (i === tallyByList.length - 1) rules[scope.tallyby] = null;
        continue;
      }
      tbodyRight.insertAdjacentElement('beforeend', generateRightTableRow(scope, extractedCows));
      tbodyLeft.insertAdjacentElement('beforeend', generateLeftTableRow(3, tallyByList[i]));

      for (let j = 0; j < breed.length; j++) {
        rules.breed = breed[j];
        extractedCows = extractCows(cows, rules);
        if (extractedCows.length === 0) {
          // 抽出基準をリセット
          if (j === breed.length - 1) rules.breed = null;
          continue;
        }
        tbodyRight.insertAdjacentElement('beforeend', generateRightTableRow(scope, extractedCows));
        tbodyLeft.insertAdjacentElement('beforeend', generateLeftTableRow(2, breed[j]));

        for (let k = 0; k < gender.length; k++) {
          rules.gender = gender[k];
          extractedCows = extractCows(cows, rules);
          if (extractedCows.length === 0) {
            // 抽出基準をリセット
            if (k === gender.length - 1) rules.gender = null;
            continue;
          }
          tbodyRight.insertAdjacentElement('beforeend', generateRightTableRow(scope, extractedCows));
          tbodyLeft.insertAdjacentElement('beforeend', generateLeftTableRow(1, gender[k]));
          // 抽出基準をリセット
          if (k === gender.length - 1) rules.gender = null;
        }
        // 抽出基準をリセット
        if (j === breed.length - 1) rules.breed = null;
      }

      // 小合計
      extractedCows = extractCows(cows, rules);
      tbodyRight.insertAdjacentElement('beforeend', generateRightTableRow(scope, extractedCows, 'sum', true));
      tbodyLeft.insertAdjacentElement('beforeend', generateLeftTableRow(2, '合計', true));
      // 抽出基準をリセット
      if (i === tallyByList.length - 1) rules[scope.tallyby] = null;
    }

    extractedCows = extractCows(cows, rules);
    // 総平均
    tbodyRight.insertAdjacentElement('beforeend', generateRightTableRow(scope, extractedCows, null, true));
    tbodyLeft.insertAdjacentElement('beforeend', generateLeftTableRow(3, '総平均', true));
    // 総合計
    tbodyRight.insertAdjacentElement('beforeend', generateRightTableRow(scope, extractedCows, 'sum', true));
    tbodyLeft.insertAdjacentElement('beforeend', generateLeftTableRow(3, '総合計', true));

    return [tbodyLeft, tbodyRight];
  }

  return {
    restrict: 'E',
    scope: {
      cows: '@',
      tallyby: '@',
      breed: '@',
      fathername: '@',
      mothername: '@',
      maternalgrandfathername: '@',
      maternalgreatgrandfathername: '@',
      maternalfather4thname: '@',
      producingfarmname: '@',
      raisingfarmname: '@',
    },
    templateUrl: 'menu/carcass-report/carcass-report-table.html',
    link: (scope, element, attrs) => {
      const leftTopTable = element.find('.left-top-table');
      const leftBottomTable = element.find('.left-bottom-table');
      const rightTopTable = element.find('.right-top-table');
      const rightBottomTable = element.find('.right-bottom-table');
      const leftTopTableOffsetTop = leftTopTable.offset().top;
      const rightTopTableOffsetTop = rightTopTable.offset().top;

      attrs.$observe('cows', () => {
        if (!scope.cows) return;
        leftTopTable.empty();
        leftBottomTable.empty();
        rightTopTable.empty();
        leftTopTable.append(generateLeftTableHead(scope));
        rightTopTable.append(generateRightTableHead());
        const tbodies = generateTableBodies(scope);
        leftBottomTable.append(tbodies[0]);
        rightBottomTable.append(tbodies[1]);
      });

      function fixHeader() {
        leftTopTable.offset({top: leftTopTableOffsetTop});
        rightTopTable.offset({top: rightTopTableOffsetTop});
      }

      element.find('.carcass-report-table').on('scroll', () => {
        fixHeader();
      });
    }
  };
}

app.directive('carcassReportTable', carcassReportTable);
