class UClipsController {
  constructor(blockUI, SloppyReportAPI, FileUtilService) {
    'ngInject';

    this.blockUI = blockUI;
    this.SloppyReportAPI = SloppyReportAPI;
    this.FileUtilService = FileUtilService;

    this.sloppyReports = [];
    this.selectedSloppyReport = null;
  }

  $onInit() {
    this.uiGrid = new UiGridGenerator().generate(this, []);

    this.blockUI.start('ロード中');
    return this.SloppyReportAPI.index()
      .then(({data}) => {
        this.sloppyReports = data;
        this.selectedSloppyReport = data[0];
        this.updateUiGrid();
      })
      .finally(() => {
        this.blockUI.stop();
      });
  }

  updateUiGrid() {
    this.uiGrid.data = [];
    this.blockUI.start('ロード中');

    return this.SloppyReportAPI.run(this.selectedSloppyReport.id)
      .then(({data}) => {
        const columns = data.columns.map(({label, align}, index) => {
          return {columnId: String(index), label, width: 200};
        });

        const cellTemplates = {};
        data.columns.forEach(({align}, index) => {
          cellTemplates[index] = `
            <div ng-class="{'align-right': ${align === 'right'}}" ng-bind="row.entity[${index}]"></div>
          `;
        });

        const options = {
          clickRowEvent: '',
          cellTemplates,
        };

        const uiGrid = new UiGridGenerator().generate(this, columns, options);
        uiGrid.data = data.rows;
        this.uiGrid = Object.assign(this.uiGrid, uiGrid);
      })
      .finally(() => {
        this.blockUI.stop();
      });
  }

  exportExcel() {
    const fileName = `${this.selectedSloppyReport.name}.xlsx`;

    const rows =
      [this.uiGrid.columnDefs.map(({name}) => name)]
        .concat(this.uiGrid.data);

    this.FileUtilService.exportAsExcel(rows, fileName);
  }
}

app.controller('UClipsController', UClipsController);
