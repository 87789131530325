class MasterInseminatorAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/master_inseminator');
  }

  create(params) {
    return this.APIService.post('/master_inseminator', params);
  }

  update(params) {
    return this.APIService.put(`/master_inseminator/${params.id}`, params);
  }

  delete(id) {
    return this.APIService.delete(`/master_inseminator/${id}`);
  }

  updateSortOrder(orders) {
    const params = {orders: orders};
    return this.APIService.post('/master_inseminator/sort_order', params);
  }
}

app.service('MasterInseminatorAPI', MasterInseminatorAPI);
