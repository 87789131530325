class MilkingChartController {
  constructor(
    $scope,
    $interval,
    MilkingService,
    MilkingScheduleService,
    DateUtilService,
    DetailsStateFactory
  ) {
    'ngInject';
    this.$interval = $interval;
    this.MilkingService = MilkingService;
    this.MilkingScheduleService = MilkingScheduleService;
    this.DateUtilService = DateUtilService;
    this.DetailsStateFactory = DetailsStateFactory;

    this.intervals = [];
  }

  $onDestroy() {
    this.intervals.forEach((i) => this.$interval.cancel(i));

    if (this.milkingAmountsChart) {
      this.milkingAmountsChart.destroy();
    }
  }

  $onInit() {
    this.chartInterval = 200;

    this.milkingAmounts = {};
    this.baseDate = this.DateUtilService.today();
    const defaults = this.DetailsStateFactory.get();
    this.chartRange = defaults.milkingChartRange;

    this.loading = false;
    this.noData = false;

    this.hasMilkingSchedule = false;
    this.MilkingScheduleService.index().then((res) => {
      this.hasMilkingSchedule = res.data.length > 0;
      this.update();
    });
  }

  update() {
    if (this.milkingAmountsChart) {
      this.milkingAmountsChart.clear();
    }

    this.load(this.baseDate, this.chartRange).then(() => {
      this.drawChart();
    }).finally(() => {
      this.loading = false;
      this.noData = Object.keys(this.milkingAmounts).length === 0;
    });
  }

  load(date, range) {
    this.loading = true;
    if (!this.cowId) {
      this.milkingAmounts = {};
      return new Promise((resolve, reject) => resolve(null));
    }

    const days = range - 1;
    const startDate = this.DateUtilService.addDays(date, -days);
    const endDate = this.DateUtilService.toDate(date);

    const params = {
      cowId: this.cowId,
      startDate: this.DateUtilService.toW3CFormat(startDate),
      endDate: this.DateUtilService.toW3CFormat(endDate)
    };

    return this.MilkingService.getDailySummaryByCowId(params).then((res) => {
      this.milkingAmounts = this.createChartData(res.data, startDate, endDate);
    });
  }

  /**
   * C3用のプロットデータを生成する
   *
   * @param {Array.<Object>} records APIレスポンス
   * @param {Date} startDate チャート表示期間の開始日
   * @param {Date} endDate チャート表示期間の終了日
   * @return {Object} C3用のプロットデータ
   *
   * ex.
   * {
   *   "x":["2018-06-26","2018-06-27","2018-06-28","2018-06-29","2018-06-30"],
   *   "1":[null,12,18,12,21],
   *   "2":[null,15,14,25,15]
   * }
   */
  createChartData(records, startDate, endDate) {
    if (!records.length) {
      return {};
    }

    const milkingCount = Math.max.apply(null, records.map((r) => r.milkingOrder));
    const milkingAmounts = this.createEmptyAmounts(startDate, endDate, milkingCount);

    records
      .filter((r) => r.milkingOrder >= 1)
      .forEach((r) => {
        const w3cDate = this.DateUtilService.toW3CFormat(r.date);
        const index = milkingAmounts['x'].findIndex((date) => date === w3cDate);
        if (index >= 0) {
          milkingAmounts[r.milkingOrder][index] = r.amount || null;
        }
      });

    return milkingAmounts;
  }

  createEmptyAmounts(startDate, endDate, milkingCount) {
    const milkingAmounts = {x: []};
    for (let i = 1; i <= milkingCount; i++) {
      milkingAmounts[i] = [];
    }

    for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
      milkingAmounts['x'].push(this.DateUtilService.toW3CFormat(d));
      for (let i = 1; i <= milkingCount; i++) {
        milkingAmounts[i].push(null);
      }
    }
    return milkingAmounts;
  }

  drawChart() {
    const interval = this.$interval(() => {
      if (!this.loading) {
        if (Object.keys(this.milkingAmounts).length) {
          if (!this.milkingAmountsChart) {
            this.milkingAmountsChart = new MilkingStackedBarChart('#milking_amounts_chart', this.hasMilkingSchedule);
          }
          this.milkingAmountsChart.show(this.milkingAmounts);
        }
        this.$interval.cancel(interval);
      }
    }, this.chartInterval);
    this.intervals.push(interval);
  }

  onChangeChartRange(range) {
    this.chartRange = range;
    this.DetailsStateFactory.set('milkingChartRange', range);
    this.update();
  }

  toNextRange() {
    this.shiftRange(1);
  }

  toPreviousRange() {
    this.shiftRange(-1);
  }

  shiftRange(range) {
    const days = this.chartRange * range;
    this.baseDate = this.DateUtilService.addDays(this.baseDate, days);
    this.update();
  }

  onChangeBaseDate() {
    this.update();
  }
}

function milkingChart() {
  return {
    templateUrl: 'cow/components/charts/milking-chart.html',
    bindings: {
      cowId: '='
    },
    controller: MilkingChartController,
    controllerAs: 'milking',
  };
}

app.component('milkingChart', milkingChart());
