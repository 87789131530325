class MobileEditBleIdModal {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  open(param) {
    return this.$modal.open({
      templateUrl: 'mobile/components/edit-ble-id-modal/edit-ble-id-modal.html',
      controller: 'MobileEditBleIdModalController as ctrl',
      resolve: {
        param: () => param
      }
    });
  }
}
app.service('MobileEditBleIdModal', MobileEditBleIdModal);
