app.config(function(toastrConfig) {
  'ngInject';

  angular.extend(toastrConfig, {
    extendedTimeOut: 0,
    timeOut: 0,
    tapToDismiss: true,
    preventOpenDuplicates: true,
    allowHtml: true,
    iconClasses: {
      error: 'toast-error-override',
      info: 'toast-info',
      success: 'toast-success',
      warning: 'toast-warning'
    },
    templates: {
      toast: 'components/toast/default.html'
    }
  });
});
