class MainHeaderAccountController {
  constructor(
    $rootScope,
    $timeout,
    $state,
    AccountService,
    SessionCache,
    SessionStateService,
    SwitchFarmDialog,
    AccountAPI,
    ViewStateAPI
  ) {
    'ngInject';
    this.$state = $state;
    this.AccountService = AccountService;
    this.SessionStateService = SessionStateService;
    this.SwitchFarmDialog = SwitchFarmDialog;
    this.AccountAPI = AccountAPI;
    this.ViewStateAPI = ViewStateAPI;
    this.deregisters = [];

    this.account = SessionCache.account();
    this.isAdministrator = this.account.isAdministrator();
    this.farm = SessionCache.farm().data;
    this.isDepositor = SessionCache.farm().isDepositor();
    this.SessionCache = SessionCache;

    this.deregisters.push($rootScope.$on('onClickUmBackdrop', () => {
      $timeout(() => this.closeBoard());
    }));

    this.uiVersion = SessionCache.account().uiVersion();
  }

  $onDestroy() {
    this.deregisters.forEach((d) => d());
  }

  buttonClass() {
    return this.tooltipOpened === 'account'
      ? 'uHeader__accountButton--open'
      : 'uHeader__accountButton';
  }

  boardClass() {
    return this.tooltipOpened === 'account'
      ? 'uHeader__accountBoard--open'
      : 'uHeader__accountBoard';
  }

  toggleBoard() {
    if (this.tooltipOpened !== 'account') {
      this.tooltipOpened = 'account';
    } else {
      this.tooltipOpened = null;
    }
  }

  closeBoard() {
    if (this.tooltipOpened === 'account') {
      this.tooltipOpened = null;
    }
  }

  switchFarm() {
    this.SwitchFarmDialog.excute();
  }

  logout() {
    this.ViewStateAPI.create('logout-account', 'account').finally(() => {
      this.SessionStateService.logout();
    });
  }

  showChangeUiVersion(uiVersionHome) {
    if (this.isDepositor) return false;

    return this.uiVersion.split('_')[0].split('-')[1] !== uiVersionHome;
  }

  changeUiVersion(uiVersion) {
    return this.AccountAPI.updateUiVersion(uiVersion).then(() => {
      const values = Object.assign({}, this.SessionCache.account().values, {uiVersion});
      const account = new Account(values);

      this.SessionCache.store('account', account);
      this.uiVersion = this.SessionCache.account().uiVersion();
    });
  }

  onClickChangeUiVersion() {
    const uiVersionHome = this.uiVersion.split('_')[0].split('-')[1] === '1' ? '2' : '1';
    const uiVersionCowDetail = this.uiVersion.split('_')[1].split('-')[1];
    const uiVersion = this.convertUiVersionString({
      h: uiVersionHome,
      c: uiVersionCowDetail
    });

    this.changeUiVersion(uiVersion).then(() => {
      this.uiVersion = this.SessionCache.account().uiVersion();

      if (uiVersionHome === '1') {
        this.$state.go('top');
        return;
      }

      this.$state.go('home');
    });
  }

  convertUiVersionString(uiVersion) {
    return Object.entries(uiVersion).map(([key, value]) => {
      return `${key}-${value}`;
    }).join('_');
  }
}

function mainHeaderAccountComponent() {
  return {
    templateUrl: 'components/main-header-account/index.html',
    controller: MainHeaderAccountController,
    bindings: {
      tooltipOpened: '='
    }
  };
}

app.component('mainHeaderAccount', mainHeaderAccountComponent());
