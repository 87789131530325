class CountMilkBoxController {
  constructor() {
    'ngInject';
  }

  $onInit() {
  }
}

function countMilkBox() {
  return {
    templateUrl: 'top/count/milk-box.html',
    controller: CountMilkBoxController,
    bindings: {
      kind: '<',
      data: '<',
    }
  };
}

app.component('countMilkBox', countMilkBox());
