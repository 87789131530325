class CowEventHistoryController {
  constructor(
    $modal,
    blockUI,
    DepositEventHistoryContext,
    $rootScope,
    SessionCache
  ) {
    'ngInject';

    this.$modal = $modal;
    this.blockUI = blockUI;

    const eventTypes = EventType.allTypes.map((type) => {
      return {
        eventType: type.eventType,
        label: type.label,
        selected: true,
      };
    });
    this.condition = {
      startDate: DateUtil.today(),
      endDate: null,
      eventTypes: eventTypes,
      ids: [],
      workers: [],
    };

    this.isFilterShown = false;
    this.filterResults = [];

    // 件数上限
    this.displayLimit = 1000;

    this.errorMsg = {
      input: null,
      response: null,
    };

    this.context = DepositEventHistoryContext;
    this.canEditFilterItem = !SessionCache.account().isItemFilterApplied();
  }

  $onInit() {
    this.context.loadConfig().then((res) => {
      this.condition.ids = res.ids.map((e) => {
        return {
          id: e.id,
          label: e.farmName,
          selected: false
        };
      });
      this.condition.workers = res.workers.map((e, index) => {
        return {
          id: index,
          label: e,
          selected: false
        };
      });
      this.selections = res.selections;
      this.embryoRecoveryRank = res.embryoRecoveryRank;

      this.index();
    }).catch((err) => console.error(err));
  }

  index() {
    this.blockUI.start('検索中');

    const condition = this.condition;
    const legacyEventTypes = condition.eventTypes.filter((e) => e.selected)
      .map((e) => EventType.toLegacyEventType(e.eventType));
    const params = {
      startDate: condition.startDate,
      endDate: condition.endDate,
      eventTypes: legacyEventTypes,
      ids: condition.ids.filter((e) => e.selected).map((e) => e.id),
      workerNames: condition.workers.filter((e) => e.selected).map((e) => e.label),
    };

    this.context.search(params).then((res) => {
      const resData = this.validateResponse(res.data);
      this.histories = this.toViewModel(resData);
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      this.blockUI.stop();
    });
  }

  toViewModel(cowEvents) {
    const legacy2standard = EventType.convertMap();
    return cowEvents.map((e) => {
      const h = Object.assign({}, e);
      h.eventType = legacy2standard[e.eventType];
      h.legacyEventType = e.eventType;
      h.display = {};

      switch (h.eventType) {
      case 'fresh_check':
        h.display.eventNameHtml = `<span>フレッシュ</span><br><span>チェック</span>`;
        break;
      case 'timed_ai':
        h.display.eventNameHtml = `<span>ホルモン</span><br><span>プログラム</span>`;
        break;
      default:
        h.display.eventNameHtml = `<span>${h.eventName}</span>`;
      }

      h.display.occurredAtDate = DateUtil.toYYYYMMDD(h.occurredAt);
      h.display.occurredAtTime = DateUtil.toHHmm(h.occurredAt);

      if (h.treatmentDiseaseDate) {
        h.display.treatedAtDate = DateUtil.toYYYYMMDD(h.treatmentDiseaseDate);
        h.display.treatedAtTime = DateUtil.toHHmm(h.treatmentDiseaseDate);
      }

      h.display.isTreatmentEvent = EventType.isTreatment(h.eventType);

      if (h.display.isTreatmentEvent) {
        h.display.occurredAtDate = DateUtil.toYYYYMMDD(h.occurredDiseaseDate);
        h.display.occurredAtTime = DateUtil.toHHmm(h.occurredDiseaseDate);
      }

      if (h.targetBreedingDate) {
        h.display.targetBreedingDate = DateUtil.toYYYYMMDD(h.targetBreedingDate);
      }

      if (h.medications && h.medications.length) {
        h.display.medicines = h.medications.map((m) => {
          const capacity = m.capacity ? `${m.capacity}${m.unit}` : '';
          return `${m.name} ${capacity} ${m.method}`;
        });
      }

      if (h.endDateOfMilkWashoutPeriod) {
        h.display.endDateOfMilkWashoutPeriod = DateUtil.toYYYYMMDD(h.endDateOfMilkWashoutPeriod);
      }

      if (h.endDateOfBeefWashoutPeriod) {
        h.display.endDateOfBeefWashoutPeriod = DateUtil.toYYYYMMDD(h.endDateOfBeefWashoutPeriod);
      }

      if (h.calfs) {
        h.display.calfs = angular.copy(h.calfs);
        h.display.calfs.forEach((calf) => {
          return calf.birthStateString = calf.birthState.join(',');
        });
      }

      if (h.eventType === 'claw_diseases') {
        const limbPositions = ['左前', '右前', '左後', '右後'];
        const lameAffected = [];
        limbPositions.forEach((position) => {
          const limb = {position, condition: ''};
          for (let i = 1; i < 5; i++) {
            if (e[`lameAffectedLimb${i}`] === position ) {
              let lameClawZone = h[`lameClawZones${i}`] || '';

              lameClawZone = lameClawZone.replace(/内,外/, '内外').replace(/([内外]+)/, '$1蹄');

              const lameAffectedPart = lameClawZone ? '' : h[`lameAffectedPart${i}`];
              const lameCondition = h[`lameCondition${i}`];
              const clawDiseaseName = h[`clawDiseaseName${i}`];
              limb.condition += `${lameClawZone || ''}${lameAffectedPart || ''} ${lameCondition || ''} ${clawDiseaseName || ''}`;
            }
          }
          lameAffected.push(limb);
        });
        h.display.lameAffected = lameAffected;
      }

      if (h.eventType === 'pregnant_diagnosis') {
        if (h.ninshinkanteiResult === '受胎' || h.ninshinkanteiResult === '双子受胎') {
          const daysAfterTargetBreedingDate = DateUtil.countDays(h.targetBreedingDate, h.occurredAt);
          h.display.daysAfterTargetBreedingDate = daysAfterTargetBreedingDate;
        }
      }

      if (h.visceralDestruction) {
        h.display.visceralDestruction = h.visceralDestruction.replace(/:/g, ',');
      }

      if (h.carcassDefects) {
        h.carcassDefectSummary = Carcass.defectSummary(h.carcassDefects);
      }

      if ('embryoMasterRegistered' in h && this.selections.embryoMasterRegistered) {
        const selection = this.selections.embryoMasterRegistered.find((s) => s.value === h.embryoMasterRegistered);
        h.display.embryoMasterRegistered = selection ? selection.label : '';
      }

      if (h.nextCowState && this.selections.embryoRecoveryNextCowState) {
        const selection = this.selections.embryoRecoveryNextCowState.find((s) => s.value === h.nextCowState);
        h.display.nextCowState = selection ? selection.label : '';
      }

      if (h.eventType === 'mastitis') {
        if (h.mastitisScore) {
          const mastitisScore = Mastitis.MASTITIS_SCORES.find((s) => s.value === h.mastitisScore);
          h.display.mastitisScore = mastitisScore.label;
        }
      }

      if (h.dressedCarcassUnitPrice) {
        h.display.dressedCarcassUnitPrice = Number(h.dressedCarcassUnitPrice).toLocaleString();
      }

      if (h.dressedCarcassSalesPrice) {
        h.display.dressedCarcassSalesPrice = Number(h.dressedCarcassSalesPrice).toLocaleString();
      }

      if (h.eventType === 'embryo_recovery') {
        const historyRanks = h.embryos.map((embryo) => embryo.rank);

        const configRanks = Object.entries(this.embryoRecoveryRank).map(([key, value]) => {
          const {subrank} = value;
          const rank = key.length === 1 ? key.toUpperCase() : key;

          if (!subrank || subrank === 1) {
            return rank;
          }

          return Array(subrank).fill('').map((_, index) => {
            const subrank = index + 1;

            return `${rank}${subrank}`;
          });
        }).flat();

        const isArraysEqual = historyRanks.length === configRanks.length &&
          historyRanks.every((rank) => configRanks.includes(rank));

        if (!isArraysEqual) {
          h.embryos = Object.entries(this.embryoRecoveryRank).map(([key, value]) => {
            const {subrank} = value;
            const rank = key.length === 1 ? key.toUpperCase() : key;

            if (!subrank || subrank === 1) {
              return {
                rank,
                count: '-'
              };
            }

            return Array(subrank).fill('').map((_, index) => {
              const subrank = index + 1;

              return {
                rank: `${rank}${subrank}`,
                count: '-'
              };
            });
          }).flat();
        }
      }

      if (h.eventType === 'others') {
        if (h.otherDiseaseNames) {
          h.display.otherDiseaseNames = h.otherDiseaseNames.map((d) => d.name).join('、');
        }
      }

      return h;
    });
  }

  onClickCondition() {
    const deepCopy = (ar, idField = 'id') => {
      return ar.map((e) => {
        const result = {
          label: e.label,
          selected: e.selected
        };
        result[idField] = e[idField];
        return result;
      });
    };

    const params = {
      farms: deepCopy(this.condition.ids),
      eventTypes: deepCopy(this.condition.eventTypes, 'eventType'),
      workers: deepCopy(this.condition.workers)
    };

    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'menu/cow-event/history/condition/index.html',
      controller: 'CowEventHistoryConditionController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      resolve: {
        params: () => {
          return params;
        }
      }
    });

    modalInstance.result.then((result) => {
      this.condition.ids = result.farms;
      this.condition.eventTypes = result.eventTypes;
      this.condition.workers = result.workers;
    });
  }

  validateInput() {
    if (!this.condition.startDate && !this.condition.endDate) {
      return this.errorMsg.input = '対象期間の開始日または終了日を入力してください。';
    }
    return this.errorMsg.input = null;
  }

  validateResponse(resData) {
    if (resData.length > this.displayLimit) {
      this.errorMsg.response = '検索結果は、表示上限1000件を超えています。';
      return resData.slice(0, this.displayLimit);
    }
    this.errorMsg.response = null;
    return resData;
  }

  shouldShowFilterItem(price) {
    return price && this.canEditFilterItem;
  }
}

app.controller('CowEventHistoryController', CowEventHistoryController);
