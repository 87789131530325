// eslint-disable-next-line no-unused-vars
class AlertCalvingSensorDropped {
  static get SOLUTION() {
    return {
      replace: '交換',
      unequip: '解除',
      reequip: '再装着',
      ignore: '問題なし'
    };
  }

  static toViewModel(obj) {
    const diffMinutes = DateUtil.diffMinutes(obj.alertAt, new Date());
    let alertAtLabel;
    if (diffMinutes < 60) {
      alertAtLabel = '1時間以内';
    } else {
      const hours = Math.floor(diffMinutes / 60);
      alertAtLabel = `${hours}時間前`;
    }

    let joinedCowLabels;
    if (obj.cowLabels) {
      joinedCowLabels = obj.cowLabels.split(',').map((label) => label.replace(/(\[|\])/g, '')).join('、');
    }

    const model = Object.assign({
      formattedCalvingSensorNumber: CowCalvingSensorHistory.formatCalvingSensorNumber(obj.calvingSensorNumber),
      alertAtLabel: alertAtLabel,
      combinedGroupName: obj.pen ? `${obj.cowGroupName}(${obj.pen})` : obj.cowGroupName,
      formattedCowUid: Cow.formatCowUid(obj.cowUid),
      joinedCowLabels: joinedCowLabels
    }, obj);

    return model;
  }
}
