app.config(function(
  $locationProvider,
  $provide,
  $stateProvider,
  $urlRouterProvider,
  $windowProvider,
  blockUIConfig
) {
  'ngInject';

  /**
   * ui-bsのモーダルを閉じるときに発生する'unhandled rejection'のエラーメッセージを沈ませる
   *  原因： ui-bsのモーダルを閉じる(dismiss/cancel)動作は $q.reject()を使っているが、
   *  ng1.6以降から catch() してない reject() は全部警告を出すように変更されたため、
   *  $modal.openが使われてるところに一々 .catch() を書かなあかん面倒な作業を発生、
   *  それを防ぐ。
   */
  $provide.decorator('$exceptionHandler', ['$delegate', ($delegate) =>
    (exception, cause) => {
      const exceptionsToIgnore = [
        'Possibly unhandled rejection: backdrop click', // 範囲外クリック
        'Possibly unhandled rejection: cancel', // dismiss()
        'Possibly unhandled rejection: escape key press', // ESC
      ];
      if (exceptionsToIgnore.indexOf(exception) >= 0) {
        return;
      }
      $delegate(exception, cause);
    }
  ]);

  const $window = $windowProvider.$get();
  const ua = $window.navigator.userAgent;
  const isMobile = (ua.indexOf('iPhone') >= 0 || ua.indexOf('iPod') >= 0 || (ua.indexOf('Android') >= 0 && ua.indexOf('Mobile') >= 0));

  // ignore Angular default hash-prefix
  $locationProvider.hashPrefix('');

  // For any unmatched url, redirect to /state1
  $urlRouterProvider.otherwise(() => isMobile ? '/mobile/home' : '/login');

  if (!isMobile) {
    // Change the default overlay message
    blockUIConfig.message = 'しばらくお待ちください';
  }
  // Disable automatically blocking of the user interface
  blockUIConfig.autoBlock = false;

  // Now set up the states
  $stateProvider
    .state('login', {
      url: '/login',
      views: {
        'login-main': {
          templateUrl: 'menu/login/index.html',
          controller: 'LoginController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        reload: false,
        redirectToName: '',
        redirectParams: {}
      }
    })
    .state('changePassword', {
      url: '/changePassword',
      views: {
        'login-main': {
          templateUrl: 'menu/login/change-password.html',
          controller: 'ChangePasswordController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        email: '',
      },
    })
    .state('top', { // 旧デザイン
      url: '/top',
      views: {
        'main': {
          templateUrl: 'top/index.html',
          controller: 'TopController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('mobile-alert-list', {
      url: '/mobile/alert-list/:alertType',
      views: {'mobile-main': {
        templateUrl: 'mobile/alert-list/index.html',
        controller: 'MobileAlertListController',
        controllerAs: 'mobileAlertListCtl'}
      }
    })
    .state('mobile-alert-dysstasia', {
      url: '/mobile/alert-dysstasia?cow_id',
      views: {'mobile-main': {
        templateUrl: 'mobile/alert-list/dysstasia/index.html',
        controller: 'MobileAlertDysstasiaController',
        controllerAs: 'mobileDysstasiaCtrl'}
      },
      resolve: {/* @ngInject */
        PreviousState: ($state) => ({
          name: $state.current.name,
          params: $state.params,
        })
      }
    })
    .state('mobile-alert-dysstasia-resolved', {
      url: '/mobile/alert-dysstasia/resolved?cow_id',
      views: {'mobile-main': {
        templateUrl: 'mobile/alert-list/dysstasia/resolved-index.html',
        controller: 'MobileAlertDysstasiaController',
        controllerAs: 'mobileDysstasiaCtrl'}
      },
      params: {
        resolved: true
      },
      resolve: {/* @ngInject */
        PreviousState: ($state) => ({
          name: $state.current.name,
          params: $state.params,
        })
      }
    })
    .state('mobile-alert-calving', {
      url: '/mobile/alert-calving',
      views: {'mobile-main': {
        templateUrl: 'mobile/alert-list/calving/index.html',
        controller: 'MobileAlertCalvingController',
        controllerAs: 'ctrl'}
      },
      resolve: {/* @ngInject */
        PreviousState: ($state) => ({
          name: $state.current.name,
          params: $state.params,
        })
      }
    })
    .state('mobile-alert-calving-confirmed', {
      url: '/mobile/alert-calving/confirmed',
      views: {'mobile-main': {
        templateUrl: 'mobile/alert-list/calving/index.html',
        controller: 'MobileAlertCalvingController',
        controllerAs: 'ctrl'}
      },
      params: {
        confirmed: true
      },
      resolve: {/* @ngInject */
        PreviousState: ($state) => ({
          name: $state.current.name,
          params: $state.params,
        })
      }
    })
    .state('mobile-alert-calving-sensor-dropped', {
      url: '/mobile/alert-calving-sensor-dropped',
      views: {'mobile-main': {
        templateUrl: 'mobile/alert-list/calving-sensor-dropped/index.html',
        controller: 'MobileAlertCalvingSensorDroppedController',
        controllerAs: 'ctrl'}
      },
      resolve: {/* @ngInject */
        PreviousState: ($state) => ({
          name: $state.current.name,
          params: $state.params,
        })
      }
    })
    .state('cow-event-bulk-input', {
      url: '/cow-event/bulk-input/:isDepositor',
      views: {'main': {
        templateUrl: 'menu/cow-event/bulk-input/index.html',
        controller: 'CowEventBulkInputController',
        controllerAs: 'ctrl'
      }},
      params: {
        eventType: '',
        cowIds: [],
        cowType: '',
        isDepositor: 'false',
        qwert: 0,
        caller: {}
      }
    })
    .state('cowEvent', {
      url: '/cow-event?eventType?dateField?startDate?endDate?entryStaff',
      views: {
        'main': {
          templateUrl: 'menu/cow-event/index.html',
          controller: 'CowEventIndexController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        eventType: '',
        dateField: '',
        startDate: '',
        endDate: '',
        entryStaff: ''
      }
    })
    .state('cowList', {
      url: '/cowList/:id/:name/:attr',
      views: {
        'main': {
          templateUrl: 'cow/list/cowList.html',
          controller: 'CowListController'
        }
      },
    })
    .state('mobileUClipsList', {
      url: '/mobile/u-clips/list',
      views: {
        'mobile-main': {
          templateUrl: 'mobile/u-clips/list/index.html',
          controller: 'MobileUClipsListController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('mobileUClips', {
      url: '/mobile/u-clips/:id',
      views: {
        'mobile-main': {
          templateUrl: 'mobile/u-clips/index.html',
          controller: 'MobileUClipsController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        id: ''
      },
    })
    .state('customlist', {
      url: '/customlist/runtime/:customlistId?config_type?cow_group_id?fattening_stage_id?name',
      views: {
        'main': {
          templateUrl: 'menu/customlist/runtime/index.html',
          controller: 'CustomlistRuntimeController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        customlistId: '',
        name: '',
        farmCondition: '',
        currentColumnSort: null,
        filterConditions: []
      },
    })
    .state('groupHeadageDetailsCustomlist', {
      url: '/group/headage/details/customlist/:customlistId?config_type?cow_group_id?fattening_stage_id?name?farm_type',
      views: {
        'main': {
          templateUrl: 'menu/customlist/runtime/index.html',
          controller: 'CustomlistRuntimeController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        groupHeadageDetails: true,
      },
    })
    .state('cowSearch', {
      url: '/cow_search',
      views: {
        'main': {
          templateUrl: 'menu/cow-search/index.html',
          controller: 'CowSearchIndexController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        searchCondition: {},
      },
    })
    .state('alertList', {
      url: '/alert_list/:customlistId?filter',
      views: {
        'main': {
          templateUrl: 'menu/alert/index.html',
          controller: 'AlertIndexController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        customlistId: '',
        currentColumnSort: null,
      },
    })
    .state('mobileAlertList', {
      url: '/mobile/alert_list/:customlistId?filter',
      views: {
        'mobile-main': {
          templateUrl: 'mobile/alert/index.html',
          controller: 'MobileAlertIndexController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        customlistId: '',
        cowIndex: null
      },
    })
    .state('cowDetail', {
      url: '/cowDetail/:cowId',
      views: {
        'main': {
          templateUrl: 'cow/detail/index.html',
          controller: 'CowDetailIndexController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        cowId: '',
        cowIdIndex: '',
        tab: '',
        activeEntryEvent: false,
        caller: {}
      },
    })
    .state('mobileCowDetail', {
      url: '/mobile/cowDetail/:cowId',
      views: {
        'mobile-main': {
          templateUrl: 'mobile/cow-detail/cow-detail.html',
          controller: 'MobileCowDetailController',
        },
      },
      params: {
        id: '',
        tab: '',
        activeEntryEvent: false,
        isRegisterEvent: false,
        isFromNavi: false,
        caller: {}
      },
      resolve: {/* @ngInject */
        PreviousState: ($state) => ({
          name: $state.current.name,
          params: $state.params,
        }),
      },
    })
    .state('cowEntryBulk', {
      url: '/cowEntryBulk',
      views: {
        'main': {
          templateUrl: 'cow/entry/cow-entry-bulk.html',
          controller: 'CowEntryBulkController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('cow-edit', {
      url: '/cow/:cowId/edit',
      views: {
        'main': {
          templateUrl: 'menu/cow/edit/index.html',
          controller: 'CowEditController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        formData: null,
        caller: {}
      },
    })
    .state('fileImport', {
      url: '/file-import',
      views: {'main': {templateUrl: 'menu/file-import/index.html', controller: 'FileImportController'}},
    })
    .state('fileImportDetail', {
      url: '/file_import/:id',
      views: {
        'main': {
          templateUrl: 'menu/file-import/detail.html',
          controller: 'FileImportDetailController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('cowshed', {
      url: '/cowshed',
      views: {'main': {
        templateUrl: 'menu/environment/cowshed/index.html',
        controller: 'CowshedController',
        controllerAs: 'ctrl'
      }},
    })
    .state('senser', {
      url: '/senser/:id',
      views: {'main': {templateUrl: 'menu/environment/senser/senser.html', controller: 'SenserController'}},
    })
    .state('analysisGraph', {
      url: '/analysis/relationGraph',
      views: {
        'main': {
          templateUrl: 'menu/legacy/analysis/relationGraph/relationGraph.html',
          controller: 'RelationGraphController'
        }
      },
      params: {
        targetId: '',
        dataType: '',
        targetType: '',
        start_date: null,
        end_date: null,
        statsType: ''
      },
    })
    .state('cowGroupList', {
      url: '/group/list',
      views: {
        'main': {
          templateUrl: 'menu/legacy/cow-group/list/cow-group-list.html',
          controller: 'CowGroupListController'
        }
      },
    })
    .state('milking', {
      url: '/milking',
      views: {
        'main': {
          templateUrl: 'menu/milking/milking.html',
          controller: 'MilkingController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('customlistConfig', {
      url: '/customlist/config',
      views: {
        'main': {
          templateUrl: 'menu/customlist/index.html',
          controller: 'CustomlistIndexController'
        }
      },
    })
    .state('setting', {
      url: '/setting',
      views: {
        'main': {templateUrl: 'menu/setting/setting.html', controller: 'settingController'},
        'group@setting': {
          templateUrl: 'menu/setting/group/index.html',
          controller: 'GroupIndexController',
          controllerAs: 'ctrl'
        },
        'settingSelectList@setting': {
          templateUrl: 'menu/setting/setting-select-list/index.html',
          controller: 'settingSelectListController',
          controllerAs: 'ctrl'
        },
        'farm@setting': {templateUrl: 'menu/setting/farm/index.html', controller: 'FarmIndexController', controllerAs: 'ctrl'},
        'milkingSchedule@setting': {templateUrl: 'menu/setting/milking-schedule/index.html', controller: 'MilkingScheduleController'},
        'alerts@setting': {templateUrl: 'menu/setting/alert/index.html', controller: 'AlertConfigController'},
        'fattening@setting': {templateUrl: 'menu/setting/fattening/index.html', controller: 'FatteningConfigController'},
        'reproduction@setting': {templateUrl: 'menu/setting/reproduction/index.html', controller: 'ReproductionConfigController'},
      },
    })
    .state('pdf', {
      url: '/pdf',
      views: {
        'main': {
          templateUrl: 'ordered-list/pdf/pdf.html',
          controller: 'PDFController'
        }
      },
    })
    .state('alert-history', {
      url: '/alert-history?alertType?startDate?endDate?searchBy?shouldIncludeSoldAndDied?cowGroupId?cowNo',
      views: {
        'main': {
          templateUrl: 'alert/alert-history/alert-history.html',
          controller: 'AlertHistoryController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        alertType: '',
        startDate: '',
        endDate: '',
        searchBy: '',
        shouldIncludeSoldAndDied: '',
        cowGroupId: '',
        cowNo: '',
      }
    })
    .state('alertMilkLow', {
      url: '/alert-milk-low',
      views: {'main': {templateUrl: 'alert/alert-cow-list.html', controller: 'AlertCowListController'}},
      params: {
        alertType: 'milk-low'
      },
    })
    .state('alertFeedLow', {
      url: '/alert-feed-low',
      views: {'main': {templateUrl: 'alert/alert-cow-list.html', controller: 'AlertCowListController'}},
      params: {
        alertType: 'feed-low'
      },
    })
    .state('alertWaterLow', {
      url: '/alert-water-low',
      views: {'main': {templateUrl: 'alert/alert-cow-list.html', controller: 'AlertCowListController'}},
      params: {
        alertType: 'water-low'
      },
    })
    .state('alertLieHigh', {
      url: '/alert-lie-high',
      views: {'main': {templateUrl: 'alert/alert-cow-list.html', controller: 'AlertCowListController'}},
      params: {
        alertType: 'lie-high'
      },
    })
    // 肉牛のアラート
    .state('alertFeedLowBeef', {
      url: '/alert-feed-low-beef',
      views: {'main': {templateUrl: 'alert/alert-beef-list.html', controller: 'AlertBeefListController'}},
      params: {
        alertType: 'feed-low'
      }
    })
    .state('alertWaterLowBeef', {
      url: '/alert-water-low-beef',
      views: {'main': {templateUrl: 'alert/alert-beef-list.html', controller: 'AlertBeefListController'}},
      params: {
        alertType: 'water-low'
      }
    })
    .state('alertLieHighBeef', {
      url: '/alert-lie-high-beef',
      views: {'main': {templateUrl: 'alert/alert-beef-list.html', controller: 'AlertBeefListController'}},
      params: {
        alertType: 'lie-high'
      }
    })
    .state('alertDysstasia', {
      url: '/alert-dysstasia?resolved',
      views: {
        'main': {
          templateUrl: 'alert/dysstasia/index.html',
          controller: 'AlertDysstasiaController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('alert-calving', {
      url: '/alert-calving',
      views: {
        'main': {
          templateUrl: 'menu/alert/calving/index.html',
          controller: 'AlertCalvingController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('alert-calving-confirmed', {
      url: '/alert-calving/confirmed',
      views: {
        'main': {
          templateUrl: 'menu/alert/calving/index.html',
          controller: 'AlertCalvingController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        confirmed: true
      }
    })
    .state('alert-calving-sensor-dropped', {
      url: '/alert-calving-sensor-dropped',
      views: {
        'main': {
          templateUrl: 'menu/alert/calving-sensor-dropped/index.html',
          controller: 'AlertCalvingSensorDroppedController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('alertLocation', {
      url: '/alert-location',
      views: {
        'main': {
          templateUrl: 'alert/alert-location-list.html',
          controller: 'AlertLocationListController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('alertLocationHistory', {
      url: '/alert-location-history',
      views: {
        'main': {
          templateUrl: 'alert/alert-location-history.html',
          controller: 'AlertLocationHistoryController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('purchase-list', {
      url: '/purchase-list',
      views: {'main': {templateUrl: 'ordered-list/purchase-list.html', controller: 'PurchaseListController'}},
    })
    .state('ordered-summary-report', {
      url: '/ordered-summary-report',
      views: {'main': {templateUrl: 'ordered-summary-report/ordered-summary-report.html', controller: 'OrderedSummaryReportController'}},
    })
    .state('ai-report', {
      url: '/ai-report',
      views: {'main': {templateUrl: 'ordered-list/ai-report.html', controller: 'AiReportController'}},
    })
    .state('mastitis-list', {
      url: '/mastitis-list',
      views: {'main': {templateUrl: 'ordered-list/mastitis-list.html', controller: 'MastitisListController', controllerAs: 'mastitisListCtl'}},
    })
    .state('perinatal-list', {
      url: '/perinatal-list',
      views: {'main': {templateUrl: 'ordered-list/perinatal-list.html', controller: 'PerinatalListController', controllerAs: 'perinatalListCtl'}},
    })
    .state('hospital-perinatal-list', {
      url: '/hospital-perinatal-list',
      views: {'main': {templateUrl: 'ordered-list/perinatal-list.html', controller: 'PerinatalListController', controllerAs: 'perinatalListCtl'}},
    })
    .state('dairy-technical-report', {
      url: '/dairy-technical-report',
      views: {
        'main': {
          templateUrl: 'report/dairy-technical-report/index.html',
          controller: 'DairyTechnicalReportController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('fattening_report', {
      url: '/fattening_report',
      views: {
        'main': {
          templateUrl: 'menu/report/fattening/index.html',
          controller: 'FatteningReportController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('reproduction-performance-report', {
      url: '/reproduction-performance-report',
      views: {
        'main': {
          templateUrl: 'reproduction-performance-report/index.html',
          controller: 'ReproductionPerformanceReportController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('reproduction-performance-report-monthly', {
      url: '/reproduction-performance-report-monthly',
      views: {
        'main': {
          templateUrl: 'reproduction-performance-report-monthly/monthly-report.html',
          controller: 'ReproductionPerformanceMonthlyReportController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('reproductionPlannedEventsList', {
      url: app.userAgent.browser === 'ie' ?
        '/reproduction-planned-events/list' : '/reproduction-planned-events/list/:date/:eventType',
      views: {
        'main': {
          templateUrl: 'reproduction-planned-events/list/reproduction-planned-events-list.html',
          controller: 'ReproductionPlannedEventsListController',
          controllerAs: 'ctrl'
        },
      },
      params: {
        eventType: null,
        date: null,
        currentColumnSort: null,
      }
    })
    .state('report-summary', {
      url: '/report-summary',
      views: {
        'main': {
          templateUrl: 'report/summary/index.html',
          controller: 'ReportSummaryController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('carcass-report', {
      url: '/carcass-report',
      views: {
        'main': {
          templateUrl: 'menu/carcass-report/carcass-report.html',
          controller: 'CarcassReportController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('uClips', {
      url: '/u-clips',
      views: {
        'main': {
          templateUrl: 'menu/deprecated/u-clips/index.html',
          controller: 'UClipsController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('depositHome', {
      url: '/deposit/home',
      views: {
        'main': {
          templateUrl: 'menu/deposit/home/home.html',
          controller: 'DepositHomeController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('cow-event-history', {
      url: '/cow-event/history',
      views: {
        'main': {
          templateUrl: 'menu/cow-event/history/index.html',
          controller: 'CowEventHistoryController',
          controllerAs: 'ctrl'
        }
      },
    })
    .state('beefHistory', {
      url: '/shipment/beef_history',
      views: {
        'main': {
          templateUrl: 'shipment/beef-history/beef-history-index.html',
          controller: 'BeefHistoryController',
          controllerAs: 'ctrl'
        }
      }
    })
    .state('embryoRecoveryReport', {
      url: '/embryo-recovery-report',
      views: {
        'main': {
          templateUrl: 'menu/report/embryo-recovery/index.html',
          controller: 'EmbryoRecoveryReportController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        requestParams: null,
        filterParams: null,
        sortBy: null,
      },
    }).state('genom-linkage', {
      url: '/external/genom/linkage',
      views: {
        'genom-linkage': {
          templateUrl: 'external/genom/linkage.html',
          controller: 'GenomLinkageController',
          controllerAs: 'ctrl'
        }
      },
    }).state('genom-linkage-authentication', {
      url: '/external/genom/authentication',
      views: {
        'genom-linkage-auth': {
          templateUrl: 'external/genom/authentication.html',
          controller: 'GenomLinkageAuthenticationController',
          controllerAs: 'ctrl'
        }
      },
    }).state('reproduction-summary-by-cow-group', {
      url: '/reproduction-summary/by-cow-group',
      views: {
        'main': {
          templateUrl: 'reproduction-summary/by-cow-group/index.html',
          controller: 'ReproductionSummaryController',
          controllerAs: 'ctrl'
        },
      },
      params: {
        summaryType: 'cowGroup',
        customlistId: '',
        cowGroupName: '',
      }
    }).state('dhi-import', {
      url: '/dhi-import',
      views: {
        'main': {
          templateUrl: 'menu/dhi/dhi-import/index.html',
          controller: 'DhiImportController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        caller: {}
      }
    }).state('dhi-import-detail', {
      url: '/dhi-import-detail/:importId',
      views: {
        'main': {
          templateUrl: 'menu/dhi/dhi-import/detail.html',
          controller: 'DhiImportHistoryDetailController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        importId: null
      }
    }).state('lactation-performance', {
      url: '/lactation-performance',
      views: {
        'main': {
          templateUrl: 'menu/report/lactation-performance/index.html',
          controller: 'LactationPerformanceController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        activeTab: null,
        selectChart: null,
        dataType: null,
        referenceDate: null,
        interval: null
      }
    }).state('group-activity-scatter-plot', {
      url: '/group-activity-scatter-plot',
      views: {
        'main': {
          templateUrl: 'menu/group-activity-scatter-plot/index.html',
          controller: 'GroupActivityScatterPlotIndexController',
          controllerAs: 'ctrl'
        },
      },
    }).state('certificate', {
      url: '/certificate',
      views: {
        'main': {
          templateUrl: 'components/menu-list/index.html',
          controller: 'CertificateController',
          controllerAs: 'ctrl'
        }
      },
    }).state('certificate-birth', {
      url: '/certificate-birth',
      views: {
        'main': {
          templateUrl: 'menu/certificate/birth/index.html',
          controller: 'CertificateBirthController',
          controllerAs: 'ctrl'
        }
      },
    }).state('certificate-ai', {
      url: '/certificate-ai',
      views: {
        'main': {
          templateUrl: 'menu/certificate/ai/index.html',
          controller: 'CertificateAiController',
          controllerAs: 'ctrl'
        }
      },
    }).state('calving-sensor', {
      url: app.userAgent.browser === 'ie' ?
        '/calving-sensor/:eventType-candidates' : '/calving-sensor/:date/:eventType-candidates',
      views: {
        'main': {
          templateUrl: 'menu/calving-sensor/index.html',
          controller: 'CalvingSensorController',
          controllerAs: 'ctrl'
        },
      },
      params: {
        eventType: null,
        date: null
      }
    }).state('mobile-calving-sensor', {
      url: '/mobile/calving-sensor/:date/:eventType-candidates',
      views: {
        'mobile-main': {
          templateUrl: 'mobile/calving-sensor/index.html',
          controller: 'MobileCalvingSensorController',
          controllerAs: 'ctrl'
        },
      },
      params: {
        eventType: null,
        date: null,
        cowIndex: null
      }
    }).state('mobileCowRegister', {
      url: '/mobile/cow/register',
      views: {
        'mobile-main': {
          templateUrl: 'mobile/react-app/index.html',
          controller: 'MobileReactAppController',
          controllerAs: 'ctrl'
        },
      },
      resolve: {/* @ngInject */
        PreviousState: ($state) => ({
          name: $state.current.name,
          params: $state.params,
        }),
      },
      params: {
        disableLegacyMobileStickyAlert: true
      },
    }).state('genomic-evaluation-list', {
      url: '/genom/evaluation-list',
      views: {
        'main': {
          templateUrl: 'menu/genom/evaluation-list/index.html',
          controller: 'GenomEvaluationListController',
          controllerAs: 'ctrl'
        }
      }
    }).state('mobile-genomic-evaluation-list', {
      url: '/mobile/genom/evaluation-list',
      views: {
        'mobile-main': {
          templateUrl: 'mobile/genom/evaluation-list/index.html',
          controller: 'MobileGenomEvaluationListController',
          controllerAs: 'ctrl'
        }
      }
    }).state('temporary-group-view-activity', {
      url: '/temporary-group-view/activity/:groupName',
      views: {
        'main': {
          templateUrl: 'temporary-group-view/activity/index.html',
          controller: 'TemporaryGroupViewActivityController',
          controllerAs: 'ctrl'
        },
      },
      params: {
        groupName: null,
        cowGroupId: null
      }
    }).state('temporary-group-view-cows', {
      url: '/temporary-group-view/cows/:cowGroupId/:pen',
      views: {
        'main': {
          templateUrl: 'temporary-group-view/cows/index.html',
          controller: 'TemporaryGroupViewCowsController',
          controllerAs: 'ctrl'
        },
      },
      params: {
        cowGroupId: null,
        pen: null
      }
    }).state('temporary-group-view-cow-detail', {
      url: '/temporary-group-view/cow-detail/:cowId',
      views: {
        'main': {
          templateUrl: 'temporary-group-view/cow-detail/index.html',
          controller: 'TemporaryGroupViewCowDetailController',
          controllerAs: 'ctrl'
        },
      },
      params: {
        cowId: '',
        tab: '',
        activeEntryEvent: false,
        caller: {}
      }
    }).state('group-transfer-input', {
      url: '/group/transfer/input/',
      views: {
        'main': {
          templateUrl: 'menu/group/transfer/input/index.html',
          controller: 'GroupTransferInputController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        cows: [],
        histories: [],
        fromId: null,
        toId: null,
        transferDate: null,
        transferType: null,
        operator: null,
        caller: {}
      }
    }).state('group-transfer-history', {
      url: '/group/transfer/history/',
      views: {
        'main': {
          templateUrl: 'menu/group/transfer/history/index.html',
          controller: 'GroupTransferHistoryController',
          controllerAs: 'ctrl'
        }
      }
    }).state('group-transfer-history-current-farm', {
      url: '/group/transfer/history-current-farm/',
      views: {
        'main': {
          templateUrl: 'menu/group/transfer/history-current-farm/index.html',
          controller: 'GroupTransferHistoryCurrentFarmController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        condition: {}
      }
    }).state('mobile-group-transfer-history-current-farm', {
      url: '/mobile/group/transfer/history-current-farm/',
      views: {
        'mobile-main': {
          templateUrl: 'mobile/group/transfer/history-current-farm/index.html',
          controller: 'MobileGroupTransferHistoryCurrentFarmController',
          controllerAs: 'ctrl'
        }
      }
    }).state('headage-details', {
      url: '/headage/details?:category&:subCategory&:item',
      views: {
        'main': {
          templateUrl: 'menu/home/headage/details/index.html',
          controller: 'HeadageDetailsController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        currentColumnSort: null,
      },
    }).state('daily-report-details', {
      url: '/daily-report/details/:date/:eventType',
      views: {
        'main': {
          templateUrl: 'menu/home/daily-report/details/index.html',
          controller: 'DailyReportDetailsController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        currentColumnSort: null,
      },
    }).state('alert-eartag-sensor-dropped', {
      url: '/alert-eartag-sensor-dropped',
      views: {
        'main': {
          templateUrl: 'menu/alert/eartag-sensor-dropped/index.html',
          controller: 'AlertEartagSensorDroppedController',
          controllerAs: 'ctrl'
        }
      },
      params: {
        currentColumnSort: null,
      },
    }).state('mobile-alert-eartag-sensor-dropped', {
      url: '/mobile/alert-eartag-sensor-dropped',
      views: {'mobile-main': {
        templateUrl: 'mobile/alert-list/eartag-sensor-dropped/index.html',
        controller: 'MobileAlertEartagSensorDroppedController',
        controllerAs: 'ctrl'}
      },
      resolve: {/* @ngInject */
        PreviousState: ($state) => ({
          name: $state.current.name,
          params: $state.params,
        })
      }
    });

  const addReactState = (env, name, url, params = env === 'mobile' ? {
    disableLegacyMobileStickyAlert: true,
    isModernScreen: true
  } : {
    disableDotBarn: true,
    isModernScreen: true
  }) => {
    let views = {
      'main': {
        templateUrl: 'react-app/index.html',
        controller: 'DesktopReactAppController',
        controllerAs: 'ctrl'
      }
    };
    if (env === 'mobile') {
      views = {
        'mobile-main': {
          templateUrl: 'mobile/react-app/index.html',
          controller: 'MobileReactAppController',
          controllerAs: 'ctrl'
        }
      };
    } else if (env === 'login-main') {
      views = {
        'login-main': {
          templateUrl: 'react-app/index.html',
          controller: 'DesktopReactAppController',
          controllerAs: 'ctrl'
        }
      };
    }

    $stateProvider.state(name, {
      url,
      views: views,
      params,
      resolve: {/* @ngInject */
        PreviousState: ($state) => ({
          name: $state.current.name,
          params: $state.params,
        }),
      }
    });
  };

  if (app.userAgent.browser === 'ie') {
    $stateProvider
      .state('home', {
        url: '/home',
        views: {
          'main': {
            templateUrl: 'menu/home/index.html',
            controller: 'HomeController',
            controllerAs: 'ctrl'
          }
        },
        params: {
          disableDotBarn: true
        }
      })
      .state('cow-register', {
        url: '/cow/register',
        views: {
          'main': {
            templateUrl: 'menu/cow/register/index.html',
            controller: 'CowRegisterController',
            controllerAs: 'ctrl'
          }
        },
        params: {
          formData: null
        },
      })
      .state('master', {
        url: '/master',
        views: {
          'main': {
            templateUrl: 'menu/master/index.html',
            controller: 'MasterController as master'
          }
        },
      })
      .state('account', {
        url: '/account',
        views: {
          'main': {
            templateUrl: 'menu/account/index.html',
            controller: 'AccountController',
            controllerAs: 'ctrl'
          }
        }
      }).state('cowGroup', {
        url: '/cow_group',
        views: {
          'main': {
            templateUrl: 'menu/cow-group/index.html',
            controller: 'CowGroupIndexController',
            controllerAs: 'ctrl'
          }
        },
      })
      .state('helpMovieIndex', {
        url: '/help-movies',
        views: {
          'main': {
            templateUrl: 'menu/help-movies/list/index.html',
            controller: 'HelpMoviesListController',
            controllerAs: 'ctrl'
          }
        },
        params: {
          callerState: ''
        },
      })
      .state('helpMovieDetail', {
        url: '/help-movies/:id',
        views: {
          'main': {
            templateUrl: 'menu/help-movies/detail/index.html',
            controller: 'HelpMovieDetailController',
            controllerAs: 'ctrl'
          }
        }
      })
      .state('alert-mail', {
        url: '/alert-mail',
        views: {
          'main': {
            templateUrl: 'menu/alert-mail-entry/index.html',
            controller: 'AlertMailIndexController',
            controllerAs: 'ctrl'
          }
        }
      });
  } else {
    addReactState('login-main', 'sign-up-sms', '/sign-up/sms', {disableMainHeader: true});

    const loginParams = {
      disableMainHeader: true,
      redirectToName: '',
      redirectParams: {},
    };
    addReactState('login-main', 'login-sms', '/login/sms', loginParams);

    addReactState('desktop', 'home', '/home');
    addReactState('desktop', 'cow-register', '/cow/register', {disableDotBarn: false});
    addReactState('desktop', 'group-home', '/group/home');
    addReactState('desktop', 'group-headage-details-farm', '/group/headage/details/farm');
    addReactState('desktop', 'group-headage-details', '/group/headage/details');
    addReactState('desktop', 'external-auth', '/external/auth', {disableDotBarn: true, disableMainHeader: true});
    addReactState('desktop', 'external-list', '/external/list');
    addReactState('desktop', 'master', '/master');
    addReactState('desktop', 'account', '/account');
    addReactState('desktop', 'cowGroup', '/cow_group');
    addReactState('desktop', 'helpMovieIndex', '/help-movies');
    addReactState('desktop', 'helpMovieDetail', '/help-movies/:id', {disableDotBarn: true, disableMainHeader: true});
    addReactState('desktop', 'sms-entry-url', '/sms/entry-url');
    addReactState('desktop', 'certificate-ai-specific', '/certificate-ai/specific/:menu_id');
    addReactState('desktop', 'alert-mail', '/alert-mail');
    addReactState('desktop', 'alertSensor', '/alert-sensor');
    addReactState('desktop', 'reproductionCalendar', '/reproduction/calendar/:type/:date');
    addReactState('mobile', 'mobile-top', '/mobile/home');
    addReactState('mobile', 'mobileCowEdit', '/mobile/cow/:cowId/edit');
    addReactState('mobile', 'mobileNotificationList', '/mobile/notification-list');
    addReactState('mobile', 'mobileNotificationDetail', '/mobile/notification-detail/:notificationId');
    addReactState('mobile', 'mobileCowGroupList', '/mobile/group/list');
    addReactState('mobile', 'mobileOperationHistory', '/mobile/operation-history');
    addReactState('mobile', 'mobileCustomlistList', '/mobile/customlist/list');
    addReactState('mobile', 'mobileCustomlistRuntime', '/mobile/customlist/runtime/:customlistId?cowGroupId');
    addReactState('mobile', 'mobileExternalList', '/mobile/external/list');
    addReactState('mobile', 'mobileMasterSpermDetail', '/mobile/master/sperm/:spermId');
    addReactState('mobile', 'mobileMasterSperm', '/mobile/master/sperm');
    addReactState('mobile', 'mobileMasterMedicineDetail', '/mobile/master/medicine/:medicineId');
    addReactState('mobile', 'mobileMasterMedicine', '/mobile/master/medicine');
    addReactState('mobile', 'mobileMasterEmbryoDetail', '/mobile/master/embryo/:embryoId');
    addReactState('mobile', 'mobileMasterEmbryo', '/mobile/master/embryo');
    addReactState('mobile', 'mobileMasterCowLabelDetail', '/mobile/master/cowLabel/:cowLabelId');
    addReactState('mobile', 'mobileMasterCowLabel', '/mobile/master/cowLabel');
    addReactState('mobile', 'mobileFarmSetting', '/mobile/setting/farm');
    addReactState('mobile', 'mobileSetting', '/mobile/setting');
    addReactState('mobile', 'mobileReproductionPlannedEventsList', '/mobile/reproduction-planned-events/list/:date/:eventType');
    addReactState('mobile', 'mobileAlertSensor', '/mobile/alert-sensor');
  }
});

app.constant('appConfig', {
  timeZone: {
    // Farm Region Timezone
    region: {
      name: 'Asia/Tokyo',
      offset: -540
    },
    // Browser Timezone
    client: {
      name: moment.tz.guess(),
      offset: moment().utcOffset() * (-1),
    }
  },
  // U-motion Operator Info
  staff: {
    name: ''
  }
});
