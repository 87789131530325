// 乳量積み立てグラフ
// eslint-disable-next-line no-unused-vars
class MilkingStackedBarChart {
  constructor(selector, hasMilkingSchedule, IsMobile) {
    this.selector = selector;
    this.hasMilkingSchedule = hasMilkingSchedule;
    this.isMobile = IsMobile;
    this.customTooltip = this.customTooltip.bind(this);
    this.options = {
      bindto: this.selector,
      padding: {
        right: 15
      },
      size: {
        height: 400,
      },
      data: {
        type: 'bar',
        x: 'x',
        json: {},
        groups: [
          ['1', '2', '3', '4', '5', '6']
        ],
        colors: {
          '1': '#94c0d8',
          '2': '#6aa7c9',
          '3': '#2981b1',
          '4': '#94c0d8',
          '5': '#6aa7c9',
          '6': '#2981b1'
        },
        names: {
          '1': '搾乳1回目',
          '2': '搾乳2回目',
          '3': '搾乳3回目',
          '4': '搾乳4回目',
          '5': '搾乳5回目',
          '6': '搾乳6回目'
        },
        order: null
      },
      axis: {
        y: {
          label: {
            text: '搾乳量(kg)',
            position: 'outer-right'
          },
          min: 0,
          padding: {
            bottom: 0
          }
        },
        x: {
          label: {
            text: '日付',
            position: 'outer-top'

          },
          type: 'timeseries',
          tick: {
            fit: true,
            format: '%m/%d'
          }
        }
      },
      subchart: {
        show: false
      },
      point: {
        show: false
      },
      tooltip: {
        show: true,
        contents: this.customTooltip
      }
    };

    if (!this.hasMilkingSchedule) {
      this.options.data.names['1'] = '乳量';
    }
  }

  /**
   * チャートを表示する
   * 搾乳回数毎に配列で搾乳量を持つデータを渡すこと(最大6回)
   * {
   *   x: ['2017-11-12', '2017-11-13'...],
   *   1: [10, 15...], 搾乳1回目
   *   2: [13, 12...], 搾乳2回目
   *   ...
   * }
   *
   * @param {Object} data { x: [Date, ..], 1: [number, ..], 2: [], ... }
   */
  show(data) {
    this.destroy();

    this.options.data.json = data;
    this.xTickFormat();
    this.xAxis();
    this.chart = c3.generate(this.options);
  }

  xTickFormat() {
    if (this.isMobile) {
      if (!this.options.axis.x.tick.culling) this.options.axis.x.tick.culling = {};
      this.options.axis.x.tick.culling.max = 7;
    }
  }

  xAxis() {
    if (this.isMobile) {
      this.options.axis.x.padding = {
        left: 1000 * 60 * 60 * 24 * 2
      };
    }
  }

  clear() {
    $(this.selector).html('');
  }

  /**
   * カスタムツールチップ用HTML生成
   * @param {Array} d
   * @param {function} defaultTitleFormat
   * @param {function} defaultValueFormat
   * @param {function} color
   */
  customTooltip(d, defaultTitleFormat, defaultValueFormat, color) {
    let valueFormat = defaultValueFormat,
      total = 0;

    if (d.length === 0) {
      return '';
    }

    const title = defaultTitleFormat ? defaultTitleFormat(d[0].x) : d[0].x;
    let text = '<table class="c3-tooltip c3-tooltip-container">' + (title || title === 0 ? '<tr><th colspan="2">' + title + '</th></tr>' : '');

    for (let i = d.length - 1; i >= 0; i--) {
      if (!(d[i] && (d[i].value || d[i].value === 0))) {
        continue;
      }

      // ラベルを設定
      let name = this.hasMilkingSchedule ? d[i].name : '乳量';

      // 値を設定
      let value = valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index);
      total += parseFloat(value);

      // 背景色
      let bgColor = color(d[i].id);

      text += this.getRow(d[i].id, name, value, bgColor);
    }

    if (this.hasMilkingSchedule) {
      text += this.getTotal(total);
    }

    return text;
  }

  /**
   * カスタムツールチップ1行分のHTMLを生成
   * @param {number} id
   * @param {string} name
   * @param {string} value
   * @param {string} bgColor
   * @returns {string}
   */
  getRow(id, name, value, bgColor) {
    let text = '<tr class="' + id + '">';

    if (this.hasMilkingSchedule) {
      text += '<td class="name"><span style="background-color:' + bgColor + '"></span>' + name + '</td>';
    } else {
      text += '<td class="name"><span style="background-color:' + bgColor + '"></span>乳量</td>';
    }
    text += '<td class="value">' + value.toLocaleString() + '</td>';
    text += '</tr>';

    return text;
  }

  /**
   * 合計行のHTMLを生成
   * @param {number} total
   */
  getTotal(total) {
    let text = '<tr><td class="name"><span style="background-color: white"></span>合計</td>';
    text += '<td class="total">' + total.toLocaleString() + '</td></tr></table>';

    return text;
  }

  destroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
