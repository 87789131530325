class FileUtilService {
  constructor($window) {
    'ngInject';
    this.$window = $window;
  }

  exportAsExcel(matrixData, fileName) {
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.aoa_to_sheet(matrixData);
    XLSX.utils.book_append_sheet(wb, ws, '1');

    XLSX.writeFile(wb, fileName);
  }

  /**
   * CSVファイルを生成してダウンロードする
   * @param {Array.<Array.<string>>} csv
   * @param {string} fileName
   */
  exportAsCsv(csv, fileName) {
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]); // Excelで扱えるようにBOMをつける
    const blob = new Blob([bom, csv], {type: 'text/csv'});
    let url = null;
    if (this.$window.navigator.msSaveBlob) {
      url = this.$window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      url = this.$window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }
}

app.service('FileUtilService', FileUtilService);
