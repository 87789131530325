class FatteningStageAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/fattening/stage');
  }

  update(params) {
    return this.APIService.post('/fattening/stage', params);
  }

  cow(cowId) {
    return this.APIService.get(`/fattening/stage/cow/${cowId}`);
  }

  summary() {
    return this.APIService.get('/fattening/stage/summary');
  }
}

app.service('FatteningStageAPI', FatteningStageAPI);
