class DepositFatteningReportAPI {
  constructor(
    APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/deposit/fattening/report');
  }
}

app.service('DepositFatteningReportAPI', DepositFatteningReportAPI);
