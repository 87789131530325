class MobileStickyAlertController {
  constructor(
    $rootScope,
    $state,
    $stateParams,
    DateTimeUtilFactory,
    AlertService,
    SessionCache,
    NotificationsService
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.DateTimeUtilFactory = DateTimeUtilFactory;
    this.AlertService = AlertService;
    this.NotificationsService = NotificationsService;
    this.alertCounts = {};
    this.deregisters = [];

    this.farm = SessionCache.farm();

    this.deregisters.push(this.$rootScope.$on('$stateChangeSuccess', () => this.setIsShowAlert()));

    this.init();
  }

  $onDestroy() {
    this.deregisters.forEach((d) => d());
  }

  init() {
    this.NotificationsService.index().then((res) => {
      this.notification = Notification.decorate(res.data).find((notification) => {
        return notification.category.key === 'incident' && notification.priority === 1;
      });

      this.$rootScope.isShowAlertNotification = this.showAlertNotification();

      if (this.showAlertNotification()) {
        this.notificationId = this.notification.id;
      }
    }).catch((err) => console.error(err));

    this.AlertService.alertDysstasiaDigest().then((res) => {
      const summary = res.data;
      this.alertCounts.dysstasia = summary.unresolved;
      this.$rootScope.isShowAlertDysstasia = this.showAlertDysstasia();
    }).catch((err) => console.error(err));
  }

  isBeef() {
    return this.farm.data.farmKind === '肉用';
  }

  goToAlertNotification() {
    const caller = {
      name: this.$state.current.name,
      params: this.$stateParams
    };

    this.$state.go('mobileNotificationDetail', {
      notificationId: this.notificationId,
      caller
    });
  }

  goToAlertDysstasia() {
    const caller = {
      name: this.$state.current.name,
      params: this.$stateParams
    };

    this.$state.go('mobile-alert-dysstasia', {
      caller
    });
  }

  showAlert() {
    if (this.$rootScope.disableLegacyMobileStickyAlert) return false;

    return this.showAlertNotification() || this.showAlertDysstasia();
  }

  showAlertNotification() {
    return !!this.notification &&
      this.$state.current.name !== 'mobileNotificationList' &&
      this.$state.current.name !== 'mobileNotificationDetail';
  }

  showAlertDysstasia() {
    return this.isBeef() &&
      this.alertCounts.dysstasia > 0 &&
      this.$state.current.name !== 'mobile-alert-dysstasia';
  }

  setIsShowAlert() {
    this.$rootScope.isShowAlertNotification = this.showAlertNotification();
    this.$rootScope.isShowAlertDysstasia = this.showAlertDysstasia();
  }
}

app.controller('MobileStickyAlertController', MobileStickyAlertController);
