// eslint-disable-next-line no-unused-vars
class DhiImportHistoryGenerator {
  generateColumnDefs(columns) {
    return columns.filter((column) => {
      return !column.hidden;
    }).map((column) => {
      return {
        field: column.field,
        name: column.name,
        width: column.width,
        disableSorting: column.disableSorting,
        hiddenName: column.hiddenName,
        headerCellTemplate: this.generateHeaderCellTemplate(column),
        headerCellClass: () => {
          return 'ui-grid-sorted-none';
        },
        cellTemplate: this.generateCellTemplate(column),
      };
    });
  }

  generateHeaderCellTemplate(column) {
    return `
      <div
        ng-click="grid.appScope.clickHeaderCell('${column.field}', ${column.disableSorting})"
        ng-class="grid.appScope.classHeaderCell('${column.field}')"
        ng-style="grid.appScope.styleHeaderCell('${column.field}')"
      >
        <div class="customlist-th-inner">
          <span>${column.hiddenName ? '' : column.name}</span>
          ${column.disableSorting ? '' : '<div class="sort-icon"></div>'}
        </div>
      </div>`;
  }

  generateCellTemplate(column) {
    if (column.field === 'errorCount') {
      return `
        <div ng-class="grid.appScope.classGridError(row.entity.id, row.entity.errorCount)">
          <p ng-bind="row.entity.errorCount" class="contents__uiGridErrorCount"></p>
          <div
            ng-if="row.entity.errorCount > 0"
            ng-click="grid.appScope.showErrorListModal(row.entity.id)"
            class="contents__uiGridErrorLink"
          >
            <p ng-mouseover="grid.appScope.hoverGridError(row.entity.id)" ng-mouseleave="grid.appScope.hoverGridError()" class="icon-s-notice contents__icon--size14 contents__icon--red">
              <span class="uTooltip" data-text="エラー詳細を表示"></span>
            </p>
          </div>
        </div>`;
    } else if (column.field === 'detailLink') {
      return `
        <div
          ng-click="grid.appScope.goToHistoryDetail(row.entity.id)"
        >
          <p class="icon-s-note contents__icon--size14">
            <span class="uTooltip" data-text="取込履歴明細を表示"></span>
          </p>
        </div>`;
    } else if (column.field === 'deleteLink') {
      return `
        <div
          ng-click="grid.appScope.showDeleteModal(row.entity.id)"
        >
          <p class="icon-s-delete contents__icon--size14">
            <span class="uTooltip" data-text="削除"></span>
          </p>
        </div>`;
    }
  }
}
