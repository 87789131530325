class MobileAlertDysstasiaController {
  constructor(
    $rootScope,
    $modal,
    $state,
    $stateParams,
    AlertService,
    DateUtilService,
    SelectCowService,
    CowService,
    Dictionary,
    $timeout,
    DetailsStateFactory,
    PreviousState,
    EventRegistrationSelectionService
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$modal = $modal;
    this.$state = $state;
    this.AlertService = AlertService;
    this.DateUtilService = DateUtilService;
    this.SelectCowService = SelectCowService.init();
    this.CowService = CowService;
    this.Dictionary = Dictionary;
    this.$timeout = $timeout;
    this.DetailsStateFactory = DetailsStateFactory;
    this.PreviousState = PreviousState;
    this.EventRegistrationSelectionService = EventRegistrationSelectionService;

    this.showResolved = $stateParams.resolved || false;
    const selectedCowId = Number($stateParams.cow_id || '0');

    this.title = this.showResolved ? '起立困難(確認済・回復済)' : '起立困難アラート';
    this.isReporting = false;
    this.sortOptionKey = `${this.$state.current.name}.sortOption`;
    this.init(selectedCowId);
  }

  set cowIndex(value) {
    this._cowIndex = value;
    const cow = this.cows[value];
    if (cow) {
      this.drawChart('graph-' + cow.cowId, cow.scores, cow.alertAtDate);
    }
  }

  get cowIndex() {
    return this._cowIndex;
  }

  init(selectedCowId) {
    this.shouldShowDetail = false;
    this.scores = [];
    this.charts = [];
    this._cowIndex = 0;

    const config = {
      cullingMax: 6,
      size: {
        // FIXME: CSSでwidth、heightを%指定してもうまくいかなかったので仕方なく
        width: screen.width * 0.70,
        height: screen.height * 0.225
      },
      padding: {
        top: 10,
        left: 30
      }
    };
    this.chartGenerator = new DysstasiaChartGenerator(config);

    this.sortOption = LocalStorageEntry.use(this.$rootScope.userName, this.sortOptionKey, {
      column: 'combinedGroupName', order: 'asc'
    });

    this.AlertService.getAlertDysstasia().then((res) => {
      this.rawCows = res.data.map((cow) => AlertDysstasia.toViewModel(cow, new Date()));

      this.index();
      this.sort();

      if (selectedCowId) {
        this.onCowClick(selectedCowId);
      }
    });
  }

  index() {
    this.cows = this.rawCows.filter((cow) => {
      if (this.showResolved) {
        return cow.resolved;
      }
      return !cow.resolved;
    });
  }

  report() {
    if (this.isReporting) {
      return;
    }
    this.isReporting = true;

    const cow = this.cows[this.cowIndex];
    if (!cow) {
      return;
    }

    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'mobile/alert-list/dysstasia/dysstasia-report.html',
      controller: 'DysstasiaReportController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'modal-select-standard',
      windowClass: 'switch-farm-modal',
      resolve: {
        params: () => {
          return {
            id: cow.id,
            confirmed: cow.confirmed,
            confirmedStatus: cow.confirmedStatus,
            comment: cow.comment
          };
        }
      }
    });

    modalInstance.result.then((result) => {
      cow.confirmed = true;
      cow.confirmedLabel = '確認済';
      cow.confirmedStatus = result.confirmedStatus;
      cow.confirmedStatusLabel = AlertDysstasia.confirmedStatusCaption(cow.confirmedStatus);
      cow.comment = result.comment;
      cow.resolved = true;
      this.index();
      this.sort();
      this.shouldShowDetail = false;
    }).finally(() => {
      this.isReporting = false;
    });
  }

  confirmedClass(cow) {
    return cow.confirmed ? 'dysstasia-state-confirmed' : 'dysstasia-state-unconfirmed';
  }

  onCloseDetailClick() {
    this.shouldShowDetail = false;
  }

  onCowClick(cowId) {
    this.$timeout(() => {
      this.cowIndex = this.cows.findIndex((cow) => {
        return cow.cowId === cowId;
      });
      this.shouldShowDetail = true;
      // グラフ描画をしつつカルーセルをスクロールしないためのダミー
      this.$timeout(() => {
        this.cowIndex = this.cowIndex;
      });
    });
  }

  onGoToCowDetailClick() {
    const cow = this.cows[this.cowIndex];
    if (!cow) {
      return;
    }

    this.$state.go('mobileCowDetail', {cowId: cow.cowId});
  }

  returnToPreviousState() {
    if (this.PreviousState.name) {
      this.$state.go(this.PreviousState.name, this.PreviousState.params);
    } else {
      this.$state.go('mobile-top');
    }
  }

  isRecovered(cow) {
    return cow.recovered ? true : false;
  }

  drawChart(element, scores, alertAt) {
    if (this.charts[element]) {
      this.charts[element].destroy();
    }
    this.charts[element] = this.chartGenerator.draw('#' + element, scores, alertAt);
  }

  addMarginBottom() {
    if (this.chartVisible) return 'chart-visible';
  }

  onSortButtonClick() {
    if (this.isSortModalOpen) return;
    this.isSortModalOpen = true;

    const params = {
      column: this.sortOption.column,
      order: this.sortOption.order
    };

    this.$modal.open({
      templateUrl: 'mobile/alert-list/dysstasia/sort-modal.html',
      controller: 'MobileAlertDysstasiaSortModalController',
      controllerAs: 'ctrl',
      size: '770',
      resolve: {
        params: () => params
      },
    }).result.then((result) => {
      this.sortOption = result;
      LocalStorageEntry.set(this.$rootScope.userName, this.sortOptionKey, this.sortOption);
      this.sort();
    }).catch((e) => {
      if (e === 'cancel') return;
      console.error(e);
    }).finally(() => this.isSortModalOpen = false);
  }

  sort() {
    const coefficient = this.sortOption.order === 'asc' ? 1 : -1;
    this.cows.sort((a, b) => {
      if (a[this.sortOption.column] > b[this.sortOption.column]) {
        return 1 * coefficient;
      } else if (a[this.sortOption.column] < b[this.sortOption.column]) {
        return -1 * coefficient;
      } else {
        return 0;
      }
    });
  }

  displayEventSelection() {
    this.EventRegistrationSelectionService.displayEventSelection(this.cows[this.cowIndex].cowId, {state: 'mobileAlertDysstasia'});
  }
}

app.controller('MobileAlertDysstasiaController', MobileAlertDysstasiaController);
