class TopTimelineController {
  constructor(
    $modal,
    $q,
    $state,
    blockUI,
    CowGroupService,
    DateTimeUtilFactory,
    DateUtilService,
    FarmHistoryService,
    FarmService,
    SelectionService,
    StandardDialog,
    TimelineService,
    SessionCache
  ) {
    'ngInject';

    this.$modal = $modal;
    this.$q = $q;
    this.$state = $state;
    this.blockUI = blockUI;
    this.CowGroupService = CowGroupService;
    this.DateTimeUtilFactory = DateTimeUtilFactory;
    this.DateUtilService = DateUtilService;
    this.FarmHistoryService = FarmHistoryService;
    this.FarmService = FarmService;
    this.SelectionService = SelectionService;
    this.StandardDialog = StandardDialog;
    this.TimelineService = TimelineService;
    this.canEditFilterItem = !SessionCache.account().isItemFilterApplied();
    this.init();
  }

  init() {
    // 表示時間を本日0時に設定
    this.displayedTime = moment().startOf('day').valueOf();
    this.groupNameMap = {}; // 牛群名のマップ 表示ようだから this に乗せた
    this.calvingDifficultyMap = {}; // 分娩難易度のマップ
    this.unfilteredEvents = []; // イベントデータのコピー。絞り込む前の綺麗なデータ。

    // 絞り込みオプション
    this.checkModel = {
      '乳房炎': true,
      '繁殖障害': true,
      '周産病・代謝病': true,
      '外傷': true,
      '跛行・蹄病': true,
      '感染症': true,
      'その他': true,
      'フレッシュチェック': true,
      '発情': true,
      'ホルモンプログラム': true,
      '種付': true,
      '妊娠鑑定': true,
      '流産': true,
      '分娩': true,
      '採卵': true,
      '牛群移動': true,
      '乾乳': true,
      'ワクチン': true,
      '削蹄': true,
      '作業メモ': true,
      '出荷': true,
      'へい死': true,
      '枝肉成績': true,
      '去勢': true,
      '除角': true,
      '肥育': true,
      '離乳': true,
      '日誌': true,
      '繁殖除外': true,
    };
    this.checkResults = [];
    this.showEditTypes = {
      'nisshi': true
    };

    this.mastitisScoreMap = Mastitis.MASTITIS_SCORES.reduce((acc, cur) => {
      acc[cur.value] = cur.label;
      return acc;
    }, {});

    // 盲乳ラベルをキーから文字に変換する
    this.monyuLabelConvertor = this.TimelineService.monyuLabelConvertor;
  }

  $onInit() {
    // APIをまとめて叩く
    this.$q.all([
      this.FarmService.show(), // 繁殖障害表示切替用
      this.CowGroupService.index(), // 牛群井戸＆乳房炎表示用
      this.SelectionService.index(), // Body表示用の分娩難易度配列作成
      this.TimelineService.index(this.displayedTime), // イベントデータ取得
    ]).then((res) => {
      this.farmKind = res[0].farmKind;

      // 牛群名のマップ作成
      res[1].data.forEach((group) => {
        this.groupNameMap[group.cowGroupId] = group.cowGroupName || '';
      });

      // 分娩難易度のマップ作成
      res[2].calvingDifficulty.forEach((calvingDifficulty) => {
        this.calvingDifficultyMap[calvingDifficulty.value] = calvingDifficulty.label;
      });

      // 絞り込む前だからそのまま参照
      this.filteredEvents = this.unfilteredEvents =
        this.TimelineService.decorateEvents(this.formatEventsData(res[3].data));

    }).catch((err) => console.error(err));
  }

  // 編集・削除ボタン表示トグル
  isShowEditType(type) {
    return this.showEditTypes[type];
  }

  shouldShowResult(category, value) {
    if (!value) return false;
    switch (category) {

    default:
      if (value === '良好') return false;
    }
    return true;
  }

  // jqDatePickerから日付けを選択してGET
  selectDate() {
    this.getEvents(this.displayedTime);
  }

  // 翌日の日付けでGET
  nextDayEvents() {
    this.displayedTime = this.traverseDate(true);
    this.getEvents(this.displayedTime);
  }

  // 前日の日付けでGET
  prevDayEvents() {
    this.displayedTime = this.traverseDate(false);
    this.getEvents(this.displayedTime);
  }

  // 種付けの内容表示用
  selectRow(cowId) {
    this.$state.go('cowDetail', {cowId: cowId});
  }

  // フィルタークリア
  checkClear() {
    Object.keys(this.checkModel).forEach((key) => {
      this.checkModel[key] = false;
    });
    this.updateTimeline();
  }

  // 絞込内容変更したときのタイムライン更新処理
  updateTimeline() {
    // データがない時 or そもそもイベントがない時、何もしない。
    if (!this.unfilteredEvents || !this.unfilteredEvents.length) {
      return;
    }

    // チェックしたフィルター項目を洗い出して
    const checkedResults = Object.keys(this.checkModel).filter((value) => this.checkModel[value]);

    // チェックしてない項目を表示しないようにフィルターする
    this.filteredEvents = this.unfilteredEvents.filter((event) => {
      return checkedResults.indexOf(event.eventName) > -1;
    });
  }

  edit(historyId, eventType) {
    if (eventType !== 'nisshi') {
      return;
    }

    this.viewMode = new ViewMode('update');
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'history/nisshi/form.html',
      controller: 'FarmHistoryController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            historyId: historyId,
            viewMode: this.viewMode
          };
        }
      }
    }).result.then(() => {
      this.getEvents(this.displayedTime);
    });
  }

  delete(index, eventId, eventType) {
    if (eventType !== 'nisshi') return;
    this.deleteFarmHistory(eventId);

  }

  deleteFarmHistory(eventId) {
    this.StandardDialog.showDeleteConfirm({
      title: '牧場日誌の削除',
      text1: '日誌イベント'
    }).result.then((modalResult) => {
      if (!modalResult) return;

      this.blockUI.start('削除中');
      this.FarmHistoryService.deleteFarmHistory(eventId).then((result) => {
        this.getEvents(this.displayedTime);
        this.blockUI.stop();
      }).catch((err) => console.error(err));
    });
  }

  toggleContents($event, isCollapsed, eventIndex) {
    if ($event.target.className === 'icon-l-arrow-A-d01') return isCollapsed;
    // 開閉アニメーション
    const commentDiv = document.querySelector(`#timeline-top .comment${eventIndex}`);
    if (!commentDiv) return isCollapsed;
    commentDiv.style.maxHeight = isCollapsed ? `${commentDiv.scrollHeight}px` : '0px';
    return !isCollapsed;
  }

  commentClass(eventIndex) {
    return `comment comment${eventIndex}`;
  }

  togglableClassIf(eventIndex) {
    const commentDiv = document.querySelector(`#timeline-top .comment${eventIndex}`);
    return (commentDiv && commentDiv.scrollHeight > 0) ? 'togglable' : '';
  }

  filtering() {
    $('.filter-list').slideToggle('fast');
  }

  entry() {
    this.viewMode = new ViewMode('create');
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'history/nisshi/form.html',
      controller: 'FarmHistoryController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            historyId: '',
            viewMode: this.viewMode
          };
        }
      }
    }).result.then(() => {
      this.getEvents(this.displayedTime);
    });
  }

  /**
   * イベントデータを整理する
   * @param {Array} events Timeline APIからのレスポンスデータ
   */
  formatEventsData(events) {
    return this.DateTimeUtilFactory.parseClientDateForHistories(events) // <- このステップいらなくない？
      .filter((event) => event.eventName !== '出荷停止解除')
      .map((event) => {
        if (event.masterMedicineIds) {
          event.selectedMedicines = TimelineItem.toMedicationItem(event.medications);
        }

        if (event.eventType === 'lame') {
          event.groupedLameDiagnosises = CowEvent.groupLameDiagnosisesByPart(event);
          event.eventName = EventType.eventNameForLegacy(event.eventType);
        }

        if (event.eventType === 'tanetsuke') {
          event.spermName = event.masterSpermName || '-';
        }

        if (event.eventType === 'carcass') {
          if (event.dressedCarcassUnitPrice) {
            event.dressedCarcassUnitPrice = this.formatComma(event.dressedCarcassUnitPrice);
          }

          if (event.dressedCarcassSalesPrice) {
            event.dressedCarcassSalesPrice = this.formatComma(event.dressedCarcassSalesPrice);
          }
        }

        event.occurredAtMMDD = this.DateUtilService.toMMDD(event.occurredAt);
        event.treatedAtHHMM = event.treatmentDiseaseDate ?
          this.DateUtilService.toHHmm(Number(event.treatmentDiseaseDate)) :
          null;
        return event;
      });
  }

  formatComma(value) {
    if (!StringUtil.isDecimal(value)) {
      return value;
    }

    return Number(value).toLocaleString();
  }

  /**
   * 表示日付けを±する
   * @param {bool} forward
   */
  traverseDate(forward) {
    return forward ?
      moment(this.displayedTime).add(1, 'day').valueOf() :
      moment(this.displayedTime).subtract(1, 'day').valueOf();
  }

  /**
   * 指定日付けのイベントをGETせよ！
   * @param {string} targetDate unix time
   */
  getEvents(targetDate) {
    this.TimelineService.index(targetDate).then((res) => {
      this.filteredEvents = this.unfilteredEvents =
        this.TimelineService.decorateEvents(this.formatEventsData(res.data));
      // フィルターの設定を新しいタイムラインに反映
      this.updateTimeline();
    }).catch((err) => console.error(err));
  }

  shouldShowLameDiagnosisOnTimeline(event) {
    return ['1', '2', '3', '4'].some((index) => {
      return event['lameAffectedLimb' + index] !== null;
    });
  }

  shouldShowLocomotionScoreOnTimeline(event) {
    return event.locomotionScore !== '';
  }

  shouldShowFilterItem(price) {
    return price && this.canEditFilterItem;
  }
}

function topTimelineComponent() {
  return {
    templateUrl: 'timeline/top/timeline.html',
    controller: TopTimelineController,
    bindings: {
    }
  };
}

app.component('topTimeline', topTimelineComponent());
