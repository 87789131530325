function dhiInputFilesDirective($parse) {
  return {
    restrict: 'A',
    scope: {
      addSelectedFiles: '&'
    },
    link: function(scope, element, attrs) {
      const name = attrs.dhiInputFiles;
      const model = $parse(name);
      const modelSetter = model.assign;

      element.bind('change', () => {
        scope.$apply(() => {
          const inputFiles = element[0].files;
          let files = [];

          Array.from(inputFiles).forEach((inputFile) => {
            files.push(inputFile);
          });

          modelSetter(scope, inputFiles);
          scope.addSelectedFiles({
            name: name,
            files: files
          });
        });
      });

      scope.$watch(attrs.dhiInputFiles, () => {
        element.val('');
      });
    }
  };
}

app.directive('dhiInputFiles', ['$parse', dhiInputFilesDirective]);
