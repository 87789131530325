class MobileBleIdHistoryModalController {
  constructor(
    $modalInstance,
    $timeout,
    blockUI,
    BleIdService,
    MobileEditBleIdConfirmModal,
    modalDialogFactory,
    param
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.BleIdService = BleIdService;
    this.MobileEditBleIdConfirmModal = MobileEditBleIdConfirmModal;
    this.modalDialogFactory = modalDialogFactory;
    this.cowId = param.cowId;
    this.bleIds = param.bleIds.reverse();
    this.lastBleId = this.bleIds[0];
  }

  onDelete() {
    if (!this.lastBleId) return;

    const blockUIMoment = (message) => {
      this.blockUI.start(message);
      this.$timeout(() => {
        this.blockUI.stop();
      }, 1000);
    };

    const showErrorMessage = (message) => {
      this.modalDialogFactory.showMobileAlertDialog({
        texts: [message]
      });
    };

    const bleIdEndDateLabel = DateUtil.isInfiniteDate(this.lastBleId.endDate) ? '-' : this.lastBleId.endDateYYYYMMDD;

    this.MobileEditBleIdConfirmModal.open({
      title: '確認',
      texts: [
        'センサー番号の履歴を削除すると対象期間の活動量が表示されなくなります。下記のセンサー番号履歴を削除しますか？',
        `センサー番号：${this.lastBleId.bleId}`,
        `適用日：${this.lastBleId.startDateYYYYMMDD}`,
        `解除日：${bleIdEndDateLabel}`
      ],
      yesLabel: '削除する',
      noLabel: 'キャンセル'
    }).result.then((r) => {
      if (r) {
        this.blockUI.start('解除中');
        this.BleIdService.delete(this.cowId, this.lastBleId.startDate)
          .then(() => {
            this.blockUI.stop();
            blockUIMoment('履歴の削除が完了しました');
            this.$modalInstance.close(true);
          })
          .catch((e) => {
            console.error(e);
            this.blockUI.stop();
            showErrorMessage(ErrorUtil.formatErrorMessage(e.data.messages));
          });
      }
    });
  }

  onCancel() {
    this.$modalInstance.close(false);
  }
}

app.controller('MobileBleIdHistoryModalController', MobileBleIdHistoryModalController);
