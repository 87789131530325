/**
 * 分娩アラートのリストアイテムコンポーネントです
 *
 * ex.
 *   <alert-calving-list-item
 *     alert-calving="alertCalving"
 *     is-show-pedigree=isShowPedigree"
 *     on-cow-no-click="onCowNoClick(id)"
 *     on-report-click="onReportClick(id)"
 *   ></alert-calving-report-form>
 *
 * プロパティ:
 *  @param {Object} alert-calving 分娩アラートオブジェクト
 *  @param {boolean} is-show-pedigree true: 血統を表示
 *  @param {Function} on-cow-no-click 牛番号クリック時に発生するイベント idを引数で渡す
 *  @param {Function} on-report-click 確認結果を報告ボタンクリック時に発生するイベント idを引数で渡す
 */
class AlertCalvingListItemController {
  constructor() {
    'ngInject';
  }

  confirmedStatusClass(confirmedStatus) {
    if (confirmedStatus === 'sign') {
      return 'confirmed-status-sign';
    } else if (confirmedStatus === 'no_sign') {
      return 'confirmed-status-no-sign';
    } else {
      return '';
    }
  }

  alertLevelClass(alertLevel) {
    return alertLevel === 'HIGH' ? 'alert-level-high' : 'alert-level-low';
  }

  handleCowNoClick() {
    this.onCowNoClick({id: this.alertCalving.id});
  }

  handleReportClick() {
    this.onReportClick({id: this.alertCalving.id});
  }
}

function alertCalvingListItemComponents() {
  return {
    templateUrl: 'menu/alert/calving/list-item.html',
    controller: AlertCalvingListItemController,
    bindings: {
      alertCalving: '<',
      isShowPedigree: '<',
      onCowNoClick: '&',
      onReportClick: '&'
    }
  };
}

app.component('alertCalvingListItem', alertCalvingListItemComponents());
