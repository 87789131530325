class DesktopReactAppController {
  constructor(
    $stateParams,
    PreviousState
  ) {
    'ngInject';

    this.PreviousState = PreviousState;
    this.disableMainHeader = $stateParams.disableMainHeader;
  }
}

app.controller('DesktopReactAppController', DesktopReactAppController);
