class ReportSummaryService {
  constructor(
    $q,
    DateUtilService,
    ReproductionPerformanceAPI,
    CarcassCharacteristicService,
    ReportSummaryCategorizerService
  ) {
    'ngInject';

    this.$q = $q;
    this.DateUtilService = DateUtilService;
    this.ReproductionPerformanceAPI = ReproductionPerformanceAPI;
    this.CarcassCharacteristicService = CarcassCharacteristicService;
    this.ReportSummaryCategorizerService = ReportSummaryCategorizerService;
  }

  /**
   * サマリーデータをロードする
   *
   * @param {Date} startDate 取得開始日
   * @param {Date} endDate 取得終了日
   * @return {Promise}
   */
  load(startDate, endDate) {
    return this.$q.all([
      this.ReproductionPerformanceAPI.index(startDate, endDate),
      this.CarcassCharacteristicService.monthly(startDate, endDate),
    ]).then((res) => {
      const summaries = {reproductionPerformance: [], carcassCharacteristic: []};

      if (res[0].data.length) {
        const complementedSummaries = this.complementSummaries(res[0].data, startDate, endDate);

        summaries.reproductionPerformance = this.ReportSummaryCategorizerService
          .reproductionPerformance(complementedSummaries);
      }

      if (res[1].data.length && res[1].data.some((r) => r.soldNumber !== 0)) {
        const complementedSummaries = this.complementSummaries(res[1].data, startDate, endDate);
        summaries.carcassCharacteristic = this.ReportSummaryCategorizerService
          .carcassCharacteristic(complementedSummaries);
      }

      return summaries;
    }).catch((err) => console.error(err));
  }

  // private

  /**
   * 不足しているサマリーを補完する
   * [example]
   * console.log(summaries) <- [{yearMonth: '201801', ...}, {yearMonth: '201802', ...}]
   * const complementedSummaries = complementSummaries(summaries, new Date('2018-01-01'), new Date('2018-03-01'))
   * console.log(summaries) <- [{yearMonth: '201801', ...}, {yearMonth: '201802', ...}, {yearMonth: '201803'}]
   *
   * @param {Array<Object>} summaries
   * @param {Date} startDate
   * @param {Date} endDate
   * @return {Array<Object>} 補完されたサマリー
   */
  complementSummaries(summaries, startDate, endDate) {
    const complementedSummaries = [];

    for (let current = new Date(startDate.getTime()); current <= endDate;
      current = this.DateUtilService.addMonths(current, 1)
    ) {
      complementedSummaries.push({yearMonth: this.DateUtilService.toYYYYMM(current)});
    }

    summaries.forEach((s) => {
      const temp = complementedSummaries.find((d) => Number(d.yearMonth) === Number(s.yearMonth));
      if (temp) Object.assign(temp, s);
    });

    return complementedSummaries;
  }
}

app.service('ReportSummaryService', ReportSummaryService);
