class ShipmentService {
  constructor(
    APIService
  ) {
    'ngInject';
    this.APIService = APIService;
  }

  /**
  * @param {Boolean} isDepositor 預託元牧場のアカウントの場合のみ true
  * @param {Object} params リクエストパラメータ（start_date, end_date）
  * @return {Array} 出荷履歴の配列を返す
  */
  searchShipments(isDepositor, params) {
    const paramString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
    if (isDepositor) return this.APIService.get('/deposit/shipment/search?' + paramString);
    return this.APIService.get('/shipment/search?' + paramString);
  }

  /**
  * @param {Number} shipmentId 出荷ID
  * @return {Array} 出荷牛の生産データの配列を返す
  */
  searchProductionCertificates(shipmentId) {
    return this.APIService.get('/shipment/' + String(shipmentId) + '/production_certificate');
  }
}

app.service('ShipmentService', ShipmentService);
