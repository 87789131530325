class DepositMasterMedicineService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  all() {
    return this.APIService.get('/deposit/master_medicine/all');
  }

  available(qwert) {
    return this.APIService.get(`/deposit/master_medicine?qwert=${qwert}`);
  }
}

app.service('DepositMasterMedicineService', DepositMasterMedicineService);
