// eslint-disable-next-line no-unused-vars
class GroupTransferHistoryCurrentFarm {
  static get COLUMNS() {
    return [{
      columnId: 'selected',
      name: '選択',
      width: 50,
    }, {
      columnId: 'transferDate',
      name: '異動日',
      width: 100,
      enableSorting: true,
      enableFiltering: true,
      conditionType: 'DATE'
    }, {
      columnId: 'inOut',
      name: '転出入',
      width: 100,
      enableSorting: true,
      enableFiltering: true,
      conditionType: 'SELECTION',
      selectionOptions: [{
        value: 'all',
        label: '全て',
        selected: false
      }, {
        value: 'in',
        label: '転入',
        selected: false
      }, {
        value: 'out',
        label: '転出',
        selected: false
      }]
    }, {
      columnId: 'inOutFarmName',
      name: '異動元／先牧場',
      width: 130,
      enableSorting: true
    }, {
      columnId: 'cowNo',
      name: '牛番号',
      width: 90,
      enableSorting: true
    }, {
      columnId: 'cowUid',
      name: '個体識別番号',
      width: 130,
      enableSorting: true
    }, {
      columnId: 'introduceDate',
      name: '導入日',
      width: 130,
      enableSorting: true
    }, {
      columnId: 'transferType',
      name: '異動理由',
      width: 130,
      enableSorting: true,
      enableFiltering: true,
      conditionType: 'SELECTION',
    }, {
      columnId: 'sensor',
      name: 'センサー情報',
      width: 150,
      enableSorting: true
    }, {
      columnId: 'notes',
      name: 'コメント',
      width: 150,
      enableSorting: true
    }, {
      columnId: 'cowState',
      name: '在籍状況',
      width: 150,
      enableSorting: true,
      enableFiltering: true,
      conditionType: 'SELECTION',
      selectionOptions: [{
        value: 'all',
        label: '全て',
        selected: false
      }, {
        value: 'enrolledOnly',
        label: '在籍牛のみ',
        selected: false
      }]
    }];
  }

  static get IN_OUT_MAP() {
    return [{
      value: 'in',
      label: '転入'
    }, {
      value: 'out',
      label: '転出'
    }];
  }

  static get COW_STATE_MAP() {
    return [{
      value: 'trasfer_out',
      label: '転出'
    }, {
      value: 'sold',
      label: '出荷'
    }, {
      value: 'died',
      label: 'へい死'
    }];
  }

  static updateCow(cows, transferTypes) {
    return cows.map((cow) => {
      return {
        origin: angular.copy(cow),
        cowId: cow.cowId,
        transferDate: DateUtil.toYYYYMMDD(cow.transferDate),
        inOut: GroupTransferHistoryCurrentFarm.decideInOutLabel(cow.inOut),
        inOutFarmName: cow.inOutFarmName,
        cowNo: cow.cowNo,
        cowUid: Cow.formatCowUid(cow.cowUid),
        introduceDate: DateUtil.toYYYYMMDD(cow.introduceDate),
        transferType: GroupTransferHistoryCurrentFarm.decideTransferTypeLabel(transferTypes, cow.transferType),
        sensor: GroupTransferHistoryCurrentFarm.decideSensorLabel(cow.inOut,
          cow.equipedSensorNumber, cow.removedSensorNumber),
        notes: cow.notes,
        cowState: GroupTransferHistoryCurrentFarm.decideCowStateLabel(cow.cowState, cow.endDate),
        canAddEvent: cow.inOut === 'in' && !cow.cowState
      };
    });
  }

  static decideInOutLabel(inOut) {
    return GroupTransferHistoryCurrentFarm.IN_OUT_MAP.find((item) => {
      return item.value === inOut;
    }).label;
  }

  static decideTransferTypeLabel(transferTypes, typeValue) {
    const transferType = transferTypes.find((type) => type.value === typeValue) || [];

    return transferType.label;
  }

  static decideSensorLabel(inOut, equipedSensorNumber, removedSensorNumber) {
    if (inOut === 'in' && equipedSensorNumber) {
      return `${equipedSensorNumber} 設定済`;
    } else if (inOut === 'out' && removedSensorNumber) {
      return `${removedSensorNumber} 解除`;
    }

    return null;
  }

  static decideCowStateLabel(cowState, endDate) {
    if (!cowState) return null;

    const label = GroupTransferHistoryCurrentFarm.COW_STATE_MAP.find((item) => {
      return item.value === cowState;
    }).label;
    const date = DateUtil.toYYYYMMDD(endDate);

    return `${date} ${label}`;
  }
}
