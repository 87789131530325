/**
 * 搾乳量取得のサービス
 */
class MilkingService {
  constructor(
    DateTimeUtilFactory,
    APIService
  ) {
    'ngInject';
    this.DateTimeUtilFactory = DateTimeUtilFactory;
    this.APIService = APIService;
  }

  /**
   * 指定した期間の搾乳量を取得する
   *
   * @param {String} startDate 取得開始日(YYYY-MM-DD)
   * @param {String} endDate 取得終了日(YYYY-MM-DD)
   * @return {Object} Promise
   */
  getMilkingDaily(startDate, endDate) {
    let endpoint = '/milking/daily/farm?';
    const querys = [];
    if (startDate) querys.push(`start_date=${startDate}`);
    if (endDate) querys.push(`end_date=${endDate}`);
    endpoint += querys.join('&');
    return this.APIService.get(endpoint);
  }

  /**
   * 指定月の搾乳量合計を取得する
   *
   * @param {String} month 取得月(YYYYMM)
   * @return {Object} promise
   */
  getMilkingMonthly(month) {
    let endpoint = '/milking/monthly/farm/';
    if (!month) {
      let now = this.DateTimeUtilFactory.parseClientDate(new Date());
      month = String(now.getFullYear()) + String(( '0' + ( now.getMonth() + 1 ) ).slice(-2));
    }
    endpoint += month;
    return this.APIService.get(endpoint);
  }

  getDailySummary(endpoint, params) {
    const querys = [];
    if (params.startDate) querys.push('start_date=' + String(params.startDate));
    if (params.endDate) querys.push('end_date=' + String(params.endDate));
    if (params.statsType) querys.push('stats_type=' + params.statsType);
    endpoint += querys.join('&');
    return this.APIService.get(endpoint);
  }

  getFarmSummary(params) {
    const endpoint = '/milking/daily/farm/summary?';
    return this.getDailySummary(endpoint, params);
  }

  getCowshedSummary(params) {
    const endpoint = `/milking/daily/cowshed/${params.cowshedId}/summary?`;
    return this.getDailySummary(endpoint, params);
  }

  getCowGroupSummary(params) {
    const endpoint = `/milking/daily/group/${params.cowGroupId}/summary?`;
    return this.getDailySummary(endpoint, params);
  }

  getDailySummaryByCowId(params) {
    let endpoint = `/milking/daily/cow/${params.cowId}?`;
    const querys = [];
    if (params.startDate) querys.push('start_date=' + String(params.startDate));
    if (params.endDate) querys.push('end_date=' + String(params.endDate));
    if (params.milkingOrder === 0 || params.milkingOrder) {
      querys.push('milking_order=' + String(params.milkingOrder));
    }
    endpoint += querys.join('&');
    return this.APIService.get(endpoint);
  }
}

app.service('MilkingService', MilkingService);
