class EnvToastController {
  constructor(
    $rootScope,
    SessionCache,
    SwitchFarmDialog
  ) {
    'ngInject';

    this.umEnv = environment.umEnv;
    this.umEnvLabel = this.umEnv === 'prod' ? '本番環境' : `${this.umEnv}環境`;
    this.SwitchFarmDialog = SwitchFarmDialog;
    this.farm = SessionCache.farm();
    this.showToast = SessionCache.account().isAdministrator() && !$rootScope.isEnvToastHide;
  }

  addClassToModuleMode() {
    return `is-${this.umEnv}`;
  }

  close() {
    this.showToast = false;
  }

  switchFarm() {
    this.SwitchFarmDialog.excute();
  }
}

function envToastComponent() {
  return {
    templateUrl: 'components/env-toast/index.html',
    controller: EnvToastController,
  };
}

app.component('envToast', envToastComponent());
