class ViewStateAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  create(toState, fromState, option = {}) {
    const params = {
      bqViewStateTo: toState,
      bqViewStateFrom: fromState,
    };
    Object.keys(option).forEach((k) => {
      params[k] = option[k];
    });

    return this.APIService.post('/view_state', params);
  }
}

app.service('ViewStateAPI', ViewStateAPI);
