class FatteningConfigController {
  constructor(
    $modal,
    $q,
    SessionCache,
    FatteningStageService
  ) {
    'ngInject';
    this.$modal = $modal;
    this.$q = $q;
    this.SessionCache = SessionCache;
    this.FatteningStageService = FatteningStageService;

    this.init();
  }

  init() {
    this.isEditing = false;
    this.farm = this.SessionCache.farm();

    this.FatteningStageService.index().then((res) => {
      this.stages = res.data;
    });
  }

  monthsOfAgeTypeCaption() {
    if (!this.farm) return '';

    if (this.farm.fatteningStageBaseDate() === 'birthday') {
      return '出生日';
    } else if (this.farm.fatteningStageBaseDate() === 'start_fattening_date') {
      return '肥育開始日';
    }
    return '設定なし';
  }

  edit() {
    const stages = angular.copy(this.stages);
    if (!stages.length) {
      stages.push({'name': '前期', 'startMonthsOfAge': '', 'endMonthsOfAge': ''});
      stages.push({'name': '中期', 'startMonthsOfAge': '', 'endMonthsOfAge': ''});
      stages.push({'name': '後期', 'startMonthsOfAge': '', 'endMonthsOfAge': 99});
    }

    this.$modal.open({
      templateUrl: 'menu/setting/fattening/edit.html',
      controller: 'FatteningConfigEditController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        params: () => ({
          templateUrl: 'menu/setting/fattening/stage.html',
          fatteningStageBaseDate: this.farm.fatteningStageBaseDate(),
          stages: stages
        })
      }
    }).result.then((res) => {
      const data = Object.assign({}, this.farm.data, {fatteningStageBaseDate: res.fatteningStageBaseDate});
      const farm = new Farm(data);
      this.SessionCache.store('farm', farm);
      this.init();
    });
  }
}

app.controller('FatteningConfigController', FatteningConfigController);
