class BredAnalysisState { // eslint-disable-line no-unused-vars
  constructor(farm) {
    this.isLoading = false;
    this.isLoaded = true;
    this.hasError = false;
    this.hasNoData = false;
    this.farm = farm;
  }

  init() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const beginYear = 2016;
    const yearDiff = currentYear - beginYear + 1;
    this.baseDate = DateUtil.addMonths(new Date().valueOf(), -1);
    this.endDate = this.baseDate.valueOf();
    this.startDate = DateUtil.addDays(
      DateUtil.addYears(this.endDate, -1).valueOf(),
      1
    );

    const factors = ReproductionPerformance.bredAnalysisFactors(this.farm.isMilk());

    this.factorLabels = factors.reduce((acc, cur) => {
      acc[cur.id] = cur.label;
      return acc;
    }, {});

    const factorSelections = factors.map((f) => ({
      value: f.id,
      label: f.label
    }));

    this.selection = {
      years: Array(yearDiff).fill(0)
        .map((key, index) => ({
          label: (beginYear + index) + '年',
          value: (beginYear + index)
        })),
      months: Array(12).fill(0)
        .map((key, index) => ({
          label: (index + 1) + '月',
          value: (index + 1)
        })),
      factors: factorSelections
    };

    this.factor = 'insemination_code';

    this.rangeYear = this.selection.years.slice(-1)[0].value;
    this.rangeMonthYear = this.baseDate.getFullYear();
    this.rangeMonth = this.baseDate.getMonth() + 1;

    this.report = {
      total: 0,
      details: []
    };

    this.isReversal = false;
    this.isFutureEndDate = false;
    this.isOutOfRange = false;

    return new Promise((resolve) => resolve());
  }

  get factorDisplay() {
    return this.factorLabels[this.factor];
  }

  get isBaseDateValid() {
    return this.baseDate && DateUtil.isValidDate(Number(this.baseDate));
  }

  get shouldDisableMenu() {
    return this.isLoading;
  }

  get shouldShowTable() {
    return !this.hasNoData && !this.isLoading;
  }

  get shouldShowNoData() {
    return this.hasNoData && !this.isLoading;
  }

  get shouldShowIsLoading() {
    return this.isLoading;
  }
}
