class temporaryGroupViewPenController {
  constructor(
    blockUI,
    CustomlistAPI,
    CustomlistRunner
  ) {
    'ngInject';

    this.blockUI = blockUI;
    this.CustomlistAPI = CustomlistAPI;
    this.CustomlistRunner = CustomlistRunner;
  }

  $onChanges(changes) {
    if (changes.cowGroupId) {
      this.index();
    }
  }

  index() {
    const today = DateUtil.today().getTime();
    const option = {
      cowGroupId: this.cowGroupId
    };

    this.blockUI.start('データ取得中');

    this.CustomlistAPI.cowGroups().then((res) => {
      const master = res.data.find((m) => m.configType === 'group_fattening');
      const customlistId = master.customlistId;

      return this.CustomlistRunner.run(
        customlistId,
        today,
        option
      ).then((res) => {
        this.blockUI.stop();

        this.pens = Object.entries(res.data.cows.reduce((acc, cur) => {
          const pen = cur.pen || '-';

          if (!acc[pen]) {
            acc[pen] = [];
          }

          acc[pen].push(cur.cow);

          return acc;
        }, {})).map(([key, value]) => {
          return {
            pen: key,
            cowCount: value.length,
            cowGroupId: this.cowGroupId
          };
        });
      });
    });
  }
}

function temporaryGroupViewPen() {
  return {
    restrict: 'E',
    templateUrl: 'temporary-group-view/activity/pen.html',
    controller: temporaryGroupViewPenController,
    controllerAs: 'ctrl',
    scope: true,
    bindToController: {
      cowGroupId: '<'
    }
  };
}

app.directive('temporaryGroupViewPen', temporaryGroupViewPen);
