class MobileAlertCalvingController {
  constructor(
    $modal,
    $state,
    $stateParams,
    AlertCalvingAPI,
    PreviousState,
    SessionCache
  ) {
    'ngInject';
    this.$modal = $modal;
    this.$state = $state;
    this.AlertCalvingAPI = AlertCalvingAPI;
    this.PreviousState = PreviousState;

    this.isDepositor = SessionCache.farm().isDepositor();
    this.isBreeding = SessionCache.farm().isBreeding();
    this.isConfirmed = $stateParams.confirmed || false;

    this.title = '分娩アラート';
    this.isReporting = false;
    this.init();
  }

  init() {
    this.shouldShowCarousel = false;
    this.currentIndex = 0;

    this.AlertCalvingAPI.index().then((res) => {
      this.rawAlerts = res.data.map((d) => AlertCalving.toViewModel(d));
      this.index();
    });
  }

  index() {
    this.alerts = this.rawAlerts.filter((alert) => {
      return alert.confirmed === this.isConfirmed;
    });
  }

  report(index) {
    if (this.isReporting) return;

    this.isReporting = true;

    const alert = this.alerts[index];
    if (!alert) return;

    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'menu/alert/calving/report.html',
      controller: 'AlertCalvingReportController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'size-normal',
      resolve: {
        params: () => {
          return {
            id: alert.id,
            confirmedStatus: alert.confirmedStatus,
            confirmedComment: alert.confirmedComment
          };
        }
      }
    });

    modalInstance.result.then((result) => {
      alert.confirmed = true;
      alert.confirmedStatus = result.confirmedStatus;
      alert.confirmedStatusLabel = AlertCalving.CONFIRMED_STATUS[result.confirmedStatus],
      alert.confirmedComment = result.confirmedComment;
      this.index();
      this.shouldShowCarousel = false;
    }).finally(() => {
      this.isReporting = false;
    });
  }

  hideCarousel() {
    this.shouldShowCarousel = false;
  }

  showCarousel(id) {
    const index = this.alerts.findIndex((a) => a.id === id);
    this.currentIndex = index;
    this.shouldShowCarousel = true;
  }

  goToCowDetail(index) {
    const alert = this.alerts[index];
    if (!alert) return;

    this.$state.go('mobileCowDetail', {cowId: alert.cowId});
  }
}

app.controller('MobileAlertCalvingController', MobileAlertCalvingController);
