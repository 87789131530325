// eslint-disable-next-line no-unused-vars
class HeadageDetailsGenerator {
  generateColumnDefs(columns) {
    return columns.map((column) => {
      return {
        field: column.itemId,
        label: column.label,
        width: column.width,
        headerCellTemplate: this.generateHeaderCellTemplate(column),
        headerCellClass: () => {
          return 'ui-grid-sorted-none';
        },
        cellTemplate: this.generateCellTemplate(column)
      };
    });
  }

  generateHeaderCellTemplate(column) {
    return `
      <div
        ng-click="grid.appScope.onClickColumnHeader('${column.itemId}', col)"
        ng-class="grid.appScope.classHeaderCell(col)"
      >
        <div class="customlist-th-inner">
          <span>${column.label}</span>
          <div class="sort-icon"></div>
        </div>
      </div>`;
  }

  generateRowTemplate() {
    return `
      <div ng-click="grid.appScope.updateCurrentCow(row.entity)"
        ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
        ng-class="grid.appScope.classCell(row.entity.cowId)"
        class="ui-grid-cell"
        ui-grid-cell>
      </div>
    `;
  }

  generateCellTemplate(column) {
    if (column.itemId === 'cowLabels') {
      return `
        <div class="cow-labels-wrapper">
          <span style="display: inline-block; min-width: 90px;">
            <span style="font-size: 1.2rem; text-align: center;" ng-if="label.length" ng-repeat="label in row.entity.cowLabels track by $index">
              <span class="cow-labels kindBackgroundColor" ng-bind="label"></span>
            </span>
          </span>
        </div>`;
    } else if (column.itemId === 'cowNo') {
      return `
        <div class="ui-grid-cell-contents">
          <a ng-bind="row.entity.cowNo" ng-click="grid.appScope.goToDetails(row.entity.cowId)"></a>
        </div>`;
    } else if (column.itemId === 'medicationSummary') {
      return `<div ng-bind-html="row.entity.medicationSummary" class="ui-grid-cell-contents"></div>`;
    }
  }
}
