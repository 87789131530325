// eslint-disable-next-line no-unused-vars
class ReproductionPlannedEventsListControllerBase {
  constructor(
    ReproductionPlannedEventsAPI,
    SelectCowService,
    $modal,
    FarmService,
    DetailsStateFactory,
    $state,
    $rootScope,
    $stateParams
  ) {
    'ngInject';

    this.ReproductionPlannedEventsAPI = ReproductionPlannedEventsAPI;
    this.$modal = $modal;
    this.SelectCowService = SelectCowService.init();
    this.FarmService = FarmService;
    this.DetailsStateFactory = DetailsStateFactory;
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.eventType = $stateParams.eventType || null;
    this.groupedEvents = [];
  }

  init(date) {
    this.updateDate(date);

    this.FarmService.show().then((res) => {
      const farm = new Farm(res);
      this.farmType = farm.farmType();
      this.typeMilk = this.farmType === Farm.farmTypeMilk;
      this.typeBeef = this.farmType === Farm.farmTypeBeef;

      this.initCallbackParam();
      this.fetchData();
    });
  }

  updateDate(date) {
    this.date = date;
    this.dateDisplay = DateUtil.toYYYYMMDD(date);
  }

  shouldShowPrintAllButton() {
    return this.groupedEvents && this.groupedEvents.length > 0;
  }

  onPrevDateClick() {
    this.updateDate(DateUtil.previousDay(this.date));
    this.fetchData();
  }

  onNextDateClick() {
    this.updateDate(DateUtil.nextDay(this.date));
    this.fetchData();
  }

  onCowNoClick(cowId) {
    this.updateCallbackParam();
    this.DetailsStateFactory.setDisplayTab('dataTab');
    this.$state.go('cowDetail', {cowId: cowId, caller: this.callbackParam});
  }

  selectRow(event) {
    this.updateSelectedRows();
    this.updateCallbackParam();
  }

  selectAllRow(task) {
    task.events.forEach((e) => e.selected = task.selected);
    this.updateCallbackParam();
    this.updateSelectedRows();
  }

  initCallbackParam(date, eventType) {
    this.callbackParam = {
      state: 'reproductionPlannedEventsList',
      name: '繁殖予定',
      params: {
        date: date,
        eventType: eventType
      },
      cowIds: []
    };
  }

  updateCallbackParam() {
    const cowIds = this.groupedEvents
      .map((t) => t.events)
      .reduce((acc, cur) => {
        return acc.concat(cur);
      }, [])
      .map((e) => e.cowId);

    this.callbackParam.params.date = this.date;
    this.callbackParam.params.eventType = this.eventType;
    this.callbackParam.cowIds = cowIds;
  }

  updateSelectedRows() {
    if (this.groupedEvents.length === 0) return;

    const events = this.groupedEvents
      .map((group) => group.events)
      .reduce((acc, cur) => acc.concat(cur))
      .filter((event) => event.selected);

    this.SelectCowService.selectRow(events, null);
    this.updateCallbackParam();
  }

  beforeFetchData() {}

  fetchData() {
    this.beforeFetchData();
    return this.ReproductionPlannedEventsAPI.show(this.date, this.eventType).then((result) => {
      this.groupedEvents = ReproductionPlannedEvent.groupEvents(result.data);

      this.eventCount = this.groupedEvents.reduce((acc, cur) => {
        return acc + cur.events.length;
      }, 0);

      this.updateSelectedRows();
      this.updateCallbackParam();
      this.afterFetchData();
    });
  }

  afterFetchData() {}

  printOne(eventType) {
    const savedEventType = this.eventType;
    this.eventType = eventType;
    this.fetchData().then(() => {
      this.$rootScope.isPrinting = true;
      setTimeout(() => print(), 0);
      setTimeout(() => {
        this.$rootScope.$apply(() => this.$rootScope.isPrinting = false);
        this.eventType = savedEventType;
        this.fetchData();
      });
    });
  }

  printAll() {
    this.$rootScope.isPrinting = true;
    setTimeout(() => {
      print();
      this.$rootScope.$apply(() => this.$rootScope.isPrinting = false);
    });
  }

  openFullScreenWindow() {
    this.$modal.open({
      templateUrl: 'reproduction-planned-events/list/reproduction-planned-events-list.html',
      controller: 'ReproductionPlannedEventsListController',
      controllerAs: 'ctrl',
      size: 'custom-lg'
    });
  }
}
