class ChartGenerator {
  constructor(ActivityAPI, HeatIndexAPI, MilkingService) {
    'ngInject';
    this.ActivityAPI = ActivityAPI;
    this.HeatIndexAPI = HeatIndexAPI;
    this.MilkingService = MilkingService;
  }

  /**
   * 活動量グラフ用のC3データを生成する。
   *
   * @param {Date} baseDate 基準日
   * @param {number} cowId
   * @param {number} days 対象期間の日数
   * @param {Boolean} useCalfManagement 子牛管理機能の利用フラグ
   * @param {Number} birthday 出生日
   * @return {Object} promise
   */
  activityChart(baseDate, cowId, days, useCalfManagement, birthday) {
    if (days === 7) {
      const period = ChartConvertor.generateXAxisDates(baseDate, days, 3);
      return this.ActivityAPI.hourlySummary(cowId, period.startDate, period.endDate).then((res) => {
        return ChartConvertor.activityToC3(period.dates, res.data, 'datehour', useCalfManagement, birthday);
      });
    } else {
      const period = ChartConvertor.generateXAxisDates(baseDate, days);
      return this.ActivityAPI.dailySummary(cowId, period.startDate, period.endDate).then((res) => {
        return ChartConvertor.activityToC3(period.dates, res.data, 'date', useCalfManagement, birthday);
      });
    }
  }

  /**
   * 発情指数グラフ用のC3データを生成する。
   *
   * @param {Date} baseDate 基準日
   * @param {number} cowId
   * @param {number} days 対象期間の日数
   * @return {Object} promise
   */
  heatIndexChart(baseDate, cowId, days) {
    if (days === 7) {
      const period = ChartConvertor.generateXAxisDates(baseDate, days, 1);
      return this.HeatIndexAPI.hourlySummary(cowId, period.startDate, period.endDate).then((res) => {
        return ChartConvertor.heatIndexToC3(period.dates, res.data, 'datehour');
      });
    } else {
      const period = ChartConvertor.generateXAxisDates(baseDate, days);
      return this.HeatIndexAPI.dailySummary(cowId, period.startDate, period.endDate).then((res) => {
        return ChartConvertor.heatIndexToC3(period.dates, res.data, 'date');
      });
    }
  }

  /**
   * 搾乳量グラフ用のC3データを生成する。
   *
   * @param {Date} baseDate 基準日
   * @param {number} cowId
   * @param {number} days 対象期間の日数
   * @return {Object} promise
   */
  milkingChart(baseDate, cowId, days) {
    const period = ChartConvertor.generateXAxisDates(baseDate, days);
    const params = {
      cowId: cowId,
      startDate: DateUtil.toW3CFormat(period.startDate),
      endDate: DateUtil.toW3CFormat(period.endDate)
    };
    return this.MilkingService.getDailySummaryByCowId(params).then((res) => {
      return ChartConvertor.milkingToC3(period.dates, res.data, 'date');
    });
  }
}

app.service('ChartGenerator', ChartGenerator);
