class AlertHeatBoxController {
  constructor(
    $state,
    SessionCache,
    AlertSummaryStateService
  ) {
    'ngInject';
    this.$state = $state;
    this.AlertSummaryStateService = AlertSummaryStateService;

    const farm = SessionCache.farm();

    this.init(SessionCache, farm);
  }

  init(SessionCache, farm) {
    const customlistSystemConfigs = SessionCache.customlistSystemConfigs();
    const config = customlistSystemConfigs.find((config) => config.configType === 'alert_heat');
    if (config) {
      this.customlistId = config.customlistId;
    }

    this.showAlert = farm.isDairy() || farm.isBreeding();
  }

  linkToAlertList() {
    if (this.customlistId) {
      this.$state.go('alertList', {customlistId: this.customlistId});
    }
  }
}

function alertHeatBox() {
  return {
    templateUrl: 'top/alert/heat-box.html',
    controller: AlertHeatBoxController,
    bindings: {
      farm: '<',
    }
  };
}

app.component('alertHeatBox', alertHeatBox());
