/**
 * @deprecated
 * この実装の修正および、新規の利用は禁止です。
 * AccountAPIなどを利用してください。
 */
class AccountService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;

    this.cache = {};
  }

  static get ROLES() {
    return {
      operator: {label: '作業者', editable: true},
      manager: {label: '管理者', editable: false},
      owner: {label: '管理者', editable: false},
      organizer: {label: 'グループ管理者', editable: false},
      supervisor: {label: 'SV', editable: false},
      admin: {label: '特権ユーザー', editable: false},
    };
  }

  static get DEFAULT_ROLE() {
    return 'operator';
  }

  static get REGEX_8_ALPHANUMERIC() {
    return /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/;
  }

  cachedAccount() {
    if (!this.cache.account) {
      this.cache.account = this.APIService.get('/account/current')
        .then((res) => res.data)
        .catch((error) => console.error(error.data.messages));
    }
    return this.cache.account;
  }

  index() {
    return this.APIService.get('/account');
  }

  show(id) {
    return this.APIService.get(`/account/${id}`);
  }

  create(name, email, password, role) {
    const params = {name: name, email: email, password: password, role: role};
    return this.APIService.post('/account', params);
  }

  update(id, name) {
    const params = {name: name};
    return this.APIService.put(`/account/${id}`, params);
  }

  updateUiVersion(uiVersion) {
    const params = {uiVersion: uiVersion};
    return this.APIService.put('/account/current/ui_version', params);
  }

  delete(id) {
    return this.APIService.delete(`/account/${id}`);
  }

  clearCache() {
    this.cache = {};
  }

  validatePassword(password) {
    return AccountService.REGEX_8_ALPHANUMERIC.test(password);
  }
}

app.service('AccountService', AccountService);
