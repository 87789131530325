class UnexpectedDysstasiaInputController {
  constructor(
    $timeout,
    $modalInstance,
    params,
    blockUI,
    appConfig,
    CowService,
    Dictionary,
    DysstasiaAPI,
    CowSearchAPI
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$modalInstance = $modalInstance;
    this.params = params;
    this.blockUI = blockUI;
    this.reporter = appConfig.staff.name;
    this.CowService = CowService;
    this.cowNoLabel = Dictionary.COW.COW_NO;
    this.DysstasiaAPI = DysstasiaAPI;
    this.CowSearchAPI = CowSearchAPI;

    this.initialize();
  }

  initialize() {
    this.viewMode = new ViewMode(this.params.viewMode);
    this.heading = `起立困難の${this.viewMode.label}`;
    this.hours = DateUtil.hours;
    this.minutes = DateUtil.minutes;
    this.placeholder = `${this.cowNoLabel}を選択`;
    this.invalid = true;
    this.selected = {};

    this.CowService.getCowNoList().then((res) => {
      this.cowNoList = res.data;
    }).catch((err) => console.error(err));

    if (this.viewMode.isCreate()) {
      this.input = {
        date: DateUtil.today().getTime(),
        hour: '00',
        minute: '00',
        comment: '',
      };
    } else {
      this.id = this.params.id;
      this.DysstasiaAPI.show(this.id).then((res) => {
        const data = res.data;
        const findingAt = DateUtil.toDate(data.findingAt);

        this.input = {
          date: DateUtil.startOfDay(findingAt),
          hour: DateUtil.format(findingAt, 'HH'),
          minute: DateUtil.format(findingAt, 'mm'),
          comment: data.comment,
        };

        this.selected = {
          cowNo: data.cowNo
        };

        this.setCow(data.cowId);
      });
    }
  }

  validate() {
    this.errorMessage = null;
    this.invalid = true;

    if (!this.input.date) return;
    if (!this.selected.cowNo) return;

    this.invalid = false;
  }

  onChangeSelected() {
    this.selected = this.cowNoList.filter((cow) => {
      return cow.cowId === this.selected.cowId;
    })[0];

    this.setCow(this.selected.cowId);
    this.invalid = false;
  }

  clearSelected() {
    this.invalid = true;
    this.selected = {};
  }

  setCow(cowId) {
    this.CowService.get(cowId).then((res) => {
      const data = res.data;

      this.selected.monthAge = DateUtil.monthAge(Number(data.birthday), DateUtil.today(), 1);
      this.selected.cowGroupName = data.pen ? `${data.cowGroupName} (${data.pen})` : data.cowGroupName;
      this.validate();
    });
  }

  save() {
    this.errorMessage = null;

    if (this.viewMode.isCreate()) {
      this.create();
    } else if (this.viewMode.isUpdate()) {
      this.update();
    }
  }

  create() {
    const cowId = this.selected.cowId;
    const findingAt = DateUtil.addTimeToDate(this.input.date, this.input.hour, this.input.minute);
    const comment = this.input.comment || '';

    this.blockUI.start('登録中');
    this.DysstasiaAPI.create(cowId, findingAt, comment, this.reporter).then((res) => {
      this.blockUI.start('起立困難の登録が完了しました');
      this.$timeout(() => {
        this.blockUI.stop();
      }, 1000);

      this.blockUI.stop();
      this.$modalInstance.close({});
    }).catch((error) => {
      this.errorMessage = ErrorUtil.formatErrorMessage(error.data.messages);
      this.blockUI.stop();
    });
  }

  update() {
    const findingAt = DateUtil.addTimeToDate(this.input.date, this.input.hour, this.input.minute);
    const comment = this.input.comment || '';

    this.blockUI.start('更新中');
    this.DysstasiaAPI.update(this.id, findingAt, comment, this.reporter).then((res) => {
      this.blockUI.start('起立困難の更新が完了しました');
      this.$timeout(() => {
        this.blockUI.stop();
      }, 1000);

      this.blockUI.stop();
      this.$modalInstance.close({
        executed: true
      });
    }).catch((error) => {
      this.errorMessage = ErrorUtil.formatErrorMessage(error.data.messages);
      this.blockUI.stop();
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('UnexpectedDysstasiaInputController', UnexpectedDysstasiaInputController);
