/**
 * cow_eventの配列の検索、部分集合の抽出などの操作を扱う実装です。
 * 基本的にイベントデータの配列は occurred_at の降順でソートされていることを前提にしています。
 */

// eslint-disable-next-line no-unused-vars
class CowEventCollection {
  static existsLaterReproduction(events, cowEvent) {
    const conditionTypes = [
      'freshCheck',
      'hatsujo',
      'hormoneProgram',
      'tanetsuke',
      'ninshinkantei',
      'kannyu',
      'bunben',
      'abort',
      'embryo_recovery',
      'doNotBreed',
    ];

    return events.some((e) => {
      if (e.id === cowEvent.id) return false;

      if (DateUtil.isBeforeDay(cowEvent.occurredAt, e.occurredAt)) {
        return conditionTypes.includes(e.eventType);
      }
      return false;
    });
  }

  static findSameDateCalvedOrAborted(events, cowEvent) {
    const conditionTypes = [
      'bunben',
      'abort',
    ];

    return events.find((e) => {
      if (e.id === cowEvent.id) return false;

      if (DateUtil.isSameDay(cowEvent.occurredAt, e.occurredAt)) {
        return conditionTypes.includes(e.eventType);
      }
      return false;
    });
  }

  static isLastReproduction(events, cowEvent) {
    const firstEvent = events.find((e) => {
      return EventType.isReproductionForLegacy(e.eventType);
    });
    if (firstEvent) {
      return firstEvent.id === cowEvent.id;
    }
    return false;
  }

  static canDelete(events, cowEvent) {
    if (EventType.isCalvedOrAbortedForLegacy(cowEvent.eventType)) {
      return CowEventCollection.isLastReproduction(events, cowEvent);
    } else {
      if (cowEvent.imported || cowEvent.readonly) {
        return false;
      }
      return true;
    }
  }
}
