/**
 * 分娩センサー番号編集モーダルの分娩センサー表示コンポーネント
 *
 * ex.
 *  <mobile-edit-calving-sensor-history-modal-history-box
 *    calving-sensor-history="calvingSensorHistory">
 *  </mobile-edit-calving-sensor-history-modal-hisoty-box>
 *
 * プロパティ:
 *  @param {Object} calving-sensor-history 分娩センサーオブジェクト
 */
class MobileEditCalvingSensorHistoryModalHistoryBoxController {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.calvingSensorNumber =
      CowCalvingSensorHistory.formatCalvingSensorNumber(this.calvingSensorHistory.calvingSensorNumber);
    this.startDate = DateUtil.toYYYYMMDD(this.calvingSensorHistory.startDate);
  }
}

function mobileEditCalvingSensorHistoryModalHistoryBoxComponent() {
  return {
    templateUrl: 'mobile/components/edit-calving-sensor-history-modal/history-box.html',
    controller: MobileEditCalvingSensorHistoryModalHistoryBoxController,
    bindings: {
      calvingSensorHistory: '<'
    }
  };
}

app.component('mobileEditCalvingSensorHistoryModalHistoryBox', mobileEditCalvingSensorHistoryModalHistoryBoxComponent());
