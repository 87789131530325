class AlertMailEntryController {
  constructor(
    $state,
    $timeout,
    $rootScope,
    $modalInstance,
    params,
    blockUI,
    AlertMailAPI,
    SessionCache,
    StandardDialog
  ) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.$modalInstance = $modalInstance;
    this.params = params;
    this.blockUI = blockUI;
    this.AlertMailAPI = AlertMailAPI;
    this.StandardDialog = StandardDialog;

    this.init(SessionCache.farm(), params);
  }

  init(farm, params) {
    this.useAlertDysstasia = farm.useAlertDysstasia();
    this.useAlertCalving = farm.useAlertCalving();
    this.showAlertField = this.useAlertDysstasia && this.useAlertCalving;

    this.records = [
      this.createEmptyRecord()
    ];

    this.titles = [
      'ユーザー名',
      'メールアドレス'
    ];
    if (this.showAlertField) {
      this.titles.push('通知アラート');
    }

    this.errorMessage = '';
  }

  validate(needRequiredField = false) {
    this.records.forEach((r) => this.validateEach(r, needRequiredField));
    if (this.records.some((r) => r.invalid)) {
      return false;
    }

    return true;
  }

  validateEach(record, needRequiredField) {
    const errors = {
      userName: [],
      mailAddress: [],
      alertNotification: [],
    };

    if (needRequiredField) {
      if (!record.userName) {
        errors.userName.push('ユーザー名が未入力です');
      }
      if (!record.mailAddress) {
        errors.mailAddress.push('メールアドレスが未入力です');
      }
    }

    if (record.mailAddress) {
      const result = StringUtil.smartlyValidateEmail(record.mailAddress);
      if (!result.valid) {
        result.messages.forEach((m) => errors.mailAddress.push(m));
      }
    }

    if (this.showAlertField) {
      if (!record.dysstasia && !record.calving) {
        errors.alertNotification.push('いずれかを選択してください');
      }
    }

    record.errors = errors;
    record.invalid = Object.values(errors).some((ar) => ar.length > 0);
  }

  save() {
    this.saved = true;
    this.errorMessage = '';
    this.removeEmptyRecord();

    const valid = this.validate(true);
    if (!valid) {
      return;
    }

    const params = this.records.map((r) => {
      return {
        userName: r.userName,
        mailAddress: r.mailAddress,
        notificationMenus: {
          dysstasia: r.dysstasia,
          calving: r.calving,
        },
      };
    });

    this.blockUI.start('登録中');

    this.AlertMailAPI.create(params)
      .then(() => {
        this.blockUI.done(() => {
          this.blockUI.start(`メールアドレスの新規登録が完了しました`);
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close();
      })
      .catch((res) => {
        if (res.status === 400) {
          this.resumeInput(res.data.errors);
        } else {
          this.errorMessage = ErrorUtil.formatErrorMessage(res.data.messages);
        }
        this.blockUI.stop();
      });
  }

  resumeInput(errors) {
    const records = [];

    errors.forEach((r) => {
      const messages = {};
      r.error.forEach((e) => {
        messages[e.field] = e.errors;
      });
      const record = this.records[r.lineNo - 1];
      record.errors = messages;
      records.push(record);
    });

    this.errorMessage = `${this.records.length}件中 ${errors.length}件にエラーがありました`;
    this.records = records;
  }

  removeEmptyRecord() {
    const records = this.records.filter((r) => {
      const isEmpty = !r.name && !r.mailAddress;
      return !isEmpty;
    });
    if (records.length === 0) {
      records.push(this.createEmptyRecord());
    }

    this.records = records;
  }

  createEmptyRecord() {
    return {
      userName: '',
      mailAddress: '',
      dysstasia: this.useAlertDysstasia,
      calving: this.useAlertCalving,
    };
  }

  add() {
    this.records.push(this.createEmptyRecord());
    this.validate();
  }

  remove(index) {
    this.records.splice(index, 1);
    this.validate();
  }

  onChangeMailAddress(record, needRequiredField) {
    const value = StringUtil.toHalfWidthCharacters(record.mailAddress);
    record.mailAddress = StringUtil.removeNonHalfWidthCharacters(value);
    this.validateEach(record, needRequiredField);
  }

  canRemove() {
    return this.records.length >= 2;
  }

  paste() {
    navigator.clipboard.readText().then((text) => {
      const rows = StringUtil.parseClippedMatrix(text);
      if (rows.length >= 1) {
        if (rows[0].length !== 2) {
          this.StandardDialog.showMessage({
            title: 'アラートメール設定の新規登録',
            text1: 'ユーザー名とメールアドレスの2列だけをコピーしてください。',
          });

          return;
        }

        this.records = rows.map((row) => {
          const record = this.createEmptyRecord();
          record.userName = row[0];
          record.mailAddress = row[1];
          return record;
        });
        this.validate();
        this.$rootScope.$digest();
      }
    });
  }

  cancel() {
    this.$modalInstance.close();
  }

  classModalFormTextBodyError(errors) {
    if (errors && errors.length) {
      return 'is-error';
    }
  }
}

app.controller('AlertMailEntryController', AlertMailEntryController);
