class HomeFatteningCountController {
  constructor(
    $q,
    $state,
    CowAPI,
    SessionCache,
    FatteningStageAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.CowAPI = CowAPI;
    this.FatteningStageAPI = FatteningStageAPI;

    const isFattening = SessionCache.farm().isFattening();
    const customlistSystemConfigs = SessionCache.customlistSystemConfigs();

    this.init(isFattening, customlistSystemConfigs);
  }

  init(isFattening, customlistSystemConfigs) {
    if (!isFattening) return;

    const config = customlistSystemConfigs.find((config) => config.configType === 'fattening_stage');

    if (config) {
      this.customlistId = config.customlistId;
    }

    this.fatteningItems = [
      {label: '0-5ヶ月', maleCount: 'ageBull0To6', femaleCount: 'ageCow0To6'},
      {label: '6-11ヶ月', maleCount: 'ageBull6To12', femaleCount: 'ageCow6To12'},
      {label: '12-17ヶ月', maleCount: 'ageBull12To18', femaleCount: 'ageCow12To18'},
      {label: '18-23ヶ月', maleCount: 'ageBull18To24', femaleCount: 'ageCow18To24'},
      {label: '24ヶ月以上', maleCount: 'ageBull24Over', femaleCount: 'ageCow24Over'}
    ];

    this.index().then(() => {
      this.loading.fatteningCount = false;
      this.enableMasonry();
    });
  }

  index() {
    return this.CowAPI.summary().then((res) => {
      const data = res.data;

      if (data.stages) {
        this.clickable = true;

        this.items = data.stages.map((stage) => {
          stage.label = this.getLabel(stage);

          return stage;
        });
      } else {
        this.clickable = false;

        this.items = this.fatteningItems.map((item) => {
          item.maleCount = data[item.maleCount];
          item.femaleCount = data[item.femaleCount];

          return item;
        });
      }

      this.subtotal = this.items.reduce((acc, cur) => {
        acc.maleCount += cur.maleCount;
        acc.femaleCount += cur.femaleCount;

        return acc;
      }, {
        maleCount: 0,
        femaleCount: 0
      });

      this.total = this.subtotal.maleCount + this.subtotal.femaleCount;
    });
  }

  getLabel(stage) {
    return FatteningStage.description(stage);
  }

  goToCustomlist(item) {
    this.$state.go('customlist', {
      name: item.label,
      customlistId: this.customlistId,
      fattening_stage_id: item.fatteningStageId,
      config_type: 'fattening_stage'
    });
  }
}

function homeFatteningCount() {
  return {
    templateUrl: 'menu/home/fattening-count/index.html',
    controller: HomeFatteningCountController,
    controllerAs: 'ctrl',
    bindings: {
      enableMasonry: '&',
      loading: '='
    }
  };
}

app.component('homeFatteningCount', homeFatteningCount());
