function certificateAiPrint() {
  'ngInject';

  return {
    restrict: 'E',
    scope: true,
    templateUrl: 'menu/certificate/ai/print/index.html',
  };
}

app.directive('certificateAiPrint', certificateAiPrint);
