class MobileGenomEvaluationListHelpController {
  constructor(
    $modalInstance
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
  }

  cancel() {
    this.$modalInstance.close();
  }
}

app.controller('MobileGenomEvaluationListHelpController', MobileGenomEvaluationListHelpController);
