class HomeHeadageSummarySimpleController {
  constructor(
    $modal,
    $timeout,
    SessionCache
  ) {
    'ngInject';

    this.$modal = $modal;
    this.$timeout = $timeout;
    this.homeSettings = new HomeSettings(SessionCache);

    HomeHeadageBaseController.bind(this);

    const farm = SessionCache.farm();

    this.init(farm);
  }

  init(farm) {
    this.calfManagement = farm.data.calfManagement;
  }

  $onChanges() {
    if (!this.data || !this.data.dairy) return;

    this.showCalf = ['calved', 'always'].includes(this.calfManagement) || this.data.dairy.dry;
  }

  classNameFooterBoard() {
    return this.homeSettings.get('headage').visible ? 'uFooterBoard' : 'uFooterBoard--closed';
  }

  openModal() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/home/headage/summary/detail.html',
      controller: 'HomeHeadageSummaryDetailController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            data: this.data
          };
        }
      }
    });
  }
}

function homeHeadageSummarySimple() {
  return {
    templateUrl: 'menu/home/headage/summary/simple.html',
    controller: HomeHeadageSummarySimpleController,
    controllerAs: 'ctrl',
    bindings: {
      data: '<'
    }
  };
}

app.component('homeHeadageSummarySimple', homeHeadageSummarySimple());
