class temporaryGroupViewCowDetailCowDataController {
  constructor() {
    'ngInject';
  }

  $onChanges() {
    if (!(Object.keys(this.cow).length)) return;

    this.index();
  }

  index() {
    this.cowUid = this.cow.cowUid || '-';
    this.cowGroupName = this.cow.cowGroupName || '-';
    this.formatPen = this.cow.pen ? `(${this.cow.pen})` : '(-)';
    this.breed = this.cow.breed || '-';
    this.gender = this.cow.gender || '-';
    this.formatWeightOnPurchase = this.cow.weightOnPurchase ? `${this.cow.weightOnPurchase}Kg` : '-';
    this.formatDayAgeAtEntered = this.cow.birthday && this.cow.introduceDate ?
      `(${DateUtil.diffDays(this.cow.birthday, this.cow.introduceDate)}日)` : '(-)';
    this.introduceRemarks = this.cow.introduceRemarks || '-';
    this.formatBirthday = this.cow.birthday ? DateUtil.toYYMMDD(this.cow.birthday) : '-';
    this.formatBirthdayMonthAge = this.cow.birthdayMonthAge ? `(${this.cow.birthdayMonthAge})` : '(-)';
    this.formatIntroduceDate = this.cow.introduceDate ? DateUtil.toYYMMDD(this.cow.introduceDate) : '-';
    this.formatPurchasePrice = this.cow.purchasePrice ? `(${this.cow.purchasePrice / 1000})` : '(-)';
    this.fatherName = this.cow.fatherName || '-';
    this.maternalGrandfatherName = this.cow.maternalGrandfatherName || '-';
    this.maternalGreatGrandfatherName = this.cow.maternalGreatGrandfatherName || '-';
    this.maternalFather4thName = this.cow.maternalFather4thName || '-';
    this.masterMarketName = this.cow.masterMarketName || '-';
  }
}

function temporaryGroupViewCowDetailCowData() {
  return {
    restrict: 'E',
    templateUrl: 'temporary-group-view/cow-detail/summary/cow-data.html',
    controller: temporaryGroupViewCowDetailCowDataController,
    controllerAs: 'ctrl',
    scope: true,
    replace: true,
    bindToController: {
      cow: '<'
    }
  };
}

app.directive('temporaryGroupViewCowDetailCowData', temporaryGroupViewCowDetailCowData);
