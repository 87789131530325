/**
 * um-form-groupコンポーネント
 *
 * 使い方
 * <um-form>
 *   <um-form-group layout="horizontal">
 *     <um-form-input
 *       um-label="時"
 *       um-type="text"
 *       um-width="50"
 *       um-model="ctrl.selectedHour"></um-form-input>
 *     <um-form-input
 *       um-label="分"
 *       um-type="text"
 *       um-width="50"
 *       um-model="ctrl.selectedMinute"></um-form-input>
 *   </um-form-group>
 * </um-form>
 *
 * パラメータ:
 * @param {string} um-label
 * @param {string} um-layout
 *    'single' inputを単独で配置する
 *    'horizontal' inputを横に2つ配置する
 *    'vertical' inputを縦でグループ化する
 *    'radio' radioボタンを縦方向にグルーピングする
 *    'radio-group' radioボタンを横方向にグルーピングする
 *    'checkbox' checkboxを縦方向にグルーピングする
 *    'checkbox-group' checkboxを横方向にグルーピングする
 * @param {bool} um-has-error フォームグループ内のinput/select/textareaにバリデーションエラーが発生しているかどうかのフラグ
 **/

app.component('umFormGroup', {
  template: ($attrs, $element) => {
    'ngInject';

    const isSubGroup = includedInSubGroup($element, $attrs);
    const isAddGroup = $attrs['umLayout'] && $attrs['umLayout'] === 'add-group';
    const isDefaultGroup = !isSubGroup && !isAddGroup;
    const layoutDiv = getLayoutDiv($attrs.umLayout);

    return `
      <div ng-class="{'mf-subgroup' : ${isSubGroup}, 'mf-group': ${isDefaultGroup}, 'mf-addgroup': ${isAddGroup}, 'is-error' : $ctrl.umHasError() , 'mf-no-border': ${isAddGroup} && !$first }">
        <label ng-if="$ctrl.umLabel" class="mf-label">
          {{$ctrl.umLabel}}
          <span ng-if="$ctrl.umIsRequired" class="label label-danger mf-label_icon">必須</span>
        </label>
        ${layoutDiv}
      </div>`;
  },
  transclude: true,
  bindings: {
    'umIsChildren': '<',
    'umLabel': '@',
    'umLayout': '@',
    'umHasError': '&',
    'umIsRequired': '<'
  },
  constoller: 'umFormGroupController as umFormGroupController'
});

function includedInSubGroup($element, $attrs) {
  if ($attrs['umIsChildren'] === 'true') {
    return true;
  } else if ($element[0] && $element[0].parentElement) {
    return $element[0].parentElement.localName === 'um-form-group';
  } else {
    return false;
  }
}

function getLayoutDiv(layout) {
  switch (layout) {
  case 'single':
  case 'horizontal':
    return '<div class="row" ng-transclude></div>';
  case 'radio':
    return '<div class="mf-radio" ng-transclude></div>';
  case 'radio-group':
    return '<div class="mf-radiogroup" ng-transclude></div>';
  case 'checkbox':
    return '<div class="mf-checkbox" ng-transclude></div>';
  case 'checkbox-group':
    return '<div class="mf-checkboxgroup" ng-transclude></div>';
  default:
    return '<ng-container ng-transclude></ng-container>';
  }
}

class umFormGroupController {
  constructor() {
    'ngIngect';
  }

  hasError() {
    return Object.keys(this.umErrorStatus).length > 0;
  }
}

app.controller('umFormGroupController', umFormGroupController);
