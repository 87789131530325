// eslint-disable-next-line no-unused-vars
class HomeAlertBaseController {
  static bind(ctrl) {
    const base = new HomeAlertBaseController();
    const functions = [
      'classNameAlertWidgetBox'
    ];

    angular.extend(ctrl, base);

    functions.forEach((f) => {
      ctrl[f] = base[f].bind(ctrl);
    });
  }

  classNameAlertWidgetBox(count) {
    return count ? 'uAlert__box' : 'uAlert__box--disabled';
  }
}
