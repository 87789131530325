/**
 * モバイル用の分娩センサー脱落アラートのリストアイテムコンポーネントです
 *
 * ex.
 *   <mobile-alert-calving-sensor-dropped-list-item
 *     alert-calving-sensor-dropped="alertCalvingSensorDropped"
 *     on-go-to-cow-detail-click="onGoToCowDetailClick(cowId)"
 *     on-solution-selection-click="onSolutionSelectionClick(id)"
 *   ></mobile-alert-calving-sensor-dropped-list-item>
 *
 * プロパティ:
 *  @param {Object} alert-calving-sensor-dropped 分娩センサー脱落アラートオブジェクト
 *  @param {Function} on-go-to-cow-detail-click 個体情報詳細へクリック時に発生するイベント cowIdを引数で渡す
 *  @param {Function} on-solution-selection-click 処置内容の選択クリック時に発生するイベント idを引数で渡す
 */
class MobileAlertCalvingSensorDroppedListItemController {
  constructor() {
    'ngInject';

    this.shouldShowDetails = false;
  }

  handleGoToCowDetailClick() {
    this.onGoToCowDetailClick({cowId: this.alertCalvingSensorDropped.cowId});
  }

  handleSolutionSelectionClick() {
    this.onSolutionSelectionClick({id: this.alertCalvingSensorDropped.id});
  }

  toggleDetails() {
    this.shouldShowDetails = !this.shouldShowDetails;
  }
}

function mobileAlertCalvingSensorDroppedListItemComponents() {
  return {
    templateUrl: 'mobile/alert-list/calving-sensor-dropped/list-item.html',
    controller: MobileAlertCalvingSensorDroppedListItemController,
    bindings: {
      alertCalvingSensorDropped: '<',
      onGoToCowDetailClick: '&',
      onSolutionSelectionClick: '&'
    }
  };
}

app.component('mobileAlertCalvingSensorDroppedListItem', mobileAlertCalvingSensorDroppedListItemComponents());
