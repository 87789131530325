// eslint-disable-next-line no-unused-vars
class MasterConfig {
  static get menus() {
    return [
      {id: 'SPERM', label: '精液'},
      {id: 'EMBRYO', label: '受精卵'},
      {id: 'HORMONE_PROGRAM', label: 'ホルモンプログラム'},
      {id: 'MEDICINE', label: '薬品'},
      {id: 'MASTER_DISEASE', label: '病名・症状'},
      {id: 'MARKET', label: '市場'},
      {id: 'COW_LABEL', label: '個体ラベル'},
    ];
  }

  static get menuIdSperm() {
    return 'SPERM';
  }

  static get menuIdEmbryo() {
    return 'EMBRYO';
  }

  static get menuIdHormoneProgram() {
    return 'HORMONE_PROGRAM';
  }

  static get menuIdMedicine() {
    return 'MEDICINE';
  }

  static get menuIdMarket() {
    return 'MARKET';
  }
}
