class MobileAlertEartagSensorDroppedFactorSelectionController {
  constructor() {
    'ngInject';
  }

  handleCloseClick() {
    this.onCloseClick();
  }

  handleFactorClick(factor) {
    this.onFactorClick({factor: factor});
  }
}

function mobileAlertEartagSensorDroppedFactorSelectionComponents() {
  return {
    templateUrl: 'mobile/alert-list/eartag-sensor-dropped/factor-selection.html',
    controller: MobileAlertEartagSensorDroppedFactorSelectionController,
    bindings: {
      onCloseClick: '&',
      onFactorClick: '&'
    }
  };
}

app.component('mobileAlertEartagSensorDroppedFactorSelection',
  mobileAlertEartagSensorDroppedFactorSelectionComponents());
