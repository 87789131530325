class UnexpectedDysstasiaInputDialog {
  constructor(
    $modal,
    $timeout,
    blockUI,
    Dictionary,
    DysstasiaAPI,
    StandardDialog
  ) {
    'ngInject';

    this.$modal = $modal;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.Dictionary = Dictionary;
    this.DysstasiaAPI = DysstasiaAPI;
    this.StandardDialog = StandardDialog;
  }

  create() {
    this.execute(ViewMode.CREATE);
  }

  update(dysstasiaId) {
    return this.execute(ViewMode.UPDATE, dysstasiaId);
  }

  execute(viewMode, dysstasiaId = null) {
    return this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'components/dialog/unexpected-dysstasia/index.html',
      controller: 'UnexpectedDysstasiaInputController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            viewMode: viewMode,
            id: dysstasiaId,
          };
        }
      }
    }).result.then((result) => {
      return result;
    });
  }

  delete(cowNo, dysstasiaId) {
    return this.StandardDialog.showDeleteConfirm({
      title: '起立困難の削除',
      text1: `${this.Dictionary.COW.COW_NO} : ${cowNo} の起立困難の報告`
    }).result.then((modalResult) => {
      if (!modalResult) return;

      this.blockUI.start('削除中');
      this.DysstasiaAPI.delete(dysstasiaId).then((res) => {
        this.blockUI.start('起立困難の削除が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);

        this.blockUI.stop();
      });
    });
  }
}

app.service('UnexpectedDysstasiaInputDialog', UnexpectedDysstasiaInputDialog);
