class ReproductionPerformanceAPI {
  constructor(APIService, DateUtilService) {
    'ngInject';
    this.APIService = APIService;
    this.DateUtilService = DateUtilService;
  }

  /**
   * @param {mixed} startMonth レポート開始月(Date())
   * @param {mixed} endMonth レポート終了月(Date())
   */
  index(startMonth, endMonth) {
    const query = `?start_month=${this.DateUtilService.toYYYYMM(startMonth)}&end_month=${this.DateUtilService.toYYYYMM(endMonth)}`;
    return this.APIService.get('/reproduction_performance/monthly' + query);
  }

  /**
  * @param {Number} startDate 開始日 ミリ秒
  * @param {Number} endDate 終了日 ミリ秒
  * @param {Number} birthNumber 産次数(未経産: 0, 初産: 1, ２産: 2)
  * @param {String} operator 引数で渡したbirth_number以上の産次数を対象とする場合は、'GE'(greater than or equal to)という文字列を渡す
  */
  cyclic(startDate, endDate, birthNumber, operator) {
    const query = `?start_date=${DateUtil.toYYYYMMDD(startDate, '-')}&end_date=${DateUtil.toYYYYMMDD(endDate, '-')}&birth_number=${birthNumber}&operator=${operator}`;
    return this.APIService.get('/reproduction_performance/cyclic' + query);
  }

  /**
  * @param {Date} startDate 開始日
  * @param {Date} endDate 終了日
  */
  dim(startDate, endDate) {
    const query = `?start_date=${DateUtil.toW3CFormat(startDate)}&end_date=${DateUtil.toW3CFormat(endDate)}`;
    return this.APIService.get(`/reproduction_performance/dim${query}`);
  }

  /**
   * @param {Number} startDate 開始日 ミリ秒
   * @param {Number} endDate 終了日 ミリ秒
   */
  firstAiDim(startDate, endDate) {
    const query = `?start_date=${this.DateUtilService.toYYYYMMDD(startDate, '-')}&end_date=${this.DateUtilService.toYYYYMMDD(endDate, '-')}`;
    return this.APIService.get('/reproduction_performance/first_ai_dim' + query);
  }

  /**
   * @param {Number} startDate 開始日 ミリ秒
   * @param {Number} endDate 終了日 ミリ秒
   */
  bredAnalysis(startDate, endDate, factor) {
    if (!startDate || !endDate || !factor) return;

    const query = `?factor=${factor}&start_date=${this.DateUtilService.toYYYYMMDD(startDate, '-')}&end_date=${this.DateUtilService.toYYYYMMDD(endDate, '-')}`;
    return this.APIService.get('/reproduction_performance/bred_analysis' + query);
  }
}

app.service('ReproductionPerformanceAPI', ReproductionPerformanceAPI);
