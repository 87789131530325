/* eslint-disable */
/**
 * @deprecated Use AlertDecorator instead.
 */
class AlertActivityMilkDecorator {
  constructor(obj, DateUtilService) {
    this.obj = obj;
    this.DateUtilService = DateUtilService;
    this.selected = false;

    // TODO: Proxyに変えたい
    Object.keys(obj).forEach((key) => {
      if (key in this) return;
      this[key] = obj[key];
    });
  }

  get cowUid() {
    return formatCowUid(this.obj.cowUid);
  }

  get latestCalvingDate() {
    return this.DateUtilService.toYYYYMMDD(this.obj.latestCalvingDate);
  }

  get daysAfterCalving() {
    return this.diffDays(this.obj.latestCalvingDate);
  }

  get expectedCalvingDate() {
    return this.DateUtilService.toYYYYMMDD(this.obj.expectedCalvingDate);
  }

  get latestFertilizationDate() {
    return this.DateUtilService.toYYYYMMDD(this.obj.latestFertilizationDate);
  }

  get daysAfterFertilization() {
    return this.diffDays(this.obj.latestFertilizationDate);
  }

  get latestPregnancyDate() {
    return this.DateUtilService.toYYYYMMDD(this.obj.latestPregnancyDate);
  }

  get daysAfterPregnancy() {
    return this.diffDays(this.obj.latestPregnancyDate);
  }

  get expectedDryDate() {
    return this.DateUtilService.toYYYYMMDD(this.obj.expectedDryDate);
  }

  // private

  /**
   * 引数の日付から今日までの日数を返す
   *
   * @param {number} date unixtime
   * @return {nuber} 日数
   */
  days(date) {
    if (!date) return '';
    return this.DateUtilService.countDays(Number(date), new Date().getTime());
  }

  /**
   * 引数の日付から今日までの差分日数を返す
   *
   * @param {number} date unixtime
   * @return {nuber} 日数
   */
  diffDays(date) {
    if (!date) return '';
    return DateUtil.diffDays(Number(date), new Date().getTime());
  }
}
