class MobileCustomlistRuntimeController {
  constructor(
    SessionCache,
    $state,
    $stateParams,
    $modal,
    CustomlistRunner,
    MobileBulkActionSelectModal,
    MobileBulkEditLabelSelectModal,
    $scope,
    modalDialogFactory,
    blockUI
  ) {
    'ngInject';
    this.$state = $state;
    this.$modal = $modal;
    this.CustomlistRunner = CustomlistRunner;
    this.modalDialogFactory = modalDialogFactory;

    this.isDepositor = SessionCache.farm().isDepositor();

    this.MobileBulkActionSelectModal = MobileBulkActionSelectModal;
    this.MobileBulkEditLabelSelectModal = MobileBulkEditLabelSelectModal;
    this.blockUI = blockUI;
    this.$scope = $scope;

    this.init($stateParams.customlistId);

    this.Customlist = new Customlist();

    this.filterConditions = [];
    this.currentConditions = [];
    this.cowIndex = $stateParams.cowIndex || null;

    this.isFilterModalOpen = false;
  }

  // private
  init(customlistId) {
    this.customlistId = customlistId;

    this.cows = [];

    this.isAllSelected = false;

    return this.run(DateUtil.today()).then(() => {
      this.columnMap = this.columns.reduce((acc, cur) => {
        acc[cur.columnId] = cur;
        return acc;
      }, {});

      this.setGroupCondition(this.cows);
    });
  }

  setGroupCondition(cows) {
    const conditions = CustomlistRuntimeCondition.generateFilterCondition(cows, 'cow_group_name');
    if (conditions) {
      this.groupConditions = conditions;
    }
  }

  run(targetDate, cowGroupId) {
    const options = {
      cowGroupId: cowGroupId || null,
      fatteningStageId: null
    };

    this.blockUI.start('ロード中');
    return this.CustomlistRunner.run(
      this.customlistId,
      targetDate,
      options
    ).then((res) => {
      this.cows = res.data.cows;
      this.rawCows = [].concat(this.cows);
      this.setConfig(res.data.config);
      if (this.cowIndex !== null) {
        this.onCowClick(this.cows[this.cowIndex]);
      }
    })
      .finally(() => {
        this.blockUI.stop();
      });
  }

  setConfig(config) {
    this.columns = config.columns;
    this.listName = config.master.name;
    this.configType = config.master.configType;
    this.needLabelAction = this.columns.some((c) => c.columnId === 'cow_labels');
    this.hasCowGroupField = this.columns.some((c) => c.columnId === 'cow_group_name');
    this.hasPenField = this.columns.some((c) => c.columnId === 'pen');
    this.hasCowUidField = this.columns.some((c) => c.columnId === 'cow_uid');
  }

  // public

  displayCaption(column) {
    if (column.shortCaption) {
      return column.shortCaption;
    }
    return column.caption;
  }

  selectAllButtonLabel() {
    return this.isAllSelected ? '選択をクリア' : '全てを選択';
  }

  hasSelection() {
    return this.cows.some((cow) => cow.selected);
  }

  toggleIsSelectMode() {
    this.isSelectMode = !this.isSelectMode;
    this.setAllSelected(false);
    this.isAllSelected = false;
  }

  setAllSelected(selected) {
    this.cows.forEach((cow) => cow.selected = selected);
  }

  setSelected(cow, selected) {
    cow.selected = selected;
  }

  openBulkEventEntry(selectedCows) {
    const callerState = {
      bqNotes: `alertList config_type = ${this.configType}`,
    };

    this.$modal.open({
      templateUrl: 'mobile/bulk-entry/mobile-bulk-entry-event-select.html',
      controller: 'EventSelectionController',
      controllerAs: 'ctrl',
      size: '770',
      resolve: {
        cowIds: () => selectedCows.map((cow) => cow.cowId),
        cowType: () => null,
        isDepositor: () => this.isDepositor,
        qwert: () => this.qwert,
        caller: () => callerState
      },
    }).result
      .then((result) => {
        if (result.status && result.status.submit) {
          this.run(null, result.status.groupCondition);
          this.toggleIsSelectMode();
        }
      })
      .catch((result) => {
        if (result.status && result.status === 'error') console.error(result);
      });
  }

  shouldDisableSubmitButton() {
    return !(this.hasSelection());
  }

  shouldShowCowLabels(cow) {
    return this.needLabelAction && cow.cowLabels.length > 0;
  }

  shouldShowCowGroup() {
    return this.hasCowGroupField && !this.hasPenField;
  }

  shouldShowPen() {
    return !this.hasCowGroupField && this.hasPenField;
  }

  shouldShowCowGroupAndPen() {
    return this.hasCowGroupField && this.hasPenField;
  }

  shouldShowCowUid() {
    return this.hasCowUidField;
  }

  onCowClick(cow) {
    if (this.isSelectMode) {
      this.onCheckBoxClick(cow);
    } else {
      this.cowIndex = this.cows.indexOf(cow);
      this.shouldShowCowDetailModal = true;
    }
  }

  onCloseCowDetailClick() {
    this.shouldShowCowDetailModal = false;
    this.selectedCow = null;
    this.cowIndex = null;
  }

  onCowDetailClick() {
    const caller = {
      state: 'mobileCustomlistRuntime',
      name: this.listName,
      params: {
        name: this.listName,
        customlistId: this.customlistId,
        cowIndex: this.cowIndex
      },
      bqNotes: `customlist config_type = ${this.configType}`
    };
    this.$state.go('mobileCowDetail', {
      cowId: this.cows[this.cowIndex].cowId,
      caller
    });
  }

  onSelectButtonClick() {
    this.toggleIsSelectMode();
  }

  onSelectAllButtonClick() {
    this.isAllSelected = !this.isAllSelected;
    this.setAllSelected(this.isAllSelected);
  }

  onSubmitButtonClick() {
    const selectedCows = this.cows.filter((cow) => cow.selected);

    const actions = [
      {value: 'registerEvent', text: 'イベント登録', new: true}
    ];
    if (this.needLabelAction) {
      actions.unshift({
        value: 'editLabel',
        text: 'ラベル編集', new: true
      });
    }

    this.MobileBulkActionSelectModal
      .open(selectedCows, actions).result.then((result) => {
        switch (result.value) {
        case 'editLabel':
          this.MobileBulkEditLabelSelectModal.open(selectedCows)
            .then((result) => {
              if (result.status && result.status.ok) {
                this.run();
                this.toggleIsSelectMode();
              }
            })
            .catch((result) => {
              if (result.status === 'error') console.error(result);
            });
          break;
        case 'registerEvent':
          const exceptions = selectedCows.filter((c) => {
            return c.origin.eliminate_date || c.origin.falling_dead_date;
          });

          if (exceptions.length === 0) {
            this.openBulkEventEntry(selectedCows);
            break;
          }

          if (exceptions.length === selectedCows.length) {
            const modalInstance = this.modalDialogFactory.showAlertModal({
              title: 'エラー',
              text1: '出荷・へい死の牛のみです。イベントを登録できません。'
            });

            return modalInstance.result.then((modalResult) => modalResult);
          } else {
            const modalInstance = this.modalDialogFactory.showYesNoConfirm({
              title: '対象牛確認',
              text1: `出荷・へい死の牛が含まれています。除外してイベント登録を行いますか？`,
              no: true,
              yes: true
            });

            return modalInstance.result.then((result) => {
              if (result === true) {
                const activeCows = selectedCows.filter((c) => !c.origin.eliminate_date && !c.origin.falling_dead_date);
                this.openBulkEventEntry(activeCows);
              }
            });
          }
        default:
        case 'cancel':
          break;
        }
      });
  }

  onCheckBoxClick(cow) {
    cow.selected = !cow.selected;
  }

  onFilterButtonClick() {
    if (this.isFilterModalOpen) return;
    this.isFilterModalOpen = true;

    const params = {
      columns: this.columns,
      conditions: this.filterConditions,
      sortColumnId: this.sortColumnId,
      sortOrder: this.sortOrder,
      groupConditions: this.groupConditions || [],
      groupCondition: this.groupCondition,
    };

    this.$modal.open({
      templateUrl: 'mobile/customlist/runtime/filter-modal/index.html',
      controller: 'MobileCustomListFilterModalController',
      controllerAs: 'ctrl',
      size: '770',
      resolve: {
        params: () => params
      },
    }).result
      .then((result) => {
        if (result.status && result.status.submit) {
          if (result.sortColumnId !== '') {
            this.sortColumnId = result.sortColumnId;
            this.sortOrder = result.sortOrder;
          }
          this.filterConditions = result.conditions;
          this.groupCondition = result.groupCondition;

          // ソート
          this.filter();
          this.sort();
        }
        this.isFilterModalOpen = false;
      })
      .catch((result) => {
        if (result.status && result.status === 'error') console.error(result);
        this.isFilterModalOpen = false;
      });
  }

  isCommentColumn(column) {
    return column.columnId === 'comments';
  }

  isCowLabelColumn(column) {
    return column.columnId === 'cow_labels';
  }

  isSummaryColumn(column) {
    return this.Customlist.isSummaryColumn(column);
  }

  isDefaultColumn(column) {
    return !this.isSummaryColumn(column) && !this.isCowLabelColumn(column) && !this.isCommentColumn(column);
  }

  listItemClass(isSelected) {
    return isSelected ? 'mm-listItem is-select' : 'mm-listItem';
  }

  sort() {
    CustomlistSort.sort(
      this.cows,
      this.columnMap[this.sortColumnId],
      this.sortOrder === 'ascending'
    );
  }

  filter() {
    let cows = [].concat(this.rawCows);
    if (this.farmCondition) {
      cows = cows.filter((cow) => {
        return cow.farm_name === this.farmCondition;
      });
    }
    if (this.groupCondition) {
      cows = cows.filter((cow) => {
        return cow.cow_group_name === this.groupCondition;
      });
    }
    this.cows = new CustomlistFilter().filter(cows, this.filterConditions);
  }

  shouldRenderCarouselItem(index) {
    return index > this.cowIndex - 3 && index < this.cowIndex + 3;
  }
}

app.controller('MobileCustomlistRuntimeController', MobileCustomlistRuntimeController);
