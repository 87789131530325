class CertificateAiController {
  constructor(
    $modal,
    $timeout,
    SessionCache,
    CertificateAiAPI,
    InseminatorDialog,
    MasterInseminatorAPI
  ) {
    'ngInject';

    this.$modal = $modal;
    this.$timeout = $timeout;
    this.CertificateAiAPI = CertificateAiAPI;
    this.InseminatorDialog = InseminatorDialog;
    this.MasterInseminatorAPI = MasterInseminatorAPI;

    const farm = SessionCache.farm();

    this.init(farm);
  }

  init(farm) {
    this.currentColumnSort = null;
    this.rawCows = [];
    this.farmData = angular.copy(farm.data);
    this.useName = farm.useName();
    this.model = {
      type: 'mother'
    };
    this.label = {
      headingSearchNo: this.useName ? '名前・番号' : '番号',
      cowNos: this.useName ? '名前' : '牛番号',
    };

    this.defaultSetting();
    this.inseminatorSelectDisabled = true;
    this.inseminators = farm.data.inseminators ? farm.data.inseminators.split('\n') : [];
  }

  defaultSetting() {
    this.invalidDate = false;

    if (this.model.type === 'mother') {
      this.model = {
        type: 'mother',
        periodType: 'bred',
        startDate: DateUtil.addDays(DateUtil.today(), -7),
        endDate: DateUtil.today(),
        noType: 'cowNos',
        searchNos: null,
        pregnantOnly: false
      };
    } else {
      this.model = {
        type: 'calf',
        startDate: DateUtil.addDays(DateUtil.today(), -7),
        endDate: DateUtil.today(),
        noType: 'cowNos',
        searchNos: null
      };
    }
  }

  columnsDefinition() {
    const columns = [{
      columnId: 'selected',
      name: '選択',
      width: 50
    }, {
      columnId: 'bredAt',
      name: '授精日',
      width: 110
    }, {
      columnId: 'masterSpermNo',
      name: '精液番号',
      width: 110
    }, {
      columnId: 'masterSpermName',
      name: '種雄牛名',
      width: 110
    }, {
      columnId: 'cowNo',
      name: '牛番号',
      width: 90,
      hidden: this.useName
    }, {
      columnId: 'cowName',
      name: '名前',
      width: 130
    }, {
      columnId: 'cowUid',
      name: '個体識別番号',
      width: 110
    }, {
      columnId: 'breed',
      name: '品種',
      width: 110
    }, {
      columnId: 'birthday',
      name: '出生日',
      width: 110
    }, {
      columnId: 'pregnant',
      name: '受胎',
      width: 100
    }, {
      columnId: 'workerName',
      name: '授精担当者',
      width: 130
    }, {
      columnId: 'calvingDate',
      name: '分娩日',
      width: 110,
      hidden: !(this.model.type === 'mother' && this.model.periodType === 'calved')
    }, {
      columnId: 'calfs',
      name: '子牛情報',
      width: 200,
      hidden: this.model.type !== 'calf'
    }];

    this.columns = columns.filter((column) => {
      return !column.hidden;
    });
  }

  search() {
    this.currentColumnSort = null;
    this.uiGrid = null;

    const params = Object.entries(this.model).reduce((acc, [key, value]) => {
      if (key === 'searchNos') {
        acc[this.model.noType] = this.separatedSearchNos(this.model.noType, value);
      } else if (key !== 'noType') {
        acc[key] = value;
      }

      return acc;
    }, {});

    this.CertificateAiAPI.search(params).then((res) => {
      this.allCheck = true;

      this.cows = res.data.map((cow) => {
        cow.origin = angular.copy(cow);
        cow.bredAt = DateUtil.toYYYYMMDD(cow.bredAt);
        cow.birthday = DateUtil.toYYYYMMDD(cow.birthday);
        cow.calvingDate = DateUtil.toYYYYMMDD(cow.calvingDate);
        cow.cowUid = Cow.formatCowUid(cow.cowUid);
        cow.selected = true;

        return cow;
      });

      this.rawCows = [].concat(this.cows);
      this.setCount();

      this.columnsDefinition();

      const generator = new CertificateAiIndexGenerator();

      this.uiGrid = {
        appScopeProvider: this,
        columnDefs: generator.generateColumnDefs(this.columns),
        rowTemplate: generator.generateRowTemplate(),
        data: this.cows,
        onRegisterApi: (gridApi) => {
          this.gridApi = gridApi;
        }
      };
    });

    this.MasterInseminatorAPI.index().then((res) => {
      this.inseminatorList = res.data.map((obj) => {
        return {
          id: obj.id,
          name: obj.name,
          licencedPrefecture: obj.licencedPrefecture,
          licencedNumber: obj.licencedNumber,
          address: obj.address
        };
      });
      this.model.inseminator = this.inseminatorList[0];
      this.setPrintInseminator();
    });
  }

  setCount() {
    this.count = `選択中：${this.cows.filter((cow) => cow.selected).length}件／${this.cows.length}件`;
  }

  separatedSearchNos(noType, value) {
    if (!value) return [];

    let result = value;

    if (noType === 'cowUids') {
      result = result.replace(/[-]/g, '');
    }

    result = result.replace(/[、，\x20\u3000]/g, ',').split(',').map((s) => s.trim());

    return result;
  }

  print() {
    const title = document.title;
    const option = {
      year: 'ggg',
      month: 'm',
      day: 'd',
      separator_style: 'jp'
    };

    document.title = `授精証明書_${DateUtil.toYYYYMMDD(DateUtil.today(), '')}`;

    this.printCows = angular.copy(this.cows).filter((cow) => {
      return cow.selected;
    }).map((cow) => {
      cow.birthday = DateUtil.formatDate(cow.origin.birthday, option);
      cow.bredAt = DateUtil.formatDate(cow.origin.bredAt, option);

      return cow;
    });

    this.printDate = DateUtil.formatDate(DateUtil.today(), option);

    this.$timeout(() => {
      print();
      document.title = title;
    });
  }

  clickHeaderCell(columnId, gridCol) {
    if (CustomlistSort.isUnsortable(columnId)) return;

    const column = this.columns.find((column) => column.columnId === columnId);
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        CustomlistSort.sort(this.cows, column, false);
        this.currentColumnSort = {column, isAscending: false};
      } else {
        this.currentColumnSort = null;
        this.cows = [].concat(this.rawCows);
        this.uiGrid.data = this.cows;
      }
    } else {
      CustomlistSort.sort(this.cows, column, true);
      this.currentColumnSort = {column, isAscending: true};
    }
  }

  clickConfig() {
    this.InseminatorDialog.execute();
  }

  toggleCheck($event, cow) {
    if ($event.target.nodeName !== 'INPUT') {
      cow.selected = !cow.selected;
    }

    this.setCount();
    this.allCheck = this.cows.every((cow) => {
      return cow.selected;
    });
  }

  toggleAllCheck() {
    const checked = this.allCheck;

    this.cows = this.cows.map((cow) => {
      cow.selected = checked;

      return cow;
    });

    this.setCount();
    this.uiGrid.data = this.cows;
  }

  changeType() {
    this.defaultSetting();
  }

  changeDate(type) {
    let invalidDate = false;

    if (type === 'date') {
      if (!(DateUtil.isValidDate(this.model.startDate) &&
          DateUtil.isValidDate(this.model.endDate) &&
          DateUtil.includedPermittedPeriod(this.model.startDate) &&
          DateUtil.includedPermittedPeriod(this.model.endDate))) {
        invalidDate = true;
      } else if (!(this.model.startDate && this.model.endDate)) {
        invalidDate = true;
      } else if (this.model.startDate > this.model.endDate) {
        invalidDate = true;
      }
    } else {
      if (this.model.startDate === null) {
        invalidDate = true;
      } else if (this.model.startDate &&
        !(DateUtil.isValidDate(this.model.startDate) &&
          DateUtil.includedPermittedPeriod(this.model.startDate))) {
        invalidDate = true;
      }

      if (this.model.endDate === null) {
        invalidDate = true;
      } else if (this.model.endDate &&
        !(DateUtil.isValidDate(this.model.endDate) &&
          DateUtil.includedPermittedPeriod(this.model.endDate))) {
        invalidDate = true;
      }

      if (this.model.startDate && this.model.endDate &&
        this.model.startDate > this.model.endDate) {
        invalidDate = true;
      }
    }

    this.invalidDate = invalidDate;
  }

  clearDate(field, type) {
    this.model[field] = '';
    this.changeDate(type);
  }

  disabledSearchButton() {
    return this.invalidDate;
  }

  disabledPrintButton() {
    if (!this.cows || this.cows.length === 0) {
      return true;
    }

    return this.cows.every((cow) => {
      return !cow.selected;
    });
  }

  showType() {
    return ['always', 'calved'].includes(this.farmData.calfManagement);
  }

  showTargetDate() {
    return this.model.type === 'mother';
  }

  showBirthday() {
    return this.model.type === 'calf';
  }

  showPregnantOnly() {
    return this.model.type === 'mother' && this.model.periodType === 'bred';
  }

  classRow(cow) {
    return cow.selected ? 'contents__uiGridSelected' : '';
  }

  classHeaderCell(gridCol) {
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        return 'ui-grid-sorted-asc';
      } else {
        return 'ui-grid-sorted-desc';
      }
    }
  }

  setPrintInseminator() {
    this.certificateInseminator = this.model.inseminator;
  }
}

app.controller('CertificateAiController', CertificateAiController);
