class CarcassReportController {
  constructor(
    $rootScope,
    CarcassService,
    CowService,
    DateUtilService,
    FarmService
  ) {
    'ngInject';

    this.title = '枝肉成績';
    this.CarcassService = CarcassService;
    this.DateUtilService = DateUtilService;
    this.cows = null;
    this.isFormValid = false;
    this.form = {
      dateField: null,
      dateFieldType: null,
      yearMonth: null,
      startDate: null,
      endDate: null,
      tallyBy: null,
    };
    this.dateFieldOptions = [
      {label: '販売日', value: 'occurred_at'},
      {label: '導入日', value: 'introduce_date'},
      {label: '出生日', value: 'birthday'},
      {label: '肥育開始日', value: 'start_fattening_date'},
    ];
    this.targetYearMonthOptions = [];
    this.tallyByOptions = Object.keys(Carcass.tallyByOptions).map((elem) => {
      return {label: Carcass.tallyByOptions[elem], value: elem};
    });

    CowService.getPedigree().then((res) => {
      this.breed = res.data.breed;
      this.fatherName = res.data.fatherName;
      this.motherName = res.data.motherName;
      this.maternalGrandfatherName = res.data.maternalGrandfatherName;
      this.maternalGreatGrandfatherName = res.data.maternalGreatGrandfatherName;
      this.maternalFather4thName = res.data.maternalFather4thName;
      this.producingFarmName = res.data.producingFarmName;
      this.raisingFarmName = res.data.raisingFarmName;
    }).catch((e) => {
      console.error(e);
    });

    FarmService.show().then((res) => {
      function createYearMonthOptions(startDate, endDate) {
        const options = DateUtilService.yearMonths(startDate, endDate, true).map((obj) => {
          const mm = (obj.month + 1 < 10) ? `0${obj.month + 1}` : `${obj.month + 1}`;
          return {label: `${obj.year}年${obj.month + 1}月`, value: `${obj.year}${mm}`};
        });
        return options;
      }
      const endDate = new Date();
      if (res.startDate) {
        const startDate = DateUtilService.addMonths(new Date(Number(res.startDate)), -120);
        this.targetYearMonthOptions = createYearMonthOptions(startDate, endDate);
        return;
      }
      if (res.createdAt) {
        const startDate = DateUtilService.addMonths(new Date(res.createdAt), -120);
        this.targetYearMonthOptions = createYearMonthOptions(startDate, endDate);
        return;
      }
    }).catch((e) => {
      console.error(e);
    });
  }

  adjustDropDownHeight(e, ifOpen) {
    if (ifOpen) {
      const listLength = (e.target.children.length > 7) ? '7' : e.target.children.length;
      e.target.setAttribute('size', listLength);
      return;
    }
    e.target.setAttribute('size', 1);
  }

  validate() {
    this.isFormValid = (
      this.form.dateField &&
      (this.form.dateFieldType === 'yearMonth' && this.form.yearMonth ||
      this.form.dateFieldType === 'arbitrary' && this.form.startDate) &&
      this.form.tallyBy
    ) ? true : false;
  }

  checkRadioButton(dateFieldType) {
    this.form.dateFieldType = dateFieldType;
  }

  tally() {
    const query = Object.keys(this.form).map((key) => {
      switch (key) {
      case 'dateField':
        return `date_field=${this.form[key]}`;

      case 'dateFieldType':
        return null;

      case 'yearMonth':
        if (this.form.dateFieldType === 'yearMonth') {
          return `month=${this.form[key]}`;
        }
        return null;

      case 'startDate':
        if (this.form.dateFieldType === 'arbitrary') {
          return `start_date=${this.DateUtilService.toW3CFormat(this.form[key])}`;
        }
        return null;

      case 'endDate':
        if (this.form.dateFieldType === 'arbitrary') {
          if (this.form[key]) return `end_date=${this.DateUtilService.toW3CFormat(this.form[key])}`;
          return `end_date=${this.DateUtilService.toW3CFormat(Date.now())}`;
        }
        return null;

      case 'tallyBy':
        return null;

      default:
        return null;
      }
    }).filter((elem) => elem).join('&');
    this.cows = null; // this.cowsの内容に変更がない場合でも「集計基準」の変更をテーブルヘッダーに反映させるため。
    this.CarcassService.getCarcassCharacteristicDetails(query).then((res) => {
      // 1.「性別」が「去勢」、「メス」、「オス」以外の牛は、集計対象でないため、母集団から除外する。
      // 2. 集計基準の項目（「種牛」など）または「品種」が未入力の牛は、集計できないため、母集団から除外する。
      this.cows = res.data.filter((cow) => {
        return Carcass.targetGender.includes(cow.gender) && cow[this.form.tallyBy] && cow.breed;
      });
    }).catch((e) => {
      console.error(e);
    });
  }

  onPrint() {
    const tableInfo = document.querySelector('.table-info.print');
    while (tableInfo.lastChild) {
      tableInfo.removeChild(tableInfo.lastChild);
    }
    Object.keys(this.form).forEach((key) => {
      const div = document.createElement('div');
      switch (key) {
      case 'dateField':
        const dateFieldLabel = this.dateFieldOptions.filter((option) => {
          return option.value === this.form[key];
        })[0].label;
        div.innerHTML = `対象期間: ${dateFieldLabel}`;
        break;

      case 'yearMonth':
        if (this.form.dateFieldType === 'yearMonth') {
          const yearMonth = this.form.yearMonth;
          const yearMonthStr = `${yearMonth.substr(0, 4)}年${yearMonth.substr(4, 6)}月`;
          div.innerHTML = `月指定: ${yearMonthStr}`;
          break;
        }
        return;

      case 'startDate':
        if (this.form.dateFieldType === 'arbitrary') {
          div.innerHTML = `期間指定: ${this.DateUtilService.toJapaneseYYYYMMDD(this.form.startDate)} ~ ${this.DateUtilService.toJapaneseYYYYMMDD(this.form.endDate)}`;
          break;
        }
        return;

      case 'tallyBy':
        div.innerHTML = `集計基準: ${Carcass.tallyByOptions[this.form.tallyBy]}`;
        break;

      default:
        return;
      }
      tableInfo.insertAdjacentElement('beforeend', div);
    });
    print();
  }
}

app.controller('CarcassReportController', CarcassReportController);
