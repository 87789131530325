class ReportSummaryContainerController {
  hasSummaries() {
    return this.summaries.length > 0;
  }
}

function reportSummaryContainerComponent() {
  return {
    templateUrl: 'report/summary/container/index.html',
    controller: ReportSummaryContainerController,
    bindings: {
      title: '@',
      summaries: '<'
    },
  };
}

app.component('reportSummaryContainer', reportSummaryContainerComponent());
