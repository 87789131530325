function cowBoardComponent() {
  'ngInject';
  return {
    controller: 'CowBoardController',
    templateUrl: 'components/cow-board/index.html',
    bindings: {
      isDepositor: '<',
      currentCowId: '<',
      currentQwert: '<',
      updateCurrentCow: '&',
      defaultTab: '<',
      defaultPeriod: '<'
    }
  };
}
app.component('cowBoard', cowBoardComponent());
