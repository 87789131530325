class InseminatorEditDialogController {
  constructor(
    $timeout,
    $modalInstance,
    SessionCache,
    params,
    blockUI,
    StandardDialog,
    MasterInseminatorAPI,
    InseminatorDialog
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$modalInstance = $modalInstance;
    this.blockUI = blockUI;
    this.StandardDialog = StandardDialog;
    this.MasterInseminatorAPI = MasterInseminatorAPI;
    this.InseminatorDialog = InseminatorDialog;
    this.SessionCache = SessionCache;

    this.init(params);
  }

  init(params) {
    this.prefectures = [
      '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県',
      '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県',
      '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県',
      '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県',
      '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県',
      '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県',
      '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'
    ];

    this.model = {
      id: params.id,
      name: params.name,
      licencedPrefecture: params.licencedPrefecture,
      licencedNumber: params.licencedNumber,
      address: params.address
    };

    this.action = params.action;
    switch (this.action) {
    case 'create':
      this.disabled = false;
      this.action_name = '登録';
      this.title = '新規登録';
      break;
    case 'update':
      this.disabled = false;
      this.action_name = '更新';
      this.title = '更新';
      break;
    case 'delete':
      this.disabled = true;
      this.action_name = '削除';
      this.title = '削除';
      break;
    }

    this.invalid = false;

    if (this.action === 'create') {
      this.invalid = true;
      this.showMessageModal();
      this.defaultLicencedPrefecture();
    }
  }

  validate() {
    if (this.model.name === undefined || this.model.name === null) {
      this.invalid = true;
      return;
    }
    this.invalid = false;
  }

  executeButtonClass() {
    return this.action === 'delete' ? 'uButton--delete' : 'uButton';
  }

  showMessageModal() {
    this.StandardDialog.showMessage({
      title: '授精師設定',
      text1: '契約している外部の獣医師や授精師の情報を設定する際には、本人の承諾を得たうえで設定してください'
    });
  }

  defaultLicencedPrefecture() {
    const farmAddress = this.SessionCache.farm().data.farmAddress;

    if (farmAddress) {
      const matchPrefecture = farmAddress.match(/^(.{2}[都道府県]|.{3}県)/);

      if (matchPrefecture) {
        this.model.licencedPrefecture = matchPrefecture[0];
      }
    }
  }

  execute() {
    switch (this.action) {
    case 'create':
      this.create();
      break;
    case 'update':
      this.update();
      break;
    case 'delete':
      this.delete();
      break;
    }
  }

  create() {
    const params = {
      name: this.model.name,
      licencedPrefecture: this.model.licencedPrefecture,
      licencedNumber: this.model.licencedNumber,
      address: this.model.address
    };

    this.blockUI.start('登録中');

    this.MasterInseminatorAPI.create(params).then((res) => {
      this.blockUI.done(() => {
        this.blockUI.start('登録が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);
      });
      this.InseminatorDialog.execute();
      this.blockUI.stop();
      this.$modalInstance.close();
    });
  }

  update() {
    const params = {
      id: this.model.id,
      name: this.model.name,
      licencedPrefecture: this.model.licencedPrefecture,
      licencedNumber: this.model.licencedNumber,
      address: this.model.address
    };
    this.blockUI.start('更新中');

    this.MasterInseminatorAPI.update(params).then((res) => {
      this.blockUI.done(() => {
        this.blockUI.start('更新が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);
      });
      this.InseminatorDialog.execute();
      this.blockUI.stop();
      this.$modalInstance.close();
    });
  }

  delete() {
    this.blockUI.start('削除中');

    this.MasterInseminatorAPI.delete(this.model.id).then((res) => {
      this.blockUI.done(() => {
        this.blockUI.start('削除が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);
      });
      this.InseminatorDialog.execute();
      this.blockUI.stop();
      this.$modalInstance.close();
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  clearLicencedPrefecture() {
    this.model.licencedPrefecture = '';
  }
}

app.controller('InseminatorEditDialogController', InseminatorEditDialogController);
