class CowHeadageAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  summary() {
    return this.APIService.get('/cow/headage/summary');
  }

  details(context) {
    let params = `category=${context.category}&sub_category=${context.subCategory}`;
    if (context.item) {
      params = `${params}&item=${context.item}`;
    }
    return this.APIService.get(`/cow/headage/details?${params}`);
  }
}

app.service('CowHeadageAPI', CowHeadageAPI);
