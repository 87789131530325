class GroupTransferHistoryEditController {
  constructor(
    $timeout,
    $modalInstance,
    params,
    blockUI,
    StandardDialog,
    GroupCowGroupAPI,
    GroupTransferAPI
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$modalInstance = $modalInstance;
    this.params = params;
    this.blockUI = blockUI;
    this.StandardDialog = StandardDialog;
    this.GroupCowGroupAPI = GroupCowGroupAPI;
    this.GroupTransferAPI = GroupTransferAPI;

    this.init();
  }

  init() {
    this.memberToFarms = GroupTransfer.setMemberToFarms(this.params.memberFarms, this.params.history.fromId);
    this.transferTypes = this.params.transferTypes;
    this.operationType = this.params.operationType;
    this.isEdit = this.operationType === 'edit';
    this.heading = this.isEdit ? '履歴の編集' : '履歴の削除';
    this.submitLabel = this.isEdit ? '更新' : '削除';
    this.model = angular.copy(this.params.history);
    this.model.error = {};
    this.model.warning = {
      cowGroupId: null
    };

    this.generateCowGroups().then(() => {
      this.generatePens();

      if (this.isEdit) {
        this.validateCowGroupAttribute();
        this.candidateCow();
      }
    });
  }

  generateCowGroups() {
    return this.GroupCowGroupAPI.index(this.model.input.toId).then((res) => {
      this.cowGroups = res.data;
    });
  }

  generatePens() {
    this.model.pens = GroupTransfer.generatePens(this.cowGroups, this.model.input.cowGroupId);
  }

  decideCowGroupAttribute() {
    const findTransferType = this.transferTypes.find((type) => type.value === this.model.input.transferType) || [];

    return findTransferType.cowGroupAttribute;
  }

  onChangeTransferDate() {
    this.validate();

    if (this.invalid) return;

    this.candidateCow();
  }

  onChangeToId() {
    this.model.pens = null;
    this.model.input.cowGroupId = null;
    this.model.input.pen = null;

    this.generateCowGroups().then(() => {
      this.validateCowGroupAttribute();
      this.generatePens();
    });
  }

  onChangeTransferType() {
    this.validateCowGroupAttribute();
    this.candidateCow();
  }

  onChangeToCowGroupId() {
    this.model.pens = null;
    this.model.input.pen = null;
    this.validate();
    this.generatePens();
  }

  candidateCow() {
    const conditions = {
      transferInputId: this.model.transferInputId
    };

    this.invalidCandidate = false;
    this.model.error = {};

    this.GroupTransferAPI.candidate(
      this.model.fromId,
      this.model.input.transferType,
      this.model.input.transferDate,
      conditions
    ).catch((err) => {
      this.invalidCandidate = true;

      if (err.data) {
        const searchError = err.data.searchError;

        if (searchError) {
          if (searchError.startsWith('状態')) {
            this.model.error.transferType = searchError;

            return;
          }

          this.model.error.transferDate = searchError;

          return;
        } else if (err.data.messages) {
          this.model.error.transferDate = ErrorUtil.formatErrorMessage(err.data.messages);

          return;
        }
      }

      this.model.error.transferDate = '牛個体照会でエラーが発生しました';
    });
  }

  submit() {
    if (this.invalidCandidate) return;

    this.validate();

    if (this.invalid) return;

    const histories = [this.model];

    this.params.function(histories).then((res) => {
      if (res) {
        this.$modalInstance.close();
      }
    });
  }

  validate() {
    this.invalid = false;
    this.model.error = {};

    const invalidDate = GroupTransfer.validateDate(this.model.input.transferDate);

    if (invalidDate) {
      this.invalid = true;
      this.model.error.transferDate = '異動日の入力が正しくありません';
    }

    const noDataRequired = GroupTransfer.validateRequired(this.model.input, GroupTransferHistory.REQUIRED_FIELDS);

    if (noDataRequired.length) {
      this.invalid = true;

      noDataRequired.forEach((item) => {
        this.model.error[item.value] = `${item.label}が入力されていません`;
      });
    }
  }

  validateCowGroupAttribute() {
    const cowGroupAttribute = this.decideCowGroupAttribute();

    this.model.warning.cowGroupId = GroupTransfer.validateCowGroupAttribute(
      this.cowGroups, this.model.input.cowGroupId, cowGroupAttribute);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  classFormLabelRequired() {
    return this.isEdit ? 'uModal__formLabel--required' : 'uModal__formLabel';
  }

  classFormBodyDisabled(name, error, warning) {
    const className = this.isEdit ? name : `${name}--disabled`;

    if (error) {
      return `${className} is-error`;
    } else if (warning) {
      return `${className} is-warning`;
    }

    return className;
  }

  classRemoveSensorDisabled() {
    if (!this.isEdit || !this.model.removeSensorEditable) {
      return 'uModal__formRadioLabel--disabled';
    }

    return 'uModal__formRadioLabel';
  }

  classButton() {
    return this.isEdit ? 'uButton' : 'uButton--delete';
  }
}

app.controller('GroupTransferHistoryEditController', GroupTransferHistoryEditController);
