class OperatorDialogController {
  constructor(
    $modalInstance,
    params
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;

    this.initialize(params);
  }

  initialize(params) {
    this.operators = params.operators;
    this.operator = null;
    this.invalid = true;
  }

  select(operator) {
    this.operator = operator;
    this.invalid = !this.operator;
  }

  ok() {
    this.$modalInstance.close(this.operator);
  }
}

app.controller('OperatorDialogController', OperatorDialogController);
