class GenomSettingGebvIndexHelpController {
  constructor(
    $modalInstance
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;

    this.init();
  }

  init() {

  }

  onClickType(type) {
    const result = GebvIndexSetting.typicalWeights(type);
    this.$modalInstance.close(result);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('GenomSettingGebvIndexHelpController', GenomSettingGebvIndexHelpController);
