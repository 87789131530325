/**
 * @deprecated
 * ユーザー一覧メニューはReactに移行されました。
 * IEユーザーへの対応のために残しています。
 */
class AccountController {
  constructor(
    $modal,
    $rootScope,
    $scope,
    AccountAPI
  ) {
    'ngInject';
    this.$modal = $modal;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.AccountAPI = AccountAPI;

    this.initialize();
  }

  initialize() {
    this.$scope.$on('ngRepeatFinished', () => {
      FixedMidashi.create();
    });

    this.accounts = [];
    this.index();
  }

  index() {
    this.AccountAPI.index().then((res) => {
      const roles = Account.ROLES;
      const accounts = res.data.map((account) => {
        const role = roles[account.role] || {label: 'unknown', editable: false};
        account['roleLabel'] = role.label;
        account['editable'] = role.editable;
        return account;
      });
      this.accounts = accounts;
    });
  }

  create() {
    this.openEditView(ViewMode.CREATE, 'ユーザー登録', null);
  }

  update(id) {
    this.openEditView(ViewMode.UPDATE, 'ユーザー更新', id);
  }

  delete(id) {
    this.openEditView(ViewMode.DELETE, 'ユーザー削除', id);
  }

  openEditView(viewMode, title, id) {
    this.$modal.open({
      templateUrl: 'menu/account/edit.html',
      controller: 'AccountEditController',
      controllerAs: 'ctrl',
      size: 'md',
      backdrop: 'static',
      resolve: {
        params: () => {
          return {
            title: title,
            id: id,
            viewMode: viewMode
          };
        }
      }
    }).result.then(() => {
      this.index();
    });
  }

  itemFilter() {
    this.$modal.open({
      templateUrl: 'menu/account/item-filter.html',
      controller: 'ItemFilterController',
      controllerAs: 'ctrl',
      size: 'md',
      backdrop: 'static',
    }).result.then(() => {
      this.index();
    });
  }
}

app.controller('AccountController', AccountController);
