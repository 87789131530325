/**
 * 対象イベントのhtmlの埋め込む
 * 使い方: <event-template type="eventType"></bulk-event-template>
 */
app.directive('cowEventBulkInputTemplate', eventTemplate);

function eventTemplate($compile) {
  'ngInject';

  const htmlPaths = {
    // 治療系
    mastitis: 'mastitis',
    perinatalDifficulty: 'perinatal-difficulty',
    lame: 'lame',
    infect: 'infect',
    otherDifficulty: 'otherDifficulty',
    // 繁殖系
    freshCheck: 'fresh-check',
    hatsujo: 'hatsujo',
    tanetsuke: 'tanetsuke',
    ninshinkantei: 'ninshinkantei',
    hormoneProgram: 'hormone-program',
    embryo_recovery: 'embryoRecovery',
    kannyu: 'kannyu',
    // その他
    vaccine: 'vaccine',
    hoofTrim: 'hoof-trim',
    gyugunidou: 'gyugunidou',
    sagyomemo: 'sagyomemo',
    touta: 'touta',
    heishi: 'heishi',
    wean: 'wean',
    dehorn: 'dehorn',
    castration: 'castration',
    fattening: 'fattening',
    carcass: 'carcass',
  };

  return {
    restrict: 'E',
    scope: '=',
    link: (s, el, attr) => {
      if (!attr.type) return;

      const htmlPath = htmlPaths[attr.type];

      const template = $compile(
        `<div class="table-scroll" ng-include="'menu/cow-event/bulk-input/event/${htmlPath}.html'"></div>`
      )(s);
      el.replaceWith(template);
    },
  };
}
