class SloppyReportAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/deprecated/sloppy_report');
  }

  run(id) {
    return this.APIService.get(`/deprecated/sloppy_report/${id}/run`);
  }
}

app.service('SloppyReportAPI', SloppyReportAPI);
