// eslint-disable-next-line no-unused-vars
class MobileCustomlistBinder {
  static bind(ctrl, base) {
    angular.extend(ctrl, base);

    const functions = [
      'run',
      'setConfig',
      'shouldShowCowLabels',
      'shouldShowCowGroup',
      'shouldShowPen',
      'shouldShowCowGroupAndPen',
      'listItemClass',
      'onCowClick',
      'onFilterButtonClick',
      'filter',
      'sort',
      'selectAllButtonLabel',
      'onSelectButtonClick',
      'onSelectAllButtonClick',
      'onCheckBoxClick',
      'toggleIsSelectMode',
      'setAllSelected',
      'onActionButtonClick',
      'openBulkEventEntry',
      'setGroupCondition',
    ];
    functions.forEach((f) => {
      ctrl[f] = base[f].bind(ctrl);
    });
  }
}
