// eslint-disable-next-line no-unused-vars
class HeadageSummaryDefinition {
  static categories(category) {
    switch (category) {
    case 'breeding':
      return '経産・未経産（繁殖）';
    case 'dairy':
      return '経産・未経産（酪農）';
    case 'state':
      return '状態別頭数';
    case 'breed':
      return '品種別頭数';
    case 'inventory':
      return '当月棚卸';
    default:
      throw `不正なcategory: ${category}`;
    }
  }

  static menus(context) {
    switch (context.category) {
    case 'breeding':
      return HeadageSummaryDefinition.breedingMenus();
    case 'dairy':
      return HeadageSummaryDefinition.dairyMenus();
    case 'state':
      return HeadageSummaryDefinition.stateMenus(context.isFattening);
    case 'breed':
      return HeadageSummaryDefinition.breedMenus();
    case 'inventory':
      return HeadageSummaryDefinition.inventoryMenus();
    default:
      throw `不正なcategory: ${context.category}`;
    }
  }

  static breedingMenus() {
    return [
      {group: '経産', label: '2歳以下', category: 'breeding', subCategory: 'cow', item: 'age_3_under'},
      {group: '経産', label: '3歳', category: 'breeding', subCategory: 'cow', item: 'age_3'},
      {group: '経産', label: '4歳', category: 'breeding', subCategory: 'cow', item: 'age_4'},
      {group: '経産', label: '5歳以上', category: 'breeding', subCategory: 'cow', item: 'age_4_over'},
      {group: '経産', label: '小計', category: 'breeding', subCategory: 'cow', item: ''},
      {group: '未経産', label: '未経産牛', category: 'breeding', subCategory: 'heifer', item: ''},
      {group: '未経産', label: '子牛（哺育育成）', category: 'breeding', subCategory: 'calf', item: ''},
      {group: '肥育', label: '肥育牛', category: 'breeding', subCategory: 'fattening', item: ''},
      {group: null, label: '分類不可', category: 'breeding', subCategory: 'irregular', item: ''}
    ];
  }

  static dairyMenus() {
    return [
      {group: '経産', label: '搾乳牛', category: 'dairy', subCategory: 'milking', item: ''},
      {group: '経産', label: 'うち休薬中', category: 'dairy', subCategory: 'milking_stop', item: ''},
      {group: '経産', label: '乾乳牛', category: 'dairy', subCategory: 'dry', item: ''},
      {group: '未経産', label: '未経産牛', category: 'dairy', subCategory: 'heifer', item: ''},
      {group: '未経産', label: '子牛（哺育育成）', category: 'dairy', subCategory: 'calf', item: ''},
      {group: null, label: '分類不可', category: 'dairy', subCategory: 'irregular', item: ''}
    ];
  }

  static stateMenus(isFattening) {
    switch (isFattening) {
    case true:
      return [
        {group: '繁殖', label: 'フレッシュ', category: 'state', subCategory: 'non_pregnant', item: 'fresh'},
        {group: '繁殖', label: '未授精', category: 'state', subCategory: 'non_pregnant', item: 'not_bred'},
        {group: '繁殖', label: '未受胎', category: 'state', subCategory: 'non_pregnant', item: 'non_pregnant'},
        {group: '繁殖', label: '授精', category: 'state', subCategory: 'pregnant', item: 'bred'},
        {group: '繁殖', label: '受胎', category: 'state', subCategory: 'pregnant', item: 'pregnant'},
        {group: '繁殖', label: '繁殖除外', category: 'state', subCategory: 'do_not_breed', item: 'do_not_breed'},
        {group: '子牛', label: '哺育', category: 'state', subCategory: 'calf', item: 'infant'},
        {group: '子牛', label: '育成', category: 'state', subCategory: 'calf', item: 'replacement'},
        {group: '肥育', label: '肥育', category: 'state', subCategory: 'fattening', item: 'fatting_stage'},
        {group: null, label: 'その他', category: 'state', subCategory: 'non_fattening', item: 'others'}
      ];
    case false:
      return [
        {group: '繁殖', label: 'フレッシュ', category: 'state', subCategory: 'non_pregnant', item: 'fresh'},
        {group: '繁殖', label: '未授精', category: 'state', subCategory: 'non_pregnant', item: 'not_bred'},
        {group: '繁殖', label: '未受胎', category: 'state', subCategory: 'non_pregnant', item: 'non_pregnant'},
        {group: '繁殖', label: '授精', category: 'state', subCategory: 'pregnant', item: 'bred'},
        {group: '繁殖', label: '受胎', category: 'state', subCategory: 'pregnant', item: 'pregnant'},
        {group: '繁殖', label: '乾乳', category: 'state', subCategory: 'pregnant', item: 'dry'},
        {group: '繁殖', label: '繁殖除外', category: 'state', subCategory: 'do_not_breed', item: 'do_not_breed'},
        {group: '子牛', label: '哺育', category: 'state', subCategory: 'calf', item: 'infant'},
        {group: '子牛', label: '育成', category: 'state', subCategory: 'calf', item: 'replacement'},
        {group: null, label: 'その他', category: 'state', subCategory: 'others', item: 'others'}
      ];
    }
  }

  static breedMenus() {
    return [
      {group: '', label: 'ホルスタイン種', category: 'breed', subCategory: 'breed', item: 'b010'},
      {group: '', label: 'ジャージー種', category: 'breed', subCategory: 'breed', item: 'b020'},
      {group: '', label: '交雑種', category: 'breed', subCategory: 'breed', item: 'b030'},
      {group: '', label: '黒毛和種', category: 'breed', subCategory: 'breed', item: 'b040'},
      {group: '', label: '褐毛和種', category: 'breed', subCategory: 'breed', item: 'b050'},
      {group: '', label: '日本短角種', category: 'breed', subCategory: 'breed', item: 'b060'},
      {group: '', label: '無角種', category: 'breed', subCategory: 'breed', item: 'b070'},
      {group: '', label: '黒毛と褐毛の交雑種', category: 'breed', subCategory: 'breed', item: 'b080'},
      {group: '', label: '和牛間交雑種', category: 'breed', subCategory: 'breed', item: 'b100'},
      {group: '', label: '肉専用種', category: 'breed', subCategory: 'breed', item: 'b110'},
      {group: '', label: '乳用種', category: 'breed', subCategory: 'breed', item: 'b120'},
      {group: null, label: '分類不可', category: 'breed', subCategory: 'breed', item: 'b990'}
    ];
  }

  static inventoryMenus() {
    return [
      {group: '入', label: '導入', category: 'inventory', subCategory: 'increase', item: 'enrollment'},
      {group: '入', label: '出生', category: 'inventory', subCategory: 'increase', item: 'birth'},
      {group: '入', label: '合計', category: 'inventory', subCategory: 'increase', item: ''},
      {group: '出', label: '出荷', category: 'inventory', subCategory: 'decrease', item: 'shipment'},
      {group: '出', label: 'へい死', category: 'inventory', subCategory: 'decrease', item: 'death'},
      {group: '出', label: '合計', category: 'inventory', subCategory: 'decrease', item: ''},
      {group: null, label: '在籍頭数', category: 'inventory', subCategory: 'current', item: ''}
    ];
  }

  static getLabel(context) {
    const findMenu = HeadageSummaryDefinition.menus(context).find((menu) => {
      return menu.subCategory === context.subCategory &&
        menu.item === context.item;
    }) || [];

    return findMenu.label;
  }

  static updateSummary(data, context) {
    return HeadageSummaryDefinition.menus(context).map((menu) => {
      const category = context.category;
      const subCategory = StringUtil.snakeToCamelCase(menu.subCategory);
      const item = StringUtil.snakeToCamelCase(menu.item);
      let count;

      if (typeof data[category][subCategory] !== 'undefined' &&
        typeof data[category][subCategory][item] !== 'undefined') {
        count = data[category][subCategory][item];
      } else if (typeof data[category][item] !== 'undefined') {
        count = data[category][item];
      } else if (item === 'dry') {
        count = 0;
      } else {
        count = data[category][subCategory];
      }

      if (typeof count === 'object') {
        const values = Object.values(count);

        count = values.reduce((sum, cur) => {
          return sum + cur;
        }, 0);
      }

      menu.count = count;
      menu.to = 'headage-details';
      menu.params = {
        category: context.category,
        subCategory: menu.subCategory,
        item: menu.item
      };
      menu.active = menu.subCategory === context.subCategory &&
        menu.item === (context.item || '');

      return menu;
    });
  }

  static grouping(items) {
    return items.reduce((acc, cur) => {
      const findItem = acc.find((item) => {
        return item.group === cur.group;
      });

      if (!findItem) {
        acc.push({
          group: cur.group,
          items: [cur]
        });
      } else {
        findItem.items.push(cur);
      }

      return acc;
    }, []);
  }

  static sortBreedItems(items) {
    const irregular = items.find((item) => {
      return item.group === null;
    });

    const breedItems = items.filter((item) => {
      return item.group === '';
    }).map((item) => {
      item.items = item.items.sort((a, b) => {
        return b.count - a.count;
      });

      return item;
    })[0];

    return [breedItems, irregular];
  }
}
