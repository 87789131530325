function HormoneProgramNameEmptyValidator(UtilService) {
  'ngInject';
  return {
    require: 'ngModel',
    scope: {
      target: '=hormoneProgramNameEmptyValidator',
    },
    link: function(scope, element, attr, model) {
      model.$validators.hormoneProgramNameEmpty = function(modelValue) {
        return (UtilService.xor(scope.target, modelValue)) ? false : true;
      };
      scope.$watch('target', function() {
        model.$validate();
      });
    }
  };
}

app.directive('hormoneProgramNameEmptyValidator', HormoneProgramNameEmptyValidator);
