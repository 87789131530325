// eslint-disable-next-line no-unused-vars
class EventState {
  constructor($q, currentDate = null, IsMobile) {
    this.$q = $q;
    this.currentDate = currentDate || DateUtil.today();
    this.isMobile = IsMobile;
  }

  generateTreatmentState(event, farm, cow, cowEventAPI, previousInput = null) {
    const state = {};
    const ctrl = {
      showDiseaseContinuation: true,
      diseaseContinuation: 'new',
      continuousOccurredDiseaseDate: null,
    };

    if (event.eventType === 'mastitis') {
      state.mastitisCowGroupId = cow.cowGroupId;
    }

    if (previousInput) {
      return this.createPromise({state: state, ctrl: ctrl});
    }

    const date = this.currentDate.getTime();
    state.occurredAt = date;
    state.occurredDiseaseDate = date;
    state.treatmentDiseaseDate = date;

    return cowEventAPI.getByEventType(cow.cowId, event.eventType).then((res) => {
      const occurredEvent = res.data.find((e) => {
        return DateUtil.isSameOrBeforeDay(e.occurredDiseaseDate, event.occurredAt);
      });

      if (occurredEvent) {
        ctrl.continuousOccurredDiseaseDate = occurredEvent.occurredDiseaseDate;
        if (event.eventType === 'mastitis') {
          ctrl.continuousMastitisCowGroupId = occurredEvent.mastitisCowGroupId;
        }

        const isIncludedWashoutPeriod = CowEvent.isIncludedWashoutPeriod(
          event.occurredAt,
          farm.isMilk(),
          occurredEvent.endDateOfBeefWashoutPeriod,
          occurredEvent.endDateOfMilkWashoutPeriod
        );
        if (isIncludedWashoutPeriod) {
          state.occurredDiseaseDate = occurredEvent.occurredDiseaseDate;
          ctrl.diseaseContinuation = 'continuous';

          if (event.eventType === 'mastitis') {
            state.occurredDiseaseDate = cow.occurredMastitisDate || event.occurredAt;
            ctrl.continuousOccurredDiseaseDate = state.occurredDiseaseDate;
            state.mastitisCowGroupId = occurredEvent.mastitisCowGroupId;
          }
        }
      }

      return {state: state, ctrl: ctrl};
    });
  }

  createPromise(result) {
    const deferred = this.$q.defer();
    deferred.resolve(result);
    return deferred.promise;
  }

  /**
   * イベントステートに受精卵情報をセットします
   * @param {object} state イベントのステート
   * @param {object} MasterEmbryoAPIが返す受精卵情報
   */
  static setEmbryo(state, embryo) {
    if (!embryo) return state;

    state['embryoName'] = embryo.name;
    state['masterEmbryoId'] = embryo.id;
    state['masterSpermId'] = embryo.masterSpermId;
    state['donorName'] = embryo.donorName;
    state['donorRegistrationNo'] = embryo.donorRegistrationNo;
    state['donorCowUid'] = embryo.donorCowUid;
    state['donorBreed'] = embryo.donorBreed;
    state['donorBreedingValue'] = embryo.donorBreedingValue;
    state['fatherNameOfDonor'] = embryo.fatherNameOfDonor;
    state['fatherRegistrationNoOfDonor'] = embryo.fatherRegistrationNoOfDonor;
    state['motherNameOfDonor'] = embryo.motherNameOfDonor;
    state['motherRegistrationNoOfDonor'] = embryo.motherRegistrationNoOfDonor;
    state['motherBreedOfDonor'] = embryo.motherBreedOfDonor;
    state['maternalGrandfatherNameOfDonor'] = embryo.maternalGrandfatherNameOfDonor;
    state['maternalGrandfatherRegistrationNoOfDonor'] = embryo.maternalGrandfatherRegistrationNoOfDonor;
  }
}
