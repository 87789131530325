class ActivityAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  /**
   * 指定した牛個体の3時間毎の活動量合計を返す。
   *
   * @param {number} cowId
   * @param {Date} startAt 対象期間の開始日時
   * @param {Date} endAt 対象期間の終了日時
   * @param {string} summaryType SERIES3H or LATEST3H
   * @return {Array.{object}}
   */
  hourlySummary(cowId, startAt, endAt, summaryType = 'SERIES3H') {
    const params = [
      `cow_id=${cowId}`,
      `start_at=${DateUtil.toMSec(startAt)}`,
      `end_at=${DateUtil.toMSec(endAt)}`,
      `summary_type=${summaryType}`
    ].join('&');

    return this.APIService.get(`/activity/hourly?${params}`);
  }

  /**
   * 指定した牛個体の日毎の活動量合計を返す。
   *
   * @param {number} cowId
   * @param {Date} startDate 対象期間の開始日
   * @param {Date} endDate 対象期間の終了日
   * @return {Array.{object}}
   */
  dailySummary(cowId, startDate, endDate) {
    const startDateW3C = DateUtil.toW3CFormat(startDate);
    const endDateW3C = DateUtil.toW3CFormat(endDate);
    const params = `start_date=${startDateW3C}&end_date=${endDateW3C}`;

    return this.APIService.get(`/activity/cow/${cowId}/daily?${params}`);
  }
}

app.service('ActivityAPI', ActivityAPI);
