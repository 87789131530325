class MedicineSelectModalController {
  constructor(
    $scope,
    $modal,
    $modalInstance,
    MasterMedicineAPI,
    DepositMasterMedicineService,
    params,
    SessionCache
  ) {
    'ngInject';
    this.$scope = $scope;
    this.$modal = $modal;
    this.$modalInstance = $modalInstance;
    this.treatmentMedicineMethods = Medicine.METHODS;
    this.selectedMedicines = params.selectedMedicines;
    this.eventType = params.eventType;

    // 預託元コンテキストか否かでサービスの使い分け
    this.MasterMedicineAPI = params.qwert ? DepositMasterMedicineService : MasterMedicineAPI;
    this.DepositMasterMedicineService = DepositMasterMedicineService;
    this.qwert = params.qwert;

    const farm = SessionCache.farm();
    this.isAgent = farm.isAgent();
    this.isMilkFarm = farm.isMilk();

    this.initialize(params.qwert, MasterMedicineAPI, DepositMasterMedicineService);
  }

  // public method

  /**
   * OKボタンクリック時処理
   */
  ok() {
    const medicines = this.rawMedicines.filter((medicine) => medicine.selected);
    this.$modalInstance.close(medicines);
  }

  /**
   * キャンセルボタンクリック時処理
   */
  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  /**
   * 引数の薬品IDが選択済みか確認する。
   * 選択済みの場合はselectedMedicineをコールする。
   *
   * @param {string} medicineId 薬品ID
   */
  checkSelected(medicineId) {
    this.selectedMedicines.forEach((medicine) => {
      if (medicine.id === medicineId) this.switchingSelectOfMedicine(medicineId);
    });
  }

  /**
   * 薬品の選択状態を切り替える。
   *
   * @param {string} medicineId 薬品ID
   */
  switchingSelectOfMedicine(medicineId) {
    this.medicines.forEach((medicine) => {
      if (medicine.id === medicineId) medicine.selected = !medicine.selected;
    });
  }

  // private method

  initialize(qwert, masterMedicineService, depositMasterMedicineService) {
    this.$scope.$on('ngRepeatFinished', function() {
      FixedMidashi.create();
    });
    this.getMedicines(qwert, masterMedicineService, depositMasterMedicineService);

    this.invalid = false;
  }

  /**
   * 薬品一覧を取得する。
   */
  getMedicines(qwert, masterMedicineService, depositMasterMedicineService) {
    const promise = qwert ?
      depositMasterMedicineService.available(qwert) : masterMedicineService.available();

    return promise.then((res) => {
      const medicines = res.data.filter((medicine) => medicine[this.eventType]);
      const selectedIds = this.selectedMedicines.map((m) => m.id);
      this.medicines = CollectionUtil.selectableMasters(medicines, selectedIds);
      this.addCapacityAndMethodToMedicine();
      this.rawMedicines = [].concat(this.medicines);
    }).catch((error) => {
      console.error('Get medicines', error);
    });
  }

  /**
   * 選択済の薬品に前回選択時の容量、処置方法を付加する。
   * 未選択の薬品は処置方法としてデフォルトの処置方法を付加する。
   */
  addCapacityAndMethodToMedicine() {
    this.medicines.forEach((medicine) => {
      const foundMedicine = this.selectedMedicines.find((m) => medicine.id === m.id);
      if (foundMedicine) {
        angular.merge(medicine, foundMedicine);
      } else {
        medicine.method = medicine.defaultTreatmentMethod;
      }
    });
  }

  onChangeFilterCondition() {
    this.medicines = this.rawMedicines.filter((m) => {
      return !this.filterCondition || m.name.includes(this.filterCondition);
    });
  }

  onChangeNumberInput(record, field) {
    record[field] = StringUtil.toHalfWidthCharacters(record[field]);
    this.validate();
  }

  validate() {
    const errors = [];

    this.medicines
      .forEach((m, index) => {
        if (!m.selected) return;

        if (m.capacity && !StringUtil.isNumeric(m.capacity)) {
          errors.push(`${index + 1}行目: 入力できるのは小数点を含めた数値のみです`);
        }
      });

    if (errors.length) {
      this.errors = errors;
      this.invalid = true;
    } else {
      this.invalid = false;
    }
  }

  openNewMedicineModal() {
    this.$modal.open({
      templateUrl: 'menu/master/medicine/edit-legacy.html',
      controller: 'MasterMedicineEditController',
      controllerAs: 'ctrl',
      size: 'md',
      backdrop: 'static',
      resolve: {
        params: () => {
          return {
            title: '薬品の登録',
            model: null,
            viewMode: ViewMode.CREATE,
            isLegacy: true,
          };
        }
      }
    }).result.then((newMedicine) => {
      if (!newMedicine) return;

      this.getMedicines(this.qwert, this.MasterMedicineAPI, this.DepositMasterMedicineService)
        .then(() => {
          const medicine = this.medicines.find((m) => m.name === newMedicine.name);
          if (medicine) {
            medicine.selected = true;
          }
        });
    });
  }
}

app.controller('MedicineSelectModalController', MedicineSelectModalController);
