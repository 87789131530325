class MainHeaderComponentController {
  constructor(
    $modal,
    SessionCache
  ) {
    'ngInject';
    this.$modal = $modal;
    this.isDepositorFarm = SessionCache.farm().isDepositor();
    this.isGroupOrganizer = SessionCache.farm().isGroupOrganizer();
  }

  bulkEventEntry() {
    this.$modal.open({
      templateUrl: 'components/event-registration/event-selection.html',
      controller: 'EventSelectionController',
      controllerAs: 'ctrl',
      size: '1012',
      resolve: {
        cowIds: () => [],
        cowType: () => null,
        isDepositor: () => false,
        qwert: () => 0,
        caller: () => {}
      },
    });
  }

  singleEventEntry() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'components/event-registration/cow-selection.html',
      controller: 'CowSelectionController as ctrl'
    });
  }

  logoClass() {
    return environment.umEnv === 'sand' ?
      'uHeader__logoSand' :
      'uHeader__logo';
  }

  searchClass() {
    return this.isDepositorFarm
      ? 'uHeader__search--depositor'
      : '';
  }

  searchDetailClass() {
    return this.isDepositorFarm
      ? 'uHeader__searchDetail--depositor'
      : '';
  }

  notificationClass() {
    return this.isDepositorFarm
      ? 'uHeader__notification--depositor'
      : 'uHeader__notification';
  }
}

function mainHeaderComponent() {
  return {
    templateUrl: 'components/main-header/index.html',
    transclude: true,
    controller: MainHeaderComponentController,
  };
}

app.component('mainHeader', mainHeaderComponent());
