class MobileAlertCalvingSensorDroppedController {
  constructor(
    $state,
    $timeout,
    blockUI,
    AlertCalvingSensorDroppedAPI,
    CowCalvingSensorHistoryAPI,
    MobileConfirmModal,
    MobileStandardModal,
    MobileEditCalvingSensorHistoryModal
  ) {
    'ngInject';
    this.$state = $state;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.AlertCalvingSensorDroppedAPI = AlertCalvingSensorDroppedAPI;
    this.CowCalvingSensorHistoryAPI = CowCalvingSensorHistoryAPI;
    this.MobileConfirmModal = MobileConfirmModal;
    this.MobileStandardModal = MobileStandardModal;
    this.MobileEditCalvingSensorHistoryModal = MobileEditCalvingSensorHistoryModal;

    this.title = '分娩センサー脱落アラート';
    this.init();
  }

  init() {
    this.shouldShowSolutionSelection = false;
    this.currentAlert = null;
    this.isSolving = false;

    this.AlertCalvingSensorDroppedAPI.unsolved().then((res) => {
      this.rawAlerts = res.data.map((d) => AlertCalvingSensorDropped.toViewModel(d));
      this.selectUnsolvedAlerts();
    });
  }

  selectUnsolvedAlerts() {
    this.alerts = this.rawAlerts.filter((alert) => !alert.solved);
  }

  update(solution) {
    const params = {solution: solution};

    this.blockUI.start('登録中');

    this.AlertCalvingSensorDroppedAPI.update(this.currentAlert.id, params).then((res) => {
      this.blockUI.done(() => {
        this.blockUI.start('処置内容の登録が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);
      });

      this.currentAlert.solved = true;
      this.selectUnsolvedAlerts();

      this.blockUI.stop();
    }).catch((error) => {
      const errorMessage = ErrorUtil.formatErrorMessage(error.data.messages);
      this.blockUI.stop();
      this.MobileConfirmModal.open(errorMessage);
    }).finally(() => {
      this.currentAlert = null;
    });
  }

  goToCowDetail(cowId) {
    this.$state.go('mobileCowDetail', {cowId: cowId});
  }

  showSolutionSelection(id) {
    this.currentAlert = this.alerts.find((a) => a.id === id);
    this.shouldShowSolutionSelection = true;
  }

  hideSolutionSelection() {
    this.shouldShowSolutionSelection = false;
  }

  solve(solution) {
    if (this.isSolving) return;

    this.isSolving = true;

    if (['ignore', 'reequip'].includes(solution)) {
      const solutionText = solution === 'ignore' ? '問題なし' : '再装着';
      const message = `処置内容を「${solutionText}」で登録します。\nよろしいでしょうか？`;
      this.MobileStandardModal.open(message, {okLabel: '登録'}).then((res) => {
        if (!res) return;
        this.hideSolutionSelection();
        this.update(solution);
      }).finally(() => this.isSolving = false);
    } else {
      this.openEditCowSensorHistoryDialog(solution).finally(() => this.isSolving = false);
    }
  }

  openEditCowSensorHistoryDialog(solution) {
    return this.CowCalvingSensorHistoryAPI.list(this.currentAlert.cowId).then((res) => {
      const params = {
        type: solution,
        cowId: this.currentAlert.cowId,
        calvingSensorHistories: res.data
      };

      return this.MobileEditCalvingSensorHistoryModal.open(params).result.then((res) => {
        if (!res) return;
        this.hideSolutionSelection();
        this.currentAlert.solved = true;
        this.currentAlert = null;
        this.selectUnsolvedAlerts();
      });
    });
  }
}

app.controller('MobileAlertCalvingSensorDroppedController', MobileAlertCalvingSensorDroppedController);
