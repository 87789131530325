app.directive('cowListCols', CowListColDirective);

/**
 * 牛群リスト画面のテーブルのヘッダーやコンテンツを生成する
 * 使い方 <thead || tbody cow-list-cols>
 */
function CowListColDirective($compile, cowListTableHeaders, FarmService, FarmKind) {
  'ngInject';
  // 必須項目
  const permanentHeaders = [
    `<th class="col-w30 p0 noPrint">
      <label class="checkbox--label">
        <input
          type="checkbox"
          class="checkbox"
          ng-model="allSelected"
          ng-click="selectAllRows(allSelected)"
          >
      </label>
    </th>`,
    '<th class="col-w100">個体ラベル</th>',
    '<th class="col-w85" ng-if="isMeatFarm">牛房</th>',
    '<th class="col-w85"><caption-cow-no></caption-cow-no></th>',
    '<th class="col-w100" ng-if="showSensorNo">個体識別番号<br>(センサー番号)</th>',
  ];
  const stateHeader = ['<th class="col-w100">状態<br>（双子受胎）</th>'];
  const permanentColumns = [
    `<td class="p0 noPrint">
      <label class="checkbox--label">
        <input
          type="checkbox"
          class="checkbox"
          ng-model="c.selected"
          ng-click="selectRow(c.selected)"
          >
      </label>
    </td>`,
    `<td>
      <span class="cow-labels kindBackgroundColor"
            style="font-size: 1.2rem; text-align: center;"
            ng-repeat="label in c.cowLabels track by $index"
            ng-bind="label"></span>
    </td>`,
    `<td ng-if="isMeatFarm">
      <span ng-bind="c.pen || '-'"></span>
    </td>`,
    `<td>
      <a ng-bind="c.cowNo || '-'"
         ng-click="goToDetails(c.cowId)"></a>
    </td>`,
    `<td ng-if="showSensorNo">
      <span ng-bind="formatCowUid(c.cowUid)"></span><br>
      <span ng-bind="c.bleId? '(' + c.bleId + ')': ''"></span>
    </td>`,
  ];
  const stateColumn = [
    `<td>
      <span ng-bind="c.state"></span><br>
      <span ng-bind="c.pregnancy === '双子受胎'? '(' + c.pregnancy + ')': ''"></span>
    </td>`,
  ];

  // X日から経過日を持つコラム
  const keysWithPassedByDates = [
    'latestCalvingDate',
    'latestPregnancyDate',
    'latestJudgePregnantDate',
    'latestDryPreviousPeriodStartDate',
    'latestFertilizationDate',
  ];

  /**
   * 牧場種類や牛群属性により、生成するコラムをフィルターする
   * @param {string} farmKind
   * @param {string} groupAttr
   * @return {array} cowListTableHeaders
   */
  function getKeys(farmKind, groupAttr) {
    const keys = Object.keys(cowListTableHeaders)
      .filter((key) => !!cowListTableHeaders[key][farmKind][groupAttr]);

    // 育成牛群の場合、子牛番号と母牛番号のキーを外す
    if (groupAttr === 'calfDefaultGroup' || groupAttr === 'calfGroup') {
      return keys
        .filter((key) => key !== 'calfNo')
        .filter((key) => key !== 'motherCowNo');
    }

    return keys;
  }

  /**
   * <TH>を生成する
   * @param {array} ColKeys
   */
  function generateHeaders(ColKeys) {
    return ColKeys.map((key) => {
      if (keysWithPassedByDates.some((dateKey) => dateKey === key)) {
        return `<th class="col-w120">${cowListTableHeaders[key].headerText}</th>`;
      } else {
        return `<th class="col-w85">${cowListTableHeaders[key].headerText}</th>`;
      }
    });
  }

  /**
   * <TD>を生成する
   * @param {array} ColKeys
   */
  function generateColumns(ColKeys) {
    return ColKeys.map((key) => addDaysPassedIfAny(key));
  }

  /**
   * 経過日数を添付する
   *  月齢の場合誕生日を添付
   * @param {string} string
   */
  function addDaysPassedIfAny(string) {

    if (keysWithPassedByDates.indexOf(string) + 1) {
      return `<td>{{c.${string}}}{{c.${string}DaysPassed}}</td>`;
    } else if (string === 'latestBcsDate') {
      return `<td>{{c.latestBcsDate}}({{c.latestBcs}})</td>`;
    } else if (string === 'monthAge') {
      return `<td>{{c.monthAge}} {{c.birthDate}}</td>`;
    } else if (string === 'introduceDate') {
      return `<td>{{c.introduceDate}}</td>`;
    }

    return `<td>{{c.${string}}}</td>`;
  }

  return {
    restrict: 'A',
    link: (s, el, attr) => {
      // 共通関数を紐づけ
      s.formatCowUid = formatCowUid;

      FarmService.show().then((account) => {
        // ヘッダーを生成する
        if (el[0].tagName === 'THEAD') {
          let calfHeaders = [];
          let colKeys = getKeys(FarmKind[account.farmKind], s.groupAttr);
          // 育成牛群の場合、子牛番号と母牛番号を別途設置する
          if (s.groupAttr === 'calfDefaultGroup' || s.groupAttr === 'calfGroup') {
            calfHeaders = generateHeaders(['calfNo', 'motherCowNo']);
          }
          const headers = generateHeaders(colKeys);
          const cols = `
            <tr>
            ${permanentHeaders}
            ${calfHeaders}
            ${stateHeader}
            ${headers}
            </tr>
          `;
          const rows = $compile(cols)(s);
          el.append(rows);
        }

        // コンテンツを生成する
        s.$watch('cows.length', (value) => {
          // データ揃ってからTBODYの中身を生成
          if (!value) {
            return;
          }

          if (el[0].tagName === 'TBODY') {
            let calfColumns = [];
            let colKeys = getKeys(FarmKind[account.farmKind], s.groupAttr);
            // 育成牛群の場合、子牛番号と母牛番号を別途設置する
            if (s.groupAttr === 'calfDefaultGroup' || s.groupAttr === 'calfGroup') {
              calfColumns = generateColumns(['calfNo', 'motherCowNo']);
            }
            const cols = generateColumns(colKeys);
            const row = `
              <tr ng-repeat="c in cows | filter : searchFilter | orderBy:predicate:reverse as filteredCows track by $index"
                  ng-class="{'row--selected': c.selected}">
              ${permanentColumns}
              ${calfColumns}
              ${stateColumn}
              ${cols}
              </tr>
            `;
            const result = $compile(row)(s);
            el.append(result);
          }
        });
      });
    },
  };
}
