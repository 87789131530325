// eslint-disable-next-line no-unused-vars
class UiGridGenerator {

  /**
   * 指定された値の UI Grid の定義を生成して返す。
   *
   * @param {Object} ctrl 呼出元のコントローラのインスタンス
   * @param {Array.<Object>} columns 列定義の配列(フィールドはcolumnId、label、width)
   * @param {Object} options 文脈毎の差異を吸収するためのオプション設定
   *                   - clickRowEvent 行選択時に発火するイベントハンドラーの関数名
   *                   - cellTemplates 列毎に固有のHTMLを描画したい場合にcolumnId毎のHTMlテンプレートを指定する
   *  ex.
   *  cellTemplates: {
   *    visible: `<label class="cow-select"><input type="checkbox" ng-model="row.entity.visible"></label>`
   *  }
   *
   * @return {Object} UI Grid 設定
   */
  generate(ctrl, columns, options = {clickRowEvent: '', cellTemplates: {}}) {
    return {
      appScopeProvider: ctrl,
      enableSorting: false,
      columnDefs: this.generateColumnDefs(columns, options.cellTemplates),
      rowTemplate: this.generateRowTemplate(options.clickRowEvent),
      onRegisterApi: (gridApi) => {
        ctrl.gridApi = gridApi;
      },
    };
  }

  generateColumnDefs(columns, cellTemplates) {
    return columns.map((c) => {
      return {
        name: c.label,
        field: c.columnId,
        headerCellTemplate: this.generateHeaderTemplate(c),
        cellTemplate: cellTemplates[c.columnId],
        cellClass: (grid, row, col, rowRenderIndex, colRenderIndex) => {
          if (row.entity.selected) {
            return 'ui-grid-row-selected';
          } else {
            return '';
          }
        },
        enableColumnMenu: false,
        width: c.width,
        minimumColumnSize: c.width,
      };
    });
  }

  generateHeaderTemplate(column) {
    return `<div>${column.label}</div>`;
  }

  generateRowTemplate(clickRowEvent) {
    if (!clickRowEvent) return null;

    return `
      <div ng-click="grid.appScope.${clickRowEvent}(row.entity)">
        <div 
          ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
          class="ui-grid-cell"
          ng-class="col.colIndex()"
          ui-grid-cell>
        </div>
      </div>`;
  }
}
