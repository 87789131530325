class DailyReportAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index(date, type) {
    return this.APIService.get(`/daily_report/${date}/${type}`);
  }

  summary(date) {
    return this.APIService.get(`/daily_report/summary?date=${date}`);
  }
}

app.service('DailyReportAPI', DailyReportAPI);
