class HomeHeadageSummaryStateController {
  constructor(
    $state,
    SessionCache
  ) {
    'ngInject';

    this.$state = $state;

    HomeHeadageBaseController.bind(this);

    const farm = SessionCache.farm();

    this.init(farm);
  }

  init(farm) {
    this.isDairy = farm.isDairy();
    this.isBreeding = farm.isBreeding();
    this.isFattening = farm.isFattening();
    this.calfManagement = farm.data.calfManagement;
  }

  $onChanges() {
    if (!this.data) return;

    this.showInfant = this.getShowInfant();
    this.showReplacement = this.getShowReplacement();
    this.showCalf = this.getShowCalf();
    this.showFattingStage = this.getShowFattingStage();
  }

  getShowInfant() {
    if ((this.isDairy || this.isBreeding) && this.calfManagement !== 'never') return true;

    return this.data.state.infant;
  }

  getShowReplacement() {
    if ((this.isDairy || this.isBreeding) && this.calfManagement !== 'never') return true;

    return this.data.state.replacement;
  }

  getShowCalf() {
    return this.showInfant || this.showReplacement;
  }

  getShowFattingStage() {
    if (this.isFattening) return true;

    return this.data.state.fattingStage;
  }

  showBreeding() {
    return this.isDairy || this.isBreeding;
  }

  showOthers() {
    return !this.isFattening && this.data.state.others;
  }

  showNonFattening() {
    return this.isFattening && this.data.state.others;
  }
}

function homeHeadageSummaryState() {
  return {
    templateUrl: 'menu/home/headage/summary/state/index.html',
    controller: HomeHeadageSummaryStateController,
    controllerAs: 'ctrl',
    bindings: {
      data: '<'
    }
  };
}

app.component('homeHeadageSummaryState', homeHeadageSummaryState());
