// eslint-disable-next-line no-unused-vars
class Notification {
  constructor(obj) {
    Object.assign(this, obj);
    this.startAt = obj.startAt ? DateUtil.toTimestampFormat(new Date(obj.startAt)) : '';
    this.endAt = obj.endAt ? DateUtil.toTimestampFormat(new Date(obj.endAt)) : '';
    this.category = Notification.CATEGORIES[obj.category];
  }

  static get CATEGORIES() {
    return {
      MAINTENANCE: {
        key: 'maintenance',
        label: 'メンテナンス'
      },
      UPDATE: {
        key: 'update',
        label: 'アップデート'
      },
      INCIDENT: {
        key: 'incident',
        label: '障害情報'
      },
      INFORMATION: {
        key: 'information',
        label: 'お知らせ'
      }
    };
  }

  static filter(notifications, isImportant) {
    const priority = isImportant ? 1 : 0;
    return notifications
      .filter((elem) => Date.now() > elem.startAt && Date.now() < elem.endAt)
      .filter((elem) => elem.priority === priority);
  }

  static sort(notifications) {
    return notifications.sort((a, b) => b.startAt - a.startAt);
  }

  static decorate(notifications) {
    return notifications.map((elem) => new Notification(elem));
  }
}
