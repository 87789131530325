class ReproductionSummaryController {
  constructor(
    $state,
    $scope,
    $stateParams,
    CustomlistRunner,
    ReproductionCowGroupSummaryAPI
  ) {
    'ngInject';

    this.$state = $state;
    this.$scope = $scope;
    this.CustomlistRunner = CustomlistRunner;
    this.ReproductionCowGroupSummaryAPI = ReproductionCowGroupSummaryAPI;

    this.summaryTpe = $stateParams.summaryTpe || 'cowGroup';
    this.summaries = [];
    this.showCowSelect = true;
    this.callerFuncs = {
      refreshGrid: () => {}
    };
    this.componentFuncs = {};
    this.totals = {
      notBredEarlyCount: 0,
      notBredMidCount: 0,
      notBredLateCount: 0,
      bredCount: 0,
      pregnantCount: 0,
    };
    this.selected = {
      customlistId: $stateParams.customlistId,
      cowGroupName: $stateParams.cowGroupName
    };
    this.isAPIError = false;
    this.isCustomlistError = false;
    this.callbackParam = {};

    this.init();
  }

  init() {
    this.isLoading = true;
    this.ReproductionCowGroupSummaryAPI.show()
      .then((resp) => {
        if (resp && resp.status === 200) {
          this.summaries = resp.data;
          this.summaries.forEach((s) => {
            this.totals.notBredEarlyCount += s.notBredEarlyCount;
            this.totals.notBredMidCount += s.notBredMidCount;
            this.totals.notBredLateCount += s.notBredLateCount;
            this.totals.bredCount += s.bredCount;
            this.totals.pregnantCount += s.pregnantCount;
          });
          if (this.selected.customlistId && this.selected.cowGroupName) {
            this.onCellClick(this.selected.customlistId, this.selected.cowGroupName);
          }
          this.setCallbackParams();
        }
      }).catch((error) => {
        console.error('error: ', error);
        this.isAPIError = true;
      }).finally(() => this.isLoading = false);
  }

  setCallbackParams() {
    this.callbackParam = {
      state: 'reproduction-summary-by-cow-group',
      name: '牛群別繁殖頭数',
      params: {
        name: '牛群別繁殖頭数',
        customlistId: this.selected.customlistId,
        cowGroupName: this.selected.cowGroupName
      },
      bqNotes: `reproduction-summary-by-cow-group`
    };
  }

  goToDetails(cowId) {
    const cowIds = this.cows.map((cow) => cow.cowId);
    this.callbackParam['cowIds'] = cowIds;
    this.$state.go('cowDetail', {cowId: cowId, caller: this.callbackParam});
  }

  onCellClick(customlistId, cowGroupName) {
    if (this.cows && this.cows.length > 0) {
      this.cows = [];
      this.setUiGrid();
    }
    this.showUiGrid = false;
    this.runCustomlist(customlistId, cowGroupName).then((cows) => {
      this.cows = cows;
      this.setUiGrid();
      this.showUiGrid = true;
      this.selected.customlistId = customlistId;
      this.selected.cowGroupName = cowGroupName;
      this.setCallbackParams();
    });
  }

  isSelected(customlistId, cowGroupName) {
    return this.selected.customlistId === customlistId &&
      this.selected.cowGroupName === cowGroupName;
  }

  runCustomlist(customlistId, cowGroupName) {
    const targetDate = new Date();
    this.isCowLoading = true;
    return this.CustomlistRunner.run(
      customlistId,
      targetDate,
      {}
    ).then((res) => {
      this.columns = res.data.config.columns.filter((c) => c.columnId !== 'cow_group_name');
      const columnMap = {};
      this.columns.forEach((c) => {
        columnMap[c.columnId] = c;
      });
      this.columnMap = columnMap;
      this.needLabelAction = this.columns.some((c) => c.columnId === 'cow_labels');

      this.isCowLoading = false;
      this.isCustomlistError = false;
      return res.data.cows.filter((c) => c.cow_group_name === cowGroupName);
    }).catch(() => {
      this.isCustomlistError = true;
    });
  }

  setUiGrid() {
    const showCowNoLink = !this.isDepositorMode;
    const generator = new UiGridColumnDefGenerator(this.columnMap, showCowNoLink);
    const columnDefs = generator.generate(this.columns);

    this.uiGrid = {
      appScopeProvider: this,
      enableSorting: false,
      columnDefs: columnDefs,
      rowTemplate: generator.generateRowTemplate(),
      data: this.cows,
    };
  }
}

app.controller('ReproductionSummaryController', ReproductionSummaryController);
