class CalvingConfigAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  show() {
    return this.APIService.get('/calving_config');
  }

  update(params) {
    return this.APIService.put('/calving_config', params);
  }
}

app.service('CalvingConfigAPI', CalvingConfigAPI);
