class CalvingSensor { // eslint-disable-line no-unused-vars
  static addDisplayFields(events) {
    const capitalize = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return events.map((event) => {
      const converted = angular.copy(event);

      //日付ラベル YYYY/MM/DD
      [
        'latestCalvingDate',
        'latestPregnancyDate',
        'expectedCalvingDate'
      ].forEach((key) => {
        if (!key in converted || !converted[key]) return;

        converted[key + 'Display'] = DateUtil.toYYMMDD(converted[key]);
      });

      // 今日から〜日前
      [
        'expectedCalvingDate'
      ].forEach((key) => {
        if (!key in converted || !converted[key]) return;

        let displayStr = '';
        const days = DateUtil.diffDays(DateUtil.today().valueOf(), DateUtil.startOfDay(Number(converted[key])));

        if (days === 0) {
          displayStr = '今日';
        } else if (days < 0) {
          displayStr = `${Math.abs(days)}日前`;
        } else if (days > 0) {
          displayStr = `${days}日後`;
        }

        converted['daysAfter' + capitalize(key) + 'Display'] = displayStr;
      });

      //経過日数
      [
        'latestCalvingDate',
        'latestPregnancyDate'
      ].forEach((key) => {
        if (key in converted && converted[key]) {
          converted['daysAfter' + capitalize(key)] =
            DateUtil.diffDays(
              DateUtil.startOfDay(Number(converted[key])),
              DateUtil.today().valueOf()
            );
        }
      });

      // 個体ラベル
      if (converted.cowLabels) {
        converted.cowLabelsDisplay = converted.cowLabels.replace(/(\[|\])/g, '').split(',');
      }

      // 予定日
      if (converted.expectedCalvingDateDisplay) {
        converted.expectedCalvingDateMobileDisplay = `${converted.expectedCalvingDateDisplay}(${converted.daysAfterExpectedCalvingDateDisplay})`;
      }

      // 分娩センサー番号
      if (converted.calvingSensorNumber) {
        const splitedNumber = CowCalvingSensorHistory.splitCalvingSensorNumber(converted.calvingSensorNumber);
        const firstNumber = splitedNumber.firstNumber;
        const lastNumber = splitedNumber.lastNumber;

        converted.calvingSensorNumberDisplay = `${firstNumber}-${lastNumber}`;
      }

      return converted;
    });
  }

  static eventTypeLabel(eventType) {
    const map = {
      equip: '装着',
      unequip: '解除'
    };

    return map[eventType];
  }

  static bulkButtonLabel(eventType) {
    const map = {
      equip: '選択した牛に分娩センサーを装着する',
      unequip: '選択した牛の分娩センサーを解除する'
    };

    return map[eventType];
  }

  static mobileButtonLabel(eventType) {
    const map = {
      equip: '分娩センサー装着',
      unequip: '分娩センサー解除'
    };

    return map[eventType];
  }

  static cowFields(isBeef) {
    const common = [
      'expectedCalvingDateMobileDisplay',
      'cowGroupName',
      'pen',
      'cowNo',
      'state',
      'cowLabelsDisplay',
      'birthNumber',
      [
        'latestCalvingDateDisplay',
        'daysAfterLatestCalvingDate'
      ],
    ];

    const beefCommon = isBeef ? [
      'breed',
      'fatherName',
      'maternalGrandfatherName',
      'maternalGreatGrandfatherName'
    ] : [];

    return {
      equip: [
        ...common,
        [
          'latestPregnancyDateDisplay',
          'daysAfterLatestPregnancyDate',
        ],
        [
          'latestBullName',
          'latestMasterSpermNo',
        ],
        ...beefCommon
      ],
      unequip: [
        ...common,
        [
          'latestPregnancyDateDisplay',
          'daysAfterLatestPregnancyDate',
        ],
        [
          'latestBullName',
          'latestMasterSpermNo',
        ],
        ...beefCommon
      ],
    };
  }

  static get cowDisplayLabels() {
    return {
      expectedCalvingDateMobileDisplay: {
        label: '予定日'
      },
      latestCalvingDateDisplay: {
        label: '最新分娩日'
      },
      daysAfterLatestCalvingDate: {
        label: '分娩後日数',
        unit: '日',
        isSubLabel: true
      },
      cowGroupName: {
        label: '牛群'
      },
      pen: {
        label: '牛房'
      },
      cowNo: {
        label: '牛番号'
      },
      state: {
        label: '状態'
      },
      cowLabelsDisplay: {
        label: '個体ラベル',
        type: 'labels'
      },
      birthNumber: {
        label: '産次'
      },
      latestPregnancyDateDisplay: {
        label: '妊娠日'
      },
      daysAfterLatestPregnancyDate: {
        label: '妊娠後日数',
        unit: '日',
        isSubLabel: true
      },
      latestBullName: {
        label: '種雄牛名'
      },
      latestMasterSpermNo: {
        label: '精液番号'
      },
      latestEstrusDateDisplay: {
        label: '最新発情日'
      },
      daysAfterLatestEstrusDate: {
        label: '発情経過日数',
        unit: '日',
        isSubLabel: true
      },
      firstFertilizationDateDisplay: {
        label: '初回授精日'
      },
      daysAfterFirstFertilizationDate: {
        label: '初回授精日数',
        unit: '日',
        isSubLabel: true
      },
      latestBreedingCount: {
        label: '授精回数'
      },
      plannedBredMethod: {
        label: '種付方法',
        isContent: true
      },
      hormoneProgramName: {
        label: 'ホルモンプログラム'
      },
      nextHormoneName: {
        label: '処置内容'
      },
      latestFertilizationDateDisplay: {
        label: '最新授精日'
      },
      daysAfterLatestFertilizationDate: {
        label: '授精後日数',
        isSubLabel: true
      },
      latestBreedingMethod: {
        label: '種付方法'
      },
      pregnancy: {
        label: '前回鑑定結果'
      },
      expectedCalvingDateDisplay: {
        label: '分娩予定日'
      },
      dryPeriod: {
        label: '前後期',
        isContent: true
      },
      breed: {
        label: '品種'
      },
      fatherName: {
        label: '父'
      },
      maternalGrandfatherName: {
        label: '母父'
      },
      maternalGreatGrandfatherName: {
        label: '母母父'
      }
    };
  }
}
