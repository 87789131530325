// eslint-disable-next-line no-unused-vars
class HomeHeadageBaseController {
  static bind(ctrl) {
    const base = new HomeHeadageBaseController();
    const functions = [
      'updateVisible',
      'goToDetails',
      'toDisplayValue'
    ];

    angular.extend(ctrl, base);

    functions.forEach((f) => {
      ctrl[f] = base[f].bind(ctrl);
    });
  }

  updateVisible() {
    const visible = !this.homeSettings.get('headage').visible;

    this.homeSettings.update('headage', {visible});

    if (typeof this.enableMasonry !== 'undefined') {
      this.enableMasonry();
    }
  }

  goToDetails(category, subCategory, item) {
    this.$state.go('headage-details', {
      category,
      subCategory,
      item
    });
  }

  toDisplayValue(value) {
    return value ? value.toLocaleString() : '-';
  }
}
