// 乳量グラフ
// eslint-disable-next-line no-unused-vars
class MilkChart {
  constructor(selector) {
    this.selector = selector;
    this.options = {
      bindto: this.selector,
      padding: {
        right: 15
      },
      size: {
        height: 400,
      },
      data: {
        type: 'bar',
        x: 'x',
        json: {}
      },
      axis: {
        y: {
          label: {
            text: '搾乳量(kg)',
            position: 'outer-right'
          },
          min: 0,
          padding: {
            bottom: 0
          }
        },
        x: {
          label: {
            text: '日付',
            position: 'outer-top'

          },
          type: 'timeseries',
          tick: {
            fit: true,
            format: '%m/%d'
          }
        }
      },
      subchart: {
        show: false
      },
      point: {
        show: false
      },
      tooltip: {
        show: true
      }
    };
  }

  show(data) {
    this.options.data.json = data;
    this.chart = c3.generate(this.options);
  }

  showCustomChart(data1, count) {
    this.options.data.json = data1;
    count = count || [];
    this.options.tooltip.contents = function(data, defaultTitleFormat, defaultValueFormat, color) {
      let text = '';
      let idx = data[0].index;
      let date = d3.time.format('%Y-%m-%d').parse(data1['x'][idx]);
      let dateString = date.getMonth() + 1 + '/' + date.getDate();
      let avg = (data[0].value === '0' || count[idx] === 0) ? '0' : parseFloat(data[0].value / count[idx]).toFixed(1);

      if (avg === '0') return;

      text = '<table class="c3-tooltip">';
      text += '<tbody>';
      text += '<tr><th id="tooltip_time" colspan="2">' + dateString + '</th></tr>';
      text += '<tr class="c3-tooltip-name-乳量">';
      text += '<td class="name" style="width: 80px;"><span id="tooltip_color" style="background-color:#1f77b4"></span>乳量</td>';
      text += '<td id="tooltip_count" class="value">' + data[0].value + '</td>';
      text += '</tr>';
      text += '<tr class="c3-tooltip-name-乳量">';
      text += '<td class="name">頭数</td>';
      text += '<td id="tooltip_count" class="value">' + count[idx] + '</td>';
      text += '</tr>';
      text += '<tr class="c3-tooltip-name-乳量">';
      text += '<td class="name">平均</td>';
      text += '<td id="tooltip_count" class="value">' + avg + '</td>';
      text += '</tr>';
      text += '</tbody>';
      text += '</table>';

      return text;
    };

    this.chart = c3.generate(this.options);
  }

  update(data) {
    this.chart.load({
      json: data
    });
  }

  clear() {
    $(this.selector).html('');
  }
}
