/* eslint-disable */

/**
 * @deprecated UtilService、DateUtilServiceなどを利用、または移行すること
 * ※この実装の修正および、新規の利用は禁止です
 */

function D(params) {
  return function(d, i) {
    if (typeof params === 'function') {
      return params(d);
    } else if (typeof params === 'string') {
      return (new Function('return (' + d + params + ')')());
    } else {
      return d;
    }
  };
}

function _find(list, iterator) {
  list = list || [];
  if (typeof iterator !== 'function') {
    return null;
  }
  let item = null;
  list.forEach(function(target) {
    if (!item) item = iterator(target);
  });
  return item;
}

function _findAll(list, iterator) {
  list = list || [];
  if (typeof iterator !== 'function') {
    return null;
  }
  let item = null;
  list.forEach(function(target) {
    if (!item) item = iterator(target);
  });
  return item;
}


// IE等未対応ブラウザに対するポリフィル
Number.isNaN = Number.isNaN || function(value) {
  return typeof value === 'number' && value !== value;
};

function I(params) {
  return function(d, i) {
    if (typeof params === 'function') {
      return params(i);
    } else if (typeof params === 'string') {
      return (new Function('return (' + i + params + ')')());
    } else {
      return i;
    }
  };
}

function F(name) {
  let params = Array.prototype.slice.call(arguments, 1);
  return function(d) {
    if (typeof params[0] === 'function') {
      return params[0](d[name]);
    } else if (typeof params[0] === 'string') {
      return (new Function('return (' + d[name] + params[0] + ')')());
    } else if (typeof name === 'object') {
      return name;
    } else {
      return d[name];
    }
  };
}

if (!Function.prototype.curry) {
  (() => {
    let slice = Array.prototype.slice;

    Function.prototype.curry = function() {
      let target = this;
      let args = slice.call(arguments);

      return function() {
        let allArgs = args;
        if (arguments.length > 0) {
          allArgs = args.concat(slice.call(arguments));
        }
        return target.apply(this, allArgs);
      };
    };
  })();
}

function R(s) {
  return Math.round(Math.random() * s);
}

function createRandam(s, additonal) {
  return Math.round(Math.random() * s) + additonal;
}

function createData(obj, length) {
  let data = [];
  for (let i = 0; i < length; i++) {
    if (typeof obj === 'function') {
      data.push(obj());
    } else {
      data.push(obj);
    }
  }
  return data;
}

function createRowData(name, obj, length) {
  let data = [];
  data.push(name);
  for (let i = 0; i < length; i++) {
    data.push((typeof obj === 'function') ? obj() : obj);
  }
  return data;

}
// firstDate から length の分だけ 進む、または遡った日付を返す
function createTimeLineColumn(firstDate, interval, length, retreat) {
  let format = d3.time.format('%Y-%m-%d');
  let data = [];
  for (let i = 0; i < length; i++) {
    let dummyDate = new Date(firstDate);
    let setMinutes = 0;
    if (retreat === 'prev') {
      setMinutes = dummyDate.getMinutes() - ((length - i) * interval);
    } else {
      setMinutes = dummyDate.getMinutes() + ((length - i) * interval);
    }
    dummyDate.setMinutes(setMinutes);
    data.push(format(dummyDate));
  }
  if (retreat === 'next') data.reverse();

  return data;
}

// start(unix) から end(unix) までのYYYY-MM-DD 形式の日付リストを返却
function createStartEndDateColumn(start, end) {
  let data = {}, startDate = moment(start), endDate = moment(end);
  data['x'] = '';
  while (startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
    data[startDate.format('YYYY-MM-DD')] = 0;
    startDate.add(1, 'd');
  }
  data[endDate.format('YYYY-MM-DD')] = 0;
  return data;
}


function getChartColumns(rowData) {
  let colums = d3.keys(rowData);
  return colums.filter((c) => c !== 'date');
}

function toBleIds(barn) {
  let bleIds = [];
  ['bleId1', 'bleId2', 'bleId3', 'bleId4', 'bleId5'].forEach(function(e) {
    if (barn[e]) {
      bleIds.push(barn[e]);
    }
  });
  return bleIds;
}

function settingLatestSenserInfo(resolve, barn) {
  resolve = resolve || {};
  let dataList = resolve.status || [];
  let summary = {};
  // console.log(resolve);

  let bleIds = toBleIds(barn);
  let initialData = createInitialDataForSensar(bleIds);

  initialData.forEach(function(obj) {
    dataList.forEach(function(data) {
      if (obj.id === data.bleId) {
	      obj.latestTemperature = parseFloat(data.temperature).toFixed(1);
	      obj.latestHumidity = parseFloat(data.humidity).toFixed(1);
	      obj.latestStress = (calculateStress(obj.latestTemperature, obj.latestHumidity)).toFixed(1);
	    }
    });
  });
  return initialData;
}
// YYYY/MM/DD 00:00 形式のラベル表示用日付取得
function getDate(targetDate) {
  let date = targetDate? new Date(targetDate): new Date();
  let dateList = [];
  dateList.push(date.getFullYear() + '/');
  dateList.push((String(date.getMonth()+1).length === 1)? '0' + String(date.getMonth()+1) + '/': String(date.getMonth()+1) + '/');
  dateList.push((String(date.getDate()).length === 1)? '0' + String(date.getDate()): String(date.getDate()));
  dateList.push(' 00:00');
  return dateList.join('');
}

// YYYY/MM/DD hh:dd 形式のラベル表示用日付取得
function getDateTimeForLabel(targetDate) {
  let date = targetDate? new Date(targetDate): new Date();
  let dateList = [];
  dateList.push(date.getFullYear() + '/');
  dateList.push((String(date.getMonth()+1).length === 1)? '0' + String(date.getMonth()+1) + '/': String(date.getMonth()+1) + '/');
  dateList.push((String(date.getDate()).length === 1)? '0' + String(date.getDate()): String(date.getDate()));

  let hour = (String(date.getHours()).length === 1)? '0' + String(date.getHours()): String(date.getHours());
  let minute = (String(date.getMinutes()).length === 1)? '0' + String(date.getMinutes()): String(date.getMinutes());
  dateList.push(' ' + hour + ':' + minute);
  return dateList.join('');
}

// YYYY/MM/DD 形式のラベル表示用日付取得
function getDateForLabel(targetDate) {
  let date = new Date();
  if (targetDate) {
    date = typeof targetDate === 'string'? new Date(Number(targetDate)): new Date(targetDate);
  }
  let dateList = [];
  dateList.push(date.getFullYear() + '/');
  dateList.push((String(date.getMonth()+1).length === 1)? '0' + String(date.getMonth()+1) + '/': String(date.getMonth()+1) + '/');
  dateList.push((String(date.getDate()).length === 1)? '0' + String(date.getDate()): String(date.getDate()));
  return dateList.join('');
}

// YYYYMMDD 形式のAPIリクエストパラメータ用日付取得
function getDateForApiParam(targetDate) {
  let date = null;
  if (typeof targetDate === 'number' || typeof targetDate === 'string') {
    date = new Date(Number(targetDate));
  } else {
    date = targetDate? targetDate: new Date();
  }

  let dateList = [];
  dateList.push(date.getFullYear());
  dateList.push((String(date.getMonth()+1).length === 1)? '0' + String(date.getMonth()+1): String(date.getMonth()+1));
  dateList.push((String(date.getDate()).length === 1)? '0' + String(date.getDate()): String(date.getDate()));
  return dateList.join('');
}

// YYYYMMDDhh 形式のAPIリクエストパラメータ用日付取得
function getDateHourForApiParam(targetDate) {
  let date = null;
  if (typeof targetDate === 'number' || typeof targetDate === 'string') {
    if (_.isNaN(Number(targetDate))) {
      date = new Date(targetDate);
    } else {
      date = new Date(Number(targetDate));
    }
  } else {
    date = targetDate? targetDate: new Date();
  }

  let dateList = [];
  dateList.push(date.getFullYear());
  dateList.push((String(date.getMonth()+1).length === 1)? '0' + String(date.getMonth()+1): String(date.getMonth()+1));
  dateList.push((String(date.getDate()).length === 1)? '0' + String(date.getDate()): String(date.getDate()));
  dateList.push((String(date.getHours()).length === 1)? '0' + String(date.getHours()): String(date.getHours()));
  return dateList.join('');
}

function getGMTDateTime(now) {
  var now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  return now;
}

function getDateLabelForApiResNumber(dateInt) {
  let reg = /(\d{4})(\d{2})(\d{2})/g;
  return String(dateInt).replace(reg, '$1-$2-$3');
}

function getDateLabelForApiResDateTime(dateTimeZoneString) {
  return moment(dateTimeZoneString).format('YYYY-MM-DD');
}

function sum(arr, fn) {
  return (fn)? sum(arr.map(fn)): arr.reduce(function(prev, current, i, arr) {
    return prev+current;
  });
}

// THI=（0.8×T）+H×（T-14.4）/100 + 46.4
// T：温度（乾球温度）℃　　H：湿度　%
// THI
// 72未満　ストレスレベル0　安全領域
// 72以上77未満　ストレスレベル1　暑熱ストレスが始まる
// 77以上84未満　ストレスレベル2　乳量の急激な低下が発生
// 84以上　ストレスレベル3　危険領域　疾病が多発する
function calculateStress(temp, hum) {
  return (0.8 * temp) + hum * (temp - 14.4) / 100 + 46.4;
  /**
  if(strees < 72) return "0";
  if(strees >= 72 && strees < 77) return "1";
  if(strees >= 77 && strees < 84) return "2";
  if(strees >= 84) return "3";
  **/
}

// urlのgetパラメータからjsオブジェクト型にパースして返却
function getPathParams() {
  let pathList = location.href.split('?');
  let pathParam = pathList[pathList.length -1] || '';
  let paramObj = {};
  pathParam.split('&').forEach(function(item) {
    let param = item.split('=');
    paramObj[param[0]] = param[1];
  });
  return paramObj;
}

/**
 * 各ワークリスト初期表示時の印刷用レイアウト調整メソッド
 * getパラメータ mode がセットされている場合にのみ
 * 印刷用レイアウト調整を行う
 */
function printScreenLayout(params, callback) {
  $(document).ready(function() {
    if (params.mode === '1') {
      $('header').remove();
      $('#header-band').remove();
      $('#print-link').remove();
      $('.box-bulk-regist').remove();
      $('.table-fix').parent().removeClass('fixed-tb');
      $('body').addClass('print');
      $('#external-input').addClass('option-wrapper');
      $('.wrapper .content').css('margin', '0px');
      $('.content .title-box').css('width', '100%');

      if (callback && typeof callback === 'function') callback(params);
    }
  });
}

/**
 * ワークリスト画面共通の印刷機能メソッド
 * ブラウザ標準の印刷機能を呼び出します
 * 画面描画完了前に印刷実行されるのを防ぐため
 * 画面読み込みのsvg($scope.loading)が非表示に
 * なったタイミングで印刷呼び出しを行います
 * params 印刷可否パラメータ
 * interval 画面読み込みsvg要素の表示・非表示チェックメソッド
 * callback 印刷実行後のコールバック関数
 */
function executePrinter(params, interval, callback) {
  if (params.mode === '1') {
    clearInterval(interval);
    window.print();
    if (callback && typeof callback === 'function') callback(params);
  }
}


/**
 * ワークリスト画面共通の別ウィンドウ起動メソッド
 * path 起動する画面
 * param 別ウィンドウに渡すパラメータ
 */
function windowOpenWorkList(path, param) {
  param = param || {};
  let workListObj = {
    estrus: {path: '#/workList/estrus'},
    pregnant: {path: '#/workList/pregnant'},
    stopShipment: {path: '#/workList/stopShipment'},
    lowerMilk: {path: '#/workList/lowerMilk'},
    hormoneProgram: {path: '#/workList/hormoneProgram'},
    moveCowGroup: {path: '#/workList/moveCowGroup'}
  };
  let list = [], context = [], href = '';
  list.push('mode=1');
  Object.keys(param).forEach(function(key) {
    list.push(key + '=' + param[key]);
  });
  context = location.href.split('#');
  // ここでカスタムリスト用の処理を行う
  if (path) {
    href = context[0] + workListObj[path].path + '?' + list.join('&');
  } else if (param.path) {
    href = context[0] + param.path + '?' + list.join('&');
  } else {
    href = '#/top';
  }
  let windowStyle = 'width=1144,height=600,menubar=no,toolbar=no,location=no,scrollbars=yes';
  window.open(href, 'workListWindow', windowStyle);
}

// UnixEpochのstartDateからendDateまでの経過日数を取得
function passedByStartAndEnd(startEpoch, endEpoch) {
  if (!startEpoch || !endEpoch) return null;
  let start = new Date(Number(startEpoch));
  let end = new Date(Number(endEpoch));
  return Math.floor((end - start)/1000/60/60/24);
}

// 経過日数が指定範囲内であるか判定
function judgeStartEnd(passed, start, end) {
  return start <= passed && passed <= end;
}

// target から days（日数）だけ経過したUnixEpoch秒を返却する
function plusDays(target, days) {
  if (!target) return null;
  return moment(Number(target))
      .add(Number(days), 'd')
      .toDate().getTime();
}

// target から days（日数）だけ遡ったUnixEpoch秒を返却する
function minusDays(target, days) {
  if (!target) return null;
  return moment(Number(target))
      .add(Number(days) * (-1), 'd')
      .toDate().getTime();
}

// 表示ラベル用の曜日を取得（牧場設定プルダウン用※指定なし含む）
function getLabelCustomWeekOfDay(num) {
  let weekOfDayLabel = ['日', '月', '火', '水', '木', '金', '土'];
  return (num && Number(num) > 0)? weekOfDayLabel[Number(num -1)] + '曜日': '指定なし';
}

// 表示ラベル用の曜日を取得（js標準 Dateオブジェクト※指定なし含まない）
function getLabelWeekOfDay(num) {
  let weekOfDayLabel = ['日', '月', '火', '水', '木', '金', '土'];
  return (Number(num) >= 0)? weekOfDayLabel[Number(num)]: null;
}

// XX分からX時間X分に変換
function parseHourMinute(num, isZeroPadding) {
  let hour = '', minute = '';
  if (typeof num !== 'number' || num === 0) return {hour: 0, minute: 0};
  if (isZeroPadding) {
    hour = parseInt(num / 60) < 9? '0' + String(parseInt(num / 60)): String(parseInt(num / 60));
    minute = parseInt(num % 60) < 9? '0' + String(parseInt(num % 60)): String(parseInt(num % 60));
  } else {
    hour = String(parseInt(num / 60));
    minute = String(parseInt(num % 60));
  }
  return {hour: hour, minute: minute};
}


/**
 * ホルモンプログラム次回処置内容取得メソッド
 * 次回処置内容だけでなく、引数で受け取った情報をもとに
 * ホルモンプログラムマスタ情報をまとめて返却します
 *
 * <argument>
 * 　hormoneProgramList : ホルモンプログラムマスタ情報
 * 　targetProgram : 対象牛個体に設定されているホルモンプログラム名
 * 　lastHormone : 直近に投稿された「ホルモンプログラム」イベントのオブジェクト
 *
 * ＜return＞
 * 　currentHormoneProgram : 適応中のホルモンプログラムマスタ情報
 * 　hormoneName : 次回処置内容
 * 　hormonePeriod : 次回処置間隔（H）
 * 　hormoneProgramCode : ホルモンプログラムコード
 * 　templateFlag : テンプレートフラグ
 * 　nextIndex : 次回処置内容のインデックス番号
 * 　index : 現在処置済みのインデックス番号
 */
function getNextHormoneProgram(hormoneProgramList, targetProgram, lastHormone) {
  hormoneProgramList = hormoneProgramList || [];
  lastHormone = lastHormone || {};
  if (!targetProgram) return null;
  let hormoneNumberList = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];

  let hormoneProgram = _find(hormoneProgramList, (program) => {
    return program.name === targetProgram? program: false;
  });

  let startNum = _find(hormoneNumberList, (num) => {
    return (hormoneProgram['hormoneName' + num] && hormoneProgram['hormonePeriod' + num])? num: false;
  });

  let resultNum = _find(hormoneNumberList, (num) => {
    if (!lastHormone.hormoneName) return startNum;
    return hormoneProgram['hormoneName' + num] === lastHormone.hormoneName? --num: false;
  });
  let index = resultNum === startNum? startNum: resultNum + 1;

  // 最終プログラムである場合には最初のプログラムを返却対象とする
  if (!hormoneProgram['hormoneName' + resultNum] && !hormoneProgram['hormonePeriod' + resultNum]) {
    resultNum = startNum;
  }

  return {
    currentHormoneProgram: hormoneProgram,
    hormoneName: hormoneProgram['hormoneName' + resultNum],
    hormonePeriod: hormoneProgram['hormonePeriod' + resultNum],
    hormoneProgramCode: hormoneProgram.hormoneProgramCode,
    templateFlag: hormoneProgram.templateFlag,
    nextIndex: resultNum,
    index: index,
    startIndex: startNum
  };
}

function getCustomArrayList(listName, info) {
  info = info || {};
  if (!info[listName]) return [];
  return _.lines(info[listName]);
}

function getCustomSelectList(listName, info, allowBlank) {
  let blank = {label: '', value: ''};
  let result = allowBlank? [blank]: [];
  info = info || {};
  if (!info[listName]) return [];
  _.each(getCustomArrayList(listName, info), (val) => {
    let _val = _.trim(val);
    result.push({label: _val, value: _val});
  });
  return result;
}

/**
 * 牛個体識別番号を耳標で使用される「00000-0000-0」の形式に変換します。
 *
 * @deprecated Use CowService.formatCowUid instead
 * @param {string} cowUid 牛個体識別番号
 * @return {string} *****-****-* or 元の値
 */
function formatCowUid(cowUid) {
  if (!cowUid) return '';

  cowUid = String(cowUid);

  if (cowUid.length !== 10) return cowUid;

  const first = cowUid.slice(0, 5);
  const second = cowUid.slice(5, 9);
  const last = cowUid.slice(9, 10);

  return first + '-' + second + '-' + last;
}

function today() {
  let date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function addDays(date, days) {
  if (!date) {
    return null;
  }
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
}

function addMonths(date, months) {
  if (!date) {
    return null;
  }
  return new Date(date.getFullYear(), date.getMonth() + months, date.getDate());
}

function firstDayOfMonth(date) {
  return moment(date).startOf('month').toDate();
}

function lastDayOfMonth(date) {
  return moment(date).endOf('month').toDate();
}

function dateConversion(date, pattern) {
  return moment(date, pattern).toDate();
}

/**
 * 引数のチャート名のチャートを非表示にして、
 * 画面上にメッセージ(データが存在しません。)を表示する。
 *
 * @param {string} chartName チャート名
 */
function appendEmptyMessage(chartName) {
  $('#' + chartName).css('display', 'none');
  $('#' + chartName + 'ChartMessage').css('display', 'block');
}

/**
 * 引数のチャート名のチャートを表示して、
 * 画面上に表示しているメッセージ(データが存在しません。)を非表示にする。
 *
 * @param {string} chartName チャート名
 */
function clearEmptyMessage(chartName) {
  $('#' + chartName).css('display', 'block');
  $('#' + chartName + 'ChartMessage').css('display', 'none');
}

/**
 * 1週間のチャート表示用データに抜けがある場合は、
 * 初期データでデータを補完する。
 *
 * @param {Array} data チャート表示用のデータ
 * @param {string} startDate チャート表示の開始日時
 * @param {string} endDate チャート表示の終了日時
 * @return {Array} チャート表示用のデータ
 */
function completionDataForWeek(data, startDate, endDate) {
  if (data.length <= 0 || data.length >= 7) {
    return data;
  }
  let start = new Date(startDate);
  let end = new Date(endDate);
  let newData = [];
  for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
    let temp = angular.copy(data[0]);
    createInitialDataForChart(temp, d);
    newData.push(temp);
  }
  for (let i = 0; i < newData.length; i++) {
    for (let j = 0; j < data.length; j++) {
      if (newData[i].date === data[j].date) {
        newData[i] = data.splice(j, 1)[0];
        break;
      }
    }
  }
  return newData;
}

/**
 * チャート用の初期データを作成する。
 *
 * [example]
 * data = { date: '2017-04-01', 牛舎1: 44, 牛舎2: 45 }
 * date = new Date('2017-04-11);
 * createInitialDataForChart(data, date);
 * console.log(data) => { date: '2017-04-11', 牛舎: 0, 牛舎2: 0 }
 *
 * @param {Object} data データのひな型
 * @param {string} date 初期データの日付
 */
function createInitialDataForChart(data, date) {
  angular.forEach(data, function(value, key) {
    if (key === 'date') {
      data[key] = moment(date).format('YYYY-MM-DD');
    } else {
      data[key] = 0;
    }
  });
}

/**
 * 各センサーの初期データを作成する。
 *
 * [example]
 * bleIds = ['example1', 'example2'];
 * data = createInitialDataForSensar(bleIds);
 * console.log(data) =>
 *  [
 *    { id: 'example1', name: 'センサー1', latestTemperature: 0, latestHumidity: 0, latestStress: 0 },
 *    { id: 'example2', name: 'センサー2', latestTemperature: 0, latestHumidity: 0, latestStress: 0 }
 *  ]
 *
 * @todo センサー名は後で変更の可能性あり
 * @param {Array} data センサーIDの配列
 * @return {Array} 各センサーの初期データ
 */
function createInitialDataForSensar(bleIds) {
  let sensarData = [];
  for (let i = 0, len = bleIds.length; i < len; i++) {
    sensarData.push({
      id: bleIds[i],
      name: 'センサー' + (i + 1),
      latestTemperature: 0,
      latestHumidity: 0,
      latestStress: 0
    });
  }
  return sensarData;
}

/**
 * 文字列を改行(\r\n or \r or \n)で分割し配列で返す。
 *
 * @deprecated Use UtilService.lines instead.
 * @param {string} str 対象文字列
 * @return {Array} 配列
 */
function lines(str) {
  return str !== null ? String(str).split(/\r\n?|\n/) : [];
}
