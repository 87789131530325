class EmbryoRecoveryReportSelectDialogController {
  constructor(
    $modalInstance,
    resolved
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;

    this.title = resolved.title;
    this.caption = resolved.caption;
    this.options = resolved.options;
    this.values = resolved.values;
  }

  submit() {
    const values = Object.entries(this.values).reduce((arr, [key, selected]) => {
      if (selected) arr.push(key);
      return arr;
    }, []);
    this.$modalInstance.close(values);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  toggle(key, $event) {
    if ($event.target.nodeName === 'INPUT') return;
    this.values[key] = !this.values[key];
  }
}

app.controller('EmbryoRecoveryReportSelectDialogController', EmbryoRecoveryReportSelectDialogController);
