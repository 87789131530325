// eslint-disable-next-line no-unused-vars
class TopScheduleBinder {
  static bind(ctrl, base) {
    angular.extend(ctrl, base);

    const functions = [
      '$onInit',
      'updateDateDisplay',
      'isToday',
      'onPreviousDay',
      'onNextDay'
    ];

    functions.forEach((f) => {
      ctrl[f] = base[f].bind(ctrl);
    });
  }
}
