app.component('umMobileGlobalMenu', {
  bindings: {},
  transclude: true,
  controller: 'umMobileGlobalMenuController as umMobileGlobalMenuCtrl',
  templateUrl: 'components/mobile-global-menu/mobile-global-menu-component.html'
});

class umMobileGlobalMenuController {
  constructor(
    $state,
    $window,
    SessionStateService,
    FarmService,
    SessionCache,
    $modal,
    MobileCowSearchUIService,
    SwitchFarmDialog,
    Dictionary,
    ViewStateAPI
  ) {
    'ngInject';

    this.isShowingMenu = false;

    this.$state = $state;
    this.$window = $window;
    this.SessionStateService = SessionStateService;
    this.FarmService = FarmService;
    this.$modal = $modal;
    this.MobileCowSearchUIService = MobileCowSearchUIService;
    this.SwitchFarmDialog = SwitchFarmDialog;
    this.Dictionary = Dictionary;
    this.ViewStateAPI = ViewStateAPI;

    this.formData = {
      cowNo: null
    };

    this.cowNoError = false;
    this.shouldShowAlert = false;

    const account = SessionCache.account();
    const farm = SessionCache.farm();
    this.useUClips = farm.data.useUClips;
    this.canSwitchFarm = account.canSwitchFarm();
    this.email = account.email;
    this.isAdministrator = account.isAdministrator();
    this.isOrganizer = account.isOrganizer();
    this.isDepositor = account.isDepositor();
    this.isGroupMember = farm.isGroupMember();
    this.showGroupManagementMenu = this.isOrganizer || this.isGroupMember;
    this.showExternalList = ['owner', 'admin', 'organizer'].includes(account.values.role);

    this.init();
  }

  openMenu() {
    this.formData.cowNo = null;
    this.isShowingMenu = true;
  }

  closeMenu() {
    this.isShowingMenu = false;
    this.closeKeyboard();
  }

  closeAlert() {
    this.shouldShowAlert = false;
  }

  toggleMenuExpansion(id) {
    this.isMenuExpanded[id] = !this.isMenuExpanded[id];
  }

  goHome() {
    this.closeMenu();
    this.$state.go('mobile-top');
  }

  goCustomlist() {
    this.closeMenu();
    this.$state.go('mobileCustomlistList');
  }

  goCowList() {
    this.closeMenu();
    this.$state.go('mobileCowGroupList');
  }

  goDailyReport() {
    this.closeMenu();
    this.$state.go('mobileOperationHistory');
  }

  goGenomicEvaluationList() {
    this.closeMenu();
    this.$state.go('mobile-genomic-evaluation-list');
  }

  goNotificationList() {
    this.closeMenu();
    this.$state.go('mobileNotificationList');
  }

  goCowRegister() {
    this.closeMenu();
    this.$state.go('mobileCowRegister');
  }

  goGroupTransferHistoryCurrentFarm() {
    this.closeMenu();
    this.$state.go('mobile-group-transfer-history-current-farm');
  }

  goExternalList() {
    this.closeMenu();
    this.$state.go('mobileExternalList');
  }

  goUClipsList() {
    this.closeMenu();
    this.$state.go('mobileUClipsList');
  }

  searchAndGo(isRegisterEvent) {
    if (!this.formData.cowNo) return;

    this.MobileCowSearchUIService.searchByCowNo(this.formData.cowNo)
      .then((result) => {
        if (result.status.EMPTY) {
          this.MobileCowSearchUIService.openNoSuchCowModal();
        } else if (result.status.FOUND) {
          this.closeMenu();
          this.MobileCowSearchUIService.goToCowDetail(result.cowId, isRegisterEvent);
        }
      });
  }

  switchFarm() {
    this.closeMenu();
    this.SwitchFarmDialog.excuteInMobile();
  }

  logout() {
    this.ViewStateAPI.create('mobile-logout', 'mobile-global-menu').finally(() => {
      this.SessionStateService.logout();
    });
  }

  // private

  init() {
    this.getFarmKind();
    this.isMenuExpanded = {
      account: false,
      deposit: false,
      umarche: false,
    };
  }

  getFarmKind() {
    this.FarmService.show().then((res) => {
      const farm = new Farm(res);
      this.cowNoType = (res.cowNoPattern && !farm.useName()) === 'number' ? 'number' : 'text';
      this.farmKind = res.farmKind;
      this.farmName = farm.data.farmName;
      const isDepositorFarm = farm.isDepositor();
      this.shouldShowCowEntry = !isDepositorFarm && !farm.isAgent();
      this.showGenomicEvaluationList = farm.showBeefGenom() && !isDepositorFarm;

    }).catch((err) => console.error(err));
  }

  closeKeyboard() {
    document.activeElement.blur();
  }
}

app.controller('umMobileGlobalMenuController', umMobileGlobalMenuController);
