// eslint-disable-next-line no-unused-vars
class DepositAlertHistory {
  /**
   * @param {Array<Object>} alertConfigs アラート設定配列
   */
  constructor(alertConfigs) {
    this.alertConfig = alertConfigs.reduce((acc, cur) => {
      acc[cur.farmId] = cur;
      return acc;
    }, {});
  }

  /**
   * 疾病アラート改良版を表示するかを返す
   * 引数が無い場合は全預託先牧場の内、1つでも疾病アラート改良版を表示する場合はtrueを返す
   *
   * @param {number} farmId 牧場ID
   * @retrun {boolean} true: 疾病アラート改良版を表示, false: 疾病アラート改良版を非表示
   */
  isShowAcuteIllness(farmId = null) {
    if (farmId) {
      return this.alertConfig[farmId].showAcuteIllness;
    } else {
      return Object.values(this.alertConfig).some((c) => c.showAcuteIllness);
    }
  }

  /**
   * アラート牛の項目値を預託元牧場用のアラート履歴用に変換する
   * 対象牛が所属する預託先牧場が疾病アラート改良版を表示する場合は変換しない
   * 疾病アラート改良版を表示しない場合は以下の変換を行う
   *  - アラートラベルから急性(改)を除去
   *  - 疾病アラート改良版のフラグをfalseへ変更
   *  - 急性が未発生で慢性が発生の場合はアラートラベルへ慢性を追加
   *
   * @param {Array<Object>} cows アラート牛配列
   * @return {Array<Object>} 変換したアラート牛配列
   */
  convert(cows) {
    return cows.map((cow) => {
      if (this.isShowAcuteIllness(cow.qwert)) return cow;

      cow.alertLabels = cow.alertLabels.filter((label) => label !== '急性(改)');
      cow['acute_illness'] = false;
      if (!cow['illness'] && cow['chronic_illness']) cow.alertLabels.push('慢性');

      return cow;
    });
  }

  /**
   * アラート牛を預託元牧場用のアラート履歴用にフィルタリングする
   * 以下の条件に一致するアラート牛を返す
   *  - 対象牛に疾病(改)アラートが発生、かつ所属する預託先牧場が疾病アラート改良版を表示する
   *  - 疾病アラート改良版以外のアラートが1つでも発生している
   *
   * @param {Array<Object>} cows アラート牛配列
   * @return {Array<Object>} フィルタリングしたアラート牛配列
   */
  filter(cows) {
    return cows.filter((cow) => {
      if (this.isShowAcuteIllness(cow.qwert) && cow['acute_illness']) return true;

      return ['illness', 'chronic_illness', 'milk_low', 'feed_low', 'lie_high', 'water_low'].some((type) => cow[type]);
    });
  }
}
