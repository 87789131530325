class MobileCustomlistListController {
  constructor(CustomlistAPI) {
    'ngInject';

    this.CustomlistAPI = CustomlistAPI;

    this.init();
  }

  init() {
    this.CustomlistAPI.index().then((res) => {
      this.customlists = res.data;
    });
  }
}

app.controller('MobileCustomlistListController', MobileCustomlistListController);
