class DepositCowGroupService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;

    this.allAttributes = [
      {key: 'calfDefaultGroup', label: '育成牛群', farmType: 'ALL', default: true},
      {key: 'cullingDefaultGroup', label: '出荷へい死牛群', farmType: 'ALL', default: true},
      {key: 'calfGroup', label: '育成牛群', farmType: 'ALL'},
      {key: 'reproductionGroup', label: '繁殖牛群', farmType: 'ALL'},
      {key: 'cullingGroup', label: '出荷へい死牛群', farmType: 'ALL'},
      {key: 'milkingGroup', label: '搾乳牛群', farmType: 'MILK'},
      {key: 'dryGroup', label: '乾乳牛群', farmType: 'MILK'},
      {key: 'fatteningGroup', label: '肥育牛群', farmType: 'BEEF'},
      {key: 'depositEntryGroup', label: '預託牛群', farmType: 'ALL', deposit: true},
    ];
  }

  getAttributes(farmType, includeDeposit = false, includeDefault = false) {
    return this.allAttributes
      .filter((a) => a.farmType === 'ALL' || a.farmType === farmType)
      .filter((a) => !a.deposit || includeDeposit)
      .filter((a) => !a.default || includeDefault);
  }

  getAttribute(cowGroup, isMilk = true) {
    const attribute = this.allAttributes.find((a) => cowGroup[a.key]);
    if (attribute) {
      return attribute;
    }
    if (isMilk) {
      return {key: 'reproductionGroup', label: '繁殖牛群', farmType: 'ALL'};
    } else {
      return {key: 'fatteningGroup', label: '肥育牛群', farmType: 'BEEF'};
    }
  }

  getAttributeKey(cowGroup, isMilk = true) {
    return this.getAttribute(cowGroup, isMilk).key;
  }

  index(qwert) {
    return this.APIService.get(`/deposit/cow_group?qwert=${qwert}`);
  }
}

app.service('DepositCowGroupService', DepositCowGroupService);
