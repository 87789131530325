// eslint-disable-next-line no-unused-vars
class CustomlistConfigType {
  static get USER_DEFINED() {
    return 'user_defined';
  }

  static get DEPOSIT() {
    return 'deposit';
  }

  constructor(configType) {
    this.value = configType;
  }

  needDateCondition() {
    return ['user_defined'].includes(this.value);
  }

  isSystemConfig() {
    return this.value !== CustomlistConfigType.USER_DEFINED;
  }

  isDepositConfig() {
    return this.value === CustomlistConfigType.DEPOSIT;
  }
}
