class MobileAlertEartagSensorDroppedShipmentsFormController {
  constructor() {
    'ngInject';

    this.shipmentsForm = {};
  }

  handleCloseClick() {
    this.shipmentsForm = {};
    this.onCloseClick();
  }

  handleSubmitClick() {
    this.onShipmentsClick({shipmentsForm: this.shipmentsForm});
  }
}

function mobileAlertEartagSensorDroppedShipmentsFormComponents() {
  return {
    templateUrl: 'mobile/alert-list/eartag-sensor-dropped/shipments-form.html',
    controller: MobileAlertEartagSensorDroppedShipmentsFormController,
    bindings: {
      expelledReasons: '<',
      buyers: '<',
      onCloseClick: '&',
      onShipmentsClick: '&'
    }
  };
}

app.component('mobileAlertEartagSensorDroppedShipmentsForm',
  mobileAlertEartagSensorDroppedShipmentsFormComponents());
