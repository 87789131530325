class CowBoardController {
  constructor(
    $q,
    $rootScope,
    CowBoardContext,
    CowState,
    DateUtilService,
    DepositCowBoardContext,
    FarmKind,
    BleIdService,
    $timeout,
    Dictionary,
    SessionCache,
    DhiAPI,
    GenomCowAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.$rootScope = $rootScope;
    this.CowBoardContext = CowBoardContext;
    this.CowState = CowState;
    this.DateUtilService = DateUtilService;
    this.DepositCowBoardContext = DepositCowBoardContext;
    this.FarmKind = FarmKind;
    this.BleIdService = BleIdService;
    this.$timeout = $timeout;
    this.Dictionary = Dictionary;
    this.DhiAPI = DhiAPI;
    this.GenomCowAPI = GenomCowAPI;
    this.deregisters = [];
    this.currentRequest = null;

    this.windowState = new WindowState();

    this.isLoading = false;

    this.showBeefGenom = SessionCache.farm().showBeefGenom();

    /**
    * 表示切り替えフラグ {bool}
    */
    this.isMilkFarm = false;
    this.isMeatFarm = false;
    this.isFatteningType = false;
    this.isCalfType = false;
    this.isReproductionType = false;
    this.isSoldOrDiedType = false;
    this.activeTab = null;
    this.canEditFilterItem = !SessionCache.account().isItemFilterApplied();

    this.shouldShowHeatChart = false;
    this.shouldShowActivityChart = false;
    this.shouldShowMilkingChart = false;

    this.isDepositor = SessionCache.farm().isDepositor();
    this.useName = SessionCache.farm().useName();
    this.useCalfManagement = SessionCache.farm().useCalfManagement();

    this.shouldShowDhi = (!this.isDepositor && SessionCache.farm().isDhiLinkage());

    this.callerId = 'cow_board'; // アクセスログ用

    this.chartSize = {
      width: 350,
      height: 350,
      chartWidth: 230,
      labelWidth: 280,
    };
  }

  $onInit() {
    this.context = this.isDepositor ? this.DepositCowBoardContext : this.CowBoardContext;

    // Set Services
    this.CowService = this.context.cowService();
    this.FarmService = this.context.farmService();

    // 初期起動に必要なデータを取得
    this.context.load().then((res) => {
      this.markets = res.data;
    });

    // listenerの登録
    // called from CowTimelineController after creating / updating / deleting an event
    this.deregisters.push(this.$rootScope.$on('cowDetail:refreshCowInfo', (ev, data, followingEventExists) => {
      if (data && Number(data.cowId) !== Number(this.currentCowId)) {
        return;
      }
      this.init(this.currentCowId, followingEventExists);
    }));

    this.selectedActivityPeriod = this.defaultPeriod;
    this.hiddenLegendIds = [];

    this.deregisters.push(this.$rootScope.$on('cowBoard:closeCowBoard', () => {
      this.closeCowBoard();
    }));
  }

  $onChanges(changes) {
    this.$timeout(() => {
      /**
       * 新しく対象となった牛の個体情報を取ってくる。
       * $onInit()よりも先に$onChanges()が走る場合、コンテキストに応じたサービスのセットが未了まま
       * init()が実行されてしまうため、$timeout内に記述
       */
      if (changes.currentCowId && changes.currentCowId.currentValue) this.init(changes.currentCowId.currentValue);

      // カウボードのトグル直後に、カウボードの有無に応じてui-gridのレイアウトを更新する
      this.windowState.resize();
    });
  }

  $onDestroy() {
    this.deregisters.forEach((d) => d());
  }

  /**
   * 初期化
   * タイムライン編集中の場合はデータは裏側で取得するが、画面は初期化しない
   *
   * @param {boolean} editingTimeline true: タイムライン編集中
   */
  init(cowId, editingTimeline) {
    if (this.currentRequest) {
      this.currentRequest.resolve();
    }
    this.isLoading = editingTimeline ? false : true;
    this.currentCowId = cowId;
    this.currentRequest = this.$q.defer();

    const requests = [
      this.CowService.get(cowId),
      this.FarmService.show(this.currentQwert),
      this.BleIdService.cowBleIds(cowId),
      this.GenomCowAPI.show(cowId)
    ];

    if (this.shouldShowDhi) requests.push(this.DhiAPI.cow(cowId));

    this.$q.all(requests)
      .then((res) => {
        if (this.currentCowId !== cowId) {
          return;
        }

        this.c = res[0].data;
        this.farm = res[1];

        this.c.cowUidDisplay = Cow.formatCowUid(this.c.cowUid);

        // 日付を表示用に変換
        Object.assign(this.c, Cow.generateDisplayDates(this.c, {separator_style: 'jp'}));

        if (!this.isDepositor) {
          let market;
          if (this.c.masterMarketId !== null) {
            market = this.markets.find((m) => m.id === this.c.masterMarketId);
          }
          this.c.masterMarketName = market ? market.name : null;
        }

        this.updateDisplayOptionFlags(this.farm.farmKind, this.c.state);

        this.cowBleIds = res[2].data;
        this.shouldShowActivityChart = this.cowBleIds.length > 0;
        this.shouldShowHeatChart = this.setShowHeatChart();
        this.shouldShowMilkingChart = this.c && this.farm.farmKind === '乳用' && !this.depositorFarm;
        this.shouldShowGenom = this.showBeefGenom && !this.isDepositor && this.c && this.c.gender === 'メス';

        this.evaluations = res[3].data;
        const formated = GeBeefCattle.formatEvaluations(this.evaluations);
        const genom = {'genom': formated};
        Object.assign(this, genom);

        this.dhis = (res[4] || {data: []}).data;
        if (this.dhis.length) {
          const birthNumbers = this.dhis.map((item) => item.birthNumber);
          this.birthNumbers = [...new Set(birthNumbers)].sort((a, b) => b - a);

          this.selectedBirthNumber = this.birthNumbers[0];

          this.birthNumberDhis = this.genereteBirthNumberDhis(this.dhis, this.selectedBirthNumber);
        }

        if (editingTimeline) return;

        const tab = this.activeTab || this.defaultTab;
        this.activeTab = this.availableTab(tab, this.shouldShowHeatChart,
          this.shouldShowActivityChart, this.shouldShowMilkingChart, this.shouldShowGenom);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        if (this.currentCowId === cowId) {
          this.isLoading = false;
        }
      });
  }

  setShowHeatChart() {
    const endDate = Number(this.c.eliminateDate) || Number(this.c.fallingDeadDate) || DateUtil.today();
    const isCalfPeriod = Cow.isCalfPeriod(this.c.birthday, endDate);

    if (this.useCalfManagement && isCalfPeriod) {
      return false;
    }
    return this.cowBleIds.length > 0 && this.c && this.c.gender === 'メス';
  }

  availableTab(tab, shouldShowHeatChart, shouldShowActivityChart, shouldShowMilkingChart, shouldShowGenom) {
    switch (tab) {
    case 'heat':
      return shouldShowHeatChart ? 'heat' : 'timeline';
    case 'activity':
      return shouldShowActivityChart ? 'activity' : 'timeline';
    case 'milking':
      return shouldShowMilkingChart ? 'milking' : 'timeline';
    case 'info':
      return 'info';
    case 'dhi':
      return 'dhi';
    case 'genom':
      return shouldShowGenom ? 'genom' : 'timeline';
    case 'timeline':
    default:
      return 'timeline';
    }
  }

  updateCow(cowId) {
    const deferred = this.$q.defer();

    this.CowService.get(cowId).then((res) => {
      this.c = res.data;
      // 日付を表示用に変換
      Object.assign(this.c, Cow.generateDisplayDates(this.c, {separator_style: 'jp'}));

      let market;
      if (this.c.masterMarketId !== null) {
        market = this.markets.find((m) => m.id === this.c.masterMarketId);
      }
      this.c.masterMarketName = market ? market.name : null;

      deferred.resolve();

    }).catch((err) => deferred.reject(err));

    return deferred.promise;
  }

  /**
  * 牧場種類や牛状態による表示フラグの切替
  * @param {string} farmKind 牧場種類
  * 牛状態による表示フラグの切替
  * @param {string} state 牛状態
   */
  updateDisplayOptionFlags(farmKind, state) {
    this.isMilkFarm = !!(this.FarmKind[farmKind] === 'milk');
    this.isMeatFarm = !!(this.FarmKind[farmKind] === 'meat');
    this.isFatteningType = !!(this.CowState[state] === 'fattening');
    this.isCalfType = !!(this.CowState[state] === 'calf');
    this.isReproductionType = !!(this.CowState[state] === 'reproduction');
    this.isSoldOrDiedType = !!(this.CowState[state] === 'soldOrDied');
  }

  openCowBoard(currentCowId) {
    return currentCowId ? 'open' : '';
  }

  closeCowBoard() {
    this.updateCurrentCow();
  }

  toggleTab(tab) {
    this.activeTab = tab;
  }

  formatCowUid(cowUid) {
    return Cow.formatCowUid(cowUid);
  }

  genereteBirthNumberDhis(dhis, birthNumber) {
    const sortedDhis = angular.copy(dhis)
      .filter((dhi) => dhi.birthNumber === birthNumber)
      .sort((a, b) => a.dhiDate < b.dhiDate ? 1 : -1);

    const items = [
      {field: 'dhiDate', name: '', format: (v) => DateUtil.toMMDD(v)},
      {field: 'dim', name: '搾乳日数'},
      {field: 'milkDaily', name: '乳量'},
      {field: 'milkExpected', name: '標準/管理乳量'},
      {field: 'fatPercent', name: '乳脂肪率'},
      {field: 'protPercent', name: '乳蛋白率'},
      {field: 'snfPercent', name: '無脂固形率'},
      {field: 'mun', name: 'MUN'},
      {field: 'scc', name: '体細胞数'},
      {field: 'linearScore', name: 'リニアスコア'},
      {field: 'milk305em', name: '305日補乳', format: (v) => Math.round(v)}
    ];

    const itemValues = items.reduce((acc, item) => {
      const values = sortedDhis.map((dhi) => item.format ? item.format(dhi[item.field]) : dhi[item.field]);
      acc.push(values);
      return acc;
    }, []);

    return {
      itemNames: items.map((v) => v.name),
      itemValues: itemValues
    };
  }

  updateDhis() {
    this.birthNumberDhis = this.genereteBirthNumberDhis(this.dhis, this.selectedBirthNumber);
  }

  updateSelectedActivityPeriod(period) {
    this.selectedActivityPeriod = period;
  }

  updateHiddenLegendIds(ids) {
    this.hiddenLegendIds = ids;
  }
}

app.controller('CowBoardController', CowBoardController);
