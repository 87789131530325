class GenomLinkageController {
  constructor(
    $rootScope,
    $location,
    $window,
    GenomLinkageAPI
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$location = $location;
    this.$window = $window;
    this.GenomLinkageAPI = GenomLinkageAPI;

    this.$rootScope.isEnvToastHide = true;

    this.initialize();
  }

  initialize() {
    this.GenomLinkageAPI.linkage().then((res) => {
      this.linkageToken = res.data['linkageToken'];
      this.redirectUrl = res.data['redirectUrl'];
      this.initialized = true;
    }).catch((err) => {
      console.error(err);
    });
  }

  onOK() {
    this.$window.location.href = `${this.redirectUrl}?linkage_token=${this.linkageToken}`;
  }

  onCancel() {
    this.$window.location.href = this.redirectUrl;
  }
}

app.controller('GenomLinkageController', GenomLinkageController);
