function uheadListMultiActionComponent() {
  'ngInject';
  return {
    templateUrl: 'components/list-action/uhead-index.html',
    controller: ListMultiActionController,
    controllerAs: 'listAction',
    bindings: {
      cows: '=',
      componentFuncs: '=',
      needLabelAction: '=',
      isDepositor: '=',
      callbackParam: '=',
      callerFuncs: '<'
    }
  };
}

app.component('uheadListMultiAction', uheadListMultiActionComponent());
