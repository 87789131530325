class DairyTechnicalReportAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  /**
   * 酪農テクニカルレポート取得API
   * @param {Date} startMonth はじめの月
   * @param {Date} endMonth 終わりの月
   */
  index(startMonth, endMonth) {
    if (!startMonth || !endMonth) {
      return null;
    }

    const startMonthParam = startMonth.getFullYear() + ('0' + (startMonth.getMonth() + 1)).slice(-2);
    const endMonthParam = endMonth.getFullYear() + ('0' + (endMonth.getMonth() + 1)).slice(-2);

    return this.APIService.get(`/dairy_technical_report?start_month=${startMonthParam}&end_month=${endMonthParam}`);
  }

  detailFields() {
    return new Array(
      {
        label: '乳量',
        unit: 'kg',
        base: {
          current: 'dailyMilkAvg',
          yoy: 'yoyDailyMilkAvg'
        },
        primal: false,
        multi: false
      },
      {
        label: '搾乳日数',
        unit: '',
        base: {
          current: 'dimAvg',
          yoy: null
        },
        primal: false,
        multi: false
      },
      {
        label: '搾乳牛頭数',
        unit: '',
        base: {
          current: 'milkingCowAvg',
          yoy: null
        },
        primal: false,
        multi: false
      },
      {
        label: '乾乳牛頭数',
        unit: '',
        base: {
          current: 'dryCowAvg',
          yoy: null
        },
        primal: false,
        multi: false
      },
      {
        label: '経産牛頭数',
        unit: '',
        base: {
          current: 'deliveredCowAvg',
          yoy: null
        },
        primal: false,
        multi: false
      },
      {
        label: '分娩頭数',
        unit: '',
        base: {
          current: 'calvingNumber',
          yoy: null
        },
        primal: false,
        multi: false
      },
      {
        label: '妊娠頭数',
        unit: '',
        base: {
          current: 'pregnantNumber',
          yoy: null
        },
        primal: false,
        multi: false
      },
      {
        label: '繁殖除外頭数',
        unit: '',
        base: {
          current: 'doNotBreedCowAvg',
          yoy: null
        },
        primal: false,
        multi: false
      },
      {
        label: '除籍頭数',
        unit: '',
        base: {
          current: 'deadAndCullNumber',
          yoy: null
        },
        primal: false,
        multi: false
      },
      {
        label: '妊娠牛割合',
        unit: '%',
        base: {
          current: 'pregnantCowRatio',
          yoy: null
        },
        primal: false,
        multi: false
      },
      {
        label: '妊娠率',
        unit: '%',
        base: {
          current: 'pregnancyRateAll',
          yoy: 'yoyPregnancyRateAll'
        },
        primal: {
          current: 'pregnancyRatePrimal',
          yoy: 'yoyPregnancyRatePrimal'
        },
        multi: {
          current: 'pregnancyRateMulti',
          yoy: 'yoyPregnancyRateMulti'
        }
      },
      {
        label: '発情発見率',
        unit: '%',
        base: {
          current: 'heatDetectionRateAll',
          yoy: 'yoyHeatDetectionRateAll'
        },
        primal: {
          current: 'heatDetectionRatePrimal',
          yoy: 'yoyHeatDetectionRatePrimal'
        },
        multi: {
          current: 'heatDetectionRateMulti',
          yoy: 'yoyHeatDetectionRateMulti'
        }
      },
      {
        label: '受胎率',
        unit: '%',
        base: {
          current: 'conceptionRateAll',
          yoy: null
        },
        primal: false,
        multi: false
      },
      {
        label: '初回授精日数',
        unit: '日',
        base: {
          current: 'daysFirstServiceAll',
          yoy: 'yoyDaysFirstServiceAll'
        },
        primal: {
          current: 'daysFirstServicePrimal',
          yoy: 'yoyDaysFirstServicePrimal'
        },
        multi: {
          current: 'daysFirstServiceMulti',
          yoy: 'yoyDaysFirstServiceMulti'
        }
      },
      {
        label: '空胎日数',
        unit: '日',
        base: {
          current: 'daysOpenAll',
          yoy: 'yoyDaysOpenAll'
        },
        primal: {
          current: 'daysOpenPrimal',
          yoy: 'yoyDaysOpenPrimal'
        },
        multi: {
          current: 'daysOpenMulti',
          yoy: 'yoyDaysOpenMulti'
        }
      },
      {
        label: '分娩間隔',
        unit: '日',
        base: {
          current: 'calvingInterval',
          yoy: 'yoyCalvingInterval'
        },
        primal: false,
        multi: false
      },
      {
        label: '産次数',
        unit: '',
        base: {
          current: 'birthNumber',
          yoy: null
        },
        primal: false,
        multi: false
      }
    );
  }
}

app.service('DairyTechnicalReportAPI', DairyTechnicalReportAPI);
