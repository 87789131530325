class ReproductionPerformanceMonthlyReportController {
  constructor(
    $rootScope,
    $scope,
    $state,
    ReproductionPerformanceAPI,
    $timeout
  ) {
    'ngInject';

    this.title = '繁殖成績';
    this.ReproductionPerformanceAPI = ReproductionPerformanceAPI;

    const monthlyAccordionReportControllerBase = new MonthlyAccordionReportControllerBase(
      $scope,
      $state,
      $timeout
    );
    MonthlyAccordionReportBinder.bind(this, monthlyAccordionReportControllerBase);

    this.initCommon(ReproductionPerformanceMonthlyReportController.DEFAULT_DISPLAY_MONTHS);
    this.initLocal();
  }

  static get DEFAULT_DISPLAY_MONTHS() {
    return 13;
  }

  initLocal() {
    this.categories = [
      {
        label: '産次数',
        unit: '産',
        base: ['birthNumber', 'yoyBirthNumber'],
        primal: false,
        multi: false,
        row_count: 1
      },
      {
        label: '発情発見率',
        unit: '%',
        base: ['heatDetectionRateAll', 'yoyHeatDetectionRateAll'],
        primal: ['heatDetectionRatePrimal', 'yoyHeatDetectionRatePrimal'],
        multi: ['heatDetectionRateMulti', 'yoyHeatDetectionRateMulti'],
        row_count: 3
      },
      {
        label: '妊娠率',
        unit: '%',
        base: ['pregnancyRateAll', 'yoyPregnancyRateAll'],
        primal: ['pregnancyRatePrimal', 'yoyPregnancyRatePrimal'],
        multi: ['pregnancyRateMulti', 'yoyPregnancyRateMulti'],
        row_count: 3
      },
      {
        label: '初回授精日数',
        unit: '日',
        base: ['daysFirstServiceAll', 'yoyDaysFirstServiceAll'],
        primal: ['daysFirstServicePrimal', 'yoyDaysFirstServicePrimal'],
        multi: ['daysFirstServiceMulti', 'yoyDaysFirstServiceMulti'],
        row_count: 3
      },
      {
        label: '空胎日数',
        unit: '日',
        base: ['daysOpenAll', 'yoyDaysOpenAll'],
        primal: ['daysOpenPrimal', 'yoyDaysOpenPrimal'],
        multi: ['daysOpenMulti', 'yoyDaysOpenMulti'],
        row_count: 3
      },
      {
        label: '分娩間隔',
        unit: '日',
        base: ['calvingInterval', 'yoyCalvingInterval'],
        primal: false,
        multi: false,
        row_count: 1
      },
    ];
  }

  /**
   * データの取得
   */
  fetchMonthlyData(startMonth, endMonth, dataMap) {
    this.shouldDisableSubmit = true;
    return this.ReproductionPerformanceAPI
      .index(startMonth.valueOf(), endMonth.valueOf())
      .then((resp) => {
        if (!resp || !Array.isArray(resp.data) || resp.data.length <= 0) {
          return [];
        }

        resp.data.forEach((monthlyData) => {
          if (dataMap[monthlyData.yearMonth]) {
            Object.assign(dataMap[monthlyData.yearMonth], monthlyData);
          }
        });

        return Object.keys(dataMap)
          .map((key) => {
            dataMap[key].month = Number(dataMap[key].month);
            return dataMap[key];
          });
      });
  }

  shouldAdjustScrollSize() {
    return true;
  }
}

app.controller('ReproductionPerformanceMonthlyReportController', ReproductionPerformanceMonthlyReportController);
