class AlertLocationBoxController {
  constructor(
    $state,
    AlertService
  ) {
    'ngInject';
    this.$state = $state;
    this.AlertService = AlertService;
  }

  $onInit() {
    this.alertLocationCount = 0;
    this.getAlertLocation();
  }

  getAlertLocation() {
    this.AlertService.getAlertLocationRecent().then((res) => {
      if (res.data) this.alertLocationCount = res.data.length;
    }).catch((error) => console.error(error));
  }

  showAlertLocation() {
    if (this.farm) {
      return this.farm.useAlertLocation() && this.alertLocationCount > 0;
    }
  }
}

function alertLocationBox() {
  return {
    templateUrl: 'top/alert/location-box.html',
    controller: AlertLocationBoxController,
    bindings: {
      farm: '<',
    }
  };
}

app.component('alertLocationBox', alertLocationBox());
