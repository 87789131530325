app.component('heatDetectionRateChartDim', {
  template: '<div ng-click="$ctrl.showTooltip()" id="heat-detection-rate-chart"></div>',
  controller: 'HeatDetectionRateChartDimController',
  bindings: {
    'performances': '<',
    'targetHeatDetectionRisk': '<',
    'targetPregnancyRisk': '<',
    'dimCaption': '<',
  }
});

class HeatDetectionRateChartDimController {
  constructor(
    UtilService
  ) {
    'ngInject';

    this.UtilService = UtilService;
    this.element = '#heat-detection-rate-chart';
  }

  $onInit() {
    if (!this.performances || this.performances.length <= 0) return;
    this.drawChart(this.element, this.performances);
  }

  $onChanges(changes) {
    if (changes.performances) {
      this.drawChart(this.element, this.performances);
    }
  }

  drawChart(element, performances) {
    const dimMedians = performances.map((p) => p.dimMedian);
    dimMedians.unshift('x');

    const heatDetectionRates =
      performances.map((p) => this.UtilService.round(p.heatDetectionRate - p.pregnancyRate, -1));
    heatDetectionRates.unshift('発情発見率');

    const pregnancyRates = performances.map((p) => p.pregnancyRate);
    pregnancyRates.unshift('妊娠率');

    const conceptionRates = performances.map((p) => p.conceptionRate);
    conceptionRates.unshift('受胎率');

    const openRates = performances.map((p) => p.openRate);
    openRates.unshift('空胎牛率');

    const prConfidenceIntervals = performances.map((p) => p.prConfidenceInterval);

    this.generatePerformanceGraph(element, dimMedians, heatDetectionRates, pregnancyRates, conceptionRates, openRates);
    ConfidenceIntervalBar.append(element, prConfidenceIntervals);
  }

  generatePerformanceGraph(element, dimMedians, heatDetectionRates, pregnancyRates, conceptionRates, openRates) {
    c3.generate({
      bindto: element,
      size: {
        width: 670
      },
      data: {
        x: 'x',
        columns: [
          dimMedians,
          pregnancyRates,
          heatDetectionRates,
          conceptionRates,
          openRates,
        ],
        type: 'bar',
        types: {
          受胎率: 'line',
          空胎牛率: 'line',
          'error-data1': 'error',
        },
        groups: [
          ['発情発見率', '妊娠率', '受胎率']
        ],
        order: null,
      },
      axis: {
        x: {
          label: {
            text: this.dimCaption,
            position: 'outer-right'
          },
          tick: {
            values: [40, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 320, 340, 360, 380],
            multiline: false,
          },
        },
        y: {
          max: 90,
        }
      },
      color: {
        pattern: ['#FAB1AF', '#FADBDA', '#FAA84B', '#000000']
      },
      point: {
        show: false
      },
      tooltip: {
        contents: this.generateTooltip(this.performances)
      },
      grid: {
        y: {
          lines: [{value: this.targetPregnancyRisk, class: 'grid20', text: '目標妊娠率'}, {value: this.targetHeatDetectionRisk, class: 'grid60', text: '目標発情発見率'}]
        },
      },
      legend: {
        show: true
      },
    });
  }

  generateTooltip(performances) {
    return (data, defaultTitleFormat, defaultValueFormat, color) => {
      const dimMedian = data[0].x;
      const performance = performances.find((p) => {
        return p.dimMedian === dimMedian;
      });

      let prConfidenceIntervalFromTo = null;
      if (performance.pregnancyRate !== null && performance.prConfidenceInterval !== null) {
        const from = this.formatRate(performance.pregnancyRate - performance.prConfidenceInterval);
        const to = this.formatRate(performance.pregnancyRate + performance.prConfidenceInterval);
        prConfidenceIntervalFromTo = `${from} - ${to}`;
      } else {
        prConfidenceIntervalFromTo = '-';
      }

      return `
        <div class="heat-detection-rate-chart-tooltip">
          <table>
            <thead>
              <tr>
                <th colspan=2>${this.dimCaption}：${performance.dimCaption} 日</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>発情発見率</td>
                <td>${this.formatRate(performance.heatDetectionRate)}</td>
              </tr>
              <tr>
                <td>受胎率</td>
                <td>${this.formatRate(performance.conceptionRate)}</td>
              </tr>
              <tr>
                <td>妊娠率</td>
                <td>${this.formatRate(performance.pregnancyRate)}</td>
              </tr>
              <tr>
                <td>空胎牛割合</td>
                <td>${this.formatRate(performance.openRate)}</td>
              </tr>
              <tr>
                <td>妊娠率95%<br>信頼区間</td>
                <td>${prConfidenceIntervalFromTo}</td>
              </tr>
            </tbody>
          </table>
        </div>
      `;
    };
  }

  formatRate(value) {
    return (value === null) ? '-' : `${String(this.UtilService.round(value, -1))}%`;
  }

  showTooltip() {
    const tooltip = document.getElementsByClassName('heat-detection-rate-chart-tooltip')[0];
    if (tooltip) {
      tooltip.className = tooltip.className + ' show-tooltip';
    }

    const container = document.getElementsByClassName('c3-tooltip-container')[0];
    if (container) {
      const containerTop = Number(container.style.top.replace(/px/, ''));
      container.className = containerTop > 150 ? container.className + ' move-up' : container.className.replace(/ move-up/g, '');
    }
  }
}

app.controller('HeatDetectionRateChartDimController', HeatDetectionRateChartDimController);
