/**
 * ローディング中にクルクル回すやつを表示するコンポネント
 * 使い方: <loading ng-if="ctrl.loading"></loading>
 */
function loadingComponent() {
  'ngInject';
  return {
    templateUrl: 'components/loading/index.html',
  };
}

app.component('loading', loadingComponent());
