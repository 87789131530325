class MobileBulkRemoveLabelModal {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  open(cows) {
    return this.$modal.open({
      templateUrl: 'mobile/components/bulk-edit-label/bulk-remove-label-modal/bulk-remove-label-modal.html',
      controller: 'MobileBulkRemoveLabelModalController as ctrl',
      resolve: {
        cows: () => cows
      }
    }).result;
  }
}
app.service('MobileBulkRemoveLabelModal', MobileBulkRemoveLabelModal);
