function umAutoFocusDirective($window, $timeout) {
  return {
    restrict: 'A',
    link: function(scope, element) {
      if ($window.ontouchstart === null) return;

      $timeout(() => {
        element.focus();
      }, 1000);
    }
  };
}

app.directive('umAutoFocus', ['$window', '$timeout', umAutoFocusDirective]);
