class MobileRemoveCowsModal {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  open(cows) {
    return this.$modal.open({
      templateUrl: 'mobile/bulk-entry/remove-cows-modal/remove-cows-modal.html',
      controller: 'MobileRemoveCowsModalController as ctrl',
      resolve: {
        cows: () => {
          return cows;
        }
      }
    }).result;
  }
}
app.service('MobileRemoveCowsModal', MobileRemoveCowsModal);
