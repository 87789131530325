function run(
  $modalStack,
  $rootScope,
  $state,
  $window,
  $location,
  $http,
  $interval,
  SessionStateService,
  DetailsStateFactory,
  IsMobile,
  ApplicationInitialService,
  SelectOperator,
  ViewStateAPI,
  modalDialogFactory,
  SideBoardService,
  SessionCache
) {
  'ngInject';
  $rootScope.isMobile = IsMobile;

  const version = new Version($http, $window, modalDialogFactory);

  if (skipRegularRooting()) {
    return;
  }

  if (SessionStateService.authenticated()) {
    $rootScope.authenticated = true;
    ApplicationInitialService.initialize().then(() => {
      SelectOperator.execute();
    });
  } else {
    $rootScope.showLogin = true;
  }

  const AUTH_URLS = [
    '/login',
    '/login/sms',
    '/changePassword',
  ];

  $rootScope.$on('$stateChangeStart', (ev, toState, toParams, fromState, fromParams) => {
    $modalStack.dismissAll('cancel');

    if (toState.url === '/sign-up/sms') {
      $rootScope.authenticated = false;
      $rootScope.showLogin = true;
      return;
    }

    if (AUTH_URLS.includes(toState.url)) {
      if (!$rootScope.authenticated) {
        if (toState.url === '/login' && fromState.url !== '/login/sms') {
          const lastAuthMethod = localStorage.getItem('u-motion-last-auth-method') || 'e-mail';
          if (lastAuthMethod === 'sms') {
            ev.preventDefault();
            $state.go('login-sms', {
              redirectToName: toState.name,
              redirectParams: toParams
            });
          }
        }
        return;
      }
      ev.preventDefault();
      redirectTop();
    } else {
      if (!$rootScope.authenticated) {
        ev.preventDefault();

        const lastAuthMethod = localStorage.getItem('u-motion-last-auth-method') || 'e-mail';
        const queryParams = $location.search();
        let redirectState = 'login';
        if (lastAuthMethod === 'sms' || queryParams.login === 'sms') {
          redirectState = 'login-sms';
        }

        $state.go(redirectState, {
          redirectToName: toState.name,
          redirectParams: toParams
        });
      }
    }

    /**
     * 牛個体画面からその他画面へ遷移するとき
     */
    if (stateIsCowDetail(fromState.name) && !stateIsCowDetail(toState.name)) {
      // タブをデフォルトにリセットする。
      DetailsStateFactory.set('displayTab', 'summaryTab');
      return;
    }

    SideBoardService.setState('show', false);
  });

  // これより上の階層だとcss がまだ読み込まれていないためcontroller のトップレベルで判定を行う
  _.each($('link'), function(target) {
    if (IsMobile && ($(target).attr('href').indexOf('app') >= 0)) {
      $(target).remove();
    } else if (!IsMobile && $(target).attr('href').indexOf('mobile') >= 0) {
      $(target).remove();
    }
  });

  $rootScope.$on('$stateChangeSuccess', function(ev, to, toParams, from, fromParams) {
    // 画面遷移後、トップへ戻す
    $window.scrollTo(0, 0);

    if ($rootScope.authenticated && !globalTestMode) {
      ViewStateAPI.create(to.name, from.name);
    }

    $rootScope.isMobileHome = to.name === 'mobile-top';

    if (!globalTestMode) version.confirm();

    if (to.name) {
      $rootScope.className = `barn--${to.name}`;
    }

    $rootScope.isShowSideBoard = SideBoardService.getState('show');

    setTemporaryGroupViewViewport();

    $rootScope.disableDotBarn = toParams.disableDotBarn === true ? true : false;
    $rootScope.disableLegacyMobileStickyAlert = toParams.disableLegacyMobileStickyAlert === true ? true : false;
    $rootScope.isModernScreen = toParams.isModernScreen === true ? true : false;
  });

  /**
   * 遷移先/元が牛個体であることを確認する
   * @param {string} state
   * @return {boolean}
   */
  function stateIsCowDetail(state) {
    return state === 'cowDetail';
  }

  function redirectTop() {
    if (IsMobile) {
      $state.go('mobile-top');
    } else {
      $state.go('top');
    }
  }

  function skipRegularRooting() {
    if ($location.$$path === '/external/genom/linkage') {
      redirectToGenomLinkage();
      return true;
    } else if ($location.$$path === '/external/auth') {
      redirectToExternalAuth();
      return true;
    }
    return false;
  }

  function redirectToGenomLinkage() {
    if (SessionStateService.authenticated()) {
      $rootScope.showGenomLinkage = true;
    } else {
      $rootScope.showGenomLinkageAuth = true;
      $state.go('genom-linkage-authentication');
    }
  }

  function redirectToExternalAuth() {
    $rootScope.showExternalAuth = true;
  }

  function setTemporaryGroupViewViewport() {
    const interval = $interval(() => {
      if (SessionCache.farm()) {
        $interval.cancel(interval);

        const useTemporaryGroupView = SessionCache.farm().useTemporaryGroupView();
        const ua = $window.navigator.userAgent;
        const isTablet = ua.indexOf('iPad') >= 0;

        if (!useTemporaryGroupView || IsMobile || isTablet) return;

        document.querySelector('meta[name="viewport"]').remove();

        let meta = document.createElement('meta');
        meta.name = 'viewport';
        meta.content = 'width=1500';
        document.querySelector('head').appendChild(meta);
      }
    }, 100);
  }
}

app.run(run);
