class DepositCowBoardContext {
  constructor(
    DepositCowService,
    DepositFarmService,
    MasterMarketService
  ) {
    'ngInject';

    this.DepositCowService = DepositCowService;
    this.DepositFarmService = DepositFarmService;
    this.MasterMarketService = MasterMarketService;
  }

  load(cowId) {
    return this.MasterMarketService.available();
  }

  cowService() {
    return this.DepositCowService;
  }

  farmService() {
    return this.DepositFarmService;
  }
}

app.service('DepositCowBoardContext', DepositCowBoardContext);
