class MobileBulkentryErrorModal {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  open(errors, dateLabel) {
    const errorMessage = this.errorsToMessage(errors, dateLabel);

    return this.$modal.open({
      templateUrl: 'mobile/bulk-entry/bulkentry-error-modal/bulkentry-error-modal.html',
      controller: 'MobileBulkentryErrorModalController as ctrl',
      resolve: {
        message: () => {
          return errorMessage.message;
        },
        details: () => {
          return errorMessage.details;
        }
      }
    }).result;
  }

  errorsToMessage(errors, dateLabel) {
    const beforeCalvingDateErrors = errors.filter((error) => {
      return error.type === CowEventValidator.ERROR_TYPE_BEFORE_CALVING_DATE;
    });
    if (beforeCalvingDateErrors.length) {
      return {
        message: `${dateLabel}が最新分娩日より前の日付になっている牛個体がいます。入力内容を修正してください。`,
        details: beforeCalvingDateErrors.map((error) => {
          return {
            cowNo: error.cowEvent.cowNo,
            label: '最新分娩日',
            value: DateUtil.toYYYYMMDD(error.cowEvent.latestCalvingDate)
          };
        })
      };
    }

    const invaliOccurredDiseaseDateErrors = errors.filter((error) => {
      return error.type === CowEventValidator.ERROR_TYPE_INVALID_OCCURRED_DISEASE_DATE;
    });
    if (invaliOccurredDiseaseDateErrors.length) {
      return {
        message: '治療日が発生日より前の日付になっている牛個体がいます。入力内容を修正してください。',
        details: invaliOccurredDiseaseDateErrors.map((error) => {
          return {
            cowNo: error.cowEvent.cowNo,
            label: '発生日',
            value: DateUtil.toYYYYMMDD(error.cowEvent.occurredDiseaseDate)
          };
        })
      };
    }

    const invaliGenderErrors = errors.filter((error) => {
      return error.type === CowEventValidator.ERROR_TYPE_UNMATCHED_GENDER;
    });
    if (invaliGenderErrors.length) {
      return {
        message: '以下の個体は去勢対象ではありません。登録対象から除外してください。',
        details: invaliGenderErrors.map((error) => {
          return {
            cowNo: error.cowEvent.cowNo,
            label: '性別',
            value: error.cowEvent.gender
          };
        })
      };
    }

    return {};
  }
}
app.service('MobileBulkentryErrorModal', MobileBulkentryErrorModal);
