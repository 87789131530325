class CalvingSensorEquipCandidatesModalController {
  constructor(
    $timeout,
    $modalInstance,
    params,
    blockUI,
    CowCalvingSensorHistoryAPI
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$modalInstance = $modalInstance;
    this.params = params;
    this.blockUI = blockUI;
    this.CowCalvingSensorHistoryAPI = CowCalvingSensorHistoryAPI;

    this.init();
  }

  init() {
    this.errorMessage = {};
    this.items = angular.copy(this.params.grid.data).map((item) => {
      item.startDate = DateUtil.toMSec(DateUtil.today());
      item.sensorNumber = {
        firstNumber: '',
        lastNumber: ''
      };

      return item;
    });
  }

  update() {
    const params = this.items.map((item) => {
      return {
        cowId: item.cowId,
        calvingSensorNumber: CowCalvingSensorHistory.generateCalvingSensorNumber(
          item.sensorNumber.firstNumber,
          item.sensorNumber.lastNumber),
        startDate: item.startDate
      };
    });

    this.errorMessage = {};
    this.CowCalvingSensorHistoryAPI.bulkEquip(params).then(() => {
      this.blockUI.done(() => {
        this.blockUI.start('分娩センサーの装着が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);
      });
      this.blockUI.stop();
      this.$modalInstance.close();
    }).catch((err) => {
      const items = err.data.messages;
      items.forEach((item) => {
        if (item.field) {
          if (!this.errorMessage[item.field]) {
            this.errorMessage[item.field] = {};
          }

          this.errorMessage[item.field][item.lineNo] = item.message;
        }
      });

      this.blockUI.stop();
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  onKeydownSensorNumber(e) {
    if (!KeyInputUtil.isNumberKey(e)) {
      e.preventDefault();
    }
  }

  onKeyupSensorNumber(e, field, index) {
    if (!KeyInputUtil.isNumberKey(e)) {
      e.preventDefault();
    }

    if (field === 'firstNumber' &&
      (StringUtil.isDigit(e.target.value) || e.key === 'ArrowRight') &&
      e.target.selectionStart === 3) {
      e.target.parentNode.querySelector('.uModal__formTextBody:nth-of-type(2)').focus();
    } else if (field === 'lastNumber' &&
      (e.key === 'ArrowLeft' || e.key === 'Backspace') &&
      e.target.selectionStart === 0) {
      e.target.parentNode.querySelector('.uModal__formTextBody:nth-of-type(1)').focus();
    }
  }

  onChangeSensorNumber(index) {
    if (!this.errorMessage.calvingSensorNumber) {
      this.errorMessage.calvingSensorNumber = {};
    } else if (this.errorMessage.calvingSensorNumber[index + 1]) {
      delete this.errorMessage.calvingSensorNumber[index + 1];
    }

    if ((this.items[index].sensorNumber.firstNumber &&
        !StringUtil.isDigit(this.items[index].sensorNumber.firstNumber)) ||
      (this.items[index].sensorNumber.lastNumber &&
        !StringUtil.isDigit(this.items[index].sensorNumber.lastNumber))) {
      this.errorMessage.calvingSensorNumber[index + 1] = '半角数字で入力してください。';
    }
  }

  disabledUpdate() {
    return !this.items.every((item) => {
      return item.sensorNumber.firstNumber.length === 3 &&
        item.sensorNumber.lastNumber.length === 4 &&
        StringUtil.isDigit(item.sensorNumber.firstNumber) &&
        StringUtil.isDigit(item.sensorNumber.lastNumber) &&
        DateUtil.isValidDate(item.startDate) &&
        DateUtil.includedPermittedPeriod(item.startDate);
    });
  }

  classFormGridTitle(index) {
    if (index === 0) {
      return 'uModal__formGridTitle--width92';
    } else if (index === 1) {
      return 'uModal__formGridTitle--width176';
    } else {
      return 'uModal__formGridTitle';
    }
  }

  classFormTextSeparate(err) {
    return err ? 'uModal__formTextSeparate--error' : 'uModal__formTextSeparate';
  }
}

app.controller('CalvingSensorEquipCandidatesModalController', CalvingSensorEquipCandidatesModalController);
