class ReproductionConfigEditController {
  constructor(
    $modalInstance,
    $modal,
    blockUI,
    params,
    ReproductionConfigService,
    PregnantDiagnosisTimingAPI,
    CalvingConfigAPI
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.$modal = $modal;
    this.blockUI = blockUI;

    this.ReproductionConfigService = ReproductionConfigService;
    this.PregnantDiagnosisTimingAPI = PregnantDiagnosisTimingAPI;
    this.CalvingConfigAPI = CalvingConfigAPI;

    this.config = params.config;
    this.pregnantDiagnosisTimings = params.pregnantDiagnosisTimings;
    this.calvingConfig = params.calvingConfig;
    this.templateUrl = `menu/setting/reproduction/${params.target}.html`;
    this.target = params.target;

    this.farmType = params.farmType;
    this.typeMilk = this.farmType === Farm.farmTypeMilk;
    this.typeBeef = this.farmType === Farm.farmTypeBeef;

    this.invalid = false;
    this.isEditing = true;
  }

  save() {
    const formatErrorMessage = (messages) => {
      if (!messages) return '';
      return messages.map((message) => message.message).join('\n');
    };

    if (this.target === 'pregnant-diagnosis-timing') {
      this.pregnantDiagnosisTimings.forEach((timing) => timing.timing = timing.periodicity === 'regular' ? timing.timing : 0);
    }

    this.blockUI.start('更新中');

    const promise = this.decidePromise();
    promise.then((res) => {
      this.blockUI.stop();
      this.$modalInstance.close();
    }).catch((res) => {
      this.blockUI.stop();
      this.message = formatErrorMessage(res.data.messages);
    });
  }

  addPregnantDiagnosisTimingField() {
    this.pregnantDiagnosisTimings.push({
      name: null,
      timing: null,
      periodicity: 'regular'
    });
  }

  removePregnantDiagnosisTimingField(index) {
    this.pregnantDiagnosisTimings.splice(index, 1);
  }

  shouldShowTimingField(timing) {
    return timing.periodicity === 'regular';
  }

  cancel() {
    this.$modalInstance.close(false);
  }

  decidePromise() {
    switch (this.target) {
    case 'pregnant-diagnosis-timing':
      return this.PregnantDiagnosisTimingAPI.update(this.pregnantDiagnosisTimings);
    case 'calving-config':
      return this.CalvingConfigAPI.update(this.calvingConfig);
    default:
      return this.ReproductionConfigService.update(this.config);
    }
  }

  // 分娩設定用
  validatePlannedDays(days, form) {
    const daysRange = {min: 0, max: 99};
    const valid = days >= daysRange.min && days <= daysRange.max;

    if (valid) {
      form.$invalid = false;
    } else {
      form.$invalid = true;
    }
  }

  onKeydownPlannedDays(e) {
    if (!KeyInputUtil.isNumberKey(e)) {
      e.preventDefault();
    }
  }
}

app.controller('ReproductionConfigEditController', ReproductionConfigEditController);
