/**
 * 分娩センサー脱落アラートのカードコンポーネントです
 *
 * ex.
 *   <alert-calving-sensor-dropped-card
 *     alert-calving-sensor-dropped="alertCalvingSensorDropped"
 *     on-cow-no-click="onCowNoClick(cowId)"
 *     on-solution-selection-click="onSolutionSelectionClick(id)"
 *   ></alert-calving-sensor-dropped-card>
 *
 * プロパティ:
 *  @param {Object} alert-calving-sensor-dropped 分娩センサー脱落アラートオブジェクト
 *  @param {Function} on-cow-no-click 牛番号クリック時に発生するイベント cowIdを引数で渡す
 *  @param {Function} on-solution-selection-click 処置内容を選択ボタンクリック時に発生するイベント idを引数で渡す
 */
class AlertCalvingSensorDroppedCardController {
  constructor() {
    'ngInject';
  }

  handleCowNoClick() {
    this.onCowNoClick({cowId: this.alertCalvingSensorDropped.cowId});
  }

  handleSolutionSelectionClick() {
    this.onSolutionSelectionClick({id: this.alertCalvingSensorDropped.id});
  }
}

function alertCalvingSensorDroppedCardComponents() {
  return {
    templateUrl: 'menu/alert/calving-sensor-dropped/card.html',
    controller: AlertCalvingSensorDroppedCardController,
    bindings: {
      alertCalvingSensorDropped: '<',
      onCowNoClick: '&',
      onSolutionSelectionClick: '&'
    }
  };
}

app.component('alertCalvingSensorDroppedCard', alertCalvingSensorDroppedCardComponents());
