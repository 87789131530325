class DhiImportBothController {
  constructor(
  ) {
    'ngInject';
  }

  changeImportType(type) {
    if (this.activeImportType === type) return;
    this.activeImportType = type;
  }

  classImportType(type) {
    return this.activeImportType === type ? 'contents__buttonGroupItem--active' : 'contents__buttonGroupItem';
  }
}

function dhiImportBothComponent() {
  return {
    templateUrl: 'menu/dhi/dhi-import/import-both.html',
    controller: DhiImportBothController,
    controllerAs: 'ctrl',
    bindings: {
      activeImportType: '='
    }
  };
}

app.component('dhiImportBoth', dhiImportBothComponent());
