function certificateBirthPrint() {
  'ngInject';

  return {
    restrict: 'E',
    scope: true,
    templateUrl: 'menu/certificate/birth/print/index.html',
  };
}

app.directive('certificateBirthPrint', certificateBirthPrint);
