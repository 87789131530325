class MobileUClipsController {
  constructor($stateParams, blockUI, SloppyReportAPI) {
    'ngInject';

    this.blockUI = blockUI;
    this.SloppyReportAPI = SloppyReportAPI;
    this.sloppyReports = [];
    this.dataList = [];
    this.selectedSloppyReport = null;
    this.uClipsId = Number($stateParams.id);
  }

  $onInit() {
    this.blockUI.start('ロード中');
    this.SloppyReportAPI.index()
      .then(({data}) => {
        data.forEach((e) => {
          if (e.id === this.uClipsId) this.selectedSloppyReport = e;
        });

        this.SloppyReportAPI.run(this.uClipsId)
          .then(({data}) => {
            data.rows.forEach((r, ri) => {
              let primary = {}, secondary = [];

              data.columns.forEach((c, ci) => {
                if (ci === 0) {
                  primary.label = c.label;
                  primary.value = r[ci];
                } else {
                  secondary.push({label: c.label, value: r[ci]});
                }
              });

              this.dataList.push({primary: primary, secondary: secondary});
            });
          });
      })
      .finally(() => {
        this.blockUI.stop();
      });
  }
}

app.controller('MobileUClipsController', MobileUClipsController);
