app.directive('treatmentOccurredDateTh', treatmentOccurredDateTh);

function treatmentOccurredDateTh($compile) {
  'ngInject';
  return {
    restrict: 'A',
    scope: '=',
    link: (s, el, attr) => {
      let template;
      template = $compile(
        `<th
          class="col col-w250"
          colspan="2"
          rowspan="2">発生日
            <span class="required">必須</span>
        </th>
       `
      )(s);

      el.replaceWith(template);
    },
  };
}
