class FirstAiDimController {
  constructor(
    $scope,
    $timeout,
    ReproductionConfigService
  ) {
    'ngInject';
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.ReproductionConfigService = ReproductionConfigService;

    this.endDate = DateUtil.today();
    this.startDate = DateUtil.addDays(
      DateUtil.addYears(this.endDate, -1).valueOf(),
      1
    );
    this.vwp = null;

    this.isReversal = false;
  }

  $onInit() {
    this.$timeout(() => {
      this.ReproductionConfigService.show().then((res) => {
        this.vwp = res.data.vwpMultiparous || 0;
        this.drawChart(
          this.startDate,
          this.endDate,
          this.vwp
        );
      }).catch((err) => console.error(err));
    });
  }

  rangeChange() {
    this.isReversal = false;
    this.isFutureEndDate = false;
    this.isOutOfRange = false;

    if (this.startDate < new Date(2000, 1, 1)) {
      return;
    }
    if (this.startDate > this.endDate) {
      this.isReversal = true;
      return;
    }

    if (DateUtil.isFutureDay(this.endDate)) {
      this.isFutureEndDate = true;
      return;
    }

    const diffMonths = DateUtil.diffMonths(this.startDate, this.endDate);
    const fiveYearMonths = 12 * 5;
    if (diffMonths >= fiveYearMonths) {
      this.isOutOfRange = true;
      return;
    }

    return this.drawChart(
      this.startDate,
      this.endDate,
      this.vwp
    );
  }

  drawChart(startDate, endDate, vwp) {
    this.$scope.$broadcast('drawChart', {startDate: startDate, endDate: endDate, vwp: vwp});
  }

  validate(baseDateString) {
    if (!moment(baseDateString.replace(/\//g, '-'), 'YYYY-MM-DD', true).isValid()) return false;
    return true;
  }
}

function firstAiDimComponent() {
  'ngInject';
  return {
    templateUrl: 'reproduction-performance-report/contents/first-ai-dim/first-ai-dim.html',
    controller: FirstAiDimController,
  };
}

app.component('firstAiDim', firstAiDimComponent());
