class MobileAlertListController {
  constructor(
    $state,
    FarmService,
    CowService,
    AlertService,
    DateUtilService,
    CowCollectionService,
    DetailsStateFactory,
    $stateParams
  ) {
    'ngInject';

    this.$state = $state;
    this.FarmService = FarmService;
    this.CowService = CowService;
    this.AlertService = AlertService;
    this.DateUtilService = DateUtilService;
    this.CowCollectionService = CowCollectionService;
    this.DetailsStateFactory = DetailsStateFactory;
    this.alertType = $stateParams.alertType;
    this.alertCows = null;
    this.goToDetails = this.goToDetails.bind(this);
    this.init();
  }

  init() {
    this.alertTitle();
    this.getFarmKind();
    this.getAlertCows();
    this.DetailsStateFactory.initialise();
  }

  // private

  /**
   * 牧場種別を取得する
   */
  getFarmKind() {
    this.FarmService.show().then((res) => {
      this.farmKind = res.farmKind;
    }).catch((err) => console.error(err));
  }

  /**
   * アラートタイトルを設定する
   */
  alertTitle() {
    const titles = {
      milkLow: '乳量低下牛の一覧', feedLow: '採食時間低下牛の一覧',
      waterLow: '飲水回数低下牛の一覧', lieHigh: '横臥時間増加牛の一覧'
    };
    this.title = titles[this.alertType];
  }

  /**
   * アラート対象牛を取得する
   */
  getAlertCows() {
    const alertTypes = {
      milkLow: 'milk-low', feedLow: 'feed-low',
      waterLow: 'water-low', lieHigh: 'lie-high'};
    this.AlertService.alertCows(alertTypes[this.alertType]).then((result) => {
      if (result.data && result.data.length > 0) {
        const alertCows = result.data;
        this.alertCows = this.decorate(alertCows);
        this.CowCollectionService.sort(this.alertCows);
      }
    }).catch((error) => console.error(error));
  }

  /**
   * アラート対象牛をデコレートする
   *
   * @param {Array} cows アラート対象牛配列
   * @return {Array} デコレート後のアラート対象牛配列
   */
  decorate(cows) {
    return cows.map((cow) => new AlertDecorator(cow, this.DateUtilService));
  }

  // NOTE: アラート・リストのテンプレートでは、ui-srefを経由して
  //       直接遷移するパターンもあり、このメソッドでイベントを
  //       全てカバーしていません
  goToDetails(cowId) {
    const caller = {
      state: this.alertType,
      name: this.title
    };

    this.$state.go('mobileCowDetail', {
      cowId,
      caller
    });
  }
}

app.controller('MobileAlertListController', MobileAlertListController);
