class AlertCalvingDetectionThresholdAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  show() {
    return this.APIService.get('/alert/calving_detection_threshold');
  }

  create(params) {
    return this.APIService.post('/alert/calving_detection_threshold', params);
  }
}

app.service('AlertCalvingDetectionThresholdAPI', AlertCalvingDetectionThresholdAPI);
