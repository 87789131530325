class CowshedCoolPesconChartOperationController {
  constructor() {
    'ngInject';

    this.formattedDate = null;
  }

  $onChanges(changesObj) {
    if (changesObj.date && changesObj.date.currentValue) {
      this.formattedDate = DateUtil.toJapaneseYYYYMMDD(changesObj.date.currentValue);
    }
  }

  prevDate() {
    const params = {startDate: null, endDate: null};

    if (this.isDaysUnit()) {
      params.endDate = DateUtil.endOfDay(DateUtil.addDays(this.date, -7));
      params.startDate = DateUtil.startOfDay(DateUtil.addDays(params.endDate, -6));
    } else {
      params.endDate = DateUtil.endOfDay(DateUtil.addDays(this.date, -1));
      params.startDate = DateUtil.startOfDay(params.endDate);
    }
    this.onDateChange(params);
  }

  nextDate() {
    const params = {startDate: null, endDate: null};

    if (this.isDaysUnit()) {
      params.endDate = DateUtil.endOfDay(DateUtil.addDays(this.date, 7));
      params.startDate = DateUtil.startOfDay(DateUtil.addDays(params.endDate, -6));
    } else {
      params.endDate = DateUtil.endOfDay(DateUtil.addDays(this.date, 1));
      params.startDate = DateUtil.startOfDay(params.endDate);
    }
    this.onDateChange(params);
  }

  handleUnitChange(unit) {
    if (this.unit === unit) return;
    this.onUnitChange({unit: unit});
  }

  isDaysUnit() {
    return this.unit === 'days';
  }

  isHoursUnit() {
    return this.unit === 'hours';
  }
}

function cowshedCoolPesconChartOperationComponent() {
  'ngInject';
  return {
    controller: CowshedCoolPesconChartOperationController,
    templateUrl: 'menu/environment/cowshed/cool-pescon/chart-operation.html',
    bindings: {
      date: '<',
      unit: '<',
      onDateChange: '&',
      onUnitChange: '&'
    }
  };
}
app.component('cowshedCoolPesconChartOperation', cowshedCoolPesconChartOperationComponent());
