/* eslint-disable */

class AlertHeatDecorator {
  constructor(obj, DateUtilService) {
    this.obj = obj;
    this.DateUtilService = DateUtilService;
    this.selected = false;

    // TODO: Proxyに変えたい
    Object.keys(obj).forEach((key) => {
      if (key in this) return;
      this[key] = obj[key];
    });
  }

  get cowUid() {
    return formatCowUid(this.obj.cowUid);
  }

  get latestCalvingDate() {
    return this.DateUtilService.toYYYYMMDD(this.obj.latestCalvingDate);
  }

  get daysAfterCalving() {
    return this.diffDays(this.obj.latestCalvingDate);
  }

  get latestFertilizationDate() {
    return this.DateUtilService.toYYYYMMDD(this.obj.latestFertilizationDate);
  }

  get daysAfterFertilization() {
    return this.diffDays(this.obj.latestFertilizationDate);
  }

  get indexDescription() {
    return this.obj.peakHeatIndex >= 75 ? 'HIGH': 'LOW';
  }

  get peakAt() {
    if (!this.obj.peakAt) return '-';
    let peakAt = new Date(Number(this.obj.peakAt));
    let minutes = Math.ceil((new Date() - peakAt) / 1000 / 60);
    if (minutes >= 60) {
      let hour = Math.floor(minutes / 60);
      return hour + '時間前';
    } else {
      return (Math.ceil(minutes / 5) * 5) + '分前';
    }
  }

  /**
   * 妊娠日数
   */
  get latestPregnancyDays() {
    return this.diffDays(this.obj.latestPregnancyDate) || null;
  }

  // private

  /**
   * 引数の日付から今日までの日数を返す
   *
   * @param {number} date unixtime
   * @return {number} 日数
   */
  countDays(date) {
    if (!date) return '';
    return this.DateUtilService.countDays(Number(date), new Date().getTime());
  }

  diffDays(date) {
    if (!date) return '';
    return this.DateUtilService.diffDays(Number(date), new Date().getTime());
  }
}
