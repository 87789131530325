class LactationPerformanceController {
  constructor(
    $state,
    $stateParams,
    $window,
    $timeout,
    SessionCache,
    Dictionary,
    DhiAPI,
    LactationPerformanceAPI
  ) {
    'ngInject';

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$window = $window;
    this.$timeout = $timeout;
    this.farm = SessionCache.farm();
    this.Dictionary = Dictionary;
    this.DhiAPI = DhiAPI;
    this.LactationPerformanceAPI = LactationPerformanceAPI;

    this.init();
  }

  init() {
    const dhiLinkage = this.farm.data.dhiLinkage;

    this.isDhiLinkage = dhiLinkage !== 'none';
    this.hasDhiDates = false;

    this.generateSelectDhiDatesMap().then(() => {
      if (this.selectDhiDatesMap.items.length > 0) {
        this.hasDhiDates = true;
      }

      const activeTabKey = this.$stateParams.activeTab || 'milkDailyAnalysis';

      this.tabMap = LactationPerformanceMaps.tab(this.isDhiLinkage, this.hasDhiDates).filter((item) => {
        return item.visible;
      });

      this.activeTab = this.tabMap.find((item) => {
        return item.key === activeTabKey;
      });

      this.dataTypeMap = LactationPerformanceMaps.dataType();
      this.intervalMap = LactationPerformanceMaps.interval();
      this.chartConfigMap = LactationPerformanceMaps.chartConfig(this.isDhiLinkage, this.hasDhiDates);

      this.generateSelectChartMap();

      if (this.$stateParams.selectChart) {
        this.stateParamsConfig();
      } else {
        const selectChartKey = this.selectChartMap[this.activeTab.key][0].key;

        LactationPerformanceDefaultConfig.init(this, selectChartKey);
      }

      this.$timeout(() => {
        this.drawChart();
      });
    });
  }

  showChartSettingDataType() {
    if (!this.activeChartConfigMap) return;

    if (this.isDhiLinkage) {
      return this.hasDhiDates && this.activeChartConfigMap.visible.dataType;
    }

    return this.activeChartConfigMap.visible.dataType;
  }

  showChartSettingThreshold() {
    if (!this.activeChartConfigMap) return;

    return this.activeChartConfigMap.visible.threshold;
  }

  showChartSettingSelectReferenceDate() {
    if (!this.activeChartConfigMap) return;

    if (this.isDhiLinkage) {
      return this.setting.dataType.key === 'dhi' &&
        typeof this.activeChartConfigMap.visible.selectReferenceDate !== 'undefined';
    }

    return this.activeChartConfigMap.visible.selectReferenceDate;
  }

  showChartSettingInputReferenceDate() {
    if (!this.activeChartConfigMap) return;

    if (this.isDhiLinkage) {
      return this.setting.dataType.key === 'milking' &&
        typeof this.activeChartConfigMap.visible.inputReferenceDate !== 'undefined';
    }

    return this.activeChartConfigMap.visible.inputReferenceDate;
  }

  showChartSettingSelectRangeDate() {
    if (!this.activeChartConfigMap) return;

    if (this.isDhiLinkage) {
      return this.setting.dataType.key === 'dhi' &&
        typeof this.activeChartConfigMap.visible.selectRangeDate !== 'undefined';
    }

    return this.activeChartConfigMap.visible.selectRangeDate;
  }

  showChartSettingInputRangeDate() {
    if (!this.activeChartConfigMap) return;

    if (this.isDhiLinkage) {
      return this.setting.dataType.key === 'milking' &&
        typeof this.activeChartConfigMap.visible.inputRangeDate !== 'undefined';
    }

    return this.activeChartConfigMap.visible.inputRangeDate;
  }

  showChartSettingInterval() {
    if (!this.activeChartConfigMap) return;

    if (this.isDhiLinkage) {
      return this.setting.dataType.key === 'milking' &&
        typeof this.activeChartConfigMap.visible.interval !== 'undefined';
    }

    return this.activeChartConfigMap.visible.interval;
  }

  showChartSettingRangeAxis(hasData) {
    if (hasData) {
      if (typeof this.hasData === 'undefined') return;

      return this.hasData && this.activeChartConfigMap.visible.rangeAxis;
    }

    if (!this.activeChartConfigMap) return;

    return this.activeChartConfigMap.visible.rangeAxis;
  }

  changeTab(tab) {
    if (this.activeTab.key === tab) return;

    this.activeTab = this.tabMap.find((item) => {
      return item.key === tab;
    });

    const selectChartKey = this.selectChartMap[this.activeTab.key][0].key;

    LactationPerformanceDefaultConfig.init(this, selectChartKey);
    this.drawChart();
  }

  changeSelectChart() {
    const selectChartKey = this.setting.selectChart;

    LactationPerformanceDefaultConfig.init(this, selectChartKey);
    this.drawChart();
  }

  changeDataType(dataType) {
    if (this.setting.dataType === dataType) return;

    this.setting.dataType = this.dataTypeMap.find((item) => {
      return item.key === dataType;
    });

    this.invalidDate = {};

    if (this.showChartSettingThreshold()) {
      this.setting.threshold = this.activeChartConfigMap.defaultSetting.threshold;
    }

    if (this.showChartSettingSelectReferenceDate()) {
      LactationPerformanceDefaultConfig.selectReferenceDate(this);
    }

    if (this.showChartSettingInputReferenceDate()) {
      LactationPerformanceDefaultConfig.inputReferenceDate(this);
    }

    if (this.showChartSettingSelectRangeDate()) {
      LactationPerformanceDefaultConfig.selectRangeDate(this, false, 'start');
      LactationPerformanceDefaultConfig.selectRangeDate(this, false, 'end');
    }

    if (this.showChartSettingInputRangeDate()) {
      LactationPerformanceDefaultConfig.inputRangeDate(this);
    }

    if (this.showChartSettingInterval()) {
      LactationPerformanceDefaultConfig.interval(this);
    }

    if (this.showChartSettingRangeAxis()) {
      LactationPerformanceDefaultConfig.rangeAxis(this);
    }

    this.drawChart();
  }

  changeThreshold() {
    this.setting.threshold = StringUtil.toHalfWidthCharacters(this.setting.threshold);
  }

  changeSelectReferenceDateYear() {
    LactationPerformanceDefaultConfig.selectReferenceDate(this, true);
  }

  changeInputReferenceDate() {
    const date = this.setting.referenceDate.date;

    this.invalidDate.referenceDate.date = !(DateUtil.isValidDate(date) && DateUtil.includedPermittedPeriod(date));
  }

  changeSelectRangeDateYear(field) {
    LactationPerformanceDefaultConfig.selectRangeDate(this, true, field);
  }

  changeRangeDate(field) {
    const fields = ['start', 'end'];
    const value = this.setting.rangeDate[field].date;

    let opposite = {};
    opposite.field = fields.find((item) => {
      return item !== field;
    });
    opposite.value = this.setting.rangeDate[opposite.field].date;

    const diff = opposite.value - value;

    this.invalidDate.rangeDate = {};

    if ((field === 'start' && diff < 0) || (field === 'end' && diff > 0)) {
      this.invalidDate.rangeDate[field] = true;
    } else {
      this.invalidDate.rangeDate[field] = !(DateUtil.isValidDate(value) && DateUtil.includedPermittedPeriod(value));
    }
  }

  changeConfigInterval(interval) {
    if (this.setting.interval.key === interval) return;

    this.setting.interval = this.intervalMap.find((item) => {
      return item.key === interval;
    });
  }

  changeRangeAxis(direction, scale) {
    const value = this.setting.rangeAxis[direction][scale];

    if (typeof value === 'undefined') return;

    this.chart.axis[scale]({
      [direction]: this.getAxisValue(direction, scale)
    });
  }

  clearReferenceDate() {
    this.setting.referenceDate.date = '';
    this.invalidDate.referenceDate.date = true;
  }

  clearRangeDate(field) {
    this.setting.rangeDate[field].date = '';
    this.invalidDate.rangeDate[field] = true;
  }

  clickDrawChart() {
    LactationPerformanceDefaultConfig.rangeAxis(this);
    this.drawChart();
  }

  disabledDrawChart() {
    if (!this.setting) return true;

    let invalidDate;

    Object.entries(this.invalidDate).forEach(([field, object]) => {
      invalidDate = Object.entries(object).some(([key, value]) => {
        return value;
      });
    });

    return invalidDate || (this.showChartSettingThreshold() && !StringUtil.isDigit(this.setting.threshold));
  }

  classTab(key) {
    return this.activeTab.key === key ? 'contents__tabItem--active' : 'contents__tabItem';
  }

  classDataType(dataType) {
    return this.setting.dataType && (this.setting.dataType.key === dataType) ?
      'contents__buttonGroupItem--active' : 'contents__buttonGroupItem';
  }

  classConfigInterval(interval) {
    return this.setting.interval && (this.setting.interval.key === interval) ?
      'contents__buttonGroupItem--active' : 'contents__buttonGroupItem';
  }

  generateSelectDhiDatesMap() {
    return this.DhiAPI.dhiDates().then((res) => {
      const dhiDates = res.data.dhiDates;

      this.selectDhiDatesMap = {
        find: {}
      };

      this.selectDhiDatesMap.items = dhiDates.map((dhiDate) => {
        return {
          origin: dhiDate,
          year: Number(DateUtil.format(dhiDate, 'YYYY')),
          date: DateUtil.toMMDD(dhiDate)
        };
      }).sort((a, b) => {
        return a.origin - b.origin;
      });

      this.selectDhiDatesMap.years = dhiDates.map((dhiDate) => {
        return Number(DateUtil.format(dhiDate, 'YYYY'));
      }).filter((item, index, array) => {
        return array.indexOf(item) === index;
      }).sort((a, b) => {
        return a - b;
      });

      this.selectDhiDatesMap.dates = dhiDates.map((dhiDate) => {
        return dhiDate;
      }).sort((a, b) => {
        return a - b;
      });
    });
  }

  generateSelectChartMap() {
    this.selectChartMap = {};

    Object.entries(this.chartConfigMap).forEach(([tab, items]) => {
      this.selectChartMap[tab] = items.filter((item) => {
        return !item.chart.hidden;
      }).map((item) => {
        return {
          key: `${item.chart.api}_${item.chart.item}`,
          label: item.chart.label
        };
      });
    });
  }

  stateParamsConfig() {
    this.invalidDate = {};
    this.setting = {
      selectChart: this.$stateParams.selectChart
    };

    this.activeChartConfigMap = this.chartConfigMap[this.activeTab.key].find((item) => {
      return this.setting.selectChart === `${item.chart.api}_${item.chart.item}`;
    });

    this.setting.dataType = this.dataTypeMap.find((item) => {
      return item.key === this.$stateParams.dataType.key;
    });

    if (this.showChartSettingSelectReferenceDate() || this.showChartSettingInputReferenceDate()) {
      this.setting.referenceDate = this.$stateParams.referenceDate;
    }

    if (this.showChartSettingInterval()) {
      this.setting.interval = this.intervalMap.find((item) => {
        return item.key === this.$stateParams.interval;
      });
    }

    if (this.showChartSettingRangeAxis()) {
      LactationPerformanceDefaultConfig.rangeAxis(this);
    }
  }

  filterSelectDhiDatesMapByYear(year) {
    return this.selectDhiDatesMap.items.filter((item) => {
      return item.year === year;
    }).map((item) => {
      return item.origin;
    });
  }

  findDhiDatesMap(key, value) {
    if (key in this.selectDhiDatesMap.find && value in this.selectDhiDatesMap.find[key]) {
      return this.selectDhiDatesMap.find[key][value];
    }

    switch (key) {
    case 'ago':
      const dates = this.selectDhiDatesMap.dates;
      const length = dates.length;
      const index = length > value ? length - value - 1 : 0;
      const result = {
        year: Number(DateUtil.format(dates[index], 'YYYY')),
        date: dates[index]
      };

      if (key in this.selectDhiDatesMap.find === false) {
        this.selectDhiDatesMap.find[key] = {};
      }

      this.selectDhiDatesMap.find.ago[value] = result;

      return result;
    }
  }

  drawChart() {
    this.isDrawChart = true;

    this.$timeout(() => {
      this.isDrawChart = false;
    });
  }

  getAxisValue(direction, scale) {
    const scales = ['min', 'max'];
    const value = this.setting.rangeAxis[direction][scale];

    let opposite = {};
    opposite.scale = scales.find((item) => {
      return item !== scale;
    });
    opposite.value = this.setting.rangeAxis[direction][opposite.scale];

    if (typeof opposite.value === 'undefined' || opposite.value === null) {
      if (opposite.scale === 'max') {
        opposite.value = this.activeChartConfigMap.axis[direction].maxValue ||
          this.activeChartConfigMap.axis[direction][opposite.scale];
      } else {
        opposite.value = this.activeChartConfigMap.axis[direction][opposite.scale];
      }
    }

    const diff = opposite.value - value;

    let result;

    if (value === null) {
      if (direction === 'x' && scale === 'max') {
        result = this.activeChartConfigMap.axis[direction].maxValue + 3;
      } else {
        result = this.activeChartConfigMap.axis[direction][scale];
      }
    } else if ((typeof opposite.value === 'undefined' || opposite.value === null) ||
      (scale === 'min' && diff > 0) ||
      (scale === 'max' && diff < 0)) {
      result = Number(value);
    } else {
      result = this.setting.rangeAxis.prev[direction][scale];
    }

    if (!this.setting.rangeAxis.prev) {
      this.setting.rangeAxis.prev = {
        x: {},
        y: {}
      };
    }

    this.setting.rangeAxis.prev[direction][scale] = result;

    return result;
  }
}

app.controller('LactationPerformanceController', LactationPerformanceController);
