class EnvironmentHumidityChartController {
  constructor(
  ) {
    'ngInject';
  }

  $onChanges(changesObj) {
    if (changesObj.chartData && changesObj.chartData.currentValue) {
      this.showChart();
    }
  }

  $onDestroy() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  existsChartData() {
    return (this.chartData && this.chartData.length) ? true : false;
  }

  // private

  initialOption() {
    return {
      bindto: '#environment-humidity-chart',
      padding: {
        right: 20,
        bottom: 30
      },
      size: {
        height: 200,
      },
      data: {
        type: 'line',
        x: 'date',
        keys: {
          x: 'date'
        }
      },
      axis: {
        y: {
          label: {
            text: '湿度(％)',
            position: 'outer-top'
          },
          max: 100,
          min: 0,
          padding: 0
        },
        x: {
          label: {
            text: '日時',
            position: 'outer-right'
          },
          type: 'timeseries',
          tick: {
            fit: true
          }
        }
      },
      point: {
        show: false
      }
    };
  }

  generateOption() {
    const option = Object.assign({}, this.initialOption(), {});
    let colums = Object.keys(this.chartData[0]).filter((c) => c !== 'date');
    option.data.json = this.chartData;
    option.data.keys.value = colums;

    if (this.unit === 'days') {
      option.axis.x.label.text = '日付';
      option.axis.x.tick.format = '%m/%d';
    } else {
      option.axis.x.label.text = '時刻';
      option.axis.x.tick.format = '%H';
    }
    return option;
  }

  showChart() {
    if (!this.existsChartData()) return;

    const option = this.generateOption();

    if (this.chart) this.chart.destroy();
    this.chart = c3.generate(option);
  }
}

function environmentHumidityChartComponent() {
  'ngInject';
  return {
    controller: EnvironmentHumidityChartController,
    templateUrl: 'menu/environment/components/humidity-chart/index.html',
    bindings: {
      chartData: '<',
      unit: '<'
    }
  };
}

app.component('environmentHumidityChart', environmentHumidityChartComponent());
