app.controller('CowGroupListController', function(
  $modal,
  $q,
  $scope,
  FarmService,
  CowGroupService,
  $rootScope
) {
  'ngInject';

  let farm;

  // 起動。
  onInit();

  function onInit() {
    $q.all([
      FarmService.show(),
      CowGroupService.summary()
    ]).then((res) => {
      farm = new Farm(res[0]);
      getAllGroupInfo(res[1].data);
    }).catch((err) => console.error(err));
  }

  /**
   * 牛群の要らないpropを整理する
   * @param {Array} groups
   */
  function getAllGroupInfo(groups) {
    $scope.groupList = groups.map((group) => {
      const attributeKey = CowGroupService.getAttributeKey(group, farm.isMilk());
      return {
        cowshedId: group.cowshedId,
        cowshedName: group.cowshedName,
        cowCount: group.cowCount || 0,
        cowGroupId: group.cowGroupId,
        cowGroupName: group.cowGroupName,
        groupAttr: attributeKey,
      };
    });
  }
});
