class MobileAlertDysstasiaSortModalController {
  constructor(
    $modalInstance,
    params
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
    this.sortColumns = [
      {value: 'combinedGroupName', label: '牛群'},
      {value: 'alertAt', label: '検知時刻'}
    ];
    this.sortOrders = [
      {value: 'asc', label: '昇順'},
      {value: 'desc', label: '降順'}
    ];
    this.sortColumn = params.column;
    this.sortOrder = params.order;
  }

  onCancelClick() {
    this.$modalInstance.dismiss('cancel');
  }

  onSubmitClick() {
    const result = {
      column: this.sortColumn,
      order: this.sortOrder,
    };
    this.$modalInstance.close(result);
  }
}

app.controller('MobileAlertDysstasiaSortModalController', MobileAlertDysstasiaSortModalController);
