function DisplayOtherDisease() {
  'ngInject';

  function displayDisease(otherDiseaseNames, otherDiseaseName, difficultiesOther) {
    if (otherDiseaseNames && !difficultiesOther) {
      const s = otherDiseaseNames.map((e) => e.name).join('、');
      return `
        <p>
          <span>その他症状：</span>
          <span>${s}</span>
        </p>
      `;
    }

    if (!otherDiseaseName) return '';

    if (!difficultiesOther) return `
      <p>
        <span>その他症状：</span>
        <span>${otherDiseaseName}</span>
      </p>
    `;

    return `
      <p>
        <span>${otherDiseaseName}：</span>
        <span>${difficultiesOther}</span>
      </p>
    `;
  }

  return {
    restrict: 'E',
    scope: {
      otherDiseaseNames: '<',
      otherDiseaseName: '<',
      difficultiesOther: '<'
    },
    template: `<p ng-bind-html="displayDisease(otherDiseaseNames, otherDiseaseName, difficultiesOther)"></p>`,
    link: function(scope, element, attrs) {
      scope.displayDisease = displayDisease;
    }
  };
}

app.directive('displayOtherDisease', DisplayOtherDisease);
