class FatteningConfigEditController {
  constructor(
    $modalInstance,
    $modal,
    blockUI,
    params,
    FatteningStageService
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.$modal = $modal;
    this.blockUI = blockUI;

    this.FatteningStageService = FatteningStageService;
    this.stages = params.stages;
    this.fatteningStageBaseDate = params.fatteningStageBaseDate;
    this.templateUrl = params.templateUrl;

    this.invalid = false;
    this.isEditing = true;
  }

  add() {
    this.stages.push({'name': '', 'startMonthsOfAge': '', 'endMonthsOfAge': ''});
  }

  remove(index) {
    this.stages.splice(index, 1);
  }

  save() {
    const formatErrorMessage = (messages) => {
      return messages.map((message) => message.message).join('\n');
    };

    const params = {
      'fatteningStageBaseDate': this.fatteningStageBaseDate,
      'stages': this.stages
    };

    this.blockUI.start('更新中');
    this.FatteningStageService.update(params)
      .then((res) => {
        this.blockUI.stop();
        this.$modalInstance.close({fatteningStageBaseDate: this.fatteningStageBaseDate});
      }).catch((res) => {
        this.blockUI.stop();
        this.message = formatErrorMessage(res.data.messages);
      });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('FatteningConfigEditController', FatteningConfigEditController);
