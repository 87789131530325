class AlertCalvingSensorDroppedAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  unsolved() {
    return this.APIService.get('/alert/calving_sensor_dropped/unsolved');
  }

  update(id, params) {
    return this.APIService.put(`/alert/calving_sensor_dropped/${id}`, params);
  }
}

app.service('AlertCalvingSensorDroppedAPI', AlertCalvingSensorDroppedAPI);
