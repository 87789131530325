class MilkingController {
  constructor(
    $rootScope,
    DateUtilService,
    DateTimeUtilFactory,
    MilkingService
  ) {
    'ngInject';
    this.DateUtilService = DateUtilService;
    this.DateTimeUtilFactory = DateTimeUtilFactory;
    this.MilkingService = MilkingService;

    this.init();
  }

  init() {
    let now = this.DateTimeUtilFactory.parseClientDate(new Date());
    this.milkingChart = new MilkChart('#total_chart');
    this.currentYear = now.getFullYear();
    this.currentMonth = (now.getMonth() + 1);
    // 乳量グラフ用
    this.getMilkings();
    // 今日の合計
    this.getTotalMilkingToday();
    // 昨日の合計
    this.getTotalMilkingYesterday();
    // 今月の合計
    this.getTotalMilkingMonth();
    // 先月の合計
    this.getTotalMilkingLastMonth();
    // 前年当月
    this.getTotalMilkingLastYearMonth();
  }

  /**
   * 前月の搾乳量を表示する
   */
  prevMilkingMonthly() {
    this.getMilkings(MilkingController.NAVIGATION.PREVIOUS);
  }

  /**
   * 次月の搾乳量を表示する
   */
  nextMilkingMonthly() {
    this.getMilkings(MilkingController.NAVIGATION.NEXT);
  }

  /**
   * 搾乳量を取得する
   * navigationが指定されている場合は前月又は次月の搾乳量を取得する
   *
   * @param {number} navigation see the MilkingController.NAVIGATION
   */
  getMilkings(navigation) {
    const date = new Date(this.currentYear, Number(this.currentMonth) + (navigation || 0), 0);
    this.currentYear = date.getFullYear();
    this.currentMonth = ('0' + ( date.getMonth() + 1 )).slice(-2);

    const startDate = this.DateUtilService.toW3CFormat(this.DateUtilService.startOfMonth(date));
    const endDate = this.DateUtilService.toW3CFormat(this.DateUtilService.endOfMonth(date));

    this.MilkingService.getMilkingDaily(startDate, endDate).then((res) => {
      this.milkingChart.showCustomChart(...this.createChartData(res.data));
    });
  }

  /**
   * 搾乳量チャートデータを作成する
   *
   * @param {Array} milkings 搾乳量配列
   * @return {Array} [搾乳量チャートデータ, 搾乳頭数]
   */
  createChartData(milkings) {
    const chartData = {x: [], '乳量': []};
    const count = [];
    const data = this.createDummyData();

    milkings.filter((r) => r.milkingOrder === 0).forEach((r) => {
      const index = data.findIndex((d) => d.date === r.date);
      if (index === -1) return;
      data[index] = r;
    });

    data.forEach((result) => {
      chartData['x'].push(this.DateUtilService.toW3CFormat(result.date));
      chartData['乳量'].push(result.amount);
      count.push(result.cowCount);
    });

    return [chartData, count];
  }

  /**
   * 本日の搾乳量合計を取得する
   */
  getTotalMilkingToday() {
    this.totalMilkingToday = 0;
    const date = this.DateUtilService.toW3CFormat(new Date());

    this.MilkingService.getMilkingDaily(date).then((res) => {
      if (!res.data[0]) return;
      this.totalMilkingToday = (Math.round(Number(res.data[0].amount))).toLocaleString();
    });
  }

  /**
   * 本日の搾乳量合計を取得する
   */
  getTotalMilkingYesterday() {
    this.totalMilkingYesterday = 0;
    let yesterday = this.DateTimeUtilFactory.parseClientDate(new Date());
    const date = this.DateUtilService.toW3CFormat(yesterday.setDate(yesterday.getDate() - 1));

    this.MilkingService.getMilkingDaily(date).then((res) => {
      if (!res.data[0]) return;
      this.totalMilkingYesterday = (Math.round(Number(res.data[0].amount))).toLocaleString();
    });
  }

  /**
   * 今月の搾乳量合計を取得する
   */
  getTotalMilkingMonth() {
    this.totalMilkingMonth = 0;
    this.MilkingService.getMilkingMonthly().then((res) => {
      if (!res.data[0]) return;
      this.totalMilkingMonth = (Math.round(Number(res.data[0].amount))).toLocaleString();
    });
  }

  /**
   * 先月の搾乳量合計を取得する
   */
  getTotalMilkingLastMonth() {
    this.totalMilkingLastMonth = 0;
    const startOfThisMonth = this.DateUtilService.startOfMonth(this.DateUtilService.today());
    const startOfLastMonth = this.DateUtilService.addMonths(startOfThisMonth, -1);
    const month = String(startOfLastMonth.getFullYear()) + String(( '0' + ( startOfLastMonth.getMonth() + 1 ) ).slice(-2));
    this.MilkingService.getMilkingMonthly(month).then((res) => {
      if (!res.data[0]) return;
      this.totalMilkingLastMonth = (Math.round(Number(res.data[0].amount))).toLocaleString();
    });
  }

  /**
   * 去年の当月の搾乳量合計を取得する
   */
  getTotalMilkingLastYearMonth() {
    this.totalMilkingLastYearMonth = 0;
    const startOfThisMonth = this.DateUtilService.startOfMonth(this.DateUtilService.today());
    const startOflastYearMonth = this.DateUtilService.addMonths(startOfThisMonth, -12);
    const month = String(startOflastYearMonth.getFullYear()) + String(( '0' + ( startOflastYearMonth.getMonth() + 1 ) ).slice(-2));
    this.MilkingService.getMilkingMonthly(month).then((res) => {
      if (!res.data[0]) return;
      this.totalMilkingLastYearMonth = (Math.round(Number(res.data[0].amount))).toLocaleString();
    });
  }

  /**
   * ダミーデータを作成する
   *
   * @return {Array} [{ date: Date, amount: 0, cowCount: 0, milkingOrder: 0 }]
   */
  createDummyData() {
    const date = new Date(this.currentYear, Number(this.currentMonth), 0);
    const start = this.DateUtilService.startOfMonth(date);
    const end = this.DateUtilService.endOfMonth(date);
    const dummyData = [];
    for (let d = start; d < end; d.setDate(d.getDate() + 1)) {
      const obj = {
        date: d.getTime(),
        amount: 0,
        cowCount: 0,
        milkingOrder: 0
      };
      dummyData.push(obj);
    }
    return dummyData;
  }
}

MilkingController.NAVIGATION = {
  PREVIOUS: -1,
  NEXT: 1
};

app.controller('MilkingController', MilkingController);
