function rightBoardFixed($window, $timeout) {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      const setStyle = () => {
        if (!document.querySelector('.uBoard')) return;

        const top = Math.floor(document.querySelector('.uBoard').getBoundingClientRect().top);
        const height = document.querySelector('.uHeader').clientHeight;

        if (top <= height) {
          element.css({
            position: 'fixed',
            top: `${height}px`,
            height: `calc(100vh - ${height}px)`
          });
        } else {
          element.css({
            position: '',
            top: '',
            height: `calc(100vh - ${top}px)`
          });
        }
      };

      angular.element($window).bind('resize', setStyle);
      angular.element($window).bind('scroll', setStyle);

      scope.$watch(attrs.notificationHidden, () => {
        setStyle();
      });

      $timeout(() => {
        setStyle();
      }, 500);

      scope.$on('$destroy', () => {
        angular.element($window).unbind('resize', setStyle);
        angular.element($window).unbind('scroll', setStyle);
      });
    }
  };
}

app.directive('rightBoardFixed', ['$window', '$timeout', rightBoardFixed]);
