class AlertCalvingSensorDroppedSolutionSelctionController {
  constructor(
    $modalInstance,
    params,
    StandardDialog
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
    this.StandardDialog = StandardDialog;
    this.calvingSensorNumber = params.calvingSensorNumber;
    this.title = `分娩センサー番号: ${this.calvingSensorNumber}`;
    this.solution = params.solution;
    this.validate();
  }

  validate() {
    this.invalid = this.solution === null;
  }

  cancel() {
    this.$modalInstance.close();
  }

  select() {
    if (['ignore', 'reequip'].includes(this.solution)) {
      const solution = this.solution === 'ignore' ? '問題なし' : '再装着';
      this.StandardDialog.showYesNoConfirm({
        title: '処置内容の登録',
        text1: `処置内容を「${solution}」で登録します。`,
        text2: 'よろしいでしょうか？',
        yes: 'OK',
        no: 'キャンセル'
      }).result.then((modalResult) => {
        this.$modalInstance.close(this.solution);
      });
    } else {
      this.$modalInstance.close(this.solution);
    }
  }
}

app.controller('AlertCalvingSensorDroppedSolutionSelctionController',
  AlertCalvingSensorDroppedSolutionSelctionController);
