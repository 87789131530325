class HomeAlertController {
  constructor(
    AlertAPI,
    SessionCache,
    UnexpectedDysstasiaInputDialog
  ) {
    'ngInject';

    const farm = SessionCache.farm();

    this.AlertAPI = AlertAPI;
    this.UnexpectedDysstasiaInputDialog = UnexpectedDysstasiaInputDialog;

    this.init(farm);
  }

  init(farm) {
    this.isBeef = farm.isBeef();
    this.useAlertDysstasia = farm.useAlertDysstasia();
    this.useAlertCalving = farm.useAlertCalving();
    this.showAlertHeat = farm.isDairy() || farm.isBreeding();
    this.index();
  }

  index() {
    this.AlertAPI.summary().then((res) => {
      this.summary = res.data.reduce((acc, cur) => {
        const key = cur.alertType.toLowerCase();

        acc[key] = cur.count;

        return acc;
      }, {});
    });
  }

  inputUnexpectedDysstasia() {
    this.UnexpectedDysstasiaInputDialog.create();
  }
}

function homeAlert() {
  return {
    templateUrl: 'menu/home/alert/index.html',
    controller: HomeAlertController,
    controllerAs: 'ctrl'
  };
}

app.component('homeAlert', homeAlert());
