class ChangePasswordController {
  constructor(
    $rootScope,
    $state,
    $stateParams,
    $timeout,
    $modal,
    blockUI,
    SessionStateService,
    UtilService
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$modal = $modal;
    this.blockUI = blockUI;
    this.SessionStateService = SessionStateService;
    this.UtilService = UtilService;

    this.email = $stateParams.email || null;
    this.invalid = true;
    this.errorMessage = null;

    this.onInit();
  }

  onInit() {
    if (this.email) this.validate();
  }

  goToLogin() {
    this.$state.go('login');
  }

  ok() {
    this.blockUI.start('処理中');
    this.SessionStateService.sendChangePasswordEmail(this.email)
      .then((res) => {
        this.blockUI.stop();
        this.blockUI.start('パスワード変更のためのメールを送信しました。');
        this.$timeout(() => {
          this.blockUI.stop();
          this.$state.go('login');
        }, 1000);
      })
      .catch((error) => {
        this.blockUI.stop();
        this.errorMessage = error.data ? this.UtilService.formatErrorMessage(error.data.messages) : 'エラーが生じました。';
      });
  }

  validate() {
    if (!this.email) {
      this.invalid = true;
    } else {
      this.invalid = false;
    }
  }

  isFieldError() {
    if (this.errorMessage) return 'is-alert';
  }

}

app.controller('ChangePasswordController', ChangePasswordController);
