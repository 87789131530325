// eslint-disable-next-line no-unused-vars
class CowEventClipboardValidator {
  validate(legacyEventType, rows, masters) {
    const errors = [];
    rows.forEach((row) => {
      if (row.moveToCowGroupId && row.moveToPen) {
        const group = masters.cowGroups.find((group) => group.cowGroupId === row.moveToCowGroupId);
        if (!group.pens.includes(row.moveToPen)) {
          const error = {
            valid: false, lineNo: row.lineNo,
            type: CowEventValidator.ERROR_TYPE_INVALID,
            field: 'moveToPen', message: '存在しない牛房です',
          };
          errors.push(error);
        }
      }

      switch (legacyEventType) {
      case 'gyugunidou': {
        // 担当者
        if (row.workerName) {
          if (!masters.workerNames.includes(row.workerName)) {
            const error = {
              valid: false, lineNo: row.lineNo,
              type: CowEventValidator.ERROR_TYPE_INVALID,
              field: 'workerName', message: '存在しない担当者です',
            };
            errors.push(error);
          }
        }

        if (!row.moveToCowGroupId) {
          if (row.moveToCowGroupName) {
            const error = {
              valid: false, lineNo: row.lineNo,
              type: CowEventValidator.ERROR_TYPE_INVALID,
              field: 'moveToCowGroupId', message: '存在しない牛群です',
            };
            errors.push(error);
          } else {
            const error = {
              valid: false, lineNo: row.lineNo,
              type: CowEventValidator.ERROR_TYPE_EMPTY,
              field: 'moveToCowGroupId', message: '必須項目です',
            };
            errors.push(error);
          }
        }
        return errors;
      }
      case 'tanetsuke': {
        // 担当者
        if (row.workerName) {
          if (!masters.inseminators.includes(row.workerName)) {
            const error = {
              valid: false, lineNo: row.lineNo,
              type: CowEventValidator.ERROR_TYPE_INVALID,
              field: 'workerName', message: '存在しない担当者です',
            };
            errors.push(error);
          }
        }

        // その他処置
        if (row.otherReproductionWork) {
          if (!masters.otherReproductionWorks.includes(row.otherReproductionWork)) {
            const error = {
              valid: false, lineNo: row.lineNo,
              type: CowEventValidator.ERROR_TYPE_INVALID,
              field: 'otherReproductionWork', message: '存在しない処置です',
            };
            errors.push(error);
          }
        }

        // 授精コード
        if (row.inseminationCode) {
          if (!masters.inseminationCodes.includes(row.inseminationCode)) {
            const error = {
              valid: false, lineNo: row.lineNo,
              type: CowEventValidator.ERROR_TYPE_INVALID,
              field: 'inseminationCode', message: '存在しない授精コードです',
            };
            errors.push(error);
          }
        }

        if (!row.tanetsukeMethod) {
          const error = {
            valid: false, lineNo: row.lineNo,
            type: CowEventValidator.ERROR_TYPE_EMPTY,
            field: 'tanetsukeMethod', message: '必須項目です',
          };
          errors.push(error);
          return errors;
        }

        const validTanetsukeMethod = ['人工授精', '移植'].includes(row.tanetsukeMethod);
        if (!validTanetsukeMethod) {
          const error = {
            valid: false, lineNo: row.lineNo,
            type: CowEventValidator.ERROR_TYPE_INVALID,
            field: 'tanetsukeMethod', message: '無効な値です',
          };
          errors.push(error);
          return errors;
        }

        if (row.tanetsukeMethod === '人工授精') {
          // 精液
          if (row.masterSpermNo && !row.masterSpermId) {
            const error = {
              valid: false, lineNo: row.lineNo,
              type: CowEventValidator.ERROR_TYPE_INVALID,
              field: 'masterSpermNo', message: '存在しない精液です',
            };
            errors.push(error);
          }
        }

        if (row.tanetsukeMethod === '移植') {
          if (row.masterEmbryoName && !row.masterEmbryoId) {
            const error = {
              valid: false, lineNo: row.lineNo,
              type: CowEventValidator.ERROR_TYPE_INVALID,
              field: 'masterEmbryoName', message: '存在しない受精卵です',
            };
            errors.push(error);
          }
        }

        if (row.cowState === '繁殖除外') {
          const error = {
            valid: false, lineNo: row.lineNo,
            type: 'warning',
            field: 'state', message: 'この牛は繁殖除外です',
          };
          errors.push(error);
        }

        return errors;
      }
      case 'ninshinkantei': {
        CollectionUtil.mergeList(
          errors, this.validatePregnantDiagnosis(row, masters)
        );
        return errors;
      }
      }
    });

    return errors;
  }

  // 妊娠鑑定
  validatePregnantDiagnosis(row, masters) {
    const errors = [];

    // 鑑定結果
    if (!row.ninshinkanteiResult) {
      const error = {
        valid: false, lineNo: row.lineNo,
        type: CowEventValidator.ERROR_TYPE_EMPTY,
        field: 'ninshinkanteiResult', message: '必須項目です',
      };
      errors.push(error);
    } else {
      const options = ['受胎', '双子受胎', '未受胎', '不明'];
      if (!options.includes(row.ninshinkanteiResult)) {
        const error = {
          valid: false, lineNo: row.lineNo,
          type: CowEventValidator.ERROR_TYPE_INVALID,
          field: 'ninshinkanteiResult', message: '無効な値です',
        };
        errors.push(error);
      }
    }

    //タイミング
    if (row.judgePregnantTiming) {
      if (!masters.judgePregnantTimings.includes(row.judgePregnantTiming)) {
        const error = {
          valid: false, lineNo: row.lineNo,
          type: CowEventValidator.ERROR_TYPE_INVALID,
          field: 'judgePregnantTiming', message: '存在しないタイミングです',
        };
        errors.push(error);
      }
    }

    // その他処置
    CollectionUtil.mergeList(
      errors, this.validateOtherReproductionWork(row, masters.otherReproductionWorks)
    );

    // 担当者
    CollectionUtil.mergeList(
      errors, this.validateWorkers(row, masters.pregnancyAppraisers)
    );

    return errors;
  }

  validateWorkers(row, workers) {
    if (row.workerName) {
      if (!workers.includes(row.workerName)) {
        const error = {
          valid: false, lineNo: row.lineNo,
          type: CowEventValidator.ERROR_TYPE_INVALID,
          field: 'workerName', message: '存在しない担当者です',
        };
        return [error];
      }
    }

    return [];
  }

  validateOtherReproductionWork(row, works) {
    if (row.otherReproductionWork) {
      if (!works.includes(row.otherReproductionWork)) {
        const error = {
          valid: false, lineNo: row.lineNo,
          type: CowEventValidator.ERROR_TYPE_INVALID,
          field: 'otherReproductionWork', message: '存在しない処置です',
        };
        return [error];
      }
    }

    return [];
  }
}
