class FatteningReportController {
  static get filterItemIds() {
    return [
      'purchasePrice',
      'purchasePriceTotal',
      'purchasePriceOfShipments',
      'salesPrice',
      'salesPriceTotal',
      'grossProfit',
      'grossProfitTotal',
      'grossProfitRate',
      'priceIncreaseRate',
      'carcassUnitPrice',
    ];
  }

  constructor(
    $rootScope,
    FatteningReportAPI,
    DepositFatteningReportAPI,
    FatteningReportConfigAPI,
    FatteningReportConfigDialog,
    FatteningReportItemDialog,
    SessionCache,
    blockUI,
    $timeout
  ) {
    'ngInject';

    this.FatteningReportConfigAPI = FatteningReportConfigAPI;
    this.FatteningReportConfigDialog = FatteningReportConfigDialog;
    this.FatteningReportItemDialog = FatteningReportItemDialog;
    this.farm = SessionCache.farm();
    this.blockUI = blockUI;
    this.$timeout = $timeout;

    this.windowState = new WindowState();

    this.years = [];
    this.frequency = 'monthly';
    this.selectedReportType = 'standard';

    this.isDepositor = this.farm.isDepositor();
    this.isItemFilterApplied = SessionCache.account().isItemFilterApplied();

    this.reportApi = this.isDepositor ? DepositFatteningReportAPI : FatteningReportAPI;

    this.index();
  }

  index() {
    this.blockUI.start('データ取得中');

    this.reportApi.index().then((res) => {
      let itemDefinitions = res.data.summary.itemDefinitions;
      if (this.isItemFilterApplied) {
        const filterItemIds = FatteningReportController.filterItemIds;
        itemDefinitions = itemDefinitions.filter((defnition) => {
          const isFilterItem = filterItemIds.includes(defnition.itemId);
          return !isFilterItem;
        });
      }
      this.itemDefinitions = itemDefinitions;

      this.annualSummaries = res.data.summary.annualSummaries;
      this.agentFarmAnnualSummaries = res.data.summary.agentFarmAnnualSummaries;

      if (this.annualSummaries.length === 0) {
        this.noSummaryIndication = '集計結果が存在しません';
        this.confirmConfigCreated();
        return;
      }

      this.summaryExist = true;

      this.years = this.annualSummaries.map((summary) => {
        return {key: summary.fiscalYear, label: `${summary.fiscalYear}年`};
      });

      if (this.years.length > 0) {
        this.year = this.years[this.years.length - 1].key;
      }

      this.$timeout(() => this.windowState.resize());
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      this.blockUI.stop();
    });
  }

  confirmConfigCreated() {
    this.FatteningReportConfigAPI.show().then((res) => {
      return;
    }).catch((error) => {
      if (ErrorUtil.isRecordNotFound(error.data)) {
        this.noSummaryIndication = '集計設定を行ってください';
        this.onClickConfig();
      }
    });
  }

  onChangeYear(year) {
    this.year = year;
  }

  onChangeFrequency(frequency) {
    this.frequency = frequency;
  }

  onClickConfig() {
    this.FatteningReportConfigDialog.execute().then((result) => {
      this.noSummaryIndication = '集計結果が存在しません';
    });
  }

  onClickItemConfig() {
    this.FatteningReportItemDialog.execute().then((result) => {
      if (result.executed) {
        this.index();
      }
    });
  }

  isStandardReportSelected() {
    return this.selectedReportType === 'standard';
  }

  isFarmReportSelected() {
    return this.selectedReportType === 'farm';
  }

  isItemReportSelected() {
    return this.selectedReportType === 'item';
  }

  onSelectReportType(reportType) {
    this.selectedReportType = reportType;
  }
}

app.controller('FatteningReportController', FatteningReportController);
