class TimelineChartController {
  constructor(
    $q,
    $interval,
    MilkingService,
    PeriodicityService,
    CowEventsService,
    CowGroupService,
    DateUtilService
  ) {
    'ngInject';
    this.$q = $q;
    this.$interval = $interval;

    this.MilkingService = MilkingService;
    this.PeriodicityService = PeriodicityService;
    this.CowEventsService = CowEventsService;
    this.CowGroupService = CowGroupService;
    this.DateUtilService = DateUtilService;

    this.chartInterval = 200;
    this.currentTimestamp = new Date().getTime();

    this.timeLineChartConfig = {
      init: false,
      data: {columns: []},
      xGridLines: [],
      regions: [],
      axis: {min: 0, max: 365},
      labels: {},
      zoom: [0, 30],
    };
  }

  $onInit() {
    this.loading = false;

    if (this.isMilkFarm) {
      this.chartType = 'milk';
      this.chartTypes = [
        {label: '搾乳量', value: 'milk'},
        {label: '採食', value: 'feed'},
        {label: '反芻', value: 'rumination'},
        {label: '動態', value: 'move'},
        {label: '横臥', value: 'lie'},
        {label: '静止', value: 'stand'},
      ];
    } else {
      this.chartType = this.showFeed ? 'feed' : 'rumination';
      this.chartTypes = [
        {label: '採食', value: 'feed'},
        {label: '反芻', value: 'rumination'},
        {label: '動態', value: 'move'},
        {label: '横臥', value: 'lie'},
        {label: '静止', value: 'stand'},
      ];
    }

    this.prepTimelineChartData();
  }

  prepTimelineChartData() {
    this.loading = true;

    this.$q.all([
      this.createPeriodsData(this.createPeriodApiParams()),
      this.createEventsData(),
    ]).then((res) => {
      this.timeLineChartConfig.data.columns = res[0].columns;
      this.timeLineChartConfig.axis.max = res[0].maxAxis;
      this.timeLineChartConfig.labels = res[0].labels;
      this.timeLineChartConfig.zoom = res[0].zoomLevels;
      this.timeLineChartConfig.xGridLines = res[1].xGrids;
      this.timeLineChartConfig.regions = res[1].regions;
      this.timeLineChartConfig.init = true;
    }).finally(() => {
      this.loading = false;
    });
  }

  createPeriodApiParams() {
    /**
     * 開始日の条件: ミリ秒
     *  1. 産次数が1以上の場合、最新分娩日
     *  2. 導入日
     *  3. 誕生日
     *  4. 360日前
     */
    const startDate = !!(this.cow.birthNumber && this.cow.latestCalvingDate) ?
      Number(this.cow.latestCalvingDate) :
      Number(this.cow.introduceDate) ||
      Number(this.cow.birthday) ||
      moment().subtract(360, 'days').valueOf();

    /**
     * 終了日の条件: ミリ秒
     *  1. 状態がへい死・売却の場合、その日
     *  2. 開始日から本日まで30日ずつ足す日付
     */
    const endDate = ['出荷', 'へい死'].includes(this.cow.state) ?
      Number(this.cow.eliminateDate || this.cow.fallingDeadDate) :
      this.incrementDateByThirtyDays(startDate);

    this.periodsApiParams = {
      type: this.chartType,
      cowId: this.cow.cowId,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      milkingOrder: 0
    };

    return this.periodsApiParams;
  }

  changeChartSelection() {
    this.loading = true;

    this.createPeriodsData(this.updatePeriodApiParamsType()).then((res) => {
      this.timeLineChartConfig.data.columns = res.columns;
      this.timeLineChartConfig.axis.max = res.maxAxis;
      this.timeLineChartConfig.labels = res.labels;
      this.timeLineChartConfig.zoom = res.zoomLevels;
      this.timeLineChartConfig.init = true;
    }).finally(() => {
      this.loading = false;
    });
  }

  updatePeriodApiParamsType(chartType = this.chartType) {
    this.periodsApiParams.type = chartType;
    return this.periodsApiParams;
  }

  incrementDateByThirtyDays(dateToIncrement) {
    return dateToIncrement > this.currentTimestamp ?
      dateToIncrement :
      this.incrementDateByThirtyDays(dateToIncrement + 2592000000);
  }

  createEmptyPeriods(days) {
    const result = {};
    for (let i = 0; i < days; i++) {
      result[i] = null;
    }
    return result;
  }

  createRegionsData(data) {
    const startDate = moment(this.periodsApiParams.startDate, 'YYYY-MM-DD');
    const regions = data
      // 牛群移動イベントを抽出
      .filter((event) => event.eventType === 'gyugunidou')
      // regionの型に変更
      .reduce((arr, event) => {
        /* 牛群所属の終了期間は今イベントじゃ判断できないから、
         * startのみ先に追加して、endは次の処理で追加
         * 最後の行はendは空のまま終了して、下で追加する。
         */
        if (arr.length) {
          arr[arr.length - 1].end = moment(Number(event.occurredAt)).diff(startDate, 'days');
        }

        arr.push({
          start: moment(Number(event.occurredAt)).diff(startDate, 'days'),
          end: '',
        });

        return arr;
      }, []);

    // 最古のregionは最初の牛群移動。誕生したから初めて所属した牛群を追加する
    regions.unshift({
      start: 0,
      end: regions.length ? regions[0].start : undefined,
    });

    // 最新のregionのendを本日にする
    regions[regions.length - 1].end = moment().diff(startDate, 'days');

    // cssクラスを追加してチャート設定に押し込む
    return regions.map((region, i) => ({
      start: region.start,
      end: region.end,
      class: `region${i % 3}`
    }));
  }

  getData() {
    if (this.chartType === 'milk') {
      return this.MilkingService.getDailySummaryByCowId(this.periodsApiParams).then((res) => {
        return res.data;
      });
    } else if (this.useCalfManagement) {
      if (this.chartType === 'move') {
        return this.$q.all([
          this.PeriodicityService.activityIndex(this.periodsApiParams),
          this.PeriodicityService.activityIndex(this.updatePeriodApiParamsType('feed')),
        ]).then((res) => {
          return this.generateCalfManagementMoveData(res[0].data, res[1].data);
        });
      }

      if (this.chartType === 'feed') {
        return this.PeriodicityService.activityIndex(this.periodsApiParams).then((res) => {
          return this.generateCalfManagementFeedData(res.data);
        });
      }
    }

    return this.PeriodicityService.activityIndex(this.periodsApiParams).then((res) => {
      return res.data;
    });
  }

  generateCalfManagementMoveData(moveData, feedData) {
    const filteredFeedData = feedData.filter((item) => {
      return Cow.isCalfPeriod(this.cow.birthday, item.startDate);
    });
    const data = [moveData, filteredFeedData].flat();

    return data.reduce((arr, cur) => {
      const find = arr.find((item) => {
        return item.startDate === cur.startDate;
      });

      if (find) {
        find.amount += cur.amount;
      } else {
        arr.push({
          startDate: cur.startDate,
          amount: cur.amount
        });
      }
      return arr;
    }, []);
  }

  generateCalfManagementFeedData(data) {
    return data.map((item) => {
      const isCalfPeriod = Cow.isCalfPeriod(this.cow.birthday, item.startDate);

      if (isCalfPeriod) {
        item.amount = 0;
      }

      return item;
    });
  }

  createPeriodsData(periodsApiParams) {
    const periodPromise = this.getData();

    // 経過日数計算用
    const paramsStartDate = moment(this.periodsApiParams.startDate);

    // 日付を経過日数に換算
    const calDaysPassed = (startDate, paramsStartDate) => moment(startDate).diff(paramsStartDate, 'days');

    // 軸ラベルを更新
    const labels = this.updateTimelineChartLabels();

    // ズーム値設定
    const zoomLevels = this.updateZoomLevels(moment().diff(paramsStartDate, 'days'));

    const nullColumns = (chartConfig, baseConfig, labels, zoomLevels, maxAxis) => {
      const columns = Object.keys(chartConfig)
        .reduce((aggr, key) => {
          aggr[0].push(null);
          aggr[1].push(key);
          return aggr;
        }, baseConfig);
      return {
        columns,
        labels,
        zoomLevels,
        maxAxis: maxAxis,
      };
    };

    return periodPromise.then((res) => {
      const baseConfig = [[this.chartType], ['x']];
      // データがなければ1年間分の空グラフを表示
      if (!res.length) {
        const emptyConfig = this.createEmptyPeriods(365);
        return nullColumns(emptyConfig, baseConfig, labels, zoomLevels, 365);
      }

      // タイムライン開始日と初イベントの誤差を計算、その分の経過日数を作成
      const responseGap = calDaysPassed(res[0].startDate || res[0].date, paramsStartDate);

      // 最低1年間分の軸データを用意する
      const initialGap = responseGap > 365 ? responseGap : 365;
      const chartConfig = this.createEmptyPeriods(initialGap);

      if (!this.showFeed && this.chartType === 'feed') {
        return nullColumns(chartConfig, baseConfig, labels, zoomLevels, initialGap);
      }

      // 軸データにレスポンスデータを追加
      res.forEach((event) => {
        const value = Number(event.amount || event.minutes);
        const date = event.startDate || event.date;
        const daysPassed = calDaysPassed(date, paramsStartDate);
        chartConfig[daysPassed] = value;
      });

      // 表示用のデータを整理
      const columns = Object.keys(chartConfig)
        .reduce((aggr, key) => {
          aggr[0].push(chartConfig[key]); // col
          aggr[1].push(key); // x軸
          return aggr;
        }, baseConfig);

      // X軸最大値を最も新しい日付に設置し直す
      const maxAxis = Number(columns[1].slice(-1)[0]);

      return {
        columns,
        labels,
        zoomLevels,
        maxAxis,
      };
    }).catch((err) => {
      console.error(err);
      return {
        columns: [[], []],
        labels: {x: '', y: ''},
        zoomLevels: [0, 0],
        maxAxis: 365,
      };
    });
  }

  updateTimelineChartLabels() {
    const yAxisLabels = {
      milk: '搾乳量(kg)',
      feed: '採食(分)',
      rumination: '反芻(分)',
      move: '動態(分)',
      lie: '横臥(分)',
      stand: '静止(分)',
    };

    const xAxisLabel = !!(Number(this.cow.birthNumber) && this.cow.latestCalvingDate) ?
      '分娩後日数' : this.cow.introduceDate ?
        '導入後日数' : this.cow.birthday ?
          '出生後日数' : '経過日数';

    return {
      x: xAxisLabel,
      y: yAxisLabels[this.chartType],
    };
  }

  createEventsData() {
    return this.$q.all([
      this.CowEventsService.index(this.cow.cowId),
      this.CowGroupService.summary(),
    ]).then((res) => {

      const cowGroupNames = {};
      res[1].data.forEach((group) =>
        cowGroupNames[group.cowGroupId] = group.cowGroupName);

      // 下記イベントのみ表示
      // 乳房炎、周産期・代謝疾病、破行・蹄病、感染症、種付、妊娠鑑定, 牛群移動
      const eventsToDisplay = {
        mastitis: {position: 'middle'},
        breedingDifficulty: {position: 'middle'},
        perinatalDifficulty: {position: 'middle'},
        injury: {position: 'middle'},
        lame: {position: 'middle'},
        infect: {position: 'middle'},
        otherDifficulty: {position: 'middle'},
        tanetsuke: {position: 'end'},
        ninshinkantei: {position: 'end'},
        gyugunidou: {position: 'start'},
      };

      // 重複するとまとめるするイベント
      const stackedEvents = {
        mastitis: '乳房炎',
        perinatalDifficulty: '周産病・代謝病',
      };

      // 表示期間開始日
      // イベントは全歴取得しちゃうから、それ以前のものを排除しなければ
      const startingTimestamp = moment(this.periodsApiParams.startDate, 'YYYY-MM-DD').valueOf();
      const startingDate = moment(this.periodsApiParams.startDate, 'YYYY-MM-DD');

      // 開始日より以前 && 表示しないイベントは排除、古い→新しい順にソート
      const sortedEvents = res[0].data
        .filter((event) => Number(event.occurredAt) > startingTimestamp && eventsToDisplay[event.eventType])
        .sort((prevEvent, nextEvent) => prevEvent.occurredAt - nextEvent.occurredAt);

      // xGridline形式に変更
      const xGrids = sortedEvents
        // 表示データをツムツム
        .reduce((aggr, event) => {
          // 3日間連続するイベントをまとめる処理
          const prevEvent = aggr[aggr.length - 1]; // undefined || {}
          const value = moment(Number(event.occurredAt)).diff(startingDate, 'days');
          const diff = prevEvent ? value - prevEvent.value : 999;
          if (stackedEvents[event.eventType] && // まとめる対象である
            prevEvent && // 全イベントが存在する
            stackedEvents[event.eventType] === prevEvent.text && // 全イベントと被ってる
            3 > diff && diff > -1 // 全イベントより三日以内
          ) {
            // 何もせず次のイベントへ
            return aggr;
          }

          let text;

          if (event.eventType === 'gyugunidou') {
            text = cowGroupNames[event.moveToCowGroupId];
          } else if (event.eventType === 'otherDifficulty') {
            text = event.otherDiseaseName || event.eventName;
          } else {
            text = event.eventName;
          }

          aggr.push({
            value,
            text,
            position: eventsToDisplay[event.eventType].position,
          });
          return aggr;
        }, []);

      // 本日を追加
      xGrids.push({
        value: moment().diff(startingDate, 'days'),
        text: '本日'
      });

      // 初牛群移動検索。あれば、初牛群 = beforeCowGroupId、なければ現在の牛群 = 初牛群
      const firstGroupMovement = sortedEvents.find((event) => event.eventType === 'gyugunidou');
      const firstCowGroupId = firstGroupMovement ? firstGroupMovement.beforeCowGroupId : this.cow.cowGroupId;

      // 登録時牛群名追加
      xGrids.unshift({
        value: 0,
        text: cowGroupNames[firstCowGroupId],
        position: 'start',
      });

      // 牛群変動歴のデータ作成
      const regions = this.createRegionsData(sortedEvents);

      return {
        regions,
        xGrids,
      };
    }).catch((err) => {
      console.error(err);
      return {
        regions: [],
        xGrids: [],
      };
    });
  }

  updateZoomLevels(endPos) {
    const startPos = endPos - 30;
    return [startPos, endPos];
  }
}

function timelineChartComponent() {
  return {
    templateUrl: 'cow/components/charts/timeline-chart.html',
    bindings: {
      cow: '=',
      isMilkFarm: '=',
      showFeed: '=',
      useCalfManagement: '='
    },
    controller: TimelineChartController,
    controllerAs: 'timeline',
  };
}

app.component('timelineChartComponent', timelineChartComponent());
