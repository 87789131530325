class CertificateAiAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  /**
   * 指定した条件に合致する種付記録を返します。
   *
   * @param {object} condition
   * @return {object} HTTP Response
   *
   * conditionは以下のデータ形式になります。
   * {
   *    type: 'mother' or 'calf',
   *    periodType: 'bred' or 'calved' (type = mothe の場合に指定する),
   *    startDate: 対象期間の開始日 (Dateインスタンス),
   *    endDate: 対象期間の終了日 (Dateインスタンス),
   *    cowNos: 対象の牛個体の牛番号の配列 (type = mothe なら母牛、type = calf なら子牛),
   *    cowUids: 対象の牛個体の個体識別番号の配列 (type = mothe なら母牛、type = calf なら子牛),
   *    pregnantOnly: 受胎に関連する種付だけを対象にする場合はtrueをセットする,
   * }
   */
  search(condition) {
    const params = {};

    const temp = {
      cowNos: condition.cowNos || [],
      cowUids: condition.cowUids || [],
    };

    if (condition.conditionInseminators) {
      temp.inseminators = condition.conditionInseminators;
    }

    if (condition.type === 'mother') {
      if (condition.periodType === 'calved') {
        temp.calvedStartDate = condition.startDate ? DateUtil.toMSec(condition.startDate) : null;
        temp.calvedEndDate = condition.endDate ? DateUtil.toMSec(condition.endDate) : null;
      } else {
        temp.bredStartDate = condition.startDate ? DateUtil.toMSec(condition.startDate) : null;
        temp.bredEndDate = condition.endDate ? DateUtil.toMSec(condition.endDate) : null;
        temp.pregnantOnly = condition.pregnantOnly === true;
      }

      params.mother = temp;
    } else {
      temp.birthStartDate = condition.startDate ? DateUtil.toMSec(condition.startDate) : null;
      temp.birthEndDate = condition.endDate ? DateUtil.toMSec(condition.endDate) : null;

      params.calf = temp;
    }
    return this.APIService.post('/certificate/ai', params);
  }
}

app.service('CertificateAiAPI', CertificateAiAPI);
