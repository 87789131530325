function reproductionHistoryItemDaysSecondary($timeout) {
  return {
    restrict: 'A',
    scope: {
      targetId: '=',
      days: '='
    },
    link: (scope, element, attrs) => {
      $timeout(() => {
        let height;

        if (scope.targetId) {
          const beforeFertilizationEvent = angular.element(`.uModal__historyItem--primary[data-event-id="${scope.targetId}"]`);

          if (beforeFertilizationEvent.length) {
            height = beforeFertilizationEvent.offset().top - element.offset().top;

            element.css({
              visibility: 'visible',
              height: height
            });

            element.find('.uModal__historyItemDaysValue').text(scope.days);
          }
        }
      });
    }
  };
}

app.directive('reproductionHistoryItemDaysSecondary', ['$timeout', reproductionHistoryItemDaysSecondary]);
