class GroupTransferInputController {
  constructor(
    $q,
    $state,
    $modal,
    $timeout,
    $stateParams,
    blockUI,
    Dictionary,
    SessionCache,
    GroupFarmAPI,
    StandardDialog,
    GroupCowGroupAPI,
    GroupTransferAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$modal = $modal;
    this.$timeout = $timeout;
    this.$stateParams = $stateParams;
    this.blockUI = blockUI;
    this.GroupFarmAPI = GroupFarmAPI;
    this.StandardDialog = StandardDialog;
    this.GroupCowGroupAPI = GroupCowGroupAPI;
    this.GroupTransferAPI = GroupTransferAPI;
    this.cowNoLabel = Dictionary.COW.COW_NO;
    this.useName = SessionCache.farm().useName();

    this.init();
  }

  init() {
    this.currentPage = 1;
    this.editType = 'create';
    this.memberToFarms = {};
    this.clipboard = {};
    this.model = {
      rows: []
    };

    this.index();
  }

  index() {
    this.model.transferDate = DateUtil.toMSec(DateUtil.today());
    this.model.conditions = 'cowNo';

    this.GroupFarmAPI.index().then((res) => {
      const farms = res.data;

      this.memberFarms = GroupTransfer.generateMemberFarms(farms);
      this.transferTypes = GroupTransfer.generateTransferTypes(farms);

      this.initStateParams();
    });
  }

  initStateParams() {
    const cows = this.$stateParams.cows;
    const histories = this.$stateParams.histories;

    if (!cows.length && !histories.length) {
      this.addRows(10);

      return;
    }

    this.model.fromId = this.$stateParams.fromId;
    this.setMemberToFarms();
    this.initStateParamsCowId();
    this.initStateParamsHistories();
    this.setCommonParams();
  }

  initStateParamsCowId() {
    const cows = this.$stateParams.cows;

    if (!cows.length) return;

    this.addRows(cows.length);

    cows.forEach((cow, index) => {
      this.model.rows[index].fromCustomlist = true;

      this.model.rows[index].cow = {
        cowId: cow.cowId,
        formattedCowUid: Cow.formatCowUid(cow.cowUid)
      };

      this.model.rows[index].input = {
        cowNo: cow.cowNo,
        removeSensor: false
      };
    });
  }

  initStateParamsHistories() {
    const histories = this.$stateParams.histories;

    if (!histories.length) return;

    this.editType = 'update';
    this.addRows(histories.length);
    this.model.toId = this.$stateParams.toId;
    this.model.transferDate = this.$stateParams.transferDate;
    this.model.transferType = this.$stateParams.transferType;
    this.model.operator = this.$stateParams.operator;

    this.GroupCowGroupAPI.index(this.model.toId).then((res) => {
      this.cowGroups = res.data;

      histories.forEach((history, index) => {
        const row = this.model.rows[index];
        const cowGroupId = history.input.toCowGroupId;
        const cowGroupAttribute = this.decideCowGroupAttribute();

        row.history = history;
        row.cow = GroupTransferInput.updateCow(history.cow);
        row.pens = GroupTransfer.generatePens(this.cowGroups, cowGroupId);
        row.input = row.history.input;
        row.warning.toCowGroupId = GroupTransfer.validateCowGroupAttribute(this.cowGroups,
          row.input.toCowGroupId, cowGroupAttribute);

        this.candidateCow(row);
      });
    });
  }

  setMemberToFarms() {
    this.memberToFarms = GroupTransfer.setMemberToFarms(this.memberFarms, this.model.fromId);
  }

  setCommonParams() {
    const transferDate = DateUtil.toYYYYMMDD(this.model.transferDate);
    const conditions = this.model.conditions === 'cowNo' ? '牛番号' : '個体識別番号';

    const from = this.memberFarms.find((farm) => {
      return farm.id === this.model.fromId;
    }) || [];

    const to = this.memberFarms.find((farm) => {
      return farm.id === this.model.toId;
    }) || [];

    const transferType = GroupTransfer.TRANSFER_TYPE_MAP.find((label) => {
      return label.value === this.model.transferType;
    }) || [];

    this.commonParams = {
      fromName: from.farmName,
      toName: to.farmName,
      transferDate: transferDate,
      transferType: transferType.label,
      operator: this.model.operator,
      conditions: conditions
    };
  }

  addRows(number) {
    const rows = new Array(number).fill().map(Object);

    rows.forEach((row) => {
      this.clearRow(row);
    });

    this.model.rows = this.model.rows.concat(rows);
  }

  clearRow(row, exclude) {
    row = GroupTransferInput.clearRow(row, exclude);
  }

  clearRowsError() {
    this.model.rows = GroupTransferInput.clearRowsError(this.model.rows);
  }

  onChangeFromId(oldValue) {
    const row = this.model.rows.some((row) => {
      return row.input.cowNo || row.input.cowUid;
    });

    if (!oldValue || !row) {
      this.setMemberToFarms();
      this.setCommonParams();

      return;
    }

    this.StandardDialog.showDeleteConfirm({
      title: '異動入力',
      text2: `異動元牧場を変更すると、入力中の${this.cowNoLabel}は削除されます。`,
      text3: '変更しますか？',
      yes: 'O K'
    }).result.then(() => {
      this.setMemberToFarms();
      this.model.rows.forEach((row) => {
        row = this.clearRow(row);
      });
    }).catch(() => {
      this.model.fromId = Number(oldValue);
    }).finally(() => {
      this.setCommonParams();
    });
  }

  onChangeToId() {
    if (!this.model.toId) return;

    this.GroupCowGroupAPI.index(this.model.toId).then((res) => {
      this.cowGroups = res.data;
    });
  }

  onClickCancel() {
    this.confirmCancel().then((res) => {
      if (!res) return;

      if (!Object.keys(this.$stateParams.caller).length) {
        this.$state.go('top');

        return;
      }

      const state = this.$stateParams.caller.state;
      const params = this.$stateParams.caller.params;

      this.$state.go(state, params);
    });
  }

  confirmCancel() {
    if (!this.model.fromId) {
      const d = this.$q.defer();
      d.resolve(true);
      return d.promise;
    }

    return this.StandardDialog.showDeleteConfirm({
      title: '異動入力',
      text2: '入力中の内容は削除されます。',
      text3: 'よろしいですか？',
      yes: 'O K'
    }).result.then(() => {
      const d = this.$q.defer();
      d.resolve(true);
      return d.promise;
    }).catch(() => {
      const d = this.$q.defer();
      d.resolve(false);
      return d.promise;
    });
  }

  goToPage(page, id) {
    if (page === 1) {
      if (id) {
        const element = `#${id}`;

        this.$timeout(() => {
          document.querySelector(element).focus();
        });
      }
    } else {
      const cowGroupAttribute = this.decideCowGroupAttribute();

      this.setCommonParams();
      this.rowsCandidateCow();

      this.model.rows.forEach((row) => {
        row.warning.toCowGroupId = GroupTransfer.validateCowGroupAttribute(this.cowGroups,
          row.input.toCowGroupId, cowGroupAttribute);
      });
    }

    this.currentPage = page;
  }

  onCopy1stLine() {
    this.model.rows = GroupTransferInput.copy1stLine(this.model.rows);
  }

  onCopySelected() {
    this.clipboard = GroupTransferInput.copySelected(this.model.rows);
  }

  onPasteSelected() {
    this.model.rows = GroupTransferInput.pasteSelected(this.model.rows, this.clipboard);
  }

  onDeleteSelected() {
    this.model.rows = GroupTransferInput.deleteSelected(this.model.rows);
    this.clearRowsError();
    this.setDuplicatedCowError();
  }

  onChangeCowNo(row) {
    this.clearRow(row, this.model.conditions);
    this.clearRowsError();
  }

  onBlurCowNo(row) {
    this.clearRow(row, this.model.conditions);
    this.clearRowsError();
    this.setDuplicatedCowError();
    this.candidateCow(row);
  }

  onChangeCowUid(row) {
    this.clearRow(row, this.model.conditions);
    this.clearRowsError();

    if (row.input.cowUid) {
      row.input.cowUid = StringUtil.removeNonSingleByteNumericCharacters(row.input.cowUid);
    }
  }

  onBlurCowUid(row) {
    this.setDuplicatedCowError();
    this.candidateCow(row);
  }

  onChangeToCowGroupId(row) {
    const cowGroupAttribute = this.decideCowGroupAttribute();

    row.input.toPen = null;
    row.pens = GroupTransfer.generatePens(this.cowGroups, row.input.toCowGroupId);
    row.warning.toCowGroupId = GroupTransfer.validateCowGroupAttribute(this.cowGroups, row.input.toCowGroupId,
      cowGroupAttribute);
    row.error.toCowGroupId = GroupTransferInput.validateCowGroupId(row.input.toCowGroupId);
  }

  decideCowGroupAttribute() {
    const findTransferType = this.transferTypes.find((type) => type.value === this.model.transferType) || [];

    return findTransferType.cowGroupAttribute;
  }

  toggleAllSelected() {
    this.model.rows = this.model.rows.map((row) => {
      if (row.cow.cowId) {
        row.input.selected = this.model.allSelected;
      }

      return row;
    });
  }

  toggleSelected() {
    this.model.allSelected = this.filteredCowId().every((row) => {
      return row.input.selected;
    });
  }

  setDuplicatedCowError() {
    const key = this.useName ? 'cowName' : this.model.conditions;
    const errors = GroupTransferInput.validateDuplicatedCow(this.model.rows, key);

    if (errors.length) {
      errors.forEach((error) => {
        this.model.rows.filter((row) => {
          return row.cow.cowId === error.cowId;
        }).map((row) => {
          row.error = error.errors;

          return row;
        });
      });
    }
  }

  rowsCandidateCow() {
    this.model.rows.forEach((row) => {
      this.candidateCow(row);
    });
  }

  candidateCow(row) {
    if (this.editType === 'create' && !row.cow.cowId && !row.input[this.model.conditions]) return;
    if (this.editType === 'update' && !row.history.transferInputId) return;

    let conditions;

    if (this.editType === 'create') {
      if (row.cow.cowId) {
        conditions = {
          cowId: row.cow.cowId
        };
      } else {
        conditions = {
          [this.model.conditions]: row.input[this.model.conditions]
        };
      }
    } else {
      conditions = {
        transferInputId: row.history.transferInputId
      };
    }

    row.invalidCandidate = true;
    row.error.cowNo = null;
    row.error.cowUid = null;

    this.GroupTransferAPI.candidate(
      this.model.fromId,
      this.model.transferType,
      this.model.transferDate,
      conditions
    ).then((res) => {
      const cows = res.data;

      if (!cows.length) {
        row.error[Object.keys(conditions)[0]] = GroupTransfer.ERROR_MESSAGE.nothing;

        return;
      }

      if (cows.length > 1) {
        const params = GroupTransferInput.generateCowNoSelectParams(cows);

        this.$modal.open({
          windowTemplateUrl: 'components/u-modal/window.html',
          templateUrl: 'components/dialog/cow-no-select/index.html',
          controller: 'CowNoSelectDialogController',
          controllerAs: 'ctrl',
          backdrop: false,
          resolve: {
            params: () => {
              return {
                cows: params
              };
            }
          }
        }).result.then((result) => {
          const findCow = cows.find((cow) => {
            return cow.cowId === result;
          });

          this.updateRow(findCow, row);
          this.setDuplicatedCowError();
          this.toggleAllSelected();
        });

        return;
      }

      if (cows.length === 1) {
        const findCow = cows[0];

        this.updateRow(findCow, row);
        this.setDuplicatedCowError();
        this.toggleAllSelected();

        return;
      }
    }).catch((err) => {
      if (err.data) {
        if (err.data.searchError) {
          row.error.cowNo = err.data.searchError;
          row.error.cowUid = err.data.searchError;

          return;
        } else if (err.data.messages) {
          row.error.cowNo = ErrorUtil.formatErrorMessage(err.data.messages);
          row.error.cowUid = ErrorUtil.formatErrorMessage(err.data.messages);

          return;
        }
      }

      row.error.cowNo = '牛個体照会でエラーが発生しました';
      row.error.cowUid = '牛個体照会でエラーが発生しました';
    });
  }

  updateRow(findCow, row) {
    row.invalidCandidate = false;

    if (this.editType === 'update') return;

    row.cow = GroupTransferInput.updateCow(findCow);
    row.input.cowNo = findCow.cowNo;
    row.input.cowUid = findCow.cowUid;
  }

  createGroupTransfer() {
    this.validate();

    if (this.invalid) return;

    const params = GroupTransferInput.generateCreateParams(this.model);

    this.blockUI.start('登録中');
    this.GroupTransferAPI.create(params).then(() => {
      this.blockUI.stop();
      this.blockUI.start('異動入力の登録が完了しました');
      this.$timeout(() => {
        this.blockUI.stop();

        this.$stateParams = {
          cowUids: [],
          histories: [],
          fromId: null,
          toId: null,
          transferType: null,
          caller: {}
        };

        this.init();
      }, 1000);
    }).catch((err) => {
      let errorMessage;

      this.blockUI.stop();

      if (err.data && err.data.messages) {
        errorMessage = ErrorUtil.formatErrorMessage(err.data.messages);
      } else {
        errorMessage = '異動入力の登録でエラーが発生しました';
      }

      this.StandardDialog.showMessage({
        title: '異動入力',
        text1: errorMessage,
      });
    });
  }

  updateGroupTransfer() {
    this.validate();

    if (this.invalid) return;

    const params = GroupTransferInput.generateUpdateParams(this.model);

    this.blockUI.start('更新中');
    this.GroupTransferAPI.update(params).then(() => {
      this.blockUI.stop();
      this.blockUI.start('入力履歴の更新が完了しました');
      this.$timeout(() => {
        this.blockUI.stop();
        this.$state.go('group-transfer-history');
      }, 1000);
    }).catch((err) => {
      let errorMessage;

      this.blockUI.stop();

      if (err.data && err.data.messages) {
        errorMessage = ErrorUtil.formatErrorMessage(err.data.messages);
      } else {
        errorMessage = '入力履歴の更新でエラーが発生しました';
      }

      this.StandardDialog.showMessage({
        title: '入力履歴',
        text1: errorMessage,
      });
    });
  }

  validate() {
    this.invalid = false;

    const invalidDate = GroupTransfer.validateDate(this.model.transferDate);

    if (invalidDate) {
      this.invalid = true;
      this.StandardDialog.showMessage({
        title: '異動入力',
        text1: '異動日の入力が正しくありません'
      });
      return;
    }

    const noDataRequired = GroupTransfer.validateRequired(this.model, GroupTransferInput.REQUIRED_FIELDS);

    if (noDataRequired.length) {
      this.invalid = true;
      this.StandardDialog.showMessage({
        title: '異動入力',
        text1: `${noDataRequired[0].label}が入力されていません`
      });

      return;
    }

    this.filteredCowId().map((row) => {
      row.error.toCowGroupId = GroupTransferInput.validateCowGroupId(row.input.toCowGroupId);

      return row;
    });

    const hasError = this.model.rows.some((row) => {
      return GroupTransfer.hasError(row);
    });

    if (hasError) {
      this.invalid = true;
      this.StandardDialog.showMessage({
        title: '異動入力',
        text1: '入力内容に誤りがあります'
      });

      return;
    }

    const noDataCows = this.model.rows.every((row) => {
      return !row.cow.cowId;
    });

    if (noDataCows) {
      this.invalid = true;
      this.StandardDialog.showMessage({
        title: '異動入力',
        text1: this.errorMessageNoData()
      });

      return;
    }
  }

  errorMessageNoData() {
    const key = this.useName ? 'cowName' : this.model.conditions;

    return GroupTransfer.ERROR_MESSAGE.noData[key];
  }

  filteredCowId() {
    return this.model.rows.filter((row) => {
      return row.cow.cowId;
    });
  }

  filteredSelected() {
    return this.model.rows.filter((row) => {
      return row.input.selected;
    });
  }

  showMessageTransferType(type) {
    return this.model.transferType === type;
  }

  showColumnCowNo() {
    return this.model.conditions === 'cowNo';
  }

  showColumnCowUidInput() {
    return this.model.conditions === 'cowUid';
  }

  showCount() {
    return this.showButtonCopySelected() ||
      this.showButtonPasteSelected() ||
      this.showButtonDeleteSelected();
  }

  showButtonCopySelected() {
    return this.filteredSelected().length === 1;
  }

  showButtonPasteSelected() {
    return Object.keys(this.clipboard).length && this.filteredSelected().length;
  }

  showButtonDeleteSelected() {
    if (this.editType === 'update') return false;

    return this.filteredSelected().length;
  }

  showButtonAddRows() {
    return this.editType === 'create';
  }

  showButtonCreate() {
    return this.editType === 'create';
  }

  disabledFromId() {
    return this.editType === 'update';
  }

  disabledButtonGoToPage(page) {
    if (page === 2) {
      const noDataRequired = GroupTransfer.validateRequired(this.model, GroupTransferInput.REQUIRED_FIELDS);

      return noDataRequired.length;
    }
  }

  disabledButtonCopy1stLine() {
    return this.filteredCowId().length < 2;
  }

  disabledInputCowNo(row) {
    if (this.editType === 'update' || row.fromCustomlist) {
      return true;
    }

    return false;
  }

  disabledInputToPen(row) {
    if (this.editType === 'create') {
      return !row.input.toCowGroupId;
    }

    return !row.input.toCowGroupId || row.invalidCandidate;
  }

  disabledInputRemoveSensor(row) {
    if (this.editType === 'create') {
      return !row.cow.bleId;
    }

    return !row.cow.bleId || !row.history.removeSensorEditable;
  }

  disabledButtonCreate() {
    return !this.filteredCowId().length;
  }

  classHeadingPagesItem(page) {
    return this.currentPage === page ? 'uHeading__pagesItem--active' : 'uHeading__pagesItem';
  }

  classDisabled(name, error) {
    const className = this.model.fromId && this.model.toId ? name : `${name}--disabled`;

    if (error) {
      return `${className} is-error`;
    }

    return className;
  }
}

app.controller('GroupTransferInputController', GroupTransferInputController);
