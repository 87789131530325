class DepositCowService {
  constructor(APIService, DateUtilService, appConfig) {
    'ngInject';
    this.APIService = APIService;
    this.DateUtilService = DateUtilService;
    this.staffName = appConfig.staff.name;
  }

  candidates() {
    return this.APIService.get('/deposit/candidate_cow');
  }

  deliver(qwert, cowIds, deliveryDate) {
    const params = {
      qwert: qwert,
      cowIds: cowIds,
      deliveryDate: deliveryDate
    };
    return this.APIService.post('/deposit/cow', params);
  }

  depositedCows() {
    return this.APIService.get('/deposit/cow');
  }

  // 単体GET
  get(cowId) {
    return this.APIService.get('/deposit/cow/' + cowId);
  }

  // ラベル「種雄牛」がついてる牛のみGET
  getBulls(qwert) {
    return this.APIService.get(`/deposit/bull?qwert=${qwert}`);
  }

  receive(cows) {
    const params = {
      cows: cows.map((cow) => {
        return {
          cowId: cow.cowId,
          cowNo: cow.cowNo,
          cowGroupId: cow.cowGroupId,
          pen: cow.pen,
        };
      })
    };
    return this.APIService.put('/deposit/cow', params);
  }

  ship(shipments) {
    const params = {
      inputUserName: this.staffName,
      shipments: shipments
    };
    return this.APIService.post('/deposit/shipment', params);
  }

  allDepositedCows() {
    return this.APIService.get('/deposit/all_cow');
  }

  deprecatedDigest(qwert, allowInactive) {
    let params = `?qwert=${qwert}`;
    params += allowInactive ? '&allow_inactive=true' : '';
    return this.APIService.get('/deposit/cow/deprecated_digest' + params);
  }
}

app.service('DepositCowService', DepositCowService);
