class MobileDysstasiaReportFormController {
  constructor(
    $modal,
    $modalInstance,
    $timeout,
    blockUI,
    CowService,
    DateTimeUtilFactory,
    Dictionary,
    DysstasiaAPI,
    params
  ) {
    'ngInject';
    this.$modal = $modal;
    this.$modalInstance = $modalInstance;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.CowService = CowService;
    this.dUtil = DateTimeUtilFactory;
    this.Dictionary = Dictionary;
    this.DysstasiaAPI = DysstasiaAPI;

    this.cowId = params.cowId;
    this.invalid = false;
    this.messagesMap = {};

    this.onInit();
  }

  onInit() {
    this.hours = DateUtil.hours;
    this.minutes = DateUtil.minutes;
    this.CowService.get(this.cowId).then((res) => {
      const currentDate = today();
      this.cow = this.dUtil.parseClientDateForCowInfo(res.data);
      const endDate = Number(this.cow.eliminateDate) || Number(this.cow.fallingDeadDate) || currentDate;
      this.cow.monthAge = this.cow.birthday ? DateUtil.monthAge(Number(this.cow.birthday), endDate, 1) : '-';
      this.formData = this.initializeFormData();
    }).catch((err) => console.error(err));
  }

  initializeFormData() {
    const date = new Date();
    return {
      occurredAt: DateUtil.today().getTime(),
      selectedHour: DateUtil.format(date, 'HH'),
      selectedMinute: (DateUtil.format(date, 'mm'))[0] + '0',
      comment: ''
    };
  }

  submit() {
    const findingAt = DateUtil.addTimeToDate(
      this.formData.occurredAt,
      this.formData.selectedHour,
      this.formData.selectedMinute
    );
    this.blockUI.start('登録中');
    this.DysstasiaAPI.create(this.cowId, findingAt, this.formData.comment)
      .then((res) => {
        this.blockUI.stop();

        this.$modalInstance.close({
          status: {DONE: true},
          params: {
            cowId: this.cow.cowId,
            cowNo: this.cow.cowNo,
            monthAge: this.cow.monthAge,
            cowGroupName: this.cow.cowGroupName,
            findingAt: findingAt,
            comment: this.formData.comment
          }
        });
      })
      .catch((err) => {
        console.error(err);
        this.blockUI.stop();
        if (err.data.messages) {
          this.$modal.open({
            animation: true,
            templateUrl: 'mobile/dysstasia-report/duplicate-error-dialog/index.html',
            controller: 'MobileDysstasiaDuplicateErrorDialogController',
            controllerAs: 'ctrl',
            backdrop: 'static',
            keyboard: false,
            size: 'event-register-lg',
            resolve: {
              params: () => {
                return {
                  messages: err.data.messages.map((m) => m.message),
                  findingAt: findingAt
                };
              }
            }
          }).result.catch((err) => console.error(err));
        }

      });
  }

  cancel() {
    this.$modalInstance.close();
  }

  validate() {
    this.invalid = true;

    if (this.formData.occurredAt) {
      this.messagesMap.occurredAt = '';
      this.invalid = false;
    } else {
      this.messagesMap.occurredAt = '発見日時を入力して下さい。';
    }
  }
}

app.controller('MobileDysstasiaReportFormController', MobileDysstasiaReportFormController);
