class ReproductionPerformanceReportContentsController {
  constructor(DateUtilService) {
    'ngInject';
    this.dateUtilService = DateUtilService;

    this.initialize();
  }

  initialize() {
    this.kind = 'heat-detection-rate';
    this.heatDetectionRateChartDate = this.dateUtilService.today();
    this.firstAiDimChartDate = this.dateUtilService.today();
  }

  changeKind(kind) {
    if (this.kind === kind) return;
    this.kind = kind;
  }

  addActiveToClassName(kind) {
    if (this.kind === kind) return 'active';
  }

}

function reproductionPerformanceReportContentsComponent() {
  'ngInject';
  return {
    templateUrl: 'reproduction-performance-report/contents/contents.html',
    controller: ReproductionPerformanceReportContentsController,
  };
}

app.component('reproductionPerformanceReportContents', reproductionPerformanceReportContentsComponent());
