class MobileModalDialogController {
  constructor() {
    this.state = {};
  }

  $onInit() {
    this.state = this.initializeState();
  }

  initializeState() {
    return {
      isOpen: false
    };
  }
}

app.controller('MobileModalDialogController', MobileModalDialogController);
