class AlertCalvingSensorDroppedController {
  constructor(
    $modal,
    $state,
    $timeout,
    blockUI,
    AlertCalvingSensorDroppedAPI,
    StandardDialog,
    CowCalvingSensorHistoryDialog
  ) {
    'ngInject';
    this.$modal = $modal;
    this.$state = $state;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.AlertCalvingSensorDroppedAPI = AlertCalvingSensorDroppedAPI;
    this.CowCalvingSensorHistoryDialog = CowCalvingSensorHistoryDialog;
    this.StandardDialog = StandardDialog;

    this.title = '分娩センサー脱落アラート';

    this.init();
  }

  init() {
    this.loading = true;
    this.solution = null;
    this.currentAlert = null;

    this.AlertCalvingSensorDroppedAPI.unsolved().then((res) => {
      this.rawAlerts = res.data.map((d) => AlertCalvingSensorDropped.toViewModel(d));
      this.selectUnsolvedAlerts();
      this.loading = false;
    });
  }

  selectUnsolvedAlerts() {
    this.alerts = this.rawAlerts.filter((a) => !a.solved);
  }

  goToDetails(cowId) {
    const cowIds = this.alerts.map((a) => a.cowId);
    const caller = {
      state: 'alert-calving-sensor-dropped',
      name: '分娩センサー脱落アラート',
      cowIds: cowIds,
      viewState: 'alert-calving-sensor-dropped'
    };

    this.$state.go('cowDetail', {cowId: cowId, caller: caller});
  }

  openSolutionSelectionModal(id) {
    this.currentAlert = this.alerts.find((a) => a.id === id);

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/alert/calving-sensor-dropped/solution-selection.html',
      controller: 'AlertCalvingSensorDroppedSolutionSelctionController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            calvingSensorNumber: this.currentAlert.formattedCalvingSensorNumber,
            solution: this.solution
          };
        }
      }
    }).result.then((res) => {
      if (!res) return this.solution = null;

      this.solution = res;

      switch (this.solution) {
      case 'reequip':
      case 'ignore':
        return this.update(this.solution);
      case 'edit-sensor-history':
        return this.openCowCalvingSensorHistoryDialog();
      }
    });
  }

  update(solution) {
    const params = {solution: solution};

    this.blockUI.start('登録中');

    this.AlertCalvingSensorDroppedAPI.update(this.currentAlert.id, params).then((res) => {
      this.blockUI.done(() => {
        this.blockUI.start('登録が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);
      });

      this.currentAlert.solved = true;
      this.selectUnsolvedAlerts();

      this.blockUI.stop();
    }).catch((error) => {
      const errorMessage = ErrorUtil.formatErrorMessage(error.data.messages);
      this.blockUI.stop();
      this.StandardDialog.showMessage({
        title: '処置内容の登録エラー',
        text1: errorMessage
      });
    }).finally(() => {
      this.currentAlert = null;
      this.solution = null;
    });
  }

  openCowCalvingSensorHistoryDialog() {
    const modalInstance = this.CowCalvingSensorHistoryDialog.open(this.currentAlert);

    modalInstance.result.then((result) => {
      this.currentAlert.solved = true;
      this.currentAlert = null;
      this.solution = null;
      this.selectUnsolvedAlerts();
    }).catch((res) => {
      this.openSolutionSelectionModal(this.currentAlert.id);
    });
  }
}

app.controller('AlertCalvingSensorDroppedController', AlertCalvingSensorDroppedController);
