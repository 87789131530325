class MasterMedicineAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/master_medicine');
  }

  /**
   * 預託管理用の拡張、入力画面ではこの関数を呼び出します。
   * 預託元牧場、通常の牧場は自牧場の全設定が返されます。
   * 預託先牧場の場合は預託元牧場と自牧場を合わせたものが返されます。
   * visible = false のものも含まれるので文脈に応じてフィルタリングが必要です。
   */
  available() {
    return this.APIService.get('/master_medicine/available');
  }

  create(params) {
    return this.APIService.post('/master_medicine', params);
  }

  update(params) {
    return this.APIService.put(`/master_medicine/${params.id}`, params);
  }

  delete(id) {
    return this.APIService.delete(`/master_medicine/${id}`);
  }

  updateSortOrder(orders) {
    const params = {orders: orders};
    return this.APIService.post('/master_medicine/sort_order', params);
  }
}

app.service('MasterMedicineAPI', MasterMedicineAPI);
