class MobileEditCalvingSensorHistoryModalController {
  constructor(
    $modalInstance,
    $timeout,
    blockUI,
    CowCalvingSensorHistoryAPI,
    MobileEditCalvingSensorHistoryConfirmModal,
    modalDialogFactory,
    param
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.CowCalvingSensorHistoryAPI = CowCalvingSensorHistoryAPI;
    this.MobileEditCalvingSensorHistoryConfirmModal = MobileEditCalvingSensorHistoryConfirmModal;
    this.modalDialogFactory = modalDialogFactory;

    this.cowId = param.cowId;
    this.type = param.type;
    this.calvingSensorHistories = param.calvingSensorHistories;
    if (this.type === 'replace') {
      this.shouldShowCurrentCalvingSensorHistory = true;
      this.currentCalvingSensorHistory =
        CowCalvingSensorHistory.currentCalvingSensorHistory(this.calvingSensorHistories);
    }
    this.submitting = false;
  }

  isSubmitting() {
    return this.submitting;
  }

  onSubmit(params) {
    this.submitting = true;

    const blockUIMoment = (message) => {
      this.blockUI.start(message);
      this.$timeout(() => {
        this.blockUI.stop();
      }, 1000);
    };

    const showErrorMessage = (message) => {
      this.modalDialogFactory.showMobileAlertDialog({
        texts: [message]
      });
    };

    switch (this.type) {
    case 'equip':
    case 'replace':
      this.blockUI.start('更新中');

      const formDataToSend = angular.copy(params);
      formDataToSend.cowId = this.cowId;
      formDataToSend.calvingSensorNumber =
        CowCalvingSensorHistory.generateCalvingSensorNumber(formDataToSend.firstNumber, formDataToSend.lastNumber);

      this.CowCalvingSensorHistoryAPI.create(formDataToSend).then(() => {
        this.blockUI.stop();
        blockUIMoment('分娩センサー番号履歴の追加が完了しました');
        this.$modalInstance.close(true);
      }).catch((e) => {
        console.error(e);
        this.blockUI.stop();
        showErrorMessage(ErrorUtil.formatErrorMessage(e.data.messages));
      }).finally(() => {
        this.submitting = false;
      });
      break;
    case 'unequip':
      const joinedCalvingSensorNumber = `${params.firstNumber}-${params.lastNumber}`;
      this.MobileEditCalvingSensorHistoryConfirmModal.open({
        title: '確認',
        texts: [
          '下記の分娩センサー番号を解除しますか？',
          `分娩センサー番号：${joinedCalvingSensorNumber}`,
          `適用日：${DateUtil.toYYYYMMDD(params.startDate)}`,
          `解除日：${DateUtil.toYYYYMMDD(params.endDate)}`,
        ],
        yesLabel: '解除する',
        noLabel: 'キャンセル',
      }).result.then((r) => {
        if (!r) return;
        this.blockUI.start('解除中');

        this.CowCalvingSensorHistoryAPI.update(params.id, {endDate: params.endDate}).then(() => {
          this.blockUI.stop();
          blockUIMoment('分娩センサー解除が完了しました');
          this.$modalInstance.close(true);
        }).catch((e) => {
          console.error(e);
          this.blockUI.stop();
          showErrorMessage(ErrorUtil.formatErrorMessage(e.data.messages));
        });
      }).catch((e) => console.error(e))
        .finally(() => {
          this.submitting = false;
        });
      break;
    }
  }

  onCancel() {
    this.$modalInstance.close(false);
  }
}

app.controller('MobileEditCalvingSensorHistoryModalController', MobileEditCalvingSensorHistoryModalController);
