// eslint-disable-next-line no-unused-vars
class Alert {
  /**
   * アラートのタイプ
   */
  static get ALERT_TYPES() {
    return {
      BAD_CONDITION: 'bad_condition',
      HEAT: 'heat',
      FALSE_HEAT: 'false_heat',
      DYSSTASIA: 'dysstasia',
      CALVING: 'calving',
      CALVING_SENSOR_DROPPED: 'calving_sensor_dropped',
      SENSOR: 'sensor',
      EARTAG_SENSOR_DROPPED: 'eartag_sensor_dropped',
      EARTAG_SENSOR: 'eartag_sensor'
    };
  }

  /**
   * アラートの種類別の名前ラベル
   */
  static get ALERT_TYPE_LABELS() {
    const alertNames = {};

    alertNames[this.ALERT_TYPES.BAD_CONDITION] = '注意牛';
    alertNames[this.ALERT_TYPES.HEAT] = '発情兆候';
    alertNames[this.ALERT_TYPES.FALSE_HEAT] = '発情兆候(妊娠牛)';
    alertNames[this.ALERT_TYPES.DYSSTASIA] = '起立困難';
    alertNames[this.ALERT_TYPES.CALVING] = '分娩';
    alertNames[this.ALERT_TYPES.CALVING_SENSOR_DROPPED] = '分娩センサー脱落';
    alertNames[this.ALERT_TYPES.SENSOR] = 'センサー異常';
    alertNames[this.ALERT_TYPES.EARTAG_SENSOR_DROPPED] = 'イヤタグセンサー脱落';
    alertNames[this.ALERT_TYPES.EARTAG_SENSOR] = 'イヤタグセンサー異常';

    return alertNames;
  }

  /**
   * アラート毎のカラム定義
   */
  static get ALERT_COLUMNS() {
    const alertColumns = {};

    alertColumns[this.ALERT_TYPES.BAD_CONDITION] = ['alertDate', 'alertLabels', 'cowLabels', 'cowNo', 'cowUid', 'occurredCowGroupName', 'cowGroupName', 'cowState'];
    alertColumns[this.ALERT_TYPES.HEAT] = ['alertDate', 'cowLabels', 'cowNo', 'cowUid', 'occurredCowGroupName', 'cowGroupName', 'cowState'];
    alertColumns[this.ALERT_TYPES.FALSE_HEAT] = ['alertDate', 'cowLabels', 'cowNo', 'cowUid', 'occurredCowGroupName', 'cowGroupName', 'cowState'];
    alertColumns[this.ALERT_TYPES.DYSSTASIA] = ['alertDateTime', 'score', 'cowLabels', 'cowNo', 'cowUid', 'monthAge', 'occurredCowGroupName', 'cowGroupName', 'cowState', 'dysstasiaConfirmedStatus', 'dysstasiaComment', 'remarks'];
    alertColumns[this.ALERT_TYPES.CALVING] = ['alertDate', 'cowLabels', 'cowNo', 'cowUid', 'calvingNotes'];
    alertColumns[this.ALERT_TYPES.CALVING_SENSOR_DROPPED] = ['alertDate', 'cowLabels', 'cowNo', 'cowUid', 'calvingSensorDroppedNotes'];
    alertColumns[this.ALERT_TYPES.SENSOR] = ['detectedAt', 'sensorNumber', 'cowGroupName', 'pen', 'cowNo', 'cowUid', 'deactivatedDate'];
    alertColumns[this.ALERT_TYPES.EARTAG_SENSOR_DROPPED] = ['detectedAt', 'cowGroupName', 'pen', 'bleId', 'cowNo', 'cowUid', 'solution', 'factor', 'cowLabels'];
    alertColumns[this.ALERT_TYPES.EARTAG_SENSOR] = ['detectedDate', 'cowGroupName', 'pen', 'bleId', 'cowNo', 'cowUid', 'anomalyTypes', 'cowLabels'];

    return alertColumns;
  }

  static alertColumnLabals(dictionary) {
    return {
      alertDate: '検知日',
      alertDateTime: '検知日時',
      alertPeakTime: 'ピーク時刻',
      alertLabels: 'アラート種別',
      cowLabels: '個体ラベル',
      occurredCowGroupName: '発生時牛群',
      cowGroupName: '牛群',
      cowNo: dictionary.COW.COW_NO,
      cowState: '状態',
      dysstasiaConfirmedStatus: '確認時の状態',
      dysstasiaComment: 'コメント',
      remarks: '備考',
      monthAge: '月齢',
      cowUid: '個体識別番号',
      calvingNotes: '備考',
      calvingSensorDroppedNotes: '備考',
      detectedAt: '検知日時',
      sensorNumber: 'センサー番号',
      pen: '牛房',
      deactivatedDate: '解除日',
      bleId: 'センサー番号',
      solution: '処置内容',
      factor: '脱落要因',
      score: 'スコア',
      detectedDate: '検知日',
      anomalyTypes: '異常種別'
    };
  }

  /**
   * 表示リミット
   */
  static get DISPLAY_LIMIT() {
    return 1000;
  }

  /**
   * CSV出力リミット
   */
  static get CSV_LIMIT() {
    return 2000;
  }

  /**
   * アラート・オブジェクトのパラメータで変換処理が必要なものを追加
   * @param {Alert.ALERT_TYPES} alertType アラートの種類
   * @param {array} alerts AlertService.alerts()で取得したアラートの配列
   * @param {Boolean} showAcuteIllness true: 疾病アラート改良版を表示する
   */
  static decorateAlerts(alertType, alerts, showAcuteIllness) {
    if (!alerts) {
      return [];
    }

    return alerts.map((alert) => {
      return Object.assign(alert, {
        alertDate: alert.alertDate,
        alertDateTime: this.getAlertDateTime(alertType, alert),
        alertPeakTime: this.getAlertPeakTime(alertType, alert),
        alertLabels: this.getAlertLabels(alert, showAcuteIllness),
        cowLabels: alert.cowLabels ? alert.cowLabels.split(',').map((label) => label.replace(/\[|\]/g, '')) : [],
        occurredCowGroupName: alert.occurredCowGroupName,
        cowGroupName: alert.cowGroupName,
        cowNo: alert.cowNo,
        cowState: alert.state,
        calvingNotes: this.generateCalvingNotes(alert.calvingNotes),
        calvingSensorDroppedNotes: this.generateCalvingSensorDroppedNotes(alert.calvingSensorDroppedNotes)
      });
    });
  }

  static decorateEartagDroppedAlerts(alerts) {
    if (!alerts) {
      return [];
    }

    return alerts.map((alert) => {
      return Object.assign(alert, {
        cowLabels: alert.cowLabels ? alert.cowLabels.split(',').map((label) => label.replace(/\[|\]/g, '')) : [],
        cowNo: alert.cowNo
      });
    });
  }

  static decorateEartagAlerts(alerts) {
    return this.decorateEartagDroppedAlerts(alerts);
  }

  static getAlertDateTime(alertType, alert) {
    switch (alertType) {
    case this.ALERT_TYPES.HEAT:
      return alert.heatAlertAt;
    case this.ALERT_TYPES.FALSE_HEAT:
      return alert.falseHeatPeakAt;
    case this.ALERT_TYPES.DYSSTASIA:
      return alert.dysstasiaAlertAt;
    case this.ALERT_TYPES.BAD_CONDITION:
    default:
      return alert.alertDate;
    }
  }

  static getAlertPeakTime(alertType, alert) {
    switch (alertType) {
    case this.ALERT_TYPES.HEAT:
      return alert.heatAlertAt;
    case this.ALERT_TYPES.FALSE_HEAT:
      return alert.falseHeatPeakAt;
    case this.ALERT_TYPES.BAD_CONDITION:
    case this.ALERT_TYPES.DYSSTASIA:
    default:
      return null;
    }
  }

  static getAlertLabels(alert, showAlertIllness) {
    const baseAlertTypes = {
      illness: '急性',
      chronicIllness: '慢性',
      milkLow: '乳量',
      feedLow: '採食',
      strongMoveLow: '動態(強)',
      waterLow: '飲水',
      lieHigh: '横臥'
    };
    const alertTypes = showAlertIllness ? Object.assign({acuteIllness: '急性(改)'}, baseAlertTypes) : baseAlertTypes;

    const alertLabels = [];

    Object.keys(alertTypes).forEach((type) => {
      if (!(type in alert)) return;
      if (alert[type] === false) return;
      if (type === 'chronicIllness' && (alert.illness || (alert.acuteIllness && showAlertIllness))) return;

      alertLabels.push(alertTypes[type]);
    });
    return alertLabels;
  }

  /**
   * アラートのリストをCSV文字列に変換する
   * @param {ALERT_TYPES} alertType アラート種別
   * @param {dictionary} Dictionary
   * @param {[OBject]} decoratedAlerts - decorateAlertsでデコレート済みのアラート・オブジェクトのリスト
   */
  static convertAlertsToCSVString(alertType, dictionary, decoratedAlerts = []) {
    const dateUtilService = new DateUtilService();

    const headerLines = [
      this.ALERT_COLUMNS[alertType].map((v) => {
        return this.alertColumnLabals(dictionary)[v];
      }).join(',')
    ];

    const bodyLines = decoratedAlerts.map((alert) => {
      return this.ALERT_COLUMNS[alertType]
        .map((column) => {
          switch (column) {
          case 'alertDate':
            return dateUtilService.toYYYYMMDD(alert.alertDate);
          case 'alertDateTime':
            return dateUtilService.toTimestampFormat(alert.alertDateTime);
          case 'alertPeakTime':
            return dateUtilService.toHHmm(alert.alertPeakTime);
          case 'alertLabels':
            return alert.alertLabels.join(', ');
          case 'cowLabels':
            return alert.cowLabels.join(', ');
          case 'dysstasiaAlertAt':
            return dateUtilService.toTimestampFormat(alert.dysstasiaAlertAt);
          case 'dysstasiaConfirmedStatus':
            return AlertDysstasia.confirmedStatusCaption(alert.dysstasiaConfirmedStatus);
          case 'remarks':
            return alert.unexpectedDysstasia ? '※アラートなし' : '';
          case 'monthAge':
            return DateUtil.monthAge(alert.birthday, alert.alertDate, 1) || '';
          case 'calvingNotes':
          case 'calvingSensorDroppedNotes':
            return alert[column].join('\n');
          case 'detectedAt':
            return dateUtilService.toYYYYMMDDHHmm(alert.detectedAt);
          case 'deactivatedDate':
            return dateUtilService.toYYYYMMDD(alert.deactivatedDate);
          default:
            return alert[column];
          }
        })
        .map((columnData) => {
          return this.escapeCSVStringIfNeeded(columnData);
        })
        .join(',');
    });

    return headerLines.concat(bodyLines).join('\n');
  }

  static convertAlertTypesToStates(alertType, farmType) {
    if (!alertType || !farmType) return '';
    switch (alertType) {
    case 'milk-low':
      return 'alertMilkLow';
    case 'feed-low':
      if (farmType === 'milk') return 'alertFeedLow';
      return 'alertFeedLowBeef';
    case 'water-low':
      if (farmType === 'milk') return 'alertWaterLow';
      return 'alertWaterLowBeef';
    case 'lie-high':
      if (farmType === 'milk') return 'alertLieHigh';
      return 'alertLieHighBeef';
    default:
      return '';
    }
  }

  static escapeCSVStringIfNeeded(str) {
    if (typeof str === 'string' && str.match(/[,"\n]/)) {
      return `"${str}"`;
    } else {
      return str;
    }
  }

  static generateCalvingNotes(calvingNotes) {
    if (!calvingNotes) return [];

    return calvingNotes.map((note) => {
      const alertLevel = AlertCalving.ALERT_LEVEL[note.alertLevel];
      const status = AlertCalving.CONFIRMED_STATUS[note.confirmedStatus] || '未確認';
      const comment = note.confirmedComment || '';
      return `${note.alertTime} ${alertLevel} ${status} ${comment}`;
    });
  }

  static generateCalvingSensorDroppedNotes(calvingSensorDroppedNotes) {
    if (!calvingSensorDroppedNotes) return [];

    return calvingSensorDroppedNotes.map((note) => {
      const solution = AlertCalvingSensorDropped.SOLUTION[note.solution] || '未確認';
      return `${note.alertTime} ${solution}`;
    });
  }
}
