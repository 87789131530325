class sideBoardController {
  constructor(
    $state,
    $window,
    $timeout,
    $rootScope,
    $stateParams,
    AlertService,
    SessionCache,
    CustomlistAPI,
    CowHeadageAPI,
    DailyReportAPI,
    DepositAlertAPI,
    CowGroupService,
    CustomlistRunner,
    SideBoardService,
    SwitchFarmDialog,
    TgvGroupConfigAPI,
    FatteningStageService,
    GroupHeadageDetailsAPI
  ) {
    'ngInject';

    this.$state = $state;
    this.$window = $window;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.AlertService = AlertService;
    this.CustomlistAPI = CustomlistAPI;
    this.CowHeadageAPI = CowHeadageAPI;
    this.DailyReportAPI = DailyReportAPI;
    this.DepositAlertAPI = DepositAlertAPI;
    this.CowGroupService = CowGroupService;
    this.CustomlistRunner = CustomlistRunner;
    this.SideBoardService = SideBoardService;
    this.SwitchFarmDialog = SwitchFarmDialog;
    this.TgvGroupConfigAPI = TgvGroupConfigAPI;
    this.FatteningStageService = FatteningStageService;
    this.GroupHeadageDetailsAPI = GroupHeadageDetailsAPI;

    const farm = SessionCache.farm();
    const customlistSystemConfigs = SessionCache.customlistSystemConfigs();

    this.init(farm, customlistSystemConfigs);
  }

  $onInit() {
    this.SideBoardService.setState('show', this.showSideBoard());
    this.decideCustomlistHeading();
    this.generateCowGroupList();
    this.generateFatteningStageList();
    this.generateUserDefinedList();
    this.generateGroupHeadageDetails();
    this.generateAlertList();
    this.generateReportList();
    this.generateCertificateList();
    this.generateTemporaryGroupList();
    this.generateTemporaryGroupPenList();
    this.generateGroupTransferList();
    this.generateHeadageDetails();
    this.generateDailyReportDetails();

    this.nameFilter = '';
    const cache = LocalStorageEntry.use(
      this.$rootScope.userName,
      this.localStorageKey(),
      {}
    );

    if (cache.inputAt) {
      const inputAt = new Date(cache.inputAt);
      if (DateUtil.isSameDay(inputAt, DateUtil.today())) {
        this.nameFilter = cache.nameFilter;
      }
    }
  }

  $doCheck() {
    if (!this.reload) return;

    if (this.category === 'daily-report-details') {
      this.generateDailyReportDetails();
    }

    this.reload = false;
  }

  init(farm, customlistSystemConfigs) {
    this.isDepositor = farm.isDepositor();
    this.isBeef = farm.isBeef();
    this.isDairy = farm.isDairy();
    this.isBreeding = farm.isBreeding();
    this.isFattening = farm.isFattening();
    this.certificateAiMenus = farm.certificateAiMenus();
    this.showEmbryoRecoveryReport = farm.isDairy() || farm.isBreeding();
    this.calfManagement = farm.data.calfManagement;
    this.showCalving = farm.useAlertCalving();
    this.showSensor = farm.useAlertSensor();
    this.showtDropoutEartagSensor = farm.detectDropoutEartagSensor();
    this.showGenomicEvaluationList = farm.showBeefGenom() && !this.isDepositor;
    this.contractedPlan = farm.data.contractedPlan;
    this.useCalfManagement = farm.data.useCalfManagement;
    this.generateCustomlistSystemConfigs(customlistSystemConfigs);
    this.setSideBoardOpened();
    this.scrollIntoView();
  }

  generateCustomlistSystemConfigs(configs) {
    this.customlistSystemConfigs = configs.reduce((acc, cur) => {
      acc[cur.configType] = {
        customlistId: cur.customlistId,
        configType: cur.configType
      };

      return acc;
    }, {});
  }

  generateCowGroupList() {
    if (!this.showCowGroupList()) return;

    this.CowGroupService.summary().then((res) => {
      this.rawItems = res.data.filter((item) => {
        return item.menuDisplayed;
      }).reduce((acc, cur) => {
        let items = {
          cowGroupId: cur.cowGroupId,
          name: cur.cowGroupName,
          cowCount: cur.cowCount,
          active: cur.cowGroupId === Number(this.$stateParams.cow_group_id)
        };

        if (cur.calfGroup) {
          items = Object.assign(items, this.customlistSystemConfigs.group_calf);
        } else if (cur.reproductionGroup) {
          items = Object.assign(items, this.customlistSystemConfigs.group_reproduction);
        } else if (cur.cullingGroup) {
          items = Object.assign(items, this.customlistSystemConfigs.group_culling);
        } else if (cur.milkingGroup) {
          items = Object.assign(items, this.customlistSystemConfigs.group_milking);
        } else if (cur.dryGroup) {
          items = Object.assign(items, this.customlistSystemConfigs.group_dry);
        } else if (cur.fatteningGroup) {
          items = Object.assign(items, this.customlistSystemConfigs.group_fattening);
        } else {
          items = Object.assign(items, this.customlistSystemConfigs.group_reproduction);
        }

        acc.push(items);

        return acc;
      }, []);

      this.refreshItems();
    });
  }

  generateFatteningStageList() {
    if (!this.showFatteningStageList()) return;

    this.FatteningStageService.index().then((res) => {
      this.items = res.data.reduce((acc, cur) => {
        let items = {
          fatteningStageId: cur.id,
          name: `${cur.name}[${cur.startMonthsOfAge}-${cur.endMonthsOfAge}ヶ月]`,
          active: cur.id === Number(this.$stateParams.fattening_stage_id)
        };

        items = Object.assign(items, this.customlistSystemConfigs.fattening_stage);
        acc.push(items);

        return acc;
      }, []);
    });
  }

  generateUserDefinedList() {
    if (!this.showUserDefinedList()) return;

    this.CustomlistAPI.index().then((res) => {
      this.rawItems = res.data.filter((item) => {
        return item.showInMenu;
      }).map((item) => {
        return {
          customlistId: item.customlistId,
          name: item.name,
          recordNumber: item.recordNumber,
          active: item.customlistId === Number(this.$stateParams.customlistId)
        };
      });

      this.refreshItems();
    });
  }

  generateGroupHeadageDetails() {
    if (!this.showGroupHeadageDetails()) return;

    const categoryLabels = {
      dairy: '酪農',
      breeding: '繁殖',
      fattening: '肥育',
    };

    this.heading = categoryLabels[this.$stateParams.farm_type];

    this.GroupHeadageDetailsAPI.farm(this.$stateParams.farm_type).then((res) => {
      this.items = res.data.summary.farms.map((item) => {
        return {
          name: item.farmName,
          active: item.farmName === this.$stateParams.name
        };
      });
    });
  }

  generateAlertList() {
    if (!this.showAlertList()) return;

    this.getAlertSummary().then((res) => {
      const hiddenEartagSensorDropped = !this.showtDropoutEartagSensor || res.eartagSensorDropped === 0;

      const columns = [{
        category: '緊急アラート',
        hidden: !(this.showDysstasia(res.dysstasia) || this.showDysstasiaResolved(res.dysstasiaResolved)),
        fields: [{
          field: 'dysstasia',
          addClassName: 'dysstasia',
          name: '起立困難',
          count: res.dysstasia,
          hidden: !this.showDysstasia(res.dysstasia),
          active: this.activeAlert('dysstasia'),
          to: 'alertDysstasia',
          params: {
            resolved: undefined
          }
        }, {
          field: 'dysstasiaResolved',
          name: '確認済・回復済',
          count: res.dysstasiaResolved,
          hidden: !this.showDysstasiaResolved(res.dysstasiaResolved),
          active: this.activeAlert('dysstasiaResolved'),
          to: 'alertDysstasia',
          params: {
            resolved: true
          }
        }]
      }, {
        category: 'センサー異常',
        hidden: (res.sensor === 0 || !this.showSensor) && hiddenEartagSensorDropped,
        fields: [
          {
            field: 'alertSensor',
            addClassName: 'alertSensor',
            name: 'センサー異常',
            count: res.sensor,
            hidden: res.sensor === 0 || !this.showSensor,
            active: this.activeAlert('alertSensor'),
            to: 'alertSensor',
          },
          {
            field: 'alertEartagSensorDropped',
            name: 'イヤタグセンサー脱落',
            count: res.eartagSensorDropped,
            hidden: hiddenEartagSensorDropped,
            active: this.activeAlert('alertEartagSensorDropped'),
            to: 'alert-eartag-sensor-dropped',
          },
        ],
      }, {
        category: '分娩',
        hidden: this.isDepositor || !this.showCalving,
        fields: [{
          field: 'alertCalving',
          addClassName: 'alertCalving',
          name: '分娩兆候（未確認）',
          count: res.calving,
          disabled: res.calving === 0,
          hidden: this.isDepositor || !this.showCalving,
          active: this.activeAlert('alertCalving'),
          to: 'alert-calving',
          params: {}
        }, {
          field: 'alertCalvingConfirmed',
          name: '確認済',
          count: res.calvingConfirmed,
          disabled: res.calvingConfirmed === 0,
          hidden: this.isDepositor || !this.showCalving,
          active: this.activeAlert('alertCalvingConfirmed'),
          to: 'alert-calving-confirmed',
          params: {}
        }, {
          field: 'alertCalvingSensorDropped',
          name: '分娩センサー脱落',
          count: res.calvingSensorDropped,
          hidden: this.isDepositor || !this.showCalving || res.calvingSensorDropped === 0,
          active: this.activeAlert('alertCalvingSensorDropped'),
          to: 'alert-calving-sensor-dropped',
          params: {}
        }]
      }, {
        category: '発情',
        fields: [{
          field: 'heat',
          addClassName: 'heat',
          name: '発情兆候',
          count: res.heat,
          disabled: res.heat === 0,
          active: this.activeAlert('heat'),
          to: 'alertList',
          params: {
            customlistId: this.customlistSystemConfigs.alert_heat.customlistId,
            filter: undefined
          }
        }, {
          field: 'falseHeat',
          name: '妊娠牛',
          count: res.falseHeat,
          disabled: res.falseHeat === 0,
          active: this.activeAlert('falseHeat'),
          to: 'alertList',
          params: {
            customlistId: this.customlistSystemConfigs.alert_false_heat.customlistId,
            filter: undefined
          }
        }]
      }, {
        category: '注意牛',
        fields: [{
          field: 'badConditionCow',
          addClassName: 'badConditionCow',
          name: '注意牛',
          count: res.badConditionCow,
          disabled: res.badConditionCow === 0,
          active: this.activeAlert('badConditionCow'),
          to: 'alertList',
          params: {
            customlistId: this.customlistSystemConfigs.alert_bad_condition.customlistId,
            filter: undefined
          }
        }, {
          field: 'illness',
          addClassName: 'illness',
          name: '疾病傾向',
          count: res.illness,
          disabled: res.illness === 0,
          hidden: this.isDepositor,
          active: this.activeAlert('illness'),
          to: 'alertList',
          params: {
            customlistId: this.customlistSystemConfigs.alert_illness.customlistId,
            filter: 'illness'
          }
        }, {
          field: 'milkLow',
          name: '乳量低下',
          count: res.milkLow,
          disabled: res.milkLow === 0,
          hidden: this.isDepositor || !this.isDairy,
          active: this.activeAlert('milkLow'),
          to: 'alertMilkLow'
        }, {
          field: 'feedLow',
          name: '採食時間低下',
          count: res.feedLow,
          disabled: res.feedLow === 0,
          hidden: this.isDepositor || this.contractedPlan === 'light',
          active: this.activeAlert('feedLow'),
          to: this.isDairy ? 'alertFeedLow' : 'alertFeedLowBeef'
        }, {
          field: 'strongMoveLow',
          name: '動態(強)低下',
          count: res.strongMoveLow,
          disabled: res.strongMoveLow === 0,
          hidden: this.isDepositor || !this.useCalfManagement,
          active: this.activeAlert('strongMoveLow'),
          to: 'alertList',
          params: {
            customlistId: this.customlistSystemConfigs.alert_bad_condition.customlistId,
            filter: 'strongMoveLow'
          }
        }, {
          field: 'lieHigh',
          name: '横臥時間増加',
          count: res.lieHigh,
          disabled: res.lieHigh === 0,
          hidden: this.isDepositor,
          active: this.activeAlert('lieHigh'),
          to: this.isDairy ? 'alertLieHigh' : 'alertLieHighBeef'
        }, {
          field: 'waterLow',
          name: '飲水回数低下',
          count: res.waterLow,
          disabled: res.waterLow === 0,
          hidden: this.isDepositor || this.contractedPlan !== 'full',
          active: this.activeAlert('waterLow'),
          to: this.isDairy ? 'alertWaterLow' : 'alertWaterLowBeef'
        }]
      }, {
        category: '過去のアラート',
        hidden: true,
        fields: [{
          field: 'alertHistory',
          addClassName: 'history',
          name: '過去のアラートを確認する',
          count: null,
          hidden: this.isDepositor,
          active: this.activeAlert('alertHistory'),
          to: 'alert-history'
        }, {
          field: 'alertHistory',
          addClassName: 'history',
          name: '過去のアラートを確認する',
          count: null,
          hidden: !this.isDepositor,
          active: this.activeAlert('alertHistory'),
          to: 'alertList',
          params: {
            customlistId: this.customlistSystemConfigs.alert_history.customlistId,
            filter: undefined
          }
        }]
      }];

      this.items = columns;
    });
  }

  generateReportList() {
    if (!this.showReportList()) return;

    const columns = [{
      field: 'report-summary',
      name: 'サマリー'
    }, {
      field: 'fattening_report',
      name: '肉牛レポート',
      hidden: !this.isBeef
    }, {
      field: 'dairy-technical-report',
      name: 'テクニカルレポート',
      hidden: !this.isDairy
    }, {
      field: 'lactation-performance',
      name: '泌乳成績',
      hidden: !this.isDairy
    }, {
      field: 'reproduction-performance-report',
      name: '繁殖成績'
    }, {
      field: 'reproduction-performance-report-monthly',
      name: '繁殖成績（月次）'
    }, {
      field: 'genomic-evaluation-list',
      name: 'ゲノミック評価',
      hidden: !this.showGenomicEvaluationList,
    }, {
      field: 'embryoRecoveryReport',
      name: '採卵成績',
      hidden: !this.showEmbryoRecoveryReport
    }, {
      field: 'carcass-report',
      name: '枝肉成績'
    }];

    this.items = columns.filter((item) => {
      return !item.hidden;
    }).map((item) => {
      return {
        field: item.field,
        name: item.name,
        active: item.field === this.$state.current.name
      };
    });
  }

  generateCertificateList() {
    if (!this.showCertificateList()) return;

    const columns = [{
      category: '出生',
      hidden: !(this.isDairy || this.isBreeding) || !['always', 'calved'].includes(this.calfManagement),
      fields: [{
        field: 'certificate-birth',
        name: '出生報告',
        hidden: !(this.isDairy || this.isBreeding) || !['always', 'calved'].includes(this.calfManagement),
        active: this.$state.current.name === 'certificate-birth'
      }]
    }, {
      category: '繁殖',
      fields: [
        {
          field: 'certificate-ai',
          name: '授精証明書',
          hidden: !this.certificateAiMenus.includes('standard'),
          active: this.$state.current.name === 'certificate-ai'
        },
        {
          field: 'certificate-ai-specific',
          name: '授精証明書 (鹿児島版)',
          params: {menu_id: 'kagoshima'},
          hidden: !this.certificateAiMenus.includes('kagoshima'),
          active: this.$state.current.name === 'certificate-ai-specific'
        }
      ]
    }];

    this.items = columns;
  }

  generateTemporaryGroupList() {
    if (!this.showTemporaryGroupList()) return;

    this.TgvGroupConfigAPI.groupSummary().then((res) => {
      const groupSummary = res.data.summary.groupSummary;

      this.items = groupSummary.map((item) => {
        item.active = item.groupName === this.$stateParams.groupName;

        return item;
      });
    });
  }

  generateTemporaryGroupPenList() {
    if (!this.showTemporaryGroupPenList()) return;

    const today = DateUtil.today().getTime();
    const option = {
      cowGroupId: this.$stateParams.cowGroupId
    };

    this.CustomlistAPI.cowGroups().then((res) => {
      const master = res.data.find((m) => m.configType === 'group_fattening');
      const customlistId = master.customlistId;

      return this.CustomlistRunner.run(
        customlistId,
        today,
        option
      ).then((res) => {
        this.items = Object.entries(res.data.cows.reduce((acc, cur) => {
          const pen = cur.pen || '-';

          if (!acc[pen]) {
            acc[pen] = [];
          }

          acc[pen].push(cur.cow);

          return acc;
        }, {})).map(([key, value]) => {
          return {
            pen: key,
            cowCount: value.length,
            cowGroupId: this.$stateParams.cowGroupId,
            active: key === this.$stateParams.pen
          };
        });
      });
    });
  }

  generateGroupTransferList() {
    if (!this.showGroupTransferList()) return;

    const columns = [{
      field: 'group-transfer-input',
      name: '異動入力'
    }, {
      field: 'group-transfer-history',
      name: '入力履歴'
    }];

    this.items = columns.map((item) => {
      return {
        field: item.field,
        name: item.name,
        active: item.field === this.$state.current.name
      };
    });
  }

  generateHeadageDetails() {
    if (!this.showHeadageDetails()) return;

    const context = Object.assign({isFattening: this.isFattening}, this.$stateParams);

    this.heading = HeadageSummaryDefinition.categories(context.category);

    this.CowHeadageAPI.summary().then((res) => {
      const summary = HeadageSummaryDefinition.updateSummary(res.data, context);
      const items = summary.filter((item) => {
        return item.count > 0;
      });

      this.items = HeadageSummaryDefinition.grouping(items);

      if (context.category === 'breed') {
        this.items = HeadageSummaryDefinition.sortBreedItems(this.items);
      }
    });
  }

  generateDailyReportDetails() {
    if (!this.showDailyReportDetails()) return;

    this.date = this.$stateParams.date;

    this.DailyReportAPI.summary(this.date).then((res) => {
      const summary = DailyReportSummaryDefinition.updateSummary(res.data, this.$stateParams);
      const items = summary.filter((item) => {
        return item.count > 0;
      });

      this.items = DailyReportSummaryDefinition.grouping(items);
    });
  }

  getAlertSummary() {
    if (this.isDepositor) {
      return this.DepositAlertAPI.summary().then((res) => {
        const alerts = {
          heat: 'heat',
          falseHeat: 'falseHeat',
          dysstasia: 'dysstasia',
          badCondition: 'badConditionCow'
        };

        return Object.entries(res.data.summary).reduce((arr, [key, value]) => {
          arr[alerts[key]] = value;

          return arr;
        }, {});
      });
    }

    return this.AlertService.summary().then((res) => {
      let summary = {};
      const alerts = {
        MILK_LOW: 'milkLow',
        FEED_LOW: 'feedLow',
        WATER_LOW: 'waterLow',
        LIE_HIGH: 'lieHigh',
        HEAT: 'heat',
        FALSE_HEAT: 'falseHeat',
        DYSSTASIA: 'dysstasia',
        DYSSTASIA_RESOLVED: 'dysstasiaResolved',
        ILLNESS: 'illness',
        BAD_CONDITION_COW: 'badConditionCow',
        SENSOR_DROPPED: 'sensorDropped',
        SENSOR: 'sensor',
        CALVING: 'calving',
        CALVING_CONFIRMED: 'calvingConfirmed',
        CALVING_SENSOR_DROPPED: 'calvingSensorDropped',
        STRONG_MOVE_LOW: 'strongMoveLow',
        EARTAG_SENSOR_DROPPED: 'eartagSensorDropped'
      };

      res.data.forEach((alert) => {
        if (alerts[alert.alertType]) {
          summary[alerts[alert.alertType]] = alert.count;
        }
      });

      return summary;
    });
  }

  setSideBoardOpened() {
    if (!this.$window.matchMedia('(pointer: coarse) and (min-height: 700px)').matches) {
      this.SideBoardService.setState('opened', true);
    }
  }

  toggleSideBoard() {
    this.SideBoardService.toggleState('opened');
  }

  goTo(to, params) {
    params = params || {};
    this.$state.go(to, params);
  }

  switchFarm() {
    this.SwitchFarmDialog.excute();
  }

  goToDailyReportDetails(days = 0) {
    const to = 'daily-report-details';
    const params = {
      date: DateUtil.toW3CFormat(DateUtil.addDays(this.date, days)),
      eventType: this.$stateParams.eventType
    };

    this.$timeout(() => {
      this.$state.go(to, params);
    });
  }

  decideTemplateUrl(category) {
    const fileName = `${category}.html`;
    return `components/side-board/${fileName}`;
  }

  decideCustomlistHeading() {
    if (this.category !== 'customlist') return;

    if (this.configType === 'user_defined') {
      this.heading = '個別リスト';
    } else if (this.configType === 'fattening_stage') {
      this.heading = '月齢別ステージリスト';
    } else if (this.configType === 'deposit') {
      this.heading = '全頭一覧';
    } else {
      this.heading = '牛群一覧';
    }
  }

  showSideBoard() {
    return this.showCowGroupList() || this.showFatteningStageList() || this.showUserDefinedList() ||
      this.showGroupHeadageDetails() || this.showAlertList() || this.showReportList() ||
      this.showCertificateList() || this.showTemporaryGroupList() || this.showTemporaryGroupPenList() ||
      this.showGroupTransferList() || this.showGroupManagementList() ||
      this.showHeadageDetails() || this.showDailyReportDetails();
  }

  showCowGroupList() {
    return !this.isDepositor && this.category === 'customlist' &&
      !['user_defined', 'fattening_stage'].includes(this.configType);
  }

  showFatteningStageList() {
    return !this.isDepositor && this.category === 'customlist' && this.configType === 'fattening_stage';
  }

  showUserDefinedList() {
    return this.category === 'customlist' && this.configType === 'user_defined';
  }

  showGroupHeadageDetails() {
    return this.category === 'customlist' && this.configType === 'deposit' && this.$stateParams.groupHeadageDetails;
  }

  showAlertList() {
    return this.category === 'alert';
  }

  showDysstasia(count) {
    return this.isBeef && count;
  }

  showDysstasiaResolved(count) {
    return this.isBeef && count;
  }

  showReportList() {
    return !this.isDepositor && this.category === 'report';
  }

  showCertificateList() {
    return this.category === 'certificate';
  }

  showTemporaryGroupList() {
    return this.category === 'temporary-group';
  }

  showTemporaryGroupPenList() {
    return this.category === 'temporary-group-pen';
  }

  showGroupTransferList() {
    return this.category === 'group-transfer';
  }

  showGroupManagementList() {
    return this.category === 'group-management';
  }

  showHeadageDetails() {
    return this.category === 'headage-details';
  }

  showDailyReportDetails() {
    return this.category === 'daily-report-details';
  }

  showNameFilter() {
    return this.showUserDefinedList() || this.showCowGroupList();
  }

  refreshItems() {
    if (!this.rawItems) return;

    this.items = this.rawItems.filter((item) => {
      if (!this.nameFilter) return true;
      return item.name.includes(this.nameFilter);
    });
  }

  localStorageKey() {
    if (this.showUserDefinedList()) {
      return CustomlistIndexController.LOCAL_STORAGE_NAME_FILTER_KEY;
    }
    if (this.showCowGroupList()) {
      return 'CowGroup-NameFilter';
    }
    return 'DUMMY';
  }

  onChangeNameFilter() {
    this.refreshItems();

    if (this.items.length) {
      LocalStorageEntry.set(
        this.$rootScope.userName,
        this.localStorageKey(),
        {nameFilter: this.nameFilter, inputAt: new Date().getTime()}
      );
    }
  }

  onClickNameFilterOff() {
    this.nameFilter = '';
    this.refreshItems();

    LocalStorageEntry.set(
      this.$rootScope.userName,
      this.localStorageKey(),
      {}
    );
  }

  activeAlert(type) {
    switch (type) {
    case 'dysstasia':
      return this.$state.current.name === 'alertDysstasia' && typeof this.$stateParams.resolved === 'undefined';
    case 'dysstasiaResolved':
      return this.$state.current.name === 'alertDysstasia' && this.$stateParams.resolved;
    case 'alertCalving':
      return this.$state.current.name === 'alert-calving';
    case 'alertCalvingConfirmed':
      return this.$state.current.name === 'alert-calving-confirmed';
    case 'alertCalvingSensorDropped':
      return this.$state.current.name === 'alert-calving-sensor-dropped';
    case 'heat':
      return Number(this.$stateParams.customlistId) === this.customlistSystemConfigs.alert_heat.customlistId;
    case 'falseHeat':
      return Number(this.$stateParams.customlistId) === this.customlistSystemConfigs.alert_false_heat.customlistId;
    case 'badConditionCow':
      return Number(this.$stateParams.customlistId) ===
        this.customlistSystemConfigs.alert_bad_condition.customlistId &&
        typeof this.$stateParams.filter === 'undefined';
    case 'illness':
      return Number(this.$stateParams.customlistId) ===
        this.customlistSystemConfigs.alert_illness.customlistId &&
        this.$stateParams.filter === 'illness';
    case 'milkLow':
      return this.$stateParams.alertType === 'milk-low';
    case 'feedLow':
      return this.$stateParams.alertType === 'feed-low';
    case 'strongMoveLow':
      return Number(this.$stateParams.customlistId) ===
        this.customlistSystemConfigs.alert_bad_condition.customlistId &&
        this.$stateParams.filter === 'strongMoveLow';
    case 'lieHigh':
      return this.$stateParams.alertType === 'lie-high';
    case 'waterLow':
      return this.$stateParams.alertType === 'water-low';
    case 'alertHistory':
      if (this.isDepositor) {
        return Number(this.$stateParams.customlistId) === this.customlistSystemConfigs.alert_history.customlistId;
      }

      return this.$state.current.name === 'alert-history';
    case 'alertSensor':
      return this.$state.current.name === 'alertSensor';
    case 'alertEartagSensorDropped':
      return this.$state.current.name === 'alert-eartag-sensor-dropped';
    }
  }

  classSideBoard() {
    if (!this.showSideBoard()) {
      return 'uSideBoard--hidden';
    }

    return this.SideBoardService.getState('opened') ? 'uSideBoard' : 'uSideBoard--closed';
  }

  classItem(active, disabled) {
    if (disabled) {
      return 'uSideBoard__item--disabled';
    }

    return active ? 'uSideBoard__item--active' : 'uSideBoard__item';
  }

  classLink(addClassName) {
    return addClassName ? `uSideBoard__link--${addClassName}` : 'uSideBoard__link';
  }

  scrollIntoView() {
    const timer = setInterval(() => {
      const active = document.querySelector('.uSideBoard__item--active');
      const itemsLength = this.items ? this.items.length : 0;
      const linkLength = document.querySelectorAll('.uSideBoard__link').length;

      if (active && itemsLength === linkLength) {
        active.scrollIntoView({
          behavior: 'auto',
          block: 'center'
        });

        clearInterval(timer);
      }
    }, 100);
  }
}

function sideBoard() {
  return {
    restrict: 'E',
    template: '<div ng-class="ctrl.classSideBoard()"><div ng-include="ctrl.decideTemplateUrl(ctrl.category)" class="uSideBoard__inner"></div></div>',
    controller: sideBoardController,
    controllerAs: 'ctrl',
    scope: true,
    replace: true,
    bindToController: {
      category: '@',
      configType: '@',
      reload: '='
    }
  };
}

app.directive('sideBoard', sideBoard);
