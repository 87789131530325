function beefHistoryPrint() {
  'ngInject';

  return {
    restrict: 'E',
    scope: true,
    templateUrl: 'shipment/beef-history/print/beef-history-print.html',
  };
}
app.directive('beefHistoryPrint', beefHistoryPrint);
