class AlertEartagSensorDroppedCardController {
  constructor() {
    'ngInject';
  }

  handleCowNoClick() {
    this.onCowNoClick({cowId: this.alertEartagSensorDropped.cowId});
  }

  handleSolutionSelectionClick() {
    this.onSolutionSelectionClick({id: this.alertEartagSensorDropped.id, active: this.alertEartagSensorDropped.active});
  }
}

function alertEartagSensorDroppedCardComponents() {
  return {
    templateUrl: 'menu/alert/eartag-sensor-dropped/card.html',
    controller: AlertEartagSensorDroppedCardController,
    bindings: {
      alertEartagSensorDropped: '<',
      onCowNoClick: '&',
      onSolutionSelectionClick: '&'
    }
  };
}

app.component('alertEartagSensorDroppedCard', alertEartagSensorDroppedCardComponents());
