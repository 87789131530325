class MobileEditLabelModalController {
  constructor(
    $modalInstance,
    MasterCowLabelAPI,
    CowLabelAPI,
    CowService,
    MobileConfirmModal,
    blockUI,
    cow
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.CowLabelAPI = CowLabelAPI;
    this.CowService = CowService;
    this.MobileConfirmModal = MobileConfirmModal;
    this.blockUI = blockUI;
    this.cow = cow;

    this.onInit(MasterCowLabelAPI);
  }

  onInit(MasterCowLabelAPI) {
    MasterCowLabelAPI.available().then((res) => {
      const labels = Cow.labelStringToArray(this.cow.cowLabels);
      const masters = CollectionUtil.selectableMasters(res.data, labels, 'name');

      this.cowLabels = masters.map((m) => {
        return {
          value: m.id,
          label: m.name,
          checked: labels.includes(m.name) ? true : false
        };
      });
    });
  }

  onCloseClick() {
    this.$modalInstance.dismiss({
      status: {
        cancel: true
      }
    });
  }

  onSubmitClick() {
    this.blockUI.start('ラベル貼付中');

    const labels = this.cowLabels
      .filter((label) => label.checked)
      .map((label) => {
        return {
          masterCowLabelId: label.value
        };
      });

    this.CowLabelAPI.update(this.cow.cowId, labels).then(() => {
      this.CowService.get(this.cow.cowId).then((res) => {
        const cowLabels = res.data.cowLabels;
        const cowLabelList = Cow.labelStringToArray(cowLabels).map((cowLabel) => {
          return {text: cowLabel};
        });
        this.blockUI.stop();
        this.$modalInstance.close({
          status: {
            ok: true
          },
          cowLabels,
          cowLabelList
        });
      }).catch((err) => {
        onSubmitError(err);
      });
    }).catch((err) => {
      onSubmitError(err);
    });

    const onSubmitError = (err) => {
      console.error(err);
      this.blockUI.stop();
      this.MobileConfirmModal.open('エラーが発生しました').then(() => {
        this.$modalInstance.dismiss({
          status: {
            error: true
          }
        });
      });
    };
  }
}

app.controller('MobileEditLabelModalController', MobileEditLabelModalController);
