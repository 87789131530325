// eslint-disable-next-line no-unused-vars
class HomeDailyReportTimelineItem {
  static items(eventType, useName, canEditFilterItem) {
    const itemIds = HomeDailyReportTimelineItem.itemIds(eventType, useName, canEditFilterItem);
    return TimelineItemDefinition.itemDefinitions(itemIds);
  }

  static itemIds(eventType, useName, canEditFilterItem) {
    switch (eventType) {
    case 'mastitis':
      return HomeDailyReportTimelineItem.mastitisItemsIds();
    case 'claw_diseases':
      return HomeDailyReportTimelineItem.clawDiseasesItemsIds();
    case 'reproductive_disorders':
      return HomeDailyReportTimelineItem.reproductivedisordersItemsIds();
    case 'perinatal_and_metabolic_diseases':
      return HomeDailyReportTimelineItem.perinatalAndMetabolicDiseasesItemsIds();
    case 'injured':
      return HomeDailyReportTimelineItem.injuredItemsIds();
    case 'infection_diseases':
      return HomeDailyReportTimelineItem.infectionDiseasesItemsIds();
    case 'others':
      return HomeDailyReportTimelineItem.othersItemsIds();
    case 'fresh_check':
      return HomeDailyReportTimelineItem.freshCheckItemsIds();
    case 'heat':
      return HomeDailyReportTimelineItem.heatItemsIds();
    case 'bred':
      return HomeDailyReportTimelineItem.bredItemsIds();
    case 'pregnant_diagnosis':
      return HomeDailyReportTimelineItem.pregnantDiagnosisItemsIds();
    case 'aborted':
      return HomeDailyReportTimelineItem.abortedItemsIds();
    case 'calved':
      return HomeDailyReportTimelineItem.calvedItemsIds();
    case 'timed_ai':
      return HomeDailyReportTimelineItem.timedAiItemsIds();
    case 'do_not_breed':
      return HomeDailyReportTimelineItem.doNotBreedItemsIds();
    case 'embryo_recovery':
      return HomeDailyReportTimelineItem.embryoRecoveryItemsIds();
    case 'body_weight_measurement':
      return HomeDailyReportTimelineItem.bodyWeightMeasurementItemsIds();
    case 'work_note':
      return HomeDailyReportTimelineItem.workNoteItemsIds();
    case 'vaccination':
      return HomeDailyReportTimelineItem.vaccinationItemsIds();
    case 'dried':
      return HomeDailyReportTimelineItem.driedItemsIds();
    case 'culled':
      return HomeDailyReportTimelineItem.culledItemsIds(canEditFilterItem);
    case 'died':
      return HomeDailyReportTimelineItem.diedItemsIds();
    case 'claw_trimmed':
      return HomeDailyReportTimelineItem.clawTrimmedItemsIds();
    case 'moved':
      return HomeDailyReportTimelineItem.movedItemsIds();
    case 'carcass_characteristic':
      return HomeDailyReportTimelineItem.carcassCharacteristicItemsIds(canEditFilterItem);
    case 'individual_observation':
      return HomeDailyReportTimelineItem.individualObservationItemsIds();
    case 'weaned':
      return HomeDailyReportTimelineItem.weanedItemsIds();
    case 'dehorned':
      return HomeDailyReportTimelineItem.dehornedItemsIds();
    case 'castrated':
      return HomeDailyReportTimelineItem.castratedItemsIds();
    case 'fattened':
      return HomeDailyReportTimelineItem.fattenedItemsIds();
    case 'farm_diary':
      return HomeDailyReportTimelineItem.farmDiaryItemsIds();
    default:
      throw `不正なeventType: ${eventType}`;
    }
  }

  static mastitisItemsIds() {
    return [
      'occurredDiseaseDate',
      'mastitisDiagnosisResultOfBreastFl',
      'mastitisDiagnosisResultOfBreastFr',
      'mastitisDiagnosisResultOfBreastBl',
      'mastitisDiagnosisResultOfBreastBr',
      'medications',
      'moveToCowGroupName',
      'workerName',
      'mastitisCowGroupName',
      'mastitisScore',
      'mastitisBacteriaOfBreastFl',
      'mastitisBacteriaOfBreastFr',
      'mastitisBacteriaOfBreastBl',
      'mastitisBacteriaOfBreastBr',
      'bodyTemperature',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'comment'
    ];
  }

  static clawDiseasesItemsIds() {
    return [
      'occurredDiseaseDate',
      'locomotionScore',
      'medications',
      'moveToCowGroupName',
      'workerName',
      'bodyTemperature',
      'diagnosisResultDetail',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'comment'
    ];
  }

  static reproductivedisordersItemsIds() {
    return [
      'occurredDiseaseDate',
      'difficultiesOvaryStill',
      'difficultiesOvaryCystic',
      'difficultiesRemnant',
      'difficultiesMetritis',
      'difficultiesPyometra',
      'medications',
      'workerName',
      'diagnosisResultOfOvaryL',
      'diagnosisResultOfOvaryR',
      'uterusDiagnosisResult',
      'bodyTemperature',
      'diagnosisResultDetail',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'comment'
    ];
  }

  static perinatalAndMetabolicDiseasesItemsIds() {
    return [
      'occurredDiseaseDate',
      'difficultiesKetosis',
      'difficultiesFattyLiver',
      'difficultiesAbomasumDisplacement',
      'difficultiesHypocalcium',
      'difficultiesAcidosis',
      'difficultiesMetritis',
      'difficultiesDowner',
      'difficultiesStagnantFood',
      'difficultiesPlacenta',
      'difficultiesTympanites',
      'medications',
      'moveToCowGroupName',
      'workerName',
      'bodyTemperature',
      'diagnosisResultDetail',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'comment'
    ];
  }

  static injuredItemsIds() {
    return [
      'occurredDiseaseDate',
      'difficultiesHipDislocation',
      'difficultiesTeatInjury',
      'otherDisease',
      'medications',
      'workerName',
      'bodyTemperature',
      'diagnosisResultDetail',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'comment'
    ];
  }

  static infectionDiseasesItemsIds() {
    return [
      'occurredDiseaseDate',
      'difficultiesPneumonia',
      'difficultiesDiarrhea',
      'difficultiesSkinDisease',
      'otherDisease',
      'medications',
      'moveToCowGroupName',
      'workerName',
      'bodyTemperature',
      'diagnosisResultDetail',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'comment'
    ];
  }

  static othersItemsIds() {
    return [
      'occurredDiseaseDate',
      'otherDisease',
      'medications',
      'moveToCowGroupName',
      'workerName',
      'bodyTemperature',
      'diagnosisResultDetail',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'comment'
    ];
  }

  static freshCheckItemsIds() {
    return [
      'diagnosisResultOfOvaryL',
      'diagnosisResultOfOvaryR',
      'uterusDiagnosisResult',
      'bcs',
      'otherReproductionWork',
      'freshCheckResult',
      'comment'
    ];
  }

  static heatItemsIds() {
    return [
      'plannedBredMethod',
      'plannedBredAtDate',
      'plannedBredAtTime',
      'heatComment'
    ];
  }

  static bredItemsIds(useName) {
    if (useName) {
      return [
        'tanetsukeMethod',
        'masterSpermName',
        'masterSpermNo',
        'workerName',
        'inseminationCode',
        'otherReproductionWork',
        'donorRegistrationNo',
        'donorName',
        'donorBreed',
        'donorSpermNo',
        'motherBreedOfDonor',
        'motherRegistrationNoOfDonor',
        'motherNameOfDonor',
        'bullCowIdUseName',
        'comment'
      ];
    }

    return [
      'tanetsukeMethod',
      'masterSpermName',
      'masterSpermNo',
      'workerName',
      'inseminationCode',
      'otherReproductionWork',
      'donorRegistrationNo',
      'donorName',
      'donorBreed',
      'donorSpermNo',
      'motherBreedOfDonor',
      'motherRegistrationNoOfDonor',
      'motherNameOfDonor',
      'bullCowId',
      'comment'
    ];
  }

  static pregnantDiagnosisItemsIds() {
    return [
      'ninshinkanteiResult',
      'workerName',
      'judgePregnantTiming',
      'otherReproductionWork',
      'targetBreedingDate',
      'comment'
    ];
  }

  static abortedItemsIds() {
    return [
      'afterCowState',
      'nextStartMilkingFlg',
      'comment'
    ];
  }

  static calvedItemsIds() {
    return [
      'birthResult01',
      'breed01',
      'gender01',
      'registered01',
      'cowNo01',
      'cowUid01',
      'weightOnBirth01',
      'calfNo01',
      'birthStateString01',
      'birthResult02',
      'breed02',
      'gender02',
      'registered02',
      'cowNo02',
      'cowUid02',
      'weightOnBirth02',
      'calfNo02',
      'birthStateString02',
      'birthResult03',
      'breed03',
      'gender03',
      'registered03',
      'cowNo03',
      'cowUid03',
      'weightOnBirth03',
      'calfNo03',
      'birthStateString03',
      'birthResult04',
      'breed04',
      'gender04',
      'registered04',
      'cowNo04',
      'cowUid04',
      'weightOnBirth04',
      'calfNo04',
      'birthStateString04',
      'moveToCowGroupName',
      'moveToPen',
      'childNumber',
      'calvingDifficulty',
      'comment',
      'registeredCalfs'
    ];
  }

  static timedAiItemsIds() {
    return [
      'hormoneProgramName',
      'hormoneName',
      'comment'
    ];
  }

  static doNotBreedItemsIds() {
    return [
      'breedingExclusionReason',
      'comment'
    ];
  }

  static embryoRecoveryItemsIds() {
    return [
      'embryoRecoveryRankA',
      'embryoRecoveryRankB',
      'embryoRecoveryRankC',
      'embryoRecoveryRankD',
      'embryoRecoveryUnfertilized',
      'masterSpermNo',
      'targetBreedingDate',
      'embryoMasterRegistered',
      'hormoneProgramName',
      'nextCowState',
      'comment'
    ];
  }

  static bodyWeightMeasurementItemsIds() {
    return [
      'weight',
      'dg',
      'breedingDays',
      'comment'
    ];
  }

  static workNoteItemsIds() {
    return [
      'taioTitle',
      'comment'
    ];
  }

  static vaccinationItemsIds() {
    return [
      'medications',
      'workerName',
      'vaccineTiming',
      'comment'
    ];
  }

  static driedItemsIds() {
    return [
      'selectedDryPeriod',
      'moveToCowGroupName',
      'moveToPen',
      'bcs',
      'comment'
    ];
  }

  static culledItemsIds(canEditFilterItem) {
    if (canEditFilterItem) {
      return [
        'expelledReason',
        'buyer',
        'salesPrice',
        'shipmentNo',
        'producingArea',
        'producingFarmName',
        'comment'
      ];
    }

    return [
      'expelledReason',
      'buyer',
      'shipmentNo',
      'producingArea',
      'producingFarmName',
      'comment'
    ];
  }

  static diedItemsIds() {
    return [
      'expelledReason',
      'deathReason',
      'comment'
    ];
  }

  static clawTrimmedItemsIds() {
    return [
      'workerName',
      'comment'
    ];
  }

  static movedItemsIds() {
    return [
      'workerName',
      'moveToCowGroupName',
      'moveToPen',
      'comment'
    ];
  }

  static carcassCharacteristicItemsIds(canEditFilterItem) {
    if (canEditFilterItem) {
      return [
        'grade',
        'totalDressedCarcassWeight',
        'slaughterNo',
        'beforeSlaughterWeight',
        'dressedCarcassWeightOfL',
        'dressedCarcassWeightOfR',
        'loinArea',
        'ribsThickness',
        'subcutaneousFat',
        'yieldBaseValue',
        'bmsNo',
        'marblingGrade',
        'bcsNo',
        'gloss',
        'bcsAndGlossGrade',
        'tight',
        'texture',
        'tightAndTextureGrade',
        'bfsNo',
        'fatLuster',
        'bfsAndFatLusterGrade',
        'carcassDefectSummary',
        'otherFaultCorrection',
        'dressedCarcassUnitPrice',
        'dressedCarcassSalesPrice',
        'visceralDestruction',
        'comment'
      ];
    }

    return [
      'grade',
      'totalDressedCarcassWeight',
      'slaughterNo',
      'beforeSlaughterWeight',
      'dressedCarcassWeightOfL',
      'dressedCarcassWeightOfR',
      'loinArea',
      'ribsThickness',
      'subcutaneousFat',
      'yieldBaseValue',
      'bmsNo',
      'marblingGrade',
      'bcsNo',
      'gloss',
      'bcsAndGlossGrade',
      'tight',
      'texture',
      'tightAndTextureGrade',
      'bfsNo',
      'fatLuster',
      'bfsAndFatLusterGrade',
      'carcassDefectSummary',
      'otherFaultCorrection',
      'visceralDestruction',
      'comment'
    ];
  }

  static individualObservationItemsIds() {
    return [
      'eyeCondition',
      'earCondition',
      'muzzleCondition',
      'hairCondition',
      'hipCondition',
      'bodyCondition',
      'fecesCondition',
      'urineCondition',
      'bodyTemperature',
      'comment'
    ];
  }

  static weanedItemsIds() {
    return [
      'comment'
    ];
  }

  static dehornedItemsIds() {
    return [
      'workerName',
      'dehornMethod',
      'comment'
    ];
  }

  static castratedItemsIds() {
    return [
      'castratMethod',
      'comment'
    ];
  }

  static fattenedItemsIds() {
    return [
      'comment'
    ];
  }

  static farmDiaryItemsIds() {
    return [
      'title',
      'memo'
    ];
  }
}
