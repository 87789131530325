class BredAnalysisActions {
  constructor(
    ReproductionPerformanceAPI,
    SessionCache
  ) {
    'ngInject';

    this.ReproductionPerformanceAPI = ReproductionPerformanceAPI;
    this.farm = SessionCache.farm();
  }

  initState() {
    this.state = new BredAnalysisState(this.farm);

    this.state.init();
    return this.state;
  }

  fetchReport(startDate, endDate, factor) {
    if (this.state.isLoading) {
      return;
    } else {
      this.state.isLoading = true;
    }

    return this.ReproductionPerformanceAPI.bredAnalysis(startDate, endDate, factor).then((result) => {
      this.state.isLoaded = true;
      this.state.hasError = false;
      this.state.report = this.convertToFloat(result.data);
      this.state.hasNoData = this.state.report.details.length === 0;
      return this.state;
    }).catch((error) => {
      console.error(error);
      this.state.hasError = true;
      this.state.hasNoData = true;
    }).finally(() => this.state.isLoading = false);
  }

  convertToFloat(report) {
    const converted = angular.copy(report, {});

    converted.details.forEach((detail) => {
      detail.conceptionRateDisplay =
        Number.parseFloat(detail.conceptionRate || 0).toFixed(1);
      detail.percentOfTotalDisplay =
        Number.parseFloat(detail.percentOfTotal || 0).toFixed(1);
      detail.servicesPerConceptionDisplay =
        Number.parseFloat(detail.servicesPerConception || 0).toFixed(1);
    });

    converted.total.conceptionRateDisplay =
      Number.parseFloat(report.total.conceptionRate || 0).toFixed(1);
    converted.total.percentOfTotalDisplay =
      Number.parseFloat(report.total.percentOfTotal || 0).toFixed(1);
    converted.total.servicesPerConceptionDisplay =
      Number.parseFloat(report.total.servicesPerConception || 0).toFixed(1);

    return converted;
  }

  handleRangeChange(range) {
    this.state.isReversal = false;

    if (range) this.state.selectedRange = range;

    switch (this.state.selectedRange) {
    case 'year':
      this.state.startDate = new Date(
        this.state.rangeYear,
        0,
        1).getTime();
      this.state.endDate = new Date(
        this.state.rangeYear,
        11,
        31).getTime();
      break;
    case 'yearMonth':
      this.state.startDate = new Date(
        this.state.rangeMonthYear,
        this.state.rangeMonth - 1,
        1).getTime();
      this.state.endDate = DateUtil.endOfMonth(
        new Date(
          this.state.rangeMonthYear,
          this.state.rangeMonth - 1
        )).getTime();
      break;
    case 'dateRange':
      this.state.isReversal = false;
      this.state.isFutureEndDate = false;
      this.state.isOutOfRange = false;

      if (this.state.startDate < new Date(2000, 1, 1)) {
        return;
      }
      if (this.state.startDate > this.state.endDate) {
        this.state.isReversal = true;
        return;
      }

      if (DateUtil.isFutureDay(this.state.endDate)) {
        this.state.isFutureEndDate = true;
        return;
      }

      const diffMonths = DateUtil.diffMonths(this.state.startDate, this.state.endDate);
      const fiveYearMonths = 12 * 5;
      if (diffMonths >= fiveYearMonths) {
        this.state.isOutOfRange = true;
        return;
      }

      break;
    }

    return this.fetchReport(
      this.state.startDate,
      this.state.endDate,
      this.state.factor
    );
  }

  handleFactorChange() {
    return this.fetchReport(
      this.state.startDate,
      this.state.endDate,
      this.state.factor
    );
  }
}

app.service('BredAnalysisActions', BredAnalysisActions);
