app.controller('CowListController', function(
  $filter,
  $modal,
  $rootScope,
  $scope,
  $stateParams,
  $state,
  FarmService,
  CowService,
  FilterSearchConditionService,
  DateUtilService,
  UtilService,
  cowListTableHeaders,
  CowCollectionService,
  SelectCowService,
  Dictionary,
  FarmKind,
  ViewStateAPI
) {
  'ngInject';

  this.Dictionary = Dictionary;

  $scope.isCollapsed = true;
  // 絞り込み条件
  $scope.birthNumbers = FilterSearchConditionService.birthNumbers;
  $scope.tanetsukeFroms = FilterSearchConditionService.tanetsukeFroms;
  $scope.tanetsukeTos = FilterSearchConditionService.tanetsukeTos;
  // 条件初期化
  FilterSearchConditionService.initializeCondition();
  $scope.formData = FilterSearchConditionService.condition;

  $scope.sortOrderList = [
    {label: '昇順', value: 'asc'},
    {label: '降順', value: 'desc'},
  ];
  $scope.sortOrder = 'asc';
  $scope.showSensorNo = false;
  $scope.dateColumns = [
    'latestCalvingDate',
    'latestPregnancyDate',
    'latestJudgePregnantDate',
    'latestDryPreviousPeriodStartDate',
    'latestFertilizationDate',
    'expectedDryDate',
    'expectedCalvingDate',
    'introduceDate',
    'latestBcsDate'
  ];
  $scope.filteredCows = [];
  // 牛選択を初期化
  $scope.SelectCowService = SelectCowService.init();

  $scope.changeSortOrder = function() {
    const sortFunction = () => {
      switch ($scope.sortOrderType) {
      case 'cowNo':
        if ($scope.sortOrder === 'asc') {
          return sortByCowNo;
        } else {
          return sortByCowNoDescending;
        }
      case 'pen':
        if ($scope.sortOrder === 'asc') {
          return sortByPen;
        } else {
          return sortByPenDescending;
        }
      default:
        const isDateColumn = $scope.dateColumns.some((column) => column === $scope.sortOrderType);
        return isDateColumn ? sortByDate : sortByNumber;
      }
    };

    if ($scope.sortOrderType && $scope.sortOrder) {
      $scope.cows.sort(sortFunction());
    }
  };

  function sortByCowNo(a, b) {
    return CowCollectionService.compareCowNo(a, b);
  }

  function sortByCowNoDescending(a, b) {
    return CowCollectionService.compareCowNo(a, b, false);
  }

  function sortByPen(a, b) {
    return CowCollectionService.comparePen(a, b);
  }

  function sortByPenDescending(a, b) {
    return CowCollectionService.comparePen(a, b, false);
  }

  function sortByNumber(a, b) {
    const aVal = Number(a[$scope.sortOrderType]) || 0;
    const bVal = Number(b[$scope.sortOrderType]) || 0;
    let val = bVal - aVal;
    // cowNoは第2ソートは行わない。
    if ($scope.sortOrderType === 'cowNo') {
      return $scope.sortOrder === 'asc' ? -val : val;
    }
    if ($scope.sortOrder === 'asc') {
      return -val || CowCollectionService.compareCowNo(a, b);
    } else {
      return val || CowCollectionService.compareCowNo(a, b);
    }
  }

  function sortByDate(a, b) {
    let aVal = a[unixtimeKey($scope.sortOrderType)] || 0;
    let bVal = b[unixtimeKey($scope.sortOrderType)] || 0;
    // 画面上はYYYY/MM/DDだが実際は時刻があるので時刻を除去する。
    aVal = aVal ? moment(getDate(aVal)).unix() : 0;
    bVal = bVal ? moment(getDate(bVal)).unix() : 0;

    let val = bVal - aVal;

    if ($scope.sortOrder === 'asc') {
      return -val || CowCollectionService.compareCowNo(a, b);
    } else {
      return val || CowCollectionService.compareCowNo(a, b);
    }
  }

  /**
   * 牧場の種類によって、ソート項目の内容を変える
   */
  function initSortOrderTypeList() {
    // 表示フラグ設定
    FarmService.show().then((account) => {
      $scope.isMeatFarm = account.farmKind === '肉用';
      farmType = $scope.isMeatFarm ? 'meat' : 'milk';

      $scope.isReproductionGroup = account.farmKind === '乳用' ||
        account.farmKind === '肉用' &&
        $stateParams.attr === 'reproductionGroup';

      $scope.sortOrderTypeList = $scope.isMeatFarm
        ? [{label: '牛房', value: 'pen'}, {label: Dictionary.COW.COW_NO, value: 'cowNo'}]
        : [{label: Dictionary.COW.COW_NO, value: 'cowNo'}];
      $scope.sortOrderType = $scope.sortOrderTypeList[0].value;
      $scope.sortData = $scope.isMeatFarm ? 'pen' : 'cowNo';
      $scope.changeSortOrder();

      // 現在のリストに合致するカラムをソート対象として抽出
      Object.keys(cowListTableHeaders)
        .filter((key) => {
          return !!cowListTableHeaders[key][farmType][$scope.groupAttr]
            && cowListTableHeaders[key].hasOwnProperty('sortOrderTypes');
        })
        .forEach((key) => {
          let column = cowListTableHeaders[key];
          $scope.sortOrderTypeList = $scope.sortOrderTypeList.concat(column.sortOrderTypes);
        });
    });
  }

  let farmType = null;

  // 初期化処理
  onInit();
  function onInit() {
    CowService.init($stateParams.id, $stateParams.name, $stateParams.attr);

    $scope.prevState = $stateParams.prevState || null;
    $scope.loading = true;
    $scope.cowGroupId = CowService.group.id;
    $scope.cowGroupName = CowService.group.name;
    $scope.groupAttr = CowService.group.attr;
    $scope.state = CowService.state;
    FilterSearchConditionService.state = $scope.state;
    $scope.selectedCowNos = []; // 一括登録に選択された牛番号

    setCallbackParam($stateParams.id, $stateParams.name, $stateParams.attr);

    CowService.findByCowGroup().then((res) => {
      // X日から経過日を持つコラム
      const keysWithPassedByDates = [
        'latestCalvingDate',
        'latestJudgePregnantDate',
        'latestDryPreviousPeriodStartDate',
        'latestFertilizationDate'
      ];
      // 日付コラム
      const keysWithDates = [
        'latestCalvingDate',
        'latestBcsDate',
        'latestPregnancyDate',
        'latestJudgePregnantDate',
        'latestDryPreviousPeriodStartDate',
        'latestFertilizationDate',
        'expectedDryDate',
        'expectedCalvingDate',
        'birthday',
        'introduceDate'
      ];

      // データを処理＆整理する
      const currentDate = today();
      const formattedData = res.data.map((cow) => {
        // ラベルを整理する
        cow['cowLabels'] = removeSquareBrackets(cow['cowLabels']);

        // 計算・ソート処理用にUNIXTIMEを保持しておく
        $scope.dateColumns.forEach((column) => {
          cow[unixtimeKey(column)] = cow[column] ? Number(cow[column]) : 0;
        });

        // X日から経過日を計算して追加する
        keysWithPassedByDates.forEach((key) =>
          cow[`${key}DaysPassed`] = diffDays(cow[key])
        );
        cow.latestPregnancyDateDaysPassed = countDays(cow.latestPregnancyDate);

        // DBにない数字を計算する
        cow['monthAge'] = cow['birthday'] ? DateUtil.monthAge(Number(cow['birthday']), currentDate, 1) : '';

        cow['dateSinceIntroduced'] = cow['introduceDate'] ?
          `${DateUtilService.diffDays(Number(cow['introduceDate']), new Date().getTime())}日` :
          '';

        cow['dateSinceIntroducedForSort'] = cow['introduceDate'] ?
          `${DateUtilService.diffDays(Number(cow['introduceDate']), new Date().getTime())}` :
          '';

        // datestringを yy/MM/dd に変換
        keysWithDates.forEach((key) =>
          cow[key] = toYYMMDD(cow[key])
        );

        return cow;
      });

      $scope.cows = formattedData || [];
      initSortOrderTypeList();
      $scope.changeSortOrder();

      cowListTableHeaders.motherCowNo.headerText = `母${Dictionary.COW.COW_NO}`;

      $scope.loading = false;
    }).catch((err) => {
      console.error(err);
      $scope.cows = [];
      $scope.loading = false;
    });
  }

  function setCallbackParam(id, name, attr) {
    $scope.callbackParam = {
      state: 'cowList',
      params: {
        id,
        name,
        attr
      }
    };
  }

  $scope.search = () => {
    $scope.isCollapsed = !$scope.isCollapsed;
  };

  // フィルターを設定
  $scope.searchFilter = angular.bind(FilterSearchConditionService, FilterSearchConditionService.filter);

  $scope.tanetsukeFromFilter = (data) => tanetsukeFilter('tanetsukeFrom', data);
  $scope.tanetsukeToFilter = (data) => tanetsukeFilter('tanetsukeTo', data);

  function tanetsukeFilter(propName, data) {
    if ( data.value === '' ) return true;
    if (propName === 'tanetsukeFrom') {
      if ($scope.formData['tanetsukeTo'].value === '') return true;
      if (Number(data.value) <= Number($scope.formData['tanetsukeTo'].value)) return true;
    } else if (propName === 'tanetsukeTo') {
      if ($scope.formData['tanetsukeFrom'].value === '') return true;
      if (Number(data.value) >= Number($scope.formData['tanetsukeFrom'].value)) return true;
    }
    return false;
  }

  $scope.csvExport = () => {
    UtilService.csvExport(toCsv(), $scope.cowGroupName + '.csv');
  };

  function toCsv() {
    const columns = Object.keys(cowListTableHeaders)
      .filter((key) => !!cowListTableHeaders[key][farmType][$scope.groupAttr]);

    const rows = $scope.cows
      .filter((row) => FilterSearchConditionService.filter(row))
      .map((cow) => {
        const row = [];
        row.push(cow.cowNo);
        row.push(cow.cowUid);
        row.push(cow.bleId);
        row.push(cow.state);

        columns.forEach((column) => {
          row.push(cow[column]);
        });
        return row.join(',');
      });

    const header = [];
    header.push(Dictionary.COW.COW_NO);
    header.push('個体識別番号');
    header.push('センサー番号');
    header.push('状態');
    columns.forEach((column) => {
      header.push(cowListTableHeaders[column].headerText.replace(/<br\/?>/, ''));
    });
    rows.unshift(header);

    return rows.join('\n');
  }

  /**
   * 牛ラベルを
   *  - StrからArrに変換
   *  - 重複データの駆除
   * @param {string} string cowLabels
   * @return {array}
   */
  function removeSquareBrackets(string) {
    return string ? string.replace(/[[\]']+/g, '')
      .split(',')
      .filter((value, index, arr) => arr.indexOf(value) === index) : [];
  }

  /**
   * dateStringを年月日に変換する
   * @param {string} dateString
   * @return {string}
   */
  function toYYMMDD(dateString) {
    return $filter('date')(dateString, 'yy/MM/dd') || dateString;
  }

  /**
   * 経過日数を計算しする
   * @param {string} date
   * @return {string}
   */
  function diffDays(date) {
    return date ? `(${DateUtilService.diffDays(Number(date), new Date().getTime())}日)` : '';
  }

  function countDays(date) {
    return date ? `(${DateUtilService.countDays(Number(date), new Date().getTime())}日)` : '';
  }

  $scope.print = function() {
    ViewStateAPI.create('print-in-cowGroupList', 'cowGroupList');
    print();
  };

  function unixtimeKey(key) {
    return key + 'Unixtime';
  }

  $scope.goToDetails = (cowId) => {
    const cowIds = $scope.filteredCows.map((cow) => cow.cowId);
    const name = `牛群：${$scope.callbackParam.params.name}`;
    const caller = {
      name: name,
      state: $scope.callbackParam.state,
      params: $scope.callbackParam.params,
      cowIds: cowIds
    };
    $state.go('cowDetail', {cowId: cowId, caller: caller});
  };

  /**
   * 行の選択トーグル
   */
  $scope.selectRow = () => {
    $scope.isCollapsed = true;
    SelectCowService.selectRow($scope.cows,
      FilterSearchConditionService.filter.bind(FilterSearchConditionService));
  };

  /**
   * 全行の選択トーグル
   */
  $scope.selectAllRows = (val) => {
    $scope.cows.forEach((row) => row.selected = val);
    $scope.selectRow();
  };

  /**
   * モバイル用
   */

  $scope.toggleDetails = (e, cow) => {
    $(e.currentTarget).next().slideToggle();
    cow.selected = !cow.selected;
  };

  $scope.controller = this;
});
