class MultiSelectDialogController {
  constructor(
    $scope,
    $modalInstance,
    params,
    uiGridConstants) {
    'ngInject';
    this.$scope = $scope;
    this.$modalInstance = $modalInstance;
    this.uiGridConstants = uiGridConstants;

    this.initialize(params);
  }

  initialize(params) {
    this.title = params.title;
    this.caption = params.caption;
    this.needToSelect = params.needToSelect || false;

    this.indexAction = params.indexAction;
    this.filterFunction = params.filterFunc;
    this.currentSelectedIds = params.currentSelectedIds || [];

    this.noDataMessage = '';
    this.invalid = true;

    this.showGrid = false;
    this.setUiGrid();

    this.index();
  }

  setUiGrid() {
    const columns = [
      {columnId: 'selected', label: '選択', width: 40},
      {columnId: 'name', label: '名称', width: 250},
      {columnId: 'notes', label: '備考', width: 250},
    ];

    const options = {
      clickRowEvent: 'onClickRow',
      cellTemplates: {
        selected: `
          <label class="cow-select">
            <input type="checkbox" ng-checked="row.entity.selected" class="no-margin">
          </label>
          `
      }
    };

    this.uiGrid = new UiGridGenerator().generate(this, columns, options);
  }

  index() {
    this.indexAction().then((res) => {
      this.rawRecords = res.data;
      this.records = this.rawRecords.filter((r) => {
        if (this.currentSelectedIds.includes(r.id)) {
          return false;
        }
        if (this.filterFunction) {
          return this.filterFunction(r);
        }

        return true;
      });

      if (this.records.length > 0) {
        this.uiGrid.data = this.records;
        this.showGrid = true;
      } else {
        this.noDataMessage = '選択できる設定はありません。';
        this.invalid = true;
      }
    });
  }

  onClickRow(record) {
    record.selected = !record.selected;
    this.validate();
  }

  validate() {
    this.invalid = false;

    if (!this.needToSelect) return;

    if (this.records.some((m) => m.selected)) {
      return;
    }

    this.invalid = true;
  }

  ok() {
    const result = this.records.filter((m) => m.selected);
    this.$modalInstance.close(result);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('MultiSelectDialogController', MultiSelectDialogController);
