// eslint-disable-next-line no-unused-vars
class EmbryoRecovery {
  static isAnyRankCounts(cowEvent) {
    return cowEvent.embryos.some((rank) => {
      return Number(rank.count || 0) !== 0;
    });
  }

  static toViewModel(cowEvent, cow, masterSperms, nextCowState) {
    const viewModel = angular.copy(cowEvent);

    viewModel.embryoMasterRegistered = 'embryoMasterRegistered' in viewModel
      ? String(viewModel.embryoMasterRegistered)
      : String(EmbryoRecovery.isAnyRankCounts(viewModel));

    if (!viewModel.masterSpermNo) {
      viewModel.masterSpermNo = cow.latestMasterSpermNo;
      const sperm = Object.values(masterSperms).find((s) => s.spermNo === cow.latestMasterSpermNo);
      viewModel.masterSpermId = sperm ? sperm.id : 0;
    }

    const today = DateUtil.today();
    viewModel.targetBreedingDate = viewModel.targetBreedingDate
      || Number(cow.latestFertilizationDate)
      || DateUtil.addDays(today, -7).valueOf();
    viewModel.nextCowState = viewModel.nextCowState || nextCowState;
    return viewModel;
  }

  static createRequestParams(input) {
    const embryoMasterRegistered = [true, 'true'].includes(input.embryoMasterRegistered);
    const embryos = input.embryos.map((embryo) => {
      embryo.count = embryo.count || 0;
      return embryo;
    });

    const params = {
      embryos,
      embryoMasterRegistered: embryoMasterRegistered,
      masterSpermId: input.masterSpermId,
      targetBreedingDate: Number(input.targetBreedingDate),
      masterHormoneProgramId: input.masterHormoneProgramId,
      nextCowState: input.nextCowState
    };

    return params;
  }
}
