class TabletCowIdSearchController {
  constructor(
    $state,
    $modalInstance,
    SessionCache,
    CowSearchAPI,
    CowSearchDialog
  ) {
    'ngInject';

    this.$state = $state;
    this.$modalInstance = $modalInstance;
    this.SessionCache = SessionCache;
    this.useName = SessionCache.farm().useName();
    this.CowSearchAPI = CowSearchAPI;
    this.CowSearchDialog = CowSearchDialog;

    this.init();
  }

  init() {
    this.model = {
      option: this.SessionCache.cowSearchOption() || 'cowNo'
    };

    this.columns = [{
      key: 'cowNo',
      label: '牛番号'
    }, {
      key: 'cowUid',
      label: '個体識別番号'
    }, {
      key: 'bleId',
      label: 'センサー番号'
    }, {
      key: 'cowGroupName',
      label: '牛群'
    }, {
      key: 'state',
      label: '状態'
    }];

    this.placeholder = this.useName ? '名前を入力してください' : '番号を入力してください';
    this.optionCowNo = this.useName ? '名前で検索中' : '牛番号で検索中';
    this.showCowSelect = false;
    this.showErrorMessage = false;
  }

  onChangeSearchId() {
    this.showCowSelect = false;
    this.showErrorMessage = false;
  }

  search() {
    document.activeElement.blur();

    this.showCowSelect = false;
    this.showErrorMessage = false;

    if (!this.model.searchId) return;

    this.SessionCache.store('cowSearchOption', this.model.option);
    const option = this.model.option.charAt(0).toUpperCase() + this.model.option.slice(1);
    const activeOnly = false;

    this.CowSearchAPI[`searchBy${option}`](this.model.searchId, activeOnly).then((res) => {
      if (res.data.length === 1) {
        this.close();
        this.goToCowDetail(res.data[0].cowId);
      } else if (res.data.length > 1) {
        this.cows = res.data;
        this.sortedColumns = this.columns.filter((column) => {
          return column.key === this.model.option;
        }).concat(this.columns.filter((column) => {
          return column.key !== this.model.option;
        }));

        this.showCowSelect = true;
      } else {
        this.showErrorMessage = true;
      }
    });
  }

  goToCowDetail(cowId) {
    this.$state.go('cowDetail', {
      cowId: cowId,
      caller: {
        state: 'cow_search'
      }
    });
  }

  close() {
    this.$modalInstance.close();
  }

  advancedSearch() {
    this.CowSearchDialog.show();
  }

  errorMessageCowNo() {
    return this.model.option === 'cowNo' && this.useName ? '名前' : '番号';
  }

  formatData(key, value) {
    if (key === 'cowUid') {
      return formatCowUid(value);
    }

    return value;
  }

  classCowSelect() {
    return this.showCowSelect ? 'uModal__formSearchCowSelect--open' : 'uModal__formSearchCowSelect';
  }

  classErrorMessage() {
    return this.showErrorMessage ? 'uModal__formSearchErrorMessage--open' : 'uModal__formSearchErrorMessage';
  }
}

app.controller('TabletCowIdSearchController', TabletCowIdSearchController);
