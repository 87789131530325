class BredAnalysisController {
  constructor(
    BredAnalysisActions
  ) {
    'ngInject';

    this.actions = BredAnalysisActions;
    this.state = this.actions.initState();

    this.state.selectedRange = 'dateRange';
    this.actions.handleRangeChange();
  }
}

app.controller('BredAnalysisController', BredAnalysisController);
