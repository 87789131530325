class InseminatorDialogController {
  constructor(
    $modal,
    $scope,
    $modalInstance,
    MasterInseminatorAPI,
    InseminatorDialog,
    SortOrderEditDialog
  ) {
    'ngInject';

    this.$modal = $modal;
    this.$scope = $scope;
    this.$modalInstance = $modalInstance;
    this.MasterInseminatorAPI = MasterInseminatorAPI;
    this.InseminatorDialog = InseminatorDialog;
    this.SortOrderEditDialog = SortOrderEditDialog;

    this.initialize();
  }

  initialize() {
    this.MasterInseminatorAPI.index().then((res) => {
      this.inseminatorList = res.data.map((obj) => {
        return {
          id: obj.id,
          name: obj.name,
          licencedPrefecture: obj.licencedPrefecture,
          licencedNumber: obj.licencedNumber,
          address: obj.address
        };
      });
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  add() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'components/dialog/inseminator/edit.html',
      controller: 'InseminatorEditDialogController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            params: {},
            action: 'create'
          };
        }
      }
    }).result.then(() => {
      this.$modalInstance.dismiss('cancel');
    });
  }

  edit(inseminator, action) {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'components/dialog/inseminator/edit.html',
      controller: 'InseminatorEditDialogController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            id: inseminator.id,
            name: inseminator.name,
            licencedPrefecture: inseminator.licencedPrefecture,
            licencedNumber: inseminator.licencedNumber,
            address: inseminator.address,
            action: action
          };
        }
      }
    }).result.then(() => {
      this.$modalInstance.dismiss('cancel');
    });
  }

  updateSortOrder() {
    const items = this.inseminatorList.map((obj) => {
      return {id: obj.id, name: obj.name};
    });
    const updateFunction = this.MasterInseminatorAPI.updateSortOrder.bind(this.MasterInseminatorAPI);

    this.SortOrderEditDialog.open(
      '授精師の表示順設定',
      '授精師名',
      items,
      updateFunction
    ).then((result) => {
      if (result.executed) {
        this.$modalInstance.dismiss('cancel');
        this.InseminatorDialog.execute();
      }
    });
  }
}

app.controller('InseminatorDialogController', InseminatorDialogController);
