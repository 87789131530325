class MobileBulkAddLabelModal {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  open(cows) {
    return this.$modal.open({
      templateUrl: 'mobile/components/bulk-edit-label/bulk-add-label-modal/bulk-add-label-modal.html',
      controller: 'MobileBulkAddLabelModalController as ctrl',
      resolve: {
        cows: () => cows
      }
    }).result;
  }
}
app.service('MobileBulkAddLabelModal', MobileBulkAddLabelModal);
