class CowDetailSearchComponentControler {
  constructor(
    CowSearchDialog,
    SessionCache
  ) {
    'ngInject';
    this.CowSearchDialog = CowSearchDialog;
    this.isDepositorFarm = SessionCache.farm().isDepositor();
  }

  search() {
    this.CowSearchDialog.show();
  }
}

function cowDetailSearchComponent() {
  return {
    templateUrl: 'components/cow-detail-search/index.html',
    controller: CowDetailSearchComponentControler,
    controllerAs: 'ctrl',
  };
}

app.component('cowDetailSearch', cowDetailSearchComponent());
