class MasterMenuController {
  constructor() {
    this.menus = MasterConfig.menus;
  }

  changeMenu(menu) {
    this.menu = menu;
  }

  addActiveToClassName(menu) {
    if (this.menu.id === menu.id) {
      return 'active';
    }
  }
}

app.controller('MasterMenuController', MasterMenuController);
