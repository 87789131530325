class CalvingSensorAPI {
  constructor(
    $q,
    APIService
  ) {
    'ngInject';

    this.$q = $q;
    this.APIService = APIService;
  }

  candidatesSummary(date) {
    if (!date) {
      const deferred = this.$q.defer();
      deferred.resolve({});
      return deferred.promise;
    }

    const dateQuery = DateUtil.format(date, 'YYYY-MM-DD');

    return this.APIService.get(`/calving_sensor/candidates_summary?date=${dateQuery}`);
  }

  equipCandidates(date) {
    if (!date) {
      const deferred = this.$q.defer();
      deferred.resolve({});
      return deferred.promise;
    }

    const dateQuery = DateUtil.format(date, 'YYYY-MM-DD');

    return this.APIService.get(`/calving_sensor/equip_candidates?date=${dateQuery}`);
  }

  unequipCandidates(date) {
    if (!date) {
      const deferred = this.$q.defer();
      deferred.resolve({});
      return deferred.promise;
    }

    const dateQuery = DateUtil.format(date, 'YYYY-MM-DD');

    return this.APIService.get(`/calving_sensor/unequip_candidates?date=${dateQuery}`);
  }
}

app.service('CalvingSensorAPI', CalvingSensorAPI);
