app.controller('AiReportController', function($scope, $rootScope, $state, $stateParams, OrderedListService, blockUI, $timeout) {
  'ngInject';

  $scope.title = '精液使用本数実績';

  // 印刷用の処理
  let params = getPathParams();

  $scope.printable = params.printable === 'true';
  if ($scope.printable) {
    $('header').remove();
    $('#header-band').remove();
    $('body').addClass('print');
    $('#external-input').addClass('option-wrapper');
    $('.wrapper .content').css('margin', '0px');
    $('#print-link').remove();
    $('.content .title-box').css('position', 'absolute');
    $('.table-fix').parent().removeClass('fixed-tb');
  }

  $scope.$on('ngRepeatFinished', function() {
    FixedMidashi.create();
  });

  (() => {
    $scope.busy = true;
    $scope.selection = {};

    search();
  })();

  $scope.onSearch = () => {
    search();
  };

  function search() {
    $scope.loading = true;

    // 2ヶ月前から1年分を表示する
    let endDate = lastDayOfMonth(addMonths(today(), -2));
    let startDate = firstDayOfMonth(addMonths(endDate, -11));
    // console.log('startDate: ' + startDate + ', endDate: ' + endDate);

    OrderedListService.getAiResults(startDate, endDate).then(function(result) {
      if (result.data && result.data.length > 0) {
        result.data.forEach(function(r) {
          r.rate = toRate(r.fertilized_cow_number, r.used_number);
          r.used_number_origin = r.used_number; // 値の変更を検知するために初期値を保存する
        });
        $scope.records = result.data;

        // 印刷モードの場合にはブラウザの印刷機能を起動する
        executePrinterIfPrintable();
      }
      $scope.loading = false;
    });
  }

  $scope.yearMonth = function(yearMonth) {
    let str = String(yearMonth);
    return str.substr(0, 4) + '/' + str.substr(4, 2);
  };

  $scope.changeUsedNumber = function(index) {
    let r = $scope.records[index];
    r.rate = toRate(r.fertilized_cow_number, r.used_number);
  };

  function toRate(n, total) {
    let num = toNumberOrZero(total);
    if (num > 0) {
      let rate = (n / num) * 100;
      return rate.toFixed(1) + '%';
    } else {
      return '';
    }
  }

  function toNumberOrZero(value) {
    if (!value) {
      return 0;
    }

    try {
      return Number(value);
    } catch (e) {
      return 0;
    }
  }

  $scope.usedNumerColor = function(index) {
    let r = $scope.records[index];
    let usedNumber = toNumberOrZero(r.used_number);
    if (usedNumber !== r.used_number_origin) {
      return {color: '#ff4500', fontWeight: 'bold'};
    } else {
      return {color: '#000000'};
    }
  };

  $scope.onSave = function() {
    let changed = $scope.records.filter(function(r) {
      let usedNumber = toNumberOrZero(r.used_number);
      return usedNumber !== r.used_number_origin;
    });
    if (changed.length === 0) {
      blockUI.start('変更がありません');
      $timeout(function() {
        blockUI.stop();
      }, 1000);
      return;
    }

    let params = changed.map(function(r) {
      return {
        year_month: r.year_month,
        used_number: toNumberOrZero(r.used_number)
      };
    });

    blockUI.start('更新中');

    OrderedListService.saveAiResults(params).then(function(result) {
      blockUI.done(function() {
        afterSave();
        blockUI.start('更新が完了しました');
        $timeout(function() {
          blockUI.stop();
        }, 1000);
      });
      blockUI.stop();
    }).catch(function(error) {
      blockUI.stop();
      console.error((error && error.stack) || error);
    });
  };

  function afterSave() {
    $scope.records.forEach(function(r) {
      r.used_number_origin = toNumberOrZero(r.used_number);
    });
  }

  $scope.onPrint = function(path) {
    oepnPrintableWindow('ai-report', []);
  };

  function oepnPrintableWindow(path, params) {
    params = params || [];
    params.push('mode=1'); // src/js/app.js で使用しているのでセットする
    params.push('printable=true');

    let context = location.href.split('#');
    let href = context[0] + '#/' + path + '?' + params.join('&');
    let windowStyle = 'width=1100,height=600,menubar=no,toolbar=no,location=no,scrollbars=yes';
    window.open(href, path, windowStyle);
  }

  function executePrinterIfPrintable() {
    if ($scope.printable) {
      let option = {separator_style: 'jp'};
      $scope.title = '精液使用本数実績 - ' + DateUtil.formatDate(new Date(), option);

      const interval = setInterval(function() {
        executePrinter(params, interval, null);
      }, 100);
    }
  }

});
