class StandardDialog {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  showDeleteConfirm(params) {
    const config = {
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'components/dialog/standard/delete.html',
      controller: 'dialogController',
      backdrop: false,
      resolve: {
        params: () => {
          return params;
        }
      }
    };

    return this.$modal.open(config);
  }

  showMessage(params) {
    const config = {
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'components/dialog/standard/message.html',
      controller: 'dialogController',
      backdrop: false,
      resolve: {
        params: () => {
          return params;
        }
      }
    };

    return this.$modal.open(config);
  }

  showYesNoConfirm(params) {
    const config = {
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'components/dialog/standard/yes-no.html',
      controller: 'dialogController',
      backdrop: false,
      resolve: {
        params: () => {
          return params;
        }
      }
    };

    return this.$modal.open(config);
  }

  showYesConfirm(params) {
    const config = {
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'components/dialog/standard/yes.html',
      controller: 'dialogController',
      backdrop: false,
      resolve: {
        params: () => {
          return params;
        }
      }
    };

    return this.$modal.open(config);
  }
}

app.service('StandardDialog', StandardDialog);
