class MasterEmbryoEditController {
  constructor(
    $modal,
    $modalInstance,
    params,
    MasterEmbryoAPI,
    SelectionService,
    CowService,
    CowSearchAPI,
    blockUI,
    $timeout,
    SessionCache,
    Dictionary,
    modalDialogFactory
  ) {
    'ngInject';

    this.$modal = $modal;
    this.$modalInstance = $modalInstance;
    this.MasterEmbryoAPI = MasterEmbryoAPI;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.SelectionService = SelectionService;
    this.CowService = CowService;
    this.CowSearchAPI = CowSearchAPI;
    this.Dictionary = Dictionary;
    this.modalDialogFactory = modalDialogFactory;
    this.farm = SessionCache.farm();

    this.initialize(params);
  }

  initialize(params) {
    this.title = params.title;
    this.model = new Embryo(params.model || {}, this.farm);
    this.viewMode = new ViewMode(params.viewMode);

    this.invalid = this.viewMode.isCreate() ? true : false;
    this.errorMessage = {};

    this.SelectionService.index()
      .then((res) => {
        this.breedSelection = res.breed.concat();
        this.breedSelection.unshift({label: '', value: ''});
      })
      .catch((error) => {
        console.error('Error getSelections', error);
      });

    this.cowNoList = params.cowNoList;

    if (this.viewMode.isUpdate() && this.model.donorCowId) {
      const donorCow = this.cowNoList.filter((cow) => {
        return cow.cowId === this.model.donorCowId;
      });
      const donorCowNo = donorCow.length !== 0 ? donorCow[0].cowNo : null;

      this.donor = {
        selected: {
          cowNo: donorCowNo
        }
      };
    }
  }

  formatCowUid(CowUid) {
    if (!CowUid) return;

    const splitCowUid = Cow.formatCowUid(CowUid).split('-');
    return `${splitCowUid[0]}<span class="uModal__formList4digits">${splitCowUid[1]}</span>${splitCowUid[2]}`;
  }

  validate() {
    this.invalid = true;
    const model = this.model;

    if (!model.masterSpermNo) return;

    this.invalid = false;
  }

  cancel() {
    this.$modalInstance.dismiss();
  }

  save() {
    if (this.viewMode.isCreate()) {
      this.create();
    } else if (this.viewMode.isUpdate()) {
      this.update();
    } else if (this.viewMode.isDelete()) {
      this.delete();
    }
  }

  create() {
    const procedure = this.MasterEmbryoAPI.create(this.model);
    this.callApi(procedure);
  }

  update() {
    const procedure = this.MasterEmbryoAPI.update(this.model);
    this.callApi(procedure);
  }

  delete() {
    const procedure = this.MasterEmbryoAPI.delete(this.model.id);
    this.callApi(procedure);
  }

  callApi(procedure) {
    this.blockUI.start('更新中');
    this.errorMessage = {};

    procedure
      .then(() => {
        this.blockUI.done(() => {
          this.blockUI.start(`受精卵の${this.viewMode.label}が完了しました`);
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close(this.model);
      })
      .catch((res) => {
        const items = res.data.messages;

        items.forEach((item) => {
          if (item.field) {
            this.errorMessage[item.field] = item.message;
          } else {
            this.errorMessage.message = item.message;
          }
        });

        this.blockUI.stop();
      });
  }

  openSpermInfo(selectedSpermNo) {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'history/selection/spermInfo/spermInfoSelectModal.html',
      controller: 'spermInfoSelectModalController',
      controllerAs: 'spermCtrl',
      backdrop: false,
      keyboard: false,
      resolve: {
        params: () => {
          return {
            spermNo: selectedSpermNo
          };
        }
      }
    }).result.then((result) => {
      if (result === false) return;
      this.model.sperm(result);
      this.validate();
    });
  }

  clearDonor() {
    this.donor = {};

    this.model.clearDonor();
  }

  searchCow(cowNoParam) {
    if (!cowNoParam) return;

    this.CowService.get(cowNoParam).then((res) => {
      this.model.cow(res.data);
    });
  }

  isEditing() {
    return this.viewMode.isCreate() || this.viewMode.isUpdate();
  }
}

app.controller('MasterEmbryoEditController', MasterEmbryoEditController);
