// eslint-disable-next-line no-unused-vars
class GroupTransferInput {
  static get REQUIRED_FIELDS() {
    return [{
      value: 'fromId',
      label: '異動元牧場'
    }, {
      value: 'toId',
      label: '異動先牧場'
    }, {
      value: 'transferDate',
      label: '異動日'
    }, {
      value: 'transferType',
      label: '異動理由'
    }];
  }

  static clearRow(row, exclude) {
    let value;

    if (exclude && row.input) {
      value = row.input[exclude];
    }

    row.input = {
      removeSensor: false
    };
    row.cow = {};
    row.pens = [];
    row.warning = {};
    row.error = {};
    row.invalidCandidate = true;

    if (value) {
      row.input[exclude] = value;
    }

    return row;
  }

  static clearRowsError(rows) {
    return rows.map((row) => {
      if (row.cow.cowId) {
        row.error = {};
      }

      return row;
    });
  }

  static updateCow(findCow) {
    return {
      cowId: findCow.cowId,
      formattedCowUid: Cow.formatCowUid(findCow.cowUid),
      bleId: findCow.bleId,
      introduceDate: DateUtil.toYYYYMMDD(findCow.introduceDate)
    };
  }

  static validateDuplicatedCow(rows, key) {
    return rows.map((row) => {
      return row.cow.cowId;
    }).filter((cowId, index, array) => {
      return cowId &&
        array.indexOf(cowId) === index &&
        array.lastIndexOf(cowId) !== index;
    }).map((cowId) => {
      return {
        cowId: cowId,
        errors: {
          cowNo: GroupTransfer.ERROR_MESSAGE.duplicate[key],
          cowUid: GroupTransfer.ERROR_MESSAGE.duplicate.cowUid
        }
      };
    });
  }

  static generateCowNoSelectParams(cows) {
    return cows.map((cow) => {
      return {
        cowId: cow.cowId,
        cowNo: cow.cowNo,
        formattedCowUid: Cow.formatCowUid(cow.cowUid),
        state: cow.state
      };
    });
  }

  static generateCreateParams(model) {
    const cows = model.rows.filter((row) => {
      return row.cow.cowId;
    }).map((row) => {
      return {
        cowId: row.cow.cowId,
        cowGroupId: row.input.toCowGroupId,
        pen: row.input.toPen,
        cowNo: row.input.toCowNo,
        removeSensor: row.input.removeSensor,
        notes: row.input.notes
      };
    });

    return {
      fromId: model.fromId,
      toId: model.toId,
      transferDate: model.transferDate,
      transferType: model.transferType,
      operator: model.operator,
      cows
    };
  }

  static generateUpdateParams(model) {
    const cows = model.rows.filter((row) => {
      return row.cow.cowId;
    }).map((row) => {
      return {
        transferInputId: row.history.transferInputId,
        cowId: row.cow.cowId,
        cowGroupId: row.input.toCowGroupId,
        pen: row.input.toPen,
        cowNo: row.input.toCowNo,
        removeSensor: row.input.removeSensor,
        notes: row.input.notes
      };
    });

    return {
      fromId: model.fromId,
      toId: model.toId,
      transferDate: model.transferDate,
      transferType: model.transferType,
      operator: model.operator,
      cows
    };
  }

  static copy1stLine(rows) {
    return rows.map((row, index, array) => {
      if (!index) {
        row.error.toCowGroupId = GroupTransferInput.validateCowGroupId(row.input.toCowGroupId);
      } else if (row.cow.cowId) {
        row.pens = array[0].pens;
        row.input.toCowGroupId = array[0].input.toCowGroupId;
        row.input.toPen = array[0].input.toPen;
        row.input.notes = array[0].input.notes;
        row.warning.toCowGroupId = array[0].warning.toCowGroupId;
        row.error.toCowGroupId = array[0].error.toCowGroupId;
      }

      return row;
    });
  }

  static copySelected(rows) {
    const selectedCow = rows.find((row) => {
      return row.input.selected;
    });

    return {
      pens: selectedCow.pens,
      input: {
        toCowGroupId: selectedCow.input.toCowGroupId,
        toPen: selectedCow.input.toPen,
        notes: selectedCow.input.notes
      },
      warning: {
        toCowGroupId: selectedCow.warning.toCowGroupId
      },
      error: {
        toCowGroupId: selectedCow.error.toCowGroupId
      }
    };
  }

  static pasteSelected(rows, clipboard) {
    return rows.map((row) => {
      if (row.input.selected) {
        row.pens = clipboard.pens;
        row.input.toCowGroupId = clipboard.input.toCowGroupId;
        row.input.toPen = clipboard.input.toPen;
        row.input.notes = clipboard.input.notes;
        row.warning.toCowGroupId = clipboard.warning.toCowGroupId;
        row.error.toCowGroupId = clipboard.error.toCowGroupId;
      }

      return row;
    });
  }

  static deleteSelected(rows) {
    return rows.filter((row) => {
      return !row.input.selected;
    });
  }

  static validateCowGroupId(toCowGroupId) {
    if (toCowGroupId) return null;

    return GroupTransfer.ERROR_MESSAGE.required;
  }
}
