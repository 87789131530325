/**
 * @deprecated
 * 牛群リストメニューはReactに移行されました。
 * IEユーザーへの対応のために残しています。
 */
class CowGroupIndexController {
  constructor(
    $q,
    CowGroupService,
    CustomlistAPI,
    $rootScope
  ) {
    'ngInject';
    this.index($q, CowGroupService, CustomlistAPI);
  }

  index($q, CowGroupService, CustomlistAPI) {
    $q.all([
      CowGroupService.summary(),
      CustomlistAPI.cowGroups()
    ]).then((res) => {
      const groups = res[0].data;

      const customlists = {};
      res[1].data.forEach((customlist) => {
        customlists[customlist.configType] = customlist;
      });

      const setCustomList = (group, customlist) => {
        group.customlistId = customlist.customlistId;
        group.customListName = customlist.name;
        group.configType = customlist.configType;
      };

      groups.forEach((group) => {
        if (group.calfGroup) {
          setCustomList(group, customlists['group_calf']);
        } else if (group.reproductionGroup) {
          setCustomList(group, customlists['group_reproduction']);
        } else if (group.cullingGroup) {
          setCustomList(group, customlists['group_culling']);
        } else if (group.milkingGroup) {
          setCustomList(group, customlists['group_milking']);
        } else if (group.dryGroup) {
          setCustomList(group, customlists['group_dry']);
        } else if (group.fatteningGroup) {
          setCustomList(group, customlists['group_fattening']);
        } else {
          setCustomList(group, customlists['group_reproduction']);
        }
      });

      this.groups = groups;
    });
  }
}

app.controller('CowGroupIndexController', CowGroupIndexController);
