function chartActivityRangeSelectorComponent() {
  'ngInject';
  return {
    controller: 'ChartActivityRangeSelectorController',
    controllerAs: 'ctrl',
    templateUrl: 'components/chart/activity/range-selector/index.html',
    bindings: {
      cow: '<',
      isLoading: '<',
      defaultActivityPeriod: '<',
      periods: '<',
      withoutBle: '<',
      onChangeDate: '&',
      onChangePeriod: '&'
    }
  };
}
app.component('chartActivityRangeSelector', chartActivityRangeSelectorComponent());
