class TgvGroupConfigAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/temporary_group_view/tgv_group_config');
  }

  groupSummary() {
    return this.APIService.get('/temporary_group_view/group_summary');
  }

  groupCurrentActivity(cowGroupId) {
    const url = `/temporary_group_view/activity/group_current?cow_group_id=${cowGroupId}`;
    return this.APIService.get(url);
  }
}

app.service('TgvGroupConfigAPI', TgvGroupConfigAPI);
