class SelectionService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/selection')
      .then((response) => this.addExtraOptions(response.data))
      .catch((error) => console.error('Data read failed', error.message));
  }

  // FIXME: API側に処理を移行する
  addExtraOptions(selection) {
    selection.difficultiesLevel = selection.difficultiesLevel || [];
    selection.otherDifficultiesLevel = Object.values(Object.assign({}, selection.difficultiesLevel));
    selection.difficultiesLevel.unshift({'label': '症状なし', 'value': ''});
    selection.childNumberList = [{'label': '1', 'value': '1'}, {'label': '2', 'value': '2'}, {'label': '3', 'value': '3'}, {'label': '4', 'value': '4'}];

    const calvingDifficulty = [
      {value: '1', label: '1：介助なしの自然分娩'},
      {value: '2', label: '2：ごく軽い介助'},
      {value: '3', label: '3：2～3人を必要とした助産'},
      {value: '4', label: '4：数人を必要とした難産'},
      {value: '5', label: '5：外科的処置を必要とした難産または分娩時母牛死亡'},
    ];
    selection['calvingDifficulty'] = calvingDifficulty;

    const locomotionScore = [
      {value: null, label: '未選択', labelBack: '', labelWalk: ''},
      {value: '1', label: '1', labelBack: '背部姿勢：佇立・歩行中もまっすぐである', labelWalk: '歩行：歩行に異常はみられない'},
      {value: '2', label: '2', labelBack: '背部姿勢：佇立時はまっすぐであるが、歩行時は希に湾曲する', labelWalk: '歩行：歩行に異常はみられない'},
      {value: '3', label: '3', labelBack: '背部姿勢：佇立・歩行時に明白な湾曲がある', labelWalk: '歩行：歩行に若干の影響がみられ、歩幅が短い肢がある'},
      {value: '4', label: '4', labelBack: '背部姿勢：佇立・歩行時に明白な湾曲がある', labelWalk: '歩行：歩行に明白な影響がみられ、振り出しに時間を要する肢がある'},
      {value: '5', label: '5', labelBack: '背部姿勢：佇立・歩行時に明白な湾曲がある', labelWalk: '歩行：自発的な歩行がほとんどできない状態'}
    ];
    selection['locomotionScore'] = locomotionScore;

    selection['clawDiseaseNames'] = [
      {label: '未選択', value: ''},
      {label: '蹄底潰瘍', value: '蹄底潰瘍'},
      {label: '白線病', value: '白線病'},
      {label: '趾皮膚炎', value: '趾皮膚炎'},
      {label: '趾間フレグモーネ', value: '趾間フレグモーネ'},
      {label: '蹄底血斑', value: '蹄底血斑'},
      {label: '蹄尖潰瘍', value: '蹄尖潰瘍'},
      {label: '変形蹄', value: '変形蹄'},
      {label: '横裂蹄', value: '横裂蹄'},
      {label: '縦裂蹄', value: '縦裂蹄'},
      {label: '趾間過形成', value: '趾間過形成'},
      {label: '蹄底菲薄化', value: '蹄底菲薄化'},
      {label: '蹄球びらん', value: '蹄球びらん'},
      {label: '趾間皮膚炎', value: '趾間皮膚炎'},
      {label: '乳頭状趾皮膚炎', value: '乳頭状趾皮膚炎'},
      {label: 'その他', value: 'その他'},
    ];

    selection['etInputType'] = [
      {label: '選択', value: 'embryo'},
      {label: '直接入力', value: 'direct'},
    ];

    selection['embryoMasterRegistered'] = [
      {label: '対象', value: true},
      {label: '対象外', value: false}
    ];

    return selection;
  }
}

app.service('SelectionService', SelectionService);
