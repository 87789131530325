class TopNotificationDialogController {
  constructor(
    $modalInstance,
    params
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.notification = params.notification;
    this.dialogClass = `top-notification-dialog ${this.notification.category.key}`;
  }

  cancel() {
    this.$modalInstance.close();
  }
}

app.controller('TopNotificationDialogController', TopNotificationDialogController);
