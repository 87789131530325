/**
 * @deprecated MasterMarketAPIを利用してください
 * ※この実装の修正および、新規の利用は禁止です
 */
class MasterMarketService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  /**
   * 全市場マスタ取得
   * 預託元牧場、通常の牧場は自牧場の全市場を取得する
   * 預託先牧場の場合は預託元牧場と自牧場の全市場を取得する
   */
  available() {
    return this.APIService.get('/master_market/available');
  }

  /**
   * 全市場マスタ取得(自牧場のみ)
   */
  index() {
    return this.APIService.get('/master_market');
  }

  /**
   * 市場マスタ登録
   *
   * @param {Market} market
   */
  insert(market) {
    return this.APIService.post('/master_market', market);
  }

  /**
   * 市場マスタ更新
   *
   * @param {market} market
   */
  update(market) {
    return this.APIService.put('/master_market/' + market.id, market);
  }

  /**
   * 市場マスタ削除
   *
   * @param {string} marketId 市場ID
   */
  delete(marketId) {
    return this.APIService.delete('/master_market/' + marketId, null);
  }

  updateSortOrder(orders) {
    const params = {orders: orders};
    return this.APIService.post('/master_market/sort_order', params);
  }
}

app.service('MasterMarketService', MasterMarketService);
