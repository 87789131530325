class CowSearchDialog {
  constructor(
    $state,
    $modal
  ) {
    'ngInject';

    this.$state = $state;
    this.$modal = $modal;
  }

  show(searchCondition = {activeOnly: true}) {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/cow-search/dialog.html',
      controller: 'CowSearchDialogController',
      controllerAs: 'ctrl',
      resolve: {
        params: () => {
          return {
            searchCondition: searchCondition
          };
        }
      }
    }).result.then((result) => {
      this.$state.go('cowSearch', {
        searchCondition: result
      }, {
        reload: true
      });
    });
  }
}

app.service('CowSearchDialog', CowSearchDialog);
