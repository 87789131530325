/**
 * @deprecated CowAPIなどを利用してください
 * ※この実装の修正および、新規の利用は禁止です
 */
class CowService {
  constructor(APIService, JWTService) {
    'ngInject';
    this.APIService = APIService;
    this.JWTService = JWTService;

    this.group = {id: '', name: ''};
    this.state = 'フレッシュ';
    this.parentState = '';
  }

  init(groupId, groupName, groupAttr) {
    this.group.id = groupId;
    this.group.name = groupName;
    this.group.attr = groupAttr;
    this.state = 'フレッシュ';
  }

  /**
   * 牛個体識別番号の拡大4桁または5桁を返します。
   * 10桁でない文字列が渡された場合は空白文字を返します。
   *
   * @param {string} cowUid 牛個体識別番号
   * @param {number} digit 生成する牛番号の桁数
   * @return {string}
   *
   * ex1. digit: 4
   * '1234567890' => '6789'
   *
   * ex2. digit: 5
   * '1234567890' => '67890'
   */
  generateCowNo(cowUid, digit = 4) {
    if (!cowUid) return '';
    if (isNaN(cowUid)) return '';

    if (cowUid.length >= 6) {
      return cowUid.substr(5, digit);
    } else {
      return '';
    }
  }

  /**
   * @deprecated Cow.formatCowUidを利用してください
   * ※この実装の修正および、新規の利用は禁止です
   *
   * 牛個体識別番号を耳標で使用される「00000-0000-0」の形式に変換します。
   *
   * @param {string} cowUid 牛個体識別番号
   * @return {string} *****-****-* or 元の値
   */
  formatCowUid(cowUid) {
    if (!cowUid) return '';

    cowUid = String(cowUid);

    if (cowUid.length !== 10) return cowUid;

    const first = cowUid.slice(0, 5);
    const second = cowUid.slice(5, 9);
    const last = cowUid.slice(9, 10);

    return first + '-' + second + '-' + last;
  }

  /**
   * 牛の状態により表示項目を変更するフラグの設定
   * @param {string} state 牛状態
   * @param {string} farmKind 牧場種類
   */
  toggleViewType(state, farmKind) {
    const types = {
      '育成': 'calf',
      '哺育': 'calf',
      '肥育': 'fattening',
      'フレッシュ': 'reproduction',
      '未授精': 'reproduction',
      '授精': 'reproduction',
      '未受胎(－)': 'reproduction',
      '受胎(＋)': 'reproduction',
      '乾乳前期': farmKind === '乳用' ? 'reproduction' : '',
      '乾乳後期': farmKind === '乳用' ? 'reproduction' : '',
      '繁殖除外': 'reproduction',
      'へい死': 'soldOrDied',
      '出荷': 'soldOrDied'
    };
    return types[state] || state;
  }

  // 単体登録
  insert(entryData) {
    return this.APIService.post('/cow', entryData);
  }

  // 一括登録
  insertInBulk(entryDataList) {
    return this.APIService.post('/cow/bulk', entryDataList);
  }

  update(data) {
    return this.APIService.put(`/cow/${data.cowId}`, data);
  }

  delete(cowId) {
    return this.APIService.delete('/cow/' + cowId);
  }

  // 単体GET
  get(cowId) {
    return this.APIService.get('/cow/' + cowId);
  }

  // 牛全頭GET
  index() {
    return this.APIService.get('/cow');
  }

  // ラベル「種雄牛」がついてる牛のみGET
  getBulls() {
    return this.APIService.get('/cow/bull');
  }

  // uId, cowId, cowNoを取得する
  getCowNoList() {
    return this.APIService.get('/cow/cow_no');
  }

  // 分析画面用
  getAnalyticTargetCows(params) {
    return this.APIService.get(`/cow/cow_analytics?${params}`);
  }

  // 牛頭数取得
  getCowSummary() {
    return this.APIService.get('/cow/summary', 'GET');
  }

  // 血統情報取得
  getPedigree() {
    return this.APIService.get('/cow/pedigree', 'GET');
  }

  // 牛群に属する牛全頭GET
  findByCowGroup() {
    return this.APIService.get('/cow/cow_group/' + this.group.id);
  }

  // イベント一括入力用
  deprecatedDigest(allowInactive) {
    const params = allowInactive ? '?allow_inactive=true' : '';
    return this.APIService.get('/cow/deprecated_digest' + params);
  }
}

app.service('CowService', CowService);
