class TopCoolPesconController {
  constructor(
  ) {
    'ngInject';
  }

  activeCount() {
    return this.coolPescon.activeCount();
  }

  inactiveCount() {
    return this.coolPescon.inactiveCount();
  }
}

function topCoolPescon() {
  return {
    templateUrl: 'top/cool-pescon/index.html',
    controller: TopCoolPesconController,
    bindings: {
      coolPescon: '<'
    }
  };
}

app.component('topCoolPescon', topCoolPescon());
