function timelineFixed($window) {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      const onResize = () => {
        if (!($window.innerWidth < 1024 &&
            scope.$root.uiVersion === 'c-1.5' &&
            scope.$ctrl.$state.current.name === 'cowDetail' &&
            scope.$ctrl.activeTab === 'dataTab')) {
          return;
        }

        element.css({
          position: '',
          width: '',
          top: '',
          right: ''
        });
      };
      angular.element($window).bind('resize', onResize);

      const onScroll = () => {
        if (!($window.innerWidth >= 1024 &&
            scope.$root.uiVersion === 'c-1.5' &&
            scope.$ctrl.$state.current.name === 'cowDetail' &&
            scope.$ctrl.activeTab === 'dataTab')) {
          return;
        }

        const height = angular.element('.uSubHeading').outerHeight() +
          parseInt(angular.element('.cow-detail').css('padding-top'), 10);
        const width = angular.element('.dataTab').width() / 3;

        if ($window.pageYOffset > height) {
          element.css({
            position: 'fixed',
            width: width,
            top: '128px',
            right: '16px'
          });
        } else {
          element.css({
            top: '',
          });
        }
      };
      angular.element($window).bind('scroll', onScroll);

      scope.$on('$destroy', () => {
        angular.element($window).unbind('resize', onResize);
        angular.element($window).unbind('scroll', onScroll);
      });
    }
  };
}

app.directive('timelineFixed', ['$window', timelineFixed]);
