class MobileUClipsListController {
  constructor(blockUI, SloppyReportAPI) {
    'ngInject';

    this.blockUI = blockUI;
    this.SloppyReportAPI = SloppyReportAPI;
    this.sloppyReports = [];
    this.selectedSloppyReport = null;
  }

  $onInit() {
    this.blockUI.start('ロード中');
    return this.SloppyReportAPI.index()
      .then(({data}) => {
        this.sloppyReports = data;
      })
      .finally(() => {
        this.blockUI.stop();
      });
  }
}

app.controller('MobileUClipsListController', MobileUClipsListController);
