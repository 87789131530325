class mobileInvalidCowUidModalController {
  constructor($modalInstance) {
    'ngInject';
    this.$modalInstance = $modalInstance;
  }

  onCloseClick() {
    this.$modalInstance.close();
  }
}

app.controller('mobileInvalidCowUidModalController', mobileInvalidCowUidModalController);
