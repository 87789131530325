class GroupActivityScatterPlotCowGroupListController {
  onSelect(group) {
    this.selectedId = group.cowGroupId;
    this.onChangeId({'$id': group.cowGroupId});
  }

  addClassActive(group) {
    if (this.selectedId === group.cowGroupId) {
      return 'active';
    }
  }
}

app.controller('GroupActivityScatterPlotCowGroupListController', GroupActivityScatterPlotCowGroupListController);

function GroupActivityScatterPlotCowGroupListDirective() {
  'ngInject';
  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'menu/group-activity-scatter-plot/cow-group-list.html',
    controller: 'GroupActivityScatterPlotCowGroupListController',
    controllerAs: 'ctrlCowGroup',
    bindToController: {
      cowGroups: '<',
      selectedId: '<',
      onChangeId: '&',
    }
  };
}

app.directive('groupActivityScatterPlotCowGroupList', GroupActivityScatterPlotCowGroupListDirective);
