/**
 * @deprecated
 * アラートメール設定メニューはReactに移行されました。
 * IEユーザーへの対応のために残しています。
 */
class AlertMailIndexController {
  constructor(
    AlertMailAPI,
    $state,
    $modal,
    blockUI,
    $timeout,
    SessionCache
  ) {
    'ngInject';
    this.AlertMailAPI = AlertMailAPI;
    this.$state = $state;
    this.$modal = $modal;
    this.blockUI = blockUI;
    this.$timeout = $timeout;

    this.init(SessionCache.farm());
  }

  init(farm) {
    this.filterCondition = null;

    const showAlertField = farm.useAlertDysstasia() && farm.useAlertCalving();
    this.setUiGrid(showAlertField);

    this.index();
  }

  index() {
    const statusLabels = {
      sending: '確認中',
      sent: '確認中',
      confirmed: '完了',
      invalid: '送信失敗',
    };

    const formatNotificationMenus = (notificationMenus) => {
      const result = [];
      if (notificationMenus.dysstasia) {
        result.push('起立困難');
      }
      if (notificationMenus.calving) {
        result.push('分娩');
      }
      return result.join('  ');
    };

    this.AlertMailAPI.index().then((res) => {
      const records = res.data.map((r) => {
        return {
          id: r.id,
          userName: r.userName,
          mailAddress: r.mailAddress,
          notificationMenus: r.notificationMenus,
          notificationMenusLabel: formatNotificationMenus(r.notificationMenus),
          status: r.status,
          statusLabel: statusLabels[r.status],
          canSendMail: r.status === 'sending' || r.status === 'sent',
          updatedAt: DateUtil.toJapaneseYYYYMMDDHHmm(r.updatedAt),
        };
      });
      this.records = records;

      this.draw();
    });
  }

  draw() {
    this.uiGrid.data = this.records.filter((r) => {
      if (this.filterCondition) {
        return r.userName.includes(this.filterCondition) || r.mailAddress.includes(this.filterCondition);
      }
      return true;
    });

    const sendingCount = this.records.filter((r) => r.status === 'sending').length;
    const invalidCount = this.records.filter((r) => r.status === 'invalid').length;
    const confirmedCount = this.records.filter((r) => r.status === 'confirmed').length;

    this.sendingCount = sendingCount > 0 ? `確認中: ${sendingCount}件` : '';
    this.invalidCount = invalidCount > 0 ? `送信失敗: ${invalidCount}件` : '';
    this.confirmedCount = `登録数: ${confirmedCount}件`;
  }

  setUiGrid(showAlertField) {
    const generateColumns = (showAlertField) => {
      if (showAlertField) {
        return [
          {columnId: 'userName', label: 'ユーザー名', width: 300},
          {columnId: 'mailAddress', label: 'メールアドレス', width: 580},
          {columnId: 'notificationMenusLabel', label: '通知アラート', width: 120},
          {columnId: 'updatedAt', label: '最新更新日時', width: 200},
          {columnId: 'action', label: '操作', width: 80},
        ];
      } else {
        return [
          {columnId: 'userName', label: 'ユーザー名', width: 300},
          {columnId: 'mailAddress', label: 'メールアドレス', width: 580},
          {columnId: 'updatedAt', label: '最新更新日時', width: 200},
          {columnId: 'action', label: '操作', width: 80},
        ];
      }
    };

    const columns = generateColumns(showAlertField);

    const options = {
      cellTemplates: {
        mailAddress: `
          <div class="flex-container align-items-center">
            <span ng-bind="row.entity.mailAddress" class="w320"></span>
            <div ng-if="row.entity.status !== 'confirmed'" class="w80">
              <span ng-if="row.entity.status === 'invalid'"><b ng-bind="row.entity.statusLabel" class="state-error--secondary"></b></span>
              <span ng-if="row.entity.status !== 'invalid'"><b ng-bind="row.entity.statusLabel" class="state-warning"></b></span>
            </div>
            <div ng-if="row.entity.canSendMail" class="w180">
              <button type="button" ng-click="grid.appScope.sendMail(row.entity)" class="button-modern--arrow">確認メール再送信</button>
            </div>
          </div>
        `,
        action: `
          <button ng-click="grid.appScope.update(row.entity)"><i class="icon-s-edit"></i></button>
          <button ng-click="grid.appScope.delete(row.entity)"><i class="icon-s-delete margin-left-15"></i></button>
        `
      }
    };

    this.uiGrid = new UiGridGenerator().generate(this, columns, options);
  }

  create() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/alert-mail-entry/entry.html',
      controller: 'AlertMailEntryController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            heading: 'アラートメール設定の新規登録'
          };
        }
      }
    }).result.then(() => {
      this.index();
    });
  }

  update(record) {
    this.openEditView(ViewMode.UPDATE, 'アラートメール設定の更新', record);
  }

  delete(record) {
    this.openEditView(ViewMode.DELETE, 'アラートメール設定の削除', record);
  }

  openEditView(viewMode, title, model) {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/alert-mail-entry/edit.html',
      controller: 'AlertMailEditController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            title: title,
            model: model,
            viewMode: viewMode
          };
        }
      }
    }).result.then(() => {
      this.index();
    });
  }

  onChangeFilterCondition() {
    this.draw();
  }

  sendMail(record) {
    this.blockUI.start('送信中');

    this.AlertMailAPI.sendMail(record.id).then((res) => {
      this.blockUI.done(() => {
        this.blockUI.start(`確認メールを再送信しました`);
        this.$timeout(() => {
          this.blockUI.stop();
          this.index();
        }, 1000);
      });
      this.blockUI.stop();
    });
  }
}

app.controller('AlertMailIndexController', AlertMailIndexController);
