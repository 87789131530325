class FatteningFarmReportContainerController {
  constructor(
  ) {
    'ngInject';
  }

  $onChanges(obj) {
    if (this.shouldUpdateReport(obj)) {
      this.drawReport();
    }
  }

  shouldUpdateReport(obj) {
    if (!this.items) return true;
    return obj.itemDefinitions && obj.itemDefinitions.currentValue;
  }

  drawReport() {
    this.headers = this.generateHeaders();
    this.items = this.generateItems();
  }

  getAnnualSummary() {
    return this.annualSummaries.find((summary) => summary.fiscalYear === this.year);
  }

  generateHeaders() {
    const headers = this.itemDefinitions.map((item) => item.label);
    headers.unshift('牧場');
    return headers;
  }

  generateItems() {
    if (this.frequency === 'monthly') {
      return FatteningReport.generateFarmMatrixLastMonth(this.agentFarmAnnualSummaries, this.itemDefinitions);
    } else {
      const yearMonth = this.getAnnualSummary().monthly[0].yearMonth;
      return FatteningReport.generateFarmMatrixYear(this.agentFarmAnnualSummaries, this.itemDefinitions, yearMonth);
    }
  }

  /** イベント **/

  changeFrequency() {
    this.closeDetails();
    this.drawReport();
    this.onChangeFrequency({frequency: this.frequency});
  }

  changeYear() {
    this.closeDetails();
    this.drawReport();
    this.onChangeYear({year: this.year});
  }

  /** 表示 **/

  closeDetails() {
    FatteningReport.closeDetails(this.items);
  }

  generateTableClass() {
    return FatteningReport.generateTableClass(this.frequency);
  }

  generateTrClass(item) {
    return FatteningReport.generateTrClass(item);
  }

  toggleTr(id) {
    FatteningReport.toggleTr(this.items, id, FatteningReport.rowIds.FARM_ID);
  }

  generateBtnClass(item) {
    return FatteningReport.generateBtnClass(item);
  }
}

function fatteningFarmReportContainer() {
  return {
    templateUrl: 'menu/report/fattening/components/farm-report-container/index.html',
    controller: FatteningFarmReportContainerController,
    bindings: {
      annualSummaries: '<',
      agentFarmAnnualSummaries: '<',
      year: '<',
      years: '<',
      frequency: '<',
      itemDefinitions: '<',
      onChangeFrequency: '&',
      onChangeYear: '&',
      onClickItemConfig: '&',
    }
  };
}
app.component('fatteningFarmReportContainer', fatteningFarmReportContainer());
