class TemporaryGroupViewCowDetailController {
  constructor(
    $q,
    $state,
    $modal,
    $timeout,
    $rootScope,
    $stateParams,
    CowAPI,
    blockUI,
    CowBleIdAPI,
    StandardDialog,
    MasterMarketAPI,
    DetailsStateFactory
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$modal = $modal;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.CowAPI = CowAPI;
    this.blockUI = blockUI;
    this.CowBleIdAPI = CowBleIdAPI;
    this.StandardDialog = StandardDialog;
    this.MasterMarketAPI = MasterMarketAPI;
    this.DetailsStateFactory = DetailsStateFactory;

    this.cowId = this.$stateParams.cowId;
    this.caller = this.$stateParams.caller;

    // アクセスログに起動経路を出力する
    if (this.$stateParams.caller) {
      this.callerId = this.$stateParams.caller.bqNotes || this.$stateParams.caller.state;
    }

    this.deregisters = [];

    this.init();
  }

  $onDestroy() {
    this.deregisters.forEach((d) => d());
  }

  $onInit() {
    const caller = this.umPagerConfig.caller;

    if (caller.name) {
      this.listName = caller.name;
      this.showReturnLink = true;
    }

    // バックグラウンドクリックにより設定ボードを閉じる
    this.$timeout(() => {
      angular.element('.uHeading__settings').click((e) => e.stopPropagation());
      this.deregisters.push(this.$rootScope.$on('onClickUmBackdrop', () => {
        this.$timeout(() => this.toggleSettingsBoard(false));
      }));
    });
  }

  init() {
    this.defaults = this.DetailsStateFactory.get();
    this.activeTab = this.$stateParams.tab || this.defaults.temporaryGroupViewDisplayTab || 'summaryTab';
    this.hasEvents = false;
    this.cow = {};
    this.dates = {};
    this.umPagerConfig = {
      caller: this.caller,
      next: '',
      prev: ''
    };

    this.currentTermDiseasesMap = {
      mastitis: {
        date: 0,
        id: ''
      },
      lame: {
        date: 0,
        id: ''
      },
      breedingDifficulty: {
        date: 0,
        id: ''
      },
      perinatalDifficulty: {
        date: 0,
        id: ''
      },
      injury: {
        date: 0,
        id: ''
      },
      infect: {
        date: 0,
        id: ''
      },
      otherDifficulty: {
        date: 0,
        id: ''
      }
    };

    this.deregisters.push(this.$rootScope.$on('cowDetail:refreshCowInfo', (ev, data) => {
      ev.stopPropagation();
      if (data && Number(data.cowId) !== Number(this.cowId)) {
        return;
      }
      this.updateCowValues(data);
    }));

    this.deregisters.push(this.$rootScope.$on('cowDetail:refreshHistories', (ev, data) => {
      this.hasEvents = data.some((event) => {
        if (event.eventType === 'alert' || event.eventType === 'shukkateishikaijo') {
          return false;
        }
        return true;
      });
      ev.stopPropagation();
      data.filter((event) => {
        return this.cow && this.cow.latestCalvingDate ?
          Number(this.cow.latestCalvingDate) <= Number(event.occurredAt) : true;
      }).filter((event) => {
        return EventType.isTreatmentForLegacy(event.eventType);
      }).forEach((event) => {
        if (this.currentTermDiseasesMap[event.eventType].date === 0 ||
          Number(this.currentTermDiseasesMap[event.eventType].date) < Number(event.occurredAt)
        ) {
          this.currentTermDiseasesMap[event.eventType] = {
            date: Number(event.occurredAt),
            id: event.id
          };
        }
      });
    }));

    this.index();
  }

  index() {
    this.$q.all([
      this.MasterMarketAPI.index(),
      this.CowAPI.show(this.cowId),
      this.updateSensorIds(),
    ]).then((res) => {
      this.masterMarkets = res[0].data;
      this.updateCowValues(res[1].data);
      this.showTimeline = true;
      this.setPager();
    });
  }

  changeTab(tab) {
    this.activeTab = tab;
    this.DetailsStateFactory.set('temporaryGroupViewDisplayTab', tab);
  }

  openUpdateCowLabels() {
    this.$modal.open({
      templateUrl: 'components/dialog/cow-label/index.html',
      controller: 'CowLabelDialogController',
      controllerAs: 'ctrl',
      size: 'm',
      resolve: {
        params: () => ({
          cowId: this.cowId
        }),
      },
    }).result.then(() => {
      this.CowAPI.show(this.$stateParams.cowId).then((res) => {
        this.updateCowValues(res.data);
      });
    });
  }

  toCowLabelList(cowLabels) {
    if (!cowLabels) return {};

    return cowLabels.split(',').map((label) => {
      return {
        text: label.replace(/(\[|\])/g, '')
      };
    });
  }

  getIdForSubString(id, start, end) {
    return id ? String(id).substr(start, end) : '';
  }

  // 牛個体削除(ヘッダー)
  delete(id) {
    if (this.hasEvents) {
      this.openHasEventsModal();
    } else if (Cow.isUMCreatedCow(this.c)) {
      this.openUMCreatedCowModal();
    } else {
      const modalInstance = this.openConfirmModal();
      modalInstance.result.then((modalResult) => {
        if (!modalResult) return;
        this.blockUI.start('削除中');
        this.CowAPI.delete(id).then(() => {
          this.blockUI.stop();
          this.$state.go('top');
        }).catch((err) => console.error(err));
      });
    }
  }

  openConfirmModal() {
    return this.StandardDialog.showDeleteConfirm({
      title: '個体情報の削除',
      text1: '牛個体情報',
    });
  }

  openHasEventsModal() {
    this.StandardDialog.showMessage({
      title: '個体情報の削除',
      text1: 'この牛個体に関連付けられたイベントがあります。',
      text2: 'イベントの存在する牛個体を削除することはできません。',
    });
  }

  openUMCreatedCowModal() {
    this.StandardDialog.showMessage({
      title: '個体情報の削除',
      text1: 'この牛個体は母牛の分娩イベントより作成されたため、削除することができません。'
    });
  }

  /**
   * ページャー先を指定する
   */
  setPager() {
    const cowIds = this.caller.cowIds;

    // 配列がない場合終了
    if (!cowIds || cowIds.length <= 0) {
      return;
    }

    // 配列の中から現個体のID位置を特定
    const idIndex = cowIds.findIndex((cowId) => {
      return cowId === this.cow.cowId;
    });

    // ペジャー機能
    // 原位置-1 = 前、 +1 = 次
    this.umPagerConfig.prev = cowIds[idIndex - 1] || '';
    this.umPagerConfig.next = cowIds[idIndex + 1] || '';

    this.umPagerConfig.current = idIndex + 1;
    this.umPagerConfig.total = cowIds.length;

    this.$rootScope.umPagerConfig = this.umPagerConfig;
  }

  /**
   * APIレスポンスに存在しないデータを足す
   * @param {Object} cow APIから返ってくる生な牛情報
   */
  updateCowValues(cow) {
    const today = DateUtil.today();

    this.cow = cow;
    this.cow.cowNo = this.cow.cowNo || '-';
    this.cow.state = this.cow.state || '-';

    if (this.cow.birthday) {
      const birthday = Number(this.cow.birthday);
      const eliminateDate = Number(this.cow.eliminateDate);
      const fallingDeadDate = Number(this.cow.fallingDeadDate);
      const endDate = eliminateDate || fallingDeadDate || today;

      this.cow.birthday = birthday;
      this.cow.birthdayMonthAge = DateUtil.monthAge(birthday, endDate, 1);
      this.cow.birthdayDayAge = DateUtil.diffDays(birthday, endDate);
    } else {
      this.cow.birthday = null;
      this.cow.birthdayMonthAge = null;
    }

    if (this.cow.introduceDate) {
      const introduceDate = Number(this.cow.introduceDate);

      this.cow.introduceDate = introduceDate;
      this.cow.introduceDayAge = DateUtil.diffDays(introduceDate, today);

    } else {
      this.cow.introduceDate = null;
      this.cow.introduceDayAge = null;
    }

    const masterMarket = this.masterMarkets.find((market) => {
      return market.id === this.cow.masterMarketId;
    }) || [];

    this.cow.masterMarketName = masterMarket.name;

    this.cowLabelList = this.toCowLabelList(this.cow.cowLabels);
  }

  /**
   * 牛センサー関連箇所を更新
   */
  updateSensorIds() {
    return this.CowBleIdAPI.cowBleIds(this.cowId).then((res) => {
      const bleIds = res.data;
      const firstCowBleId = bleIds.length ? bleIds[0] : null;

      this.ble = {
        bleIds: bleIds,
        bleId: CowBleId.lastCowBleId(bleIds),
        currentCowBleId: CowBleId.currentCowBleId(bleIds),
        startDate: firstCowBleId ? new Date(firstCowBleId.startDate) : null
      };
    });
  }

  showContent(tab) {
    return this.activeTab === tab;
  }

  toggleSettingsBoard(isOpen) {
    if (typeof isOpen === 'boolean') {
      this.showSettingsBoard = isOpen;
    } else {
      this.showSettingsBoard = !this.showSettingsBoard;
    }

    if (this.showSettingsBoard === true) {
      this.overwriteOpen = true;
    }
  }

  mouseleaveSettingsBoard() {
    this.overwriteOpen = false;
  }

  returnToLink() {
    if (this.umPagerConfig.caller.state) {
      const caller = this.umPagerConfig.caller;

      this.$state.go(caller.state, caller.params);
    }
  }

  goTo(to, params) {
    this.$state.go(to, params);
  }

  classSettingsButton() {
    if (this.overwriteOpen === true) {
      return 'uHeading__settingsButton--overwriteOpen';
    }

    return this.showSettingsBoard ?
      'uHeading__settingsButton--open' :
      'uHeading__settingsButton';
  }

  classSettingsBoard() {
    return this.showSettingsBoard ?
      'uHeading__settingsBoard--open' :
      'uHeading__settingsBoard';
  }

  classNumberButton(enable) {
    return enable ?
      'uHeading__numberButton' :
      'uHeading__numberButton--disabled';
  }

  classTabSelector(tab) {
    return this.activeTab === tab ? 'active' : '';
  }
}

app.controller('TemporaryGroupViewCowDetailController', TemporaryGroupViewCowDetailController);
