// eslint-disable-next-line no-unused-vars
class Mastitis {
  constructor(params) {
    Object.assign(this, {mastitisScore: 0}, params);
  }

  static get MASTITIS_SCORES() {
    return [
      {value: 0, label: ''},
      {value: 1, label: '1:乳質異常'},
      {value: 2, label: '2:乳房異常'},
      {value: 3, label: '3:全身症状'}
    ];
  }
}
