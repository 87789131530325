class AlertCalvingReportController {
  constructor(
    $modalInstance,
    AlertCalvingAPI,
    UtilService,
    blockUI,
    $timeout,
    params
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.AlertCalvingAPI = AlertCalvingAPI;
    this.UtilService = UtilService;
    this.blockUI = blockUI;
    this.$timeout = $timeout;

    this.initialize(params);
  }

  initialize(params) {
    this.id = params.id;
    this.confirmedStatus = params.confirmedStatus;
    this.confirmedComment = params.confirmedComment;
  }

  ok(confirmedStatus, confirmedComment) {
    const params = {
      confirmedStatus: confirmedStatus,
      confirmedComment: confirmedComment
    };

    this.blockUI.start('更新中');

    this.AlertCalvingAPI.update(this.id, params).then(() => {
      this.blockUI.done(() => {
        this.blockUI.start('更新が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);
      });
      this.blockUI.stop();

      const result = {
        confirmedStatus: confirmedStatus,
        confirmedComment: confirmedComment
      };

      this.$modalInstance.close(result);
    }).catch((error) => {
      this.errorMessage = this.UtilService.formatErrorMessage(error.data.messages);
      this.blockUI.stop();
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('AlertCalvingReportController', AlertCalvingReportController);
