class ChartMilkingController {
  constructor(
    MilkingScheduleService,
    ChartGenerator,
    $scope,
    IsMobile
  ) {
    'ngInject';

    this.MilkingScheduleService = MilkingScheduleService;
    this.ChartGenerator = ChartGenerator;
    this.defaults = {
      chartDisplayRange: 30
    };
    this.$scope = $scope;
    this.isInitialized = false;
    this.height = 320;
    this.isMobile = IsMobile;
  }

  $onDestroy() {
    if (this.state && this.state.milkingStackedBarChart) {
      this.state.milkingStackedBarChart.destroy();
    }
  }

  initializeState() {
    return {
      activityPeriod: null,
      baseDate: new Date(),
      activityDate: null,
      milkingStackedBarChart: null,
      isLoading: false
    };
  }

  $onInit() {
    this.state = this.initializeState();
    this.setIsLoading(true);

    // 搾乳スケジュール
    this.MilkingScheduleService.index()
      .then((res) => {
        const hasMilkingSchedule = res.data.length > 0;
        const milkingStackedBarChart = new MilkingStackedBarChart('#activity-stacked-bar-chart', hasMilkingSchedule, this.isMobile);
        const options = milkingStackedBarChart.options;
        options.size.height = this.height || options.size.height;
        options.padding.left = this.paddingLeft || options.padding.left,
        options.padding.right = this.paddingRight || options.padding.right,
        options.padding.top = this.paddingTop || options.padding.top,
        options.padding.bottom = this.paddingBottom || options.padding.bottom;
        options.axis.y.label.position = 'inner-right';
        this.state.milkingStackedBarChart = milkingStackedBarChart;

        this.setIsLoading(false);
        this.isInitialized = true;
      });
  }

  setIsLoading(state) {
    this.state.isLoading = state;
    this.$scope.$broadcast('isLoadingChange', state);
  }

  loadDataAndDrawChart() {
    if (!this.state.activityDate || !this.state.activityPeriod || !this.cow) return;

    this.setIsLoading(true);
    this.state.milkingStackedBarChart.clear();

    return this.ChartGenerator.milkingChart(
      this.state.activityDate,
      this.cow.cowId,
      this.state.activityPeriod
    ).then((res) => {
      this.state.chartData = res;
    }).then(() => {
      this.drawChart(
        this.state.milkingStackedBarChart,
        this.state.chartData,
        this.state.activityPeriod
      );
    }).finally(() => this.setIsLoading(false));
  }

  drawChart(milkingStackedBarChart, chartData, activityPeriod) {
    if (this.hasNoData()) return;

    let kind;
    if (activityPeriod === 7) {
      kind = this.disableHourlyTick
        ? ActivityStackedBarChart.KIND.DATE_TIME_SIMPLE
        : ActivityStackedBarChart.KIND.DATE_TIME;
    } else {
      kind = ActivityStackedBarChart.KIND.DATE;
    }

    milkingStackedBarChart.show(chartData, kind);
  }

  hasNoData() {
    return !this.state.isLoading && this.state.chartData && Object.keys(this.state.chartData).length <= 1;
  }

  onActivityPeriodChanged(period) {
    this.state.activityPeriod = period;
    this.onChangePeriod({period: period});
    this.loadDataAndDrawChart();
  }

  onActivityDateChanged(activityDate) {
    this.state.activityDate = activityDate;
    this.loadDataAndDrawChart();
  }
}

app.controller('ChartMilkingController', ChartMilkingController);
