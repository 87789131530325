class SortOrderSwitcherControler {
  constructor() {
    this.seletedItemKey = null;
  }

  up() {
    if (!this.seletedItemKey) return;

    const selectedIndex = this.indexOfSelected(this.seletedItemKey, this.items);
    const swappedIndex = selectedIndex - 1;
    if (swappedIndex < 0) return;

    this.swapItem(selectedIndex, swappedIndex);
  }

  down() {
    if (!this.seletedItemKey) return;

    const selectedIndex = this.indexOfSelected(this.seletedItemKey, this.items);
    const swappedIndex = selectedIndex + 1;
    if (swappedIndex >= this.items.length) return;

    this.swapItem(selectedIndex, swappedIndex);
  }

  toFirst() {
    if (!this.seletedItemKey) return;

    const selectedIndex = this.indexOfSelected(this.seletedItemKey, this.items);
    const selectedItem = this.items[selectedIndex];

    for (let i = selectedIndex - 1; i >= 0; i--) {
      this.items[i + 1] = this.items[i];
    }
    this.items[0] = selectedItem;
  }

  toLast() {
    if (!this.seletedItemKey) return;

    const selectedIndex = this.indexOfSelected(this.seletedItemKey, this.items);
    const selectedItem = this.items[selectedIndex];
    const lastIndex = this.items.length - 1;

    for (let i = selectedIndex + 1; i <= lastIndex; i++) {
      this.items[i - 1] = this.items[i];
    }
    this.items[lastIndex] = selectedItem;
  }

  indexOfSelected(seletedItemKey, items) {
    let selectedIndex = -1;
    items.some((item, index) => {
      if (item[this.keyField] === seletedItemKey) {
        selectedIndex = index;
        return true;
      }
      return false;
    });
    return selectedIndex;
  }

  swapItem(selectedIndex, swappedIndex) {
    const selectedItem = this.items[selectedIndex];
    const swappedItem = this.items[swappedIndex];
    this.items[swappedIndex] = selectedItem;
    this.items[selectedIndex] = swappedItem;
  }

  decideTemplateUrl(isLegacy) {
    const fileName = isLegacy ? 'index-legacy.html' : 'index.html';
    return `components/sort-order-switcher/${fileName}`;
  }
}

function sortOrderSwitcher() {
  return {
    template: '<ng-include src="ctrl.decideTemplateUrl(ctrl.isLegacy)"></ng-include>',
    controller: SortOrderSwitcherControler,
    controllerAs: 'ctrl',
    bindings: {
      items: '=',
      keyField: '<',
      seletedItemKey: '<',
      isLegacy: '<',
    }
  };
}

app.component('sortOrderSwitcher', sortOrderSwitcher());
