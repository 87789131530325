// eslint-disable-next-line no-unused-vars
class KeyInputUtil {
  static get SYSTEM_KEYS() {
    return ['Tab', 'Delete', 'Backspace', 'Enter', 'ArrowRight', 'ArrowLeft', 'Home', 'End'];
  }

  /**
   * 入力されたキーが数字かどうかを判定します。
   *
   * @param {Object} event AngularJSの$eventオブジェクト
   * @param {boolean} allowSysteKey true: タブやデリートキーを許容する
   * @return {boolean} true: 数字キー
   */
  static isNumberKey(event, allowSysteKey = true) {
    if ('0123456789'.indexOf(event.key) >= 0) {
      return true;
    }

    if (event.ctrlKey) {
      return true;
    }

    if (allowSysteKey && KeyInputUtil.SYSTEM_KEYS.includes(event.key)) {
      return true;
    }

    return false;
  }

  static isEnterKey(event) {
    return event.keyCode === 13;
  }
}
