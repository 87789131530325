class ReproductionHistoryModalController {
  constructor(
    $timeout,
    $modalInstance,
    params
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
    this.$timeout = $timeout;

    this.init(params);
  }

  init(params) {
    const items = angular.copy(params.items);

    this.activeTab = params.birthNumber >= 0 ? params.birthNumber : items[0].birthNumber;
    this.showTabMoreList = false;

    this.items = items.map((item, index, arr) => {
      const afterItem = arr[index + 1];

      if (afterItem) {
        const calvingEvent = angular.copy(afterItem.cowEvents).find((event) => {
          return event.eventType === 'bunben';
        });

        if (calvingEvent) {
          calvingEvent.afterCalviedDays = null;

          if (item.cowEvents[item.cowEvents.length - 1] &&
            item.cowEvents[item.cowEvents.length - 1].display.year.value === calvingEvent.display.year.value) {
            calvingEvent.display.year.hidden = true;
          }

          item.cowEvents.push(calvingEvent);
        } else {
          const abortNextStartMilkingEvent = angular.copy(afterItem.cowEvents).find((event) => {
            return event.eventType === 'abort' && event.nextStartMilkingFlg;
          });

          if (abortNextStartMilkingEvent) {
            abortNextStartMilkingEvent.afterCalviedDays = null;

            if (item.cowEvents[item.cowEvents.length - 1] &&
              item.cowEvents[item.cowEvents.length - 1].display.year.value ===
              abortNextStartMilkingEvent.display.year.value) {
              calvingEvent.display.year.hidden = true;
            }

            item.cowEvents.push(abortNextStartMilkingEvent);
          }
        }
      }

      return item;
    });

    if (params.timelineTargetId) {
      this.$timeout(() => {
        const offsetTopHistory = angular.element('.uModal__history').offset().top;
        const offsetTopHistoryItem = angular.element(`.uModal__historyItem--primary[data-event-id="${params.timelineTargetId}"]`).offset().top;

        angular.element('.uModal__history').animate({
          scrollTop: offsetTopHistoryItem - offsetTopHistory - 60
        }, 500, 'swing');
      }, 100);
    }
  }

  cancel() {
    this.$modalInstance.close();
  }

  showHistoryInner(birthNumber) {
    return this.activeTab === birthNumber;
  }

  showHistoryDaysLine(cowEvents, last) {
    if (last) return false;

    return cowEvents.length;
  }

  showHistoryItemPrimary(eventType) {
    return ['tanetsuke'].includes(eventType);
  }

  showHistoryItemSecondary(eventType) {
    return [
      'ninshinkantei',
      'kannyu',
      'bunben'
    ].includes(eventType);
  }

  showHistoryItemTertiary(eventType, grouping) {
    return eventType === 'hormoneProgram' && grouping;
  }

  showHistoryItemQuaternary(eventType) {
    return [
      'breedingDifficulty',
      'freshCheck',
      'hatsujo',
      'abort',
      'embryoRecovery',
      'doNotBreed',
    ].includes(eventType);
  }

  showAfterFertilizationDays(event) {
    return !event.afterFertilizationEventId && !event.pregnant;
  }

  changeActiveTab(birthNumber) {
    this.activeTab = birthNumber;
    this.showTabMoreList = false;
    angular.element('.uModal__history').scrollTop(0);
  }

  toggleShowTabMoreList() {
    this.showTabMoreList = !this.showTabMoreList;
  }

  pageTop() {
    angular.element('.uModal__history').animate({
      scrollTop: 0
    }, 400, 'swing');
  }

  classTabLink(birthNumber) {
    return this.activeTab === birthNumber ? 'uModal__tabLink--active' : 'uModal__tabLink';
  }

  classTabList(birthNumber) {
    return this.activeTab === birthNumber ? 'uModal__tabList--active' : 'uModal__tabList';
  }

  classTabLinkMore() {
    return this.showTabMoreList ? 'uModal__tabLink--active' : 'uModal__tabLink';
  }
}

app.controller('ReproductionHistoryModalController', ReproductionHistoryModalController);
