class CowEventBulkInputController {
  static get LOCAL_STORAGE_PASTE_MODE_KEY() {
    return 'CowEventBulkInput-PasteMode';
  }

  constructor(
    CowEventBulkInput,
    SelectBoxFactory,
    modalDialogFactory,
    $stateParams,
    SelectMedicineModal,
    blockUI,
    $rootScope,
    FileUtilService,
    ViewStateAPI,
    ReproductionConfigService
  ) {
    'ngInject';

    this.SelectBoxFactory = SelectBoxFactory;
    this.modalDialogFactory = modalDialogFactory;
    this.SelectMedicineModal = SelectMedicineModal;
    this.blockUI = blockUI;
    this.$rootScope = $rootScope;
    this.FileUtilService = FileUtilService;
    this.ViewStateAPI = ViewStateAPI;
    this.ReproductionConfigService = ReproductionConfigService;

    this.state = CowEventBulkInput;

    this.eventType = $stateParams.eventType;
    this.qwert = $stateParams.qwert;

    let isPasteMode = false;
    if (this.shouldShowClipboardButton()) {
      isPasteMode = LocalStorageEntry.use(
        this.$rootScope.userName,
        CowEventBulkInputController.LOCAL_STORAGE_PASTE_MODE_KEY,
        false
      );
    }

    this.ReproductionConfigService.show().then((res) => {
      this.state.embryos = Object.entries(res.data.embryoRecoveryRank).filter(([_, value]) => {
        return value.use;
      }).map(([key, value]) => {
        const {subrank} = value;
        const rank = key.length === 1 ? key.toUpperCase() : key;

        if (!subrank || subrank === 1) {
          return {
            rank,
            count: ''
          };
        }

        return Array(subrank).fill('').map((_, index) => {
          const subrank = index + 1;

          return {
            rank: `${rank}${subrank}`,
            count: ''
          };
        });
      }).flat();
    });

    // FIXME: BulkEntryEventsStateをfactoryなどで実装し直す
    this.state
      .init($stateParams, isPasteMode);
  }

  onRemoveLameClick(eventIndex, lameIndex) {
    this.state.removeLame(eventIndex, lameIndex);
  }

  onAddLameClick(index) {
    this.state.addLame(index);
  }

  changeDisplayOfTime(faultDate) {
    if (faultDate) return ' top';
    return ' middle';
  }

  openMedicineModal(ch) {
    const medicineModal = this.SelectMedicineModal.open(
      ch.selectedMedicines || [],
      this.eventType,
      this.qwert
    );

    medicineModal.result.then((result) => {
      ch.selectedMedicines = result;
    });
  }

  /**
  * 行選択ハンドラー
  * NOTE: 選択イベントのselectedの値のトグルは、テンプレートのng-modelバインディングで行うため、ここでは変更しない
  */
  onToggleSelection() {
    this.state.updateHasSelection();
  }

  onToggleAllSelection(selected) {
    this.state.toggleAllSelection(selected);
  }

  onCopy1stLine() {
    this.state.copy1stLine();
  }

  onCancelClick() {
    this.state.cancel();
  }

  onSubmitClick() {
    this.state.toggleAllSelection();
    this.state.bulkEntry().catch(() => {
      this.blockUI.stop();
      this.modalDialogFactory.showAlertModal({
        title: 'エラー',
        text1: '入力内容に誤りがあります。',
        no: false, yes: false
      });
    });
  }

  saveFromClipboard() {
    this.state.saveFromClipboard();
  }

  onChangeTargetDate() {
    switch (this.eventType) {
    case 'tanetsuke':
      this.state.cowEvents.forEach((event) => {
        const targetCow = this.cowIdMap[event.cowId];
        if (!targetCow) return;

        const hour = this.targetDate ? event.selectedHour : 0;
        const minute = this.targetDate ? event.selectedMinute : 0;
        const occurredAt = this.DateUtilService.addTimeToDate(Number(this.targetDate), hour, minute);

        event.inseminationCode =
          event.inseminationCode || HormoneProgram.currentHormoneProgramName(targetCow, occurredAt);
      });
      break;
    }
  }

  onRemoveEmbryoClick(c) {
    c.masterEmbryoId = '';
    c.masterEmbryoName = '';
  }

  onSelectMasterEmbryoClick(c) {
    this.modalDialogFactory.openEmbryoSelectDialog(
      this.state.masterEmbryoId,
      this.state.farmKind,
      this.state.embryoMasters
    ).result
      .then((result) => {
        if (result.status === EmbryoSelectDialogController.RETURN_STATUS.OK) {
          c.masterEmbryoId = result.masterEmbryoId;
          c.masterEmbryoName = result.masterEmbryoName;
        }

        const selected = this.state.masterEmbryos.find((m) => Number(m.id) === Number(c.masterEmbryoId));

        // 新規作成された場合はマスターを再度ロードする
        if (!selected) {
          // TODO: isDepositでリクエストを変更する
          this.state.updateEmbryoMasters();
        }
      }).catch((result) => {
        //キャンセル時にコンソールエラーがでるのでダミー
      });
  }

  /**
   * ターゲット日付・時刻を表示するかどうかの判定
   */
  shouldShowTargetDateTime() {
    return this.state.targetDate ? true : false;
  }

  shouldDisableTreatmentDetail() {
    return this.state.targetDate ? false : true;
  }

  /**
   * 種付けイベント 精液番号を表示するか、受精卵マスターを表示するか
   */

  shouldShowSpermField() {
    return this.state.tanetsukeMethod === '人工授精';
  }

  shouldShowEmbryoField() {
    return this.state.tanetsukeMethod === '移植';
  }

  shouldShowRemoveEmbryoButton(cow) {
    return this.shouldShowEmbryoField() && cow.masterEmbryoId;
  }

  shouldShowInseminationCodeField() {
    return this.state.tanetsukeMethod === '人工授精';
  }

  shouldShowClipboardButton() {
    const patableEventTypes = [
      'gyugunidou',
      'tanetsuke',
      'ninshinkantei',
    ];
    return patableEventTypes.includes(this.eventType);
  }

  paste() {
    this.ViewStateAPI.create(
      'clipboard-paste-cow-event-bulk-input',
      'cow-event-bulk-input',
      {eventType: this.eventType}
    );

    ClipboardUtil.pasteFromClipboard().then((text) => {
      const matrix = StringUtil.parseClippedMatrix(text, false);
      this.state.pasteFromClipboard(matrix);
      this.$rootScope.$digest();
    });
  }

  pasteModeOn() {
    this.state.pasteModeOn();
    LocalStorageEntry.set(
      this.$rootScope.userName,
      CowEventBulkInputController.LOCAL_STORAGE_PASTE_MODE_KEY,
      true
    );
  }

  filterPasteRow() {
    this.state.filterPasteRow();
  }

  pasteModeOff() {
    this.state.pasteModeOff();
    LocalStorageEntry.set(
      this.$rootScope.userName,
      CowEventBulkInputController.LOCAL_STORAGE_PASTE_MODE_KEY,
      false
    );
  }

  exportPasteSample() {
    const result = this.state.pasteSample();
    this.FileUtilService.exportAsExcel(result.sample, result.fileName);
  }
}

app.controller('CowEventBulkInputController', CowEventBulkInputController);
