class MobileGroupTransferHistoryCurrentFarmController {
  constructor(
    $modal,
    GroupTransferAPI,
    MobileBulkActionSelectModal
  ) {
    'ngInject';

    this.$modal = $modal;
    this.GroupTransferAPI = GroupTransferAPI;
    this.MobileBulkActionSelectModal = MobileBulkActionSelectModal;

    this.init();
  }

  init() {
    this.model = {
      condition: {},
      histories: []
    };
    this.transferTypes = GroupTransfer.generateAllTransferTypes();
    this.columns = GroupTransferHistoryCurrentFarm.COLUMNS;

    this.index();
  }

  index() {
    this.model.condition = {
      transferDate: {
        minDate: DateUtil.toMSec(DateUtil.addDays(DateUtil.today(), -7)),
        maxDate: DateUtil.toMSec(DateUtil.today())
      },
      inOut: 'all',
      transferType: 'all',
      cowState: 'enrolledOnly'
    };

    this.search();
  }

  search() {
    const condition = {
      fromTransferDate: this.model.condition.transferDate.minDate,
      toTransferDate: this.model.condition.transferDate.maxDate,
      inOut: this.model.condition.inOut,
      transferType: this.model.condition.transferType,
      enrolledOnly: this.model.condition.cowState === 'enrolledOnly'
    };

    return this.GroupTransferAPI.historyCurrentFarm(condition).then((res) => {
      const data = res.data;

      this.model.histories = GroupTransferHistoryCurrentFarm.updateCow(data, this.transferTypes);

      this.groupingCow();
    });
  }

  groupingCow() {
    this.model.histories = this.model.histories.reduce((acc, cur) => {
      const findTransferDate = acc.find((item) => {
        return item.transferDate === cur.origin.transferDate;
      });

      if (findTransferDate) {
        const findCows = findTransferDate.items.find((history) => {
          return history.inOut === cur.origin.inOut;
        }).cows;

        findCows.push(cur);
      } else {
        const isIn = cur.origin.inOut === 'in';

        acc.push({
          transferDate: cur.origin.transferDate,
          formattedTransferDate: cur.transferDate,
          items: [{
            inOut: 'in',
            label: GroupTransferHistoryCurrentFarm.decideInOutLabel('in'),
            cows: isIn ? [cur] : []
          }, {
            inOut: 'out',
            label: GroupTransferHistoryCurrentFarm.decideInOutLabel('out'),
            cows: isIn ? [] : [cur]
          }]
        });
      }

      return acc;
    }, []);
  }

  sortColumns() {
    const emptyColumn = {
      columnId: null,
      name: ''
    };
    const filtered = this.columns.filter((column) => {
      return column.enableSorting;
    });

    return [emptyColumn].concat(filtered);
  }

  filterColumns() {
    const filtered = this.columns.filter((column) => {
      return column.enableFiltering;
    }).map((column) => {
      if (column.columnId === 'transferType') {
        const allColumn = {
          value: 'all',
          label: '全て',
        };

        column.selectionOptions = [allColumn].concat(this.transferTypes);
      }

      return column;
    });

    return filtered;
  }

  onSelectButtonClick() {
    this.showFloatingboxLists = false;
    this.toggleIsSelectMode();
  }

  onSelectAllButtonClick() {
    this.isAllSelected = !this.isAllSelected;
    this.setAllSelected(this.isAllSelected);
  }

  onCheckBoxClick(cow) {
    cow.selected = !cow.selected;

    this.cowSelected = this.model.histories.some((history) => {
      const cows = history.items.map((item) => {
        return item.cows;
      }).flat();

      return cows.some((cow) => {
        return cow.selected;
      });
    });

    this.isAllSelected = this.model.histories.every((history) => {
      const cows = history.items.map((item) => {
        return item.cows;
      }).flat();

      return cows.filter((cow) => {
        return cow.canAddEvent;
      }).every((cow) => {
        return cow.selected;
      });
    });
  }

  toggleIsSelectMode() {
    this.isSelectMode = !this.isSelectMode;
    this.setAllSelected(false);
    this.isAllSelected = false;

    if (this.isSelectMode) {
      this.maskHeadAndFoot = true;
    } else {
      this.maskHeadAndFoot = false;
    }
  }

  toggleShowFloatingboxLists() {
    this.showFloatingboxLists = !this.showFloatingboxLists;
  }

  setAllSelected(selected) {
    this.model.histories = this.model.histories.map((history) => {
      history.items = history.items.map((item) => {
        item.cows = item.cows.map((cow) => {
          if (cow.canAddEvent) {
            cow.selected = selected;
          }

          return cow;
        });

        return item;
      });

      return history;
    });

    this.cowSelected = selected;
  }

  onActionButtonClick() {
    const selectedCows = this.model.histories.map((history) => {
      const cows = history.items.map((item) => {
        return item.cows;
      }).flat();

      return cows.filter((cow) => {
        return cow.selected;
      });
    }).flat();

    const actions = [{
      value: 'registerEvent',
      text: 'イベント登録',
      new: true
    }];

    // ダイアログの上下がブロックされるのを防ぐためのフラグ操作
    this.maskHeadAndFoot = false;
    this.canceled = true;

    this.MobileBulkActionSelectModal.open(selectedCows, actions).result.then((result) => {
      this.canceled = false;

      switch (result.value) {
      case 'registerEvent':
        this.openBulkEventEntry(selectedCows).finally(() => {
          this.maskHeadAndFoot = this.isSelectMode;
        });
        break;
      default:
      case 'cancel':
        this.maskHeadAndFoot = true;
        break;
      }
    }).finally(() => {
      if (this.canceled) {
        this.maskHeadAndFoot = true;
      }
    });
  }

  openBulkEventEntry(selectedCows) {
    return this.$modal.open({
      templateUrl: 'mobile/bulk-entry/mobile-bulk-entry-event-select.html',
      controller: 'EventSelectionController',
      controllerAs: 'ctrl',
      size: '770',
      resolve: {
        cowIds: () => selectedCows.map((cow) => cow.cowId),
        cowType: () => null,
        isDepositor: () => null,
        qwert: () => null,
        caller: () => null
      },
    }).result.then((result) => {
      if (result.status && result.status.submit) {
        this.toggleIsSelectMode();
      }
    });
  }

  onCowClick(cow) {
    if (!this.isSelectMode || !cow.canAddEvent) return;

    this.onCheckBoxClick(cow);
  }

  onFilterButtonClick() {
    this.showFloatingboxLists = false;

    if (this.isFilterModalOpen) return;
    this.isFilterModalOpen = true;

    const params = {
      sortColumns: this.sortColumns(),
      filterColumns: this.filterColumns(),
      conditions: this.model.condition,
      sortColumnId: this.sortColumnId,
      sortOrder: this.sortOrder
    };

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'mobile/group/transfer/history-current-farm/filter/index.html',
      controller: 'MobileGroupTransferHistoryCurrentFarmFilterController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => params
      }
    }).result.then((result) => {
      if (result.status && result.status.submit) {
        if (result.sortColumnId !== '') {
          this.sortColumnId = result.sortColumnId;
          this.sortOrder = result.sortOrder;
        }

        this.model.condition = result.conditions;

        this.search().then(() => {
          this.sort();
        });
      }
      this.isFilterModalOpen = false;
    }).catch((result) => {
      this.isFilterModalOpen = false;
    });
  }

  sort() {
    if (!this.sortColumnId) return;

    if (this.sortColumnId === 'transferDate') {
      this.model.histories = this.model.histories.sort((a, b) => {
        if (this.sortOrder === 'ascending') {
          return a.transferDate - b.transferDate;
        }

        return b.transferDate - a.transferDate;
      });
    } else if (this.sortColumnId === 'inOut') {
      this.model.histories = this.model.histories.map((history) => {
        history.items = history.items.sort((a, b) => {
          if (this.sortOrder === 'ascending') {
            return a.inOut > b.inOut ? 1 : -1;
          }

          return a.inOut < b.inOut ? 1 : -1;
        });

        return history;
      });
    } else {
      this.model.histories = this.model.histories.map((history) => {
        history.items = history.items.map((item) => {
          item.cows = item.cows.sort((a, b) => {
            const valA = a[this.sortColumnId] || '';
            const valB = b[this.sortColumnId] || '';

            if (this.sortOrder === 'ascending') {
              return valA > valB ? 1 : -1;
            }

            return valA < valB ? 1 : -1;
          });

          return item;
        });

        return history;
      });
    }
  }

  showConditionsOther() {
    return this.model.condition.inOut !== 'all' ||
      this.model.condition.transferType !== 'all' ||
      this.model.condition.cowState !== 'all';
  }

  showCardCheckbox(cow) {
    return this.isSelectMode && cow.canAddEvent;
  }

  formatDate(date) {
    return DateUtil.toYYYYMMDD(date);
  }

  classCard() {
    return this.isSelectMode ? 'uCard--selectable' : 'uCard--unselectable';
  }

  classCardClickable(selected) {
    return selected ? 'uCard__clickable--selected' : 'uCard__clickable';
  }

  classCardInner(selected) {
    return selected ? 'uCard__inner--selected' : '';
  }

  classFloatingbox() {
    return this.isSelectMode ? 'uFloatingbox' : 'uFloatingbox--open';
  }

  classHeadMask() {
    return document.querySelector('.stickyAlert__items') ? 'uContent__mask--head__withStickyAlert' : 'uContent__mask--head';
  }
}

app.controller('MobileGroupTransferHistoryCurrentFarmController', MobileGroupTransferHistoryCurrentFarmController);
