// eslint-disable-next-line no-unused-vars
class TimelineElementDecorator {
  constructor(obj, selections) {
    this.obj = obj;
    // TODO: Proxyに変えたい
    Object.keys(obj).forEach((key) => {
      if (key in this) return;
      this[key] = obj[key];
    });

    this.carcassDefectSummary = Carcass.defectSummary(this.carcassDefects);
    this.selections = selections;
  }

  static get OTHER_DISEASE_NAME() {
    return {
      otherDifficulty: 'その他症状',
      injury: 'その他',
      infect: 'その他'
    };
  }

  /**
   * その他の病名・症状を返す
   * otherDiseaseNameが無い場合は治療イベント毎の文字列を返す
   *
   * @see TimelineElementDecorator.OTHER_DISEASE_NAME
   * @return {string}
   */
  get otherDiseaseName() {
    if (this.obj.otherDiseaseName) {
      return this.obj.otherDiseaseName;
    } else {
      return TimelineElementDecorator.OTHER_DISEASE_NAME[this.obj.eventType];
    }
  }

  /**
   * 採卵イベント: 受精卵マスタへの登録
   */
  get embryoMasterRegisteredLabel() {
    return this.obj.embryoMasterRegistered ? '対象' : '対象外';
  }

  get nextCowStateDisplay() {
    if (!this.obj.nextCowState) {
      return undefined;
    }

    if (!this.selections || !this.selections.embryoRecoveryNextCowState) return '';

    const selection = this.selections.embryoRecoveryNextCowState.find((s) => {
      return s.value === this.obj.nextCowState;
    });

    return selection ? selection.label : '';
  }
}
