class TopCalvingSensorController {
  constructor(
    SessionCache,
    CalvingSensorAPI
  ) {
    'ngInject';

    this.CalvingSensorAPI = CalvingSensorAPI;

    const farm = SessionCache.farm();

    this.init(farm);
  }

  init(farm) {
    this.useAlertCalving = farm.data.useAlertCalving;
  }

  $onChanges(changes) {
    if (changes.date) {
      this.index(changes.date.currentValue);
      this.dateYYYYMMDD = DateUtil.toYYYYMMDD(changes.date.currentValue, '-');
    }
  }

  index(date) {
    if (!this.useAlertCalving) return;

    this.CalvingSensorAPI.candidatesSummary(date).then((res) => {
      const data = res.data;
      const total = Object.values(data).reduce((a, b) => a + b);
      const events = Object.entries(data).filter((item) => {
        return item[1] > 0;
      }).map((item) => {
        const key = item[0];
        const count = item[1];

        return {
          eventType: key,
          label: `${CalvingSensor.eventTypeLabel(key)}予定`,
          count: count
        };
      });

      this.summary = {
        total: total,
        events: events
      };

      this.total.calvingSensor = this.summary.total;
    });
  }
}

function topCalvingSensor() {
  return {
    templateUrl: 'top/schedule/calving-sensor/index.html',
    controller: TopCalvingSensorController,
    controllerAs: 'ctrl',
    bindings: {
      date: '<',
      total: '='
    }
  };
}

app.component('topCalvingSensor', topCalvingSensor());
