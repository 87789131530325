// 活動量プロットグラフ
// eslint-disable-next-line no-unused-vars
class ActivityScatterPlotChart {
  constructor(selector,
    Dictionary,
    options = null
  ) {
    this.selector = selector;
    this.Dictionary = Dictionary;
    this.options = {
      bindto: this.selector,
      padding: {
        top: 10,
        left: 60,
        right: 40,
        bottom: 10
      },
      data: {
        classes: {
          self: 'self'
        },
        xs: {
          normal: 'normal_x',
          fertilization: 'fertilization_x',
          sick: 'sick_x',
          self: 'self_x'
        },
        json: {
          //normal: [],
          //normal_x: [],
          //fertilization: [],
          //fertilization_x: [],
          //sick: [],
          //sick_x: [],
          //self: [],
          //self_x: []
        },
        names: {
          normal: 'その他牛個体',
          fertilization: '授精',
          sick: '疾病',
          self: 'self'
        },
        colors: {
          normal: '#006400',
          fertilization: '#ab19b1',
          sick: '#176fe3',
          self: '#000000'
        },
        type: 'scatter',
        onclick: this._clickEventHandler.bind(this)
      },
      title: {
        text: ''
      },
      zoom: {
        enabled: true,
      },
      axis: {
        x: {
          min: 0,
          label: {
            text: '総反芻時間',
            position: 'outer-bottom'
          },
          tick: {
            fit: false
          },
          padding: {
            left: 0,
            right: 100
          }
        },
        y: {
          min: 0,
          label: {
            text: '総活動時間',
            position: 'outer-right'
          },
          padding: {
            top: 50,
            bottom: 0
          }
        }
      },
      grid: {
        x: {
          show: true,
          lines: [],
        },
        y: {
          show: true,
          lines: [],
        }
      },
      regions: [
      ],
      subchart: {
        show: false
      },
      point: {
        show: true
      },
      tooltip: {
        show: true,
      },
      onrendered: () => {
      },
      transition: {
        duration: 0
      }
    };

    if (options) this.applyOptions(options);
  }

  /**
   * チャートを表示する。
   *
   * @param {Object} { normal:[], fertilization:[], sick:[], self:[] }
   */
  show(data) {
    this.destroy();

    if (data.self[0]) this.options.data.names.self = data.self[0].cowNo;
    this.originData = data;
    this._c3ChartData();
    this._createTooltipContents();
    this.chart = c3.generate(this.options);
  }

  update(jsonData) {
    if (!this.chart) return;
    this.chart.load({
      json: jsonData
    });
  }

  destroy() {
    if (!this.chart) return;
    this.chart.destroy();
  }

  clear() {
    $(this.selector).html('');
  }

  afterClickEventHandler() {
    // do nothing
  }

  applyOptions(options) {
    if (options.chart.data.names) {
      this.options.data.names = options.chart.data.names;
    }
    if (options.action) {
      this.afterClickEventHandler = options.action;
    }
  }

  _createTooltipContents() {
    this.options.tooltip.contents = (data, defaultTitleFormat, defaultValueFormat, color) => {
      let id = data[0].id;
      let targets = this.originData[id].filter((datum, index, array)=> {
        return (Number(datum.totalActivity) === data[0].value && Number(datum.totalRumination) === data[0].x);
      });

      let text = '<table class="c3-tooltip">';
      text += '<tbody>';
      text += `<tr><th>${this.Dictionary.COW.COW_NO}</th><th>総活動時間</th><th>総反芻時間</th></tr>`;
      targets.forEach((target) => {
        text += '<tr class="c3-tooltip-name-' + id + '">';
        text += '<td class="name">' + target.cowNo + '</td>';
        text += '<td class="value">' + target.totalActivity + '</td>';
        text += '<td class="value">' + target.totalRumination + '</td>';
        text += '</tr>';
      });
      text += '</tbody>';
      text += '</table>';
      return text;
    };
  }

  _c3ChartData() {
    const chartData = {
      normal: [],
      normal_x: [],
      fertilization: [],
      fertilization_x: [],
      sick: [],
      sick_x: [],
      self: [],
      self_x: []
    };

    const summaryData = [
      'groupCaption',
      'totalActivity10percentile',
      'totalActivity90percentile',
      'totalRumination10percentile',
      'totalRumination90percentile',
      'totalLying10percentile',
      'totalLying90percentile',
      'ruminationLying10percentile',
      'ruminationLying90percentile',
    ];

    this.options.grid.y.lines = [
      {'value': this.originData['totalActivity10percentile']},
      {'value': this.originData['totalActivity90percentile']},
    ];

    this.options.grid.x.lines = [
      {'value': this.originData['totalRumination10percentile']},
      {'value': this.originData['totalRumination90percentile']},
    ];

    Object.keys(this.originData).forEach((key) => {
      if (summaryData.includes(key)) {
        return;
      }
      this.originData[key].forEach((datum) => {
        chartData[key].push(datum.totalActivity);
        chartData[key + '_x'].push(datum.totalRumination);
      });
    });
    this.options.data.json = chartData;
  }

  _clickEventHandler(d, element) {
    let id = d.id;
    let targets = this.originData[id].filter((datum, index, array)=> {
      return (Number(datum.totalActivity) === d.value && Number(datum.totalRumination) === d.x);
    });
    if (targets.length === 1) {
      this.afterClickEventHandler(targets[0].cowId);
    } else if (targets.length > 1) {
      this._createTargetsPanel(targets, element);
    } else {
      console.error('targets is illegal');
    }
  }

  _createTargetsPanel(targets, element) {
    const chart = $(this.selector);
    const div = $('<div>').addClass('targets-panel').offset({
      top: $(element).offset().top - $(this.selector).offset().top,
      left: $(element).offset().left - $(this.selector).offset().left
    }).on('mouseleave', (e) => {
      chart.find('.targets-panel').remove();
    });
    const title = $('<div>').addClass('title').text(this.Dictionary.COW.COW_NO);
    div.append(title);
    targets.forEach((target) => {
      let targetDiv = $('<div>').addClass('target').text(target.cowNo).on('click', (e) => {
        this.afterClickEventHandler(target.cowId);
      });
      div.append(targetDiv);
    });
    chart.append(div);
  }
}
