class FileImportErrorController {
  constructor(
    $modalInstance,
    params
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;

    this.errors = params.errors;
    this.errorRowCount = params.errorRowCount;
    this.message = `${this.errorRowCount}行にエラーがあります`;
  }

  close() {
    this.$modalInstance.close(false);
  }
}

app.controller('FileImportErrorController', FileImportErrorController);
