// eslint-disable-next-line no-unused-vars
class UmPlatformPartnerMonitoring {
  constructor($interval, api, sessionCache) {
    this.$interval = $interval;
    this.api = api;
    this.sessionCache = sessionCache;
  }

  static get MONITORING_MSEC() {
    return 3600000; // 1時間
  }

  run() {
    this.getUmPlatformPartner();

    this.$interval(() => this.getUmPlatformPartner(), UmPlatformPartnerMonitoring.MONITORING_MSEC);
  }

  // private

  /**
   * um-platform-apiから連携情報を取得する。
   */
  getUmPlatformPartner() {
    this.api.show().then((res) => {
      const partner = res ? res.data : {};
      const umPlatformPartner = new UmPlatformPartner(partner);
      this.sessionCache.store('umPlatformPartner', umPlatformPartner);
    }).catch((error) => console.error(error.message));
  }
}
