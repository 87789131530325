class HomeScheduleReproductionPlannedEventsController {
  constructor(
    $timeout,
    ReproductionPlannedEventsAPI
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.ReproductionPlannedEventsAPI = ReproductionPlannedEventsAPI;

    this.init();
  }

  init() {
    this.filterEvents = [
      'bunben',
      'overBunben',
      'hatsujo',
      'tanetsuke',
      'hormoneProgram',
      'ninshinkantei',
      'kannyu'
    ];
  }

  $onChanges() {
    this.loading.schedule.reproductionPlannedEvents = true;

    if (!this.date) return;

    this.index().then(() => {
      this.loading.schedule.reproductionPlannedEvents = false;
      this.$timeout(() => {
        this.enableMasonry();
      }, 200);
    });
  }

  index() {
    return this.ReproductionPlannedEventsAPI.summary(this.date).then((res) => {
      const total = Object.keys(res.data.summary).reduce((acc, key) => {
        return acc + res.data.summary[key];
      }, 0);

      const events = this.filterEvents.filter((key) => {
        return key in res.data.summary;
      }).reduce((acc, key) => {
        return acc.concat([{
          eventType: key,
          label: ReproductionPlannedEvent.eventTypeLabel(key),
          count: res.data.summary[key]
        }]);
      }, []);

      this.summary = {
        total,
        events
      };

      this.total.reproductionPlannedEventsTotal = this.summary.total;
    });
  }
}

function homeScheduleReproductionPlannedEvents() {
  return {
    templateUrl: 'menu/home/schedule/reproduction-planned-events/index.html',
    controller: HomeScheduleReproductionPlannedEventsController,
    controllerAs: 'ctrl',
    bindings: {
      enableMasonry: '&',
      loading: '=',
      total: '=',
      date: '<'
    }
  };
}

app.component('homeScheduleReproductionPlannedEvents', homeScheduleReproductionPlannedEvents());
