app.factory('modalDialogFactory', ($rootScope, $modal, IsMobile) => {
  'ngInject';
  let _option = {
    animation: true,
    controller: 'dialogController',
    backdrop: 'static',
    keyboard: false,
    size: 'm',
    resolve: {params: () => {}}
  };

  return {
    showHistoryConfirm: (params) => {
      params = params || {};
      params.isConfirm = true;
      _option.templateUrl = 'dialog/dialog.html';
      _option.resolve = {params: () => {
        return params;
      }};
      return $modal.open(_option);
    },
    showYesNoConfirm: (params) => {
      params = params || {};
      params.isConfirm = true;
      _option.templateUrl = 'dialog/dialog.html';
      _option.resolve = {params: () => {
        return params;
      }};
      return $modal.open(_option);
    },
    showMultipleChoicesConfirm: (params) => {
      params = params || {};
      _option.templateUrl = 'dialog/multiple-choices-dialog.html';
      _option.resolve = {params: () => {
        return params;
      }};
      return $modal.open(_option);
    },
    showAlertModal: (params, backdrop, keyboard) => {
      params = params || {};
      _option.templateUrl = 'dialog/dialog.html';
      _option.backdrop = typeof backdrop === 'boolean' ? backdrop : backdrop || _option.backdrop;
      _option.keyboard = keyboard || _option.keyboard;
      _option.resolve = {params: () => {
        return params;
      }};
      return $modal.open(_option);
    },
    showSuccessiveAlertModal: (params, backdrop, keyboard) => {
      params = params || {};
      params.isSuccessive = true;
      _option.templateUrl = 'dialog/dialog.html';
      _option.backdrop = typeof backdrop === 'boolean' ? backdrop : backdrop || _option.backdrop;
      _option.keyboard = keyboard || _option.keyboard;
      _option.resolve = {params: () => {
        return params;
      }};
      return $modal.open(_option);
    },
    showMobileAlertDialog: (params, backdrop, keyboard) => {
      params = params || {};

      _option.templateUrl = 'dialog/mobile-alert-dialog.html';
      _option.backdrop = typeof backdrop === 'boolean' ? backdrop : backdrop || _option.backdrop;
      _option.keyboard = keyboard || _option.keyboard;
      _option.resolve = {
        params: () => {
          return params;
        }
      };
      return $modal.open(_option);
    },
    showMobileListAlertDialog: (params, backdrop, keyboard) => {
      params = params || {};

      _option.templateUrl = 'dialog/mobile-list-alert-dialog.html';
      _option.backdrop = typeof backdrop === 'boolean' ? backdrop : backdrop || _option.backdrop;
      _option.keyboard = keyboard || _option.keyboard;
      _option.resolve = {
        params: () => {
          return params;
        }
      };
      return $modal.open(_option);
    },
    openEmbryoSelectDialog(masterEmbryoId, farmKind, embryoMasters) {
      return $modal.open({
        windowTemplateUrl: 'components/u-modal/window.html',
        templateUrl: 'history/selection/embryo/embryo-select-dialog.html',
        controller: 'EmbryoSelectDialogController',
        controllerAs: 'ctrl',
        backdrop: false,
        keyboard: false,
        resolve: {
          params: () => {
            return {
              masterEmbryoId,
              farmKind
            };
          },
          embryoMasters: () => {
            return embryoMasters;
          }
        }
      });
    }
  };
});
