/*1
 * 起立困難アラート設定の閾値用コンポーネント
 *
 * ex.
 *   <alert-dysstasia-config-edit-threshold
 *     errors="errors"
 *     thresholds="thresholds"
 *     on-change="onChange()"
 *   ></alert-dysstasia-config-edit-threshold>
 *
 * プロパティ:
 *  @param {Array<string>} errors エラー配列
 *  @param {Array<Object>} thresholds 閾値オブジェクト配列
 *  @param {Function} onChange 入力値変更時のイベント
 */
class AlertDysstasiaConfigEditThresholdController {
  constructor() {
    'ngInject';
  }

  changeDisabledMonthAgeTo(threshold) {
    if (threshold.disabledMonthAgeTo) {
      threshold.monthAgeTo = null;
    } else {
      threshold.monthAgeTo = threshold.monthAgeFrom;
    }
    this.onChange();
  }

  preventDefaultIfNonNumber(e) {
    if (!KeyInputUtil.isNumberKey(e)) {
      e.preventDefault();
    }
  }

  addThreshold() {
    this.thresholds.push({
      threshold: AlertDysstasiaDetectionThreshold.DEFAULT_THRESHOLD,
      disabledMonthAgeTo: false
    });
    this.onChange();
  }

  removeThreshold(index) {
    this.thresholds.splice(index, 1);
  }
}

function alertDysstasiaConfigEditThresholdComponent() {
  return {
    templateUrl: 'menu/setting/alert/dysstasia-config/edit/threshold.html',
    controller: AlertDysstasiaConfigEditThresholdController,
    transclude: true,
    bindings: {
      errors: '<',
      thresholds: '=',
      onChange: '&'
    }
  };
}

app.component('alertDysstasiaConfigEditThreshold', alertDysstasiaConfigEditThresholdComponent());
