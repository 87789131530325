class CowBoardContext {
  constructor(
    CowService,
    FarmService,
    MasterMarketService
  ) {
    'ngInject';

    this.CowService = CowService;
    this.FarmService = FarmService;
    this.MasterMarketService = MasterMarketService;
  }

  load() {
    return this.MasterMarketService.available();
  }

  cowService() {
    return this.CowService;
  }

  farmService() {
    return this.FarmService;
  }
}

app.service('CowBoardContext', CowBoardContext);
