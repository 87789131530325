class FatteningReportAPI {
  constructor(
    APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/fattening/report');
  }
}

app.service('FatteningReportAPI', FatteningReportAPI);
