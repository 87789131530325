class DhiImportHistoryController {
  constructor(
    $state,
    $modal,
    DhiImportAPI
  ) {
    'ngInject';

    this.$state = $state;
    this.$modal = $modal;
    this.DhiImportAPI = DhiImportAPI;
  }

  $onChanges(changes) {
    if (changes.activeTab.currentValue === 'dataImportHistory') {
      this.columnDefinitions();
      this.index();
    }
  }

  columnDefinitions() {
    this.columns = [{
      field: 'createdAt',
      name: '実行日時',
      width: 160,
    }, {
      field: 'dhiLinkage',
      name: '取込元システム',
      width: 160,
      hidden: !this.showBothImport()
    }, {
      field: 'dhiDate',
      name: '検定日',
      width: 100
    }, {
      field: 'fileName',
      name: 'ファイル名',
      width: 400
    }, {
      field: 'operationUserName',
      name: '実行者',
      width: 140
    }, {
      field: 'allCount',
      name: '総件数',
      width: 80,
      disableSorting: true
    }, {
      field: 'successCount',
      name: '取込件数',
      width: 80,
      disableSorting: true
    }, {
      field: 'errorCount',
      name: 'エラー件数',
      width: 110,
      disableSorting: true
    }, {
      field: 'detailLink',
      name: '明細',
      width: 30,
      disableSorting: true,
      hiddenName: true
    }, {
      field: 'deleteLink',
      name: '削除',
      width: 30,
      disableSorting: true,
      hiddenName: true
    }];
  }

  showDataImportHistory() {
    return this.activeTab === 'dataImportHistory';
  }

  index() {
    this.defaultSort = {
      field: 'createdAt',
      order: 'desc'
    };

    this.currentSort = angular.copy(this.defaultSort);
    this.uiGrid = null;
    this.historyErrorMessage = null;

    this.DhiImportAPI.index().then((res) => {
      this.history = res.data.map((item) => {
        return {
          origin: item,
          id: item.id,
          createdAt: DateUtil.format(item.createdAt, 'YYYY/MM/DD HH:mm:ss'),
          dhiLinkage: this.convertDhiLinkage(item.dhiLinkage),
          dhiDate: DateUtil.toYYYYMMDD(item.dhiDate),
          fileName: item.fileName,
          operationUserName: item.operationUserName,
          allCount: item.allCount,
          successCount: item.successCount,
          errorCount: item.errorCount
        };
      });

      this.uiGrid = {
        appScopeProvider: this,
        columnDefs: new DhiImportHistoryGenerator().generateColumnDefs(this.columns),
        data: this.history,

        onRegisterApi: (gridApi) => {
          this.gridApi = gridApi;
        }
      };

      this.uiGrid.excessRows = this.history.length;

      this.sortHistory();
    }).catch((res) => {
      this.historyErrorMessage = this.formatErrorMessage({
        res: res,
        defaultMessage: '検定データ取込履歴の取得でエラーが発生しました。'
      });

    });
  }

  convertDhiLinkage(dhiLinkage) {
    if (dhiLinkage === 'hmrt') {
      return '牛群検定Webシステム';
    } else {
      return '繁殖台帳Webシステム';
    }
  }

  clickHeaderCell(field, disableSorting) {
    if (disableSorting) return;

    this.sortHistory(field);
  }

  sortHistory(field) {
    if (field) {
      if (this.currentSort.field !== field) {
        this.currentSort.field = field;
        this.currentSort.order = 'asc';
      } else {
        if (this.currentSort.order === 'asc') {
          this.currentSort.order = 'desc';
        } else if (this.currentSort.field !== this.defaultSort.field) {
          this.currentSort = angular.copy(this.defaultSort);
        } else {
          this.currentSort.order = 'asc';
        }
      }
    }

    this.history = this.history.sort((a, b) => {
      const asc = a.origin[this.currentSort.field] < b.origin[this.currentSort.field] ? -1 : 1;
      const desc = b.origin[this.currentSort.field] < a.origin[this.currentSort.field] ? -1 : 1;

      return this.currentSort.order === 'asc' ? asc : desc;
    });
  }

  classHeaderCell(field) {
    if (this.currentSort.field === field) {
      if (this.currentSort.order === 'asc') {
        return 'ui-grid-sorted-asc';
      } else {
        return 'ui-grid-sorted-desc';
      }
    }
  }

  styleHeaderCell(field) {
    const width = `${this.columns.find((column) => {
      return column.field === field;
    }).width}px`;

    return {
      width: width,
      minWidth: width
    };
  }

  classGridError(id, errorCount) {
    if (this.hoverGridId === id) {
      return 'contents__uiGridError--hover';
    }

    return errorCount > 0 ? 'contents__uiGridError--emphasis' : 'contents__uiGridError';
  }

  hoverGridError(id = null) {
    this.hoverGridId = id;
  }

  goToHistoryDetail(importId) {
    this.$state.go('dhi-import-detail', {
      importId: importId
    });
  }

  showErrorListModal(importId) {
    this.DhiImportAPI.errorList(importId).then((res) => {
      const titles = ['行番号', 'エラー内容'];

      this.$modal.open({
        windowTemplateUrl: 'components/u-modal/window.html',
        templateUrl: 'menu/dhi/dhi-import/modal.html',
        controller: 'DhiImportModalController',
        controllerAs: 'ctrl',
        backdrop: false,
        resolve: {
          params: () => {
            return {
              mode: 'grid',
              heading: '検定データ取込のエラー詳細',
              grid: {
                titles: titles,
                data: res.data
              }
            };
          }
        }
      });
    }).catch((res) => {
      const message = this.formatErrorMessage({
        res: res,
        defaultMessage: 'エラー詳細の取得でエラーが発生しました。'
      });

      this.$modal.open({
        windowTemplateUrl: 'components/u-modal/window.html',
        templateUrl: 'menu/dhi/dhi-import/modal.html',
        controller: 'DhiImportModalController',
        controllerAs: 'ctrl',
        backdrop: false,
        resolve: {
          params: () => {
            return {
              size: 'small',
              mode: 'message',
              heading: '検定データ取込履歴',
              message: message
            };
          }
        }
      });
    });
  }

  showDeleteModal(id) {
    const history = this.history.find((item) => {
      return item.id === id;
    });

    const data = [{
      field: 'createdAt',
      name: '実行日時'
    }, {
      field: 'dhiDate',
      name: '検定日'
    }, {
      field: 'fileName',
      name: 'ファイル名'
    }].map((item) => {
      return {
        field: item.field,
        name: item.name,
        value: history[item.field]
      };
    });

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/dhi/dhi-import/modal.html',
      controller: 'DhiImportModalController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            size: 'small',
            mode: 'delete',
            heading: '検定データの削除',
            message: '以下の検定データを<span class="uModal__textEmphasis">削除</span>しますが、よろしいですか？',
            id: id,
            data: data
          };
        }
      }
    }).result.then(() => {
      this.index();
    }, () => {
      return;
    });
  }
}

function dhiImportHistoryComponent() {
  return {
    templateUrl: 'menu/dhi/dhi-import/import-history.html',
    controller: DhiImportHistoryController,
    controllerAs: 'ctrl',
    bindings: {
      activeTab: '<',
      showBothImport: '&',
      formatErrorMessage: '&'
    }
  };
}

app.component('dhiImportHistory', dhiImportHistoryComponent());
