class MobileCalvingSensorEquipModalController {
  constructor(
    $timeout,
    $modalInstance,
    params,
    blockUI,
    CowCalvingSensorHistoryAPI
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$modalInstance = $modalInstance;
    this.params = params;
    this.blockUI = blockUI;
    this.CowCalvingSensorHistoryAPI = CowCalvingSensorHistoryAPI;

    this.init();
  }

  init() {
    this.invalidSensorNumber = false;
    this.invalidStartDate = false;
    this.errorMessage = {};
    this.model = angular.copy([this.params.cow]).map((cow) => {
      cow.startDate = DateUtil.toMSec(DateUtil.today());
      cow.sensorNumber = {
        firstNumber: '',
        lastNumber: ''
      };

      return cow;
    })[0];

    this.CowCalvingSensorHistoryAPI.list(this.model.cowId).then((res) => {
      this.latestCowCalvingSensorEndDate = Math.max(...res.data.map((item) => item.endDate));
    });
  }

  update() {
    const params = [{
      cowId: this.model.cowId,
      calvingSensorNumber: CowCalvingSensorHistory.generateCalvingSensorNumber(
        this.model.sensorNumber.firstNumber,
        this.model.sensorNumber.lastNumber),
      startDate: this.model.startDate
    }];

    this.errorMessage = {};
    this.CowCalvingSensorHistoryAPI.bulkEquip(params).then(() => {
      this.blockUI.done(() => {
        this.blockUI.start('分娩センサーの装着が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);
      });
      this.blockUI.stop();
      this.$modalInstance.close();
    }).catch((err) => {
      const items = err.data.messages;

      items.forEach((item) => {
        if (item.field) {
          if (!this.errorMessage[item.field]) {
            this.errorMessage[item.field] = {};
          }

          this.errorMessage[item.field][item.lineNo] = item.message;
        }
      });

      this.blockUI.stop();
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  onKeydownSensorNumber(e) {
    if (!KeyInputUtil.isNumberKey(e)) {
      e.preventDefault();
    }
  }

  onKeyupSensorNumber(e, field) {
    if (!KeyInputUtil.isNumberKey(e)) {
      e.preventDefault();
    }

    if (field === 'firstNumber' &&
      (StringUtil.isDigit(e.target.value) || e.key === 'ArrowRight') &&
      e.target.selectionStart === 3) {
      document.querySelector('#sensorNumberLastNumber').focus();
    } else if (field === 'lastNumber' &&
      (e.key === 'ArrowLeft' || e.key === 'Backspace') &&
      e.target.selectionStart === 0) {
      document.querySelector('#sensorNumberFirstNumber').focus();
    }
  }

  onChangeSensorNumber() {
    this.invalidSensorNumber = false;
    this.errorMessage.calvingSensorNumber = {};

    if ((this.model.sensorNumber.firstNumber &&
        !StringUtil.isDigit(this.model.sensorNumber.firstNumber)) ||
      (this.model.sensorNumber.lastNumber &&
        !StringUtil.isDigit(this.model.sensorNumber.lastNumber))) {
      this.invalidSensorNumber = true;
      this.errorMessage.calvingSensorNumber[1] = '半角数字で入力してください。';
    }
  }

  onChangeStartDate() {
    this.invalidStartDate = false;
    this.errorMessage.startDate = {};

    if (this.model.startDate <= this.latestCowCalvingSensorEndDate) {
      this.invalidStartDate = true;
      this.errorMessage.startDate[1] = '適用日が前回のセンサー解除日以前の日付です。適用日を変更して下さい。';
    }
  }

  disabledUpdate() {
    if (!(this.model.sensorNumber.firstNumber.length === 3 &&
        this.model.sensorNumber.lastNumber.length === 4)) {
      return true;
    } else if (!(DateUtil.isValidDate(this.model.startDate) &&
        DateUtil.includedPermittedPeriod(this.model.startDate))) {
      return true;
    } else if (this.invalidSensorNumber || this.invalidStartDate) {
      return true;
    }

    return false;
  }
}

app.controller('MobileCalvingSensorEquipModalController', MobileCalvingSensorEquipModalController);
