class DepositMasterHormoneProgramService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  available(qwert) {
    return this.APIService.get(`/deposit/master_hormone_program?qwert=${qwert}`);
  }
}

app.service('DepositMasterHormoneProgramService', DepositMasterHormoneProgramService);
