function heatDetectionRateChartCyclicDirective(
  DateUtilService,
  UtilService
) {
  'ngInject';

  function drawChart(element, performances, targetHeatDetectionRisk, targetPregnancyRisk) {

    const startDates = performances.map((p) => p.startDate);
    startDates.unshift('x');

    const heatDetectionRates = performances.map((p) => UtilService.round(p.heatDetectionRate - p.pregnancyRate, -1));
    heatDetectionRates.unshift('発情発見率');

    const pregnancyRates = performances.map((p) => p.pregnancyRate);
    pregnancyRates.unshift('妊娠率');

    const conceptionRates = performances.map((p) => p.conceptionRate);
    conceptionRates.unshift('受胎率');

    const prConfidenceIntervals = performances.map((p) => p.prConfidenceInterval);

    let yearLines = [];
    const firstDate = startDates[startDates.length - 1];
    const lastDate = startDates[1];
    const firstYear = new Date(firstDate).getFullYear();
    const lastYear = new Date(lastDate).getFullYear();
    for (let i = 0; i < lastYear - firstYear; i++) {
      yearLines.push(lastYear - i);
    }
    yearLines = yearLines.map((e) => {
      return {
        value: `${e}-01-01`,
        class: 'year',
        text: `${e}年`
      };
    });

    c3.generate({
      bindto: element,
      size: {
        width: 670
      },
      data: {
        x: 'x',
        columns: [
          startDates,
          pregnancyRates,
          heatDetectionRates,
          conceptionRates,
        ],
        type: 'bar',
        types: {
          受胎率: 'line',
          'error-data1': 'error',
        },
        groups: [
          ['発情発見率', '妊娠率', '受胎率']
        ],
        order: null,
      },
      axis: {
        x: {
          type: 'timeseries',
          label: {
            text: '期間',
            position: 'outer-right'
          },
          tick: {
            format: '%m/%d',
            rotate: 0,
            multiline: false,
            culling: {
              max: 20
            },
            fit: true,
          },
        },
        y: {
          max: 90,
        }
      },
      color: {
        pattern: ['#FAB1AF', '#FADBDA', '#FAA84B']
      },
      point: {
        show: false
      },
      tooltip: {
        contents: (data, defaultTitleFormat, defaultValueFormat, color) => {
          let startDate = data[0].x,
            endDate = null,
            formattedStartDate = null,
            formattedEndDate = null,
            pregnancyRate = null,
            heatDetectionRate = null,
            conceptionRate = null,
            prConfidenceInterval = null,
            prConfidenceIntervalFromTo = null;

          data.forEach((e) => {
            switch (e.id) {
            case '妊娠率':
              pregnancyRate = e.value;
              break;
            case '発情発見率':
              heatDetectionRate = e.value;
              break;
            case '受胎率':
              conceptionRate = e.value;
              break;
            }
          });

          endDate = performances.filter((p) => {
            return p.startDate === startDate.getTime();
          })[0].endDate;

          if (heatDetectionRate !== null && pregnancyRate !== null) {
            heatDetectionRate = UtilService.round(heatDetectionRate + pregnancyRate, -1);
          }

          prConfidenceInterval = performances.filter((p) => {
            return p.startDate === startDate.getTime();
          })[0].prConfidenceInterval;

          if (pregnancyRate !== null && prConfidenceInterval !== null) {
            prConfidenceIntervalFromTo = `${UtilService.round(pregnancyRate - prConfidenceInterval, -1)}% -
            ${UtilService.round(pregnancyRate + prConfidenceInterval, -1)}%`;
          } else {
            prConfidenceIntervalFromTo = '-';
          }

          formattedStartDate = DateUtilService.toMMDD(startDate);
          formattedEndDate = DateUtilService.toMMDD(endDate);
          pregnancyRate = (pregnancyRate === null) ? '-' : String(pregnancyRate) + '%';
          heatDetectionRate = (heatDetectionRate === null) ? '-' : String(heatDetectionRate) + '%';
          conceptionRate = (conceptionRate === null) ? '-' : String(conceptionRate) + '%';

          return `
            <div class="heat-detection-rate-chart-tooltip">
              <table>
                <thead>
                  <tr>
                    <th colspan=2>${formattedStartDate} - ${formattedEndDate}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>発情発見率</td>
                    <td>${heatDetectionRate}</td>
                  </tr>
                  <tr>
                    <td>受胎率</td>
                    <td>${conceptionRate}</td>
                  </tr>
                  <tr>
                    <td>妊娠率</td>
                    <td>${pregnancyRate}</td>
                  </tr>
                  <tr>
                    <td>妊娠率95%<br>信頼区間</td>
                    <td>${prConfidenceIntervalFromTo}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          `;
        }
      },
      grid: {
        y: {
          lines: [{value: targetPregnancyRisk, class: 'grid20', text: '目標妊娠率'}, {value: targetHeatDetectionRisk, class: 'grid60', text: '目標発情発見率'}]
        },
        x: {
          lines: yearLines
        }
      },
      legend: {
        show: true
      },
    });

    ConfidenceIntervalBar.append(element, prConfidenceIntervals.reverse());
  }

  return {
    restrict: 'E',
    scope: {
      targetHeatDetectionRisk: '<',
      targetPregnancyRisk: '<',
      performances: '<',
    },
    template: `<div id="heat-detection-rate-chart"></div>`,
    link: (scope, element, attrs) => {
      const heatDetectionRateChart = document.getElementById('heat-detection-rate-chart');

      function showTooltip() {
        const heatDetectionRateChartTooltip = document.getElementsByClassName('heat-detection-rate-chart-tooltip')[0];
        if (heatDetectionRateChartTooltip) heatDetectionRateChartTooltip.className = heatDetectionRateChartTooltip.className.includes(' show-tooltip') ?
          heatDetectionRateChartTooltip.className.replace(/ show-tooltip/g, '') :
          heatDetectionRateChartTooltip.className + ' show-tooltip';

        const c3TooltipContainer = document.getElementsByClassName('c3-tooltip-container')[0];
        const c3TooltipContainerTop = Number(c3TooltipContainer.style.top.replace(/px/, ''));
        if (c3TooltipContainer) c3TooltipContainer.className = c3TooltipContainerTop > 150 ?
          c3TooltipContainer.className + ' move-up' :
          c3TooltipContainer.className.replace(/ move-up/g, '');
      }

      heatDetectionRateChart.onclick = showTooltip;

      scope.$watch('performances', () => {
        if (scope.performances) {
          drawChart('#heat-detection-rate-chart', scope.performances, scope.targetHeatDetectionRisk, scope.targetPregnancyRisk);
        }
      });
    }
  };
}

app.directive('heatDetectionRateChartCyclic', ['DateUtilService', 'UtilService', heatDetectionRateChartCyclicDirective]);
