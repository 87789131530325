// eslint-disable-next-line no-unused-vars
class ErrorUtil {

  /**
   * APIのエラーメッセージを改行整形して返します。
   *
   * @param {Array.<Object>} messages APIのエラーメッセージ
   * @return {string} 改行整形されたエラーメッセージ
   *
   * ex.
   * [{field: 'name', message: '既に使用されている名称です。'},
   *  {field: 'startDate', message: '履歴が重複しています。'}]
   * ⇒ '既に使用されている名称です。\n履歴が重複しています。'
  */
  static formatErrorMessage(messages) {
    return messages.map((m) => m.message).join('\n');
  }

  /**
   * APIの一括用のエラーメッセージを改行整形して返します。
   *
   * @param {Array.<Object>} messages APIのエラーメッセージ
   * @return {string} 改行整形されたエラーメッセージ
   *
   * ex.
   * [{field: 'name', lineNo: 1, message: '既に使用されている名称です。'},
   *  {field: 'startDate', lineNo: 1, message: '履歴が重複しています。'},
   *  {field: 'startDate', lineNo:2, message: '履歴が重複しています。'}]
   * ⇒ '1行目:既に使用されている名称です。履歴が重複しています。\n2行目:履歴が重複しています。'
  */
  static formatMultilineErrorMessage(messages) {
    const errors = {};

    messages.forEach((m) => {
      errors[m.lineNo] = errors[m.lineNo] || '';
      errors[m.lineNo] += m.message;
    });

    return Object.keys(errors).sort((a, b) => a - b).map((lineNo) => {
      return `${lineNo}行目： ${errors[lineNo]}`;
    }).join('\n');
  }

  /**
   * APIのレスポンスが「RECORD_NOT_FOUND」エラーかを判定します。
   *
   * @param {Object} response AngularJSのレスポンスオブジェクトのdataフィールド
   * @return {boolean} true: 対象のレコードが存在しない
  */
  static isRecordNotFound(response) {
    if (!response) return false;
    return response.errorCode === 'RECORD_NOT_FOUND';
  }
}
