class HomeHeadageSummaryController {
  constructor(
    SessionCache
  ) {
    'ngInject';

    this.homeSettings = new HomeSettings(SessionCache);

    HomeHeadageBaseController.bind(this);
  }

  updateVisibleDetails(category) {
    const isVisibleDetails = this.isVisibleDetails(category);
    const visibleDetails = this.homeSettings.get('headage').visibleDetails;
    let newVisibleDetails;

    if (isVisibleDetails) {
      newVisibleDetails = {
        visibleDetails: visibleDetails.filter((item) => item !== category)
      };
    } else {
      newVisibleDetails = {
        visibleDetails: [...visibleDetails, ...[category]]
      };
    }

    this.homeSettings.update('headage', newVisibleDetails);
  }

  isVisibleDetails(category) {
    return this.homeSettings.get('headage').visibleDetails.find((item) => {
      return item === category;
    });
  }

  classNameRightBoard() {
    return this.homeSettings.get('headage').visible ? 'uRightBoard' : 'uRightBoard--closed';
  }

  classNameCardHeader(category) {
    return this.isVisibleDetails(category) ? 'uRightBoard__cardHeader' : 'uRightBoard__cardHeader--closed';
  }
}

function homeHeadageSummary() {
  return {
    templateUrl: 'menu/home/headage/summary/index.html',
    controller: HomeHeadageSummaryController,
    controllerAs: 'ctrl',
    bindings: {
      enableMasonry: '&',
      data: '<'
    }
  };
}

app.component('homeHeadageSummary', homeHeadageSummary());
