class AlertIndexController {
  constructor(
    $rootScope,
    $modal,
    $state,
    $stateParams,
    $window,
    $timeout,
    SessionCache,
    CustomlistRunner,
    FileUtilService,
    uiGridConstants,
    ViewStateAPI,
    SideBoardService,
    AlertConfigService,
    DepositAlertConfigAPI
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$modal = $modal;
    this.$state = $state;
    this.$window = $window;
    this.$timeout = $timeout;
    this.CustomlistRunner = CustomlistRunner;
    this.FileUtilService = FileUtilService;
    this.uiGridConstants = uiGridConstants;
    this.ViewStateAPI = ViewStateAPI;
    this.SideBoardService = SideBoardService;
    this.AlertConfigService = AlertConfigService;
    this.DepositAlertConfigAPI = DepositAlertConfigAPI;

    this.windowState = new WindowState();

    this.customlistId = $stateParams.customlistId;

    const farm = SessionCache.farm();
    this.farmType = farm.farmType();
    this.isDepositor = farm.isDepositor();
    this.useCalfManagement = farm.useCalfManagement();

    const params = getPathParams() || {};

    this.isPrinting = false;
    this.loading = true;
    this.showCowBoard = false;

    // ウィンドウ・オープン時にウェブ用のレイアウトが表示されるのを防ぐため、空でプリンタレイアウトを表示
    printScreenLayout({}, () => { });

    this.farmCondition = $stateParams.farmCondition || '';
    this.alertTypeCondition = $stateParams.alertTypeCondition || '';
    this.currentColumnSort = $stateParams.currentColumnSort || null;

    this.callerFuncs = {
      refreshGrid: this.notifyUiGridRowChange.bind(this)
    };

    this.filterIllness = $stateParams.filter === 'illness';
    this.filterStrongMoveLow = $stateParams.filter === 'strongMoveLow';

    this.init(params);
  }

  init(params) {
    this.listName = 'アラート一覧';
    this.printParams = params;

    this.customlist = new Customlist();

    this.master = {};
    this.columns = [];
    this.conditions = [];

    this.cows = [];
    this.rawCows = [];

    this.showFarmCondition = false;
    this.showAlertTypeCondition = false;
    this.filterConditions = [];

    this.sortedGridCol = null; // ソート対象でない他の列のソート状態を解除するための苦肉の策

    this.showCowSelect = true;

    this.endDate = DateUtil.today();
    this.startDate = DateUtil.addDays(this.endDate, -6);

    // 牛個体詳細ボードの表示用
    this.currentCow = null;

    this.componentFuncs = {}; // 一括操作用のイベントバインディングに使用する

    this.loadAlertConfig().then(() => this.run());
  }

  loadAlertConfig() {
    const promise = this.isDepositor ? this.DepositAlertConfigAPI.index() : this.AlertConfigService.show();

    return promise.then((res) => {
      if (this.isDepositor) {
        this.depositAlertHistory = new DepositAlertHistory(res.data);
        this.showAcuteIllness = this.depositAlertHistory.isShowAcuteIllness();
      } else {
        this.showAcuteIllness = res.data.showAcuteIllness;
      }
    });
  }

  setCallbackParam() {
    this.callbackParam = {
      state: 'alertList',
      name: this.listName,
      params: {
        customlistId: this.customlistId
      },
      bqNotes: `alertList config_type = ${this.master.configType}`
    };

    if (this.filterIllness) {
      this.callbackParam.params.filter = 'illness';
    } else if (this.filterStrongMoveLow) {
      this.callbackParam.params.filter = 'strongMoveLow';
    }
  }

  run() {
    const option = {
      period: {
        startDate: this.startDate || DateUtil.toDate('2016-01-01'),
        endDate: this.endDate || DateUtil.today()
      }
    };

    this.loading = true;

    const runner = this.isDepositor ?
      new DepositCustomlistRunner(this.CustomlistRunner, this.showAcuteIllness) : this.CustomlistRunner;

    return runner.run(
      this.customlistId,
      DateUtil.today(),
      option
    ).then((res) => {
      this.setConfig(res.data.config);

      if (this.isDepositor && this.master.isAlertHistory()) {
        this.cows = this.depositAlertHistory.convert(res.data.cows);
      } else {
        this.cows = res.data.cows;
      }

      this.rawCows = [].concat(this.cows);
      this.loading = false;
      this.setFilterCondition(this.rawCows);

      this.setUiGrid();

      this.setCallbackParam();

      if (this.currentColumnSort) {
        const {column, isAscending} = this.currentColumnSort;
        CustomlistSort.sort(this.cows, column, isAscending);
      }
    });
  }

  setConfig(config) {
    this.master = new CustomlistMaster(config.master);
    this.columns = config.columns;
    this.needLabelAction = this.columns.some((c) => c.columnId === 'cow_labels');

    if (this.showAcuteIllness) {
      this.columns.filter((column) => {
        return column.columnId === 'alert_type';
      }).map((column) => {
        column.width = 340;
        return column;
      });
    }

    if (this.master.showEntryColumn) {
      const column = {columnId: 'dummy', actualCaption: '記入欄', width: 200};
      this.columns.push(column);
    }

    this.showPeriod = this.master.needPeriodCondition();

    if (this.filterIllness) {
      this.listName = '疾病傾向';
      this.showAlertTypeCondition = false;
    } else if (this.filterStrongMoveLow) {
      this.listName = '動態(強)低下';
      this.showAlertTypeCondition = false;
    } else {
      this.listName = this.master.name;
      this.showAlertTypeCondition = this.master.hasAlertType();
    }

    if (this.showAlertTypeCondition) {
      this.alertTypeConditions = this.generateAlertTypeOptions(this.farmType, this.showAcuteIllness,
        this.useCalfManagement);
    }

    this.showUFilter = this.master.availableUFilter();

    this.tableStyle = this.tableWidth();

    const columnMap = {};
    this.columns.forEach((c) => {
      columnMap[c.columnId] = c;
    });
    this.columnMap = columnMap;

    this.conditions = config.conditions;

    if (this.master.showDepositedCow) {
      this.isDepositorMode = true;
    } else {
      this.isDepositorMode = false;
    }
    // isDepositorMode が初期化されるまでは、テンプレート中の cow-board をng-ifで非表示にする
    this.showCowBoard = true;

    switch (this.master.configType) {
    case 'alert_heat':
    case 'alert_false_heat':
      this.cowBoardDefaultTab = 'heat';
      break;
    case 'alert_bad_condition':
    case 'alert_illness':
      this.cowBoardDefaultTab = 'activity';
      this.defaultPeriod = 30;
      break;
    case 'alert_dysstasia':
      this.cowBoardDefaultTab = 'activity';
      break;
    default:
      this.cowBoardDefaultTab = 'timeline';
    }
  }

  setUiGrid() {
    const showCowNoLink = !this.isDepositorMode;
    const generator = new UiGridColumnDefGenerator(this.columnMap, showCowNoLink);
    const columnDefs = generator.generate(this.columns);

    this.filter();
    this.uiGrid = {
      appScopeProvider: this,
      enableSorting: false,
      columnDefs: columnDefs,
      rowTemplate: generator.generateRowTemplate(),
      data: this.cows,
      onRegisterApi: (gridApi) => {
        this.gridApi = gridApi;
      },
    };

    this.showUiGrid = true;
    this.showLegacyGrid = false;

    setTimeout(() => this.windowState.resize());
  }

  notifyUiGridRowChange() {
    this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
  }

  setFilterCondition(cows) {
    if (this.isDepositorMode) {
      const conditions = CustomlistRuntimeCondition.generateFilterCondition(cows, 'farm_name');
      if (conditions) {
        this.farmConditions = conditions;
        this.showFarmCondition = true;
        this.showCowSelect = false;
        return;
      }
    }
  }

  generateAlertTypeOptions(farmType, showAcuteIllness, useCalfManagement) {
    const alertTypes = [
      {key: '', label: '全て表示', farmType: 'ALL'},
      {key: 'acute_illness', label: '急性(改)', farmType: 'ALL', showAcuteIllness: true},
      {key: 'illness', label: '急性疾病', farmType: 'ALL'},
      {key: 'chronic_illness', label: '慢性疾病', farmType: 'ALL'},
      {key: 'milk_low', label: '乳量', farmType: 'MILK'},
      {key: 'feed_low', label: '採食', farmType: 'ALL'},
      {key: 'strong_move_low', label: '動態(強)', farmType: 'ALL', useCalfManagement: true},
      {key: 'lie_high', label: '横臥', farmType: 'ALL'},
      {key: 'water_low', label: '飲水', farmType: 'ALL'},
    ];
    return alertTypes.filter((type) => {
      return (type.farmType === 'ALL' || type.farmType === farmType) &&
        (type.showAcuteIllness === showAcuteIllness || !type.showAcuteIllness) &&
        (type.useCalfManagement === useCalfManagement || !type.useCalfManagement);
    });
  }

  filter() {
    let cows = [].concat(this.rawCows);
    if (this.isDepositor && this.master.isAlertHistory()) {
      cows = this.depositAlertHistory.filter(cows);
    }

    if (this.farmCondition) {
      cows = cows.filter((cow) => {
        return cow.farm_name === this.farmCondition;
      });
    }

    if (this.filterIllness) {
      cows = cows.filter((cow) => {
        return cow.acute_illness || cow.illness || cow.chronic_illness;
      });
    } else if (this.filterStrongMoveLow) {
      cows = cows.filter((cow) => {
        return cow.strong_move_low;
      });
    } else if (this.alertTypeCondition) {
      cows = cows.filter((cow) => {
        if (this.alertTypeCondition === 'chronic_illness' &&
          (cow.acute_illness || cow.illness) &&
          cow.chronic_illness) {
          return false;
        }

        return cow[this.alertTypeCondition];
      });
    }

    this.cows = new CustomlistFilter().filter(cows, this.filterConditions);

    if (this.uiGrid) {
      this.uiGrid.data = this.cows;
    }
  }

  onChangeFilterCondition() {
    if (!this.farmCondition) {
      this.showCowSelect = false;
    } else {
      this.showCowSelect = true;
    }
    this.callbackParam.params.farmCondition = this.farmCondition;

    this.filter();
    if (this.currentColumnSort) {
      CustomlistSort.sort(this.cows, this.currentColumnSort.column, this.currentColumnSort.isAscending);
    }
  }

  onClickFilter() {
    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'menu/customlist/runtime/filter-dialog.html',
      controller: 'FilterDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      resolve: {
        params: () => {
          return {
            columns: this.columns,
            currentConditions: this.filterConditions
          };
        }
      }
    });

    modalInstance.result.then((result) => {
      this.filterConditions = result;
      this.filter();
    });
  }

  onClickColumnHeader(columnId, gridCol) {
    if (CustomlistSort.isUnsortable(columnId)) return;

    const column = this.columnMap[columnId];
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        CustomlistSort.sort(this.cows, column, false);
        this.currentColumnSort = {column, isAscending: false};
      } else {
        this.currentColumnSort = null;
        this.filter();
      }
    } else {
      CustomlistSort.sort(this.cows, column, true);
      this.currentColumnSort = {column, isAscending: true};
    }
  }

  // 旧実装用の関数
  generateHeaderClassLegacy(classes) {
    let thClass = '';
    classes.forEach((c) => thClass += ` ${c}`);
    return thClass;
  }

  /**
   * 列ヘッダーのCSSを生成します。
   * 項目毎の静的なCSSクラスと列のソート状態を表すCSSクラスを結合します。
   *
   * @param {String} customlist_column_definition.css_class
   * @param {Object} gridCol UI Gridの列オブジェクト
   * @return {String}
   */
  generateHeaderClass(cssClass, gridCol) {
    let sortedClass = '';
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        sortedClass = 'ui-grid-sorted-asc';
      } else {
        sortedClass = 'ui-grid-sorted-desc';
      }
    }

    return `${cssClass} ${sortedClass}`;
  }

  clearInput(key) {
    this[key] = null;
  }

  onClickConfig() {
    this.$modal.open({
      templateUrl: 'menu/customlist/config/edit.html',
      controller: 'CustomlistEditController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        params: () => {
          return {
            title: 'リスト設定編集',
            customlistId: this.customlistId,
            farmType: this.farmType,
            isDepositor: this.isDepositor,
            disableLegacyGrid: true
          };
        }
      }
    }).result.then(() => {
      this.cows = []; // CustomlistRuntimeDirectiveを再描画させるための措置
      this.loading = true;
      this.run();
    });
  }

  tableWidth() {
    const totalColumnWidth = this.columns.reduce((sum, current) => {
      return sum + current.width;
    }, 0);
    let width = totalColumnWidth + 70;
    if (this.master.showEntryColumn) {
      width += 200;
    }

    return {'max-width': `${width}px`, width: '100%'};
  }

  columnStyle(columnId) {
    const definition = this.columnMap[columnId];
    if (!definition) {
      return null;
    }

    const width = `${definition.width}px`;
    return {width: width, minWidth: width};
  }

  cowBoardState() {
    return this.currentCow ? 'cow-board-opened' : '';
  }

  /* 以下は全画面での共通処理 */

  cowNumber() {
    return this.cows.length + '頭';
  }

  goToDetails(cowId, alertLabel = null) {
    if (this.isDepositor) return;

    const cowIds = this.cows.map((cow) => cow.cowId);
    this.callbackParam['cowIds'] = cowIds;

    let viewState = 'ACTIVITY';
    if (this.master.configType === 'alert_heat') {
      viewState = 'ACTIVITY_RECENT';
    } else if (alertLabel === '乳量') { // alertType「milk_low」で指定したかったが印刷時に影響がでてしまうため苦肉の策
      viewState = 'MILKING';
    }

    this.callbackParam['viewState'] = viewState;
    this.callbackParam.params.currentColumnSort = this.currentColumnSort;
    this.$state.go('cowDetail', {cowId: cowId, caller: this.callbackParam});
  }

  alertLabelsBodyClass() {
    if (this.showAcuteIllness) {
      return 'ui-grid-alertLabels-body--isAcuteIllness';
    }

    return 'ui-grid-alertLabels-body';
  }

  // alertType「illness」などで指定したかったが印刷時に影響がでてしまうため苦肉の策
  alertLabelClass(alertLabel) {
    if (alertLabel === '急性(改)') return 'alertLabel--acuteIllness';
    if (alertLabel === '急性') return 'alertLabel--illness';
    if (alertLabel === '慢性') return 'alertLabel--chronicIllness';
  }

  updateCurrentCow(cow = null, colRenderIndex) {
    if (colRenderIndex === 0) return;

    if (cow) {
      this.SideBoardService.setState('opened', false);
    }

    return this.currentCow = cow;
  }

  classCell(cowId) {
    if (!this.currentCow) return;
    if (cowId !== this.currentCow.cowId) return;

    return 'ui-grid-row-cow-board-opened';
  }

  onClickExport() {
    const cows = angular.copy(this.cows).map((cow) => {
      cow.cow_uid = cow.origin.cow_uid;
      return cow;
    });
    const matrixData = Customlist.formatAsExport(cows, this.columns);
    const csv = matrixData.join('\n');
    this.FileUtilService.exportAsCsv(csv, `${this.listName}.csv`);
  }

  onClickExportAsExcel() {
    const cows = angular.copy(this.cows).map((cow) => {
      cow.cow_uid = cow.origin.cow_uid;
      return cow;
    });
    const matrixData = Customlist.formatAsExport(cows, this.columns);
    this.FileUtilService.exportAsExcel(matrixData, `${this.listName}.xlsx`);
  }

  /**
   * 印刷時に旧グリッドにすげ替えるための苦肉の策
   */
  printGimmick() {
    if (this.showUiGrid) {
      this.printGimmickOn = true;
      this.showUiGrid = false;
      this.showLegacyGrid = true;
    }
  }

  onClickPrint() {
    const defaultTitle = document.title;

    document.title = this.listName;

    this.ViewStateAPI.create(`print-in-customlist-${this.master.configType}`, 'alertList');

    this.farmConditionLabel = this.farmCondition ? this.farmCondition : '全ての牧場';
    if (this.showAlertTypeCondition) {
      const alertTypeCondition = this.alertTypeConditions.find((e) => e.key === this.alertTypeCondition);
      this.alertTypeConditionLabel = alertTypeCondition.label;
    }

    this.$rootScope.isPrinting = true; // 「class: print」を付与するための状態変更
    this.isPrinting = true;

    this.$timeout(() => {
      print(); // 豆知識: 印刷中は描画・画面操作がブロックされる

      if (this.printGimmickOn) {
        this.showUiGrid = true;
        this.showLegacyGrid = false;
      }
      this.$rootScope.isPrinting = false;
      this.isPrinting = false;

      document.title = defaultTitle;
    });
  }
}

app.controller('AlertIndexController', AlertIndexController);
