// eslint-disable-next-line no-unused-vars
class CustomlistSummaryStatus {
  static get SUMMARY_STATUS() {
    return {
      WAITING: '処理待ち',
      PROCESSING: '処理中',
      FINISHED: '完了',
      ABEND: '異常終了'
    };
  }

  static toStatusLabel(status) {
    return CustomlistSummaryStatus.SUMMARY_STATUS[status];
  }

  static toTimeLabel(summaryStatus) {
    switch (summaryStatus.status) {
    case 'WAITING': {
      return '';
    }
    case 'PROCESSING': {
      return DateUtil.toJapaneseYYYYMMDDHHmm(summaryStatus.startAt);
    }
    case 'FINISHED': {
      return DateUtil.toJapaneseYYYYMMDDHHmm(summaryStatus.endAt);
    }
    case 'ABEND': {
      return DateUtil.toJapaneseYYYYMMDDHHmm(summaryStatus.endAt);
    }
    }
  }
}
