class HeaderNotificationsController {
  constructor(
    $rootScope,
    $timeout,
    DateUtilService,
    NotificationsService
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.DateUtilService = DateUtilService;
    this.NotificationsService = NotificationsService;
    this.formattedNotifications = [];
    this.deregisters = [];

    this.index();
  }

  $onDestroy() {
    this.deregisters.forEach((d) => d());
  }

  index() {
    this.NotificationsService.index().then((res) => {
      this.formattedNotifications = Notification.decorate(Notification.sort(Notification.filter(res.data, false)));
    }).catch((err) => {
      console.error(err);
    });

    this.deregisters.push(this.$rootScope.$on('onClickUmBackdrop', () => {
      this.$timeout(() => this.close());
    }));
  }

  buttonClass() {
    return this.tooltipOpened === 'notification'
      ? 'uHeader__notificationButton--open'
      : 'uHeader__notificationButton';
  }

  boardClass() {
    return this.tooltipOpened === 'notification'
      ? 'uHeader__notificationBoard--open'
      : 'uHeader__notificationBoard';
  }

  categoryClass(notification) {
    return `uHeader__notificationBoardItemCategory--${notification.category.key}`;
  }

  toggleBoard() {
    if (this.tooltipOpened !== 'notification') {
      this.tooltipOpened = 'notification';
    } else {
      this.tooltipOpened = null;
    }
  }

  close() {
    if (this.tooltipOpened === 'notification') {
      this.tooltipOpened = null;
    }
  }
}

app.controller('HeaderNotificationsController', HeaderNotificationsController);
