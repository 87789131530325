class CowCalvingSensorHistoryDialogController {
  constructor(
    $modalInstance,
    params,
    CowCalvingSensorHistoryAPI,
    blockUI,
    $timeout,
    modalDialogFactory
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
    this.CowCalvingSensorHistoryAPI = CowCalvingSensorHistoryAPI;
    this.blockUI = blockUI;
    this.$timeout = $timeout;
    this.modalDialogFactory = modalDialogFactory;

    this.cowId = params.cowId || '';
    this.cowNo = params.cowNo || '';

    this.deleteExecuted = false;

    this.init();
  }

  init() {
    this.cowCalvingSensorHistories = [];

    this.CowCalvingSensorHistoryAPI.list(this.cowId).then((res) => {
      const cowCalvingSensorHistories = res.data.reverse();
      this.cowCalvingSensorHistories = cowCalvingSensorHistories;
    });
  }

  onEquip(cowId, calvingSensorNumber, startDate) {
    this.blockUI.start('更新中');

    const params = {
      cowId: cowId,
      calvingSensorNumber: calvingSensorNumber,
      startDate: startDate
    };

    this.CowCalvingSensorHistoryAPI.create(params).then(() => {
      this.blockUI.done(() => {
        this.blockUI.start('分娩センサー番号履歴の追加が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);
      });
      this.$modalInstance.close();
    }).catch((error) => {
      this.errorMessage = ErrorUtil.formatErrorMessage(error.data.messages);
    }).finally(() => this.blockUI.stop());
  }

  onUnequip(id, endDate) {
    const modalInstance = this.modalDialogFactory.showYesNoConfirm({
      title: '解除確認',
      text1: '分娩センサーを解除します。',
      text2: 'よろしいでしょうか？',
      no: true,
      yes: true,
    });

    modalInstance.result.then((result) => {
      if (!result) return;

      this.blockUI.start('解除中');

      const params = {
        endDate: endDate
      };

      this.CowCalvingSensorHistoryAPI.update(id, params).then(() => {
        this.blockUI.done(() => {
          this.blockUI.start('分娩センサー解除が完了しました');
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.$modalInstance.close();
      }).catch((error) => {
        this.errorMessage = ErrorUtil.formatErrorMessage(error.data.messages);
      }).finally(() => this.blockUI.stop());
    });
  }

  onDelete(id, startDate) {
    const formattedStartDate = DateUtil.toYYYYMMDD(startDate);
    const modalInstance = this.modalDialogFactory.showYesNoConfirm({
      title: '削除確認',
      text1: `適用日が [${formattedStartDate}] の履歴を削除します。`,
      text2: 'よろしいでしょうか？',
      no: true,
      yes: true,
    });

    modalInstance.result.then((result) => {
      if (!result) return;

      this.blockUI.start('削除中');

      this.CowCalvingSensorHistoryAPI.delete(id).then(() => {
        this.deleteExecuted = true;

        this.blockUI.done(() => {
          this.blockUI.start('履歴の削除が完了しました');
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.init();
      }).catch((error) => {
        this.errorMessage = ErrorUtil.formatErrorMessage(error.data.messages);
      }).finally(() => this.blockUI.stop());
    });
  }

  cancel() {
    if (this.deleteExecuted) {
      this.$modalInstance.close();
    } else {
      this.$modalInstance.dismiss('cancel');
    }
  }
}

app.controller('CowCalvingSensorHistoryDialogController', CowCalvingSensorHistoryDialogController);
