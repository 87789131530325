/**
 * 牛個体登録/編集確認のヘッダーコンポーネント
 *
 * ex.
 *  <cow-confirmation-header
 *    error-message="errorMessage"
 *    is-registered="isRegistered"
 *    on-return-click="onReturnClick()"
 *    on-submit-click="onSubmitClick()"
 *  ></cow-confirmation-header>
 *
 * プロパティ:
 *  @param {string} error-message エラーメッセージ
 *  @param {boolean} is-registered true: 登録済(編集)、false: 未登録(新規)
 *  @param {Function} on-return-click 戻るクリック時のイベント
 *  @param {Function} on-submit-click 登録/更新クリック時のイベント
 */
class CowConfirmationHeaderController {
  constructor() {
    'ngInject';
  }

  $onInit() {
    if (this.isRegistered) {
      this.screenName = '編集内容確認';
      this.btnText = '更新';
      this.defaultMessage = '以下の内容で更新します。よろしいですか？';
    } else {
      this.screenName = '登録内容確認';
      this.btnText = '登録';
      this.defaultMessage = '以下の内容で登録します。よろしいですか？';
    }
  }

  get labelText() {
    return this.errorMessage || this.defaultMessage;
  }

  get error() {
    return !!this.errorMessage;
  }
}

function cowConfirmationHeaderComponent() {
  return {
    templateUrl: 'menu/cow/components/confirmation/header.html',
    controller: CowConfirmationHeaderController,
    bindings: {
      errorMessage: '<',
      isRegistered: '<',
      onReturnClick: '&',
      onSubmitClick: '&'
    }
  };
}

app.component('cowConfirmationHeader', cowConfirmationHeaderComponent());
