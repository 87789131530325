// eslint-disable-next-line no-unused-vars
class State {
  static values(farmType, excludes = []) {
    const values = [
      {id: 'infant', label: '哺育', farmType: 'ALL', explanation: '哺乳期の子牛'},
      {id: 'replacement', label: '育成', farmType: 'ALL', explanation: '離乳した育成期の牛'},
      {id: 'fresh', label: 'フレッシュ', farmType: 'ALL', explanation: '分娩後のフレッシュ期間'},
      {id: 'not_bred', label: '未授精', farmType: 'ALL', explanation: '種付が1回も行われていない'},
      {id: 'bred', label: '授精', farmType: 'ALL', explanation: '種付が行われた'},
      {id: 'non_pregnant', label: '未受胎(－)', farmType: 'ALL', explanation: '妊娠鑑定で受胎が確認されなかった'},
      {id: 'pregnant', label: '受胎(＋)', farmType: 'ALL', explanation: '妊娠鑑定で受胎が確認された'},
      {id: 'far_off', label: '乾乳前期', farmType: 'MILK', explanation: '乾乳期間の前期に該当する牛'},
      {id: 'close_up', label: '乾乳後期', farmType: 'MILK', explanation: '乾乳期間の後期に該当する牛'},
      {id: 'do_not_breed', label: '繁殖除外', farmType: 'ALL', explanation: '繁殖対象から除外された牛'},
      {id: 'fatting_stage', label: '肥育', farmType: 'BEEF', explanation: '肉用として肥育中の牛'},
      {id: 'sold', label: '出荷', farmType: 'ALL', explanation: '出荷済みの牛'},
      {id: 'died', label: 'へい死', farmType: 'ALL', explanation: 'へい死した牛'},
      {id: 'others', label: 'その他', farmType: 'ALL', explanation: '上記該当しない牛'},
    ];

    return values.filter((v) => {
      if (v.farmType !== 'ALL' && v.farmType !== farmType) {
        return false;
      }

      // excludeに含まれるステータスを除外
      let isPass = true;
      excludes.forEach((id) => {
        if (id === v.id) {
          isPass = false;
        }
      });

      return isPass;
    });
  }

  static isShipmentOrDead(state) {
    return ['出荷', 'へい死'].includes(state);
  }
}
