class MobileBleIdHistoryModal {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  open(param) {
    return this.$modal.open({
      templateUrl: 'mobile/components/ble-id-history-modal/ble-id-history-modal.html',
      controller: 'MobileBleIdHistoryModalController as ctrl',
      resolve: {
        param: () => param
      }
    });
  }
}
app.service('MobileBleIdHistoryModal', MobileBleIdHistoryModal);
