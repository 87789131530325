/**
 * um-form-radio-groupコンポーネント
 *
 * ラジオボタン
 *
 * 使い方
 * <um-form>
  * </um-form>
 *
 * パラメータ:
 * @param {string} um-label グループのラベル
 * @param {boolean} um-disabled グループ内のum-form-inputにng-disabledを設定
 **/

app.component('umFormRadioGroup', {
  controller: 'UmFormRadioGroupController',
  bindings: {
    'umOptions': '=',
    'umModel': '=',
    'umLabel': '@',
    'umNameId': '@',
    'umLabelAttribute': '@',
    'umValueAttribute': '@',
    'umInit': '&',
    'umChange': '&',
    'umDisabled': '='
  },
  transclude: true,
  template: ($attrs, $element) => {
    'ngInject';

    const parentElement = $element[0] ? $element[0].parentElement : null;
    const isSubGroup = parentElement
      ? parentElement.localName === 'um-form-group' || parentElement.className === 'mf-group'
      : false;

    return `
      <um-form-group um-layout="radio-group"
        um-label={{$ctrl.umLabel}}
        ${isSubGroup ? 'um-is-children="true"' : ''}>
          <um-form-input 
            ng-repeat="option in $ctrl.options track by $index"
            um-type="radio"
            um-model="$ctrl.umModel"
            um-init="$ctrl.umInit"
            um-name="{{$ctrl.umNameId + '_' + $index}}"
            um-id="{{$ctrl.umNameId + '_' + $index}}"
            um-label={{option.label}}
            um-value={{option.value}}
            um-width="100"
            um-change="$ctrl.umChange()"
            um-is-children="true"
            um-disabled="$ctrl.umDisabled"></um-form-input>
      </um-form-group>
      <div ng-transclude></div>
    `;
  }
});

class UmFormRadioGroupController {
  constructor($timeout) {
    'ngInject';
    this.$timeout = $timeout;
  }

  /**
  * binding完了後に初期化処理を行う
  */
  $onInit() {
    if (!this.umOptions) return;

    const isObjectOptions = typeof this.umOptions[0] === 'object';
    if (isObjectOptions) {
      this.labelAttribute = this.umLabelAttribute || 'label';
      this.valueAttribute = this.umValueAttrribute || 'value';
      this.options = this.umOptions.map((option) => {
        return {
          label: option[this.labelAttribute],
          value: option[this.valueAttribute],
          isSelected: this.umDefault ? option[this.valueAttribute] === this.umDefault : ''
        };
      });
    } else {
      this.options = this.umOptions;
    }
  }
}

app.controller('UmFormRadioGroupController', UmFormRadioGroupController);
