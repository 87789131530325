// eslint-disable-next-line no-unused-vars
class LocalStorageEntry {
  /**
   * userName@itemKeyの値を設定する
   *
   * @param {string} userName ユーザー名
   * @param {string} itemKey アイテムのキー
   * @param {any} value アイテムの値
   */
  static set(userName, itemKey, value) {
    const storageKey = `${userName}@${itemKey}`;
    localStorage.setItem(storageKey, JSON.stringify(value));
  }

  /**
   * userName@itemKeyの値を返す
   * キーに一致するアイテムの値がない場合はinitValueを返す
   *
   * @param {string} userName ユーザー名
   * @param {string} itemKey アイテムのキー
   * @param {any} initValue アイテムの値が無い場合にかえす初期値
   * @return {any}
   */
  static use(userName, itemKey, initValue) {
    const storageKey = `${userName}@${itemKey}`;
    return JSON.parse(localStorage.getItem(storageKey)) || initValue;
  }
}
