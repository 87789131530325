/**
 * @deprecated listMultiActionを利用してください
 * ※この実装の修正および、新規の利用は禁止です
 */

/**
 * 一覧系画面から選択された牛をまとめて一括登録する
 *  使い方: <list-registration></list-registration>
 *
 * 例: <list-registration qwert="<qwert>"></list-registration>
 */
app.component('listRegistration', listRegistrationComponent());

function listRegistrationComponent() {
  'ngInject';
  return {
    template: `
      <button class="bulk-event-button" ng-click="$ctrl.openEventDialog()">
        {{$ctrl.SelectCowService.size()}}頭をまとめてイベント登録
      </button>
    `,
    controller: listRegistrationController,
    bindings: {
      isDepositor: '=',
      qwert: '=',
      callbackParam: '='
    }
  };
}

function listRegistrationController(
  $modal,
  SelectCowService,
  IsMobile) {
  'ngInject';
  const ctrl = this;
  this.IsMobile = IsMobile;

  /**
   * 起動処理
   */
  ctrl.$onInit = () => {
    ctrl.SelectCowService = SelectCowService;
  };

  /**
   * 一括登録のモーダルを開く
   */
  ctrl.openEventDialog = () => {
    const templateUrl = IsMobile ? 'mobile/bulk-entry/mobile-bulk-entry-event-select.html' : 'components/event-registration/event-selection.html';

    $modal.open({
      templateUrl: templateUrl,
      controller: 'EventSelectionController',
      controllerAs: 'ctrl',
      size: '1012',
      resolve: {
        cowIds: () => ctrl.SelectCowService.cowIds,
        cowType: () => null,
        isDepositor: () => this.isDepositor,
        qwert: () => this.qwert,
        caller: () => this.callbackParam
      },
    });
  };
}
