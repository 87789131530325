class MobileEditBleIdConfirmModalController {
  constructor(
    $modalInstance,
    param
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.title = param.title || '';
    this.texts = param.texts || [];
    this.yesLabel = param.yesLabel || '';
    this.noLabel = param.noLabel || '';
  }

  yes() {
    this.$modalInstance.close(true);
  }

  no() {
    this.$modalInstance.close(false);
  }
}

app.controller('MobileEditBleIdConfirmModalController', MobileEditBleIdConfirmModalController);
