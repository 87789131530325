// eslint-disable-next-line no-unused-vars
class MobileFooterNavController {
  constructor(
    $state,
    $modal,
    blockUI,
    MobileCowSearchUIService,
    EventRegisterService
  ) {
    'ngInject';
    this.$state = $state;
    this.$modal = $modal;
    this.blockUI = blockUI;
    this.MobileCowSearchUIService = MobileCowSearchUIService;
    this.EventRegisterService = EventRegisterService;
  }

  onHomeClick() {
    this.$state.go('mobile-top');
  }

  onSearchCowClick() {
    const labels = {title: '牛番号検索', searchBtn: '検索', nextBtn: '詳細画面へ'};
    const openModal = this.MobileCowSearchUIService.openCowSearchModal.bind(this);

    this.openSearchCowModal(openModal, labels)
      .then((cowId) => {
        if (!cowId) return;
        this.MobileCowSearchUIService.goToCowDetail(cowId);
      });
  }

  onRegisterEventClick() {
    const labels = {title: '牛番号検索', searchBtn: '検索', nextBtn: 'イベント選択画面へ'};
    const openModal = this.MobileCowSearchUIService.openCowNoModal.bind(this);
    const caller = {
      state: 'mobile_cow_event_input'
    };

    this.openSearchCowModal(openModal, labels)
      .then((cowId) => {
        if (!cowId) return;
        this.blockUI.start('ロード中');
        this.EventRegisterService.displayEventSelection(cowId, caller)
          .then(() => {
            this.blockUI.stop();
          });
      });
  }

  openCowNoModal(title, searchButton, nextButton) {
    return this.MobileCowSearchUIService.openCowNoModal(title, searchButton)
      .then((result) => {
        if (!result.status.CANCEL) {
          return this.MobileCowSearchUIService.searchCow(result.searchType, result.searchKey, nextButton)
            .then((result) => {
              if (result.status.FOUND) return result.cowId;
              if (result.status.EMPTY) {
                return this.MobileCowSearchUIService.openNoSuchCowModal(result.searchType)
                  .then(() => this.openSearchCowModal(openModal, labels));
              }
            });
        }
      });
  }

  openSearchCowModal(openModal, labels) {
    return openModal(labels.title, labels.searchBtn)
      .then((result) => {
        if (!result.status.CANCEL) {
          return this.MobileCowSearchUIService.searchCow(
            result.searchType, result.searchKey, labels.nextBtn, result.activeOnly
          ).then((result) => {
            if (result.status.FOUND) return result.cowId;
            if (result.status.EMPTY) {
              return this.MobileCowSearchUIService.openNoSuchCowModal(result.searchType)
                .then(() => this.openSearchCowModal(openModal, labels));
            }
          });
        }
      });
  }
}
