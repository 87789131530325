class MobileConfirmModal {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  open(message) {
    return this.$modal.open({
      templateUrl: 'mobile/components/confirm-modal/confirm-modal.html',
      controller: 'MobileConfirmModalController as ctrl',
      resolve: {
        message: () => message
      }
    }).result;
  }
}
app.service('MobileConfirmModal', MobileConfirmModal);
