/**
 * um-form-medicinesコンポーネント
 *
 * 治療系イベントで薬品情報を登録するフォームUIを構築
 *
 * 使い方
 * <um-form>
 *   <um-form-medicines
 *     um-label="分"
 *     um-type="text"
 *     um-width="50"
 *     um-model="ctrl.selectedMinute"></um-form-input>
  * </um-form>
 *
 * パラメータ:
 * @param {string} um-label グループのラベル
 * @param {string} um-medicines 薬品情報
 * @param {string} um-event-type イベントの種類('masttitis', etc)
 * @param {string} um-on-update-medicines 選択された薬品が更新された際に実行されるコード
 * @param {string} um-on-update-medicine-params 選択された薬品のパラメータが更新された際に実行されるコード
 **/

app.component('umFormMedicines', {
  controller: 'UmFormMedicinesController',
  bindings: {
    'umMedicines': '=',
    'umDisabled': '<',
    'umEventType': '@',
    'umLabel': '@',
    'umOnUpdateMedicines': '&',
    'umOnUpdateMedicineParams': '&'
  },
  templateUrl: 'components/um-form/um-form-medicines-component.html'
});

class UmFormMedicinesController {
  constructor(MasterMedicineAPI, $timeout, EventRegistrationSelectionService) {
    'ngInject';
    this.MasterMedicineAPI = MasterMedicineAPI;
    this.treatmentMedicineMethods = Medicine.METHODS;
    this.$timeout = $timeout;
    this.EventRegistrationSelectionService = EventRegistrationSelectionService;

    // 薬品選択肢
    this.medicineOptions = [];
    this.medicineOptionsMaster = [];
    this.selectedIds = [];

    // 薬品マスター
    this.medicineInfos = [];
  }

  /**
  * binding完了後に初期化処理を行う
  */
  $onInit() {
    this.getMedicineInfos().then(() => {
      this.updateMedicineOptions();
    });

    if (this.umMedicines) {
      this.umMedicines.forEach((medicine) => {
        medicine.id = String(medicine.id);
      });
    }

    // 値がnull のmedicine.idがStringに変換されないよう、変換処理のあとで追加
    if (this.umMedicines.length === 0) {
      this.addMedicine();
    }
  }

  /**
   * 「薬品情報を追加」ボタンが押されたときの処理
   */
  onAddMedicineClick() {
    this.addMedicine();
    this.updateMedicineOptions();
  }

  /**
   * 空の薬品情報を追加
   */
  addMedicine() {
    this.umMedicines.push({
      capacity: null,
      unit: null,
      method: null,
      id: null,
    });
  }

  /**
   * 薬品情報を削除
   * @param {number} index 削除する薬品のindex
   */
  removeMedicine(index) {
    delete this.umMedicines.splice(index, 1);
    this.umOnUpdateMedicines();
    this.updateMedicineOptions();
    this.umOnUpdateMedicines();
  }

  /**
   * 薬品情報が選択されたときの処理
   * @param {number} index 削除する薬品のindex
   */
  onMedicineChange(medicine) {
    this.EventRegistrationSelectionService.refreshInfoMap().then(() => {
      this.$timeout(() => {
        this.addCapacityAndMethodToMedicine(medicine);
        this.umOnUpdateMedicines();
        this.updateMedicineOptions();

        medicine.method = medicine.defaultTreatmentMethod;
      }, 0);
    });
  }

  /**
   * 薬品容量が変更されたときの処理
   * @param {object} medicine
   */
  onCapacityChange(medicine) {
    this.validateCapacity(medicine);
    this.umOnUpdateMedicineParams && this.umOnUpdateMedicineParams();
    this.umOnUpdateMedicines();
  }

  /**
   * 投薬方法が変更されたときの処理
   * @param {object} medicine
   */
  onMethodChange(medicine) {
    this.umOnUpdateMedicines();
  }

  /**
   * 薬品一覧を取得する
   */
  getMedicineInfos() {
    return this.MasterMedicineAPI.available().then((res) => {
      this.medicineInfos = res.data.filter((medicineInfo) => medicineInfo[this.umEventType]);
    }).catch((error) => {
      console.error('Get medicines', error);
    });
  }

  /**
   * 薬品選択肢をアップデート
   */
  updateMedicineOptions() {
    // ng-optionsのためにidをStringに変換する
    // Ref: https://docs.angularjs.org/api/ng/directive/select#binding-select-to-a-non-string-value-via-ngmodel-parsing-formatting

    this.selectedIds = this.umMedicines
      .filter((medicine) => medicine && medicine.id)
      .map((medicine) => String(medicine.id));

    this.medicineOptions = [];

    this.umMedicines.forEach((medicine) => {
      const selectables = CollectionUtil.selectableMasters(this.medicineInfos, [Number(medicine.id)]);
      const options = selectables
        .filter((medicineInfo) => {
          if (!medicine) return true;
          if (medicineInfo.id === medicine.id) return true;
          return this.selectedIds.every((id) => medicineInfo.id !== id);
        })
        .map((medicineInfo) => {
          medicineInfo.id = String(medicineInfo.id);
          return medicineInfo;
        });

      this.medicineOptions.push(options);
    });
  }

  /**
   * 薬品追加ボタンを表示するかどうかのフラグ
   * @param {object} medicine
   */
  shouldShowAddMedicineButton() {
    return this.selectedIds.length < this.medicineInfos.length;
  }

  /**
   * 薬品追加ボタンを非アクティブにするかどうかのフラグ
   */
  shouldDisableAddMedicineButton() {
    return this.umDisabled ? true : false;
  }

  /**
   * 選択済み薬品を非表示にする
   */
  shouldHideMedicines() {
    return this.umDisabled ? true : false;
  }

  /**
   * 選択済の薬品に、前回選択時の「容量」「処置方法」を付加する。
   */
  addCapacityAndMethodToMedicine(medicine) {
    if (!medicine || !medicine.id) return;

    const match = this.medicineInfos.find((medicineInfo) => medicine.id === medicineInfo.id);
    if (match) {
      angular.merge(medicine, match);
    }
  }

  /*
    容量フィールドのフォーマットをチェックする
    NOTE: Angularバリデーションを使用すると状態の受け渡しが複雑になるため、コントローラに実装しなおしました
   */
  validateCapacity(medicine) {
    const REGEX = /^(\d+$|\d+\.\d)$/;
    if (!medicine.capacity || REGEX.test(medicine.capacity)) {
      medicine.errorMessage = '';
      return true;
    } else {
      medicine.errorMessage = '入力できるのは半角数値(小数の場合は小数点第1位まで)のみです';
      return false;
    }
  }
}

app.controller('UmFormMedicinesController', UmFormMedicinesController);
