class GroupTransferHistoryAdvancedSearchController {
  constructor(
    $modalInstance,
    params
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
    this.params = params;

    this.init();
  }

  init() {
    this.cowNoLabel = this.params.cowNoLabel;
    this.memberFarms = this.params.memberFarms;
    this.transferTypes = this.params.transferTypes;
    this.model = angular.copy(this.params.historyCondition);
  }

  onBlurCowConditionValue() {
    const value = this.model.cowCondition.value;

    if (this.model.cowCondition.type !== 'cow_uid' || !value) return;

    this.model.cowCondition.value = StringUtil.removeNonSingleByteNumericCharacters(value);
  }

  submit() {
    this.$modalInstance.close(this.model);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  showCowConditionMessage() {
    return this.model.cowCondition.type === 'cow_no';
  }
}

app.controller('GroupTransferHistoryAdvancedSearchController', GroupTransferHistoryAdvancedSearchController);
