// eslint-disable-next-line no-unused-vars
class Bred {

  static inseminationCodeOptions(hormonePrograms) {
    const options = [];
    options.push({name: '自然発情', value: '自然発情', label: '自然発情'});

    if (hormonePrograms && hormonePrograms.length > 0) {
      hormonePrograms.forEach((p) => {
        options.push({name: p.name, value: p.name, label: p.name});
      });
    } else {
      options.push({name: 'ホルモンプログラム', value: 'ホルモンプログラム', label: 'ホルモンプログラム'});
    }

    options.push({name: 'その他', value: 'その他', label: 'その他'});
    return options;
  }

}
