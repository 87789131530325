class MobileBulkActionSelectModal {
  constructor(
    modalDialogFactory
  ) {
    'ngInject';

    this.modalDialogFactory = modalDialogFactory;
  }

  open(cows, choices) {

    return this.modalDialogFactory.showMultipleChoicesConfirm({
      template: `<span>選択済みの牛個体は${cows.length}頭です。<br>どの操作を一括で行いますか？</span>`,
      choices: choices
    });
  }
}
app.service('MobileBulkActionSelectModal', MobileBulkActionSelectModal);
