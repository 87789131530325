// eslint-disable-next-line no-unused-vars
class SelectionBaseController {
  static bind(ctrl) {
    const base = new SelectionBaseController();
    angular.extend(ctrl, base);

    const functions = [
      'filter'
    ];
    functions.forEach((f) => {
      ctrl[f] = base[f].bind(ctrl);
    });
  }

  constructor() {
    this.filterCondition = '';
  }

  filter() {
    let records = this.rawRecords;

    if (this.filterCondition) {
      records = NormalizedString.filter(records, this.filterItemKeys, this.filterCondition);
    }

    this.records = records;
  }
}
