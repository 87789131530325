class CowEditController {
  constructor(
    $q,
    $state,
    $stateParams,
    CowGroupService,
    CowService,
    DateTimeUtilFactory,
    MasterHormoneProgramAPI,
    SelectionService,
    MasterSpermAPI,
    MasterMarketAPI,
    UserDefinedSelectionAPI,
    SessionCache
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.penListMap = {};
    this.penList = [];
    this.groupSelection = [];
    this.errors = {};

    this.canEditFilterItem = !SessionCache.account().isItemFilterApplied();

    this.CowGroupService = CowGroupService;
    this.CowService = CowService;
    this.DateTimeUtilFactory = DateTimeUtilFactory;
    this.MasterHormoneProgramAPI = MasterHormoneProgramAPI;
    this.MasterSpermAPI = MasterSpermAPI;
    this.MasterMarketAPI = MasterMarketAPI;
    this.UserDefinedSelectionAPI = UserDefinedSelectionAPI;
    this.SelectionService = SelectionService;

    this.farm = SessionCache.farm();
    this.farmKind = this.farm.data.farmKind;
    this.isMilk = this.farm.isMilk();
    this.isBeef = this.farm.isBeef();

    this.cowValidator = new CowValidator(this.farm);

    this.useCowNoInput = this.farm.useCowNo() || this.farm.useName();

    this.isEntering = true;

    this.init();
  }

  init() {
    this.$q.all([
      this.SelectionService.index(),
      this.MasterHormoneProgramAPI.index(),
      this.MasterSpermAPI.index(),
      this.MasterMarketAPI.index(),
      this.UserDefinedSelectionAPI.available(),
      this.CowService.get(this.$stateParams.cowId),
      this.CowGroupService.index()
    ]).then((res) => {
      const selection = res[0];
      this.selection = selection;

      this.hormonePrograms = res[1].data;
      this.sperms = res[2].data;
      this.markets = res[3].data;

      const userDefinedSelections = res[4].data;
      UserDefinedOption.definitions.forEach((listNameObj) => {
        this.selection[listNameObj.name] = getCustomSelectList(
          listNameObj.name,
          userDefinedSelections,
          listNameObj.allowBlank
        );
      });

      this.formData = this.DateTimeUtilFactory.parseClientDateForCowInfo(res[5].data);
      this.viewType = this.CowService.toggleViewType(this.formData.state, this.farmKind);

      const groupList = res[6].data || [];

      const selectableList = CollectionUtil.selectableMasters(groupList, [this.formData.cowGroupId], 'cowGroupId', 'menuDisplayed');
      selectableList.forEach((group) => {
        // 牛群ドロップダウン
        this.groupSelection.push({
          label: group.cowGroupName,
          value: group.cowGroupId
        });

        // 牛房ドロップダウン用マップ
        this.penListMap[group.cowGroupId] = [];
        if (typeof group.pens === 'string') {
          group.pens.split(',').forEach((val) => {
            this.penListMap[group.cowGroupId].push({label: val, value: val});
          });
        }
      });

      this.updatePenList();
      this.onChangeGender();
      // 確認画面から戻った際に元々の値がformDataにはなくなる為、APIで取得した牛個体情報を渡す。
      this.addUserEntryValueToSelection(this.selection, res[5].data);

      this.setMasters();

      this.existsRaisingFarmNames = this.selection.raisingFarmNames.length > 0;
      this.existsProducingAreas = this.selection.producingAreas.length > 0;
      this.existsProducingFarmNames = this.selection.producingFarmNames.length > 0;
    }).catch((err) => console.error(err));
  }

  onChangeGender() {
    this.showBreedingBull = this.formData.gender === 'オス';
    if (!this.showBreedingBull) {
      this.formData.breedingBull = false;
    }
  }

  updatePenList() {
    if (this.formData && this.formData.cowGroupId && Object.keys(this.penListMap).length) {
      this.penList = this.penListMap[this.formData.cowGroupId];
    }
  }

  setMasters() {
    this.setHormonePrograms();
    this.setSperms();
    this.setMarkets();
  }

  setHormonePrograms() {
    if (!this.formData) return;
    if (!this.hormonePrograms) return;

    const hormoneNameList = [{label: '', value: ''}];
    const selectableMasters = CollectionUtil.selectableMasters(
      this.hormonePrograms,
      [this.formData.hormoneProgramName],
      'name'
    );
    selectableMasters.forEach((m) => {
      hormoneNameList.push({label: m.name, value: m.name});
    });
    this.selection['hormoneNameList'] = hormoneNameList;
  }

  setSperms() {
    if (!this.formData) return;
    if (!this.sperms) return;

    this.selection['spermInfo'] = CollectionUtil.selectableMasters(
      this.sperms,
      [this.formData.latestMasterSpermNo],
      'spermNo'
    );
  }

  setMarkets() {
    if (!this.formData) return;
    if (!this.markets) return;

    const masterMarkets = [{label: '', value: 0}];
    const selectableMasters = CollectionUtil.selectableMasters(this.markets, [this.formData.masterMarketId]);
    selectableMasters.forEach((m) => {
      masterMarkets.push({label: m.name, value: m.id});
    });
    this.selection['masterMarkets'] = masterMarkets;
  }

  changeGroupId() {
    this.penList = [];
    if (this.formData.cowGroupId) {
      this.penList = this.penListMap[this.formData.cowGroupId];
    }
  }

  changeCowUid() {
    if (this.useCowNoInput) return;
    const cowUid = this.formData.cowUid;
    const length = this.farm.useRegulationNumber4() ? 4 : 5;
    this.formData.cowNo = this.CowService.generateCowNo(cowUid, length);
  }

  clearInput(targetName) {
    this.formData[targetName] = null;
  }

  confirm() {
    this.cow = CowConfirmation.toDisplayObj(this.formData, this.groupSelection, this.selection.masterMarkets);
    this.isEntering = false;
  }

  validateCowNo(cowNo) {
    const result = this.cowValidator.validateCowNo(cowNo);

    this.invalid = !result.valid;

    this.errors.cowNo = result;
  }

  isValidField(field) {
    if (!this.errors[field]) return true;
    return this.errors[field].valid;
  }

  addUserEntryValueToSelection(selection, cow) {
    [
      {selectionItem: 'raisingFarmNames', cowItem: 'raisingFarmName'},
      {selectionItem: 'producingAreas', cowItem: 'producingArea'},
      {selectionItem: 'producingFarmNames', cowItem: 'producingFarmName'}
    ].forEach((obj) => {
      if (!selection[obj.selectionItem].length) return;
      if (!cow[obj.cowItem]) return;

      const optionExisted = selection[obj.selectionItem].some((option) => option.value === cow[obj.cowItem]);
      if (optionExisted) return;

      const option = {label: cow[obj.cowItem], value: cow[obj.cowItem]};
      selection[obj.selectionItem].splice(1, 0, option);
    });
  }

  toEntering() {
    this.isEntering = true;
  }
}
app.controller('CowEditController', CowEditController);
