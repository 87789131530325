class CowBleIdAPI {
  constructor(APIService, $q, DateUtilService) {
    'ngInject';
    this.APIService = APIService;
    this.$q = $q;
  }

  cowBleIds(cowId) {
    return this.APIService.get(`/ble_id/cow/${cowId}`);
  }

  equip(params) {
    return this.APIService.post('/ble_id/cow', params);
  }

  unequip(cowId, endDate) {
    const params = {endDate: endDate};
    return this.APIService.put(`/ble_id/cow/${cowId}`, params);
  }

  delete(cowId, startDate) {
    const date = DateUtil.toW3CFormat(startDate);
    return this.APIService.delete(`/ble_id/cow/${cowId}/${date}`, null);
  }
}

app.service('CowBleIdAPI', CowBleIdAPI);
