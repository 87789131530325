class PerinatalListController {
  constructor($rootScope, $scope, $state, $stateParams, OrderedListService, UtilService, DateUtilService) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.title = $state.current.name === 'hospital-perinatal-list' ? 'E周産期リスト' : '周産期リスト';
    this.getPerinatalList = null;
    if ($state.current.name === 'hospital-perinatal-list') {
      this.getPerinatalList = OrderedListService.getHospitalPerinatalList;
    } else {
      this.getPerinatalList = OrderedListService.getOtherPerinatalList;
    }
    this.utilService = UtilService;
    this.DateUtilService = DateUtilService;
    // 印刷用の処理
    this.parameterForPrint = getPathParams();
    this.initialize();
  }

  // public method
  /**
   * 値を真偽値にして返す。
   *
   * @param {string} value
   * @return {boolean} true or false
   */
  toBoolean(value) {
    return this.utilService.toBoolean(value);
  }

  /**
   * 印刷モードの場合はtrueを返す。
   *
   * @return {boolean} true or false
   */
  printable() {
    return this.utilService.toBoolean(this.parameterForPrint.printable);
  }

  current() {
    return this.$state.current;
  }

  /**
   * 印刷ボタンクリック処理
   */
  print() {
    const params = params || [];
    params.push('mode=1'); // src/js/app.js で使用しているのでセットする
    params.push('printable=true');

    let context = location.href.split('#');
    let href = context[0] + '#/' + this.$state.current.name + '?' + params.join('&');
    let windowStyle = 'width=1100,height=600,menubar=no,toolbar=no,location=no,scrollbars=yes';
    window.open(href, this.$state.current.name, windowStyle);
  }

  /**
   * 最新治療日が昨日の場合、牛群の背景色を緑に変更する。
   *
   * @param {string} latestTreatmentDate 最新治療日時（ミリ秒）
   * @return {string}
   */
  changeBackgroundLatestTreatmentDate(latestTreatmentDate) {
    if (this.utilService.diffDays(new Date(Number(latestTreatmentDate)), new Date()) === 1) return 'latestTreatmentYesterday';
    return '';
  }

  /**
   * 注意牛かどうかによって背景色を変更する。
   *
   * @param {boolean} cautionCow 注意牛
   * @return {string}
   */
  changeBackgroundCautionCow(cautionCow) {
    if (this.utilService.toBoolean(cautionCow)) return 'cautionCow';
    return '';
  }

  /**
   * 産次が5以上の場合、背景色を赤に変更する。
   *
   * @param {Number} birthNumber 産次
   * @return {string}
   */
  changeBackgroundBirthNumber(birthNumber) {
    if (Number(birthNumber) >= 5) return 'birth-number-over-5';
    return '';
  }

  /**
   * 体重50以上 or 分娩時状態に"双" あり or 分娩時状態に "SS"ありかどうかによって背景色を変更する。
   *
   * @param {boolean} birthToTwins 双子フラグ
   * @return {string}
   */
  changeBackgroundBirthToTwins(birthToTwins) {
    if (this.utilService.toBoolean(birthToTwins)) return 'birthToTwins';
    return '';
  }

  /**
   * 分娩後日数が6日の場合、背景色を変更する。
   *
   * @param {Number} afterCalviedDays 分娩後日数
   * @return {string}
   */
  changeBackgroundAfterCalviedDays(afterCalviedDays) {
    if (Number(afterCalviedDays) === 6) return 'after-calvied-6days';
    return '';
  }

  /**
   * 「前期」「後期」で足して80以上の場合、または「前期」が一桁（＝10未満）の場合「前期」の背景色を赤に変更する。
   *
   * @param {Number} latestDryPreviousPeriodDaysCount 前期乾乳日数
   * @param {Number} latestDryLatePeriodDaysCount 後期乾乳日数
   * @return {string}
   */
  changeBackgroundDryPreviousDays(dryPreviousDays, dryLateDays) {
    dryPreviousDays = dryPreviousDays || 0;
    dryLateDays = dryLateDays || 0;
    let totalDryDays = Number(dryPreviousDays) + Number(dryLateDays);
    if (totalDryDays >= 80) return 'dry-days-over-80';
    if (dryPreviousDays < 10) return 'dry-previous-days-under-10';
    return '';
  }

  /**
   * 「前期」「後期」で足して80以上の場合、または「後期」が一桁（＝10未満）の場合「後期」の背景色を赤に変更する。
   *
   * @param {Number} latestDryPreviousPeriodDaysCount 前期乾乳日数
   * @param {Number} latestDryLatePeriodDaysCount 後期乾乳日数
   * @return {string}
   */
  changeBackgroundDryLateDays(dryPreviousDays, dryLateDays) {
    dryPreviousDays = dryPreviousDays || 0;
    dryLateDays = dryLateDays || 0;
    let totalDryDays = Number(dryPreviousDays) + Number(dryLateDays);
    if (totalDryDays >= 80) return 'dry-days-over-80';
    if (dryLateDays < 10) return 'dry-late-days-under-10';
    return '';
  }

  /**
   * 牛群IDがnullまたは前回の牛群IDと違う場合はクラス名を返す。
   *
   * @param {Object} cowGroupId 牛群ID
   * @param {boolean} first 最初の要素かどうか
   * @return {string} クラス名 or ''
   */
  addClassOfCowGroup(cowGroupId, first) {
    if (first) {
      this.prevCowGroupId = cowGroupId;
      return '';
    }

    if (this.prevCowGroupId !== cowGroupId) {
      this.prevCowGroupId = cowGroupId;
      return 'start-cow-group';
    }
    return '';
  }

  // private method

  initialize() {
    // グループ毎の罫線用
    this.prevCowGroupId = null;
    this.loading = true;
    if (this.parameterForPrint.printable) this.preparaPrint();
    this.$scope.$on('ngRepeatFinished', this.fixedHeader);

    this.getPerinatalList().then((result) => {
      if (result.data && result.data.length > 0) {
        this.cows = result.data;
        if (this.parameterForPrint.printable) this.executePrint();
      }
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      this.loading = false;
    });
  }

  /**
   * 固定ヘッダー作成
   */
  fixedHeader() {
    FixedMidashi.create();
  }

  /**
   * 印刷時の前処理
   */
  preparaPrint() {
    $('header').remove();
    $('#header-band').remove();
    $('body').addClass('print');
    $('#external-input').addClass('option-wrapper');
    $('.wrapper .content').css('margin', '0px');
    $('#print-link').remove();
    $('.content .title-box').css('position', 'absolute');
    $('.table-fix').parent().removeClass('fixed-tb');
  }

  /**
   * 印刷実行
   */
  executePrint() {
    const interval = setInterval(() => {
      executePrinter(this.parameterForPrint, interval, null);
    }, 100);
  }
}

app.controller('PerinatalListController', PerinatalListController);
