function HormoneProgramPreviousNameEmptyValidator() {
  'ngInject';
  return {
    require: 'ngModel',
    scope: {
      target: '=hormoneProgramPreviousNameEmptyValidator',
    },
    link: function(scope, element, attr, model) {
      model.$validators.hormoneProgramPreviousNameEmpty = function(modelValue) {
        if (!modelValue) return true;
        return (scope.target && String(scope.target).length) ? true : false;
      };
      scope.$watch('target', function() {
        model.$validate();
      });
    }
  };
}

app.directive('hormoneProgramPreviousNameEmptyValidator', HormoneProgramPreviousNameEmptyValidator);
