/**
 * モバイル用のイヤタグセンサー脱落アラートの処置選択コンポーネントです
 *
 * ex.
 *   <mobile-alert-eartag-sensor-dropped-work-selection
 *     on-close-click="onCloseClick()"
 *     on-solution-click="onSolutionClick(solution)"
 *   ></mobile-alert-eartag-sensor-dropped-work-selection>
 *
 * プロパティ:
 *  @param {Function} on-close-click Xクリック時に発生するイベント
 *  @param {Function} on-solution-click 処置内容クリック時に発生するイベント solutionを引数で渡す
 */
class MobileAlertEartagSensorDroppedSolutionSelectionController {
  constructor() {
    'ngInject';
  }

  handleCloseClick() {
    this.onCloseClick();
  }

  handleSolutionClick(solution) {
    this.onSolutionClick({solution: solution});
  }

  handleIgnoreSolutionClick(solution) {
    this.onIgnoreSolutionClick({solution: solution});
  }

  handleShipmentsSolutionClick() {
    this.onShipmentsSolutionClick();
  }
}

function mobileAlertEartagSensorDroppedSolutionSelectionComponents() {
  return {
    templateUrl: 'mobile/alert-list/eartag-sensor-dropped/solution-selection.html',
    controller: MobileAlertEartagSensorDroppedSolutionSelectionController,
    bindings: {
      onCloseClick: '&',
      onSolutionClick: '&',
      onIgnoreSolutionClick: '&',
      onShipmentsSolutionClick: '&'
    }
  };
}

app.component('mobileAlertEartagSensorDroppedSolutionSelection',
  mobileAlertEartagSensorDroppedSolutionSelectionComponents());
