class ReportSummaryBoxController {
  /**
   * 成績表のヘッダーで「年」を表示するか
   *
   * @param {Number} index
   * @param {String} yearMonth
   */
  shouldShowYear(index, yearMonth) {
    return (index === 0 || this.month(yearMonth) === '01');
  }

  /**
   * YYYYMMからYYYYを切り出して返す
   *
   * @param {String} yearMonth(YYYYMM)
   * @return {String} YYYY
   */
  year(yearMonth) {
    return yearMonth.slice(0, 4);
  }

  /**
   * YYYYMMからMMを切り出して返す
   *
   * @param {String} yearMonth(YYYYMM)
   * @return {String} MM
   */
  month(yearMonth) {
    return yearMonth.slice(-2);
  }

  /**
   * 値をロケールに合わせて整形した文字列を返す
   * [example]
   * formattedValue(12345.5) <- 12,345.5
   *
   * @param {number} value
   * @return {String} 整形した文字列
   */
  toLocaleString(value) {
    return parseFloat(value).toLocaleString();
  }
}

function reportSummaryBoxComponent() {
  return {
    templateUrl: 'report/summary/box/index.html',
    controller: ReportSummaryBoxController,
    bindings: {
      label: '@',
      unit: '@',
      summaries: '<'
    }
  };
}

app.component('reportSummaryBox', reportSummaryBoxComponent());
