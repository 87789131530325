class temporaryGroupViewCowDetailDataController {
  constructor(
    $state,
    $modal,
    $window,
    $timeout,
    $rootScope,
    SessionCache,
    StandardDialog,
    CowCalvingSensorHistoryAPI,
    CowCalvingSensorHistoryDialog
  ) {
    'ngInject';

    this.$state = $state;
    this.$modal = $modal;
    this.$window = $window;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.StandardDialog = StandardDialog;
    this.CowCalvingSensorHistoryAPI = CowCalvingSensorHistoryAPI;
    this.CowCalvingSensorHistoryDialog = CowCalvingSensorHistoryDialog;

    this.farm = SessionCache.farm();

    const account = SessionCache.account();

    this.init(account);
  }

  $onChanges() {
    if (!(Object.keys(this.cow).length && this.ble)) return;

    this.index();
  }

  init(account) {
    this.activeTab = 'dataTab';
    this.canEditFilterItem = !account.isItemFilterApplied();
    this.showClavingSensorNumber = this.farm.useAlertCalving();
    this.isMilk = this.farm.isMilk();
    this.isBeef = this.farm.isBeef();
    this.showFeed = !this.farm.isUMLight();
    this.useName = this.farm.useName();
  }

  index() {
    this.isInfant = this.cow.state === '哺育';
    this.isCalfType = ['育成', '哺育'].includes(this.cow.state);
    this.isReproductionType = ['フレッシュ', '未授精', '授精', '未受胎(－)', '受胎(＋)',
      '乾乳前期', '乾乳後期', '繁殖除外'
    ].includes(this.cow.state);
    this.isSoldOrDiedType = ['へい死', '出荷'].includes(this.cow.state);

    this.showHeat = this.isReproductionType || this.isCalfType;
    this.showReproduction = this.isReproductionType || this.isSoldOrDiedType;
    this.showDry = this.isMilk && this.isReproductionType;

    this.showCalvingSensorSetting = Cow.canEquipCalvingSensor(this.cow, this.ble.currentCowBleId) ||
      !!this.currentCowCalvingSensorNumber;

    this.showTimelineChart = false;
    this.$timeout(() => {
      this.showTimelineChart = true;
    });
    this.showTimeline = true;

    this.formatBirthdayMonthAge = this.cow.birthdayMonthAge ? `(${this.cow.birthdayMonthAge}ヶ月)` : '(-)';
    this.formatBirthdayDayAge = this.cow.birthdayDayAge ? `(${this.cow.birthdayDayAge}日)` : '(-)';

    this.updateCalvingSensorNumbers();
  }

  /**
   * 分娩センサー関連箇所を更新
   */
  updateCalvingSensorNumbers() {
    return this.CowCalvingSensorHistoryAPI.list(this.cow.cowId).then((res) => {
      this.currentCowCalvingSensorNumber = CowCalvingSensorHistory.currentCalvingSensorNumber(res.data);
      this.openCalvingSensorAlert();
    });
  }

  toBleIdHistory(cow) {
    this.$modal.open({
      animation: true,
      templateUrl: 'dialog/ble-id-dialog.html',
      controller: 'BleIdDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'modal-select-standard',
      resolve: {
        params: () => {
          return {
            cowId: cow.cowId,
            cowNo: cow.cowNo,
            canDelete: true
          };
        }
      }
    }).result.then(() => {
      this.updateSensorIds();
    });
  }

  openCowCalvingSensorHistoryDialog(cow) {
    this.CowCalvingSensorHistoryDialog.open(cow).result.then(() => {
      this.updateCalvingSensorNumbers();
    });
  }

  openCalvingSensorAlert() {
    if (this.ble.currentCowBleId || !this.currentCowCalvingSensorNumber) return;

    this.StandardDialog.showMessage({
      title: '警告',
      text1: '分娩兆候アラートをご利用いただくためには、活動量センサーと分娩センサーが装着されている必要があります。',
      text2: `分娩兆候アラートの算出を行いたい場合は活動量センサーを装着してください。
              算出を行わない場合は分娩センサーを解除してください。`
    });
  }

  updateCowDetails(eventType) {
    if (['heishi', 'touta'].includes(eventType)) {
      this.updateSensorIds();
    }
  }

  formatCowUid(cowUid) {
    return Cow.formatCowUid(cowUid);
  }

  formatDate(date) {
    return DateUtil.toJapaneseYYYYMMDD(Number(date));
  }

  diffDays(date, unit = '日') {
    if (!date) return '';
    return `${DateUtil.diffDays(Number(date), new Date().getTime())}${unit}`;
  }
}

function temporaryGroupViewCowDetailData() {
  return {
    restrict: 'E',
    templateUrl: 'temporary-group-view/cow-detail/data/index.html',
    controller: temporaryGroupViewCowDetailDataController,
    controllerAs: 'ctrl',
    scope: true,
    replace: true,
    bindToController: {
      cow: '<',
      ble: '<',
      callerId: '<',
      updateSensorIds: '&'
    }
  };
}

app.directive('temporaryGroupViewCowDetailData', temporaryGroupViewCowDetailData);
