class EventRegistrationSelectionService {
  constructor(
    $modal,
    $q,
    $rootScope,
    $timeout,
    FarmService,
    appConfig,
    appConst,
    blockUI,
    CarcassService,
    CowGroupService,
    MasterHormoneProgramAPI,
    IsMobile,
    MasterMedicineAPI,
    modalDialogFactory,
    SelectBoxFactory,
    SelectionService,
    SelectMedicineModal,
    DateUtilService,
    TimelineService,
    DoNotBreedAlert,
    EventSelectionSimpleConfirm,
    EventSelectionMultipleChoicesConfirm,
    UtilService,
    AlertService,
    MasterSpermAPI,
    CowService,
    CowEventsService,
    DateTimeUtilFactory,
    $controller,
    TimelineContext,
    DepositTimelineContext,
    CowAPI
  ) {
    'ngInject';

    this.CowEventsService = CowEventsService;
    this.$controller = $controller;
    this.CowService = CowService;
    this.DateTimeUtilFactory = DateTimeUtilFactory;
    this.$rootScope = $rootScope;
    this.CowAPI = CowAPI;
    this.blockUI = blockUI;

    const $scope = $rootScope.$new();

    this.cowTimelineController = $controller('CowTimelineController', {
      $modal,
      $q,
      $rootScope,
      $scope,
      $timeout,
      FarmService,
      appConfig,
      appConst,
      blockUI,
      CarcassService,
      CowEventsService,
      CowService,
      DateTimeUtilFactory,
      CowGroupService,
      MasterHormoneProgramAPI,
      IsMobile,
      MasterMedicineAPI,
      modalDialogFactory,
      SelectBoxFactory,
      SelectionService,
      SelectMedicineModal,
      DateUtilService,
      TimelineService,
      DoNotBreedAlert,
      EventSelectionSimpleConfirm,
      EventSelectionMultipleChoicesConfirm,
      UtilService,
      AlertService,
      MasterSpermAPI,
      TimelineContext,
      DepositTimelineContext
    });
  }

  /**
   * イベント登録画面を呼び出します
   */
  displayReactEventRegister(cowId, callerState, cow) {
    if (!cowId && !cow) return Promise.resolve();

    this.cowTimelineController.init();
    this.$rootScope.isOpenEventEntryToast = false;
    this.CowEventsService.selectedCowId = cowId;

    const cowPromise = cow
      ? Promise.resolve(cow)
      : this.CowAPI.show(cowId)
        .then((res) => {
          const rawCow = res.data;
          const parsed = this.DateTimeUtilFactory.parseClientDateForCowInfo(rawCow);
          return parsed;
        });

    this.blockUI.start('牛個体データのロード中');
    return cowPromise
      .then((cow) => {
        this.CowEventsService.selectedCow = cow;
        this.cowTimelineController.cow = cow;
        return this.cowTimelineController.initialize();
      })
      .then(() => {
        this.cowTimelineController.showDetailAfterPost = false;
        this.cowTimelineController.callerState = callerState;
        this.cowTimelineController.displayReactEventRegister((eventName) => {
          this.$rootScope.$emit('deprecatedMobileCowDetail:refreshEvents');
          this.$rootScope.isOpenEventEntryToast = true;
          this.$rootScope.eventNameEventEntryToast = eventName;
        });
        this.blockUI.stop();
      });
  }

  /**
   * イベント登録画面の旧実装を呼び出します。
   * @deprecated displayReactEventRegister に置き換えていきます。
   */
  displayEventSelection(cowId, callerState, cow) {
    if (!cowId && !cow) return new Promise((resolved, rejected) => {
      resolved();
    });

    this.cowTimelineController.init();
    this.blockUI.start('ロード中');
    this.$rootScope.isOpenEventEntryToast = false;
    this.CowEventsService.selectedCowId = cowId;

    const callback = (eventName) => {
      this.$rootScope.$emit('deprecatedMobileCowDetail:refreshEvents');
      this.$rootScope.isOpenEventEntryToast = true;
      this.$rootScope.eventNameEventEntryToast = eventName;
      if (eventName === '起立困難') {
        this.$rootScope.$broadcast('deprecatedMobileCowDetail:changeTab');
      }
    };

    if (cow) {
      this.CowEventsService.selectedCow = this.cowTimelineController.cow = cow;
      this.cowTimelineController.initialize();
      this.cowTimelineController.showDetailAfterPost = false;
      this.cowTimelineController.callerState = callerState;

      const timer = setInterval(() => {
        const initialized = this.cowTimelineController.initialized;

        if (initialized) {
          this.blockUI.stop();
          this.cowTimelineController.displayEventSelection(callback);
          clearInterval(timer);
        }
      }, 1000);

      return;
    }

    return this.CowAPI.show(cowId).then((res) => {
      this.CowEventsService.selectedCow =
      this.cowTimelineController.cow =
      this.DateTimeUtilFactory.parseClientDateForCowInfo(res.data);

      return this.cowTimelineController.initialize();
    }).then(() => {
      this.cowTimelineController.showDetailAfterPost = false;
      this.cowTimelineController.callerState = callerState;

      const timer = setInterval(() => {
        const initialized = this.cowTimelineController.initialized;

        if (initialized) {
          this.blockUI.stop();
          this.cowTimelineController.displayEventSelection(callback);
          clearInterval(timer);
        }
      }, 1000);
    });
  }

  refreshInfoMap() {
    return this.cowTimelineController.refreshInfoMap();
  }
}

app.service('EventRegistrationSelectionService', EventRegistrationSelectionService);
