// eslint-disable-next-line no-unused-vars
class ReproductionPerformance {
  static bredAnalysisFactors(isMilk) {
    return [
      {
        id: 'insemination_code',
        label: '授精コード'
      },
      {
        id: 'dim',
        label: isMilk ? '搾乳日数' : '分娩後日数'
      },
      {
        id: 'birth_number',
        label: '産次'
      },
      {
        id: 'bred_interval',
        label: '授精間隔'
      },
      {
        id: 'breeding_count',
        label: '授精回数'
      },
      {
        id: 'sperm',
        label: '精液'
      },
      {
        id: 'inseminator',
        label: '授精担当者'
      },
    ];
  }
}
