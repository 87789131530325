// eslint-disable-next-line no-unused-vars
class AlertHeatDetectionConfig {
  /**
   * 発情指数算出ロジック毎のラベル
   */
  static get HEAT_INDEX_CALC_LOGIC_LABELS() {
    return {
      SLICE3H: '(フリーストールなど)',
      TS1908: '(タイストール)'
    };
  }

  /**
   * 発情アラート検出の持続時間
   */
  static get HEAT_DURATIONS() {
    return [
      1, 2, 3, 4, 5
    ];
  }

  /**
   * 発情アラートのイベントの日数
   */
  static get HEAT_EVENT_CONDITION_DAYS() {
    return [
      0, 1, 2, 3, 4, 5
    ];
  }

  static isSlice3h(heatIndexCalcLogic) {
    return heatIndexCalcLogic === 'SLICE3H';
  }

  static createRequestParams(params) {
    const heatAlertLatestFertilizationInterval = parseInt(params.heatAlertLatestFertilizationInterval || 0);
    const heatAlertCowStates = params.heatAlertCowStates ? params.heatAlertCowStates.split(',') : [];
    const heatAlertCowGroupIds = params.heatAlertCowGroupIds ?
      params.heatAlertCowGroupIds.split(',').map((id) => parseInt(id)) : [];

    return {
      heatAlertLatestFertilizationInterval: heatAlertLatestFertilizationInterval,
      heatAlertCowStates: heatAlertCowStates,
      heatAlertCowGroupIds: heatAlertCowGroupIds,
      heatDetectionDetailsAttributes: params.heatDetectionDetailsAttributes,
      includeVwpHeifer: params.includeVwpHeifer,
      periodExclusionHeifer: params.periodExclusionHeifer,
      includeVwpParous: params.includeVwpParous,
      periodExclusionParous: params.periodExclusionParous,
      heatDetectionEventAttributes: params.heatDetectionEventAttributes
    };
  }

  static initializeHeatDetectionEvents(heatDetectionEvents) {
    return heatDetectionEvents.map((heatDetectionEvent) => {
      const event = Object.assign({}, heatDetectionEvent);
      event.eventName = EventType.eventName(event.eventType);
      return event;
    }).sort((a, b) => a.eventType > b.eventType ? 1 : -1);
  }
}
