/**
 * 牛個体登録/編集確認のフォームコンポーネント
 *
 * ex.
 *  <cow-confirmation-form
 *    can-edit-filter-item="canEditFilterItem"
 *    cow="cow"
 *    farm="farm"
 *    is-registered="isRegistered"
 *  ></cow-confirmation-form>
 *
 * プロパティ:
 *  @param {boolean} can-edit-filter-item true: 特定項目を表示、false：特定項目を非表示
 *  @param {Object} cow 牛個体オブジェクト
 *  @param {Object} farm 牧場オブジェクト
 *  @param {boolean} is-registered true: 登録済(編集)、false: 未登録(新規)
 */
class CowConfirmationFormController {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.isMilk = this.farm.isMilk();
    this.isBeef = this.farm.isBeef();
    this.useName = this.farm.useName();
  }

  formatCowUid(cowUid) {
    return Cow.formatCowUid(cowUid);
  }
}

function cowConfirmationFormComponent() {
  return {
    templateUrl: 'menu/cow/components/confirmation/form.html',
    controller: CowConfirmationFormController,
    bindings: {
      canEditFilterItem: '<',
      cow: '<',
      farm: '<',
      isRegistered: '<'
    }
  };
}

app.component('cowConfirmationForm', cowConfirmationFormComponent());
