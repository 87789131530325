class MasterDialogController {
  constructor(
    $scope,
    $modalInstance,
    params) {
    'ngInject';
    this.$scope = $scope;
    this.$modalInstance = $modalInstance;

    this.initialize(params);
  }

  initialize(params) {
    this.$scope.$on('ngRepeatFinished', () => {
      FixedMidashi.create();
    });

    this.title = params.title || '条件選択';
    this.caption = params.caption;
    this.needToSelect = params.needToSelect || false;
    const currentSelections = params.currentSelections;

    const idColumn = params.idColumn || 'id';
    const nameColumn = params.nameColumn || 'name';
    const masterId = params.masterId;

    params.indexAction().then((res) => {
      const masters = masterId === 'cow_group' ? CollectionUtil.selectableMasters(res.data, currentSelections, 'cowGroupId', 'menuDisplayed') : CollectionUtil.selectableMasters(res.data);
      this.masters = masters
        .map((m) => {
          const id = m[idColumn];
          const name = m[nameColumn];
          const selected = currentSelections.includes(id);
          return {
            key: id,
            label: name,
            selected: selected
          };
        });

      this.validate();
    });
  }

  validate() {
    this.invalid = false;

    if (!this.needToSelect) return;

    if (this.masters.some((m) => m.selected)) {
      return;
    }

    this.invalid = true;
  }

  clickRow(master) {
    master.selected = !master.selected;
    this.validate();
  }

  ok() {
    const result = this.masters
      .filter((m) => m.selected)
      .map((m) => {
        return {id: m.key, name: m.label};
      });
    this.$modalInstance.close(result);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('MasterDialogController', MasterDialogController);
