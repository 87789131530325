// eslint-disable-next-line no-unused-vars
class EventSelectionMasterContainer {
  constructor(event) {
    // public
    this.farmKind = null; // 牧場種類
    this.selection = {}; // その他選択肢
    this.event = event;

    if (!event || CowEvent.isMoveEvent(event.eventType)) {
      this.cowGroupNameMap = {}; // 牛群名
      this.cowGroupList = []; // groupList --> cowGroupList
      this.cowPenMap = {}; // penListMap --> cowPenMap

      if (event) {
        this.medicineLabels = [];
      }
    }

    if (!event || event.eventType === 'bunben') {
      this.calvingDifficultyMap = {}; //分娩難易度
    }

    if (!event || event.eventType === 'tanetsuke') {
      this.tanetsukeBulls = []; // 種牛
      this.spermInfoMap = {}; // 精液情報
    }

    if (!event || event.treatmentDiseaseDate) {
      this.medicineInfoMap = {}; // 薬品名
    }

    if (!event || event.eventType === 'hormoneprogram') {
      this.hormoneProgramNameList = CowEvent.hormoneProgramNameListDefault;
      this.hormoneListMap = {};
    }
  }
}
