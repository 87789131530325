/**
 * パフォーマンスを考慮してクローンせずにキャッシュオブジェクトをそのまま返します。
 * 参照先で更新しないでください。
 */
class SessionCache {
  constructor() {
    this.cache = {};
  }

  store(id, value) {
    this.cache[id] = value;
  }

  homeSettings() {
    return this.cache.homeSettings;
  }

  farm() {
    return this.cache.farm;
  }

  account() {
    return this.cache.account;
  }

  umPlatformPartner() {
    return this.cache.umPlatformPartner;
  }

  customlistSystemConfigs() {
    return this.cache.customlistSystemConfigs;
  }

  cowSearchOption() {
    return this.cache.cowSearchOption;
  }

  cowDetailState() {
    return this.cache.cowDetailState;
  }

  sharedOperatorName() {
    return this.cache.sharedOperatorName;
  }
}

app.service('SessionCache', SessionCache);
