app.directive('generateAlertHistoryColumns', generateAlertHistoryColumns);

function generateAlertHistoryColumns($compile, $timeout, DateUtilService, Dictionary) {
  'ngInject';

  const dictionary = Dictionary;

  /**
   * colを生成する
   * @param {string} カラム幅
   * @return {Node} <col>
   */
  function generateCol(colw) {
    const el = document.createElement('col');
    el.className = colw;
    return el;
  }

  /**
   * テーブルヘッダーの上段の中身を生成する
   * @param {string} eventType mastitis, など
   * @return {Node} <tr><th>...</tr>
   */
  function generateTHeadRow(ctrl) {
    const alertElements = Alert.ALERT_COLUMNS[ctrl.alertType]
      .map((column) => {
        const el = document.createElement('th');
        el.innerHTML = Alert.alertColumnLabals(dictionary)[column];
        el.setAttribute('scope', 'col');
        if (column === 'alertLabels') {
          el.setAttribute('colspan', ctrl.showAcuteIllness ? 3 : 2);
          return el;
        }
        el.setAttribute('rowspan', 2);
        return el;
      });
    const tr = document.createElement('tr');
    alertElements.forEach((element) => tr.appendChild(element));
    return tr;
  }

  /**
   * テーブルヘッダーの下段の中身を生成する
   * @param {Boolean} showAcuteIllness true: 疾病アラート改良版を表示
   * @return {Node} <tr><th>...</tr>
   */
  function generateTHeadSubRow(showAcuteIllness) {
    const subtitles = ['疾病', 'その他'];
    const subtitleElements = subtitles.map((subtitle, index)=>{
      const el = document.createElement('th');
      if (showAcuteIllness && index === 0) {
        el.setAttribute('colspan', 2);
      }
      el.innerHTML = subtitle;
      el.setAttribute('scope', 'col');
      return el;
    });
    const tr = document.createElement('tr');
    subtitleElements.forEach((element) => tr.appendChild(element));
    return tr;
  }

  /**
   * テーブルボディの中身を生成する
   * @param {string} eventType mastitis, など
   * @param {Boolean} showAcuteIllness true: 疾病アラート改良版を表示
   * @return {Node} <tr ng-repeat="..."><td>...</tr>
   */
  function generateTBodyRow(alertType, alerts, showAcuteIllness) {
    return alerts.map((alert) => {
      const tds = Alert.ALERT_COLUMNS[alertType]
        .map((column) => {
          const td = document.createElement('td');

          switch (column) {
          case 'alertDate':
            td.innerHTML = DateUtilService.toYYYYMMDD(alert.alertDate);
            return td;
          case 'alertDateTime':
            td.innerHTML = DateUtilService.toTimestampFormat(alert.alertDateTime);
            return td;
          case 'alertPeakTime':
            td.innerHTML = DateUtilService.toHHmm(alert.alertPeakTime);
            return td;
          case 'alertLabels':
            const alertLabels = ['', '', ''];
            alert.alertLabels.forEach((label) => {
              if (label === '急性(改)') {
                alertLabels[0] += `<span ng-click="ctrl.onAlertLabelClick('${alert.cowId}', '${label}')" class="cow-labels acute-illness">${label}</span>`;
              } else if (label === '急性' || label === '慢性') {
                alertLabels[1] += `<span ng-click="ctrl.onAlertLabelClick('${alert.cowId}', '${label}')" class="cow-labels ${label === '急性' ? 'illness' : 'chronic-illness'}">${label}</span>`;
              } else {
                alertLabels[2] += `<span ng-click="ctrl.onAlertLabelClick('${alert.cowId}', '${label}')" class="cow-labels">${label}</span>`;
              }
            });
            if (!showAcuteIllness) alertLabels.shift();
            return alertLabels.map((el, index) => {
              const tdEl = document.createElement('td');
              tdEl.innerHTML = el;
              if (showAcuteIllness) {
                const classNames = ['labels-td labels-td-acute-illness', 'labels-td labels-td-illness', 'labels-td'];
                tdEl.className = classNames[index];
              } else {
                tdEl.className = `${index === 0 ? 'labels-td labels-td-illness' : 'labels-td'}`;
              }
              return tdEl;
            });
          case 'cowLabels':
            const cowLabels = alert.cowLabels.map((label) => {
              return `<span class="cow-labels kindBackgroundColor">${label}</span>`;
            }).join('');
            td.innerHTML = cowLabels;
            return td;
          case 'dysstasiaAlertAt':
            td.innerHTML = dateUtilService.toTimestampFormat(alert.dysstasiaAlertAt);
            return td;
          case 'cowNo':
            if (alert.cowId) {
              td.innerHTML = `<span></span><a ng-click="ctrl.goToDetails(${alert.cowId})">${alert.cowNo}</a>`;
              return td;
            }

            td.innerHTML = '-';
            return td;
          case 'dysstasiaConfirmedStatus': {
            td.innerHTML = AlertDysstasia.confirmedStatusCaption(alert.dysstasiaConfirmedStatus);
            return td;
          }
          case 'dysstasiaComment': {
            td.innerHTML = alert.dysstasiaComment;
            return td;
          }
          case 'remarks': {
            if (alert.unexpectedDysstasia) {
              const caption = '<span class="margin-right-10"><b>※アラートなし</b></span>';
              const button = `
                <button ng-click="ctrl.onClickEditUnexpectedDysstasia(${alert.dysstasiaId})" class="margin-right-10">
                  <i class="icon-s-edit"></i>
                </button>
                <button ng-click="ctrl.onClickDeleteUnexpectedDysstasia('${alert.cowNo}', ${alert.dysstasiaId})">
                  <i class="icon-s-delete"></i>
                </button>`;
              td.innerHTML = `${caption} ${button}`;
            } else {
              td.innerHTML = '';
            }
            return td;
          }
          case 'monthAge':
            const monthAge = DateUtil.monthAge(alert.birthday, alert.alertDate, 1);
            td.innerHTML = monthAge || null;
            return td;
          case 'cowUid':
            td.innerHTML = Cow.formatCowUid(alert.cowUid);
            return td;
          case 'calvingNotes':
          case 'calvingSensorDroppedNotes':
            const notes = alert[column].map((note) => `<p class="text-left">${note}</p>`);
            td.innerHTML = notes.join('');
            return td;
          case 'detectedAt':
            td.innerHTML = DateUtilService.toYYYYMMDDHHmm(alert.detectedAt);
            return td;
          case 'deactivatedDate':
            td.innerHTML = DateUtilService.toYYYYMMDD(alert.deactivatedDate);
            return td;
          case 'detectedDate':
            td.innerHTML = DateUtilService.toYYYYMMDD(alert.detectedDate);
            return td;
          case 'anomalyTypes':
            const result = alert.anomalyTypes.split(',').map(element => {
              return `<span class="anomaly-types">${element}</span>`;
            }).join('<br/>');
            td.innerHTML = result;
            return td;
          default:
            td.innerHTML = alert[column];
            return td;
          }
        });
      const tr = document.createElement('tr');
      tds.forEach((td) => (Array.isArray(td)) ? td.forEach((tdEl) => tr.appendChild(tdEl)) : tr.appendChild(td));
      return tr;
    });
  }

  return {
    restrict: 'A',
    scope: '=',
    link: (s, el, attr) => {
      attr.$observe('generateAlertHistoryColumns', () => {
        $timeout(() => {
          if (el[0].tagName === 'COLGROUP') {
            const colHash = {
              bad_condition: [
                'col-w80',
                'col-w60',
                'col-w130',
                'col-w80',
                'col-w50',
                'col-w100',
                'col-w150',
                'col-w150',
                'col-w50'
              ],
              heat: [
                'col-w80',
                'col-w100',
                'col-w50',
                'col-w80',
                'col-w150',
                'col-w150',
                'col-w50'
              ],
              false_heat: [
                'col-w80',
                'col-w100',
                'col-w50',
                'col-w80',
                'col-w150',
                'col-w150',
                'col-w50'
              ],
              dysstasia: [
                'col-w80',
                'col-w50',
                'col-w80',
                'col-w50',
                'col-w80',
                'col-w50',
                'col-w80',
                'col-w80',
                'col-w50',
                'col-w50',
                'col-w150',
                'col-w150'
              ],
              calving: [
                'col-w80',
                'col-w80',
                'col-w50',
                'col-w80',
                'col-w150'
              ],
              calving_sensor_dropped: [
                'col-w80',
                'col-w80',
                'col-w50',
                'col-w80',
                'col-w150'
              ],
              sensor: [
                'col-w90',
                'col-w110',
                'col-w80',
                'col-w80',
                'col-w80',
                'col-w110',
                'col-w80',
              ],
              eartag_sensor_dropped: [
                'col-w60',
                'col-w60',
                'col-w60',
                'col-w60',
                'col-w60',
                'col-w60',
                'col-w50',
                'col-w50',
                'col-w130'
              ],
              eartag_sensor: [
                'col-w50',
                'col-w60',
                'col-w60',
                'col-w60',
                'col-w50',
                'col-w60',
                'col-w70',
                'col-w130'
              ]
            };

            if (s.ctrl.showAcuteIllness) colHash['bad_condition'].splice(1, 0, 'col-w70');

            const colws = colHash[s.ctrl.alertType];
            el.html('');
            colws.map((colw)=>{
              const col = $compile(generateCol(colw))(s);
              el.append(col);
            });
            return;
          }

          if (el[0].tagName === 'THEAD') {
            // HTML生成
            const thead = $compile(generateTHeadRow(s.ctrl))(s);
            el.html('');
            el.append(thead);
            if (s.ctrl.alertType === 'bad_condition') {
              const theadSub = $compile(generateTHeadSubRow(s.ctrl.showAcuteIllness))(s);
              el.append(theadSub);
            }
            return;
          }

          if (el[0].tagName === 'TBODY') {
            const alerts = s.ctrl.alerts.slice(0, s.ctrl.DISPLAY_LIMIT);
            const tbody = $compile(generateTBodyRow(s.ctrl.alertType, alerts, s.ctrl.showAcuteIllness))(s);
            el.html('');
            el.append(tbody);
            return;
          }
        });
      });
    },
  };
}
