class CowCalvingSensorHistoryDialog {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  open(cow) {
    return this.$modal.open({
      animation: true,
      templateUrl: 'components/dialog/cow-calving-sensor-history/index.html',
      controller: 'CowCalvingSensorHistoryDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'modal-select-standard',
      resolve: {
        params: () => {
          return {
            cowId: cow.cowId,
            cowNo: cow.cowNo
          };
        }
      }
    });
  }
}

app.service('CowCalvingSensorHistoryDialog', CowCalvingSensorHistoryDialog);
