// eslint-disable-next-line no-unused-vars
class Cow {

  static get DATE_COLUMNS() {
    return [
      'artificialInseminationDate',
      'birthday',
      'breedingExcludeDate',
      'castratedDate',
      'dehornedDate',
      'eliminateDate',
      'endDateOfBeefWashoutPeriod',
      'endDateOfMilkWashoutPeriod',
      'enterFarmDate',
      'exitFarmDate',
      'fallingDeadDate',
      'fertilizationDateOnPurchase',
      'firstFertilizationDate',
      'firstTreatmentDate',
      'introduceDate',
      'latestBcsDate',
      'latestCalvingDate',
      'latestDryPreviousPeriodStartDate',
      'latestDryLatePeriodStartDate',
      'latestEstrusDate',
      'latestFertilizationDate',
      'latestHoofTrimDate',
      'latestHormoneDate',
      'latestJudgePregnantDate',
      'latestPregnancyDate',
      'latestTreatmentDate',
      'nextHormoneDate',
      'occurredMastitisDateOfBreastFl',
      'occurredMastitisDateOfBreastFr',
      'occurredMastitisDateOfBreastBl',
      'occurredMastitisDateOfBreastBr',
      'startHormoneProgramDate',
      'startFatteningDate',
      'stopMilkingDateOfBreastFl',
      'stopMilkingDateOfBreastFr',
      'stopMilkingDateOfBreastBl',
      'stopMilkingDateOfBreastBr',
      'treatmentMastitisDateOfBreastFl',
      'treatmentMastitisDateOfBreastFr',
      'treatmentMastitisDateOfBreastBl',
      'treatmentMastitisDateOfBreastBr',
      'vaccinationDate',
      'weanedDate',
    ];
  }

  /**
   * 空胎日数を返します。
   * @param {Date|number} calvingDate 前回の分娩日
   * @param {Date|number} pregnancyDate 妊娠日
   * @return {number} 分娩日を含まない対象期間の日数
   *
   * [空胎日数の計算式]
   * 受胎：妊娠日 - 前回の分娩日
   * 未次胎：当日 - 前回の分娩日
   * 未経産：0を返す
   */
  static daysOpen(calvingDate, pregnancyDate) {
    if (!calvingDate) return 0;

    const date = pregnancyDate || DateUtil.today();
    return DateUtil.diffDays(calvingDate, date);
  }

  /**
   * DGを計算する
   * @param {Object} cow 牛個体データ
   * @param {Number} breedingDays 飼育日数
   * @param {Number} weight 体重
   * @returns {Number} DG値。パラメータ・エラーの場合はnullを返す
   *
   * DG計算のロジック:
   *   導入日で飼育日数を算出した場合でかつ、 体重が入力されている場合 → 導入時体重と入力体重よりDGを計算する
   *   出生日で飼育日数を算出した場合でかつ、 体重が入力されている場合 → 出生時体重入力体重よりDGを計算する
   */
  static calculateDg(cow, breedingDays, weight) {
    if (!cow || !weight || !breedingDays) {
      return null;
    }

    if (!cow.weightOnPurchase && !cow.weightOnBirth) {
      return 'calculateDgError';
    }

    if (cow.introduceDate && cow.weightOnPurchase) {
      return parseFloat(((weight - Number(cow.weightOnPurchase)) / breedingDays).toFixed(2));
    } else if (cow.birthday && cow.weightOnBirth) {
      return parseFloat(((weight - Number(cow.weightOnBirth)) / breedingDays).toFixed(2));
    }

    return null;
  }

  /**
   * 飼育日数計算のロジック:
   *   導入日が存在する場合 → 導入日～イベント登録日付で飼育日数を計算する
   *   導入日が存在しないで、出生日が存在する場合 → 出生日～イベント登録日付で飼育日数を計算する
   * @param {Object} cow 牛個体データ
   * @param {Number} occuredAt 計算を行う対象の日付
   * @returns {Number} DG値。パラメータ・エラーの場合はnullを返す
   */
  static calculateBreedingDays(cow, occurredAt) {
    if (!occurredAt) {
      return null;
    }

    if (cow.introduceDate) {
      // 導入日が存在する場合
      return passedByStartAndEnd(cow.introduceDate, occurredAt);
    } else if (cow.birthday) {
      // 出生日が存在する場合
      return passedByStartAndEnd(cow.birthday, occurredAt);
    } else {
      return null;
    }
  }

  /**
   * 妊娠牛か否かを判定する
   * @param {Object} cow 牛個体データ
   * @returns {Boolean} 妊娠牛の場合はtrueを返す
   */
  static isPregnant(cow) {
    if (cow.state === '受胎(＋)' || cow.state === '乾乳前期' || cow.state === '乾乳後期') return true;
    return false;
  }

  /**
   * 牛個体識別番号を耳標で使用される「00000-0000-0」の形式に変換します。
   *
   * @param {string} cowUid 牛個体識別番号
   * @return {string} *****-****-* or 元の値
   */
  static formatCowUid(cowUid) {
    if (!cowUid) return '';

    cowUid = String(cowUid);

    if (cowUid.length !== 10) return cowUid;

    const first = cowUid.slice(0, 5);
    const second = cowUid.slice(5, 9);
    const last = cowUid.slice(9, 10);

    return first + '-' + second + '-' + last;
  }

  /**
   * 「12345-6789-0」形式の牛個体識別番号を内部操作用の「1234567890」形式に変換します。
   * 「12345-6789-0」形式以外の文字列が渡された場合は引数をそのまま返します。
   *
   * @param {string} cowUid 「12345-6789-0」形式の牛個体識別番号
   * @return {string} 数字10桁の文字列 or 引数
   */
  static unformatCowUid(cowUid) {
    const cowUidPattern = /^[0-9]{5}-[0-9]{4}-[0-9]$/;
    if (cowUidPattern.test(cowUid)) {
      return cowUid.replace(/-/g, '');
    } else {
      return cowUid;
    }
  }

  /**
   * 日付表示用のオブジェクトを生成
   *
   * @param {object} cow 牛個体
   * @param {object} option DateUtilクラスのformatDateメソッドに渡す引数
   * @return {object}
   */
  static generateDisplayDates(cow, option) {
    const dateUtilService = new DateUtilService();
    const displayDates = {};
    this.DATE_COLUMNS.forEach((column) => {
      const date = cow[column];
      if (date) {
        const columnName = `${column}Display`;
        displayDates[columnName] = dateUtilService.formatDate(Number(date), option);
      }
    });

    return displayDates;
  }

  /**
   * 牛個体に個体ラベルを追加する破壊的操作です。
   *
   * @param {Array.<Object>} cows {cowLabels: ['OK', '再検診']} のデータ形式の個体情報の配列
   * @param {Array.<string>} labels 追加するラベルの配列
   */
  static addLabels(cows, labels) {
    cows.forEach((cow) => {
      labels.forEach((label) => {
        if (!cow.cowLabels.includes(label)) {
          cow.cowLabels.push(label);
        }
      });
    });
  }

  /**
   * 牛個体から指定したラベルを除去する破壊的操作です。
   *
   * @param {Array.<Object>} cows {cowLabels: ['OK', '再検診']} のデータ形式の個体情報の配列
   * @param {Array.<string>} labels 除去するラベルの配列
   */
  static removeLabels(cows, labels) {
    cows.forEach((cow) => {
      cow.cowLabels = cow.cowLabels.filter((label) => {
        return !labels.includes(label);
      });
    });
  }

  /**
   * 文字列の個体ラベル群を配列に変換
   *
   * @param {String} labelString e.g. '[種雄牛],[注意牛]'
   * @return {Array} 個体ラベルの配列 e.g. ['種雄牛', '注意牛']
   */
  static labelStringToArray(labelString) {
    if (!labelString) return [];
    return labelString.replace(/\[|\]/g, '').split(',');
  }

  /**
   * 分娩イベントで作成された子牛かどうかを判定する
   * @param {Object} cow 牛個体情報
   * @return {Boolean} 分娩イベントで作成された子牛ならtrue
   */
  static isUMCreatedCow(cow) {
    return cow && cow.motherCowId ? true : false;
  }

  /**
   * 牛個体情報の表示たために日付ラベルを追加する
   * @param {Object} cow 牛個体情報
   */
  static decorateCowWithDateLabel(cow, noneString = '-') {
    function dateToString(date) {
      const dateString = !date ? noneString : DateUtil.toYYMMDD(parseInt(date));
      return dateString;
    }

    return Object.assign({}, {
      breedingExcludeDateLabel: dateToString(cow.breedingExcludeDate),
      eliminateDateLabel: dateToString(cow.eliminateDate),
      embryoRecoveryDateLabel: dateToString(cow.embryoRecoveryDate),
      endDateOfBeefWashoutPeriodLabel: dateToString(cow.endDateOfBeefWashoutPeriod),
      endDateOfMilkWashoutPeriodLabel: dateToString(cow.endDateOfMilkWashoutPeriod),
      expectedCalvingDateLabel: dateToString(cow.expectedCalvingDate),
      fallingDeadDateLabel: dateToString(cow.fallingDeadDate),
      firstFertilizationDateLabel: dateToString(cow.firstFertilizationDate),
      introduceDateLabel: dateToString(cow.introduceDate),
      latestCalvingDateLabel: dateToString(cow.latestCalvingDate),
      latestDryLatePeriodStartDateLabel: dateToString(cow.latestDryLatePeriodStartDate),
      latestDryPreviousPeriodStartDateLabel: dateToString(cow.latestDryPreviousPeriodStartDate),
      latestFertilizationDateLabel: dateToString(cow.latestFertilizationDate),
      latestHormoneDateLabel: dateToString(cow.latestHormoneDate),
      latestJudgePregnantDateLabel: dateToString(cow.latestJudgePregnantDate),
      latestPregnancyDateLabel: dateToString(cow.latestPregnancyDate),
      latestTreatmentDateLabel: dateToString(cow.latestTreatmentDate),
      startFatteningDateLabel: dateToString(cow.startFatteningDate),
      stopMilkingDateOfBreastBlLabel: dateToString(cow.stopMilkingDateOfBreastBl),
      stopMilkingDateOfBreastBrLabel: dateToString(cow.stopMilkingDateOfBreastBr),
      stopMilkingDateOfBreastFlLabel: dateToString(cow.stopMilkingDateOfBreastFl),
      stopMilkingDateOfBreastFrLabel: dateToString(cow.stopMilkingDateOfBreastFr),
      vaccinationDateLabel: dateToString(cow.vaccinationDate),
    }, cow);
  }

  /**
   * 分娩センサーを装着できる牛の場合はtrueを返す
   *
   * @param {Object} cow 牛個体情報
   * @param {string} currentCowBleId 装着中の活動量センサー番号
   * @return {boolean}
   */
  static canEquipCalvingSensor(cow, currentCowBleId) {
    return cow.active && !!cow.cowNo && !!currentCowBleId;
  }

  /**
   * 子牛期間（出生0日～ 90日終日まで）か否かを判定する
   * @param {Number} birthday 出生日
   * @param {Number} endDate 終了日
   * @returns {Boolean} 子牛期間の場合はtrueを返す
   */
  static isCalfPeriod(birthday, endDate = DateUtil.today()) {
    if (!birthday) return false;
    endDate = DateUtil.startOfDay(endDate);
    return DateUtil.diffDays(Number(birthday), endDate) <= 90;
  }
}
