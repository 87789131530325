class HomeController {
  constructor(
    $state,
    $modal,
    $timeout,
    AccountAPI,
    SessionCache
  ) {
    'ngInject';

    const farm = SessionCache.farm();

    this.$state = $state;
    this.$modal = $modal;
    this.$timeout = $timeout;
    this.AccountAPI = AccountAPI;
    this.SessionCache = SessionCache;
    this.homeSettings = new HomeSettings(SessionCache);
    this.homeSettings.init();
    this.uiVersion = SessionCache.account().uiVersion();

    this.init(farm);
  }

  init(farm) {
    if (farm.isDepositor()) {
      this.$state.go('depositHome');
    }

    const uiVersionHome = this.uiVersion.split('_')[0];

    if (!['h-2', 'h-1'].includes(uiVersionHome)) {
      const uiVersion = this.convertUiVersionString({
        h: '2', // home v2
        c: '1.5' // cowDetail v1.5
      });

      this.changeUiVersion(uiVersion);
    } else if (uiVersionHome === 'h-1') {
      this.$state.go('top');
    }

    this.farmName = farm.data.farmName;
    this.useAlertCalving = farm.data.useAlertCalving;
    this.useTemporaryGroupView = farm.useTemporaryGroupView();
    this.isDairy = farm.isDairy();
    this.isBreeding = farm.isBreeding();
    this.isFattening = farm.isFattening();

    this.loading = {
      schedule: {
        calvingSensor: this.useAlertCalving,
        reproductionPlannedEvents: true
      },
      temporaryGroupView: this.useTemporaryGroupView,
      dailyReport: true,
      coolPescon: true,
      fatteningCount: this.isFattening
    };

    this.showMasonry = false;

    this.masonryMainBoard = new Masonry(document.querySelector('#masonry__mainBoard'), {
      initLayout: false,
      itemSelector: '.masonry__item'
    });
  }

  changeUiVersion(uiVersion) {
    return this.AccountAPI.updateUiVersion(uiVersion).then(() => {
      const values = Object.assign({}, this.SessionCache.account().values, {uiVersion});
      const account = new Account(values);

      this.SessionCache.store('account', account);
      this.uiVersion = this.SessionCache.account().uiVersion();
    });
  }

  onClickChangeUiVersion() {
    const uiVersion = this.convertUiVersionString({
      h: '1',
      c: this.uiVersion.split('_')[1].split('-')[1]
    });

    this.changeUiVersion(uiVersion).then(() => {
      this.$state.go('top');
    });
  }

  convertUiVersionString(uiVersion) {
    return Object.entries(uiVersion).map(([key, value]) => {
      return `${key}-${value}`;
    }).join('_');
  }

  enableMasonry() {
    if (typeof this.hasCoolPescon === 'undefined' || this.isLoading()) return;

    if (!this.showMasonry) {
      if (!this.showSchedule()) {
        this.masonryMainBoard.remove(document.querySelector('.masonry__schedule'));
      }

      if (!this.showTemporaryGroupView()) {
        this.masonryMainBoard.remove(document.querySelector('.masonry__temporaryGroupView'));
      }

      if (!this.showCoolPescon()) {
        this.masonryMainBoard.remove(document.querySelector('.masonry__coolPescon'));
      }

      if (!this.showFatteningCount()) {
        this.masonryMainBoard.remove(document.querySelector('.masonry__fatteningCount'));
      }
    }

    this.$timeout(() => {
      this.masonryMainBoard.layout();
      this.showMasonry = true;
    }, 500);
  }

  isLoading() {
    return Object.values(this.loading).map((item) => {
      if (typeof item === 'object') {
        item = Object.values(item).some((boolean) => {
          return boolean;
        });
      }

      return item;
    }, []).some((boolean, a, b) => {
      return boolean;
    });
  }

  showSchedule() {
    return this.isDairy || this.isBreeding;
  }

  showTemporaryGroupView() {
    return this.useTemporaryGroupView;
  }

  showCoolPescon() {
    return this.hasCoolPescon;
  }

  showFatteningCount() {
    return this.isFattening;
  }

  classNameMainBoard() {
    return this.homeSettings.get('headage').visible ? 'uMainBoard' : 'uMainBoard--rightBoardClosed';
  }

  styleMainBoardMasonry() {
    return {
      opacity: Number(this.showMasonry)
    };
  }

  openNotificationDetailModal(notification) {
    this.$modal.open({
      templateUrl: 'dialog/top-notification/top-notification-dialog.html',
      controller: 'TopNotificationDialogController',
      controllerAs: 'ctrl',
      windowClass: 'modal-center',
      animation: true,
      keyboard: false,
      resolve: {
        params: () => {
          return {
            notification
          };
        }
      }
    });
  }
}

app.controller('HomeController', HomeController);
