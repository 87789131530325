class FarmService {
  constructor(
    APIService
  ) {
    'ngInject';
    this.APIService = APIService;
    this.cache = {};
  }

  index() {
    return this.APIService.get('/farm');
  }

  /**
   * アカウント情報を取得する。
   * TODO: 将来的にはこの関数は初回読み込み時以外、使用しない。
   * 各データはWebsocketなどを使用して非同期更新にする。
   *
   * @return {Object} promiseオブジェクト
   */

  show() {
    // キャッシュがなければ、作成する
    if (!this.cache.account) {
      this.cache.account = this.APIService.get('/farm/current')
        .then((response) => response.data)
        .catch((error) => console.error('Data read failed', error.message));
    }

    // キャッシュしたプロミスを返す
    return this.cache.account;
  }

  /**
   * アカウント情報を更新する。
   *
   * @param {Object} updateData 更新データ
   * @return {Object} promiseオブジェクト
   */
  update(updateData) {
    return this.APIService.put('/farm', updateData).then((res) => {
      // 更新成功のため、古くなったキャッシュを削除。
      if ('account' in this.cache) {
        delete this.cache.account;
      }
      return res.data;
    }).catch((error) => {
      console.error('Data update failed', error);
      throw error.data;
    });
  }
}

app.service('FarmService', FarmService);
