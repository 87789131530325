class AlertCalvingController {
  constructor(
    $modal,
    $state,
    $stateParams,
    AlertCalvingAPI,
    DateUtilService,
    CowService,
    SessionCache,
    ViewStateAPI
  ) {
    'ngInject';
    this.$modal = $modal;
    this.$state = $state;
    this.AlertCalvingAPI = AlertCalvingAPI;
    this.DateUtilService = DateUtilService;
    this.CowService = CowService;
    this.ViewStateAPI = ViewStateAPI;

    this.title = '分娩アラート';

    this.isConfirmed = $stateParams.confirmed || false;
    this.isShowPedigree = SessionCache.farm().isBreeding();

    this.printTitle = this.isConfirmed ? `${this.title}(確認済)` : `${this.title}(未確認)`;

    this.init();
  }

  init() {
    this.loading = true;

    this.AlertCalvingAPI.index().then((res) => {
      this.rawAlerts = res.data.map((d) => AlertCalving.toViewModel(d));
      this.index();
    }).catch((err) => {
      console.error(err);
    }).finally(() => this.loading = false);
  }

  index() {
    this.alerts = this.rawAlerts.filter((alert) => {
      return alert.confirmed === this.isConfirmed;
    });
  }

  report(id) {
    const alertCalving = this.alerts.find((a) => a.id === id);

    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'menu/alert/calving/report.html',
      controller: 'AlertCalvingReportController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'modal-select-standard',
      resolve: {
        params: () => {
          return {
            id: alertCalving.id,
            confirmedStatus: alertCalving.confirmedStatus,
            confirmedComment: alertCalving.confirmedComment
          };
        }
      }
    });

    modalInstance.result.then((result) => {
      alertCalving.confirmed = true;
      alertCalving.confirmedStatus = result.confirmedStatus;
      alertCalving.confirmedStatusLabel = AlertCalving.CONFIRMED_STATUS[result.confirmedStatus],
      alertCalving.confirmedComment = result.confirmedComment;
      this.index();
    });
  }

  goToDetails(id) {
    const cowIds = this.alerts.map((a) => a.cowId);
    const state = this.isConfirmed ? 'alert-calving-confirmed' : 'alert-calving';
    const name = this.isConfirmed ? `${this.title}(確認済)` : `${this.title}(未確認)`;
    const caller = {
      state: state,
      name: name,
      cowIds: cowIds,
      viewState: state
    };

    const alertCalving = this.alerts.find((a) => a.id === id);
    this.$state.go('cowDetail', {cowId: alertCalving.cowId, caller: caller});
  }

  onPrint() {
    const state = this.isConfirmed ? 'alert-calving-confirmed' : 'alert-calving';
    this.ViewStateAPI.create(`print-in-${state}`, state);
    print();
  }
}

app.controller('AlertCalvingController', AlertCalvingController);
