class EmbryoRecoveryReportFilterDialogController {
  constructor(
    $modalInstance,
    $modal,
    SessionCache,
    resolved
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
    this.$modal = $modal;
    this.farm = SessionCache.farm();
    this.filterParams = resolved.filterParams;

    this.textOperators = [
      {key: 'equal', label: '条件値と等しい'},
      {key: 'notEqual', label: '条件値以外'},
      {key: 'include', label: '条件値を含む'}
    ];
    this.selectOperators = [
      {key: 'include', label: '指定した値を含む'},
      {key: 'exclude', label: '指定した値を含まない'}
    ];
    this.rangeOperators = [
      {key: 'include', label: '指定範囲内'}
    ];

    const cowGroupOptions = resolved.cowGroups.map(({cowGroupId, cowGroupName}) => {
      return {key: String(cowGroupId), label: cowGroupName};
    });
    const stateOptions = State.values(SessionCache.farm().farmType())
      .map(({label}) => {
        return {key: label, label};
      });
    const cowLabelOptions = resolved.cowLabels
      .filter(({visible}) => visible)
      .map(({name}) => {
        return {key: name, label: name};
      });

    this.filters = [
      {key: 'embryoRecoveryDate', label: '最新採卵日', type: 'date'},
      {key: 'cowNo', label: '牛番号', type: 'comma'},
      {key: 'cowGroupId', label: '牛群', type: 'select', options: cowGroupOptions},
      {key: 'monthAge', label: '月齢', type: 'range'},
      {key: 'birthNumber', label: '産次', type: 'range'},
      {key: 'state', label: '状態', type: 'select', options: stateOptions},
      {key: 'recoveryCount', label: '採卵回数', type: 'range'},
      {key: 'cowLabels', label: '個体ラベル', type: 'select', options: cowLabelOptions}
    ];

    if (this.filterParams.length === 0) {
      this.addFilterParam();
    }
  }

  submit() {
    this.$modalInstance.close(this.filterParams);
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  clear() {
    this.filterParams.length = 0;
  }

  addFilterParam() {
    const selectedKeys = this.filterParams.map(({key}) => key);
    const unselectedFilters = this.filters.filter(({key}) => !selectedKeys.includes(key));

    if (unselectedFilters.length > 0) {
      const filterParam = this.createFilterParam(unselectedFilters[0]);
      this.filterParams.push(filterParam);
    }
  }

  removeFilterParam(index) {
    this.filterParams.splice(index, 1);
  }

  openSelectDialog(filterParam, $index) {
    const filter = this.findFilter(filterParam.key);
    if (!filter) return;

    const values = filter.options.reduce((obj, {key}) => {
      obj[key] = filterParam.value.includes(key);
      return obj;
    }, {});

    this.$modal.open({
      templateUrl: 'menu/report/embryo-recovery/select-dialog.html',
      controller: 'EmbryoRecoveryReportSelectDialogController',
      controllerAs: 'selectDialogCtrl',
      backdrop: 'static',
      animation: true,
      keyboard: false,
      size: 'select-standard',
      resolve: {
        resolved: () => ({
          title: '条件選択',
          caption: filter.label,
          options: filter.options,
          values
        })
      },
    }).result
      .then((values) => {
        this.filterParams[$index].value = values;
      });
  }

  onChangeFilterKey(filterParam, $index) {
    const filter = this.findFilter(filterParam.key);
    if (!filter) return;

    const newFilterParam = this.createFilterParam(filter);
    this.filterParams.splice($index, 1, newFilterParam);
  }

  displayFilterParam(filterParam) {
    const filter = this.findFilter(filterParam.key);
    if (!filter) {
      return filterParam.value;
    }

    switch (filter.type) {
    case 'select':
      return filterParam.value.map((value) => {
        return filter.options.find((option) => {
          return String(option.key) === String(value);
        }).label;
      }).join(', ');
    }
  }

  createFilterParam(filter) {
    let operator;
    let value;

    switch (filter.type) {
    case 'comma':
      operator = this.textOperators[0].key;
      value = null;
      break;
    case 'range':
    case 'date':
      operator = this.rangeOperators[0].key;
      value = [null, null];
      break;
    case 'select':
      operator = this.selectOperators[0].key;
      value = [];
      break;
    default:
      value = null;
    }

    return {key: filter.key, operator, value};
  }

  findFilter(filterKey) {
    const filter = this.filters.find((filter) => filter.key === filterKey);
    if (!filter) console.error(`'${filterKey}' is invalid filter key.`);
    return filter;
  }

  getFilter(filterKey) {
    return this.findFilter(filterKey) || {};
  }
}

app.controller('EmbryoRecoveryReportFilterDialogController', EmbryoRecoveryReportFilterDialogController);
