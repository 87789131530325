class AlertHistoryController {
  constructor(
    AlertAPI,
    DateUtilService,
    CowGroupAPI,
    UtilService,
    FarmService,
    DetailsStateFactory,
    Dictionary,
    $state,
    $timeout,
    UnexpectedDysstasiaInputDialog,
    $rootScope,
    ViewStateAPI,
    $stateParams,
    AlertConfigService,
    AlertEartagSensorDroppedAPI,
    AlertEartagSensorAPI,
    $q
  ) {
    'ngInject';

    this.AlertAPI = AlertAPI;
    this.DateUtilService = DateUtilService;
    this.DetailsStateFactory = DetailsStateFactory;
    this.UtilService = UtilService;
    this.Dictionary = Dictionary;
    this.$state = $state;
    this.$timeout = $timeout;
    this.UnexpectedDysstasiaInputDialog = UnexpectedDysstasiaInputDialog;
    this.ViewStateAPI = ViewStateAPI;
    this.AlertEartagSensorDroppedAPI = AlertEartagSensorDroppedAPI;
    this.AlertEartagSensorAPI = AlertEartagSensorAPI;

    this.cowNoNumericValidator = new CowNoNumericValidator();
    this.cowNoAlphamericValidator = new CowNoAlphamericValidator();

    this.title = 'アラート履歴';
    this.form = {};

    this.CSV_LIMIT = Alert.CSV_LIMIT;
    this.DISPLAY_LIMIT = Alert.DISPLAY_LIMIT;

    // NOTE: 「検索」ボタンをクリックするまで実際の検索を行わない仕様にするため、
    //        アラートリストUIにバインドされる this.xxxx と this.form.xxx をわける
    this.initParams($stateParams);

    // アラート検索結果
    this.alerts = [];

    // フォームのバリデーションエラー
    this.form.error = null;

    // 牛番号のバリデーションパターン
    this.alphanumericCowNo;

    this.masterLoaded = false;

    // 牛群オプション
    $q.all([
      CowGroupAPI.active(),
      FarmService.show(),
      AlertConfigService.show()
    ]).then((res) => {
      this.cowGroupOptions = res[0].data.map((cowGroup) => {
        return {
          label: cowGroup.cowGroupName,
          value: cowGroup.cowGroupId
        };
      });

      this.farm = new Farm(res[1]);
      this.alphanumericCowNo = res[1].cowNoPattern === 'alphanumeric';

      // アラート種別選択候補
      this.alertTypeOptions = Object.keys(Alert.ALERT_TYPES).filter((key) => {
        if (key === 'DYSSTASIA' && res[1].farmKind === '乳用') {
          return false;
        } else if (['CALVING', 'CALVING_SENSOR_DROPPED'].includes(key) && !this.farm.useAlertCalving()) {
          return false;
        } else if (key === 'SENSOR' && !this.farm.useAlertSensor()) {
          return true;
        } else if (key === 'EARTAG_SENSOR_DROPPED' && !this.farm.detectDropoutEartagSensor()) {
          return false;
        } else if (key === 'EARTAG_SENSOR' && !this.farm.useAlertEartagSensor()) {
          return false;
        } else {
          return true;
        }
      }).map((key) => {
        const alertType = Alert.ALERT_TYPES[key];
        return {
          label: Alert.ALERT_TYPE_LABELS[alertType],
          value: alertType
        };
      });

      this.showAcuteIllness = res[2].data.showAcuteIllness;
      this.masterLoaded = true;
      this.init();
    });
  }

  // private
  init() {
    this.getAlerts();
  }

  getAlerts() {
    this.loading = true;
    this.alerts = [];

    const options = {
      cowGroupId: this.searchBy === 'cowGroupId' ? this.cowGroupId : null,
      cowNo: this.searchBy === 'cowNo' ? this.cowNo : null,
      inactive: this.shouldIncludeSoldAndDied ? true : false
    };

    if (this.alertType === 'sensor') {
      this.AlertAPI.getAlertSensor(this.startDate, this.endDate, options).then((result) => {
        this.alerts = Alert.decorateAlerts(this.form.alertType, result.data, this.showAcuteIllness);
      })
        .catch((error) => console.error(error))
        .finally(() => this.loading = false);

      return;
    }

    if (this.alertType === 'dysstasia') {
      this.AlertAPI.dysstasiaAlerts(this.startDate, this.endDate, options).then((result) => {
        this.alerts = Alert.decorateAlerts(this.form.alertType, result.data, this.showAcuteIllness);
      })
        .catch((error) => console.error(error))
        .finally(() => this.loading = false);

      return;
    }

    if (this.alertType === 'eartag_sensor_dropped') {
      this.AlertEartagSensorDroppedAPI.getAlerts(this.startDate, this.endDate, options).then((result) => {
        this.alerts = Alert.decorateEartagDroppedAlerts(result.data);
      })
        .catch((error) => console.error(error))
        .finally(() => this.loading = false);

      return;
    }

    if (this.alertType === 'eartag_sensor') {
      this.AlertEartagSensorAPI.getAlerts(this.startDate, this.endDate, options).then((result) => {
        this.alerts = Alert.decorateEartagAlerts(result.data);
      })
        .catch((error) => console.error(error))
        .finally(() => this.loading = false);

      return;
    }

    this.AlertAPI.alerts(this.alertType, this.startDate, this.endDate, options).then((result) => {
      this.alerts = Alert.decorateAlerts(this.form.alertType, result.data, this.showAcuteIllness);
    }).catch((error) => console.error(error))
      .finally(() => this.loading = false);
  }

  /**
   * UIハンドラー
   */

  /**
   * 印刷
   */
  onPrint() {
    this.ViewStateAPI.create('print-in-alert-history', 'alert-history');
    print();
  }

  /**
   * CSVエクスポート
   */
  onExportAsCSV() {
    const alertName = Alert.ALERT_TYPE_LABELS[this.alertType];
    const fileName = `${alertName}アラート-${this.DateUtilService.toYYYYMMDD(this.startDate, '')}-${this.DateUtilService.toYYYYMMDD(this.endDate, '')}.csv`;
    const csvString = Alert.convertAlertsToCSVString(
      this.alertType,
      this.Dictionary,
      this.alerts.slice(0, this.CSV_LIMIT));

    this.UtilService.csvExport(csvString, fileName);
  }

  /**
   * 検索
   */
  onSearch() {
    this.alertType = this.form.alertType;
    this.startDate = this.form.startDate;
    this.endDate = this.form.endDate;
    this.cowGroupId = this.form.cowGroupId;
    this.cowNo = this.form.cowNo;
    this.shouldIncludeSoldAndDied = this.form.shouldIncludeSoldAndDied;

    this.getAlerts();
  }

  /**
   * 牛番号変更時、検索対象を「牛番号指定」に変更
   */
  onCowNoChange() {
    if (this.form.cowNo) {
      this.searchBy = 'cowNo';
    }
  }

  /**
   * 牛群選択時、検索対象を「牛群指定」に変更
   */
  onCowGroupIdChange() {
    if (this.form.cowGroupId) {
      this.searchBy = 'cowGroupId';
    }
  }

  /**
   * アラート種別のラベルがクリックされたとき
   */
  onAlertLabelClick(cowId, alertLabel) {
    this.goToDetails(cowId, alertLabel);
  }

  onClickEditUnexpectedDysstasia(id) {
    this.UnexpectedDysstasiaInputDialog.update(id).then((result) => {
      if (result.executed) {
        this.onSearch();
      }
    });
  }

  onClickDeleteUnexpectedDysstasia(cowNo, dysstasiaId) {
    this.UnexpectedDysstasiaInputDialog.delete(cowNo, dysstasiaId).then(() => {
      this.$timeout(() => {
        this.onSearch();
      }, 1000);
    });
  }

  /**
   * ng-if用チェックロジック
   */
  shouldShowValidationError() {
    return this.form.error;
  }

  shouldActivateExport() {
    return (this.alerts.length > 0 && this.isFormValid());
  }

  /**
   * 検索が実行できる状態か？
   */
  isFormValid() {
    this.form.error = null;

    if (this.searchBy === 'cowGroupId' && !this.form.cowGroupId) {
      this.form.error = '発生時牛群を選択してください';
      return false;
    }

    if (this.searchBy === 'cowNo' && !this.form.cowNo) {
      this.form.error = `正しい${this.Dictionary.COW.COW_NO}を入力してください`;
      return false;
    }

    if (!this.form.startDate || !this.form.endDate) {
      this.form.error = '開始日と終了日を入力してください';
      return false;
    }

    if (this.DateUtilService.startOfDay(this.form.startDate) > this.DateUtilService.startOfDay(this.form.endDate)) {
      this.form.error = '開始日は終了日の前の日付を入力してください';
      return false;
    }

    if (!this.DateUtilService.isValidDate(this.form.startDate)
      || !this.DateUtilService.isValidDate(this.form.endDate)) {
      this.form.error = '正しい日付を入力してください';
      return false;
    }

    if (this.farm && this.farm.useName()) {
      return true;
    } else if (this.alphanumericCowNo) {
      if (this.cowNoAlphamericValidator.validate(this.form.cowNo) === false) {
        this.form.error = `正しい${this.Dictionary.COW.COW_NO}を入力してください`;
        return false;
      }
    } else {
      if (this.cowNoNumericValidator.validate(this.form.cowNo) === false) {
        this.form.error = `正しい${this.Dictionary.COW.COW_NO}を入力してください`;
        return false;
      }
    }

    return true;
  }

  /**
   * 検索件数リミットのアラートを表示するか？
   */
  shouldShowDisplayLimit() {
    return this.alerts.length >= Alert.DISPLAY_LIMIT;
  }

  /**
   * CSV出力件数リミットのアラートを表示するか？
   */
  shouldShowCSVLimit() {
    return this.alerts.length >= Alert.CSV_LIMIT;
  }

  initParams(params) {
    // 選択されたアラート種別
    this.alertType = this.form.alertType = params.alertType || Alert.ALERT_TYPES.BAD_CONDITION;

    // 対象期間(開始日)
    this.startDate = this.form.startDate = DateUtil.toDate(params.startDate) || this.DateUtilService.weekAgo(1);

    // 対象期間(終了日)
    this.endDate = this.form.endDate = DateUtil.toDate(params.endDate) || new Date().valueOf();

    // 検索対象牛
    this.searchBy = params.searchBy || 'all';

    // 検索対象牛群
    const cowGroupId = params.cowGroupId ? Number(params.cowGroupId) : null;
    this.cowGroupId = this.form.cowGroupId = cowGroupId;

    // 検索対象牛番号
    this.cowNo = this.form.cowNo = params.cowNo || null;

    // 出荷・へい死を含む
    const shouldIncludeSoldAndDied = params.shouldIncludeSoldAndDied === 'true';
    this.shouldIncludeSoldAndDied = this.form.shouldIncludeSoldAndDied = shouldIncludeSoldAndDied;
  }

  goToDetails(cowId, alertLabel = null) {
    this.DetailsStateFactory.setDisplayTab(alertLabel === '乳量'
      ? this.DetailsStateFactory.tabNames.milkingTab
      : this.DetailsStateFactory.tabNames.activityTab);

    const cowIds = this.alerts.reduce((arr, ev) => {
      if (arr.indexOf(ev.cowId) < 0) arr.push(ev.cowId);
      return arr;
    }, []);

    const params = {
      alertType: this.alertType,
      startDate: DateUtil.toW3CFormat(this.startDate),
      endDate: DateUtil.toW3CFormat(this.endDate),
      searchBy: this.searchBy,
      shouldIncludeSoldAndDied: this.shouldIncludeSoldAndDied ? true : false
    };

    if (this.searchBy === 'cowGroupId') {
      params.cowGroupId = this.cowGroupId || null;
    } else if (this.searchBy === 'cowNo') {
      params.cowNo = this.cowNo || null;
    }

    const caller = {
      name: 'アラート履歴',
      cowIds,
      state: 'alert-history',
      params: params
    };

    this.$state.go('cowDetail', {cowId, caller});
  }
}

app.controller('AlertHistoryController', AlertHistoryController);
