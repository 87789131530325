// eslint-disable-next-line no-unused-vars
class DailyReportSummaryDefinition {
  static updateSummary(data, context) {
    return EventType.allTypes.map((menu) => {
      menu.count = data[menu.legacyEventType] || 0;
      menu.to = 'daily-report-details';
      menu.params = {
        date: context.date,
        eventType: menu.eventType
      };
      menu.active = menu.eventType === context.eventType;

      return menu;
    });
  }

  static grouping(items) {
    return items.reduce((acc, cur) => {
      const findItem = acc.find((item) => {
        return item.group === cur.group;
      });

      if (!findItem) {
        acc.push({
          group: cur.group,
          items: [cur]
        });
      } else {
        findItem.items.push(cur);
      }

      return acc;
    }, []);
  }
}
