class FatteningReportSwitcherController {
  constructor(
  ) {
    'ngInject';
  }

  isStandardReportSelected() {
    return this.reportType === 'standard';
  }

  isFarmReportSelected() {
    return this.reportType === 'farm';
  }

  isItemReportSelected() {
    return this.reportType === 'item';
  }

  selectReportType(reportType) {
    this.onSelectReportType({reportType: reportType});
  }
}

function fatteningReportSwitcher() {
  return {
    templateUrl: 'menu/report/fattening/components/report-switcher/index.html',
    controller: FatteningReportSwitcherController,
    bindings: {
      reportType: '<',
      onSelectReportType: '&',
    }
  };
}
app.component('fatteningReportSwitcher', fatteningReportSwitcher());
