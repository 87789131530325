class TopController {
  constructor(
    $rootScope,
    $scope,
    $state,
    $window,
    SessionCache,
    NotificationsService,
    CoolPesconApi
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$window = $window;
    this.NotificationsService = NotificationsService;
    this.CoolPesconApi = CoolPesconApi;

    this.uiVersion = SessionCache.account().uiVersion();
    this.farm = SessionCache.farm();
    if (this.farm.isDepositor()) {
      $state.go('depositHome');
    }

    if (this.uiVersion.split('_')[0] !== 'h-1') {
      $state.go('home');
    }

    this.onInit($scope, SessionCache);
  }

  onInit($scope, SessionCache) {
    this.NotificationsService.index().then((res) => {
      this.notifications = Notification.decorate(Notification.sort(Notification.filter(res.data, true)));
    }).catch((err) => {
      console.error(err);
    });

    $scope.$watch(() => SessionCache.umPlatformPartner(), (newValue) => {
      this.umPlatformPartner = newValue;
      this.getCoolPescon();
    });
  }

  getCoolPescon() {
    if (this.umPlatformPartner && this.umPlatformPartner.hasCoolPescon()) {
      this.CoolPesconApi.account().then((res) => {
        if (res && res.data) this.coolPescon = new CoolPescon(res.data);
      });
    }
  }

  rightContainerClass() {
    const notificationVisibleCount = this.notifications ? Math.min(this.notifications.length, 3) : 0;
    let containerClass = `right--container notification-visible-count-${notificationVisibleCount}`;
    if (!this.showCoolPescon()) return containerClass;
    return `${containerClass} contains-cool-pescon`;
  }

  showCoolPescon() {
    return this.umPlatformPartner && this.umPlatformPartner.hasCoolPescon() && !!this.coolPescon;
  }
}

app.controller('TopController', TopController);
