/**
 * 起立困難アラートの編集フォームコンポーネント
 *
 * ex.
 *  <alert-dysstasia-config-edit-form
 *    error="error"
 *    thresholds="thresholds"
 *    threshold-options="thresholdOptions"
 *    on-change="onChange()"
 *    on-threshold-option-add="onThresholdOptionAdd()"
 *    on-threshold-option-remove="onThresholdOptionRemove(index)"
 *    on-breed-selection-click="onBreedSelectionClick(index)"
 *  ></alert-dysstasia-config-edit-form>
 *
 * プロパティ:
 *  @param {Object} error エラーオブジェクト
 *  @param {Array<Object>} thresholds 閾値オブジェクト配列
 *  @param {Array<Object>} thresholdOptions オプション閾値オブジェクト配列
 *  @param {Function} onChange 閾値変更時のイベント
 *  @param {Function} onThresholdOptionAdd 品種別の閾値追加時のイベント
 *  @param {Function} onThresholdOptionRemove 品種別の閾値追加時のイベント、引数に削除対象のインデックスを渡す
 *  @param {Function} onBreedSelectionClick 品種選択時のイベント、引数に品種別の閾値のインデックスを渡す
 */
class AlertDysstasiaConfigEditFormController {
  constructor() {
    'ngInject';
  }

  joinedBreeds(breeds) {
    return breeds ? breeds.join('、') : null;
  }
}

function alertDysstasiaConfigEditFormComponent() {
  return {
    templateUrl: 'menu/setting/alert/dysstasia-config/edit/form.html',
    controller: AlertDysstasiaConfigEditFormController,
    bindings: {
      error: '<',
      thresholds: '=',
      thresholdOptions: '=',
      onChange: '&',
      onThresholdOptionAdd: '&',
      onThresholdOptionRemove: '&',
      onBreedSelectionClick: '&'
    }
  };
}

app.component('alertDysstasiaConfigEditForm', alertDysstasiaConfigEditFormComponent());
