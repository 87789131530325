class HomeHeadageSummaryBreedController {
  constructor(
    $state
  ) {
    'ngInject';

    this.$state = $state;

    HomeHeadageBaseController.bind(this);
  }
}

function homeHeadageSummaryBreed() {
  return {
    templateUrl: 'menu/home/headage/summary/breed/index.html',
    controller: HomeHeadageSummaryBreedController,
    controllerAs: 'ctrl',
    bindings: {
      data: '<'
    }
  };
}

app.component('homeHeadageSummaryBreed', homeHeadageSummaryBreed());
