class CertificateBirthController {
  constructor(
    $q,
    $modal,
    $window,
    $timeout,
    SessionCache,
    StandardDialog,
    FileUtilService,
    CertificateBirthAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.$modal = $modal;
    this.$window = $window;
    this.$timeout = $timeout;
    this.StandardDialog = StandardDialog;
    this.FileUtilService = FileUtilService;
    this.CertificateBirthAPI = CertificateBirthAPI;

    const farm = SessionCache.farm();

    this.init(farm);
  }

  init(farm) {
    this.currentColumnSort = null;
    this.rawCows = [];
    this.farmData = angular.copy(farm.data);
    this.useName = farm.useName();
    this.model = {
      type: 'mother'
    };
    this.label = {
      headingSearchNo: this.useName ? '名前・番号' : '番号',
      cowNos: this.useName ? '名前' : '牛番号',
    };

    this.defaultSetting();
    this.breedsDefinition();
  }

  defaultSetting() {
    this.invalidDate = false;

    if (this.model.type === 'mother') {
      this.model = {
        type: 'mother',
        birthStartDate: DateUtil.addDays(DateUtil.today(), -7),
        birthEndDate: DateUtil.today(),
        noType: 'motherCowNos',
        searchNos: null
      };
    } else {
      this.model = {
        type: 'calf',
        birthStartDate: DateUtil.addDays(DateUtil.today(), -7),
        birthEndDate: DateUtil.today(),
        noType: 'cowNos',
        searchNos: null
      };
    }
  }

  defaultBirthday() {
    return {
      era: Object.entries(DateUtil.japaneseEra()).map((item) => {
        return item[1];
      }).reduce((a, b) => {
        return a.time > b.time ? a : b;
      }).era.ggg,
      date: this.separatedNumber(null, 6),
    };
  }

  columnsDefinition() {
    this.columns = [{
      columnId: 'selected',
      name: '選択',
      width: 50
    }, {
      columnId: 'cowNo',
      name: this.useName ? '名前' : '牛番号',
      width: 110
    }, {
      columnId: 'cowUid',
      name: '個体識別番号',
      width: 140
    }, {
      columnId: 'birthday',
      name: '出生日',
      width: 110
    }, {
      columnId: 'gender',
      name: '性別',
      width: 110
    }, {
      columnId: 'motherCowNo',
      name: this.useName ? '母牛の名前' : '母牛の牛番号',
      width: 110
    }, {
      columnId: 'motherCowUid',
      name: '母牛の個体識別番号',
      width: 160
    }, {
      columnId: 'breed',
      name: '品種',
      width: 110
    }];
  }

  breedsDefinition() {
    this.breeds = {
      1: 'ホルスタイン種',
      2: 'ジャージ種',
      3: '交雑種',
      4: '黒毛和種',
      5: '褐毛和種',
      6: '日本短角種',
      7: '無角種',
      8: '黒毛と褐毛の交雑種',
      10: '和牛間交雑種',
      11: '肉専用種',
      12: '乳用種'
    };
  }

  search() {
    this.currentColumnSort = null;
    this.uiGrid = null;

    const params = Object.entries(this.model).reduce((acc, [key, value]) => {
      if (key === 'searchNos') {
        acc[this.model.noType] = this.separatedSearchNos(this.model.noType, value);
      } else if (key !== 'noType') {
        acc[key] = value;
      }

      return acc;
    }, {});

    this.CertificateBirthAPI.search(params).then((res) => {
      this.allCheck = true;

      this.cows = res.data.map((cow) => {
        cow.origin = angular.copy(cow);
        cow.cowUid = Cow.formatCowUid(cow.cowUid);
        cow.birthday = DateUtil.toYYYYMMDD(cow.birthday);
        cow.motherCowUid = Cow.formatCowUid(cow.motherCowUid);
        cow.breed = this.breeds[cow.breedCode];
        cow.selected = true;

        return cow;
      });

      this.rawCows = [].concat(this.cows);
      this.setCount();
      this.columnsDefinition();

      const generator = new CertificateBirthIndexGenerator();

      this.uiGrid = {
        appScopeProvider: this,
        columnDefs: generator.generateColumnDefs(this.columns),
        rowTemplate: generator.generateRowTemplate(),
        data: this.cows,
        onRegisterApi: (gridApi) => {
          this.gridApi = gridApi;
        }
      };
    });
  }

  print() {
    this.showEmptyFieldsConfirmModal().then(() => {
      this.showEmptyFieldsMessageModal().then(() => {
        const title = document.title;
        const size = 10;
        const option = {
          year: 'ggg',
          month: 'mm',
          day: 'dd'
        };

        document.title = `出生報告_${DateUtil.toYYYYMMDD(DateUtil.today(), '')}`;

        this.printItems = angular.copy(this.cows).filter((cow) => {
          return cow.selected;
        }).map((cow) => {
          cow.cowUid = this.separatedNumber(cow.origin.cowUid, 10);
          cow.motherCowUid = this.separatedNumber(cow.origin.motherCowUid, 10);
          cow.birthday = this.separatedDate(DateUtil.formatDate(cow.origin.birthday, option));
          cow.gender = this.formatGender(cow.origin.gender);
          cow.breedCode = this.separatedNumber(cow.origin.breedCode, 2, 'prefix');

          return cow;
        });

        this.printItems = this.printItems.reduce((acc, value, index) => {
          return index % size ? acc : [...acc, this.printItems.slice(index, index + size)];
        }, []).map((item) => {
          for (let i = item.length; i < size; i++) {
            item[i] = {
              cowUid: this.separatedNumber(null, 10),
              motherCowUid: this.separatedNumber(null, 10),
              birthday: this.defaultBirthday(),
              gender: null,
              breedCode: this.separatedNumber(null, 2),
            };
          }

          return item;
        });

        this.printFarmCode = this.separatedNumber(this.farmData.farmCode, 10);

        this.$timeout(() => {
          print();
          document.title = title;
        });
      });
    });
  }

  csv() {
    this.showEmptyFieldsMessageModal().then(() => {
      const heading = new Uint8Array([
        35, 35, 35, 35, 35, // #####
        143, 111, 144, 182, 130, 204, 147, 205, 143, 111, 147, 199, 141, 158, 151, 108, 142, 174, // 出生の届出読込様式
        35, 35, 35, 35, 35, // #####
        44, 44, 44, 44, // ,,,,
        13, 10 // CR LF
      ]);

      const header = new Uint8Array([
        140, 194, 145, 204, 142, 175, 149, 202, 148, 212, 141, 134, // 個体識別番号
        44, // ,
        143, 111, 144, 182, 148, 78, 140, 142, 147, 250, // 出生年月日
        44, // ,
        142, 147, 151, 89, 130, 204, 149, 202, // 雌雄の別
        44, // ,
        149, 234, 139, 141, 140, 194, 145, 204, 142, 175, 149, 202, 148, 212, 141, 134, // 母牛個体識別番号
        44, // ,
        142, 237, 149, 202, // 種別
        13, 10 // CR LF
      ]);

      let items = angular.copy(this.cows).filter((cow) => {
        return cow.selected;
      }).map((cow) => {
        return [
          cow.origin.cowUid,
          DateUtil.toYYYYMMDD(cow.origin.birthday, ''),
          this.formatGender(cow.origin.gender),
          cow.origin.motherCowUid,
          cow.origin.breedCode
        ];
      }).join('\r\n');

      items = `${items}\r\n`;

      const fileName = `出生報告_${DateUtil.toYYYYMMDD(DateUtil.today(), '')}.csv`;
      const blob = new Blob([heading, header, items], {type: 'text/csv'});

      let url = null;
      if (this.$window.navigator.msSaveBlob) {
        url = this.$window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        url = this.$window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    });
  }

  showEmptyFieldsConfirmModal() {
    if (this.farmData.faxNumber && this.farmData.farmCode) {
      const deferred = this.$q.defer();
      deferred.resolve({});
      return deferred.promise;
    }

    let emptyFields = [];

    if (!this.farmData.faxNumber) {
      emptyFields.push('FAX番号');
    }

    if (!this.farmData.farmCode) {
      emptyFields.push('農家コード');
    }

    emptyFields = emptyFields.map((field) => `「${field}」`).join('');

    return this.StandardDialog.showYesNoConfirm({
      title: '出生報告',
      text1: `${emptyFields}が未登録のため空欄のまま出力されます。`,
      text2: `${emptyFields}は牧場設定から登録できます。`,
      text3: '出力しますか？',
      yes: 'OK',
      no: 'キャンセル'
    }).result.then((modalResult) => {
      if (!modalResult) {
        const deferred = this.$q.defer();
        deferred.resolve({});
        return deferred.promise;
      }
    });
  }

  showEmptyFieldsMessageModal() {
    const hasFreemartin = this.cows.filter((cow) => {
      return cow.selected;
    }).some((cow) => {
      return cow.gender === 'フリーマーチン';
    });

    if (!hasFreemartin) {
      const deferred = this.$q.defer();
      deferred.resolve({});
      return deferred.promise;
    }

    return this.StandardDialog.showYesConfirm({
      title: '出生報告',
      text1: '出力対象にフリーマーチンの牛が含まれています。',
      text2: '「性別」欄は空欄で出力されるため、出力後に「性別」を追記してください。',
      yes: 'OK',
    }).result.then((modalResult) => {
      if (!modalResult) {
        const deferred = this.$q.defer();
        deferred.resolve({});
        return deferred.promise;
      }
    });
  }

  setCount() {
    this.count = `選択中：${this.cows.filter((cow) => cow.selected).length}件／${this.cows.length}件`;
  }

  separatedSearchNos(noType, value) {
    if (!value) return [];

    let result = value;

    if (['cowUids', 'motherCowUids'].includes(noType)) {
      result = result.replace(/[-]/g, '');
    }

    result = result.replace(/[、，\x20\u3000]/g, ',').split(',').map((s) => s.trim());

    return result;
  }

  separatedNumber(number, space, affix) {
    if (!number) return [...Array(space)];

    if (affix === 'prefix') {
      return [...Array(space - number.length)].concat(number.split(''));
    }

    return number.split('').concat([...Array(space - number.length)]);
  }

  separatedDate(date) {
    if (!date) return this.defaultBirthday();

    let result = date.replace(/\//g, '').match(/([^0-9].)([0-9]{5,6})/).slice(1, 3);

    if (result[1].length === 5) {
      result[1] = `0${result[1]}`;
    }

    return {
      era: result[0],
      date: result[1].split('')
    };
  }

  formatGender(gender) {
    switch (gender) {
    case 'オス':
      return 1;
    case 'メス':
      return 2;
    default:
      return null;
    }
  }

  clickHeaderCell(columnId, gridCol) {
    if (CustomlistSort.isUnsortable(columnId)) return;

    const column = this.columns.find((column) => column.columnId === columnId);
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        CustomlistSort.sort(this.cows, column, false);
        this.currentColumnSort = {column, isAscending: false};
      } else {
        this.currentColumnSort = null;
        this.cows = [].concat(this.rawCows);
        this.uiGrid.data = this.cows;
      }
    } else {
      CustomlistSort.sort(this.cows, column, true);
      this.currentColumnSort = {column, isAscending: true};
    }
  }

  toggleCheck($event, cow) {
    if ($event.target.nodeName !== 'INPUT') {
      cow.selected = !cow.selected;
    }

    this.setCount();

    this.allCheck = this.cows.every((cow) => {
      return cow.selected;
    });
  }

  toggleAllCheck() {
    const checked = this.allCheck;

    this.cows = this.cows.map((cow) => {
      cow.selected = checked;

      return cow;
    });

    this.setCount();
    this.uiGrid.data = this.cows;
  }

  changeType() {
    this.defaultSetting();
  }

  changeDate(type) {
    let invalidDate = false;

    if (type === 'date') {
      if (!(DateUtil.isValidDate(this.model.birthStartDate) &&
          DateUtil.isValidDate(this.model.birthEndDate) &&
          DateUtil.includedPermittedPeriod(this.model.birthStartDate) &&
          DateUtil.includedPermittedPeriod(this.model.birthEndDate))) {
        invalidDate = true;
      } else if (!(this.model.birthStartDate && this.model.birthEndDate)) {
        invalidDate = true;
      } else if (this.model.birthStartDate > this.model.birthEndDate) {
        invalidDate = true;
      }
    } else {
      if (this.model.birthStartDate === null) {
        invalidDate = true;
      } else if (this.model.birthStartDate &&
        !(DateUtil.isValidDate(this.model.birthStartDate) &&
          DateUtil.includedPermittedPeriod(this.model.birthStartDate))) {
        invalidDate = true;
      }

      if (this.model.birthEndDate === null) {
        invalidDate = true;
      } else if (this.model.birthEndDate &&
        !(DateUtil.isValidDate(this.model.birthEndDate) &&
          DateUtil.includedPermittedPeriod(this.model.birthEndDate))) {
        invalidDate = true;
      }

      if (this.model.birthStartDate && this.model.birthEndDate &&
        this.model.birthStartDate > this.model.birthEndDate) {
        invalidDate = true;
      }
    }

    this.invalidDate = invalidDate;
  }

  clearDate(field, type) {
    this.model[field] = '';
    this.changeDate(type);
  }

  disabledSearchButton() {
    return this.invalidDate;
  }

  disabledExportButton() {
    if (!this.cows || this.cows.length === 0) {
      return true;
    }

    return this.cows.every((cow) => {
      return !cow.selected;
    });
  }

  showType() {
    return ['always', 'calved'].includes(this.farmData.calfManagement);
  }

  showTypeMother() {
    return this.model.type === 'mother';
  }

  showTypeCalf() {
    return this.model.type === 'calf';
  }

  showGender(cow, code) {
    return cow.gender === code;
  }

  classRow(cow) {
    return cow.selected ? 'contents__uiGridSelected' : '';
  }

  classHeaderCell(gridCol) {
    if (this.currentColumnSort && this.currentColumnSort.column.columnId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        return 'ui-grid-sorted-asc';
      } else {
        return 'ui-grid-sorted-desc';
      }
    }
  }
}

app.controller('CertificateBirthController', CertificateBirthController);
