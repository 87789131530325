app.controller('PDFController', function($scope, $rootScope, $modal, $state, $stateParams, DateTimeUtilFactory, PDFService, FarmService, CowGroupAPI, blockUI, $timeout) {
  'ngInject';

  let dUtil = DateTimeUtilFactory;
  let now = dUtil.parseClientDate(new Date());
  let toDay = moment(now).hour(0).minute(0).toDate();
  let params = getPathParams();
  params = params || {};
  $scope.selection = $scope.selection || {};
  $scope.itemLogicalNameList = null;
  $scope.itemPhysicalNameList = null;

  $scope.targetDate = null;
  $scope.itemCount = 0;

  $scope.$on('ngRepeatFinished', function() {
    FixedMidashi.create();
  });

  $scope.mode = params.mode || '0';

  if (params.mode === '1') {
    $('header').remove();
    $('#header-band').remove();
    $('.wrapper .content').css('margin', '0px');
    $('#print-link').remove();
    $('.content .title-box').css('position', 'absolute');
  }

  if (params.targetDate) {
    $scope.targetDate = new Date(Number(params.targetDate)).getTime();
  } else {
    $scope.targetDate = toDay.getTime();
  }

  // 初期化処理
  (() => {
    $scope.busy = true;
    $scope.loading = true;
    $scope.listName = $stateParams.name;

    $scope.selection = {};

    $scope.selection.cowGroup = [];
  })();

  $scope.clearInput = (key) => {
    $scope[key] = null;
  };

  FarmService.show().then((result) => {
    $scope.showPdfMenu = result.useLegacyReportMenu;
    if ($scope.showPdfMenu) {
      loadGroups();
    }
  });

  function loadGroups() {
    CowGroupAPI.active().then((res) => {
      if ('data' in res && res.data.length > 0) {
        let options = res.data.map(function(e) {
          return {'label': e.cowGroupName, 'value': e.cowGroupId};
        });
        options.unshift({'label': 'すべての牛群', 'value': 'ALL'});
        $scope.selection.cowGroup = options;
        $scope.cowGroupId = res.data[0].cowGroupId;
      }
    }).catch((err) => console.error(err));
  }

  $scope.PDFReport = (menuId) => {
    let date = new Date(Number($scope.targetDate));
    let formatDate = getDateLabelForApiResDateTime(date);

    let params = [{key: 'target_date', value: formatDate}];
    if (menuId === 'grouped-cow-list') {
      params.push({key: 'cow_group_id', value: $scope.cowGroupId});
    } else if (menuId === 'estrus-cycle-list') {
      params = null;
    }

    blockUI.start('出力中');

    PDFService.getPDFReport(menuId, params)
      .then(function(res) {
        blockUI.done(function() {
          blockUI.start('出力が完了しました');
          createPDFWindow(res.data);
          $timeout(function() {
            blockUI.stop();
          }, 1000);
        });
        blockUI.stop();
      })
      .catch(function(error) {
        blockUI.stop();
        console.error((error && error.stack) || error);
      });
  };

  function createPDFWindow(data) {
    let file = new Blob([data], {type: 'application/pdf'});
    let url = window.URL || window.webkitURL;
    if (window.navigator.msSaveOrOpenBlob) {
      // IE、Edge対応です
      window.navigator.msSaveOrOpenBlob(file, 'report.pdf');
    } else {
      let fileURL = url.createObjectURL(file);
      window.open(fileURL);
    }
  }
});
