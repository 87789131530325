app.constant('appConst',
  {
    'default': {
      AccountInfo: {
        thresholdActivityRate1: '45',
        thresholdActivityRate2: '55',
        thresholdActivityRate3: '60',
        thresholdActivityRate4: '65',
        thresholdActivityRate5: '100'
      }
    },
    settingListNameMap: {
      'ContactInfo': [{
        name: 'staffNames',
        label: 'ユーザ名',
        getDefault: (info) => {
          return (info || {})['name'] || '';
        }
      }],
      'AccountInfo': [{
        name: 'inseminators',
        label: '授精担当者',
        getDefault: (info) => {
          return '';
        },
        allowBlank: true
      }, {
        name: 'pregnancyAppraisers',
        label: '妊娠鑑定担当者',
        getDefault: (info) => {
          return '';
        },
        allowBlank: true
      }, {
        name: 'treatmentPersons',
        label: '治療担当者',
        getDefault: (info) => {
          return '';
        },
        allowBlank: true
      }, {
        name: 'vaccineTimings',
        label: 'ワクチンタイミングリスト',
        getDefault: (info) => {
          return '';
        },
        allowBlank: true
      }, {
        name: 'judgePregnantTimings',
        label: '妊娠鑑定タイミングリスト',
        getDefault: (info) => {
          return '';
        },
        allowBlank: true
      }, {
        name: 'producingAreas',
        label: '生産地リスト',
        getDefault: (info) => {
          return '';
        },
        allowBlank: true
      }, {
        name: 'producingFarmNames',
        label: '生産農場名リスト',
        getDefault: (info) => {
          return '';
        },
        allowBlank: true
      }, {
        name: 'raisingFarmNames',
        label: '育成農場名リスト',
        getDefault: (info) => {
          return '';
        },
        allowBlank: true
      }, {
        name: 'transporters',
        label: '運送会社リスト',
        getDefault: (info) => {
          return '';
        },
        allowBlank: true
      }, {
        name: 'buyers',
        label: '販売先リスト',
        getDefault: (info) => {
          return '';
        },
        allowBlank: true
      }, {
        name: 'introduceStates',
        label: '導入時状態',
        getDefault: (info) => {
          return '';
        },
        allowBlank: true
      }, {
        name: 'birthStates',
        label: '出生時状態',
        getDefault: (info) => {
          return '';
        },
        allowBlank: false
      }, {
        name: 'otherReproductionWorks',
        label: 'その他処置内容',
        getDefault: (info) => {
          return '';
        },
        allowBlank: true
      }]
    },

    eventTypes: {
      'alert': {
        name: 'アラート'
      },
      'mastitis': {
        name: '乳房炎',
        customConditionMatch: true,
        treatmentEvent: true,
        occurredAtName: '発生日',
        category: '治療',
        bulkEntry: {
          dateLabel: '治療日',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'occurredDate'},
            'occurredDate': {key: 'occurredDate', required: true, error: 'requiredOccurredDate', type: 'text', next: 'mastitisScore'},
            'mastitisDiagnosisResultOfBreastFl': {key: 'mastitisDiagnosisResultOfBreastFl', type: 'select', next: 'mastitisDiagnosisResultOfBreastFr'},
            'mastitisDiagnosisResultOfBreastFr': {key: 'mastitisDiagnosisResultOfBreastFr', type: 'select', next: 'mastitisDiagnosisResultOfBreastBl'},
            'mastitisDiagnosisResultOfBreastBl': {key: 'mastitisDiagnosisResultOfBreastBl', type: 'select', next: 'mastitisDiagnosisResultOfBreastBr'},
            'mastitisDiagnosisResultOfBreastBr': {key: 'mastitisDiagnosisResultOfBreastBr', type: 'select', next: 'workerName'},
            //'bacteriaKind': {key: 'bacteriaKind', type: 'select2', next: 'workerName' },
            'workerName': {key: 'workerName', type: 'select', next: 'medicineNames'},
            'medicineNames': {key: 'medicineNames', type: 'select2Multi', next: 'cowNo'}
          },
          options: {
            mastitisScore: [
              {label: '1：異常乳・ブツ・凝乳', value: '異常乳・ブツ・凝乳'},
              {label: '2：乳房異常・シコリ', value: '乳房異常・シコリ'},
              {label: '3：発熱・食欲不振', value: '発熱・食欲不振'}
            ],
            targetBreast: [
              {label: '1：左前', value: '左前'},
              {label: '2：右前', value: '右前'},
              {label: '3：左後', value: '左後'},
              {label: '4：右後', value: '右後'}
            ]
          }
        },
        default: {
          workerName: '',
          stopMilkingOfBreastFl: false,
          stopMilkingOfBreastFr: false,
          stopMilkingOfBreastBl: false,
          stopMilkingOfBreastBr: false,
          mastitisBacteriaOfBreastFl: null,
          mastitisBacteriaOfBreastFr: null,
          mastitisBacteriaOfBreastBl: null,
          mastitisBacteriaOfBreastBr: null,
          mastitisDiagnosisResultOfBreastFl: '',
          mastitisDiagnosisResultOfBreastFr: '',
          mastitisDiagnosisResultOfBreastBl: '',
          mastitisDiagnosisResultOfBreastBr: '',
          occurredAt: {
            date: true,
            time: false
          }
        },
        cowInfos: [{
          before: 'stopMilkingDateOfBreastFl',
          after: 'stopMilkingOfBreastFl',
          parse: (before, after) => {
            return (before !== null && before !== undefined);
          }
        }, {
          before: 'stopMilkingDateOfBreastFr',
          after: 'stopMilkingOfBreastFr',
          parse: (before, after) => {
            return (before !== null && before !== undefined);
          }
        }, {
          before: 'stopMilkingDateOfBreastBl',
          after: 'stopMilkingOfBreastBl',
          parse: (before, after) => {
            return (before !== null && before !== undefined);
          }
        }, {
          before: 'stopMilkingDateOfBreastBr',
          after: 'stopMilkingOfBreastBr',
          parse: (before, after) => {
            return (before !== null && before !== undefined);
          }
        }],
        operationHistory: {
          show: true,
          order: 2
        }
      },
      'lame': {
        name: '跛行・蹄病',
        treatmentEvent: true,
        occurredAtName: '発生日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '治療日',
          input: {
            'cowNo': {
              key: 'cowNo',
              required: true,
              error: 'requiredId',
              duplicate: 'duplicateId',
              type: 'text',
            },
            'locomotionScore': {
              key: 'locomotionScore',
              type: 'select',
            },
            'occurredDate': {
              key: 'occurredDate',
              required: true,
              error: 'requiredOccurredDate',
              type: 'text',
              next: 'lameAffectedLimb1'
            },
            'lameAffectedLimb1': {
              key: 'lameAffectedLimb1',
              type: 'select',
            },
            'lameAffectedPart1': {
              key: 'lameAffectedPart1',
              type: 'select',
            },
            'lameClawZones1': {
              key: 'lameClawZones1',
              type: 'select',
            },
            'lameCondition1': {
              key: 'lameCondition1',
              type: 'select',
            },
            'clawDiseaseName1': {
              key: 'clawDiseaseName1',
              type: 'select',
            },

            'lameAffectedLimb2': {
              key: 'lameAffectedLimb2',
              type: 'select',
            },
            'lameAffectedPart2': {
              key: 'lameAffectedPart2',
              type: 'select',
            },
            'lameClawZones2': {
              key: 'lameClawZones2',
              type: 'select',
            },
            'lameCondition2': {
              key: 'lameCondition2',
              type: 'select',
            },
            'clawDiseaseName2': {
              key: 'clawDiseaseName2',
              type: 'select',
            },

            'lameAffectedLimb3': {
              key: 'lameAffectedLimb3',
              type: 'select',
            },
            'lameAffectedPart3': {
              key: 'lameAffectedPart3',
              type: 'select',
            },
            'lameClawZones3': {
              key: 'lameClawZones3',
              type: 'select',
            },
            'lameCondition3': {
              key: 'lameCondition3',
              type: 'select',
            },
            'clawDiseaseName3': {
              key: 'clawDiseaseName3',
              type: 'select',
            },

            'lameAffectedLimb4': {
              key: 'lameAffectedLimb4',
              type: 'select',
            },
            'lameAffectedPart4': {
              key: 'lameAffectedPart4',
              type: 'select',
            },
            'lameClawZones4': {
              key: 'lameClawZones4',
              type: 'select',
            },
            'lameCondition4': {
              key: 'lameCondition4',
              type: 'select',
            },
            'clawDiseaseName4': {
              key: 'clawDiseaseName4',
              type: 'select',
            },
            'workerName': {
              key: 'workerName',
              type: 'select2',
            },
            'medicineNames': {
              key: 'medicineNames',
              type: 'select2Multi',
            },
            'bodyTemperature': {
              key: 'bodyTemperature',
              type: 'text',
            },
            'comment': {
              key: 'comment',
              type: 'text',
            }
          },
          options: {
            targetHoof: [
              {label: '1：感染：注意', value: '0'},
              {label: '2：感染：悪い', value: '1'},
              {label: '3：感染：重篤', value: '2'},
              {label: '4：非感染：注意', value: '3'},
              {label: '5：非感染：悪い', value: '4'},
              {label: '6：非感染：重篤', value: '5'},
              {label: '7：関節傷害：注意', value: '6'},
              {label: '8：関節傷害：悪い', value: '7'},
              {label: '9：関節傷害：重篤', value: '8'}
            ]
          }
        },
        default: {
          workerName: '',
          locomotionScore: '',
          occurredAt: {
            date: true,
            time: false
          }
        },
        operationHistory: {
          show: true,
          order: 6
        }
      },
      'breedingDifficulty': {
        name: '繁殖障害',
        treatmentEvent: true,
        occurredAtName: '発生日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '治療日',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'breedingDifficulty'},
            'breedingDifficulty': {key: 'breedingDifficulty', type: 'select2Multi', next: 'diagnosisResultOfOvaryL'},
            'diagnosisResultOfOvaryL': {key: 'diagnosisResultOfOvaryL', type: 'select', next: 'diagnosisResultOfOvaryR'},
            'diagnosisResultOfOvaryR': {key: 'diagnosisResultOfOvaryR', type: 'select', next: 'uterusDiagnosisResult'},
            'uterusDiagnosisResult': {key: 'uterusDiagnosisResult', type: 'select', next: 'workerName'},
            'workerName': {key: 'workerName', type: 'select', next: 'medicineNames'},
            'medicineNames': {key: 'medicineNames', type: 'select2Multi', next: 'cowNo'}
          },
          options: {
            breedingDifficulty: [
              {label: '1：卵巣静止・萎縮：軽度', value: '0'},
              {label: '2：卵巣静止・萎縮：中度', value: '1'},
              {label: '3：卵巣静止・萎縮：重度', value: '2'},
              {label: '4：卵胞嚢腫：軽度', value: '3'},
              {label: '5：卵胞嚢腫：中度', value: '4'},
              {label: '6：卵胞嚢腫：重度', value: '5'},
              {label: '7：黄体遺残：軽度', value: '6'},
              {label: '8：黄体遺残：中度', value: '7'},
              {label: '9：黄体遺残：重度', value: '8'},
              {label: '10：子宮炎：軽度', value: '9'},
              {label: '11：子宮炎：中度', value: '10'},
              {label: '12：子宮炎：重度', value: '11'},
              {label: '13：子宮蓄膿症：軽度', value: '12'},
              {label: '14：子宮蓄膿症：中度', value: '13'},
              {label: '15：子宮蓄膿症：重度', value: '14'},
            ]
          }
        },
        default: {
          workerName: '',
          difficultiesOvaryStill: '',
          difficultiesOvaryCystic: '',
          difficultiesRemnant: '',
          difficultiesMetritis: '',
          difficultiesPyometra: '',
          diagnosisResultOfOvaryL: '良好',
          diagnosisResultOfOvaryR: '良好',
          uterusDiagnosisResult: '良好',
          occurredAt: {
            date: true,
            time: false
          }
        },
        operationHistory: {
          show: true,
          order: 3
        }
      },
      'perinatalDifficulty': {
        name: '周産病・代謝病',
        treatmentEvent: true,
        occurredAtName: '発生日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '治療日',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'occurredDate'},
            'occurredDate': {key: 'occurredDate', required: true, error: 'requiredOccurredDate', type: 'text', next: 'mastitisScore'},
            'condition': {key: 'condition', type: 'select2Multi', next: 'severity'},
            'severity': {key: 'severity', type: 'select', next: 'workerName'},
            'workerName': {key: 'workerName', type: 'select', next: 'medicineNames'},
            'medicineNames': {key: 'medicineNames', type: 'select2Multi', next: 'cowNo'}
          },
          options: {
            condition: [
              {label: '1:ケトーシス', value: 'Ketosis'},
              {label: '2:脂肪肝', value: 'FattyLiver'},
              {label: '3:第四胃変位', value: 'AbomasumDisplacement'},
              {label: '4:低カルシウム', value: 'Hypocalcium'},
              {label: '5:アシドーシス', value: 'Acidosis'},
              {label: '6:子宮炎', value: 'Metritis'},
              {label: '7:ダウナー症候群', value: 'Downer'},
              {label: '8:食滞', value: 'StagnantFood'},
              {label: '9:胎盤停滞', value: 'Placenta'},
              {label: '10:鼓張症', value: 'Tympanites'},
              {label: '11:ビタミンA欠乏症', value: 'VitaminA', beefOnly: true},
              {label: '12:尿石', value: 'Urolithiasis', beefOnly: true}
            ],
            severity: [
              {label: '', value: ''},
              {label: '1:軽症', value: '軽'},
              {label: '2:中症', value: '中'},
              {label: '3:重症', value: '重'}
            ]
          }
        },
        default: {
          workerName: '',
          difficultiesKetosis: '',
          difficultiesFattyLiver: '',
          difficultiesAbomasumDisplacement: '',
          difficultiesMetritis: '',
          difficultiesDowner: '',
          difficultiesStagnantFood: '',
          difficultiesHypocalcium: '',
          difficultiesAcidosis: '',
          difficultiesPlacenta: '',
          difficultiesVitaminA: '',
          difficultiesUrolithiasis: '',
          difficultiesTympanites: '',
          occurredAt: {
            date: true,
            time: false
          }
        },
        operationHistory: {
          show: true,
          order: 4
        }
      },
      'injury': {
        name: '外傷',
        treatmentEvent: true,
        occurredAtName: '発生日',
        customConditionMatch: true,
        default: {
          workerName: '',
          difficultiesHipDislocation: '',
          difficultiesTeatInjury: '',
          difficultiesOther: '',
          occurredAt: {
            date: true,
            time: false
          }
        },
        operationHistory: {
          show: true,
          order: 5
        }
      },
      'infect': {
        name: '感染症',
        treatmentEvent: true,
        occurredAtName: '発生日',
        customConditionMatch: true,
        default: {
          workerName: '',
          difficultiesPneumonia: '',
          difficultiesDiarrhea: '',
          difficultiesSkinDisease: '',
          difficultiesOther: '',
          occurredAt: {
            date: true,
            time: false
          }
        },
        bulkEntry: {
          dateLabel: '治療日',
          input: {
            'cowNo': {
              key: 'cowNo',
              required: true,
              error: 'requiredId',
              duplicate: 'duplicateId',
              type: 'text',
              next: 'occurredDate'
            },
            'occurredDate': {
              key: 'occurredDate',
              required: true,
              error: 'requiredOccurredDate',
              type: 'text',
              next: 'difficultiesPneumonia'
            },
            'difficultiesPneumonia': {
              key: 'difficultiesPneumonia',
              type: 'select',
            },
            'difficultiesDiarrhea': {
              key: 'difficultiesDiarrhea',
              type: 'select',
            },
            'difficultiesSkinDisease': {
              key: 'difficultiesSkinDisease',
              type: 'select',
            },
            'difficultiesOther': {
              key: 'difficultiesOther',
              type: 'select',
            },
            'workerName': {
              key: 'workerName',
              type: 'select',
              next: 'medicineNames'
            },
            'medicineNames': {
              key: 'medicineNames',
              type: 'select2Multi',
              next: 'cowNo'
            }
          },
          options: {
            difficulties: [
              {label: '症状なし', value: ''},
              {label: '軽', value: '軽'},
              {label: '中', value: '中'},
              {label: '重', value: '重'}
            ],
            otherDifficulties: [
              {label: '軽', value: '軽'},
              {label: '中', value: '中'},
              {label: '重', value: '重'}
            ]
          }
        },
        operationHistory: {
          show: true,
          order: 7
        }
      },
      'otherDifficulty': {
        name: 'その他',
        treatmentEvent: true,
        occurredAtName: '発生日',
        default: {
          workerName: '',
          difficultiesOther: '',
          occurredAt: {
            date: true,
            time: false
          }
        },
        bulkEntry: {
          dateLabel: '治療日',
          input: {
            'cowNo': {
              key: 'cowNo',
              required: true,
              error: 'requiredId',
              duplicate: 'duplicateId',
              type: 'text',
              next: 'occurredDate'
            },
            'occurredDate': {
              key: 'occurredDate',
              required: true,
              error: 'requiredOccurredDate',
              type: 'text',
              next: 'difficultiesOther'
            },
            'difficultiesOther': {
              key: 'difficultiesOther',
              type: 'select',
            },
            'workerName': {
              key: 'workerName',
              type: 'select',
              next: 'medicineNames'
            },
            'medicineNames': {
              key: 'medicineNames',
              type: 'select2Multi',
              next: 'cowNo'
            }
          },
          options: {
            difficulties: [
              {label: '症状なし', value: ''},
              {label: '軽', value: '軽'},
              {label: '中', value: '中'},
              {label: '重', value: '重'}
            ],
            otherDifficulties: [
              {label: '軽', value: '軽'},
              {label: '中', value: '中'},
              {label: '重', value: '重'}
            ],
          }
        },
        operationHistory: {
          show: true,
          order: 8
        }
      },
      'observation': {
        name: '個体観察',
        occurredAtName: '観察日',
        customConditionMatch: true,
        default: {
          eyeCondition: '良好',
          earCondition: '良好',
          muzzleCondition: '良好',
          hairCondition: '良好',
          hipCondition: '良好',
          bodyCondition: '良好',
          fecesCondition: '良好',
          urineCondition: '良好',
          occurredAt: {
            date: true,
            time: true
          }
        },
        operationHistory: {
          show: true,
          order: 22
        }
      },
      'wean': {
        name: '離乳',
        occurredAtName: '離乳日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '離乳日',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: ''}
          }
        },
        default: {
          occurredAt: {
            date: true,
            time: true
          }
        },
        operationHistory: {
          show: true,
          order: 23
        }
      },
      'dehorn': {
        name: '除角',
        occurredAtName: '除角日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '除角日',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'dehornMethod'},
            'dehornMethod': {key: 'cowNo', type: 'select', next: 'cowNo'}
          }
        },
        default: {
          occurredAt: {
            date: true,
            time: true
          }
        },
        operationHistory: {
          show: true,
          order: 24
        }
      },
      'castration': {
        name: '去勢',
        occurredAtName: '去勢日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '去勢日',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'castrationMethod'},
            'castrationMethod': {key: 'cowNo', type: 'select', next: 'cowNo'}
          }
        },
        default: {
          occurredAt: {
            date: true,
            time: true
          }
        },
        operationHistory: {
          show: true,
          order: 25
        }
      },
      'freshCheck': {
        name: 'フレッシュチェック',
        occurredAtName: '日付',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '日付',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'diagnosisResultOfOvaryL'},
            'diagnosisResultOfOvaryL': {key: 'diagnosisResultOfOvaryL', type: 'select', next: 'diagnosisResultOfOvaryR'},
            'diagnosisResultOfOvaryR': {key: 'diagnosisResultOfOvaryR', type: 'select', next: 'uterusDiagnosisResult'},
            'uterusDiagnosisResult': {key: 'uterusDiagnosisResult', type: 'select', next: 'bcs'},
            'bcs': {key: 'bcs', type: 'text', next: 'otherReproductionWork'},
            'otherReproductionWork': {key: 'otherReproductionWork', type: 'select', next: 'freshCheckResult'},
            'freshCheckResult': {key: 'freshCheckResult', type: 'select', next: 'cowNo'}
          }
        },
        default: {
          diagnosisResultOfOvaryL: '良好',
          diagnosisResultOfOvaryR: '良好',
          uterusDiagnosisResult: '良好',
          freshCheckResult: '良好',
          occurredAt: {
            date: true,
            time: false
          }
        },
        continue: {
          eventType: 'breedingDifficulty',
          text: '引き続き繁殖障害イベントの登録を行いますか',
          conditions: [{
            name: 'freshCheckResult',
            equals: ['注意', '悪い']
          }],
          inherit: [{
            before: 'occurredAt',
            after: 'occurredAt'
          }, {
            before: 'occurredAt',
            after: 'occurredDiseaseDate'
          }, {
            before: 'diagnosisResultOfOvaryL',
            after: 'diagnosisResultOfOvaryL'
          }, {
            before: 'diagnosisResultOfOvaryR',
            after: 'diagnosisResultOfOvaryR'
          }, {
            before: 'uterusDiagnosisResult',
            after: 'uterusDiagnosisResult'
          }]
        },
        operationHistory: {
          show: true,
          order: 9
        }
      },
      'hatsujo': {
        name: '発情',
        occurredAtName: '発情日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '発情日',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'cowNo'}
          }
        },
        default: {
          occurredAt: {
            date: true,
            time: true
          }
        },
        operationHistory: {
          show: true,
          order: 10
        }
      },
      'tanetsuke': {
        name: '種付',
        occurredAtName: '種付日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '種付日',
          common: {
            'tanetsukeMethod': {key: 'tanetsukeMethod', type: 'label'}
          },
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'masterSpermNo'},
            'masterSpermNo': {key: 'masterSpermNo', type: 'select', next: 'workerName'},
            'workerName': {key: 'workerName', type: 'select', next: 'inseminationCode'},
            'inseminationCode': {key: 'inseminationCode', type: 'select', next: 'otherReproductionWork'},
            'otherReproductionWork': {key: 'otherReproductionWork', type: 'select', next: 'cowNo'}
          }
        },
        default: {
          tanetsukeMethod: '人工授精',
          occurredAt: {
            date: true,
            time: true
          }
        },
        operationHistory: {
          show: true,
          order: 12
        }
      },
      'ninshinkantei': {
        name: '妊娠鑑定',
        intervalAfterBreedingDate: 14,
        occurredAtName: '鑑定日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '鑑定日',
          common: {
            'workerName': {key: 'workerName', type: 'select'}
          },
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'judgePregnantTiming'},
            'judgePregnantTiming': {key: 'judgePregnantTiming', type: 'select', next: 'otherReproductionWork'},
            'otherReproductionWork': {key: 'otherReproductionWork', type: 'select', next: 'ninshinkanteiResult'},
            'ninshinkanteiResult': {key: 'ninshinkanteiResult', type: 'select', next: 'cowNo'}
          }
        },
        default: {
          occurredAt: {
            date: true
          }
        },
        operationHistory: {
          show: true,
          order: 13
        }
      },
      'abort': {
        name: '流産',
        occurredAtName: '流産日',
        default: {
          afterCowState: 'フレッシュ',
          occurredAt: {
            date: true,
            time: true
          }
        },
        operationHistory: {
          show: true,
          order: 14
        }
      },
      'bunben': {
        name: '分娩',
        occurredAtName: '分娩日',
        customConditionMatch: true,
        default: {
          childNumber: '1',
          calfs: [{
            birthResult: '出産',
            gender: 'メス'
          }],
          calvingDifficulty: '1',
          occurredAt: {
            date: true,
            time: true
          }
        },
        continue: {
          eventType: 'perinatalDifficulty',
          text: '引き続き周産病・代謝病イベントの登録を行いますか',
          inherit: [{
            before: 'occurredAt',
            after: 'occurredDiseaseDate'
          }, {
            before: 'occurredAt',
            after: 'treatmentDiseaseDate'
          }, {
            before: 'selectedHour',
            after: 'selectedHour'
          }, {
            before: 'selectedMinute',
            after: 'selectedMinute'
          }]
        },
        operationHistory: {
          show: true,
          order: 15
        }
      },
      'fattening': {
        name: '肥育',
        occurredAtName: '肥育開始日',
        customConditionMatch: true,
        default: {
          occurredAt: {
            date: true,
            time: false
          }
        },
        operationHistory: {
          show: true,
          order: 26
        },
        bulkEntry: {
          dateLabel: '肥育開始日',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'occurredAt'},
            'occurredAt': {key: 'occurredAt', type: 'text', next: 'comment'},
            'comment': {key: 'comment', type: 'text'}
          }
        },
      },
      'measurement': {
        name: '体重測定',
        occurredAtName: '測定日',
        customConditionMatch: false,
        default: {
          occurredAt: {
            date: true,
            time: true
          }
        },
        operationHistory: {
          show: true,
          order: 27
        }
      },
      'sagyomemo': {
        name: '作業メモ',
        occurredAtName: '日付',
        customConditionMatch: true,
        default: {
          occurredAt: {
            date: true,
            time: true
          }
        },
        operationHistory: {
          show: true,
          order: 20
        },
        bulkEntry: {
          dateLabel: '日付',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'tioTitle'},
            'tioTitle': {key: 'tioTitle', required: false}
          }
        }
      },
      'nisshi': {
        name: '日誌',
        operationHistory: {
          show: true,
          order: 1
        }
      },
      'hormoneProgram': {
        name: 'ホルモンプログラム',
        occurredAtName: '処置日',
        nextTreat: true,
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '処置日',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'occurredAt'},
            'occurredAt': {key: 'occurredAt', type: 'text', next: 'hour'},
            'hour': {key: 'hour', type: 'select', next: 'minutes'},
            'minutes': {key: 'minutes', type: 'select', next: 'hormoneProgramName'},
            'hormoneProgramName': {key: 'hormoneProgramName', type: 'select', next: 'hormoneCode'},
            'hormoneCode': {key: 'hormoneCode', type: 'select', next: 'cowNo'},
          }
        },
        default: {
          occurredAt: {
            date: true,
            time: true
          }
        },
        operationHistory: {
          show: true,
          order: 11
        }
      },
      'vaccine': {
        name: 'ワクチン',
        occurredAtName: '投与日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '投与日',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'vaccineName'},
            'vaccineName': {key: 'vaccineName', type: 'select', next: 'vaccineTiming'},
            'vaccineTiming': {key: 'vaccineTiming', type: 'select', next: 'cowNo'}
          }
        },
        default: {
          occurredAt: {
            date: true,
            time: false
          }
        },
        operationHistory: {
          show: true,
          order: 19
        }
      },
      'kannyu': {
        name: '乾乳',
        occurredAtName: '乾乳日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '乾乳日',
          common: {
            'moveToCowGroupId': {key: 'moveToCowGroupId', type: 'select'},
            'moveToPen': {key: 'moveToPen', type: 'select'}
          },
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'selectedDryPeriod'},
            'selectedDryPeriod': {key: 'selectedDryPeriod', required: true, error: 'requiredSelectedDryPeriod', type: 'select', next: 'bcs'},
            'bcs': {key: 'bcs', type: 'text', next: 'cowNo'}
          }
        },
        default: {
          workerName: '',
          occurredAt: {
            date: true
          }
        },
        operationHistory: {
          show: true,
          order: 18
        }
      },
      'touta': {
        name: '出荷',
        occurredAtName: '出荷日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '出荷日',
          common: {
            //'salesDate': {key: 'salesDate', type: 'text' },
            'buyer': {key: 'buyer', type: 'select'}
          },
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'expelledReason'},
            'expelledReason': {key: 'expelledReason', type: 'select', next: 'shipmentNo'},
            'shipmentNo': {key: 'shipmentNo', type: 'text', next: 'producingArea'},
            'producingArea': {key: 'producingArea', type: 'select', next: 'producingFarmName'},
            'producingFarmName': {key: 'producingFarmName', type: 'select', next: 'salesPrice'},
            'salesPrice': {key: 'salesPrice', type: 'text', next: 'cowNo'}
          }
        },
        default: {
          workerName: '',
          occurredAt: {
            date: true
          }
        },
        operationHistory: {
          show: true,
          order: 28
        }
      },
      'heishi': {
        name: 'へい死',
        occurredAtName: 'へい死日',
        bulkEntry: {
          dateLabel: 'へい死日',
          common: {
            'expelledReason': {key: 'expelledReason', type: 'label'}
          },
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'deathReason'},
            'deathReason': {key: 'deathReason', type: 'select', next: 'cowNo'}
          }
        },
        default: {
          workerName: '',
          occurredAt: {
            date: true
          }
        },
        operationHistory: {
          show: true,
          order: 29
        }
      },
      'hoofTrim': {
        name: '削蹄',
        occurredAtName: '削蹄日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '削蹄日',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'cowNo'}
          }
        },
        default: {
          workerName: '',
          occurredAt: {
            date: true
          }
        },
        operationHistory: {
          show: true,
          order: 21
        }
      },
      'gyugunidou': {
        name: '牛群移動',
        occurredAtName: '移動日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '移動日',
          common: {
            'moveToCowGroupId': {key: 'moveToCowGroupId', type: 'select'},
            'moveToPen': {key: 'moveToPen', type: 'select'}
          },
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'cowNo'}
          }
        },
        default: {
          workerName: '',
          occurredAt: {
            date: true
          }
        },
        operationHistory: {
          show: true,
          order: 17
        }
      },
      'shukkateishikaijo': {
        name: '出荷停止解除',
        occurredAtName: '出荷再開日',
        customConditionMatch: true,
        default: {
          workerName: '',
          occurredAt: {
            date: true
          }
        },
        continue: {
          eventType: 'gyugunidou',
          text: '引き続き牛群移動イベントの登録を行いますか'
        }
      },
      'carcass': {
        name: '枝肉成績',
        occurredAtName: '販売日',
        customConditionMatch: true,
        bulkEntry: {
          dateLabel: '販売日',
          input: {
            'slaughterNo': {key: 'slaughterNo', type: 'text', next: 'cowUid'},
            'cowUid': {key: 'cowUid', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'grade'},
            'yieldGrade': {key: 'yieldGrade', required: true, error: 'requiredYield', type: 'select', next: 'meetGrade'},
            'meetGrade': {key: 'meetGrade', required: true, error: 'requiredMeat', type: 'select', next: 'beforeSlaughterWeight'},
            'beforeSlaughterWeight': {key: 'beforeSlaughterWeight', type: 'select', next: 'dressedCarcassWeightOfL'},
            'dressedCarcassWeightOfL': {key: 'dressedCarcassWeightOfL', type: 'select', next: 'dressedCarcassWeightOfR'},
            'dressedCarcassWeightOfR': {key: 'dressedCarcassWeightOfR', type: 'select', next: 'loinArea'},
            'loinArea': {key: 'loinArea', type: 'select', next: 'ribsThickness'},
            'ribsThickness': {key: 'ribsThickness', type: 'select', next: 'subcutaneousFat'},
            'subcutaneousFat': {key: 'subcutaneousFat', type: 'select', next: 'yieldBaseValue'},
            'yieldBaseValue': {key: 'yieldBaseValue', type: 'select', next: 'bmsNo'},
            'bmsNo': {key: 'bmsNo', type: 'select', next: 'marblingGrade'},
            'marblingGrade': {key: 'marblingGrade', type: 'select', next: 'bcsNo'},
            'bcsNo': {key: 'bcsNo', type: 'select', next: 'gloss'},
            'gloss': {key: 'gloss', type: 'select', next: 'bcsAndGlossGrade'},
            'bcsAndGlossGrade': {key: 'bcsAndGlossGrade', type: 'select', next: 'tight'},
            'tight': {key: 'tight', type: 'select', next: 'texture'},
            'texture': {key: 'texture', type: 'select', next: 'tightAndTextureGrade'},
            'tightAndTextureGrade': {key: 'tightAndTextureGrade', type: 'select', next: 'bfsNo'},
            'bfsNo': {key: 'bfsNo', type: 'select', next: 'fatLuster'},
            'fatLuster': {key: 'fatLuster', type: 'select', next: 'bfsAndFatLusterGrade'},
            'bfsAndFatLusterGrade': {key: 'bfsAndFatLusterGrade', type: 'select', next: 'fault'},
            'fault': {key: 'fault', type: 'select', next: 'otherFault'},
            'otherFault': {key: 'otherFault', type: 'select', next: 'otherFaultCorrection'},
            'otherFaultCorrection': {key: 'otherFaultCorrection', type: 'select', next: 'dressedCarcassUnitPrice'},
            'dressedCarcassUnitPrice': {key: 'dressedCarcassUnitPrice', type: 'select', next: 'dressedCarcassSalesPrice'},
            'dressedCarcassSalesPrice': {key: 'dressedCarcassSalesPrice', type: 'select', next: 'totalDressedCarcassWeight'},
            'totalDressedCarcassWeight': {key: 'totalDressedCarcassWeight', type: 'text', next: 'visceralDestruction'},
            'visceralDestruction': {key: 'visceralDestruction', type: 'select2Multi', next: 'cowNo'}
          }
        },
        default: {
          occurredAt: {
            date: true
          }
        },
        operationHistory: {
          show: true,
          order: 30
        }
      },
      'lameness': {
        name: '跛行',
        occurredAtName: '発生日',
        customConditionMatch: false,
        default: {
          locomotionScore: '3',
          lamenessAffectedLimbFl: false,
          lamenessAffectedLimbFr: false,
          lamenessAffectedLimbBl: false,
          lamenessAffectedLimbBr: false,
          lamenessAffectedPartFl: '',
          lamenessAffectedPartFr: '',
          lamenessAffectedPartBl: '',
          lamenessAffectedPartBr: '',
          workerName: '',
          occurredAt: {
            date: true
          }
        },
        continue: {
          eventType: 'lame',
          text: '引き続き跛行・蹄病イベントの登録を行いますか',
          inherit: [{
            before: 'occurredAt',
            after: 'occurredAt'
          }, {
            before: 'occurredAt',
            after: 'occurredDiseaseDate'
          }]
        }
      },
      'doNotBreed': {
        name: '繁殖除外',
        occurredAtName: '繁殖除外日',
        default: {
          workerName: '',
          occurredAt: {
            date: true
          }
        },
        operationHistory: {
          show: true,
          order: 16
        }
      },
      'embryo_recovery': {
        name: '採卵',
        occurredAtName: '採卵日',
        nextTreat: false,
        default: {
          workerName: '',
          occurredAt: {
            date: true
          }
        },
        operationHistory: {
          show: true,
          order: 16
        },
        bulkEntry: {
          dateLabel: '採卵日',
          input: {
            'cowNo': {key: 'cowNo', required: true, error: 'requiredId', duplicate: 'duplicateId', type: 'text', next: 'occurredDate'},
            'embryoRecoveryRankA': {key: 'embryoRecoveryRankA', type: 'input', next: 'embryoRecoveryRankA'},
            'embryoRecoveryRankB': {key: 'embryoRecoveryRankB', type: 'input', next: 'embryoRecoveryRankB'},
            'embryoRecoveryRankC': {key: 'embryoRecoveryRankC', type: 'input', next: 'embryoRecoveryRankC'},
            'embryoRecoveryRankD': {key: 'embryoRecoveryRankD', type: 'input', next: 'embryoRecoveryRankD'},
            'embryoRecoveryRankUnfertilized': {key: 'embryoRecoveryRankUnfertilized', type: 'input', next: 'embryoMasterRegistered'},
            'embryoMasterRegistered': {key: 'embryoMasterRegistered', type: 'input', next: 'workerName'},
            'workerName': {key: 'workerName', type: 'select', next: 'masterSpermNo'},
            'masterSpermId': {key: 'masterSpermId', type: 'select', required: true, error: 'requiredMasterSpermId'},
            'nextCowState': {key: 'nextCowState', type: 'nextCowState'}
          },
        }
      },
      'dysstasia': {
        name: '起立困難',
      }
    }
  }
);
