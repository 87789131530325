class MobileCalvingSensorUnequipModalController {
  constructor(
    $timeout,
    $modalInstance,
    params,
    blockUI,
    CowCalvingSensorHistoryAPI
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$modalInstance = $modalInstance;
    this.params = params;
    this.blockUI = blockUI;
    this.CowCalvingSensorHistoryAPI = CowCalvingSensorHistoryAPI;

    this.init();
  }

  init() {
    this.invalidEndDate = false;
    this.errorMessage = {};
    this.model = angular.copy([this.params.cow]).map((cow) => {
      cow.startDateDisplay = DateUtil.toYYYYMMDD(cow.startDate);
      cow.endDate = DateUtil.toMSec(DateUtil.today());
      cow.sensorNumber = CowCalvingSensorHistory.splitCalvingSensorNumber(cow.calvingSensorNumber);

      return cow;
    })[0];
  }

  update() {
    const params = [{
      id: this.model.cowCalvingSensorHistoryId,
      endDate: this.model.endDate
    }];

    this.errorMessage = {};
    this.CowCalvingSensorHistoryAPI.bulkUnequip(params).then(() => {
      this.blockUI.done(() => {
        this.blockUI.start('分娩センサーの解除が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
        }, 1000);
      });
      this.blockUI.stop();
      this.$modalInstance.close();
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  onChangeEndDate() {
    this.invalidEndDate = false;
    this.errorMessage.endDate = {};

    if (this.model.startDate > this.model.endDate) {
      this.invalidEndDate = true;
      this.errorMessage.endDate[1] = '解除日が適用日より前の日付になっています。';
    }
  }

  disabledUpdate() {
    if (!(DateUtil.isValidDate(this.model.endDate) &&
        DateUtil.includedPermittedPeriod(this.model.endDate))) {
      return true;
    } else if (this.invalidEndDate) {
      return true;
    }

    return false;
  }
}

app.controller('MobileCalvingSensorUnequipModalController', MobileCalvingSensorUnequipModalController);
