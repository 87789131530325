class CoolPesconApi {
  constructor(UmPlatformPartnerApi) {
    'ngInject';
    this.UmPlatformPartnerApi = UmPlatformPartnerApi;
  }

  account(beginUnixtime = null, endUnixtime = null) {
    const conditions = [];
    if (beginUnixtime) conditions.push(`begin=${beginUnixtime}`);
    if (endUnixtime) conditions.push(`end=${endUnixtime}`);

    let path = '/coolpescon/account';
    if (conditions.length) path += `?${conditions.join('&')}`;

    return this.UmPlatformPartnerApi.get(path);
  }
}

app.service('CoolPesconApi', CoolPesconApi);
