// eslint-disable-next-line no-unused-vars
class LactationPerformanceMaps {
  static tab(isDhiLinkage, hasDhiDates) {
    return [{
      key: 'milkDailyAnalysis',
      label: '乳量分析',
      visible: true
    }, {
      key: 'componentAnalysis',
      label: '成分分析',
      visible: isDhiLinkage && hasDhiDates
    }, {
      key: 'somaticCellAnalysis',
      label: '体細胞分析',
      visible: isDhiLinkage && hasDhiDates
    }];
  }

  static dataType() {
    return [{
      key: 'dhi',
      label: '検定'
    }, {
      key: 'milking',
      label: '乳量連携'
    }];
  }

  static interval() {
    return [{
      key: 1,
      label: '1日'
    }, {
      key: 7,
      label: '7日'
    }, {
      key: 30,
      label: '30日'
    }];
  }

  static chartConfig(isDhiLinkage, hasDhiDates) {
    return {
      milkDailyAnalysis: [{
        chart: {
          item: 'milk_daily',
          api: 'cow',
          label: '乳量分布'
        },
        visible: {
          dataType: isDhiLinkage,
          selectReferenceDate: isDhiLinkage,
          inputReferenceDate: !isDhiLinkage,
          interval: !isDhiLinkage,
          rangeAxis: true
        },
        defaultSetting: {
          dataType: isDhiLinkage ? 'dhi' : 'milking',
          selectReferenceDate: {
            ago: 0
          },
          inputReferenceDate: {
            daysAgo: 1
          },
          interval: 30
        },
        axis: {
          x: {
            min: 0,
            max: 420,
            step: 1,
            label: {
              text: '搾乳日数'
            }
          },
          y: {
            min: 0,
            step: 5,
            label: {
              text: '乳量(kg)'
            }
          }
        }
      }, {
        chart: {
          item: 'milk_daily',
          api: 'parity',
          label: '乳量推移'
        },
        visible: {
          dataType: isDhiLinkage,
          selectRangeDate: isDhiLinkage,
          inputRangeDate: !isDhiLinkage,
          interval: !isDhiLinkage
        },
        defaultSetting: {
          dataType: isDhiLinkage ? 'dhi' : 'milking',
          selectRangeDate: {
            start: {
              ago: 12
            },
            end: {
              ago: 0
            }
          },
          inputRangeDate: {
            start: {
              monthsAgo: 13
            },
            end: {
              daysAgo: 1
            }
          },
          interval: 30
        },
        axis: {
          x: {
            label: {
              text: {
                dhi: '検定日',
                milking: '日付'
              }
            }
          },
          y: {
            label: {
              text: '乳量(kg)'
            }
          }
        }
      }, {
        chart: {
          item: 'milk_daily',
          api: 'farm',
          label: '乳量Xkg以上の割合推移'
        },
        visible: {
          dataType: isDhiLinkage,
          threshold: true,
          selectRangeDate: isDhiLinkage,
          inputRangeDate: !isDhiLinkage,
          interval: !isDhiLinkage
        },
        defaultSetting: {
          dataType: isDhiLinkage ? 'dhi' : 'milking',
          threshold: 35,
          selectRangeDate: {
            start: {
              ago: 12
            },
            end: {
              ago: 0
            }
          },
          inputRangeDate: {
            start: {
              monthsAgo: 13
            },
            end: {
              daysAgo: 1
            }
          },
          interval: 30
        },
        label: {
          threshold: '閾値(kg)'
        },
        names: {
          lessThanThreshold: ['乳量', 'kg未満'],
          aboveThreshold: ['乳量', 'kg以上']
        },
        axis: {
          x: {
            label: {
              text: {
                dhi: '検定日',
                milking: '日付'
              }
            }
          },
          y: {
            label: {
              text: '%'
            }
          }
        }
      }, {
        chart: {
          item: 'milk_305em',
          api: 'cow',
          label: '305日補正乳量分布',
          hidden: !(isDhiLinkage && hasDhiDates)
        },
        visible: {
          selectReferenceDate: true,
          rangeAxis: true
        },
        defaultSetting: {
          dataType: 'dhi',
          selectReferenceDate: {
            ago: 0
          }
        },
        axis: {
          x: {
            min: 0,
            max: 420,
            step: 1,
            label: {
              text: '搾乳日数'
            }
          },
          y: {
            min: 5000,
            step: 1,
            label: {
              text: '305日補正乳量(kg)'
            }
          }
        }
      }, {
        chart: {
          item: 'milk_305em',
          api: 'farm',
          label: '305日補正乳量Xkg以上の割合推移',
          hidden: !(isDhiLinkage && hasDhiDates)
        },
        visible: {
          threshold: true,
          selectRangeDate: true
        },
        defaultSetting: {
          dataType: 'dhi',
          threshold: 10000,
          selectRangeDate: {
            start: {
              ago: 12
            },
            end: {
              ago: 0
            }
          }
        },
        label: {
          threshold: '閾値(kg)'
        },
        names: {
          lessThanThreshold: ['305日補正乳量', 'kg未満'],
          aboveThreshold: ['305日補正乳量', 'kg以上']
        },
        axis: {
          x: {
            label: {
              text: '検定日'
            }
          },
          y: {
            label: {
              text: '%'
            }
          }
        }
      }],
      componentAnalysis: [{
        chart: {
          item: 'fat_percent',
          api: 'cow',
          label: '乳脂肪率分布'
        },
        visible: {
          selectReferenceDate: true,
          rangeAxis: true
        },
        defaultSetting: {
          dataType: 'dhi',
          selectReferenceDate: {
            ago: 0
          }
        },
        axis: {
          x: {
            min: 0,
            max: 420,
            step: 1,
            label: {
              text: '搾乳日数'
            }
          },
          y: {
            min: 0,
            step: 1,
            label: {
              text: '乳脂肪率(%)'
            }
          }
        }
      }, {
        chart: {
          item: 'prot_percent',
          api: 'cow',
          label: '乳蛋白率分布'
        },
        visible: {
          selectReferenceDate: true,
          rangeAxis: true
        },
        defaultSetting: {
          dataType: 'dhi',
          selectReferenceDate: {
            ago: 0
          }
        },
        axis: {
          x: {
            min: 0,
            max: 420,
            step: 1,
            label: {
              text: '搾乳日数'
            }
          },
          y: {
            min: 0,
            step: 1,
            label: {
              text: '乳蛋白率(%)'
            }
          }
        }
      }, {
        chart: {
          item: 'pf_ratio',
          api: 'cow',
          label: 'PF比(乳脂率/乳蛋白率)分布'
        },
        visible: {
          selectReferenceDate: true,
          rangeAxis: true
        },
        defaultSetting: {
          dataType: 'dhi',
          selectReferenceDate: {
            ago: 0
          }
        },
        axis: {
          x: {
            min: 0,
            max: 420,
            step: 1,
            label: {
              text: '搾乳日数'
            }
          },
          y: {
            min: 0,
            step: 1,
            label: {
              text: 'P/F比(乳脂率/乳蛋白率)'
            }
          }
        }
      }],
      somaticCellAnalysis: [{
        chart: {
          item: 'scc',
          api: 'cow',
          label: '体細胞数分布'
        },
        visible: {
          selectReferenceDate: true,
          rangeAxis: true
        },
        defaultSetting: {
          dataType: 'dhi',
          selectReferenceDate: {
            ago: 0
          }
        },
        axis: {
          x: {
            min: 0,
            max: 420,
            step: 1,
            label: {
              text: '搾乳日数'
            }
          },
          y: {
            min: 0,
            step: 1,
            label: {
              text: '体細胞数(千個/ml)'
            }
          }
        }
      }, {
        chart: {
          item: 'scc',
          api: 'parity',
          label: '体細胞数推移'
        },
        visible: {
          selectRangeDate: true
        },
        defaultSetting: {
          dataType: 'dhi',
          selectRangeDate: {
            start: {
              ago: 12
            },
            end: {
              ago: 0
            }
          }
        },
        axis: {
          x: {
            label: {
              text: '検定日'
            }
          },
          y: {
            label: {
              text: '体細胞数(千個/ml)'
            }
          }
        }
      }, {
        chart: {
          item: 'scc',
          api: 'farm',
          label: '体細胞数X千個/ml以上の割合推移'
        },
        visible: {
          threshold: true,
          selectRangeDate: true
        },
        defaultSetting: {
          dataType: 'dhi',
          threshold: 300,
          selectRangeDate: {
            start: {
              ago: 12
            },
            end: {
              ago: 0
            }
          }
        },
        label: {
          threshold: '閾値(単位：千個/ml)'
        },
        names: {
          lessThanThreshold: ['体細胞数', '千個/ml未満'],
          aboveThreshold: ['体細胞数', '千個/ml以上']
        },
        axis: {
          x: {
            label: {
              text: '検定日'
            }
          },
          y: {
            label: {
              text: '%'
            }
          }
        }
      }, {
        chart: {
          item: 'linear_score',
          api: 'cow',
          label: 'リニアスコア分布'
        },
        visible: {
          selectReferenceDate: true,
          rangeAxis: true
        },
        defaultSetting: {
          dataType: 'dhi',
          selectReferenceDate: {
            ago: 0
          }
        },
        axis: {
          x: {
            min: 0,
            max: 420,
            step: 1,
            label: {
              text: '搾乳日数'
            }
          },
          y: {
            min: 0,
            step: 0.5,
            label: {
              text: 'リニアスコア'
            }
          }
        }
      }, {
        chart: {
          item: 'linear_score',
          api: 'parity',
          label: 'リニアスコア推移'
        },
        visible: {
          selectRangeDate: true
        },
        defaultSetting: {
          dataType: 'dhi',
          selectRangeDate: {
            start: {
              ago: 12
            },
            end: {
              ago: 0
            }
          }
        },
        axis: {
          x: {
            label: {
              text: '検定日'
            }
          },
          y: {
            label: {
              text: 'リニアスコア'
            }
          }
        }
      }, {
        dataType: 'dhi',
        chart: {
          item: 'linear_score',
          api: 'farm',
          label: 'リニアスコアX以上の割合推移'
        },
        visible: {
          threshold: true,
          selectRangeDate: true,
        },
        defaultSetting: {
          dataType: 'dhi',
          threshold: 5,
          selectRangeDate: {
            start: {
              ago: 12
            },
            end: {
              ago: 0
            }
          }
        },
        label: {
          threshold: '閾値'
        },
        names: {
          lessThanThreshold: ['リニアスコア', '未満'],
          aboveThreshold: ['リニアスコア', '以上']
        },
        axis: {
          x: {
            label: {
              text: '検定日'
            }
          },
          y: {
            label: {
              text: '%'
            }
          }
        }
      }]
    };
  }
}
