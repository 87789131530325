// eslint-disable-next-line no-unused-vars
class DailyReportItemDefinition {
  static get definitions() {
    return [
      // type: STRING
      {itemId: 'cowNo', label: '牛番号', type: 'STRING', width: 90},
      {itemId: 'workerName', label: '担当者', type: 'STRING', width: 150},
      {itemId: 'mastitisBacteriaOfBreastFl', label: '乳房炎菌（左前）', type: 'STRING', width: 150},
      {itemId: 'mastitisDiagnosisResultOfBreastFl', label: '診断結果（左前）', type: 'STRING', width: 150},
      {itemId: 'mastitisBacteriaOfBreastFr', label: '乳房炎菌（右前）', type: 'STRING', width: 150},
      {itemId: 'mastitisDiagnosisResultOfBreastFr', label: '診断結果（右前）', type: 'STRING', width: 150},
      {itemId: 'mastitisBacteriaOfBreastBl', label: '乳房炎菌（左後）', type: 'STRING', width: 150},
      {itemId: 'mastitisDiagnosisResultOfBreastBl', label: '診断結果（左後）', type: 'STRING', width: 150},
      {itemId: 'mastitisBacteriaOfBreastBr', label: '乳房炎菌（右後）', type: 'STRING', width: 150},
      {itemId: 'mastitisDiagnosisResultOfBreastBr', label: '診断結果（右後）', type: 'STRING', width: 150},
      {itemId: 'diagnosisResultDetail', label: '診断治療結果', type: 'STRING', width: 300},
      {itemId: 'comment', label: 'コメント', type: 'STRING', width: 300},
      {itemId: 'diagnosisResultOfOvaryL', label: '卵巣（左）', type: 'STRING', width: 100},
      {itemId: 'diagnosisResultOfOvaryR', label: '卵巣（右）', type: 'STRING', width: 100},
      {itemId: 'uterusDiagnosisResult', label: '子宮', type: 'STRING', width: 70},
      {itemId: 'difficultiesOvaryStill', label: '卵巣静止・萎縮', type: 'STRING', width: 130},
      {itemId: 'difficultiesOvaryCystic', label: '卵胞嚢腫', type: 'STRING', width: 90},
      {itemId: 'difficultiesRemnant', label: '黄体遺残', type: 'STRING', width: 90},
      {itemId: 'difficultiesMetritis', label: '子宮炎', type: 'STRING', width: 70},
      {itemId: 'difficultiesPyometra', label: '子宮蓄膿症', type: 'STRING', width: 100},
      {itemId: 'difficultiesKetosis', label: 'ケトーシス', type: 'STRING', width: 100},
      {itemId: 'difficultiesFattyLiver', label: '脂肪肝', type: 'STRING', width: 70},
      {itemId: 'difficultiesAbomasumDisplacement', label: '第四胃変位', type: 'STRING', width: 100},
      {itemId: 'difficultiesHypocalcium', label: '低カルシウム', type: 'STRING', width: 110},
      {itemId: 'difficultiesAcidosis', label: 'アシドーシス', type: 'STRING', width: 110},
      {itemId: 'difficultiesDowner', label: 'ダウナー症候群', type: 'STRING', width: 130},
      {itemId: 'difficultiesStagnantFood', label: '食滞', type: 'STRING', width: 70},
      {itemId: 'difficultiesPlacenta', label: '胎盤停滞', type: 'STRING', width: 90},
      {itemId: 'difficultiesTympanites', label: '鼓張症', type: 'STRING', width: 70},
      {itemId: 'difficultiesHipDislocation', label: '股関節脱臼', type: 'STRING', width: 100},
      {itemId: 'difficultiesTeatInjury', label: '乳頭損傷', type: 'STRING', width: 90},
      {itemId: 'difficultiesPneumonia', label: '肺炎', type: 'STRING', width: 70},
      {itemId: 'difficultiesDiarrhea', label: '下痢', type: 'STRING', width: 70},
      {itemId: 'difficultiesSkinDisease', label: '皮膚病', type: 'STRING', width: 70},
      {itemId: 'freshCheckResult', label: 'チェック結果', type: 'STRING', width: 110},
      {itemId: 'otherReproductionWork', label: 'その他処置', type: 'STRING', width: 150},
      {itemId: 'heatComment', label: '所見', type: 'STRING', width: 400, relatedColumn: 'comment'},
      {itemId: 'tanetsukeMethod', label: '種付方法', type: 'STRING', width: 90},
      {itemId: 'inseminationCode', label: '授精コード', type: 'STRING', width: 150},
      {itemId: 'masterSpermName', label: '種雄牛名', type: 'STRING', width: 120},
      {itemId: 'masterSpermNo', label: '精液番号', type: 'STRING', width: 120},
      {itemId: 'donorName', label: '供卵牛', type: 'STRING', width: 120},
      {itemId: 'donorRegistrationNo', label: '供卵牛登録番号', type: 'STRING', width: 130},
      {itemId: 'donorBreed', label: '供卵牛品種', type: 'STRING', width: 120},
      {itemId: 'donorBreedingValue', label: '育種価', type: 'STRING', width: 120},
      {itemId: 'ninshinkanteiResult', label: '鑑定結果', type: 'STRING', width: 90},
      {itemId: 'judgePregnantTiming', label: 'タイミング', type: 'STRING', width: 150},
      {itemId: 'afterCowState', label: '牛個体の状態', type: 'STRING', width: 120},
      {itemId: 'calvingDifficulty', label: '分娩難易度', type: 'STRING', width: 100},
      {itemId: 'hormoneProgramName', label: 'ホルモンプログラム名', type: 'STRING', width: 200},
      {itemId: 'hormoneName', label: '処置内容', type: 'STRING', width: 200},
      {itemId: 'breedingExclusionReason', label: '除外理由', type: 'STRING', width: 150},
      {itemId: 'taioTitle', label: 'タイトル', type: 'STRING', width: 300},
      {itemId: 'vaccineTiming', label: 'タイミング', type: 'STRING', width: 150},
      {itemId: 'selectedDryPeriod', label: '前後期', type: 'STRING', width: 70},
      {itemId: 'expelledReason', label: '除籍理由', type: 'STRING', width: 90},
      {itemId: 'producingArea', label: '生産地', type: 'STRING', width: 70},
      {itemId: 'producingFarmName', label: '生産農場名', type: 'STRING', width: 300},
      {itemId: 'buyer', label: '販売先', type: 'STRING', width: 300},
      {itemId: 'deathReason', label: 'へい死理由', type: 'STRING', width: 100},
      {itemId: 'grade', label: '等級', type: 'STRING', width: 70},
      {itemId: 'otherFaultCorrection', label: '歩留補正', type: 'STRING', width: 120},
      {itemId: 'eyeCondition', label: '目', type: 'STRING', width: 70},
      {itemId: 'earCondition', label: '耳', type: 'STRING', width: 70},
      {itemId: 'muzzleCondition', label: '鼻', type: 'STRING', width: 70},
      {itemId: 'hairCondition', label: '毛', type: 'STRING', width: 70},
      {itemId: 'hipCondition', label: '尻', type: 'STRING', width: 70},
      {itemId: 'bodyCondition', label: '体', type: 'STRING', width: 70},
      {itemId: 'fecesCondition', label: '糞', type: 'STRING', width: 70},
      {itemId: 'urineCondition', label: '尿', type: 'STRING', width: 70},
      {itemId: 'dehornMethod', label: '除角方法', type: 'STRING', width: 150},
      {itemId: 'castratMethod', label: '去勢方法', type: 'STRING', width: 150},
      {itemId: 'title', label: 'タイトル', type: 'STRING', width: 150},
      {itemId: 'memo', label: 'メモ', type: 'STRING', width: 150, relatedColumn: 'comment'},
      {itemId: 'masterWorkNoteTypeName', label: '作業区分', type: 'STRING', width: 150},
      {itemId: 'plannedBredMethod', label: '種付予定', type: 'STRING', width: 100},
      {itemId: 'embryoType', label: '受精卵種類', type: 'STRING', width: 110},
      {itemId: 'embryoCertificateNo', label: '受精卵証明書番号', type: 'STRING', width: 170},

      // type: DATE
      {itemId: 'occurredAtDate', label: '日付', type: 'DATE', width: 100, relatedColumn: 'occurredAt'},
      {itemId: 'endDateOfMilkWashoutPeriod', label: '休薬終了日（乳）', type: 'DATE', width: 140},
      {itemId: 'endDateOfBeefWashoutPeriod', label: '休薬終了日（肉）', type: 'DATE', width: 140},
      {itemId: 'targetBreedingDate', label: '対象種付日', type: 'DATE', width: 100},

      // type: DIFF_DAYS
      {itemId: 'fertilizedDays', label: '授精後日数', type: 'DIFF_DAYS', width: 100, relatedColumn: 'targetBreedingDate'},

      // type: TIME
      {itemId: 'treatedAtTime', label: '治療時刻', type: 'TIME', width: 90, relatedColumn: 'treatmentDiseaseDate'},
      {itemId: 'occurredAtTime', label: '時刻', type: 'TIME', width: 70, relatedColumn: 'occurredAt'},

      // type: FLOAT
      {itemId: 'bodyTemperature', label: '体温', type: 'FLOAT', width: 60},
      {itemId: 'bcs', label: 'BCS', type: 'FLOAT', width: 70},
      {itemId: 'dressedCarcassWeightOfL', label: '枝肉重量（左）', type: 'FLOAT', width: 130},
      {itemId: 'dressedCarcassWeightOfR', label: '枝肉重量（右）', type: 'FLOAT', width: 130},
      {itemId: 'totalDressedCarcassWeight', label: '枝肉総重量', type: 'FLOAT', width: 120},
      {itemId: 'dressedCarcassUnitPrice', label: '枝肉単価', type: 'FLOAT', width: 100},
      {itemId: 'dressedCarcassSalesPrice', label: '枝肉販売金額', type: 'FLOAT', width: 120},
      {itemId: 'beforeSlaughterWeight', label: 'と畜前体重', type: 'FLOAT', width: 100},
      {itemId: 'loinArea', label: '胸最長筋面積', type: 'FLOAT', width: 110},
      {itemId: 'ribsThickness', label: 'ばらの厚さ', type: 'FLOAT', width: 100},
      {itemId: 'subcutaneousFat', label: '皮下脂肪の厚さ', type: 'FLOAT', width: 130},
      {itemId: 'yieldBaseValue', label: '歩留基準値', type: 'FLOAT', width: 100},
      {itemId: 'dg', label: 'DG', type: 'FLOAT', width: 60},

      // type: INTEGER
      {itemId: 'locomotionScore', label: '跛行スコア', type: 'INTEGER', width: 100},
      {itemId: 'salesPrice', label: '販売価格', type: 'INTEGER', width: 150},
      {itemId: 'shipmentNo', label: '出荷番号', type: 'INTEGER', width: 150},
      {itemId: 'slaughterNo', label: 'と畜番号', type: 'INTEGER', width: 90},
      {itemId: 'bmsNo', label: 'BMSNo', type: 'INTEGER', width: 80},
      {itemId: 'marblingGrade', label: '脂肪交雑等級', type: 'INTEGER', width: 110},
      {itemId: 'bcsNo', label: 'BCSNo', type: 'INTEGER', width: 80},
      {itemId: 'gloss', label: '光沢', type: 'INTEGER', width: 60},
      {itemId: 'bcsAndGlossGrade', label: '肉色及び光沢の等級', type: 'INTEGER', width: 150},
      {itemId: 'tight', label: '締まり', type: 'INTEGER', width: 70},
      {itemId: 'texture', label: 'きめ', type: 'INTEGER', width: 60},
      {itemId: 'tightAndTextureGrade', label: '締まり及びきめの等級', type: 'INTEGER', width: 170},
      {itemId: 'bfsNo', label: 'BFSNo', type: 'INTEGER', width: 80},
      {itemId: 'fatLuster', label: '光沢と質', type: 'INTEGER', width: 100},
      {itemId: 'bfsAndFatLusterGrade', label: '色沢と質の等級', type: 'INTEGER', width: 150},
      {itemId: 'weight', label: '体重', type: 'INTEGER', width: 60},
      {itemId: 'breedingDays', label: '飼育日数  ', type: 'INTEGER', width: 100},

      // type: SPECIAL
      {itemId: 'cowUid', label: '個体識別番号', type: 'SPECIAL', width: 110},
      {itemId: 'mastitisScore', label: '乳房炎スコア', type: 'SPECIAL', width: 110},
      {itemId: 'medications', label: '投薬', type: 'SPECIAL', width: 460},
      {itemId: 'lameAffected', label: '症状・診断', type: 'SPECIAL', width: 300},
      {itemId: 'otherDisease', label: 'その他症状', type: 'SPECIAL', width: 140},
      {itemId: 'donorCowUid', label: '供卵牛の個体識別番号', type: 'SPECIAL', width: 170},
      {itemId: 'nextStartMilkingFlg', label: '次産泌乳', type: 'SPECIAL', width: 110},
      {itemId: 'birthResult01', label: '出産結果（1頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'breed01', label: '品種（1頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'gender01', label: '性別（1頭目）', type: 'SPECIAL', width: 120, relatedColumn: 'calfs'},
      {itemId: 'registered01', label: 'データ作成（1頭目）', type: 'SPECIAL', width: 170, relatedColumn: 'calfs'},
      {itemId: 'cowNo01', label: '牛番号（1頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'cowUid01', label: '個体識別番号（1頭目）', type: 'SPECIAL', width: 170, relatedColumn: 'calfs'},
      {itemId: 'weightOnBirth01', label: '体重（1頭目）', type: 'SPECIAL', width: 120, relatedColumn: 'calfs'},
      {itemId: 'calfNo01', label: '子牛番号（1頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'birthStateString01', label: '出生時状態（1頭目）', type: 'SPECIAL', width: 170, relatedColumn: 'calfs'},
      {itemId: 'birthResult02', label: '出産結果（2頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'breed02', label: '品種（2頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'gender02', label: '性別（2頭目）', type: 'SPECIAL', width: 120, relatedColumn: 'calfs'},
      {itemId: 'registered02', label: 'データ作成（2頭目）', type: 'SPECIAL', width: 170, relatedColumn: 'calfs'},
      {itemId: 'cowNo02', label: '牛番号（2頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'cowUid02', label: '個体識別番号（2頭目）', type: 'SPECIAL', width: 170, relatedColumn: 'calfs'},
      {itemId: 'weightOnBirth02', label: '体重（2頭目）', type: 'SPECIAL', width: 120, relatedColumn: 'calfs'},
      {itemId: 'calfNo02', label: '子牛番号（2頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'birthStateString02', label: '出生時状態（2頭目）', type: 'SPECIAL', width: 170, relatedColumn: 'calfs'},
      {itemId: 'birthResult03', label: '出産結果（3頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'breed03', label: '品種（3頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'gender03', label: '性別（3頭目）', type: 'SPECIAL', width: 120, relatedColumn: 'calfs'},
      {itemId: 'registered03', label: 'データ作成（3頭目）', type: 'SPECIAL', width: 170, relatedColumn: 'calfs'},
      {itemId: 'cowNo03', label: '牛番号（3頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'cowUid03', label: '個体識別番号（3頭目）', type: 'SPECIAL', width: 170, relatedColumn: 'calfs'},
      {itemId: 'weightOnBirth03', label: '体重（3頭目）', type: 'SPECIAL', width: 120, relatedColumn: 'calfs'},
      {itemId: 'calfNo03', label: '子牛番号（3頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'birthStateString03', label: '出生時状態（3頭目）', type: 'SPECIAL', width: 170, relatedColumn: 'calfs'},
      {itemId: 'birthResult04', label: '出産結果（4頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'breed04', label: '品種（4頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'gender04', label: '性別（4頭目）', type: 'SPECIAL', width: 120, relatedColumn: 'calfs'},
      {itemId: 'registered04', label: 'データ作成（4頭目）', type: 'SPECIAL', width: 170, relatedColumn: 'calfs'},
      {itemId: 'cowNo04', label: '牛番号（4頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'cowUid04', label: '個体識別番号（4頭目）', type: 'SPECIAL', width: 170, relatedColumn: 'calfs'},
      {itemId: 'weightOnBirth04', label: '体重（4頭目）', type: 'SPECIAL', width: 120, relatedColumn: 'calfs'},
      {itemId: 'calfNo04', label: '子牛番号（4頭目）', type: 'SPECIAL', width: 150, relatedColumn: 'calfs'},
      {itemId: 'birthStateString04', label: '出生時状態（4頭目）', type: 'SPECIAL', width: 170, relatedColumn: 'calfs'},
      {itemId: 'beforeCowGroupName', label: '移動元牛群', type: 'SPECIAL', width: 150, relatedColumn: 'beforeCowGroupId'},
      {itemId: 'moveToCowGroupName', label: '移動先牛群', type: 'SPECIAL', width: 150, relatedColumn: 'moveToCowGroupId'},
      {itemId: 'beforePen', label: '移動元牛房', type: 'SPECIAL', width: 150},
      {itemId: 'moveToPen', label: '移動先牛房', type: 'SPECIAL', width: 150},
      {itemId: 'embryoMasterRegistered', label: '受精卵マスタへの登録', type: 'SPECIAL', width: 170},
      {itemId: 'nextCowState', label: '採卵後の状態', type: 'SPECIAL', width: 150},
      {itemId: 'carcassDefectSummary', label: '瑕疵', type: 'SPECIAL', width: 300, relatedColumn: 'carcassDefects'},
      {itemId: 'visceralDestruction', label: '内臓廃棄', type: 'SPECIAL', width: 300},
      {itemId: 'embryoRecoveryRankA', label: 'A', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankA1', label: 'A1', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankA2', label: 'A2', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankA3', label: 'A3', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankA4', label: 'A4', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankA5', label: 'A5', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankB', label: 'B', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankB1', label: 'B1', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankB2', label: 'B2', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankB3', label: 'B3', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankB4', label: 'B4', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankB5', label: 'B5', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankC', label: 'C', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankD', label: 'D', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankDegenerated', label: '変性', type: 'SPECIAL', width: 70, relatedColumn: 'embryos'},
      {itemId: 'embryoRecoveryRankUnfertilized', label: '未受精', type: 'SPECIAL', width: 90, relatedColumn: 'embryos'},
      {itemId: 'plannedBredAt', label: '種付予定日', type: 'SPECIAL', width: 160},

      // type: BOOLEAN
      {itemId: 'stopMilkingOfBreastFl', label: '盲乳（左前）', type: 'BOOLEAN', width: 120},
      {itemId: 'stopMilkingOfBreastFr', label: '盲乳（右前）', type: 'BOOLEAN', width: 120},
      {itemId: 'stopMilkingOfBreastBl', label: '盲乳（左後）', type: 'BOOLEAN', width: 120},
      {itemId: 'stopMilkingOfBreastBr', label: '盲乳（右後）', type: 'BOOLEAN', width: 120},

      // type: BUTTON
      {itemId: 'edit', label: '編集', type: 'BUTTON', width: 50},
      {itemId: 'delete', label: '削除', type: 'BUTTON', width: 50},
    ];
  }

  static deinitionMap() {
    const defs = DailyReportItemDefinition.definitions;
    return defs.reduce((obj, def) => {
      obj[def.itemId] = def;
      return obj;
    }, {});
  }

  static itemDefinitions(itemIds) {
    const defs = DailyReportItemDefinition.deinitionMap();
    return itemIds.map((itemId) => defs[itemId]);
  }

  static toDisplayFormat(items, records, map, baseDate = DateUtil.today()) {
    return records.map((r) => {
      const result = {
        cowId: r.cowId,
        cowNoForOrder: r.cowNoForOrder,
        penForOrder: r.penForOrder,
      };

      items.forEach((item) => {
        result[item.itemId] = DailyReportItemDefinition.toDisplayValue(
          item, r, map, baseDate
        );
      });

      return result;
    });
  }

  static toDisplayValue(item, record, map, date) {
    if (item.type === 'SPECIAL') {
      if (['cowUid', 'donorCowUid'].includes(item.itemId)) {
        return Cow.formatCowUid(record[item.itemId]);
      }

      if (item.itemId === 'mastitisScore') {
        const mastitisScore = Mastitis.MASTITIS_SCORES.find((s) => s.value === record.mastitisScore) || [];
        return mastitisScore.label;
      }

      if (item.itemId === 'medications') {
        return record.medications.map((m) => {
          const capacity = m.capacity ? `${m.capacity}${m.unit}` : '';
          return `${m.name} ${capacity} ${m.method}`;
        }).join('、');
      }

      if (item.itemId === 'lameAffected') {
        return [...Array(4).keys()].map((i) => {
          ++i;

          const lameAffectedLimb = record[`lameAffectedLimb${i}`];
          const lameCondition = record[`lameCondition${i}`];
          const clawDiseaseName = record[`clawDiseaseName${i}`];

          let lameClawZones = record[`lameClawZones${i}`] || '';

          lameClawZones = lameClawZones.replace(/内,外/, '内外').replace(/([内外]+)/, '$1蹄');

          const lameAffectedPart = lameClawZones ? '' : record[`lameAffectedPart${i}`];

          return [
            lameAffectedLimb ? `[${lameAffectedLimb}]` : null,
            lameClawZones,
            lameAffectedPart,
            lameCondition,
            clawDiseaseName
          ].filter((item) => item).join(' ');
        }).filter((item) => item);
      }

      if (item.itemId === 'otherDisease') {
        if (record.eventType === 'otherDifficulty' && !record.difficultiesOther) {
          return record.otherDiseaseNames.map((d) => d.name).join('、');
        }

        if (record.difficultiesOther) {
          return `${record.otherDiseaseName}：${record.difficultiesOther}`;
        } else {
          return null;
        }
      }

      if (item.itemId === 'nextStartMilkingFlg') {
        return record.nextStartMilkingFlg ? 'あり' : 'なし';
      }

      if (item.relatedColumn === 'calfs') {
        const match = item.itemId.match(/^([a-zA-Z]+)([0-9]+)$/);
        const itemId = match[1];
        const index = match[2] - 1;

        if (record.calfs.length <= index) return null;

        return record.calfs[index][itemId];
      }

      if (item.relatedColumn === 'embryos') {
        if (!record.embryos) return null;

        const embryo = record.embryos.find((embryo) => {
          const rank = item.itemId.split('Rank')[1];

          if (rank === 'Degenerated') {
            return embryo.rank === 'degenerated';
          }

          if (rank === 'Unfertilized') {
            return embryo.rank === 'unfertilized';
          }

          return embryo.rank === rank;
        });

        return embryo ? embryo.count : null;
      }

      if (['beforeCowGroupName', 'moveToCowGroupName'].includes(item.itemId)) {
        if (!record[item.relatedColumn]) return null;

        const cowGroup = map.cowGroupMap[record[item.relatedColumn]];
        if (!cowGroup) return null;

        return cowGroup.cowGroupName;
      }

      if (['beforePen', 'moveToPen'].includes(item.itemId)) {
        if (!record.moveToCowGroupId) return null;

        return record[item.itemId];
      }

      if (item.itemId === 'embryoMasterRegistered') {
        if (record.embryoMasterRegistered) {
          return '対象';
        }

        return '対象外';
      }

      if (item.itemId === 'nextCowState') {
        return map.nextCowStateMap[record.nextCowState].label;
      }

      if (item.itemId === 'carcassDefectSummary') {
        return Carcass.defectSummary(record[item.relatedColumn]);
      }

      if (item.itemId === 'visceralDestruction') {
        if (!record.visceralDestruction) return null;

        return record.visceralDestruction.replace(/:/g, '、');
      }

      if (item.itemId === 'plannedBredAt') {
        return DateUtil.toYYYYMMDDHHmm(record.plannedBredAt);
      }
    }

    if (item.type === 'STRING') {
      return record[item.itemId] || record[item.relatedColumn];
    }

    if (item.type === 'DATE') {
      return DateUtil.toYYYYMMDD(record[item.itemId]) || DateUtil.toYYYYMMDD(record[item.relatedColumn]);
    }

    if (item.type === 'TIME') {
      return DateUtil.toHHmm(record[item.itemId]) || DateUtil.toHHmm(record[item.relatedColumn]);
    }

    if (item.type === 'DIFF_DAYS') {
      const days = DateUtil.diffDays(record[item.relatedColumn], date);
      return days > 0 ? String(days) : '';
    }

    if (item.type === 'BUTTON') {
      return record.id;
    }

    const value = record[item.itemId];
    if (!value) {
      return item.default || '';
    }
    return value;
  }
}
