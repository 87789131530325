/**
 * 温度、湿度などの環境情報用のサービス
 */
class EnvironmentService {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  /**
   * 最新の環境情報を取得する
   *
   * @param {String} bleIds センサー番号
   * @return {Object} Promise
   */
  getLatestEnvironment(bleIds) {
    const endpoint = '/environment/latest?ble_ids=' + bleIds;
    return this.APIService.get(endpoint);
  }

  /**
   * 指定した日付の環境情報を取得する
   *
   * @param {String} bleIds センサー番号
   * @param {Date} startDate 取得開始日
   * @param {Date} endDate 取得終了日
   * @retrun {Object} Promise
   */
  getDailyEnvironment(bleIds, startDate, endDate) {
    // YYYY-MM-DD形式に変換する
    const paramStartDate = getDateLabelForApiResDateTime(startDate || new Date());
    const paramEndDate = getDateLabelForApiResDateTime(endDate || new Date());
    const endpoint = '/environment/daily?'
               + 'ble_ids=' + bleIds
               + '&start_date=' + paramStartDate
               + '&end_date=' + paramEndDate;
    return this.APIService.get(endpoint);
  }

  /**
   * エンドポイント、パラメータで指定された条件で環境情報を取得する
   *
   * @param {String} endpoing エンドポイント
   * @param {Object} params 条件 { data_type, start_date, end_date, stats_type }
   * @return {Object} Promise
   */
  getDailySummary(endpoint, params) {
    const querys = [];
    if (params.summaryType) querys.push(`data_type=${params.summaryType}`);
    if (params.startDate) querys.push(`start_date=${String(params.startDate)}`);
    if (params.endDate) querys.push(`end_date=${String(params.endDate)}`);
    if (params.statsType) querys.push(`stats_type=${params.statsType}`);
    endpoint += querys.join('&');
    return this.APIService.get(endpoint);
  }

  getFarmSummary(params) {
    const endpoint = '/environment/daily/summary/farm?';
    return this.getDailySummary(endpoint, params);
  }

  getCowshedSummary(params) {
    const endpoint = `/environment/daily/summary/cowshed/${params.cowshedId}?`;
    return this.getDailySummary(endpoint, params);
  }

  getCowGroupSummary(params) {
    const endpoint = `/environment/daily/summary/group/${params.cowGroupId}?`;
    return this.getDailySummary(endpoint, params);
  }

  getDailySummaryByCowId(params) {
    if (!params.cowId) {
      console.error('Cow id is nothing');
    }
    const endpoint = `/environment/daily/summary/cow/${params.cowId}?`;
    return this.getDailySummary(endpoint, params);
  }
}

app.service('EnvironmentService', EnvironmentService);
