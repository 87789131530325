class CowLabelAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  show(cowId) {
    return this.APIService.get(`/cow_label/${cowId}`);
  }

  update(cowId, labels) {
    const values = labels.map((label, index) => {
      return {
        masterCowLabelId: label.masterCowLabelId,
        sortOrder: index + 1
      };
    });
    const params = {labels: values};
    return this.APIService.put(`/cow_label/${cowId}`, params);
  }

  bulkAdd(cowIds, labels) {
    return this.bulkAction(cowIds, labels, 'bulk_add');
  }

  bulkRemove(cowIds, labels) {
    return this.bulkAction(cowIds, labels, 'bulk_remove');
  }

  bulkAction(cowIds, labels, action) {
    const params = {
      cowIds: cowIds,
      masterCowLabelIds: labels.map((label) => label.masterCowLabelId)
    };
    return this.APIService.post(`/cow_label/${action}`, params);
  }
}

app.service('CowLabelAPI', CowLabelAPI);
