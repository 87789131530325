// eslint-disable-next-line no-unused-vars
class HeadageDetailsItem {
  static items(context) {
    const itemIds = HeadageDetailsItem.itemIds(context);
    return HeadageItemDefinition.itemDefinitions(itemIds);
  }

  static itemIds(context) {
    switch (context.category) {
    case 'breeding':
      return HeadageDetailsItem.breedingItemsIds(context.subCategory, context.isFattening);
    case 'dairy':
      return HeadageDetailsItem.dairyItemsIds(context.subCategory);
    case 'state':
      return HeadageDetailsItem.stateItemsIds(context.subCategory, context.isDairy);
    case 'breed':
      return HeadageDetailsItem.breedItemsIds(context.isDairy);
    case 'inventory':
      return HeadageDetailsItem.inventoryItemsIds(context.subCategory, context.isDairy);
    default:
      throw `不正なcategory: ${context.category}`;
    }
  }

  static breedingItemsIds(subCategory, isFattening) {
    switch (subCategory) {
    case 'cow': {
      return [
        'cowGroupName',
        'pen',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'monthAge',
        'birthNumber',
        'openDays',
        'consecutiveBredCount',
        'fertilizedDays',
        'pregnantDays',
        'expectedCalvingDate',
        'fatherName',
        'maternalGrandfatherName',
        'motherName',
      ];
    }
    case 'heifer': {
      return [
        'cowGroupName',
        'pen',
        'state',
        'cowNo',
        'cowUid',
        'birthday',
        'dayAge',
        'monthAge',
        'fatherName',
        'maternalGrandfatherName',
        'motherName',
        'consecutiveBredCount',
        'fertilizedDays',
        'pregnantDays',
        'expectedCalvingDate',
      ];
    }
    case 'calf': {
      return [
        'cowGroupName',
        'breed',
        'state',
        'cowNo',
        'cowUid',
        'gender',
        'birthday',
        'dayAge',
        'monthAge',
        'fatherName',
        'maternalGrandfatherName',
        'motherName',
        'maternalGreatGrandfatherName',
        'maternalFather4thName',
      ];
    }
    case 'fattening': {
      return [
        'cowGroupName',
        'pen',
        'breed',
        'cowLabels',
        'cowNo',
        'cowUid',
        'gender',
        'birthday',
        'monthAge',
        'introduceDate',
        'enrolledMonths',
        'fatherName',
        'maternalGrandfatherName',
        'maternalGreatGrandfatherName',
        'maternalFather4thName',
      ];
    }
    case 'irregular': {
      return HeadageDetailsItem.irregularBreedingItemsIds(isFattening);
    }
    default:
      throw `不正なsubCategory: ${subCategory}`;
    }
  }

  static irregularBreedingItemsIds(isFattening) {
    if (isFattening) {
      return [
        'cowGroupName',
        'pen',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'introduceDate',
        'birthday',
        'eliminateDate',
        'birthNumber',
        'latestCalvingDate',
        'latestFertilizationDate',
        'startFatteningDate',
      ];
    }

    return [
      'cowGroupName',
      'pen',
      'cowLabels',
      'state',
      'cowNo',
      'cowUid',
      'introduceDate',
      'birthday',
      'eliminateDate',
      'birthNumber',
      'latestCalvingDate',
      'latestFertilizationDate',
    ];
  }

  static dairyItemsIds(subCategory) {
    switch (subCategory) {
    case 'milking': {
      return [
        'cowGroupName',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'birthNumber',
        'milkingDays',
        'weeklyMean',
        'pregnantDays',
        'latestFertilizationDate',
        'fertilizedDays',
        'bullSpermNo',
        'expectedDryDate',
      ];
    }
    case 'heifer': {
      return [
        'cowGroupName',
        'state',
        'cowNo',
        'cowUid',
        'birthday',
        'dayAge',
        'monthAge',
        'fatherName',
        'maternalGrandfatherName',
        'motherName',
        'consecutiveBredCount',
        'fertilizedDays',
        'pregnantDays',
        'expectedCalvingDate',
      ];
    }
    case 'calf': {
      return [
        'cowGroupName',
        'breed',
        'state',
        'cowNo',
        'cowUid',
        'gender',
        'birthday',
        'dayAge',
        'monthAge',
        'fatherName',
        'maternalGrandfatherName',
        'motherName',
        'maternalGreatGrandfatherName',
        'maternalFather4thName',
      ];
    }
    case 'dry': {
      return [
        'cowGroupName',
        'cowLabels',
        'cowNo',
        'cowUid',
        'birthNumber',
        'calvedDays',
        'openDays',
        'pregnantDays',
        'dryEarlierDays',
        'dryLaterDays',
        'expectedCalvingDate',
      ];
    }
    case 'milking_stop': {
      return [
        'cowGroupName',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'birthNumber',
        'calvedDays',
        'endDateOfMilkWashoutPeriod',
        'medicationSummary',
      ];
    }
    case 'irregular': {
      return [
        'cowGroupName',
        'breed',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'introduceDate',
        'birthday',
        'birthNumber',
        'latestCalvingDate',
        'latestFertilizationDate',
        'latestDryPreviousPeriodStartDate',
      ];
    }
    default:
      throw `不正なsubCategory: ${subCategory}`;
    }
  }

  static stateItemsIds(subCategory, isDairy) {
    switch (subCategory) {
    case 'fattening': {
      return [
        'cowGroupName',
        'pen',
        'breed',
        'cowLabels',
        'cowNo',
        'cowUid',
        'gender',
        'birthday',
        'monthAge',
        'introduceDate',
        'enrolledMonths',
        'fatherName',
        'maternalGrandfatherName',
        'maternalGreatGrandfatherName',
        'maternalFather4thName',
      ];
    }
    case 'non_fattening': {
      return [
        'cowGroupName',
        'pen',
        'breed',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'gender',
        'birthday',
        'monthAge',
        'enrolledMonths',
        'startFatteningDate',
      ];
    }
    case 'others': {
      return [
        'cowGroupName',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'birthday',
        'birthNumber',
        'latestFertilizationDate',
        'latestCalvingDate',
      ];
    }
    case 'calf': {
      return [
        'cowGroupName',
        'breed',
        'state',
        'cowNo',
        'cowUid',
        'gender',
        'birthday',
        'dayAge',
        'monthAge',
        'fatherName',
        'maternalGrandfatherName',
        'motherName',
        'maternalGreatGrandfatherName',
      ];
    }
    }

    if (isDairy) {
      return HeadageDetailsItem.dairyStateItemsIds(subCategory);
    } else {
      return HeadageDetailsItem.breedingStateItemsIds(subCategory);
    }
  }

  static dairyStateItemsIds(subCategory) {
    switch (subCategory) {
    case 'non_pregnant': {
      return [
        'cowGroupName',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'birthNumber',
        'milkingDays',
        'weeklyMean',
        'latestFertilizationDate',
        'consecutiveBredCount',
        'fertilizedDays',
        'bullSpermNo',
      ];
    }
    case 'pregnant': {
      return [
        'cowGroupName',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'birthNumber',
        'milkingDays',
        'weeklyMean',
        'pregnantDays',
        'latestFertilizationDate',
        'bullSpermNo',
        'latestDryPreviousPeriodStartDate',
        'expectedCalvingDate',
      ];
    }
    case 'do_not_breed': {
      return [
        'cowGroupName',
        'cowLabels',
        'cowNo',
        'cowUid',
        'birthNumber',
        'breedingExcludeDate',
        'breedingExclusionReason',
        'latestCalvingDate',
        'openDays',
        'weeklyMean',
      ];
    }
    default:
      throw `不正なcategory: ${category}`;
    }
  }

  static breedingStateItemsIds(subCategory) {
    switch (subCategory) {
    case 'non_pregnant': {
      return [
        'cowGroupName',
        'pen',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'birthNumber',
        'fatherName',
        'maternalGrandfatherName',
        'motherName',
        'openDays',
        'consecutiveBredCount',
        'fertilizedDays',
        'bullSpermNo',
      ];
    }
    case 'pregnant': {
      return [
        'cowGroupName',
        'pen',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'birthNumber',
        'fatherName',
        'maternalGrandfatherName',
        'motherName',
        'openDays',
        'consecutiveBredCount',
        'pregnantDays',
        'expectedCalvingDate',
      ];
    }
    case 'do_not_breed': {
      return [
        'cowGroupName',
        'pen',
        'cowLabels',
        'cowNo',
        'cowUid',
        'birthNumber',
        'breedingExcludeDate',
        'breedingExclusionReason',
        'latestCalvingDate',
        'openDays',
      ];
    }
    default:
      throw `不正なcategory: ${category}`;
    }
  }

  static breedItemsIds(isDairy) {
    if (isDairy) {
      return [
        'cowGroupName',
        'breed',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'gender',
        'birthday',
        'monthAge',
        'introduceDate',
        'enrolledMonths',
        'fatherName',
        'maternalGrandfatherName',
        'maternalGreatGrandfatherName',
      ];
    }

    return [
      'cowGroupName',
      'pen',
      'breed',
      'cowLabels',
      'state',
      'cowNo',
      'cowUid',
      'gender',
      'birthday',
      'monthAge',
      'introduceDate',
      'enrolledMonths',
      'fatherName',
      'maternalGrandfatherName',
      'maternalGreatGrandfatherName',
      'maternalFather4thName',
    ];
  }

  static inventoryItemsIds(subCategory, isDairy) {
    switch (subCategory) {
    case 'increase': {
      return HeadageDetailsItem.increaseInventoryItemsIds(isDairy);
    }
    case 'decrease': {
      return HeadageDetailsItem.decreaseInventoryItemsIds(isDairy);
    }
    case 'current': {
      return HeadageDetailsItem.currentInventoryItemsIds(isDairy);
    }
    default:
      throw `不正なsubCategory: ${subCategory}`;
    }
  }

  static increaseInventoryItemsIds(isDairy) {
    if (isDairy) {
      return [
        'cowGroupName',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'introduceDate',
        'birthday',
        'gender',
        'weightOnBirth',
        'fatherName',
        'maternalGrandfatherName',
        'motherName',
      ];
    }

    return [
      'cowGroupName',
      'pen',
      'cowLabels',
      'state',
      'cowNo',
      'cowUid',
      'introduceDate',
      'birthday',
      'gender',
      'weightOnBirth',
      'fatherName',
      'maternalGrandfatherName',
      'motherName',
    ];
  }

  static decreaseInventoryItemsIds(isDairy) {
    if (isDairy) {
      return [
        'cowGroupName',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'introduceDate',
        'eliminateDate',
        'expelledReason',
        'fallingDeadDate',
        'deathReason',
      ];
    }

    return [
      'cowGroupName',
      'pen',
      'cowLabels',
      'state',
      'cowNo',
      'cowUid',
      'introduceDate',
      'eliminateDate',
      'expelledReason',
      'fallingDeadDate',
      'deathReason',
    ];
  }

  static currentInventoryItemsIds(isDairy) {
    if (isDairy) {
      return [
        'cowGroupName',
        'cowLabels',
        'state',
        'cowNo',
        'cowUid',
        'birthday',
        'monthAge',
        'introduceDate',
        'enrolledMonths',
      ];
    }

    return [
      'cowGroupName',
      'pen',
      'cowLabels',
      'state',
      'cowNo',
      'cowUid',
      'birthday',
      'monthAge',
      'introduceDate',
      'enrolledMonths',
    ];
  }
}
