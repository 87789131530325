class AlertBadConditionCowBoxController {
  constructor(
    $state,
    SessionCache,
    AlertSummaryStateService
  ) {
    'ngInject';
    this.$state = $state;
    this.AlertSummaryStateService = AlertSummaryStateService;
    this.summary = null;

    this.init(SessionCache);
  }

  init(SessionCache) {
    this.AlertSummaryStateService.load();
    this.summary = this.AlertSummaryStateService.summary;

    const customlistSystemConfigs = SessionCache.customlistSystemConfigs();
    const config = customlistSystemConfigs.find((config) => config.configType === 'alert_bad_condition');
    if (config) {
      this.customlistId = config.customlistId;
    }
  }

  linkToAlertList(filter) {
    if (this.customlistId) {
      this.$state.go('alertList', {customlistId: this.customlistId, filter: filter});
    }
  }

  linkToAlertFeedLow() {
    if (this.farm.isMilk()) {
      this.$state.go('alertFeedLow', {}, {parent: this.$state.current});
    } else {
      this.$state.go('alertFeedLowBeef', {}, {parent: this.$state.current});
    }
  }

  linkToAlertWaterLow() {
    if (this.farm.isMilk()) {
      this.$state.go('alertWaterLow', {}, {parent: this.$state.current});
    } else {
      this.$state.go('alertWaterLowBeef', {}, {parent: this.$state.current});
    }
  }

  linkToAlertLieHigh() {
    if (this.farm.isMilk()) {
      this.$state.go('alertLieHigh', {}, {parent: this.$state.current});
    } else {
      this.$state.go('alertLieHighBeef', {}, {parent: this.$state.current});
    }
  }
}

function alertBadConditionCowBox() {
  return {
    templateUrl: 'top/alert/bad-condition-cow-box.html',
    controller: AlertBadConditionCowBoxController,
    bindings: {
      farm: '<',
    }
  };
}

app.component('alertBadConditionCowBox', alertBadConditionCowBox());
