class CountBeefBoxController {
  constructor(
    $state,
    SessionCache
  ) {
    'ngInject';
    this.$state = $state;
    this.farm = SessionCache.farm();

    this.cowDataKeys = ['ageCow0To6', 'ageCow6To12', 'ageCow12To18', 'ageCow18To24', 'ageCow24Over'];
    this.bullDataKeys = ['ageBull0To6', 'ageBull6To12', 'ageBull12To18', 'ageBull18To24', 'ageBull24Over'];
  }

  $onInit() {
    this.loading = true;
  }

  stageCaption(stage) {
    return FatteningStage.description(stage);
  }

  showList(stage) {
    if (!this.clickable(stage)) return;
    this.$state.go('customlist',
      {
        'name': this.stageCaption(stage),
        'customlistId': this.customlistId,
        'fattening_stage_id': stage.fatteningStageId,
        'config_type': 'fattening_stage'
      }
    );
  }

  showInitialFattening() {
    return this.farm.isFattening() && !this.data;
  }

  showOldStyle() {
    return !this.beefByStages && this.farm.isFattening() && this.data;
  }

  showStage() {
    return this.beefByStages && this.farm.isFattening() && this.data;
  }

  clickable(stage) {
    return stage.maleCount > 0 || stage.femaleCount > 0;
  }

  calcSumOfSex(sex) {
    const keys = sex === 'male' ? 'bullDataKeys' : 'cowDataKeys';
    return this[keys].map((key) => this.data[key]).reduce((acc, cur) => acc + cur);
  }

  calcSum() {
    return this.calcSumOfSex('male') + this.calcSumOfSex('female');
  }

  calcStageSumOfSex(sex) {
    const count = sex === 'male' ? 'maleCount' : 'femaleCount';
    return this.data.stages.map((stage) => stage[count]).reduce((acc, cur) => acc + cur);
  }

  calcStageSum() {
    return this.calcStageSumOfSex('male') + this.calcStageSumOfSex('female');
  }
}

function countBeefBox() {
  return {
    templateUrl: 'top/count/beef-box.html',
    controller: CountBeefBoxController,
    bindings: {
      kind: '<',
      data: '<',
      beefByStages: '<',
      customlistId: '<',
    }
  };
}

app.component('countBeefBox', countBeefBox());
