class DoNotBreedAlert {
  constructor(modalDialogFactory) {
    'ngInject';
    this.modalDialogFactory = modalDialogFactory;
  }

  open() {
    return this.modalDialogFactory.showAlertModal({
      title: 'エラー',
      text1: 'この牛は妊娠しています。',
      no: false, yes: false
    });
  }
}

app.service('DoNotBreedAlert', DoNotBreedAlert);
