// eslint-disable-next-line no-unused-vars
class CowEvent {

  static get alertTypes() {
    return {
      'heat': '発情兆候',
      'falseHeat': '発情兆候(妊娠牛)',
      'dysstasia': '起立困難',
      'acuteIllness': '急性(改)',
      'illness': '急性疾病',
      'chronicIllness': '慢性疾病',
      'milkLow': '乳量低下',
      'feedLow': '採食低下',
      'strongMoveLow': '動態(強)低下',
      'lieHigh': '横臥増加',
      'waterLow': '飲水回数低下',
      'calving': '分娩',
      'eartagDropped': 'イヤタグセンサー脱落',
    };
  }

  static get alertDisplayOrders() {
    return {
      'heat': 0,
      'falseHeat': 1,
      'dysstasia': 2,
      'acuteIllness': 3,
      'illness': 4,
      'chronicIllness': 5,
      'milkLow': 6,
      'feedLow': 7,
      'strongMoveLow': 8,
      'lieHigh': 9,
      'waterLow': 10,
      'calving': 11,
    };
  }

  static get eventsExcludedFromMobileRegistration() {
    return ['carcass'];
  }

  static get pregnantDiagnosisPositiveStatuses() {
    return ['受胎', '双子受胎'];
  }

  static get pregnancyDateAdjustmentDays() {
    return 7;
  }

  static get hormoneProgramNameListDefault() {
    return [{label: '適用なし', value: 0}];
  }

  static occuredAtHasTime(eventType) {
    return [
      'abort',
      'bunben',
      'hatsujo',
      'hormoneProgram',
      'nisshi',
      'sagyomemo',
      'tanetsuke'
    ].includes(eventType);
  }

  static isTreatmentEvent(eventType) {
    return [
      'mastitis',
      'lame',
      'breedingDifficulty',
      'perinatalDifficulty',
      'injury',
      'infect',
      'otherDifficulty'
    ].includes(eventType);
  }

  static isCalfEvent(eventType) {
    return [
      'observation',
      'wean',
      'dehorn',
      'castration',
      'fattening',
    ].includes(eventType);
  }

  static isReproductionEvent(eventType) {
    return [
      'freshCheck',
      'hatsujo',
      'tanetsuke',
      'ninshinkantei',
      'abort',
      'bunben',
      'hormoneProgram',
      'doNotBreed',
      'embryo_recovery'
    ].includes(eventType);
  }

  static isOtherEvent(eventType) {
    return [
      'measurement',
      'sagyomemo',
      'vaccine',
      'kannyu',
      'touta',
      'heishi',
      'hoofTrim',
      'gyugunidou',
      'carcass',
    ].includes(eventType);
  }

  static isMoveEvent(eventType, isMilkFarm) {
    if (isMilkFarm) {
      return [
        'gyugunidou',
        'bunben',
        'kannyu',
        'mastitis',
        'perinatalDifficulty',
        'injury',
        'lame',
        'infect',
        'otherDifficulty',
      ].includes(eventType);
    }
    return [
      'gyugunidou',
      'bunben',
    ].includes(eventType);
  }

  /**
   * 妊娠鑑定イベントに種付けイベントを関連付けする際の
   * 対象種付け日を計算する
   * @params {Object} tanetsukeEvent 対象種付けイベント
   */
  static calcTargetBreedingDate(tanetsukeEvent) {
    const dateUtilService = new DateUtilService();
    return tanetsukeEvent.tanetsukeMethod === '移植'
      ? dateUtilService.addDays(Number(tanetsukeEvent.occurredAt), -(CowEvent.pregnancyDateAdjustmentDays)).valueOf()
      : tanetsukeEvent.occurredAt;
  }

  /**
   * 最も最後に発生したイベントを返します。
   * @param {Array} events イベントオブジェクトのリスト
   */
  static latest(events) {
    if (!events) return null;
    if (events.length === 0) return null;

    const sorted = Array.from(events).sort((a, b) => Number(b.occurredAt) - Number(a.occurredAt));
    return sorted[0];
  }

  /**
   * getAlertCow()で取得したアラートをタイムラインのイベントの形式に変換する
   * @param {Array} alerts アラート・オブジェクトのリスト
   * @return {Array} イベント・オブジェクトのリスト
   */
  static alertsToEvents(alerts) {
    let alertEvents = [];
    let index = 1;
    alerts.forEach((alert) => {
      Object.keys(this.alertTypes).forEach((alertType) => {
        if (alert[alertType] !== true) {
          return;
        }

        let alertName = this.alertTypes[alertType];
        let alertEvent = this.constructAlertEvent(alert, alertType, alertName, index);

        switch (alertType) {
        case 'heat':
          alertEvent.heatAlertAt = alert.heatAlertAt;
          break;
        case 'falseHeat':
          alertEvent.falseHeatPeakAt = alert.falseHeatPeakAt;
          break;
        case 'dysstasia':
          alertEvent.dysstasiaAlertAt = alert.dysstasiaAlertAt;
          alertEvent.dysstasiaComment = alert.dysstasiaComment;
          alertEvent.dysstasiaConfirmedStatus = AlertDysstasia.confirmedStatusCaption(alert.dysstasiaConfirmedStatus);
          alertEvent.unexpectedDysstasia = alert.unexpectedDysstasia;
          alertEvent.dysstasiaId = alert.dysstasiaId;
          break;
        case 'eartagDropped':
          alertEvent.eartagSolution = alert.eartagSolution;
          break;
        case 'calving':
          alertEvent.calvingNotes = alert.calvingNotes.map((note) => {
            return {
              alertTime: note.alertTime,
              alertLevel: AlertCalving.ALERT_LEVEL[note.alertLevel],
              status: AlertCalving.CONFIRMED_STATUS[note.confirmedStatus] || '未確認',
              comment: note.confirmedComment || ''
            };
          });
        }

        alertEvents.push(alertEvent);
        index++;
      });
    });

    return alertEvents;
  }

  static constructAlertEvent(alert, alertType, alertName, index) {
    let alertEvent = {
      displayId: `alert_${index}`,
      eventName: alertName + 'アラート',
      occurredAt: alert.alertDate,
      eventType: 'alert',
      alertType: alertType
    };

    if (alertType !== 'dysstasia') {
      return alertEvent;
    }

    if (alert.unexpectedDysstasia) {
      alertEvent['eventName'] = alertName;
      alertEvent['eventType'] = alertType;
      alertEvent['alertType'] = null;
    }

    return alertEvent;
  }

  /**
    * alertsToEvents()で変換したアラート・イベントをイベントのリストに追加してソートする
    * [release20241128] 起立困難を全量表示する対応に伴い、以下のソートルールに変更しました:
    *   - 今まで通り、出荷・へい死イベントは常に該当日の先頭に表示
    *   - 起立困難系の場合は occurred_at を比較して決定
    *   - 同時刻の場合はイベントを先に表示
    *  @param {Array} events イベントのリスト
    *  @param {Array} alerts アラートのリスト
    *  @return {Array} タイムラインに表示するイベントのリスト
    */
  static addAlertsToEvents(events, alerts) {
    if (!events) {
      events = [];
    }

    return events.concat(this.alertsToEvents(alerts))
      .sort((a, b) => {
        const dateA = moment(a.occurredAt).hour(0).minute(0).second(0).millisecond(0).valueOf();
        const dateB = moment(b.occurredAt).hour(0).minute(0).second(0).millisecond(0).valueOf();
        const dateDiff = dateB - dateA;

        if (DateUtil.isSameDay(a.occurredAt, b.occurredAt) && ['touta', 'heishi'].includes(a.eventType)) {
          return -1;
        }

        if (DateUtil.isSameDay(a.occurredAt, b.occurredAt) && ['touta', 'heishi'].includes(b.eventType)) {
          return 1;
        }

        if (dateDiff !== 0) {
          return dateDiff;
        }

        if (!a.hasOwnProperty('alertType') && !b.hasOwnProperty('alertType')) {
          const occurredAtDiff = b.occurredAt - a.occurredAt;
          if (occurredAtDiff !== 0) {
            return occurredAtDiff;
          }

          const createdAtDiff = b.createdAt - a.createdAt;
          if (createdAtDiff !== 0) {
            return createdAtDiff;
          }

          return b.id - a.id;
        }

        if (a.dysstasiaAlertAt || b.dysstasiaAlertAt) {
          return b.occurredAt - a.occurredAt;
        }

        if (a.hasOwnProperty('alertType') && b.hasOwnProperty('alertType')) {
          return this.alertDisplayOrders[a.alertType] - this.alertDisplayOrders[b.alertType];
        }

        return !b.hasOwnProperty('alertType') - !a.hasOwnProperty('alertType');
      });
  }

  static groupMastitisDiagnosisesByPart(event) {
    return [
      {label: '左前', id: 'Fl'},
      {label: '右前', id: 'Fr'},
      {label: '左後', id: 'Bl'},
      {label: '右後', id: 'Br'}
    ].map((part) => {
      return {
        label: part.label,
        mastitisDiagnosisResultOfBreast: event['mastitisDiagnosisResultOfBreast' + part.id],
        stopMilkingOfBreast: event['stopMilkingOfBreast' + part.id]
      };
    }).filter((part) =>
      part.mastitisDiagnosisResultOfBreast || part.stopMilkingOfBreast
    );
  }

  /**
   * 蹄病イベントの診断結果を部位ごとにグルーピングする
   * @param {object} event 蹄病イベントオブジェクト
   */
  static groupLameDiagnosisesByPart(event) {
    const grouped = {
      '左前': [],
      '右前': [],
      '左後': [],
      '右後': []
    };

    ['1', '2', '3', '4'].forEach((index) => {
      const key = event['lameAffectedLimb' + index];
      if (!(key in grouped)) return;

      const lameAffectedLimb = event['lameAffectedLimb' + index],
        lameAffectedPart = event['lameAffectedPart' + index] || '',
        lameCondition = event['lameCondition' + index],
        clawDiseaseName = event['clawDiseaseName' + index];

      if (!lameAffectedLimb) return;

      let lameClawZones = event['lameClawZones' + index] || '蹄';

      lameClawZones = lameClawZones.replace(/内,外/, '内外').replace(/([内外]+)/, '$1蹄');

      const lameAffectedLimbAndPart = lameAffectedPart === '蹄'
        ? lameClawZones
        : lameAffectedPart;

      grouped[key].push({
        lameAffectedLimb: lameAffectedLimb,
        lameAffectedPart: lameAffectedPart,
        lameClawZones: lameClawZones,
        lameCondition: lameCondition,
        clawDiseaseName: clawDiseaseName,
        lameAffectedLimbAndPart: lameAffectedLimbAndPart
      });
    });

    return grouped;
  }

  static dateStringToNumber(event) {
    const converted = angular.copy(event);

    Object.keys(event).forEach((fieldName) => {
      if (this.isDateField(fieldName))
        converted[fieldName] = Number(event[fieldName]);
    });

    return converted;
  }

  static isDateField(fieldName) {
    return [
      'occurredAt',
      'treatmentDiseaseDate',
      'occurredDiseaseDate',
      'startDate',
      'endDateOfBeefWashoutPeriod',
      'endDateOfMilkWashoutPeriod'
    ].some((key) => fieldName === key);
  }

  /**
   * イベントの診断結果の親フィールドを表示するべきか
   * @param {object} event イベント・オブジェクト
   * @param {string} fieldName フィールド名
   *         繁殖障害: 'Difficulties' (症状)
   *         感染症: 'Difficulties' (症状)
   *         外傷: 'Difficulties' (症状)
   *         跛行・蹄病: 'Diagnosis' (診断結果)
   *         乳房炎: 'Diagnosis' (診断結果)
   *                 'StopMilking' (盲乳)
   *                 'BacteriaOfBreast' (乳房炎菌)
   *         周産病・代謝病: 'Difficulties' (症状)
   */
  static shouldShowField(event, fieldName) {
    const target = event.eventType + fieldName;

    switch (target) {
    case 'breedingDifficultyDifficulties':
      return [
        'difficultiesOvaryStill',
        'difficultiesOvaryCystic',
        'difficultiesRemnant',
        'difficultiesMetritis',
        'difficultiesPyometra'
      ].some((key) => event[key]);
    case 'infectDifficulties':
      return [
        'difficultiesPneumonia',
        'difficultiesDiarrhea',
        'difficultiesSkinDisease',
        'otherDiseaseName'
      ].some((key) => event[key]);
    case 'injuryDifficulties':
      return [
        'difficultiesHipDislocation',
        'difficultiesTeatInjury',
        'otherDiseaseName'
      ].some((key) => event[key]);
    case 'lameDiagnosis':
      return event.groupedLameDiagnosises && [
        '左前',
        '右前',
        '左後',
        '右後'
      ].some((pos) => event.groupedLameDiagnosises[pos].length > 0);
    case 'mastitisDiagnosis':
      return [
        'mastitisDiagnosisResultOfBreastFl',
        'mastitisDiagnosisResultOfBreastFr',
        'mastitisDiagnosisResultOfBreastBl',
        'mastitisDiagnosisResultOfBreastBr'
      ].some((key) => event[key]);
    case 'mastitisStopMilking':
      return [
        'stopMilkingOfBreastFl',
        'stopMilkingOfBreastFr',
        'stopMilkingOfBreastBl',
        'stopMilkingOfBreastBr'
      ].some((key) => event[key]);
    case 'mastitisBacteriaOfBreast':
      return [
        'mastitisBacteriaOfBreastFl',
        'mastitisBacteriaOfBreastFr',
        'mastitisBacteriaOfBreastBl',
        'mastitisBacteriaOfBreastBr'
      ].some((key) => event[key]);
    case 'perinatalDifficultyDifficulties':
      return [
        'difficultiesKetosis',
        'difficultiesFattyLiver',
        'difficultiesAbomasumDisplacement',
        'difficultiesHypocalcium',
        'difficultiesAcidosis',
        'difficultiesMetritis',
        'difficultiesDowner',
        'difficultiesStagnantFood',
        'difficultiesPlacenta',
        'difficultiesTympanites',
        'difficultiesVitaminA',
        'difficultiesUrolithiasis'
      ].some((key) => event[key]);
    default:
      return false;
    }
  }

  /**
   * 投薬した薬品情報を総合し、cow_eventテーブル用の形式に変換します。
   * @param {Array<Object>} medicines 投薬内容
   * @param {Date|unixtime} occurredAt 投薬日
   * @return {Object}
   *
   * [戻り値の例]
   * {
   *   masterMedicineIds: '1、10、15',
   *   medicineCapacities: '5、、3',
   *   medicineMethods: '経口投与、塗布、',
   *   maxMilkMedicineName: '薬品A',
   *   maxBeefMedicineName: '薬品C',
   *   endDateOfMilkWashoutPeriod: 1644418800000,
   *   endDateOfBeefWashoutPeriod: 1644850800000
   * }
   */
  static summarizeMedication(medicines, occurredAt) {
    const masterMedicineIds = [];
    const medicineCapacities = [];
    const medicineMethods = [];

    let maxMilkPeriod = 0;
    let maxMilkMedicineName = '';
    let maxBeefPeriod = 0;
    let maxBeefMedicineName = '';

    medicines.forEach((m) => {
      masterMedicineIds.push(m.id);
      medicineCapacities.push(m.capacity);
      medicineMethods.push(m.method);

      const milkWashoutPeriod = Number(m.milkWashoutPeriod || '0');
      if (milkWashoutPeriod > maxMilkPeriod) {
        maxMilkPeriod = milkWashoutPeriod;
        maxMilkMedicineName = m.name;
      }

      const beefWashoutPeriod = Number(m.beefWashoutPeriod || '0');
      if (beefWashoutPeriod > maxBeefPeriod) {
        maxBeefPeriod = beefWashoutPeriod;
        maxBeefMedicineName = m.name;
      }
    });

    const result = {
      masterMedicineIds: masterMedicineIds.join('、'),
      medicineCapacities: medicineCapacities.join('、'),
      medicineMethods: medicineMethods.join('、'),
      maxMilkMedicineName: maxMilkMedicineName,
      maxBeefMedicineName: maxBeefMedicineName,
      endDateOfMilkWashoutPeriod: null,
      endDateOfBeefWashoutPeriod: null
    };

    const date = DateUtil.startOfDay(occurredAt);
    if (maxMilkPeriod > 0) {
      const endDate = DateUtil.addDays(date, Math.ceil(maxMilkPeriod));
      result.endDateOfMilkWashoutPeriod = DateUtil.toMSec(endDate);
    }
    if (maxBeefPeriod > 0) {
      const endDate = DateUtil.addDays(date, Math.ceil(maxBeefPeriod));
      result.endDateOfBeefWashoutPeriod = DateUtil.toMSec(endDate);
    }

    return result;
  }

  static isIncludedWashoutPeriod(
    occurredAt,
    isMilkFarm,
    endDateOfBeefWashoutPeriod,
    endDateOfMilkWashoutPeriod
  ) {
    if (isMilkFarm) {
      if (!endDateOfBeefWashoutPeriod && !endDateOfMilkWashoutPeriod) return false;

      let endDate = endDateOfBeefWashoutPeriod;
      if (!endDate || DateUtil.isBeforeDay(endDate, endDateOfMilkWashoutPeriod)) {
        endDate = endDateOfMilkWashoutPeriod;
      }
      if (!endDate) return false;

      return DateUtil.isSameOrBeforeDay(occurredAt, endDate);
    } else {
      if (!endDateOfBeefWashoutPeriod) return false;

      return DateUtil.isSameOrBeforeDay(occurredAt, endDateOfBeefWashoutPeriod);
    }
  }
}
