class MobileBulkentryErrorModalController {
  constructor(
    $modalInstance,
    message,
    details
  ) {
    'ngInject';

    this.message = message;
    this.details = details;
    this.$modalInstance = $modalInstance;
  }

  onOkClick() {
    this.$modalInstance.close({
      status: {
        ok: true,
      },
    });
  }
}

app.controller('MobileBulkentryErrorModalController', MobileBulkentryErrorModalController);
