class MasterCowLabelEditController {
  constructor(
    $modalInstance,
    params,
    MasterCowLabelAPI,
    blockUI,
    $timeout
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.MasterCowLabelAPI = MasterCowLabelAPI;
    this.$timeout = $timeout;
    this.blockUI = blockUI;

    this.initialize(params);
  }

  initialize(params) {
    this.title = params.title;
    this.model = params.model || {name: '', notes: ''};
    this.viewMode = new ViewMode(params.viewMode);

    this.invalid = this.viewMode.isCreate() ? true : false;
    this.errorMessage = {};
  }

  validate() {
    this.invalid = true;
    const model = this.model;

    if (!model.name) return;

    this.invalid = false;
  }

  cancel() {
    this.$modalInstance.dismiss();
  }

  save() {
    if (this.viewMode.isCreate()) {
      this.create();
    } else if (this.viewMode.isUpdate()) {
      this.update();
    } else if (this.viewMode.isDelete()) {
      this.delete();
    }
  }

  create() {
    const params = {
      name: this.model.name,
      notes: this.model.notes,
    };
    const procedure = this.MasterCowLabelAPI.create(params);
    this.callApi(procedure);
  }

  update() {
    const procedure = this.MasterCowLabelAPI.update(this.model);
    this.callApi(procedure);
  }

  delete() {
    const procedure = this.MasterCowLabelAPI.delete(this.model.id);
    this.callApi(procedure);
  }

  callApi(procedure) {
    this.blockUI.start('更新中');
    this.errorMessage = {};

    procedure
      .then(() => {
        this.blockUI.done(() => {
          this.blockUI.start(`個体ラベルの${this.viewMode.label}が完了しました`);
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close();
      })
      .catch((res) => {
        const items = res.data.messages;
        items.forEach((item) => {
          if (item.field) {
            this.errorMessage[item.field] = item.message;
          } else {
            this.errorMessage.message = item.message;
          }
        });
        this.blockUI.stop();
      });
  }

  isEditing() {
    return this.viewMode.isCreate() || this.viewMode.isUpdate();
  }
}

app.controller('MasterCowLabelEditController', MasterCowLabelEditController);
