class FatteningReportItemDialog {
  constructor(
    $modal,
    blockUI
  ) {
    'ngInject';
    this.$modal = $modal;
    this.blockUI = blockUI;
  }

  execute() {
    const config = {
      animation: true,
      templateUrl: 'menu/report/fattening/config/item.html',
      controller: 'FatteningReportItemController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      resolve: {
        params: () => {
          return {
            isLegacy: true
          };
        }
      }
    };

    return this.$modal.open(config).result.then((result) => {
      return result;
    });
  }
}

app.service('FatteningReportItemDialog', FatteningReportItemDialog);
