// eslint-disable-next-line no-unused-vars
class AlertDysstasiaDetectionThreshold {
  static get MIN_THRESHOLD() {
    return 16;
  }

  static get MAX_THRESHOLD() {
    return 50;
  }

  static get DEFAULT_THRESHOLD() {
    return 25;
  }

  static get NOT_SPECIFIED_MONTH_AGE_TO() {
    return 9999;
  }

  static toViewModel(params) {
    return params.map((p) => {
      const temp = Object.assign({}, p);
      temp.disabledMonthAgeTo = temp.monthAgeTo === AlertDysstasiaDetectionThreshold.NOT_SPECIFIED_MONTH_AGE_TO;
      if (temp.disabledMonthAgeTo) temp.monthAgeTo = null;
      return temp;
    });
  }

  static createRequestParams(input) {
    return input.map((t) => {
      const temp = Object.assign({}, t);
      if (temp.disabledMonthAgeTo) temp.monthAgeTo = AlertDysstasiaDetectionThreshold.NOT_SPECIFIED_MONTH_AGE_TO;
      delete temp.disabledMonthAgeTo;
      return temp;
    });
  }

  static validate(input) {
    const {
      threshold,
      monthAgeFrom,
      monthAgeTo,
      disabledMonthAgeTo
    } = input;

    if (!threshold) return false;
    if ([null, undefined, ''].includes(monthAgeFrom)) return false;
    if (disabledMonthAgeTo === false && [null, undefined, ''].includes(monthAgeTo)) return false;

    if (threshold < AlertDysstasiaDetectionThreshold.MIN_THRESHOLD) return false;
    if (threshold > AlertDysstasiaDetectionThreshold.MAX_THRESHOLD) return false;

    return true;
  }
}
