// eslint-disable-next-line no-unused-vars
class CowCalvingSensorHistory {
  static get PRIFIX_NUMBER() {
    return '00100';
  }

  static get FIRST_NUMBER() {
    return {
      START_INDEX: 5,
      LENGTH: 3
    };
  }

  static get LAST_NUMBER() {
    return {
      START_INDEX: 8,
      LENGTH: 4
    };
  }

  /**
   * 牛個体に関連付けられた分娩センサー履歴のリストから、現在の履歴の分娩センサー番号を返す
   *
   * @param {Array<Object>} cowCalvingSensorHistories 分娩センサー履歴のリスト
   * @return {string}
   */
  static currentCalvingSensorNumber(cowCalvingSensorHistories) {
    const currentTime = today().getTime();

    const finded = cowCalvingSensorHistories.find((history) => {
      return (currentTime >= history.startDate) && (currentTime < history.endDate);
    });

    if (!finded) return '';

    return finded.calvingSensorNumber;
  }

  /**
   * 牛個体に関連付けられた分娩センサー履歴のリストから、現在の履歴を返す
   *
   * @param {Array<Object>} cowCalvingSensorHistories 分娩センサー履歴のリスト
   * @return {Object}
   */
  static currentCalvingSensorHistory(cowCalvingSensorHistories) {
    const currentTime = today().getTime();

    const finded = cowCalvingSensorHistories.find((history) => {
      return (currentTime >= history.startDate) && (currentTime < history.endDate);
    });

    return finded || {};
  }

  /**
   * 分娩センサー番号を表示/入力用の番号に分割してオブジェクトで返す
   *
   * ex.
   *  CowCalvingSensorHistory.splitCalvingSensorNumber('001001234567')
   *  => { firstNumber: 1234, lastNumber: 567 }
   *
   *  @param {string} calvingSensorNumber 分娩センサー番号
   *  @return {Object}
   */
  static splitCalvingSensorNumber(calvingSensorNumber) {
    const firstNumber = calvingSensorNumber.substr(
      CowCalvingSensorHistory.FIRST_NUMBER.START_INDEX, CowCalvingSensorHistory.FIRST_NUMBER.LENGTH);
    const lastNumber = calvingSensorNumber.substr(
      CowCalvingSensorHistory.LAST_NUMBER.START_INDEX, CowCalvingSensorHistory.LAST_NUMBER.LENGTH);

    return {
      firstNumber: firstNumber,
      lastNumber: lastNumber
    };
  }

  /**
   * 表示/入力用の番号から分娩センサー番号を生成する
   *
   * ex.
   *  CowCalvingSensorHistory.generateCalvingSensorNumber('1234', '567')
   *  => '001001234567'
   *
   *  @param {string} firstNumber 4桁の番号
   *  @param {string} lastNumber 3桁の番号
   *  @return {string}
   */
  static generateCalvingSensorNumber(firstNumber, lastNumber) {
    const number1 = firstNumber.padStart(CowCalvingSensorHistory.FIRST_NUMBER.LENGTH, '0');
    const number2 = lastNumber.padStart(CowCalvingSensorHistory.LAST_NUMBER.LENGTH, '0');

    return `${CowCalvingSensorHistory.PRIFIX_NUMBER}${number1}${number2}`;
  }

  /**
   * 有効な分娩センサー番号かを判定する
   * Falsyな値か、数値以外が文字列に存在した場合は無効とする。
   *
   * @param {string} firstNumber 4桁の番号
   * @param {string} lastNumber 3桁の番号
   * @return {bool} 有効な番号ならばtrue、無効ならばfalseを返す。
   */
  static validate(firstNumber, lastNumber) {
    if (!firstNumber && !lastNumber) return false;

    return [firstNumber, lastNumber].every((number) => {
      if (!number) return true;
      return StringUtil.isDigit(number);
    });
  }

  /**
   * 表示用のオブジェクトを返す
   *
   * ex.
   *  CowCalvingSensorHistories.toDisplayObj({
   *    id: 1,
   *    calvingSensorNumber: '001001234567',
   *    startDate: 1509375600000,
   *    endDate: 1511967600000
   *  })
   *  => {
   *    id: 1,
   *    calvingSensorNumber: '1234-567',
   *    startDate: '2017-10-31',
   *    endDate: '2017-11-30'
   *  }
   *
   *  @param {Object} obj 分娩センサー履歴のオブジェクト
   *  @return {Object}
   */
  static toDisplayObj(obj) {
    const splittedCalvingSensorNumber = CowCalvingSensorHistory.splitCalvingSensorNumber(obj.calvingSensorNumber);
    const calvingSensorNumber = `${splittedCalvingSensorNumber.firstNumber}-${splittedCalvingSensorNumber.lastNumber}`;
    const startDate = DateUtil.toYYYYMMDD(obj.startDate);
    const endDate = DateUtil.isInfiniteDate(obj.endDate) ? '-' : DateUtil.toYYYYMMDD(obj.endDate);

    return {
      id: obj.id,
      calvingSensorNumber: calvingSensorNumber,
      startDate: startDate,
      endDate: endDate
    };
  }

  /**
   * 表示用の分娩センサー番号に変換して返す
   *
   * ex.
   *  CowCalvingSensorHistories.formatCalvingSensorNumber('001001234567')
   *  => '1234-567',
   *
   *  @param {string} obj 分娩センサー番号
   *  @return {string}
   */
  static formatCalvingSensorNumber(calvingSensorNumber) {
    const temp = CowCalvingSensorHistory.splitCalvingSensorNumber(calvingSensorNumber);
    return `${temp.firstNumber}-${temp.lastNumber}`;
  }
}
