class SelectionAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/selection');
  }
}

app.service('SelectionAPI', SelectionAPI);
