class HeatIndexAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  /**
   * 指定した牛個体の3時間毎の発情指数を返す。
   *
   * @param {number} cowId
   * @param {Date} startAt 対象期間の開始日時
   * @param {Date} endAt 対象期間の終了日時
   * @return {Array.{object}}
   */
  hourlySummary(cowId, startAt, endAt) {
    const params = [
      `cow_id=${cowId}`,
      `start_at=${DateUtil.toMSec(startAt)}`,
      `end_at=${DateUtil.toMSec(endAt)}`,
      'summary_type=SLICE3H'
    ].join('&');

    return this.APIService.get(`/activity/hourly/heat?${params}`);
  }

  /**
   * 指定した牛個体の日毎の最大発情指数を返す。
   *
   * @param {number} cowId
   * @param {Date} startDate 対象期間の開始日
   * @param {Date} endDate 対象期間の終了日
   * @return {Array.{object}}
   */
  dailySummary(cowId, startDate, endDate) {
    const startDateW3C = DateUtil.toW3CFormat(startDate);
    const endDateW3C = DateUtil.toW3CFormat(endDate);
    const params = `start_date=${startDateW3C}&end_date=${endDateW3C}`;

    return this.APIService.get(`/activity/cow/${cowId}/heat/max?${params}`);
  }
}

app.service('HeatIndexAPI', HeatIndexAPI);
