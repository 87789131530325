function headerNotificationsComponent() {
  'ngInject';
  return {
    controller: 'HeaderNotificationsController',
    templateUrl: 'components/notifications/header-notifications/index.html',
    bindings: {
      tooltipOpened: '='
    }
  };
}

app.component('headerNotifications', headerNotificationsComponent());
