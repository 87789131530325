app.controller('MobileCowDetailController', function(
  $scope,
  $rootScope,
  $q,
  $interval,
  FarmService,
  DateTimeUtilFactory,
  DetailsStateFactory,
  $stateParams,
  CowService,
  CowEventsService,
  CowState,
  FarmKind,
  $state,
  PreviousState,
  DateUtilService,
  BleIdService,
  ActiveAmountService,
  UtilService,
  Dictionary,
  ChartGenerator,
  MobileEditLabelModal,
  MobileEditBleIdModal,
  MobileBleIdHistoryModal,
  modalDialogFactory,
  CowCalvingSensorHistoryAPI,
  MobileEditCalvingSensorHistoryModal,
  MobileCalvingSensorHistoryModal,
  $window,
  $location,
  MasterMarketService,
  SessionCache,
  GenomCowAPI,
  $timeout,
  $modal,
  EventRegistrationSelectionService
) {
  'ngInject';

  this.$window = $window;

  const dUtil = DateTimeUtilFactory;
  const dState = DetailsStateFactory;
  const now = dUtil.parseClientDate(new Date());
  let farmKind, farm;
  const deregisters = [];

  $scope.$on('$destroy', ()=> {
    deregisters.forEach((d) => d());
    $window.onresize = null;
  });

  $scope.activityDaysOptions = [{
    label: '7日',
    value: '7'
  }, {
    label: '30日',
    value: '30'
  }, {
    label: '90日',
    value: '90'
  }];

  $scope.previous = returnToPreviousState;
  $scope.activityPeriod = 30; //活動量グラフの表示期間

  // タブ共有
  $scope.chartInit = {
    activityStackedBarChart: false
  };

  $scope.tabs = [
    'information',
    'timeline'
  ];

  // 起動処理を行う
  onInit();

  $scope.$$listeners['deprecatedMobileCowDetail:notifyForceRefresh'] = [];
  $scope.$on('deprecatedMobileCowDetail:notifyForceRefresh', () => {
    this.needRefreshTimeline = true;
  });

  $scope.$on('deprecatedMobileCowDetail:changeTab', () => {
    $scope.changeTab('timeline');
  });

  $scope.changeTab = (newTab) => {
    $scope.selectedTabIndex = $scope.tabs.findIndex((tab) => tab === newTab);

    setTimeout(() => {
      setTabResultHeight();
    });

    if (newTab !== 'timeline' || !this.needRefreshTimeline) return;

    $rootScope.$emit('deprecatedMobileCowDetail:refreshEvents');
    this.needRefreshTimeline = false;
  };

  $scope.isTabActive = (tabName) => {
    if (!$scope.tabs || $scope.tabs.length === 0) {
      return false;
    }
    return $scope.tabs[$scope.selectedTabIndex] === tabName;
  };

  /**
   * 牛ラベルリスト作成
   * @param {string} cowLabels ラベル文字列 ([ラベル1],[ラベル2],[ラベル3])
   * @return {array}
   */
  function toCowLabelList(cowLabels) {
    cowLabels = cowLabels || '';
    const characters = ['[', ']'];
    const list = [];
    _.each(cowLabels.split(','), (label) => {
      if (label) list.push({text: _.trim(label, characters)});
    });
    return list;
  }

  // サマリータブ用
  $scope.getIdForSubString = (id, start, end) => {
    return id ? String(id).substr(start, end) : '';
  };

  $scope.splitCowUid = (uid, index) => {
    switch (index) {
    case 0:
      return $scope.getIdForSubString(uid, 0, 5) || '-';
    case 1:
      return $scope.getIdForSubString(uid, 5, 4) || '-';
    case 2:
      return $scope.getIdForSubString(uid, 9, 1) || '-';
    }
  };

  $scope.countDays = (date) => {
    if (!date) return '';
    return DateUtilService.countDays(Number(date), new Date().getTime()) + '日';
  };

  $scope.diffDays = (date, unit = '日') => {
    if (!date) return '';
    return DateUtilService.diffDays(Number(date), new Date().getTime()) + unit;
  };

  $scope.replaceBlank = UtilService.replaceBlank;

  $scope.unpregnantDays = (start, end) => {
    if (start) {
      return (Number(start) < Number(end)) ?
        passedByStartAndEnd(start, end) :
        passedByStartAndEnd(start, now.getTime());
    }
  };

  $scope.shouldShowActivityTab = () => {
    return $scope.cowBleId;
  };

  $scope.shouldShowScatterPlotTab = () => {
    return $scope.cowBleId;
  };

  $scope.shouldShowMilkingTab = () => {
    return typeof $scope.cowBleId !== 'undefined' && $scope.isMilkFarm;
  };

  $scope.shouldShowGenomTab = () => {
    return $scope.showBeefGenom && $scope.c && $scope.c.gender === 'メス';
  };

  $scope.shouldShowMotherCowLink = () => {
    return $scope.c && $scope.c.motherCowId ? true : false;
  };

  /**
  * 活動量推移グラフのデータが空かどうか
  */
  $scope.isActivityStackBarChartEmpty = () => {
    return !$scope.chartInit.activityStackedBarChart;
  };

  /**
   * コントローラー起動処理
   */
  function onInit() {
    /**
     * 表示切り替えフラグ {bool}
     */
    $scope.isFatteningType = false; // 肥育
    $scope.isCalfType = false; // 育成
    $scope.isReproductionType = false; // 繁殖
    $scope.isMilkFarm = false;
    $scope.isMeatFarm = false;
    $scope.Dictionary = Dictionary;
    $scope.canEditFilterItem = !SessionCache.account().isItemFilterApplied();
    $scope.shouldShowSpinner = true;

    $scope.accordionState = {
      base: true,
      curving: false,
      heat: false,
      hormoneProgram: false
    };

    $scope.activityDays = ['7', '30', '90'].includes(dState.get().chartDisplayRange.toString())
      ? dState.get().chartDisplayRange.toString()
      : '30';

    $scope.hiddenLegendIds = [];
    $scope.milkingDays = 30;

    if ($stateParams.isRegisterEvent) {
      $scope.activeTab = 'timeline';
      $scope.isRegisterEvent = true;
    } else {
      $scope.isRegisterEvent = false;
    }

    // 初期化処理
    CowService.init($stateParams.cowId, $stateParams.name);
    CowEventsService.selectedCowId = $stateParams.cowId;

    // 牧場種類
    FarmService.show().then((res) => {
      farmKind = res.farmKind;
      farm = new Farm(res);
      $scope.farm = farm;
      $scope.isDairyFarm = farm.isDairy();
      $scope.isBreedingFarm = farm.isBreeding();
      $scope.isFatteningFarm = farm.isFattening();
      $scope.showHeatIndexFattening = farm.showHeatIndexFattening();
      $scope.showCowName = !farm.useName();
      $scope.showCalvingSensor = farm.useAlertCalving();
      $scope.showBeefGenom = farm.showBeefGenom();
      $scope.showGebvIndexSetting = farm.isBeefGenomLinkage();
      $scope.useCalfManagement = farm.useCalfManagement();
      return getCow($stateParams.cowId);
    }).then(() => {
      return $q.all([
        BleIdService.cowBleIds($stateParams.cowId),
        CowCalvingSensorHistoryAPI.list($stateParams.cowId)
      ]).catch((error) => console.error(error));
    }).then((res) => {
      const bleIds = res[0].data;
      $scope.cowBleIds = bleIds;
      $scope.cowBleId = CowBleId.lastCowBleId(bleIds);
      $scope.currentCowBleId = CowBleId.currentCowBleId(bleIds);

      const calvingSensorHistories = res[1].data;
      $scope.cowCalvingSensorHistories = calvingSensorHistories;
      $scope.currentCowCalvingSensorNumber = CowCalvingSensorHistory.currentCalvingSensorNumber(calvingSensorHistories);

      $scope.selectedChart = 'activity';

      if ($scope.shouldShowActivityTab()) {
        $scope.tabs.push('activity');
      }

      if ($scope.shouldShowScatterPlotTab()) {
        $scope.tabs.push('scatterPlot');
      }

      if ($scope.shouldShowMilkingTab()) {
        $scope.tabs.push('milking');
      }

      if ($scope.shouldShowGenomTab()) {
        setSbvRaderChartSize();
        $scope.tabs.push('genom');
      }

      if ($stateParams.caller.state === 'mobileAlertList') {
        if ($stateParams.caller.name === '発情兆候アラート' ||
          $stateParams.caller.name === '発情兆候(妊娠牛)アラート') {
          $scope.changeTab('activity');
          $scope.selectedChart = 'heat';
          $scope.activityDays = 7;
        } else if ($stateParams.caller.name === '注意牛アラート' || '疾病傾向') {
          $scope.changeTab('activity');
          $scope.activityDays = 30;
        }
      } else if ($stateParams.caller.state === 'feedLow' ||
        $stateParams.caller.state === 'waterLow' ||
        $stateParams.caller.state === 'lieHigh' ||
        $stateParams.caller.state === 'eartag-sensor-dropped') {
        $scope.changeTab('activity');
        $scope.activityDays = 30;
      } else if ($stateParams.caller.state === 'milkLow') {
        $scope.changeTab('milking');
        $scope.milkingDays = 30;
      } else if ($stateParams.caller.state === 'mobile-genomic-evaluation-list') {
        $scope.changeTab('genom');
      } else if ($stateParams.caller.state === 'ScatterPlotDrawer') {
        $scope.changeTab($stateParams.caller.activeTab);

        if ($stateParams.caller.activeTab === 'activity') {
          $scope.activityDays = 30;
        }
      } else if ($stateParams.caller.state === 'BottomNavigationCowPager') {
        $scope.changeTab($stateParams.caller.activeTab);

        if ($stateParams.caller.activeTab === 'activity') {
          $scope.selectedChart = $stateParams.caller.selectedChart;
          $scope.activityDays = $stateParams.caller.activityDays;
        }
      }

      const queryParams = $location.search();
      if (queryParams.activetab) {
        switch (queryParams.activetab) {
        case 'activity-heat':
          $scope.changeTab('activity');
          $scope.selectedChart = 'heat';
          $scope.activityDays = 7;
          break;
        case 'activity-daily':
          $scope.changeTab('activity');
          $scope.activityDays = 30;
          break;
        }
      }

      // コンテンツの高さを設定する
      setTabResultHeight();
      $window.onresize = () => {
        setTabResultHeight();
        setSbvRaderChartSize();
      };

      // アクティブタブリストの位置調整
      $scope.$watch('selectedTabIndex', () => {
        if (typeof $scope.selectedTabIndex === 'undefined') return;

        const tab = angular.element('.js-dt-list');
        const activeTabName = $scope.tabs[$scope.selectedTabIndex];
        const activeTab = tab.find(`.${activeTabName}`);
        const left = activeTab.offset().left;
        const right = (tab.scrollLeft() + activeTab.offset().left + activeTab.outerWidth() + 70) - $window.innerWidth;

        if (left < 0) {
          tab.animate({scrollLeft: 0}, 200);
        } else if (right > 0) {
          tab.animate({scrollLeft: right}, 200);
        }

        $timeout(() => {
          setSbvRaderChartSize();
        }, 1000);
      });

      openCalvingSensorAlert();

      setTimeout(() => {
        $scope.shouldShowSpinner = false;
      }, 500);
    });

    // アクセスログに起動経路を出力する
    if ($stateParams.caller) {
      $scope.callerId = $stateParams.caller.bqNotes || $stateParams.caller.state;
    }

    deregisters.push($rootScope.$on('cowDetail:refreshCowInfo', (ev, data, _, eventType) => {
      if (['abort', 'bunben'].includes(eventType)) {
        fetchCalvingSensorHistories(false);
      }

      const prevState = $scope.c.state;

      ev.stopPropagation();
      if (data && Number(data.cowId) !== Number($stateParams.cowId)) {
        return;
      }
      getCow($stateParams.cowId).then(() => {
        if (State.isShipmentOrDead(prevState) || State.isShipmentOrDead($scope.c.state)) {
          fetchBleIds(false);
          fetchCalvingSensorHistories(false);
        }
      });
    }));
  }

  /**
   * 牛個体情報をGET
   * @param {number|string} cowId 牛のcowId
   */
  function getCow(cowId) {
    return CowService.get(cowId).then((res) => {
      const currentDate = today();
      $scope.c = dUtil.parseClientDateForCowInfo(res.data);
      $scope.c = Cow.decorateCowWithDateLabel($scope.c);
      CowEventsService.selectedCow = $scope.c;

      if ($scope.c.birthday) {
        const endDate = Number($scope.c.eliminateDate) || Number($scope.c.fallingDeadDate) || currentDate;
        const dayAge = DateUtil.diffDays($scope.c.birthday, endDate);
        const monthAge = DateUtil.monthAge($scope.c.birthday, endDate, 1);
        $scope.c.birthdayDayAge = `${DateUtil.toYYMMDD($scope.c.birthday)} (${dayAge})`;
        $scope.c.birthdayMonthAge = `${DateUtil.toYYMMDD($scope.c.birthday)} (${monthAge})`;
      } else {
        $scope.c.birthdayDayAge = '-';
        $scope.c.birthdayMonthAge = '-';
      }

      $scope.c.introduceMoonAge = $scope.c.introduceDate ? DateUtil.diffMonths(new Date(Number($scope.c.introduceDate)), currentDate, 1) : '-';
      setDisplayOptionFlags(farmKind, $scope.c.state);
      setSelectedChartOptions();
      $scope.shouldShowReproductionField = !$scope.isFatteningType
        && (!$scope.isSoldOrDied || $scope.c.birthNumber >= 1);

      $scope.cowLabelList = toCowLabelList($scope.c.cowLabels);

      if ($scope.c.introduceDate) {
        $scope.c.formatIntroduceDate = DateUtil.toYYMMDD(Number($scope.c.introduceDate));
      } else {
        $scope.c.introduceDate = '-';
      }

      if ($scope.c.weightOnPurchase) {
        $scope.c.formatWeightOnPurchase = `${$scope.c.weightOnPurchase} Kg`;
      }

      if ($scope.c.purchasePrice) {
        $scope.c.formatPurchasePrice = `${$scope.c.purchasePrice} 円`;
      }

      MasterMarketService.available().then((res) => {
        const masterMarkets = res.data;
        const masterMarket = masterMarkets.find((market) => {
          return market.id === $scope.c.masterMarketId;
        });

        $scope.c.masterMarketName = masterMarket ? masterMarket.name : '';
      });

      $scope.showParentsName = $scope.isBreedingFarm || $scope.isFatteningFarm || $scope.isInfant;
      $scope.showBirthInfo = ($scope.isBreedingFarm || $scope.isFatteningFarm || $scope.isInfant) &&
        $scope.c.motherCowId;
      $scope.c.formatWeightOnBirth = $scope.c.weightOnBirth ? `${$scope.c.weightOnBirth} kg` : '';

      if ($scope.shouldShowGenomTab()) {
        fetchGenom();
      }

    }).catch((err) => console.error(err));
  }

  /**
   * センサー情報を更新
   */
  function fetchBleIds(needOpenCalvingSensorAlert = true) {
    BleIdService.cowBleIds($stateParams.cowId)
      .then((res) => {
        $scope.cowBleIds = res.data;
        $scope.cowBleId = CowBleId.lastCowBleId(res.data);
        $scope.currentCowBleId = res.data && res.data.length > 0
          ? CowBleId.currentCowBleId(res.data)
          : '';

        if (needOpenCalvingSensorAlert) openCalvingSensorAlert();
      });
  }

  /**
   * 分娩センサー情報を更新
   */
  function fetchCalvingSensorHistories(needOpenCalvingSensorAlert = true) {
    CowCalvingSensorHistoryAPI.list($stateParams.cowId)
      .then((res) => {
        $scope.cowCalvingSensorHistories = res.data;
        $scope.currentCowCalvingSensorNumber = CowCalvingSensorHistory.currentCalvingSensorNumber(res.data);

        if (needOpenCalvingSensorAlert) openCalvingSensorAlert();
      });
  }

  /**
   * ゲノム情報を更新
   */
  function fetchGenom() {
    GenomCowAPI.show($stateParams.cowId)
      .then((res) => {
        $scope.genomicEvaluations = res.data;
        const formated = GeBeefCattle.formatEvaluations($scope.genomicEvaluations);
        Object.assign($scope.c, formated);
      });
  }

  /**
   * 前のページへ戻る
   */
  function returnToPreviousState() {
    if (PreviousState.name === 'mobileCustomlistRuntime' && 'cowGroupId' in PreviousState.params) {
      window.history.back();
      return;
    }

    // 前ステートが存在しなければ、トップへGO!
    if ($stateParams.caller && $stateParams.caller.state, $stateParams.caller.params) {
      $state.go(
        $stateParams.caller.state,
        $stateParams.caller.params
      );
    } else {
      $state.go(
        (PreviousState.name || 'mobile-top'),
        PreviousState.params
      );
    }
  }

  /**
   * 牧場種類や牛状態による表示フラグの切替
   * @param {string} farmKind 牧場種類
   * @param {string} state 牛状態
   */
  function setDisplayOptionFlags(farmKind, state) {
    const endDate = Number($scope.c.eliminateDate) || Number($scope.c.fallingDeadDate) || DateUtil.today();

    $scope.isCalfPeriod = Cow.isCalfPeriod($scope.c.birthday, endDate);
    $scope.isMilkFarm = !!(FarmKind[farmKind] === 'milk');
    $scope.isMeatFarm = !!(FarmKind[farmKind] === 'meat');
    $scope.isFatteningType = !!(CowState[state] === 'fattening');
    $scope.isCalfType = !!(CowState[state] === 'calf');
    $scope.isReproductionType = !!(CowState[state] === 'reproduction');
    $scope.isSoldOrDied = CowState[state] === 'soldOrDied';
    $scope.isInfant = state === '哺育';
  }

  /**
   * グラフの選択肢を設定
   */
  function setSelectedChartOptions() {
    $scope.selectedChartOptions = [{
      label: '活動量推移',
      value: 'activity'
    }, {
      label: 'タイムバジェット',
      value: 'timeBudget'
    }];

    const isFemale = $scope.c.gender === 'メス';
    const isHeatIndexEnabledFarm = $scope.isBreedingFarm || $scope.isDairyFarm || $scope.showHeatIndexFattening;
    const isCalfManagementDisabled = !$scope.useCalfManagement || !$scope.isCalfPeriod;

    if (isFemale && isHeatIndexEnabledFarm && isCalfManagementDisabled) {
      $scope.selectedChartOptions.push({
        label: '発情指数',
        value: 'heat'
      });
    }
  }

  /**
   * コンテンツの高さを設定
   */
  function setTabResultHeight() {
    const tabResult = angular.element('.js-tab-result');
    const excludeHeight = [
      angular.element('.js-stickyAlert').outerHeight(),
      angular.element('.js-layout-header').outerHeight(),
      angular.element('.js-dt-list').outerHeight(),
      angular.element('.js-nav-footer').outerHeight()
    ].reduce((sum, current) => {
      return sum + current;
    }, 0);
    const height = $window.innerHeight - excludeHeight;

    tabResult.css({
      height: height
    });
  }

  function setSbvRaderChartSize() {
    const baseSize = $window.innerWidth - 20;

    $scope.sbvRaderChartSize = {
      width: baseSize,
      height: baseSize,
      chartWidth: baseSize - 120,
      labelWidth: baseSize - 70,
    };
  }

  function openCalvingSensorAlert() {
    if ($scope.currentCowBleId || !$scope.currentCowCalvingSensorNumber) return;

    modalDialogFactory.showMobileAlertDialog({
      title: '警告',
      texts: [
        '分娩兆候アラートをご利用いただくためには、活動量センサーと分娩センサーが装着されている必要があります。',
        `分娩兆候アラートの算出を行いたい場合は活動量センサーを装着してください。
        算出を行わない場合は分娩センサーを解除してください。`
      ]
    });
  }

  /*
  戻るリンクを表示するべきか？
  */
  $scope.shouldShowPreviousLink = () => {
    return !$stateParams.isFromNavi && PreviousState.name !== 'mobileCowDetail';
  };

  /**
  * 種雄牛フィールドの名前
  */
  $scope.bullNameLabel = () => {
    return $scope.c.latestPregnancyDate ? '種雄牛名' : '最新種雄牛名';
  };

  /**
   * 種雄牛の名前
   */
  $scope.bullName = () => {
    return ($scope.c.latestPregnancyDate ? $scope.c.bullName : $scope.c.latestBullName) || '-';
  };

  /**
  * 個体ラベルの編集
  */
  $scope.editLabels = () => {
    MobileEditLabelModal.open($scope.c).then((result) => {
      $scope.c.cowLabels = result.cowLabels;
      $scope.cowLabelList = result.cowLabelList;
    }).catch((result) => {
      if (result.status === 'error') console.error(result);
    });
  };

  /**
  * センサー番号の編集
  */
  $scope.editBleId = () => {
    const cowBleIds = ($scope.cowBleIds || []).map((b) => new CowBleId(b));

    const openEditBleIdModal = (param) => {
      MobileEditBleIdModal.open(param).result.then((r) => {
        if (r) {
          fetchBleIds();
        }
      });
    };

    const openBleIdHistoryModal = (param) => {
      MobileBleIdHistoryModal.open(param).result.then((r) => {
        if (r) {
          fetchBleIds();
        }
      });
    };

    if ($scope.currentCowBleId) {
      const template = '<span>この牛はセンサー番号が適用中です。</span><br><span>操作する内容を選択してください。</span>';
      modalDialogFactory.showMultipleChoicesConfirm({
        template,
        choices: [
          {text: 'センサー番号を交換する', value: 'change'},
          {text: 'センサー番号を解除する', value: 'unequip'},
          {text: 'センサー番号履歴を確認する', value: 'records'}
        ]
      }).result.then((r) => {
        if (r.value === 'change') {
          openEditBleIdModal({type: r.value, cowId: $stateParams.cowId, bleIds: cowBleIds});
        }

        if (r.value === 'unequip') {
          openEditBleIdModal({type: r.value, cowId: $stateParams.cowId, bleIds: cowBleIds});
        }

        if (r.value === 'records') {
          openBleIdHistoryModal({cowId: $stateParams.cowId, bleIds: cowBleIds});
        }
      });
    } else if (cowBleIds.length > 0) {
      const template = '<span>この牛はセンサー番号を適用していません。</span><br><span>操作する内容を選択してください。</span>';
      modalDialogFactory.showMultipleChoicesConfirm({
        template,
        choices: [
          {text: 'センサー番号を適用する', value: 'equip'},
          {text: 'センサー番号履歴を確認する', value: 'records'}
        ]
      }).result.then((r) => {
        if (r.value === 'equip') {
          openEditBleIdModal({type: r.value, cowId: $stateParams.cowId, bleIds: cowBleIds});
        }

        if (r.value === 'records') {
          openBleIdHistoryModal({cowId: $stateParams.cowId, bleIds: cowBleIds});
        }
      });
    } else {
      openEditBleIdModal({type: 'equip', cowId: $stateParams.cowId, bleIds: cowBleIds});
    }
  };

  /**
  * 分娩センサー履歴の編集
  */
  $scope.editCalvingSensorHistory = () => {
    const cowCalvingSensorHistories = $scope.cowCalvingSensorHistories || [];

    const openEditModal = (param) => {
      MobileEditCalvingSensorHistoryModal.open(param).result.then((r) => {
        if (r) fetchCalvingSensorHistories();
      });
    };

    const openHistoryModal = (param) => {
      MobileCalvingSensorHistoryModal.open(param).result.then((r) => {
        if (r) fetchCalvingSensorHistories();
      });
    };

    if ($scope.currentCowCalvingSensorNumber) {
      const template = '<span>この牛は分娩センサー番号が適用中です。</span><br><span>操作する内容を選択してください。</span>';
      modalDialogFactory.showMultipleChoicesConfirm({
        template,
        choices: [
          {text: '分娩センサー番号を解除する', value: 'unequip'},
          {text: '分娩センサー番号履歴を確認する', value: 'records'}
        ]
      }).result.then((r) => {
        if (r.value === 'unequip') {
          openEditModal({type: r.value, cowId: $stateParams.cowId, calvingSensorHistories: cowCalvingSensorHistories});
        }

        if (r.value === 'records') {
          openHistoryModal({cowId: $stateParams.cowId, calvingSensorHistories: cowCalvingSensorHistories});
        }
      });
    } else if (cowCalvingSensorHistories.length > 0) {
      const template = '<span>この牛は分娩センサー番号を適用していません。</span><br><span>操作する内容を選択してください。</span>';
      modalDialogFactory.showMultipleChoicesConfirm({
        template,
        choices: [
          {text: '分娩センサー番号を適用する', value: 'equip'},
          {text: '分娩センサー番号履歴を確認する', value: 'records'}
        ]
      }).result.then((r) => {
        if (r.value === 'equip') {
          openEditModal({type: r.value, cowId: $stateParams.cowId, calvingSensorHistories: cowCalvingSensorHistories});
        }

        if (r.value === 'records') {
          openHistoryModal({cowId: $stateParams.cowId, calvingSensorHistories: cowCalvingSensorHistories});
        }
      });
    } else {
      openEditModal({type: 'equip', cowId: $stateParams.cowId, calvingSensorHistories: cowCalvingSensorHistories});
    }
  };

  $scope.isCowNoAlphaNumeric = function() {
    return farm.useName();
  };

  $scope.isAccordionOpen = function(id) {
    return $scope.accordionState[id];
  };

  $scope.toggleAccordionState = function(id) {
    if (!id in $scope.accordionState) return;
    $scope.accordionState[id] = !$scope.accordionState[id];
  };

  $scope.shouldShowCalvingSensorSetting = function() {
    if (!$scope.c) return false;
    return Cow.canEquipCalvingSensor($scope.c, $scope.currentCowBleId) || !!$scope.currentCowCalvingSensorNumber;
  };

  $scope.openSettingGebvIndexModal = () => {
    $modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'mobile/genom/setting/gebv-index/index.html',
      controller: 'GenomSettingGebvIndexController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            helpTemplateUrl: 'mobile/genom/setting/gebv-index/help.html'
          };
        }
      }
    }).result.then(() => {
      fetchGenom();
    });
  };

  $scope.updateSensors = (eventType) => {
    if (['abort', 'bunben'].includes(eventType)) {
      fetchCalvingSensorHistories(false);
    }
  };

  $scope.fetchMoreEvent = () => {
    $scope.doFetchEvent = {};
  };

  $scope.onChangeSelectedChart = (value) => {
    $scope.currentSelectedChart = value;

    // NOTE: um-form-input 使用時は u-model で双方向でバインドされるため、明示的な更新は不要だった。
    // しかし react2angular でラップした場合は明示的に更新する必要がある。
    // またこの React コンポーネントにてトリガーされたイベントは AngularJS の Digest サイクル外で発生するため、
    // そのままでは変更が反映されない。よって $timeout でラップし、 Digest サイクルをトリガーしている。
    $timeout(() => {
      $scope.selectedChart = value;
    }, 0);
  };

  $scope.onChangePeriod = (period) => {
    $scope.currentActivityDays = period;
  };

  $scope.ctrl = {
    displayReactEventRegister: () => {
      EventRegistrationSelectionService.displayReactEventRegister($scope.c.cowId, {state: 'mobileCowDetail'}, $scope.c);
    },
    displayEventSelection: () => {
      EventRegistrationSelectionService.displayEventSelection($scope.c.cowId, {state: 'mobileCowDetail'}, $scope.c);
    },
    goToDetail: (cowId, name, state, activeTab = $scope.tabs[$scope.selectedTabIndex]) => {
      const caller = {
        name,
        state,
        activeTab
      };

      if (state === 'BottomNavigationCowPager') {
        caller.selectedChart = $scope.currentSelectedChart || $scope.selectedChart;
        caller.activityDays = $scope.currentActivityDays;
      }

      $state.go('mobileCowDetail', {cowId, caller});
    },
    goToCowGroupList: () => {
      const caller = {
        name: '個体詳細',
        state: 'CowDetail',
      };

      $state.go('mobileCowGroupList', {caller});
    },
    getCow: (cowId) => getCow(cowId),
  };

  $scope.shouldShowStopShipment = () => {
    if (!$scope.farm) return false;
    if ($scope.farm.stopShipmentDisplay() === 'none') {
      return false;
    }

    return $scope.shouldShowStopShipmentMilking() || $scope.shouldShowStopShipmentBeef();
  };

  $scope.shouldShowStopShipmentMilking = () => {
    if (!$scope.c) return false;
    if (!$scope.isMilkFarm) return false;

    switch ($scope.farm.stopShipmentDisplay()) {
    case 'default': {
      return $scope.c.stopShipmentMilkingFlg;
    }
    case 'period': {
      const endDate = DateUtil.addDays(
        Number($scope.c.endDateOfMilkWashoutPeriod),
        $scope.farm.stopShipmentDisplayPeriod()
      );
      return DateUtil.isSameOrAfterDay(endDate, DateUtil.today());
    }
    default: {
      return false;
    }
    }
  };

  $scope.shouldShowStopShipmentBeef = () => {
    if (!$scope.c) return false;

    switch ($scope.farm.stopShipmentDisplay()) {
    case 'default': {
      return $scope.c.stopShipmentBeefFlg;
    }
    case 'period': {
      const endDate = DateUtil.addDays(
        Number($scope.c.endDateOfBeefWashoutPeriod),
        $scope.farm.stopShipmentDisplayPeriod()
      );
      return DateUtil.isSameOrAfterDay(endDate, DateUtil.today());
    }
    default: {
      return false;
    }
    }
  };
});
