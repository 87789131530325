class AlertDysstasiaController {
  constructor(
    $rootScope,
    $modal,
    $state,
    $stateParams,
    AlertService,
    DateUtilService,
    SelectCowService,
    CowService,
    ViewStateAPI
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$modal = $modal;
    this.$state = $state;
    this.AlertService = AlertService;
    this.DateUtilService = DateUtilService;
    this.SelectCowService = SelectCowService.init();
    this.CowService = CowService;
    this.ViewStateAPI = ViewStateAPI;

    this.showResolved = $stateParams.resolved === 'true';
    this.title = this.showResolved ? '起立困難(確認済・回復済)' : '起立困難アラート';
    if (this.showResolved) {
      this.sortOptionKey = `${this.$state.current.name}-resolved.sortOption`;
    } else {
      this.sortOptionKey = `${this.$state.current.name}.sortOption`;
    }

    this.callbackParam = {
      state: 'alertDysstasia',
      params: $stateParams
    };

    this.init();
  }

  init() {
    this.loading = true;

    this.sortOption = LocalStorageEntry.use(this.$rootScope.userName, this.sortOptionKey, {
      column: 'combinedGroupName', order: 'asc'
    });

    this.AlertService.getAlertDysstasia().then((res) => {
      this.rawAlerts = res.data.map((alert) => AlertDysstasia.toViewModel(alert, new Date()));

      this.index();
      this.sort();

      this.loading = false;
    });
  }

  index() {
    this.alerts = this.rawAlerts.filter((alert) => {
      if (this.showResolved) {
        return alert.resolved;
      }
      return !alert.resolved;
    });
  }

  sort() {
    const coefficient = this.sortOption.order === 'asc' ? 1 : -1;
    this.alerts.sort((a, b) => {
      if (a[this.sortOption.column] > b[this.sortOption.column]) {
        return 1 * coefficient;
      } else if (a[this.sortOption.column] < b[this.sortOption.column]) {
        return -1 * coefficient;
      } else {
        return 0;
      }
    });
  }

  report(alert) {
    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'alert/dysstasia/dysstasia-report.html',
      controller: 'DysstasiaReportController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'modal-select-standard',
      resolve: {
        params: () => {
          return {
            id: alert.id,
            confirmed: alert.confirmed,
            confirmedStatus: alert.confirmedStatus,
            comment: alert.comment
          };
        }
      }
    });

    modalInstance.result.then((result) => {
      alert.confirmed = true;
      alert.confirmedLabel = '確認済';
      alert.confirmedStatus = result.confirmedStatus;
      alert.confirmedStatusLabel = AlertDysstasia.confirmedStatusCaption(alert.confirmedStatus);
      alert.comment = result.comment;
      alert.resolved = true;
      this.index();
      this.sort();
    });
  }

  gotToDetails(cowId) {
    const cowIds = this.alerts.map((a) => a.cowId);
    const caller = {
      name: this.title,
      state: this.callbackParam.state,
      params: this.callbackParam.params,
      cowIds
    };
    this.$state.go('cowDetail', {cowId: cowId, caller: caller});
  }

  onPrint() {
    this.ViewStateAPI.create('print-in-alertDysstasia', 'alertDysstasia');
    print();
  }

  onSortOptionChange() {
    LocalStorageEntry.set(this.$rootScope.userName, this.sortOptionKey, this.sortOption);
    this.sort();
  }
}

app.controller('AlertDysstasiaController', AlertDysstasiaController);
