class TopReproductionPlannedEventsController {
  constructor(
    ReproductionPlannedEventsAPI
  ) {
    'ngInject';

    this.ReproductionPlannedEventsAPI = ReproductionPlannedEventsAPI;
  }

  $onChanges(changes) {
    if (changes.date) {
      this.index(changes.date.currentValue);
      this.dateYYYYMMDD = DateUtil.toYYYYMMDD(changes.date.currentValue, '-');
    }
  }

  index(date) {
    this.ReproductionPlannedEventsAPI.summary(date).then((res) => {
      const total = Object.keys(res.data.summary)
        .reduce((acc, key) => {
          return acc + res.data.summary[key];
        }, 0);

      const events = [
        'bunben',
        'overBunben',
        'hatsujo',
        'tanetsuke',
        'hormoneProgram',
        'ninshinkantei',
        'kannyu'
      ].filter((key) => key in res.data.summary)
        .reduce((acc, key) => {
          return acc.concat([{
            eventType: key,
            label: ReproductionPlannedEvent.eventTypeLabel(key),
            count: res.data.summary[key]
          }]);
        }, []);

      this.summary = {
        total: total || null,
        events: events
      };

      this.total.reproductionPlannedEventsTotal = this.summary.total || 0;
    });
  }
}

function topReproductionPlannedEvents() {
  return {
    templateUrl: 'top/schedule/reproduction-planned-events/index.html',
    controller: TopReproductionPlannedEventsController,
    controllerAs: 'ctrl',
    bindings: {
      date: '<',
      total: '='
    }
  };
}

app.component('topReproductionPlannedEvents', topReproductionPlannedEvents());
