class DhiImportModalController {
  constructor(
    $timeout,
    $modalInstance,
    params,
    blockUI,
    DhiImportAPI
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$modalInstance = $modalInstance;
    this.params = params;
    this.blockUI = blockUI;
    this.DhiImportAPI = DhiImportAPI;
  }

  showModalParts(mode, visibleMode) {
    return visibleMode.indexOf(mode) !== -1;
  }

  classModal() {
    return this.params.size === 'small' ? 'uModal--small' : 'uModal';
  }

  yes() {
    this.$modalInstance.close();
  }

  no() {
    this.$modalInstance.dismiss();
  }

  cancel() {
    this.$modalInstance.close();
  }

  formatErrorMessage(res, defaultMessage) {
    if (res.data && res.data.messages) {
      return ErrorUtil.formatErrorMessage(res.data.messages).replace(/\n/g, '<br>');
    }

    return defaultMessage;
  }

  deleteHistory(id) {
    this.blockUI.start('削除中');
    this.errorMessage = null;

    this.DhiImportAPI.delete(id).then((res) => {
      this.blockUI.stop();
      this.blockUI.start('検定データの削除が完了しました');
      this.$timeout(() => {
        this.$modalInstance.close();
        this.blockUI.stop();
      }, 1000);
    }).catch((res) => {
      this.errorMessage = this.formatErrorMessage(res, '検定データの削除でエラーが発生しました。');
      this.blockUI.stop();
    });
  }
}

app.controller('DhiImportModalController', DhiImportModalController);
