/**
 * 入力用血統ボックス
 *
 * [example]
 * <input-pedigree-box
 *   farm="farm"
 *   cow="cow"
 *   form="form"
 *   selection="selection">
 * </input-pedigree-box>
 *
 * @param {Farm} farm model/farm.js
 * @param {Object} cow 対象牛
 * @param {Object} form formオブジェクト<form name="form"></form>
 * @param {Object} selection 選択項目オブジェくト
 */
class InputPedigreeBoxController {
  constructor() {
    'ngInject';
  }
}

function inputPedigreeBox() {
  return {
    templateUrl: 'cow/components/templates/input-pedigree-box.html',
    controller: InputPedigreeBoxController,
    bindings: {
      farm: '<',
      cow: '=',
      form: '=',
      selection: '<'
    }
  };
}

app.component('inputPedigreeBox', inputPedigreeBox());
