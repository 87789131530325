// eslint-disable-next-line no-unused-vars
class FatteningReport {
  static get breeds() {
    return {
      B010: '黒毛和種',
      B020: '交雑種',
      B030: '褐毛和種',
      B040: '日本短角種',
      B050: '無角種',
      B060: '黒毛と褐毛の交雑種',
      B070: '和牛間交雑種',
      B080: '肉専用種',
      B090: 'ホルスタイン種',
      B100: '乳用種',
    };
  }

  static get genders() {
    return {
      1: 'オス',
      2: 'メス',
      3: 'フリーマーチン',
      4: '去勢',
    };
  }

  static get rowIds() {
    return {
      ITEM_ID: 'itemId',
      FARM_ID: 'farmId'
    };
  }

  /**
   * 月次の集計結果を集計項目を縦軸にした二次元表に変換します。
   * 各項目の合計行の下に品種・性別毎の明細行が続きます。
   *
   * @param {Date} annualSummary APIのレスポンス
   * @return {Array.<Object>}
   *
   * - itemId: 項目のID
   * - detailRow: true 明細行、false 合計行
   * - breedTotal: true 明細行の内で品種の合計行(項目合計行にはセットされない)
   * - cells: 月毎の集計値の配列
   *
   * ex.
   * [
   *  {
   *    itemId: 'totalNumber', detailRow: false,
   *    cells: [
   *      {summary: '在庫頭数', change: ''},
   *      {summary: '100', change: '+5'},
   *      {summary: '110', change: -2'},
   *      ...
   *    ]
   *  },
   *  {
   *    itemId: 'totalNumber', detailRow: true, breedTotal: true,
   *    cells: [
   *      {summary: '黒毛和種', change: '0'},
   *      {summary: '80', change: '-3'},
   *      {summary: '95', change: +4'},
   *      ...
   *    ]
   *  },
   *  {
   *    itemId: 'totalNumber', detailRow: true, breedTotal: false,
   *    cells: [
   *      {summary: 'メス', change: ''},
   *      {summary: '40', change: '+1'},
   *      {summary: '48', change: +2'},
   *      ...
   *    ]
   *  },
   *  ...
   * ]
   */
  static generateMatrix(annualSummary, itemDefinitions) {
    const summaryKeys = FatteningReport.generateSummaryKeys(annualSummary.monthly);

    const rows = itemDefinitions.map((item) => {
      const row = [{summary: item.label, change: ''}];

      const details = FatteningReport.generateMatrixDetails(summaryKeys, 13);

      const records = FatteningReport.fillLackMonth(annualSummary.monthly);
      records.forEach((record, index) => {
        row[index + 1] = {
          summary: record.summary[item.itemId] || '-',
          change: record.change[item.itemId] || '-',
        };

        record.details.forEach((detail) => {
          const key = `${detail.breedCode}-${detail.genderCode}`;
          const cells = details[key].cells;
          cells[index + 1] = {
            summary: detail.summary[item.itemId] || '-',
            change: detail.change[item.itemId] || '-',
          };
        });
      });

      return {itemId: item.itemId, summary: row, details: details};
    });

    const gridRows = FatteningReport.generateGridRows(rows);
    return FatteningReport.decorateItems(gridRows);
  }

  static generateMatrixYearly(annualSummaries, itemDefinitions) {
    const records = annualSummaries.map((annualSummary) => {
      return annualSummary.monthly.find((monthlySummary) => monthlySummary.yearMonth % 100 === 99);
    }).filter((record) => record);

    const summaryKeys = FatteningReport.generateSummaryKeys(records);

    const rows = itemDefinitions.map((item) => {
      const row = [{summary: item.label, change: ''}];

      const details = FatteningReport.generateMatrixDetails(summaryKeys, records.length);
      FatteningReport.complementMatrixDetails(details);

      records.forEach((record, index) => {
        row[index + 1] = {
          summary: record.summary[item.itemId] || '-',
          change: record.change[item.itemId] || '-',
        };

        record.details.forEach((detail) => {
          const key = `${detail.breedCode}-${detail.genderCode}`;
          const cells = details[key].cells;
          cells[index + 1] = {
            summary: detail.summary[item.itemId] || '-',
            change: detail.change[item.itemId] || '-',
          };
        });
      });
      // 少なくとも10年分のカラムを表示する。
      for (let i = 1; i <= 10 - records.length; i++) {
        row[records.length + i] = {
          summary: '',
          change: ''
        };
      }

      return {itemId: item.itemId, summary: row, details: details};
    });

    const gridRows = FatteningReport.generateGridRows(rows);
    return FatteningReport.decorateItems(gridRows);
  }

  static generateSummaryKeys(records) {
    const uniqueKeys = new Set();
    records.forEach((record, index) => {
      record.details.forEach((detail) => {
        const key = `${detail.breedCode}-${detail.genderCode}`;
        uniqueKeys.add(key);
      });
    });
    return Array.from(uniqueKeys).sort();
  }

  static fillLackMonth(records) {
    const result = [].concat(records);
    if (result.length < 13) {
      const total = result.pop();

      const gap = 12 - result.length;
      const latestYearMonth = result[result.length - 1].yearMonth;
      let year = Math.floor(latestYearMonth / 100);
      let month = latestYearMonth % 100;

      for (let i = 1; i <= gap; i++) {
        month += 1;
        if (month === 13) {
          year += 1;
          month = 1;
        }
        const filler = {
          yearMonth: year * 100 + month,
          summary: {},
          change: {},
          details: []
        };
        result.push(filler);
      }
      result.push(total);
    }
    return result;
  }

  /** 牧場別レポート用 **/

  /**
   * 牧場毎の前月分の集計結果を返します。
   *
   * @param {Array} agentFarmAnnualSummaries
   * @param {Array} itemDefinitions
   * @return {Array.<Object>} FatteningReport.generateFarmMatrixを参照
   */
  static generateFarmMatrixLastMonth(agentFarmAnnualSummaries, itemDefinitions) {
    // 前月を取得
    const lastMonth = DateUtil.toYYYYMM(DateUtil.addMonths(new Date, -1));
    const yearMonth = Number(lastMonth);

    const filterdAgentFarmAnnualSummaries = agentFarmAnnualSummaries.map((farm) => {
      const temp = {farmId: farm.farmId, farmName: farm.farmName};

      temp.monthly = farm.annualSummaries.reduce((acc, cur) => {
        const summary = cur.monthly.find((m) => m.yearMonth === yearMonth);
        if (summary) acc.push(summary);
        return acc;
      }, []);
      // データが存在しないとエラーになるためダミーデータで補完する
      if (!temp.monthly) temp.monthly = [FatteningReport.generateDummySummary()];

      return temp;
    });

    return FatteningReport.generateFarmMatrix(filterdAgentFarmAnnualSummaries, itemDefinitions);
  }

  /**
   * 対象年度の牧場毎の集計結果を返します。
   *
   * @param {Array} agentFarmAnnualSummaries
   * @param {Array} itemDefinitions
   * @param {Integer} yearMonth
   * @return {Array.<Object>} FatteningReport.generateFarmMatrixを参照
   */
  static generateFarmMatrixYear(agentFarmAnnualSummaries, itemDefinitions, yearMonth) {
    const year = Math.floor(yearMonth / 100);

    const filterdAgentFarmAnnualSummaries = agentFarmAnnualSummaries.map((farm) => {
      const temp = {farmId: farm.farmId, farmName: farm.farmName, monthly: []};

      const summary = farm.annualSummaries.find((s) => s.fiscalYear === year && s.monthly[0].yearMonth === yearMonth);

      if (summary) {
        temp.monthly.push(summary.monthly.find((m) => m.yearMonth % 100 === 99));
      } else {
        // データが存在しないとエラーになるためダミーデータで補完する
        temp.monthly.push(FatteningReport.generateDummySummary());
      }

      return temp;
    });

    return FatteningReport.generateFarmMatrix(filterdAgentFarmAnnualSummaries, itemDefinitions);
  }

  /*
   * 各項目の集計結果を牧場を縦軸にした二次元表に変換します。
   * 牧場の合計行の下に品種・性別毎の明細行が続きます。
   *
   * @access private
   * @param {Array} agentFarmAnnualSummaries
   * @param {Array} itemDefinitions
   * @return {Array.<Object>}
   *
   * - farmId: 牧場のID
   * - detailRow: true 明細行、false 合計行
   * - breedTotal: true 明細行の内で品種の合計行(項目合計行にはセットされない)
   * - cells: 月毎の集計値の配列
   *
   * ex.
   * [
   *  {
   *    farmId: 2, detailRow: false,
   *    cells: [
   *      {summary: '預託先牧場1', change: ''},
   *      {summary: '100', change: '+5'},
   *      {summary: '110', change: -2'},
   *      ...
   *    ]
   *  },
   *  {
   *    farmId: 2, detailRow: true, breedTotal: true,
   *    cells: [
   *      {summary: '黒毛和種', change: '0'},
   *      {summary: '80', change: '-3'},
   *      {summary: '95', change: +4'},
   *      ...
   *    ]
   *  },
   *  {
   *    farmId: 2, detailRow: true, breedTotal: false,
   *    cells: [
   *      {summary: 'メス', change: ''},
   *      {summary: '40', change: '+1'},
   *      {summary: '48', change: +2'},
   *      ...
   *    ]
   *  },
   *  ...
   * ]
   */
  static generateFarmMatrix(agentFarmAnnualSummaries, itemDefinitions) {
    const itemLength = itemDefinitions.length;

    const rows = agentFarmAnnualSummaries.map((farm) => {
      const row = [{summary: farm.farmName, change: ''}];

      const summaryKeys = FatteningReport.generateSummaryKeys(farm.monthly);
      const details = FatteningReport.generateMatrixDetails(summaryKeys, itemLength);

      const monthData = farm.monthly[0];
      itemDefinitions.forEach((item, index) => {
        row[index + 1] = {
          summary: monthData && monthData.summary[item.itemId] || '-',
          change: monthData && monthData.change[item.itemId] || '-',
        };

        if (!monthData) return;

        monthData.details.forEach((detail) => {
          const key = `${detail.breedCode}-${detail.genderCode}`;
          const cells = details[key].cells;

          cells[index + 1] = {
            summary: detail.summary[item.itemId] || '-',
            change: detail.change[item.itemId] || '-',
          };
        });
      });

      return {farmId: farm.farmId, summary: row, details: details};
    });

    const gridRows = FatteningReport.generateGridRows(rows, FatteningReport.rowIds.FARM_ID);
    return FatteningReport.decorateItems(gridRows);
  }

  /** 項目別レポート用 **/

  /**
   * 牧場毎の引数で指定された項目の月毎の集計結果を返します。
   *
   * @param {Array} agentFarmAnnualSummaries
   * @param {String} itemId
   * @param {Integer} yearMonth
   * @return {Array.<Object>} FatteningReport.generateFarmMatrixを参照
   */
  static generateItemMatrixMonthly(agentFarmAnnualSummaries, itemId, yearMonth) {
    const year = Math.floor(yearMonth / 100);

    const filterdAgentFarmAnnualSummaries = agentFarmAnnualSummaries.map((farm) => {
      const temp = {farmId: farm.farmId, farmName: farm.farmName};
      const summary = farm.annualSummaries.find((s) => s.fiscalYear === year && s.monthly[0].yearMonth === yearMonth);

      if (summary) {
        temp.monthly = summary.monthly;
      } else {
        // データが存在しないとエラーになるためダミーデータで補完する
        temp.monthly = Array(13).fill().map(() => FatteningReport.generateDummySummary());
      }
      return temp;
    });

    const rows = filterdAgentFarmAnnualSummaries.map((farm) => {
      const row = [{summary: farm.farmName, change: ''}];

      const summaryKeys = FatteningReport.generateSummaryKeys(farm.monthly);
      const details = FatteningReport.generateMatrixDetails(summaryKeys, 13);

      const records = FatteningReport.fillLackMonth(farm.monthly);
      records.forEach((record, index) => {
        row[index + 1] = {
          summary: record.summary[itemId] || '-',
          change: record.change[itemId] || '-',
        };

        record.details.forEach((detail) => {
          const key = `${detail.breedCode}-${detail.genderCode}`;
          const cells = details[key].cells;
          cells[index + 1] = {
            summary: detail.summary[itemId] || '-',
            change: detail.change[itemId] || '-',
          };
        });
      });
      return {farmId: farm.farmId, summary: row, details: details};
    });

    const gridRows = FatteningReport.generateGridRows(rows, FatteningReport.rowIds.FARM_ID);
    return FatteningReport.decorateItems(gridRows);
  }

  /**
   * 牧場毎の引数で指定された項目の年度毎の集計結果を返します。
   *
   * @param {Array} agentFarmAnnualSummaries
   * @param {String} itemId
   * @param {Array.<Integer>} years
   * @param {Integer} startMonth 年度開始月
   * @return {Array.<Object>} FatteningReport.generateFarmMatrixを参照
   */
  static generateItemMatrixYearly(agentFarmAnnualSummaries, itemId, years, startMonth) {
    const filterdAgentFarmAnnualSummaries = agentFarmAnnualSummaries.map((farm) => {
      const temp = {farmId: farm.farmId, farmName: farm.farmName};

      temp.monthly = years.reduce((acc, year) => {
        const summary = farm.annualSummaries.find((s) => {
          const month = s.monthly[0].yearMonth % 100;
          return s.fiscalYear === year && month === startMonth;
        });

        if (summary) {
          acc.push(summary.monthly.find((s) => s.yearMonth % 100 === 99));
        } else {
          // データが存在しないとエラーになるためダミーデータで補完する
          acc.push(FatteningReport.generateDummySummary());
        }
        return acc;
      }, []);

      return temp;
    });

    const rows = filterdAgentFarmAnnualSummaries.map((farm) => {
      const row = [{summary: farm.farmName, change: ''}];

      const summaryKeys = FatteningReport.generateSummaryKeys(farm.monthly);
      const details = FatteningReport.generateMatrixDetails(summaryKeys, farm.monthly.length);
      FatteningReport.complementMatrixDetails(details);

      farm.monthly.forEach((record, index) => {
        row[index + 1] = {
          summary: record.summary[itemId] || '-',
          change: record.change[itemId] || '-',
        };

        record.details.forEach((detail) => {
          const key = `${detail.breedCode}-${detail.genderCode}`;
          const cells = details[key].cells;
          cells[index + 1] = {
            summary: detail.summary[itemId] || '-',
            change: detail.change[itemId] || '-',
          };
        });
      });
      // 10年に満たない場合の補完処理
      for (let i = row.length; i <= 10; i++) {
        row[i] = {
          summary: '',
          change: ''
        };
      }

      return {farmId: farm.farmId, summary: row, details: details};
    });

    const gridRows = FatteningReport.generateGridRows(rows, FatteningReport.rowIds.FARM_ID);
    return FatteningReport.decorateItems(gridRows);
  }

  /** 各レポート共通 **/

  /** データ生成 **/

  static generateGridRows(rows, id = FatteningReport.rowIds.ITEM_ID) {
    const result = [];
    rows.forEach((row) => {
      result.push({[id]: row[id], cells: row.summary, detailRow: false});

      Object.values(row.details).forEach((detail) => {
        const notEmptyRow = detail.cells.some((cell, index) => {
          if (index === 0) return false;

          const isEmptyValue = ['0', '0.0', '0.00', '-', ''].includes(cell.summary);
          return !isEmptyValue;
        });

        if (notEmptyRow) {
          result.push({[id]: row[id], cells: detail.cells, detailRow: true, breedTotal: detail.breedTotal});
        }
      });
    });
    return result;
  }

  static generateMatrixDetails(keys, length) {
    const breeds = FatteningReport.breeds;
    const genders = FatteningReport.genders;

    return keys.reduce((details, key) => {
      const x = key.split('-');
      let label = breeds[x[0]];
      let breedTotal = true;
      if (x[1] !== '0') {
        label = genders[x[1]];
        breedTotal = false;
      }

      const cells = [{summary: label, change: ''}];
      for (let i = 1; i <= length; i++) {
        cells.push({summary: '-', change: '-'});
      }
      details[key] = {cells: cells, breedTotal: breedTotal};
      return details;
    }, {});
  }

  /**
   * 年次データが10年未満の場合の補完処理
   */
  static complementMatrixDetails(details) {
    Object.values(details).forEach((value) => {
      const cells = value.cells;
      for (let i = cells.length; i <= 10; i++) {
        cells.push({summary: '', change: ''});
      }
    });
  }

  static decorateItems(items) {
    items.forEach((item, index) => {
      item.detailOpened = false;

      const previousItem = items[index - 1];
      // 最後の明細行に lastDetailRow プロパティを追加し、その値をtrueに
      if (!item.detailRow && previousItem && previousItem.detailRow) {
        previousItem.lastDetailRow = true;
      }
      // サマリー行に対して関連する明細行がない場合、そのサマリー行に noDetail プロパティを追加し、その値をtrueに
      if (!item.detailRow && previousItem && !previousItem.detailRow) {
        previousItem.noDetail = true;
      }
    });

    // 最後の行に、lastDetailRow、noDetail プロパティを場合によって追加
    const lastIndex = items.length - 1;
    if (items[lastIndex] && items[lastIndex].detailRow) {
      items[lastIndex].lastDetailRow = true;
    }
    if (items[lastIndex] && !items[lastIndex].detailRow) {
      items[lastIndex].noDetail = true;
    }

    return items;
  }

  /**
   * サマリーのダミーオブジェクトを返す
   *
   * @param {Integer} yearMonth
   * @return {Object}
   */
  static generateDummySummary(yearMonth) {
    const dummy = {summary: {}, change: {}, details: []};
    if (yearMonth) dummy.yearMonth = yearMonth;
    return dummy;
  }

  /** 表示 **/

  static closeDetails(items) {
    items.forEach((item) => item.detailOpened = false);
  }

  static generateTableClass(frequency) {
    return `${frequency} smart`;
  }

  static generateTrClass(item) {
    const classNames = [];

    if (!item.detailRow) {
      classNames.push('summary-row');
    } else if (item.breedTotal) {
      classNames.push('detail-row');
      classNames.push('breed-row');
    } else {
      classNames.push('detail-row');
      classNames.push('sex-row');
    }

    if (item.noDetail) classNames.push('no-detail');
    if (item.lastDetailRow) classNames.push('last-detail-row');
    if (item.detailOpened) classNames.push('open');

    return classNames.join(' ');
  }

  static toggleTr(items, id, rowId) {
    items.forEach((item) => {
      if (item[rowId] === id) item.detailOpened = !item.detailOpened;
    });
  }

  static generateBtnClass(item) {
    const classNames = ['icon-l-arrow-A-d01', 'accordion-toggle-button'];
    if (item.detailOpened) classNames.push('open');
    return classNames.join(' ');
  }
}
