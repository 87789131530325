// eslint-disable-next-line no-unused-vars
class TimelineItem {
  static toMedicationItem(medications) {
    if (!medications) return [];

    return medications.map((m) => {
      return {
        id: m.masterMedicineId,
        name: m.name,
        method: m.method,
        capacity: m.capacity,
        unit: m.unit,
        selected: true,
      };
    });
  }
}
