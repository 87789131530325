class HomeHeadageSummaryInventoryController {
  constructor(
    $state,
    SessionCache
  ) {
    'ngInject';

    this.$state = $state;

    HomeHeadageBaseController.bind(this);

    const farm = SessionCache.farm();

    this.init(farm);
  }

  init(farm) {
    this.isDairy = farm.isDairy();
    this.isBreeding = farm.isBreeding();
  }

  $onChanges() {
    if (!this.data) return;

    this.showBirth = this.getShowBirth();
  }

  getShowBirth() {
    if (this.isDairy || this.isBreeding) return true;

    return this.data.inventory.increase.birth;
  }
}

function homeHeadageSummaryInventory() {
  return {
    templateUrl: 'menu/home/headage/summary/inventory/index.html',
    controller: HomeHeadageSummaryInventoryController,
    controllerAs: 'ctrl',
    bindings: {
      data: '<'
    }
  };
}

app.component('homeHeadageSummaryInventory', homeHeadageSummaryInventory());
