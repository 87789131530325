class CustomlistCopyController {
  constructor(
    $scope,
    $modalInstance,
    params
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.masters = params.masters;
    this.selected = null;
    this.isValid = false;

    $scope.$on('ngRepeatFinished', () => {
      FixedMidashi.create();
    });
  }

  ok() {
    if (this.validate())
      this.$modalInstance.close(this.selected);
  }

  cancel() {
    this.$modalInstance.dismiss();
  }

  validate() {
    this.isValid = Number.isInteger(this.selected);
    return this.isValid;
  }

  select(master) {
    this.selected = master.customlistId;
    this.validate();
  }
}

app.controller('CustomlistCopyController', CustomlistCopyController);
