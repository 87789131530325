class FarmIndexController {
  constructor(
    $modal,
    FarmService
  ) {
    'ngInject';
    this.$modal = $modal;
    this.FarmService = FarmService;

    this.identificationTypes = {
      cow_no: '牛番号',
      regulation_number_4: '個体識別番号の拡大4桁',
      regulation_number_5: '個体識別番号の下5桁',
      name: '名前'
    };
    this.cowNoMatchingPatterns = {
      complete: '完全一致',
      prefix: '前方一致',
      fuzzy: 'あいまい検索'
    };

    this.index();
  }

  index() {
    this.FarmService.show().then((res) => {
      this.farm = new Farm(res);
      this.farm.fiscalYearStartMonthDisplay = `${this.farm.fiscalYearStartMonth()}月`;

    }).catch((err) => console.error(err));
  }

  edit() {
    this.$modal.open({
      templateUrl: 'menu/setting/farm/edit.html',
      controller: 'FarmEditController',
      controllerAs: 'ctrl',
      size: 'lg'
    }).result.then(() => {
      this.index();
    });
  }

  identificationTypeCaption(type) {
    return this.identificationTypes[type];
  }

  allowDuplicationCowNoCaption() {
    return this.farm.allowDuplicationCowNo() ? '許す' : '許さない';
  }

  cowNoPatternCaption(pattern) {
    return pattern === 'number' ? '数字' : '英数字';
  }

  cowNoMatchingPatternCaption(pattern) {
    return this.cowNoMatchingPatterns[pattern];
  }

  fatteningStartMonthAgeCaption() {
    return `${this.farm.fatteningStartMonthAge()}ヶ月`;
  }

  stopShipmentDisplayCaption() {
    if (this.farm) {
      const option = Farm.stopShipmentDisplayOptions.find((option) => {
        return option.key === this.farm.stopShipmentDisplay();
      });
      return option.label;
    }
    return null;
  }
}

app.controller('FarmIndexController', FarmIndexController);
