class CustomlistAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/customlist/master');
  }

  system() {
    return this.APIService.get('/customlist/master/system');
  }

  cowGroups() {
    return this.system().then((res) => {
      const masters = res.data;
      const groups = masters.filter((m) => {
        return m.configType.startsWith('group');
      });
      res.data = groups;
      return res;
    });
  }

  farm() {
    return this.system().then((res) => {
      const masters = res.data;
      res.data = masters.find((m) => m.configType === 'farm');
      return res;
    });
  }

  deposit() {
    return this.system().then((res) => {
      const masters = res.data;
      res.data = masters.find((m) => m.configType === 'deposit');
      return res;
    });
  }

  show(customlistId) {
    return this.APIService.get('/customlist/master/' + customlistId);
  }

  create(params) {
    return this.APIService.post('/customlist/master', params);
  }

  update(customlistId, params) {
    return this.APIService.put('/customlist/master/' + customlistId, params);
  }

  delete(customlistId) {
    return this.APIService.delete('/customlist/master/' + customlistId, {});
  }

  definition() {
    return this.APIService.get('/customlist/definition');
  }

  template() {
    return this.APIService.get('/customlist/template');
  }

  updateSortOrder(values) {
    const params = {
      masters: values.map((v) => {
        return {
          customlistId: v.id,
          sortOrder: v.sortOrder
        };
      })
    };
    return this.APIService.put('/customlist/master/sort_order', params);
  }

  eventTypes() {
    return this.APIService.get('/customlist/event_type');
  }

  updateEventType(params) {
    return this.APIService.post('/customlist/event_type', params);
  }

  runtime(customlistId) {
    return this.APIService.get(`/customlist/master/${customlistId}/runtime`);
  }

  run(customlistId, date, option) {
    const w3cDate = DateUtil.toW3CFormat(date);
    let queryParams = `?date=${w3cDate}`;

    if (option.cowGroupId) {
      queryParams += `&cow_group_id=${option.cowGroupId}`;
    }

    if (option.fatteningStageId) {
      queryParams += `&fattening_stage_id=${option.fatteningStageId}`;
    }

    if (option.period) {
      const startDate = DateUtil.toW3CFormat(option.period.startDate);
      queryParams += `&start_date=${startDate}`;

      const endDate = DateUtil.toW3CFormat(option.period.endDate);
      queryParams += `&end_date=${endDate}`;
    }

    return this.APIService.get(`/customlist/runtime/${customlistId}${queryParams}`);
  }

  search(customlistId, condition) {
    const conditions = condition.conditions.map((c) => {
      const cloned = Object.assign({}, c);
      if (cloned.conditionType === 'DATE') {
        cloned.conditionMinDate = DateUtil.toW3CFormat(cloned.conditionMinDate);
        cloned.conditionMaxDate = DateUtil.toW3CFormat(cloned.conditionMaxDate);
      }
      return cloned;
    });

    const params = {
      customlistId: customlistId,
      condition: {
        activeOnly: condition.activeOnly,
        conditions: conditions
      }
    };
    return this.APIService.post('/customlist/search', params);
  }

  executeSummary() {
    return this.APIService.post('/customlist/summary', {});
  }

  summaryStatus() {
    return this.APIService.get('/customlist/summary/status');
  }
}

app.service('CustomlistAPI', CustomlistAPI);
