/**
 * フォーム表示用
 */
function DisplaySelectedMedicine() {
  'ngInject';
  /**
   * 画面表示用に薬品名、容量、処置方法を結合する。
   *
   * @param {Object} medicine
   * @return {string} 薬品表示用文字列
   */
  function displayMedicine(medicine) {
    const unit = medicine.unit || '';
    const capacityWithUnit = (medicine.capacity) ? medicine.capacity + unit : '';
    const method = medicine.method || '';
    return `${medicine.name} ${capacityWithUnit} ${method}&nbsp;`;
  }

  /**
  * 複数の薬品のうち、１つでも処置方法・容量がある場合は、薬品の数だけ改行して表示する.
  * それ以外の場合は、全ての薬品名を１行で表示する。
  */
  function shouldBreakLine(medicines) {
    for (let i = 0; i < medicines.length; i++) {
      if (medicines[i].method || medicines[i].capacity) return true;
    }
    return false;
  }

  function displayMedicines(medicines = []) {
    const breakLine = shouldBreakLine(medicines);
    let html = '';
    medicines.forEach((m) => {
      const e = breakLine ? document.createElement('p') : document.createElement('span');
      e.innerHTML = displayMedicine(m);
      html += e.outerHTML;
    });
    return html;
  }

  return {
    restrict: 'E',
    scope: {
      medicines: '=',
    },
    template: `<div ng-bind-html="displayMedicines(medicines)"></div>`,
    link: function(scope, element, attrs) {
      scope.displayMedicines = displayMedicines;
    }
  };
}

app.directive('displaySelectedMedicine', DisplaySelectedMedicine);
