class EmbryoRecoveryReportAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index(startDate, endDate) {
    const formattedStart = DateUtil.toW3CFormat(startDate);
    const formattedEnd = DateUtil.toW3CFormat(endDate);
    return this.APIService.get(`/embryo_recovery_report?start_date=${formattedStart}&end_date=${formattedEnd}`);
  }
}

app.service('EmbryoRecoveryReportAPI', EmbryoRecoveryReportAPI);
