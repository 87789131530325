/**
 * TOP画面の分娩センサー脱落アラート件数表示欄のコンポーネント
 *
 * ex.
 *  <alert-calving-sensor-dropped-box
 *    alert-count="alertCount">
 *  </alert-calving-box>
 *
 * プロパティ:
 *  @param {number} alert-count アラート件数
 */
class AlertCalvingSensorDroppedBoxController {
  constructor() {
    'ngInject';
  }
}

function alertCalvingSensorDroppedBox() {
  return {
    templateUrl: 'top/alert/calving-sensor-dropped-box/index.html',
    controller: AlertCalvingSensorDroppedBoxController,
    bindings: {
      alertCount: '<',
    }
  };
}

app.component('alertCalvingSensorDroppedBox', alertCalvingSensorDroppedBox());
