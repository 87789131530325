class FatteningReportConfigAPI {
  constructor(
    APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  show() {
    return this.APIService.get('/fattening/report_config');
  }

  create(fiscalYearStartMonth) {
    const params = {fiscalYearStartMonth: fiscalYearStartMonth};
    return this.APIService.post('/fattening/report_config', params);
  }
}

app.service('FatteningReportConfigAPI', FatteningReportConfigAPI);
