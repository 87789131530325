// eslint-disable-next-line no-unused-vars
class Calved {
  constructor(params) {
    Object.assign(this, params);
  }

  static get CALF_RESULT() {
    return '出産';
  }

  static get CALF_GENDER() {
    return 'メス';
  }

  initChildNumber() {
    if (this.isNewEvent) this.calfs[0] = {};
    this.changeChildNumber();
  }

  changeChildNumber() {
    switch (this.childNumber) {
    case '1':
      this.childNumbers = [1];
      this.createChild(1);
      this.clearChild(2);
      this.clearChild(3);
      this.clearChild(4);
      break;
    case '2':
      this.childNumbers = [1, 2];
      this.createChild(1);
      this.createChild(2);
      this.clearChild(3);
      this.clearChild(4);
      break;
    case '3':
      this.childNumbers = [1, 2, 3];
      this.createChild(1);
      this.createChild(2);
      this.createChild(3);
      this.clearChild(4);
      break;
    case '4':
      this.childNumbers = [1, 2, 3, 4];
      this.createChild(1);
      this.createChild(2);
      this.createChild(3);
      this.createChild(4);
      break;
    }
  }

  /**
   * 不要な情報を削除する
   */
  deleteCalf() {
    const startIndex = Number(this.childNumber);
    this.calfs.splice(startIndex, 4 - startIndex);

    this.calfs.forEach((calf) => {
      delete calf.birthStateString;
    });

    delete this.isNewEvent;
    delete this.calfManagement;
  }

  /**
   * エラーメッセージを作成する
   * ex.
   *  [モバイル]
   *  Calved.generateErrorMessages(errors, true)
   *  => [{field: 'xxx', message: 'xxx'}, {field: 'yyy', message: 'yyy'}, ...]
   *
   *  [PC]
   *  Calved.generateErrorMessages(errors, false)
   *  => ['xxx', 'yyy', ...]
   *
   * @param <Array> errors エラー配列
   * @param <boolean> isMobile true: モバイル, false: PC
   * @return <Array>
   */
  static generateErrorMessages(errors, isMobile) {
    const occurredAtErrorMessage = '分娩日時が最新のイベント発生日時より前の日時になっています。';
    if (errors.length === 1 && errors[0].message === occurredAtErrorMessage) {
      return isMobile ? [{field: 'occurredAt', message: occurredAtErrorMessage}] : [occurredAtErrorMessage];
    }

    return errors.map((error) => {
      const lineNo = error.lineNo;
      const field = error.field;

      if (isMobile) {
        return {
          field: `${field}${lineNo - 1}`,
          message: error.message
        };
      } else {
        return `[${lineNo}頭目] ${error.message}`;
      }
    });
  }

  // private
  createChild(child) {
    const index = child - 1;
    if (!this.calfs[index].calfNo) this.calfs[index].calfNo = '';
    if (!this.calfs[index].cowNo) this.calfs[index].cowNo = '';
    if (!this.calfs[index].cowUid) this.calfs[index].cowUid = '';
    if (!this.calfs[index].gender) this.calfs[index].gender = Calved.CALF_GENDER;
    if (!this.calfs[index].weightOnBirth) this.calfs[index].weightOnBirth = '';
    if (!this.calfs[index].breed) this.calfs[index].breed = null;
    if (!this.calfs[index].birthResult) this.calfs[index].birthResult = Calved.CALF_RESULT;
    if (!this.calfs[index].birthState) this.calfs[index].birthState = [];
    if (typeof this.calfs[index].registered === 'undefined') {
      if (this.calfManagement === 'always') {
        this.calfs[index].registered = true;
      } else {
        this.calfs[index].registered = false;
      }
    }
  }

  clearChild(child) {
    const index = child - 1;
    this.calfs[index] = {
      calfNo: '',
      cowNo: '',
      cowUid: '',
      registered: undefined,
      gender: '',
      weightOnBirth: '',
      breed: null,
      birthResult: '',
      birthState: []
    };
  }
}
