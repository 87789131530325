// eslint-disable-next-line no-unused-vars
class EnvironmentChart {
  static get CHART_TYPES() {
    return {
      stress: {
        name: 'stress',
        regions: [
          {axis: 'y', start: 65, end: 70, class: 'score-color1', opacity: '0.15'},
          {axis: 'y', start: 70, end: 75, class: 'score-color2', opacity: '0.15'},
          {axis: 'y', start: 75, end: 100, class: 'score-color3', opacity: '0.15'}
        ]
      },
      temperature: {
        name: 'temperature'
      },
      humidity: {
        name: 'humidity'
      }
    };
  }
}
