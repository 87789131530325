// 日毎各活動量グラフ
// eslint-disable-next-line no-unused-vars
class ActiveDailyChart {
  constructor(selector) {
    this.options = {
      bindto: selector,
      padding: {
        right: 15
      },
      size: {
        height: 250,
      },
      data: {
        x: 'x',
        columns: [],
        colors: {}
      },
      axis: {
        y: {
          label: {
            text: '時間(分)',
            position: 'outer-right'
          },
          min: 0,
          padding: {
            bottom: 0
          }
        },
        x: {
          label: {
            text: '日付',
            position: 'outer-top'

          },
          type: 'timeseries',
          tick: {
            fit: true,
            format: '%m/%d'
          }
        }
      },
      subchart: {
        show: false
      },
      point: {
        show: false
      },
      tooltip: {
        show: true
      }
    };
  }

  show(data, type) {
    let typeObj = {
      drinking: {lbl: '飲水', color: '#56a9d4'},
      feeding: {lbl: '採食', color: '#b4c82d'},
      rumination: {lbl: '反芻', color: '#c87f2c'},
      lying: {lbl: '横臥', color: '#b872e9'},
      running: {lbl: '走る', color: '#f2669a'},
      standing: {lbl: '静止', color: '#777a71'},
      walking: {lbl: '歩く', color: '#f8c515'},
      rumination_lying: {lbl: '横臥反芻', color: '#c87f2c'},
      rumination_standing: {lbl: '静止反芻', color: '#896D4F'}
    };

    this.options.data.columns = data;
    this.options.data.colors[typeObj[type].lbl] = typeObj[type].color;
    this.chart = c3.generate(this.options);
  }

  showCustomChart(data1, type, count) {
    let typeObj = {
      drinking: {lbl: '飲水', color: '#56a9d4'},
      feeding: {lbl: '採食', color: '#b4c82d'},
      rumination: {lbl: '反芻', color: '#c87f2c'},
      lying: {lbl: '横臥', color: '#b872e9'},
      running: {lbl: '走る', color: '#f2669a'},
      standing: {lbl: '静止', color: '#777a71'},
      walking: {lbl: '歩く', color: '#f8c515'},
      rumination_lying: {lbl: '横臥反芻', color: '#c87f2c'},
      rumination_standing: {lbl: '静止反芻', color: '#896D4F'}
    };

    this.options.data.columns = data1;
    this.options.data.colors[typeObj[type].lbl] = typeObj[type].color;
    count = count || [];
    this.options.tooltip.contents = function(data, defaultTitleFormat, defaultValueFormat, color) {
      let text = '';
      let date = data[0].x;
      let dateString = date.getMonth() + 1 + '/' + date.getDate();
      text = '<table class="c3-tooltip">';
      text += '<tbody>';
      text += '<tr><th id="tooltip_time" colspan="2">' + dateString + '</th></tr>';
      text += '<tr class="c3-tooltip-name-活動量">';
      text += '<td class="name" style="width: 80px;"><span id="tooltip_color" style="background-color:' + typeObj[type].color + '"></span>' + typeObj[type].lbl + '</td>';
      text += '<td id="tooltip_count" class="value">' + data[0].value + '</td>';
      text += '</tr>';

      // TODO マウスオーバー時の回数表示は一旦封印
      /****
      if (type === "feeding" || type === "drinking") {
        text += '<tr class="c3-tooltip-name-活動量">';
        text += '<td class="name">回数</td>';
        text += '<td id="tooltip_count" class="value">' + count[idx] + '</td>';
        text += '</tr>';
      }
      ****/

      text += '</tbody>';
      text += '</table>';

      return text;
    };

    this.chart = c3.generate(this.options);
  }

  update(data) {
    this.chart.load({
      columns: data
    });
  }
}
