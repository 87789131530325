class DiseaseEditDialogController {
  constructor(
    $modalInstance,
    params,
    MasterDiseaseAPI,
    blockUI,
    $timeout,
    modalDialogFactory
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.MasterDiseaseAPI = MasterDiseaseAPI;
    this.$timeout = $timeout;
    this.blockUI = blockUI;
    this.modalDialogFactory = modalDialogFactory;

    this.initialize(params);
  }

  initialize(params) {
    this.title = params.title;
    this.master = params.master;
    this.viewMode = new ViewMode(params.viewMode);

    this.invalid = this.viewMode.isDelete() ? false : true;
    this.errorMessage = null;

    if (this.viewMode.isCreate()) {
      this.master = {
        name: '',
        notes: '',
      };
    }
  }

  validate() {
    this.invalid = true;

    if (!this.master.name) return;

    this.invalid = false;
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }

  save() {
    if (this.viewMode.isCreate()) {
      this.create();
    } else if (this.viewMode.isUpdate()) {
      this.update();
    } else if (this.viewMode.isDelete()) {
      this.delete();
    }
  }

  create() {
    const params = {
      name: this.master.name,
      notes: this.master.notes
    };
    const procedure = this.MasterDiseaseAPI.create(params);
    this.callApi(procedure);
  }

  update() {
    const params = {
      id: this.master.id,
      name: this.master.name,
      notes: this.master.notes,
      visible: this.master.visible
    };
    const procedure = this.MasterDiseaseAPI.update(params);
    this.callApi(procedure);
  }

  delete() {
    const procedure = this.MasterDiseaseAPI.delete(this.master.id);
    this.callApi(procedure);
  }

  callApi(procedure) {
    this.blockUI.start('更新中');

    procedure
      .then(() => {
        this.blockUI.done(() => {
          this.blockUI.start(`病名・症状の${this.viewMode.label}が完了しました`);
          this.$timeout(() => {
            this.blockUI.stop();
          }, 1000);
        });
        this.blockUI.stop();
        this.$modalInstance.close();
      })
      .catch((res) => {
        this.errorMessage = ErrorUtil.formatErrorMessage(res.data.messages);
        this.blockUI.stop();
      });
  }
}

app.controller('DiseaseEditDialogController', DiseaseEditDialogController);
