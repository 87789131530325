class HelpMovieDetailController {
  constructor(
    $stateParams,
    $rootScope
  ) {
    'ngInject';
    $rootScope.isPrinting = true;
    $rootScope.isEnvToastHide = true;
    this.id = $stateParams.id;
  }
}

app.controller('HelpMovieDetailController', HelpMovieDetailController);
