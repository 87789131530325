// eslint-disable-next-line no-unused-vars
class AlertDysstasia {
  static confirmedStatusCaption(status) {
    const statusOptions = {
      dysstasia: '起立困難',
      louse: 'シラミ',
      dead: '死亡',
      normal: '異常なし',
      other: 'その他',
    };
    return statusOptions[status] || '(未報告)';
  }

  /**
   * 表示用のオブジェクトを作成する
   *
   * @params {Object} dysstasia 起立困難アラートオブジェクト
   * @params {Date} baseDate 基準日(月齢に使用)
   * @return {Object} 表示用オブジェクト
   */
  static toViewModel(dysstasia, baseDate) {
    const scores = [];
    let startAt = null;
    let endAt = null;
    if (dysstasia.scores[0]) {
      startAt = dysstasia.scores[0].detectedAt;
      endAt = dysstasia.scores.slice(-1)[0].detectedAt;

      for (let at = startAt; at <= endAt; at += 600000) {
        const score = dysstasia.scores.find((s) => s.detectedAt === at);
        if (score) {
          scores.push(score);
        } else {
          scores.push({detectedAt: at, score: null});
        }
      }
    }

    const vm = Object.assign({}, dysstasia);
    vm.scores = scores.map((s) => {
      s.time = DateUtil.toDate(s.detectedAt);
      return s;
    });
    vm.alertAtDate = DateUtil.toDate(dysstasia.alertAt);
    vm.confirmedLabel = dysstasia.confirmed ? '確認済' : '未確認';
    vm.confirmedStatusLabel = this.confirmedStatusCaption(dysstasia.confirmedStatus);
    vm.formattedCowUid = Cow.formatCowUid(dysstasia.cowUid);
    vm.monthAge = DateUtil.monthAge(dysstasia.birthday, baseDate, 1) || '';
    vm.combinedGroupName = dysstasia.pen ? `${dysstasia.cowGroupName} (${dysstasia.pen})` : dysstasia.cowGroupName;

    vm.alertAtLabel = DateUtil.format(dysstasia.alertAt, 'HH:mm');
    const diffMinutes = DateUtil.diffMinutes(dysstasia.alertAt, baseDate);
    if (diffMinutes < 60) {
      vm.alertAtLabel += '(1時間以内)';
    } else {
      const hours = Math.floor(diffMinutes / 60);
      vm.alertAtLabel += `(${hours}時間前)`;
    }
    return vm;
  }
}
