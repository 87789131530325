class LabelTimelineCategoryController {
  constructor() {
    'ngInject';
  }

  className(eventType) {
    if (['ninshinkantei'].includes(eventType)) {
      return 'uLabel--timelineCategory01';
    } else if (['breedingDifficulty', 'hatsujo'].includes(eventType)) {
      return 'uLabel--timelineCategory02';
    } else if (['tanetsuke'].includes(eventType)) {
      return 'uLabel--timelineCategory03';
    } else if (['abort', 'bunben', 'embryo_recovery'].includes(eventType)) {
      return 'uLabel--timelineCategory04';
    } else if (['freshCheck', 'hormoneProgram', 'kannyu'].includes(eventType)) {
      return 'uLabel--timelineCategory05';
    } else if (['doNotBreed'].includes(eventType)) {
      return 'uLabel--timelineCategory06';
    }
  }
}

function labelTimelineCategoryComponent() {
  return {
    templateUrl: 'components/label/timeline-category/index.html',
    controller: LabelTimelineCategoryController,
    bindings: {
      eventName: '<',
      eventType: '<'
    }
  };
}

app.component('labelTimelineCategory', labelTimelineCategoryComponent());
