// eslint-disable-next-line no-unused-vars
class ConfidenceIntervalBar {
  static append(element, confidenceIntervals) {
    const errorBars = d3.select(`${element} svg .c3-chart`).append('g');

    errorBars.selectAll('path')
      .data(confidenceIntervals)
      .enter().append('path')
      .attr('id', (d, i) => 'error-bar-line-' + i)
      .attr('class', 'error-bar');

    const barMaxHeight = Number(document.getElementsByClassName('c3-zoom-rect')[0].getAttribute('height'));

    d3.selectAll('.c3-bars-発情発見率').selectAll('.c3-bar').each(function(d, i) {
      const segList = this.pathSegList;//eslint-disable-line no-invalid-this
      const yPos = segList.getItem(1).y + d.value * (barMaxHeight / 100);
      const xPos = (segList.getItem(2).x + segList.getItem(0).x) / 2;

      errorBars.select('#error-bar-line-' + i)
        .attr('d', (d) => {
          return 'M' + xPos + ',' + yPos + ' ' +
              'L' + xPos + ',' + (yPos - d * (barMaxHeight / 100)) + ' ' +
              'z';
        });
    });
  }
}
