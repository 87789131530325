app = app || {};
app.TimeBudget = {dataInfo: {}};

// 活動量タイムバジェット
// eslint-disable-next-line no-unused-vars
class TimeBudget {
  constructor(selector, showFeed, showDrink) {
    this.showFeed = showFeed;
    this.showDrink = showDrink;
    this.DateUtilService = new DateUtilService();

    this.svgWidth = 16;
    this.svgHeight = 0;
    const selectedDom = d3.select('#' + selector);
    this.svg = selectedDom
      .append('svg')
      .attr('id', selector + 'BudgetSvg');
    this.drinkSvg = selectedDom
      .append('svg')
      .attr('id', selector + 'BudgetSvgDrink');
    this.svgBgColor = selectedDom
      .append('svg')
      .attr('id', selector + 'BgColorSvg');
    this.svgAxis = selectedDom
      .append('svg')
      .attr('id', selector + 'XAxisSvg');

    this.rotation = {
      transform: 'rotate(90deg)',
      'transform-origin': '0 bottom 0',
      position: 'absolute',
      left: '39px',
      bottom: '100px'
    };

    this.xAxisStyle = {
      left: '0',
      position: 'absolute',
      bottom: '43px',
      'z-index': '7',
    };

    this.barColor = {
      move: {name: 'move', label: '動態', color: '#ffd600'},
      feed: {name: 'feed', label: '採食', color: '#6c9f00'},
      ruminate: {name: 'ruminate', label: '反芻', color: '#ba8800'},
      lie: {name: 'lie', label: '横臥', color: '#c365ff'},
      stand: {name: 'stand', label: '静止', color: '#666666'},
      none: {name: 'none', label: '', color: '#ffffff'},
    };

    this.dataInfo = {
      feed: {label: '採食', count: 0, time: 0},
      ruminate: {label: '反芻', count: 0, time: 0},
      move: {label: '動態', count: 0, time: 0},
      lie: {label: '横臥', count: 0, time: 0},
      stand: {label: '静止', count: 0, time: 0},
      none: {label: '', count: 0, time: 0},
    };

    if (showDrink) {
      this.barColor.drink = {name: 'drink', label: '飲水', color: '#598cff'};
      this.dataInfo.drink = {label: '飲水', count: 0, time: 0};
    }

    this.xAxisItem = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
      13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
    ];
  }

  _drawXAxis(selector) {
    const padding = 40;
    this.svgAxis.attr('width', this.svgHeight).attr('height', 60);

    let xScale = d3.scale.linear()
      .domain([0, d3.max(this.xAxisItem, (d) => d)])
      .range([padding, (this.svgHeight) - padding * 2]);

    // X 軸の定義
    let xAxis = d3.svg.axis()
      .scale(xScale)
      .orient('bottom')
      .ticks(12);
    // X 軸の生成
    this.svgAxis.append('g')
      .attr('class', 'axis')
      .attr('transform', 'translate(0, 18)')
      .attr('fill', '#999')
      .call(xAxis);

    this.svgAxis.selectAll('line').attr('y2', '22');

    if (app.userAgent.browser === 'ie' || app.userAgent.browser === 'gecko') {
      this.svgAxis.attr('height', 100);
      this.svgAxis.selectAll('text').attr('y', '25');
    }

    this.svgAxis.style(this.xAxisStyle);
    let first = $('#' + selector + 'XAxisSvg' + ' .axis g:first line').offset();
    let last = $('#' + selector + 'XAxisSvg' + ' .axis g:last line').offset();
    this.pathWidth = last.left - first.left;
  }

  show(data, budgetType, selector, drinkData) {
    this.svgHeight = $('#' + selector).width();
    this.drinkSvg.attr('width', this.svgWidth).attr('height', this.svgHeight);
    this.svg.attr('width', this.svgWidth).attr('height', this.svgHeight);
    this.svgBgColor.attr('width', this.svgWidth).attr('height', this.svgHeight);
    this._drawXAxis(selector);
    this.item = [];
    this.drinkItem = [];

    const value = (this.pathWidth + 2) / 144;

    // 飲水のデータ
    const drinkMotion = this.processingTimelineDate(drinkData.map((v) => ({
      datehourmin: this.DateUtilService.toYYYYMMDDHHmmParam(this.DateUtilService.alignMinute(v.startAt)),
      status: 'drink',
    }))).reduce((acc, data) => {
      if (acc.state === data.status) {
        acc.cnt++;
      } else if (!acc.state) {
        acc.state = data.status;
        acc.cnt = 1;
      } else {
        acc.list.push([{
          x: 1,
          y: value * acc.cnt,
        }]);
        this.drinkItem.push({
          name: this.barColor[acc.state].name,
          color: this.barColor[acc.state].color,
        });
        acc.state = data.status;
        acc.cnt = 1;
      }
      return acc;
    }, {
      list: [],
      cnt: 0,
      state: undefined,
    });

    // 飲水以外のモーションデータ
    const motions = this.processingTimelineDate(data).reduce((acc, data) => {
      if (acc.state === data.status) {
        acc.cnt++;
      } else if (!acc.state) {
        acc.state = data.status;
        acc.cnt = 1;
      } else {
        acc.list.push([{x: 1, y: value * acc.cnt}]);
        this.item.push({
          name: this.barColor[acc.state].name,
          color: this.barColor[acc.state].color,
        });
        this.dataInfo[acc.state].count += 1;
        this.dataInfo[acc.state].time += acc.cnt * 10;
        acc.state = data.status;
        acc.cnt = 1;
      }
      return acc;
    }, {
      list: [],
      cnt: 0,
      state: undefined,
    });

    const bgList = [];
    const bgValue = (this.pathWidth + 1) / 144;
    for (let i = 0; i < 12; i++) {
      bgList.push([{x: 1, y: bgValue * 12}]);
    }

    app.TimeBudget.dataInfo[selector] = this.dataInfo;
    // 積み上げ棒グラフ データをセット
    const stack = d3.layout.stack();
    const dataSet = stack(motions.list);
    const drinkSet = stack(drinkMotion.list);
    const bgDataSet = stack(bgList);

    // 回転スタイル適応
    this.svg.style(this.rotation);
    this.drinkSvg.style(this.rotation);
    this.svgBgColor.style(this.rotation);

    this.svg.style({
      'z-index': 8,
    });
    this.drinkSvg.style({
      'z-index': 9,
    });
    this.svgBgColor.style({
      'z-index': 6,
    });

    this.svg.selectAll('g')
      .data(dataSet)
      .enter()
      .append('g')
      .attr('fill', (d, i) => this.item[i].color)
      .attr('name', (d, idx) => 'item_' + idx)
      .attr('opacity', (d, i) => this.item[i].name !== 'none' ? '1.0' : '0.0')
      .attr('class', (d, i) => 'point ' + this.item[i].name)
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d, i) => i * 50)
      .attr('y', (d) => this.svgHeight - d.y0 - d.y)
      .attr('width', 16)
      .attr('height', (d) => d.y);

    // 飲水
    this.drinkSvg.selectAll('g')
      .data(drinkSet)
      .enter()
      .append('g')
      .attr('fill', (d, i) => this.drinkItem[i].color)
      .attr('name', (d, idx) => 'item_' + idx)
      .attr('opacity', (d, i) => this.drinkItem[i].name !== 'none' ? '1.0' : '0.0')
      .attr('class', (d, i) => 'point ' + this.drinkItem[i].name)
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d, i) => i * 50)
      .attr('y', (d) => this.svgHeight - d.y0 - d.y)
      .attr('width', 50)
      .attr('height', (d) => d.y);

    this.svgBgColor.selectAll('g')
      .data(bgDataSet)
      .enter()
      .append('g')
      .attr('fill', '#ffffff')
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d, i) => i * 50)
      .attr('y', (d) => this.svgHeight - d.y0 - d.y)
      .attr('width', 16)
      .attr('height', (d) => d.y);

    this.selectShow(budgetType, selector, drinkData.length);
  }

  // レスポンスデータよりタイムバジェットのデータ開始位置を特定
  // 不足しているデータがある場合にはstatus=noneで補填する
  processingTimelineDate(data) {
    data = data || [];
    let target = data.length > 0 ? data[0] : {};
    let date = String(target.datehourmin).substring(0, 8);
    if (date === '') return [];

    // 時系列毎に空のオブジェクトを生成
    let timeKey = '', dataTimeList = [], dateTimeObj = {};
    // 24時間ごとの10分刻みの配列を作成 (yyyymmdd0000 ~ yyyymmdd2350)
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 6; m++) {
        timeKey = h < 10 ? ('0' + h) + (m + '0') : h + (m + '0');
        dateTimeObj[date + timeKey] = {'datehourmin': date + timeKey, 'status': 'none'};
        dataTimeList.push(date + timeKey);
      }
    }
    // data を配列からobjに変換
    let timelineObj = {};
    data.forEach(function(item) {
      if (item.status === 'ruminate_stand' || item.status === 'ruminate_lie') {
        timelineObj[String(item.datehourmin)] = {
          'datehourmin': item.datehourmin,
          'status': 'ruminate',
        };
      } else {
        timelineObj[String(item.datehourmin)] = item;
      }
    });

    const result = dataTimeList.map((key) => {
      return timelineObj[key] || dateTimeObj[key];
    });

    result.push({'datehourmin': '', 'status': 'none'});
    return result;
  }

  selectShow(budgetType, selector, drinkCount) {
    // 全ての活動量を非表示にする
    // 選択された対象の活動量のみ表示する
    // 回数と時間の算出結果を表示用DOM要素に挿入する
    // 内部で保持しているタイムバジェット状態変数を更新する

    let selectKind = '#' + selector + 'SelectKind';
    let selectMinutes = '#' + selector + 'SelectMinutes';
    let selectCount = '#' + selector + 'SelectCount';

    $(selectKind).text('');
    $(selectMinutes).text('');
    $(selectCount).text('');
    // this.svg だとウィンドウサイズ変更後DOM操作できなくなるため、selectで指定する
    const svgElement = d3.select('svg#' + selector + 'BudgetSvg');
    const drinkSvgElement = d3.select('svg#' + selector + 'BudgetSvgDrink');
    // "all"の場合には全て表示して終了
    if (budgetType === 'all') {
      $('.count-tx').addClass('hidden');
      svgElement.selectAll('g.point').attr('display', 'block');
      drinkSvgElement.selectAll('g.point').attr('display', 'block');

      this._setTimebudgetDetail(drinkCount);

      return;
    }

    $('.count-tx').removeClass('hidden');
    this.dataInfo = app.TimeBudget.dataInfo[selector];

    $(selectKind).text(this.dataInfo[budgetType].label + '：');
    const time = this.dataInfo[budgetType].time;

    if ((!this.showFeed && budgetType === 'feed') || (!this.showDrink && budgetType === 'drink')) {
      $(selectCount).text(' -');
    } else if (budgetType !== 'drink' || time !== 0) {
      $(selectMinutes).text(Math.floor(time / 60) + '時間' + (time % 60) + '分');
      $(selectCount).text('（' + this.dataInfo[budgetType].count + '回）');
      drinkSvgElement.selectAll('g.point').attr('display', 'none');
    } else {
      $(selectCount).text((drinkCount) + '回');
      drinkSvgElement.selectAll('g.point').attr('display', 'block');
    }

    this._setTimebudgetDetail(drinkCount);

    svgElement.selectAll('g.point').attr('display', 'none');
    svgElement.selectAll('g.point' + '.' + budgetType).attr('display', 'block');
  }

  /**
   * 牛個体画面活動タブタイムバジェットの詳細合計値を生成する
   */
  _setTimebudgetDetail(drinkCount) {
    const timebudgetDetail = document.getElementById('activityTimeBudget-detail');

    // 枠がない（隠された）場合、処理を停止する。
    if (!timebudgetDetail) {
      return;
    }

    let count = 0;
    timebudgetDetail.textContent = null;
    let rowElement = null;
    Object.keys(this.dataInfo).forEach((key) => {
      if (!this.dataInfo[key].label) return;
      if (count % 3 === 0) {
        rowElement = document.createElement('div');
      }
      let divElement = document.createElement('div');
      divElement.className = 'activityTimeBudget-detail-child';
      let pElement = document.createElement('p');
      pElement.className = 'activityTimeBudget-detail-child-content';
      let kindElement = document.createElement('span');
      kindElement.appendChild(document.createTextNode(this.dataInfo[key].label + ':'));

      let countElement = document.createElement('span');
      pElement.appendChild(kindElement);

      if ((!this.showFeed && key === 'feed') || (!this.showDrink && key === 'drink')) {
        pElement.appendChild(document.createTextNode(' -'));
      } else if (key !== 'drink' || this.dataInfo[key].time !== 0) {
        // ここで飲水が 0分の場合には回数のみの表示とする
        let minutesElement = document.createElement('span');
        minutesElement.appendChild(
          document.createTextNode(
            Math.floor(this.dataInfo[key].time / 60) + '時間' +
            (this.dataInfo[key].time % 60) + '分'
          )
        );
        countElement.appendChild(document.createTextNode('(' + this.dataInfo[key].count + '回)'));
        pElement.appendChild(minutesElement);
        pElement.appendChild(countElement);
      } else {
        countElement.appendChild(document.createTextNode((drinkCount) + '回'));
        pElement.appendChild(countElement);
      }

      divElement.appendChild(pElement);
      rowElement.appendChild(divElement);

      if (count % 2 === 1) {
        timebudgetDetail.appendChild(rowElement);
      }
      count++;
    });
    if (count % 2 === 1) {
      timebudgetDetail.appendChild(rowElement);
    }
  }

  /**
   * メモリー解放
   * @param {string} selector summary || activity
   */
  clear(selector) {
    d3.selectAll(`#${selector}TimeBudget svg`).remove();
  }
}
