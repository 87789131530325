class MobileAlertEartagSensorDroppedController {
  constructor(
    $q,
    $state,
    $timeout,
    appConfig,
    blockUI,
    AlertEartagSensorDroppedAPI,
    SelectionService,
    UserDefinedSelectionAPI,
    MobileConfirmModal,
    MobileStandardModal
  ) {
    'ngInject';
    this.$q = $q;
    this.$state = $state;
    this.$timeout = $timeout;
    this.appConfig = appConfig;
    this.blockUI = blockUI;
    this.AlertEartagSensorDroppedAPI = AlertEartagSensorDroppedAPI;
    this.SelectionService = SelectionService;
    this.UserDefinedSelectionAPI = UserDefinedSelectionAPI;
    this.MobileConfirmModal = MobileConfirmModal;
    this.MobileStandardModal = MobileStandardModal;

    this.title = 'イヤタグセンサー脱落アラート';
    this.init();
  }

  init() {
    this.shouldShowSolutionSelection = false;
    this.currentAlert = null;
    this.isSolving = false;

    this.$q.all([
      this.AlertEartagSensorDroppedAPI.unsolved(),
      this.SelectionService.index(),
      this.UserDefinedSelectionAPI.available()
    ]).then((res) => {
      this.alerts = res[0].data.map((d) => AlertEartagSensorDropped.convertViewModel(d));

      this.expelledReasons = res[1].expelledReason.map((reason) => {
        return {
          label: reason.label,
          value: reason.value
        };
      });

      this.buyers = getCustomSelectList('buyers', res[2].data, false);

      this.loading = false;
    });
  }

  update(solution, factor, shipmentsForm) {
    const params = Object.assign({solution: solution, factor: factor}, shipmentsForm);

    this.blockUI.start('登録中');

    this.AlertEartagSensorDroppedAPI.update(this.currentAlert.id, params).then((res) => {
      this.blockUI.done(() => {
        this.blockUI.start('処置内容の登録が完了しました');
        this.$timeout(() => {
          this.blockUI.stop();
          this.$state.reload();
        }, 1000);
      });

      this.blockUI.stop();
    }).catch((error) => {
      const errorMessage = ErrorUtil.formatErrorMessage(error.data.messages);
      this.blockUI.stop();
      this.MobileConfirmModal.open(errorMessage);
    }).finally(() => {
      this.currentAlert = null;
    });
  }

  goToCowDetail(cowId) {
    this.$state.go('mobileCowDetail', {cowId: cowId, caller: {state: 'eartag-sensor-dropped'}});
  }

  showSolutionSelection(id) {
    this.currentAlert = this.alerts.find((a) => a.id === id);
    this.shouldShowSolutionSelection = true;
  }

  hideSolutionSelection() {
    this.shouldShowSolutionSelection = false;
  }

  showFactorSelection(solution) {
    this.solution = solution;
    this.shouldShowSolutionSelection = false;
    this.shouldShowFactorSelection = true;
  }

  showShipmentsSelection() {
    if (this.currentAlert.active) {
      this.shouldShowSolutionSelection = false;
      this.shouldShowShipmentsForm = true;
    } else {
      this.solve('shipments', 0, {});
    }
  }

  hideFactorSelection() {
    this.shouldShowFactorSelection = false;
    this.shouldShowSolutionSelection = true;
  }

  hideShipmentsForm() {
    this.shouldShowSolutionSelection = true;
    this.shouldShowShipmentsForm = false;
  }

  solve(solution, factor = 0, shipmentsForm = null) {
    if (this.isSolving) return;

    this.isSolving = true;
    const solutionHash = {'reequip': '再装着', 'change': '交換・解除', 'ignore': '問題なし', 'shipments': '出荷'};

    let message = `処置内容を「${solutionHash[solution]}」で登録します。\nよろしいでしょうか？`;
    if (solution === 'shipments') {
      if (shipmentsForm.occurredAt) {
        shipmentsForm.entryStaffName = this.appConfig.staff.name;
        shipmentsForm.lastEditStaffName = this.appConfig.staff.name;

        message = `処置内容を「${solutionHash[solution]}」で登録します。\n同時に出荷イベントが登録されますが、\nよろしいでしょうか？`;
      }
    }

    if (solution === 'change') {
      message = `処置内容を「${solutionHash[solution]}」で登録します。\nセンサーの交換・解除を行ったうえで登録して下さい。\nよろしいでしょうか？`;
    }

    this.MobileStandardModal.open(message, {okLabel: '登録'}).then((res) => {
      if (!res) return;

      this.hideSolutionSelection();
      this.update(solution, factor, shipmentsForm);
    }).finally(() => this.isSolving = false);
  }
}

app.controller('MobileAlertEartagSensorDroppedController', MobileAlertEartagSensorDroppedController);
