class FatteningReportItemAPI {
  constructor(
    APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/fattening/report_item');
  }

  create(items) {
    const params = {items: items};
    return this.APIService.post('/fattening/report_item', params);
  }
}

app.service('FatteningReportItemAPI', FatteningReportItemAPI);
