// eslint-disable-next-line no-unused-vars
class CustomlistHtmlGenerator {
  static cell(columnId, cssStyle) {
    const value = cssStyle ? cssStyle : '';
    return `
      <div>
        <span ng-bind="cow.${columnId}" ng-style="${value}"></span>
      </div>`;
  }

  static cowNo(showCowDetailLink) {
    if (showCowDetailLink) {
      return `<div><a href="" ng-bind="cow.cow_no" ng-click="ctrl.goToDetails(cow.cowId)" class="cowNo"></a></div>`;
    } else {
      return `<div><span ng-bind="cow.cow_no" class="cowNo"></span></div>`;
    }
  }

  static calfNo(showCowDetailLink) {
    if (showCowDetailLink) {
      return `<div><a href="" ng-bind="cow.calf_no" ng-click="ctrl.goToDetails(cow.cowId)"></a></div>`;
    } else {
      return `<div><span ng-bind="cow.calf_no"></span></div>`;
    }
  }

  static cowLables() {
    return `
      <div class="cow-labels-wrapper">
        <span style="display: inline-block; min-width: 90px;">
          <span style="font-size: 1.2rem; text-align: center;" ng-repeat="label in cow.cowLabels track by $index">
            <span class="cow-labels kindBackgroundColor" ng-bind="label"></span>
          </span>
        </span>
      </div>`;
  }

  static comment() {
    return `
      <div ng-repeat="comment in cow.comments track by $index">
        <span ng-bind="comment"></span>
      </div>`;
  }

  static summary(columnId) {
    return `
      <div>
        <span ng-bind-html="cow.${columnId}"></span>
      </div>`;
  }

  static cowAlertType() {
    return `
      <div>
        <span
          ng-repeat="label in cow.alertLabels track by $index"
          ng-bind="label"
          class="text-join"
        >
        </span>
      </div>`;
  }
}
