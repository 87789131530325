class CarcassCharacteristicService {
  constructor(
    APIService,
    DateUtilService
  ) {
    'ngInject';
    this.APIService = APIService;
    this.DateUtilService = DateUtilService;
  }

  /**
   * @param {Date|String|number} startMonth 集計開始月
   * @param {Date|String|number} endMonth 集計終了月
   */
  monthly(startMonth, endMonth) {
    let query = `?start_month=${this.DateUtilService.toYYYYMM(startMonth)}`;
    query += `&end_month=${this.DateUtilService.toYYYYMM(endMonth)}`;
    return this.APIService.get('/carcass_characteristic/monthly' + query);
  }
}

app.service('CarcassCharacteristicService', CarcassCharacteristicService);
