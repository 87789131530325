class FatteningReportItemController {
  constructor(
    $modalInstance,
    modalDialogFactory,
    blockUI,
    $timeout,
    FatteningReportItemAPI,
    uiGridConstants,
    params) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.modalDialogFactory = modalDialogFactory;
    this.blockUI = blockUI;
    this.$timeout = $timeout;
    this.FatteningReportItemAPI = FatteningReportItemAPI;
    this.uiGridConstants = uiGridConstants;

    this.initialize(params);
  }

  initialize(params) {
    this.keyField = 'itemId';
    this.showGrid = false;
    this.items = [];
    this.invalid = false;
    this.isLegacy = params.isLegacy;

    this.setUiGrid();
    this.index();
  }

  setUiGrid() {
    const columns = [
      {columnId: 'label', label: '項目名称', width: 250},
      {columnId: 'aliasLabel', label: '略称', width: 250},
      {columnId: 'visible', label: '表示対象', width: 70},
    ];

    const options = {
      clickRowEvent: 'onClickRow',
      cellTemplates: {
        visible: `<div ng-bind="row.entity.visibleLabel" class="align-center"></div`
      }
    };

    const uiGrid = new UiGridGenerator().generate(this, columns, options);
    this.uiGrid = uiGrid;
  }

  index() {
    this.FatteningReportItemAPI.index().then((res) => {
      this.items = res.data.map((item) => {
        item.visibleLabel = item.visible ? '〇' : '';
        return item;
      });
      this.uiGrid.data = this.items;
      this.showGrid = true; // 最初からUI Gridを表示していると何故か再表示時に表示が欠ける
    });
  }

  validate() {
    this.invalid = true;

    const anyShownItem = this.items.some((item) => item.visible);
    if (!anyShownItem) return;

    this.invalid = false;
  }

  onClickRow(row) {
    if (this.currentRow) {
      this.currentRow.selected = false;
    }
    this.currentRow = row;
    this.seletedItemKey = row.itemId;
    this.currentRow.selected = true;

    this.notifyUiGridRowChange();
  }

  onChangeVisible(row) {
    row.visibleLabel = row.visible ? '〇' : '';
    this.validate();
  }

  notifyUiGridRowChange() {
    this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
  }

  save() {
    this.blockUI.start('更新中');

    this.FatteningReportItemAPI.create(this.items).then((res) => {
      this.blockUI.stop();

      this.blockUI.start('更新が完了しました');
      this.$timeout(() => {
        this.blockUI.stop();
      }, 1000);
      this.$modalInstance.close({executed: true});
    }).catch((error) => {
      this.errorMessage = ErrorUtil.formatErrorMessage(error.data.messages);
      this.blockUI.stop();
    });
  }

  cancel() {
    this.$modalInstance.dismiss('cancel');
  }
}

app.controller('FatteningReportItemController', FatteningReportItemController);
