class MobileRemoveCowsModalController {
  constructor(
    $modalInstance,
    cows
  ) {
    'ngInject';

    this.cows = cows.map((cow) => {
      cow.checked = true;
      cow.cowUidDisplay = Cow.formatCowUid(cow.uid);
      return cow;
    });
    this.$modalInstance = $modalInstance;
  }

  onCheckBoxClick(cow) {
    cow.selected = !cow.selected;
  }

  onCancelClick() {
    this.$modalInstance.dismiss({
      status: {
        cancel: true
      }
    });
  }

  onOkClick() {
    this.$modalInstance.close({
      status: {
        ok: true,
      },
      cows: this.cows.filter((cow) => cow.selected)
    });
  }
}

app.controller('MobileRemoveCowsModalController', MobileRemoveCowsModalController);
