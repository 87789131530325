/**
 * @deprecated ActiveAmountAPIを利用してください
 * ※この実装の修正および、新規の利用は禁止です
 */
class ActiveAmountService {
  constructor(JWTService, APIService) {
    'ngInject';
    this.JWTService = JWTService;
    this.APIService = APIService;
  }

  getActiveTimelineFindByDate(bleId, startDate, endDate) {
    return this.JWTService.bleSenserRequest('/cow/timeline?bleid=' + bleId + '&start_date=' + startDate + '&end_date=' + endDate, 'GET');
  }

  getEartagActivityStatusIn10minutes(bleId, startDateTime) {
    const endpoint = `/eartag_activity_log?ble_id=${bleId}&start_date_time=${startDateTime}`;
    return this.APIService.get(endpoint);
  }

  getFarmSummary(params) {
    let endpoint = '/activity/daily/farm?';
    endpoint += params.dataType ? '&data_type=' + params.dataType : '';
    endpoint += params.startDate ? '&start_date=' + String(params.startDate) : '';
    endpoint += params.endDate ? '&end_date=' + String(params.endDate) : '';
    endpoint += params.statsType ? '&stats_type=' + params.statsType : '';
    return this.APIService.get(endpoint);
  }

  getCowshedSummary(params) {
    let endpoint = '/activity/daily/cowshed/' + params.cowshedId + '?';
    endpoint += params.dataType ? '&data_type=' + params.dataType : '';
    endpoint += params.startDate ? '&start_date=' + String(params.startDate) : '';
    endpoint += params.endDate ? '&end_date=' + String(params.endDate) : '';
    endpoint += params.statsType ? '&stats_type=' + params.statsType : '';
    return this.APIService.get(endpoint);
  }

  getCowGroupSummary(params) {
    let endpoint = '/activity/daily/group/' + params.cowGroupId + '?';
    endpoint += params.dataType ? '&data_type=' + params.dataType : '';
    endpoint += params.startDate ? '&start_date=' + String(params.startDate) : '';
    endpoint += params.endDate ? '&end_date=' + String(params.endDate) : '';
    endpoint += params.statsType ? '&stats_type=' + params.statsType : '';
    return this.APIService.get(endpoint);
  }

  getFatteningStageSummary(params) {
    let endpoint = `/activity/daily/fattening_stage/${params.fatteningStageId}?`;
    endpoint += params.dataType ? `&data_type=${params.dataType}` : '';
    endpoint += params.startDate ? `&start_date=${String(params.startDate)}` : '';
    endpoint += params.endDate ? `&end_date=${String(params.endDate)}` : '';
    endpoint += params.statsType ? `&stats_type=${params.statsType}` : '';
    return this.APIService.get(endpoint);
  }

  // 指定期間の飲水回数を取得 (date + count)
  getDrinkingFindByDatehour(cowId, startAt, endAt) {
    return this.APIService.get('/activity/drinking/count/' + cowId + '?start_at=' + startAt + '&end_at=' + endAt);
  }

  /**
   * 指定期間の飲水開始時刻を取得 (events)
   * @params {string} cowId
   * @params {number} startDate 表示したい期間の0時のtimestamp -> 2017-10-01 00:00:00 -> 1506783600000
   * @params {number} endDate 表示したい期間の終日時刻のtimestamp -> 2017-10-01 23:59:59 -> 1506869999000
   */
  getDailyDrinkingTimes(cowId, startDate, endDate) {
    return this.APIService.get('/activity/drinking/' + cowId + '?start_at=' + startDate + '&end_at=' + endDate);
  }

  getDeprecatedFeedSourceType(cowId, date) {
    return this.APIService.get(`/activity/deprecated_feed_source?cow_id=${cowId}&date=${date}`);
  }
}

app.service('ActiveAmountService', ActiveAmountService);
