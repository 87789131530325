// eslint-disable-next-line no-unused-vars
class CowValidator {
  constructor(farm) {
    this.farm = farm;
  }

  /**
   * 牛番号用のバリデーション
   * バリデーション結果とエラーが発生している場合は
   * エラーメッセージを付加してオブジェクトで返す
   *
   * {valid: boolean(false: エラー), errorMessage: String}
   *
   * ex.
   * {valid: true}
   * {valid: false, error: '必須項目です'}
   *
   * @param {String} cowNo 牛番号
   * @return {Object}
   */
  validateCowNo(cowNo) {
    const requiredResult = this.validateRequired(cowNo);

    if (!requiredResult.valid) return requiredResult;

    if (this.farm.useName()) return {valid: true};

    if (this.farm.isAlphanumericCowNo()) {
      return this.validateAlphanumeric(cowNo);
    } else {
      return this.validateNumber(cowNo);
    }
  }

  validateRequired(value) {
    if ([null, undefined, ''].includes(value)) {
      return {valid: false, errorMessage: '必須項目です'};
    } else {
      return {valid: true};
    }
  }

  validateAlphanumeric(value) {
    if (/^[a-zA-Z0-9]+$/.test(value)) {
      return {valid: true};
    } else {
      return {valid: false, errorMessage: '入力できるのは英数字のみです'};
    }
  }

  validateNumber(value) {
    if (/^[0-9]+$/.test(value)) {
      return {valid: true};
    } else {
      return {valid: false, errorMessage: '入力できるのは半角数値のみです'};
    }
  }
}
