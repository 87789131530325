class AlertMonitoringService {
  constructor(AlertDysstasiaMonitoringService) {
    'ngInject';
    this.AlertDysstasiaMonitoringService = AlertDysstasiaMonitoringService;
  }

  run() {
    this.AlertDysstasiaMonitoringService.run();
  }
}

app.service('AlertMonitoringService', AlertMonitoringService);
