class MasterDialog {
  constructor(
    $modal,
    CowGroupAPI,
    MasterMarketAPI
  ) {
    'ngInject';
    this.$modal = $modal;
    this.CowGroupAPI = CowGroupAPI;
    this.MasterMarketAPI = MasterMarketAPI;

    this.configs = {
      'cow_group': {
        caption: '牛群',
        indexAction: this.CowGroupAPI.index.bind(this.CowGroupAPI),
        idColumn: 'cowGroupId',
        nameColumn: 'cowGroupName'
      },
      'master_market': {
        caption: '購入先',
        indexAction: this.MasterMarketAPI.available.bind(this.MasterMarketAPI),
        needToSelect: true
      },
    };
  }

  execute(masterId, currentSelections) {
    const config = this.configs[masterId];

    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'dialog/condition/master-dialog.html',
      controller: 'MasterDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'select-standard',
      resolve: {
        params: () => {
          return {
            indexAction: config.indexAction,
            caption: config.caption,
            currentSelections: currentSelections,
            idColumn: config.idColumn || 'id',
            nameColumn: config.nameColumn || 'name',
            needToSelect: config.needToSelect,
            masterId: masterId
          };
        }
      }
    });

    return modalInstance.result;
  }
}

app.service('MasterDialog', MasterDialog);
