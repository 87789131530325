// eslint-disable-next-line no-unused-vars
class TimelineItemDefinition {
  static get definitions() {
    return [
      // type: STRING
      {itemId: 'mastitisDiagnosisResultOfBreastFl', label: '診断結果（左前）', type: 'STRING'},
      {itemId: 'mastitisDiagnosisResultOfBreastFr', label: '診断結果（右前）', type: 'STRING'},
      {itemId: 'mastitisDiagnosisResultOfBreastBl', label: '診断結果（左後）', type: 'STRING'},
      {itemId: 'mastitisDiagnosisResultOfBreastBr', label: '診断結果（右後）', type: 'STRING'},
      {itemId: 'workerName', label: '担当者', type: 'STRING'},
      {itemId: 'mastitisBacteriaOfBreastFl', label: '乳房炎菌（左前）', type: 'STRING'},
      {itemId: 'mastitisBacteriaOfBreastFr', label: '乳房炎菌（右前）', type: 'STRING'},
      {itemId: 'mastitisBacteriaOfBreastBl', label: '乳房炎菌（左後）', type: 'STRING'},
      {itemId: 'mastitisBacteriaOfBreastBr', label: '乳房炎菌（右後）', type: 'STRING'},
      {itemId: 'diagnosisResultDetail', label: '診断治療結果', type: 'STRING'},
      {itemId: 'comment', label: 'コメント', type: 'STRING'},
      {itemId: 'diagnosisResultOfOvaryL', label: '卵巣（左）', type: 'STRING'},
      {itemId: 'diagnosisResultOfOvaryR', label: '卵巣（右）', type: 'STRING'},
      {itemId: 'uterusDiagnosisResult', label: '子宮', type: 'STRING'},
      {itemId: 'difficultiesOvaryStill', label: '卵巣静止・萎縮', type: 'STRING'},
      {itemId: 'difficultiesOvaryCystic', label: '卵胞嚢腫', type: 'STRING'},
      {itemId: 'difficultiesRemnant', label: '黄体遺残', type: 'STRING'},
      {itemId: 'difficultiesMetritis', label: '子宮炎', type: 'STRING'},
      {itemId: 'difficultiesPyometra', label: '子宮蓄膿症', type: 'STRING'},
      {itemId: 'difficultiesKetosis', label: 'ケトーシス', type: 'STRING'},
      {itemId: 'difficultiesFattyLiver', label: '脂肪肝', type: 'STRING'},
      {itemId: 'difficultiesAbomasumDisplacement', label: '第四胃変位', type: 'STRING'},
      {itemId: 'difficultiesHypocalcium', label: '低カルシウム', type: 'STRING'},
      {itemId: 'difficultiesAcidosis', label: 'アシドーシス', type: 'STRING'},
      {itemId: 'difficultiesDowner', label: 'ダウナー症候群', type: 'STRING'},
      {itemId: 'difficultiesStagnantFood', label: '食滞', type: 'STRING'},
      {itemId: 'difficultiesPlacenta', label: '胎盤停滞', type: 'STRING'},
      {itemId: 'difficultiesTympanites', label: '鼓張症', type: 'STRING'},
      {itemId: 'difficultiesHipDislocation', label: '股関節脱臼', type: 'STRING'},
      {itemId: 'difficultiesTeatInjury', label: '乳頭損傷', type: 'STRING'},
      {itemId: 'difficultiesPneumonia', label: '肺炎', type: 'STRING'},
      {itemId: 'difficultiesDiarrhea', label: '下痢', type: 'STRING'},
      {itemId: 'difficultiesSkinDisease', label: '皮膚病', type: 'STRING'},
      {itemId: 'freshCheckResult', label: 'チェック結果', type: 'STRING'},
      {itemId: 'otherReproductionWork', label: 'その他処置', type: 'STRING'},
      {itemId: 'plannedBredMethod', label: '種付予定', type: 'STRING'},
      {itemId: 'heatComment', label: '発情所見', type: 'STRING', relatedColumn: 'comment'},
      {itemId: 'tanetsukeMethod', label: '種付方法', type: 'STRING'},
      {itemId: 'inseminationCode', label: '授精コード', type: 'STRING'},
      {itemId: 'masterSpermName', label: '種雄牛名', type: 'STRING'},
      {itemId: 'masterSpermNo', label: '精液番号', type: 'STRING'},
      {itemId: 'donorName', label: '供卵牛', type: 'STRING'},
      {itemId: 'donorRegistrationNo', label: '供卵牛登録番号', type: 'STRING'},
      {itemId: 'donorBreed', label: '供卵牛品種', type: 'STRING'},
      {itemId: 'donorSpermNo', label: '供卵父牛精液番号', type: 'STRING'},
      {itemId: 'motherBreedOfDonor', label: '供卵母牛品種', type: 'STRING'},
      {itemId: 'motherRegistrationNoOfDonor', label: '供卵母牛登録番号', type: 'STRING'},
      {itemId: 'motherNameOfDonor', label: '供卵母牛名', type: 'STRING'},
      {itemId: 'ninshinkanteiResult', label: '鑑定結果', type: 'STRING'},
      {itemId: 'judgePregnantTiming', label: 'タイミング', type: 'STRING'},
      {itemId: 'afterCowState', label: '牛個体の状態', type: 'STRING'},
      {itemId: 'calvingDifficulty', label: '分娩難易度', type: 'STRING'},
      {itemId: 'hormoneProgramName', label: 'ホルモンプログラム名', type: 'STRING'},
      {itemId: 'hormoneName', label: '処置内容', type: 'STRING'},
      {itemId: 'breedingExclusionReason', label: '除外理由', type: 'STRING'},
      {itemId: 'taioTitle', label: 'タイトル', type: 'STRING'},
      {itemId: 'vaccineTiming', label: 'タイミング', type: 'STRING'},
      {itemId: 'selectedDryPeriod', label: '前後期', type: 'STRING'},
      {itemId: 'expelledReason', label: '除籍理由', type: 'STRING'},
      {itemId: 'producingArea', label: '生産地', type: 'STRING'},
      {itemId: 'producingFarmName', label: '生産農場名', type: 'STRING'},
      {itemId: 'buyer', label: '販売先', type: 'STRING'},
      {itemId: 'deathReason', label: 'へい死理由', type: 'STRING'},
      {itemId: 'grade', label: '等級', type: 'STRING'},
      {itemId: 'otherFaultCorrection', label: '歩留補正', type: 'STRING'},
      {itemId: 'eyeCondition', label: '目', type: 'STRING'},
      {itemId: 'earCondition', label: '耳', type: 'STRING'},
      {itemId: 'muzzleCondition', label: '鼻', type: 'STRING'},
      {itemId: 'hairCondition', label: '毛', type: 'STRING'},
      {itemId: 'hipCondition', label: '尻', type: 'STRING'},
      {itemId: 'bodyCondition', label: '体', type: 'STRING'},
      {itemId: 'fecesCondition', label: '糞', type: 'STRING'},
      {itemId: 'urineCondition', label: '尿', type: 'STRING'},
      {itemId: 'dehornMethod', label: '除角方法', type: 'STRING'},
      {itemId: 'castratMethod', label: '去勢方法', type: 'STRING'},
      {itemId: 'title', label: 'タイトル', type: 'STRING'},
      {itemId: 'memo', label: 'メモ', type: 'STRING', relatedColumn: 'comment'},
      {itemId: 'bodyTemperature', label: '体温', type: 'FLOAT', suffix: '℃'},

      // type: DATE
      {itemId: 'occurredDiseaseDate', label: '発生日', type: 'DATE'},
      {itemId: 'endDateOfMilkWashoutPeriod', label: '休薬終了日（乳）', type: 'DATE'},
      {itemId: 'endDateOfBeefWashoutPeriod', label: '休薬終了日（肉）', type: 'DATE'},
      {itemId: 'plannedBredAtDate', label: '種付予定日', type: 'DATE', relatedColumn: 'plannedBredAt'},
      {itemId: 'targetBreedingDate', label: '対象種付日', type: 'DATE'},

      // type: TIME
      {itemId: 'plannedBredAtTime', label: '種付予定時刻', type: 'TIME', relatedColumn: 'plannedBredAt'},

      // type: FLOAT
      {itemId: 'bcs', label: 'BCS', type: 'FLOAT'},
      {itemId: 'dg', label: 'DG', type: 'FLOAT', suffix: 'kg'},
      {itemId: 'dressedCarcassWeightOfL', label: '枝肉重量（左）', type: 'FLOAT'},
      {itemId: 'dressedCarcassWeightOfR', label: '枝肉重量（右）', type: 'FLOAT'},
      {itemId: 'totalDressedCarcassWeight', label: '枝肉総重量', type: 'FLOAT', suffix: 'kg'},
      {itemId: 'dressedCarcassUnitPrice', label: '枝肉単価', type: 'FLOAT', suffix: '円'},
      {itemId: 'dressedCarcassSalesPrice', label: '枝肉販売金額', type: 'FLOAT', suffix: '円'},
      {itemId: 'beforeSlaughterWeight', label: 'と畜前体重', type: 'FLOAT', suffix: 'kg'},
      {itemId: 'loinArea', label: '胸最長筋面積', type: 'FLOAT'},
      {itemId: 'ribsThickness', label: 'ばらの厚さ', type: 'FLOAT'},
      {itemId: 'subcutaneousFat', label: '皮下脂肪の厚さ', type: 'FLOAT'},
      {itemId: 'yieldBaseValue', label: '歩留基準値', type: 'FLOAT'},

      // type: INTEGER
      {itemId: 'childNumber', label: '出生頭数', type: 'INTEGER'},
      {itemId: 'locomotionScore', label: '跛行スコア', type: 'INTEGER'},
      {itemId: 'embryoRecoveryRankA', label: 'A', type: 'INTEGER'},
      {itemId: 'embryoRecoveryRankB', label: 'B', type: 'INTEGER'},
      {itemId: 'embryoRecoveryRankC', label: 'C', type: 'INTEGER'},
      {itemId: 'embryoRecoveryRankD', label: '変性', type: 'INTEGER'},
      {itemId: 'embryoRecoveryUnfertilized', label: '未受精', type: 'INTEGER'},
      {itemId: 'weight', label: '体重', type: 'INTEGER', suffix: 'kg'},
      {itemId: 'breedingDays', label: '飼育日数', type: 'INTEGER', suffix: '日'},
      {itemId: 'salesPrice', label: '販売価格', type: 'INTEGER'},
      {itemId: 'shipmentNo', label: '出荷番号', type: 'INTEGER'},
      {itemId: 'slaughterNo', label: 'と畜番号', type: 'INTEGER'},
      {itemId: 'bmsNo', label: 'BMSNo', type: 'INTEGER'},
      {itemId: 'marblingGrade', label: '脂肪交雑等級', type: 'INTEGER'},
      {itemId: 'bcsNo', label: 'BCSNo', type: 'INTEGER'},
      {itemId: 'gloss', label: '光沢', type: 'INTEGER'},
      {itemId: 'bcsAndGlossGrade', label: '肉色及び光沢の等級', type: 'INTEGER'},
      {itemId: 'tight', label: '締まり', type: 'INTEGER'},
      {itemId: 'texture', label: 'きめ', type: 'INTEGER'},
      {itemId: 'tightAndTextureGrade', label: '締まり及びきめの等級', type: 'INTEGER'},
      {itemId: 'bfsNo', label: 'BFSNo', type: 'INTEGER'},
      {itemId: 'fatLuster', label: '光沢と質', type: 'INTEGER'},
      {itemId: 'bfsAndFatLusterGrade', label: '色沢と質の等級', type: 'INTEGER'},

      // type: SPECIAL
      {itemId: 'medications', label: '投薬', type: 'SPECIAL'},
      {itemId: 'mastitisScore', label: '乳房炎スコア', type: 'SPECIAL'},
      {itemId: 'bullCowId', label: '種牛番号', type: 'SPECIAL'},
      {itemId: 'bullCowIdUseName', label: '種牛', type: 'SPECIAL'},
      {itemId: 'otherDisease', label: 'その他症状', type: 'SPECIAL'},
      {itemId: 'nextStartMilkingFlg', label: '次産泌乳', type: 'SPECIAL'},
      {itemId: 'birthResult01', label: '出産結果（1頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'breed01', label: '品種（1頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'gender01', label: '性別（1頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'registered01', label: 'データ作成（1頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'cowNo01', label: '牛番号（1頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'cowUid01', label: '個体識別番号（1頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'weightOnBirth01', label: '体重（1頭目）', type: 'SPECIAL', relatedColumn: 'calfs', suffix: 'kg'},
      {itemId: 'calfNo01', label: '子牛番号（1頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'birthStateString01', label: '出生時状態（1頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'birthResult02', label: '出産結果（2頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'breed02', label: '品種（2頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'gender02', label: '性別（2頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'registered02', label: 'データ作成（2頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'cowNo02', label: '牛番号（2頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'cowUid02', label: '個体識別番号（2頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'weightOnBirth02', label: '体重（2頭目）', type: 'SPECIAL', relatedColumn: 'calfs', suffix: 'kg'},
      {itemId: 'calfNo02', label: '子牛番号（2頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'birthStateString02', label: '出生時状態（2頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'birthResult03', label: '出産結果（3頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'breed03', label: '品種（3頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'gender03', label: '性別（3頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'registered03', label: 'データ作成（3頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'cowNo03', label: '牛番号（3頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'cowUid03', label: '個体識別番号（3頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'weightOnBirth03', label: '体重（3頭目）', type: 'SPECIAL', relatedColumn: 'calfs', suffix: 'kg'},
      {itemId: 'calfNo03', label: '子牛番号（3頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'birthStateString03', label: '出生時状態（3頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'birthResult04', label: '出産結果（4頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'breed04', label: '品種（4頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'gender04', label: '性別（4頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'registered04', label: 'データ作成（4頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'cowNo04', label: '牛番号（4頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'cowUid04', label: '個体識別番号（4頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'weightOnBirth04', label: '体重（4頭目）', type: 'SPECIAL', relatedColumn: 'calfs', suffix: 'kg'},
      {itemId: 'calfNo04', label: '子牛番号（4頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'birthStateString04', label: '出生時状態（4頭目）', type: 'SPECIAL', relatedColumn: 'calfs'},
      {itemId: 'registeredCalfs', label: '子牛情報', type: 'SPECIAL'},
      {itemId: 'moveToCowGroupName', label: '移動先牛群', type: 'SPECIAL', relatedColumn: 'moveToCowGroupId'},
      {itemId: 'mastitisCowGroupName', label: '発生時所属牛群', type: 'SPECIAL', relatedColumn: 'mastitisCowGroupId'},
      {itemId: 'moveToPen', label: '移動先牛房', type: 'SPECIAL'},
      {itemId: 'embryoMasterRegistered', label: '受精卵マスタへの登録', type: 'SPECIAL'},
      {itemId: 'nextCowState', label: '採卵後の状態', type: 'SPECIAL'},
      {itemId: 'carcassDefectSummary', label: '瑕疵', type: 'SPECIAL', relatedColumn: 'carcassDefects'},
      {itemId: 'visceralDestruction', label: '内臓廃棄', type: 'SPECIAL'}
    ];
  }

  static deinitionMap() {
    const defs = TimelineItemDefinition.definitions;
    return defs.reduce((obj, def) => {
      obj[def.itemId] = def;
      return obj;
    }, {});
  }

  static itemDefinitions(itemIds) {
    const defs = TimelineItemDefinition.deinitionMap();
    return itemIds.map((itemId) => defs[itemId]);
  }

  static toDisplayFormat(items, record, map) {
    const eventType = map.eventTypeMap[record.eventType];
    const eventName = EventType.eventName(eventType);
    const color = map.eventMap[eventType].color;

    let date = null;
    let time = null;

    if (['mastitis', 'claw_diseases', 'reproductive_disorders', 'perinatal_and_metabolic_diseases',
      'injured', 'infection_diseases', 'others'].includes(eventType)) {
      date = record.treatmentDiseaseDate;
      time = DateUtil.toHHmm(record.treatmentDiseaseDate);
    } else if (['heat', 'bred', 'aborted', 'calved', 'timed_ai', 'work_note', 'farm_diary'].includes(eventType)) {
      date = record.occurredAt;
      time = DateUtil.toHHmm(record.occurredAt);
    }

    const details = items.map((item) => {
      const value = TimelineItemDefinition.toDisplayValue(item, record, map);

      return Object.assign(item, {value});
    }).filter((item) => item.value);

    return {
      id: record.id,
      eventType,
      eventName,
      color,
      date,
      time,
      cowId: record.cowId || null,
      cowNo: record.cowNo || null,
      details
    };
  }

  static toDisplayValue(item, record, map) {
    if (item.type === 'SPECIAL') {
      if (item.itemId === 'mastitisScore') {
        const mastitisScore = Mastitis.MASTITIS_SCORES.find((s) => s.value === record.mastitisScore) || [];
        return mastitisScore.label;
      }

      if (item.itemId === 'medications') {
        return record.medications.map((m) => {
          const capacity = m.capacity ? ` ${m.capacity}${m.unit}` : '';
          const method = m.method ? ` ${m.method}` : '';

          return `${m.name}${capacity}${method}`;
        }).join('、');
      }

      if (['bullCowId', 'bullCowIdUseName'].includes(item.itemId)) {
        if (!record.bullCowId) return;

        return {
          type: 'cowDetailLinks',
          cows: [{
            cowId: record.bullCowId,
            label: '対象種牛'
          }]
        };
      }

      if (item.itemId === 'otherDisease') {
        if (!record.difficultiesOther) {
          return record.otherDiseaseName;
        }

        return `${record.otherDiseaseName}：${record.difficultiesOther}`;
      }

      if (item.itemId === 'nextStartMilkingFlg') {
        return record.nextStartMilkingFlg ? 'あり' : 'なし';
      }

      if (item.relatedColumn === 'calfs') {
        const match = item.itemId.match(/^([a-zA-Z]+)([0-9]+)$/);
        const itemId = match[1];
        const index = match[2] - 1;

        if (record.calfs.length <= index) return null;

        if (itemId === 'birthStateString') {
          return record.calfs[index].birthState.join('、');
        }

        return record.calfs[index][itemId];
      }

      if (item.itemId === 'registeredCalfs') {
        const cows = record.calfs.filter((calf) => {
          return calf.registered;
        }).map((calf) => {
          return {
            cowId: calf.cowId,
            label: calf.cowNo || '-'
          };
        });

        return {
          type: 'cowDetailLinks',
          cows
        };
      }

      if (['moveToCowGroupName', 'mastitisCowGroupName'].includes(item.itemId)) {
        const cowGroupId = record[item.relatedColumn];

        if (!cowGroupId) return null;

        return map.cowGroupMap[cowGroupId].cowGroupName;
      }

      if (item.itemId === 'moveToPen') {
        const pen = record[item.itemId];

        if (!pen) return null;

        return pen;
      }

      if (item.itemId === 'embryoMasterRegistered') {
        if (record.embryoMasterRegistered) {
          return '対象';
        }

        return '対象外';
      }

      if (item.itemId === 'nextCowState') {
        return map.nextCowStateMap[record.nextCowState].label;
      }

      if (item.itemId === 'carcassDefectSummary') {
        return Carcass.defectSummary(record[item.relatedColumn]);
      }

      if (item.itemId === 'visceralDestruction') {
        if (!record.visceralDestruction) return null;

        return record.visceralDestruction.replace(/:/g, '、');
      }
    }

    if (item.type === 'STRING') {
      return record[item.itemId] || record[item.relatedColumn];
    }

    if (item.type === 'DATE') {
      return DateUtil.toMMDD(record[item.itemId]) || DateUtil.toMMDD(record[item.relatedColumn]);
    }

    if (item.type === 'TIME') {
      return DateUtil.toHHmm(record[item.itemId]) || DateUtil.toHHmm(record[item.relatedColumn]);
    }

    const value = record[item.itemId];
    if (!value) {
      return item.default || '';
    }

    const prefix = item.prefix || '';
    const suffix = item.suffix || '';

    return `${prefix}${value}${suffix}`;
  }
}
