class Dictionary {
  constructor(
    FarmService
  ) {
    'ngInject';
    this.FarmService = FarmService;
  }

  initialize() {
    return this.FarmService.show().then((res) => {
      const farm = new Farm(res);

      this.COW = {
        COW_NO: farm.useName() ? '名前' : '牛番号'
      };
    });
  }
}

app.service('Dictionary', Dictionary);
