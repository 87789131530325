/**
 * 牛個体のタイムラインコンポーネント
 *
 * ex.
 *  <cow-timeline
 *    cow: 'cow',
 *    is-register-event: 'isRegisterEvent',
 *    qwert: 'qwert',
 *    is-deposit: 'isDeposit',
 *    caller-id: 'callerId',
 *    on-item-updated: 'onItemUpdated'
 *    enable-pager: 'enablePager'
 *  </cow-timeline>
 *
 * プロパティ:
 *  @param {Object} cow 牛個体オブジェクト
 *  @param {boolean} isRegisterEvent true: イベント登録 // TODO: 現状は未使用っぽいので確認後削除
 *  @param {number} qwert 預託先の牧場ID
 *  @param {boolean} isDeposit true: 預託元牧場
 *  @param {string} callerId 呼び出し元画面のID(cow_board, etc..)
 *  @param {Function} onItemUpdated タイムラインのアイテム更新後のイベント、eventTypeを引数で渡す
 *  @param {Object} doFetchEvent Deprecated: タイムラインのイベントをさらに表示する際のトリガーイベント
 *  @param {boolean} enablePager イベントの読み込み完了まで牛送りの抑止を行うためのフラグ true: 牛送り可能
 */
class CowTimelineController {
  constructor(
    $modal,
    $rootScope,
    $scope,
    $timeout,
    appConfig,
    appConst,
    blockUI,
    IsMobile,
    DateTimeUtilFactory,
    modalDialogFactory,
    SelectBoxFactory,
    SelectMedicineModal,
    DoNotBreedAlert,
    EventSelectionSimpleConfirm,
    EventSelectionMultipleChoicesConfirm,
    CarcassService,
    TimelineService,
    UtilService,
    AlertService,
    DepositAlertService,
    $state,
    DateUtilService,
    TimelineContext,
    DepositTimelineContext,
    SessionCache,
    $q,
    Dictionary,
    DysstasiaAPI
  ) {
    'ngInject';

    $scope.latestEventYear = null;

    this.$modal = $modal;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$q = $q;

    this.appConfig = appConfig;
    this.appConst = appConst;
    this.blockUI = blockUI;
    this.IsMobile = IsMobile;
    this.DateTimeUtilFactory = DateTimeUtilFactory;
    this.modalDialogFactory = modalDialogFactory;
    this.selectBoxFactory = SelectBoxFactory;
    this.SelectMedicineModal = SelectMedicineModal;
    this.DoNotBreedAlert = DoNotBreedAlert;
    this.EventSelectionSimpleConfirm = EventSelectionSimpleConfirm;
    this.EventSelectionMultipleChoicesConfirm = EventSelectionMultipleChoicesConfirm;
    this.CarcassService = CarcassService;
    this.TimelineService = TimelineService;
    this.UtilService = UtilService;
    this.AlertService = AlertService;
    this.DepositAlertService = DepositAlertService;
    this.showDetailAfterPost = false;
    this.$state = $state;
    this.DateUtilService = DateUtilService;
    this.DepositTimelineContext = DepositTimelineContext;
    this.TimelineContext = TimelineContext;
    this.Dictionary = Dictionary;
    this.DysstasiaAPI = DysstasiaAPI;

    this.SessionCache = SessionCache;
    this.sessionFarm = this.SessionCache.farm();
    this.canEditFilterItem = this.SessionCache.account() && !this.SessionCache.account().isItemFilterApplied();
    this.isAdministrator = this.SessionCache.account() && this.SessionCache.account().isAdministrator();

    this.deregisters = [];

    // FIXME: イベント入力のReact化が完了するまでの暫定措置
    this.isShowReactEventRegister = environment.reactCowEvent === 'true';

    this.localStorageKey = 'CowTimeline-EntryPerinatalDifficulty';

    this.init();
  }

  /**
   * コントローラーがロードされる際の初期化処理。
   * 入力毎に変動する値、制御フラグはinitializeStateで初期化すること。
   */
  init() {
    const date = new Date();
    this.nowHour = this.DateUtilService.format(date, 'HH');
    this.nowMinute = (this.DateUtilService.format(date, 'mm'))[0] + '0';
    this.today = this.DateUtilService.today();
    // へい死時の除籍理由
    this.EXPELLED_REASON_OF_DEATH = '死亡';
    this.eventTypes = this.appConst.eventTypes;
    this.historyModalBlock = this.blockUI;
    this.selectedEvent = '';
    this.tanetsukeBulls = []; // 種付けイベントの雄種牛データ
    this.isRegistered = false; // false: 新規登録、true: 更新
    this.formRef = null;

    this.isFilterShown = false;
    this.targetBreedingList = [];

    this.selectedMedicineList = [];
    this.hormoneProgramNameList = [{label: '適用なし', value: 0}];
    this.penList = [];
    this.targetHormoneList = [];
    this.groupList = [];
    this.mastitisScores = Mastitis.MASTITIS_SCORES;

    // Hash tables
    this.groupNameMap = {};
    this.medicineInfoMap = {}; // 薬品名のマップ
    this.calvingDifficultyMap = {}; // 分娩難易度のマップ
    this.hormoneListMap = {};
    this.penListMap = {};
    this.spermInfoMap = {};
    this.mastitisScoreMap = this.mastitisScores.reduce((acc, cur) => {
      acc[cur.value] = cur.label;
      return acc;
    }, {});

    // 絞り込みモデル
    this.filterResults = [];

    // データ
    this.formTemplate = '';
    this.selectedBullInfo = {};
    this.selectedBreedingId = 0;
    this.Hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    this.Minutes = ['00', '10', '20', '30', '40', '50'];
    this.formData = this.resetFormData();
    this.showEditTypes = {
      'mastitis': true,
      'lame': true,
      'breedingDifficulty': true,
      'perinatalDifficulty': true,
      'injury': true,
      'infect': true,
      'otherDifficulty': true,
      'freshCheck': true,
      'abort': true,
      'hoofTrim': true,
      'hatsujo': true,
      'tanetsuke': true,
      'ninshinkantei': true,
      'bunben': true,
      'sagyomemo': true,
      'hormoneProgram': true,
      'vaccine': true,
      'kannyu': true,
      'touta': true,
      'heishi': true,
      'gyugunidou': true,
      'wean': true,
      'observation': true,
      'dehorn': true,
      'castration': true,
      'fattening': true,
      'measurement': true,
      'carcass': true,
      'lameness': true,
      'doNotBreed': true,
      'embryo_recovery': true,
      'dysstasia': true
    };

    // 盲乳ラベルをキーから文字に変換する
    this.monyuLabelConvertor = this.TimelineService.monyuLabelConvertor;

    //あれこれのフラグ
    this.calvedDateTimeDisabled = false; // 分娩日時の操作不可フラグ

    // アラート保存用
    this.alerts = [];

    // エラーメッセージ用
    this.messages = [];
    this.messagesMap = {};

    // モバイル用
    this.isShowingMobileEventSelection = false;

    // スタイル用
    this.invalid = false;

    // 受精卵マスタ
    this.embryoMasters = [];

    // 枝肉成績
    this.carcassDefectAction = '瑕疵を入力';
    this.canAddCarcassDefect = true;

    this.callerState = {};

    this.entryProcessFailure = false;

    // 採卵
    this.canMasterEmbryoRegistration = false;

    this.eventFetchSize = 25;
  }

  $onInit() {
    this.deregisters.push(this.$rootScope.$on('deprecatedMobileCowDetail:refreshEvents', () => {
      this.refreshEvents();
    }));

    this.initialize();
  }

  initialize() {
    this.initialized = false;

    if (this.isDeposit) {
      this.DepositTimelineContext.setCurrentQwert(this.qwert);
      this.context = this.DepositTimelineContext;
      this.alertsByCow = this.DepositAlertService.alertsByCow;
    } else {
      this.context = this.TimelineContext;
      this.alertsByCow = this.AlertService.alertsByCow;
    }

    // Set Services
    this.CowEventsService = this.context.cowEventsService();
    this.CowService = this.context.cowService();
    this.MasterEmbryoAPI = this.context.masterEmbryoAPI();

    // 初期起動に必要なデータを取得
    return this.context.load(this.cow.cowId).then((res) => {
      this.enablePager = true;
      this.farm = new Farm(res[0]);
      this.farmKind = res[0].farmKind;
      this.implementFreshCheckFlg = res[10].data.implementFreshCheckFlg;
      this.isAgentFarm = res[0].agentFarm;

      this.showDiseaseConfig = !this.sessionFarm.isAgent();
      this.isDairyFarm = this.sessionFarm.isDairy();
      this.isFatteningFarm = this.sessionFarm.isFattening();

      // move to finally()?
      this.updateEventDisplayMap();

      // 牛群名のマップ作成
      this.createGroupNameMap(res[1].data);

      // 種付けイベント用に雄種牛のデータを取得
      this.tanetsukeBulls = res[2].data;

      // 薬品名のマップ作成
      res[3].data.forEach((medicine) => {
        this.medicineInfoMap[medicine.id] = medicine;
      });

      // 分娩難易度のマップ作成
      res[4].calvingDifficulty.forEach((calvingDifficulty) => {
        this.calvingDifficultyMap[calvingDifficulty.value] = calvingDifficulty.label;
      });

      this.hormonePrograms = res[5].data;

      // 精液情報
      // NOTE: ↓formatEventsData、generateBreedingListでspermInfoMapを使用するため先に実行する必要があります
      const spermMasters = res[8].data;
      spermMasters.forEach((spermMaster) => {
        this.spermInfoMap[spermMaster.id] = spermMaster;
      });

      // 妊娠鑑定イベントの種付け情報選択リストを作成
      const tanetsukeEvents = res[6].data.filter((event) => event.eventType === 'tanetsuke');
      this.targetBreedingList = this.generateBreedingList(tanetsukeEvents, this.cow.cowId);

      this.setEvents(res[6].data);

      // 各イベントのドロップダウンオプション作成
      const userDefinedSelections = res[11].data;
      const pregnantDiagnosisTimings = res[12].data;
      if (!this.isDeposit && pregnantDiagnosisTimings.length > 0) {// 預託は未対応
        userDefinedSelections.judgePregnantTimings = pregnantDiagnosisTimings.map((t) => t.name).join('\n');
      }
      userDefinedSelections.operationUsers = res[13].data.map((u) => u.name).join('\n');

      this.selection = this.initSelectionOptions(res[4], userDefinedSelections);

      this.selection['diseaseDefault'] = this.generateDiseaseSelection(res[9].data);
      this.showDiseaseSelection = this.selection.diseaseDefault.length >= 2;

      const masterWorkNoteTypes = res[17].data;
      if (masterWorkNoteTypes.length) {
        this.selection['workNoteType'] = this.generateWorkNoteTypeSelection(masterWorkNoteTypes);
        this.showWorkNoteTypeSelection = true;
        const initialSelectedMaster = masterWorkNoteTypes.find((r) => r.initialSelected);
        if (initialSelectedMaster) {
          this.defaultMasterWorkNoteTypeId = initialSelectedMaster.id;
        } else {
          this.defaultMasterWorkNoteTypeId = 0;
        }
      } else {
        this.showWorkNoteTypeSelection = false;
      }

      // イベントデータ整理
      this.alerts = this.constructAlertList(res[7].data, res[16].data);
      const events = CowEvent.addAlertsToEvents(this.formatEventsData(res[6].data), this.alerts);
      this.rawEvents = this.TimelineService.markLastEventOfYear(
        this.TimelineService.decorateEvents(events, this.selection),
        this.filterResults
      );
      this.fetchHeadEvents();
      this.updateLatestEventYear();

      this.username = this.appConfig.staff.name || res[14].name;
      this.useCowNoInput = this.farm.useCowNo() || this.farm.useName();

      this.showAcuteIllness = res[15].data.showAcuteIllness;

      this.embryos = Object.entries(res[10].data.embryoRecoveryRank).filter(([_, value]) => {
        return value.use;
      }).map(([key, value]) => {
        const {subrank} = value;
        const rank = key.length === 1 ? key.toUpperCase() : key;

        if (!subrank || subrank === 1) {
          return {
            rank,
            count: ''
          };
        }

        return Array(subrank).fill('').map((_, index) => {
          const subrank = index + 1;

          return {
            rank: `${rank}${subrank}`,
            count: ''
          };
        });
      }).flat();
    })
      .catch((err) => console.error(err))
      .finally(() => {
        this.initialized = true;

        if (this.IsMobile) return;
        const events = this.rawEvents.map((e) => e.obj);
        this.$rootScope.$emit('cowDetail:refreshHistories', events);
      }).then(() => {
        if (this.IsMobile && this.isRegisterEvent) {
          this.displayEventSelection();
        }
      });
  }

  $onDestroy() {
    this.deregisters.forEach((d) => d());
  }

  setEvents(events) {
    this.lastTimedAiEvent = events.find((event) => event.eventType === 'hormoneProgram');
  }

  /**
   * 入力画面を表示する際の初期化処理。
   * 制御フラグや入力に伴って変更される値はここで初期化すること。
   */
  initializeState() {
    this.state = {}; // 制御フラグ、グローバルな値を管理するオブジェクト
    this.inputStartAt = new Date();
  }

  /**
   * イベントモデル、コントローラーの状態を更新します。
   *
   * @param {Object} updatedEvent イベントモデルの更新内容
   * @param {Object} updatedCtrl: コントローラーの更新内容
   */
  updateState(updatedEvent, updatedCtrl = {}) {
    Object.keys(updatedEvent).forEach((k) => this.formData[k] = updatedEvent[k]);
    Object.keys(updatedCtrl).forEach((k) => this.state[k] = updatedCtrl[k]);
  }

  /* イベントハンドラーを記述するゾーン Start */

  onChangeDiseaseContinuation() {
    if (this.state.diseaseContinuation === 'new') {
      this.formData.occurredDiseaseDate = this.today.getTime();
      if (this.formData.eventType === 'mastitis') {
        this.formData.mastitisCowGroupId = this.cow.cowGroupId;
      }
    } else if (this.state.diseaseContinuation === 'continuous') {
      if (this.state.continuousOccurredDiseaseDate) {
        this.formData.occurredDiseaseDate = this.state.continuousOccurredDiseaseDate;
        if (this.formData.eventType === 'mastitis') {
          this.formData.mastitisCowGroupId = this.state.continuousMastitisCowGroupId;
        }
      }
    }
  }

  onClickCarcassDefectAdd() {
    this.formData.addDefect();
    this.updateCarcassDefectAction();
  }

  onClickCarcassDefectRemove(index) {
    this.formData.removeDefect(index);
    this.updateCarcassDefectAction();
  }

  onClickCarcassDefectPartAdd(index) {
    this.formData.addDefectPart(index);
  }

  onClickCarcassDefectPartRemove(index, partIndex) {
    this.formData.removeDefectPart(index, partIndex);
  }

  updateCarcassDefectAction() {
    if (this.formData.carcassDefects.length >= 1) {
      this.carcassDefectAction = '瑕疵を追加';
    } else {
      this.carcassDefectAction = '瑕疵を入力';
    }
    this.canAddCarcassDefect = this.formData.canAddDefect();
  }

  onChangeChildNumber() {
    const start = Number(this.formData.childNumber) + 1;
    this.clearBirthStateSelected(start);
    this.formData.changeChildNumber();
  }

  onChangeBirthResult(index) {
    if (this.farm.data.calfManagement === 'never') return;

    if (this.formData.calfs[index].birthResult !== '出産') {
      this.formData.calfs[index].registered = false;
      this.formData.calfs[index].cowNo = '';
      this.formData.calfs[index].cowUid = '';
    } else if (this.farm.data.calfManagement === 'always') {
      this.formData.calfs[index].registered = true;
    }
  }

  onClickCalfRegistered(index) {
    this.formData.calfs[index].registered = !this.formData.calfs[index].registered;

    if (!this.formData.calfs[index].registered) {
      this.formData.calfs[index].cowNo = '';
      this.formData.calfs[index].cowUid = '';
    }
  }

  onChangeCalfCowUid(index) {
    this.formData.calfs[index].cowUid = StringUtil.toHalfWidthCharacters(this.formData.calfs[index].cowUid);

    this.entryProcessFailure = false;
    this.isValidatorCalfCowUidLength = false;
    this.validate();
    if (this.useCowNoInput) return;
    const cowUid = this.formData.calfs[index].cowUid;
    const length = this.farm.useRegulationNumber4() ? 4 : 5;
    this.formData.calfs[index].cowNo = this.CowService.generateCowNo(cowUid, length);
  }

  onBlurCalfCowUid() {
    if (this.entryProcessFailure === true) return;
    this.isValidatorCalfCowUidLength = true;
    this.validate();
  }

  onChangeEmbryoRecoveryRank(field) {
    if (field) {
      this.formData.embryos[field].count = StringUtil.toHalfWidthCharacters(this.formData.embryos[field].count);
    }

    this.canMasterEmbryoRegistration = EmbryoRecovery.isAnyRankCounts(this.formData);
    if (!this.canMasterEmbryoRegistration) this.formData.embryoMasterRegistered = 'false';
    if (this.IsMobile && this.mobileScope) {
      this.mobileScope.canMasterEmbryoRegistration = this.canMasterEmbryoRegistration;
    }

    this.validate();
  }

  onChangeVaccineOccurredAt() {
    this.setMedicationSummary();
    this.validate();
  }

  /* イベントハンドラーを記述するゾーン End */

  /**
   * フィルタ設定プルダウンメニューの表示・非表示を切り替える
  */
  toggleShowFilterMenu() {
    this.isFilterShown = !this.isFilterShown;
  }

  // 分娩日が存在する場合には分娩日以前のイベント編集不可
  // 存在しない場合には全てのイベント編集可
  // 流産イベントの場合、全イベントの中で最新の場合のみ削除可能とする
  // 外部連携の場合、イベント編集削除不可
  isShowEditType(event) {
    const eventTypeMap = EventType.convertMap();

    if (event.isInputPathExternal) return false;
    if (event.imported) return false;
    if (event.readonly && this.IsMobile) return false;
    if (event.eventType === 'touta' && !this.canEditFilterItem) return false;
    if (event.eventType === 'carcass') {
      if (this.IsMobile || (this.isAgentFarm && !this.isDeposit) || !this.canEditFilterItem) return false;
    }

    if (event.eventType === 'abort') {
      return CowEventCollection.isLastReproduction(this.rawEvents, event);
    }

    if (EventType.canEditAfterCalved(eventTypeMap[event.eventType])) {
      return true;
    }

    if (this.cow.latestCalvingDate) {
      return this.showEditTypes[event.eventType] &&
        Number(this.cow.latestCalvingDate) <= Number(event.occurredAt);
    }

    return this.showEditTypes[event.eventType];
  }

  shouldShowResult(category, value) {
    if (!value) return false;
    switch (category) {

    default:
      if (value === '良好') return false;
    }
    return true;
  }

  displayReactEventRegister(callback) {
    this.resetState();
    this.updateEventDisplayMap();
    this.openReactEventRegister({callback});
  }

  displayReactEventEdit(editCowEventId) {
    this.openReactEventRegister({editCowEventId});
  }

  displayEventSelection(callback) {
    this.resetState();
    this.updateEventDisplayMap();

    if (this.IsMobile) {
      this.openEventSelectionMobile();
      this.callbackEventEntry = callback;
    } else {
      this.changeBackgroundColor(true);
      $('#box-history').addClass('on-event-input');
      $('#event-input02').css('display', 'none');
      $('#event-input01').fadeIn('3000');
    }
  }

  eventCancel() {
    if (!this.IsMobile) {
      this.changeBackgroundColor();
      $('#box-history').removeClass('on-event-input');
      $('#event-input01').css('display', 'none');
      $('#event-input02').css('display', 'none');
    }
    this.resetState();
  }

  reportDysstasiaMobile() {
    this.$modal.open({
      animation: true,
      templateUrl: 'mobile/dysstasia-report/form.html',
      controller: 'MobileDysstasiaReportFormController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'event-register-lg',
      resolve: {
        params: () => {
          return {
            cowId: this.cow.cowId
          };
        }
      }
    }).result.then((res) => {
      if (!res) return;

      if (res.status && res.status.DONE) {
        // モバイルトップ画面のイベント投稿から起立困難を入力する場合は、登録完了後に確認モーダルを表示
        if (this.callerState.state === 'mobile-top' || this.callerState.state === 'mobile_cow_event_input') {
          this.$modal.open({
            templateUrl: 'mobile/dysstasia-report/result.html',
            controller: 'MobileDysstasiaReportResultController as ctrl',
            keyboard: false,
            size: 'lg',
            resolve: {
              params: () => {
                return res.params;
              }
            }
          });
        } else {
          if (this.callerState.state === 'mobileCowDetail') {
            this.callbackEventEntry('起立困難');
          } else {
            this.refreshAlerts().then(() => this.refreshEvents());
          }
        }
      }
    });
  }

  newEvent(eventType) {
    if (eventType === 'hormoneProgram' || eventType === 'embryo_recovery') {
      this.initHormoneValues(this.hormonePrograms);
    } else if (eventType === 'tanetsuke') {
      const selectableMasters = CollectionUtil.selectableMasters(this.hormonePrograms);
      this.inseminationCodeList = Bred.inseminationCodeOptions(selectableMasters);
    }

    if (this.showDiseaseSelection) {
      this.selection['disease'] = this.adjustDiseaseSelection(this.selection['diseaseDefault'], null);
    }

    const modal = this.createSelectedEventDialog(eventType);

    if (modal) {
      modal.result.then((r) => {
        if (!r) {
          if (this.IsMobile) {
            this.openEventSelectionMobile();
          }
          return false;
        }

        if (Object.prototype.toString.call(r) === '[object Object]') {
          // r が オブジェクトの場合
          if (r.value) {
            // 登録または編集画面に進む場合
            if (r.new) {
              // 登録の場合
              this.selectEvent(r.value);
            } else {
              // 編集の場合
              const eventToEdit = this.rawEvents.find((e) => {
                return e.eventType === r.value;
              });
              if (eventToEdit) this.editEvent(this.rawEvents.indexOf(eventToEdit), eventToEdit.id);
            }
          }
        } else {
          this.selectEvent(eventType);
        }

      }).catch((e) => {
        if (this.IsMobile) {
          this.openEventSelectionMobile();
        }
        return false;
      });
    } else {
      this.selectEvent(eventType);
    }
  }

  /**
   * 選択したイベント種別に応じた入力用のモデルデータを生成する
   *
   * @param {string} eventType cow_event.event_type
   * @param {Object} previousInput 直前の入力内容(関連するイベントを連続して入力する文脈のみ渡される)
   * @param {boolean} editing true: 登録済のイベントを編集する文脈
   */
  selectEvent(eventType, previousInput, editing) {
    this.initializeState();

    if (eventType === 'embryo_recovery') {
      this.isValidatorDisabled = true;
    }

    this.selectedEvent = this.eventTypes[eventType];
    this.isRegistered = editing;
    this.editable = true; // 入力モードと照会モードを切り替える制御フラグ

    // FIXME 臨時措置
    this.editMode = editing;

    const selectedCowGroupIds = editing ? [this.formData.moveToCowGroupId] : [];
    this.cowGroupOptions = CollectionUtil.selectableMasters(this.groupList, selectedCowGroupIds, 'id', 'menuDisplayed');

    // 新規投稿時のみデフォルト値、項目引き継ぎの処理を行う
    if (!editing) {
      this.formData = LegacyEventType.createEvent(eventType, this.resetFormData());
      this.formData.eventType = eventType;
      this.formData.eventName = this.selectedEvent.name;

      Object.keys(this.selectedEvent.default).forEach((key) => {
        const value = this.selectedEvent.default[key];
        if (key === 'occurredAt') {
          if (value.date) this.formData.occurredAt = this.today.getTime();
          if (value.time) {
            this.formData.selectedHour = this.nowHour;
            this.formData.selectedMinute = this.nowMinute;
          }
        } else {
          if (value === 'toDay') this.formData[key] = this.today.getTime();
          else this.formData[key] = value;
        }
      });

      // 牛個体からのデータ引き継ぎ処理を行う
      if (this.selectedEvent.cowInfos) {
        this.selectedEvent.cowInfos.forEach((keyMap) => {
          this.formData[keyMap.after] = (keyMap.parse) ?
            keyMap.parse(this.cow[keyMap.before], this.formData[keyMap.after]) :
            this.cow[keyMap.before];
        });
      }

      if (previousInput) {
        this.eventTypes[previousInput.eventType].continue.inherit.forEach((keyMap) => {
          this.formData[keyMap.after] = previousInput[keyMap.before];
        });
      }

      // 治療関連イベントの場合で新規投稿の場合、前回イベントリストより発生日を取得
      if (this.selectedEvent.treatmentEvent) {
        if (!this.farm) return;

        this.formData.examinationTreatmentKind = this.selectedEvent.name;

        const eventState = new EventState(this.$q, this.today, this.IsMobile);
        eventState.generateTreatmentState(
          this.formData,
          this.farm,
          this.cow,
          this.CowEventsService,
          previousInput
        ).then((res) => {
          this.updateState(res.state, res.ctrl);
          // 初期化時に治療日がセットされるので、現在時刻の設定を行う
          this.onUpdateTreatmentDiseaseDate();

          if (eventType === 'mastitis') {
            this.mastitisCowGroupOptions = CollectionUtil.selectableMasters(this.groupList, [this.formData.mastitisCowGroupId], 'id', 'menuDisplayed');
            if (this.IsMobile) {
              this.mobileScope.mastitisCowGroupOptions = this.mastitisCowGroupOptions;
            }
          }
        });
      }
      // 新規の場合は操作可能にする
      this.calvedDateTimeDisabled = false;
      // モバイル用
      if (this.IsMobile && this.mobileScope) this.mobileScope.calvedDateTimeDisabled = this.calvedDateTimeDisabled;

      if (eventType === 'hatsujo') {
        this.formData.plannedBredMethod = '';
      }

      if (eventType === 'ninshinkantei') {
        this.formData.ninshinkanteiResult = '受胎';
        this.formData.workerName = this.defaultWorkerName(this.selection.pregnancyAppraisers, this.username);
        this.selectBreedingDate();
      }

      if (this.selectedEvent.treatmentEvent || eventType === 'vaccine') {
        this.formData.workerName = this.defaultWorkerName(this.selection.treatmentPersons, this.username);
      }

      if (eventType === 'tanetsuke') {
        this.formData.workerName = this.defaultWorkerName(this.selection.inseminators, this.username);
      }

      if (['hoofTrim', 'dehorn', 'gyugunidou'].includes(eventType)) {
        this.formData.workerName = this.defaultWorkerName(this.selection.workerNames, this.username);
      }

      if (eventType === 'embryo_recovery') {
        this.formData.embryos = this.embryos;
      }

      if (eventType === 'otherDifficulty') {
        this.formData.otherDiseaseNames = [{id: 0}];
      }

      if (eventType === 'sagyomemo' && this.showWorkNoteTypeSelection) {
        this.formData.masterWorkNoteTypeId = this.defaultMasterWorkNoteTypeId;
      }
    }

    if (eventType === 'mastitis') {
      this.mastitisCowGroupOptions = CollectionUtil.selectableMasters(this.groupList, [this.formData.mastitisCowGroupId], 'id', 'menuDisplayed');
    }

    // ホルモンプログラムイベントの場合
    if (this.selectedEvent.nextTreat) {
      if (!editing) {
        this.formData.masterHormoneProgramId = 0;

        if (this.lastTimedAiEvent) {
          const masterId = this.lastTimedAiEvent.masterHormoneProgramId;
          const master = this.hormoneListMap[masterId];

          if (master) {
            const nextHormoneCode = this.cow.nextHormoneCode;
            const step = master.find((step) => step.value === nextHormoneCode) || {};
            if (step.value) {
              this.formData.masterHormoneProgramId = masterId;
              this.formData.hormoneCode = step.value;
            }
          }
        }
      }
      this.changeHormoneProgramName();
    }
    // 治療、ワクチンイベント編集
    const hasMedicine = this.selectedEvent.treatmentEvent || this.formData.eventType === 'vaccine';
    if (editing && hasMedicine) {
      this.selectedMedicineList = TimelineItem.toMedicationItem(this.formData.medications);
      this.setMedicationSummary();
    }
    // へい死の場合は、除籍理由を死亡に固定する。
    if (this.formData.eventType === 'heishi') {
      this.formData.expelledReason = this.EXPELLED_REASON_OF_DEATH;
    }

    if (this.formData.eventType === 'carcass') {
      this.carcassSelection = this.CarcassService.makeCarcassSelection();
      if (!editing) this.disableSubmit(true);
    }

    if (this.formData.eventType === 'tanetsuke') {
      // イベント間の整合性を保つために妊娠鑑定が行われた種付イベントは編集できないようにする
      this.editable = !this.formData.readonly;

      this.setInseminationCode();
      // 受精卵マスタ
      // TODO: isDepositでリクエストを変更する
      this.MasterEmbryoAPI.available()
        .then((res) => {
          this.embryoMasters = res.data;

          this.selection['masterEmbryoOptions'] = this.embryoMasters.map((embryoMaster) => {
            return {
              label: embryoMaster.name,
              masterEmbryoId: embryoMaster.id
            };
          });

          if (editing) {
            const selected = this.embryoMasters.find((m) => Number(m.id) === Number(this.formData.masterEmbryoId));
            if (selected) {
              this.formData['masterEmbryoName'] = selected.name;
            }
          }

          this.formData.etInputType = (!editing || this.formData.masterEmbryoId) && this.embryoMasters.length > 0 ? 'embryo' : 'direct';
        });

      if (!editing) {
        this.formData.embryoType = '体内受精卵';
      }
    }

    // 体重測定時に、飼育日数を計算する
    if (eventType === 'measurement') {
      this.errorMessage = '';
      const breedingDays = Cow.calculateBreedingDays(this.cow,
        Number(this.formData.occurredAt));
      this.formData.breedingDays = this.UtilService.nullIfZeroOrUnder(breedingDays);
    }

    // 蹄病
    if (eventType === 'lame') {
      this.formData.lames = [];

      // locomotion_scoreは未選択の場合nullなので空文字に変換する
      if (this.formData.locomotionScore === null) {
        this.formData.locomotionScore = '';
      }

      ['1', '2', '3', '4'].forEach((index) => {
        if (!this.formData['lameAffectedLimb' + index]) return;

        const l = {
          lameAffectedLimb: this.formData['lameAffectedLimb' + index],
          lameAffectedPart: this.formData['lameAffectedPart' + index],
          lameCondition: this.formData['lameCondition' + index],
          clawDiseaseName: this.formData['clawDiseaseName' + index],
          lameAffectedPartLateral: false,
          lameAffectedPartMedial: false
        };

        if (l.lameAffectedPart === '蹄') {
          this.formData['lameClawZones' + index].split(',')
            .forEach((p) => {
              switch (p) {
              case '内':
                l.lameAffectedPartMedial = true;
                break;
              case '外':
                l.lameAffectedPartLateral = true;
                break;
              }
            });
        }

        this.formData.lames.push(l);
      });

      if (this.formData.lames.length === 0) {
        this.addLame();
      }
    }

    if (eventType === 'hatsujo') {
      this.selection.plannedBredMethod = ReproductionPlannedEvent.plannedBredMethods;
    }

    if (eventType === 'embryo_recovery') {
      if (!editing) {
        if (this.lastTimedAiEvent) {
          this.formData.masterHormoneProgramId = this.lastTimedAiEvent.masterHormoneProgramId;
        } else {
          this.formData.masterHormoneProgramId = 0;
        }
      }

      const nextCowState = this.selection.embryoRecoveryNextCowState[0].value;
      this.formData = EmbryoRecovery.toViewModel(this.formData, this.cow, this.spermInfoMap, nextCowState);
      this.canMasterEmbryoRegistration = EmbryoRecovery.isAnyRankCounts(this.formData);
    }

    if (eventType === 'bunben') {
      this.selection.entryPerinatalDifficulty = [{
        value: 'true',
        label: '入力する'
      }, {
        value: 'confirm',
        label: '分娩イベント登録時に判断する'
      }, {
        value: 'false',
        label: '入力しない'
      }];

      if (!editing) {
        this.formData.isNewEvent = true;
      }
      this.formData.calfManagement = this.farm.data.calfManagement;
      this.formData.entryPerinatalDifficulty = LocalStorageEntry.use(
        this.$rootScope.userName,
        this.localStorageKey,
        'confirm'
      );
    }

    if (eventType === 'touta') {
      this.existsProducingAreas = this.selection.producingAreas.length > 0;
      this.existsProducingFarmNames = this.selection.producingFarmNames.length > 0;

      if (!editing) {
        this.formData.producingArea = this.cow.producingArea;
        this.formData.producingFarmName = this.cow.producingFarmName;
        this.formData.expelledReason = this.isDairyFarm || this.isFatteningFarm ? '肉用出荷' : '素牛出荷';
      }

      this.addUserEntryValueToSelection(this.selection, this.formData);
    }

    if (this.IsMobile) {
      this.openEventRegisterMobile();
    } else {
      const formHTML = this.editable ? '/form.html' : '/show.html';
      this.formTemplate = 'history/' + eventType + formHTML;
      this.eventInput();
    }

    // 治療系イベントは非同期で初期化をしているので、完了後に
    // 別途validate()を呼び出す
    if (!this.selectedEvent.treatmentEvent && !['carcass'].includes(eventType)) {
      this.validate(true);
    }
  }

  ok() {
    this.isValidatorDisabled = false;
    this.validate();
    if (this.messages.length > 0) return;

    // NOTE:
    // treatmentDiseaseDateのとる値は、下記の3つ
    // ・jqDatePickerで日付をセットした場合はNumber
    // ・フォームでテキストさ削除した場合はStringの空文字
    // ・clearInput()でクリアされた場合はNull
    if (this.selectedEvent.treatmentEvent && !this.formData.treatmentDiseaseDate) {
      this.resetTreatmentDetail();
    }

    switch (this.formData.eventType) {
    case 'abort':
      this.confirmAbort().then((abortResult) => {
        this.formData.nextStartMilkingFlg = abortResult;

        if (this.formData.nextStartMilkingFlg) {
          // 泌乳ありが選択された場合、登録しようとしている流産イベント日時が最新イベント日時より前でないかをチェック
          if (!this.validate()) {
            if (this.IsMobile) {
              this.openEventRegisterMobile();
              return;
            } else {
              return;
            }
          }

          this.confirmNotEdit().then((notEditResult) => {
            if (notEditResult) {
              this.entryProcess();
            } else if (this.IsMobile) {
              this.openEventRegisterMobile();
            }
          });
        } else {
          this.entryProcess();
        }
      });
      break;

    case 'bunben':
      this.confirmCheckDigitCowUid().then((res) => {
        if (!res) {
          if (this.IsMobile) {
            this.formData.isNewEvent = false;
            this.clearBirthStateSelected();
            this.openEventRegisterMobile();
          }

          return;
        }

        this.confirmNotEdit().then((notEditResult) => {
          if (notEditResult) {
            this.entryProcess();
          } else if (this.IsMobile) {
            this.formData.isNewEvent = false;
            this.clearBirthStateSelected();
            this.openEventRegisterMobile();
            return;
          }
        });
      });

      break;

    case 'ninshinkantei':
      const daysAfterLatestFertilizationDate = this.DateUtilService.countDays(
        Number(this.formData.targetBreedingDate),
        Number(this.formData.occurredAt));
      if (daysAfterLatestFertilizationDate < 14 && (this.formData.ninshinkanteiResult === '受胎' || this.formData.ninshinkanteiResult === '双子受胎')) {
        const errorMessage = `授精後${daysAfterLatestFertilizationDate}日です。登録できません。`;
        this.messagesMap.occurredAt = errorMessage;
        this.messages.push(errorMessage);
        if (this.IsMobile) {
          this.openEventRegisterMobile();
          return;
        } else {
          return;
        }
      }
      this.entryProcess();
      break;
    case 'kannyu':
      if (
        this.cow.state === '乾乳前期' &&
        this.formData.selectedDryPeriod === '前期' &&
        !this.editMode // FIXME 臨時措置
      ) {
        const modal = this.EventSelectionSimpleConfirm.open(
          '確認',
          'この牛は乾乳前期ですが登録してもよいですか？'
        );
        modal.result.then((r) => {
          if (r) {
            this.entryProcess();
          }
        });
      } else {
        this.entryProcess();
      }

      break;

    case 'lame':
      if (this.formData.locomotionScore === '') {
        this.formData.locomotionScore = null;
      }

      [1, 2, 3, 4].forEach((index) => {
        // 編集時に引き継いだデータを一旦クリアする
        this.formData['lameAffectedLimb' + index] = null;
        this.formData['lameAffectedPart' + index] = null;
        this.formData['lameClawZones' + index] = null;
        this.formData['lameCondition' + index] = null;
        this.formData['clawDiseaseName' + index] = null;

        const lame = this.formData.lames[index - 1];
        if (!lame) return;

        if (!lame.lameAffectedLimb) {
          return;
        }

        this.formData['lameAffectedLimb' + index] = lame.lameAffectedLimb;

        if (lame.lameAffectedPart) {
          this.formData['lameAffectedPart' + index] = lame.lameAffectedPart;
        }

        const lameClawZoneValues = [];

        if (lame.lameAffectedPartMedial === true) {
          lameClawZoneValues.push('内');
        }

        if (lame.lameAffectedPartLateral === true) {
          lameClawZoneValues.push('外');
        }

        this.formData['lameClawZones' + index] = lameClawZoneValues.join(',');
        this.formData['lameCondition' + index] = lame.lameCondition;
        this.formData['clawDiseaseName' + index] = lame.clawDiseaseName;
      });
      delete this.formData.lames;

      this.entryProcess();
      break;

    case 'embryo_recovery':
      let promise;
      if (EmbryoRecovery.isAnyRankCounts(this.formData)) {
        promise = new Promise((resolve, reject) => resolve(true));
      } else {
        promise = this.confirmEmbryoRecoveryRegistration();
      }

      promise.then((res) => {
        if (!res) {
          if (this.IsMobile) this.openEventRegisterMobile();
          return;
        }

        if (this.editMode && this.formData.embryoMasterRegistered) {
          this.confirmEmbryoRecovery(this.formData, 'edit').then((confirmed) => {
            if (confirmed) {
              this.entryProcess();
            } else {
              if (this.IsMobile) this.openEventRegisterMobile();
            }
          });
        } else {
          this.entryProcess();
        }
      });
      break;
    case 'tanetsuke':
      if (!this.formData.masterSpermId) {
        this.formData.masterSpermId = 0;
      }
      this.entryProcess();
      break;

    default:
      this.entryProcess();
    }

  }

  entryProcess() {
    // 枝肉成績の場合
    if (this.formData.eventType === 'carcass') {
      this.formData.dressedCarcassUnitPrice = this.removeComma(this.formData.dressedCarcassUnitPrice);
      this.formData.dressedCarcassSalesPrice = this.removeComma(this.formData.dressedCarcassSalesPrice);

      // 等級を算出する。
      const dataWithGrade = this.CarcassService.calculateGrade(this.formData);
      // 小数点を制限、四捨五入する
      const cleanNumbers = this.CarcassService.roundToSingleDecimal(dataWithGrade);
      // 臓器配列をSTRに変換
      cleanNumbers.visceralDestruction = cleanNumbers.selectedVisceralDestruction ?
        cleanNumbers.selectedVisceralDestruction.value.join(':') : [];
      delete cleanNumbers['selectedVisceralDestruction'];
      // 個体に戻す
      this.formData = cleanNumbers;
    }

    // TODO: 将来的にはモデル側でリクエストパラメータ作成時に処理する
    const hasOtherDisease = ['injury', 'infect', 'otherDifficulty'];
    if (hasOtherDisease.includes(this.formData.eventType)) {
      this.clearDifficultiesOther();
    }

    // 牛群の移動がある場合は現在の所属牛群、所属牛房を移動元牛群、移動元牛房に設定する。
    if (CowEvent.isMoveEvent(this.formData.eventType, this.farm.isMilk()) && this.formData.moveToCowGroupId) {
      this.formData.beforeCowGroupId = this.formData.beforeCowGroupId || this.cow.cowGroupId;
      this.formData.beforePen = this.formData.beforePen || this.cow.pen;
    }

    if (this.formData.eventType === 'otherDifficulty') {
      if (this.showDiseaseSelection) {
        const otherDiseaseNames = this.formData.otherDiseaseNames
          .filter((input) => input.id !== 0)
          .map((input) => {
            const disease = this.selection.disease.find((option) => option.key === input.id);
            if (input.id > 0) {
              return {id: input.id, name: disease.label.split(': ')[1]};
            } else {
              return {name: disease.label.split(': ')[1]};
            }
          });
        this.formData.otherDiseaseNames = otherDiseaseNames;
        this.formData.otherDiseaseName = '';
      } else {
        if (this.formData.otherDiseaseName) {
          this.formData.otherDiseaseNames = [
            {name: this.formData.otherDiseaseName}
          ];
        } else {
          this.formData.otherDiseaseNames = [];
        }
        this.formData.otherDiseaseName = '';
      }
    }

    this.historyModalBlock.start('登録中');
    this.modifyFormData();
    this.formData.entryStaffName = this.appConfig.staff.name;
    this.formData.lastEditStaffName = this.appConfig.staff.name;

    if (this.formData.eventType === 'tanetsuke') {
      if (this.formData.tanetsukeMethod === '移植') this.setEmbryo();
      if (this.formData.tanetsukeMethod !== '移植') this.resetEmbryo();
      this.formData.bredForEmbryoRecovery = this.formData.tanetsukeMethod === '人工授精' && this.formData.bredForEmbryoRecovery ? true : false;
    }

    let sendFormData = angular.copy(this.formData);
    if (!this.isRegistered) {
      sendFormData['cowId'] = this.cow.cowId;
    }
    if (sendFormData.eventType === 'abort' && sendFormData.afterCowState === '繁殖除外') {
      sendFormData.breedingExclusionReason = '流産';
    }
    delete sendFormData['selectedHour'];
    delete sendFormData['selectedMinute'];
    // 不要な表示用項目を削除
    delete sendFormData['selectedMedicines'];

    sendFormData['bqInputTime'] = new Date().getTime() - this.inputStartAt.getTime();
    if (this.callerId || this.callerState) {
      const callerId = this.callerId || this.callerState.state;
      sendFormData['bqNotes'] = `path: ${callerId}`;
    }

    if (sendFormData.eventType === 'bunben') {
      LocalStorageEntry.set(
        this.$rootScope.userName,
        this.localStorageKey,
        sendFormData.entryPerinatalDifficulty
      );

      delete sendFormData.entryPerinatalDifficulty;
    }

    const action = this.isRegistered ?
      this.CowEventsService.update(sendFormData) : this.CowEventsService.insert(sendFormData);

    action.then((resp) => {
      this.historyModalBlock.stop();
      if (this.showDetailAfterPost) {
        const eventId = resp && resp.data && resp.data.id
          ? resp.data.id
          : undefined;

        const cowEventPromise = eventId
          ? this.CowEventsService.index(this.cow.cowId)
          : Promise.resolve(undefined);

        cowEventPromise
          .then((resp) => {
            const eventData = resp && resp.data
              ? resp.data.find((ev) => ev.id === eventId)
              : undefined;

            return this.$modal.open({
              templateUrl: 'mobile/events/event-detail-modal/mobile-event-detail-modal.html',
              controller: 'MobileEventDetailModalController as ctrl',
              keyboard: false,
              size: 'lg',
              resolve: {
                event: () => {
                  return eventData || sendFormData;
                },
                options: () => {
                  return {
                    showCowNo: false
                  };
                }
              }
            }).result.then((result) => {
              if (result.status === MobileEventDetailModalController.RETURN_STATUS.SUBMIT) {
                this.$state.go('mobileCowDetail', {
                  cowId: this.CowEventsService.selectedCow.cowId
                });
              }
            });
          });
      }
    }).then(() => {
      if (this.IsMobile && !this.isRegistered) {
        this.callbackEventEntry(this.formData.eventName);
        this.refreshEvents();
        this.eventCancel();
        return;
      }

      let modalInstance = this.openConfirmModal();

      this.onItemUpdated({eventType: this.formData.eventType});

      if (!modalInstance) {
        this.refreshEvents();
        this.eventCancel();
        return;
      }

      modalInstance.result.then((modalResult) => {
        if (!modalResult) {
          this.refreshEvents();
          this.eventCancel();
          return;
        }

        let beforeFormData = this.formData;
        this.resetState();
        this.refreshEvents(true);
        this.selectEvent(this.eventTypes[beforeFormData.eventType].continue.eventType, beforeFormData);
        this.$rootScope.$broadcast('deprecatedMobileCowDetail:notifyForceRefresh');
      });
    }).catch((err) => this.entryCowEventFailure(err));
  }

  openConfirmModal() {
    const followingEvent = this.eventTypes[this.formData.eventType].continue;
    if (followingEvent &&
        !(this.IsMobile && CowEvent.eventsExcludedFromMobileRegistration.includes(followingEvent.eventType))
    ) {
      if (followingEvent.conditions) {
        if (!followingEvent.conditions.every((condition) => {
          return condition.equals.includes(this.formData[condition.name]);
        })) {
          return null;
        }
      }

      if (this.formData.eventType === 'bunben' && ['true', 'false'].includes(this.formData.entryPerinatalDifficulty)) {
        if (this.formData.entryPerinatalDifficulty === 'true') {
          return {
            result: new Promise((resolve) => resolve(true))
          };
        }

        return null;
      }

      return this.modalDialogFactory.showHistoryConfirm({
        title: '確認',
        text1: '登録完了しました。',
        text2: followingEvent.text,
        no: true, yes: true
      });
    }
    return null;
  }

  /**
   * イベント編集・削除
   * @param {number} index イベント配列のindex
   * @param {number} id イベントID
   */
  editEvent(index, id) {
    this.editedEventIndex = index; // 未使用
    const event = this.events.find((event) => event.id === id);
    let formData = angular.copy(event.obj);
    if (formData.eventType === 'carcass') {
      formData = this.reformatCarcassData(formData);
    } else if (formData.eventType === 'tanetsuke' && formData.tanetsukeMethod === '本交') {
      // FIXME: 古の伝統を受け継いでcow_eventのAPIレスポンスは全て文字列で返される
      this.selectedBullInfo = this.tanetsukeBulls.find((bull) => bull.cowId === Number(formData.bullCowId));
    } else if (formData.eventType === 'ninshinkantei') {
      this.selectedBreedingId = formData.breedingCowEventId;
    } else if (formData.eventType === 'otherDifficulty') {
      if (this.showDiseaseSelection) {
        this.selection['disease'] = this.adjustDiseaseSelection(this.selection['diseaseDefault'], formData.otherDiseaseName);

        const otherDiseaseNames = formData.otherDiseaseNames.map((e) => {
          if (e.id > 0 ) {
            return {id: e.id};
          } else {
            return {id: -1};
          }
        });
        if (otherDiseaseNames.length === 0) {
          otherDiseaseNames.push({id: 0});
        }
        formData.otherDiseaseNames = otherDiseaseNames;
      }
    } else if (formData.eventType === 'hormoneProgram' || formData.eventType === 'embryo_recovery') {
      this.initHormoneValues(this.hormonePrograms, formData.masterHormoneProgramId);
    } else if (formData.eventType === 'tanetsuke') {
      const selectableMasters = CollectionUtil.selectableMasters(this.hormonePrograms, [formData.inseminationCode], 'name');
      this.inseminationCodeList = Bred.inseminationCodeOptions(selectableMasters);
    }

    this.calvedDateTimeDisabled = (formData.eventType === 'bunben' && !this.isLatestNonAlertEvent(id));
    // モバイル用
    if (this.IsMobile && this.mobileScope) this.mobileScope.calvedDateTimeDisabled = this.calvedDateTimeDisabled;

    this.formData = LegacyEventType.createEvent(formData.eventType, this.resetFormData());

    Object.keys(formData).forEach((key) => {
      const value = formData[key];
      if (key === '$$hashKey') return;
      if (key === 'occurredAt') {
        this.formData.selectedHour = this.DateUtilService.format(value, 'HH');
        this.formData.selectedMinute = this.DateUtilService.format(value, 'mm');
      }
      this.formData[key] = value;
    });
    this.formData.initialOccurredAt = Number(this.formData.occurredAt); // FIXME: APIの日付・時刻レスポンスを数値に置き換える

    if (this.formData.plannedBredAt) {
      this.formData.plannedBredAt = Number(this.formData.plannedBredAt);
      this.formData.plannedBredAtHour = this.DateUtilService.format(this.formData.plannedBredAt, 'HH');
      this.formData.plannedBredAtMinute = this.DateUtilService.format(this.formData.plannedBredAt, 'mm');
    }

    this.selectEvent(this.formData.eventType, null, true);
  }

  confirmAbort() {
    let pregnancyDays = DateUtil.diffDays(
      Number(this.cow.latestPregnancyDate),
      this.formData.occurredAt
    );
    pregnancyDays = this.UtilService.isNumber(pregnancyDays) ? pregnancyDays : 0;
    const modalInstance = this.modalDialogFactory.showHistoryConfirm({
      title: '確認',
      text1: '妊娠日数' + pregnancyDays + '日の流産です。産次数を上げますか？',
      text2: '',
      no: true, yes: true
    });

    return modalInstance.result.then((modalResult) => modalResult);
  }

  confirmNotEdit() {
    const modalInstance = this.modalDialogFactory.showHistoryConfirm({
      title: '確認',
      text1: 'イベント登録後、過去の繁殖関連イベントが編集できなくなります。よろしいですか？',
      text2: '',
      no: true, yes: true
    });

    return modalInstance.result.then((modalResult) => modalResult);
  }

  confirmEmbryoRecoveryRegistration() {
    const modalInstance = this.modalDialogFactory.showHistoryConfirm({
      title: '確認',
      text1: '回収卵の数が入力されていません。よろしいですか？',
      text2: '',
      no: true, yes: true
    });

    return modalInstance.result.then((modalResult) => modalResult);
  }

  confirmCheckDigitCowUid() {
    const hasInvalidCowUid = this.formData.calfs
      .filter((calf) => {
        return calf.cowUid;
      })
      .map((calf) => {
        const checkDigitCowUid = NumberUtil.modulus10Weight3CheckDigit(calf.cowUid.slice(0, 9));

        return checkDigitCowUid !== Number(calf.cowUid.slice(9, 10));
      }).some((invalid) => {
        return invalid;
      });

    if (!hasInvalidCowUid) return new Promise((resolve, reject) => resolve(true));

    const modalInstance = this.modalDialogFactory.showHistoryConfirm({
      title: '確認',
      text1: '「個体識別番号」が間違っています。このまま登録しますか？',
      text2: '',
      no: true,
      yes: true
    });

    return modalInstance.result.then((modalResult) => modalResult);
  }

  // occurredAtにhh:MMを足す
  addTimeToDate(date) {
    const hour = Number(this.formData['selectedHour']);
    const minute = Number(this.formData['selectedMinute']);
    return this.DateUtilService.addTimeToDate(Number(date), hour, minute);
  }

  modifyFormData() {
    // 治療日時分をtimestampに
    if (this.formData['treatmentDiseaseDate']) {
      this.formData['treatmentDiseaseDate'] = this.addTimeToDate(this.formData['treatmentDiseaseDate']);
      this.formData['occurredAt'] = this.formData['treatmentDiseaseDate'];
    } else if (this.formData['occurredDiseaseDate']) {
      this.formData['occurredAt'] = this.formData['occurredDiseaseDate'];
    } else if (this.formData['selectedHour'] && this.formData['selectedMinute']) {
      this.formData['occurredAt'] = this.addTimeToDate(this.formData['occurredAt']);
    }

    if (this.formData.eventType === 'doNotBreed') {
      this.formData['occurredAt'] = this.DateUtilService.endOfDay(new Date(this.formData['occurredAt'])).valueOf();
    }

    if (this.formData.eventType === 'bunben') this.formData.deleteCalf();

    if (this.formData.eventType === 'hatsujo') {
      if (this.formData['plannedBredAt']) {
        const hour = Number(this.formData['plannedBredAtHour']);
        const minute = Number(this.formData['plannedBredAtMinute']);
        const date = DateUtil.startOfDay(Number(this.formData['plannedBredAt']));

        this.formData['plannedBredAt'] = this.DateUtilService.addTimeToDate(
          date,
          hour,
          minute
        );
      } else {
        this.formData['plannedBredAt'] = '';
      }
      delete this.formData['plannedBredAtHour'];
      delete this.formData['plannedBredAtMinute'];
    }

    if (this.formData.eventType === 'embryo_recovery') {
      Object.assign(this.formData, EmbryoRecovery.createRequestParams(this.formData));
    }
  }

  clearInput(targetName) {
    this.formData[targetName] = null;

    if (targetName === 'treatmentDiseaseDate' || this.formData.eventType === 'vaccine') {
      this.setMedicationSummary();
    }

    this.validate();
  }

  resetState() {
    this.selectedEvent = '';
    this.editedEventIndex = null;
    this.formTemplate = '';
    this.messages = [];
    this.messagesMap = {};
    this.clearBirthStateSelected();
    this.clearRadioSelected();
    this.formData = this.resetFormData();
    this.selectedMedicineList = [];
    this.dateLabelOfMilkWashoutPeriod = '';
    this.dateLabelOfBeefWashoutPeriod = '';
    this.invalid = false;
  }

  openMedicineModal() {
    const medicineModal = this.SelectMedicineModal.open(
      this.selectedMedicineList,
      this.formData.eventType,
      this.qwert
    );

    medicineModal.result.then((result) => {
      this.selectedMedicineList = result;

      let promise;
      if (this.selectedMedicineList.every((m) => this.medicineInfoMap[m.id])) {
        promise = new Promise((resolve, reject) => resolve(true));
      } else {
        promise = this.refreshInfoMap();
      }

      promise.then(() => {
        this.setMedicationSummary();
      });
    });
  }

  onMedicineListUpdate() {
    this.setMedicationSummary();
  }

  onMedicineParamsUpdate() {
    this.validate();
  }

  onLameAffectedPartChange(lame) {
    this.resetLameDiagnosisDetail(lame);
  }

  resetLameDiagnosisDetail(lame) {
    lame.clawDiseaseName = null;
    lame.lameAffectedPartLateral = null;
    lame.lameAffectedPartMedial = null;
  }

  onSelectedBullInfoUpdated(bullInfo) {
    this.formData['bullCowId'] = bullInfo ? bullInfo['cowId'] : null;
  }

  onRemoveEmbryoClick() {
    this.formData['masterEmbryoId'] = '';
    this.formData['masterEmbryoName'] = '';
  }

  onChangeEtInputType() {
    if (this.formData.etInputType === 'direct') {
      this.formData['masterEmbryoId'] = '';
      this.formData['masterEmbryoName'] = '';
    }
  }

  onPlannedBredMethodChange() {
    const date = DateUtil.startOfDay(Number(this.formData.occurredAt));

    switch (this.formData.plannedBredMethod) {
    case '人工授精':
      this.formData.plannedBredAt = DateUtil.addDays(date, 1).valueOf();
      break;
    case '移植':
      this.formData.plannedBredAt = DateUtil.addDays(date, 7).valueOf();
      break;
    case '本交':
      this.formData.plannedBredAt = DateUtil.addDays(date, 1).valueOf();
      break;
    default:
      this.formData.plannedBredAt = '';
      this.formData.plannedBredAtHour = '';
      this.formData.plannedBredAtMinute = '';
    }

    if (this.formData.plannedBredAt) {
      this.formData.plannedBredAtHour = this.formData.selectedHour;
      this.formData.plannedBredAtMinute = this.formData.selectedMinute;
    }

    this.validate();
  }

  shouldShowSelectEventKind() {
    return !this.IsMobile;
  }

  shouldShowAddLame() {
    if (!this.formData.lames) return false;
    return this.formData.lames.length < 4;
  }

  shouldShowLameDiagnosisDetail(lame) {
    return lame.lameAffectedLimb !== '';
  }

  shouldShowLameDiagnosisOnTimeline(event) {
    return ['1', '2', '3', '4'].some((index) => {
      return event['lameAffectedLimb' + index] !== null;
    });
  }

  shouldShowLocomotionScoreOnTimeline(event) {
    return event.locomotionScore !== null;
  }

  shouldShowRemoveEmbryoButton() {
    return this.formData['masterEmbryoId'] ? true : false;
  }

  shouldShowPlannedBredAt() {
    return this.formData['plannedBredMethod'] ? true : false;
  }

  shouldShowBredForEmbryoRecovery() {
    return this.formData.tanetsukeMethod === '人工授精';
  }

  shouldShowAddOtherDiseaseName() {
    const maxLength = Math.min(this.selection.disease.length - 1, 5);
    return this.formData.otherDiseaseNames.length < maxLength;
  }

  openSpermInfoModal(spermKind) {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'history/selection/spermInfo/spermInfoSelectModal.html',
      controller: 'spermInfoSelectModalController',
      controllerAs: 'spermCtrl',
      backdrop: false,
      keyboard: false,
      resolve: {
        params: () => {
          return {
            spermId: this.formData[spermKind],
            isDeposit: this.isDeposit,
            qwert: this.qwert
          };
        }
      }
    }).result.then((result) => {
      if (result === false) return;
      result = result || {};
      this.formData[spermKind] = result.id;
      this.formData.masterSpermNo = result.spermNo;
      this.validate();
    });
  }

  openBullInfoModal() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'history/selection/bullInfo/bullInfoSelectModal.html',
      controller: 'bullInfoSelectModalController',
      backdrop: false,
      keyboard: false,
      resolve: {
        params: () => {
          return {
            cowId: this.selectedBullInfo.cowId,
            bulls: this.tanetsukeBulls,
            isDeposit: this.isDeposit,
            qwert: this.qwert
          };
        }
      }
    }).result.then((result) => {
      result = result || {};
      this.selectedBullInfo = result;
      this.formData['bullCowId'] = result.cowId;
    });
  }

  openEmbryoSelectDialog() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'history/selection/embryo/embryo-select-dialog.html',
      controller: 'EmbryoSelectDialogController',
      controllerAs: 'ctrl',
      backdrop: false,
      keyboard: false,
      resolve: {
        params: () => {
          return {
            masterEmbryoId: this.formData['masterEmbryoId'],
            farmKind: this.farmKind,
          };
        },
        embryoMasters: () => {
          return this.embryoMasters;
        }
      }
    }).result.then((result) => {
      if (result.status === EmbryoSelectDialogController.RETURN_STATUS.OK) {
        this.formData['masterEmbryoId'] = result.masterEmbryoId;
        this.formData['masterEmbryoName'] = result.masterEmbryoName;
      }

      const selected = this.embryoMasters.find((m) => Number(m.id) === Number(this.formData['masterEmbryoId']));

      // 新規作成された場合はマスターを再度ロードする
      if (!selected) {
        this.disableSubmit(true);
        // TODO: isDepositでリクエストを変更する
        this.MasterEmbryoAPI.available().then((res) => {
          this.embryoMasters = res.data;
          this.disableSubmit(false);
        });
      }
    }).catch((result) => {
      //キャンセル時にコンソールエラーがでるのでダミー
    });
  }

  entryCowEventFailure(reject) {
    this.entryProcessFailure = true;
    this.historyModalBlock.stop();

    if (this.formData.eventType !== 'bunben') return this.messages.push(reject);

    const errors = Calved.generateErrorMessages(reject.data.messages, this.IsMobile);
    let delay = 0;

    if (this.IsMobile) {
      this.clearBirthStateSelected();
      this.openEventRegisterMobile();
      delay = 1000;
    }

    this.$timeout(() => {
      if (this.IsMobile) {
        errors.map((e) => this.messagesMap[e.field] = e.message);
      } else {
        this.messages = errors;
      }
      this.disableSubmit(true);
    }, delay);
  }

  /**
   * 飼育日数とDGを再計算し、フォームの値を更新する
   */
  updateBreedingDaysAndDg() {
    const breedingDays = Cow.calculateBreedingDays(this.cow, Number(this.formData.occurredAt));
    const dg = Cow.calculateDg(this.cow, breedingDays, Number(this.formData.weight));

    if (dg === 'calculateDgError') {
      this.errorMessage = '出生時体重または購入時体重が未入力のため計算できません';
      if (this.IsMobile) {
        this.mobileScope.errorMessage = this.errorMessage;
      }
      return;
    }

    this.formData.dg = this.UtilService.nullIfZeroOrUnder(dg);
    this.formData.breedingDays = this.UtilService.nullIfZeroOrUnder(breedingDays);
  }

  setMedicationSummary() {
    if (!EventType.isMedicationForLegacy(this.formData.eventType)) return;

    const clearWithdrawalPeriod = () => {
      [
        'masterMedicineIds', 'medicineCapacities', 'medicineMethods',
        'maxMilkMedicineName', 'maxBeefMedicineName',
        'endDateOfMilkWashoutPeriod', 'endDateOfBeefWashoutPeriod'
      ].forEach((f) => {
        this.formData[f] = null;
      });

      this.dateLabelOfBeefWashoutPeriod = null;
      this.dateLabelOfMilkWashoutPeriod = null;

      if (this.IsMobile && this.mobileScope) {
        this.mobileScope.dateLabelOfMilkWashoutPeriod = null;
        this.mobileScope.dateLabelOfBeefWashoutPeriod = null;
      }
    };

    if (this.selectedMedicineList.length === 0) {
      clearWithdrawalPeriod();
      return;
    }

    let treatmentDate = this.formData.treatmentDiseaseDate;
    if (this.formData.eventType === 'vaccine') {
      treatmentDate = this.formData.occurredAt;
    }
    if (!treatmentDate) {
      clearWithdrawalPeriod();
      return;
    }

    const medicines = this.selectedMedicineList.map((m) => {
      const master = this.medicineInfoMap[m.id] || {};
      return {
        id: m.id,
        capacity: m.capacity,
        method: m.method,
        name: m.name,
        milkWashoutPeriod: master.milkWashoutPeriod,
        beefWashoutPeriod: master.beefWashoutPeriod,
      };
    });

    const medication = CowEvent.summarizeMedication(
      medicines,
      treatmentDate
    );

    [
      'masterMedicineIds', 'medicineCapacities', 'medicineMethods',
      'maxMilkMedicineName', 'maxBeefMedicineName',
      'endDateOfMilkWashoutPeriod', 'endDateOfBeefWashoutPeriod'
    ].forEach((f) => {
      this.formData[f] = medication[f];
    });

    if (medication.endDateOfMilkWashoutPeriod) {
      this.dateLabelOfMilkWashoutPeriod = DateUtil.toYYYYMMDD(treatmentDate)
        + '　～　' + DateUtil.toYYYYMMDD(medication.endDateOfMilkWashoutPeriod);
    } else {
      this.dateLabelOfMilkWashoutPeriod = null;
    }

    if (medication.endDateOfBeefWashoutPeriod) {
      this.dateLabelOfBeefWashoutPeriod = DateUtil.toYYYYMMDD(treatmentDate)
        + '　～　' + DateUtil.toYYYYMMDD(medication.endDateOfBeefWashoutPeriod);
    } else {
      this.dateLabelOfBeefWashoutPeriod = null;
    }

    if (this.IsMobile && this.mobileScope) {
      this.mobileScope.dateLabelOfMilkWashoutPeriod = this.dateLabelOfMilkWashoutPeriod;
      this.mobileScope.dateLabelOfBeefWashoutPeriod = this.dateLabelOfBeefWashoutPeriod;
    }
  }

  changeCheck(key) {
    this.formData[key] = !this.formData[key];
  }

  changeTanetsukeMethod() {
    let clearInputList = [{
      method: '人工授精',
      clear: ['donorRegistrationNo', 'donorBreed', 'donorName', 'donorSpermNo',
        'motherRegistrationNoOfDonor', 'motherNameOfDonor', 'bullCowId']
    }, {
      method: '移植',
      clear: ['bullCowId', 'masterSpermId', 'masterSpermNo']
    }, {
      method: '本交',
      clear: ['workerName', 'masterSpermId', 'masterSpermNo', 'donorRegistrationNo', 'donorBreed',
        'donorName', 'donorSpermNo', 'motherRegistrationNoOfDonor', 'motherNameOfDonor']
    }];
    clearInputList.forEach((array) => {
      if (this.formData.tanetsukeMethod === array.method) {
        array.clear.forEach((key) => {
          if (key === 'bullCowId') this.selectedBullInfo = {};

          if (key === 'masterSpermId') {
            this.formData.masterSpermId = null;
          } else {
            this.formData[key] = '';
          }
        });
      }
    });
  }

  selectBreedingDate() {
    let targetBreeding = null;

    // 妊鑑結果が "受胎","双子受胎"の場合のみ初期値をセットする
    if (this.formData.ninshinkanteiResult === '受胎' || this.formData.ninshinkanteiResult === '双子受胎') {
      this.targetBreedingList.forEach((breedObj) => {
        if (Number(breedObj.date) <= Number(this.formData.occurredAt)
          && (targetBreeding === null || targetBreeding.date === null || targetBreeding.date < Number(breedObj.date))) {
          targetBreeding = breedObj;
        }
      });
      this.changeBreedingId(targetBreeding.value);
    } else {
      this.selectedBreedingId = 0;
      this.formData.targetBreedingDate = null;
      this.selectSpermInfo = '';
      this.selectBreedingMethod = '';
      this.formData.breedingCowEventId = 0;
    }
    this.validate();
  }

  changeBreedingId(selectBreedingId) {
    let targetBreeding = this.targetBreedingList.find((breeding) => {
      return breeding.value === selectBreedingId;
    }) || {value: 0};
    this.selectedBreedingId = targetBreeding.value;

    this.formData.targetBreedingDate = targetBreeding.date;
    this.selectSpermInfo = targetBreeding.spermInfo;
    this.selectBreedingMethod = targetBreeding.breedingMethod;

    // 導入直後、移行直後の場合は対象イベントIDをセットしない
    if (this.selectedBreedingId && this.selectedBreedingId !== 'introduce' && this.selectedBreedingId !== 'latest') {
      this.formData.breedingCowEventId = this.selectedBreedingId;
    } else if (this.selectedBreedingId === '') {
      this.formData.breedingCowEventId = 0;
    }

    this.validate();

    if (this.IsMobile && this.mobileScope) {
      this.mobileScope.ninshinkantei.selectedBreedingId = this.selectedBreedingId;
      this.mobileScope.ninshinkantei.selectSpermInfo = this.selectSpermInfo;
      this.mobileScope.ninshinkantei.selectBreedingMethod = this.selectBreedingMethod;
    }
  }

  // 未使用？
  validOccurredDiseaseDate() {
    let latestCalvingDate = this.cow.latestCalvingDate;
    if (!this.formData.occurredDiseaseDate) {
      return false;
    }

    const occurredDate = this.DateUtilService.startOfDay(Number(this.formData.occurredDiseaseDate)).valueOf();
    const treatmentDiseaseDate = this.formData.treatmentDiseaseDate
      ? this.DateUtilService.startOfDay(Number(this.formData.treatmentDiseaseDate)).valueOf() : null;

    if (!treatmentDiseaseDate || (occurredDate <= treatmentDiseaseDate && latestCalvingDate <= treatmentDiseaseDate)) {
      this.disableSubmit(false);
    } else {
      this.disableSubmit(true);
      if (treatmentDiseaseDate < occurredDate) this.messagesMap.treatmentDiseaseDate = '治療日が発生日より前の日付になっています。';
      if (treatmentDiseaseDate < latestCalvingDate) this.messagesMap.treatmentDiseaseDate = '治療日が最新分娩日より前の日付になっています。';
    }

    this.ifAfterFallingDeadDate();
    this.ifAfterEliminateDate();
  }

  ifAfterFallingDeadDate() {
    const fallingDeadDate = Number(this.cow.fallingDeadDate) || null;
    if (fallingDeadDate && this.targetDate() > fallingDeadDate) {
      this.disableSubmit(true);
      this.messages[this.targetDateField()] = `${this.targetDateName(this.formData.eventType)}がへい死日より後の日付になっています。`;
      return true;
    }
    return false;
  }

  ifAfterEliminateDate() {
    const eliminateDate = Number(this.cow.eliminateDate) || null;
    if (eliminateDate && this.targetDate() > eliminateDate) {
      this.disableSubmit(true);
      this.messages[this.targetDateField()] = `${this.targetDateName(this.formData.eventType)}が出荷日より後の日付になっています。`;
      return true;
    }
    return false;
  }

  daysAfterBreedingDate(targetBreedingDate, occurredAt) {
    return this.DateUtilService.countDays(Number(targetBreedingDate), Number(occurredAt));
  }

  targetDate() {
    if (this.eventTypes[this.formData.eventType].treatmentEvent) {
      return this.formData.treatmentDiseaseDate;
    }
    return this.formData.occurredAt;
  }

  targetDateName(eventType) {
    if (this.eventTypes[eventType].treatmentEvent) {
      return '治療日';
    }
    return this.eventTypes[eventType].occurredAtName || '日付';
  }

  targetDateField() {
    if (this.eventTypes[this.formData.eventType].treatmentEvent) {
      return 'treatmentDiseaseDate';
    }
    return 'occurredAt';
  }

  /**
   * イベントのバリデーションを行う
   * @param {boolean} [isInitializing] - 初期化中の実行であればtrue
   * @returns {boolean} - バリデーション結果
   */
  validate(isInitializing) {
    if (this.isValidatorDisabled) return;

    // AngularJSの仕様でインスタンスを使い回す必要がある
    this.messages.length = 0;
    Object.keys(this.messagesMap).forEach((k) => delete this.messagesMap[k]);

    const event = {};
    Object.assign(event, this.formData);

    const hour = Number(event.selectedHour || 0);
    const minute = Number(event.selectedMinute || 0);
    if (hour > 0 || minute > 0) {
      event.occurredAt = DateUtil.addTimeToDate(event.occurredAt, hour, minute);
    }

    const events = this.rawEvents.filter((e) => e.eventType !== 'alert');
    let params = {};
    if (event.eventType === 'bunben') {
      params.labelCowNo = this.Dictionary.COW.COW_NO;
      params.alphanumericCowNo = this.farm.data.cowNoPattern === 'alphanumeric';
      params.useName = this.farm.useName();
      params.isMobile = this.IsMobile;
      params.isValidatorCalfCowUidLength = this.isValidatorCalfCowUidLength;
      params.skipLaterReproductionValidation = this.editMode && (this.calvedDateTimeDisabled || isInitializing);
    }
    const validator = new EventValidator();
    const result = validator.validate(event, events, this.cow, params);

    const medicineErrors = this.selectedMedicineList.filter((m) => m.errorMessage);

    if (result.valid && medicineErrors.length === 0) {
      this.disableSubmit(false);
      return true;
    } else {
      if (result.errors) {
        result.errors.forEach((e) => {
          if (!this.messages.includes(e.message)) this.messages.push(e.message);
          this.messagesMap[e.field] = e.message;
        });
      }

      this.disableSubmit(true);
      return false;
    }
  }

  changeMoveToCowGroup() {
    this.penList.length = 0;
    if (this.formData.moveToCowGroupId) {
      Array.prototype.push.apply(this.penList, this.penListMap[this.formData.moveToCowGroupId]);
    }
    if (this.penList.length === 0 || this.penList[0].value === '') {
      this.formData.moveToPen = null;
    }
  }

  changeHormoneProgramName(isChange) {
    this.targetHormoneList.length = 0;
    this.hormoneNameDisabled = false;

    const master = this.hormoneListMap[this.formData.masterHormoneProgramId];

    if (master) {
      Array.prototype.push.apply(this.targetHormoneList, master);
      this.formData.hormoneCode = isChange ? '1' : this.formData.hormoneCode;
      if (!this.formData.hormoneCode) {
        this.formData.hormoneCode = this.targetHormoneList[0].value;
      }
    } else {
      this.hormoneNameDisabled = true;
    }

    if (this.IsMobile && this.mobileScope) this.mobileScope.hormoneNameDisabled = this.hormoneNameDisabled;

    this.setHormoneCode();
  }

  setHormoneCode() {
    const master = this.hormoneListMap[this.formData.masterHormoneProgramId] || [];
    const hormoneProgram = master.find((s) => s.value === this.formData.hormoneCode) || {};

    this.formData.hormoneCode = hormoneProgram.value || null;
  }

  /**
   * イベント投稿・編集後、データの更新
   *
   * @param {boolean} followingEventExists true: 次のイベントが存在する
   */
  refreshEvents(followingEventExists) {
    if (!this.CowService) return;

    // 牛個体画面、メインナビの牛群情報も更新するように知らせる
    this.CowService.get(this.cow.cowId).then((res) => {
      this.cow = this.DateTimeUtilFactory.parseClientDateForCowInfo(res.data);
      this.$rootScope.$emit('cowDetail:refreshCowInfo', this.cow, followingEventExists, this.formData.eventType);
    }).catch((err) => console.error(err));
    // 削除の時はeventTypeが無いので強制的にリフレッシュする
    if ((!this.formData.eventType || this.formData.eventType === 'gyugunidou')) {
      this.$rootScope.$emit('menu:refreshGroups');
    }

    this.refreshInfoMap()
      .then(() => {
        return this.CowEventsService.index(this.cow.cowId);
      })
      .then((res) => {
        this.setEvents(res.data);

        const tanetsukeEvents = res.data.filter((event) => event.eventType === 'tanetsuke');
        this.targetBreedingList = this.generateBreedingList(tanetsukeEvents, this.cow.cowId);

        const events = CowEvent.addAlertsToEvents(this.formatEventsData(res.data), this.alerts);
        this.$rootScope.$emit('cowDetail:refreshHistories', events);

        this.$timeout(() => {
          this.rawEvents = this.TimelineService.markLastEventOfYear(
            this.TimelineService.decorateEvents(events, this.selection),
            this.filterResults
          );
          this.fetchHeadEvents();
          this.updateLatestEventYear();
        });
      })
      .catch((err) => console.error(err));
  }

  refreshInfoMap() {
    if (!this.context) return new Promise((resolve, reject) => resolve(true));

    return this.context.load(this.cow.cowId).then((res) => {
      res[3].data.forEach((medicine) => {
        this.medicineInfoMap[medicine.id] = medicine;
      });

      res[8].data.forEach((spermMaster) => {
        this.spermInfoMap[spermMaster.id] = spermMaster;
      });

      this.alerts = this.constructAlertList(res[7].data, res[16].data);
    });
  }

  refreshAlerts() {
    return this.alertsByCow(this.cow.cowId)
      .then((res) => this.alerts = res.data)
      .catch((err) => console.error(err));
  }

  constructAlertList(dailyAlerts, dysstasiaAlerts) {
    dailyAlerts.forEach((record) => {
      record.dysstasia = false;
      record.dysstasiaAlertAt = null;
    });

    return dailyAlerts.concat(dysstasiaAlerts);
  }

  /**
   * イベント間の整合性を保つために妊娠鑑定が行われた種付イベントは編集できないようにする
   * @param {Array} events
   * @return {Array<Object>} 変換されたイベントデータ
   */
  setReadOnlyTanetsuke(events) {
    const relatedIds = {};
    events.filter((event) => event.eventType === 'ninshinkantei')
      .forEach((event) => relatedIds[event.breedingCowEventId] = true);

    return events.map((event) => {
      if (event.eventType === 'tanetsuke' && relatedIds[event.id]) {
        event.readonly = true;
      }
      return event;
    });
  }

  /**
   * 出生時状態の選択を初期化する。
   */
  clearBirthStateSelected(start = 1) {
    if (!this.selection) return;

    for (let i = start; i <= 4; i++) {
      this.selection['birthStates' + i].forEach((state) => {
        state.selected = Boolean(false);
      });
    }
  }

  clearRadioSelected() {
    this.formData.calvingDifficulty = '';
  }

  selectByName(events) {
    return events.filter((e) => {
      if (!e.eventName) return false;
      if (e.eventName === '出荷停止解除') return false;

      for (let i in this.filterResults) {
        if (e.eventName === this.filterResults[i]) {
          return true;
        }
      }

      return false;
    });
  }

  validateThenDelete(event) {
    switch (event.eventType) {
    case 'embryo_recovery':
      if (!event.embryoMasterRegistered) {
        this.delete(event.id, event.eventName, event.eventType);
        break;
      }
      return this.confirmEmbryoRecovery(event, 'delete').then((confirmed) => {
        if (confirmed) {
          this.delete(event.id, event.eventName, event.eventType);
        }
      }).catch((e) => console.error('confirmEbryoRecovery Error: ', e));
    case 'dysstasia':
      this.deleteDysstasia(event.dysstasiaId, event.eventName, event.eventType);
      break;
    default:
      this.delete(event.id, event.eventName, event.eventType);
    }
  }

  delete(eventId, eventName, eventType) {
    eventName = eventName || '';
    const modalInstance = this.modalDialogFactory.showHistoryConfirm({
      title: '確認',
      text1: eventName + 'イベントを削除します。よろしいですか？',
      text2: '',
      no: true, yes: true
    });

    modalInstance.result.then((modalResult) => {
      if (modalResult) {
        this.blockUI.start('削除中');
        this.CowEventsService.delete(eventId).then(() => {
          this.onItemUpdated({eventType: eventType});
          this.refreshEvents();
          this.blockUI.stop();
        }).catch((err) => {
          console.error(err);
          this.blockUI.stop();

          if (eventName === '分娩') {
            let errors = [];
            err.data.messages.forEach((error) => {
              const lineNo = error.lineNo;
              let message = error.message;
              message = lineNo ? `[${lineNo}頭目] ${message}` : message;
              errors.push(message);
            });

            this.modalDialogFactory.showAlertModal({
              title: 'エラー',
              text1: errors.join(''),
              no: false,
              yes: false
            });
          }
        });
      }
    });
  }

  deleteDysstasia(dysstasiaId, eventName, eventType) {
    eventName = eventName || '';
    const modalInstance = this.modalDialogFactory.showHistoryConfirm({
      title: '確認',
      text1: eventName + 'を削除します。よろしいですか？',
      text2: '',
      no: true, yes: true
    });

    modalInstance.result.then((modalResult) => {
      if (modalResult) {
        this.blockUI.start('削除中');
        this.DysstasiaAPI.delete(dysstasiaId).then(() => {
          this.onItemUpdated({eventType: eventType});
          this.refreshEvents();
          this.blockUI.stop();
        }).catch((err) => {
          console.error(err);
          this.blockUI.stop();
        });
      }
    });
  }

  toggleContents($event, isCollapsed, eventIndex) {
    if ($event.target.className === 'icon-css-arrow') return isCollapsed;
    // 開閉アニメーション
    const commentDiv = document.querySelector(`#timeline-cow .comment${eventIndex}`);
    if (!commentDiv) return isCollapsed;
    commentDiv.style.maxHeight = isCollapsed ? `${commentDiv.scrollHeight}px` : '0px';
    return !isCollapsed;
  }

  commentClass(eventIndex) {
    return `comment comment${eventIndex}`;
  }

  togglableClassIf(eventIndex) {
    const commentDiv = document.querySelector(`#timeline-cow .comment${eventIndex}`);
    return (commentDiv && commentDiv.scrollHeight > 0) ? 'togglable' : '';
  }

  /**
   * birthstateチェックボックスのチェックを切り替える。
   *
   * @param {Object|null} event イベントオブジェクト or null
   * @param {string} listKey リストのキー
   * @param {string} key 値を追加するキー
   * @param {string} value 追加する値
   */
  changeBirthState(event, listKey, key, value) {
    this.selection[listKey].forEach((state) => {
      if (state.label === value) state.selected = !state.selected;
    });

    if (event) {
      this.setBirthStateInFormData(event, key, value);
    }
  }

  /**
   * formDataのbirthstateを設定する。
   *
   * @param {Object} event イベントオブジェクト
   * @param {string} key 値を追加するキー
   * @param {string} value 追加する値
   */
  setBirthStateInFormData(event, key, value) {
    let tempArray = this.formData.calfs[key].birthState || [];
    if (event.target.checked) {
      tempArray.push(value);
    } else {
      tempArray = tempArray.filter((temp) => {
        return temp !== value;
      });
    }
    this.formData.calfs[key].birthState = tempArray;
  }

  /**
   * 引数の値が、選択済みか確認する。
   * 選択済みの場合はchangeBirthStateをコールする。
   *
   * @param {string} listKey リストのキー
   * @param {string} key 値を確認するキー
   * @param {string} value 確認する値
   */
  checkSelectedBirthState(listKey, key, value) {
    if (this.formData.calfs[key]) {
      if (~this.formData.calfs[key].birthState.indexOf(value)) {
        this.changeBirthState(null, listKey, key, value);
      }
    }
  }

  /**
   * 総枝肉重量を計算するあれ
   */
  calculateTotalCarcassWeight() {
    this.formData.totalDressedCarcassWeight =
      Math.round(
        (Number(this.formData.dressedCarcassWeightOfL) +
          Number(this.formData.dressedCarcassWeightOfR)) * 10
      ) / 10;

    this.calculateDressedCarcassSalesPrice();
  }

  /**
   * 枝肉販売金額の計算
   */
  calculateDressedCarcassSalesPrice() {
    this.formData.dressedCarcassSalesPrice = Carcass.calculateDressedCarcassSalesPrice(
      this.formData.dressedCarcassUnitPrice,
      this.formData.totalDressedCarcassWeight
    );
  }

  focusDressedCarcassUnitPrice() {
    const dressedCarcassUnitPrice = this.formData.dressedCarcassUnitPrice;
    this.formData.dressedCarcassUnitPrice = this.removeComma(dressedCarcassUnitPrice);
  }

  focusDressedCarcassSalesPrice() {
    const dressedCarcassSalesPrice = this.formData.dressedCarcassSalesPrice;
    this.formData.dressedCarcassSalesPrice = this.removeComma(dressedCarcassSalesPrice);
  }

  blurDressedCarcassUnitPrice() {
    const dressedCarcassUnitPrice = this.formData.dressedCarcassUnitPrice;
    this.formData.dressedCarcassUnitPrice = this.formatComma(dressedCarcassUnitPrice);
  }

  blurDressedCarcassSalesPrice() {
    const dressedCarcassSalesPrice = this.formData.dressedCarcassSalesPrice;
    this.formData.dressedCarcassSalesPrice = this.formatComma(dressedCarcassSalesPrice);
  }

  formatComma(value) {
    if (!StringUtil.isDecimal(value)) {
      return value;
    }

    return Number(value).toLocaleString();
  }

  removeComma(value) {
    if (!value) {
      return value;
    }

    return value.replace(/,/g, '');
  }

  /**
   * 枝肉成績のデータを編集ように再構築
   * @param {Object} event
   * @return {Object} event
   */
  reformatCarcassData(event) {
    const numCols = [
      'slaughterNo',
      'meetGrade',
      'beforeSlaughterWeight',
      'dressedCarcassWeightOfL',
      'dressedCarcassWeightOfR',
      'loinArea',
      'ribsThickness',
      'subcutaneousFat',
      'yieldBaseValue',
      'bmsNo',
      'bcsNo',
      'gloss',
      'marblingGrade',
      'bcsAndGlossGrade',
      'bfsNo',
      'fatLuster',
      'bfsAndFatLusterGrade',
      'texture',
      'tight',
      'tightAndTextureGrade',
      'totalDressedCarcassWeight'
    ];

    // 数字をNumに変更
    numCols.forEach((v) => {
      if ([undefined, null, ''].includes(event[v])) return;
      event[v] = parseFloat(event[v]);
    });

    // 臓器STRを配列に変更
    if (event.visceralDestruction) {
      event.selectedVisceralDestruction = {value: event.visceralDestruction.split(':')};
    }

    if (event.dressedCarcassUnitPrice) {
      event.dressedCarcassUnitPrice = this.formatComma(event.dressedCarcassUnitPrice);
    }

    if (event.dressedCarcassSalesPrice) {
      event.dressedCarcassSalesPrice = this.formatComma(event.dressedCarcassSalesPrice);
    }

    return event;
  }

  /*
   * 跛行患肢が未選択なら対応する跛行患部を未選択にする
   *
   * @param {string} affectedLimb 跛行患肢
   * @param {string} affectedPart 跛行患部
   */
  changeLamenessAffectedPart(affectedLimb, affectedPart) {
    this.changeCheck(affectedLimb);
    if (!this.formData[affectedLimb]) {
      this.formData[affectedPart] = '';
    }
  }

  /**
   * 跛行患部が選択されたら跛行患肢を選択する
   *
   * @param {string} affectedPart 跛行患部
   * @param {string} affectedLimb 跛行患肢
   */
  changeLamenessAffectedLimb(affectedPart, affectedLimb) {
    this.formData[affectedLimb] = (this.formData[affectedPart] !== null);
  }

  showDeleteButton(cowEvent) {
    return CowEventCollection.canDelete(this.rawEvents, cowEvent);
  }

  /**
   * 編集ボタンを表示する
   * 流産の場合は、編集ボタンは表示しない
   *
   * @param {string} eventType イベント種別
   * @param {boolean} readonly 読み込みフラグ
   */
  showEditButton(eventType, readonly) {
    if (['abort', 'dysstasia'].includes(eventType)) return false;
    return !readonly;
  }

  /**
   * AngulrJSのバリデーションシステムのAPIを呼び出すため、フォーム参照を保存する
   * @param {*} inFormRef フォームコントローラ
   */
  setFormRef(inFormRef) {
    this.formRef = inFormRef;
  }

  /**
   * 治療日更新時のバリデーション
   */
  onUpdateTreatmentDiseaseDate() {
    if (this.today.valueOf() === this.formData.treatmentDiseaseDate
      && this.formData.selectedHour === '00'
      && this.formData.selectedMinute === '00'
    ) {
      this.formData.selectedHour = this.nowHour;
      this.formData.selectedMinute = this.nowMinute;
    }
    this.setMedicationSummary();
    this.validate();
  }

  /**
   * 牛群移動移動先更新時のバリデーション
   */
  onUpdateMoveToCowGroupId() {
    this.$timeout(() => {
      this.validate();
      this.changeMoveToCowGroup();
    });
  }

  /**
   * 測定日更新時のバリデーション
   */
  onUpdateMeasurementDate() {
    this.$timeout(() => {
      this.updateBreedingDaysAndDg();
      this.validate();
    });
  }

  /**
   * 妊娠鑑定イベントの種付け情報選択リストを作成
   *  イベントがある場合、targetBreedingListに押し込む
   *  ない場合個体情報を取得して新しく作る
   *  cowIdすらない場合、空なBreedingListを返す
   * @param {Array} events
   * @return {Array<Object>} BreedingList
   */
  generateBreedingList(events, cowId) {
    const breedingList = [{
      label: '-',
      value: 0,
      date: null,
      spermInfo: '',
      breedingMethod: ''
    }];

    if (!events.length) {
      return cowId ?
        breedingList.concat(this.createNewBreedingList(cowId)) :
        breedingList;
    }

    events.forEach((event) => {
      const occurredAt = event.tanetsukeMethod === '移植' ?
        this.DateUtilService.subtractDays(Number(event.occurredAt), 7).valueOf() :
        Number(event.occurredAt);

      if (occurredAt < this.cow.latestCalvingDate) return;

      const spermNo = (this.spermInfoMap[event.masterSpermId] || {}).spermNo;

      breedingList.push({
        label: this.DateUtilService.toYYYYMMDD(Number(event.occurredAt))
          + (event.workerName ? ':' + event.workerName : '')
          + (event.tanetsukeMethod ? ':' + event.tanetsukeMethod : ''),
        value: event.id,
        date: occurredAt,
        spermInfo: spermNo,
        breedingMethod: event.tanetsukeMethod
      });
    });

    return breedingList;
  }

  /**
   * 種付けイベントが存在しない場合、作成する (妊娠鑑定用)
   * @param {number || string} cowId
   * @return {Array} [{BreedingList}]
   */
  createNewBreedingList(cowId) {
    const cowInfo = this.cow;

    // 牛個体の最新情報から対象種付情報を取得
    const occurredAt = cowInfo.latestBreedingMethod === '移植' ?
      this.DateUtilService.subtractDays(Number(cowInfo.latestFertilizationDate), 7).valueOf() :
      Number(cowInfo.latestFertilizationDate);

    if (occurredAt === 0) return [];

    // 最新分娩日以降に導入された場合には導入情報から対象種付情報を取得
    const introAfterCalving = Number(cowInfo.latestCalvingDate || 0) <= Number(cowInfo.introduceDate || 0);

    const occurredAtYMD = this.DateUtilService.toYYYYMMDD(occurredAt);
    const label = introAfterCalving ?
      occurredAtYMD + ':人工授精' :
      occurredAtYMD + (cowInfo.latestBreedingMethod ? ':' + cowInfo.latestBreedingMethod : '');

    // 既存オプションにconcatするため配列で返す
    return [{
      label: label,
      value: introAfterCalving ? 'introduce' : 'latest',
      date: occurredAt,
      spermInfo: cowInfo.latestMasterSpermNo,
      breedingMethod: introAfterCalving ? '人工授精' : cowInfo.latestBreedingMethod
    }];
  }

  /**
   * イベントデータ整理
   * @param {Array} events
   * @return {Array<Object>} 整理されたevents配列
   */
  formatEventsData(events) {
    const parsedEvents = this.DateTimeUtilFactory.parseClientDateForHistories(events); // いらなくね？
    // 妊娠鑑定されたら種付けイベントをロック
    const formattedEvents = this.setReadOnlyTanetsuke(parsedEvents);

    formattedEvents.forEach((event, index) => {
      if (!index) event.isLatestEvent = true;

      if (event.masterMedicineIds) {
        event.selectedMedicines = TimelineItem.toMedicationItem(event.medications);
      }

      if (event.eventType === 'lame') {
        event.groupedLameDiagnosises = CowEvent.groupLameDiagnosisesByPart(event);
        event.eventName = EventType.eventNameForLegacy(event.eventType);
      }

      if (event.eventType === 'tanetsuke') {
        if (event.masterSpermId) {
          event.spermName = event.masterSpermName;
        } else {
          event.spermName = '-';
        }
      }

      if (event.eventType === 'bunben') {
        if (event.calfs) {
          event.calfs.forEach((calf) => {
            calf.birthStateString = calf.birthState.join(',');
          });

          event.registeredCalfs = angular.copy(event.calfs).filter((calf) => {
            return calf.registered;
          }).map((calf) => {
            calf.cowNo = calf.cowNo || '-';
            return calf;
          });
        }
      }

      if (event.eventType === 'carcass') {
        if (event.dressedCarcassUnitPrice) {
          event.dressedCarcassUnitPrice = this.formatComma(event.dressedCarcassUnitPrice);
        }

        if (event.dressedCarcassSalesPrice) {
          event.dressedCarcassSalesPrice = this.formatComma(event.dressedCarcassSalesPrice);
        }
      }

      event.occurredAtMMDD = this.DateUtilService.toMMDD(event.occurredAt);
      event.treatedAtHHMM = event.treatmentDiseaseDate ?
        this.DateUtilService.toHHmm(Number(event.treatmentDiseaseDate)) :
        null;

      event.displayId = `event_${event.id}`;

      const daysAgo = DateUtil.diffDays(DateUtil.startOfDay(event.occurredAt), DateUtil.today());

      if (daysAgo === 0) {
        event.daysAgo = '本日';
      } else if (daysAgo < 0) {
        event.daysAgo = `${Math.abs(daysAgo)}日後`;
      } else if (daysAgo <= 365) {
        event.daysAgo = `${daysAgo}日前`;
      } else {
        event.daysAgo = null;
      }

      const umInputPaths = [
        'u-motion',
        'file-import',
        'legacy-file-import',
        'migration-file-import',
        'admin',
        'transfer',
        'legacy-transfer',
      ];

      event.isInputPathExternal = !umInputPaths.includes(event.inputPath);
    });

    return formattedEvents;
  }

  /**
   * ホルモンプログラム名オプション生成
   * @param {Array.<Object>} masters ホルモンプログラムマスタ設定(全て)
   * @param {string} hormoneProgramName 入力済のホルモンプログラム名称
   */
  initHormoneValues(masters, masterHormoneProgramId = null) {
    const hormonePrograms = CollectionUtil.selectableMasters(masters, [masterHormoneProgramId]);
    this.hormoneProgramNameList = CowEvent.hormoneProgramNameListDefault;
    this.hormoneListMap.length = 0;

    hormonePrograms.forEach((program, index) => {
      this.hormoneProgramNameList.push({
        label: `${index + 1}: ${program.name}`,
        value: program.id,
      });

      // ホルモンコードオプション生成
      this.hormoneListMap[program.id] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        .reduce((arr, value) => {
          if (!program[`hormoneCode${value}`]) return arr;

          arr.push({
            label: program[`hormoneName${value}`],
            value: program[`hormoneCode${value}`],
            period: program[`hormonePeriod${value}`],
          });
          return arr;
        }, []);
    });
  }

  /**
   * 各イベントのドロップダウンオプション作成
   * @param {Object} selections
   * @return {Object} transformedSelections
   */
  initSelectionOptions(selections, userDefinedSelections) {
    const transformedSelections = angular.copy(selections);
    this.appConst.settingListNameMap.AccountInfo.forEach((listNameObj) => {
      transformedSelections[listNameObj.name] = getCustomSelectList(
        listNameObj.name,
        userDefinedSelections,
        listNameObj.allowBlank
      );
    });

    transformedSelections['workerNames'] = getCustomSelectList(
      'operationUsers',
      userDefinedSelections,
      true
    );

    // 子牛毎に出生時状態を作成(分娩)
    [1, 2, 3, 4].forEach((i) =>
      transformedSelections['birthStates' + i] = angular.copy(transformedSelections['birthStates']));

    transformedSelections['clawDiseaseNames'] = [
      '',
      '蹄底潰瘍',
      '白線病',
      '趾皮膚炎',
      '趾間フレグモーネ',
      '蹄底血斑',
      '蹄尖潰瘍',
      '変形蹄',
      '横裂蹄',
      '縦裂蹄',
      '趾間過形成',
      '蹄底菲薄化',
      '蹄球びらん',
      '趾間皮膚炎',
      '乳頭状趾皮膚炎',
      'その他',
    ];

    transformedSelections['diseaseContinuation'] = [
      {label: '新規', value: 'new'},
      {label: '継続', value: 'continuous'},
    ];

    transformedSelections.mastitisDiagnosisResult.unshift({label: '', value: ''});

    transformedSelections['embryoType'] = [
      {label: '体内受精卵', value: '体内受精卵'},
      {label: '体外受精卵', value: '体外受精卵'},
    ];

    return transformedSelections;
  }

  /**
   * 牛群名のマップ作成
   */
  createGroupNameMap(groups) {
    this.groupList.length = 0;

    groups.forEach((group) => {
      this.groupNameMap[group.cowGroupId] = group.cowGroupName || '';
      this.groupList.push(
        {label: group.cowGroupName, value: group.cowGroupName, id: group.cowGroupId, menuDisplayed: group.menuDisplayed}
      );

      if (typeof group.pens === 'string') {
        this.penListMap[group.cowGroupId] = group.pens.split(',')
          .map((val) => ({label: val, value: val}));
      }
    });
  }

  /**
   * フォームを初期化
   * @return {Object}
   */
  resetFormData() {
    return {
      selectedHour: '00',
      selectedMinute: '00',
      stopMilkingOfBreastFl: false,
      stopMilkingOfBreastFr: false,
      stopMilkingOfBreastBl: false,
      stopMilkingOfBreastBr: false,
      nextStartMilkingFlg: false,
      otherDiseaseName: '',
      mastitisScore: 0
    };
  }

  defaultWorkerName(workerNames, username) {
    if (!workerNames) return '';
    const matched = workerNames.find((workerName) => {
      return StringUtil.removeSpaces(workerName.value) === StringUtil.removeSpaces(username);
    });
    return matched ? matched.value : '';
  }

  /**
   * イベント登録画面のイベント表示マップを作成
   * @param {string} state
   * @param {string} farmKind
   * @return {Object} EventDisplayMap
   */
  createEventDisplayMap(state, farmKind, implementFreshCheckFlg, isAgentFarm) {
    const isNurse = state === '哺育';
    const isCalf = state === '育成';
    const isFattening = state === '肥育';
    const isDoNotBreed = state === '繁殖除外';
    const isHeishi = state === 'へい死';
    const isTouta = state === '出荷';

    return {
      'milking': !isNurse && !isCalf && !isFattening && farmKind === '乳用',
      'calf': isNurse || isCalf,
      'breeding': !isNurse && !isCalf && !isFattening,
      'fattening': !isNurse && !isCalf && isFattening && farmKind === '肉用',
      'calfAndFatten': isNurse || isCalf || isFattening,
      'beef': farmKind === '肉用',
      'doNotBreed': !isDoNotBreed,
      'doFreshCheck': implementFreshCheckFlg,
      'touta': !isHeishi && !isTouta && this.canEditFilterItem,
      'heishi': !isHeishi && !isTouta,
      'carcass': !isAgentFarm && this.canEditFilterItem ||
        isAgentFarm && this.isAdministrator
    };
  }

  // TODO: replace jQuery with CSS or flag
  changeBackgroundColor(selectEntry) {
    $('#timeline-cow .changeBackgroundColor').css({backgroundColor: selectEntry ? '#8aa633' : '#f6f6f6'});
    $('#timeline-cow .box-history-innner').css({border: selectEntry ? '4px solid #8aa633' : 'none'});
  }

  changeVisceralDestruction() {
    if (this.formData.selectedVisceralDestruction.value.includes('全廃棄')) {
      this.formData.selectedVisceralDestruction.value = ['全廃棄'];
    }
  }

  // TODO: replace jQuery with CSS or flag
  eventInput() {
    this.changeBackgroundColor(true);
    $('#box-history').addClass('on-event-input');
    $('#event-input01').css('display', 'none');
    $('#event-input02').fadeIn('3000');
  }

  /**
   * イベント選択時に特定条件に一致する場合はダイアログを作成する
   * 条件に一致しない場合は、nullを返す
   *
   * @param {String} eventType イベント種別
   * @return {Mixed} Promise or null
   */
  createSelectedEventDialog(eventType) {
    if (!this.rawEvents) return;

    let modal = null;

    const editNinshinkanteiEvent = this.rawEvents.find((e) => e.eventType === 'ninshinkantei')
      ? [{
        text: '妊娠鑑定結果を変更する',
        value: 'ninshinkantei',
        new: false
      }]
      : [];

    switch (eventType) {
    case 'freshCheck':
      if (Cow.isPregnant(this.cow)) {
        const template = '<span>この牛は妊娠しています。<br><span class="event-name">フレッシュチェック</span>の登録をつづけますか？</span>';
        modal = this.EventSelectionMultipleChoicesConfirm.open(
          template,
          [
            {text: '登録をつづける', value: 'freshCheck', new: true},
            {text: '分娩イベントを登録する', value: 'bunben', new: true},
            {text: '流産イベントを登録する', value: 'abort', new: true}
          ]
        );
      }
      break;

    case 'hatsujo':
      if (Cow.isPregnant(this.cow)) {
        const template = '<span>この牛は妊娠しています。<br><span class="event-name">発情</span>の登録をつづけますか？</span>';
        modal = this.EventSelectionMultipleChoicesConfirm.open(
          template,
          [
            {text: '登録をつづける', value: 'hatsujo', new: true},
            ...editNinshinkanteiEvent,
            {text: '流産イベントを登録する', value: 'abort', new: true}
          ]
        );
      }
      break;

    case 'hormoneProgram':
      if (Cow.isPregnant(this.cow)) {
        const template = '<span>この牛は妊娠しています。<br><span class="event-name">ホルモンプログラム</span>の登録をつづけますか？</span>';
        modal = this.EventSelectionMultipleChoicesConfirm.open(
          template,
          [
            {text: '登録をつづける', value: 'hormoneProgram', new: true},
            ...editNinshinkanteiEvent,
            {text: '流産イベントを登録する', value: 'abort', new: true}
          ]
        );
      }
      break;

    case 'tanetsuke':
      if (Cow.isPregnant(this.cow)) {
        const template = '<span>この牛は妊娠しています。<br><span class="event-name">種付</span>の登録をつづけますか？</span>';
        modal = this.EventSelectionMultipleChoicesConfirm.open(
          template,
          [
            {text: '登録をつづける', value: 'tanetsuke', new: true},
            ...editNinshinkanteiEvent,
            {text: '流産イベントを登録する', value: 'abort', new: true}
          ]
        );
      } else if (
        this.cow.state === '繁殖除外'
      ) {
        modal = this.EventSelectionSimpleConfirm.open(
          `繁殖除外牛種付確認`,
          `この牛は繁殖除外ですが種付しますか？`
        );
      }
      break;

    case 'abort':
      if (!this.cow.latestFertilizationDate) {
        modal = this.EventSelectionSimpleConfirm.open(
          '確認',
          'この牛は未授精ですが流産させますか？'
        );
      } else if (
        this.cow.state === '授精' ||
        this.cow.state === '未受胎(－)'
      ) {
        const template = '<span>この牛は妊娠していません。<br><span class="event-name">流産</span>の登録をつづけますか？</span>';
        modal = this.EventSelectionMultipleChoicesConfirm.open(
          template,
          [
            {text: '登録をつづける', value: 'abort', new: true},
            {text: '妊娠鑑定イベントを登録する', value: 'ninshinkantei', new: true},
          ]
        );
      }
      break;

    case 'bunben':
      if (!this.cow.latestFertilizationDate) {
        modal = this.EventSelectionSimpleConfirm.open(
          '確認',
          'この牛は未授精ですが分娩させますか？'
        );
      } else if (
        this.cow.state === '授精' ||
        this.cow.state === '未受胎(－)'
      ) {
        const template = '<span>この牛は妊娠していません。<br><span class="event-name">分娩</span>の登録をつづけますか？</span>';
        modal = this.EventSelectionMultipleChoicesConfirm.open(
          template,
          [
            {text: '分娩の登録をつづける', value: 'bunben', new: true},
            {text: '妊娠鑑定イベントを登録する', value: 'ninshinkantei', new: true},
          ]
        );
      }
      break;

    case 'carcass':
      if (!this.cow.eliminateDate) {
        const template = '<span>この牛は出荷されていません。<br><span class="event-name">枝肉成績</span>の登録をつづけますか？</span>';
        modal = this.EventSelectionMultipleChoicesConfirm.open(
          template,
          [
            {text: '登録をつづける', value: 'carcass', new: true},
            {text: '出荷イベントを登録する', value: 'touta', new: true},
          ]
        );
      }
      break;

    case 'doNotBreed':
      if (
        this.cow.state === '授精'
      ) {
        modal = this.EventSelectionSimpleConfirm.open(
          '授精牛繁殖除外確認',
          'この牛は授精済みです。',
          '繁殖除外イベントを登録しますか？'
        );
      } else if (Cow.isPregnant(this.cow)) {
        modal = this.DoNotBreedAlert.open();
      }

      break;

    case 'ninshinkantei':
      if (!this.cow.latestFertilizationDate) {
        const template = '<span>この牛は未授精です。<br><span class="event-name">妊娠鑑定</span>の登録をつづけますか？</span>';
        modal = this.EventSelectionMultipleChoicesConfirm.open(
          template,
          [
            {text: '登録をつづける', value: 'ninshinkantei', new: true},
            {text: '種付イベントを登録する', value: 'tanetsuke', new: true},
          ]
        );
      }
      break;

    case 'kannyu':
      if (!(Cow.isPregnant(this.cow))) {
        const template = '<span>この牛は妊娠していません。<br><span class="event-name">乾乳</span>の登録をつづけますか？</span>';
        modal = this.EventSelectionMultipleChoicesConfirm.open(
          template,
          [
            {text: '登録をつづける', value: 'kannyu', new: true},
            {text: '妊娠鑑定イベントを登録する', value: 'ninshinkantei', new: true},
          ]
        );
      } else if (this.cow.state === '乾乳後期') {
        modal = this.EventSelectionSimpleConfirm.open(
          '確認',
          'この牛は乾乳後期ですが乾乳を登録しますか？'
        );
      }
      break;

    case 'embryo_recovery':
      if (this.cow.state !== '授精') {
        const template = '<span>この牛は授精されていません。<br><span class="event-name">採卵</span>の登録をつづけますか？</span>';
        modal = this.EventSelectionMultipleChoicesConfirm.open(
          template,
          [
            {text: '登録をつづける', value: 'embryo_recovery', new: true},
            {text: '種付イベントを登録する', value: 'tanetsuke', new: true},
          ]
        );
      }
      break;

    }

    return modal;
  }

  /**
   * this.events のなかで最後のアラート以外のイベントであるか判定
   * @param {number} id イベントのID
   * @return {bool} 最後のアラート以外のイベントであるか
   */

  isLatestNonAlertEvent(id) {
    const events = this.rawEvents.filter((e) => e.eventType !== 'alert');
    return events[0].id === id;
  }

  /**
   * React 版 EventRegister Modal を開きます。
   *
   * @param request.callback モーダルが閉じた際に呼ばれるコールバック
   * @param request.editCowEventId 編集モードの場合、そのイベントのID (optional)
   */
  openReactEventRegister(request) {
    const scope = this.$rootScope.$new();
    scope.cowId = this.cow.cowId;
    scope.editCowEventId = request.editCowEventId;
    scope.farmKind = this.farmKind;
    scope.sharedAccountOperatorName = this.IsMobile ? undefined : this.appConfig.staff.name;
    scope.isMobile = this.IsMobile;
    scope.callerId = this.callerId || this.callerState.state;

    return this.$modal.open({
      animation: true,
      templateUrl: 'mobile/events/react-event-register-modal.html',
      controller: 'ReactEventRegisterModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'event-register-lg',
      scope: scope,
    }).result.then((result) => {
      if (result) {
        request.callback && request.callback(result.label);
        this.refreshEvents();
      }
      this.eventCancel();
    });
  }

  openEventSelectionMobile() {
    if (this.isShowingMobileEventSelection) return;
    this.isShowingMobileEventSelection = true;

    $('main').hide();
    $('header').hide();

    // FIXME: モバイル用の一時的な対応、だれか修正お願いします。
    const scope = this.createMobileEventSelectionScope();

    return this.$modal.open({
      animation: true,
      templateUrl: 'mobile/events/mobile-event-selection-modal.html',
      controller: 'MobileEventSelectionModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'event-register-lg',
      scope: scope,
    }).result.then((result) => {
      if (result === false) {
        $('main').show();
        $('header').show();
        return;
      }

      if (result === 'dysstasia') {
        this.reportDysstasiaMobile();
        $('main').show();
        $('header').show();
        return;
      }

      this.eventType = result;
      this.newEvent(result);
    }).finally(() => {
      this.isShowingMobileEventSelection = false;
    });
  }

  openEventRegisterMobile() {
    $('main').hide();
    $('header').hide();

    // FIXME: モバイル用の一時的な対応、だれか修正お願いします。
    const scope = this.createMobileEventRegisterScope();

    this.$modal.open({
      animation: true,
      templateUrl: 'mobile/events/mobile-event-register-modal.html',
      controller: 'MobileEventRegisterModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      scope: scope,
      size: 'event-register-lg'
    }).result.then((result) => {
      $('main').show();
      $('header').show();

      switch (result) {
      case MobileEventRegisterModalController.RETURN_STATUS.SUBMIT:
        this.ok();
        break;
      case MobileEventRegisterModalController.RETURN_STATUS.CANCEL:
        this.resetState();
        return;
      case MobileEventRegisterModalController.RETURN_STATUS.GO_BACK:
        if (this.isRegistered) {
          this.resetState();
          return;
        }
        this.selectedMedicineList = [];
        this.formData = this.resetFormData();
        this.resetState();
        this.openEventSelectionMobile();
        break;
      }
    });
  }

  updateEventDisplayMap() {
    this.eventDisplayMap = this.createEventDisplayMap(
      this.cow.state,
      this.farmKind,
      this.implementFreshCheckFlg,
      this.isAgentFarm
    );
  }

  addLame() {
    if (this.formData.lames.length >= 4) return;

    this.formData.lames.push({
      lameAffectedPart: '蹄',
      lameAffectedLimb: '',
      lameAffectedPartMedial: false,
      lameAffectedPartLateral: false,
      lameCondition: ''
    });
  }

  deleteLame(index) {
    this.formData.lames.splice(index, 1);
  }

  addOtherDiseaseName() {
    this.formData.otherDiseaseNames.push({
      id: 0
    });
  }

  deleteOtherDiseaseName(index) {
    this.formData.otherDiseaseNames.splice(index, 1);
  }

  getEventDisplayName(eventType) {
    switch (eventType) {
    case 'lame':
      return '跛行・蹄病';
    case 'touta':
      return '出荷';
    default:
      return (eventType in this.eventTypes) ? this.eventTypes[eventType].name : '';
    }
  }

  /**
   * モバイルのイベント選択用画面のscopeを作成する。
   *
   * @return {Object} scope
   */
  createMobileEventSelectionScope() {
    const scope = this.$rootScope.$new();

    scope.cowId = this.cow.cowId;
    scope.eventDisplayMap = this.eventDisplayMap;
    scope.farmKind = this.farmKind;

    return scope;
  }

  /**
   * モバイルのイベント登録用画面のscopeを作成する。
   *
   * @return {Object} scope
   */
  createMobileEventRegisterScope() {
    this.mobileScope = this.$rootScope.$new();
    // 共通
    this.mobileScope.formData = this.formData;
    this.mobileScope.state = this.state;
    this.mobileScope.messages = this.messages;
    this.mobileScope.messagesMap = this.messagesMap;
    this.mobileScope.validate = this.validate.bind(this);
    this.mobileScope.selection = this.selection;
    this.mobileScope.Hours = this.Hours;
    this.mobileScope.Minutes = this.Minutes;
    this.mobileScope.cowGroupOptions = this.cowGroupOptions;
    this.mobileScope.penList = this.penList;
    this.mobileScope.selectBoxFactory = this.selectBoxFactory;
    this.mobileScope.farmKind = this.farmKind;
    this.mobileScope.setFormRef = this.setFormRef.bind(this);
    this.mobileScope.selectedCow = this.cow;
    this.mobileScope.getEventDisplayName = this.getEventDisplayName.bind(this);
    this.mobileScope.invalid = this.invalid;
    this.mobileScope.showDiseaseSelection = this.showDiseaseSelection;
    this.mobileScope.showMoveToGroup = this.showMoveToGroup.bind(this);
    this.mobileScope.showMoveToPen = this.showMoveToPen.bind(this);
    // 治療全般
    this.mobileScope.shouldShowTreatmentDetailWarning = this.shouldShowTreatmentDetailWarning.bind(this);
    this.mobileScope.onUpdateTreatmentDiseaseDate = this.onUpdateTreatmentDiseaseDate.bind(this);
    this.mobileScope.selectedMedicineList = this.selectedMedicineList;
    this.mobileScope.onMedicineListUpdate = this.onMedicineListUpdate.bind(this);
    this.mobileScope.onMedicineParamsUpdate = this.onMedicineParamsUpdate.bind(this);
    this.mobileScope.dateLabelOfMilkWashoutPeriod = this.dateLabelOfMilkWashoutPeriod;
    this.mobileScope.dateLabelOfBeefWashoutPeriod = this.dateLabelOfBeefWashoutPeriod;
    this.mobileScope.onChangeDiseaseContinuation = this.onChangeDiseaseContinuation.bind(this);
    this.mobileScope.shouldHideTreatmentDetail = this.shouldHideTreatmentDetail.bind(this);
    this.mobileScope.shouldShowMilkWashoutPeriod = this.shouldShowMilkWashoutPeriod.bind(this);
    this.mobileScope.shouldShowMeatWashoutPeriod = this.shouldShowMeatWashoutPeriod.bind(this);

    // 分娩
    this.mobileScope.changeMoveToCowGroup = this.changeMoveToCowGroup.bind(this);
    this.mobileScope.changeBirthState = this.changeBirthState.bind(this);
    this.mobileScope.checkSelectedBirthState = this.checkSelectedBirthState.bind(this);
    this.mobileScope.calvedDateTimeDisabled = this.calvedDateTimeDisabled;
    this.mobileScope.shouldShowCalfRegistered = this.shouldShowCalfRegistered.bind(this);
    this.mobileScope.shouldShowCalfRegisteredParams = this.shouldShowCalfRegisteredParams.bind(this);
    this.mobileScope.useCowNoInput = this.useCowNoInput;
    this.mobileScope.onChangeChildNumber = this.onChangeChildNumber.bind(this);
    this.mobileScope.onChangeBirthResult = this.onChangeBirthResult.bind(this);
    this.mobileScope.onClickCalfRegistered = this.onClickCalfRegistered.bind(this);
    this.mobileScope.onChangeCalfCowUid = this.onChangeCalfCowUid.bind(this);
    this.mobileScope.onBlurCalfCowUid = this.onBlurCalfCowUid.bind(this);
    // 牛群移動
    this.mobileScope.onUpdateMoveToCowGroupId = this.onUpdateMoveToCowGroupId.bind(this);
    // ホルモンプログラム
    this.mobileScope.changeHormoneProgramName = this.changeHormoneProgramName.bind(this);
    this.mobileScope.hormoneProgramNameList = this.hormoneProgramNameList;
    this.mobileScope.setHormoneCode = this.setHormoneCode.bind(this);
    this.mobileScope.targetHormoneList = this.targetHormoneList;
    this.mobileScope.hormoneNameDisabled = this.hormoneNameDisabled;
    // 蹄病・跛行
    this.mobileScope.deleteLame = this.deleteLame.bind(this);
    this.mobileScope.shouldShowLameDiagnosisDetail = this.shouldShowLameDiagnosisDetail.bind(this);
    this.mobileScope.onLameAffectedPartChange = this.onLameAffectedPartChange.bind(this);
    this.mobileScope.deleteLame = this.deleteLame.bind(this);
    this.mobileScope.shouldShowAddLame = this.shouldShowAddLame.bind(this);
    this.mobileScope.addLame = this.addLame.bind(this);
    // 乳房炎
    this.mobileScope.changeCheck = this.changeCheck.bind(this);
    this.mobileScope.mastitisScores = this.mastitisScores;
    this.mobileScope.mastitisCowGroupOptions = this.mastitisCowGroupOptions;
    // その他
    this.mobileScope.addOtherDiseaseName = this.addOtherDiseaseName.bind(this);
    this.mobileScope.deleteOtherDiseaseName = this.deleteOtherDiseaseName.bind(this);
    this.mobileScope.shouldShowAddOtherDiseaseName = this.shouldShowAddOtherDiseaseName.bind(this);
    // 体重測定
    this.mobileScope.onUpdateMeasurementDate = this.onUpdateMeasurementDate.bind(this);
    this.mobileScope.updateBreedingDaysAndDg = this.updateBreedingDaysAndDg.bind(this);

    // 妊娠鑑定
    this.mobileScope.selectBreedingDate = this.selectBreedingDate.bind(this);
    this.mobileScope.changeBreedingId = this.changeBreedingId.bind(this);
    this.mobileScope.targetBreedingList = this.targetBreedingList;
    this.mobileScope.ninshinkantei = {};
    this.mobileScope.ninshinkantei.selectedBreedingId = this.selectedBreedingId;
    this.mobileScope.ninshinkantei.selectSpermInfo = this.selectSpermInfo;
    this.mobileScope.ninshinkantei.selectBreedingMethod = this.selectBreedingMethod;
    // 種付
    this.mobileScope.changeTanetsukeMethod = this.changeTanetsukeMethod.bind(this);
    this.mobileScope.onSelectedBullInfoUpdated = this.onSelectedBullInfoUpdated.bind(this);
    this.mobileScope.tanetsukeBulls = this.tanetsukeBulls;
    this.mobileScope.inseminationCodeList = this.inseminationCodeList;
    this.mobileScope.onChangeEtInputType = this.onChangeEtInputType.bind(this);
    this.mobileScope.setInseminationCode = this.setInseminationCode.bind(this);
    this.mobileScope.shouldShowBredForEmbryoRecovery = this.shouldShowBredForEmbryoRecovery.bind(this);
    this.mobileScope.selectedBullInfo = this.selectedBullInfo;
    // 発情
    this.mobileScope.shouldShowPlannedBredAt = this.shouldShowPlannedBredAt.bind(this);
    this.mobileScope.onPlannedBredMethodChange = this.onPlannedBredMethodChange.bind(this);
    this.mobileScope.selection.plannedBredMethod = this.selection.plannedBredMethod;

    // 採卵
    this.mobileScope.setIsValidatorDisabled = this.setIsValidatorDisabled.bind(this);
    this.mobileScope.canMasterEmbryoRegistration = this.canMasterEmbryoRegistration;
    this.mobileScope.onChangeEmbryoRecoveryRank = this.onChangeEmbryoRecoveryRank.bind(this);
    this.mobileScope.shouldShowEmbryoRecoveryWarning = this.shouldShowEmbryoRecoveryWarning.bind(this);

    // 出荷
    this.mobileScope.existsProducingAreas = this.existsProducingAreas;
    this.mobileScope.existsProducingFarmNames = this.existsProducingFarmNames;

    // 作業メモ
    this.mobileScope.showWorkNoteTypeSelection = this.showWorkNoteTypeSelection;

    return this.mobileScope;
  }

  /**
   * 送信(#ok)ボタンのdisabledを設定する
   *
   * @param {boolean} disabled true or false
   */
  disableSubmit(disabled) {
    this.invalid = disabled;
    if (this.IsMobile && this.mobileScope) {
      this.mobileScope.invalid = this.invalid;
    }
  }

  /**
   * その他病名・症状が空の場合はその他重症度を空文字にする
   * [対象イベント]
   * injury(外傷), infect(感染症), otherDifficulty(その他)
   */
  clearDifficultiesOther() {
    if (!this.formData.otherDiseaseName) {
      this.formData.difficultiesOther = '';
    }
  }

  // FIXME: EventState.setEmbryo を使うようにする
  setEmbryo() {
    const embryo = this.embryoMasters.find((embryoMaster) => embryoMaster.id === this.formData['masterEmbryoId']);

    if (embryo) {
      this.formData['embryoName'] = embryo.name;
      this.formData['masterEmbryoId'] = embryo.id;
      this.formData['masterSpermId'] = embryo.masterSpermId;
      this.formData['donorName'] = embryo.donorName;
      this.formData['donorRegistrationNo'] = embryo.donorRegistrationNo;
      this.formData['donorCowUid'] = embryo.donorCowUid;
      this.formData['donorBreed'] = embryo.donorBreed;
      this.formData['donorBreedingValue'] = embryo.donorBreedingValue;
      this.formData['fatherNameOfDonor'] = embryo.fatherNameOfDonor;
      this.formData['fatherRegistrationNoOfDonor'] = embryo.fatherRegistrationNoOfDonor;
      this.formData['motherNameOfDonor'] = embryo.motherNameOfDonor;
      this.formData['motherRegistrationNoOfDonor'] = embryo.motherRegistrationNoOfDonor;
      this.formData['motherBreedOfDonor'] = embryo.motherBreedOfDonor;
      this.formData['maternalGrandfatherNameOfDonor'] = embryo.maternalGrandfatherNameOfDonor;
      this.formData['maternalGrandfatherRegistrationNoOfDonor'] = embryo.maternalGrandfatherRegistrationNoOfDonor;
    }
  }

  resetEmbryo() {
    this.formData['embryoName'] = '';
    this.formData['masterEmbryoId'] = '';
    this.formData['donorName'] = '';
    this.formData['donorRegistrationNo'] = '';
    this.formData['donorCowUid'] = '';
    this.formData['donorBreed'] = '';
    this.formData['donorBreedingValue'] = '';
    this.formData['fatherNameOfDonor'] = '';
    this.formData['fatherRegistrationNoOfDonor'] = '';
    this.formData['motherNameOfDonor'] = '';
    this.formData['motherRegistrationNoOfDonor'] = '';
    this.formData['motherBreedOfDonor'] = '';
    this.formData['maternalGrandfatherNameOfDonor'] = '';
    this.formData['maternalGrandfatherRegistrationNoOfDonor'] = '';
    this.formData['embryoType'] = '';
    this.formData['embryoCertificateNo'] = '';
  }

  onClickDiseaseConfig() {
    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: 'dialog/disease/index.html',
      controller: 'DiseaseIndexDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'select-standard'
    });

    modalInstance.result.then((result) => {
      this.context.masterDiseaseAPI().available().then((res) => {
        this.selection['diseaseDefault'] = this.generateDiseaseSelection(res.data);
        this.showDiseaseSelection = this.selection.diseaseDefault.length >= 2;
        if (this.showDiseaseSelection) {
          this.selection['disease'] = this.adjustDiseaseSelection(this.selection['diseaseDefault'], this.formData.otherDiseaseName);
        }
      });
    });
  }

  generateDiseaseSelection(diseases) {
    const result = diseases.filter((d) => d.visible).map((d, index) => {
      return {
        key: d.id,
        label: `${index + 1}: ${d.name}`
      };
    });
    result.unshift({key: 0, label: ''});
    return result;
  }

  adjustDiseaseSelection(origin, input) {
    const selection = angular.copy(origin);
    if (input) {
      const registered = selection.some((option, index) => {
        const label = `${index}: ${input}`;
        return option.label === label;
      });
      if (!registered) {
        selection.push({key: -1, label: `?: ${input}`});
      }
    }
    return selection;
  }

  generateWorkNoteTypeSelection(masters) {
    const result = masters.filter((m) => m.visible).map((m, index) => {
      return {
        key: m.id,
        label: `${index + 1}: ${m.name}`
      };
    });
    result.unshift({key: 0, label: ''});
    return result;
  }

  setInseminationCode() {
    const occurredAt = this.addTimeToDate(this.formData.occurredAt);
    this.formData['inseminationCode'] = this.formData['inseminationCode'] || HormoneProgram.currentHormoneProgramName(this.cow, occurredAt);
  }

  updateLatestEventYear() {
    if (!this.events) return;

    const filteredEvents = this.events.filter((e) => this.filterResults.includes(e.eventName));

    if (filteredEvents.length > 0) {
      this.$scope.latestEventYear = new Date(filteredEvents[0].occurredAt).getFullYear();
    } else {
      this.$scope.latestEventYear = null;
    }
  }

  /**
   * 治療日がクリアされた際の治療詳細のリセット処理
   */
  resetTreatmentDetail() {
    this.selectedMedicineList.length = 0;
    this.setMedicationSummary();

    this.formData.selectedHour = '00';
    this.formData.selectedMinute = '00';
  }

  shouldShowTreatmentDetailWarning() {
    return this.selectedEvent.treatmentEvent && !this.formData.treatmentDiseaseDate;
  }

  shouldHideTreatmentDetail() {
    if (this.formData.eventType === 'vaccine') {
      return false;
    }

    return this.formData.treatmentDiseaseDate ? false : true;
  }

  shouldShowMilkWashoutPeriod() {
    return !this.shouldHideTreatmentDetail() && this.farmKind === '乳用';
  }

  shouldShowMeatWashoutPeriod() {
    return !this.shouldHideTreatmentDetail();
  }

  shouldShowCalfRegistered(index) {
    if (!this.formData.calfs) return false;

    switch (this.farm.data.calfManagement) {
    case 'always':
    case 'never':
      return false;
    case 'calved':
      return this.formData.calfs[index].birthResult === '出産';
    }
  }

  shouldShowCalfRegisteredParams(index) {
    if (!this.formData.calfs) return false;

    return this.formData.calfs[index].registered;
  }

  shouldShowFilterItem(price) {
    return price && this.canEditFilterItem;
  }

  shouldShowEmbryoRecoveryWarning() {
    return this.formData.eventType === 'embryo_recovery' && !this.canMasterEmbryoRegistration;
  }

  confirmEmbryoRecovery(formData, operation) {
    this.disableSubmit(true);

    return this.CowEventsService.confirm({
      'eventType': 'embryo_recovery',
      'operationType': operation,
      'cowId': formData.cowId,
      'cowEventId': formData.id,
      'masterSpermId': formData.masterSpermId,
      'embryoMasterRegistered': formData.embryoMasterRegistered === 'true'
    }).then((resp) => {
      if (!resp.data) return false;
      if (resp.data.valid) {
        return true;
      } else {
        const messages = resp.data.message.split(/<br>/gi);
        switch (resp.data.nextAction) {
        default:
        case 'SHOW_CONFIRMATION_DIALOG':
          const modal =
          this.modalDialogFactory.showYesNoConfirm({
            title: '確認',
            text1: messages[0] || '',
            text2: messages[1] || '',
            text3: messages[2] || '',
            no: true, yes: true
          });

          return modal.result.then((r) => {
            if (r) {
              return true;
            } else {
              return false;
            }
          }).catch((r) => {
            return false;
          });
        }
      }
    }).catch((e) => console.error('confirmEbryoRecovery Error: ', e))
      .finally(() => this.disableSubmit(false));
  }

  isNumeric(value) {
    return StringUtil.isNumeric(value);
  }

  isDysstasiaAlert(r) {
    return r.alertType === 'dysstasia';
  }

  isCalvingAlert(r) {
    return r.alertType === 'calving';
  }

  isEartagDroppedAlert(r) {
    return r.alertType === 'eartagDropped';
  }

  setIsValidatorDisabled(value) {
    this.isValidatorDisabled = value;
  }

  addUserEntryValueToSelection(selection, cowEvent) {
    [
      {selectionItem: 'producingAreas', cowEventItem: 'producingArea'},
      {selectionItem: 'producingFarmNames', cowEventItem: 'producingFarmName'}
    ].forEach((obj) => {
      if (!selection[obj.selectionItem].length) return;
      if (!cowEvent[obj.cowEventItem]) return;

      const optionExisted = selection[obj.selectionItem].some((option) => option.value === cowEvent[obj.cowEventItem]);
      if (optionExisted) return;

      const option = {label: cowEvent[obj.cowEventItem], value: cowEvent[obj.cowEventItem]};
      selection[obj.selectionItem].splice(1, 0, option);
    });
  }

  goToCowDetail(cowId) {
    const to = this.IsMobile ? 'mobileCowDetail' : 'cowDetail';

    this.$state.go(to, {
      cowId: cowId,
      caller: {
        state: to
      }
    });
  }

  fetchHeadEvents() {
    if (!this.rawEvents) return;

    const filteredEvents = this.selectByName(this.rawEvents);

    if (filteredEvents.length <= this.eventFetchSize) {
      this.events = filteredEvents;
    } else {
      this.events = filteredEvents.slice(0, this.eventFetchSize);
    }
  }

  fetchMore() {
    if (!this.rawEvents) return;

    const filteredEvents = this.selectByName(this.rawEvents);
    const displayedEventsSize = this.events.length;
    let appendEvents;

    if ((filteredEvents.length - displayedEventsSize) <= this.eventFetchSize) {
      appendEvents = filteredEvents.slice(displayedEventsSize);
    } else {
      appendEvents = filteredEvents.slice(displayedEventsSize, displayedEventsSize + this.eventFetchSize);
    }

    appendEvents.forEach((e) => {
      this.events.push(e);
    });
  }

  $onChanges(doFetchEvent) {
    this.fetchMore();
  }

  showMoveToGroup() {
    return this.farm && this.farm.isMilk();
  }

  showMoveToPen() {
    return this.showMoveToGroup() && this.penList.length > 0 && this.penList[0].value !== '';
  }

  onChangeNumberInput(record, field) {
    record[field] = StringUtil.toHalfWidthCharacters(record[field]);
    this.validate();
  }
}

/**
 * 預託牛を表示する場合は、isDepositにtrueを設定する
 */
function cowTimelineComponent() {
  return {
    templateUrl: 'timeline/cow/timeline.html',
    controller: CowTimelineController,
    bindings: {
      cow: '=',
      isRegisterEvent: '=',
      qwert: '=',
      isDeposit: '=',
      callerId: '=',
      onItemUpdated: '&',
      activeTab: '=',
      doFetchEvent: '<',
      enablePager: '=?'
    }
  };
}

app.controller('CowTimelineController', CowTimelineController);
app.component('cowTimeline', cowTimelineComponent());
