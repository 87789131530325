'use strict';
let app = angular.module('app', [
  'ngMessages',
  'ngSanitize',
  'ui.router',
  'ui.bootstrap',
  'fixed.table.header',
  'blockUI',
  'ui.select',
  'toastr',
  'file-model',
  'ui.grid',
  'angular-carousel',
  'wu.masonry'
]);

// underscore mixin underscore.string
_.mixin(s.exports());

// eslint-disable-next-line no-unused-vars
let globalTestMode = false;

app.userAgent = (function() {
  let userAgent = window.navigator.userAgent.toLowerCase();
  let result = {};
  if (userAgent.indexOf('opera') !== -1) {
    result.browser = 'opera';
  } else if (userAgent.indexOf('msie') !== -1) {
    result.browser = 'ie';
    if (appVersion.indexOf('msie 6.') !== -1) {
      result.version = 'ie6';
    } else if (appVersion.indexOf('msie 7.') !== -1) {
      result.version = 'ie7';
    } else if (appVersion.indexOf('msie 8.') !== -1) {
      result.version = 'ie8';
    } else if (appVersion.indexOf('msie 9.') !== -1) {
      result.version = 'ie9';
    } else if (appVersion.indexOf('msie 10.') !== -1) {
      result.version = 'ie10';
    }
  } else if (userAgent.indexOf('trident/7.') !== -1) {
    result.browser = 'ie';
    result.version = 'ie11';
  } else if (userAgent.indexOf('chrome') !== -1 && userAgent.indexOf('edge') === -1) {
    result.browser = 'chrome';
  } else if (userAgent.indexOf('chrome') !== -1 && userAgent.indexOf('edge') !== -1) {
    result.browser = 'edge';
  } else if (userAgent.indexOf('firefox') !== -1) {
    result.browser = 'firefox';
  } else if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('edge') === -1) {
    result.browser = 'safari';
  } else if (userAgent.indexOf('gecko') !== -1) {
    result.browser = 'gecko';
  }
  return result;
})();
