// eslint-disable-next-line no-unused-vars
class UserDefinedOption {
  static get definitions() {
    return [
      {
        name: 'inseminators',
        label: '授精担当者',
        allowBlank: true
      }, {
        name: 'pregnancyAppraisers',
        label: '妊娠鑑定担当者',
        allowBlank: true
      }, {
        name: 'treatmentPersons',
        label: '治療担当者',
        allowBlank: true
      }, {
        name: 'vaccineTimings',
        label: 'ワクチンタイミングリスト',
        allowBlank: true
      }, {
        name: 'judgePregnantTimings',
        label: '妊娠鑑定タイミングリスト',
        allowBlank: true
      }, {
        name: 'producingAreas',
        label: '生産地リスト',
        allowBlank: true
      }, {
        name: 'producingFarmNames',
        label: '生産農場名リスト',
        allowBlank: true
      }, {
        name: 'raisingFarmNames',
        label: '育成農場名リスト',
        allowBlank: true
      }, {
        name: 'transporters',
        label: '運送会社リスト',
        allowBlank: true
      }, {
        name: 'buyers',
        label: '販売先リスト',
        allowBlank: true
      }, {
        name: 'introduceStates',
        label: '導入時状態',
        allowBlank: true
      }, {
        name: 'birthStates',
        label: '出生時状態',
        allowBlank: false
      }, {
        name: 'otherReproductionWorks',
        label: 'その他処置内容',
        allowBlank: true
      }
    ];
  }
}
