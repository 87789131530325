function chartActivityStackedBarComponent() {
  'ngInject';
  return {
    controller: 'ActivityStackedBarChartComponentController',
    controllerAs: 'ctrl',
    templateUrl: 'components/chart/activity/stacked-bar/index.html',
    transclude: true,
    bindings: {
      cow: '<',
      height: '<',
      paddingTop: '<',
      paddingBottom: '<',
      paddingRight: '<',
      paddingLeft: '<',
      disableHourlyTick: '<',
      defaultActivityPeriod: '<',
      defaultHiddenLegendIds: '<',
      onChangePeriod: '&',
      onChangeHiddenLegendIds: '&'
    }
  };
}
app.component('chartActivityStackedBar', chartActivityStackedBarComponent());
