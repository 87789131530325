class HeadageDetailsController {
  constructor(
    $state,
    $timeout,
    $rootScope,
    ViewStateAPI,
    $stateParams,
    SessionCache,
    CowHeadageAPI,
    FileUtilService,
    SideBoardService
  ) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.ViewStateAPI = ViewStateAPI;
    this.CowHeadageAPI = CowHeadageAPI;
    this.FileUtilService = FileUtilService;
    this.SideBoardService = SideBoardService;

    const farm = SessionCache.farm();

    this.context = {
      category: $stateParams.category,
      subCategory: $stateParams.subCategory,
      item: $stateParams.item || '',
      isDairy: farm.isDairy(),
      isFattening: farm.isFattening()
    };

    this.currentColumnSort = $stateParams.currentColumnSort || null;

    this.init();
  }

  init() {
    this.heading = HeadageSummaryDefinition.getLabel(this.context);
    this.items = HeadageDetailsItem.items(this.context);
    this.columnMap = this.columnMap();
    this.isPrinting = false;

    this.index();
  }

  columnMap() {
    return this.items.reduce((acc, cur) => {
      acc[cur.itemId] = cur;
      acc[cur.itemId].columnId = cur.itemId;

      return acc;
    }, {});
  }

  index() {
    this.CowHeadageAPI.details(this.context).then((res) => {
      const generator = new HeadageDetailsGenerator();

      this.cows = HeadageItemDefinition.toDisplayFormat(this.items, res.data);
      this.rawCows = [].concat(this.cows);

      this.matrixData = this.generateMatrixData();

      this.uiGrid = {
        appScopeProvider: this,
        columnDefs: generator.generateColumnDefs(this.items),
        rowTemplate: generator.generateRowTemplate(),
        data: this.cows,
        onRegisterApi: (gridApi) => {
          this.gridApi = gridApi;
        }
      };

      if (this.currentColumnSort) {
        const {column, isAscending} = this.currentColumnSort;
        CustomlistSort.sort(this.cows, column, isAscending);
      }
    });
  }

  goToDetails(cowId) {
    const params = {
      cowId,
      caller: {
        state: 'headage-details',
        name: this.heading,
        cowIds: this.cows.map((cow) => cow.cowId),
        params: {
          name: `頭数内訳リスト ${this.heading}`,
          category: this.context.category,
          subCategory: this.context.subCategory,
          item: this.context.item,
          currentColumnSort: this.currentColumnSort
        }
      }
    };

    this.$state.go('cowDetail', params);
  }

  updateCurrentCow(cow = null) {
    if (cow) {
      this.SideBoardService.setState('opened', false);
    }

    return this.currentCow = cow;
  }

  styleLegacyHeader(column) {
    return {
      width: column.width
    };
  }

  cowBoardState() {
    return this.currentCow ? 'cow-board-opened' : '';
  }

  openCowBoard(currentCowId) {
    return currentCowId ? 'open' : '';
  }

  onClickExport() {
    const headers = this.items.map((column) => {
      return column.label;
    });
    const matrixData = this.generateMatrixData(true);
    const formatAsExport = [headers].concat(matrixData).join('\n');

    this.FileUtilService.exportAsCsv(formatAsExport, `${this.heading}.csv`);
  }

  onClickPrint() {
    this.ViewStateAPI.create('print-in-headage-details', 'headage-details');

    this.$rootScope.isPrinting = true;
    this.isPrinting = true;

    this.$timeout(() => {
      print();

      this.$rootScope.isPrinting = false;
      this.isPrinting = false;
    });
  }

  generateMatrixData(isExport = false) {
    return this.cows.map((cow) => {
      return this.items.map((column) => {
        if (column.itemId === 'cowLabels') {
          return cow.cowLabels.join('、');
        } else if (column.itemId === 'medicationSummary') {
          return cow.medicationSummary.replace(/<b>/g, '')
            .replace(/<\/b>/g, '')
            .replace(/<br>/g, '、');
        }

        if (isExport) {
          if (column.itemId === 'cowUid') {
            return Cow.unformatCowUid(cow.cowUid);
          }
        }

        return cow[column.itemId];
      });
    });
  }

  onClickColumnHeader(itemId, gridCol) {
    if (CustomlistSort.isUnsortable(itemId)) return;

    const column = this.columnMap[itemId];

    if (this.currentColumnSort && this.currentColumnSort.column.itemId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        CustomlistSort.sort(this.cows, column, false);
        this.currentColumnSort = {column, isAscending: false};
      } else {
        this.currentColumnSort = null;
        this.cows = [].concat(this.rawCows);
        this.uiGrid.data = this.cows;
      }
    } else {
      CustomlistSort.sort(this.cows, column, true);
      this.currentColumnSort = {column, isAscending: true};
    }
  }

  classHeaderCell(gridCol) {
    if (this.currentColumnSort && this.currentColumnSort.column.itemId === gridCol.field) {
      if (this.currentColumnSort.isAscending) {
        return 'ui-grid-sorted-asc';
      } else {
        return 'ui-grid-sorted-desc';
      }
    }
  }

  classCell(cowId) {
    if (!this.currentCow) return;
    if (cowId !== this.currentCow.cowId) return;

    return 'ui-grid-row-cow-board-opened';
  }
}

app.controller('HeadageDetailsController', HeadageDetailsController);
