/**
 * 起立困難アラート設定の表示用コンポーネント
 *
 * ex.
 *  <alert-dysstasia-config-view
 *    thresholds="thresholds"
 *    threshold-options="thresholdOptions"
 *    on-edit-click="onEditClick()">
 *  </alert-dysstasia-config-view>
 *
 * プロパティ:
 *  @param {Array<Object>} thresholds 閾値オブジェクト配列
 *  @param {Array<Object>} thresholdOptions オプション閾値オブジェクト配列
 *  @param {Function} onEditClick 編集クリック時のイベント
 */
class AlertDysstasiaConfigViewController {
  constructor() {
    'ngInject';
  }

  joinedBreeds(breeds) {
    return breeds.join('、');
  }
}

function alertDysstasiaConfigViewComponent() {
  return {
    templateUrl: 'menu/setting/alert/dysstasia-config/view.html',
    controller: AlertDysstasiaConfigViewController,
    bindings: {
      thresholds: '<',
      thresholdOptions: '<',
      onEditClick: '&'
    }
  };
}

app.component('alertDysstasiaConfigView', alertDysstasiaConfigViewComponent());
