app.controller('dialogController', function($scope, $modalInstance, params) {
  'ngInject';
  let _scopeKeys = ['title', 'cancelable', 'determinable', 'text1', 'text2', 'text3', 'texts', 'htmlMessage', 'template', 'choices', 'no', 'yes', 'isConfirm', 'isSuccessive'];
  _.each(_scopeKeys, (key) => {
    if (params[key]) $scope[key] = params[key];
  });

  $scope.ok = () => {
    if (params.ok && typeof params.ok === 'function') params.ok();
    $modalInstance.close(true);
  };

  $scope.cancel = () => {
    if (params.cancel && typeof params.cancel === 'function') params.cancel();
    $modalInstance.dismiss('cancel');
  };

  $scope.choose = (value) => {
    if (params.choose && typeof params.choose === 'function') params.choose();
    $modalInstance.close(value);
  };

  $scope.nofunc = () => {
    if (params.nofunc && typeof params.nofunc === 'function') params.nofunc();
    $modalInstance.close(false);
  };

  $scope.yesfunc = () => {
    if (params.yesfunc && typeof params.yesfunc === 'function') params.yesfunc();
    $modalInstance.close(true);
  };

});
