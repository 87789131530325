class MasterEmbryoController {
  constructor(
    MasterEmbryoAPI,
    uiGridConstants,
    $modal,
    SortOrderEditDialog,
    CowService,
    SessionCache
  ) {
    'ngInject';
    this.MasterEmbryoAPI = MasterEmbryoAPI;
    this.uiGridConstants = uiGridConstants;
    this.$modal = $modal;
    this.SortOrderEditDialog = SortOrderEditDialog;
    this.CowService = CowService;
    this.farm = SessionCache.farm();

    MasterBaseController.bind(this);

    this.initialize();
  }

  initialize() {
    this.title = '受精卵';
    this.showAllLabel = '全ての受精卵を表示する';
    this.filterInstruction = '受精卵名で絞り込む';
    this.filterItemKey = 'name';

    this.CowService.getCowNoList().then((res) => {
      this.cowNoList = res.data;
      this.showAll = false;
      this.showGrid = false;
      this.setUiGrid();
      this.index();
    }).catch((err) => console.error(err));
  }

  setUiGrid() {
    const columns = [
      {columnId: 'name', label: '受精卵名称', width: 230},
      {columnId: 'fatherNameOfDonor', label: '供卵父牛', width: 100},
      {columnId: 'maternalGrandfatherNameOfDonor', label: '供卵母父牛', width: 100},
      {columnId: 'visible', label: '表示対象', width: 70},
      {columnId: 'action', label: '操作', width: 80},
    ];

    const options = {
      clickRowEvent: 'onClickRow',
      cellTemplates: {
        visible: `<div ng-if="row.entity.visible" class="align-center"><i class="icon-s-check-box-outlined"></i></div>`,
        action: `
          <button ng-click="grid.appScope.update(row.entity)"><i class="icon-s-edit"></i></button>
          <button ng-if="!row.entity.used" ng-click="grid.appScope.delete(row.entity)"><i class="icon-s-delete margin-left-15"></i></button>
          `
      }
    };

    this.uiGrid = new UiGridGenerator().generate(this, columns, options);
  }

  index() {
    this.MasterEmbryoAPI.index().then((res) => {
      this.rawRecords = res.data;
      this.records = this.rawRecords.filter((r) => r.visible || this.showAll);
      this.uiGrid.data = this.records;
      this.showGrid = true;
      this.enableUpdateSortOrder = this.rawRecords.filter((r) => r.visible).length >= 2;
    });
  }

  create() {
    this.openEditView(ViewMode.CREATE, '受精卵の新規登録', null);
  }

  update(record) {
    this.openEditView(ViewMode.UPDATE, '受精卵の更新', record);
  }

  delete(record) {
    this.openEditView(ViewMode.DELETE, '受精卵の削除', record);
  }

  openEditView(viewMode, title, model) {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/master/embryo/edit.html',
      controller: 'MasterEmbryoEditController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            title: title,
            model: model,
            viewMode: viewMode,
            cowNoList: this.cowNoList,
          };
        }
      }
    }).result.then(() => {
      this.showAll = false;
      this.filterCondition = '';
      this.index();
    }, () => {
      // do nothing
    });
  }

  updateSortOrder() {
    const items = this.rawRecords.filter((r) => r.visible)
      .map((r) => {
        return {id: r.id, name: r.name};
      });
    const updateFunction = this.MasterEmbryoAPI.updateSortOrder.bind(this.MasterEmbryoAPI);

    this.SortOrderEditDialog.open(
      '受精卵の表示順設定',
      '受精卵名称',
      items,
      updateFunction
    ).then((result) => {
      if (result.executed) {
        this.index();
      }
    });
  }

  toggleShowAll() {
    this.records = this.rawRecords.filter((r) => r.visible || this.showAll);
    this.uiGrid.data = this.records;
  }
}

app.controller('MasterEmbryoController', MasterEmbryoController);
