/**
 * 牛群一覧表のコラム名
 * 牧場種類と牛群属性によって表示が変わる
 *
 * 乳牛牧場 = milk
 * 肉牛牧場 = beef
 *
 * 育成牛群 : calfGroup && calfDefaultGroup
 * 繁殖牛群 : reproductionGroup
 * 肥育牛群 : fatteningGroup
 * 出荷へい死牛群 : cullingGroup && cullingDefaultGroup
 * 搾乳牛群 : milkingGroup
 * 乾乳牛群 : dryGroup
 *
 * data_model_name: {
 *   牧場種類: {
 *    牛群: { boolean }
 *   },
 *    headerText: { string }
 * }
 */

/**
 * 牛群種別ごとのON/OFF設定デフォルト値
 */
const milkDefault = {
  reproductionGroup: true,
  milkingGroup: true,
  dryGroup: true,
  cullingGroup: true,
  cullingDefaultGroup: true
};

const meatDefault = {
  calfDefaultGroup: true,
  calfGroup: true,
  fatteningGroup: true,
  cullingGroup: true,
  cullingDefaultGroup: true
};

app.constant('cowListTableHeaders', {
  birthNumber: {
    milk: milkDefault,
    meat: {
      reproductionGroup: true,
    },
    headerText: '産次数',
    sortOrderTypes: [
      {
        label: '産次数',
        value: 'birthNumber',
      },
    ],
  },
  latestCalvingDate: {
    milk: milkDefault,
    meat: {
      reproductionGroup: true,
    },
    headerText: '分娩日',
    sortOrderTypes: [
      {
        label: '分娩日',
        value: 'latestCalvingDate',
      },
    ],
  },
  latestBcsDate: {
    milk: milkDefault,
    meat: {
      reproductionGroup: true,
    },
    headerText: 'BCS確認日<br/>(BCS)',
    sortOrderTypes: [
      {
        label: 'BCS確認日',
        value: 'latestBcsDate',
      },
      {
        label: 'BCS',
        value: 'latestBcs',
      },
    ],
  },
  latestPregnancyDate: {
    milk: milkDefault,
    meat: {
      reproductionGroup: true,
    },
    headerText: '受胎日',
    sortOrderTypes: [
      {
        label: '受胎日',
        value: 'latestPregnancyDate',
      },
    ],
  },
  latestJudgePregnantDate: {
    milk: milkDefault,
    meat: {
      reproductionGroup: true,
    },
    headerText: '妊娠鑑定日',
    sortOrderTypes: [
      {
        label: '妊娠鑑定日',
        value: 'latestJudgePregnantDate',
      },
    ],
  },
  latestDryPreviousPeriodStartDate: {
    milk: milkDefault,
    meat: {
      reproductionGroup: false,
    },
    headerText: '乾乳開始日',
    sortOrderTypes: [
      {
        label: '乾乳開始日',
        value: 'latestDryPreviousPeriodStartDate',
      },
    ],
  },
  calvingInterval: {
    milk: milkDefault,
    meat: {
      reproductionGroup: true,
    },
    headerText: '分娩<br>間隔',
    sortOrderTypes: [
      {
        label: '分娩間隔',
        value: 'calvingInterval',
      },
    ],
  },
  latestFertilizationDate: {
    milk: milkDefault,
    meat: {
      reproductionGroup: true,
    },
    headerText: '最終授精日',
    sortOrderTypes: [
      {
        label: '最終授精日',
        value: 'latestFertilizationDate',
      },
    ],
  },
  latestBreedingCount: {
    milk: milkDefault,
    meat: {
      reproductionGroup: true,
    },
    headerText: '授精<br>回数',
    sortOrderTypes: []
  },
  latestMasterSpermNo: {
    milk: milkDefault,
    meat: {
      reproductionGroup: true,
    },
    headerText: '精液',
    sortOrderTypes: [],
  },
  expectedDryDate: {
    milk: milkDefault,
    meat: {},
    headerText: '乾乳予定日',
    sortOrderTypes: [
      {
        label: '乾乳予定日',
        value: 'expectedDryDate',
      },
    ],
  },
  expectedCalvingDate: {
    milk: milkDefault,
    meat: {
      reproductionGroup: true,
    },
    headerText: '分娩予定日',
    sortOrderTypes: [
      {
        label: '分娩予定日',
        value: 'expectedCalvingDate',
      },
    ],
  },
  calfNo: {
    milk: {
      calfDefaultGroup: true,
      calfGroup: true,
    },
    meat: {
      calfDefaultGroup: true,
      calfGroup: true,
    },
    headerText: '子牛番号',
    sortOrderTypes: [],
  },
  motherCowNo: {
    milk: {
      calfDefaultGroup: true,
      calfGroup: true,
    },
    meat: {
      calfDefaultGroup: true,
      calfGroup: true,
    },
    headerText: '母牛番号',
    sortOrderTypes: [],
  },
  monthAge: {
    milk: {
      calfDefaultGroup: true,
      calfGroup: true,
      fatteningGroup: true,
    },
    meat: meatDefault,
    headerText: '月齢',
    sortOrderTypes: [
      {
        label: '月齢',
        value: 'monthAge',
      },
    ],
  },
  dateSinceIntroduced: {
    milk: {
      calfDefaultGroup: true,
      calfGroup: true,
      fatteningGroup: true,
    },
    meat: meatDefault,
    headerText: '導入後日数',
    sortOrderTypes: [
      {
        label: '導入後日数',
        value: 'dateSinceIntroducedForSort',
      },
    ],
  },
  introduceDate: {
    milk: {
      calfDefaultGroup: true,
      calfGroup: true,
    },
    meat: meatDefault,
    headerText: '導入日',
    sortOrderTypes: [
      {
        label: '導入日',
        value: 'introduceDate',
      },
    ],
  },
  breed: {
    milk: {
      calfDefaultGroup: true,
      calfGroup: true,
    },
    meat: meatDefault,
    headerText: '品種',
    sortOrderTypes: [],
  },
  gender: {
    milk: {
      calfDefaultGroup: true,
      calfGroup: true,
    },
    meat: meatDefault,
    headerText: '性別',
    sortOrderTypes: [],
  },
  fatherName: {
    milk: {
      calfDefaultGroup: false,
      calfGroup: false,
    },
    meat: meatDefault,
    headerText: '父親の名前',
    sortOrderTypes: [],
  },
  maternalGrandfatherName: {
    milk: {
      calfDefaultGroup: false,
      calfGroup: false,
    },
    meat: meatDefault,
    headerText: '母父の名前',
    sortOrderTypes: [],
  },
  maternalGreatGrandfatherName: {
    milk: {
      calfDefaultGroup: false,
      calfGroup: false,
    },
    meat: meatDefault,
    headerText: '母母父の名前',
    sortOrderTypes: [],
  },
  maternalGrandmotherName: {
    milk: {
      calfDefaultGroup: false,
      calfGroup: false,
    },
    meat: {
      calfDefaultGroup: false,
      calfGroup: false,
    },
    headerText: '母母の名前',
    sortOrderTypes: [],
  }
});
