function topNotificationsComponent() {
  'ngInject';
  return {
    controller: 'TopNotificationsController',
    templateUrl: 'components/notifications/top-notifications/index.html',
    bindings: {
      notifications: '<'
    }
  };
}

app.component('topNotifications', topNotificationsComponent());
