/**
 * @deprecated
 * ヘルプメニューはReactに移行されました。
 * IEユーザーへの対応のために残しています。
 */
class HelpMoviesListController {
  constructor(
    FarmService,
    $state,
    $window,
    $rootScope,
    $timeout,
    ViewStateAPI
  ) {
    'ngInject';

    this.FarmService = FarmService;
    this.$state = $state;
    this.$window = $window;
    this.$timeout = $timeout;
    this.ViewStateAPI = ViewStateAPI;

    this.isInitialized = false;

    this.helps = [];

    this.init();
  }

  init() {
    this.prevVideoWindow = null;
    this.prevPdfWindow = null;
    this.movieDetailUrlBase = this.$state.href('helpMovieDetail', {}, {absolute: true});
    this.initHelps().finally(() => {
      if (this.helps.length > 0) {
        this.onSelectMenu(this.helps[0]);
        this.isInitialized = true;
        this.shouldShowHelps = true;
      }
    });
  }

  initHelps() {
    return this.FarmService.show()
      .then((res) => {
        this.farm = new Farm(res);

        if (this.farm.isMilk()) {
          const breedingPdfFiles = HelpData.breedingPdfFiles.filter((file) => {
            return !file.isBeef;
          });

          this.helps = [
            {
              label: 'マニュアル',
              files: this.addUrl('pdf', breedingPdfFiles)
            },
            {
              label: '使い方説明動画',
              files: this.addUrl('video', HelpData.milkVideoFiles)
            },
          ];
        } else {
          const breedingPdfFiles = HelpData.breedingPdfFiles.filter((file) => {
            return !file.isMilk;
          });

          const videoFiles = [
            ...HelpData.commonVideoFiles,
            ...(this.farm.isFattening() ? HelpData.fatteningVideoFiles : []),
            ...(this.farm.isBreeding() ? HelpData.breedingVideoFiles : [])
          ];

          this.helps = [
            ...(this.farm.isFattening() ? [{
              label: '【肥育】マニュアル',
              files: this.addUrl('pdf', HelpData.fatteningPdfFiles)
            }] : []),
            ...(this.farm.isBreeding() ? [{
              label: '【繁殖】マニュアル',
              files: this.addUrl('pdf', breedingPdfFiles)
            }] : []),
            {
              label: '使い方説明動画',
              files: this.addUrl('video', videoFiles)
            }
          ];
        }
      })
      .catch((err) => console.error(err));
  }

  addUrl(type, files) {
    return files.map((file) => {
      file.type = file.icon = type;
      file.url = type === 'video'
        ? this.movieDetailUrlBase + file.id + '?mode=1'
        : file.url;
      return file;
    });
  }

  onSelectMenu(menu) {
    this.helps.forEach((menu) => menu.isActive = false);
    menu.isActive = true;
    this.shouldShowHelps = false;
    this.$timeout(() => {
      this.selectedMenu = menu;
      this.shouldShowHelps = true;
    });
  }

  onRowClick(row) {
    this.ViewStateAPI.create(
      'helpDetail',
      'help',
      {bqNotes: row.title}
    );

    switch (row.type) {
    case 'video':
      if (this.prevVideoWindow) {
        this.prevVideoWindow.close();
      }
      this.prevVideoWindow = this.$window.open(row.url, 'video');
      break;
    case 'pdf':
      if (this.prevPdfWindow) {
        this.prevPdfWindow.close();
      }
      this.prevPdfWindow = this.$window.open(row.url, 'pdf');
      break;
    }
  }
}

app.controller('HelpMoviesListController', HelpMoviesListController);
