class GroupIndexController {
  constructor(
    $modal,
    $rootScope,
    CowGroupAPI,
    SortOrderEditDialog,
    CowGroupService,
    UtilService
  ) {
    'ngInject';
    this.$modal = $modal;
    this.$rootScope = $rootScope;
    this.CowGroupAPI = CowGroupAPI;
    this.SortOrderEditDialog = SortOrderEditDialog;

    this.CowGroupService = CowGroupService; // FIXME: 牛群設定画面を作り直す時は廃止する

    this.toBoolean = UtilService.toBoolean;

    this.index();
  }

  index(refreshMenu = false) {
    this.CowGroupService.summary().then((res) => {
      const groups = res.data;
      this.groups = groups.map((group) => {
        group.groupAttr = this.CowGroupService.getAttribute(group).label;
        return group;
      });

      if (refreshMenu) {
        this.$rootScope.$emit('menu:refreshGroups', groups);
      }
    });
  }

  entry() {
    this.openEditForm(null, 'menu/setting/group/entry.html');
  }

  edit(cowGroupId) {
    this.openEditForm(cowGroupId, 'menu/setting/group/edit.html');
  }

  delete(cowGroupId) {
    this.openEditForm(cowGroupId, 'menu/setting/group/delete.html');
  }

  openEditForm(cowGroupId, templateUrl) {
    this.$modal.open({
      templateUrl: templateUrl,
      controller: 'GroupEditController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        cowGroupId: () => cowGroupId,
      },
    }).result.then(() => this.index(true));
  }

  updateSortOrder() {
    const items = this.groups.map((r) => {
      return {id: r.cowGroupId, name: r.cowGroupName};
    });
    const updateFunction = this.CowGroupAPI.updateSortOrder.bind(this.CowGroupAPI);

    this.SortOrderEditDialog.open(
      '牛群の表示順設定',
      '牛群名',
      items,
      updateFunction
    ).then((result) => {
      if (result.executed) {
        this.index();
      }
    });
  }
}

app.controller('GroupIndexController', GroupIndexController);
