class CowEventIndexHtmlGenerator {
  static get workerLabels() {
    return {
      'mastitis': '治療担当者',
      'perinatal_and_metabolic_diseases': '治療担当者',
      'reproductive_disorders': '治療担当者',
      'claw_diseases': '治療担当者',
      'infection_diseases': '治療担当者',
      'injured': '治療担当者',
      'vaccination': '治療担当者',
      'bred': '授精担当者',
      'pregnant_diagnosis': '妊娠鑑定担当者',
    };
  }

  static workerLabel(eventType) {
    return CowEventIndexHtmlGenerator.workerLabels[eventType] || '作業者名';
  }

  static generateHeader(eventType, headers, itemDefinitions) {
    const workerLabel = CowEventIndexHtmlGenerator.workerLabel(eventType);

    const ths = headers.map((header) => {
      const item = itemDefinitions[header.itemId];
      const el = document.createElement('th');
      const label = header.itemId === 'workerName' ? workerLabel : item.label;
      const className = item.classesTh.join(' ');

      el.innerHTML = `<div class="customlist-th-inner"><span>${label}</span><div class="sort-icon"></div></div>`;

      if (header.colspan) {
        el.setAttribute('colspan', String(header.colspan));
        el.setAttribute('ng-class', `ctrl.generateHeaderClass('${className}')`);
      } else {
        el.setAttribute('ng-class', `ctrl.generateHeaderClass('${className}', '${header.itemId}')`);
        el.setAttribute('ng-click', `ctrl.onClickColumnHeader('${header.itemId}')`);
      }

      if (header.rowspan) {
        el.setAttribute('rowspan', String(header.rowspan));
      }
      return el;
    });

    const tr = document.createElement('tr');
    ths.forEach((th) => tr.appendChild(th));

    return tr;
  }

  static generateRow(itemIds, itemDefinitions) {
    const tds = itemIds.map((itemId) => {
      const item = itemDefinitions[itemId];

      const el = document.createElement('td');
      el.className = item.classesTd.join(' ');

      if (itemId === 'medication') {
        el.innerHTML = `<p ng-repeat="m in e.medication track by $index">{{m}}</p>`;
      } else if (itemId.substr(0, 19) === 'stopMilkingOfBreast' || /^calf[1-4]registered$/.test(itemId)) {
        el.innerHTML = `<i class="icon-s-check-box-outlined" ng-if="e.${itemId}"></i>`;
      } else if (itemId === 'cowNo') {
        el.innerHTML = `<a ng-click="ctrl.goToDetails(e.cowId)">{{e.${itemId}}}</a>`;
      } else if (['lameDiagnosisResultFl', 'lameDiagnosisResultFr', 'lameDiagnosisResultBl', 'lameDiagnosisResultBr'].some((key) => itemId === key)) {
        el.innerHTML = `<p ng-repeat="m in e['${itemId}'] track by $index">{{m}}</p>`;
      } else {
        const prefix = item.textBefore || '';
        const suffix = item.textAfter || '';
        el.innerHTML = `${prefix}{{::e.${itemId}}}${suffix}`;
      }
      return el;
    });

    const tr = document.createElement('tr');
    tr.setAttribute('ng-repeat', 'e in ctrl.events | limitTo: ctrl.displayLimit track by e.id');
    tds.forEach((td) => tr.appendChild(td));

    return tr;
  }
}

function generateEventColumns($compile, $timeout) {
  'ngInject';

  const carcassFirstHeader = [
    {itemId: 'cowNo', rowspan: 2},
    {itemId: 'cowUid', rowspan: 2},
    {itemId: 'slaughterNo', rowspan: 2},
    {itemId: 'occurredAt', rowspan: 2},
    {itemId: 'carcassGrade', colspan: 2},
    {itemId: 'beforeSlaughterWeight', rowspan: 2},
    {itemId: 'carcassYieldGrade', colspan: 6},
    {itemId: 'carcassMeatGrade', colspan: 11},
    {itemId: 'carcassDefectSummary', rowspan: 2},
    {itemId: 'otherFaultCorrection', rowspan: 2},
    {itemId: 'dressedCarcassUnitPrice', rowspan: 2},
    {itemId: 'totalDressedCarcassWeight', rowspan: 2},
    {itemId: 'dressedCarcassSalesPrice', rowspan: 2},
    {itemId: 'selectedVisceralDestruction', rowspan: 2},
    {itemId: 'comment', rowspan: 2},
  ];
  const carcassSecondHeader = [
    'yieldGrade',
    'meetGrade',
    'dressedCarcassWeightOfL',
    'dressedCarcassWeightOfR',
    'loinArea',
    'ribsThickness',
    'subcutaneousFat',
    'yieldBaseValue',
    'bmsNo',
    'marblingGrade',
    'bcsNo',
    'gloss',
    'bcsAndGlossGrade',
    'tight',
    'texture',
    'tightAndTextureGrade',
    'bfsNo',
    'fatLuster',
    'bfsAndFatLusterGrade',
  ];

  return {
    restrict: 'A',
    scope: '=',
    link: (s, el, attr) => {
      attr.$observe('generateEventColumns', () => {
        if (!attr.generateEventColumns) return;
        const eventType = s.ctrl.selectedEventType;
        const itemIds = s.ctrl.itemIds;
        const itemDefinitions = s.ctrl.itemDefinitions;

        $timeout(() => {
          if (el[0].tagName === 'THEAD') {
            el.html('');
            if (eventType === 'carcass_characteristic') {
              const filterItems = ['dressedCarcassUnitPrice', 'dressedCarcassSalesPrice'];
              const headers = carcassFirstHeader.filter((header) => {
                if (filterItems.includes(header.itemId)) {
                  return itemIds.includes(header.itemId, itemDefinitions);
                }
                return true;
              });
              const firstHeader = $compile(
                CowEventIndexHtmlGenerator.generateHeader(eventType, headers, itemDefinitions)
              )(s);
              el.append(firstHeader);

              const secondHeaders = carcassSecondHeader
                .filter((itemId) => itemIds.includes(itemId))
                .map((itemId) => {
                  return {itemId: itemId};
                });
              const secondHeader = $compile(
                CowEventIndexHtmlGenerator.generateHeader(eventType, secondHeaders, itemDefinitions)
              )(s);
              el.append(secondHeader);
              return;
            }

            const headers = itemIds.map((itemId) => {
              return {itemId: itemId};
            });
            const thead = $compile(
              CowEventIndexHtmlGenerator.generateHeader(eventType, headers, itemDefinitions)
            )(s);
            el.append(thead);
            return;
          }

          if (el[0].tagName === 'TBODY') {
            const tbody = $compile(
              CowEventIndexHtmlGenerator.generateRow(itemIds, itemDefinitions)
            )(s);
            el.html('');
            el.append(tbody);
            return;
          }
        });
      });
    },
  };
}

app.directive('generateEventColumns', generateEventColumns);
