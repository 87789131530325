class MobileGenomEvaluationListFilterController {
  constructor(
    DateUtilService,
    SelectionService,
    SessionCache,
    $modalInstance,
    $window,
    $timeout,
    $scope,
    params,
    MasterCowLabelAPI
  ) {
    'ngInject';
    this.DateUtilService = DateUtilService;
    this.SelectionService = SelectionService;
    this.state = {};
    this.$modalInstance = $modalInstance;
    this.$timeout = $timeout;
    this.$scope = $scope;

    this.MasterCowLabelAPI = MasterCowLabelAPI;

    this.mode = 'sort';

    const farm = SessionCache.farm();
    this.farmType = farm.farmType();

    this.state = this.initialState();
    this.init(params);
  }

  init(params) {
    const sortColumns = params.sortColumns;
    const filterColumns = params.filterColumns;
    const conditions = params.conditions;
    const sortColumnId = params.sortColumnId || null;
    const sortOrder = params.sortOrder || 'ascending';
    const groupConditions = params.groupConditions || [];
    const groupCondition = params.groupCondition || '';

    this.initialSortOptions(sortColumns);
    const newState = this.initialConditions(filterColumns, conditions, groupConditions);
    newState.isInitialized = true;
    newState.sortColumnId = sortColumnId;
    newState.sortOrder = sortOrder;
    newState.canAddCondition = conditions.length < filterColumns.length;
    newState.shouldShowGroupCondition = groupConditions.length > 0;
    newState.groupCondition = groupCondition;

    this.setState(newState);

    this.state.conditions.forEach((c) => {
      if (c.conditionType === 'SELECTION') {
        this.initialSelectionOptions(c).then(() => {
          this.setSelection(c);
        });
      }
      this.onChangeoperator(c);
    });

    this.clearErrorMessage();
  }

  initialState() {
    return {
      isInitialized: false,
      filterColumns: [],
      currentConditions: [],
      selectionConditions: [],
      groupCondition: '',
    };
  }

  initialSortOptions(sortColumns) {
    this.state.sortOptions = sortColumns.map((column) => {
      return {
        key: column.columnId,
        label: column.caption
      };
    });
  }

  initialSelectionOptions(condition) {
    if (condition.columnId === 'cow_labels') {
      return this.MasterCowLabelAPI.available().then((res) => {
        const masters = CollectionUtil.selectableMasters(res.data);
        condition.selectionOptions = masters.map((m) => {
          return {
            key: m.id,
            label: m.name,
            selected: false
          };
        });
      });
    }

    return this.loadSelection(condition.columnId).then((selections) => {
      condition.selectionOptions = selections.map((s) => {
        return {
          key: s.value,
          label: s.label,
          selected: false
        };
      });
    });
  }

  setSelection(condition) {
    condition.selectionOptions.forEach((o) => {
      o.selected = condition.selections.includes(o.label);
    });
  }

  initialConditions(filterColumns, conditions, groupConditions) {
    const state = {};

    state.filterColumns = filterColumns;

    state.filterOptions = filterColumns.map((column) => {
      return {
        key: column.columnId,
        label: column.caption
      };
    });

    state.filterColumnMap = state.filterColumns.reduce((obj, column) => {
      obj[column.columnId] = column;
      return obj;
    }, {});

    state.operators = {
      // STRING
      STRING: [
        {
          value: 'include',
          label: '指定した値を含む',
          conditionType: 'STRING'
        },
        {
          value: 'exclude',
          label: '指定した値を含まない',
          conditionType: 'STRING'
        },
        {
          value: 'blank',
          label: '未入力',
          conditionType: 'STRING'
        },
      ],
      // NUMBER
      NUMERIC: [
        {
          value: 'between',
          label: '指定範囲内',
          conditionType: 'NUMERIC'
        },
      ],
      // SELECTION
      SELECTION: [
        {
          value: 'include',
          label: '指定した値を含む',
          conditionType: 'SELECTION'
        },
        {
          value: 'exclude',
          label: '指定した値を含まない',
          conditionType: 'SELECTION'
        },
      ],
      // ORDINAL_SCALE
      ORDINAL_SCALE: [
        {
          value: 'between',
          label: '指定範囲内',
          conditionType: 'ORDINAL_SCALE'
        },
      ],
    };

    state.groupConditions = groupConditions;

    if (conditions.length > 0) {
      state.conditions = angular.copy(conditions);
      state.canClear = true;
    } else {
      state.conditions = [];
      state.canClear = false;
    }
    state.canAddCondition = state.conditions.length < state.filterColumns.length;

    return state;
  }

  // UIハンドラー

  onCancelClick() {
    this.$modalInstance.dismiss('cancel');
  }

  onSubmitClick() {
    if (this.checkInputError()) return;

    const result = {};

    result.conditions = this.state.conditions.map((c) => {
      const result = {
        columnId: c.columnId,
        conditionType: c.conditionType,
        operator: c.operator
      };

      if (c.conditionType === 'STRING') {
        result['conditionString'] = c.conditionString;
      } else if (c.conditionType === 'NUMERIC') {
        result['conditionMinNumber'] = c.conditionMinNumber;
        result['conditionMaxNumber'] = c.conditionMaxNumber;
      } else if (c.conditionType === 'SELECTION') {
        result['selections'] = c.selectionOptions
          .filter((s) => s.selected)
          .map((s) => s.label);
      } else if (c.conditionType === 'ORDINAL_SCALE') {
        result['conditionMinNumber'] = c.conditionMinNumber;
        result['conditionMaxNumber'] = c.conditionMaxNumber;
      }

      return result;
    });

    result.sortColumnId = this.state.sortColumnId;
    result.sortOrder = this.state.sortOrder;
    result.groupCondition = this.state.groupCondition;

    result.status = {
      submit: true
    };

    this.$modalInstance.close(result);
  }

  addCondition() {
    const column = this.state.filterColumns.find((column) => {
      const used = this.state.conditions.some((condition) => {
        return column.columnId === condition.columnId;
      });
      return !used;
    });

    if (!column) return;

    const condition = {
      columnId: column.columnId
    };
    this.state.conditions.push(condition);
    this.onChangeFilterColumn(condition);

    this.state.canAddCondition = this.state.conditions.length < this.state.filterColumns.length;
  }

  removeCondition(index) {
    this.state.conditions.splice(index, 1);
    this.state.canAddCondition = true;
    this.clearErrorMessage();
  }

  onChangeFilterColumn(c, index) {
    const column = this.state.filterColumnMap[c.columnId];

    if (column.fieldType === 'STRING') {
      if (CustomlistSelection.isSelection(c.columnId) || CustomlistSelection.isUserDefined(c.columnId)) {
        c.conditionType = 'SELECTION';
        this.initialSelectionOptions(c);
      } else {
        c.conditionType = 'STRING';
      }
    } else if (this.isNumericType(column.fieldType)) {
      c.conditionType = 'NUMERIC';
      c.conditionMinNumber = null;
      c.conditionMaxNumber = null;
    } else if (column.fieldType === 'ORDINAL_SCALE') {
      c.conditionType = 'ORDINAL_SCALE';
      c.conditionMinNumber = null;
      c.conditionMaxNumber = null;
    }

    this.clearErrorMessage();
    this.state.conditions.forEach((condition, idx) => {
      if (index === idx) return;
      if (condition.columnId === c.columnId) {
        this.putError(index, 'columnId', '既に選択済みの項目です');
        return;
      }
    });

    this.onChangeoperator(c);
  }

  onChangeoperator(c) {
    c.showStringCondition = false;
    c.showNumericCondition = false;
    c.showSelectionCondition = false;
    c.showOrdinalScaleCondition = false;

    if (c.conditionType === 'STRING') {
      const valid = ['include', 'exclude', 'blank'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'include';
      }
      c.showStringCondition = c.operator === 'include' || c.operator === 'exclude';

      if (!c.showStringCondition) {
        c.conditionString = '';
      }
    } else if (c.conditionType === 'NUMERIC') {
      const valid = ['between'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'between';
      }
      c.showNumericCondition = true;
    } else if (c.conditionType === 'SELECTION') {
      const valid = ['include', 'exclude'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'include';
      }
      c.showSelectionCondition = true;
    } else if (c.conditionType === 'ORDINAL_SCALE') {
      const valid = ['between'].some((s) => c.operator === s);
      if (!valid) {
        c.operator = 'between';
      }

      this.ordinalScaleOptions = CustomlistOrdinalScale.selectOptions(c.columnId).map((option) => {
        return {
          key: option.value,
          label: option.label
        };
      });

      c.showOrdinalScaleCondition = true;
    }
  }

  setState(state) {
    Object.assign(this.state, state);
  }

  isNumericType(fieldType) {
    return ['INTEGER', 'FLOAT', 'LAPSED_DAYS', 'DIFF_DAYS', 'LAPSED_MONTHS', 'EXPECTED_DAYS'].some((s) => fieldType === s);
  }

  loadSelection(columnId) {
    const map = {
      gender: {
        'selectionKey': 'gender'
      },
      state: {
        'selectionKey': 'state'
      },
    };
    const selectionKey = map[columnId].selectionKey;

    return this.SelectionService.index().then((res) => {
      return res[selectionKey];
    });
  }

  onChangeNumericValue(index, item) {
    this.checkNumber(index, item);
  }

  checkNumber(index, item) {
    this.putError(index, item, '');

    const condition = this.state.conditions[index];
    if (!condition[item]) {
      return false;
    }

    if (isNaN(condition[item])) {
      this.putError(index, item, '数字を入力してください');
      return true;
    }
    return false;
  }

  checkInputError() {
    let error = false;
    this.clearErrorMessage();

    this.state.conditions.forEach((c, index) => {
      if (c.conditionType === 'NUMERIC' || c.conditionType === 'ORDINAL_SCALE') {
        if (!c.conditionMinNumber && !c.conditionMaxNumber) {
          this.putError(index, 'conditionMinNumber', '入力して下さい');
          error = true;
        } else {
          const minError = this.checkNumber(index, 'conditionMinNumber');
          const maxError = this.checkNumber(index, 'conditionMaxNumber');
          error = minError || maxError;
        }
      }

      this.state.conditions.forEach((other, idx) => {
        if (index === idx) return;
        if (other.columnId === c.columnId) {
          this.putError(index, 'columnId', '重複しています');
          error = true;
        }
      });
    });

    return error;
  }

  putError(index, item, errorMessage) {
    this.errorMessage['filter'] = this.errorMessage['filter'] || {};
    this.errorMessage['filter'][index] = this.errorMessage['filter'][index] || {};

    this.errorMessage['filter'][index][item] = errorMessage;
  }

  clearErrorMessage() {
    this.errorMessage = {};
  }

  showSort() {
    return this.mode === 'sort';
  }

  showFilter() {
    return this.mode === 'filter';
  }

  classModalFormConditionToggleBackdrop(model, value) {
    return model === value ? 'uModal__formConditionToggleBackdrop' : 'uModal__formConditionToggleBackdrop--right';
  }

  classModalFormConditionToggleText(model, value) {
    return model === value ? 'uModal__formConditionToggleText--active' : 'uModal__formConditionToggleText';
  }

  classMain() {
    const openSelectMultiple = this.conditions.some((condition) => {
      return condition.openSelectMultiple;
    });

    return openSelectMultiple ? 'uModal__formConditionMain--openSelectMultiple' : 'uModal__formConditionMain';
  }

  openSelectMultiple(condition, event) {
    condition.openSelectMultiple = true;
  }

  closeSelectMultiple(condition) {
    condition.openSelectMultiple = false;
  }

  classSelectMultipleBackdrop(condition) {
    return condition.openSelectMultiple ?
      'uModal__formConditionSelectMultipleBackdrop--open' : 'uModal__formConditionSelectMultipleBackdrop';
  }

  classSelectMultipleFocus(condition) {
    return condition.openSelectMultiple ?
      'uModal__formConditionSelectMultipleFocus--focus' : 'uModal__formConditionSelectMultipleFocus';
  }

  classSelectMultipleList(condition) {
    return condition.openSelectMultiple ?
      'uModal__formConditionSelectMultipleList--open' : 'uModal__formConditionSelectMultipleList';
  }

  formatSelections(selectionOptions) {
    if (!selectionOptions) return;

    return selectionOptions.filter((s) => s.selected).map((s) => s.label).join('、');
  }

  classFieldTypeText(condition) {
    return condition.operator === 'blank' ?
      'uModal__formConditionTextBody--disabled' : 'uModal__formConditionTextBody';
  }
}

app.controller('MobileGenomEvaluationListFilterController', MobileGenomEvaluationListFilterController);
