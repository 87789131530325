// eslint-disable-next-line no-unused-vars
class DhiImportHistoryDetailGenerator {
  generateColumnDefs(columns) {
    return columns.map((column) => {
      return {
        field: column.field,
        name: column.name,
        width: column.width,
        headerCellTemplate: this.generateHeaderCellTemplate(column),
        headerCellClass: () => {
          return 'ui-grid-sorted-none';
        },
        cellTemplate: this.generateCellTemplate(column)
      };
    });
  }

  generateHeaderCellTemplate(column) {
    return `
      <div
        ng-click="grid.appScope.clickHeaderCell('${column.field}', ${column.enableSorting})"
        ng-class="grid.appScope.classHeaderCell('${column.field}')"
        ng-style="grid.appScope.styleHeaderCell('${column.field}')"
      >
        <div class="customlist-th-inner">
          <span>${column.name}</span>
          <div class="sort-icon"></div>
        </div>
      </div>`;
  }

  generateCellTemplate(column) {
    if (column.field === 'cowNo') {
      return `
        <a
          ng-bind="row.entity.cowNo"
          ng-click="grid.appScope.goToCowDetail(row.entity.cowId)"
          class="contents__uiGridLink"
        >
        </a>`;
    }
  }
}
