class MobileEditCalvingSensorHistoryModal {
  constructor(
    $modal
  ) {
    'ngInject';

    this.$modal = $modal;
  }

  open(param) {
    return this.$modal.open({
      templateUrl: 'mobile/components/edit-calving-sensor-history-modal/index.html',
      controller: 'MobileEditCalvingSensorHistoryModalController as ctrl',
      resolve: {
        param: () => param
      }
    });
  }
}
app.service('MobileEditCalvingSensorHistoryModal', MobileEditCalvingSensorHistoryModal);
