class MasterMarketAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/master_market');
  }

  available() {
    return this.APIService.get('/master_market/available');
  }

  insert(params) {
    return this.APIService.post('/master_market', params);
  }

  update(params) {
    return this.APIService.put(`/master_market/${params.id}`, params);
  }

  delete(id) {
    return this.APIService.delete(`/master_market/${id}`);
  }

  updateSortOrder(orders) {
    const params = {orders: orders};
    return this.APIService.post('/master_market/sort_order', params);
  }
}

app.service('MasterMarketAPI', MasterMarketAPI);
