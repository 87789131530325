class SelectOperator {
  constructor(
    $rootScope,
    $modal,
    $location,
    IsMobile,
    SessionCache,
    OperationUserService,
    appConfig
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$modal = $modal;
    this.IsMobile = IsMobile;
    this.SessionCache = SessionCache;
    this.OperationUserService = OperationUserService;
    this.appConfig = appConfig;

    const params = $location.search();
    this.isPrintMode = params.mode === '1';
  }

  execute() {
    if (this.isPrintMode) return;

    const account = this.SessionCache.account();
    if (!account.shared() || this.IsMobile) {
      this.appConfig.staff.name = account.name;
      this.$rootScope.userName = account.name;
      return;
    }

    this.OperationUserService.index().then((res) => {
      const operators = res.data.map((operator) => {
        return operator.name;
      });

      if (operators.length > 1) {
        this.$modal.open({
          windowTemplateUrl: 'components/u-modal/window.html',
          templateUrl: 'components/dialog/operator/index.html',
          controller: 'OperatorDialogController',
          controllerAs: 'ctrl',
          backdrop: false,
          resolve: {
            params: () => {
              return {
                operators: operators
              };
            }
          }
        }).result.then((result) => {
          this.SessionCache.store('sharedOperatorName', result);

          // FIXME: appConfig.staff は廃止する
          this.appConfig.staff.name = result;
        });
      } else if (operators.length === 1) {
        this.SessionCache.store('sharedOperatorName', operators[0]);

        // FIXME: appConfig.staff は廃止する
        this.appConfig.staff.name = operators[0];
      }
      // FIXME: $rootScope.userName は廃止する
      this.$rootScope.userName = this.appConfig.staff.name;
    });
  }
}

app.service('SelectOperator', SelectOperator);
