class CowshedService {
  constructor(
    APIService
  ) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/cowshed');
  }
}

app.service('CowshedService', CowshedService);
