// eslint-disable-next-line no-unused-vars
class CustomlistRuntimeCondition {
  static generateFilterCondition(cows, key, ignoreSingleCondition = true) {
    const label = key === 'cow_group_name' ? '牛群' : '牧場';

    const values = new Set();
    cows.forEach((cow) => {
      const value = cow[key];
      if (value) {
        values.add(cow[key]);
      }
    });

    if (values.size === 0) {
      return null;
    }
    if (ignoreSingleCondition && (values.size === 1)) {
      return null;
    }

    const conditions = Array.from(values)
      .sort()
      .map((value) => {
        return {key: value, label: value};
      });
    conditions.unshift({key: '', label: `全ての${label}`});
    return conditions;
  }
}
