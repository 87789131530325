class ReportSummaryCategorizerService {
  constructor(
    ReportSummary
  ) {
    'ngInject';

    this.ReportSummary = ReportSummary;
  }

  /**
   * カテゴリー毎に整形された枝肉成績サマリーデータを返す
   *
   * @param {Array<Object>} sumaries 枝肉成績サマリーデータ
   * @return カテゴリー毎の配列
   */
  carcassCharacteristic(summaries) {
    return this.categorize(summaries, this.ReportSummary.categories.carcassCharacteristic);
  }

  /**
   * カテゴリー毎に整形された繁殖成績サマリーデータを返す
   *
   * @param {Array<Object>} sumaries 繁殖成績サマリーデータ
   * @return カテゴリー毎の配列
   */
  reproductionPerformance(summaries) {
    return this.categorize(summaries, this.ReportSummary.categories.reproductionPerformance);
  }

  /**
   * カテゴリー毎に整形しサマリーデータを返す
   *
   * @param {Array<Object>} sumaries サマリーデータ
   * @param {Array<Object>} category カテゴリー
   * @return カテゴリー毎の配列
   */
  categorize(summaries, category) {
    return category.map((c) => {
      const temp = {};
      summaries.forEach((s) => temp[s.yearMonth] = s[c.base]);
      return {label: c.label, unit: c.unit, summaries: temp};
    });
  }
}

app.service('ReportSummaryCategorizerService', ReportSummaryCategorizerService);
