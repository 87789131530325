class MobileTopCalvingSensorController {
  constructor(
    SessionCache,
    CalvingSensorAPI
  ) {
    'ngInject';

    const base = new TopCalvingSensorController(SessionCache, CalvingSensorAPI);
    const farm = SessionCache.farm();

    TopCalvingSensorBinder.bind(this, base);

    this.init(farm);
  }
}

function mobileTopCalvingSensor() {
  return {
    templateUrl: 'mobile/schedule/calving-sensor/index.html',
    controller: MobileTopCalvingSensorController,
    controllerAs: 'ctrl',
    bindings: {
      date: '<',
      total: '='
    }
  };
}

app.component('mobileTopCalvingSensor', mobileTopCalvingSensor());
