function reproductionHistoryItemYear($window, $timeout) {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      const top = Math.round(element.offset().top - angular.element('.uModal__history').offset().top);

      element.attr('data-top', top);

      angular.element('.uModal__history').bind('scroll', (e) => {
        if (!element.data('top')) return;

        const top = Math.round(
          angular.element('.uModal__history').offset().top - angular.element($window).scrollTop()
        ) + 10;
        if (element.data('top') <= e.target.scrollTop) {
          element.css({
            position: 'fixed',
            top: top,
            left: 'calc(50% + 315px)'
          });
        } else {
          element.css({
            position: '',
            top: '',
            left: ''
          });
        }
      });
    }
  };
}

app.directive('reproductionHistoryItemYear', ['$window', '$timeout', reproductionHistoryItemYear]);
