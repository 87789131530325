class AlertConfigEditController {
  constructor(
    AlertConfigService,
    AlertHeatDetectionConfigAPI,
    AlertCalvingDetectionThresholdAPI,
    $modalInstance,
    $modal,
    params,
    blockUI,
    MasterDialog
  ) {
    'ngInject';
    this.$modalInstance = $modalInstance;
    this.$modal = $modal;
    this.blockUI = blockUI;
    this.MasterDialog = MasterDialog;

    this.config = params.config;
    this.templateUrl = params.templateUrl;
    this.farmType = params.farmType;
    this.isDepositor = params.isDepositor;
    this.typeMilk = this.farmType === Farm.farmTypeMilk;
    this.typeBeef = this.farmType === Farm.farmTypeBeef;

    const alerts = {
      heat: {
        api: AlertHeatDetectionConfigAPI,
        createParamsFunc: AlertHeatDetectionConfig.createRequestParams
      },
      calving: {
        api: AlertCalvingDetectionThresholdAPI,
        createParamsFunc: (input) => input.calving
      }
    };
    this.alertObj = alerts[params.alertType] ||
      {api: AlertConfigService, createParamsFunc: this.createParams.bind(this)};

    const alertColumns = {
      heat: {
        cowStateIds: 'heatAlertCowStateIds',
        cowStates: 'heatAlertCowStates',
        stateConditionLabel: 'stateConditionLabel',
        cowGroupIds: 'heatAlertCowGroupIds',
        cowGroupConditionLabel: 'cowGroupConditionLabel'
      },
      illness: {
        cowStateIds: 'illnessCowStateIds',
        cowStates: 'illnessCowStates',
        stateConditionLabel: 'illnessStateConditionLabel',
        cowGroupIds: 'illnessCowGroupIds',
        cowGroupConditionLabel: 'illnessCowGroupConditionLabel',
        showAcuteIllness: 'showAcuteIllness'
      },
      chronicIllness: {
        cowStateIds: 'chronicIllnessCowStateIds',
        cowStates: 'chronicIllnessCowStates',
        stateConditionLabel: 'chronicIllnessStateConditionLabel',
        cowGroupIds: 'chronicIllnessCowGroupIds',
        cowGroupConditionLabel: 'chronicIllnessCowGroupConditionLabel'
      }
    };
    this.alertColumns = alertColumns[params.alertType];

    this.heatDurations = AlertHeatDetectionConfig.HEAT_DURATIONS.map((d) => {
      return {label: d, value: d};
    });

    this.heatEventConditionDays = AlertHeatDetectionConfig.HEAT_EVENT_CONDITION_DAYS.map((d) => {
      return {label: d, value: d};
    });

    this.invalid = false;
    this.isEditing = true;
  }

  static get chronicIllnessMonthAge() {
    return {
      FROM: 0,
      TO: 99
    };
  }

  selectState() {
    const states = this.config[this.alertColumns.cowStateIds] ?
      this.config[this.alertColumns.cowStateIds].split(',') : [];

    this.$modal.open({
      animation: true,
      templateUrl: 'dialog/state-dialog.html',
      controller: 'StateDialogController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'select-standard',
      resolve: {
        params: () => ({
          states: states,
          farmType: this.farmType,
          exclude: ['sold', 'died']
        }),
      },
    }).result.then((result) => {
      const ids = result.map((r) => r.id).join(',');
      const states = result.map((r) => r.label).join(',');
      const label = result.map((r) => r.label).join('、') || '(全ての状態が対象になります)';
      this.config[this.alertColumns.cowStateIds] = ids;
      this.config[this.alertColumns.cowStates] = states;
      this.config[this.alertColumns.stateConditionLabel] = label;
    });
  }

  selectCowGroup() {
    const cowGroupIds = this.config[this.alertColumns.cowGroupIds] ?
      this.config[this.alertColumns.cowGroupIds].split(',').map((s) => Number(s)) : [];

    this.MasterDialog.execute('cow_group', cowGroupIds).then((result) => {
      const cowGroupIds = result.map((r) => r.id).join(',');
      const cowGroupNames = result.map((r) => r.name).join('、') || '(全ての牛群が対象になります)';
      this.config[this.alertColumns.cowGroupIds] = cowGroupIds;
      this.config[this.alertColumns.cowGroupConditionLabel] = cowGroupNames;
    });
  }

  save() {
    const params = this.alertObj.createParamsFunc(this.config);

    this.blockUI.start('更新中');
    this.alertObj.api.create(params)
      .then((res) => {
        this.isEditing = false;
        this.blockUI.stop();
        this.$modalInstance.close();
      }).catch((res) => {
        this.blockUI.stop();
        this.message = ErrorUtil.formatErrorMessage(res.data.messages);
      });
  }

  /**
   * 保存するパラメーターの作成
   */
  createParams(config) {
    const params = {
      milkThresholdRate: parseFloat(config.milkThresholdRate || 0),
      feedThresholdRate: parseFloat(config.feedThresholdRate || 0),
      waterThresholdRate: parseFloat(config.waterThresholdRate || 0),
      lieThresholdRate: parseFloat(config.lieThresholdRate || 0),
      illnessCowStates: config.illnessCowStates,
      illnessCowGroupIds: config.illnessCowGroupIds,
      illnessUseMoonAge: config.illnessUseMoonAge,
      illnessMoonAgeFrom: config.illnessMoonAgeFrom,
      illnessMoonAgeTo: config.illnessMoonAgeTo,
      showAcuteIllness: config.showAcuteIllness
    };

    if (this.typeMilk || this.typeBeef && !config.illnessUseMoonAge) {
      params.illnessUseMoonAge = false;
      params.illnessMoonAgeFrom = 0;
      params.illnessMoonAgeTo = 0;
    }
    Object.assign(params, this.chronicIllnessSettingParams());
    return params;
  }

  cancel() {
    this.$modalInstance.close(false);
  }

  /**
   * 慢性疾病アラートの設定項目を返す
   *
   * @return {Object}
   *    { chronicIllnessCowStates: {String},
   *      chronicIllnessCowGroupIds: {String},
   *      chronicIllnessUseMonthAge: {boolean},
   *      chronicIllnessMonthAgeFrom: {number},
   *      chronicIllnessMonthAgeTo: {number} }
   */
  chronicIllnessSettingParams() {
    const params = {
      chronicIllnessCowStates: this.config.chronicIllnessCowStates,
      chronicIllnessCowGroupIds: this.config.chronicIllnessCowGroupIds,
      chronicIllnessUseMonthAge: this.config.chronicIllnessUseMonthAge
    };

    if (params.chronicIllnessUseMonthAge) {
      params.chronicIllnessMonthAgeFrom = this.config.chronicIllnessMonthAgeFrom;
      params.chronicIllnessMonthAgeTo = this.config.chronicIllnessMonthAgeTo;
    } else {
      params.chronicIllnessMonthAgeFrom = AlertConfigEditController.chronicIllnessMonthAge.FROM;
      params.chronicIllnessMonthAgeTo = AlertConfigEditController.chronicIllnessMonthAge.TO;
    }
    return params;
  }

  // 分娩アラート用
  validateCalvingThreshold(threshold, form) {
    const thresholdRange = {min: 1, max: 20};
    const valid = threshold >= thresholdRange.min && threshold <= thresholdRange.max;

    if (valid) {
      form.$invalid = false;
    } else {
      form.$invalid = true;
    }
  }

  onKeydownCalvingThreshold(e) {
    if (!KeyInputUtil.isNumberKey(e)) {
      e.preventDefault();
    }
  }
}

app.controller('AlertConfigEditController', AlertConfigEditController);
