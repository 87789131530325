// eslint-disable-next-line no-unused-vars
class Version {
  constructor($http, $window, modalDialogFactory) {
    this.$http = $http;
    this.$window = $window;
    this.modalDialogFactory = modalDialogFactory;

    const appFileElement = document.getElementById('app-js-file') || {src: ''};
    this.appFileName = appFileElement.src.split('/').pop();
    this.confirmVersionAt = DateUtil.addHours(new Date(), 2);
  }

  confirm() {
    if (DateUtil.diffSeconds(this.confirmVersionAt, new Date()) <= 0) return;

    this.$http({method: 'GET', url: '/rev-manifest.json'}).then((res) => {
      const path = Object.keys(res.data).find((p) => p.includes('app.js'));
      const fileName = res.data[path].split('/').pop();

      if (this.appFileName !== fileName) {
        this.showUpdateAlertDialog().result.then(() => {
          this.$window.location.href = '/';
        });
      } else {
        this.confirmVersionAt = DateUtil.addHours(new Date(), 2);
      }
    }).catch((error) => console.error(error));
  }

  showUpdateAlertDialog() {
    return this.modalDialogFactory.showSuccessiveAlertModal({
      title: '更新',
      text1: '最新のバージョンがリリースされているので更新します',
      text2: '更新後はホーム画面に遷移します'
    });
  }
}
