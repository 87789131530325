class MasterCowLabelAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  index() {
    return this.APIService.get('/master_cow_label');
  }

  available() {
    return this.APIService.get('/master_cow_label/available');
  }

  create(params) {
    return this.APIService.post('/master_cow_label', params);
  }

  update(params) {
    return this.APIService.put(`/master_cow_label/${params.id}`, params);
  }

  delete(id) {
    return this.APIService.delete(`/master_cow_label/${id}`);
  }

  updateSortOrder(orders) {
    const params = {orders: orders};
    return this.APIService.post('/master_cow_label/sort_order', params);
  }
}

app.service('MasterCowLabelAPI', MasterCowLabelAPI);
