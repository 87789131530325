class mainFooterComponentController {
  constructor(
    $state,
    $modal,
    $rootScope,
    SessionCache,
    CowSearchDialog,
    SideBoardService
  ) {
    'ngInject';

    this.$state = $state;
    this.$modal = $modal;
    this.$rootScope = $rootScope;
    this.CowSearchDialog = CowSearchDialog;
    this.SideBoardService = SideBoardService;
    this.isDepositorFarm = SessionCache.farm().isDepositor();
    this.deregisters = [];

    this.init();
  }

  $onDestroy() {
    this.deregisters.forEach((d) => d());
  }

  init() {
    this.showList = false;
    this.isCowDetail = false;

    this.deregisters.push(this.$rootScope.$on('$stateChangeSuccess', (ev, toState, toParams, fromState, fromParams) => {
      if (toState.name === 'cowDetail') {
        this.isCowDetail = true;
      } else {
        this.isCowDetail = false;
      }
    }));
  }

  toggleShowSideBoard() {
    this.SideBoardService.toggleState('opened');
  }

  toggleShowList() {
    this.showList = !this.showList;
  }

  closeShowList() {
    this.showList = false;
  }

  showSideBoard() {
    return this.SideBoardService.getState('show');
  }

  showPager() {
    return this.isCowDetail && this.$rootScope.umPagerConfig && this.$rootScope.umPagerConfig.total;
  }

  returnToLink() {
    if (this.$rootScope.umPagerConfig.caller.state) {
      const caller = this.$rootScope.umPagerConfig.caller;
      this.$state.go(caller.state, caller.params);
      return;
    }

    this.$state.go(this.$rootScope.umPagerConfig.listState.state.name, this.$rootScope.umPagerConfig.listState.params);
  }

  showEventEntry() {
    this.closeShowList();

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'components/event-registration/cow-selection.html',
      controller: 'CowSelectionController',
      controllerAs: 'ctrl'
    });
  }

  showBulkEventEntry() {
    this.closeShowList();

    this.$modal.open({
      templateUrl: 'components/event-registration/event-selection.html',
      controller: 'EventSelectionController',
      controllerAs: 'ctrl',
      size: '1012',
      resolve: {
        cowIds: () => [],
        cowType: () => null,
        isDepositor: () => false,
        qwert: () => 0,
        caller: () => {}
      },
    });
  }

  showCowIdSearch() {
    this.closeShowList();

    if (this.isDepositorFarm) {
      this.CowSearchDialog.show();
      return;
    }

    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'components/cow-id-search/index-tablet.html',
      controller: 'TabletCowIdSearchController',
      controllerAs: 'ctrl'
    });
  }

  classSideBoardIcon() {
    return this.SideBoardService.getState('opened') ? 'uFooter__menuIcon--black' : 'uFooter__menuIcon';
  }

  classMenuLinks() {
    return this.showList ? 'uFooter__menuLinks--open' : 'uFooter__menuLinks';
  }

  classPagerLink(enable) {
    return enable ? 'uFooter__pagerLink' : 'uFooter__pagerLink--disabled';
  }
}

function mainFooterComponent() {
  return {
    templateUrl: 'components/main-footer/index.html',
    controller: mainFooterComponentController
  };
}

app.component('mainFooter', mainFooterComponent());
