class DepositBulkEntryEventContext {
  constructor(
    $q,
    DepositFarmService,
    SelectionService,
    DepositCowEventsService,
    DepositCowService,
    DepositCowGroupService,
    DepositMasterHormoneProgramService,
    DepositMasterMedicineService,
    DepositMasterSpermService,
    MasterDiseaseAPI,
    DepositUserDefinedSelectionAPI,
    PregnantDiagnosisTimingAPI,
    DepositOperationUserAPI,
    AccountService,
    MasterEmbryoAPI,
    MasterWorkNoteTypeAPI
  ) {
    'ngInject';

    this.$q = $q;
    this.FarmService = DepositFarmService;
    this.SelectionService = SelectionService;
    this.CowEventsService = DepositCowEventsService;
    this.CowService = DepositCowService;
    this.CowGroupService = DepositCowGroupService;
    this.MasterHormoneProgramService = DepositMasterHormoneProgramService;
    this.MasterMedicineService = DepositMasterMedicineService;
    this.MasterSpermService = DepositMasterSpermService;
    this.MasterDiseaseAPI = MasterDiseaseAPI;
    this.DepositUserDefinedSelectionAPI = DepositUserDefinedSelectionAPI;
    this.PregnantDiagnosisTimingAPI = PregnantDiagnosisTimingAPI;
    this.OperationUserAPI = DepositOperationUserAPI;
    this.AccountService = AccountService;
    this.MasterEmbryoAPI = MasterEmbryoAPI;
    this.MasterWorkNoteTypeAPI = MasterWorkNoteTypeAPI;

    this.currentFarmId = 0;
  }

  setCurrentFarmId(farmId) {
    this.currentFarmId = farmId;
  }

  load(allowInactiveCow) {
    return this.$q.all([
      this.CowService.deprecatedDigest(this.currentFarmId, allowInactiveCow),
      this.CowGroupService.index(this.currentFarmId),
      this.FarmService.show(this.currentFarmId),
      this.SelectionService.index(),
      this.MasterDiseaseAPI.available(),
      this.DepositUserDefinedSelectionAPI.available(this.currentFarmId),
      this.PregnantDiagnosisTimingAPI.show(),
      this.OperationUserAPI.available(this.currentFarmId),
      this.AccountService.cachedAccount(),
      this.MasterEmbryoAPI.available(),
      this.MasterWorkNoteTypeAPI.available()
    ]);
  }

  bulkImport(cowEvents, options = {}) {
    return this.CowEventsService.insertInBulk(cowEvents, options);
  }

  loadDeprecatedDigestCows(allowInactiveCow) {
    return this.CowService.deprecatedDigest(this.currentFarmId, allowInactiveCow);
  }

  loadSperms() {
    return this.MasterSpermService.available(this.currentFarmId);
  }

  loadHormonePrograms() {
    return this.MasterHormoneProgramService.available(this.currentFarmId);
  }

  findBredByCow(cowId) {
    return this.CowEventsService.getByEventType(cowId, 'tanetsuke');
  }

  loadMasterEmbryo() {
    return this.MasterEmbryoAPI.available();
  }

  findEventByCow(cowId, eventType) {
    return this.CowEventsService.getByEventType(cowId, eventType);
  }
}

app.service('DepositBulkEntryEventContext', DepositBulkEntryEventContext);
