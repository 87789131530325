class MobileEventSelectionModalController {
  constructor($modalInstance,
    $scope,
    $modal,
    FarmService,
    CowService,
    SessionCache,
    $window,
    $timeout
  ) {
    'ngInject';
    this.$scope = $scope;
    this.$scope.onCancel = this.onCancel.bind(this);
    this.$scope.onEventSelected = this.onEventSelected.bind(this);
    this.$modalInstance = $modalInstance;
    this.$modal = $modal;
    this.$timeout = $timeout;

    this.farm = SessionCache.farm();

    this.farmType = '乳用';
    this.$timeout(this.calculateMainAreaHeight.bind(this));

    $window.onresize = this.calculateMainAreaHeight.bind(this);
    this.$scope.$on('$destroy', () => {
      $window.onresize = null;
    });
  }

  calculateMainAreaHeight() {
    // ウィンドウの高さを変更中に呼ばれると高さが不正になるためブロックする
    if (this.applying) {
      return;
    }
    this.applying = true;

    this.$timeout(() => {
      const windowHeight = $(window).innerHeight();
      const headerHeight = $('header.layout-header .container').innerHeight();
      this.$scope.$apply(() => {
        this.mainAreaHeight = windowHeight - headerHeight;
        this.applying = false;
      });
    });
  }

  onCancel() {
    this.$modalInstance.close(false);
  }

  onEventSelected(eventType) {
    this.$modalInstance.close(eventType);
  }

  isMilkFarm() {
    return this.farmType === '乳用';
  }

  isMeatFarm() {
    return this.farmType === '肉用';
  }

  shouldShowEvent(eventType) {
    const eventDisplayMap = this.$scope.eventDisplayMap;

    switch (eventType) {
    case 'lameness':
    case 'carcass':
      return false;
    case 'mastitis':
      return eventDisplayMap['milking'];
    case 'breedingDifficulty':
      return eventDisplayMap['breeding'] || eventDisplayMap['calf'];
    case 'perinatalDifficulty':
      return eventDisplayMap['breeding'] || eventDisplayMap['fattening'];
    case 'fattening':
      return this.$scope.farmKind === '肉用';
    case 'kannyu':
      return eventDisplayMap['milking'];
    case 'measurement':
      return eventDisplayMap['calfAndFatten'];
    case 'doNotBreed':
      return eventDisplayMap['doNotBreed'];
    case 'heishi':
      return eventDisplayMap['heishi'];
    case 'touta':
      return eventDisplayMap['touta'];
    case 'freshCheck':
      return eventDisplayMap['doFreshCheck'];
    case 'dysstasia':
      return this.farm.useAlertDysstasia();
    default:
      return true;
    }
  }

  shouldShowEvents(state) {
    return this.$scope.eventDisplayMap[state];
  }
}

app.controller('MobileEventSelectionModalController', MobileEventSelectionModalController);
