class MobileDysstasiaDuplicateErrorDialogController {
  constructor(
    $modal,
    $modalInstance,
    params
  ) {
    'ngInject';

    this.$modalInstance = $modalInstance;
    this.$modal = $modal;
    this.messages = params.messages;
    this.findingAtDisplay = DateUtil.toYYYYMMDDHHmm(params.findingAt);
  }

  cancel() {
    this.$modalInstance.close();
  }

}
app.controller('MobileDysstasiaDuplicateErrorDialogController', MobileDysstasiaDuplicateErrorDialogController);
