// 分析グラフ
// eslint-disable-next-line no-unused-vars
class AnalyticChart {
  constructor(selector) {
    this.selector = selector;
    this.options = {
      bindto: this.selector,
      padding: {},
      size: {
        height: 468,
      },
      data: {
        x: 'x',
        columns: [],
        classes: {},
        axes: {},
        types: {},
        order: null,
      },
      axis: {
        y: {
          label: {
            text: '',
            position: 'outer-right',
          },
          min: 0,
          padding: {
            bottom: 0,
          },
        },
        x: {
          label: {
            text: '日付',
            position: 'outer-top',
          },
          type: 'timeseries',
          tick: {
            fit: true,
            format: '%m/%d',
          },
        },
        y2: {
          show: false,
          label: {
            text: '',
            position: 'outer-right',
          },
        },
      },
      subchart: {
        show: false,
      },
      point: {
        show: false,
      },
      tooltip: {
        show: true,
      },
    };
  }

  showSummaryChart(data, colors, axes, types, axisY, height = 200) {
    this.options.size.height = height;
    this.options.padding.right = 50;
    this.options.padding.left = 50;

    this.showAnalyticChart(data, {}, colors, axes, types, axisY);
  }

  showAnalyticChart(columns, classes, colors, axes, types, axisY) {
    this.destroy();

    this.options.data.columns = columns = columns || [];
    this.options.data.classes = classes = classes || {};
    this.options.data.colors = colors = colors || {};
    this.options.data.axes = axes = axes || {};
    this.options.data.types = types = types || {};
    axisY = axisY || {};

    let labelMapping = {
      milk: '搾乳量(kg)',
      feed: '採食(分)',
      water: '飲水(分)',
      rumination: '反芻(分)',
      move: '動態(分)',
      lie: '横臥(分)',
      stand: '静止(分)',
      temperature: '気温(℃)',
      humidity: '湿度(％)',
      stress: 'THI(pt)',
    };

    this.options.axis.y.label.text = labelMapping[axisY.labelY];
    this.options.axis.y2.show = Boolean(axisY.isShowY2);
    if (this.options.axis.y2.show) {
      this.options.axis.y2.label.text = labelMapping[axisY.labelY2];
    }

    this.chart = c3.generate(this.options);
  }

  clear() {
    $(this.selector).html('');
  }

  destroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
