app.factory('DateTimeUtilFactory', (appConfig) => {
  'ngInject';
  /**
   * 変換前後のタイムゾーンを指定し対象のDateオブジェクトに変換処理を行う
   */
  let parseDate = (fromTimezoneName, toTimezoneName, targetDate) => {
    if (!targetDate) return null;
    let from = appConfig.timeZone[fromTimezoneName].offset;
    let to = appConfig.timeZone[toTimezoneName].offset;

    // サマータイムを考慮してunix time をセットする際にはoffset値を比較する
    if (typeof targetDate === 'number') {
      if (from !== (moment.tz(targetDate, appConfig.timeZone[fromTimezoneName].name).utcOffset() * (-1))) {
        from = moment.tz(targetDate, appConfig.timeZone[fromTimezoneName].name).utcOffset() * (-1);
      }
      if (to !== (moment.tz(targetDate, appConfig.timeZone[toTimezoneName].name).utcOffset() * (-1))) {
        from = moment.tz(targetDate, appConfig.timeZone[toTimezoneName].name).utcOffset() * (-1);
      }
    }

    return moment(targetDate).add(from, 'm').add(to * (-1), 'm').toDate();
  };

  let getTime = (targetDate) => {
    return targetDate ? targetDate.getTime() : null;
  };

  let objectDateKeys = {
    'history': ['occurredAt', 'kannyuDate'],
    'cowInfo': ['lastModifiedDate', 'modifyDatetime', 'birthday', 'latestCalvingDate',
      'latestFertilizationDate', 'purchaseDate',
      'fertilizationDateOnPurchase', 'salesDate'],
  };

  let parseDateForObject = (parseType, objectType, object) => {
    _.each(object, (value, key) => {
      if (_.contains(objectDateKeys[objectType], key)) {
        if (parseType === 'client') {
          object[key] = getTime(parseDate('client', 'region', Number(value)));
        } else {
          object[key] = getTime(parseDate('region', 'client', Number(value)));
        }
      }
    });
    return object;
  };

  return {
    parseTimezoneDateObject: parseDate,
    parseRegionDate: (date) => {
      return parseDate('region', 'client', date);
    },
    parseClientDate: (date) => {
      return parseDate('client', 'region', date);
    },
    parseRegionUnixTime: (date) => {
      return getTime(parseDate('region', 'client', Number(date)));
    },
    parseClientUnixTime: (date) => {
      return getTime(parseDate('client', 'region', Number(date)));
    },
    parseClientDateForHistory: (history) => {
      return parseDateForObject('client', 'history', history);
    },
    parseClientDateForHistories: (histories) => {
      return _.chain(histories).map( (history) => {
        return parseDateForObject('client', 'history', history);
      }).value();
    },
    parseRegionDateForHistory: (history) => {
      return parseDateForObject('region', 'history', history);
    },
    parseRegionDateForHistories: (histories) => {
      return _.chain(histories).map( (history) => {
        return parseDateForObject('region', 'history', history);
      }).value();
    },

    parseClientDateForCowInfo: (cowInfo) => {
      return parseDateForObject('client', 'cowInfo', cowInfo);
    },
    parseClientDateForCowInfos: (cowInfos) => {
      return _.chain(cowInfos).map( (cowInfo) => {
        return parseDateForObject('client', 'cowInfo', cowInfo);
      }).value();
    },
    parseRegionDateForCowInfo: (cowInfo) => {
      return parseDateForObject('region', 'cowInfo', cowInfo);
    },
    parseRegionDateForCowInfos: (cowInfos) => {
      return _.chain(cowInfos).map( (cowInfo) => {
        return parseDateForObject('region', 'cowInfo', cowInfo);
      }).value();
    }
  };
});
