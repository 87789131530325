class CowshedCoolPesconController {
  constructor(
    CoolPesconApi
  ) {
    'ngInject';

    this.stressRegions = EnvironmentChart.CHART_TYPES.stress.regions;
    this.CoolPesconApi = CoolPesconApi;
    this.measurementRange = {};

    this.initialize();
  }

  decideThiColorClass(value) {
    const findedRegion = this.stressRegions.find((region, i) => {
      const stressRegion = this.stressRegions[i];
      if (i && (stressRegion.start < value && stressRegion.end >= value)) return stressRegion;
      if (stressRegion.start <= value && stressRegion.end >= value) return stressRegion;
    });
    return (findedRegion || {class: ''}).class;
  }

  changeDate(startDate, endDate) {
    this.date = endDate;

    const startUnixtime = DateUtil.toUnixtime(startDate);
    const endUnixtime = DateUtil.toUnixtime(endDate);

    if (this.existsInMeasurementRange(startDate, endDate)) {
      this.updateChartData(startUnixtime, endUnixtime);
    } else {
      this.loading = true;
      this.loadCoolPescon(startUnixtime, endUnixtime).then(() => {
        this.updateChartData(startUnixtime, endUnixtime);
      }).finally(() => this.loading = false);
    }
  }

  changeUnit(unit) {
    this.unit = unit;

    const endDate = DateUtil.endOfDay(this.date);
    const endUnixtime = DateUtil.toUnixtime(endDate);

    let startDate;
    if (this.unit === 'days') {
      startDate = DateUtil.startOfDay(DateUtil.addDays(endDate, -6));
    } else {
      startDate = DateUtil.startOfDay(endDate);
    }

    const startUnixtime = DateUtil.toUnixtime(startDate);

    if (this.existsInMeasurementRange(startDate, endDate)) {
      this.updateChartData(startUnixtime, endUnixtime);
    } else {
      this.loading = true;
      this.loadCoolPescon(startUnixtime, endUnixtime).then(() => {
        this.updateChartData(startUnixtime, endUnixtime);
      }).finally(() => this.loading = false);
    }
  }

  // private

  initialize() {
    const now = Date.now();
    const endDate = DateUtil.endOfDay(now);
    const endUnixtime = DateUtil.toUnixtime(endDate);

    const startDate = DateUtil.startOfDay(DateUtil.addDays(now, -6));
    const startUnixtime = DateUtil.toUnixtime(startDate);

    this.initializeChartData();
    this.date = endDate;
    this.unit = 'days';
    this.loading = true;

    this.loadCoolPescon(startUnixtime, endUnixtime).then(() => {
      this.updateChartData(startUnixtime, endUnixtime);
    }).finally(() => this.loading = false);
  }

  initializeChartData() {
    CoolPesconDevice.items().forEach((ct) => {
      this[ct] = {data: null};
    });
  }

  /**
   * cool-pesconのデータを取得する
   *
   * @param {number} startUnixtime
   * @param {number} endUnixtime
   */
  loadCoolPescon(startUnixtime, endUnixtime) {
    return this.CoolPesconApi.account(startUnixtime, endUnixtime).then((res) => {
      if (res && res.data) {
        this.coolPescon = new CoolPescon(res.data);
        const newMeasurementRange = this.createMeasurementRange(startUnixtime, endUnixtime, this.coolPescon);
        this.measurementRange = angular.extend({}, this.measurementRange, newMeasurementRange);
      }
    });
  }

  /**
   * 範囲内の全てのデータが存在するか確認する
   *
   * @param {number} startUnixtime
   * @param {number} endUnixtime
   * @return {boolean}
   */
  existsInMeasurementRange(startDate, endDate) {
    for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
      const dateString = DateUtil.toW3CFormat(d);
      const data = this.measurementRange[dateString];
      if (!data) return false;
    }
    return true;
  }

  /**
   * 引数で渡された日付範囲のデータを作成する
   *
   * @param {number} startUnixtime
   * @param {number} endUnixtime
   * @param {CoolPescon} coolPescon
   * @return {Array<Object>} { '2019-01-01': { '牛舎1': [], ... }, ...]
   */
  createMeasurementRange(startUnixtime, endUnixtime, coolPescon) {
    let startDate = DateUtil.toDate(startUnixtime * 1000);
    let endDate = DateUtil.toDate(endUnixtime * 1000);

    const range = {};
    for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
      const temp = {};
      coolPescon.devices.forEach((device) => {
        temp[device.label] = device.pickupMeasurements(d);
      });
      if (Object.values(temp).every((v) => v === null)) continue;
      range[DateUtil.toW3CFormat(d)] = temp;
    }

    return range;
  }

  /**
   * chartDataを更新する
   *
   * @param {number} startUnixtime
   * @param {number} endUnixtime
   */
  updateChartData(startUnixtime, endUnixtime) {
    let startDate = DateUtil.toDate(startUnixtime * 1000);
    let endDate = DateUtil.toDate(endUnixtime * 1000);

    let chartTypes = CoolPesconDevice.items();

    const filteredMeasurements = [];
    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      const formattedDate = DateUtil.toW3CFormat(date);
      const data = this.measurementRange[formattedDate] || null;
      filteredMeasurements.push({date: formattedDate, data: data});
    }

    chartTypes.forEach((ct) => {
      this[ct] = angular.extend({}, this[ct], {data: []});
      if (this.unit === 'days') {
        this.updateDailyChartTypeData(filteredMeasurements, ct);
        if (this[ct].data.length) this.complementDailyChartTypeData(startUnixtime, endUnixtime, ct);
      } else {
        this.updateHourlyChartTypeData(filteredMeasurements[0], ct);
      }
    });
  }

  updateDailyChartTypeData(measurements, chartType) {
    measurements.forEach((m) => {
      const temp = {date: m.date};
      if (m.data) {
        Object.keys(m.data).forEach((key) => temp[key] = m.data[key] ? m.data[key][chartType] : null);
        this[chartType].data.push(temp);
      }
    });
  }

  complementDailyChartTypeData(startUnixtime, endUnixtime, chartType) {
    let startDate = DateUtil.toDate(startUnixtime * 1000);
    let endDate = DateUtil.toDate(endUnixtime * 1000);

    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      const formattedDate = DateUtil.toW3CFormat(date);
      const findedData = this[chartType].data.find((d) => d.date === formattedDate);
      if (findedData) continue;
      this[chartType].data.push({date: formattedDate}).sort((a) => a.date);
    }
  }

  updateHourlyChartTypeData(measurement, chartType) {
    const data = measurement && measurement.data || {};
    const noDataKeys = [];
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        (data[key].details || []).forEach((detail) => {
          const findedData = this[chartType].data.find((d) => DateUtil.isSameHour(d.date, detail.date));
          const temp = findedData || {date: detail.date};
          temp[key] = detail[chartType];
          this[chartType].data.push(temp);
        });
      } else {
        noDataKeys.push(key);
      }
    });
    this[chartType].data.forEach((d) => {
      noDataKeys.forEach((key) => d[key] = null);
    });
  }
}

function cowshedCoolPesconComponent() {
  'ngInject';
  return {
    controller: CowshedCoolPesconController,
    controllerAs: 'ctrl',
    templateUrl: 'menu/environment/cowshed/cool-pescon/index.html'
  };
}
app.component('cowshedCoolPescon', cowshedCoolPesconComponent());
