// eslint-disable-next-line no-unused-vars
class Customlist {
  static get COMMA_DELIMITED_COLUMNS() {
    return [
      'dressed_carcass_unit_price',
      'dressed_carcass_sales_price',
    ];
  }

  static get SUMMARY_COLUMNS() {
    return [
      'comment_summary',
      'medication_summary',
      'treatment_summary',
      'vaccine_summary',
      'bred_summary',
      'bred_digest',
    ];
  }

  static get EXTRACT_COLUMNS() {
    return [
      'cow_no',
      'calf_no',
      'cow_labels',
      'comments',
      'alert_type',
    ].concat(Customlist.SUMMARY_COLUMNS);
  }

  static get NUMBER_FIELD_TYPES() {
    return ['INTEGER', 'FLOAT', 'LAPSED_DAYS', 'DIFF_DAYS', 'LAPSED_MONTHS', 'EXPECTED_DAYS'];
  }

  static get ORIGIN_SORT_COLUMNS() {
    return ['peak_at'];
  }

  static get DEFAULT_DATE_STYLE() {
    return 'yyyy-mm-dd-slash';
  }

  static get DEFAULT_BRED_DIGEST_OPTIONS() {
    return {
      contents: ['date', 'comment', 'detail'],
      eventTypes: ['bred', 'diagnosis', 'timed_ai', 'heat'],
      range: 'all',
      sortOrder: 'desc',
      style: 'line_break',
    };
  }

  static get BOOLEAN_STYLE_OPTIONS() {
    return [
      { key: 'MARK', trueValue: '○', falseValue: '×', label: '「○」または「×」' },
      { key: 'MARK_OR_BLANK', trueValue: '○', falseValue: '', label: '「○」または「空欄」' },
      { key: 'YES_OR_NO', trueValue: 'YES', falseValue: 'NO', label: '「YES」または「NO」' },
    ];
  }

  constructor(showAcuteIllness) {
    this.ZERO_FILL_20 = '0'.repeat(20);
    this.showAcuteIllness = showAcuteIllness;
  }

  convert(columns, records, unixtimeDate) {
    const currentAt = new Date();

    const result = records.map((r) => {
      const converted = {
        cowId: r.cow.cow_id,
        cowNo: r.cow.cow_no,
        cowNoForOrder: r.cow.cow_no_for_order,
        penForOrder: r.cow.pen_for_order,
        qwert: r.cow.qwert,
        origin: r.cow,
        selected: false
      };

      if (!r.cow.cow_no) {
        r.cow.cow_no = '－';
      }

      const endDate = r.cow.eliminate_date || r.cow.falling_dead_date || unixtimeDate;

      columns.forEach((c) => {
        if (this.isCowLabelColumn(c)) {
          // 個体ラベルを文字列リストに変換する('[OK],[注意]' => ['OK', '注意'])
          const conbinedCowLabel = r.cow['cow_labels'] || '';
          const cowLabels = conbinedCowLabel.split(',')
            .filter((label) => label) // String.prototype.split() は空文字に対して空文字の配列を返すので除去する
            .map((label) => label.replace(/\[|\]/g, ''));
          converted['cowLabels'] = CollectionUtil.uniqueList(cowLabels);
        } else if (this.isCommentColumn(c)) {
          converted['comments'] = CollectionUtil.uniqueList(r.comments).filter((s) => s);
        } else if (this.isAlertTypeColumn(c)) {
          const alertLabels = this.generateAlertLabels(r.cow);
          converted['alertLabels'] = CollectionUtil.uniqueList(alertLabels);
          ['acute_illness', 'illness', 'chronic_illness', 'feed_low', 'strong_move_low', 'water_low', 'lie_high', 'milk_low'].forEach((type) => {
            converted[type] = r.cow[type] || false;
          });
        } else {
          converted[c.columnId] = this.displayValue(c, r.cow, endDate, currentAt);
        }
      });
      return converted;
    });
    return result;
  }

  countDays(from, to) {
    if (!from || !to) return '';
    const days = DateUtil.countDays(Number(from), to);
    return days > 0 ? String(days) : '';
  }

  diffDays(from, to) {
    if (!from || !to) return '';
    const days = DateUtil.diffDays(Number(from), to);
    return days > 0 ? String(days) : '';
  }

  monthAge(from, to) {
    if (!from || !to) return '';
    const months = DateUtil.monthAge(new Date(Number(from)), new Date(to), 1);
    return months > 0 ? String(months) : '';
  }

  diffMonths(from, to) {
    if (!from || !to) return '';
    const months = DateUtil.diffMonths(new Date(Number(from)), new Date(to), 1);
    return months > 0 ? String(months) : '';
  }

  expectedDays(expectedDate, currentDate) {
    if (!expectedDate || !currentDate) return '';
    const days = DateUtil.diffDays(currentDate, Number(expectedDate));
    return String(days);
  }

  commaDelimitedNumber(value) {
    if (value === null) return '';
    return Number(value).toLocaleString();
  }

  displayValue(column, record, unixtimeDate, currentAt) {
    // 導入時月齢・日齢は導入日時点の日数を計算する
    if (column.columnId === 'month_age_at_entered' || column.columnId === 'day_age_at_entered') {
      unixtimeDate = record['introduce_date'];
    }

    if (column.columnId === 'month_age_at_start_fattening') {
      unixtimeDate = record['start_fattening_date'];
    }

    // 個体識別番号は5-4-1形式で表示する
    if (column.columnId === 'cow_uid') {
      return Cow.formatCowUid(record[column.columnId]);
    }

    // 空胎日数は分娩日と妊娠日から計算する
    if (column.columnId === 'days_open') {
      const daysOpen = Cow.daysOpen(record['latest_calving_date'], record['latest_pregnancy_date']);
      return daysOpen > 0 ? String(daysOpen) : '';
    }

    if (column.columnId === 'peak_at' || column.columnId === 'alert_heat_peak_at') {
      const peakAt = DateUtil.toDate(record[column.columnId]);
      if (!peakAt) return '-';

      // 「0分前」という表示は避けたいので秒以下を切り上げる
      const minutes = Math.ceil((currentAt - peakAt) / 1000 / 60);
      if (minutes >= 60) {
        const hour = Math.floor(minutes / 60);
        return hour + '時間前';
      } else {
        return (Math.ceil(minutes / 5) * 5) + '分前';
      }
    }

    if (column.columnId === 'resolved') {
      return record.resolved ? '確認済' : '未確認';
    }

    if (column.columnId === 'alert_notes' || column.columnId === 'alert_cow_view_alert_notes') {
      if (this.showAcuteIllness) {
        if (record['tag_dropped_out'] && record['acute_tag_dropped_out']) {
          return 'タグ脱落チェック(新・旧)';
        } else if (record['tag_dropped_out']) {
          return 'タグ脱落チェック(旧)';
        } else if (record['acute_tag_dropped_out']) {
          return 'タグ脱落チェック(新)';
        }
      } else {
        if (record['tag_dropped_out']) {
          return 'タグ脱落チェック';
        }
      }

      return '';
    }

    // 真偽値
    if (column.fieldType === 'BOOLEAN') {
      const option = Customlist.BOOLEAN_STYLE_OPTIONS.find((option) => {
        return option.key === column.options.booleanStyle;
      });
      if (record[column.columnId]) {
        return option.trueValue;
      } else {
        return option.falseValue;
      }
    }

    // 日付・日時項目
    if (column.fieldType === 'DATE' || column.fieldType === 'DATETIME') {
      const unixtime = record[column.columnId];
      if (!unixtime) return '';
      return DateUtil.formatDate(new Date(unixtime), column.dateFormat);
    }

    if (column.fieldType === 'TIMESTAMP') {
      const unixtime = record[column.columnId];
      if (!unixtime) return '';
      return DateUtil.toMMDDHHmm(unixtime);
    }

    // 経過日数項目
    if (column.fieldType === 'LAPSED_DAYS') {
      return this.countDays(record[column.relatedColumnId], unixtimeDate);
    }

    // 差分日数項目
    if (column.fieldType === 'DIFF_DAYS') {
      return this.diffDays(record[column.relatedColumnId], unixtimeDate);
    }

    // 月齢
    if (['birthday_months', 'month_age_at_entered'].some((s) => s === column.columnId)) {
      return this.monthAge(record[column.relatedColumnId], unixtimeDate);
    }

    // 経過月数項目
    if (column.fieldType === 'LAPSED_MONTHS') {
      return this.diffMonths(record[column.relatedColumnId], unixtimeDate);
    }

    // 予定日数項目
    if (column.fieldType === 'EXPECTED_DAYS') {
      return this.expectedDays(record[column.relatedColumnId], unixtimeDate);
    }

    // 順序尺度項目
    if (column.fieldType === 'ORDINAL_SCALE') {
      // 肉牛ゲノミック育種価区分
      if (GeBeefCattle.isClassItem(column.columnId)) {
        return GeBeefCattle.classNotation(record[column.columnId]);
      }
    }

    // カンマ区切り数値 (金額項目)
    if (Customlist.isCommaDelimitedColumn(column.columnId)) {
      return this.commaDelimitedNumber(record[column.columnId]);
    }

    // 搾乳量項目
    if (['weekly_mean', 'previous_day_amount'].some((s) => s === column.columnId)) {
      return StringUtil.replaceZeroOrNull(record[column.columnId], '-');
    }

    // 牛群検定項目
    const dhiZeroOrNullColumns = [
      'monthly_milk_daily', 'monthly_scc', 'monthly_fat_percent', 'monthly_prot_percent',
      'monthly_mun', 'monthly_milk_305em', 'peak_milk_daily'
    ];
    if (dhiZeroOrNullColumns.includes(column.columnId)) {
      return StringUtil.replaceZeroOrNull(record[column.columnId], '-');
    }

    const dhiNullColumns = ['monthly_linear_score', 'alert_linear_score_count', 'difference_previous_milk_daily'];
    if (dhiNullColumns.includes(column.columnId)) {
      return StringUtil.replaceNull(record[column.columnId], '-');
    }

    // 分娩センサー番号
    if (column.columnId === 'calving_sensor_number' && record[column.columnId]) {
      return CowCalvingSensorHistory.formatCalvingSensorNumber(record[column.columnId]);
    }

    // センサー番号
    if (column.columnId === 'ble_id') {
      if (DateUtil.isSameOrBeforeDay(record.ble_id_end_date, DateUtil.today())) return '';
    }

    return record[column.columnId];
  }

  generateAlertLabels(record) {
    let alertTypeLabels = [
      {
        type: 'acute_illness',
        label: '急性(改)'
      }, {
        type: 'illness',
        label: '急性'
      }, {
        type: 'chronic_illness',
        label: '慢性'
      }, {
        type: 'milk_low',
        label: '乳量'
      }, {
        type: 'feed_low',
        label: '採食'
      }, {
        type: 'strong_move_low',
        label: '動態(強)'
      }, {
        type: 'lie_high',
        label: '横臥'
      }, {
        type: 'water_low',
        label: '飲水'
      }
    ];

    alertTypeLabels = alertTypeLabels.filter((elem) => {
      if (elem.type === 'chronic_illness' &&
        (record.acute_illness || record.illness) &&
        record.chronic_illness) {
        return false;
      }

      return record[elem.type];
    }).map((elem) => elem.label);

    return alertTypeLabels;
  }

  /* ここからカラムの属性判定ゾーン */

  isCowNoColumn(column) {
    return column.columnId === 'cow_no';
  }

  isCalfNoColumn(column) {
    return column.columnId === 'calf_no';
  }

  isCowLabelColumn(column) {
    return column.columnId === 'cow_labels';
  }

  isCommentColumn(column) {
    return column.columnId === 'comments';
  }

  isAlertTypeColumn(column) {
    return column.columnId === 'alert_type' || column.columnId === 'alert_cow_view_alert_type';
  }

  isSummaryColumn(column) {
    return Customlist.SUMMARY_COLUMNS.includes(column.columnId);
  }

  isSimpleColumn(column) {
    if (Customlist.EXTRACT_COLUMNS.some((extractColumn) => extractColumn === column.columnId)) {
      return false;
    } else {
      return true;
    }
  }

  static isCommaDelimitedColumn(columnId) {
    return Customlist.COMMA_DELIMITED_COLUMNS.includes(columnId);
  }

  toDateFormat(displayStyle) {
    const style = displayStyle ? displayStyle : 'yyyy-mm-dd-slash';
    const styles = style.split('-');
    return {
      year: styles[0],
      month: styles[1],
      day: styles[2],
      separator_style: styles[3]
    };
  }

  /* ここまでカラムの属性判定ゾーン */

  static isIncludeAlertsConfig(configType) {
    const configtTypes = [
      'user_defined',
      'group',
      'fattening_stage',
    ];
    return configtTypes.some((ct) => {
      return configType === ct || configType.includes(ct);
    });
  }

  static inputToMaster(input) {
    const result = {
      customlistId: input.customlistId,
      showEntryColumn: input.showEntryColumn,
      useFixedColumnWidth: input.useFixedColumnWidth,
    };

    if (input.configType === 'user_defined') {
      result.name = input.name;
      result.remarks = input.remarks;
      result.sortOrder = input.sortOrder;
      result.showInMenu = input.showInMenu;
      result.conditionType = input.conditionType;
      result.showDepositedCow = input.showDepositedCow;
      result.allowAgentToUse = input.allowAgentToUse;

      if (input.alertContent && input.alertContent !== 'none') {
        result.includeAlerts = [input.alertContent];
      } else {
        result.includeAlerts = [];
      }
    }

    if (input.configType.startsWith('group') || input.configType === 'fattening_stage') {
      if (input.alertContent && input.alertContent !== 'none') {
        result.includeAlerts = [input.alertContent];
      } else {
        result.includeAlerts = [];
      }
    }

    return result;
  }

  static inputToColumnOptions(column, options) {
    if (!options) return null;

    if (CustomlistColumnDefinition.isBooleanField(column)) {
      const result = {
        'boolean_style': options.booleanStyle,
      };
      return JSON.stringify(result);
    }
    if (column.columnId === 'bred_digest') {
      const result = {
        'contents': options.contents,
        'event_types': options.eventTypes,
        'range': options.range,
        'sort_order': options.sortOrder,
        'style': options.style,
      };
      return JSON.stringify(result);
    }
    if (column.columnId.startsWith('work_note_type_')) {
      const result = {
        'master_work_note_type_id': options.masterWorkNoteTypeId,
      };
      return JSON.stringify(result);
    }
  }

  static inputToConditions(input, standardType) {
    const conditions = input
      .filter((condition) => standardType || condition.inputPhrase)
      .map((condition, index) => {
        const inputPhrase = standardType ? '' : condition.inputPhrase;
        const reactionEventType = standardType ? '' : condition.reactionEventType;

        return {
          groupOrder: index + 1,
          inputPhrase: inputPhrase,
          matchedComment: condition.matchedComment,
          reactionEventType: reactionEventType,
          cowGroupCondition: condition.cowGroupCondition,
          cowGroupConditionOperator: condition.cowGroupConditionOperator,
          stateCondition: condition.stateCondition,
          stateConditionOperator: condition.stateConditionOperator,
          excludeNonActive: condition.excludeNonActive,
          showCalf: condition.showCalf,
          alertOnly: condition.alertOnly
        };
      });
    if (conditions.length === 0) {
      conditions.push({
        groupOrder: 1,
        inputPhrase: ''
      });
    }

    return conditions;
  }

  static inputToConditionDetail(input, groupOrder, displayOrder) {
    const toNumberOrZero = (s) => {
      try {
        const n = s ? Number(s) : 0;
        return Number.isNaN(n) ? 0 : n;
      } catch (e) {
        return 0;
      }
    };

    return {
      groupOrder: groupOrder,
      displayOrder: displayOrder,
      fieldType: input.fieldType,
      columnId: input.columnId,
      conditionOperator: input.conditionOperator,
      conditionString: input.conditionString,
      conditionNumber1: toNumberOrZero(input.conditionNumber1),
      conditionNumber2: toNumberOrZero(input.conditionNumber2),
      valueCorrectType1: input.valueCorrectType1,
      valueCorrectType2: input.valueCorrectType2,
      conditionValue: input.conditionValue,
    };
  }

  static inputToConditionDetails(conditions, standardType) {
    const result = [];
    conditions
      .filter((condition) => standardType)
      .forEach((condition, index) => {
        const groupOrder = index + 1;
        condition.conditionDetails.forEach((input, detailIndex) => {
          const displayOrder = detailIndex + 1;
          result.push(Customlist.inputToConditionDetail(input, groupOrder, displayOrder));
        });
      });
    return result;
  }

  static groupTags(definitions) {
    const tags = definitions.reduce((acc, cur) => {
      if (cur.groupTag.includes(',')) {
        acc.push(...cur.groupTag.split(','));
      } else {
        acc.push(cur.groupTag);
      }
      return acc;
    }, []);
    const availableTags = new Set(tags);

    const allGroupTags = [
      {groupTag: 'BASIC_INFO', label: '基本情報'},
      {groupTag: 'MILK', label: '搾乳'},
      {groupTag: 'REPRODUCTION', label: '繁殖'},
      {groupTag: 'MASTITIS', label: '乳房炎'},
      {groupTag: 'TREATMENT', label: '疾病・治療'},
      {groupTag: 'PEDIGREE', label: '血統'},
      {groupTag: 'MEAT', label: '肉質'},
      {groupTag: 'LAPSED_DAYS', label: '経過日数'},
      {groupTag: 'DHI', label: '牛群検定'},
      {groupTag: 'GEBV_BEEF', label: 'ゲノミック育種価'},
      {groupTag: 'EVENT', label: 'イベント'},
    ];

    const result = allGroupTags.filter((t) => availableTags.has(t.groupTag));
    result.unshift({groupTag: 'ALL', label: 'すべて'});
    return result;
  }

  static formatAsExport(cows, columns) {
    const model = new Customlist();

    const matrixData = cows.map((cow) => {
      return columns.map((column) => {
        if (model.isCowLabelColumn(column)) {
          return cow.cowLabels.join('、');
        } else if (model.isCommentColumn(column)) {
          return cow.comments.join('、');
        } else if (model.isSummaryColumn(column)) {
          const summary = cow[column.columnId];
          if (!summary) return '';

          return summary.replace(/<b>/g, '')
            .replace(/<\/b>/g, '')
            .replace(/<br>/g, '、');
        } else if (model.isAlertTypeColumn(column)) {
          return cow.alertLabels.join('、');
        } else if (Customlist.isCommaDelimitedColumn(column.columnId)) {
          if (cow[column.columnId]) {
            return `"${cow[column.columnId]}"`;
          }
        }

        if ([null, undefined].includes(cow[column.columnId])) return '';

        return String(cow[column.columnId]).replace(/\n/g, ' ');
      });
    });

    const headers = columns.map((column) => {
      return column.shortCaption || column.caption;
    });
    matrixData.unshift(headers);

    return matrixData;
  }
}
