// eslint-disable-next-line no-unused-vars
class Farm {
  static get identificationTypeOptions() {
    return [
      {value: 'cow_no', caption: '牛番号'},
      {value: 'regulation_number_4', caption: '個体識別番号の拡大4桁'},
      {value: 'regulation_number_5', caption: '個体識別番号の下5桁'},
      {value: 'name', caption: '名前'}
    ];
  }

  static get cowNoPatternOptions() {
    return [
      {value: 'number', caption: '数字'},
      {value: 'alphanumeric', caption: '英数字'}
    ];
  }

  static get cowNoMatchingPatternOptions() {
    return [
      {value: 'complete', caption: '完全一致'},
      {value: 'prefix', caption: '前方一致'},
      {value: 'fuzzy', caption: 'あいまい検索'}
    ];
  }

  static get fatteningStartMonthAgeOptions() {
    return [
      {key: 6, label: '6ヶ月'},
      {key: 7, label: '7ヶ月'},
      {key: 8, label: '8ヶ月'},
      {key: 9, label: '9ヶ月'},
      {key: 10, label: '10ヶ月'},
      {key: 11, label: '11ヶ月'},
      {key: 12, label: '12ヶ月'},
      {key: 13, label: '13ヶ月'},
      {key: 14, label: '14ヶ月'},
      {key: 15, label: '15ヶ月'},
    ];
  }

  static get stopShipmentDisplayOptions() {
    return [
      {key: 'default', label: '指定しない'},
      {key: 'period', label: '指定する'},
      {key: 'none', label: '表示しない'},
    ];
  }

  static get farmTypeMilk() {
    return 'MILK';
  }

  static isFarmTypeMilk(farmType) {
    return farmType === Farm.farmTypeMilk;
  }

  static get farmTypeBeef() {
    return 'BEEF';
  }

  static isFarmTypeBeef(farmType) {
    return farmType === Farm.farmTypeBeef;
  }

  constructor(data) {
    this.data = angular.extend({}, data);
    this.initializeValues();
  }

  useCowNo() {
    return this.data.identificationType === 'cow_no';
  }

  useRegulationNumber4() {
    return this.data.identificationType === 'regulation_number_4';
  }

  useRegulationNumber5() {
    return this.data.identificationType === 'regulation_number_5';
  }

  useName() {
    return this.data.identificationType === 'name';
  }

  isAlphanumericCowNo() {
    return this.data.cowNoPattern === 'alphanumeric';
  }

  farmType() {
    if (this.data.farmKind === '乳用') {
      return Farm.farmTypeMilk;
    } else {
      return Farm.farmTypeBeef;
    }
  }

  isMilk() {
    return this.data.farmKind === '乳用' ? true : false;
  }

  isBeef() {
    return this.data.farmKind === '肉用' ? true : false;
  }

  isDairy() {
    return this.data.farmTypeDairy;
  }

  isBreeding() {
    return this.data.farmTypeBreeding;
  }

  isFattening() {
    return this.data.farmTypeFattening;
  }

  isGroupOrganizer() {
    return this.data.groupRole === 'organizer';
  }

  isGroupMember() {
    return this.data.groupRole === 'member';
  }

  isStandard() {
    return this.data.managementStyle === 'standard';
  }

  isDepositor() {
    return this.data.depositorFarm;
  }

  isAgent() {
    return this.data.agentFarm;
  }

  useAlertLocation() {
    return this.data.alertLocation;
  }

  isUMFull() {
    return this.data.contractedPlan === 'full';
  }

  isUMLight() {
    return this.data.contractedPlan === 'light';
  }

  showHeatIndexFattening() {
    return this.data.showHeatIndexFattening;
  }

  allowDuplicationCowNo() {
    return this.data.allowDuplicationCowNo;
  }

  useDuplicationOption() {
    return this.useCowNo() || this.useName();
  }

  fatteningStageBaseDate() {
    return this.data.fatteningStageBaseDate;
  }

  useAlertDysstasia() {
    return this.data.alertDysstasia;
  }

  farmTypeDescription() {
    let descriptions = [];

    if (this.isDairy()) {
      descriptions.push('酪農');
    }

    if (this.isBreeding()) {
      descriptions.push('繁殖');
    }

    if (this.isFattening()) {
      descriptions.push('肥育');
    }

    return descriptions.join(' ');
  }

  fiscalYearStartMonth() {
    return this.data.fiscalYearStartMonth;
  }

  isDhiLinkage() {
    return ['both', 'hmrt', 'liaj'].includes(this.data.dhiLinkage);
  }

  useAlertCalving() {
    return this.data.useAlertCalving;
  }

  useAlertMail() {
    return this.useAlertDysstasia() || this.useAlertCalving() || this.useAlertSensor();
  }

  isBeefGenomLinkage() {
    return this.data.genomLinkage === 'beef';
  }

  showBeefGenom() {
    return this.isBeef() && this.isBreeding();
  }

  useTemporaryGroupView() {
    return this.data.useTemporaryGroupView;
  }

  useCalfManagement() {
    return this.data.useCalfManagement;
  }

  certificateAiMenus() {
    return this.data.certificateAiMenus;
  }

  fatteningStartMonthAge() {
    return this.data.fatteningStartMonthAge;
  }

  stopShipmentDisplay() {
    return this.data.stopShipmentDisplay;
  }

  stopShipmentDisplayPeriod() {
    return this.data.stopShipmentDisplayPeriod;
  }

  useAlertSensor() {
    return this.data.useAlertSensor;
  }

  detectDropoutEartagSensor() {
    return this.data.detectDropoutEartagSensor;
  }

  useAlertEartagSensor() {
    return this.data.useAlertEartagSensor;
  }

  useSensorTypeCaption() {
    const sensorTypeMapping = {
      'use_neck_sensor': '首センサー',
      'use_ear_sensor': 'イヤタグセンサー',
      'use_both_sensor': 'イヤタグセンサーと首センサーを併用'
    };

    return sensorTypeMapping[this.data.useSensorType];
  }

  // private

  initializeValues() {
    const dateUtilService = new DateUtilService();
    this.startDate = this.data.startDate ? dateUtilService.toDate(Number(this.data.startDate)) : null;

    this.data.identificationType = this.data.identificationType || 'cow_no';
    this.data.cowNoPattern = this.data.cowNoPattern || 'number';
    this.data.cowNoMatchingPattern = this.data.cowNoMatchingPattern || 'complete';
  }
}
