class FileImportController {
  constructor(
    $rootScope,
    $scope,
    $modal,
    $q,
    $state,
    FileImportAPI,
    DateUtilService,
    FarmService,
    AccountService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$modal = $modal;
    this.$state = $state;
    this.FileImportAPI = FileImportAPI;
    this.DateUtilService = DateUtilService;

    this.importResults = [];

    this.$scope.$on('ngRepeatFinished', () => {
      FixedMidashi.create();
    });

    this.init($q, FarmService, AccountService);
  }

  init($q, FarmService, AccountService) {
    const fileTypes = {};

    $q.all([
      FarmService.show(),
      AccountService.cachedAccount()
    ]).then((res) => {
      this.farm = new Farm(res[0]);
      const account = res[1];
      this.isAdmin = account.role === 'admin';

      FileImport.fileTypes(this.farm, this.isAdmin).forEach((fileType) => {
        fileTypes[fileType.key] = fileType.label;
      });
    });

    this.fileTypes = fileTypes;

    this.index();
  }

  index() {
    const statusTypes = {
      'doing': '処理中',
      'finished': '完了',
      'abend': '異常終了',
    };

    const statusDisplay = (record) => {
      if (record.canceled) {
        return '削除済';
      }
      return statusTypes[record.status] || '-';
    };

    const ignoredHeadersDisplay = (ignoredHeaders) => {
      if (!ignoredHeaders) {
        return '';
      }
      if (ignoredHeaders.length <= 5) {
        return ignoredHeaders.join('、');
      } else {
        let s = ignoredHeaders.slice(0, 5).join('、');
        return `${s}  など${ignoredHeaders.length}項目`;
      }
    };

    this.FileImportAPI.index().then((res) => {
      this.importResults = res.data.map((record) => {
        record['createdAtDisplay'] = this.DateUtilService.toTimestampFormat(record.createdAt);
        record['fileTypeDisplay'] = this.fileTypes[record.fileType] || '-';
        record['statusDisplay'] = statusDisplay(record);
        if (record.status === 'finished') {
          record['allCountDisplay'] = record.allCount > 0 ? String(record.allCount) : '-';
          record['successCountDisplay'] = record.allCount > 0 ? String(record.successCount) : '-';
          record['errorCountDisplay'] = String(record.errorCount);
        } else {
          record['allCountDisplay'] = '-';
          record['successCountDisplay'] = '-';
          record['errorCountDisplay'] = '-';
        }
        record['detailExists'] = record.detailExists;
        record['hasError'] = record.errorCount > 0;
        record.ignoredHeadersDisplay = ignoredHeadersDisplay(record.ignoredHeaders);
        return record;
      });
    });
  }

  import() {
    this.$modal.open({
      windowTemplateUrl: 'components/u-modal/window.html',
      templateUrl: 'menu/file-import/file-upload.html',
      controller: 'FileUploadController',
      controllerAs: 'ctrl',
      backdrop: false,
      resolve: {
        params: () => {
          return {
            farm: this.farm,
            isAdmin: this.isAdmin,
            importedFiles: this.importedFiles(this.importResults)
          };
        }
      }
    });
  }

  importedFiles(importResults) {
    return importResults.filter((file) => {
      if (file.status === 'finished' && file.canceled === false) {
        if (file.fileType === 'cow_event_advanced') {
          return file.errorCount === 0;
        } else {
          return true;
        }
      } else {
        return false;
      }
    });
  }

  showError(importStatus) {
    this.FileImportAPI.errorList(importStatus.id).then((res) => {
      this.$modal.open({
        windowTemplateUrl: 'components/u-modal/window.html',
        templateUrl: 'menu/file-import/error-list.html',
        controller: 'FileImportErrorController',
        controllerAs: 'ctrl',
        backdrop: false,
        resolve: {
          params: () => {
            return {
              errors: res.data
            };
          }
        }
      });
    });
  }

  showDetail(importStatus) {
    this.$state.go('fileImportDetail', {
      id: importStatus.id
    });
  }
}

app.controller('FileImportController', FileImportController);
