// eslint-disable-next-line no-unused-vars
class DailyReportDetailsItem {
  static items(context, canEditFilterItem) {
    const itemIds = DailyReportDetailsItem.itemIds(context, canEditFilterItem);
    return DailyReportItemDefinition.itemDefinitions(itemIds);
  }

  static itemIds(context, canEditFilterItem) {
    switch (context.eventType) {
    case 'mastitis':
      return DailyReportDetailsItem.mastitisItemsIds();
    case 'claw_diseases':
      return DailyReportDetailsItem.clawDiseasesItemsIds();
    case 'reproductive_disorders':
      return DailyReportDetailsItem.reproductivedisordersItemsIds();
    case 'perinatal_and_metabolic_diseases':
      return DailyReportDetailsItem.perinatalAndMetabolicDiseasesItemsIds();
    case 'injured':
      return DailyReportDetailsItem.injuredItemsIds();
    case 'infection_diseases':
      return DailyReportDetailsItem.infectionDiseasesItemsIds();
    case 'others':
      return DailyReportDetailsItem.othersItemsIds();
    case 'fresh_check':
      return DailyReportDetailsItem.freshCheckItemsIds();
    case 'heat':
      return DailyReportDetailsItem.heatItemsIds();
    case 'bred':
      return DailyReportDetailsItem.bredItemsIds();
    case 'pregnant_diagnosis':
      return DailyReportDetailsItem.pregnantDiagnosisItemsIds();
    case 'aborted':
      return DailyReportDetailsItem.abortedItemsIds();
    case 'calved':
      return DailyReportDetailsItem.calvedItemsIds();
    case 'timed_ai':
      return DailyReportDetailsItem.timedAiItemsIds();
    case 'do_not_breed':
      return DailyReportDetailsItem.doNotBreedItemsIds();
    case 'embryo_recovery':
      return DailyReportDetailsItem.embryoRecoveryItemsIds();
    case 'body_weight_measurement':
      return DailyReportDetailsItem.bodyWeightMeasurementItemsIds();
    case 'work_note':
      return DailyReportDetailsItem.workNoteItemsIds();
    case 'vaccination':
      return DailyReportDetailsItem.vaccinationItemsIds();
    case 'dried':
      return DailyReportDetailsItem.driedItemsIds();
    case 'culled':
      return DailyReportDetailsItem.culledItemsIds(canEditFilterItem);
    case 'died':
      return DailyReportDetailsItem.diedItemsIds();
    case 'claw_trimmed':
      return DailyReportDetailsItem.clawTrimmedItemsIds();
    case 'moved':
      return DailyReportDetailsItem.movedItemsIds();
    case 'carcass_characteristic':
      return DailyReportDetailsItem.carcassCharacteristicItemsIds(canEditFilterItem);
    case 'individual_observation':
      return DailyReportDetailsItem.individualObservationItemsIds();
    case 'weaned':
      return DailyReportDetailsItem.weanedItemsIds();
    case 'dehorned':
      return DailyReportDetailsItem.dehornedItemsIds();
    case 'castrated':
      return DailyReportDetailsItem.castratedItemsIds();
    case 'fattened':
      return DailyReportDetailsItem.fattenedItemsIds();
    case 'farm_diary':
      return DailyReportDetailsItem.farmDiaryItemsIds();
    default:
      throw `不正なeventType: ${context.eventType}`;
    }
  }

  static mastitisItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'workerName',
      'treatedAtTime',
      'bodyTemperature',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'mastitisScore',
      'stopMilkingOfBreastFl',
      'mastitisBacteriaOfBreastFl',
      'mastitisDiagnosisResultOfBreastFl',
      'stopMilkingOfBreastFr',
      'mastitisBacteriaOfBreastFr',
      'mastitisDiagnosisResultOfBreastFr',
      'stopMilkingOfBreastBl',
      'mastitisBacteriaOfBreastBl',
      'mastitisDiagnosisResultOfBreastBl',
      'stopMilkingOfBreastBr',
      'mastitisBacteriaOfBreastBr',
      'mastitisDiagnosisResultOfBreastBr',
      'medications',
      'diagnosisResultDetail',
      'comment'
    ];
  }

  static clawDiseasesItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'workerName',
      'treatedAtTime',
      'bodyTemperature',
      'locomotionScore',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'lameAffected',
      'medications',
      'diagnosisResultDetail',
      'comment'
    ];
  }

  static reproductivedisordersItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'workerName',
      'treatedAtTime',
      'bodyTemperature',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'diagnosisResultOfOvaryL',
      'diagnosisResultOfOvaryR',
      'uterusDiagnosisResult',
      'difficultiesOvaryStill',
      'difficultiesOvaryCystic',
      'difficultiesRemnant',
      'difficultiesMetritis',
      'difficultiesPyometra',
      'medications',
      'diagnosisResultDetail',
      'comment'
    ];
  }

  static perinatalAndMetabolicDiseasesItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'workerName',
      'treatedAtTime',
      'bodyTemperature',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'difficultiesKetosis',
      'difficultiesFattyLiver',
      'difficultiesAbomasumDisplacement',
      'difficultiesHypocalcium',
      'difficultiesAcidosis',
      'difficultiesMetritis',
      'difficultiesDowner',
      'difficultiesStagnantFood',
      'difficultiesPlacenta',
      'difficultiesTympanites',
      'medications',
      'diagnosisResultDetail',
      'comment'
    ];
  }

  static injuredItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'workerName',
      'treatedAtTime',
      'bodyTemperature',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'difficultiesHipDislocation',
      'difficultiesTeatInjury',
      'otherDisease',
      'medications',
      'diagnosisResultDetail',
      'comment'
    ];
  }

  static infectionDiseasesItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'workerName',
      'treatedAtTime',
      'bodyTemperature',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'difficultiesPneumonia',
      'difficultiesDiarrhea',
      'difficultiesSkinDisease',
      'otherDisease',
      'medications',
      'diagnosisResultDetail',
      'comment'
    ];
  }

  static othersItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'workerName',
      'treatedAtTime',
      'bodyTemperature',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
      'otherDisease',
      'medications',
      'diagnosisResultDetail',
      'comment'
    ];
  }

  static freshCheckItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'diagnosisResultOfOvaryL',
      'diagnosisResultOfOvaryR',
      'uterusDiagnosisResult',
      'freshCheckResult',
      'bcs',
      'otherReproductionWork',
      'comment'
    ];
  }

  static heatItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'occurredAtTime',
      'plannedBredMethod',
      'plannedBredAt',
      'heatComment'
    ];
  }

  static bredItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'workerName',
      'occurredAtTime',
      'tanetsukeMethod',
      'inseminationCode',
      'masterSpermName',
      'masterSpermNo',
      'donorName',
      'embryoType',
      'embryoCertificateNo',
      'donorCowUid',
      'donorRegistrationNo',
      'donorBreed',
      'donorBreedingValue',
      'otherReproductionWork',
      'comment'
    ];
  }

  static pregnantDiagnosisItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'workerName',
      'ninshinkanteiResult',
      'targetBreedingDate',
      'fertilizedDays',
      'judgePregnantTiming',
      'otherReproductionWork',
      'comment'
    ];
  }

  static abortedItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'occurredAtTime',
      'afterCowState',
      'nextStartMilkingFlg',
      'comment'
    ];
  }

  static calvedItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'occurredAtTime',
      'calvingDifficulty',
      'birthResult01',
      'breed01',
      'gender01',
      'registered01',
      'cowNo01',
      'cowUid01',
      'weightOnBirth01',
      'calfNo01',
      'birthStateString01',
      'birthResult02',
      'breed02',
      'gender02',
      'registered02',
      'cowNo02',
      'cowUid02',
      'weightOnBirth02',
      'calfNo02',
      'birthStateString02',
      'birthResult03',
      'breed03',
      'gender03',
      'registered03',
      'cowNo03',
      'cowUid03',
      'weightOnBirth03',
      'calfNo03',
      'birthStateString03',
      'birthResult04',
      'breed04',
      'gender04',
      'registered04',
      'cowNo04',
      'cowUid04',
      'weightOnBirth04',
      'calfNo04',
      'birthStateString04',
      'beforeCowGroupName',
      'beforePen',
      'moveToCowGroupName',
      'moveToPen',
      'comment'
    ];
  }

  static timedAiItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'occurredAtTime',
      'hormoneProgramName',
      'hormoneName',
      'comment'
    ];
  }

  static doNotBreedItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'breedingExclusionReason',
      'comment'
    ];
  }

  static embryoRecoveryItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'embryoRecoveryRankA',
      'embryoRecoveryRankA1',
      'embryoRecoveryRankA2',
      'embryoRecoveryRankA3',
      'embryoRecoveryRankA4',
      'embryoRecoveryRankA5',
      'embryoRecoveryRankB',
      'embryoRecoveryRankB1',
      'embryoRecoveryRankB2',
      'embryoRecoveryRankB3',
      'embryoRecoveryRankB4',
      'embryoRecoveryRankB5',
      'embryoRecoveryRankC',
      'embryoRecoveryRankD',
      'embryoRecoveryRankDegenerated',
      'embryoRecoveryRankUnfertilized',
      'masterSpermNo',
      'targetBreedingDate',
      'embryoMasterRegistered',
      'hormoneProgramName',
      'nextCowState'
    ];
  }

  static bodyWeightMeasurementItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'weight',
      'breedingDays',
      'dg',
      'comment'
    ];
  }

  static workNoteItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'occurredAtTime',
      'masterWorkNoteTypeName',
      'taioTitle',
      'comment'
    ];
  }

  static vaccinationItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'workerName',
      'vaccineTiming',
      'medications',
      'comment'
    ];
  }

  static driedItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'selectedDryPeriod',
      'bcs',
      'beforeCowGroupName',
      'beforePen',
      'moveToCowGroupName',
      'moveToPen',
      'comment'
    ];
  }

  static culledItemsIds(canEditFilterItem) {
    if (canEditFilterItem) {
      return [
        'cowNo',
        'cowUid',
        'occurredAtDate',
        'expelledReason',
        'producingArea',
        'producingFarmName',
        'shipmentNo',
        'buyer',
        'salesPrice',
        'comment'
      ];
    }

    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'expelledReason',
      'producingArea',
      'producingFarmName',
      'shipmentNo',
      'buyer',
      'comment'
    ];
  }

  static diedItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'expelledReason',
      'deathReason',
      'comment'
    ];
  }

  static clawTrimmedItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'workerName',
      'comment'
    ];
  }

  static movedItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'workerName',
      'beforeCowGroupName',
      'beforePen',
      'moveToCowGroupName',
      'moveToPen',
      'comment'
    ];
  }

  static carcassCharacteristicItemsIds(canEditFilterItem) {
    if (canEditFilterItem) {
      return [
        'cowNo',
        'cowUid',
        'occurredAtDate',
        'grade',
        'dressedCarcassWeightOfL',
        'dressedCarcassWeightOfR',
        'totalDressedCarcassWeight',
        'dressedCarcassUnitPrice',
        'dressedCarcassSalesPrice',
        'slaughterNo',
        'beforeSlaughterWeight',
        'loinArea',
        'ribsThickness',
        'subcutaneousFat',
        'yieldBaseValue',
        'bmsNo',
        'marblingGrade',
        'bcsNo',
        'gloss',
        'bcsAndGlossGrade',
        'tight',
        'texture',
        'tightAndTextureGrade',
        'bfsNo',
        'fatLuster',
        'bfsAndFatLusterGrade',
        'carcassDefectSummary',
        'otherFaultCorrection',
        'visceralDestruction',
        'comment'
      ];
    }

    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'grade',
      'dressedCarcassWeightOfL',
      'dressedCarcassWeightOfR',
      'totalDressedCarcassWeight',
      'slaughterNo',
      'beforeSlaughterWeight',
      'loinArea',
      'ribsThickness',
      'subcutaneousFat',
      'yieldBaseValue',
      'bmsNo',
      'marblingGrade',
      'bcsNo',
      'gloss',
      'bcsAndGlossGrade',
      'tight',
      'texture',
      'tightAndTextureGrade',
      'bfsNo',
      'fatLuster',
      'bfsAndFatLusterGrade',
      'carcassDefectSummary',
      'otherFaultCorrection',
      'visceralDestruction',
      'comment'
    ];
  }

  static individualObservationItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'bodyTemperature',
      'eyeCondition',
      'earCondition',
      'muzzleCondition',
      'hairCondition',
      'hipCondition',
      'bodyCondition',
      'fecesCondition',
      'urineCondition',
      'comment'
    ];
  }

  static weanedItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'comment'
    ];
  }

  static dehornedItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'workerName',
      'dehornMethod',
      'comment'
    ];
  }

  static castratedItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'castratMethod',
      'comment'
    ];
  }

  static fattenedItemsIds() {
    return [
      'cowNo',
      'cowUid',
      'occurredAtDate',
      'comment'
    ];
  }

  static farmDiaryItemsIds() {
    return [
      'occurredAtDate',
      'title',
      'memo',
      'edit',
      'delete'
    ];
  }
}
