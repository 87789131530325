/**
 * モバイル用の分娩アラートのカルーセルコンポーネントです
 *
 * ex.
 *   <mobile-alert-calving-carousel
 *     alert-calvings="alertCalvings"
 *     currentIndex="currentIndex"
 *     is-show-go-to-cow-detail-button="isShowGoToCowDetailButton"
 *     is-show-pedigree="isShowPedigree"
 *     on-close-click="onCloseClick()"
 *     on-go-to-cow-detail-click="onGoToCowDetailClick(index)"
 *     on-report-click="onReport-Click(index)"
 *   ></mobile-alert-calving-carousel>
 *
 * プロパティ:
 *  @param {Array.<Object>} alert-calvings 分娩アラート配列
 *  @param {number} current-index 現在のインデックス
 *  @param {boolean} is-show-go-to-cow-detail-button true: 個体詳細画面へボタンを表示
 *  @param {boolean} is-show-pedigree true: 血統を表示
 *  @param {Function} on-close-click Xクリック時に発生するイベント
 *  @param {Function} on-go-to-cow-detail-click 個体詳細画面へボタンクリック時に発生するイベント indexを引数で渡す
 *  @param {Function} on-report-click 確認結果の報告クリック時に発生するイベント indexを引数で渡す
 */
class MobileAlertCalvingCarouselController {
  constructor(
    EventRegistrationSelectionService
  ) {
    'ngInject';
    this.EventRegistrationSelectionService = EventRegistrationSelectionService;
  }

  get carouselIndex() {
    return this.currentIndex;
  }

  set carouselIndex(index) {
    this.currentIndex = index;
  }

  confirmedStatusClass(confirmedStatus) {
    if (confirmedStatus === 'sign') {
      return 'confirmed-status-sign';
    } else if (confirmedStatus === 'no_sign') {
      return 'confirmed-status-no-sign';
    } else {
      return '';
    }
  }

  alertLevelClass(alertLevel) {
    return alertLevel === 'HIGH' ? 'alert-level-high' : 'alert-level-low';
  }

  handleCloseClick() {
    this.onCloseClick();
  }

  handleGoToCowDetailClick() {
    this.onGoToCowDetailClick({index: this.carouselIndex});
  }

  handleReportClick() {
    this.onReportClick({index: this.carouselIndex});
  }

  displayEventSelection() {
    this.EventRegistrationSelectionService.displayEventSelection(this.alertCalvings[this.currentIndex].cowId, {state: 'mobileAlertCalving'});
  }
}

function mobileAlertCalvingCarouselComponents() {
  return {
    templateUrl: 'mobile/alert-list/calving/carousel.html',
    controller: MobileAlertCalvingCarouselController,
    bindings: {
      alertCalvings: '<',
      currentIndex: '<',
      isShowGoToCowDetailButton: '<',
      isShowPedigree: '<',
      onCloseClick: '&',
      onGoToCowDetailClick: '&',
      onReportClick: '&'
    }
  };
}

app.component('mobileAlertCalvingCarousel', mobileAlertCalvingCarouselComponents());
