function CowEventDetailDirective($compile) {
  'ngInject';

  return {
    restrict: 'E',
    scope: '=',
    link: (s, el, attr) => {
      attr.$observe('eventType', () => {
        const fileName = StringUtil.snakeToKebabCase(attr.eventType);
        const template = $compile(
          `<div ng-include="'menu/cow-event/history/template/${fileName}.html'"></div>`
        )(s);
        el.html(template);
      });
    }
  };
}

app.directive('cowEventDetailDirective', CowEventDetailDirective);
