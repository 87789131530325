class UserDefinedSelectionAPI {
  constructor(APIService) {
    'ngInject';
    this.APIService = APIService;
  }

  available() {
    return this.APIService.get('/user_defined_selection/available');
  }
}

app.service('UserDefinedSelectionAPI', UserDefinedSelectionAPI);
