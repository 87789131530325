// eslint-disable-next-line no-unused-vars
class MobileCustomlistControllerBase {
  constructor(
    $stateParams,
    $modal,
    SessionCache,
    CustomlistRunner,
    MobileBulkActionSelectModal,
    MobileBulkEditLabelSelectModal,
    modalDialogFactory,
    routingState
  ) {
    this.$modal = $modal;
    this.CustomlistRunner = CustomlistRunner;
    this.modalDialogFactory = modalDialogFactory;
    this.MobileBulkActionSelectModal = MobileBulkActionSelectModal;
    this.MobileBulkEditLabelSelectModal = MobileBulkEditLabelSelectModal;

    this.init($stateParams, SessionCache.farm(), routingState);
  }

  init(params, farm, routingState) {
    this.customlistId = params.customlistId;
    this.customlist = new Customlist();
    this.state = routingState;

    this.isDepositor = farm.isDepositor();

    this.cows = [];
    this.filterConditions = [];
    this.currentConditions = [];
    this.cowIndex = params.cowIndex || null;
    this.isFilterModalOpen = false;
    this.isAllSelected = false;
    this.useGroupCondition = false;
    this.isFilterIllness = params.filter === 'illness';
    this.isFilterStrongMoveLow = params.filter === 'strongMoveLow';
  }

  run(date, option = {}) {
    return this.CustomlistRunner.run(
      this.customlistId,
      date,
      option
    ).then((res) => {
      this.cows = res.data.cows;
      this.rawCows = [].concat(this.cows);
      this.setConfig(res.data.config);
      if (this.useGroupCondition) {
        this.setGroupCondition(this.rawCows);
      }

      this.stateParams = {
        customlistId: this.customlistId,
        name: this.name,
        cowGroupId: this.cowGroupId,
        state: this.state,
        configType: this.configType
      };

      if (this.configType === 'alert_bad_condition') {
        if (this.isFilterIllness) {
          this.alertTypeConditions = ['acute_illness', 'illness', 'chronic_illness'];
        } else if (this.isFilterStrongMoveLow) {
          this.alertTypeConditions = ['strong_move_low'];
        }
        this.filter();
      }

      if (this.cowIndex !== null) {
        this.onCowClick(this.cows[this.cowIndex]);
      }

      this.columnMap = res.data.config.columns.reduce((acc, cur) => {
        acc[cur.columnId] = cur;
        return acc;
      }, {});

      return res;
    });
  }

  setConfig(config) {
    this.columns = config.columns;
    this.configType = config.master.configType;
    this.needLabelAction = this.columns.some((c) => c.columnId === 'cow_labels');
    this.hasCowGroupField = this.columns.some((c) => c.columnId === 'cow_group_name');
    this.hasPenField = this.columns.some((c) => c.columnId === 'pen');

    if (this.configType === 'alert_bad_condition') {
      if (this.isFilterIllness) {
        this.name = '疾病傾向';
      } else if (this.isFilterStrongMoveLow) {
        this.name = '動態(強)低下';
      }
    } else {
      this.name = config.master.name;
    }
  }

  setGroupCondition(cows) {
    const conditions = CustomlistRuntimeCondition.generateFilterCondition(cows, 'cow_group_name');
    if (conditions) {
      this.groupConditions = conditions;
    }
  }

  shouldShowCowLabels(cow) {
    return this.needLabelAction && cow.cowLabels.length > 0;
  }

  shouldShowCowGroup() {
    return this.hasCowGroupField && !this.hasPenField;
  }

  shouldShowPen() {
    return !this.hasCowGroupField && this.hasPenField;
  }

  shouldShowCowGroupAndPen() {
    return this.hasCowGroupField && this.hasPenField;
  }

  listItemClass(isSelected) {
    return isSelected ? 'mm-listItem is-select' : 'mm-listItem';
  }

  onCowClick(cow) {
    if (this.isSelectMode) {
      this.onCheckBoxClick(cow);
    } else {
      this.cowIndex = this.cows.indexOf(cow);
      this.showDetailDialog = true;
    }
  }

  onFilterButtonClick() {
    if (this.isFilterModalOpen) return;
    this.isFilterModalOpen = true;

    const params = {
      columns: this.columns,
      conditions: this.filterConditions,
      sortColumnId: this.sortColumnId,
      sortOrder: this.sortOrder,
      groupConditions: this.groupConditions || [],
      groupCondition: this.groupCondition,
      alertTypeConditions: this.alertTypeConditions,
    };

    this.$modal.open({
      templateUrl: 'mobile/customlist/runtime/filter-modal/index.html',
      controller: 'MobileCustomListFilterModalController',
      controllerAs: 'ctrl',
      size: '770',
      resolve: {
        params: () => params
      },
    }).result
      .then((result) => {
        if (result.status && result.status.submit) {
          if (result.sortColumnId !== '') {
            this.sortColumnId = result.sortColumnId;
            this.sortOrder = result.sortOrder;
          }
          this.filterConditions = result.conditions;
          this.groupCondition = result.groupCondition;
          this.alertTypeConditions = result.alertTypeConditions;
          this.filter();
          this.sort();
        }
        this.isFilterModalOpen = false;
      })
      .catch((result) => {
        if (result.status && result.status === 'error') console.error(result);
        this.isFilterModalOpen = false;
      });
  }

  filter() {
    let cows = [].concat(this.rawCows);
    if (this.farmCondition) {
      cows = cows.filter((cow) => {
        return cow.farm_name === this.farmCondition;
      });
    }
    if (this.groupCondition) {
      cows = cows.filter((cow) => {
        return cow.cow_group_name === this.groupCondition;
      });
    }
    if (this.alertTypeConditions && this.alertTypeConditions.length) {
      cows = cows.filter((cow) => {
        return this.alertTypeConditions.some((condition) => {
          if (condition === 'chronic_illness' &&
            (cow.acute_illness || cow.illness) &&
            cow.chronic_illness) {
            return false;
          }

          return cow[condition];
        });
      });
    }
    this.cows = new CustomlistFilter().filter(cows, this.filterConditions);
  }

  sort() {
    CustomlistSort.sort(
      this.cows,
      this.columnMap[this.sortColumnId],
      this.sortOrder === 'ascending'
    );
  }

  selectAllButtonLabel() {
    return this.isAllSelected ? '選択をクリア' : '全てを選択';
  }

  onSelectButtonClick() {
    this.toggleIsSelectMode();
  }

  onSelectAllButtonClick() {
    this.isAllSelected = !this.isAllSelected;
    this.setAllSelected(this.isAllSelected);
    this.cowSelected = this.cows.some((cow) => cow.selected);
  }

  onCheckBoxClick(cow) {
    cow.selected = !cow.selected;
    this.cowSelected = this.cows.some((cow) => cow.selected);
  }

  toggleIsSelectMode() {
    this.isSelectMode = !this.isSelectMode;
    this.setAllSelected(false);
    this.isAllSelected = false;
  }

  setAllSelected(selected) {
    this.cows.forEach((cow) => cow.selected = selected);
    this.cowSelected = selected;
  }

  onActionButtonClick() {
    const selectedCows = this.cows.filter((cow) => cow.selected);

    const actions = [
      {value: 'registerEvent', text: 'イベント登録', new: true}
    ];
    if (this.needLabelAction) {
      actions.unshift({
        value: 'editLabel',
        text: 'ラベル編集', new: true
      });
    }

    this.MobileBulkActionSelectModal
      .open(selectedCows, actions).result.then((result) => {
        switch (result.value) {
        case 'editLabel':
          this.MobileBulkEditLabelSelectModal.open(selectedCows)
            .then((result) => {
              if (result.status && result.status.ok) {
                this.run();
                this.toggleIsSelectMode();
              }
            })
            .catch((result) => {
              if (result.status === 'error') console.error(result);
            });
          break;
        case 'registerEvent':
          const exceptions = selectedCows.filter((c) => {
            return c.origin.eliminate_date || c.origin.falling_dead_date;
          });

          if (exceptions.length === 0) {
            this.openBulkEventEntry(selectedCows);
            break;
          }

          if (exceptions.length === selectedCows.length) {
            const modalInstance = this.modalDialogFactory.showAlertModal({
              title: 'エラー',
              text1: '出荷・へい死の牛のみです。イベントを登録できません。'
            });

            return modalInstance.result.then((modalResult) => modalResult);
          } else {
            const modalInstance = this.modalDialogFactory.showYesNoConfirm({
              title: '対象牛確認',
              text1: `出荷・へい死の牛が含まれています。除外してイベント登録を行いますか？`,
              no: true,
              yes: true
            });

            return modalInstance.result.then((result) => {
              if (result === true) {
                const activeCows = selectedCows.filter((c) => !c.origin.eliminate_date && !c.origin.falling_dead_date);
                this.openBulkEventEntry(activeCows);
              }
            });
          }
        default:
        case 'cancel':
          break;
        }
      });
  }

  openBulkEventEntry(selectedCows) {
    const callerState = {
      bqNotes: `alertList config_type = ${this.configType}`,
    };

    this.$modal.open({
      templateUrl: 'mobile/bulk-entry/mobile-bulk-entry-event-select.html',
      controller: 'EventSelectionController',
      controllerAs: 'ctrl',
      size: '770',
      resolve: {
        cowIds: () => selectedCows.map((cow) => cow.cowId),
        cowType: () => null,
        isDepositor: () => this.isDepositor,
        qwert: () => this.qwert,
        caller: () => callerState
      },
    }).result
      .then((result) => {
        if (result.status && result.status.submit) {
          this.run();
          this.toggleIsSelectMode();
        }
      })
      .catch((result) => {
        if (result.status && result.status === 'error') console.error(result);
      });
  }
}
