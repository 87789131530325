class DiseaseIndexDialogController {
  constructor(
    $scope,
    $modal,
    $modalInstance,
    MasterDiseaseAPI) {
    'ngInject';
    this.$modal = $modal;
    this.$modalInstance = $modalInstance;
    this.MasterDiseaseAPI = MasterDiseaseAPI;

    this.initialize($scope);
  }

  initialize($scope) {
    $scope.$on('ngRepeatFinished', () => {
      FixedMidashi.create();
    });

    this.updated = false;

    this.index();
  }

  index() {
    this.MasterDiseaseAPI.index().then((res) => {
      this.masters = res.data;

      this.showChangeOrder = this.masters.length >= 2;
    });
  }

  return() {
    if (this.updated) {
      this.$modalInstance.close();
    } else {
      this.$modalInstance.dismiss('cancel');
    }
  }

  create() {
    this.openEditView(ViewMode.CREATE, '病名・症状登録', null);
  }

  update(master) {
    this.openEditView(ViewMode.UPDATE, '病名・症状更新', master);
  }

  delete(master) {
    this.openEditView(ViewMode.DELETE, '病名・症状削除', master);
  }

  openEditView(viewMode, title, master) {
    this.$modal.open({
      templateUrl: 'dialog/disease/edit.html',
      controller: 'DiseaseEditDialogController',
      controllerAs: 'ctrl',
      size: 'md',
      backdrop: 'static',
      resolve: {
        params: () => {
          return {
            title: title,
            master: master,
            viewMode: viewMode
          };
        }
      }
    }).result.then((res) => {
      this.updated = true;
      this.index();
    });
  }

  changeOrder() {
    const items = this.masters.map((m) => {
      return {key: m.id, label: m.name};
    });

    this.$modal.open({
      templateUrl: 'dialog/sort-order/index.html',
      controller: 'SortOrderController',
      controllerAs: 'ctrl',
      size: 'md',
      backdrop: 'static',
      resolve: {
        params: () => {
          return {
            title: '病名・症状 - 表示順設定',
            items: items,
            updateFunction: this.MasterDiseaseAPI.updateSortOrder.bind(this.MasterDiseaseAPI)
          };
        }
      }
    }).result.then(() => {
      this.updated = true;
      this.index();
    });
  }
}

app.controller('DiseaseIndexDialogController', DiseaseIndexDialogController);
