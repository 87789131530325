// eslint-disable-next-line no-unused-vars
class DailyReportDetailsGenerator {
  generateColumnDefs(columns) {
    return columns.map((column) => {
      return {
        field: column.itemId,
        label: column.label,
        width: column.width,
        headerCellTemplate: this.generateHeaderCellTemplate(column),
        headerCellClass: () => {
          return 'ui-grid-sorted-none';
        },
        cellTemplate: this.generateCellTemplate(column)
      };
    });
  }

  generateHeaderCellTemplate(column) {
    if (column.type === 'BUTTON') {
      return `
        <div ng-class="grid.appScope.classHeaderCell(col)">
          <div class="customlist-th-inner justify-center">
            <span>${column.label}</span>
          </div>
        </div>`;
    } else {
      return `
        <div
          ng-click="grid.appScope.onClickColumnHeader('${column.itemId}', col)"
          ng-class="grid.appScope.classHeaderCell(col)"
        >
          <div class="customlist-th-inner">
            <span>${column.label}</span>
            <div class="sort-icon"></div>
          </div>
        </div>`;
    }
  }

  generateRowTemplate() {
    return `
      <div ng-click="grid.appScope.updateCurrentCow(row.entity)"
        ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
        ng-class="grid.appScope.classCell(row.entity.cowId)"
        class="ui-grid-cell"
        ui-grid-cell>
      </div>
    `;
  }

  generateCellTemplate(column) {
    if (column.type === 'BOOLEAN' ||
      column.itemId === 'registered01' || column.itemId === 'registered02' ||
      column.itemId === 'registered03' || column.itemId === 'registered04') {
      return `
        <div class="ui-grid-cell-contents">
          <i ng-if="row.entity.${column.itemId}" class="icon-ss-check"></i>
        </div>`;
    } else if (column.itemId === 'cowNo') {
      return `
        <div class="ui-grid-cell-contents">
          <a ng-bind="row.entity.cowNo" ng-click="grid.appScope.goToDetails(row.entity.cowId)"></a>
        </div>`;
    } else if (column.itemId === 'lameAffected') {
      return `
        <div class="ui-grid-cell-contents">
          <div ng-repeat="item in row.entity.lameAffected track by $index">
            <span ng-bind="item"></span>
          </div>
        </div>`;
    } else if (column.itemId === 'edit') {
      return `
        <div class="ui-grid-cell-contents center">
          <button ng-click="grid.appScope.onClickEdit(row.entity.edit)"><i class="icon-s-edit"></i></button>
        </div>`;
    } else if (column.itemId === 'delete') {
      return `
        <div class="ui-grid-cell-contents center">
          <button ng-click="grid.appScope.onClickDelete(row.entity.delete)"><i class="icon-s-delete"></i></button>
        </div>`;
    }
  }
}
